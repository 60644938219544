import apiService from "./api";

export const getTaxes = async (parameters) =>
  (await apiService.get("/v2/taxes", parameters)).data;
export const getTax = async (taxId) =>
  (await apiService.get(`/v2/taxes/${taxId}`)).data;
export const createTax = async (request) =>
  (await apiService.post("/v2/taxes", request)).data;
export const updateTax = async (taxId, request) =>
  (await apiService.post(`/v2/taxes/${taxId}`, request)).data;
export const deleteTax = async (taxId) =>
  (await apiService.delete(`/v2/taxes/${taxId}`)).data;
export const sortTaxes = async (request) =>
  (await apiService.post("/v2/taxes/sort", request)).data;
