const TABLE_ACTION_TYPES = {
  RESET_TABLE_REDUCER: "table/RESET_TABLE_REDUCER",

  SET_IS_MODAL_AUTO_CLOSE: "table/SET_IS_MODAL_AUTO_CLOSE",
  SET_TABLES: "table/SET_TABLES",
  SET_ORDER_TABLES: "table/SET_ORDER_TABLES",
  SET_SELECT_TABLES: "table/SET_SELECT_TABLES",
  SET_TABLE: "table/SET_TABLE",
  SET_ORDER_TABLE: "table/SET_ORDER_TABLE",

  SET_IS_TABLES_HAS_MORE: "table/SET_IS_TABLES_HAS_MORE",
  SET_IS_ORDER_TABLES_HAS_MORE: "table/SET_IS_ORDER_TABLES_HAS_MORE",
  SET_IS_SELECT_TABLES_HAS_MORE: "table/SET_IS_SELECT_TABLES_HAS_MORE",

  SET_FETCH_TABLES_SEARCH: "table/SET_FETCH_TABLES_SEARCH",
  SET_FETCH_TABLES_SORT: "table/SET_FETCH_TABLES_SORT",
  SET_FETCH_TABLES_KEY_BY: "table/SET_FETCH_TABLES_KEY_BY",
  SET_FETCH_TABLES_PAGE: "table/SET_FETCH_TABLES_PAGE",
  SET_FETCH_TABLES_PER_PAGE: "table/SET_FETCH_TABLES_PER_PAGE",
  SET_FETCH_TABLES_INCLUDES: "table/SET_FETCH_TABLES_INCLUDES",
  SET_FETCH_TABLES_FILTER_MARKET_ID: "table/SET_FETCH_TABLES_FILTER_MARKET_ID",
  SET_FETCH_TABLES_FILTER_BRANCH_ID: "table/SET_FETCH_TABLES_FILTER_BRANCH_ID",
  SET_FETCH_TABLES_FILTER_STATUS: "table/SET_FETCH_TABLES_FILTER_STATUS",
  SET_FETCH_TABLES_LOADING: "table/SET_FETCH_TABLES_LOADING",
  SET_FETCH_TABLES_SUCCESS: "table/SET_FETCH_TABLES_SUCCESS",
  SET_FETCH_TABLES_FAILED: "table/SET_FETCH_TABLES_FAILED",

  SET_FETCH_ORDER_TABLES_SEARCH: "table/SET_FETCH_ORDER_TABLES_SEARCH",
  SET_FETCH_ORDER_TABLES_SORT: "table/SET_FETCH_ORDER_TABLES_SORT",
  SET_FETCH_ORDER_TABLES_KEY_BY: "table/SET_FETCH_ORDER_TABLES_KEY_BY",
  SET_FETCH_ORDER_TABLES_PAGE: "table/SET_FETCH_ORDER_TABLES_PAGE",
  SET_FETCH_ORDER_TABLES_PER_PAGE: "table/SET_FETCH_ORDER_TABLES_PER_PAGE",
  SET_FETCH_ORDER_TABLES_INCLUDES: "table/SET_FETCH_ORDER_TABLES_INCLUDES",
  SET_FETCH_ORDER_TABLES_FILTER_MARKET_ID:
    "table/SET_FETCH_ORDER_TABLES_FILTER_MARKET_ID",
  SET_FETCH_ORDER_TABLES_FILTER_BRANCH_ID:
    "table/SET_FETCH_ORDER_TABLES_FILTER_BRANCH_ID",
  SET_FETCH_ORDER_TABLES_FILTER_STATUS:
    "table/SET_FETCH_ORDER_TABLES_FILTER_STATUS",
  SET_FETCH_ORDER_TABLES_LOADING: "table/SET_FETCH_ORDER_TABLES_LOADING",
  SET_FETCH_ORDER_TABLES_SUCCESS: "table/SET_FETCH_ORDER_TABLES_SUCCESS",
  SET_FETCH_ORDER_TABLES_FAILED: "table/SET_FETCH_ORDER_TABLES_FAILED",

  SET_FETCH_SELECT_TABLES_SEARCH: "table/SET_FETCH_SELECT_TABLES_SEARCH",
  SET_FETCH_SELECT_TABLES_SORT: "table/SET_FETCH_SELECT_TABLES_SORT",
  SET_FETCH_SELECT_TABLES_KEY_BY: "table/SET_FETCH_SELECT_TABLES_KEY_BY",
  SET_FETCH_SELECT_TABLES_PAGE: "table/SET_FETCH_SELECT_TABLES_PAGE",
  SET_FETCH_SELECT_TABLES_PER_PAGE: "table/SET_FETCH_SELECT_TABLES_PER_PAGE",
  SET_FETCH_SELECT_TABLES_INCLUDES: "table/SET_FETCH_SELECT_TABLES_INCLUDES",
  SET_FETCH_SELECT_TABLES_FILTER_MARKET_ID:
    "table/SET_FETCH_SELECT_TABLES_FILTER_MARKET_ID",
  SET_FETCH_SELECT_TABLES_FILTER_BRANCH_ID:
    "table/SET_FETCH_SELECT_TABLES_FILTER_BRANCH_ID",
  SET_FETCH_SELECT_TABLES_FILTER_TABLES_IDS:
    "table/SET_FETCH_SELECT_TABLES_FILTER_TABLES_IDS",
  SET_FETCH_SELECT_TABLES_FILTER_STATUS:
    "table/SET_FETCH_SELECT_TABLES_FILTER_STATUS",
  SET_FETCH_SELECT_TABLES_LOADING: "table/SET_FETCH_SELECT_TABLES_LOADING",
  SET_FETCH_SELECT_TABLES_SUCCESS: "table/SET_FETCH_SELECT_TABLES_SUCCESS",
  SET_FETCH_SELECT_TABLES_FAILED: "table/SET_FETCH_SELECT_TABLES_FAILED",

  SET_FETCH_TABLE_LOADING: "table/SET_FETCH_TABLE_LOADING",
  SET_FETCH_TABLE_SUCCESS: "table/SET_FETCH_TABLE_SUCCESS",
  SET_FETCH_TABLE_FAILED: "table/SET_FETCH_TABLE_FAILED",

  SET_CREATE_TABLE_LOADING: "table/SET_CREATE_TABLE_LOADING",
  SET_CREATE_TABLE_SUCCESS: "table/SET_CREATE_TABLE_SUCCESS",
  SET_CREATE_TABLE_FAILED: "table/SET_CREATE_TABLE_FAILED",

  SET_MULTIPLE_CREATE_TABLE_LOADING: "table/SET_MULTIPLE_CREATE_TABLE_LOADING",
  SET_MULTIPLE_CREATE_TABLE_SUCCESS: "table/SET_MULTIPLE_CREATE_TABLE_SUCCESS",
  SET_MULTIPLE_CREATE_TABLE_FAILED: "table/SET_MULTIPLE_CREATE_TABLE_FAILED",

  SET_UPDATE_TABLE_LOADING: "table/SET_UPDATE_TABLE_LOADING",
  SET_UPDATE_TABLE_SUCCESS: "table/SET_UPDATE_TABLE_SUCCESS",
  SET_UPDATE_TABLE_FAILED: "table/SET_UPDATE_TABLE_FAILED",

  SET_DELETE_TABLE_LOADING: "table/SET_DELETE_TABLE_LOADING",
  SET_DELETE_TABLE_SUCCESS: "table/SET_DELETE_TABLE_SUCCESS",
  SET_DELETE_TABLE_FAILED: "table/SET_DELETE_TABLE_FAILED",

  APPEND_TABLES: "table/APPEND_TABLES",
  APPEND_ORDER_TABLES: "table/APPEND_ORDER_TABLES",
  APPEND_SELECT_TABLES: "table/APPEND_SELECT_TABLES",

  SET_IS_FETCH_TABLES_HITTED: "table/SET_IS_FETCH_TABLES_HITTED",
  SET_IS_FETCH_ORDER_TABLES_HITTED: "table/SET_IS_FETCH_ORDER_TABLES_HITTED",
  SET_IS_FETCH_SELECT_TABLES_HITTED: "table/SET_IS_FETCH_SELECT_TABLES_HITTED",
  SET_IS_FETCH_TABLE_HITTED: "table/SET_IS_FETCH_TABLE_HITTED",
  SET_IS_CREATE_TABLE_HITTED: "table/SET_IS_CREATE_TABLE_HITTED",
  SET_IS_MULTIPLE_CREATE_TABLE_HITTED:
    "table/SET_IS_MULTIPLE_CREATE_TABLE_HITTED",
  SET_IS_UPDATE_TABLE_HITTED: "table/SET_IS_UPDATE_TABLE_HITTED",
  SET_IS_DELETE_TABLE_HITTED: "table/SET_IS_DELETE_TABLE_HITTED",

  FETCH_TABLES_START: "table/FETCH_TABLES_START",
  FETCH_ORDER_TABLES_START: "table/FETCH_ORDER_TABLES_START",
  FETCH_SELECT_TABLES_START: "table/FETCH_SELECT_TABLES_START",
  FETCH_TABLE_START: "table/FETCH_TABLE_START",
  CREATE_TABLE_START: "table/CREATE_TABLE_START",
  UPDATE_TABLE_START: "table/UPDATE_TABLE_START",
  DELETE_TABLE_START: "table/DELETE_TABLE_START",
  MULTIPLE_CREATE_TABLE_START: "table/MULTIPLE_CREATE_TABLE_START",
};

export default TABLE_ACTION_TYPES;
