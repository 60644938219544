import apiService from "./api";

export const getMerchants = async (parameters) =>
  (await apiService.get("/v2/merchants", parameters)).data;
export const getMerchant = async (merchantId) =>
  (await apiService.get(`/v2/merchants/${merchantId}`)).data;
export const createMerchant = async (request) =>
  (await apiService.post(`/v2/merchants`, request)).data;
export const updateMerchant = async (merchantId, request) =>
  (await apiService.post(`/v2/merchants/${merchantId}`, request)).data;
export const deleteMerchant = async (merchantId) =>
  (await apiService.delete(`/v2/merchants/${merchantId}`)).data;
