import DASHBOARD_MERCHANT_ACTION_TYPES from "./dashboard-merchant.type";

export const DASHBOARD_MERCHANT_INITIAL_STATE = {
  dashboardTotalMarket: null,
  dashboardTotalBranch: null,
  dashboardTotalOrder: null,
  dashboardTotalRevenue: null,
  dashboardOrdersChart: null,
  dashboardLatestMarkets: [],

  dashboardTotalMarketLoading: false,
  dashboardTotalBranchLoading: false,
  dashboardTotalOrderLoading: false,
  dashboardTotalRevenueLoading: false,
  dashboardOrdersChartLoading: false,
  dashboardLatestMarketsLoading: false,

  fetchDashboardMerchantFilterCreatedAtBefore: null,
  fetchDashboardMerchantFilterCreatedAtAfter: null,
  fetchDashboardMerchantFilterCurrency: null,
  fetchDashboardMerchantLoading: false,
  fetchDashboardMerchantSuccess: null,
  fetchDashboardMerchantFailed: null,

  isFetchDashboardMerchantHitted: false,
};

export const dashboardMerchantReducer = (
  state = DASHBOARD_MERCHANT_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_MARKET:
      return { ...state, dashboardTotalMarket: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_BRANCH:
      return { ...state, dashboardTotalBranch: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_ORDER:
      return { ...state, dashboardTotalOrder: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_REVENUE:
      return { ...state, dashboardTotalRevenue: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_ORDERS_CHART:
      return { ...state, dashboardOrdersChart: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_LATEST_MARKETS:
      return { ...state, dashboardLatestMarkets: payload };

    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_MARKET_LOADING:
      return { ...state, dashboardTotalMarketLoading: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_BRANCH_LOADING:
      return { ...state, dashboardTotalBranchLoading: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_ORDER_LOADING:
      return { ...state, dashboardTotalOrderLoading: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_REVENUE_LOADING:
      return { ...state, dashboardTotalRevenueLoading: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_ORDERS_CHART_LOADING:
      return { ...state, dashboardOrdersChartLoading: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_LATEST_MARKETS_LOADING:
      return { ...state, dashboardLatestMarketsLoading: payload };

    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_FETCH_DASHBOARD_MERCHANT_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchDashboardMerchantFilterCreatedAtBefore: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_FETCH_DASHBOARD_MERCHANT_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchDashboardMerchantFilterCreatedAtAfter: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_FETCH_DASHBOARD_MERCHANT_FILTER_CURRENCY:
      return { ...state, fetchDashboardMerchantFilterCurrency: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_FETCH_DASHBOARD_MERCHANT_LOADING:
      return { ...state, fetchDashboardMerchantLoading: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_FETCH_DASHBOARD_MERCHANT_SUCCESS:
      return { ...state, fetchDashboardMerchantSuccess: payload };
    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_FETCH_DASHBOARD_MERCHANT_FAILED:
      return { ...state, fetchDashboardMerchantFailed: payload };

    case DASHBOARD_MERCHANT_ACTION_TYPES.SET_IS_FETCH_DASHBOARD_MERCHANT_HITTED:
      return { ...state, isFetchDashboardMerchantHitted: payload };

    case DASHBOARD_MERCHANT_ACTION_TYPES.RESET_DASHBOARD_MERCHANT_REDUCER:
      return DASHBOARD_MERCHANT_INITIAL_STATE;
    default:
      return state;
  }
};
