import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { LANGUAGE_JSONS } from "./constants/common.constant";

const resources = Object.keys(LANGUAGE_JSONS).reduce((languages, code) => {
  return { ...languages, [code]: { translation: LANGUAGE_JSONS[code] } };
}, {});

const detection = {
  order: ["querystring", "localStorage"],
  lookupQuerystring: "lng",
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection,
    debug: true,
    interpolation: { escapeValue: false },
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i"],
  });

export default i18next;
