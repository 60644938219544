import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { UPLOAD_TYPES } from "../../constants/upload.constant";

import { getValidFiles, getValidUrls } from "../../utils/store.utils";
import { isEmpty } from "../../utils/validation.utils";

import PRODUCT_ACTION_TYPES from "./product.type";

import {
  appendOrderProducts,
  appendProducts,
  setCreateProductFailed,
  setCreateProductLoading,
  setCreateProductSuccess,
  setDeleteProductFailed,
  setDeleteProductLoading,
  setDeleteProductSuccess,
  setFetchOrderProductsFailed,
  setFetchOrderProductsLoading,
  setFetchOrderProductsPage,
  setFetchOrderProductsSuccess,
  setFetchProductFailed,
  setFetchProductLoading,
  setFetchProductsFailed,
  setFetchProductsLoading,
  setFetchProductsPage,
  setFetchProductsSuccess,
  setFetchProductSuccess,
  setIsCreateProductHitted,
  setIsDeleteProductHitted,
  setIsFetchOrderProductsHitted,
  setIsFetchProductHitted,
  setIsFetchProductsHitted,
  setIsMultipleCreateProductsHitted,
  setIsMultipleDeleteProductsHitted,
  setIsOrderProductsHasMore,
  setIsProductsHasMore,
  setIsUpdateProductHitted,
  setMultipleCreateProductsFailed,
  setMultipleCreateProductsLoading,
  setMultipleCreateProductsSuccess,
  setMultipleDeleteProductsFailed,
  setMultipleDeleteProductsLoading,
  setMultipleDeleteProductsSuccess,
  setOrderProducts,
  setProduct,
  setProducts,
  setUpdateProductFailed,
  setUpdateProductLoading,
  setUpdateProductSuccess,
} from "./product.action";
import {
  getFetchOrderProductsFilterBranchId,
  getFetchOrderProductsFilterIsActive,
  getFetchOrderProductsFilterMarketId,
  getFetchOrderProductsFilterProductCategoryId,
  getFetchOrderProductsFilterProductsIds,
  getFetchOrderProductsIncludes,
  getFetchOrderProductsKeyBy,
  getFetchOrderProductsPage,
  getFetchOrderProductsPerPage,
  getFetchOrderProductsSearch,
  getFetchOrderProductsSort,
  getFetchProductsFilterBranchId,
  getFetchProductsFilterIsActive,
  getFetchProductsFilterMarketId,
  getFetchProductsFilterProductCategoryId,
  getFetchProductsFilterProductsIds,
  getFetchProductsIncludes,
  getFetchProductsKeyBy,
  getFetchProductsPage,
  getFetchProductsPerPage,
  getFetchProductsSearch,
  getFetchProductsSort,
  getIsFetchOrderProductsHitted,
  getIsFetchProductsHitted,
} from "./product.selector";
import {
  getFetchProductsParams,
  getIsFetchProductsHitted as getIsFetchSelectProductsHitted,
} from "../select-product/select-product.selector";
import { _getFetchProducts as _getSelectProducts } from "../select-product/select-product.saga";

import {
  getProducts,
  getProduct,
  createProduct,
  updateProduct,
  deleteProduct,
  multiDeleteProducts,
  multiCreateProducts,
} from "../../api/product.api";
import { uploadFiles } from "../../api/upload.api";

export function* _getProducts() {
  try {
    yield put(setFetchProductsLoading(true));

    const search = yield select(getFetchProductsSearch);
    const sort = yield select(getFetchProductsSort);
    const key_by = yield select(getFetchProductsKeyBy);
    const page = yield select(getFetchProductsPage);
    const per_page = yield select(getFetchProductsPerPage);
    const includes = yield select(getFetchProductsIncludes);
    const market_id = yield select(getFetchProductsFilterMarketId);
    const branch_id = yield select(getFetchProductsFilterBranchId);
    const product_category_id = yield select(
      getFetchProductsFilterProductCategoryId
    );
    const products_ids = yield select(getFetchProductsFilterProductsIds);
    const is_active = yield select(getFetchProductsFilterIsActive);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        product_category_id,
        products_ids,
        is_active,
      },
    };

    const {
      meta: { message },
      data: { data: products },
    } = yield call(getProducts, parameters);

    yield put(setIsFetchProductsHitted(true));
    yield put(setIsProductsHasMore(products.length > 0));

    if (page > 1) {
      yield put(appendProducts(products));
    } else {
      yield put(setProducts(products));
    }

    yield put(setFetchProductsSuccess(message));
    yield put(setFetchProductsLoading(false));
  } catch (error) {
    yield put(setFetchProductsFailed(error));
    yield put(setFetchProductsLoading(false));
  }
}
export function* _getOrderProducts() {
  try {
    yield put(setFetchOrderProductsLoading(true));

    const search = yield select(getFetchOrderProductsSearch);
    const sort = yield select(getFetchOrderProductsSort);
    const key_by = yield select(getFetchOrderProductsKeyBy);
    const page = yield select(getFetchOrderProductsPage);
    const per_page = yield select(getFetchOrderProductsPerPage);
    const includes = yield select(getFetchOrderProductsIncludes);
    const market_id = yield select(getFetchOrderProductsFilterMarketId);
    const branch_id = yield select(getFetchOrderProductsFilterBranchId);
    const product_category_id = yield select(
      getFetchOrderProductsFilterProductCategoryId
    );
    const products_ids = yield select(getFetchOrderProductsFilterProductsIds);
    const is_active = yield select(getFetchOrderProductsFilterIsActive);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        product_category_id,
        products_ids,
        is_active,
      },
    };

    const {
      meta: { message },
      data: { data: products },
    } = yield call(getProducts, parameters);

    yield put(setIsFetchOrderProductsHitted(true));
    yield put(setIsOrderProductsHasMore(products.length > 0));

    if (page > 1) {
      yield put(appendOrderProducts(products));
    } else {
      yield put(setOrderProducts(products));
    }

    yield put(setFetchOrderProductsSuccess(message));
    yield put(setFetchOrderProductsLoading(false));
  } catch (error) {
    yield put(setFetchOrderProductsFailed(error));
    yield put(setFetchOrderProductsLoading(false));
  }
}
export function* _getProduct({ payload: productId }) {
  try {
    yield put(setFetchProductLoading(true));

    const {
      meta: { message },
      data: product,
    } = yield call(getProduct, productId);

    yield put(setIsFetchProductHitted(true));
    yield put(setProduct(product));

    yield put(setFetchProductSuccess(message));
    yield put(setFetchProductLoading(false));
  } catch (error) {
    yield put(setFetchProductFailed(error));
    yield put(setFetchProductLoading(false));
  }
}
export function* _createProduct({ payload: request }) {
  try {
    yield put(setCreateProductLoading(true));

    const payload = { ...request };
    if (!isEmpty(payload?.images)) {
      const files = getValidFiles(payload?.images);

      if (!isEmpty(files)) {
        const prevUrls = getValidUrls(payload.images);
        const request = { type: UPLOAD_TYPES.PRODUCT, files };

        const { data: imageUrls } = yield call(uploadFiles, request);

        payload.images = [...prevUrls, ...imageUrls];
      }
    }

    const {
      meta: { message },
    } = yield call(createProduct, payload);

    yield put(setIsCreateProductHitted(true));

    const isFetchProductsHitted = yield select(getIsFetchProductsHitted);
    const isFetchOrderProductsHitted = yield select(
      getIsFetchOrderProductsHitted
    );
    const isFetchSelectProductsHitted = yield select(
      getIsFetchSelectProductsHitted
    );

    if (isFetchProductsHitted) {
      yield put(setFetchProductsPage(1));
      yield call(_getProducts);
    }
    if (isFetchOrderProductsHitted) {
      yield put(setFetchOrderProductsPage(1));
      yield call(_getOrderProducts);
    }
    if (isFetchSelectProductsHitted) {
      const params = yield select(getFetchProductsParams);
      yield call(_getSelectProducts, { payload: { ...params, page: 1 } });
    }

    yield put(setCreateProductSuccess(message));
    yield put(setCreateProductLoading(false));
  } catch (error) {
    yield put(setCreateProductFailed(error));
    yield put(setCreateProductLoading(false));
  }
}
export function* _updateProduct({ payload: { productId, request } }) {
  try {
    yield put(setUpdateProductLoading(true));

    const payload = { ...request };
    if (!isEmpty(payload?.images)) {
      const files = getValidFiles(payload?.images);

      if (!isEmpty(files)) {
        const prevUrls = getValidUrls(payload.images);
        const request = { type: UPLOAD_TYPES.PRODUCT, files };

        const { data: imageUrls } = yield call(uploadFiles, request);

        payload.images = [...prevUrls, ...imageUrls];
      }
    }

    const {
      meta: { message },
    } = yield call(updateProduct, productId, payload);

    yield put(setIsUpdateProductHitted(true));

    const isFetchProductsHitted = yield select(getIsFetchProductsHitted);
    const isFetchOrderProductsHitted = yield select(
      getIsFetchOrderProductsHitted
    );
    const isFetchSelectProductsHitted = yield select(
      getIsFetchSelectProductsHitted
    );

    if (isFetchProductsHitted) {
      yield put(setFetchProductsPage(1));
      yield call(_getProducts);
    }
    if (isFetchOrderProductsHitted) {
      yield put(setFetchOrderProductsPage(1));
      yield call(_getOrderProducts);
    }
    if (isFetchSelectProductsHitted) {
      const params = yield select(getFetchProductsParams);
      yield call(_getSelectProducts, { payload: { ...params, page: 1 } });
    }

    yield put(setUpdateProductSuccess(message));
    yield put(setUpdateProductLoading(false));
  } catch (error) {
    yield put(setUpdateProductFailed(error));
    yield put(setUpdateProductLoading(false));
  }
}
export function* _deleteProduct({ payload: productId }) {
  try {
    yield put(setDeleteProductLoading(true));

    const {
      meta: { message },
    } = yield call(deleteProduct, productId);

    yield put(setIsDeleteProductHitted(true));

    const isFetchProductsHitted = yield select(getIsFetchProductsHitted);
    const isFetchOrderProductsHitted = yield select(
      getIsFetchOrderProductsHitted
    );
    const isFetchSelectProductsHitted = yield select(
      getIsFetchSelectProductsHitted
    );

    if (isFetchProductsHitted) {
      yield put(setFetchProductsPage(1));
      yield call(_getProducts);
    }
    if (isFetchOrderProductsHitted) {
      yield put(setFetchOrderProductsPage(1));
      yield call(_getOrderProducts);
    }
    if (isFetchSelectProductsHitted) {
      const params = yield select(getFetchProductsParams);
      yield call(_getSelectProducts, { payload: { ...params, page: 1 } });
    }

    yield put(setDeleteProductSuccess(message));
    yield put(setDeleteProductLoading(false));
  } catch (error) {
    yield put(setDeleteProductFailed(error));
    yield put(setDeleteProductLoading(false));
  }
}
export function* _multipleCreateProducts({ payload: request }) {
  try {
    yield put(setMultipleCreateProductsLoading(true));

    const payload = { ...request };
    if (!isEmpty(payload?.images)) {
      const files = getValidFiles(payload?.images);

      if (!isEmpty(files)) {
        const prevUrls = getValidUrls(payload.images);
        const request = { type: UPLOAD_TYPES.PRODUCT, files };

        const { data: imageUrls } = yield call(uploadFiles, request);

        payload.images = [...prevUrls, ...imageUrls];
      }
    }

    const {
      meta: { message },
    } = yield call(multiCreateProducts, payload);

    yield put(setIsMultipleCreateProductsHitted(true));

    const isFetchProductsHitted = yield select(getIsFetchProductsHitted);
    const isFetchOrderProductsHitted = yield select(
      getIsFetchOrderProductsHitted
    );
    const isFetchSelectProductsHitted = yield select(
      getIsFetchSelectProductsHitted
    );

    if (isFetchProductsHitted) {
      yield put(setFetchProductsPage(1));
      yield call(_getProducts);
    }
    if (isFetchOrderProductsHitted) {
      yield put(setFetchOrderProductsPage(1));
      yield call(_getOrderProducts);
    }
    if (isFetchSelectProductsHitted) {
      const params = yield select(getFetchProductsParams);
      yield call(_getSelectProducts, { payload: { ...params, page: 1 } });
    }

    yield put(setMultipleCreateProductsSuccess(message));
    yield put(setMultipleCreateProductsLoading(false));
  } catch (error) {
    yield put(setMultipleCreateProductsFailed(error));
    yield put(setMultipleCreateProductsLoading(false));
  }
}
export function* _multipleDeleteProducts({ payload: request }) {
  try {
    yield put(setMultipleDeleteProductsLoading(true));

    const {
      meta: { message },
    } = yield call(multiDeleteProducts, request);

    yield put(setIsMultipleDeleteProductsHitted(true));

    const isFetchProductsHitted = yield select(getIsFetchProductsHitted);
    const isFetchOrderProductsHitted = yield select(
      getIsFetchOrderProductsHitted
    );
    const isFetchSelectProductsHitted = yield select(
      getIsFetchSelectProductsHitted
    );

    if (isFetchProductsHitted) {
      yield put(setFetchProductsPage(1));
      yield call(_getProducts);
    }
    if (isFetchOrderProductsHitted) {
      yield put(setFetchOrderProductsPage(1));
      yield call(_getOrderProducts);
    }
    if (isFetchSelectProductsHitted) {
      const params = yield select(getFetchProductsParams);
      yield call(_getSelectProducts, { payload: { ...params, page: 1 } });
    }

    yield put(setMultipleDeleteProductsSuccess(message));
    yield put(setMultipleDeleteProductsLoading(false));
  } catch (error) {
    yield put(setMultipleDeleteProductsFailed(error));
    yield put(setMultipleDeleteProductsLoading(false));
  }
}

export function* onFetchProductsStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_START, _getProducts);
}
export function* onFetchOrderProductsStart() {
  yield takeLatest(
    PRODUCT_ACTION_TYPES.FETCH_ORDER_PRODUCTS_START,
    _getOrderProducts
  );
}
export function* onFetchProductStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.FETCH_PRODUCT_START, _getProduct);
}
export function* onCreateProductStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.CREATE_PRODUCT_START, _createProduct);
}
export function* onUpdateProductStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_START, _updateProduct);
}
export function* onDeleteProductStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.DELETE_PRODUCT_START, _deleteProduct);
}
export function* onMultipleCreateProductsStart() {
  yield takeLatest(
    PRODUCT_ACTION_TYPES.MULTIPLE_CREATE_PRODUCTS_START,
    _multipleCreateProducts
  );
}
export function* onMultipleDeleteProductsStart() {
  yield takeLatest(
    PRODUCT_ACTION_TYPES.MULTIPLE_DELETE_PRODUCTS_START,
    _multipleDeleteProducts
  );
}

export function* productSaga() {
  yield all([
    call(onFetchProductsStart),
    call(onFetchOrderProductsStart),
    call(onFetchProductStart),
    call(onCreateProductStart),
    call(onUpdateProductStart),
    call(onDeleteProductStart),
    call(onMultipleCreateProductsStart),
    call(onMultipleDeleteProductsStart),
  ]);
}
