const SUBSCRIPTION_ACTION_TYPES = {
  RESET_SUBSCRIPTION_REDUCER: "subscription/RESET_SUBSCRIPTION_REDUCER",

  SET_SUBSCRIPTION: "subscription/SET_SUBSCRIPTION",

  SET_FETCH_SUBSCRIPTION_LOADING: "subscription/SET_FETCH_SUBSCRIPTION_LOADING",
  SET_FETCH_SUBSCRIPTION_SUCCESS: "subscription/SET_FETCH_SUBSCRIPTION_SUCCESS",
  SET_FETCH_SUBSCRIPTION_FAILED: "subscription/SET_FETCH_SUBSCRIPTION_FAILED",

  SET_UPDATE_SUBSCRIPTION_LOADING:
    "subscription/SET_UPDATE_SUBSCRIPTION_LOADING",
  SET_UPDATE_SUBSCRIPTION_SUCCESS:
    "subscription/SET_UPDATE_SUBSCRIPTION_SUCCESS",
  SET_UPDATE_SUBSCRIPTION_FAILED: "subscription/SET_UPDATE_SUBSCRIPTION_FAILED",

  SET_IS_FETCH_SUBSCRIPTION_HITTED:
    "subscription/SET_IS_FETCH_SUBSCRIPTION_HITTED",
  SET_IS_UPDATE_SUBSCRIPTION_HITTED:
    "subscription/SET_IS_UPDATE_SUBSCRIPTION_HITTED",

  FETCH_SUBSCRIPTION_START: "subscription/FETCH_SUBSCRIPTION_START",
  UPDATE_SUBSCRIPTION_START: "subscription/UPDATE_SUBSCRIPTION_START",
};

export default SUBSCRIPTION_ACTION_TYPES;
