export const ATTENDANCE_REPORTS = {
  CASHIER: "CASHIER",
  BRANCH: "BRANCH",
};

export const ATTENDANCE_COLUMNS = {
  ID: "id",
  MARKET_ID: "market_id",
  BRANCH_ID: "branch_id",
  USER_ID: "user_id",
  START_AT: "start_at",
  END_AT: "end_at",
  START_CASH: "start_cash",
  END_CASH: "end_cash",
};

export const ATTENDANCE_INCLUDES = {
  MARKET: "market",
  BRANCH: "branch",
  USER: "user",
};
