import WATHQ_ACTION_TYPES from "./wathq.type";
import { createAction } from "../../utils/store.utils";

export const setWathq = (wathq) => createAction(WATHQ_ACTION_TYPES.SET_WATHQ, wathq);

export const setFetchWathqLoading = (fetchWathqLoading) =>
  createAction(WATHQ_ACTION_TYPES.SET_FETCH_WATHQ_LOADING, fetchWathqLoading);

export const setFetchWathqSuccess = (fetchWathqSuccess) =>
  createAction(WATHQ_ACTION_TYPES.SET_FETCH_WATHQ_SUCCESS, fetchWathqSuccess);

export const setFetchWathqFailed = (fetchWathqFailed) =>
  createAction(WATHQ_ACTION_TYPES.SET_FETCH_WATHQ_FAILED, fetchWathqFailed);

export const fetchWathqStart = (crn) => createAction(WATHQ_ACTION_TYPES.FETCH_WATHQ_START, crn);

export const resetWathqReducer = () => createAction(WATHQ_ACTION_TYPES.RESET_WATHQ_REDUCER);
