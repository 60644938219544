import moment from "moment";

import { store } from "../store/store";

import { CURRENCY_SHORT } from "../constants/common.constant";

import { parseToFloat, parseToInteger } from "./parser.utils";
import { isEmpty, isInvalidNumber } from "./validation.utils";

export const getFormattedValues = (inputValues = {}) => {
  const getFormattedValue = (value) => {
    const isArray = Array.isArray(value);
    const isFile = value instanceof File;
    const isObject =
      typeof value === "object" && !isArray && value !== null && !isFile;
    const isBoolean = typeof value === "boolean";

    if (isArray) {
      value =
        value.length === 0 ? null : value.map((val) => getFormattedValue(val));
    } else if (isObject) {
      value =
        Object.keys(value).length === 0
          ? null
          : Object.entries(value).reduce((newVal, [key, val]) => {
              return { ...newVal, [key]: getFormattedValue(val) };
            }, {});
    } else if (isBoolean) {
      value = value === true ? 1 : 0;
    } else {
      value = value === "" ? null : value;
    }

    return value;
  };

  return Object.keys(inputValues).reduce((newInputValues, field) => {
    return {
      ...newInputValues,
      [field]: getFormattedValue(inputValues[field]),
    };
  }, {});
};

export const getConstantFormat = (constant) =>
  `${constant}`
    .toLowerCase()
    .replace(/[-_]/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());

export const getPhoneNumberFormat = (prefix, number) => {
  const phonePrefix = `${prefix ?? ""}`.replace(/[^0-9\.]+/g, "");
  const phoneNumber = `${number ?? ""}`.replace(/[^0-9\.]+/g, "");

  let phoneFormatted = "";
  if (phonePrefix !== "") {
    phoneFormatted += `(${phonePrefix})`;
  }
  if (phoneNumber !== "") {
    if (phoneNumber.length < 6) {
      phoneFormatted += " " + phoneNumber;
    } else {
      phoneFormatted += " " + phoneNumber.slice(0, 3) + "-";
      phoneFormatted += phoneNumber.slice(3, phoneNumber.length);
    }
  }

  return phoneFormatted;
};

export const getCurrencyFormat = (nominal, currency = null) => {
  const state = store.getState();
  const country = state?.global?.currentMarket?.setting?.country;

  currency ??= country?.currency ?? "SAR";

  const options = {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return new Intl.NumberFormat("en-US", options).format(parseToFloat(nominal));
};

export const getNumberFormat = (number) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parseToFloat(number));

export const getMoneyValueFormat = (amount, currency = null, precision = 2) => {
  const state = store.getState();
  const country = state?.global?.currentMarket?.setting?.country;

  currency ??= country?.currency ?? "SAR";

  const factor = Math.pow(10, precision);

  return Math.round(amount * factor) / factor;
};

export const getDateTimeFormat = (
  milliseconds,
  format = "DD MMM YYYY, h:mm A",
  locale
) => {
  if (isEmpty(milliseconds)) return "-";

  const dateMoment = moment.isMoment(milliseconds)
    ? milliseconds
    : moment(parseToInteger(milliseconds), "x");

  return isEmpty(locale)
    ? dateMoment.format(format)
    : dateMoment.locale(locale).format(format);
};

export const getSeparatedStringFormat = (string, number) => {
  if (["", null, undefined].includes(string)) return;

  let formatted = "";
  let count = 0;
  for (let i = 0; i < string.length; i++) {
    formatted += count === number ? ` ${string[i]}` : string[i];
    if (count === number) count = 0;
    count++;
  }

  return formatted;
};

export const getQueryParamsString = (params, prefix) => {
  const queries = [];
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      const paramName = prefix ? `${prefix}[${key}]` : key;
      if (value !== null && typeof value === "object") {
        queries.push(getQueryParamsString(value, paramName));
      } else {
        queries.push(
          `${encodeURIComponent(paramName)}=${encodeURIComponent(value)}`
        );
      }
    }
  }
  return queries.join("&");
};

export const getRemovedDuplicates = (array, uniqueKey) => {
  const uniqueMap = new Map();

  for (const obj of array) {
    const key = obj[uniqueKey];
    uniqueMap.set(key, obj);
  }

  const uniqueArray = Array.from(uniqueMap.values());

  return uniqueArray;
};

export const getStrippedHtml = (string) => string.replace(/<[^>]*>/g, "");

export const getStrippedSpecials = (string) => string.replace(/[^\w\s]/g, "");

export const getFormattedPhone = (country, phone) => {
  const { phone_start, phone_max } = country ?? {};

  const prefixRegex = new RegExp(
    `${phone_start}`.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  );
  const phoneNumber = `${phone_start ?? ""}${phone.replace(prefixRegex, "")}`;
  const maxLength = !isEmpty(phone_max)
    ? phone_max + `${phone_start ?? ""}`.length
    : phoneNumber.length;

  return phoneNumber.slice(0, maxLength);
};

export const getFileNameUrl = (fileUrl) => {
  const pathName = new URL(fileUrl).pathname;
  const fileName = pathName.substring(pathName.lastIndexOf("/") + 1);

  return fileName;
};

export const getHiddenString = (string, visible = 3) => {
  if (typeof string !== "string" || string.length <= visible) {
    return string;
  }

  const hiddenPart = "*".repeat(string.length - visible);
  const visiblePart = string.slice(-visible);

  return hiddenPart + visiblePart;
};
