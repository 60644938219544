const REPORT_ATTENDANCE_ACTION_TYPES = {
  RESET_REPORT_ATTENDANCE_REDUCER:
    "report-attendance/RESET_REPORT_ATTENDANCE_REDUCER",

  SET_REPORT_TOTALS: "report-attendance/SET_REPORT_TOTALS",
  SET_REPORT_ATTENDANCES: "report-attendance/SET_REPORT_ATTENDANCES",
  SET_REPORT_CASHIER_TOTALS: "report-attendance/SET_REPORT_CASHIER_TOTALS",
  SET_REPORT_BRANCH_TOTALS: "report-attendance/SET_REPORT_BRANCH_TOTALS",

  SET_IS_REPORT_ATTENDANCES_HAS_MORE:
    "report-attendance/SET_IS_REPORT_ATTENDANCES_HAS_MORE",

  FETCH_REPORTS_FILTER_MARKET_ID:
    "report-attendance/FETCH_REPORTS_FILTER_MARKET_ID",
  FETCH_REPORTS_FILTER_BRANCH_ID:
    "report-attendance/FETCH_REPORTS_FILTER_BRANCH_ID",
  FETCH_REPORTS_FILTER_USER_ID:
    "report-attendance/FETCH_REPORTS_FILTER_USER_ID",
  FETCH_REPORTS_FILTER_START_AT_AFTER:
    "report-attendance/FETCH_REPORTS_FILTER_START_AT_AFTER",
  FETCH_REPORTS_FILTER_END_AT_BEFORE:
    "report-attendance/FETCH_REPORTS_FILTER_END_AT_BEFORE",

  FETCH_REPORT_TOTALS_LOADING: "report-attendance/FETCH_REPORT_TOTALS_LOADING",
  FETCH_REPORT_TOTALS_SUCCESS: "report-attendance/FETCH_REPORT_TOTALS_SUCCESS",
  FETCH_REPORT_TOTALS_FAILED: "report-attendance/FETCH_REPORT_TOTALS_FAILED",

  FETCH_REPORT_ATTENDANCES_SEARCH:
    "report-attendance/FETCH_REPORT_ATTENDANCES_SEARCH",
  FETCH_REPORT_ATTENDANCES_SORT:
    "report-attendance/FETCH_REPORT_ATTENDANCES_SORT",
  FETCH_REPORT_ATTENDANCES_KEY_BY:
    "report-attendance/FETCH_REPORT_ATTENDANCES_KEY_BY",
  FETCH_REPORT_ATTENDANCES_PAGE:
    "report-attendance/FETCH_REPORT_ATTENDANCES_PAGE",
  FETCH_REPORT_ATTENDANCES_PER_PAGE:
    "report-attendance/FETCH_REPORT_ATTENDANCES_PER_PAGE",
  FETCH_REPORT_ATTENDANCES_INCLUDES:
    "report-attendance/FETCH_REPORT_ATTENDANCES_INCLUDES",
  FETCH_REPORT_ATTENDANCES_LOADING:
    "report-attendance/FETCH_REPORT_ATTENDANCES_LOADING",
  FETCH_REPORT_ATTENDANCES_SUCCESS:
    "report-attendance/FETCH_REPORT_ATTENDANCES_SUCCESS",
  FETCH_REPORT_ATTENDANCES_FAILED:
    "report-attendance/FETCH_REPORT_ATTENDANCES_FAILED",

  FETCH_REPORT_ATTENDANCE_TOTALS_FILTER_ORDER_STATUSES:
    "report-attendance/FETCH_REPORT_ATTENDANCE_TOTALS_FILTER_ORDER_STATUSES",
  FETCH_REPORT_ATTENDANCE_TOTALS_FILTER_TRANSACTION_STATUSES:
    "report-attendance/FETCH_REPORT_ATTENDANCE_TOTALS_FILTER_TRANSACTION_STATUSES",

  FETCH_REPORT_CASHIER_TOTALS_FILTER_CASHIER_ID:
    "report-attendance/FETCH_REPORT_CASHIER_TOTALS_FILTER_CASHIER_ID",
  FETCH_REPORT_CASHIER_TOTALS_LOADING:
    "report-attendance/FETCH_REPORT_CASHIER_TOTALS_LOADING",
  FETCH_REPORT_CASHIER_TOTALS_SUCCESS:
    "report-attendance/FETCH_REPORT_CASHIER_TOTALS_SUCCESS",
  FETCH_REPORT_CASHIER_TOTALS_FAILED:
    "report-attendance/FETCH_REPORT_CASHIER_TOTALS_FAILED",

  FETCH_REPORT_BRANCH_TOTALS_LOADING:
    "report-attendance/FETCH_REPORT_BRANCH_TOTALS_LOADING",
  FETCH_REPORT_BRANCH_TOTALS_SUCCESS:
    "report-attendance/FETCH_REPORT_BRANCH_TOTALS_SUCCESS",
  FETCH_REPORT_BRANCH_TOTALS_FAILED:
    "report-attendance/FETCH_REPORT_BRANCH_TOTALS_FAILED",

  APPEND_REPORT_ATTENDANCES: "report-attendance/APPEND_REPORT_ATTENDANCES",

  SET_IS_FETCH_REPORT_TOTALS_HITTED:
    "report-attendance/SET_IS_FETCH_REPORT_TOTALS_HITTED",
  SET_IS_FETCH_REPORT_ATTENDANCES_HITTED:
    "report-attendance/SET_IS_FETCH_REPORT_ATTENDANCES_HITTED",
  SET_IS_FETCH_REPORT_CASHIER_TOTALS_HITTED:
    "report-attendance/SET_IS_FETCH_REPORT_CASHIER_TOTALS_HITTED",
  SET_IS_FETCH_REPORT_BRANCH_TOTALS_HITTED:
    "report-attendance/SET_IS_FETCH_REPORT_BRANCH_TOTALS_HITTED",

  FETCH_REPORT_TOTALS_START: "report-attendance/FETCH_REPORT_TOTALS_START",
  FETCH_REPORT_ATTENDANCES_START:
    "report-attendance/FETCH_REPORT_ATTENDANCES_START",
  FETCH_REPORT_CASHIER_TOTALS_START:
    "report-attendance/FETCH_REPORT_CASHIER_TOTALS_START",
  FETCH_REPORT_BRANCH_TOTALS_START:
    "report-attendance/FETCH_REPORT_BRANCH_TOTALS_START",
};

export default REPORT_ATTENDANCE_ACTION_TYPES;
