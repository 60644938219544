import apiService from "./api";

export const getUserCustomers = async (parameters) =>
  (await apiService.get("/v2/users/customers", parameters)).data;
export const getUserCustomer = async (customerId) =>
  (await apiService.get(`/v2/users/customers/${customerId}`)).data;
export const createUserCustomer = async (request) =>
  (await apiService.post("/v2/users/customers", request)).data;
export const updateUserCustomer = async (customerId, request) =>
  (await apiService.post(`/v2/users/customers/${customerId}`, request)).data;

export const addMarketUserCustomer = async (customerId, marketId) =>
  (
    await apiService.post(
      `/v2/users/customers/${customerId}/markets/${marketId}`
    )
  ).data;
export const removeMarketUserCustomer = async (customerId, marketId) =>
  (
    await apiService.delete(
      `/v2/users/customers/${customerId}/markets/${marketId}`
    )
  ).data;
