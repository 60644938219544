const ORDER_ACTION_TYPES = {
  RESET_ORDER_REDUCER: "order/RESET_ORDER_REDUCER",

  SET_ORDER_TABLE_COLUMNS: "order/SET_ORDER_TABLE_COLUMNS",
  SET_ORDER_TAB: "order/SET_ORDER_TAB",
  SET_ORDERS: "order/SET_ORDERS",
  SET_WAITING_ORDERS: "order/SET_WAITING_ORDERS",
  SET_PROCESS_ORDERS: "order/SET_PROCESS_ORDERS",
  SET_COMPLETED_ORDERS: "order/SET_COMPLETED_ORDERS",
  SET_REPORT_ORDERS: "order/SET_REPORT_ORDERS",
  SET_CASHIER_ORDERS: "order/SET_CASHIER_ORDERS",
  SET_CUSTOMER_ORDERS: "order/SET_CUSTOMER_ORDERS",
  SET_LATEST_ORDERS: "order/SET_LATEST_ORDERS",
  SET_TABLE_ORDERS: "order/SET_TABLE_ORDERS",

  SET_ORDER: "order/SET_ORDER",
  SET_CUSTOMER_ORDER: "order/SET_CUSTOMER_ORDER",
  SET_CREATE_ORDER: "order/SET_CREATE_ORDER",
  SET_UPDATE_ORDER: "order/SET_UPDATE_ORDER",
  SET_CALCULATE_ORDER: "order/SET_CALCULATE_ORDER",
  SET_CHECKOUT_ORDER: "order/SET_CHECKOUT_ORDER",
  SET_CALCULATE_CHECKOUT_ORDER: "order/SET_CALCULATE_CHECKOUT_ORDER",
  SET_RETURN_ORDER: "order/SET_RETURN_ORDER",
  SET_CALCULATE_RETURN_ORDER: "order/SET_CALCULATE_RETURN_ORDER",

  SET_IS_ORDERS_HAS_MORE: "order/SET_IS_ORDERS_HAS_MORE",
  SET_IS_WAITING_ORDERS_HAS_MORE: "order/SET_IS_WAITING_ORDERS_HAS_MORE",
  SET_IS_PROCESS_ORDERS_HAS_MORE: "order/SET_IS_PROCESS_ORDERS_HAS_MORE",
  SET_IS_COMPLETED_ORDERS_HAS_MORE: "order/SET_IS_COMPLETED_ORDERS_HAS_MORE",
  SET_IS_REPORT_ORDERS_HAS_MORE: "order/SET_IS_REPORT_ORDERS_HAS_MORE",
  SET_IS_CASHIER_ORDERS_HAS_MORE: "order/SET_IS_CASHIER_ORDERS_HAS_MORE",
  SET_IS_CUSTOMER_ORDERS_HAS_MORE: "order/SET_IS_CUSTOMER_ORDERS_HAS_MORE",
  SET_IS_LATEST_ORDERS_HAS_MORE: "order/SET_IS_LATEST_ORDERS_HAS_MORE",

  SET_FETCH_ORDERS_SEARCH: "order/SET_FETCH_ORDERS_SEARCH",
  SET_FETCH_ORDERS_SORT: "order/SET_FETCH_ORDERS_SORT",
  SET_FETCH_ORDERS_PAGE: "order/SET_FETCH_ORDERS_PAGE",
  SET_FETCH_ORDERS_PER_PAGE: "order/SET_FETCH_ORDERS_PER_PAGE",
  SET_FETCH_ORDERS_INCLUDES: "order/SET_FETCH_ORDERS_INCLUDES",
  SET_FETCH_ORDERS_FILTER_PERIOD_TYPE:
    "order/SET_FETCH_ORDERS_FILTER_PERIOD_TYPE",
  SET_FETCH_ORDERS_FILTER_START_AT: "order/SET_FETCH_ORDERS_FILTER_START_AT",
  SET_FETCH_ORDERS_FILTER_END_AT: "order/SET_FETCH_ORDERS_FILTER_END_AT",
  SET_FETCH_ORDERS_FILTER_TIME_PERIOD:
    "order/SET_FETCH_ORDERS_FILTER_TIME_PERIOD",
  SET_FETCH_ORDERS_FILTER_CREATED_AT_BEFORE:
    "order/SET_FETCH_ORDERS_FILTER_CREATED_AT_BEFORE",
  SET_FETCH_ORDERS_FILTER_CREATED_AT_AFTER:
    "order/SET_FETCH_ORDERS_FILTER_CREATED_AT_AFTER",
  SET_FETCH_ORDERS_FILTER_MARKET_ID: "order/SET_FETCH_ORDERS_FILTER_MARKET_ID",
  SET_FETCH_ORDERS_FILTER_BRANCH_ID: "order/SET_FETCH_ORDERS_FILTER_BRANCH_ID",
  SET_FETCH_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
    "order/SET_FETCH_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS",
  SET_FETCH_ORDERS_FILTER_SOURCES: "order/SET_FETCH_ORDERS_FILTER_SOURCES",
  SET_FETCH_ORDERS_FILTER_TYPES: "order/SET_FETCH_ORDERS_FILTER_TYPES",
  SET_FETCH_ORDERS_FILTER_STATUSES: "order/SET_FETCH_ORDERS_FILTER_STATUSES",
  SET_FETCH_ORDERS_FILTER_TABLE_ID: "order/SET_FETCH_ORDERS_FILTER_TABLE_ID",
  SET_FETCH_ORDERS_FILTER_CASHIER_ID:
    "order/SET_FETCH_ORDERS_FILTER_CASHIER_ID",
  SET_FETCH_ORDERS_LOADING: "order/SET_FETCH_ORDERS_LOADING",
  SET_FETCH_ORDERS_SUCCESS: "order/SET_FETCH_ORDERS_SUCCESS",
  SET_FETCH_ORDERS_FAILED: "order/SET_FETCH_ORDERS_FAILED",

  SET_FETCH_WAITING_ORDERS_SEARCH: "order/SET_FETCH_WAITING_ORDERS_SEARCH",
  SET_FETCH_WAITING_ORDERS_SORT: "order/SET_FETCH_WAITING_ORDERS_SORT",
  SET_FETCH_WAITING_ORDERS_PAGE: "order/SET_FETCH_WAITING_ORDERS_PAGE",
  SET_FETCH_WAITING_ORDERS_PER_PAGE: "order/SET_FETCH_WAITING_ORDERS_PER_PAGE",
  SET_FETCH_WAITING_ORDERS_INCLUDES: "order/SET_FETCH_WAITING_ORDERS_INCLUDES",
  SET_FETCH_WAITING_ORDERS_FILTER_PERIOD_TYPE:
    "order/SET_FETCH_WAITING_ORDERS_FILTER_PERIOD_TYPE",
  SET_FETCH_WAITING_ORDERS_FILTER_START_AT:
    "order/SET_FETCH_WAITING_ORDERS_FILTER_START_AT",
  SET_FETCH_WAITING_ORDERS_FILTER_END_AT:
    "order/SET_FETCH_WAITING_ORDERS_FILTER_END_AT",
  SET_FETCH_WAITING_ORDERS_FILTER_TIME_PERIOD:
    "order/SET_FETCH_WAITING_ORDERS_FILTER_TIME_PERIOD",
  SET_FETCH_WAITING_ORDERS_FILTER_CREATED_AT_BEFORE:
    "order/SET_FETCH_WAITING_ORDERS_FILTER_CREATED_AT_BEFORE",
  SET_FETCH_WAITING_ORDERS_FILTER_CREATED_AT_AFTER:
    "order/SET_FETCH_WAITING_ORDERS_FILTER_CREATED_AT_AFTER",
  SET_FETCH_WAITING_ORDERS_FILTER_MARKET_ID:
    "order/SET_FETCH_WAITING_ORDERS_FILTER_MARKET_ID",
  SET_FETCH_WAITING_ORDERS_FILTER_BRANCH_ID:
    "order/SET_FETCH_WAITING_ORDERS_FILTER_BRANCH_ID",
  SET_FETCH_WAITING_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
    "order/SET_FETCH_WAITING_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS",
  SET_FETCH_WAITING_ORDERS_FILTER_SOURCES:
    "order/SET_FETCH_WAITING_ORDERS_FILTER_SOURCES",
  SET_FETCH_WAITING_ORDERS_FILTER_TYPES:
    "order/SET_FETCH_WAITING_ORDERS_FILTER_TYPES",
  SET_FETCH_WAITING_ORDERS_FILTER_TABLE_ID:
    "order/SET_FETCH_WAITING_ORDERS_FILTER_TABLE_ID",
  SET_FETCH_WAITING_ORDERS_FILTER_CASHIER_ID:
    "order/SET_FETCH_WAITING_ORDERS_FILTER_CASHIER_ID",
  SET_FETCH_WAITING_ORDERS_LOADING: "order/SET_FETCH_WAITING_ORDERS_LOADING",
  SET_FETCH_WAITING_ORDERS_SUCCESS: "order/SET_FETCH_WAITING_ORDERS_SUCCESS",
  SET_FETCH_WAITING_ORDERS_FAILED: "order/SET_FETCH_WAITING_ORDERS_FAILED",

  SET_FETCH_PROCESS_ORDERS_SEARCH: "order/SET_FETCH_PROCESS_ORDERS_SEARCH",
  SET_FETCH_PROCESS_ORDERS_SORT: "order/SET_FETCH_PROCESS_ORDERS_SORT",
  SET_FETCH_PROCESS_ORDERS_PAGE: "order/SET_FETCH_PROCESS_ORDERS_PAGE",
  SET_FETCH_PROCESS_ORDERS_PER_PAGE: "order/SET_FETCH_PROCESS_ORDERS_PER_PAGE",
  SET_FETCH_PROCESS_ORDERS_INCLUDES: "order/SET_FETCH_PROCESS_ORDERS_INCLUDES",
  SET_FETCH_PROCESS_ORDERS_FILTER_PERIOD_TYPE:
    "order/SET_FETCH_PROCESS_ORDERS_FILTER_PERIOD_TYPE",
  SET_FETCH_PROCESS_ORDERS_FILTER_START_AT:
    "order/SET_FETCH_PROCESS_ORDERS_FILTER_START_AT",
  SET_FETCH_PROCESS_ORDERS_FILTER_END_AT:
    "order/SET_FETCH_PROCESS_ORDERS_FILTER_END_AT",
  SET_FETCH_PROCESS_ORDERS_FILTER_TIME_PERIOD:
    "order/SET_FETCH_PROCESS_ORDERS_FILTER_TIME_PERIOD",
  SET_FETCH_PROCESS_ORDERS_FILTER_CREATED_AT_BEFORE:
    "order/SET_FETCH_PROCESS_ORDERS_FILTER_CREATED_AT_BEFORE",
  SET_FETCH_PROCESS_ORDERS_FILTER_CREATED_AT_AFTER:
    "order/SET_FETCH_PROCESS_ORDERS_FILTER_CREATED_AT_AFTER",
  SET_FETCH_PROCESS_ORDERS_FILTER_MARKET_ID:
    "order/SET_FETCH_PROCESS_ORDERS_FILTER_MARKET_ID",
  SET_FETCH_PROCESS_ORDERS_FILTER_BRANCH_ID:
    "order/SET_FETCH_PROCESS_ORDERS_FILTER_BRANCH_ID",
  SET_FETCH_PROCESS_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
    "order/SET_FETCH_PROCESS_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS",
  SET_FETCH_PROCESS_ORDERS_FILTER_SOURCES:
    "order/SET_FETCH_PROCESS_ORDERS_FILTER_SOURCES",
  SET_FETCH_PROCESS_ORDERS_FILTER_TYPES:
    "order/SET_FETCH_PROCESS_ORDERS_FILTER_TYPES",
  SET_FETCH_PROCESS_ORDERS_FILTER_TABLE_ID:
    "order/SET_FETCH_PROCESS_ORDERS_FILTER_TABLE_ID",
  SET_FETCH_PROCESS_ORDERS_FILTER_CASHIER_ID:
    "order/SET_FETCH_PROCESS_ORDERS_FILTER_CASHIER_ID",
  SET_FETCH_PROCESS_ORDERS_LOADING: "order/SET_FETCH_PROCESS_ORDERS_LOADING",
  SET_FETCH_PROCESS_ORDERS_SUCCESS: "order/SET_FETCH_PROCESS_ORDERS_SUCCESS",
  SET_FETCH_PROCESS_ORDERS_FAILED: "order/SET_FETCH_PROCESS_ORDERS_FAILED",

  SET_FETCH_COMPLETED_ORDERS_SEARCH: "order/SET_FETCH_COMPLETED_ORDERS_SEARCH",
  SET_FETCH_COMPLETED_ORDERS_SORT: "order/SET_FETCH_COMPLETED_ORDERS_SORT",
  SET_FETCH_COMPLETED_ORDERS_PAGE: "order/SET_FETCH_COMPLETED_ORDERS_PAGE",
  SET_FETCH_COMPLETED_ORDERS_PER_PAGE:
    "order/SET_FETCH_COMPLETED_ORDERS_PER_PAGE",
  SET_FETCH_COMPLETED_ORDERS_INCLUDES:
    "order/SET_FETCH_COMPLETED_ORDERS_INCLUDES",
  SET_FETCH_COMPLETED_ORDERS_FILTER_PERIOD_TYPE:
    "order/SET_FETCH_COMPLETED_ORDERS_FILTER_PERIOD_TYPE",
  SET_FETCH_COMPLETED_ORDERS_FILTER_START_AT:
    "order/SET_FETCH_COMPLETED_ORDERS_FILTER_START_AT",
  SET_FETCH_COMPLETED_ORDERS_FILTER_END_AT:
    "order/SET_FETCH_COMPLETED_ORDERS_FILTER_END_AT",
  SET_FETCH_COMPLETED_ORDERS_FILTER_TIME_PERIOD:
    "order/SET_FETCH_COMPLETED_ORDERS_FILTER_TIME_PERIOD",
  SET_FETCH_COMPLETED_ORDERS_FILTER_CREATED_AT_BEFORE:
    "order/SET_FETCH_COMPLETED_ORDERS_FILTER_CREATED_AT_BEFORE",
  SET_FETCH_COMPLETED_ORDERS_FILTER_CREATED_AT_AFTER:
    "order/SET_FETCH_COMPLETED_ORDERS_FILTER_CREATED_AT_AFTER",
  SET_FETCH_COMPLETED_ORDERS_FILTER_MARKET_ID:
    "order/SET_FETCH_COMPLETED_ORDERS_FILTER_MARKET_ID",
  SET_FETCH_COMPLETED_ORDERS_FILTER_BRANCH_ID:
    "order/SET_FETCH_COMPLETED_ORDERS_FILTER_BRANCH_ID",
  SET_FETCH_COMPLETED_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
    "order/SET_FETCH_COMPLETED_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS",
  SET_FETCH_COMPLETED_ORDERS_FILTER_SOURCES:
    "order/SET_FETCH_COMPLETED_ORDERS_FILTER_SOURCES",
  SET_FETCH_COMPLETED_ORDERS_FILTER_TYPES:
    "order/SET_FETCH_COMPLETED_ORDERS_FILTER_TYPES",
  SET_FETCH_COMPLETED_ORDERS_FILTER_TABLE_ID:
    "order/SET_FETCH_COMPLETED_ORDERS_FILTER_TABLE_ID",
  SET_FETCH_COMPLETED_ORDERS_FILTER_CASHIER_ID:
    "order/SET_FETCH_COMPLETED_ORDERS_FILTER_CASHIER_ID",
  SET_FETCH_COMPLETED_ORDERS_LOADING:
    "order/SET_FETCH_COMPLETED_ORDERS_LOADING",
  SET_FETCH_COMPLETED_ORDERS_SUCCESS:
    "order/SET_FETCH_COMPLETED_ORDERS_SUCCESS",
  SET_FETCH_COMPLETED_ORDERS_FAILED: "order/SET_FETCH_COMPLETED_ORDERS_FAILED",

  SET_FETCH_REPORT_ORDERS_SEARCH: "order/SET_FETCH_REPORT_ORDERS_SEARCH",
  SET_FETCH_REPORT_ORDERS_SORT: "order/SET_FETCH_REPORT_ORDERS_SORT",
  SET_FETCH_REPORT_ORDERS_PAGE: "order/SET_FETCH_REPORT_ORDERS_PAGE",
  SET_FETCH_REPORT_ORDERS_PER_PAGE: "order/SET_FETCH_REPORT_ORDERS_PER_PAGE",
  SET_FETCH_REPORT_ORDERS_INCLUDES: "order/SET_FETCH_REPORT_ORDERS_INCLUDES",
  SET_FETCH_REPORT_ORDERS_FILTER_PERIOD_TYPE:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_PERIOD_TYPE",
  SET_FETCH_REPORT_ORDERS_FILTER_START_AT:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_START_AT",
  SET_FETCH_REPORT_ORDERS_FILTER_END_AT:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_END_AT",
  SET_FETCH_REPORT_ORDERS_FILTER_TIME_PERIOD:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_TIME_PERIOD",
  SET_FETCH_REPORT_ORDERS_FILTER_CREATED_AT_BEFORE:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_CREATED_AT_BEFORE",
  SET_FETCH_REPORT_ORDERS_FILTER_CREATED_AT_AFTER:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_CREATED_AT_AFTER",
  SET_FETCH_REPORT_ORDERS_FILTER_MARKET_ID:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_MARKET_ID",
  SET_FETCH_REPORT_ORDERS_FILTER_BRANCH_ID:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_BRANCH_ID",
  SET_FETCH_REPORT_ORDERS_FILTER_PAYMENT_METHOD_KEY:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_REPORT_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS",
  SET_FETCH_REPORT_ORDERS_FILTER_SOURCES:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_SOURCES",
  SET_FETCH_REPORT_ORDERS_FILTER_TYPES:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_TYPES",
  SET_FETCH_REPORT_ORDERS_FILTER_STATUSES:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_STATUSES",
  SET_FETCH_REPORT_ORDERS_FILTER_TABLE_ID:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_TABLE_ID",
  SET_FETCH_REPORT_ORDERS_FILTER_CASHIER_ID:
    "order/SET_FETCH_REPORT_ORDERS_FILTER_CASHIER_ID",
  SET_FETCH_REPORT_ORDERS_LOADING: "order/SET_FETCH_REPORT_ORDERS_LOADING",
  SET_FETCH_REPORT_ORDERS_SUCCESS: "order/SET_FETCH_REPORT_ORDERS_SUCCESS",
  SET_FETCH_REPORT_ORDERS_FAILED: "order/SET_FETCH_REPORT_ORDERS_FAILED",

  SET_FETCH_CASHIER_ORDERS_SEARCH: "order/SET_FETCH_CASHIER_ORDERS_SEARCH",
  SET_FETCH_CASHIER_ORDERS_SORT: "order/SET_FETCH_CASHIER_ORDERS_SORT",
  SET_FETCH_CASHIER_ORDERS_PAGE: "order/SET_FETCH_CASHIER_ORDERS_PAGE",
  SET_FETCH_CASHIER_ORDERS_PER_PAGE: "order/SET_FETCH_CASHIER_ORDERS_PER_PAGE",
  SET_FETCH_CASHIER_ORDERS_INCLUDES: "order/SET_FETCH_CASHIER_ORDERS_INCLUDES",
  SET_FETCH_CASHIER_ORDERS_FILTER_PERIOD_TYPE:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_PERIOD_TYPE",
  SET_FETCH_CASHIER_ORDERS_FILTER_START_AT:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_START_AT",
  SET_FETCH_CASHIER_ORDERS_FILTER_END_AT:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_END_AT",
  SET_FETCH_CASHIER_ORDERS_FILTER_TIME_PERIOD:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_TIME_PERIOD",
  SET_FETCH_CASHIER_ORDERS_FILTER_CREATED_AT_BEFORE:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_CREATED_AT_BEFORE",
  SET_FETCH_CASHIER_ORDERS_FILTER_CREATED_AT_AFTER:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_CREATED_AT_AFTER",
  SET_FETCH_CASHIER_ORDERS_FILTER_MARKET_ID:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_MARKET_ID",
  SET_FETCH_CASHIER_ORDERS_FILTER_BRANCH_ID:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_BRANCH_ID",
  SET_FETCH_CASHIER_ORDERS_FILTER_PAYMENT_METHOD_KEY:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_CASHIER_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS",
  SET_FETCH_CASHIER_ORDERS_FILTER_SOURCES:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_SOURCES",
  SET_FETCH_CASHIER_ORDERS_FILTER_TYPES:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_TYPES",
  SET_FETCH_CASHIER_ORDERS_FILTER_STATUSES:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_STATUSES",
  SET_FETCH_CASHIER_ORDERS_FILTER_TABLE_ID:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_TABLE_ID",
  SET_FETCH_CASHIER_ORDERS_FILTER_CASHIER_ID:
    "order/SET_FETCH_CASHIER_ORDERS_FILTER_CASHIER_ID",
  SET_FETCH_CASHIER_ORDERS_LOADING: "order/SET_FETCH_CASHIER_ORDERS_LOADING",
  SET_FETCH_CASHIER_ORDERS_SUCCESS: "order/SET_FETCH_CASHIER_ORDERS_SUCCESS",
  SET_FETCH_CASHIER_ORDERS_FAILED: "order/SET_FETCH_CASHIER_ORDERS_FAILED",

  SET_FETCH_CUSTOMER_ORDERS_SEARCH: "order/SET_FETCH_CUSTOMER_ORDERS_SEARCH",
  SET_FETCH_CUSTOMER_ORDERS_SORT: "order/SET_FETCH_CUSTOMER_ORDERS_SORT",
  SET_FETCH_CUSTOMER_ORDERS_PAGE: "order/SET_FETCH_CUSTOMER_ORDERS_PAGE",
  SET_FETCH_CUSTOMER_ORDERS_PER_PAGE:
    "order/SET_FETCH_CUSTOMER_ORDERS_PER_PAGE",
  SET_FETCH_CUSTOMER_ORDERS_INCLUDES:
    "order/SET_FETCH_CUSTOMER_ORDERS_INCLUDES",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_PERIOD_TYPE:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_PERIOD_TYPE",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_START_AT:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_START_AT",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_END_AT:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_END_AT",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_TIME_PERIOD:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_TIME_PERIOD",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_CREATED_AT_BEFORE:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_CREATED_AT_BEFORE",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_CREATED_AT_AFTER:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_CREATED_AT_AFTER",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_MARKET_ID:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_MARKET_ID",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_BRANCH_ID:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_BRANCH_ID",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_PAYMENT_METHOD_KEY:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_CUSTOMER_ID:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_CUSTOMER_ID",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_SOURCES:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_SOURCES",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_TYPES:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_TYPES",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_STATUSES:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_STATUSES",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_TABLE_ID:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_TABLE_ID",
  SET_FETCH_CUSTOMER_ORDERS_FILTER_CASHIER_ID:
    "order/SET_FETCH_CUSTOMER_ORDERS_FILTER_CASHIER_ID",
  SET_FETCH_CUSTOMER_ORDERS_LOADING: "order/SET_FETCH_CUSTOMER_ORDERS_LOADING",
  SET_FETCH_CUSTOMER_ORDERS_SUCCESS: "order/SET_FETCH_CUSTOMER_ORDERS_SUCCESS",
  SET_FETCH_CUSTOMER_ORDERS_FAILED: "order/SET_FETCH_CUSTOMER_ORDERS_FAILED",

  SET_FETCH_LATEST_ORDERS_PAGE: "order/SET_FETCH_LATEST_ORDERS_PAGE",
  SET_FETCH_LATEST_ORDERS_SORT: "order/SET_FETCH_LATEST_ORDERS_SORT",
  SET_FETCH_LATEST_ORDERS_PER_PAGE: "order/SET_FETCH_LATEST_ORDERS_PER_PAGE",
  SET_FETCH_LATEST_ORDERS_INCLUDES: "order/SET_FETCH_LATEST_ORDERS_INCLUDES",
  SET_FETCH_LATEST_ORDERS_FILTER_PERIOD_TYPE:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_PERIOD_TYPE",
  SET_FETCH_LATEST_ORDERS_FILTER_START_AT:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_START_AT",
  SET_FETCH_LATEST_ORDERS_FILTER_END_AT:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_END_AT",
  SET_FETCH_LATEST_ORDERS_FILTER_TIME_PERIOD:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_TIME_PERIOD",
  SET_FETCH_LATEST_ORDERS_FILTER_CREATED_AT_BEFORE:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_CREATED_AT_BEFORE",
  SET_FETCH_LATEST_ORDERS_FILTER_CREATED_AT_AFTER:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_CREATED_AT_AFTER",
  SET_FETCH_LATEST_ORDERS_FILTER_MARKET_ID:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_MARKET_ID",
  SET_FETCH_LATEST_ORDERS_FILTER_BRANCH_ID:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_BRANCH_ID",
  SET_FETCH_LATEST_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS",
  SET_FETCH_LATEST_ORDERS_FILTER_SOURCES:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_SOURCES",
  SET_FETCH_LATEST_ORDERS_FILTER_TYPES:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_TYPES",
  SET_FETCH_LATEST_ORDERS_FILTER_STATUSES:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_STATUSES",
  SET_FETCH_LATEST_ORDERS_FILTER_TABLE_ID:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_TABLE_ID",
  SET_FETCH_LATEST_ORDERS_FILTER_CASHIER_ID:
    "order/SET_FETCH_LATEST_ORDERS_FILTER_CASHIER_ID",
  SET_FETCH_LATEST_ORDERS_LOADING: "order/SET_FETCH_LATEST_ORDERS_LOADING",
  SET_FETCH_LATEST_ORDERS_SUCCESS: "order/SET_FETCH_LATEST_ORDERS_SUCCESS",
  SET_FETCH_LATEST_ORDERS_FAILED: "order/SET_FETCH_LATEST_ORDERS_FAILED",

  SET_FETCH_ORDER_LOADING: "order/SET_FETCH_ORDER_LOADING",
  SET_FETCH_ORDER_SUCCESS: "order/SET_FETCH_ORDER_SUCCESS",
  SET_FETCH_ORDER_FAILED: "order/SET_FETCH_ORDER_FAILED",

  SET_FETCH_CUSTOMER_ORDER_LOADING: "order/SET_FETCH_CUSTOMER_ORDER_LOADING",
  SET_FETCH_CUSTOMER_ORDER_SUCCESS: "order/SET_FETCH_CUSTOMER_ORDER_SUCCESS",
  SET_FETCH_CUSTOMER_ORDER_FAILED: "order/SET_FETCH_CUSTOMER_ORDER_FAILED",

  SET_CREATE_ORDER_LOADING: "order/SET_CREATE_ORDER_LOADING",
  SET_CREATE_ORDER_SUCCESS: "order/SET_CREATE_ORDER_SUCCESS",
  SET_CREATE_ORDER_FAILED: "order/SET_CREATE_ORDER_FAILED",

  SET_UPDATE_ORDER_LOADING: "order/SET_UPDATE_ORDER_LOADING",
  SET_UPDATE_ORDER_SUCCESS: "order/SET_UPDATE_ORDER_SUCCESS",
  SET_UPDATE_ORDER_FAILED: "order/SET_UPDATE_ORDER_FAILED",

  SET_DELETE_ORDER_LOADING: "order/SET_DELETE_ORDER_LOADING",
  SET_DELETE_ORDER_SUCCESS: "order/SET_DELETE_ORDER_SUCCESS",
  SET_DELETE_ORDER_FAILED: "order/SET_DELETE_ORDER_FAILED",

  SET_CALCULATE_ORDER_LOADING: "order/SET_CALCULATE_ORDER_LOADING",
  SET_CALCULATE_ORDER_SUCCESS: "order/SET_CALCULATE_ORDER_SUCCESS",
  SET_CALCULATE_ORDER_FAILED: "order/SET_CALCULATE_ORDER_FAILED",

  SET_CHECKOUT_ORDER_LOADING: "order/SET_CHECKOUT_ORDER_LOADING",
  SET_CHECKOUT_ORDER_SUCCESS: "order/SET_CHECKOUT_ORDER_SUCCESS",
  SET_CHECKOUT_ORDER_FAILED: "order/SET_CHECKOUT_ORDER_FAILED",

  SET_CALCULATE_CHECKOUT_ORDER_LOADING:
    "order/SET_CALCULATE_CHECKOUT_ORDER_LOADING",
  SET_CALCULATE_CHECKOUT_ORDER_SUCCESS:
    "order/SET_CALCULATE_CHECKOUT_ORDER_SUCCESS",
  SET_CALCULATE_CHECKOUT_ORDER_FAILED:
    "order/SET_CALCULATE_CHECKOUT_ORDER_FAILED",

  SET_RETURN_ORDER_LOADING: "order/SET_RETURN_ORDER_LOADING",
  SET_RETURN_ORDER_SUCCESS: "order/SET_RETURN_ORDER_SUCCESS",
  SET_RETURN_ORDER_FAILED: "order/SET_RETURN_ORDER_FAILED",

  SET_CALCULATE_RETURN_ORDER_LOADING:
    "order/SET_CALCULATE_RETURN_ORDER_LOADING",
  SET_CALCULATE_RETURN_ORDER_SUCCESS:
    "order/SET_CALCULATE_RETURN_ORDER_SUCCESS",
  SET_CALCULATE_RETURN_ORDER_FAILED: "order/SET_CALCULATE_RETURN_ORDER_FAILED",

  APPEND_ORDERS: "order/APPEND_ORDERS",
  APPEND_WAITING_ORDERS: "order/APPEND_WAITING_ORDERS",
  APPEND_PROCESS_ORDERS: "order/APPEND_PROCESS_ORDERS",
  APPEND_COMPLETED_ORDERS: "order/APPEND_COMPLETED_ORDERS",
  APPEND_REPORT_ORDERS: "order/APPEND_REPORT_ORDERS",
  APPEND_CASHIER_ORDERS: "order/APPEND_CASHIER_ORDERS",
  APPEND_CUSTOMER_ORDERS: "order/APPEND_CUSTOMER_ORDERS",
  APPEND_LATEST_ORDERS: "order/APPEND_LATEST_ORDERS",

  SET_IS_FETCH_ORDERS_HITTED: "order/SET_IS_FETCH_ORDERS_HITTED",
  SET_IS_FETCH_WAITING_ORDERS_HITTED:
    "order/SET_IS_FETCH_WAITING_ORDERS_HITTED",
  SET_IS_FETCH_PROCESS_ORDERS_HITTED:
    "order/SET_IS_FETCH_PROCESS_ORDERS_HITTED",
  SET_IS_FETCH_COMPLETED_ORDERS_HITTED:
    "order/SET_IS_FETCH_COMPLETED_ORDERS_HITTED",
  SET_IS_FETCH_REPORT_ORDERS_HITTED: "order/SET_IS_FETCH_REPORT_ORDERS_HITTED",
  SET_IS_FETCH_CASHIER_ORDERS_HITTED:
    "order/SET_IS_FETCH_CASHIER_ORDERS_HITTED",
  SET_IS_FETCH_CUSTOMER_ORDERS_HITTED:
    "order/SET_IS_FETCH_CUSTOMER_ORDERS_HITTED",
  SET_IS_FETCH_LATEST_ORDERS_HITTED: "order/SET_IS_FETCH_LATEST_ORDERS_HITTED",
  SET_IS_FETCH_ORDER_HITTED: "order/SET_IS_FETCH_ORDER_HITTED",
  SET_IS_FETCH_CUSTOMER_ORDER_HITTED: "order/SET_IS_FETCH_CUSTOMER_ORDER_HITTED",
  SET_IS_CREATE_ORDER_HITTED: "order/SET_IS_CREATE_ORDER_HITTED",
  SET_IS_UPDATE_ORDER_HITTED: "order/SET_IS_UPDATE_ORDER_HITTED",
  SET_IS_DELETE_ORDER_HITTED: "order/SET_IS_DELETE_ORDER_HITTED",
  SET_IS_CALCULATE_ORDER_HITTED: "order/SET_IS_CALCULATE_ORDER_HITTED",
  SET_IS_CHECKOUT_ORDER_HITTED: "order/SET_IS_CHECKOUT_ORDER_HITTED",
  SET_IS_CALCULATE_CHECKOUT_ORDER_HITTED:
    "order/SET_IS_CALCULATE_CHECKOUT_ORDER_HITTED",
  SET_IS_RETURN_ORDER_HITTED: "order/SET_IS_RETURN_ORDER_HITTED",
  SET_IS_CALCULATE_RETURN_ORDER_HITTED:
    "order/SET_IS_CALCULATE_RETURN_ORDER_HITTED",

  FETCH_ORDERS_START: "order/FETCH_ORDERS_START",
  FETCH_WAITING_ORDERS_START: "order/FETCH_WAITING_ORDERS_START",
  FETCH_PROCESS_ORDERS_START: "order/FETCH_PROCESS_ORDERS_START",
  FETCH_COMPLETED_ORDERS_START: "order/FETCH_COMPLETED_ORDERS_START",
  FETCH_REPORT_ORDERS_START: "order/FETCH_REPORT_ORDERS_START",
  FETCH_CASHIER_ORDERS_START: "order/FETCH_CASHIER_ORDERS_START",
  FETCH_CUSTOMER_ORDERS_START: "order/FETCH_CUSTOMER_ORDERS_START",
  FETCH_LATEST_ORDERS_START: "order/FETCH_LATEST_ORDERS_START",
  FETCH_ORDER_START: "order/FETCH_ORDER_START",
  FETCH_CUSTOMER_ORDER_START: "order/FETCH_CUSTOMER_ORDER_START",
  CREATE_ORDER_START: "order/CREATE_ORDER_START",
  UPDATE_ORDER_START: "order/UPDATE_ORDER_START",
  DELETE_ORDER_START: "order/DELETE_ORDER_START",
  CALCULATE_ORDER_START: "order/CALCULATE_ORDER_START",
  CHECKOUT_ORDER_START: "order/CHECKOUT_ORDER_START",
  CALCULATE_CHECKOUT_ORDER_START: "order/CALCULATE_CHECKOUT_ORDER_START",
  RETURN_ORDER_START: "order/RETURN_ORDER_START",
  CALCULATE_RETURN_ORDER_START: "order/CALCULATE_RETURN_ORDER_START",
};

export default ORDER_ACTION_TYPES;
