import { createSelector } from "reselect";

const attendanceSelector = ({ attendance }) => attendance;

export const getAttendances = createSelector([attendanceSelector], ({ attendances }) => attendances);

export const getAttendance = createSelector([attendanceSelector], ({ attendance }) => attendance);

export const getIsAttendancesHasMore = createSelector(
  [attendanceSelector],
  ({ isAttendancesHasMore }) => isAttendancesHasMore
);

export const getFetchAttendancesSearch = createSelector(
  [attendanceSelector],
  ({ fetchAttendancesSearch }) => fetchAttendancesSearch
);

export const getFetchAttendancesSort = createSelector(
  [attendanceSelector],
  ({ fetchAttendancesSort }) => fetchAttendancesSort
);

export const getFetchAttendancesPage = createSelector(
  [attendanceSelector],
  ({ fetchAttendancesPage }) => fetchAttendancesPage
);

export const getFetchAttendancesPerPage = createSelector(
  [attendanceSelector],
  ({ fetchAttendancesPerPage }) => fetchAttendancesPerPage
);

export const getFetchAttendancesIncludes = createSelector(
  [attendanceSelector],
  ({ fetchAttendancesIncludes }) => fetchAttendancesIncludes
);

export const getFetchAttendancesFilterMarketId = createSelector(
  [attendanceSelector],
  ({ fetchAttendancesFilterMarketId }) => fetchAttendancesFilterMarketId
);

export const getFetchAttendancesFilterBranchId = createSelector(
  [attendanceSelector],
  ({ fetchAttendancesFilterBranchId }) => fetchAttendancesFilterBranchId
);

export const getFetchAttendancesFilterUserId = createSelector(
  [attendanceSelector],
  ({ fetchAttendancesFilterUserId }) => fetchAttendancesFilterUserId
);

export const getFetchAttendancesLoading = createSelector(
  [attendanceSelector],
  ({ fetchAttendancesLoading }) => fetchAttendancesLoading
);

export const getFetchAttendancesSuccess = createSelector(
  [attendanceSelector],
  ({ fetchAttendancesSuccess }) => fetchAttendancesSuccess
);

export const getFetchAttendancesFailed = createSelector(
  [attendanceSelector],
  ({ fetchAttendancesFailed }) => fetchAttendancesFailed
);

export const getFetchAttendanceMarketId = createSelector(
  [attendanceSelector],
  ({ fetchAttendanceMarketId }) => fetchAttendanceMarketId
);

export const getFetchAttendanceBranchId = createSelector(
  [attendanceSelector],
  ({ fetchAttendanceBranchId }) => fetchAttendanceBranchId
);

export const getFetchAttendanceLoading = createSelector(
  [attendanceSelector],
  ({ fetchAttendanceLoading }) => fetchAttendanceLoading
);

export const getFetchAttendanceSuccess = createSelector(
  [attendanceSelector],
  ({ fetchAttendanceSuccess }) => fetchAttendanceSuccess
);

export const getFetchAttendanceFailed = createSelector(
  [attendanceSelector],
  ({ fetchAttendanceFailed }) => fetchAttendanceFailed
);

export const getCreateOrUpdateAttendanceLoading = createSelector(
  [attendanceSelector],
  ({ createOrUpdateAttendanceLoading }) => createOrUpdateAttendanceLoading
);

export const getCreateOrUpdateAttendanceSuccess = createSelector(
  [attendanceSelector],
  ({ createOrUpdateAttendanceSuccess }) => createOrUpdateAttendanceSuccess
);

export const getCreateOrUpdateAttendanceFailed = createSelector(
  [attendanceSelector],
  ({ createOrUpdateAttendanceFailed }) => createOrUpdateAttendanceFailed
);

export const getIsFetchAttendancesHitted = createSelector(
  [attendanceSelector],
  ({ isFetchAttendancesHitted }) => isFetchAttendancesHitted
);

export const getIsFetchAttendanceHitted = createSelector(
  [attendanceSelector],
  ({ isFetchAttendanceHitted }) => isFetchAttendanceHitted
);

export const getIsCreateOrUpdateAttendanceHitted = createSelector(
  [attendanceSelector],
  ({ isCreateOrUpdateAttendanceHitted }) => isCreateOrUpdateAttendanceHitted
);
