import { takeLatest, put, all, call } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getUserSubscribers } from "../../api/user-subscriber.api";

import SELECT_CASHIER_ACTION_TYPES from "./select-cashier.type";
import {
  appendCashiers,
  appendSearchCashiers,
  setAppendCashiersFailed,
  setAppendCashiersLoading,
  setAppendCashiersSuccess,
  setFetchCashiersFailed,
  setFetchCashiersLoading,
  setFetchCashiersSuccess,
  setIsAppendCashiersHitted,
  setIsFetchCashiersHitted,
  setIsCashiersHasMore,
  setIsSearchCashiersHasMore,
  setIsSearchCashiersHitted,
  setCashiers,
  setSearchCashiers,
  setSearchCashiersFailed,
  setSearchCashiersLoading,
  setSearchCashiersSuccess,
} from "./select-cashier.action";

export function* _getFetchCashiers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setFetchCashiersLoading(true));

    const {
      meta: { message },
      data: users,
    } = yield call(getUserSubscribers, parameters);

    yield put(setIsFetchCashiersHitted(true));
    yield put(setIsCashiersHasMore(Object.keys(users).length > 0));

    if (parameters.page > 1) {
      yield put(appendCashiers(users));
    } else {
      yield put(setCashiers(users));
    }

    yield put(setFetchCashiersSuccess(message));
    yield put(setFetchCashiersLoading(false));
  } catch (error) {
    yield put(setFetchCashiersFailed(error));
    yield put(setFetchCashiersLoading(false));
  }
}
export function* _getSearchCashiers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setSearchCashiersLoading(true));

    const {
      meta: { message },
      data: users,
    } = yield call(getUserSubscribers, parameters);

    yield put(setIsSearchCashiersHitted(true));
    yield put(setIsSearchCashiersHasMore(Object.keys(users).length > 0));

    if (parameters.page > 1) {
      yield put(appendSearchCashiers(users));
    } else {
      yield put(setSearchCashiers(users));
    }

    yield put(setSearchCashiersSuccess(message));
    yield put(setSearchCashiersLoading(false));
  } catch (error) {
    yield put(setSearchCashiersFailed(error));
    yield put(setSearchCashiersLoading(false));
  }
}
export function* _getAppendCashiers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setAppendCashiersLoading(true));

    const {
      meta: { message },
      data: users,
    } = yield call(getUserSubscribers, parameters);

    yield put(setIsAppendCashiersHitted(true));
    yield put(appendCashiers(users));

    yield put(setAppendCashiersSuccess(message));
    yield put(setAppendCashiersLoading(false));
  } catch (error) {
    yield put(setAppendCashiersFailed(error));
    yield put(setAppendCashiersLoading(false));
  }
}

export function* onFetchCashiersStart() {
  yield takeLatest(
    SELECT_CASHIER_ACTION_TYPES.FETCH_CASHIERS_START,
    _getFetchCashiers
  );
}
export function* onSearchCashiersStart() {
  yield takeLatest(
    SELECT_CASHIER_ACTION_TYPES.SEARCH_CASHIERS_START,
    _getSearchCashiers
  );
}
export function* onAppendCashiersStart() {
  yield takeLatest(
    SELECT_CASHIER_ACTION_TYPES.APPEND_CASHIERS_START,
    _getAppendCashiers
  );
}

export function* selectCashierSaga() {
  yield all([
    call(onFetchCashiersStart),
    call(onSearchCashiersStart),
    call(onAppendCashiersStart),
  ]);
}
