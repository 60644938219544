import { createAction } from "../../utils/store.utils";

import PERMISSION_ADMIN_ACTION_TYPES from "./permission-admin.type";

export const setPermissionTab = (permissionTab) =>
  createAction(PERMISSION_ADMIN_ACTION_TYPES.SET_PERMISSION_TAB, permissionTab);
export const setPermissionTypes = (permissionTypes) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_PERMISSION_TYPES,
    permissionTypes
  );
export const setPermissionAdmins = (permissionAdmins) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_PERMISSION_ADMINS,
    permissionAdmins
  );
export const setPermissionSubscribers = (permissionSubscribers) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_PERMISSION_SUBSCRIBERS,
    permissionSubscribers
  );
export const setSelectPermissionAdmins = (selectPermissionAdmins) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_SELECT_PERMISSION_ADMINS,
    selectPermissionAdmins
  );
export const setSelectPermissionSubscribers = (selectPermissionSubscribers) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_SELECT_PERMISSION_SUBSCRIBERS,
    selectPermissionSubscribers
  );
export const setPermission = (permission) =>
  createAction(PERMISSION_ADMIN_ACTION_TYPES.SET_PERMISSION, permission);

export const setIsPermissionAdminsHasMore = (isPermissionAdminsHasMore) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_PERMISSION_ADMINS_HAS_MORE,
    isPermissionAdminsHasMore
  );
export const setIsPermissionSubscribersHasMore = (
  isPermissionSubscribersHasMore
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE,
    isPermissionSubscribersHasMore
  );
export const setIsSelectPermissionAdminsHasMore = (
  isSelectPermissionAdminsHasMore
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_SELECT_PERMISSION_ADMINS_HAS_MORE,
    isSelectPermissionAdminsHasMore
  );
export const setIsSelectPermissionSubscribersHasMore = (
  isSelectPermissionSubscribersHasMore
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_SELECT_PERMISSION_SUBSCRIBERS_HAS_MORE,
    isSelectPermissionSubscribersHasMore
  );

export const setFetchPermissionAdminsSearch = (fetchPermissionAdminsSearch) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_SEARCH,
    fetchPermissionAdminsSearch
  );
export const setFetchPermissionAdminsSort = (fetchPermissionAdminsSort) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_SORT,
    fetchPermissionAdminsSort
  );
export const setFetchPermissionAdminsKeyBy = (fetchPermissionAdminsKeyBy) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_KEY_BY,
    fetchPermissionAdminsKeyBy
  );
export const setFetchPermissionAdminsPage = (fetchPermissionAdminsPage) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_PAGE,
    fetchPermissionAdminsPage
  );
export const setFetchPermissionAdminsPerPage = (fetchPermissionAdminsPerPage) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_PER_PAGE,
    fetchPermissionAdminsPerPage
  );
export const setFetchPermissionAdminsIncludes = (
  fetchPermissionAdminsIncludes
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_INCLUDES,
    fetchPermissionAdminsIncludes
  );
export const setFetchPermissionAdminsFilterIsDefault = (
  fetchPermissionAdminsFilterIsDefault
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_FILTER_IS_DEFAULT,
    fetchPermissionAdminsFilterIsDefault
  );
export const setFetchPermissionAdminsLoading = (fetchPermissionAdminsLoading) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_LOADING,
    fetchPermissionAdminsLoading
  );
export const setFetchPermissionAdminsSuccess = (fetchPermissionAdminsSuccess) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_SUCCESS,
    fetchPermissionAdminsSuccess
  );
export const setFetchPermissionAdminsFailed = (fetchPermissionAdminsFailed) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_FAILED,
    fetchPermissionAdminsFailed
  );

export const setFetchPermissionSubscribersSearch = (
  fetchPermissionSubscribersSearch
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SEARCH,
    fetchPermissionSubscribersSearch
  );
export const setFetchPermissionSubscribersSort = (
  fetchPermissionSubscribersSort
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SORT,
    fetchPermissionSubscribersSort
  );
export const setFetchPermissionSubscribersKeyBy = (
  fetchPermissionSubscribersKeyBy
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_KEY_BY,
    fetchPermissionSubscribersKeyBy
  );
export const setFetchPermissionSubscribersPage = (
  fetchPermissionSubscribersPage
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_PAGE,
    fetchPermissionSubscribersPage
  );
export const setFetchPermissionSubscribersPerPage = (
  fetchPermissionSubscribersPerPage
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_PER_PAGE,
    fetchPermissionSubscribersPerPage
  );
export const setFetchPermissionSubscribersIncludes = (
  fetchPermissionSubscribersIncludes
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_INCLUDES,
    fetchPermissionSubscribersIncludes
  );
export const setFetchPermissionSubscribersFilterIsDefault = (
  fetchPermissionSubscribersFilterIsDefault
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT,
    fetchPermissionSubscribersFilterIsDefault
  );
export const setFetchPermissionSubscribersLoading = (
  fetchPermissionSubscribersLoading
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING,
    fetchPermissionSubscribersLoading
  );
export const setFetchPermissionSubscribersSuccess = (
  fetchPermissionSubscribersSuccess
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS,
    fetchPermissionSubscribersSuccess
  );
export const setFetchPermissionSubscribersFailed = (
  fetchPermissionSubscribersFailed
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED,
    fetchPermissionSubscribersFailed
  );

export const setFetchSelectPermissionAdminsSearch = (
  fetchSelectPermissionAdminsSearch
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_SEARCH,
    fetchSelectPermissionAdminsSearch
  );
export const setFetchSelectPermissionAdminsSort = (
  fetchSelectPermissionAdminsSort
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_SORT,
    fetchSelectPermissionAdminsSort
  );
export const setFetchSelectPermissionAdminsKeyBy = (
  fetchSelectPermissionAdminsKeyBy
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_KEY_BY,
    fetchSelectPermissionAdminsKeyBy
  );
export const setFetchSelectPermissionAdminsPage = (
  fetchSelectPermissionAdminsPage
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_PAGE,
    fetchSelectPermissionAdminsPage
  );
export const setFetchSelectPermissionAdminsPerPage = (
  fetchSelectPermissionAdminsPerPage
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_PER_PAGE,
    fetchSelectPermissionAdminsPerPage
  );
export const setFetchSelectPermissionAdminsIncludes = (
  fetchSelectPermissionAdminsIncludes
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_INCLUDES,
    fetchSelectPermissionAdminsIncludes
  );
export const setFetchSelectPermissionAdminsFilterPermissionsIds = (
  fetchSelectPermissionAdminsFilterPermissionsIds
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_FILTER_PERMISSIONS_IDS,
    fetchSelectPermissionAdminsFilterPermissionsIds
  );
export const setFetchSelectPermissionAdminsFilterIsDefault = (
  fetchSelectPermissionAdminsFilterIsDefault
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_FILTER_IS_DEFAULT,
    fetchSelectPermissionAdminsFilterIsDefault
  );
export const setFetchSelectPermissionAdminsLoading = (
  fetchSelectPermissionAdminsLoading
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_LOADING,
    fetchSelectPermissionAdminsLoading
  );
export const setFetchSelectPermissionAdminsSuccess = (
  fetchSelectPermissionAdminsSuccess
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_SUCCESS,
    fetchSelectPermissionAdminsSuccess
  );
export const setFetchSelectPermissionAdminsFailed = (
  fetchSelectPermissionAdminsFailed
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_FAILED,
    fetchSelectPermissionAdminsFailed
  );

export const setFetchSelectPermissionSubscribersSearch = (
  fetchSelectPermissionSubscribersSearch
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SEARCH,
    fetchSelectPermissionSubscribersSearch
  );
export const setFetchSelectPermissionSubscribersSort = (
  fetchSelectPermissionSubscribersSort
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SORT,
    fetchSelectPermissionSubscribersSort
  );
export const setFetchSelectPermissionSubscribersKeyBy = (
  fetchSelectPermissionSubscribersKeyBy
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY,
    fetchSelectPermissionSubscribersKeyBy
  );
export const setFetchSelectPermissionSubscribersPage = (
  fetchSelectPermissionSubscribersPage
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PAGE,
    fetchSelectPermissionSubscribersPage
  );
export const setFetchSelectPermissionSubscribersPerPage = (
  fetchSelectPermissionSubscribersPerPage
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE,
    fetchSelectPermissionSubscribersPerPage
  );
export const setFetchSelectPermissionSubscribersIncludes = (
  fetchSelectPermissionSubscribersIncludes
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES,
    fetchSelectPermissionSubscribersIncludes
  );
export const setFetchSelectPermissionSubscribersFilterPermissionsIds = (
  fetchSelectPermissionSubscribersFilterPermissionsIds
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS,
    fetchSelectPermissionSubscribersFilterPermissionsIds
  );
export const setFetchSelectPermissionSubscribersFilterIsDefault = (
  fetchSelectPermissionSubscribersFilterIsDefault
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT,
    fetchSelectPermissionSubscribersFilterIsDefault
  );
export const setFetchSelectPermissionSubscribersLoading = (
  fetchSelectPermissionSubscribersLoading
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_LOADING,
    fetchSelectPermissionSubscribersLoading
  );
export const setFetchSelectPermissionSubscribersSuccess = (
  fetchSelectPermissionSubscribersSuccess
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS,
    fetchSelectPermissionSubscribersSuccess
  );
export const setFetchSelectPermissionSubscribersFailed = (
  fetchSelectPermissionSubscribersFailed
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FAILED,
    fetchSelectPermissionSubscribersFailed
  );

export const setAppendSelectPermissionAdminsSearch = (
  appendSelectPermissionAdminsSearch
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_SEARCH,
    appendSelectPermissionAdminsSearch
  );
export const setAppendSelectPermissionAdminsSort = (
  appendSelectPermissionAdminsSort
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_SORT,
    appendSelectPermissionAdminsSort
  );
export const setAppendSelectPermissionAdminsKeyBy = (
  appendSelectPermissionAdminsKeyBy
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_KEY_BY,
    appendSelectPermissionAdminsKeyBy
  );
export const setAppendSelectPermissionAdminsPage = (
  appendSelectPermissionAdminsPage
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_PAGE,
    appendSelectPermissionAdminsPage
  );
export const setAppendSelectPermissionAdminsPerPage = (
  appendSelectPermissionAdminsPerPage
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_PER_PAGE,
    appendSelectPermissionAdminsPerPage
  );
export const setAppendSelectPermissionAdminsIncludes = (
  appendSelectPermissionAdminsIncludes
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_INCLUDES,
    appendSelectPermissionAdminsIncludes
  );
export const setAppendSelectPermissionAdminsFilterPermissionsIds = (
  appendSelectPermissionAdminsFilterPermissionsIds
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_FILTER_PERMISSIONS_IDS,
    appendSelectPermissionAdminsFilterPermissionsIds
  );
export const setAppendSelectPermissionAdminsFilterIsDefault = (
  appendSelectPermissionAdminsFilterIsDefault
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_FILTER_IS_DEFAULT,
    appendSelectPermissionAdminsFilterIsDefault
  );
export const setAppendSelectPermissionAdminsLoading = (
  appendSelectPermissionAdminsLoading
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_LOADING,
    appendSelectPermissionAdminsLoading
  );
export const setAppendSelectPermissionAdminsSuccess = (
  appendSelectPermissionAdminsSuccess
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_SUCCESS,
    appendSelectPermissionAdminsSuccess
  );
export const setAppendSelectPermissionAdminsFailed = (
  appendSelectPermissionAdminsFailed
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_FAILED,
    appendSelectPermissionAdminsFailed
  );

export const setAppendSelectPermissionSubscribersSearch = (
  appendSelectPermissionSubscribersSearch
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SEARCH,
    appendSelectPermissionSubscribersSearch
  );
export const setAppendSelectPermissionSubscribersSort = (
  appendSelectPermissionSubscribersSort
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SORT,
    appendSelectPermissionSubscribersSort
  );
export const setAppendSelectPermissionSubscribersKeyBy = (
  appendSelectPermissionSubscribersKeyBy
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY,
    appendSelectPermissionSubscribersKeyBy
  );
export const setAppendSelectPermissionSubscribersPage = (
  appendSelectPermissionSubscribersPage
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PAGE,
    appendSelectPermissionSubscribersPage
  );
export const setAppendSelectPermissionSubscribersPerPage = (
  appendSelectPermissionSubscribersPerPage
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE,
    appendSelectPermissionSubscribersPerPage
  );
export const setAppendSelectPermissionSubscribersIncludes = (
  appendSelectPermissionSubscribersIncludes
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES,
    appendSelectPermissionSubscribersIncludes
  );
export const setAppendSelectPermissionSubscribersFilterPermissionsIds = (
  appendSelectPermissionSubscribersFilterPermissionsIds
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS,
    appendSelectPermissionSubscribersFilterPermissionsIds
  );
export const setAppendSelectPermissionSubscribersFilterIsDefault = (
  appendSelectPermissionSubscribersFilterIsDefault
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT,
    appendSelectPermissionSubscribersFilterIsDefault
  );
export const setAppendSelectPermissionSubscribersLoading = (
  appendSelectPermissionSubscribersLoading
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_LOADING,
    appendSelectPermissionSubscribersLoading
  );
export const setAppendSelectPermissionSubscribersSuccess = (
  appendSelectPermissionSubscribersSuccess
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS,
    appendSelectPermissionSubscribersSuccess
  );
export const setAppendSelectPermissionSubscribersFailed = (
  appendSelectPermissionSubscribersFailed
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FAILED,
    appendSelectPermissionSubscribersFailed
  );

export const setFetchPermissionLoading = (fetchPermissionLoading) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_LOADING,
    fetchPermissionLoading
  );
export const setFetchPermissionSuccess = (fetchPermissionSuccess) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUCCESS,
    fetchPermissionSuccess
  );
export const setFetchPermissionFailed = (fetchPermissionFailed) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_FAILED,
    fetchPermissionFailed
  );

export const setCreatePermissionLoading = (createPermissionLoading) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_CREATE_PERMISSION_LOADING,
    createPermissionLoading
  );
export const setCreatePermissionSuccess = (createPermissionSuccess) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_CREATE_PERMISSION_SUCCESS,
    createPermissionSuccess
  );
export const setCreatePermissionFailed = (createPermissionFailed) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_CREATE_PERMISSION_FAILED,
    createPermissionFailed
  );

export const setUpdatePermissionLoading = (updatePermissionLoading) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_UPDATE_PERMISSION_LOADING,
    updatePermissionLoading
  );
export const setUpdatePermissionSuccess = (updatePermissionSuccess) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_UPDATE_PERMISSION_SUCCESS,
    updatePermissionSuccess
  );
export const setUpdatePermissionFailed = (updatePermissionFailed) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_UPDATE_PERMISSION_FAILED,
    updatePermissionFailed
  );

export const setDeletePermissionLoading = (deletePermissionLoading) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_DELETE_PERMISSION_LOADING,
    deletePermissionLoading
  );
export const setDeletePermissionSuccess = (deletePermissionSuccess) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_DELETE_PERMISSION_SUCCESS,
    deletePermissionSuccess
  );
export const setDeletePermissionFailed = (deletePermissionFailed) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_DELETE_PERMISSION_FAILED,
    deletePermissionFailed
  );

export const setIsFetchPermissionAdminsHitted = (
  isFetchPermissionAdminsHitted
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_PERMISSION_ADMINS_HITTED,
    isFetchPermissionAdminsHitted
  );
export const setIsFetchPermissionSubscribersHitted = (
  isFetchPermissionSubscribersHitted
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED,
    isFetchPermissionSubscribersHitted
  );
export const setIsFetchSelectPermissionAdminsHitted = (
  isFetchSelectPermissionAdminsHitted
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_SELECT_PERMISSION_ADMINS_HITTED,
    isFetchSelectPermissionAdminsHitted
  );
export const setIsFetchSelectPermissionSubscribersHitted = (
  isFetchSelectPermissionSubscribersHitted
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_SELECT_PERMISSION_SUBSCRIBERS_HITTED,
    isFetchSelectPermissionSubscribersHitted
  );
export const setIsAppendSelectPermissionAdminsHitted = (
  isAppendSelectPermissionAdminsHitted
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_APPEND_SELECT_PERMISSION_ADMINS_HITTED,
    isAppendSelectPermissionAdminsHitted
  );
export const setIsAppendSelectPermissionSubscribersHitted = (
  isAppendSelectPermissionSubscribersHitted
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_APPEND_SELECT_PERMISSION_SUBSCRIBERS_HITTED,
    isAppendSelectPermissionSubscribersHitted
  );
export const setIsFetchPermissionHitted = (isFetchPermissionHitted) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_PERMISSION_HITTED,
    isFetchPermissionHitted
  );
export const setIsCreatePermissionHitted = (isCreatePermissionHitted) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_CREATE_PERMISSION_HITTED,
    isCreatePermissionHitted
  );
export const setIsUpdatePermissionHitted = (isUpdatePermissionHitted) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_UPDATE_PERMISSION_HITTED,
    isUpdatePermissionHitted
  );
export const setIsDeletePermissionHitted = (isDeletePermissionHitted) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.SET_IS_DELETE_PERMISSION_HITTED,
    isDeletePermissionHitted
  );

export const appendPermissionAdmins = (permissionAdmins) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.APPEND_PERMISSION_ADMINS,
    permissionAdmins
  );
export const appendPermissionSubscribers = (permissionSubscribers) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.APPEND_PERMISSION_SUBSCRIBERS,
    permissionSubscribers
  );
export const appendSelectPermissionAdmins = (selectPermissionAdmins) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.APPEND_SELECT_PERMISSION_ADMINS,
    selectPermissionAdmins
  );
export const appendSelectPermissionSubscribers = (
  selectPermissionSubscribers
) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.APPEND_SELECT_PERMISSION_SUBSCRIBERS,
    selectPermissionSubscribers
  );

export const fetchPermissionAdminsStart = () =>
  createAction(PERMISSION_ADMIN_ACTION_TYPES.FETCH_PERMISSION_ADMINS_START);
export const fetchPermissionSubscribersStart = () =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.FETCH_PERMISSION_SUBSCRIBERS_START
  );
export const fetchSelectPermissionAdminsStart = () =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.FETCH_SELECT_PERMISSION_ADMINS_START
  );
export const fetchSelectPermissionSubscribersStart = () =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.FETCH_SELECT_PERMISSION_SUBSCRIBERS_START
  );
export const appendSelectPermissionAdminsStart = () =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.APPEND_SELECT_PERMISSION_ADMINS_START
  );
export const appendSelectPermissionSubscribersStart = () =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.APPEND_SELECT_PERMISSION_SUBSCRIBERS_START
  );
export const fetchPermissionStart = (permissionId) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.FETCH_PERMISSION_START,
    permissionId
  );
export const createPermissionStart = (request) =>
  createAction(PERMISSION_ADMIN_ACTION_TYPES.CREATE_PERMISSION_START, request);
export const updatePermissionStart = (permissionId, request) =>
  createAction(PERMISSION_ADMIN_ACTION_TYPES.UPDATE_PERMISSION_START, {
    permissionId,
    request,
  });
export const deletePermissionStart = (permissionId) =>
  createAction(
    PERMISSION_ADMIN_ACTION_TYPES.DELETE_PERMISSION_START,
    permissionId
  );

export const resetPermissionAdminReducer = () =>
  createAction(PERMISSION_ADMIN_ACTION_TYPES.RESET_PERMISSION_ADMIN_REDUCER);
