import apiService from "./api";

export const getAdmins = async (parameters) => (await apiService.get("/admin", parameters)).data;

export const getAdmin = async (id) => (await apiService.get(`/admin/${id}`)).data;

export const createAdmin = async (request) => (await apiService.post("/admin", request)).data;

export const updateAdmin = async (id, request) => (await apiService.post(`/admin/${id}`, request)).data;

export const deleteAdmin = async (id) => (await apiService.delete(`/admin/${id}`)).data;
