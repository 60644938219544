import EXPORT_PDF_ACTION_TYPES from "./export-pdf.type";

export const EXPORT_PDF_INITIAL_STATE = {
  exportPdfOrderInvoiceUrl: null,
  exportPdfOrderInvoiceBlob: null,
  exportPdfOrderInvoiceName: null,

  exportPdfOrderKitchenUrl: null,
  exportPdfOrderKitchenBlob: null,
  exportPdfOrderKitchenName: null,

  exportPdfExtendInvoiceUrl: null,
  exportPdfExtendInvoiceBlob: null,
  exportPdfExtendInvoiceName: null,

  exportPdfOrderInvoiceLoading: false,
  exportPdfOrderInvoiceSuccess: null,
  exportPdfOrderInvoiceFailed: null,

  exportPdfOrderKitchenLoading: false,
  exportPdfOrderKitchenSuccess: null,
  exportPdfOrderKitchenFailed: null,

  exportPdfExtendInvoiceLoading: false,
  exportPdfExtendInvoiceSuccess: null,
  exportPdfExtendInvoiceFailed: null,

  isExportPdfOrderInvoiceHitted: false,
  isExportPdfOrderKitchenHitted: false,
  isExportPdfExtendInvoiceHitted: false,
};

export const exportPdfReducer = (state = EXPORT_PDF_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_URL:
      return { ...state, exportPdfOrderInvoiceUrl: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_BLOB:
      return { ...state, exportPdfOrderInvoiceBlob: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_NAME:
      return { ...state, exportPdfOrderInvoiceName: payload };

    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_URL:
      return { ...state, exportPdfOrderKitchenUrl: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_BLOB:
      return { ...state, exportPdfOrderKitchenBlob: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_NAME:
      return { ...state, exportPdfOrderKitchenName: payload };

    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_EXTEND_INVOICE_URL:
      return { ...state, exportPdfExtendInvoiceUrl: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_EXTEND_INVOICE_BLOB:
      return { ...state, exportPdfExtendInvoiceBlob: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_EXTEND_INVOICE_NAME:
      return { ...state, exportPdfExtendInvoiceName: payload };

    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_LOADING:
      return { ...state, exportPdfOrderInvoiceLoading: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_SUCCESS:
      return { ...state, exportPdfOrderInvoiceSuccess: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_FAILED:
      return { ...state, exportPdfOrderInvoiceFailed: payload };

    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_LOADING:
      return { ...state, exportPdfOrderKitchenLoading: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_SUCCESS:
      return { ...state, exportPdfOrderKitchenSuccess: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_FAILED:
      return { ...state, exportPdfOrderKitchenFailed: payload };

    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_EXTEND_INVOICE_LOADING:
      return { ...state, exportPdfExtendInvoiceLoading: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_EXTEND_INVOICE_SUCCESS:
      return { ...state, exportPdfExtendInvoiceSuccess: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_EXTEND_INVOICE_FAILED:
      return { ...state, exportPdfExtendInvoiceFailed: payload };

    case EXPORT_PDF_ACTION_TYPES.SET_IS_EXPORT_PDF_ORDER_INVOICE_HITTED:
      return { ...state, isExportPdfOrderInvoiceHitted: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_IS_EXPORT_PDF_ORDER_KITCHEN_HITTED:
      return { ...state, isExportPdfOrderKitchenHitted: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_IS_EXPORT_PDF_EXTEND_INVOICE_HITTED:
      return { ...state, isExportPdfExtendInvoiceHitted: payload };

    case EXPORT_PDF_ACTION_TYPES.RESET_EXPORT_PDF_REDUCER:
      return EXPORT_PDF_INITIAL_STATE;
    default:
      return state;
  }
};
