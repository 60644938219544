import { takeLatest, put, all, call, select } from "redux-saga/effects";

import EXTEND_PERIOD_ACTION_TYPES from "./extend-period.type";

import {
  appendExtendPeriods,
  setCreateExtendPeriodFailed,
  setCreateExtendPeriodLoading,
  setCreateExtendPeriodSuccess,
  setFetchExtendPeriodFailed,
  setFetchExtendPeriodLoading,
  setFetchExtendPeriodsFailed,
  setFetchExtendPeriodsLoading,
  setFetchExtendPeriodsSuccess,
  setFetchExtendPeriodSuccess,
  setIsExtendPeriodsHasMore,
  setExtendPeriod,
  setExtendPeriods,
  setIsFetchExtendPeriodsHitted,
  setIsFetchExtendPeriodHitted,
  setIsCreateExtendPeriodHitted,
  setCreateExtendPeriod,
  setFetchExtendPeriodsPage,
  setCustomExtendPeriodLoading,
  setCustomExtendPeriodSuccess,
  setCustomExtendPeriodFailed,
  setIsCustomExtendPeriodHitted,
  setCustomExtendPeriod,
  setStopExtendPeriodLoading,
  setIsStopExtendPeriodHitted,
  setStopExtendPeriodSuccess,
  setStopExtendPeriodFailed,
  setCalculateCreateExtendPeriodLoading,
  setIsCalculateCreateExtendPeriodHitted,
  setCalculateCreateExtendPeriod,
  setCalculateCreateExtendPeriodSuccess,
  setCalculateCreateExtendPeriodFailed,
  setCalculateUpdateExtendPeriodLoading,
  setIsCalculateUpdateExtendPeriodHitted,
  setCalculateUpdateExtendPeriod,
  setCalculateUpdateExtendPeriodSuccess,
  setCalculateUpdateExtendPeriodFailed,
  setUpdateExtendPeriodLoading,
  setIsUpdateExtendPeriodHitted,
  setUpdateExtendPeriod,
  setUpdateExtendPeriodSuccess,
  setUpdateExtendPeriodFailed,
} from "./extend-period.action";
import {
  getFetchExtendPeriodsFilterCreatedBy,
  getFetchExtendPeriodsFilterMarketId,
  getFetchExtendPeriodsFilterStatuses,
  getFetchExtendPeriodsIncludes,
  getFetchExtendPeriodsKeyBy,
  getFetchExtendPeriodsPage,
  getFetchExtendPeriodsPerPage,
  getFetchExtendPeriodsSearch,
  getFetchExtendPeriodsSort,
  getIsFetchExtendPeriodsHitted,
} from "./extend-period.selector";

import {
  getExtendPeriods,
  getExtendPeriod,
  createExtendPeriod,
  customExtendPeriod,
  stopExtendPeriod,
  calculateCreateExtendPeriod,
  calculateUpdateExtendPeriod,
  updateExtendPeriod,
} from "../../api/extend-period.api";

export function* _getExtendPeriods() {
  try {
    yield put(setFetchExtendPeriodsLoading(true));

    const search = yield select(getFetchExtendPeriodsSearch);
    const sort = yield select(getFetchExtendPeriodsSort);
    const key_by = yield select(getFetchExtendPeriodsKeyBy);
    const page = yield select(getFetchExtendPeriodsPage);
    const per_page = yield select(getFetchExtendPeriodsPerPage);
    const includes = yield select(getFetchExtendPeriodsIncludes);
    const market_id = yield select(getFetchExtendPeriodsFilterMarketId);
    const statuses = yield select(getFetchExtendPeriodsFilterStatuses);
    const created_by = yield select(getFetchExtendPeriodsFilterCreatedBy);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        statuses,
        created_by,
      },
    };

    const {
      meta: { message },
      data: { data: extendPeriods },
    } = yield call(getExtendPeriods, parameters);

    yield put(setIsFetchExtendPeriodsHitted(true));
    yield put(setIsExtendPeriodsHasMore(extendPeriods.length > 0));

    if (page > 1) {
      yield put(appendExtendPeriods(extendPeriods));
    } else {
      yield put(setExtendPeriods(extendPeriods));
    }

    yield put(setFetchExtendPeriodsSuccess(message));
    yield put(setFetchExtendPeriodsLoading(false));
  } catch (error) {
    yield put(setFetchExtendPeriodsFailed(error));
    yield put(setFetchExtendPeriodsLoading(false));
  }
}
export function* _getExtendPeriod({ payload: extendPeriodId }) {
  try {
    yield put(setFetchExtendPeriodLoading(true));

    const {
      meta: { message },
      data: extendPeriod,
    } = yield call(getExtendPeriod, extendPeriodId);

    yield put(setIsFetchExtendPeriodHitted(true));
    yield put(setExtendPeriod(extendPeriod));

    yield put(setFetchExtendPeriodSuccess(message));
    yield put(setFetchExtendPeriodLoading(false));
  } catch (error) {
    yield put(setFetchExtendPeriodFailed(error));
    yield put(setFetchExtendPeriodLoading(false));
  }
}
export function* _calculateCreateExtendPeriod({ payload: request }) {
  try {
    yield put(setCalculateCreateExtendPeriodLoading(true));

    const {
      meta: { message },
      data: extendPeriod,
    } = yield call(calculateCreateExtendPeriod, request);

    yield put(setIsCalculateCreateExtendPeriodHitted(true));
    yield put(setCalculateCreateExtendPeriod(extendPeriod));

    yield put(setCalculateCreateExtendPeriodSuccess(message));
    yield put(setCalculateCreateExtendPeriodLoading(false));
  } catch (error) {
    yield put(setCalculateCreateExtendPeriodFailed(error));
    yield put(setCalculateCreateExtendPeriodLoading(false));
  }
}
export function* _calculateUpdateExtendPeriod({
  payload: { extendId, request },
}) {
  try {
    yield put(setCalculateUpdateExtendPeriodLoading(true));

    const {
      meta: { message },
      data: extendPeriod,
    } = yield call(calculateUpdateExtendPeriod, extendId, request);

    yield put(setIsCalculateUpdateExtendPeriodHitted(true));
    yield put(setCalculateUpdateExtendPeriod(extendPeriod));

    yield put(setCalculateUpdateExtendPeriodSuccess(message));
    yield put(setCalculateUpdateExtendPeriodLoading(false));
  } catch (error) {
    yield put(setCalculateUpdateExtendPeriodFailed(error));
    yield put(setCalculateUpdateExtendPeriodLoading(false));
  }
}
export function* _createExtendPeriod({ payload: request }) {
  try {
    yield put(setCreateExtendPeriodLoading(true));

    const {
      meta: { message },
      data: extendPeriod,
    } = yield call(createExtendPeriod, request);

    yield put(setIsCreateExtendPeriodHitted(true));
    yield put(setCreateExtendPeriod(extendPeriod));

    const isFetchExtendPeriodsHitted = yield select(
      getIsFetchExtendPeriodsHitted
    );

    if (isFetchExtendPeriodsHitted) {
      yield put(setFetchExtendPeriodsPage(1));
      yield call(_getExtendPeriods);
    }

    yield put(setCreateExtendPeriodSuccess(message));
    yield put(setCreateExtendPeriodLoading(false));
  } catch (error) {
    yield put(setCreateExtendPeriodFailed(error));
    yield put(setCreateExtendPeriodLoading(false));
  }
}
export function* _updateExtendPeriod({ payload: { extendId, request } }) {
  try {
    yield put(setUpdateExtendPeriodLoading(true));

    const {
      meta: { message },
      data: extendPeriod,
    } = yield call(updateExtendPeriod, extendId, request);

    yield put(setIsUpdateExtendPeriodHitted(true));
    yield put(setUpdateExtendPeriod(extendPeriod));

    const isFetchExtendPeriodsHitted = yield select(
      getIsFetchExtendPeriodsHitted
    );

    if (isFetchExtendPeriodsHitted) {
      yield put(setFetchExtendPeriodsPage(1));
      yield call(_getExtendPeriods);
    }

    yield put(setUpdateExtendPeriodSuccess(message));
    yield put(setUpdateExtendPeriodLoading(false));
  } catch (error) {
    yield put(setUpdateExtendPeriodFailed(error));
    yield put(setUpdateExtendPeriodLoading(false));
  }
}
export function* _customExtendPeriod({ payload: request }) {
  try {
    yield put(setCustomExtendPeriodLoading(true));

    const {
      meta: { message },
      data: extendPeriod,
    } = yield call(customExtendPeriod, request);

    yield put(setIsCustomExtendPeriodHitted(true));
    yield put(setCustomExtendPeriod(extendPeriod));

    yield put(setCustomExtendPeriodSuccess(message));
    yield put(setCustomExtendPeriodLoading(false));
  } catch (error) {
    yield put(setCustomExtendPeriodFailed(error));
    yield put(setCustomExtendPeriodLoading(false));
  }
}
export function* _stopExtendPeriod({ payload: request }) {
  try {
    yield put(setStopExtendPeriodLoading(true));

    const {
      meta: { message },
    } = yield call(stopExtendPeriod, request);

    yield put(setIsStopExtendPeriodHitted(true));

    yield put(setStopExtendPeriodSuccess(message));
    yield put(setStopExtendPeriodLoading(false));
  } catch (error) {
    yield put(setStopExtendPeriodFailed(error));
    yield put(setStopExtendPeriodLoading(false));
  }
}

export function* onFetchExtendPeriodsStart() {
  yield takeLatest(
    EXTEND_PERIOD_ACTION_TYPES.FETCH_EXTEND_PERIODS_START,
    _getExtendPeriods
  );
}
export function* onFetchExtendPeriodStart() {
  yield takeLatest(
    EXTEND_PERIOD_ACTION_TYPES.FETCH_EXTEND_PERIOD_START,
    _getExtendPeriod
  );
}
export function* onCalculateCreateExtendPeriodStart() {
  yield takeLatest(
    EXTEND_PERIOD_ACTION_TYPES.CALCULATE_CREATE_EXTEND_PERIOD_START,
    _calculateCreateExtendPeriod
  );
}
export function* onCalculateUpdateExtendPeriodStart() {
  yield takeLatest(
    EXTEND_PERIOD_ACTION_TYPES.CALCULATE_UPDATE_EXTEND_PERIOD_START,
    _calculateUpdateExtendPeriod
  );
}
export function* onCreateExtendPeriodStart() {
  yield takeLatest(
    EXTEND_PERIOD_ACTION_TYPES.CREATE_EXTEND_PERIOD_START,
    _createExtendPeriod
  );
}
export function* onUpdateExtendPeriodStart() {
  yield takeLatest(
    EXTEND_PERIOD_ACTION_TYPES.UPDATE_EXTEND_PERIOD_START,
    _updateExtendPeriod
  );
}
export function* onCustomExtendPeriodStart() {
  yield takeLatest(
    EXTEND_PERIOD_ACTION_TYPES.CUSTOM_EXTEND_PERIOD_START,
    _customExtendPeriod
  );
}
export function* onStopExtendPeriodStart() {
  yield takeLatest(
    EXTEND_PERIOD_ACTION_TYPES.STOP_EXTEND_PERIOD_START,
    _stopExtendPeriod
  );
}

export function* extendPeriodSaga() {
  yield all([
    call(onFetchExtendPeriodsStart),
    call(onFetchExtendPeriodStart),
    call(onCalculateCreateExtendPeriodStart),
    call(onCalculateUpdateExtendPeriodStart),
    call(onCreateExtendPeriodStart),
    call(onUpdateExtendPeriodStart),
    call(onCustomExtendPeriodStart),
    call(onStopExtendPeriodStart),
  ]);
}
