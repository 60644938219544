import { createSelector } from "reselect";

const productSelector = ({ product }) => product;

export const getSelectedProducts = createSelector(
  [productSelector],
  ({ selectedProducts }) => selectedProducts
);
export const getIsSelectProducts = createSelector(
  [productSelector],
  ({ isSelectProducts }) => isSelectProducts
);

export const getEditModifierIndex = createSelector(
  [productSelector],
  ({ editModifierIndex }) => editModifierIndex
);
export const getEditModifierModel = createSelector(
  [productSelector],
  ({ editModifierModel }) => editModifierModel
);

export const getProducts = createSelector(
  [productSelector],
  ({ products }) => products
);
export const getOrderProducts = createSelector(
  [productSelector],
  ({ orderProducts }) => orderProducts
);
export const getProduct = createSelector(
  [productSelector],
  ({ product }) => product
);

export const getIsProductsHasMore = createSelector(
  [productSelector],
  ({ isProductsHasMore }) => isProductsHasMore
);
export const getIsOrderProductsHasMore = createSelector(
  [productSelector],
  ({ isOrderProductsHasMore }) => isOrderProductsHasMore
);

export const getFetchProductsSearch = createSelector(
  [productSelector],
  ({ fetchProductsSearch }) => fetchProductsSearch
);
export const getFetchProductsSort = createSelector(
  [productSelector],
  ({ fetchProductsSort }) => fetchProductsSort
);
export const getFetchProductsKeyBy = createSelector(
  [productSelector],
  ({ fetchProductsKeyBy }) => fetchProductsKeyBy
);
export const getFetchProductsPage = createSelector(
  [productSelector],
  ({ fetchProductsPage }) => fetchProductsPage
);
export const getFetchProductsPerPage = createSelector(
  [productSelector],
  ({ fetchProductsPerPage }) => fetchProductsPerPage
);
export const getFetchProductsIncludes = createSelector(
  [productSelector],
  ({ fetchProductsIncludes }) => fetchProductsIncludes
);
export const getFetchProductsFilterMarketId = createSelector(
  [productSelector],
  ({ fetchProductsFilterMarketId }) => fetchProductsFilterMarketId
);
export const getFetchProductsFilterBranchId = createSelector(
  [productSelector],
  ({ fetchProductsFilterBranchId }) => fetchProductsFilterBranchId
);
export const getFetchProductsFilterProductCategoryId = createSelector(
  [productSelector],
  ({ fetchProductsFilterProductCategoryId }) =>
    fetchProductsFilterProductCategoryId
);
export const getFetchProductsFilterProductsIds = createSelector(
  [productSelector],
  ({ fetchProductsFilterProductsIds }) => fetchProductsFilterProductsIds
);
export const getFetchProductsFilterIsActive = createSelector(
  [productSelector],
  ({ fetchProductsFilterIsActive }) => fetchProductsFilterIsActive
);
export const getFetchProductsLoading = createSelector(
  [productSelector],
  ({ fetchProductsLoading }) => fetchProductsLoading
);
export const getFetchProductsSuccess = createSelector(
  [productSelector],
  ({ fetchProductsSuccess }) => fetchProductsSuccess
);
export const getFetchProductsFailed = createSelector(
  [productSelector],
  ({ fetchProductsFailed }) => fetchProductsFailed
);

export const getFetchOrderProductsSearch = createSelector(
  [productSelector],
  ({ fetchOrderProductsSearch }) => fetchOrderProductsSearch
);
export const getFetchOrderProductsSort = createSelector(
  [productSelector],
  ({ fetchOrderProductsSort }) => fetchOrderProductsSort
);
export const getFetchOrderProductsKeyBy = createSelector(
  [productSelector],
  ({ fetchOrderProductsKeyBy }) => fetchOrderProductsKeyBy
);
export const getFetchOrderProductsPage = createSelector(
  [productSelector],
  ({ fetchOrderProductsPage }) => fetchOrderProductsPage
);
export const getFetchOrderProductsPerPage = createSelector(
  [productSelector],
  ({ fetchOrderProductsPerPage }) => fetchOrderProductsPerPage
);
export const getFetchOrderProductsIncludes = createSelector(
  [productSelector],
  ({ fetchOrderProductsIncludes }) => fetchOrderProductsIncludes
);
export const getFetchOrderProductsFilterMarketId = createSelector(
  [productSelector],
  ({ fetchOrderProductsFilterMarketId }) => fetchOrderProductsFilterMarketId
);
export const getFetchOrderProductsFilterBranchId = createSelector(
  [productSelector],
  ({ fetchOrderProductsFilterBranchId }) => fetchOrderProductsFilterBranchId
);
export const getFetchOrderProductsFilterProductCategoryId = createSelector(
  [productSelector],
  ({ fetchOrderProductsFilterProductCategoryId }) =>
    fetchOrderProductsFilterProductCategoryId
);
export const getFetchOrderProductsFilterProductsIds = createSelector(
  [productSelector],
  ({ fetchOrderProductsFilterProductsIds }) =>
    fetchOrderProductsFilterProductsIds
);
export const getFetchOrderProductsFilterIsActive = createSelector(
  [productSelector],
  ({ fetchOrderProductsFilterIsActive }) => fetchOrderProductsFilterIsActive
);
export const getFetchOrderProductsLoading = createSelector(
  [productSelector],
  ({ fetchOrderProductsLoading }) => fetchOrderProductsLoading
);
export const getFetchOrderProductsSuccess = createSelector(
  [productSelector],
  ({ fetchOrderProductsSuccess }) => fetchOrderProductsSuccess
);
export const getFetchOrderProductsFailed = createSelector(
  [productSelector],
  ({ fetchOrderProductsFailed }) => fetchOrderProductsFailed
);

export const getFetchProductLoading = createSelector(
  [productSelector],
  ({ fetchProductLoading }) => fetchProductLoading
);
export const getFetchProductSuccess = createSelector(
  [productSelector],
  ({ fetchProductSuccess }) => fetchProductSuccess
);
export const getFetchProductFailed = createSelector(
  [productSelector],
  ({ fetchProductFailed }) => fetchProductFailed
);

export const getCreateProductLoading = createSelector(
  [productSelector],
  ({ createProductLoading }) => createProductLoading
);
export const getCreateProductSuccess = createSelector(
  [productSelector],
  ({ createProductSuccess }) => createProductSuccess
);
export const getCreateProductFailed = createSelector(
  [productSelector],
  ({ createProductFailed }) => createProductFailed
);

export const getUpdateProductLoading = createSelector(
  [productSelector],
  ({ updateProductLoading }) => updateProductLoading
);
export const getUpdateProductSuccess = createSelector(
  [productSelector],
  ({ updateProductSuccess }) => updateProductSuccess
);
export const getUpdateProductFailed = createSelector(
  [productSelector],
  ({ updateProductFailed }) => updateProductFailed
);

export const getDeleteProductLoading = createSelector(
  [productSelector],
  ({ deleteProductLoading }) => deleteProductLoading
);
export const getDeleteProductSuccess = createSelector(
  [productSelector],
  ({ deleteProductSuccess }) => deleteProductSuccess
);
export const getDeleteProductFailed = createSelector(
  [productSelector],
  ({ deleteProductFailed }) => deleteProductFailed
);

export const getMultipleCreateProductsLoading = createSelector(
  [productSelector],
  ({ multipleCreateProductsLoading }) => multipleCreateProductsLoading
);
export const getMultipleCreateProductsSuccess = createSelector(
  [productSelector],
  ({ multipleCreateProductsSuccess }) => multipleCreateProductsSuccess
);
export const getMultipleCreateProductsFailed = createSelector(
  [productSelector],
  ({ multipleCreateProductsFailed }) => multipleCreateProductsFailed
);

export const getMultipleDeleteProductsLoading = createSelector(
  [productSelector],
  ({ multipleDeleteProductsLoading }) => multipleDeleteProductsLoading
);
export const getMultipleDeleteProductsSuccess = createSelector(
  [productSelector],
  ({ multipleDeleteProductsSuccess }) => multipleDeleteProductsSuccess
);
export const getMultipleDeleteProductsFailed = createSelector(
  [productSelector],
  ({ multipleDeleteProductsFailed }) => multipleDeleteProductsFailed
);

export const getIsFetchProductsHitted = createSelector(
  [productSelector],
  ({ isFetchProductsHitted }) => isFetchProductsHitted
);
export const getIsFetchOrderProductsHitted = createSelector(
  [productSelector],
  ({ isFetchOrderProductsHitted }) => isFetchOrderProductsHitted
);
export const getIsFetchProductHitted = createSelector(
  [productSelector],
  ({ isFetchProductHitted }) => isFetchProductHitted
);
export const getIsCreateProductHitted = createSelector(
  [productSelector],
  ({ isCreateProductHitted }) => isCreateProductHitted
);
export const getIsUpdateProductHitted = createSelector(
  [productSelector],
  ({ isUpdateProductHitted }) => isUpdateProductHitted
);
export const getIsDeleteProductHitted = createSelector(
  [productSelector],
  ({ isDeleteProductHitted }) => isDeleteProductHitted
);
export const getIsMultipleCreateProductsHitted = createSelector(
  [productSelector],
  ({ isMultipleCreateProductsHitted }) => isMultipleCreateProductsHitted
);
export const getIsMultipleDeleteProductsHitted = createSelector(
  [productSelector],
  ({ isMultipleDeleteProductsHitted }) => isMultipleDeleteProductsHitted
);
