import CARD_ACTION_TYPES from "./card.type";

export const CARD_INITIAL_STATE = {
  cards: [],
  card: null,

  isCardsHasMore: 1,

  fetchCardsSearch: null,
  fetchCardsSort: null,
  fetchCardsPage: 1,
  fetchCardsPerPage: null,
  fetchCardsIncludes: null,
  fetchCardsFilterTransferType: null,
  fetchCardsFilterIsVerified: null,
  fetchCardsLoading: false,
  fetchCardsSuccess: null,
  fetchCardsFailed: null,

  fetchCardLoading: false,
  fetchCardSuccess: null,
  fetchCardFailed: null,

  createOrUpdateCardLoading: false,
  createOrUpdateCardSuccess: null,
  createOrUpdateCardFailed: null,

  isFetchCardsHitted: false,
  isFetchCardHitted: false,
  isCreateOrUpdateCardHitted: false,
};

export const cardReducer = (state = CARD_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CARD_ACTION_TYPES.SET_CARDS:
      return { ...state, cards: payload };
    case CARD_ACTION_TYPES.SET_CARD:
      return { ...state, card: payload };

    case CARD_ACTION_TYPES.SET_IS_CARDS_HAS_MORE:
      return { ...state, isCardsHasMore: payload };

    case CARD_ACTION_TYPES.SET_FETCH_CARDS_SEARCH:
      return { ...state, fetchCardsSearch: payload };
    case CARD_ACTION_TYPES.SET_FETCH_CARDS_SORT:
      return { ...state, fetchCardsSort: payload };
    case CARD_ACTION_TYPES.SET_FETCH_CARDS_PAGE:
      return { ...state, fetchCardsPage: payload };
    case CARD_ACTION_TYPES.SET_FETCH_CARDS_PER_PAGE:
      return { ...state, fetchCardsPerPage: payload };
    case CARD_ACTION_TYPES.SET_FETCH_CARDS_INCLUDES:
      return { ...state, fetchCardsIncludes: payload };
    case CARD_ACTION_TYPES.SET_FETCH_CARDS_FILTER_TRANSFER_TYPE:
      return { ...state, fetchCardsFilterTransferType: payload };
    case CARD_ACTION_TYPES.SET_FETCH_CARDS_FILTER_IS_VERIFIED:
      return { ...state, fetchCardsFilterIsVerified: payload };
    case CARD_ACTION_TYPES.SET_FETCH_CARDS_LOADING:
      return { ...state, fetchCardsLoading: payload };
    case CARD_ACTION_TYPES.SET_FETCH_CARDS_SUCCESS:
      return { ...state, fetchCardsSuccess: payload };
    case CARD_ACTION_TYPES.SET_FETCH_CARDS_FAILED:
      return { ...state, fetchCardsFailed: payload };

    case CARD_ACTION_TYPES.SET_FETCH_CARD_LOADING:
      return { ...state, fetchCardLoading: payload };
    case CARD_ACTION_TYPES.SET_FETCH_CARD_SUCCESS:
      return { ...state, fetchCardSuccess: payload };
    case CARD_ACTION_TYPES.SET_FETCH_CARD_FAILED:
      return { ...state, fetchCardFailed: payload };

    case CARD_ACTION_TYPES.SET_CREATE_OR_UPDATE_CARD_LOADING:
      return { ...state, createOrUpdateCardLoading: payload };
    case CARD_ACTION_TYPES.SET_CREATE_OR_UPDATE_CARD_SUCCESS:
      return { ...state, createOrUpdateCardSuccess: payload };
    case CARD_ACTION_TYPES.SET_CREATE_OR_UPDATE_CARD_FAILED:
      return { ...state, createOrUpdateCardFailed: payload };

    case CARD_ACTION_TYPES.APPEND_CARDS:
      return { ...state, cards: [...state.cards, ...payload] };

    case CARD_ACTION_TYPES.SET_IS_FETCH_CARDS_HITTED:
      return { ...state, isFetchCardsHitted: payload };
    case CARD_ACTION_TYPES.SET_IS_FETCH_CARD_HITTED:
      return { ...state, isFetchCardHitted: payload };
    case CARD_ACTION_TYPES.SET_IS_CREATE_OR_UPDATE_CARD_HITTED:
      return { ...state, isCreateOrUpdateCardHitted: payload };

    case CARD_ACTION_TYPES.RESET_CARD_REDUCER:
      return CARD_INITIAL_STATE;
    default:
      return state;
  }
};
