const WHATSAPP_ACTION_TYPES = {
  RESET_WHATSAPP_REDUCER: "whatsapp/RESET_WHATSAPP_REDUCER",

  SET_WHATSAPPS: "whatsapp/SET_WHATSAPPS",
  SET_WHATSAPP: "whatsapp/SET_WHATSAPP",

  SET_IS_WHATSAPPS_HAS_MORE: "whatsapp/SET_IS_WHATSAPPS_HAS_MORE",

  SET_FETCH_WHATSAPPS_PAGE: "whatsapp/SET_FETCH_WHATSAPPS_PAGE",
  SET_FETCH_WHATSAPPS_PER_PAGE: "whatsapp/SET_FETCH_WHATSAPPS_PER_PAGE",
  SET_FETCH_WHATSAPPS_INCLUDES: "whatsapp/SET_FETCH_WHATSAPPS_INCLUDES",
  SET_FETCH_WHATSAPPS_FILTER_IS_ACTIVE: "whatsapp/SET_FETCH_WHATSAPPS_FILTER_IS_ACTIVE",
  SET_FETCH_WHATSAPPS_LOADING: "whatsapp/SET_FETCH_WHATSAPPS_LOADING",
  SET_FETCH_WHATSAPPS_SUCCESS: "whatsapp/SET_FETCH_WHATSAPPS_SUCCESS",
  SET_FETCH_WHATSAPPS_FAILED: "whatsapp/SET_FETCH_WHATSAPPS_FAILED",

  SET_FETCH_WHATSAPP_IS_QR_CODE: "whatsapp/SET_FETCH_WHATSAPP_IS_QR_CODE",
  SET_FETCH_WHATSAPP_LOADING: "whatsapp/SET_FETCH_WHATSAPP_LOADING",
  SET_FETCH_WHATSAPP_SUCCESS: "whatsapp/SET_FETCH_WHATSAPP_SUCCESS",
  SET_FETCH_WHATSAPP_FAILED: "whatsapp/SET_FETCH_WHATSAPP_FAILED",

  SET_CREATE_OR_UPDATE_WHATSAPP_LOADING: "whatsapp/SET_CREATE_OR_UPDATE_WHATSAPP_LOADING",
  SET_CREATE_OR_UPDATE_WHATSAPP_SUCCESS: "whatsapp/SET_CREATE_OR_UPDATE_WHATSAPP_SUCCESS",
  SET_CREATE_OR_UPDATE_WHATSAPP_FAILED: "whatsapp/SET_CREATE_OR_UPDATE_WHATSAPP_FAILED",

  APPEND_WHATSAPPS: "whatsapp/APPEND_WHATSAPPS",

  IS_FETCH_WHATSAPPS_HITTED: "whatsapp/IS_FETCH_WHATSAPPS_HITTED",
  IS_FETCH_WHATSAPP_HITTED: "whatsapp/IS_FETCH_WHATSAPP_HITTED",
  IS_CREATE_OR_UPDATE_WHATSAPP_HITTED: "whatsapp/IS_CREATE_OR_UPDATE_WHATSAPP_HITTED",

  FETCH_WHATSAPPS_START: "whatsapp/FETCH_WHATSAPPS_START",
  FETCH_WHATSAPP_START: "whatsapp/FETCH_WHATSAPP_START",
  CREATE_OR_UPDATE_WHATSAPP_START: "whatsapp/CREATE_OR_UPDATE_WHATSAPP_START",
};

export default WHATSAPP_ACTION_TYPES;
