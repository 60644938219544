import { createAction } from "../../utils/store.utils";

import SELECT_CASHIER_ACTION_TYPES from "./select-cashier.type";

export const setCashiers = (cashiers) =>
  createAction(SELECT_CASHIER_ACTION_TYPES.SET_CASHIERS, cashiers);
export const setSearchCashiers = (searchCashiers) =>
  createAction(SELECT_CASHIER_ACTION_TYPES.SET_SEARCH_CASHIERS, searchCashiers);

export const appendCashiers = (cashiers) =>
  createAction(SELECT_CASHIER_ACTION_TYPES.APPEND_CASHIERS, cashiers);
export const appendSearchCashiers = (searchCashiers) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.APPEND_SEARCH_CASHIERS,
    searchCashiers
  );

export const setIsCashiersHasMore = (isCashiersHasMore) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_IS_CASHIERS_HAS_MORE,
    isCashiersHasMore
  );
export const setIsSearchCashiersHasMore = (isSearchCashiersHasMore) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_IS_SEARCH_CASHIERS_HAS_MORE,
    isSearchCashiersHasMore
  );

export const setFetchCashiersParams = (fetchCashiersParams) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_FETCH_CASHIERS_PARAMS,
    fetchCashiersParams
  );
export const setFetchCashiersLoading = (fetchCashiersLoading) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_FETCH_CASHIERS_LOADING,
    fetchCashiersLoading
  );
export const setFetchCashiersSuccess = (fetchCashiersSuccess) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_FETCH_CASHIERS_SUCCESS,
    fetchCashiersSuccess
  );
export const setFetchCashiersFailed = (fetchCashiersFailed) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_FETCH_CASHIERS_FAILED,
    fetchCashiersFailed
  );

export const setSearchCashiersParams = (searchCashiersParams) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_SEARCH_CASHIERS_PARAMS,
    searchCashiersParams
  );
export const setSearchCashiersLoading = (searchCashiersLoading) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_SEARCH_CASHIERS_LOADING,
    searchCashiersLoading
  );
export const setSearchCashiersSuccess = (searchCashiersSuccess) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_SEARCH_CASHIERS_SUCCESS,
    searchCashiersSuccess
  );
export const setSearchCashiersFailed = (searchCashiersFailed) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_SEARCH_CASHIERS_FAILED,
    searchCashiersFailed
  );

export const setAppendCashiersParams = (appendCashiersParams) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_APPEND_CASHIERS_PARAMS,
    appendCashiersParams
  );
export const setAppendCashiersLoading = (appendCashiersLoading) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_APPEND_CASHIERS_LOADING,
    appendCashiersLoading
  );
export const setAppendCashiersSuccess = (appendCashiersSuccess) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_APPEND_CASHIERS_SUCCESS,
    appendCashiersSuccess
  );
export const setAppendCashiersFailed = (appendCashiersFailed) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_APPEND_CASHIERS_FAILED,
    appendCashiersFailed
  );

export const setIsFetchCashiersHitted = (isFetchCashiersHitted) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_IS_FETCH_CASHIERS_HITTED,
    isFetchCashiersHitted
  );
export const setIsSearchCashiersHitted = (isSearchCashiersHitted) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_IS_SEARCH_CASHIERS_HITTED,
    isSearchCashiersHitted
  );
export const setIsAppendCashiersHitted = (isAppendCashiersHitted) =>
  createAction(
    SELECT_CASHIER_ACTION_TYPES.SET_IS_APPEND_CASHIERS_HITTED,
    isAppendCashiersHitted
  );

export const fetchCashiersStart = (params) =>
  createAction(SELECT_CASHIER_ACTION_TYPES.FETCH_CASHIERS_START, params);
export const searchCashiersStart = (params) =>
  createAction(SELECT_CASHIER_ACTION_TYPES.SEARCH_CASHIERS_START, params);
export const appendCashiersStart = (params) =>
  createAction(SELECT_CASHIER_ACTION_TYPES.APPEND_CASHIERS_START, params);

export const resetCashierReducer = () =>
  createAction(SELECT_CASHIER_ACTION_TYPES.RESET_SELECT_CASHIER_REDUCER);
