import { takeLatest, put, all, call } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getCustomers } from "../../api/customer.api";

import SELECT_CUSTOMER_ACTION_TYPES from "./select-customer.type";
import {
  appendCustomers,
  appendSearchCustomers,
  setAppendCustomersFailed,
  setAppendCustomersLoading,
  setAppendCustomersSuccess,
  setFetchCustomersFailed,
  setFetchCustomersLoading,
  setFetchCustomersSuccess,
  setIsAppendCustomersHitted,
  setIsFetchCustomersHitted,
  setIsCustomersHasMore,
  setIsSearchCustomersHasMore,
  setIsSearchCustomersHitted,
  setCustomers,
  setSearchCustomers,
  setSearchCustomersFailed,
  setSearchCustomersLoading,
  setSearchCustomersSuccess,
} from "./select-customer.action";

export function* _getFetchCustomers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setFetchCustomersLoading(true));

    const {
      meta: { message },
      data: customers,
    } = yield call(getCustomers, parameters);

    yield put(setIsFetchCustomersHitted(true));
    yield put(setIsCustomersHasMore(Object.keys(customers).length > 0));

    if (parameters.page > 1) {
      yield put(appendCustomers(customers));
    } else {
      yield put(setCustomers(customers));
    }

    yield put(setFetchCustomersSuccess(message));
    yield put(setFetchCustomersLoading(false));
  } catch (error) {
    yield put(setFetchCustomersFailed(error));
    yield put(setFetchCustomersLoading(false));
  }
}
export function* _getSearchCustomers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setSearchCustomersLoading(true));

    const {
      meta: { message },
      data: customers,
    } = yield call(getCustomers, parameters);

    yield put(setIsSearchCustomersHitted(true));
    yield put(setIsSearchCustomersHasMore(Object.keys(customers).length > 0));

    if (parameters.page > 1) {
      yield put(appendSearchCustomers(customers));
    } else {
      yield put(setSearchCustomers(customers));
    }

    yield put(setSearchCustomersSuccess(message));
    yield put(setSearchCustomersLoading(false));
  } catch (error) {
    yield put(setSearchCustomersFailed(error));
    yield put(setSearchCustomersLoading(false));
  }
}
export function* _getAppendCustomers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setAppendCustomersLoading(true));

    const {
      meta: { message },
      data: customers,
    } = yield call(getCustomers, parameters);

    yield put(setIsAppendCustomersHitted(true));
    yield put(appendCustomers(customers));

    yield put(setAppendCustomersSuccess(message));
    yield put(setAppendCustomersLoading(false));
  } catch (error) {
    yield put(setAppendCustomersFailed(error));
    yield put(setAppendCustomersLoading(false));
  }
}

export function* onFetchCustomersStart() {
  yield takeLatest(
    SELECT_CUSTOMER_ACTION_TYPES.FETCH_CUSTOMERS_START,
    _getFetchCustomers
  );
}
export function* onSearchCustomersStart() {
  yield takeLatest(
    SELECT_CUSTOMER_ACTION_TYPES.SEARCH_CUSTOMERS_START,
    _getSearchCustomers
  );
}
export function* onAppendCustomersStart() {
  yield takeLatest(
    SELECT_CUSTOMER_ACTION_TYPES.APPEND_CUSTOMERS_START,
    _getAppendCustomers
  );
}

export function* selectCustomerSaga() {
  yield all([
    call(onFetchCustomersStart),
    call(onSearchCustomersStart),
    call(onAppendCustomersStart),
  ]);
}
