import { createSelector } from "reselect";

const permissionSubscriberSelector = ({ permissionSubscriber }) =>
  permissionSubscriber;

export const getPermissions = createSelector(
  [permissionSubscriberSelector],
  ({ permissions }) => permissions
);
export const getSelectPermissions = createSelector(
  [permissionSubscriberSelector],
  ({ selectPermissions }) => selectPermissions
);
export const getPermission = createSelector(
  [permissionSubscriberSelector],
  ({ permission }) => permission
);

export const getIsPermissionsHasMore = createSelector(
  [permissionSubscriberSelector],
  ({ isPermissionsHasMore }) => isPermissionsHasMore
);
export const getIsSelectPermissionsHasMore = createSelector(
  [permissionSubscriberSelector],
  ({ isSelectPermissionsHasMore }) => isSelectPermissionsHasMore
);

export const getFetchPermissionsSearch = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionsSearch }) => fetchPermissionsSearch
);
export const getFetchPermissionsSort = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionsSort }) => fetchPermissionsSort
);
export const getFetchPermissionsKeyBy = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionsKeyBy }) => fetchPermissionsKeyBy
);
export const getFetchPermissionsPage = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionsPage }) => fetchPermissionsPage
);
export const getFetchPermissionsPerPage = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionsPerPage }) => fetchPermissionsPerPage
);
export const getFetchPermissionsIncludes = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionsIncludes }) => fetchPermissionsIncludes
);
export const getFetchPermissionsFilterMarketId = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionsFilterMarketId }) => fetchPermissionsFilterMarketId
);
export const getFetchPermissionsFilterIsDefault = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionsFilterIsDefault }) => fetchPermissionsFilterIsDefault
);
export const getFetchPermissionsLoading = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionsLoading }) => fetchPermissionsLoading
);
export const getFetchPermissionsSuccess = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionsSuccess }) => fetchPermissionsSuccess
);
export const getFetchPermissionsFailed = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionsFailed }) => fetchPermissionsFailed
);

export const getFetchSelectPermissionsSearch = createSelector(
  [permissionSubscriberSelector],
  ({ fetchSelectPermissionsSearch }) => fetchSelectPermissionsSearch
);
export const getFetchSelectPermissionsSort = createSelector(
  [permissionSubscriberSelector],
  ({ fetchSelectPermissionsSort }) => fetchSelectPermissionsSort
);
export const getFetchSelectPermissionsKeyBy = createSelector(
  [permissionSubscriberSelector],
  ({ fetchSelectPermissionsKeyBy }) => fetchSelectPermissionsKeyBy
);
export const getFetchSelectPermissionsPage = createSelector(
  [permissionSubscriberSelector],
  ({ fetchSelectPermissionsPage }) => fetchSelectPermissionsPage
);
export const getFetchSelectPermissionsPerPage = createSelector(
  [permissionSubscriberSelector],
  ({ fetchSelectPermissionsPerPage }) => fetchSelectPermissionsPerPage
);
export const getFetchSelectPermissionsIncludes = createSelector(
  [permissionSubscriberSelector],
  ({ fetchSelectPermissionsIncludes }) => fetchSelectPermissionsIncludes
);
export const getFetchSelectPermissionsFilterPermissionsIds = createSelector(
  [permissionSubscriberSelector],
  ({ fetchSelectPermissionsFilterPermissionsIds }) =>
    fetchSelectPermissionsFilterPermissionsIds
);
export const getFetchSelectPermissionsFilterMarketId = createSelector(
  [permissionSubscriberSelector],
  ({ fetchSelectPermissionsFilterMarketId }) =>
    fetchSelectPermissionsFilterMarketId
);
export const getFetchSelectPermissionsFilterIsDefault = createSelector(
  [permissionSubscriberSelector],
  ({ fetchSelectPermissionsFilterIsDefault }) =>
    fetchSelectPermissionsFilterIsDefault
);
export const getFetchSelectPermissionsLoading = createSelector(
  [permissionSubscriberSelector],
  ({ fetchSelectPermissionsLoading }) => fetchSelectPermissionsLoading
);
export const getFetchSelectPermissionsSuccess = createSelector(
  [permissionSubscriberSelector],
  ({ fetchSelectPermissionsSuccess }) => fetchSelectPermissionsSuccess
);
export const getFetchSelectPermissionsFailed = createSelector(
  [permissionSubscriberSelector],
  ({ fetchSelectPermissionsFailed }) => fetchSelectPermissionsFailed
);

export const getAppendSelectPermissionsSearch = createSelector(
  [permissionSubscriberSelector],
  ({ appendSelectPermissionsSearch }) => appendSelectPermissionsSearch
);
export const getAppendSelectPermissionsSort = createSelector(
  [permissionSubscriberSelector],
  ({ appendSelectPermissionsSort }) => appendSelectPermissionsSort
);
export const getAppendSelectPermissionsKeyBy = createSelector(
  [permissionSubscriberSelector],
  ({ appendSelectPermissionsKeyBy }) => appendSelectPermissionsKeyBy
);
export const getAppendSelectPermissionsPage = createSelector(
  [permissionSubscriberSelector],
  ({ appendSelectPermissionsPage }) => appendSelectPermissionsPage
);
export const getAppendSelectPermissionsPerPage = createSelector(
  [permissionSubscriberSelector],
  ({ appendSelectPermissionsPerPage }) => appendSelectPermissionsPerPage
);
export const getAppendSelectPermissionsIncludes = createSelector(
  [permissionSubscriberSelector],
  ({ appendSelectPermissionsIncludes }) => appendSelectPermissionsIncludes
);
export const getAppendSelectPermissionsFilterPermissionsIds = createSelector(
  [permissionSubscriberSelector],
  ({ appendSelectPermissionsFilterPermissionsIds }) =>
    appendSelectPermissionsFilterPermissionsIds
);
export const getAppendSelectPermissionsFilterMarketId = createSelector(
  [permissionSubscriberSelector],
  ({ appendSelectPermissionsFilterMarketId }) =>
    appendSelectPermissionsFilterMarketId
);
export const getAppendSelectPermissionsFilterIsDefault = createSelector(
  [permissionSubscriberSelector],
  ({ appendSelectPermissionsFilterIsDefault }) =>
    appendSelectPermissionsFilterIsDefault
);
export const getAppendSelectPermissionsLoading = createSelector(
  [permissionSubscriberSelector],
  ({ appendSelectPermissionsLoading }) => appendSelectPermissionsLoading
);
export const getAppendSelectPermissionsSuccess = createSelector(
  [permissionSubscriberSelector],
  ({ appendSelectPermissionsSuccess }) => appendSelectPermissionsSuccess
);
export const getAppendSelectPermissionsFailed = createSelector(
  [permissionSubscriberSelector],
  ({ appendSelectPermissionsFailed }) => appendSelectPermissionsFailed
);

export const getFetchPermissionLoading = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionLoading }) => fetchPermissionLoading
);
export const getFetchPermissionSuccess = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionSuccess }) => fetchPermissionSuccess
);
export const getFetchPermissionFailed = createSelector(
  [permissionSubscriberSelector],
  ({ fetchPermissionFailed }) => fetchPermissionFailed
);

export const getCreatePermissionLoading = createSelector(
  [permissionSubscriberSelector],
  ({ createPermissionLoading }) => createPermissionLoading
);
export const getCreatePermissionSuccess = createSelector(
  [permissionSubscriberSelector],
  ({ createPermissionSuccess }) => createPermissionSuccess
);
export const getCreatePermissionFailed = createSelector(
  [permissionSubscriberSelector],
  ({ createPermissionFailed }) => createPermissionFailed
);

export const getUpdatePermissionLoading = createSelector(
  [permissionSubscriberSelector],
  ({ updatePermissionLoading }) => updatePermissionLoading
);
export const getUpdatePermissionSuccess = createSelector(
  [permissionSubscriberSelector],
  ({ updatePermissionSuccess }) => updatePermissionSuccess
);
export const getUpdatePermissionFailed = createSelector(
  [permissionSubscriberSelector],
  ({ updatePermissionFailed }) => updatePermissionFailed
);

export const getDeletePermissionLoading = createSelector(
  [permissionSubscriberSelector],
  ({ deletePermissionLoading }) => deletePermissionLoading
);
export const getDeletePermissionSuccess = createSelector(
  [permissionSubscriberSelector],
  ({ deletePermissionSuccess }) => deletePermissionSuccess
);
export const getDeletePermissionFailed = createSelector(
  [permissionSubscriberSelector],
  ({ deletePermissionFailed }) => deletePermissionFailed
);

export const getIsFetchPermissionsHitted = createSelector(
  [permissionSubscriberSelector],
  ({ isFetchPermissionsHitted }) => isFetchPermissionsHitted
);
export const getIsFetchSelectPermissionsHitted = createSelector(
  [permissionSubscriberSelector],
  ({ isFetchSelectPermissionsHitted }) => isFetchSelectPermissionsHitted
);
export const getIsAppendSelectPermissionsHitted = createSelector(
  [permissionSubscriberSelector],
  ({ isAppendSelectPermissionsHitted }) => isAppendSelectPermissionsHitted
);
export const getIsFetchPermissionHitted = createSelector(
  [permissionSubscriberSelector],
  ({ isFetchPermissionHitted }) => isFetchPermissionHitted
);
export const getIsCreatePermissionHitted = createSelector(
  [permissionSubscriberSelector],
  ({ isCreatePermissionHitted }) => isCreatePermissionHitted
);
export const getIsUpdatePermissionHitted = createSelector(
  [permissionSubscriberSelector],
  ({ isUpdatePermissionHitted }) => isUpdatePermissionHitted
);
export const getIsDeletePermissionHitted = createSelector(
  [permissionSubscriberSelector],
  ({ isDeletePermissionHitted }) => isDeletePermissionHitted
);
