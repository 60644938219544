import { takeLatest, put, all, call, select } from "redux-saga/effects";

import DISCOUNT_ACTION_TYPES from "./discount.type";

import {
  appendDiscounts,
  appendOrderDiscounts,
  setCreateDiscount,
  setCreateDiscountFailed,
  setCreateDiscountLoading,
  setCreateDiscountSuccess,
  setDeleteDiscountFailed,
  setDeleteDiscountLoading,
  setDeleteDiscountSuccess,
  setDiscount,
  setDiscounts,
  setFetchDiscountFailed,
  setFetchDiscountLoading,
  setFetchDiscountsFailed,
  setFetchDiscountsLoading,
  setFetchDiscountsPage,
  setFetchDiscountsSuccess,
  setFetchDiscountSuccess,
  setFetchOrderDiscountsFailed,
  setFetchOrderDiscountsLoading,
  setFetchOrderDiscountsPage,
  setFetchOrderDiscountsSuccess,
  setIsCreateDiscountHitted,
  setIsDeleteDiscountHitted,
  setIsDiscountsHasMore,
  setIsFetchDiscountHitted,
  setIsFetchDiscountsHitted,
  setIsFetchOrderDiscountsHitted,
  setIsOrderDiscountsHasMore,
  setIsUpdateDiscountHitted,
  setIsVerifyCouponDiscountHitted,
  setIsVerifyLoyaProDiscountHitted,
  setOrderDiscounts,
  setUpdateDiscount,
  setUpdateDiscountFailed,
  setUpdateDiscountLoading,
  setUpdateDiscountSuccess,
  setVerifyCouponDiscount,
  setVerifyCouponDiscountFailed,
  setVerifyCouponDiscountLoading,
  setVerifyCouponDiscountSuccess,
  setVerifyLoyaProDiscount,
  setVerifyLoyaProDiscountFailed,
  setVerifyLoyaProDiscountLoading,
  setVerifyLoyaProDiscountSuccess,
} from "./discount.action";
import {
  getFetchDiscountsFilterBranchId,
  getFetchDiscountsFilterExpiredAt,
  getFetchDiscountsFilterMarketId,
  getFetchDiscountsFilterTypes,
  getFetchDiscountsIncludes,
  getFetchDiscountsPage,
  getFetchDiscountsPerPage,
  getFetchDiscountsSearch,
  getFetchOrderDiscountsFilterBranchId,
  getFetchOrderDiscountsFilterExpiredAt,
  getFetchOrderDiscountsFilterMarketId,
  getFetchOrderDiscountsFilterTypes,
  getFetchOrderDiscountsIncludes,
  getFetchOrderDiscountsPage,
  getFetchOrderDiscountsPerPage,
  getFetchOrderDiscountsSearch,
  getIsFetchDiscountsHitted,
  getIsFetchOrderDiscountsHitted,
} from "./discount.selector";

import {
  getDiscounts,
  getDiscount,
  createDiscount,
  updateDiscount,
  deleteDiscount,
  verifyCouponDiscount,
  verifyLoyaProDiscount,
} from "../../api/discount.api";

export function* _getDiscounts() {
  try {
    yield put(setFetchDiscountsLoading(true));

    const search = yield select(getFetchDiscountsSearch);
    const page = yield select(getFetchDiscountsPage);
    const per_page = yield select(getFetchDiscountsPerPage);
    const includes = yield select(getFetchDiscountsIncludes);
    const market_id = yield select(getFetchDiscountsFilterMarketId);
    const branch_id = yield select(getFetchDiscountsFilterBranchId);
    const types = yield select(getFetchDiscountsFilterTypes);
    const expired_at = yield select(getFetchDiscountsFilterExpiredAt);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        types,
        expired_at,
      },
    };

    const {
      meta: { message },
      data: { data: discounts },
    } = yield call(getDiscounts, parameters);

    yield put(setIsFetchDiscountsHitted(true));
    yield put(setIsDiscountsHasMore(discounts.length > 0));

    if (page > 1) {
      yield put(appendDiscounts(discounts));
    } else {
      yield put(setDiscounts(discounts));
    }

    yield put(setFetchDiscountsSuccess(message));
    yield put(setFetchDiscountsLoading(false));
  } catch (error) {
    yield put(setFetchDiscountsFailed(error));
    yield put(setFetchDiscountsLoading(false));
  }
}
export function* _getOrderDiscounts() {
  try {
    yield put(setFetchOrderDiscountsLoading(true));

    const search = yield select(getFetchOrderDiscountsSearch);
    const page = yield select(getFetchOrderDiscountsPage);
    const per_page = yield select(getFetchOrderDiscountsPerPage);
    const includes = yield select(getFetchOrderDiscountsIncludes);
    const market_id = yield select(getFetchOrderDiscountsFilterMarketId);
    const branch_id = yield select(getFetchOrderDiscountsFilterBranchId);
    const types = yield select(getFetchOrderDiscountsFilterTypes);
    const expired_at = yield select(getFetchOrderDiscountsFilterExpiredAt);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        types,
        expired_at,
      },
    };

    const {
      meta: { message },
      data: { data: discounts },
    } = yield call(getDiscounts, parameters);

    yield put(setIsFetchOrderDiscountsHitted(true));
    yield put(setIsOrderDiscountsHasMore(discounts.length > 0));

    if (page > 1) {
      yield put(appendOrderDiscounts(discounts));
    } else {
      yield put(setOrderDiscounts(discounts));
    }

    yield put(setFetchOrderDiscountsSuccess(message));
    yield put(setFetchOrderDiscountsLoading(false));
  } catch (error) {
    yield put(setFetchOrderDiscountsFailed(error));
    yield put(setFetchOrderDiscountsLoading(false));
  }
}
export function* _getDiscount({ payload: discountId }) {
  try {
    yield put(setFetchDiscountLoading(true));

    const {
      meta: { message },
      data: discount,
    } = yield call(getDiscount, discountId);

    yield put(setIsFetchDiscountHitted(true));
    yield put(setDiscount(discount));

    yield put(setFetchDiscountSuccess(message));
    yield put(setFetchDiscountLoading(false));
  } catch (error) {
    yield put(setFetchDiscountFailed(error));
    yield put(setFetchDiscountLoading(false));
  }
}
export function* _createDiscount({ payload: request }) {
  try {
    yield put(setCreateDiscountLoading(true));

    const {
      meta: { message },
      data: discount,
    } = yield call(createDiscount, request);

    yield put(setIsCreateDiscountHitted(true));
    yield put(setCreateDiscount(discount));

    const isFetchDiscountsHitted = yield select(getIsFetchDiscountsHitted);
    const isFetchOrderDiscountsHitted = yield select(
      getIsFetchOrderDiscountsHitted
    );

    if (isFetchDiscountsHitted) {
      yield put(setFetchDiscountsPage(1));
      yield call(_getDiscounts);
    }
    if (isFetchOrderDiscountsHitted) {
      yield put(setFetchOrderDiscountsPage(1));
      yield call(_getOrderDiscounts);
    }

    yield put(setCreateDiscountSuccess(message));
    yield put(setCreateDiscountLoading(false));
  } catch (error) {
    yield put(setCreateDiscountFailed(error));
    yield put(setCreateDiscountLoading(false));
  }
}
export function* _updateDiscount({ payload: { discountId, request } }) {
  try {
    yield put(setUpdateDiscountLoading(true));

    const {
      meta: { message },
      data: discount,
    } = yield call(updateDiscount, discountId, request);

    yield put(setIsUpdateDiscountHitted(true));
    yield put(setUpdateDiscount(discount));

    const isFetchDiscountsHitted = yield select(getIsFetchDiscountsHitted);
    const isFetchOrderDiscountsHitted = yield select(
      getIsFetchOrderDiscountsHitted
    );

    if (isFetchDiscountsHitted) {
      yield put(setFetchDiscountsPage(1));
      yield call(_getDiscounts);
    }
    if (isFetchOrderDiscountsHitted) {
      yield put(setFetchOrderDiscountsPage(1));
      yield call(_getOrderDiscounts);
    }

    yield put(setUpdateDiscountSuccess(message));
    yield put(setUpdateDiscountLoading(false));
  } catch (error) {
    yield put(setUpdateDiscountFailed(error));
    yield put(setUpdateDiscountLoading(false));
  }
}
export function* _deleteDiscount({ payload: discountId }) {
  try {
    yield put(setDeleteDiscountLoading(true));

    const {
      meta: { message },
    } = yield call(deleteDiscount, discountId);

    yield put(setIsDeleteDiscountHitted(true));

    const isFetchDiscountsHitted = yield select(getIsFetchDiscountsHitted);
    const isFetchOrderDiscountsHitted = yield select(
      getIsFetchOrderDiscountsHitted
    );

    if (isFetchDiscountsHitted) {
      yield put(setFetchDiscountsPage(1));
      yield call(_getDiscounts);
    }
    if (isFetchOrderDiscountsHitted) {
      yield put(setFetchOrderDiscountsPage(1));
      yield call(_getOrderDiscounts);
    }

    yield put(setDeleteDiscountSuccess(message));
    yield put(setDeleteDiscountLoading(false));
  } catch (error) {
    yield put(setDeleteDiscountFailed(error));
    yield put(setDeleteDiscountLoading(false));
  }
}
export function* _verifyCouponDiscount({ payload: request }) {
  try {
    yield put(setVerifyCouponDiscountLoading(true));

    const {
      meta: { message },
      data: discount,
    } = yield call(verifyCouponDiscount, request);

    yield put(setIsVerifyCouponDiscountHitted(true));
    yield put(setVerifyCouponDiscount(discount));

    yield put(setVerifyCouponDiscountSuccess(message));
    yield put(setVerifyCouponDiscountLoading(false));
  } catch (error) {
    yield put(setVerifyCouponDiscountFailed(error));
    yield put(setVerifyCouponDiscountLoading(false));
  }
}
export function* _verifyLoyaProDiscount({ payload: request }) {
  try {
    yield put(setVerifyLoyaProDiscountLoading(true));

    const {
      meta: { message },
      data: discount,
    } = yield call(verifyLoyaProDiscount, request);

    yield put(setIsVerifyLoyaProDiscountHitted(true));
    yield put(setVerifyLoyaProDiscount(discount));

    yield put(setVerifyLoyaProDiscountSuccess(message));
    yield put(setVerifyLoyaProDiscountLoading(false));
  } catch (error) {
    yield put(setVerifyLoyaProDiscountFailed(error));
    yield put(setVerifyLoyaProDiscountLoading(false));
  }
}

export function* onFetchDiscountsStart() {
  yield takeLatest(DISCOUNT_ACTION_TYPES.FETCH_DISCOUNTS_START, _getDiscounts);
}
export function* onFetchOrderDiscountsStart() {
  yield takeLatest(
    DISCOUNT_ACTION_TYPES.FETCH_ORDER_DISCOUNTS_START,
    _getOrderDiscounts
  );
}
export function* onFetchDiscountStart() {
  yield takeLatest(DISCOUNT_ACTION_TYPES.FETCH_DISCOUNT_START, _getDiscount);
}
export function* onCreateDiscountStart() {
  yield takeLatest(
    DISCOUNT_ACTION_TYPES.CREATE_DISCOUNT_START,
    _createDiscount
  );
}
export function* onUpdateDiscountStart() {
  yield takeLatest(
    DISCOUNT_ACTION_TYPES.UPDATE_DISCOUNT_START,
    _updateDiscount
  );
}
export function* onDeleteDiscountStart() {
  yield takeLatest(
    DISCOUNT_ACTION_TYPES.DELETE_DISCOUNT_START,
    _deleteDiscount
  );
}
export function* onVerifyCouponDiscountStart() {
  yield takeLatest(
    DISCOUNT_ACTION_TYPES.VERIFY_COUPON_DISCOUNT_START,
    _verifyCouponDiscount
  );
}
export function* onVerifyLoyaProDiscountStart() {
  yield takeLatest(
    DISCOUNT_ACTION_TYPES.VERIFY_LOYAPRO_DISCOUNT_START,
    _verifyLoyaProDiscount
  );
}

export function* discountSaga() {
  yield all([
    call(onFetchDiscountsStart),
    call(onFetchOrderDiscountsStart),
    call(onFetchDiscountStart),
    call(onCreateDiscountStart),
    call(onUpdateDiscountStart),
    call(onDeleteDiscountStart),
    call(onVerifyCouponDiscountStart),
    call(onVerifyLoyaProDiscountStart),
  ]);
}
