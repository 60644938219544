import { createAction } from "../../utils/store.utils";

import SELECT_BRANCH_ACTION_TYPES from "./select-branch.type";

export const setBranches = (branches) =>
  createAction(SELECT_BRANCH_ACTION_TYPES.SET_BRANCHES, branches);
export const setSearchBranches = (searchBranches) =>
  createAction(SELECT_BRANCH_ACTION_TYPES.SET_SEARCH_BRANCHES, searchBranches);

export const appendBranches = (branches) =>
  createAction(SELECT_BRANCH_ACTION_TYPES.APPEND_BRANCHES, branches);
export const appendSearchBranches = (searchBranches) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.APPEND_SEARCH_BRANCHES,
    searchBranches
  );

export const setIsBranchesHasMore = (isBranchesHasMore) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_IS_BRANCHES_HAS_MORE,
    isBranchesHasMore
  );
export const setIsSearchBranchesHasMore = (isSearchBranchesHasMore) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_IS_SEARCH_BRANCHES_HAS_MORE,
    isSearchBranchesHasMore
  );

export const setFetchBranchesParams = (fetchBranchesParams) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PARAMS,
    fetchBranchesParams
  );
export const setFetchBranchesLoading = (fetchBranchesLoading) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_LOADING,
    fetchBranchesLoading
  );
export const setFetchBranchesSuccess = (fetchBranchesSuccess) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SUCCESS,
    fetchBranchesSuccess
  );
export const setFetchBranchesFailed = (fetchBranchesFailed) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FAILED,
    fetchBranchesFailed
  );

export const setSearchBranchesParams = (searchBranchesParams) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_SEARCH_BRANCHES_PARAMS,
    searchBranchesParams
  );
export const setSearchBranchesLoading = (searchBranchesLoading) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_SEARCH_BRANCHES_LOADING,
    searchBranchesLoading
  );
export const setSearchBranchesSuccess = (searchBranchesSuccess) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_SEARCH_BRANCHES_SUCCESS,
    searchBranchesSuccess
  );
export const setSearchBranchesFailed = (searchBranchesFailed) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_SEARCH_BRANCHES_FAILED,
    searchBranchesFailed
  );

export const setAppendBranchesParams = (appendBranchesParams) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_APPEND_BRANCHES_PARAMS,
    appendBranchesParams
  );
export const setAppendBranchesLoading = (appendBranchesLoading) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_APPEND_BRANCHES_LOADING,
    appendBranchesLoading
  );
export const setAppendBranchesSuccess = (appendBranchesSuccess) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_APPEND_BRANCHES_SUCCESS,
    appendBranchesSuccess
  );
export const setAppendBranchesFailed = (appendBranchesFailed) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_APPEND_BRANCHES_FAILED,
    appendBranchesFailed
  );

export const setIsFetchBranchesHitted = (isFetchBranchesHitted) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCHES_HITTED,
    isFetchBranchesHitted
  );
export const setIsSearchBranchesHitted = (isSearchBranchesHitted) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_IS_SEARCH_BRANCHES_HITTED,
    isSearchBranchesHitted
  );
export const setIsAppendBranchesHitted = (isAppendBranchesHitted) =>
  createAction(
    SELECT_BRANCH_ACTION_TYPES.SET_IS_APPEND_BRANCHES_HITTED,
    isAppendBranchesHitted
  );

export const fetchBranchesStart = (params) =>
  createAction(SELECT_BRANCH_ACTION_TYPES.FETCH_BRANCHES_START, params);
export const searchBranchesStart = (params) =>
  createAction(SELECT_BRANCH_ACTION_TYPES.SEARCH_BRANCHES_START, params);
export const appendBranchesStart = (params) =>
  createAction(SELECT_BRANCH_ACTION_TYPES.APPEND_BRANCHES_START, params);

export const resetBranchReducer = () =>
  createAction(SELECT_BRANCH_ACTION_TYPES.RESET_SELECT_BRANCH_REDUCER);
