import { takeLatest, put, all, call, select } from "redux-saga/effects";

import ADMIN_ACTION_TYPES from "./admin.type";

import {
  appendAdmins,
  setAdmin,
  setAdmins,
  setCreateAdminFailed,
  setCreateAdminLoading,
  setCreateAdminSuccess,
  setDeleteAdminFailed,
  setDeleteAdminLoading,
  setDeleteAdminSuccess,
  setFetchAdminFailed,
  setFetchAdminLoading,
  setFetchAdminSuccess,
  setFetchAdminsFailed,
  setFetchAdminsLoading,
  setFetchAdminsPage,
  setFetchAdminsSuccess,
  setIsAdminsHasMore,
  setIsCreateAdminHitted,
  setIsDeleteAdminHitted,
  setIsFetchAdminHitted,
  setIsFetchAdminsHitted,
  setIsUpdateAdminHitted,
  setUpdateAdminFailed,
  setUpdateAdminLoading,
  setUpdateAdminSuccess,
} from "./admin.action";
import {
  getFetchAdminsFilterPermissionId,
  getFetchAdminsIncludes,
  getFetchAdminsPage,
  getFetchAdminsPerPage,
  getFetchAdminsSearch,
  getIsFetchAdminsHitted,
} from "./admin.selector";

import { createAdmin, deleteAdmin, getAdmin, getAdmins, updateAdmin } from "../../api/admin.api";

export function* _getAdmins() {
  try {
    yield put(setFetchAdminsLoading(true));

    const search = yield select(getFetchAdminsSearch);
    const page = yield select(getFetchAdminsPage);
    const per_page = yield select(getFetchAdminsPerPage);
    const includes = yield select(getFetchAdminsIncludes);
    const permission_id = yield select(getFetchAdminsFilterPermissionId);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: { permission_id },
    };

    const {
      meta: { message },
      data: { data: admins },
    } = yield call(getAdmins, parameters);

    yield put(setIsFetchAdminsHitted(true));
    yield put(setIsAdminsHasMore(admins.length > 0));

    if (page > 1) {
      yield put(appendAdmins(admins));
    } else {
      yield put(setAdmins(admins));
    }

    yield put(setFetchAdminsSuccess(message));
    yield put(setFetchAdminsLoading(false));
  } catch (error) {
    yield put(setFetchAdminsFailed(error));
    yield put(setFetchAdminsLoading(false));
  }
}

export function* _getAdmin({ payload: id }) {
  try {
    yield put(setFetchAdminLoading(true));

    const {
      meta: { message },
      data: admin,
    } = yield call(getAdmin, id);

    yield put(setIsFetchAdminHitted(true));
    yield put(setAdmin(admin));

    yield put(setFetchAdminSuccess(message));
    yield put(setFetchAdminLoading(false));
  } catch (error) {
    yield put(setFetchAdminFailed(error));
    yield put(setFetchAdminLoading(false));
  }
}

export function* _createAdmin({ payload: request }) {
  try {
    yield put(setCreateAdminLoading(true));

    const {
      meta: { message },
    } = yield call(createAdmin, request);

    yield put(setIsCreateAdminHitted(true));

    const isFetchAdminsHitted = yield select(getIsFetchAdminsHitted);

    if (isFetchAdminsHitted) {
      yield put(setFetchAdminsPage(1));
      yield call(_getAdmins);
    }

    yield put(setCreateAdminSuccess(message));
    yield put(setCreateAdminLoading(false));
  } catch (error) {
    yield put(setCreateAdminFailed(error));
    yield put(setCreateAdminLoading(false));
  }
}

export function* _updateAdmin({ payload: { id, request } }) {
  try {
    yield put(setUpdateAdminLoading(true));

    const {
      meta: { message },
    } = yield call(updateAdmin, id, request);

    yield put(setIsUpdateAdminHitted(true));

    const isFetchAdminsHitted = yield select(getIsFetchAdminsHitted);

    if (isFetchAdminsHitted) {
      yield put(setFetchAdminsPage(1));
      yield call(_getAdmins);
    }

    yield put(setUpdateAdminSuccess(message));
    yield put(setUpdateAdminLoading(false));
  } catch (error) {
    yield put(setUpdateAdminFailed(error));
    yield put(setUpdateAdminLoading(false));
  }
}

export function* _deleteAdmin({ payload: id }) {
  try {
    yield put(setDeleteAdminLoading(true));

    const {
      meta: { message },
    } = yield call(deleteAdmin, id);

    yield put(setIsDeleteAdminHitted(true));

    const isFetchAdminsHitted = yield select(getIsFetchAdminsHitted);

    if (isFetchAdminsHitted) {
      yield put(setFetchAdminsPage(1));
      yield call(_getAdmins);
    }

    yield put(setDeleteAdminSuccess(message));
    yield put(setDeleteAdminLoading(false));
  } catch (error) {
    yield put(setDeleteAdminFailed(error));
    yield put(setDeleteAdminLoading(false));
  }
}

export function* onFetchAdminsStart() {
  yield takeLatest(ADMIN_ACTION_TYPES.FETCH_ADMINS_START, _getAdmins);
}

export function* onFetchAdminStart() {
  yield takeLatest(ADMIN_ACTION_TYPES.FETCH_ADMIN_START, _getAdmin);
}

export function* onCreateAdminStart() {
  yield takeLatest(ADMIN_ACTION_TYPES.CREATE_ADMIN_START, _createAdmin);
}

export function* onUpdateAdminStart() {
  yield takeLatest(ADMIN_ACTION_TYPES.UPDATE_ADMIN_START, _updateAdmin);
}

export function* onDeleteAdminStart() {
  yield takeLatest(ADMIN_ACTION_TYPES.DELETE_ADMIN_START, _deleteAdmin);
}

export function* adminSaga() {
  yield all([
    call(onFetchAdminsStart),
    call(onFetchAdminStart),
    call(onCreateAdminStart),
    call(onUpdateAdminStart),
    call(onDeleteAdminStart),
  ]);
}
