import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PRINTER_TYPE_ACTION_TYPES from "./printer-type.type";

import {
  appendPrinterTypes,
  appendSelectPrinterTypes,
  setCreatePrinterTypeFailed,
  setCreatePrinterTypeLoading,
  setCreatePrinterTypeSuccess,
  setDeletePrinterTypeFailed,
  setDeletePrinterTypeLoading,
  setDeletePrinterTypeSuccess,
  setFetchPrinterTypeFailed,
  setFetchPrinterTypeLoading,
  setFetchPrinterTypeSuccess,
  setFetchPrinterTypesFailed,
  setFetchPrinterTypesLoading,
  setFetchPrinterTypesPage,
  setFetchPrinterTypesSuccess,
  setFetchSelectPrinterTypesFailed,
  setFetchSelectPrinterTypesLoading,
  setFetchSelectPrinterTypesPage,
  setFetchSelectPrinterTypesSuccess,
  setIsCreatePrinterTypeHitted,
  setIsDeletePrinterTypeHitted,
  setIsFetchPrinterTypeHitted,
  setIsFetchPrinterTypesHitted,
  setIsFetchSelectPrinterTypesHitted,
  setIsPrinterTypesHasMore,
  setIsSelectPrinterTypesHasMore,
  setIsUpdatePrinterTypeHitted,
  setPrinterType,
  setPrinterTypes,
  setSelectPrinterTypes,
  setUpdatePrinterTypeFailed,
  setUpdatePrinterTypeLoading,
  setUpdatePrinterTypeSuccess,
} from "./printer-type.action";
import {
  getFetchPrinterTypesPage,
  getFetchPrinterTypesPerPage,
  getFetchSelectPrinterTypesPage,
  getFetchSelectPrinterTypesPerPage,
  getIsFetchPrinterTypesHitted,
  getIsFetchSelectPrinterTypesHitted,
} from "./printer-type.selector";

import {
  createPrinterType,
  deletePrinterType,
  getPrinterType,
  getPrinterTypes,
  updatePrinterType,
} from "../../api/printer-type.api";

export function* _getPrinterTypes() {
  try {
    yield put(setFetchPrinterTypesLoading(true));

    const page = yield select(getFetchPrinterTypesPage);
    const per_page = yield select(getFetchPrinterTypesPerPage);

    const parameters = { page, per_page };

    const {
      meta: { message },
      data: { data: printerTypes },
    } = yield call(getPrinterTypes, parameters);

    yield put(setIsFetchPrinterTypesHitted(true));
    yield put(setIsPrinterTypesHasMore(printerTypes.length > 0));

    if (page > 1) {
      yield put(appendPrinterTypes(printerTypes));
    } else {
      yield put(setPrinterTypes(printerTypes));
    }

    yield put(setFetchPrinterTypesSuccess(message));
    yield put(setFetchPrinterTypesLoading(false));
  } catch (error) {
    yield put(setFetchPrinterTypesFailed(error));
    yield put(setFetchPrinterTypesLoading(false));
  }
}

export function* _getSelectPrinterTypes() {
  try {
    yield put(setFetchSelectPrinterTypesLoading(true));

    const page = yield select(getFetchSelectPrinterTypesPage);
    const per_page = yield select(getFetchSelectPrinterTypesPerPage);

    const parameters = { page, per_page };

    const {
      meta: { message },
      data: { data: printerTypes },
    } = yield call(getPrinterTypes, parameters);

    yield put(setIsFetchSelectPrinterTypesHitted(true));
    yield put(setIsSelectPrinterTypesHasMore(printerTypes.length > 0));

    if (page > 1) {
      yield put(appendSelectPrinterTypes(printerTypes));
    } else {
      yield put(setSelectPrinterTypes(printerTypes));
    }

    yield put(setFetchSelectPrinterTypesSuccess(message));
    yield put(setFetchSelectPrinterTypesLoading(false));
  } catch (error) {
    yield put(setFetchSelectPrinterTypesFailed(error));
    yield put(setFetchSelectPrinterTypesLoading(false));
  }
}

export function* _getPrinterType({ payload: id }) {
  try {
    yield put(setFetchPrinterTypeLoading(true));

    const {
      meta: { message },
      data: printer,
    } = yield call(getPrinterType, id);

    yield put(setIsFetchPrinterTypeHitted(true));
    yield put(setPrinterType(printer));

    yield put(setFetchPrinterTypeSuccess(message));
    yield put(setFetchPrinterTypeLoading(false));
  } catch (error) {
    yield put(setFetchPrinterTypeFailed(error));
    yield put(setFetchPrinterTypeLoading(false));
  }
}

export function* _createPrinterType({ payload: request }) {
  try {
    yield put(setCreatePrinterTypeLoading(true));

    const {
      meta: { message },
    } = yield call(createPrinterType, request);

    yield put(setIsCreatePrinterTypeHitted(true));

    const isFetchPrinterTypesHitted = yield select(getIsFetchPrinterTypesHitted);
    const isFetchSelectPrinterTypesHitted = yield select(getIsFetchSelectPrinterTypesHitted);

    if (isFetchPrinterTypesHitted) {
      yield put(setFetchPrinterTypesPage(1));
      yield call(_getPrinterTypes);
    }
    if (isFetchSelectPrinterTypesHitted) {
      yield put(setFetchSelectPrinterTypesPage(1));
      yield call(_getSelectPrinterTypes);
    }

    yield put(setCreatePrinterTypeSuccess(message));
    yield put(setCreatePrinterTypeLoading(false));
  } catch (error) {
    yield put(setCreatePrinterTypeFailed(error));
    yield put(setCreatePrinterTypeLoading(false));
  }
}

export function* _updatePrinterType({ payload: { id, request } }) {
  try {
    yield put(setUpdatePrinterTypeLoading(true));

    const {
      meta: { message },
    } = yield call(updatePrinterType, id, request);

    yield put(setIsUpdatePrinterTypeHitted(true));

    const isFetchPrinterTypesHitted = yield select(getIsFetchPrinterTypesHitted);
    const isFetchSelectPrinterTypesHitted = yield select(getIsFetchSelectPrinterTypesHitted);

    if (isFetchPrinterTypesHitted) {
      yield put(setFetchPrinterTypesPage(1));
      yield call(_getPrinterTypes);
    }
    if (isFetchSelectPrinterTypesHitted) {
      yield put(setFetchSelectPrinterTypesPage(1));
      yield call(_getSelectPrinterTypes);
    }

    yield put(setUpdatePrinterTypeSuccess(message));
    yield put(setUpdatePrinterTypeLoading(false));
  } catch (error) {
    yield put(setUpdatePrinterTypeFailed(error));
    yield put(setUpdatePrinterTypeLoading(false));
  }
}

export function* _deletePrinterType({ payload: id }) {
  try {
    yield put(setDeletePrinterTypeLoading(true));

    const {
      meta: { message },
    } = yield call(deletePrinterType, id);

    yield put(setIsDeletePrinterTypeHitted(true));

    const isFetchPrinterTypesHitted = yield select(getIsFetchPrinterTypesHitted);
    const isFetchSelectPrinterTypesHitted = yield select(getIsFetchSelectPrinterTypesHitted);

    if (isFetchPrinterTypesHitted) {
      yield put(setFetchPrinterTypesPage(1));
      yield call(_getPrinterTypes);
    }
    if (isFetchSelectPrinterTypesHitted) {
      yield put(setFetchSelectPrinterTypesPage(1));
      yield call(_getSelectPrinterTypes);
    }

    yield put(setDeletePrinterTypeSuccess(message));
    yield put(setDeletePrinterTypeLoading(false));
  } catch (error) {
    yield put(setDeletePrinterTypeFailed(error));
    yield put(setDeletePrinterTypeLoading(false));
  }
}

export function* onFetchPrinterTypesStart() {
  yield takeLatest(PRINTER_TYPE_ACTION_TYPES.FETCH_PRINTER_TYPES_START, _getPrinterTypes);
}
export function* onFetchSelectPrinterTypesStart() {
  yield takeLatest(PRINTER_TYPE_ACTION_TYPES.FETCH_SELECT_PRINTER_TYPES_START, _getSelectPrinterTypes);
}
export function* onFetchPrinterTypeStart() {
  yield takeLatest(PRINTER_TYPE_ACTION_TYPES.FETCH_PRINTER_TYPE_START, _getPrinterType);
}
export function* onCreatePrinterStart() {
  yield takeLatest(PRINTER_TYPE_ACTION_TYPES.CREATE_PRINTER_TYPE_START, _createPrinterType);
}
export function* onUpdatePrinterStart() {
  yield takeLatest(PRINTER_TYPE_ACTION_TYPES.UPDATE_PRINTER_TYPE_START, _updatePrinterType);
}
export function* onDeletePrinterStart() {
  yield takeLatest(PRINTER_TYPE_ACTION_TYPES.DELETE_PRINTER_TYPE_START, _deletePrinterType);
}

export function* printerTypeSaga() {
  yield all([
    call(onFetchPrinterTypesStart),
    call(onFetchSelectPrinterTypesStart),
    call(onFetchPrinterTypeStart),
    call(onCreatePrinterStart),
    call(onUpdatePrinterStart),
    call(onDeletePrinterStart),
  ]);
}
