import { createSelector } from "reselect";

const supplierSelector = ({ supplier }) => supplier;

export const getSuppliers = createSelector([supplierSelector], ({ suppliers }) => suppliers);

export const getSelectSuppliers = createSelector([supplierSelector], ({ selectSuppliers }) => selectSuppliers);

export const getSupplier = createSelector([supplierSelector], ({ supplier }) => supplier);

export const getIsSuppliersHasMore = createSelector([supplierSelector], ({ isSuppliersHasMore }) => isSuppliersHasMore);

export const getIsSelectSuppliersHasMore = createSelector(
  [supplierSelector],
  ({ isSelectSuppliersHasMore }) => isSelectSuppliersHasMore
);

export const getFetchSuppliersSearch = createSelector(
  [supplierSelector],
  ({ fetchSuppliersSearch }) => fetchSuppliersSearch
);

export const getFetchSuppliersPage = createSelector([supplierSelector], ({ fetchSuppliersPage }) => fetchSuppliersPage);

export const getFetchSuppliersPerPage = createSelector(
  [supplierSelector],
  ({ fetchSuppliersPerPage }) => fetchSuppliersPerPage
);

export const getFetchSuppliersIncludes = createSelector(
  [supplierSelector],
  ({ fetchSuppliersIncludes }) => fetchSuppliersIncludes
);

export const getFetchSuppliersFilterMarketId = createSelector(
  [supplierSelector],
  ({ fetchSuppliersFilterMarketId }) => fetchSuppliersFilterMarketId
);

export const getFetchSuppliersFilterBranchId = createSelector(
  [supplierSelector],
  ({ fetchSuppliersFilterBranchId }) => fetchSuppliersFilterBranchId
);

export const getFetchSuppliersFilterSuppliersIds = createSelector(
  [supplierSelector],
  ({ fetchSuppliersFilterSuppliersIds }) => fetchSuppliersFilterSuppliersIds
);

export const getFetchSuppliersLoading = createSelector(
  [supplierSelector],
  ({ fetchSuppliersLoading }) => fetchSuppliersLoading
);

export const getFetchSuppliersSuccess = createSelector(
  [supplierSelector],
  ({ fetchSuppliersSuccess }) => fetchSuppliersSuccess
);

export const getFetchSuppliersFailed = createSelector(
  [supplierSelector],
  ({ fetchSuppliersFailed }) => fetchSuppliersFailed
);

export const getFetchSelectSuppliersSearch = createSelector(
  [supplierSelector],
  ({ fetchSelectSuppliersSearch }) => fetchSelectSuppliersSearch
);

export const getFetchSelectSuppliersPage = createSelector(
  [supplierSelector],
  ({ fetchSelectSuppliersPage }) => fetchSelectSuppliersPage
);

export const getFetchSelectSuppliersPerPage = createSelector(
  [supplierSelector],
  ({ fetchSelectSuppliersPerPage }) => fetchSelectSuppliersPerPage
);

export const getFetchSelectSuppliersIncludes = createSelector(
  [supplierSelector],
  ({ fetchSelectSuppliersIncludes }) => fetchSelectSuppliersIncludes
);

export const getFetchSelectSuppliersFilterMarketId = createSelector(
  [supplierSelector],
  ({ fetchSelectSuppliersFilterMarketId }) => fetchSelectSuppliersFilterMarketId
);

export const getFetchSelectSuppliersFilterBranchId = createSelector(
  [supplierSelector],
  ({ fetchSelectSuppliersFilterBranchId }) => fetchSelectSuppliersFilterBranchId
);

export const getFetchSelectSuppliersFilterSuppliersIds = createSelector(
  [supplierSelector],
  ({ fetchSelectSuppliersFilterSuppliersIds }) => fetchSelectSuppliersFilterSuppliersIds
);

export const getFetchSelectSuppliersLoading = createSelector(
  [supplierSelector],
  ({ fetchSelectSuppliersLoading }) => fetchSelectSuppliersLoading
);

export const getFetchSelectSuppliersSuccess = createSelector(
  [supplierSelector],
  ({ fetchSelectSuppliersSuccess }) => fetchSelectSuppliersSuccess
);

export const getFetchSelectSuppliersFailed = createSelector(
  [supplierSelector],
  ({ fetchSelectSuppliersFailed }) => fetchSelectSuppliersFailed
);

export const getFetchSupplierLoading = createSelector(
  [supplierSelector],
  ({ fetchSupplierLoading }) => fetchSupplierLoading
);

export const getFetchSupplierSuccess = createSelector(
  [supplierSelector],
  ({ fetchSupplierSuccess }) => fetchSupplierSuccess
);

export const getFetchSupplierFailed = createSelector(
  [supplierSelector],
  ({ fetchSupplierFailed }) => fetchSupplierFailed
);

export const getCreateSupplierLoading = createSelector(
  [supplierSelector],
  ({ createSupplierLoading }) => createSupplierLoading
);

export const getCreateSupplierSuccess = createSelector(
  [supplierSelector],
  ({ createSupplierSuccess }) => createSupplierSuccess
);

export const getCreateSupplierFailed = createSelector(
  [supplierSelector],
  ({ createSupplierFailed }) => createSupplierFailed
);

export const getUpdateSupplierLoading = createSelector(
  [supplierSelector],
  ({ updateSupplierLoading }) => updateSupplierLoading
);

export const getUpdateSupplierSuccess = createSelector(
  [supplierSelector],
  ({ updateSupplierSuccess }) => updateSupplierSuccess
);

export const getUpdateSupplierFailed = createSelector(
  [supplierSelector],
  ({ updateSupplierFailed }) => updateSupplierFailed
);

export const getDeleteSupplierLoading = createSelector(
  [supplierSelector],
  ({ deleteSupplierLoading }) => deleteSupplierLoading
);

export const getDeleteSupplierSuccess = createSelector(
  [supplierSelector],
  ({ deleteSupplierSuccess }) => deleteSupplierSuccess
);

export const getDeleteSupplierFailed = createSelector(
  [supplierSelector],
  ({ deleteSupplierFailed }) => deleteSupplierFailed
);

export const getIsFetchSuppliersHitted = createSelector(
  [supplierSelector],
  ({ isFetchSuppliersHitted }) => isFetchSuppliersHitted
);

export const getIsFetchSelectSuppliersHitted = createSelector(
  [supplierSelector],
  ({ isFetchSelectSuppliersHitted }) => isFetchSelectSuppliersHitted
);

export const getIsFetchSupplierHitted = createSelector(
  [supplierSelector],
  ({ isFetchSupplierHitted }) => isFetchSupplierHitted
);

export const getIsCreateSupplierHitted = createSelector(
  [supplierSelector],
  ({ isCreateSupplierHitted }) => isCreateSupplierHitted
);

export const getIsUpdateSupplierHitted = createSelector(
  [supplierSelector],
  ({ isUpdateSupplierHitted }) => isUpdateSupplierHitted
);

export const getIsDeleteSupplierHitted = createSelector(
  [supplierSelector],
  ({ isDeleteSupplierHitted }) => isDeleteSupplierHitted
);
