import KITCHEN_ACTION_TYPES from "./kitchen.type";

export const KITCHEN_INITIAL_STATE = {
  firstProcessOrders: [],
  secondProcessOrders: [],
  thirdProcessOrders: [],

  processOrders: [],
  completedOrders: [],

  isProcessOrdersHasMore: true,
  isCompletedOrdersHasMore: true,

  fetchProcessOrdersSearch: null,
  fetchProcessOrdersSort: null,
  fetchProcessOrdersPage: 1,
  fetchProcessOrdersPerPage: null,
  fetchProcessOrdersIncludes: null,
  fetchProcessOrdersFilterPeriodType: null,
  fetchProcessOrdersFilterStartAt: null,
  fetchProcessOrdersFilterEndAt: null,
  fetchProcessOrdersFilterMarketId: null,
  fetchProcessOrdersFilterBranchId: null,
  fetchProcessOrdersFilterProductCategoriesIds: null,
  fetchProcessOrdersFilterSources: null,
  fetchProcessOrdersFilterTypes: null,
  fetchProcessOrdersFilterTableId: null,
  fetchProcessOrdersFilterCashierId: null,
  fetchProcessOrdersLoading: false,
  fetchProcessOrdersSuccess: null,
  fetchProcessOrdersFailed: null,

  fetchCompletedOrdersSearch: null,
  fetchCompletedOrdersSort: null,
  fetchCompletedOrdersPage: 1,
  fetchCompletedOrdersPerPage: null,
  fetchCompletedOrdersIncludes: null,
  fetchCompletedOrdersFilterPeriodType: null,
  fetchCompletedOrdersFilterStartAt: null,
  fetchCompletedOrdersFilterEndAt: null,
  fetchCompletedOrdersFilterMarketId: null,
  fetchCompletedOrdersFilterBranchId: null,
  fetchCompletedOrdersFilterProductCategoriesIds: null,
  fetchCompletedOrdersFilterSources: null,
  fetchCompletedOrdersFilterTypes: null,
  fetchCompletedOrdersFilterTableId: null,
  fetchCompletedOrdersFilterCashierId: null,
  fetchCompletedOrdersLoading: false,
  fetchCompletedOrdersSuccess: null,
  fetchCompletedOrdersFailed: null,

  isFetchProcessOrdersHitted: false,
  isFetchCompletedOrdersHitted: false,
};

export const kitchenReducer = (state = KITCHEN_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case KITCHEN_ACTION_TYPES.SET_FIRST_PROCESS_ORDERS:
      return { ...state, firstProcessOrders: payload };
    case KITCHEN_ACTION_TYPES.SET_SECOND_PROCESS_ORDERS:
      return { ...state, secondProcessOrders: payload };
    case KITCHEN_ACTION_TYPES.SET_THIRD_PROCESS_ORDERS:
      return { ...state, thirdProcessOrders: payload };

    case KITCHEN_ACTION_TYPES.SET_PROCESS_ORDERS:
      return { ...state, processOrders: payload };
    case KITCHEN_ACTION_TYPES.SET_COMPLETED_ORDERS:
      return { ...state, completedOrders: payload };

    case KITCHEN_ACTION_TYPES.SET_IS_PROCESS_ORDERS_HAS_MORE:
      return { ...state, isProcessOrdersHasMore: payload };
    case KITCHEN_ACTION_TYPES.SET_IS_COMPLETED_ORDERS_HAS_MORE:
      return { ...state, isCompletedOrdersHasMore: payload };

    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_SEARCH:
      return { ...state, fetchProcessOrdersSearch: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_SORT:
      return { ...state, fetchProcessOrdersSort: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_PAGE:
      return { ...state, fetchProcessOrdersPage: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_PER_PAGE:
      return { ...state, fetchProcessOrdersPerPage: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_INCLUDES:
      return { ...state, fetchProcessOrdersIncludes: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_PERIOD_TYPE:
      return { ...state, fetchProcessOrdersFilterPeriodType: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_START_AT:
      return { ...state, fetchProcessOrdersFilterStartAt: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_END_AT:
      return { ...state, fetchProcessOrdersFilterEndAt: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_MARKET_ID:
      return { ...state, fetchProcessOrdersFilterMarketId: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_BRANCH_ID:
      return { ...state, fetchProcessOrdersFilterBranchId: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
      return { ...state, fetchProcessOrdersFilterProductCategoriesIds: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_SOURCES:
      return { ...state, fetchProcessOrdersFilterSources: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_TYPES:
      return { ...state, fetchProcessOrdersFilterTypes: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_TABLE_ID:
      return { ...state, fetchProcessOrdersFilterTableId: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_CASHIER_ID:
      return { ...state, fetchProcessOrdersFilterCashierId: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_LOADING:
      return { ...state, fetchProcessOrdersLoading: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_SUCCESS:
      return { ...state, fetchProcessOrdersSuccess: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FAILED:
      return { ...state, fetchProcessOrdersFailed: payload };

    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_SEARCH:
      return { ...state, fetchCompletedOrdersSearch: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_SORT:
      return { ...state, fetchCompletedOrdersSort: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_PAGE:
      return { ...state, fetchCompletedOrdersPage: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_PER_PAGE:
      return { ...state, fetchCompletedOrdersPerPage: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_INCLUDES:
      return { ...state, fetchCompletedOrdersIncludes: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_PERIOD_TYPE:
      return { ...state, fetchCompletedOrdersFilterPeriodType: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_START_AT:
      return { ...state, fetchCompletedOrdersFilterStartAt: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_END_AT:
      return { ...state, fetchCompletedOrdersFilterEndAt: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_MARKET_ID:
      return { ...state, fetchCompletedOrdersFilterMarketId: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_BRANCH_ID:
      return { ...state, fetchCompletedOrdersFilterBranchId: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
      return { ...state, fetchCompletedOrdersFilterProductCategoriesIds: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_SOURCES:
      return { ...state, fetchCompletedOrdersFilterSources: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_TYPES:
      return { ...state, fetchCompletedOrdersFilterTypes: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_TABLE_ID:
      return { ...state, fetchCompletedOrdersFilterTableId: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_CASHIER_ID:
      return { ...state, fetchCompletedOrdersFilterCashierId: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_LOADING:
      return { ...state, fetchCompletedOrdersLoading: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_SUCCESS:
      return { ...state, fetchCompletedOrdersSuccess: payload };
    case KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FAILED:
      return { ...state, fetchCompletedOrdersFailed: payload };

    case KITCHEN_ACTION_TYPES.APPEND_FIRST_PROCESS_ORDERS:
      return { ...state, firstProcessOrders: [...state.firstProcessOrders, ...payload] };
    case KITCHEN_ACTION_TYPES.APPEND_SECOND_PROCESS_ORDERS:
      return { ...state, secondProcessOrders: [...state.secondProcessOrders, ...payload] };
    case KITCHEN_ACTION_TYPES.APPEND_THIRD_PROCESS_ORDERS:
      return { ...state, thirdProcessOrders: [...state.thirdProcessOrders, ...payload] };

    case KITCHEN_ACTION_TYPES.APPEND_PROCESS_ORDERS:
      return { ...state, processOrders: [...state.processOrders, ...payload] };
    case KITCHEN_ACTION_TYPES.APPEND_COMPLETED_ORDERS:
      return { ...state, completedOrders: [...state.completedOrders, ...payload] };

    case KITCHEN_ACTION_TYPES.REMOVE_FIRST_PROCESS_ORDER:
      return { ...state, firstProcessOrders: state.firstProcessOrders.filter(({ id }) => id !== payload) };
    case KITCHEN_ACTION_TYPES.REMOVE_SECOND_PROCESS_ORDER:
      return { ...state, secondProcessOrders: state.secondProcessOrders.filter(({ id }) => id !== payload) };
    case KITCHEN_ACTION_TYPES.REMOVE_THIRD_PROCESS_ORDER:
      return { ...state, thirdProcessOrders: state.thirdProcessOrders.filter(({ id }) => id !== payload) };

    case KITCHEN_ACTION_TYPES.SET_IS_FETCH_PROCESS_ORDERS_HITTED:
      return { ...state, isFetchProcessOrdersHitted: payload };
    case KITCHEN_ACTION_TYPES.SET_IS_FETCH_COMPLETED_ORDERS_HITTED:
      return { ...state, isFetchCompletedOrdersHitted: payload };

    case KITCHEN_ACTION_TYPES.RESET_KITCHEN_REDUCER:
      return KITCHEN_INITIAL_STATE;
    default:
      return state;
  }
};
