import SETTING_ACTION_TYPES from "./setting.type";

export const SETTING_INITIAL_STATE = {
  settings: {},
  setting: null,

  isSettingsHasMore: true,

  fetchSettingsSearch: null,
  fetchSettingsSort: null,
  fetchSettingsKeyBy: null,
  fetchSettingsPage: 1,
  fetchSettingsPerPage: null,
  fetchSettingsFilterKeys: null,
  fetchSettingsLoading: false,
  fetchSettingsSuccess: null,
  fetchSettingsFailed: null,

  fetchSettingLoading: false,
  fetchSettingSuccess: null,
  fetchSettingFailed: null,

  createOrUpdateSettingLoading: false,
  createOrUpdateSettingSuccess: null,
  createOrUpdateSettingFailed: null,

  isFetchSettingsHitted: false,
  isFetchSettingHitted: false,
  isCreateOrUpdateSettingHitted: false,
};

export const settingReducer = (state = SETTING_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SETTING_ACTION_TYPES.SET_SETTINGS:
      return { ...state, settings: payload };
    case SETTING_ACTION_TYPES.SET_SETTING:
      return { ...state, setting: payload };

    case SETTING_ACTION_TYPES.SET_IS_SETTINGS_HAS_MORE:
      return { ...state, isSettingsHasMore: payload };

    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_SEARCH:
      return { ...state, fetchSettingsSearch: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_SORT:
      return { ...state, fetchSettingsSort: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_KEY_BY:
      return { ...state, fetchSettingsKeyBy: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_PAGE:
      return { ...state, fetchSettingsPage: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_PER_PAGE:
      return { ...state, fetchSettingsPerPage: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_FILTER_KEYS:
      return { ...state, fetchSettingsFilterKeys: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_LOADING:
      return { ...state, fetchSettingsLoading: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_SUCCESS:
      return { ...state, fetchSettingsSuccess: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_FAILED:
      return { ...state, fetchSettingsFailed: payload };

    case SETTING_ACTION_TYPES.SET_FETCH_SETTING_LOADING:
      return { ...state, fetchSettingLoading: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTING_SUCCESS:
      return { ...state, fetchSettingSuccess: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTING_FAILED:
      return { ...state, fetchSettingFailed: payload };

    case SETTING_ACTION_TYPES.SET_CREATE_OR_UPDATE_SETTING_LOADING:
      return { ...state, createOrUpdateSettingLoading: payload };
    case SETTING_ACTION_TYPES.SET_CREATE_OR_UPDATE_SETTING_SUCCESS:
      return { ...state, createOrUpdateSettingSuccess: payload };
    case SETTING_ACTION_TYPES.SET_CREATE_OR_UPDATE_SETTING_FAILED:
      return { ...state, createOrUpdateSettingFailed: payload };

    case SETTING_ACTION_TYPES.APPEND_SETTINGS:
      return { ...state, settings: { ...state.settings, ...payload } };

    case SETTING_ACTION_TYPES.SET_IS_FETCH_SETTINGS_HITTED:
      return { ...state, isFetchSettingsHitted: payload };
    case SETTING_ACTION_TYPES.SET_IS_FETCH_SETTING_HITTED:
      return { ...state, isFetchSettingHitted: payload };
    case SETTING_ACTION_TYPES.SET_IS_CREATE_OR_UPDATE_SETTING_HITTED:
      return { ...state, isCreateOrUpdateSettingHitted: payload };

    case SETTING_ACTION_TYPES.RESET_SETTING_REDUCER:
      return SETTING_INITIAL_STATE;
    default:
      return state;
  }
};
