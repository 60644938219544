const PAYMENT_DEVICE_ACTION_TYPES = {
  RESET_PAYMENT_DEVICE_REDUCER: "payment-device/RESET_PAYMENT_DEVICE_REDUCER",

  SET_PAYMENT_DEVICES: "payment-device/SET_PAYMENT_DEVICES",
  SET_PAYMENT_DEVICE: "payment-device/SET_PAYMENT_DEVICE",

  SET_IS_PAYMENT_DEVICES_HAS_MORE: "payment-device/SET_IS_PAYMENT_DEVICES_HAS_MORE",

  SET_FETCH_PAYMENT_DEVICES_SEARCH: "payment-device/SET_FETCH_PAYMENT_DEVICES_SEARCH",
  SET_FETCH_PAYMENT_DEVICES_PAGE: "payment-device/SET_FETCH_PAYMENT_DEVICES_PAGE",
  SET_FETCH_PAYMENT_DEVICES_PER_PAGE: "payment-device/SET_FETCH_PAYMENT_DEVICES_PER_PAGE",
  SET_FETCH_PAYMENT_DEVICES_INCLUDES: "payment-device/SET_FETCH_PAYMENT_DEVICES_INCLUDES",
  SET_FETCH_PAYMENT_DEVICES_FILTER_MARKET_ID: "payment-device/SET_FETCH_PAYMENT_DEVICES_FILTER_MARKET_ID",
  SET_FETCH_PAYMENT_DEVICES_FILTER_BRANCH_ID: "payment-device/SET_FETCH_PAYMENT_DEVICES_FILTER_BRANCH_ID",
  SET_FETCH_PAYMENT_DEVICES_FILTER_PAYMENT_DEVICES_IDS:
    "payment-device/SET_FETCH_PAYMENT_DEVICES_FILTER_PAYMENT_DEVICES_IDS",
  SET_FETCH_PAYMENT_DEVICES_FILTER_USERS_IDS: "payment-device/SET_FETCH_PAYMENT_DEVICES_FILTER_USERS_IDS",
  SET_FETCH_PAYMENT_DEVICES_LOADING: "payment-device/SET_FETCH_PAYMENT_DEVICES_LOADING",
  SET_FETCH_PAYMENT_DEVICES_SUCCESS: "payment-device/SET_FETCH_PAYMENT_DEVICES_SUCCESS",
  SET_FETCH_PAYMENT_DEVICES_FAILED: "payment-device/SET_FETCH_PAYMENT_DEVICES_FAILED",

  SET_FETCH_PAYMENT_DEVICE_LOADING: "payment-device/SET_FETCH_PAYMENT_DEVICE_LOADING",
  SET_FETCH_PAYMENT_DEVICE_SUCCESS: "payment-device/SET_FETCH_PAYMENT_DEVICE_SUCCESS",
  SET_FETCH_PAYMENT_DEVICE_FAILED: "payment-device/SET_FETCH_PAYMENT_DEVICE_FAILED",

  SET_CREATE_PAYMENT_DEVICE_LOADING: "payment-device/SET_CREATE_PAYMENT_DEVICE_LOADING",
  SET_CREATE_PAYMENT_DEVICE_SUCCESS: "payment-device/SET_CREATE_PAYMENT_DEVICE_SUCCESS",
  SET_CREATE_PAYMENT_DEVICE_FAILED: "payment-device/SET_CREATE_PAYMENT_DEVICE_FAILED",

  SET_UPDATE_PAYMENT_DEVICE_LOADING: "payment-device/SET_UPDATE_PAYMENT_DEVICE_LOADING",
  SET_UPDATE_PAYMENT_DEVICE_SUCCESS: "payment-device/SET_UPDATE_PAYMENT_DEVICE_SUCCESS",
  SET_UPDATE_PAYMENT_DEVICE_FAILED: "payment-device/SET_UPDATE_PAYMENT_DEVICE_FAILED",

  SET_DELETE_PAYMENT_DEVICE_LOADING: "payment-device/SET_DELETE_PAYMENT_DEVICE_LOADING",
  SET_DELETE_PAYMENT_DEVICE_SUCCESS: "payment-device/SET_DELETE_PAYMENT_DEVICE_SUCCESS",
  SET_DELETE_PAYMENT_DEVICE_FAILED: "payment-device/SET_DELETE_PAYMENT_DEVICE_FAILED",

  APPEND_PAYMENT_DEVICES: "payment-device/APPEND_PAYMENT_DEVICES",

  SET_IS_FETCH_PAYMENT_DEVICES_HITTED: "payment-device/SET_IS_FETCH_PAYMENT_DEVICES_HITTED",
  SET_IS_FETCH_PAYMENT_DEVICE_HITTED: "payment-device/SET_IS_FETCH_PAYMENT_DEVICE_HITTED",
  SET_IS_CREATE_PAYMENT_DEVICE_HITTED: "payment-device/SET_IS_CREATE_PAYMENT_DEVICE_HITTED",
  SET_IS_UPDATE_PAYMENT_DEVICE_HITTED: "payment-device/SET_IS_UPDATE_PAYMENT_DEVICE_HITTED",
  SET_IS_DELETE_PAYMENT_DEVICE_HITTED: "payment-device/SET_IS_DELETE_PAYMENT_DEVICE_HITTED",

  FETCH_PAYMENT_DEVICES_START: "payment-device/FETCH_PAYMENT_DEVICES_START",
  FETCH_PAYMENT_DEVICE_START: "payment-device/FETCH_PAYMENT_DEVICE_START",
  CREATE_PAYMENT_DEVICE_START: "payment-device/CREATE_PAYMENT_DEVICE_START",
  UPDATE_PAYMENT_DEVICE_START: "payment-device/UPDATE_PAYMENT_DEVICE_START",
  DELETE_PAYMENT_DEVICE_START: "payment-device/DELETE_PAYMENT_DEVICE_START",
};

export default PAYMENT_DEVICE_ACTION_TYPES;
