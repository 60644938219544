import USER_CUSTOMER_ACTION_TYPES from "./user-customer.type";

export const USER_CUSTOMER_INITIAL_STATE = {
  userCustomers: [],
  searchUserCustomers: [],
  userCustomer: null,
  currentUserCustomer: null,
  createUserCustomer: null,
  updateUserCustomer: null,

  isUserCustomersHasMore: true,
  isSearchUserCustomersHasMore: true,

  fetchUserCustomersSearch: null,
  fetchUserCustomersSort: null,
  fetchUserCustomersKeyBy: null,
  fetchUserCustomersPage: 1,
  fetchUserCustomersPerPage: null,
  fetchUserCustomersIncludes: null,
  fetchUserCustomersFilterMarketId: null,
  fetchUserCustomersLoading: false,
  fetchUserCustomersSuccess: null,
  fetchUserCustomersFailed: null,

  fetchSearchUserCustomersSearch: null,
  fetchSearchUserCustomersSort: null,
  fetchSearchUserCustomersKeyBy: null,
  fetchSearchUserCustomersPage: 1,
  fetchSearchUserCustomersPerPage: null,
  fetchSearchUserCustomersIncludes: null,
  fetchSearchUserCustomersFilterMarketId: null,
  fetchSearchUserCustomersLoading: false,
  fetchSearchUserCustomersSuccess: null,
  fetchSearchUserCustomersFailed: null,

  fetchUserCustomerLoading: false,
  fetchUserCustomerSuccess: null,
  fetchUserCustomerFailed: null,

  createUserCustomerLoading: false,
  createUserCustomerSuccess: null,
  createUserCustomerFailed: null,

  updateUserCustomerLoading: false,
  updateUserCustomerSuccess: null,
  updateUserCustomerFailed: null,

  addMarketUserCustomerLoading: false,
  addMarketUserCustomerSuccess: null,
  addMarketUserCustomerFailed: null,

  removeMarketUserCustomerLoading: false,
  removeMarketUserCustomerSuccess: null,
  removeMarketUserCustomerFailed: null,

  isFetchUserCustomersHitted: false,
  isFetchSearchUserCustomersHitted: false,
  isFetchUserCustomerHitted: false,
  isCreateUserCustomerHitted: false,
  isUpdateUserCustomerHitted: false,
  isAddMarketUserCustomerHitted: false,
  isRemoveMarketUserCustomerHitted: false,
};

export const userCustomerReducer = (
  state = USER_CUSTOMER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case USER_CUSTOMER_ACTION_TYPES.SET_USER_CUSTOMERS:
      return { ...state, userCustomers: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_SEARCH_USER_CUSTOMERS:
      return { ...state, searchUserCustomers: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_USER_CUSTOMER:
      return { ...state, userCustomer: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_CURRENT_USER_CUSTOMER:
      return { ...state, currentUserCustomer: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_CREATE_USER_CUSTOMER:
      return { ...state, createUserCustomer: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_UPDATE_USER_CUSTOMER:
      return { ...state, updateUserCustomer: payload };

    case USER_CUSTOMER_ACTION_TYPES.SET_IS_USER_CUSTOMERS_HAS_MORE:
      return { ...state, isUserCustomersHasMore: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_IS_SEARCH_USER_CUSTOMERS_HAS_MORE:
      return { ...state, isSearchUserCustomersHasMore: payload };

    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_SEARCH:
      return { ...state, fetchUserCustomersSearch: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_SORT:
      return { ...state, fetchUserCustomersSort: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_KEY_BY:
      return { ...state, fetchUserCustomersKeyBy: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_PAGE:
      return { ...state, fetchUserCustomersPage: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_PER_PAGE:
      return { ...state, fetchUserCustomersPerPage: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_INCLUDES:
      return { ...state, fetchUserCustomersIncludes: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_FILTER_MARKET_ID:
      return { ...state, fetchUserCustomersFilterMarketId: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_LOADING:
      return { ...state, fetchUserCustomersLoading: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_SUCCESS:
      return { ...state, fetchUserCustomersSuccess: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_FAILED:
      return { ...state, fetchUserCustomersFailed: payload };

    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_USER_CUSTOMERS_SEARCH:
      return { ...state, fetchSearchUserCustomersSearch: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_USER_CUSTOMERS_SORT:
      return { ...state, fetchSearchUserCustomersSort: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_USER_CUSTOMERS_KEY_BY:
      return { ...state, fetchSearchUserCustomersKeyBy: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_USER_CUSTOMERS_PAGE:
      return { ...state, fetchSearchUserCustomersPage: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_USER_CUSTOMERS_PER_PAGE:
      return { ...state, fetchSearchUserCustomersPerPage: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_USER_CUSTOMERS_INCLUDES:
      return { ...state, fetchSearchUserCustomersIncludes: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_USER_CUSTOMERS_FILTER_MARKET_ID:
      return { ...state, fetchSearchUserCustomersFilterMarketId: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_USER_CUSTOMERS_LOADING:
      return { ...state, fetchSearchUserCustomersLoading: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_USER_CUSTOMERS_SUCCESS:
      return { ...state, fetchSearchUserCustomersSuccess: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_USER_CUSTOMERS_FAILED:
      return { ...state, fetchSearchUserCustomersFailed: payload };

    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMER_LOADING:
      return { ...state, fetchUserCustomerLoading: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMER_SUCCESS:
      return { ...state, fetchUserCustomerSuccess: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMER_FAILED:
      return { ...state, fetchUserCustomerFailed: payload };

    case USER_CUSTOMER_ACTION_TYPES.SET_CREATE_USER_CUSTOMER_LOADING:
      return { ...state, createUserCustomerLoading: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_CREATE_USER_CUSTOMER_SUCCESS:
      return { ...state, createUserCustomerSuccess: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_CREATE_USER_CUSTOMER_FAILED:
      return { ...state, createUserCustomerFailed: payload };

    case USER_CUSTOMER_ACTION_TYPES.SET_UPDATE_USER_CUSTOMER_LOADING:
      return { ...state, updateUserCustomerLoading: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_UPDATE_USER_CUSTOMER_SUCCESS:
      return { ...state, updateUserCustomerSuccess: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_UPDATE_USER_CUSTOMER_FAILED:
      return { ...state, updateUserCustomerFailed: payload };

    case USER_CUSTOMER_ACTION_TYPES.SET_ADD_MARKET_USER_CUSTOMER_LOADING:
      return { ...state, addMarketUserCustomerLoading: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_ADD_MARKET_USER_CUSTOMER_SUCCESS:
      return { ...state, addMarketUserCustomerSuccess: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_ADD_MARKET_USER_CUSTOMER_FAILED:
      return { ...state, addMarketUserCustomerFailed: payload };

    case USER_CUSTOMER_ACTION_TYPES.SET_REMOVE_MARKET_USER_CUSTOMER_LOADING:
      return { ...state, removeMarketUserCustomerLoading: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_REMOVE_MARKET_USER_CUSTOMER_SUCCESS:
      return { ...state, removeMarketUserCustomerSuccess: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_REMOVE_MARKET_USER_CUSTOMER_FAILED:
      return { ...state, removeMarketUserCustomerFailed: payload };

    case USER_CUSTOMER_ACTION_TYPES.APPEND_USER_CUSTOMERS:
      return { ...state, userCustomers: [...state.userCustomers, ...payload] };
    case USER_CUSTOMER_ACTION_TYPES.APPEND_SEARCH_USER_CUSTOMERS:
      return {
        ...state,
        searchUserCustomers: [...state.searchUserCustomers, ...payload],
      };

    case USER_CUSTOMER_ACTION_TYPES.SET_IS_FETCH_USER_CUSTOMERS_HITTED:
      return { ...state, isFetchUserCustomersHitted: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_IS_FETCH_SEARCH_USER_CUSTOMERS_HITTED:
      return { ...state, isFetchSearchUserCustomersHitted: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_IS_FETCH_USER_CUSTOMER_HITTED:
      return { ...state, isFetchUserCustomerHitted: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_IS_CREATE_USER_CUSTOMER_HITTED:
      return { ...state, isCreateUserCustomerHitted: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_IS_UPDATE_USER_CUSTOMER_HITTED:
      return { ...state, isUpdateUserCustomerHitted: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_IS_ADD_MARKET_USER_CUSTOMER_HITTED:
      return { ...state, isAddMarketUserCustomerHitted: payload };
    case USER_CUSTOMER_ACTION_TYPES.SET_IS_REMOVE_MARKET_USER_CUSTOMER_HITTED:
      return { ...state, isRemoveMarketUserCustomerHitted: payload };

    case USER_CUSTOMER_ACTION_TYPES.RESET_USER_CUSTOMER_REDUCER:
      return USER_CUSTOMER_INITIAL_STATE;
    default:
      return state;
  }
};
