import { createSelector } from "reselect";

const recipeSelector = ({ recipe }) => recipe;

export const getRecipes = createSelector([recipeSelector], ({ recipes }) => recipes);

export const getRecipe = createSelector([recipeSelector], ({ recipe }) => recipe);

export const getIsRecipesHasMore = createSelector([recipeSelector], ({ isRecipesHasMore }) => isRecipesHasMore);

export const getFetchRecipesSearch = createSelector([recipeSelector], ({ fetchRecipesSearch }) => fetchRecipesSearch);

export const getFetchRecipesPage = createSelector([recipeSelector], ({ fetchRecipesPage }) => fetchRecipesPage);

export const getFetchRecipesPerPage = createSelector(
  [recipeSelector],
  ({ fetchRecipesPerPage }) => fetchRecipesPerPage
);

export const getFetchRecipesIncludes = createSelector(
  [recipeSelector],
  ({ fetchRecipesIncludes }) => fetchRecipesIncludes
);

export const getFetchRecipesFilterMarketId = createSelector(
  [recipeSelector],
  ({ fetchRecipesFilterMarketId }) => fetchRecipesFilterMarketId
);

export const getFetchRecipesFilterBranchId = createSelector(
  [recipeSelector],
  ({ fetchRecipesFilterBranchId }) => fetchRecipesFilterBranchId
);

export const getFetchRecipesLoading = createSelector(
  [recipeSelector],
  ({ fetchRecipesLoading }) => fetchRecipesLoading
);

export const getFetchRecipesSuccess = createSelector(
  [recipeSelector],
  ({ fetchRecipesSuccess }) => fetchRecipesSuccess
);

export const getFetchRecipesFailed = createSelector([recipeSelector], ({ fetchRecipesFailed }) => fetchRecipesFailed);

export const getFetchRecipeLoading = createSelector([recipeSelector], ({ fetchRecipeLoading }) => fetchRecipeLoading);

export const getFetchRecipeSuccess = createSelector([recipeSelector], ({ fetchRecipeSuccess }) => fetchRecipeSuccess);

export const getFetchRecipeFailed = createSelector([recipeSelector], ({ fetchRecipeFailed }) => fetchRecipeFailed);

export const getCreateRecipeLoading = createSelector(
  [recipeSelector],
  ({ createRecipeLoading }) => createRecipeLoading
);

export const getCreateRecipeSuccess = createSelector(
  [recipeSelector],
  ({ createRecipeSuccess }) => createRecipeSuccess
);

export const getCreateRecipeFailed = createSelector([recipeSelector], ({ createRecipeFailed }) => createRecipeFailed);

export const getUpdateRecipeLoading = createSelector(
  [recipeSelector],
  ({ updateRecipeLoading }) => updateRecipeLoading
);

export const getUpdateRecipeSuccess = createSelector(
  [recipeSelector],
  ({ updateRecipeSuccess }) => updateRecipeSuccess
);

export const getUpdateRecipeFailed = createSelector([recipeSelector], ({ updateRecipeFailed }) => updateRecipeFailed);

export const getDeleteRecipeLoading = createSelector(
  [recipeSelector],
  ({ deleteRecipeLoading }) => deleteRecipeLoading
);

export const getDeleteRecipeSuccess = createSelector(
  [recipeSelector],
  ({ deleteRecipeSuccess }) => deleteRecipeSuccess
);

export const getDeleteRecipeFailed = createSelector([recipeSelector], ({ deleteRecipeFailed }) => deleteRecipeFailed);

export const getIsFetchRecipesHitted = createSelector(
  [recipeSelector],
  ({ isFetchRecipesHitted }) => isFetchRecipesHitted
);

export const getIsFetchRecipeHitted = createSelector(
  [recipeSelector],
  ({ isFetchRecipeHitted }) => isFetchRecipeHitted
);

export const getIsCreateRecipeHitted = createSelector(
  [recipeSelector],
  ({ isCreateRecipeHitted }) => isCreateRecipeHitted
);

export const getIsUpdateRecipeHitted = createSelector(
  [recipeSelector],
  ({ isUpdateRecipeHitted }) => isUpdateRecipeHitted
);

export const getIsDeleteRecipeHitted = createSelector(
  [recipeSelector],
  ({ isDeleteRecipeHitted }) => isDeleteRecipeHitted
);
