import { ATTENDANCE_REPORTS } from "../../constants/attendance.constant";

import STATE_ATTENDANCE_ACTION_TYPES from "./state-attendance.type";

export const STATE_ATTENDANCE_INITIAL_STATE = {
  attendanceReports: {
    [ATTENDANCE_REPORTS.CASHIER]: {
      label: "Report Cashier",
      value: ATTENDANCE_REPORTS.CASHIER,
    },
    [ATTENDANCE_REPORTS.BRANCH]: {
      label: "Report Branch",
      value: ATTENDANCE_REPORTS.BRANCH,
    },
  },
  attendanceReport: ATTENDANCE_REPORTS.CASHIER,
};

export const stateAttendanceReducer = (
  state = STATE_ATTENDANCE_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case STATE_ATTENDANCE_ACTION_TYPES.SET_ATTENDANCE_REPORT:
      return { ...state, attendanceReport: payload };

    case STATE_ATTENDANCE_ACTION_TYPES.RESET_STATE_ATTENDANCE_REDUCER:
      return STATE_ATTENDANCE_INITIAL_STATE;
    default:
      return state;
  }
};
