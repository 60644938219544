import { takeLatest, put, all, call, select } from "redux-saga/effects";

import INGREDIENT_HISTORY_ACTION_TYPES from "./ingredient-history.type";

import {
  appendIngredientHistories,
  setFetchIngredientHistoriesFailed,
  setFetchIngredientHistoriesLoading,
  setFetchIngredientHistoriesSuccess,
  setFetchIngredientHistoryFailed,
  setFetchIngredientHistoryLoading,
  setFetchIngredientHistorySuccess,
  setIngredientHistories,
  setIngredientHistory,
  setIsFetchIngredientHistoriesHitted,
  setIsFetchIngredientHistoryHitted,
  setIsIngredientHistoriesHasMore,
} from "./ingredient-history.action";
import {
  getFetchIngredientHistoriesPage,
  getFetchIngredientHistoriesPerPage,
  getFetchIngredientHistoriesSearch,
  getFetchIngredientHistoriesSort,
} from "./ingredient-history.selector";

import { getIngredientHistories, getIngredientHistory } from "../../api/ingredient-history.api";

export function* _getIngredientHistories({ payload: ingredientId }) {
  try {
    yield put(setFetchIngredientHistoriesLoading(true));

    const search = yield select(getFetchIngredientHistoriesSearch);
    const sort = yield select(getFetchIngredientHistoriesSort);
    const page = yield select(getFetchIngredientHistoriesPage);
    const per_page = yield select(getFetchIngredientHistoriesPerPage);

    const parameters = { search, sort, page, per_page };

    const {
      meta: { message },
      data: { data: ingredientHistories },
    } = yield call(getIngredientHistories, ingredientId, parameters);

    yield put(setIsFetchIngredientHistoriesHitted(true));
    yield put(setIsIngredientHistoriesHasMore(ingredientHistories.length > 0));

    if (page > 1) {
      yield put(appendIngredientHistories(ingredientHistories));
    } else {
      yield put(setIngredientHistories(ingredientHistories));
    }

    yield put(setFetchIngredientHistoriesSuccess(message));
    yield put(setFetchIngredientHistoriesLoading(false));
  } catch (error) {
    yield put(setFetchIngredientHistoriesFailed(error));
    yield put(setFetchIngredientHistoriesLoading(false));
  }
}

export function* _getIngredientHistory({ payload: { ingredientId, ingredientHistoryId } }) {
  try {
    yield put(setFetchIngredientHistoryLoading(true));

    const {
      meta: { message },
      data: ingredientHistory,
    } = yield call(getIngredientHistory, ingredientId, ingredientHistoryId);

    yield put(setIsFetchIngredientHistoryHitted(true));
    yield put(setIngredientHistory(ingredientHistory));

    yield put(setFetchIngredientHistorySuccess(message));
    yield put(setFetchIngredientHistoryLoading(false));
  } catch (error) {
    yield put(setFetchIngredientHistoryFailed(error));
    yield put(setFetchIngredientHistoryLoading(false));
  }
}

export function* onFetchIngredientHistoriesStart() {
  yield takeLatest(INGREDIENT_HISTORY_ACTION_TYPES.FETCH_INGREDIENT_HISTORIES_START, _getIngredientHistories);
}

export function* onFetchIngredientHistoryStart() {
  yield takeLatest(INGREDIENT_HISTORY_ACTION_TYPES.FETCH_INGREDIENT_HISTORY_START, _getIngredientHistory);
}

export function* ingredientHistorySaga() {
  yield all([call(onFetchIngredientHistoriesStart), call(onFetchIngredientHistoryStart)]);
}
