import { takeLatest, put, all, call, select } from "redux-saga/effects";

import MERCHANT_ACTION_TYPES from "./merchant.type";

import {
  appendMerchants,
  setMerchant,
  setMerchants,
  setCreateMerchantFailed,
  setCreateMerchantLoading,
  setCreateMerchantSuccess,
  setDeleteMerchantFailed,
  setDeleteMerchantLoading,
  setDeleteMerchantSuccess,
  setFetchMerchantFailed,
  setFetchMerchantLoading,
  setFetchMerchantSuccess,
  setFetchMerchantsFailed,
  setFetchMerchantsLoading,
  setFetchMerchantsPage,
  setFetchMerchantsSuccess,
  setIsMerchantsHasMore,
  setIsCreateMerchantHitted,
  setIsDeleteMerchantHitted,
  setIsFetchMerchantHitted,
  setIsFetchMerchantsHitted,
  setIsUpdateMerchantHitted,
  setUpdateMerchantFailed,
  setUpdateMerchantLoading,
  setUpdateMerchantSuccess,
  setIsFetchSelectMerchantsHitted,
  setFetchSelectMerchantsSuccess,
  setFetchSelectMerchantsLoading,
  setFetchSelectMerchantsFailed,
  appendSelectMerchants,
  setSelectMerchants,
  setIsSelectMerchantsHasMore,
  setAppendSelectMerchantsLoading,
  setIsAppendSelectMerchantsHitted,
  setAppendSelectMerchantsSuccess,
  setAppendSelectMerchantsFailed,
  setFetchSelectMerchantsPage,
} from "./merchant.action";
import {
  getAppendSelectMerchantsFilterMerchantsIds,
  getAppendSelectMerchantsIncludes,
  getAppendSelectMerchantsKeyBy,
  getAppendSelectMerchantsPage,
  getAppendSelectMerchantsPerPage,
  getAppendSelectMerchantsSearch,
  getAppendSelectMerchantsSort,
  getFetchMerchantsFilterMerchantsIds,
  getFetchMerchantsIncludes,
  getFetchMerchantsKeyBy,
  getFetchMerchantsPage,
  getFetchMerchantsPerPage,
  getFetchMerchantsSearch,
  getFetchMerchantsSort,
  getFetchSelectMerchantsFilterMerchantsIds,
  getFetchSelectMerchantsIncludes,
  getFetchSelectMerchantsKeyBy,
  getFetchSelectMerchantsPage,
  getFetchSelectMerchantsPerPage,
  getFetchSelectMerchantsSearch,
  getFetchSelectMerchantsSort,
  getIsFetchMerchantsHitted,
  getIsFetchSelectMerchantsHitted,
} from "./merchant.selector";

import {
  createMerchant,
  deleteMerchant,
  getMerchant,
  getMerchants,
  updateMerchant,
} from "../../api/merchant.api";

export function* _getMerchants() {
  try {
    yield put(setFetchMerchantsLoading(true));

    const search = yield select(getFetchMerchantsSearch);
    const sort = yield select(getFetchMerchantsSort);
    const key_by = yield select(getFetchMerchantsKeyBy);
    const page = yield select(getFetchMerchantsPage);
    const per_page = yield select(getFetchMerchantsPerPage);
    const includes = yield select(getFetchMerchantsIncludes);
    const merchants_ids = yield select(getFetchMerchantsFilterMerchantsIds);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { merchants_ids },
    };

    const {
      meta: { message },
      data: { data: merchants },
    } = yield call(getMerchants, parameters);

    yield put(setIsFetchMerchantsHitted(true));
    yield put(setIsMerchantsHasMore(merchants.length > 0));

    if (page > 1) {
      yield put(appendMerchants(merchants));
    } else {
      yield put(setMerchants(merchants));
    }

    yield put(setFetchMerchantsSuccess(message));
    yield put(setFetchMerchantsLoading(false));
  } catch (error) {
    yield put(setFetchMerchantsFailed(error));
    yield put(setFetchMerchantsLoading(false));
  }
}
export function* _getSelectMerchants() {
  try {
    yield put(setFetchSelectMerchantsLoading(true));

    const search = yield select(getFetchSelectMerchantsSearch);
    const sort = yield select(getFetchSelectMerchantsSort);
    const key_by = yield select(getFetchSelectMerchantsKeyBy);
    const page = yield select(getFetchSelectMerchantsPage);
    const per_page = yield select(getFetchSelectMerchantsPerPage);
    const includes = yield select(getFetchSelectMerchantsIncludes);
    const merchants_ids = yield select(
      getFetchSelectMerchantsFilterMerchantsIds
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { merchants_ids },
    };

    const {
      meta: { message },
      data: merchants,
    } = yield call(getMerchants, parameters);

    yield put(setIsFetchSelectMerchantsHitted(true));
    yield put(setIsSelectMerchantsHasMore(Object.keys(merchants).length > 0));

    if (page > 1) {
      yield put(appendSelectMerchants(merchants));
    } else {
      yield put(setSelectMerchants(merchants));
    }

    yield put(setFetchSelectMerchantsSuccess(message));
    yield put(setFetchSelectMerchantsLoading(false));
  } catch (error) {
    yield put(setFetchSelectMerchantsFailed(error));
    yield put(setFetchSelectMerchantsLoading(false));
  }
}
export function* _getAppendSelectMerchants() {
  try {
    yield put(setAppendSelectMerchantsLoading(true));

    const search = yield select(getAppendSelectMerchantsSearch);
    const sort = yield select(getAppendSelectMerchantsSort);
    const key_by = yield select(getAppendSelectMerchantsKeyBy);
    const page = yield select(getAppendSelectMerchantsPage);
    const per_page = yield select(getAppendSelectMerchantsPerPage);
    const includes = yield select(getAppendSelectMerchantsIncludes);
    const merchants_ids = yield select(
      getAppendSelectMerchantsFilterMerchantsIds
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { merchants_ids },
    };

    const {
      meta: { message },
      data: merchants,
    } = yield call(getMerchants, parameters);

    yield put(setIsAppendSelectMerchantsHitted(true));
    yield put(appendSelectMerchants(merchants));

    yield put(setAppendSelectMerchantsSuccess(message));
    yield put(setAppendSelectMerchantsLoading(false));
  } catch (error) {
    yield put(setAppendSelectMerchantsFailed(error));
    yield put(setAppendSelectMerchantsLoading(false));
  }
}
export function* _getMerchant({ payload: merchantId }) {
  try {
    yield put(setFetchMerchantLoading(true));

    const {
      meta: { message },
      data: merchant,
    } = yield call(getMerchant, merchantId);

    yield put(setIsFetchMerchantHitted(true));
    yield put(setMerchant(merchant));

    yield put(setFetchMerchantSuccess(message));
    yield put(setFetchMerchantLoading(false));
  } catch (error) {
    yield put(setFetchMerchantFailed(error));
    yield put(setFetchMerchantLoading(false));
  }
}
export function* _createMerchant({ payload: request }) {
  try {
    yield put(setCreateMerchantLoading(true));

    const {
      meta: { message },
    } = yield call(createMerchant, request);

    yield put(setIsCreateMerchantHitted(true));

    const isFetchMerchantsHitted = yield select(getIsFetchMerchantsHitted);
    const isFetchSelectMerchantsHitted = yield select(
      getIsFetchSelectMerchantsHitted
    );

    if (isFetchMerchantsHitted) {
      yield put(setFetchMerchantsPage(1));
      yield call(_getMerchants);
    }
    if (isFetchSelectMerchantsHitted) {
      yield put(setFetchSelectMerchantsPage(1));
      yield call(_getSelectMerchants);
    }

    yield put(setCreateMerchantSuccess(message));
    yield put(setCreateMerchantLoading(false));
  } catch (error) {
    yield put(setCreateMerchantFailed(error));
    yield put(setCreateMerchantLoading(false));
  }
}
export function* _updateMerchant({ payload: { merchantId, request } }) {
  try {
    yield put(setUpdateMerchantLoading(true));

    const {
      meta: { message },
    } = yield call(updateMerchant, merchantId, request);

    yield put(setIsUpdateMerchantHitted(true));

    const isFetchMerchantsHitted = yield select(getIsFetchMerchantsHitted);
    const isFetchSelectMerchantsHitted = yield select(
      getIsFetchSelectMerchantsHitted
    );

    if (isFetchMerchantsHitted) {
      yield put(setFetchMerchantsPage(1));
      yield call(_getMerchants);
    }
    if (isFetchSelectMerchantsHitted) {
      yield put(setFetchSelectMerchantsPage(1));
      yield call(_getSelectMerchants);
    }

    yield put(setUpdateMerchantSuccess(message));
    yield put(setUpdateMerchantLoading(false));
  } catch (error) {
    yield put(setUpdateMerchantFailed(error));
    yield put(setUpdateMerchantLoading(false));
  }
}
export function* _deleteMerchant({ payload: merchantId }) {
  try {
    yield put(setDeleteMerchantLoading(true));

    const {
      meta: { message },
    } = yield call(deleteMerchant, merchantId);

    yield put(setIsDeleteMerchantHitted(true));

    const isFetchMerchantsHitted = yield select(getIsFetchMerchantsHitted);
    const isFetchSelectMerchantsHitted = yield select(
      getIsFetchSelectMerchantsHitted
    );

    if (isFetchMerchantsHitted) {
      yield put(setFetchMerchantsPage(1));
      yield call(_getMerchants);
    }
    if (isFetchSelectMerchantsHitted) {
      yield put(setFetchSelectMerchantsPage(1));
      yield call(_getSelectMerchants);
    }

    yield put(setDeleteMerchantSuccess(message));
    yield put(setDeleteMerchantLoading(false));
  } catch (error) {
    yield put(setDeleteMerchantFailed(error));
    yield put(setDeleteMerchantLoading(false));
  }
}

export function* onFetchMerchantsStart() {
  yield takeLatest(MERCHANT_ACTION_TYPES.FETCH_MERCHANTS_START, _getMerchants);
}
export function* onFetchSelectMerchantsStart() {
  yield takeLatest(
    MERCHANT_ACTION_TYPES.FETCH_SELECT_MERCHANTS_START,
    _getSelectMerchants
  );
}
export function* onAppendSelectMerchantsStart() {
  yield takeLatest(
    MERCHANT_ACTION_TYPES.APPEND_SELECT_MERCHANTS_START,
    _getAppendSelectMerchants
  );
}
export function* onFetchMerchantStart() {
  yield takeLatest(MERCHANT_ACTION_TYPES.FETCH_MERCHANT_START, _getMerchant);
}
export function* onCreateMerchantStart() {
  yield takeLatest(
    MERCHANT_ACTION_TYPES.CREATE_MERCHANT_START,
    _createMerchant
  );
}
export function* onUpdateMerchantStart() {
  yield takeLatest(
    MERCHANT_ACTION_TYPES.UPDATE_MERCHANT_START,
    _updateMerchant
  );
}
export function* onDeleteMerchantStart() {
  yield takeLatest(
    MERCHANT_ACTION_TYPES.DELETE_MERCHANT_START,
    _deleteMerchant
  );
}

export function* merchantSaga() {
  yield all([
    call(onFetchMerchantsStart),
    call(onFetchSelectMerchantsStart),
    call(onAppendSelectMerchantsStart),
    call(onFetchMerchantStart),
    call(onCreateMerchantStart),
    call(onUpdateMerchantStart),
    call(onDeleteMerchantStart),
  ]);
}
