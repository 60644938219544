import USER_MERCHANT_ACTION_TYPES from "./user-merchant.type";

export const USER_MERCHANT_INITIAL_STATE = {
  userMerchants: [],
  selectUserMerchants: {},
  userMerchant: null,

  isUserMerchantsHasMore: true,
  isSelectUserMerchantsHasMore: true,

  fetchUserMerchantsSearch: null,
  fetchUserMerchantsSort: null,
  fetchUserMerchantsKeyBy: null,
  fetchUserMerchantsPage: 1,
  fetchUserMerchantsPerPage: null,
  fetchUserMerchantsIncludes: null,
  fetchUserMerchantsFilterMerchantId: null,
  fetchUserMerchantsFilterPermissionMerchantId: null,
  fetchUserMerchantsFilterPermissionSubscriberId: null,
  fetchUserMerchantsFilterMarketId: null,
  fetchUserMerchantsFilterUsersIds: null,
  fetchUserMerchantsLoading: false,
  fetchUserMerchantsSuccess: null,
  fetchUserMerchantsFailed: null,

  fetchSelectUserMerchantsSearch: null,
  fetchSelectUserMerchantsSort: null,
  fetchSelectUserMerchantsKeyBy: null,
  fetchSelectUserMerchantsPage: 1,
  fetchSelectUserMerchantsPerPage: null,
  fetchSelectUserMerchantsIncludes: null,
  fetchSelectUserMerchantsFilterMerchantId: null,
  fetchSelectUserMerchantsFilterPermissionMerchantId: null,
  fetchSelectUserMerchantsFilterPermissionSubscriberId: null,
  fetchSelectUserMerchantsFilterMarketId: null,
  fetchSelectUserMerchantsFilterUsersIds: null,
  fetchSelectUserMerchantsLoading: false,
  fetchSelectUserMerchantsSuccess: null,
  fetchSelectUserMerchantsFailed: null,

  appendSelectUserMerchantsSearch: null,
  appendSelectUserMerchantsSort: null,
  appendSelectUserMerchantsKeyBy: null,
  appendSelectUserMerchantsPage: 1,
  appendSelectUserMerchantsPerPage: null,
  appendSelectUserMerchantsIncludes: null,
  appendSelectUserMerchantsFilterMerchantId: null,
  appendSelectUserMerchantsFilterPermissionMerchantId: null,
  appendSelectUserMerchantsFilterPermissionSubscriberId: null,
  appendSelectUserMerchantsFilterMarketId: null,
  appendSelectUserMerchantsFilterUsersIds: null,
  appendSelectUserMerchantsLoading: false,
  appendSelectUserMerchantsSuccess: null,
  appendSelectUserMerchantsFailed: null,

  fetchUserMerchantLoading: false,
  fetchUserMerchantSuccess: null,
  fetchUserMerchantFailed: null,

  createUserMerchantLoading: false,
  createUserMerchantSuccess: null,
  createUserMerchantFailed: null,

  updateUserMerchantLoading: false,
  updateUserMerchantSuccess: null,
  updateUserMerchantFailed: null,

  deleteUserMerchantLoading: false,
  deleteUserMerchantSuccess: null,
  deleteUserMerchantFailed: null,

  isFetchUserMerchantsHitted: false,
  isFetchSelectUserMerchantsHitted: false,
  isAppendSelectUserMerchantsHitted: false,
  isFetchUserMerchantHitted: false,
  isCreateUserMerchantHitted: false,
  isUpdateUserMerchantHitted: false,
  isDeleteUserMerchantHitted: false,
};

export const userMerchantReducer = (
  state = USER_MERCHANT_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case USER_MERCHANT_ACTION_TYPES.SET_USER_MERCHANTS:
      return { ...state, userMerchants: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_SELECT_USER_MERCHANTS:
      return { ...state, selectUserMerchants: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_USER_MERCHANT:
      return { ...state, userMerchant: payload };

    case USER_MERCHANT_ACTION_TYPES.SET_IS_USER_MERCHANTS_HAS_MORE:
      return { ...state, isUserMerchantsHasMore: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_IS_SELECT_USER_MERCHANTS_HAS_MORE:
      return { ...state, isSelectUserMerchantsHasMore: payload };

    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_SEARCH:
      return { ...state, fetchUserMerchantsSearch: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_SORT:
      return { ...state, fetchUserMerchantsSort: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_KEY_BY:
      return { ...state, fetchUserMerchantsKeyBy: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_PAGE:
      return { ...state, fetchUserMerchantsPage: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_PER_PAGE:
      return { ...state, fetchUserMerchantsPerPage: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_INCLUDES:
      return { ...state, fetchUserMerchantsIncludes: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_FILTER_MERCHANT_ID:
      return { ...state, fetchUserMerchantsFilterMerchantId: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_FILTER_PERMISSION_MERCHANT_ID:
      return {
        ...state,
        fetchUserMerchantsFilterPermissionMerchantId: payload,
      };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_FILTER_PERMISSION_SUBSCRIBER_ID:
      return {
        ...state,
        fetchUserMerchantsFilterPermissionSubscriberId: payload,
      };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_FILTER_MARKET_ID:
      return { ...state, fetchUserMerchantsFilterMarketId: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_FILTER_USERS_IDS:
      return { ...state, fetchUserMerchantsFilterUsersIds: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_LOADING:
      return { ...state, fetchUserMerchantsLoading: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_SUCCESS:
      return { ...state, fetchUserMerchantsSuccess: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_FAILED:
      return { ...state, fetchUserMerchantsFailed: payload };

    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_SEARCH:
      return { ...state, fetchSelectUserMerchantsSearch: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_SORT:
      return { ...state, fetchSelectUserMerchantsSort: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_KEY_BY:
      return { ...state, fetchSelectUserMerchantsKeyBy: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_PAGE:
      return { ...state, fetchSelectUserMerchantsPage: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_PER_PAGE:
      return { ...state, fetchSelectUserMerchantsPerPage: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_INCLUDES:
      return { ...state, fetchSelectUserMerchantsIncludes: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_FILTER_MERCHANT_ID:
      return { ...state, fetchSelectUserMerchantsFilterMerchantId: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_FILTER_PERMISSION_MERCHANT_ID:
      return {
        ...state,
        fetchSelectUserMerchantsFilterPermissionMerchantId: payload,
      };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_FILTER_PERMISSION_SUBSCRIBER_ID:
      return {
        ...state,
        fetchSelectUserMerchantsFilterPermissionSubscriberId: payload,
      };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_FILTER_MARKET_ID:
      return { ...state, fetchSelectUserMerchantsFilterMarketId: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_FILTER_USERS_IDS:
      return { ...state, fetchSelectUserMerchantsFilterUsersIds: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_LOADING:
      return { ...state, fetchSelectUserMerchantsLoading: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_SUCCESS:
      return { ...state, fetchSelectUserMerchantsSuccess: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_FAILED:
      return { ...state, fetchSelectUserMerchantsFailed: payload };

    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_SEARCH:
      return { ...state, appendSelectUserMerchantsSearch: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_SORT:
      return { ...state, appendSelectUserMerchantsSort: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_KEY_BY:
      return { ...state, appendSelectUserMerchantsKeyBy: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_PAGE:
      return { ...state, appendSelectUserMerchantsPage: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_PER_PAGE:
      return { ...state, appendSelectUserMerchantsPerPage: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_INCLUDES:
      return { ...state, appendSelectUserMerchantsIncludes: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_FILTER_MERCHANT_ID:
      return { ...state, appendSelectUserMerchantsFilterMerchantId: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_FILTER_PERMISSION_MERCHANT_ID:
      return {
        ...state,
        appendSelectUserMerchantsFilterPermissionMerchantId: payload,
      };
    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_FILTER_PERMISSION_SUBSCRIBER_ID:
      return {
        ...state,
        appendSelectUserMerchantsFilterPermissionSubscriberId: payload,
      };
    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_FILTER_MARKET_ID:
      return { ...state, appendSelectUserMerchantsFilterMarketId: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_FILTER_USERS_IDS:
      return { ...state, appendSelectUserMerchantsFilterUsersIds: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_LOADING:
      return { ...state, appendSelectUserMerchantsLoading: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_SUCCESS:
      return { ...state, appendSelectUserMerchantsSuccess: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_FAILED:
      return { ...state, appendSelectUserMerchantsFailed: payload };

    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANT_LOADING:
      return { ...state, fetchUserMerchantLoading: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANT_SUCCESS:
      return { ...state, fetchUserMerchantSuccess: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANT_FAILED:
      return { ...state, fetchUserMerchantFailed: payload };

    case USER_MERCHANT_ACTION_TYPES.SET_CREATE_USER_MERCHANT_LOADING:
      return { ...state, createUserMerchantLoading: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_CREATE_USER_MERCHANT_SUCCESS:
      return { ...state, createUserMerchantSuccess: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_CREATE_USER_MERCHANT_FAILED:
      return { ...state, createUserMerchantFailed: payload };

    case USER_MERCHANT_ACTION_TYPES.SET_UPDATE_USER_MERCHANT_LOADING:
      return { ...state, updateUserMerchantLoading: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_UPDATE_USER_MERCHANT_SUCCESS:
      return { ...state, updateUserMerchantSuccess: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_UPDATE_USER_MERCHANT_FAILED:
      return { ...state, updateUserMerchantFailed: payload };

    case USER_MERCHANT_ACTION_TYPES.SET_DELETE_USER_MERCHANT_LOADING:
      return { ...state, deleteUserMerchantLoading: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_DELETE_USER_MERCHANT_SUCCESS:
      return { ...state, deleteUserMerchantSuccess: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_DELETE_USER_MERCHANT_FAILED:
      return { ...state, deleteUserMerchantFailed: payload };

    case USER_MERCHANT_ACTION_TYPES.APPEND_USER_MERCHANTS:
      return { ...state, userMerchants: [...state.userMerchants, ...payload] };
    case USER_MERCHANT_ACTION_TYPES.APPEND_SELECT_USER_MERCHANTS:
      return {
        ...state,
        selectUserMerchants: { ...state.selectUserMerchants, ...payload },
      };

    case USER_MERCHANT_ACTION_TYPES.SET_IS_FETCH_USER_MERCHANTS_HITTED:
      return { ...state, isFetchUserMerchantsHitted: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_IS_FETCH_SELECT_USER_MERCHANTS_HITTED:
      return { ...state, isFetchSelectUserMerchantsHitted: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_IS_APPEND_SELECT_USER_MERCHANTS_HITTED:
      return { ...state, isAppendSelectUserMerchantsHitted: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_IS_FETCH_USER_MERCHANT_HITTED:
      return { ...state, isFetchUserMerchantHitted: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_IS_CREATE_USER_MERCHANT_HITTED:
      return { ...state, isCreateUserMerchantHitted: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_IS_UPDATE_USER_MERCHANT_HITTED:
      return { ...state, isUpdateUserMerchantHitted: payload };
    case USER_MERCHANT_ACTION_TYPES.SET_IS_DELETE_USER_MERCHANT_HITTED:
      return { ...state, isDeleteUserMerchantHitted: payload };

    case USER_MERCHANT_ACTION_TYPES.RESET_USER_MERCHANT_REDUCER:
      return USER_MERCHANT_INITIAL_STATE;
    default:
      return state;
  }
};
