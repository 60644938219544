const EXPORT_ACTION_TYPES = {
  RESET_EXPORT_REDUCER: "export/RESET_EXPORT_REDUCER",

  SET_REPORT_ORDER_FILE_URL: "export/SET_REPORT_ORDER_FILE_URL",
  SET_REPORT_ORDER_FILE_NAME: "export/SET_REPORT_ORDER_FILE_NAME",
  SET_REPORT_SUMMARY_FILE_URL: "export/SET_REPORT_SUMMARY_FILE_URL",
  SET_REPORT_SUMMARY_FILE_NAME: "export/SET_REPORT_SUMMARY_FILE_NAME",
  SET_CUSTOMER_REPORT_URL_FILE: "export/SET_CUSTOMER_REPORT_URL_FILE",
  SET_CUSTOMER_REPORT_FILE_NAME: "export/SET_CUSTOMER_REPORT_FILE_NAME",
  SET_INGREDIENT_REPORT_URL_FILE: "export/SET_INGREDIENT_REPORT_URL_FILE",
  SET_INGREDIENT_REPORT_FILE_NAME: "export/SET_INGREDIENT_REPORT_FILE_NAME",
  SET_INGREDIENT_HISTORY_REPORT_URL_FILE: "export/SET_INGREDIENT_HISTORY_REPORT_URL_FILE",
  SET_INGREDIENT_HISTORY_REPORT_FILE_NAME: "export/SET_INGREDIENT_HISTORY_REPORT_FILE_NAME",
  SET_TABLE_QRCODE_URL_FILE: "export/SET_TABLE_QRCODE_URL_FILE",
  SET_TABLE_QRCODE_FILE_NAME: "export/SET_TABLE_QRCODE_FILE_NAME",
  SET_PRODUCT_BARCODE_URL_FILE: "export/SET_PRODUCT_BARCODE_URL_FILE",
  SET_PRODUCT_BARCODE_FILE_NAME: "export/SET_PRODUCT_BARCODE_FILE_NAME",
  SET_SUBSCRIPTION_RECEIPT_URL_FILE: "export/SET_SUBSCRIPTION_RECEIPT_URL_FILE",
  SET_SUBSCRIPTION_RECEIPT_FILE_NAME: "export/SET_SUBSCRIPTION_RECEIPT_FILE_NAME",

  SET_EXPORT_REPORT_ORDER_FILTER_MARKET_ID: "export/SET_EXPORT_REPORT_ORDER_FILTER_MARKET_ID",
  SET_EXPORT_REPORT_ORDER_FILTER_BRANCH_ID: "export/SET_EXPORT_REPORT_ORDER_FILTER_BRANCH_ID",
  SET_EXPORT_REPORT_ORDER_FILTER_PAYMENT_METHOD_KEY: "export/SET_EXPORT_REPORT_ORDER_FILTER_PAYMENT_METHOD_KEY",
  SET_EXPORT_REPORT_ORDER_FILTER_CASHIER_ID: "export/SET_EXPORT_REPORT_ORDER_FILTER_CASHIER_ID",
  SET_EXPORT_REPORT_ORDER_FILTER_CUSTOMER_ID: "export/SET_EXPORT_REPORT_ORDER_FILTER_CUSTOMER_ID",
  SET_EXPORT_REPORT_ORDER_FILTER_CREATED_AT_BEFORE: "export/SET_EXPORT_REPORT_ORDER_FILTER_CREATED_AT_BEFORE",
  SET_EXPORT_REPORT_ORDER_FILTER_CREATED_AT_AFTER: "export/SET_EXPORT_REPORT_ORDER_FILTER_CREATED_AT_AFTER",
  SET_EXPORT_REPORT_ORDER_LOADING: "export/SET_EXPORT_REPORT_ORDER_LOADING",
  SET_EXPORT_REPORT_ORDER_SUCCESS: "export/SET_EXPORT_REPORT_ORDER_SUCCESS",
  SET_EXPORT_REPORT_ORDER_FAILED: "export/SET_EXPORT_REPORT_ORDER_FAILED",

  SET_EXPORT_REPORT_SUMMARY_FILTER_MARKET_ID: "export/SET_EXPORT_REPORT_SUMMARY_FILTER_MARKET_ID",
  SET_EXPORT_REPORT_SUMMARY_FILTER_BRANCH_ID: "export/SET_EXPORT_REPORT_SUMMARY_FILTER_BRANCH_ID",
  SET_EXPORT_REPORT_SUMMARY_FILTER_PAYMENT_METHOD_KEY: "export/SET_EXPORT_REPORT_SUMMARY_FILTER_PAYMENT_METHOD_KEY",
  SET_EXPORT_REPORT_SUMMARY_FILTER_CASHIER_ID: "export/SET_EXPORT_REPORT_SUMMARY_FILTER_CASHIER_ID",
  SET_EXPORT_REPORT_SUMMARY_FILTER_CUSTOMER_ID: "export/SET_EXPORT_REPORT_SUMMARY_FILTER_CUSTOMER_ID",
  SET_EXPORT_REPORT_SUMMARY_FILTER_CREATED_AT_BEFORE: "export/SET_EXPORT_REPORT_SUMMARY_FILTER_CREATED_AT_BEFORE",
  SET_EXPORT_REPORT_SUMMARY_FILTER_CREATED_AT_AFTER: "export/SET_EXPORT_REPORT_SUMMARY_FILTER_CREATED_AT_AFTER",
  SET_EXPORT_REPORT_SUMMARY_LOADING: "export/SET_EXPORT_REPORT_SUMMARY_LOADING",
  SET_EXPORT_REPORT_SUMMARY_SUCCESS: "export/SET_EXPORT_REPORT_SUMMARY_SUCCESS",
  SET_EXPORT_REPORT_SUMMARY_FAILED: "export/SET_EXPORT_REPORT_SUMMARY_FAILED",

  SET_EXPORT_CUSTOMER_REPORT_FILTER_MARKET_ID: "export/SET_EXPORT_CUSTOMER_REPORT_FILTER_MARKET_ID",
  SET_EXPORT_CUSTOMER_REPORT_FILTER_CUSTOMERS_IDS: "export/SET_EXPORT_CUSTOMER_REPORT_FILTER_CUSTOMERS_IDS",
  SET_EXPORT_CUSTOMER_REPORT_LOADING: "export/SET_EXPORT_CUSTOMER_REPORT_LOADING",
  SET_EXPORT_CUSTOMER_REPORT_SUCCESS: "export/SET_EXPORT_CUSTOMER_REPORT_SUCCESS",
  SET_EXPORT_CUSTOMER_REPORT_FAILED: "export/SET_EXPORT_CUSTOMER_REPORT_FAILED",

  SET_EXPORT_INGREDIENT_REPORT_FILTER_PERIOD_TYPE: "export/SET_EXPORT_INGREDIENT_REPORT_FILTER_PERIOD_TYPE",
  SET_EXPORT_INGREDIENT_REPORT_FILTER_START_AT: "export/SET_EXPORT_INGREDIENT_REPORT_FILTER_START_AT",
  SET_EXPORT_INGREDIENT_REPORT_FILTER_END_AT: "export/SET_EXPORT_INGREDIENT_REPORT_FILTER_END_AT",
  SET_EXPORT_INGREDIENT_REPORT_FILTER_MARKET_ID: "export/SET_EXPORT_INGREDIENT_REPORT_FILTER_MARKET_ID",
  SET_EXPORT_INGREDIENT_REPORT_FILTER_BRANCH_ID: "export/SET_EXPORT_INGREDIENT_REPORT_FILTER_BRANCH_ID",
  SET_EXPORT_INGREDIENT_REPORT_FILTER_INGREDIENTS_IDS: "export/SET_EXPORT_INGREDIENT_REPORT_FILTER_INGREDIENTS_IDS",
  SET_EXPORT_INGREDIENT_REPORT_LOADING: "export/SET_EXPORT_INGREDIENT_REPORT_LOADING",
  SET_EXPORT_INGREDIENT_REPORT_SUCCESS: "export/SET_EXPORT_INGREDIENT_REPORT_SUCCESS",
  SET_EXPORT_INGREDIENT_REPORT_FAILED: "export/SET_EXPORT_INGREDIENT_REPORT_FAILED",

  SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_PERIOD_TYPE:
    "export/SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_PERIOD_TYPE",
  SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_START_AT: "export/SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_START_AT",
  SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_END_AT: "export/SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_END_AT",
  SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_MARKET_ID: "export/SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_MARKET_ID",
  SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_BRANCH_ID: "export/SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_BRANCH_ID",
  SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_INGREDIENTS_IDS:
    "export/SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_INGREDIENTS_IDS",
  SET_EXPORT_INGREDIENT_HISTORY_REPORT_LOADING: "export/SET_EXPORT_INGREDIENT_HISTORY_REPORT_LOADING",
  SET_EXPORT_INGREDIENT_HISTORY_REPORT_SUCCESS: "export/SET_EXPORT_INGREDIENT_HISTORY_REPORT_SUCCESS",
  SET_EXPORT_INGREDIENT_HISTORY_REPORT_FAILED: "export/SET_EXPORT_INGREDIENT_HISTORY_REPORT_FAILED",

  SET_EXPORT_TABLE_QRCODE_FILTER_MARKET_ID: "export/SET_EXPORT_TABLE_QRCODE_FILTER_MARKET_ID",
  SET_EXPORT_TABLE_QRCODE_FILTER_BRANCH_ID: "export/SET_EXPORT_TABLE_QRCODE_FILTER_BRANCH_ID",
  SET_EXPORT_TABLE_QRCODE_FILTER_TABLES_IDS: "export/SET_EXPORT_TABLE_QRCODE_FILTER_TABLES_IDS",
  SET_EXPORT_TABLE_QRCODE_LOADING: "export/SET_EXPORT_TABLE_QRCODE_LOADING",
  SET_EXPORT_TABLE_QRCODE_SUCCESS: "export/SET_EXPORT_TABLE_QRCODE_SUCCESS",
  SET_EXPORT_TABLE_QRCODE_FAILED: "export/SET_EXPORT_TABLE_QRCODE_FAILED",

  SET_EXPORT_PRODUCT_BARCODE_FILTER_MARKET_ID: "export/SET_EXPORT_PRODUCT_BARCODE_FILTER_MARKET_ID",
  SET_EXPORT_PRODUCT_BARCODE_FILTER_BRANCH_ID: "export/SET_EXPORT_PRODUCT_BARCODE_FILTER_BRANCH_ID",
  SET_EXPORT_PRODUCT_BARCODE_FILTER_PRODUCTS_IDS: "export/SET_EXPORT_PRODUCT_BARCODE_FILTER_PRODUCTS_IDS",
  SET_EXPORT_PRODUCT_BARCODE_LOADING: "export/SET_EXPORT_PRODUCT_BARCODE_LOADING",
  SET_EXPORT_PRODUCT_BARCODE_SUCCESS: "export/SET_EXPORT_PRODUCT_BARCODE_SUCCESS",
  SET_EXPORT_PRODUCT_BARCODE_FAILED: "export/SET_EXPORT_PRODUCT_BARCODE_FAILED",

  SET_EXPORT_SUBSCRIPTION_RECEIPT_LOADING: "export/SET_EXPORT_SUBSCRIPTION_RECEIPT_LOADING",
  SET_EXPORT_SUBSCRIPTION_RECEIPT_SUCCESS: "export/SET_EXPORT_SUBSCRIPTION_RECEIPT_SUCCESS",
  SET_EXPORT_SUBSCRIPTION_RECEIPT_FAILED: "export/SET_EXPORT_SUBSCRIPTION_RECEIPT_FAILED",

  SET_IS_EXPORT_REPORT_ORDER_HITTED: "export/SET_IS_EXPORT_REPORT_ORDER_HITTED",
  SET_IS_EXPORT_REPORT_SUMMARY_HITTED: "export/SET_IS_EXPORT_REPORT_SUMMARY_HITTED",
  SET_IS_EXPORT_CUSTOMER_REPORT_HITTED: "export/SET_IS_EXPORT_CUSTOMER_REPORT_HITTED",
  SET_IS_EXPORT_INGREDIENT_REPORT_HITTED: "export/SET_IS_EXPORT_INGREDIENT_REPORT_HITTED",
  SET_IS_EXPORT_INGREDIENT_HISTORY_REPORT_HITTED: "export/SET_IS_EXPORT_INGREDIENT_HISTORY_REPORT_HITTED",
  SET_IS_EXPORT_TABLE_QRCODE_HITTED: "export/SET_IS_EXPORT_TABLE_QRCODE_HITTED",
  SET_IS_EXPORT_PRODUCT_BARCODE_HITTED: "export/SET_IS_EXPORT_PRODUCT_BARCODE_HITTED",
  SET_IS_EXPORT_SUBSCRIPTION_RECEIPT_HITTED: "export/SET_IS_EXPORT_SUBSCRIPTION_RECEIPT_HITTED",

  EXPORT_REPORT_ORDER_START: "export/EXPORT_REPORT_ORDER_START",
  EXPORT_REPORT_SUMMARY_START: "export/EXPORT_REPORT_SUMMARY_START",
  EXPORT_CUSTOMER_REPORT_START: "export/EXPORT_CUSTOMER_REPORT_START",
  EXPORT_INGREDIENT_REPORT_START: "export/EXPORT_INGREDIENT_REPORT_START",
  EXPORT_INGREDIENT_HISTORY_REPORT_START: "export/EXPORT_INGREDIENT_HISTORY_REPORT_START",
  EXPORT_TABLE_QRCODE_START: "export/EXPORT_TABLE_QRCODE_START",
  EXPORT_PRODUCT_BARCODE_START: "export/EXPORT_PRODUCT_BARCODE_START",
  EXPORT_SUBSCRIPTION_RECEIPT_START: "export/EXPORT_SUBSCRIPTION_RECEIPT_START",
};

export default EXPORT_ACTION_TYPES;
