import { takeLatest, put, all, call, select } from "redux-saga/effects";

import ADVERTISEMENT_ACTION_TYPES from "./advertisement.type";

import {
  appendAdvertisements,
  setCreateAdvertisementFailed,
  setCreateAdvertisementLoading,
  setCreateAdvertisementSuccess,
  setDeleteAdvertisementFailed,
  setDeleteAdvertisementLoading,
  setDeleteAdvertisementSuccess,
  setFetchAdvertisementsFailed,
  setFetchAdvertisementsLoading,
  setFetchAdvertisementsPage,
  setFetchAdvertisementsSuccess,
  setFetchAdvertisementFailed,
  setFetchAdvertisementLoading,
  setFetchAdvertisementSuccess,
  setIsAdvertisementsHasMore,
  setAdvertisement,
  setAdvertisements,
  setUpdateAdvertisementFailed,
  setUpdateAdvertisementLoading,
  setUpdateAdvertisementSuccess,
  setIsFetchAdvertisementsHitted,
  setIsFetchAdvertisementHitted,
  setIsCreateAdvertisementHitted,
  setIsUpdateAdvertisementHitted,
  setIsDeleteAdvertisementHitted,
} from "./advertisement.action";
import {
  getFetchAdvertisementsFilterEndAt,
  getFetchAdvertisementsFilterMarketId,
  getFetchAdvertisementsFilterStartAt,
  getFetchAdvertisementsFilterStatuses,
  getFetchAdvertisementsFilterType,
  getFetchAdvertisementsIncludes,
  getFetchAdvertisementsPage,
  getFetchAdvertisementsPerPage,
  getFetchAdvertisementsSearch,
  getIsFetchAdvertisementsHitted,
} from "./advertisement.selector";

import {
  getAdvertisements,
  getAdvertisement,
  createAdvertisement,
  updateAdvertisement,
  deleteAdvertisement,
} from "../../api/advertisement.api";

export function* _getAdvertisements() {
  try {
    yield put(setFetchAdvertisementsLoading(true));

    const search = yield select(getFetchAdvertisementsSearch);
    const page = yield select(getFetchAdvertisementsPage);
    const per_page = yield select(getFetchAdvertisementsPerPage);
    const includes = yield select(getFetchAdvertisementsIncludes);
    const market_id = yield select(getFetchAdvertisementsFilterMarketId);
    const type = yield select(getFetchAdvertisementsFilterType);
    const statuses = yield select(getFetchAdvertisementsFilterStatuses);
    const start_at = yield select(getFetchAdvertisementsFilterStartAt);
    const end_at = yield select(getFetchAdvertisementsFilterEndAt);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        type,
        statuses,
        start_at,
        end_at,
      },
    };

    const {
      meta: { message },
      data: { data: advertisements },
    } = yield call(getAdvertisements, parameters);

    yield put(setIsFetchAdvertisementsHitted(true));
    yield put(setIsAdvertisementsHasMore(advertisements.length > 0));

    if (page > 1) {
      yield put(appendAdvertisements(advertisements));
    } else {
      yield put(setAdvertisements(advertisements));
    }

    yield put(setFetchAdvertisementsSuccess(message));
    yield put(setFetchAdvertisementsLoading(false));
  } catch (error) {
    yield put(setFetchAdvertisementsFailed(error));
    yield put(setFetchAdvertisementsLoading(false));
  }
}

export function* _getAdvertisement({ payload: id }) {
  try {
    yield put(setFetchAdvertisementLoading(true));

    const {
      meta: { message },
      data: advertisement,
    } = yield call(getAdvertisement, id);

    yield put(setIsFetchAdvertisementHitted(true));
    yield put(setAdvertisement(advertisement));

    yield put(setFetchAdvertisementSuccess(message));
    yield put(setFetchAdvertisementLoading(false));
  } catch (error) {
    yield put(setFetchAdvertisementFailed(error));
    yield put(setFetchAdvertisementLoading(false));
  }
}

export function* _createAdvertisement({ payload: request }) {
  try {
    yield put(setCreateAdvertisementLoading(true));

    const {
      meta: { message },
    } = yield call(createAdvertisement, request);

    yield put(setIsCreateAdvertisementHitted(true));

    const isFetchAdvertisementsHitted = yield select(getIsFetchAdvertisementsHitted);

    if (isFetchAdvertisementsHitted) {
      yield put(setFetchAdvertisementsPage(1));
      yield call(_getAdvertisements);
    }

    yield put(setCreateAdvertisementSuccess(message));
    yield put(setCreateAdvertisementLoading(false));
  } catch (error) {
    yield put(setCreateAdvertisementFailed(error));
    yield put(setCreateAdvertisementLoading(false));
  }
}

export function* _updateAdvertisement({ payload: { id, request } }) {
  try {
    yield put(setUpdateAdvertisementLoading(true));

    const {
      meta: { message },
    } = yield call(updateAdvertisement, id, request);

    yield put(setIsUpdateAdvertisementHitted(true));

    const isFetchAdvertisementsHitted = yield select(getIsFetchAdvertisementsHitted);

    if (isFetchAdvertisementsHitted) {
      yield put(setFetchAdvertisementsPage(1));
      yield call(_getAdvertisements);
    }

    yield put(setUpdateAdvertisementSuccess(message));
    yield put(setUpdateAdvertisementLoading(false));
  } catch (error) {
    yield put(setUpdateAdvertisementFailed(error));
    yield put(setUpdateAdvertisementLoading(false));
  }
}

export function* _deleteAdvertisement({ payload: id }) {
  try {
    yield put(setDeleteAdvertisementLoading(true));

    const {
      meta: { message },
    } = yield call(deleteAdvertisement, id);

    yield put(setIsDeleteAdvertisementHitted(true));

    const isFetchAdvertisementsHitted = yield select(getIsFetchAdvertisementsHitted);

    if (isFetchAdvertisementsHitted) {
      yield put(setFetchAdvertisementsPage(1));
      yield call(_getAdvertisements);
    }

    yield put(setDeleteAdvertisementSuccess(message));
    yield put(setDeleteAdvertisementLoading(false));
  } catch (error) {
    yield put(setDeleteAdvertisementFailed(error));
    yield put(setDeleteAdvertisementLoading(false));
  }
}

export function* onFetchAdvertisementsStart() {
  yield takeLatest(ADVERTISEMENT_ACTION_TYPES.FETCH_ADVERTISEMENTS_START, _getAdvertisements);
}

export function* onFetchAdvertisementStart() {
  yield takeLatest(ADVERTISEMENT_ACTION_TYPES.FETCH_ADVERTISEMENT_START, _getAdvertisement);
}

export function* onCreateAdvertisementStart() {
  yield takeLatest(ADVERTISEMENT_ACTION_TYPES.CREATE_ADVERTISEMENT_START, _createAdvertisement);
}

export function* onUpdateAdvertisementStart() {
  yield takeLatest(ADVERTISEMENT_ACTION_TYPES.UPDATE_ADVERTISEMENT_START, _updateAdvertisement);
}

export function* onDeleteAdvertisementStart() {
  yield takeLatest(ADVERTISEMENT_ACTION_TYPES.DELETE_ADVERTISEMENT_START, _deleteAdvertisement);
}

export function* advertisementSaga() {
  yield all([
    call(onFetchAdvertisementsStart),
    call(onFetchAdvertisementStart),
    call(onCreateAdvertisementStart),
    call(onUpdateAdvertisementStart),
    call(onDeleteAdvertisementStart),
  ]);
}
