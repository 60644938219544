import { createSelector } from "reselect";

const tableSelector = ({ table }) => table;

export const getIsModalAutoClose = createSelector(
  [tableSelector],
  ({ isModalAutoClose }) => isModalAutoClose
);

export const getTables = createSelector(
  [tableSelector],
  ({ tables }) => tables
);
export const getOrderTables = createSelector(
  [tableSelector],
  ({ orderTables }) => orderTables
);
export const getSelectTables = createSelector(
  [tableSelector],
  ({ selectTables }) => selectTables
);
export const getTable = createSelector([tableSelector], ({ table }) => table);
export const getOrderTable = createSelector(
  [tableSelector],
  ({ orderTable }) => orderTable
);

export const getIsTablesHasMore = createSelector(
  [tableSelector],
  ({ isTablesHasMore }) => isTablesHasMore
);
export const getIsOrderTablesHasMore = createSelector(
  [tableSelector],
  ({ isOrderTablesHasMore }) => isOrderTablesHasMore
);
export const getIsSelectTablesHasMore = createSelector(
  [tableSelector],
  ({ isSelectTablesHasMore }) => isSelectTablesHasMore
);

export const getFetchTablesSearch = createSelector(
  [tableSelector],
  ({ fetchTablesSearch }) => fetchTablesSearch
);
export const getFetchTablesPage = createSelector(
  [tableSelector],
  ({ fetchTablesPage }) => fetchTablesPage
);
export const getFetchTablesPerPage = createSelector(
  [tableSelector],
  ({ fetchTablesPerPage }) => fetchTablesPerPage
);
export const getFetchTablesIncludes = createSelector(
  [tableSelector],
  ({ fetchTablesIncludes }) => fetchTablesIncludes
);
export const getFetchTablesFilterMarketId = createSelector(
  [tableSelector],
  ({ fetchTablesFilterMarketId }) => fetchTablesFilterMarketId
);
export const getFetchTablesFilterBranchId = createSelector(
  [tableSelector],
  ({ fetchTablesFilterBranchId }) => fetchTablesFilterBranchId
);
export const getFetchTablesFilterStatus = createSelector(
  [tableSelector],
  ({ fetchTablesFilterStatus }) => fetchTablesFilterStatus
);
export const getFetchTablesLoading = createSelector(
  [tableSelector],
  ({ fetchTablesLoading }) => fetchTablesLoading
);
export const getFetchTablesSuccess = createSelector(
  [tableSelector],
  ({ fetchTablesSuccess }) => fetchTablesSuccess
);
export const getFetchTablesFailed = createSelector(
  [tableSelector],
  ({ fetchTablesFailed }) => fetchTablesFailed
);

export const getFetchOrderTablesSearch = createSelector(
  [tableSelector],
  ({ fetchOrderTablesSearch }) => fetchOrderTablesSearch
);
export const getFetchOrderTablesPage = createSelector(
  [tableSelector],
  ({ fetchOrderTablesPage }) => fetchOrderTablesPage
);
export const getFetchOrderTablesPerPage = createSelector(
  [tableSelector],
  ({ fetchOrderTablesPerPage }) => fetchOrderTablesPerPage
);
export const getFetchOrderTablesIncludes = createSelector(
  [tableSelector],
  ({ fetchOrderTablesIncludes }) => fetchOrderTablesIncludes
);
export const getFetchOrderTablesFilterMarketId = createSelector(
  [tableSelector],
  ({ fetchOrderTablesFilterMarketId }) => fetchOrderTablesFilterMarketId
);
export const getFetchOrderTablesFilterBranchId = createSelector(
  [tableSelector],
  ({ fetchOrderTablesFilterBranchId }) => fetchOrderTablesFilterBranchId
);
export const getFetchOrderTablesFilterTablesIds = createSelector(
  [tableSelector],
  ({ fetchOrderTablesFilterTablesIds }) => fetchOrderTablesFilterTablesIds
);
export const getFetchOrderTablesFilterStatus = createSelector(
  [tableSelector],
  ({ fetchOrderTablesFilterStatus }) => fetchOrderTablesFilterStatus
);
export const getFetchOrderTablesLoading = createSelector(
  [tableSelector],
  ({ fetchOrderTablesLoading }) => fetchOrderTablesLoading
);
export const getFetchOrderTablesSuccess = createSelector(
  [tableSelector],
  ({ fetchOrderTablesSuccess }) => fetchOrderTablesSuccess
);
export const getFetchOrderTablesFailed = createSelector(
  [tableSelector],
  ({ fetchOrderTablesFailed }) => fetchOrderTablesFailed
);

export const getFetchSelectTablesSearch = createSelector(
  [tableSelector],
  ({ fetchSelectTablesSearch }) => fetchSelectTablesSearch
);
export const getFetchSelectTablesPage = createSelector(
  [tableSelector],
  ({ fetchSelectTablesPage }) => fetchSelectTablesPage
);
export const getFetchSelectTablesPerPage = createSelector(
  [tableSelector],
  ({ fetchSelectTablesPerPage }) => fetchSelectTablesPerPage
);
export const getFetchSelectTablesIncludes = createSelector(
  [tableSelector],
  ({ fetchSelectTablesIncludes }) => fetchSelectTablesIncludes
);
export const getFetchSelectTablesFilterMarketId = createSelector(
  [tableSelector],
  ({ fetchSelectTablesFilterMarketId }) => fetchSelectTablesFilterMarketId
);
export const getFetchSelectTablesFilterBranchId = createSelector(
  [tableSelector],
  ({ fetchSelectTablesFilterBranchId }) => fetchSelectTablesFilterBranchId
);
export const getFetchSelectTablesFilterTablesIds = createSelector(
  [tableSelector],
  ({ fetchSelectTablesFilterTablesIds }) => fetchSelectTablesFilterTablesIds
);
export const getFetchSelectTablesFilterStatus = createSelector(
  [tableSelector],
  ({ fetchSelectTablesFilterStatus }) => fetchSelectTablesFilterStatus
);
export const getFetchSelectTablesLoading = createSelector(
  [tableSelector],
  ({ fetchSelectTablesLoading }) => fetchSelectTablesLoading
);
export const getFetchSelectTablesSuccess = createSelector(
  [tableSelector],
  ({ fetchSelectTablesSuccess }) => fetchSelectTablesSuccess
);
export const getFetchSelectTablesFailed = createSelector(
  [tableSelector],
  ({ fetchSelectTablesFailed }) => fetchSelectTablesFailed
);

export const getFetchTableLoading = createSelector(
  [tableSelector],
  ({ fetchTableLoading }) => fetchTableLoading
);
export const getFetchTableSuccess = createSelector(
  [tableSelector],
  ({ fetchTableSuccess }) => fetchTableSuccess
);
export const getFetchTableFailed = createSelector(
  [tableSelector],
  ({ fetchTableFailed }) => fetchTableFailed
);

export const getCreateTableLoading = createSelector(
  [tableSelector],
  ({ createTableLoading }) => createTableLoading
);
export const getCreateTableSuccess = createSelector(
  [tableSelector],
  ({ createTableSuccess }) => createTableSuccess
);
export const getCreateTableFailed = createSelector(
  [tableSelector],
  ({ createTableFailed }) => createTableFailed
);

export const getUpdateTableLoading = createSelector(
  [tableSelector],
  ({ updateTableLoading }) => updateTableLoading
);
export const getUpdateTableSuccess = createSelector(
  [tableSelector],
  ({ updateTableSuccess }) => updateTableSuccess
);
export const getUpdateTableFailed = createSelector(
  [tableSelector],
  ({ updateTableFailed }) => updateTableFailed
);

export const getDeleteTableLoading = createSelector(
  [tableSelector],
  ({ deleteTableLoading }) => deleteTableLoading
);
export const getDeleteTableSuccess = createSelector(
  [tableSelector],
  ({ deleteTableSuccess }) => deleteTableSuccess
);
export const getDeleteTableFailed = createSelector(
  [tableSelector],
  ({ deleteTableFailed }) => deleteTableFailed
);

export const getMultipleCreateTableLoading = createSelector(
  [tableSelector],
  ({ multipleCreateTableLoading }) => multipleCreateTableLoading
);
export const getMultipleCreateTableSuccess = createSelector(
  [tableSelector],
  ({ multipleCreateTableSuccess }) => multipleCreateTableSuccess
);
export const getMultipleCreateTableFailed = createSelector(
  [tableSelector],
  ({ multipleCreateTableFailed }) => multipleCreateTableFailed
);

export const getIsFetchTablesHitted = createSelector(
  [tableSelector],
  ({ isFetchTablesHitted }) => isFetchTablesHitted
);
export const getIsFetchOrderTablesHitted = createSelector(
  [tableSelector],
  ({ isFetchOrderTablesHitted }) => isFetchOrderTablesHitted
);
export const getIsFetchSelectTablesHitted = createSelector(
  [tableSelector],
  ({ isFetchSelectTablesHitted }) => isFetchSelectTablesHitted
);
export const getIsFetchTableHitted = createSelector(
  [tableSelector],
  ({ isFetchTableHitted }) => isFetchTableHitted
);
export const getIsCreateTableHitted = createSelector(
  [tableSelector],
  ({ isCreateTableHitted }) => isCreateTableHitted
);
export const getIsUpdateTableHitted = createSelector(
  [tableSelector],
  ({ isUpdateTableHitted }) => isUpdateTableHitted
);
export const getIsDeleteTableHitted = createSelector(
  [tableSelector],
  ({ isDeleteTableHitted }) => isDeleteTableHitted
);
export const getIsMultipleCreateTableHitted = createSelector(
  [tableSelector],
  ({ isMultipleCreateTableHitted }) => isMultipleCreateTableHitted
);
