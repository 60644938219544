import { useSelector } from "react-redux";

import { getIsRightToLeft } from "../../store/global/global.selector";

import Spinner, {
  SPINNER_COLORS,
  SPINNER_SIZES,
} from "../../components/spinner/spinner.component";

import { LoadingContainer, LoadingLogo } from "./loading.style";

import { ReactComponent as LogoImageArSvg } from "../../assets/images/logos/flavours-black-ar.svg";
import { ReactComponent as LogoImageEnSvg } from "../../assets/images/logos/flavours-black-en.svg";

const Loading = () => {
  const isRightToLeft = useSelector(getIsRightToLeft);

  return (
    <LoadingContainer>
      <LoadingLogo>
        {isRightToLeft ? <LogoImageArSvg /> : <LogoImageEnSvg />}
      </LoadingLogo>
      <Spinner
        spinnerColor={SPINNER_COLORS.DARK}
        spinnerSize={SPINNER_SIZES.NORMAL}
      />
    </LoadingContainer>
  );
};

export default Loading;
