import { takeLatest, put, all, call, select } from "redux-saga/effects";

import SUMMARY_ACTION_TYPES from "./summary.type";

import {
  appendDashboardSummaryTopProducts,
  appendReportSummaryTopProducts,
  appendSummaryBranches,
  appendSummaryPaymentMethods,
  appendSummarySubscribers,
  appendSummaryTopProductCategories,
  setAccountSummaryFinancialTotal,
  setDashboardSummaryFinancialAllTimeTotal,
  setDashboardSummaryFinancialTotal,
  setDashboardSummaryTopProducts,
  setDashboardSummaryTotalBranch,
  setDashboardSummaryTotalMarket,
  setDashboardSummaryTotalUser,
  setFetchAccountSummaryFinancialTotalFailed,
  setFetchAccountSummaryFinancialTotalLoading,
  setFetchAccountSummaryFinancialTotalSuccess,
  setFetchDashboardSummaryFinancialAllTimeTotalFailed,
  setFetchDashboardSummaryFinancialAllTimeTotalLoading,
  setFetchDashboardSummaryFinancialAllTimeTotalSuccess,
  setFetchDashboardSummaryFinancialTotalFailed,
  setFetchDashboardSummaryFinancialTotalLoading,
  setFetchDashboardSummaryFinancialTotalSuccess,
  setFetchDashboardSummaryTopProductsFailed,
  setFetchDashboardSummaryTopProductsLoading,
  setFetchDashboardSummaryTopProductsSuccess,
  setFetchDashboardSummaryTotalBranchFailed,
  setFetchDashboardSummaryTotalBranchLoading,
  setFetchDashboardSummaryTotalBranchSuccess,
  setFetchDashboardSummaryTotalMarketFailed,
  setFetchDashboardSummaryTotalMarketLoading,
  setFetchDashboardSummaryTotalMarketSuccess,
  setFetchDashboardSummaryTotalUserFailed,
  setFetchDashboardSummaryTotalUserLoading,
  setFetchDashboardSummaryTotalUserSuccess,
  setFetchReportSummaryFinancialTotalFailed,
  setFetchReportSummaryFinancialTotalLoading,
  setFetchReportSummaryFinancialTotalSuccess,
  setFetchReportSummaryTopProductsFailed,
  setFetchReportSummaryTopProductsLoading,
  setFetchReportSummaryTopProductsSuccess,
  setFetchReportSummaryTotalBranchFailed,
  setFetchReportSummaryTotalBranchLoading,
  setFetchReportSummaryTotalBranchSuccess,
  setFetchReportSummaryTotalMarketFailed,
  setFetchReportSummaryTotalMarketLoading,
  setFetchReportSummaryTotalMarketSuccess,
  setFetchReportSummaryTotalUserFailed,
  setFetchReportSummaryTotalUserLoading,
  setFetchReportSummaryTotalUserSuccess,
  setFetchSummaryBranchesFailed,
  setFetchSummaryBranchesLoading,
  setFetchSummaryBranchesSuccess,
  setFetchSummaryFinancialChartFailed,
  setFetchSummaryFinancialChartLoading,
  setFetchSummaryFinancialChartSuccess,
  setFetchSummaryLatestOrdersFailed,
  setFetchSummaryLatestOrdersLoading,
  setFetchSummaryLatestOrdersSuccess,
  setFetchSummaryPaymentMethodsFailed,
  setFetchSummaryPaymentMethodsLoading,
  setFetchSummaryPaymentMethodsSuccess,
  setFetchSummarySubscriberReportBranchFailed,
  setFetchSummarySubscriberReportBranchLoading,
  setFetchSummarySubscriberReportBranchSuccess,
  setFetchSummarySubscriberReportFailed,
  setFetchSummarySubscriberReportLoading,
  setFetchSummarySubscriberReportSuccess,
  setFetchSummarySubscribersFailed,
  setFetchSummarySubscribersLoading,
  setFetchSummarySubscribersSuccess,
  setFetchSummaryTopProductCategoriesFailed,
  setFetchSummaryTopProductCategoriesLoading,
  setFetchSummaryTopProductCategoriesSuccess,
  setIsDashboardSummaryTopProductsHasMore,
  setIsReportSummaryTopProductsHasMore,
  setIsSummaryBranchesHasMore,
  setIsSummaryPaymentMethodsHasMore,
  setIsSummarySubscribersHasMore,
  setIsSummaryTopProductCategoriesHasMore,
  setReportSummaryFinancialTotal,
  setReportSummaryTopProducts,
  setReportSummaryTotalBranch,
  setReportSummaryTotalMarket,
  setReportSummaryTotalUser,
  setSummaryBranches,
  setSummaryFinancialChart,
  setSummaryLatestOrders,
  setSummaryPaymentMethods,
  setSummarySubscriberReport,
  setSummarySubscriberReportBranch,
  setSummarySubscribers,
  setSummaryTopProductCategories,
} from "./summary.action";
import {
  getFetchAccountSummaryFinancialTotalFilterBranchId,
  getFetchAccountSummaryFinancialTotalFilterCashierId,
  getFetchAccountSummaryFinancialTotalFilterEndAt,
  getFetchAccountSummaryFinancialTotalFilterMarketId,
  getFetchAccountSummaryFinancialTotalFilterPaymentMethodKey,
  getFetchAccountSummaryFinancialTotalFilterPeriodType,
  getFetchAccountSummaryFinancialTotalFilterStartAt,
  getFetchDashboardSummaryFinancialAllTimeTotalFilterBranchId,
  getFetchDashboardSummaryFinancialAllTimeTotalFilterCashierId,
  getFetchDashboardSummaryFinancialAllTimeTotalFilterEndAt,
  getFetchDashboardSummaryFinancialAllTimeTotalFilterMarketId,
  getFetchDashboardSummaryFinancialAllTimeTotalFilterPaymentMethodKey,
  getFetchDashboardSummaryFinancialAllTimeTotalFilterPeriodType,
  getFetchDashboardSummaryFinancialAllTimeTotalFilterStartAt,
  getFetchDashboardSummaryFinancialTotalFilterBranchId,
  getFetchDashboardSummaryFinancialTotalFilterCashierId,
  getFetchDashboardSummaryFinancialTotalFilterEndAt,
  getFetchDashboardSummaryFinancialTotalFilterMarketId,
  getFetchDashboardSummaryFinancialTotalFilterPaymentMethodKey,
  getFetchDashboardSummaryFinancialTotalFilterPeriodType,
  getFetchDashboardSummaryFinancialTotalFilterStartAt,
  getFetchDashboardSummaryTopProductsFilterBranchId,
  getFetchDashboardSummaryTopProductsFilterCashierId,
  getFetchDashboardSummaryTopProductsFilterEndAt,
  getFetchDashboardSummaryTopProductsFilterMarketId,
  getFetchDashboardSummaryTopProductsFilterPaymentMethodKey,
  getFetchDashboardSummaryTopProductsFilterPeriodType,
  getFetchDashboardSummaryTopProductsFilterStartAt,
  getFetchDashboardSummaryTopProductsPage,
  getFetchDashboardSummaryTopProductsPerPage,
  getFetchDashboardSummaryTotalBranchFilterIsActive,
  getFetchDashboardSummaryTotalBranchFilterMarketId,
  getFetchDashboardSummaryTotalMarketFilterPhonePrefix,
  getFetchDashboardSummaryTotalMarketFilterSectorId,
  getFetchDashboardSummaryTotalUserFilterEndAt,
  getFetchDashboardSummaryTotalUserFilterIsActive,
  getFetchDashboardSummaryTotalUserFilterPhonePrefix,
  getFetchDashboardSummaryTotalUserFilterStartAt,
  getFetchDashboardSummaryTotalUserRoles,
  getFetchReportSummaryFinancialTotalFilterBranchId,
  getFetchReportSummaryFinancialTotalFilterCashierId,
  getFetchReportSummaryFinancialTotalFilterEndAt,
  getFetchReportSummaryFinancialTotalFilterMarketId,
  getFetchReportSummaryFinancialTotalFilterPaymentMethodKey,
  getFetchReportSummaryFinancialTotalFilterPeriodType,
  getFetchReportSummaryFinancialTotalFilterStartAt,
  getFetchReportSummaryTopProductsFilterBranchId,
  getFetchReportSummaryTopProductsFilterCashierId,
  getFetchReportSummaryTopProductsFilterEndAt,
  getFetchReportSummaryTopProductsFilterMarketId,
  getFetchReportSummaryTopProductsFilterPaymentMethodKey,
  getFetchReportSummaryTopProductsFilterPeriodType,
  getFetchReportSummaryTopProductsFilterStartAt,
  getFetchReportSummaryTopProductsPage,
  getFetchReportSummaryTopProductsPerPage,
  getFetchReportSummaryTotalBranchFilterIsActive,
  getFetchReportSummaryTotalBranchFilterMarketId,
  getFetchReportSummaryTotalMarketFilterPhonePrefix,
  getFetchReportSummaryTotalMarketFilterSectorId,
  getFetchReportSummaryTotalUserFilterEndAt,
  getFetchReportSummaryTotalUserFilterIsActive,
  getFetchReportSummaryTotalUserFilterPhonePrefix,
  getFetchReportSummaryTotalUserFilterStartAt,
  getFetchReportSummaryTotalUserRoles,
  getFetchSummaryBranchesFilterBranchId,
  getFetchSummaryBranchesFilterCashierId,
  getFetchSummaryBranchesFilterEndAt,
  getFetchSummaryBranchesFilterMarketId,
  getFetchSummaryBranchesFilterPaymentMethodKey,
  getFetchSummaryBranchesFilterPeriodType,
  getFetchSummaryBranchesFilterStartAt,
  getFetchSummaryBranchesPage,
  getFetchSummaryBranchesPerPage,
  getFetchSummaryFinancialChartFilterBranchId,
  getFetchSummaryFinancialChartFilterEndAt,
  getFetchSummaryFinancialChartFilterMarketId,
  getFetchSummaryFinancialChartFilterPeriodType,
  getFetchSummaryFinancialChartFilterStartAt,
  getFetchSummaryLatestOrdersFilterBranchId,
  getFetchSummaryLatestOrdersFilterCashierId,
  getFetchSummaryLatestOrdersFilterEndAt,
  getFetchSummaryLatestOrdersFilterMarketId,
  getFetchSummaryLatestOrdersFilterPaymentMethodKey,
  getFetchSummaryLatestOrdersFilterPeriodType,
  getFetchSummaryLatestOrdersFilterStartAt,
  getFetchSummaryLatestOrdersIncludes,
  getFetchSummaryLatestOrdersLimit,
  getFetchSummaryPaymentMethodsFilterBranchId,
  getFetchSummaryPaymentMethodsFilterCashierId,
  getFetchSummaryPaymentMethodsFilterEndAt,
  getFetchSummaryPaymentMethodsFilterMarketId,
  getFetchSummaryPaymentMethodsFilterPaymentMethodKey,
  getFetchSummaryPaymentMethodsFilterPeriodType,
  getFetchSummaryPaymentMethodsFilterStartAt,
  getFetchSummaryPaymentMethodsPage,
  getFetchSummaryPaymentMethodsPerPage,
  getFetchSummarySubscriberReportBranchFilterBranchId,
  getFetchSummarySubscriberReportBranchFilterEndAt,
  getFetchSummarySubscriberReportBranchFilterMarketId,
  getFetchSummarySubscriberReportBranchFilterPeriodType,
  getFetchSummarySubscriberReportBranchFilterStartAt,
  getFetchSummarySubscriberReportFilterBranchId,
  getFetchSummarySubscriberReportFilterCashierId,
  getFetchSummarySubscriberReportFilterEndAt,
  getFetchSummarySubscriberReportFilterMarketId,
  getFetchSummarySubscriberReportFilterPeriodType,
  getFetchSummarySubscriberReportFilterStartAt,
  getFetchSummarySubscribersFilterBranchId,
  getFetchSummarySubscribersFilterCashierId,
  getFetchSummarySubscribersFilterEndAt,
  getFetchSummarySubscribersFilterMarketId,
  getFetchSummarySubscribersFilterPaymentMethodKey,
  getFetchSummarySubscribersFilterPeriodType,
  getFetchSummarySubscribersFilterStartAt,
  getFetchSummarySubscribersPage,
  getFetchSummarySubscribersPerPage,
  getFetchSummaryTopProductCategoriesFilterBranchId,
  getFetchSummaryTopProductCategoriesFilterCashierId,
  getFetchSummaryTopProductCategoriesFilterEndAt,
  getFetchSummaryTopProductCategoriesFilterMarketId,
  getFetchSummaryTopProductCategoriesFilterPaymentMethodKey,
  getFetchSummaryTopProductCategoriesFilterPeriodType,
  getFetchSummaryTopProductCategoriesFilterStartAt,
  getFetchSummaryTopProductCategoriesPage,
  getFetchSummaryTopProductCategoriesPerPage,
} from "./summary.selector";

import {
  getSummaryBranches,
  getSummaryFinancialChart,
  getSummaryFinancialTotal,
  getSummaryLatestOrders,
  getSummaryPaymentMethods,
  getSummarySubscriberReport,
  getSummarySubscribers,
  getSummaryTopProductCategories,
  getSummaryTopProducts,
  getSummaryTotalBranch,
  getSummaryTotalMarket,
  getSummaryTotalUser,
} from "../../api/summary.api";

export function* _getDashboardSummaryFinancialTotal() {
  try {
    yield put(setFetchDashboardSummaryFinancialTotalLoading(true));

    const period_type = yield select(getFetchDashboardSummaryFinancialTotalFilterPeriodType);
    const market_id = yield select(getFetchDashboardSummaryFinancialTotalFilterMarketId);
    const branch_id = yield select(getFetchDashboardSummaryFinancialTotalFilterBranchId);
    const payment_method_key = yield select(getFetchDashboardSummaryFinancialTotalFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchDashboardSummaryFinancialTotalFilterCashierId);
    const start_at = yield select(getFetchDashboardSummaryFinancialTotalFilterStartAt);
    const end_at = yield select(getFetchDashboardSummaryFinancialTotalFilterEndAt);

    const parameters = {
      filter: {
        period_type,
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
        start_at,
        end_at,
      },
    };

    const {
      meta: { message },
      data: total,
    } = yield call(getSummaryFinancialTotal, parameters);

    yield put(setDashboardSummaryFinancialTotal(total));

    yield put(setFetchDashboardSummaryFinancialTotalSuccess(message));
    yield put(setFetchDashboardSummaryFinancialTotalLoading(false));
  } catch (error) {
    yield put(setFetchDashboardSummaryFinancialTotalFailed(error));
    yield put(setFetchDashboardSummaryFinancialTotalLoading(false));
  }
}

export function* _getDashboardSummaryFinancialAllTimeTotal() {
  try {
    yield put(setFetchDashboardSummaryFinancialAllTimeTotalLoading(true));

    const period_type = yield select(getFetchDashboardSummaryFinancialAllTimeTotalFilterPeriodType);
    const market_id = yield select(getFetchDashboardSummaryFinancialAllTimeTotalFilterMarketId);
    const branch_id = yield select(getFetchDashboardSummaryFinancialAllTimeTotalFilterBranchId);
    const payment_method_key = yield select(getFetchDashboardSummaryFinancialAllTimeTotalFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchDashboardSummaryFinancialAllTimeTotalFilterCashierId);
    const start_at = yield select(getFetchDashboardSummaryFinancialAllTimeTotalFilterStartAt);
    const end_at = yield select(getFetchDashboardSummaryFinancialAllTimeTotalFilterEndAt);

    const parameters = {
      filter: {
        period_type,
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
        start_at,
        end_at,
      },
    };

    const {
      meta: { message },
      data: total,
    } = yield call(getSummaryFinancialTotal, parameters);

    yield put(setDashboardSummaryFinancialAllTimeTotal(total));

    yield put(setFetchDashboardSummaryFinancialAllTimeTotalSuccess(message));
    yield put(setFetchDashboardSummaryFinancialAllTimeTotalLoading(false));
  } catch (error) {
    yield put(setFetchDashboardSummaryFinancialAllTimeTotalFailed(error));
    yield put(setFetchDashboardSummaryFinancialAllTimeTotalLoading(false));
  }
}

export function* _getReportSummaryFinancialTotal() {
  try {
    yield put(setFetchReportSummaryFinancialTotalLoading(true));

    const period_type = yield select(getFetchReportSummaryFinancialTotalFilterPeriodType);
    const market_id = yield select(getFetchReportSummaryFinancialTotalFilterMarketId);
    const branch_id = yield select(getFetchReportSummaryFinancialTotalFilterBranchId);
    const payment_method_key = yield select(getFetchReportSummaryFinancialTotalFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchReportSummaryFinancialTotalFilterCashierId);
    const start_at = yield select(getFetchReportSummaryFinancialTotalFilterStartAt);
    const end_at = yield select(getFetchReportSummaryFinancialTotalFilterEndAt);

    const parameters = {
      filter: {
        period_type,
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
        start_at,
        end_at,
      },
    };

    const {
      meta: { message },
      data: total,
    } = yield call(getSummaryFinancialTotal, parameters);

    yield put(setReportSummaryFinancialTotal(total));

    yield put(setFetchReportSummaryFinancialTotalSuccess(message));
    yield put(setFetchReportSummaryFinancialTotalLoading(false));
  } catch (error) {
    yield put(setFetchReportSummaryFinancialTotalFailed(error));
    yield put(setFetchReportSummaryFinancialTotalLoading(false));
  }
}

export function* _getAccountSummaryFinancialTotal() {
  try {
    yield put(setFetchAccountSummaryFinancialTotalLoading(true));

    const period_type = yield select(getFetchAccountSummaryFinancialTotalFilterPeriodType);
    const market_id = yield select(getFetchAccountSummaryFinancialTotalFilterMarketId);
    const branch_id = yield select(getFetchAccountSummaryFinancialTotalFilterBranchId);
    const payment_method_key = yield select(getFetchAccountSummaryFinancialTotalFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchAccountSummaryFinancialTotalFilterCashierId);
    const start_at = yield select(getFetchAccountSummaryFinancialTotalFilterStartAt);
    const end_at = yield select(getFetchAccountSummaryFinancialTotalFilterEndAt);

    const parameters = {
      filter: {
        period_type,
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
        start_at,
        end_at,
      },
    };

    const {
      meta: { message },
      data: total,
    } = yield call(getSummaryFinancialTotal, parameters);

    yield put(setAccountSummaryFinancialTotal(total));

    yield put(setFetchAccountSummaryFinancialTotalSuccess(message));
    yield put(setFetchAccountSummaryFinancialTotalLoading(false));
  } catch (error) {
    yield put(setFetchAccountSummaryFinancialTotalFailed(error));
    yield put(setFetchAccountSummaryFinancialTotalLoading(false));
  }
}

export function* _getSummaryFinancialChart() {
  try {
    yield put(setFetchSummaryFinancialChartLoading(true));

    const period_type = yield select(getFetchSummaryFinancialChartFilterPeriodType);
    const market_id = yield select(getFetchSummaryFinancialChartFilterMarketId);
    const branch_id = yield select(getFetchSummaryFinancialChartFilterBranchId);
    const start_at = yield select(getFetchSummaryFinancialChartFilterStartAt);
    const end_at = yield select(getFetchSummaryFinancialChartFilterEndAt);

    const parameters = {
      filter: {
        period_type,
        market_id,
        branch_id,
        start_at,
        end_at,
      },
    };

    const {
      meta: { message },
      data: chart,
    } = yield call(getSummaryFinancialChart, parameters);

    yield put(setSummaryFinancialChart(chart));

    yield put(setFetchSummaryFinancialChartSuccess(message));
    yield put(setFetchSummaryFinancialChartLoading(false));
  } catch (error) {
    yield put(setFetchSummaryFinancialChartFailed(error));
    yield put(setFetchSummaryFinancialChartLoading(false));
  }
}

export function* _getDashboardSummaryTopProducts() {
  try {
    yield put(setFetchDashboardSummaryTopProductsLoading(true));

    const page = yield select(getFetchDashboardSummaryTopProductsPage);
    const per_page = yield select(getFetchDashboardSummaryTopProductsPerPage);
    const period_type = yield select(getFetchDashboardSummaryTopProductsFilterPeriodType);
    const start_at = yield select(getFetchDashboardSummaryTopProductsFilterStartAt);
    const end_at = yield select(getFetchDashboardSummaryTopProductsFilterEndAt);
    const market_id = yield select(getFetchDashboardSummaryTopProductsFilterMarketId);
    const branch_id = yield select(getFetchDashboardSummaryTopProductsFilterBranchId);
    const payment_method_key = yield select(getFetchDashboardSummaryTopProductsFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchDashboardSummaryTopProductsFilterCashierId);

    const parameters = {
      page,
      per_page,
      filter: {
        period_type,
        start_at,
        end_at,
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: products },
    } = yield call(getSummaryTopProducts, parameters);

    yield put(setIsDashboardSummaryTopProductsHasMore(products.length > 0));

    if (page > 1) {
      yield put(appendDashboardSummaryTopProducts(products));
    } else {
      yield put(setDashboardSummaryTopProducts(products));
    }

    yield put(setFetchDashboardSummaryTopProductsSuccess(message));
    yield put(setFetchDashboardSummaryTopProductsLoading(false));
  } catch (error) {
    yield put(setFetchDashboardSummaryTopProductsFailed(error));
    yield put(setFetchDashboardSummaryTopProductsLoading(false));
  }
}

export function* _getReportSummaryTopProducts() {
  try {
    yield put(setFetchReportSummaryTopProductsLoading(true));

    const page = yield select(getFetchReportSummaryTopProductsPage);
    const per_page = yield select(getFetchReportSummaryTopProductsPerPage);
    const period_type = yield select(getFetchReportSummaryTopProductsFilterPeriodType);
    const start_at = yield select(getFetchReportSummaryTopProductsFilterStartAt);
    const end_at = yield select(getFetchReportSummaryTopProductsFilterEndAt);
    const market_id = yield select(getFetchReportSummaryTopProductsFilterMarketId);
    const branch_id = yield select(getFetchReportSummaryTopProductsFilterBranchId);
    const payment_method_key = yield select(getFetchReportSummaryTopProductsFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchReportSummaryTopProductsFilterCashierId);

    const parameters = {
      page,
      per_page,
      filter: {
        period_type,
        start_at,
        end_at,
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: products },
    } = yield call(getSummaryTopProducts, parameters);

    yield put(setIsReportSummaryTopProductsHasMore(products.length > 0));

    if (page > 1) {
      yield put(appendReportSummaryTopProducts(products));
    } else {
      yield put(setReportSummaryTopProducts(products));
    }

    yield put(setFetchReportSummaryTopProductsSuccess(message));
    yield put(setFetchReportSummaryTopProductsLoading(false));
  } catch (error) {
    yield put(setFetchReportSummaryTopProductsFailed(error));
    yield put(setFetchReportSummaryTopProductsLoading(false));
  }
}

export function* _getSummaryTopProductCategories() {
  try {
    yield put(setFetchSummaryTopProductCategoriesLoading(true));

    const page = yield select(getFetchSummaryTopProductCategoriesPage);
    const per_page = yield select(getFetchSummaryTopProductCategoriesPerPage);
    const period_type = yield select(getFetchSummaryTopProductCategoriesFilterPeriodType);
    const start_at = yield select(getFetchSummaryTopProductCategoriesFilterStartAt);
    const end_at = yield select(getFetchSummaryTopProductCategoriesFilterEndAt);
    const market_id = yield select(getFetchSummaryTopProductCategoriesFilterMarketId);
    const branch_id = yield select(getFetchSummaryTopProductCategoriesFilterBranchId);
    const payment_method_key = yield select(getFetchSummaryTopProductCategoriesFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchSummaryTopProductCategoriesFilterCashierId);

    const parameters = {
      page,
      per_page,
      filter: {
        period_type,
        start_at,
        end_at,
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: productCategories },
    } = yield call(getSummaryTopProductCategories, parameters);

    yield put(setIsSummaryTopProductCategoriesHasMore(productCategories.length > 0));

    if (page > 1) {
      yield put(appendSummaryTopProductCategories(productCategories));
    } else {
      yield put(setSummaryTopProductCategories(productCategories));
    }

    yield put(setFetchSummaryTopProductCategoriesSuccess(message));
    yield put(setFetchSummaryTopProductCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchSummaryTopProductCategoriesFailed(error));
    yield put(setFetchSummaryTopProductCategoriesLoading(false));
  }
}

export function* _getSummaryPaymentMethods() {
  try {
    yield put(setFetchSummaryPaymentMethodsLoading(true));

    const page = yield select(getFetchSummaryPaymentMethodsPage);
    const per_page = yield select(getFetchSummaryPaymentMethodsPerPage);
    const period_type = yield select(getFetchSummaryPaymentMethodsFilterPeriodType);
    const start_at = yield select(getFetchSummaryPaymentMethodsFilterStartAt);
    const end_at = yield select(getFetchSummaryPaymentMethodsFilterEndAt);
    const market_id = yield select(getFetchSummaryPaymentMethodsFilterMarketId);
    const branch_id = yield select(getFetchSummaryPaymentMethodsFilterBranchId);
    const payment_method_key = yield select(getFetchSummaryPaymentMethodsFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchSummaryPaymentMethodsFilterCashierId);

    const parameters = {
      page,
      per_page,
      filter: {
        period_type,
        start_at,
        end_at,
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: paymentMethods },
    } = yield call(getSummaryPaymentMethods, parameters);

    yield put(setIsSummaryPaymentMethodsHasMore(paymentMethods.length > 0));

    if (page > 1) {
      yield put(appendSummaryPaymentMethods(paymentMethods));
    } else {
      yield put(setSummaryPaymentMethods(paymentMethods));
    }

    yield put(setFetchSummaryPaymentMethodsSuccess(message));
    yield put(setFetchSummaryPaymentMethodsLoading(false));
  } catch (error) {
    yield put(setFetchSummaryPaymentMethodsFailed(error));
    yield put(setFetchSummaryPaymentMethodsLoading(false));
  }
}

export function* _getSummarySubscribers() {
  try {
    yield put(setFetchSummarySubscribersLoading(true));

    const page = yield select(getFetchSummarySubscribersPage);
    const per_page = yield select(getFetchSummarySubscribersPerPage);
    const period_type = yield select(getFetchSummarySubscribersFilterPeriodType);
    const start_at = yield select(getFetchSummarySubscribersFilterStartAt);
    const end_at = yield select(getFetchSummarySubscribersFilterEndAt);
    const market_id = yield select(getFetchSummarySubscribersFilterMarketId);
    const branch_id = yield select(getFetchSummarySubscribersFilterBranchId);
    const payment_method_key = yield select(getFetchSummarySubscribersFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchSummarySubscribersFilterCashierId);

    const parameters = {
      page,
      per_page,
      filter: {
        period_type,
        start_at,
        end_at,
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: subscribers },
    } = yield call(getSummarySubscribers, parameters);

    yield put(setIsSummarySubscribersHasMore(subscribers.length > 0));

    if (page > 1) {
      yield put(appendSummarySubscribers(subscribers));
    } else {
      yield put(setSummarySubscribers(subscribers));
    }

    yield put(setFetchSummarySubscribersSuccess(message));
    yield put(setFetchSummarySubscribersLoading(false));
  } catch (error) {
    yield put(setFetchSummarySubscribersFailed(error));
    yield put(setFetchSummarySubscribersLoading(false));
  }
}

export function* _getSummaryBranches() {
  try {
    yield put(setFetchSummaryBranchesLoading(true));

    const page = yield select(getFetchSummaryBranchesPage);
    const per_page = yield select(getFetchSummaryBranchesPerPage);
    const period_type = yield select(getFetchSummaryBranchesFilterPeriodType);
    const start_at = yield select(getFetchSummaryBranchesFilterStartAt);
    const end_at = yield select(getFetchSummaryBranchesFilterEndAt);
    const market_id = yield select(getFetchSummaryBranchesFilterMarketId);
    const branch_id = yield select(getFetchSummaryBranchesFilterBranchId);
    const payment_method_key = yield select(getFetchSummaryBranchesFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchSummaryBranchesFilterCashierId);

    const parameters = {
      page,
      per_page,
      filter: {
        period_type,
        start_at,
        end_at,
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: branches },
    } = yield call(getSummaryBranches, parameters);

    yield put(setIsSummaryBranchesHasMore(branches.length > 0));

    if (page > 1) {
      yield put(appendSummaryBranches(branches));
    } else {
      yield put(setSummaryBranches(branches));
    }

    yield put(setFetchSummaryBranchesSuccess(message));
    yield put(setFetchSummaryBranchesLoading(false));
  } catch (error) {
    yield put(setFetchSummaryBranchesFailed(error));
    yield put(setFetchSummaryBranchesLoading(false));
  }
}

export function* _getSummaryLatestOrders() {
  try {
    yield put(setFetchSummaryLatestOrdersLoading(true));

    const limit = yield select(getFetchSummaryLatestOrdersLimit);
    const includes = yield select(getFetchSummaryLatestOrdersIncludes);
    const period_type = yield select(getFetchSummaryLatestOrdersFilterPeriodType);
    const start_at = yield select(getFetchSummaryLatestOrdersFilterStartAt);
    const end_at = yield select(getFetchSummaryLatestOrdersFilterEndAt);
    const market_id = yield select(getFetchSummaryLatestOrdersFilterMarketId);
    const branch_id = yield select(getFetchSummaryLatestOrdersFilterBranchId);
    const payment_method_key = yield select(getFetchSummaryLatestOrdersFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchSummaryLatestOrdersFilterCashierId);

    const parameters = {
      limit,
      includes,
      filter: {
        period_type,
        start_at,
        end_at,
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: orders,
    } = yield call(getSummaryLatestOrders, parameters);

    yield put(setSummaryLatestOrders(orders));

    yield put(setFetchSummaryLatestOrdersSuccess(message));
    yield put(setFetchSummaryLatestOrdersLoading(false));
  } catch (error) {
    yield put(setFetchSummaryLatestOrdersFailed(error));
    yield put(setFetchSummaryLatestOrdersLoading(false));
  }
}

export function* _getSummarySubscriberReport() {
  try {
    yield put(setFetchSummarySubscriberReportLoading(true));

    const period_type = yield select(getFetchSummarySubscriberReportFilterPeriodType);
    const start_at = yield select(getFetchSummarySubscriberReportFilterStartAt);
    const end_at = yield select(getFetchSummarySubscriberReportFilterEndAt);
    const market_id = yield select(getFetchSummarySubscriberReportFilterMarketId);
    const branch_id = yield select(getFetchSummarySubscriberReportFilterBranchId);
    const cashier_id = yield select(getFetchSummarySubscriberReportFilterCashierId);

    const parameters = {
      filter: {
        period_type,
        start_at,
        end_at,
        market_id,
        branch_id,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: subscriberReport,
    } = yield call(getSummarySubscriberReport, parameters);

    yield put(setSummarySubscriberReport(subscriberReport));

    yield put(setFetchSummarySubscriberReportSuccess(message));
    yield put(setFetchSummarySubscriberReportLoading(false));
  } catch (error) {
    yield put(setFetchSummarySubscriberReportFailed(error));
    yield put(setFetchSummarySubscriberReportLoading(false));
  }
}

export function* _getSummarySubscriberReportBranch() {
  try {
    yield put(setFetchSummarySubscriberReportBranchLoading(true));

    const period_type = yield select(getFetchSummarySubscriberReportBranchFilterPeriodType);
    const start_at = yield select(getFetchSummarySubscriberReportBranchFilterStartAt);
    const end_at = yield select(getFetchSummarySubscriberReportBranchFilterEndAt);
    const market_id = yield select(getFetchSummarySubscriberReportBranchFilterMarketId);
    const branch_id = yield select(getFetchSummarySubscriberReportBranchFilterBranchId);

    const parameters = {
      filter: {
        period_type,
        start_at,
        end_at,
        market_id,
        branch_id,
      },
    };

    const {
      meta: { message },
      data: subscriberReport,
    } = yield call(getSummarySubscriberReport, parameters);

    yield put(setSummarySubscriberReportBranch(subscriberReport));

    yield put(setFetchSummarySubscriberReportBranchSuccess(message));
    yield put(setFetchSummarySubscriberReportBranchLoading(false));
  } catch (error) {
    yield put(setFetchSummarySubscriberReportBranchFailed(error));
    yield put(setFetchSummarySubscriberReportBranchLoading(false));
  }
}

export function* _getDashboardSummaryTotalUser() {
  try {
    yield put(setFetchDashboardSummaryTotalUserLoading(true));

    const roles = yield select(getFetchDashboardSummaryTotalUserRoles);
    const start_at = yield select(getFetchDashboardSummaryTotalUserFilterStartAt);
    const end_at = yield select(getFetchDashboardSummaryTotalUserFilterEndAt);
    const phone_prefix = yield select(getFetchDashboardSummaryTotalUserFilterPhonePrefix);
    const is_active = yield select(getFetchDashboardSummaryTotalUserFilterIsActive);

    const parameters = {
      roles,
      filter: {
        start_at,
        end_at,
        phone_prefix,
        is_active,
      },
    };

    const {
      meta: { message },
      data: totalUser,
    } = yield call(getSummaryTotalUser, parameters);

    yield put(setDashboardSummaryTotalUser(totalUser));

    yield put(setFetchDashboardSummaryTotalUserSuccess(message));
    yield put(setFetchDashboardSummaryTotalUserLoading(false));
  } catch (error) {
    yield put(setFetchDashboardSummaryTotalUserFailed(error));
    yield put(setFetchDashboardSummaryTotalUserLoading(false));
  }
}

export function* _getDashboardSummaryTotalMarket() {
  try {
    yield put(setFetchDashboardSummaryTotalMarketLoading(true));

    const sector_id = yield select(getFetchDashboardSummaryTotalMarketFilterSectorId);
    const phone_prefix = yield select(getFetchDashboardSummaryTotalMarketFilterPhonePrefix);

    const parameters = { filter: { sector_id, phone_prefix } };

    const {
      meta: { message },
      data: totalMarket,
    } = yield call(getSummaryTotalMarket, parameters);

    yield put(setDashboardSummaryTotalMarket(totalMarket));

    yield put(setFetchDashboardSummaryTotalMarketSuccess(message));
    yield put(setFetchDashboardSummaryTotalMarketLoading(false));
  } catch (error) {
    yield put(setFetchDashboardSummaryTotalMarketFailed(error));
    yield put(setFetchDashboardSummaryTotalMarketLoading(false));
  }
}

export function* _getDashboardSummaryTotalBranch() {
  try {
    yield put(setFetchDashboardSummaryTotalBranchLoading(true));

    const market_id = yield select(getFetchDashboardSummaryTotalBranchFilterMarketId);
    const is_active = yield select(getFetchDashboardSummaryTotalBranchFilterIsActive);

    const parameters = { filter: { market_id, is_active } };

    const {
      meta: { message },
      data: totalBranch,
    } = yield call(getSummaryTotalBranch, parameters);

    yield put(setDashboardSummaryTotalBranch(totalBranch));

    yield put(setFetchDashboardSummaryTotalBranchSuccess(message));
    yield put(setFetchDashboardSummaryTotalBranchLoading(false));
  } catch (error) {
    yield put(setFetchDashboardSummaryTotalBranchFailed(error));
    yield put(setFetchDashboardSummaryTotalBranchLoading(false));
  }
}

export function* _getReportSummaryTotalUser() {
  try {
    yield put(setFetchReportSummaryTotalUserLoading(true));

    const roles = yield select(getFetchReportSummaryTotalUserRoles);
    const start_at = yield select(getFetchReportSummaryTotalUserFilterStartAt);
    const end_at = yield select(getFetchReportSummaryTotalUserFilterEndAt);
    const phone_prefix = yield select(getFetchReportSummaryTotalUserFilterPhonePrefix);
    const is_active = yield select(getFetchReportSummaryTotalUserFilterIsActive);

    const parameters = {
      roles,
      filter: {
        start_at,
        end_at,
        phone_prefix,
        is_active,
      },
    };

    const {
      meta: { message },
      data: totalUser,
    } = yield call(getSummaryTotalUser, parameters);

    yield put(setReportSummaryTotalUser(totalUser));

    yield put(setFetchReportSummaryTotalUserSuccess(message));
    yield put(setFetchReportSummaryTotalUserLoading(false));
  } catch (error) {
    yield put(setFetchReportSummaryTotalUserFailed(error));
    yield put(setFetchReportSummaryTotalUserLoading(false));
  }
}

export function* _getReportSummaryTotalMarket() {
  try {
    yield put(setFetchReportSummaryTotalMarketLoading(true));

    const sector_id = yield select(getFetchReportSummaryTotalMarketFilterSectorId);
    const phone_prefix = yield select(getFetchReportSummaryTotalMarketFilterPhonePrefix);

    const parameters = { filter: { sector_id, phone_prefix } };

    const {
      meta: { message },
      data: totalMarket,
    } = yield call(getSummaryTotalMarket, parameters);

    yield put(setReportSummaryTotalMarket(totalMarket));

    yield put(setFetchReportSummaryTotalMarketSuccess(message));
    yield put(setFetchReportSummaryTotalMarketLoading(false));
  } catch (error) {
    yield put(setFetchReportSummaryTotalMarketFailed(error));
    yield put(setFetchReportSummaryTotalMarketLoading(false));
  }
}

export function* _getReportSummaryTotalBranch() {
  try {
    yield put(setFetchReportSummaryTotalBranchLoading(true));

    const market_id = yield select(getFetchReportSummaryTotalBranchFilterMarketId);
    const is_active = yield select(getFetchReportSummaryTotalBranchFilterIsActive);

    const parameters = { filter: { market_id, is_active } };

    const {
      meta: { message },
      data: totalBranch,
    } = yield call(getSummaryTotalBranch, parameters);

    yield put(setReportSummaryTotalBranch(totalBranch));

    yield put(setFetchReportSummaryTotalBranchSuccess(message));
    yield put(setFetchReportSummaryTotalBranchLoading(false));
  } catch (error) {
    yield put(setFetchReportSummaryTotalBranchFailed(error));
    yield put(setFetchReportSummaryTotalBranchLoading(false));
  }
}

export function* onFetchDashboardSummaryFinancialTotal() {
  yield takeLatest(
    SUMMARY_ACTION_TYPES.FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_START,
    _getDashboardSummaryFinancialTotal
  );
}

export function* onFetchDashboardSummaryFinancialAllTimeTotal() {
  yield takeLatest(
    SUMMARY_ACTION_TYPES.FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_START,
    _getDashboardSummaryFinancialAllTimeTotal
  );
}

export function* onFetchReportSummaryFinancialTotal() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_START, _getReportSummaryFinancialTotal);
}

export function* onFetchAccountSummaryFinancialTotal() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_START, _getAccountSummaryFinancialTotal);
}

export function* onFetchSummaryFinancialChart() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_FINANCIAL_CHART_START, _getSummaryFinancialChart);
}

export function* onFetchDashboardSummaryTopProducts() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_START, _getDashboardSummaryTopProducts);
}

export function* onFetchReportSummaryTopProducts() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_REPORT_SUMMARY_TOP_PRODUCTS_START, _getReportSummaryTopProducts);
}

export function* onFetchSummaryTopProductCategories() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_TOP_PRODUCT_CATEGORIES_START, _getSummaryTopProductCategories);
}

export function* onFetchSummaryPaymentMethods() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_PAYMENT_METHODS_START, _getSummaryPaymentMethods);
}
export function* onFetchSummarySubscribers() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_SUBSCRIBERS_START, _getSummarySubscribers);
}

export function* onFetchSummaryBranches() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_BRANCHES_START, _getSummaryBranches);
}

export function* onFetchSummaryLatestOrders() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_LATEST_ORDERS_START, _getSummaryLatestOrders);
}

export function* onFetchSummarySubscriberReport() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_SUBSCRIBER_REPORT_START, _getSummarySubscriberReport);
}

export function* onFetchSummarySubscriberReportBranch() {
  yield takeLatest(
    SUMMARY_ACTION_TYPES.FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_START,
    _getSummarySubscriberReportBranch
  );
}

export function* onFetchDashboardSummaryTotalUser() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_DASHBOARD_SUMMARY_TOTAL_USER_START, _getDashboardSummaryTotalUser);
}

export function* onFetchDashboardSummaryTotalMarket() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_START, _getDashboardSummaryTotalMarket);
}

export function* onFetchDashboardSummaryTotalBranch() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_START, _getDashboardSummaryTotalBranch);
}

export function* onFetchReportSummaryTotalUser() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_REPORT_SUMMARY_TOTAL_USER_START, _getReportSummaryTotalUser);
}

export function* onFetchReportSummaryTotalMarket() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_REPORT_SUMMARY_TOTAL_MARKET_START, _getReportSummaryTotalMarket);
}

export function* onFetchReportSummaryTotalBranch() {
  yield takeLatest(SUMMARY_ACTION_TYPES.FETCH_REPORT_SUMMARY_TOTAL_BRANCH_START, _getReportSummaryTotalBranch);
}

export function* summarySaga() {
  yield all([
    call(onFetchDashboardSummaryFinancialTotal),
    call(onFetchDashboardSummaryFinancialAllTimeTotal),
    call(onFetchReportSummaryFinancialTotal),
    call(onFetchAccountSummaryFinancialTotal),
    call(onFetchSummaryFinancialChart),
    call(onFetchDashboardSummaryTopProducts),
    call(onFetchReportSummaryTopProducts),
    call(onFetchSummaryTopProductCategories),
    call(onFetchSummaryPaymentMethods),
    call(onFetchSummarySubscribers),
    call(onFetchSummaryBranches),
    call(onFetchSummaryLatestOrders),
    call(onFetchSummarySubscriberReport),
    call(onFetchSummarySubscriberReportBranch),
    call(onFetchDashboardSummaryTotalUser),
    call(onFetchDashboardSummaryTotalMarket),
    call(onFetchDashboardSummaryTotalBranch),
    call(onFetchReportSummaryTotalUser),
    call(onFetchReportSummaryTotalMarket),
    call(onFetchReportSummaryTotalBranch),
  ]);
}
