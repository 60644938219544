import SELECT_SUPPLIER_ACTION_TYPES from "./select-supplier.type";

const SELECT_SUPPLIER_INITIAL_STATE = {
  suppliers: {},
  searchSuppliers: {},

  isSuppliersHasMore: true,
  isSearchSuppliersHasMore: true,

  fetchSuppliersParams: {},
  fetchSuppliersLoading: false,
  fetchSuppliersSuccess: null,
  fetchSuppliersFailed: null,

  searchSuppliersParams: {},
  searchSuppliersLoading: false,
  searchSuppliersSuccess: null,
  searchSuppliersFailed: null,

  appendSuppliersParams: {},
  appendSuppliersLoading: false,
  appendSuppliersSuccess: null,
  appendSuppliersFailed: null,

  isFetchSuppliersHitted: false,
  isSearchSuppliersHitted: false,
  isAppendSuppliersHitted: false,
};

export const selectSupplierReducer = (
  state = SELECT_SUPPLIER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_SUPPLIER_ACTION_TYPES.SET_SUPPLIERS:
      return { ...state, suppliers: payload };
    case SELECT_SUPPLIER_ACTION_TYPES.SET_SEARCH_SUPPLIERS:
      return { ...state, searchSuppliers: payload };

    case SELECT_SUPPLIER_ACTION_TYPES.SET_IS_SUPPLIERS_HAS_MORE:
      return { ...state, isSuppliersHasMore: payload };
    case SELECT_SUPPLIER_ACTION_TYPES.SET_IS_SEARCH_SUPPLIERS_HAS_MORE:
      return { ...state, isSearchSuppliersHasMore: payload };

    case SELECT_SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_PARAMS:
      return { ...state, fetchSuppliersParams: payload };
    case SELECT_SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_LOADING:
      return { ...state, fetchSuppliersLoading: payload };
    case SELECT_SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_SUCCESS:
      return { ...state, fetchSuppliersSuccess: payload };
    case SELECT_SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_FAILED:
      return { ...state, fetchSuppliersFailed: payload };

    case SELECT_SUPPLIER_ACTION_TYPES.SET_SEARCH_SUPPLIERS_PARAMS:
      return { ...state, searchSuppliersParams: payload };
    case SELECT_SUPPLIER_ACTION_TYPES.SET_SEARCH_SUPPLIERS_LOADING:
      return { ...state, searchSuppliersLoading: payload };
    case SELECT_SUPPLIER_ACTION_TYPES.SET_SEARCH_SUPPLIERS_SUCCESS:
      return { ...state, searchSuppliersSuccess: payload };
    case SELECT_SUPPLIER_ACTION_TYPES.SET_SEARCH_SUPPLIERS_FAILED:
      return { ...state, searchSuppliersFailed: payload };

    case SELECT_SUPPLIER_ACTION_TYPES.SET_APPEND_SUPPLIERS_PARAMS:
      return { ...state, appendSuppliersParams: payload };
    case SELECT_SUPPLIER_ACTION_TYPES.SET_APPEND_SUPPLIERS_LOADING:
      return { ...state, appendSuppliersLoading: payload };
    case SELECT_SUPPLIER_ACTION_TYPES.SET_APPEND_SUPPLIERS_SUCCESS:
      return { ...state, appendSuppliersSuccess: payload };
    case SELECT_SUPPLIER_ACTION_TYPES.SET_APPEND_SUPPLIERS_FAILED:
      return { ...state, appendSuppliersFailed: payload };

    case SELECT_SUPPLIER_ACTION_TYPES.SET_IS_FETCH_SUPPLIERS_HITTED:
      return { ...state, isFetchSuppliersHitted: payload };
    case SELECT_SUPPLIER_ACTION_TYPES.SET_IS_SEARCH_SUPPLIERS_HITTED:
      return { ...state, isSearchSuppliersHitted: payload };
    case SELECT_SUPPLIER_ACTION_TYPES.SET_IS_APPEND_SUPPLIERS_HITTED:
      return { ...state, isAppendSuppliersHitted: payload };

    case SELECT_SUPPLIER_ACTION_TYPES.APPEND_SUPPLIERS:
      return {
        ...state,
        suppliers: { ...state.suppliers, ...payload },
      };
    case SELECT_SUPPLIER_ACTION_TYPES.APPEND_SEARCH_SUPPLIERS:
      return {
        ...state,
        searchSuppliers: { ...state.searchSuppliers, ...payload },
      };

    case SELECT_SUPPLIER_ACTION_TYPES.RESET_SELECT_SUPPLIER_REDUCER:
      return SELECT_SUPPLIER_INITIAL_STATE;
    default:
      return state;
  }
};
