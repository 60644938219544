import { createSelector } from "reselect";

const dashboardSelector = ({ dashboard }) => dashboard;

export const getDashboard = createSelector(
  [dashboardSelector],
  ({ dashboard }) => dashboard
);
export const getDashboardSubscriber = createSelector(
  [dashboardSelector],
  ({ dashboardSubscriber }) => dashboardSubscriber
);

export const getFetchDashboardFilterMarketId = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFilterMarketId }) => fetchDashboardFilterMarketId
);
export const getFetchDashboardFilterBranchId = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFilterBranchId }) => fetchDashboardFilterBranchId
);
export const getFetchDashboardFilterTimePeriod = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFilterTimePeriod }) => fetchDashboardFilterTimePeriod
);
export const getFetchDashboardFilterCreatedAtBefore = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFilterCreatedAtBefore }) =>
    fetchDashboardFilterCreatedAtBefore
);
export const getFetchDashboardFilterCreatedAtAfter = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFilterCreatedAtAfter }) => fetchDashboardFilterCreatedAtAfter
);
export const getFetchDashboardFilterCurrency = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFilterCurrency }) => fetchDashboardFilterCurrency
);
export const getFetchDashboardLoading = createSelector(
  [dashboardSelector],
  ({ fetchDashboardLoading }) => fetchDashboardLoading
);
export const getFetchDashboardSuccess = createSelector(
  [dashboardSelector],
  ({ fetchDashboardSuccess }) => fetchDashboardSuccess
);
export const getFetchDashboardFailed = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFailed }) => fetchDashboardFailed
);

export const getFetchDashboardSubscriberFilterMarketId = createSelector(
  [dashboardSelector],
  ({ fetchDashboardSubscriberFilterMarketId }) =>
    fetchDashboardSubscriberFilterMarketId
);
export const getFetchDashboardSubscriberFilterBranchId = createSelector(
  [dashboardSelector],
  ({ fetchDashboardSubscriberFilterBranchId }) =>
    fetchDashboardSubscriberFilterBranchId
);
export const getFetchDashboardSubscriberFilterOrderStatuses = createSelector(
  [dashboardSelector],
  ({ fetchDashboardSubscriberFilterOrderStatuses }) =>
    fetchDashboardSubscriberFilterOrderStatuses
);
export const getFetchDashboardSubscriberFilterTransactionStatuses =
  createSelector(
    [dashboardSelector],
    ({ fetchDashboardSubscriberFilterTransactionStatuses }) =>
      fetchDashboardSubscriberFilterTransactionStatuses
  );
export const getFetchDashboardSubscriberFilterStartAt = createSelector(
  [dashboardSelector],
  ({ fetchDashboardSubscriberFilterStartAt }) =>
    fetchDashboardSubscriberFilterStartAt
);
export const getFetchDashboardSubscriberFilterEndAt = createSelector(
  [dashboardSelector],
  ({ fetchDashboardSubscriberFilterEndAt }) =>
    fetchDashboardSubscriberFilterEndAt
);
export const getFetchDashboardSubscriberLoading = createSelector(
  [dashboardSelector],
  ({ fetchDashboardSubscriberLoading }) => fetchDashboardSubscriberLoading
);
export const getFetchDashboardSubscriberSuccess = createSelector(
  [dashboardSelector],
  ({ fetchDashboardSubscriberSuccess }) => fetchDashboardSubscriberSuccess
);
export const getFetchDashboardSubscriberFailed = createSelector(
  [dashboardSelector],
  ({ fetchDashboardSubscriberFailed }) => fetchDashboardSubscriberFailed
);

export const getIsFetchDashboardHitted = createSelector(
  [dashboardSelector],
  ({ isFetchDashboardHitted }) => isFetchDashboardHitted
);
export const getIsFetchDashboardSubscriberHitted = createSelector(
  [dashboardSelector],
  ({ isFetchDashboardSubscriberHitted }) => isFetchDashboardSubscriberHitted
);
