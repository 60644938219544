const PAYMENT_METHOD_SETTING_ACTION_TYPES = {
  RESET_PAYMENT_METHOD_SETTING_REDUCER:
    "payment-method-setting/RESET_PAYMENT_METHOD_SETTING_REDUCER",

  SET_IS_MODAL_AUTO_CLOSE: "payment-method-setting/SET_IS_MODAL_AUTO_CLOSE",
  SET_PAYMENT_METHOD_SETTINGS:
    "payment-method-setting/SET_PAYMENT_METHOD_SETTINGS",
  SET_SELECT_PAYMENT_METHOD_SETTINGS:
    "payment-method-setting/SET_SELECT_PAYMENT_METHOD_SETTINGS",
  SET_ACTIVE_PAYMENT_METHOD_SETTINGS:
    "payment-method-setting/SET_ACTIVE_PAYMENT_METHOD_SETTINGS",
  SET_PAYMENT_METHOD_SETTING:
    "payment-method-setting/SET_PAYMENT_METHOD_SETTING",

  SET_IS_PAYMENT_METHOD_SETTINGS_HAS_MORE:
    "payment-method-setting/SET_IS_PAYMENT_METHOD_SETTINGS_HAS_MORE",
  SET_IS_SELECT_PAYMENT_METHOD_SETTINGS_HAS_MORE:
    "payment-method-setting/SET_IS_SELECT_PAYMENT_METHOD_SETTINGS_HAS_MORE",
  SET_IS_ACTIVE_PAYMENT_METHOD_SETTINGS_HAS_MORE:
    "payment-method-setting/SET_IS_ACTIVE_PAYMENT_METHOD_SETTINGS_HAS_MORE",

  SET_FETCH_PAYMENT_METHOD_SETTINGS_SORT:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_SORT",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_PAGE:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_PAGE",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_PER_PAGE:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_PER_PAGE",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_INCLUDES:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_INCLUDES",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_IS_ACTIVE:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_IS_ACTIVE",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_LOADING:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_LOADING",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS",
  SET_FETCH_PAYMENT_METHOD_SETTINGS_FAILED:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTINGS_FAILED",

  SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_SORT:
    "payment-method-setting/SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_SORT",
  SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_PAGE:
    "payment-method-setting/SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_PAGE",
  SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_PER_PAGE:
    "payment-method-setting/SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_PER_PAGE",
  SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_INCLUDES:
    "payment-method-setting/SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_INCLUDES",
  SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID:
    "payment-method-setting/SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID",
  SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_FILTER_IS_ACTIVE:
    "payment-method-setting/SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_FILTER_IS_ACTIVE",
  SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_LOADING:
    "payment-method-setting/SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_LOADING",
  SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_SUCCESS:
    "payment-method-setting/SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_SUCCESS",
  SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_FAILED:
    "payment-method-setting/SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_FAILED",

  SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_SORT:
    "payment-method-setting/SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_SORT",
  SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_PAGE:
    "payment-method-setting/SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_PAGE",
  SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_PER_PAGE:
    "payment-method-setting/SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_PER_PAGE",
  SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_INCLUDES:
    "payment-method-setting/SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_INCLUDES",
  SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID:
    "payment-method-setting/SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID",
  SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_LOADING:
    "payment-method-setting/SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_LOADING",
  SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_SUCCESS:
    "payment-method-setting/SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_SUCCESS",
  SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_FAILED:
    "payment-method-setting/SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_FAILED",

  SET_FETCH_PAYMENT_METHOD_SETTING_LOADING:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTING_LOADING",
  SET_FETCH_PAYMENT_METHOD_SETTING_SUCCESS:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTING_SUCCESS",
  SET_FETCH_PAYMENT_METHOD_SETTING_FAILED:
    "payment-method-setting/SET_FETCH_PAYMENT_METHOD_SETTING_FAILED",

  SET_CREATE_PAYMENT_METHOD_SETTING_LOADING:
    "payment-method-setting/SET_CREATE_PAYMENT_METHOD_SETTING_LOADING",
  SET_CREATE_PAYMENT_METHOD_SETTING_SUCCESS:
    "payment-method-setting/SET_CREATE_PAYMENT_METHOD_SETTING_SUCCESS",
  SET_CREATE_PAYMENT_METHOD_SETTING_FAILED:
    "payment-method-setting/SET_CREATE_PAYMENT_METHOD_SETTING_FAILED",

  SET_UPDATE_PAYMENT_METHOD_SETTING_LOADING:
    "payment-method-setting/SET_UPDATE_PAYMENT_METHOD_SETTING_LOADING",
  SET_UPDATE_PAYMENT_METHOD_SETTING_SUCCESS:
    "payment-method-setting/SET_UPDATE_PAYMENT_METHOD_SETTING_SUCCESS",
  SET_UPDATE_PAYMENT_METHOD_SETTING_FAILED:
    "payment-method-setting/SET_UPDATE_PAYMENT_METHOD_SETTING_FAILED",

  SET_DELETE_PAYMENT_METHOD_SETTING_LOADING:
    "payment-method-setting/SET_DELETE_PAYMENT_METHOD_SETTING_LOADING",
  SET_DELETE_PAYMENT_METHOD_SETTING_SUCCESS:
    "payment-method-setting/SET_DELETE_PAYMENT_METHOD_SETTING_SUCCESS",
  SET_DELETE_PAYMENT_METHOD_SETTING_FAILED:
    "payment-method-setting/SET_DELETE_PAYMENT_METHOD_SETTING_FAILED",

  APPEND_PAYMENT_METHOD_SETTINGS:
    "payment-method-setting/APPEND_PAYMENT_METHOD_SETTINGS",
  APPEND_SELECT_PAYMENT_METHOD_SETTINGS:
    "payment-method-setting/APPEND_SELECT_PAYMENT_METHOD_SETTINGS",
  APPEND_ACTIVE_PAYMENT_METHOD_SETTINGS:
    "payment-method-setting/APPEND_ACTIVE_PAYMENT_METHOD_SETTINGS",

  SET_IS_FETCH_PAYMENT_METHOD_SETTINGS_HITTED:
    "payment-method-setting/SET_IS_FETCH_PAYMENT_METHOD_SETTINGS_HITTED",
  SET_IS_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_HITTED:
    "payment-method-setting/SET_IS_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_HITTED",
  SET_IS_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_HITTED:
    "payment-method-setting/SET_IS_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_HITTED",
  SET_IS_FETCH_PAYMENT_METHOD_SETTING_HITTED:
    "payment-method-setting/SET_IS_FETCH_PAYMENT_METHOD_SETTING_HITTED",
  SET_IS_CREATE_PAYMENT_METHOD_SETTING_HITTED:
    "payment-method-setting/SET_IS_CREATE_PAYMENT_METHOD_SETTING_HITTED",
  SET_IS_UPDATE_PAYMENT_METHOD_SETTING_HITTED:
    "payment-method-setting/SET_IS_UPDATE_PAYMENT_METHOD_SETTING_HITTED",
  SET_IS_DELETE_PAYMENT_METHOD_SETTING_HITTED:
    "payment-method-setting/SET_IS_DELETE_PAYMENT_METHOD_SETTING_HITTED",

  FETCH_PAYMENT_METHOD_SETTINGS_START:
    "payment-method-setting/FETCH_PAYMENT_METHOD_SETTINGS_START",
  FETCH_SELECT_PAYMENT_METHOD_SETTINGS_START:
    "payment-method-setting/FETCH_SELECT_PAYMENT_METHOD_SETTINGS_START",
  FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_START:
    "payment-method-setting/FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_START",
  FETCH_PAYMENT_METHOD_SETTING_START:
    "payment-method-setting/FETCH_PAYMENT_METHOD_SETTING_START",
  CREATE_PAYMENT_METHOD_SETTING_START:
    "payment-method-setting/CREATE_PAYMENT_METHOD_SETTING_START",
  UPDATE_PAYMENT_METHOD_SETTING_START:
    "payment-method-setting/UPDATE_PAYMENT_METHOD_SETTING_START",
  DELETE_PAYMENT_METHOD_SETTING_START:
    "payment-method-setting/DELETE_PAYMENT_METHOD_SETTING_START",
};

export default PAYMENT_METHOD_SETTING_ACTION_TYPES;
