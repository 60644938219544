import { PAYMENT_METHOD_SETTING_VALUE_TYPES } from "../../constants/payment-method-setting.constant";

import PAYMENT_METHOD_SETTING_ACTION_TYPES from "./payment-method-setting.type";

export const PAYMENT_METHOD_SETTING_INITIAL_STATE = {
  isModalAutoClose: true,

  paymentMethodSettingValueTypes: [
    {
      label: "Percentage %",
      value: PAYMENT_METHOD_SETTING_VALUE_TYPES.PERCENT,
    },
    {
      label: "Fixed Price",
      value: PAYMENT_METHOD_SETTING_VALUE_TYPES.NOMINAL,
    },
  ],
  paymentMethodSettings: [],
  selectPaymentMethodSettings: [],
  activePaymentMethodSettings: [],
  paymentMethodSetting: null,

  isPaymentMethodSettingsHasMore: true,
  isSelectPaymentMethodSettingsHasMore: true,
  isActivePaymentMethodSettingsHasMore: true,

  fetchPaymentMethodSettingsSort: null,
  fetchPaymentMethodSettingsPage: 1,
  fetchPaymentMethodSettingsPerPage: null,
  fetchPaymentMethodSettingsIncludes: null,
  fetchPaymentMethodSettingsFilterMarketId: null,
  fetchPaymentMethodSettingsFilterIsActive: null,
  fetchPaymentMethodSettingsLoading: false,
  fetchPaymentMethodSettingsSuccess: null,
  fetchPaymentMethodSettingsFailed: null,

  fetchSelectPaymentMethodSettingsSort: null,
  fetchSelectPaymentMethodSettingsPage: 1,
  fetchSelectPaymentMethodSettingsPerPage: null,
  fetchSelectPaymentMethodSettingsIncludes: null,
  fetchSelectPaymentMethodSettingsFilterMarketId: null,
  fetchSelectPaymentMethodSettingsFilterIsActive: null,
  fetchSelectPaymentMethodSettingsLoading: false,
  fetchSelectPaymentMethodSettingsSuccess: null,
  fetchSelectPaymentMethodSettingsFailed: null,

  fetchActivePaymentMethodSettingsSort: null,
  fetchActivePaymentMethodSettingsPage: 1,
  fetchActivePaymentMethodSettingsPerPage: null,
  fetchActivePaymentMethodSettingsIncludes: null,
  fetchActivePaymentMethodSettingsFilterMarketId: null,
  fetchActivePaymentMethodSettingsLoading: false,
  fetchActivePaymentMethodSettingsSuccess: null,
  fetchActivePaymentMethodSettingsFailed: null,

  fetchPaymentMethodSettingLoading: false,
  fetchPaymentMethodSettingSuccess: null,
  fetchPaymentMethodSettingFailed: null,

  createPaymentMethodSettingLoading: false,
  createPaymentMethodSettingSuccess: null,
  createPaymentMethodSettingFailed: null,

  updatePaymentMethodSettingLoading: false,
  updatePaymentMethodSettingSuccess: null,
  updatePaymentMethodSettingFailed: null,

  deletePaymentMethodSettingLoading: false,
  deletePaymentMethodSettingSuccess: null,
  deletePaymentMethodSettingFailed: null,

  isFetchPaymentMethodSettingsHitted: false,
  isFetchSelectPaymentMethodSettingsHitted: false,
  isFetchActivePaymentMethodSettingsHitted: false,
  isFetchPaymentMethodSettingHitted: false,
  isCreatePaymentMethodSettingHitted: false,
  isUpdatePaymentMethodSettingHitted: false,
  isDeletePaymentMethodSettingHitted: false,
};

export const paymentMethodSettingReducer = (
  state = PAYMENT_METHOD_SETTING_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE:
      return { ...state, isModalAutoClose: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_PAYMENT_METHOD_SETTINGS:
      return { ...state, paymentMethodSettings: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_SELECT_PAYMENT_METHOD_SETTINGS:
      return { ...state, selectPaymentMethodSettings: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_ACTIVE_PAYMENT_METHOD_SETTINGS:
      return { ...state, activePaymentMethodSettings: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_PAYMENT_METHOD_SETTING:
      return { ...state, paymentMethodSetting: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_PAYMENT_METHOD_SETTINGS_HAS_MORE:
      return { ...state, isPaymentMethodSettingsHasMore: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_SELECT_PAYMENT_METHOD_SETTINGS_HAS_MORE:
      return { ...state, isSelectPaymentMethodSettingsHasMore: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_ACTIVE_PAYMENT_METHOD_SETTINGS_HAS_MORE:
      return { ...state, isActivePaymentMethodSettingsHasMore: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_SORT:
      return { ...state, fetchPaymentMethodSettingsSort: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_PAGE:
      return { ...state, fetchPaymentMethodSettingsPage: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_PER_PAGE:
      return { ...state, fetchPaymentMethodSettingsPerPage: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_INCLUDES:
      return { ...state, fetchPaymentMethodSettingsIncludes: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID:
      return { ...state, fetchPaymentMethodSettingsFilterMarketId: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_IS_ACTIVE:
      return { ...state, fetchPaymentMethodSettingsFilterIsActive: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_LOADING:
      return { ...state, fetchPaymentMethodSettingsLoading: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS:
      return { ...state, fetchPaymentMethodSettingsSuccess: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FAILED:
      return { ...state, fetchPaymentMethodSettingsFailed: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_SORT:
      return { ...state, fetchSelectPaymentMethodSettingsSort: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_PAGE:
      return { ...state, fetchSelectPaymentMethodSettingsPage: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_PER_PAGE:
      return { ...state, fetchSelectPaymentMethodSettingsPerPage: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_INCLUDES:
      return { ...state, fetchSelectPaymentMethodSettingsIncludes: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID:
      return {
        ...state,
        fetchSelectPaymentMethodSettingsFilterMarketId: payload,
      };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_FILTER_IS_ACTIVE:
      return {
        ...state,
        fetchSelectPaymentMethodSettingsFilterIsActive: payload,
      };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_LOADING:
      return { ...state, fetchSelectPaymentMethodSettingsLoading: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_SUCCESS:
      return { ...state, fetchSelectPaymentMethodSettingsSuccess: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_FAILED:
      return { ...state, fetchSelectPaymentMethodSettingsFailed: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_SORT:
      return { ...state, fetchActivePaymentMethodSettingsSort: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_PAGE:
      return { ...state, fetchActivePaymentMethodSettingsPage: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_PER_PAGE:
      return { ...state, fetchActivePaymentMethodSettingsPerPage: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_INCLUDES:
      return { ...state, fetchActivePaymentMethodSettingsIncludes: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID:
      return {
        ...state,
        fetchActivePaymentMethodSettingsFilterMarketId: payload,
      };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_LOADING:
      return { ...state, fetchActivePaymentMethodSettingsLoading: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_SUCCESS:
      return { ...state, fetchActivePaymentMethodSettingsSuccess: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_FAILED:
      return { ...state, fetchActivePaymentMethodSettingsFailed: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTING_LOADING:
      return { ...state, fetchPaymentMethodSettingLoading: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTING_SUCCESS:
      return { ...state, fetchPaymentMethodSettingSuccess: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTING_FAILED:
      return { ...state, fetchPaymentMethodSettingFailed: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_CREATE_PAYMENT_METHOD_SETTING_LOADING:
      return { ...state, createPaymentMethodSettingLoading: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_CREATE_PAYMENT_METHOD_SETTING_SUCCESS:
      return { ...state, createPaymentMethodSettingSuccess: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_CREATE_PAYMENT_METHOD_SETTING_FAILED:
      return { ...state, createPaymentMethodSettingFailed: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_UPDATE_PAYMENT_METHOD_SETTING_LOADING:
      return { ...state, updatePaymentMethodSettingLoading: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_UPDATE_PAYMENT_METHOD_SETTING_SUCCESS:
      return { ...state, updatePaymentMethodSettingSuccess: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_UPDATE_PAYMENT_METHOD_SETTING_FAILED:
      return { ...state, updatePaymentMethodSettingFailed: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_DELETE_PAYMENT_METHOD_SETTING_LOADING:
      return { ...state, deletePaymentMethodSettingLoading: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_DELETE_PAYMENT_METHOD_SETTING_SUCCESS:
      return { ...state, deletePaymentMethodSettingSuccess: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_DELETE_PAYMENT_METHOD_SETTING_FAILED:
      return { ...state, deletePaymentMethodSettingFailed: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.APPEND_PAYMENT_METHOD_SETTINGS:
      return {
        ...state,
        paymentMethodSettings: [...state.paymentMethodSettings, ...payload],
      };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.APPEND_SELECT_PAYMENT_METHOD_SETTINGS:
      return {
        ...state,
        selectPaymentMethodSettings: [
          ...state.selectPaymentMethodSettings,
          ...payload,
        ],
      };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.APPEND_ACTIVE_PAYMENT_METHOD_SETTINGS:
      return {
        ...state,
        activePaymentMethodSettings: [
          ...state.activePaymentMethodSettings,
          ...payload,
        ],
      };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_FETCH_PAYMENT_METHOD_SETTINGS_HITTED:
      return { ...state, isFetchPaymentMethodSettingsHitted: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_HITTED:
      return { ...state, isFetchSelectPaymentMethodSettingsHitted: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_HITTED:
      return { ...state, isFetchActivePaymentMethodSettingsHitted: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_FETCH_PAYMENT_METHOD_SETTING_HITTED:
      return { ...state, isFetchPaymentMethodSettingHitted: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_CREATE_PAYMENT_METHOD_SETTING_HITTED:
      return { ...state, isCreatePaymentMethodSettingHitted: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_UPDATE_PAYMENT_METHOD_SETTING_HITTED:
      return { ...state, isUpdatePaymentMethodSettingHitted: payload };
    case PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_DELETE_PAYMENT_METHOD_SETTING_HITTED:
      return { ...state, isDeletePaymentMethodSettingHitted: payload };

    case PAYMENT_METHOD_SETTING_ACTION_TYPES.RESET_PAYMENT_METHOD_SETTING_REDUCER:
      return PAYMENT_METHOD_SETTING_INITIAL_STATE;
    default:
      return state;
  }
};
