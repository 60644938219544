const GLOBAL_ACTION_TYPES = {
  RESET_GLOBAL_REDUCER: "global/RESET_GLOBAL_REDUCER",

  SET_IS_RIGHT_TO_LEFT: "global/SET_IS_RIGHT_TO_LEFT",
  SET_IS_GLOBAL_INITIALIZED: "global/SET_IS_GLOBAL_INITIALIZED",
  SET_IS_GLOBAL_LOADING: "global/SET_IS_GLOBAL_LOADING",
  SET_CURRENT_LANGUAGE: "global/SET_CURRENT_LANGUAGE",

  SET_AUTHORIZE_PAGES: "global/SET_AUTHORIZE_PAGES",
  SET_IS_MARKET_ADMINISTRATOR: "global/SET_IS_MARKET_ADMINISTRATOR",
  SET_IS_CENTER_ADMINISTRATOR: "global/SET_IS_CENTER_ADMINISTRATOR",

  SET_CURRENT_MARKET: "global/SET_CURRENT_MARKET",
  SET_CURRENT_BRANCH: "global/SET_CURRENT_BRANCH",
  SET_CURRENT_MERCHANT: "global/SET_CURRENT_MERCHANT",
  SET_CURRENT_PERMISSION: "global/SET_CURRENT_PERMISSION",
  SET_CURRENT_SCHEDULES: "global/SET_CURRENT_SCHEDULES",

  SET_CURRENT_MARKET_ID: "global/SET_CURRENT_MARKET_ID",
  SET_CURRENT_BRANCH_ID: "global/SET_CURRENT_BRANCH_ID",
  SET_CURRENT_MERCHANT_ID: "global/SET_CURRENT_MERCHANT_ID",
  SET_CURRENT_PERMISSION_ID: "global/SET_CURRENT_PERMISSION_ID",

  SET_PERMISSION_LIMITED_PAGES: "global/SET_PERMISSION_LIMITED_PAGES",
  SET_IS_CURRENT_KITCHEN_ACTIVE: "global/SET_IS_CURRENT_KITCHEN_ACTIVE",

  SET_TODAY_SCHEDULE: "global/SET_TODAY_SCHEDULE",
  SET_IS_TODAY_IN_SCHEDULE: "global/SET_IS_TODAY_IN_SCHEDULE",

  SET_TODAY_ATTENDANCE: "global/SET_TODAY_ATTENDANCE",
  SET_IS_TODAY_HAS_ATTENDANCE: "global/SET_IS_TODAY_HAS_ATTENDANCE",

  SET_MARKET_PLANS: "global/SET_MARKET_PLANS",
  SET_BRANCH_PLANS: "global/SET_BRANCH_PLANS",

  SET_IS_HAS_ACTIVE_PLAN: "global/SET_IS_HAS_ACTIVE_PLAN",
  SET_IS_HAS_ACTIVE_ADVANCED_PLAN: "global/SET_IS_HAS_ACTIVE_ADVANCED_PLAN",
  SET_IS_HAS_ACTIVE_BASIC_PLAN: "global/SET_IS_HAS_ACTIVE_BASIC_PLAN",
  SET_IS_HAS_ACTIVE_TRIAL_PLAN: "global/SET_IS_HAS_ACTIVE_TRIAL_PLAN",

  SET_IS_MARKET_HAS_PLAN: "global/SET_IS_MARKET_HAS_PLAN",
  SET_IS_MARKET_HAS_ADVANCED_PLAN: "global/SET_IS_MARKET_HAS_ADVANCED_PLAN",
  SET_IS_MARKET_HAS_BASIC_PLAN: "global/SET_IS_MARKET_HAS_BASIC_PLAN",
  SET_IS_MARKET_HAS_TRIAL_PLAN: "global/SET_IS_MARKET_HAS_TRIAL_PLAN",

  SET_IS_BRANCH_HAS_PLAN: "global/SET_IS_BRANCH_HAS_PLAN",
  SET_IS_BRANCH_HAS_ADVANCED_PLAN: "global/SET_IS_BRANCH_HAS_ADVANCED_PLAN",
  SET_IS_BRANCH_HAS_BASIC_PLAN: "global/SET_IS_BRANCH_HAS_BASIC_PLAN",
  SET_IS_BRANCH_HAS_TRIAL_PLAN: "global/SET_IS_BRANCH_HAS_TRIAL_PLAN",

  SET_PLAN_BILLING_CYCLES: "global/SET_PLAN_BILLING_CYCLES",
  SET_GLOBAL_SUBSCRIPTIONS: "global/SET_GLOBAL_SUBSCRIPTIONS",
  SET_GLOBAL_FEATURE_SUBSCRIPTIONS: "global/SET_GLOBAL_FEATURE_SUBSCRIPTIONS",
  APPEND_GLOBAL_SUBSCRIPTIONS: "global/APPEND_GLOBAL_SUBSCRIPTIONS",
  APPEND_GLOBAL_FEATURE_SUBSCRIPTIONS:
    "global/APPEND_GLOBAL_FEATURE_SUBSCRIPTIONS",

  SET_IS_GLOBAL_SUBSCRIPTIONS_HAS_MORE:
    "global/SET_IS_GLOBAL_SUBSCRIPTIONS_HAS_MORE",
  SET_IS_GLOBAL_FEATURE_SUBSCRIPTIONS_HAS_MORE:
    "global/SET_IS_GLOBAL_FEATURE_SUBSCRIPTIONS_HAS_MORE",

  SET_FETCH_CURRENT_MARKET_LOADING: "global/SET_FETCH_CURRENT_MARKET_LOADING",
  SET_FETCH_CURRENT_MARKET_SUCCESS: "global/SET_FETCH_CURRENT_MARKET_SUCCESS",
  SET_FETCH_CURRENT_MARKET_FAILED: "global/SET_FETCH_CURRENT_MARKET_FAILED",

  SET_FETCH_CURRENT_BRANCH_LOADING: "global/SET_FETCH_CURRENT_BRANCH_LOADING",
  SET_FETCH_CURRENT_BRANCH_SUCCESS: "global/SET_FETCH_CURRENT_BRANCH_SUCCESS",
  SET_FETCH_CURRENT_BRANCH_FAILED: "global/SET_FETCH_CURRENT_BRANCH_FAILED",

  SET_FETCH_CURRENT_MERCHANT_LOADING:
    "global/SET_FETCH_CURRENT_MERCHANT_LOADING",
  SET_FETCH_CURRENT_MERCHANT_SUCCESS:
    "global/SET_FETCH_CURRENT_MERCHANT_SUCCESS",
  SET_FETCH_CURRENT_MERCHANT_FAILED: "global/SET_FETCH_CURRENT_MERCHANT_FAILED",

  SET_FETCH_CURRENT_PERMISSION_LOADING:
    "global/SET_FETCH_CURRENT_PERMISSION_LOADING",
  SET_FETCH_CURRENT_PERMISSION_SUCCESS:
    "global/SET_FETCH_CURRENT_PERMISSION_SUCCESS",
  SET_FETCH_CURRENT_PERMISSION_FAILED:
    "global/SET_FETCH_CURRENT_PERMISSION_FAILED",

  SET_FETCH_CURRENT_SCHEDULES_INCLUDES:
    "global/SET_FETCH_CURRENT_SCHEDULES_INCLUDES",
  SET_FETCH_CURRENT_SCHEDULES_FILTER_MARKET_ID:
    "global/SET_FETCH_CURRENT_SCHEDULES_FILTER_MARKET_ID",
  SET_FETCH_CURRENT_SCHEDULES_FILTER_BRANCH_ID:
    "global/SET_FETCH_CURRENT_SCHEDULES_FILTER_BRANCH_ID",
  SET_FETCH_CURRENT_SCHEDULES_LOADING:
    "global/SET_FETCH_CURRENT_SCHEDULES_LOADING",
  SET_FETCH_CURRENT_SCHEDULES_SUCCESS:
    "global/SET_FETCH_CURRENT_SCHEDULES_SUCCESS",
  SET_FETCH_CURRENT_SCHEDULES_FAILED:
    "global/SET_FETCH_CURRENT_SCHEDULES_FAILED",

  SET_FETCH_TODAY_ATTENDANCE_MARKET_ID:
    "global/SET_FETCH_TODAY_ATTENDANCE_MARKET_ID",
  SET_FETCH_TODAY_ATTENDANCE_BRANCH_ID:
    "global/SET_FETCH_TODAY_ATTENDANCE_BRANCH_ID",
  SET_FETCH_TODAY_ATTENDANCE_LOADING:
    "global/SET_FETCH_TODAY_ATTENDANCE_LOADING",
  SET_FETCH_TODAY_ATTENDANCE_SUCCESS:
    "global/SET_FETCH_TODAY_ATTENDANCE_SUCCESS",
  SET_FETCH_TODAY_ATTENDANCE_FAILED: "global/SET_FETCH_TODAY_ATTENDANCE_FAILED",

  SET_FETCH_GLOBAL_SUBSCRIPTIONS_SEARCH:
    "global/SET_FETCH_GLOBAL_SUBSCRIPTIONS_SEARCH",
  SET_FETCH_GLOBAL_SUBSCRIPTIONS_SORT:
    "global/SET_FETCH_GLOBAL_SUBSCRIPTIONS_SORT",
  SET_FETCH_GLOBAL_SUBSCRIPTIONS_KEY_BY:
    "global/SET_FETCH_GLOBAL_SUBSCRIPTIONS_KEY_BY",
  SET_FETCH_GLOBAL_SUBSCRIPTIONS_PAGE:
    "global/SET_FETCH_GLOBAL_SUBSCRIPTIONS_PAGE",
  SET_FETCH_GLOBAL_SUBSCRIPTIONS_PER_PAGE:
    "global/SET_FETCH_GLOBAL_SUBSCRIPTIONS_PER_PAGE",
  SET_FETCH_GLOBAL_SUBSCRIPTIONS_INCLUDES:
    "global/SET_FETCH_GLOBAL_SUBSCRIPTIONS_INCLUDES",
  SET_FETCH_GLOBAL_SUBSCRIPTIONS_LOADING:
    "global/SET_FETCH_GLOBAL_SUBSCRIPTIONS_LOADING",
  SET_FETCH_GLOBAL_SUBSCRIPTIONS_SUCCESS:
    "global/SET_FETCH_GLOBAL_SUBSCRIPTIONS_SUCCESS",
  SET_FETCH_GLOBAL_SUBSCRIPTIONS_FAILED:
    "global/SET_FETCH_GLOBAL_SUBSCRIPTIONS_FAILED",

  SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_SEARCH:
    "global/SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_SEARCH",
  SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_SORT:
    "global/SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_SORT",
  SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_KEY_BY:
    "global/SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_KEY_BY",
  SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_PAGE:
    "global/SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_PAGE",
  SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_PER_PAGE:
    "global/SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_PER_PAGE",
  SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_INCLUDES:
    "global/SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_INCLUDES",
  SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_FILTER_IS_HIGHLIGHT:
    "global/SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_FILTER_IS_HIGHLIGHT",
  SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_LOADING:
    "global/SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_LOADING",
  SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_SUCCESS:
    "global/SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_SUCCESS",
  SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_FAILED:
    "global/SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_FAILED",

  SET_IS_FETCH_CURRENT_MARKET_HITTED:
    "global/SET_IS_FETCH_CURRENT_MARKET_HITTED",
  SET_IS_FETCH_CURRENT_BRANCH_HITTED:
    "global/SET_IS_FETCH_CURRENT_BRANCH_HITTED",
  SET_IS_FETCH_CURRENT_MERCHANT_HITTED:
    "global/SET_IS_FETCH_CURRENT_MERCHANT_HITTED",
  SET_IS_FETCH_CURRENT_PERMISSION_HITTED:
    "global/SET_IS_FETCH_CURRENT_PERMISSION_HITTED",
  SET_IS_FETCH_CURRENT_SCHEDULES_HITTED:
    "global/SET_IS_FETCH_CURRENT_SCHEDULES_HITTED",
  SET_IS_FETCH_TODAY_ATTENDANCE_HITTED:
    "global/SET_IS_FETCH_TODAY_ATTENDANCE_HITTED",
  SET_IS_FETCH_GLOBAL_SUBSCRIPTIONS_HITTED:
    "global/SET_IS_FETCH_GLOBAL_SUBSCRIPTIONS_HITTED",
  SET_IS_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_HITTED:
    "global/SET_IS_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_HITTED",

  FETCH_CURRENT_MARKET_START: "global/FETCH_CURRENT_MARKET_START",
  FETCH_CURRENT_BRANCH_START: "global/FETCH_CURRENT_BRANCH_START",
  FETCH_CURRENT_MERCHANT_START: "global/FETCH_CURRENT_MERCHANT_START",
  FETCH_CURRENT_PERMISSION_START: "global/FETCH_CURRENT_PERMISSION_START",
  FETCH_CURRENT_SCHEDULES_START: "global/FETCH_CURRENT_SCHEDULES_START",
  FETCH_TODAY_ATTENDANCE_START: "global/FETCH_TODAY_ATTENDANCE_START",
  FETCH_GLOBAL_SUBSCRIPTIONS_START: "global/FETCH_GLOBAL_SUBSCRIPTIONS_START",
  FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_START:
    "global/FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_START",
};

export default GLOBAL_ACTION_TYPES;
