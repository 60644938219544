const REPORT_ACTION_TYPES = {
  RESET_REPORT_REDUCER: "report/RESET_REPORT_REDUCER",

  SET_PREV_REPORT_TOTALS: "report/SET_PREV_REPORT_TOTALS",
  SET_NEXT_REPORT_TOTALS: "report/SET_NEXT_REPORT_TOTALS",

  SET_REPORT_CATEGORIES: "report/SET_REPORT_CATEGORIES",
  SET_REPORT_PRODUCTS: "report/SET_REPORT_PRODUCTS",
  SET_REPORT_CASHIERS: "report/SET_REPORT_CASHIERS",
  SET_REPORT_PAYMENTS: "report/SET_REPORT_PAYMENTS",
  SET_REPORT_TABLES: "report/SET_REPORT_TABLES",

  SET_IS_REPORT_CATEGORIES_HAS_MORE: "report/SET_IS_REPORT_CATEGORIES_HAS_MORE",
  SET_IS_REPORT_PRODUCTS_HAS_MORE: "report/SET_IS_REPORT_PRODUCTS_HAS_MORE",
  SET_IS_REPORT_CASHIERS_HAS_MORE: "report/SET_IS_REPORT_CASHIERS_HAS_MORE",
  SET_IS_REPORT_PAYMENTS_HAS_MORE: "report/SET_IS_REPORT_PAYMENTS_HAS_MORE",
  SET_IS_REPORT_TABLES_HAS_MORE: "report/SET_IS_REPORT_TABLES_HAS_MORE",

  FETCH_REPORTS_FILTER_MARKET_ID: "report/FETCH_REPORTS_FILTER_MARKET_ID",
  FETCH_REPORTS_FILTER_BRANCH_ID: "report/FETCH_REPORTS_FILTER_BRANCH_ID",
  FETCH_REPORTS_FILTER_CASHIER_ID: "report/FETCH_REPORTS_FILTER_CASHIER_ID",
  FETCH_REPORTS_FILTER_CUSTOMER_ID: "report/FETCH_REPORTS_FILTER_CUSTOMER_ID",
  FETCH_REPORTS_FILTER_CREATED_AT_AFTER:
    "report/FETCH_REPORTS_FILTER_CREATED_AT_AFTER",
  FETCH_REPORTS_FILTER_CREATED_AT_BEFORE:
    "report/FETCH_REPORTS_FILTER_CREATED_AT_BEFORE",
  FETCH_REPORTS_FILTER_ORDER_STATUSES:
    "report/FETCH_REPORTS_FILTER_ORDER_STATUSES",
  FETCH_REPORTS_FILTER_TRANSACTION_STATUSES:
    "report/FETCH_REPORTS_FILTER_TRANSACTION_STATUSES",

  FETCH_PREV_REPORT_TOTALS_FILTER_CREATED_AT_AFTER:
    "report/FETCH_PREV_REPORT_TOTALS_FILTER_CREATED_AT_AFTER",
  FETCH_PREV_REPORT_TOTALS_FILTER_CREATED_AT_BEFORE:
    "report/FETCH_PREV_REPORT_TOTALS_FILTER_CREATED_AT_BEFORE",
  FETCH_PREV_REPORT_TOTALS_LOADING: "report/FETCH_PREV_REPORT_TOTALS_LOADING",
  FETCH_PREV_REPORT_TOTALS_SUCCESS: "report/FETCH_PREV_REPORT_TOTALS_SUCCESS",
  FETCH_PREV_REPORT_TOTALS_FAILED: "report/FETCH_PREV_REPORT_TOTALS_FAILED",

  FETCH_NEXT_REPORT_TOTALS_FILTER_CREATED_AT_AFTER:
    "report/FETCH_NEXT_REPORT_TOTALS_FILTER_CREATED_AT_AFTER",
  FETCH_NEXT_REPORT_TOTALS_FILTER_CREATED_AT_BEFORE:
    "report/FETCH_NEXT_REPORT_TOTALS_FILTER_CREATED_AT_BEFORE",
  FETCH_NEXT_REPORT_TOTALS_LOADING: "report/FETCH_NEXT_REPORT_TOTALS_LOADING",
  FETCH_NEXT_REPORT_TOTALS_SUCCESS: "report/FETCH_NEXT_REPORT_TOTALS_SUCCESS",
  FETCH_NEXT_REPORT_TOTALS_FAILED: "report/FETCH_NEXT_REPORT_TOTALS_FAILED",

  FETCH_REPORT_CATEGORIES_SEARCH: "report/FETCH_REPORT_CATEGORIES_SEARCH",
  FETCH_REPORT_CATEGORIES_SORT: "report/FETCH_REPORT_CATEGORIES_SORT",
  FETCH_REPORT_CATEGORIES_KEY_BY: "report/FETCH_REPORT_CATEGORIES_KEY_BY",
  FETCH_REPORT_CATEGORIES_PAGE: "report/FETCH_REPORT_CATEGORIES_PAGE",
  FETCH_REPORT_CATEGORIES_PER_PAGE: "report/FETCH_REPORT_CATEGORIES_PER_PAGE",
  FETCH_REPORT_CATEGORIES_FILTER_PRODUCT_STATUSES:
    "report/FETCH_REPORT_CATEGORIES_FILTER_PRODUCT_STATUSES",
  FETCH_REPORT_CATEGORIES_LOADING: "report/FETCH_REPORT_CATEGORIES_LOADING",
  FETCH_REPORT_CATEGORIES_SUCCESS: "report/FETCH_REPORT_CATEGORIES_SUCCESS",
  FETCH_REPORT_CATEGORIES_FAILED: "report/FETCH_REPORT_CATEGORIES_FAILED",

  FETCH_REPORT_PRODUCTS_SEARCH: "report/FETCH_REPORT_PRODUCTS_SEARCH",
  FETCH_REPORT_PRODUCTS_SORT: "report/FETCH_REPORT_PRODUCTS_SORT",
  FETCH_REPORT_PRODUCTS_KEY_BY: "report/FETCH_REPORT_PRODUCTS_KEY_BY",
  FETCH_REPORT_PRODUCTS_PAGE: "report/FETCH_REPORT_PRODUCTS_PAGE",
  FETCH_REPORT_PRODUCTS_PER_PAGE: "report/FETCH_REPORT_PRODUCTS_PER_PAGE",
  FETCH_REPORT_PRODUCTS_FILTER_PRODUCT_STATUSES:
    "report/FETCH_REPORT_PRODUCTS_FILTER_PRODUCT_STATUSES",
  FETCH_REPORT_PRODUCTS_LOADING: "report/FETCH_REPORT_PRODUCTS_LOADING",
  FETCH_REPORT_PRODUCTS_SUCCESS: "report/FETCH_REPORT_PRODUCTS_SUCCESS",
  FETCH_REPORT_PRODUCTS_FAILED: "report/FETCH_REPORT_PRODUCTS_FAILED",

  FETCH_REPORT_CASHIERS_SEARCH: "report/FETCH_REPORT_CASHIERS_SEARCH",
  FETCH_REPORT_CASHIERS_SORT: "report/FETCH_REPORT_CASHIERS_SORT",
  FETCH_REPORT_CASHIERS_KEY_BY: "report/FETCH_REPORT_CASHIERS_KEY_BY",
  FETCH_REPORT_CASHIERS_PAGE: "report/FETCH_REPORT_CASHIERS_PAGE",
  FETCH_REPORT_CASHIERS_PER_PAGE: "report/FETCH_REPORT_CASHIERS_PER_PAGE",
  FETCH_REPORT_CASHIERS_LOADING: "report/FETCH_REPORT_CASHIERS_LOADING",
  FETCH_REPORT_CASHIERS_SUCCESS: "report/FETCH_REPORT_CASHIERS_SUCCESS",
  FETCH_REPORT_CASHIERS_FAILED: "report/FETCH_REPORT_CASHIERS_FAILED",

  FETCH_REPORT_PAYMENTS_SEARCH: "report/FETCH_REPORT_PAYMENTS_SEARCH",
  FETCH_REPORT_PAYMENTS_SORT: "report/FETCH_REPORT_PAYMENTS_SORT",
  FETCH_REPORT_PAYMENTS_KEY_BY: "report/FETCH_REPORT_PAYMENTS_KEY_BY",
  FETCH_REPORT_PAYMENTS_PAGE: "report/FETCH_REPORT_PAYMENTS_PAGE",
  FETCH_REPORT_PAYMENTS_PER_PAGE: "report/FETCH_REPORT_PAYMENTS_PER_PAGE",
  FETCH_REPORT_PAYMENTS_FILTER_PAYMENT_STATUSES:
    "report/FETCH_REPORT_PAYMENTS_FILTER_PAYMENT_STATUSES",
  FETCH_REPORT_PAYMENTS_LOADING: "report/FETCH_REPORT_PAYMENTS_LOADING",
  FETCH_REPORT_PAYMENTS_SUCCESS: "report/FETCH_REPORT_PAYMENTS_SUCCESS",
  FETCH_REPORT_PAYMENTS_FAILED: "report/FETCH_REPORT_PAYMENTS_FAILED",

  FETCH_REPORT_TABLES_SEARCH: "report/FETCH_REPORT_TABLES_SEARCH",
  FETCH_REPORT_TABLES_SORT: "report/FETCH_REPORT_TABLES_SORT",
  FETCH_REPORT_TABLES_KEY_BY: "report/FETCH_REPORT_TABLES_KEY_BY",
  FETCH_REPORT_TABLES_PAGE: "report/FETCH_REPORT_TABLES_PAGE",
  FETCH_REPORT_TABLES_PER_PAGE: "report/FETCH_REPORT_TABLES_PER_PAGE",
  FETCH_REPORT_TABLES_LOADING: "report/FETCH_REPORT_TABLES_LOADING",
  FETCH_REPORT_TABLES_SUCCESS: "report/FETCH_REPORT_TABLES_SUCCESS",
  FETCH_REPORT_TABLES_FAILED: "report/FETCH_REPORT_TABLES_FAILED",

  APPEND_REPORT_CATEGORIES: "report/APPEND_REPORT_CATEGORIES",
  APPEND_REPORT_PRODUCTS: "report/APPEND_REPORT_PRODUCTS",
  APPEND_REPORT_CASHIERS: "report/APPEND_REPORT_CASHIERS",
  APPEND_REPORT_PAYMENTS: "report/APPEND_REPORT_PAYMENTS",
  APPEND_REPORT_TABLES: "report/APPEND_REPORT_TABLES",

  SET_IS_FETCH_PREV_REPORT_TOTALS_HITTED:
    "report/SET_IS_FETCH_PREV_REPORT_TOTALS_HITTED",
  SET_IS_FETCH_NEXT_REPORT_TOTALS_HITTED:
    "report/SET_IS_FETCH_NEXT_REPORT_TOTALS_HITTED",
  SET_IS_FETCH_REPORT_CATEGORIES_HITTED:
    "report/SET_IS_FETCH_REPORT_CATEGORIES_HITTED",
  SET_IS_FETCH_REPORT_PRODUCTS_HITTED:
    "report/SET_IS_FETCH_REPORT_PRODUCTS_HITTED",
  SET_IS_FETCH_REPORT_CASHIERS_HITTED:
    "report/SET_IS_FETCH_REPORT_CASHIERS_HITTED",
  SET_IS_FETCH_REPORT_PAYMENTS_HITTED:
    "report/SET_IS_FETCH_REPORT_PAYMENTS_HITTED",
  SET_IS_FETCH_REPORT_TABLES_HITTED: "report/SET_IS_FETCH_REPORT_TABLES_HITTED",

  FETCH_PREV_REPORT_TOTALS_START: "report/FETCH_PREV_REPORT_TOTALS_START",
  FETCH_NEXT_REPORT_TOTALS_START: "report/FETCH_NEXT_REPORT_TOTALS_START",
  FETCH_REPORT_CATEGORIES_START: "report/FETCH_REPORT_CATEGORIES_START",
  FETCH_REPORT_PRODUCTS_START: "report/FETCH_REPORT_PRODUCTS_START",
  FETCH_REPORT_CASHIERS_START: "report/FETCH_REPORT_CASHIERS_START",
  FETCH_REPORT_PAYMENTS_START: "report/FETCH_REPORT_PAYMENTS_START",
  FETCH_REPORT_TABLES_START: "report/FETCH_REPORT_TABLES_START",
};

export default REPORT_ACTION_TYPES;
