import apiService from "./api";

export const getOrderProducts = async (orderId, parameters) =>
  (await apiService.get(`/order/${orderId}`, parameters)).data;

export const getOrderProduct = async (orderId, orderProductId) =>
  (await apiService.get(`/order/${orderId}/product/${orderProductId}`)).data;

export const updateOrderProduct = async (orderId, orderProductId, request) =>
  (await apiService.post(`/order/${orderId}/product/${orderProductId}`, request)).data;
