import apiService from "./api";

export const getPaymentDevices = async (parameters) => (await apiService.get("/payment-device", parameters)).data;

export const getPaymentDevice = async (id) => (await apiService.get(`/payment-device/${id}`)).data;

export const createPaymentDevice = async (request) => (await apiService.post("/payment-device", request)).data;

export const updatePaymentDevice = async (id, request) => (await apiService.post(`/payment-device/${id}`, request)).data;

export const deletePaymentDevice = async (id) => (await apiService.delete(`/payment-device/${id}`)).data;
