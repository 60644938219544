import INGREDIENT_ACTION_TYPES from "./ingredient.type";

export const INGREDIENT_INITIAL_STATE = {
  ingredients: [],
  selectIngredients: {},
  selectGroupIngredients: {},
  outOfStockIngredients: [],
  ingredient: null,

  isIngredientsHasMore: true,
  isSelectIngredientsHasMore: true,
  isSelectGroupIngredientsHasMore: true,
  isOutOfStockIngredientsHasMore: true,

  fetchIngredientsSearch: null,
  fetchIngredientsSort: null,
  fetchIngredientsKeyBy: null,
  fetchIngredientsPage: 1,
  fetchIngredientsPerPage: null,
  fetchIngredientsIncludes: null,
  fetchIngredientsFilterMarketId: null,
  fetchIngredientsFilterBranchId: null,
  fetchIngredientsFilterIsOutOfStock: null,
  fetchIngredientsLoading: false,
  fetchIngredientsSuccess: null,
  fetchIngredientsFailed: null,

  fetchSelectIngredientsSearch: null,
  fetchSelectIngredientsSort: null,
  fetchSelectIngredientsKeyBy: null,
  fetchSelectIngredientsPage: 1,
  fetchSelectIngredientsPerPage: null,
  fetchSelectIngredientsIncludes: null,
  fetchSelectIngredientsFilterMarketId: null,
  fetchSelectIngredientsFilterBranchId: null,
  fetchSelectIngredientsFilterIngredientsIds: null,
  fetchSelectIngredientsFilterIsGroup: null,
  fetchSelectIngredientsLoading: false,
  fetchSelectIngredientsSuccess: null,
  fetchSelectIngredientsFailed: null,

  fetchSelectGroupIngredientsSearch: null,
  fetchSelectGroupIngredientsSort: null,
  fetchSelectGroupIngredientsKeyBy: null,
  fetchSelectGroupIngredientsPage: 1,
  fetchSelectGroupIngredientsPerPage: null,
  fetchSelectGroupIngredientsIncludes: null,
  fetchSelectGroupIngredientsFilterMarketId: null,
  fetchSelectGroupIngredientsFilterBranchId: null,
  fetchSelectGroupIngredientsFilterIngredientsIds: null,
  fetchSelectGroupIngredientsFilterIsGroup: null,
  fetchSelectGroupIngredientsLoading: false,
  fetchSelectGroupIngredientsSuccess: null,
  fetchSelectGroupIngredientsFailed: null,

  fetchOutOfStockIngredientsSearch: null,
  fetchOutOfStockIngredientsSort: null,
  fetchOutOfStockIngredientsKeyBy: null,
  fetchOutOfStockIngredientsPage: 1,
  fetchOutOfStockIngredientsPerPage: null,
  fetchOutOfStockIngredientsIncludes: null,
  fetchOutOfStockIngredientsFilterMarketId: null,
  fetchOutOfStockIngredientsFilterBranchId: null,
  fetchOutOfStockIngredientsLoading: false,
  fetchOutOfStockIngredientsSuccess: null,
  fetchOutOfStockIngredientsFailed: null,

  appendSelectIngredientsSearch: null,
  appendSelectIngredientsSort: null,
  appendSelectIngredientsKeyBy: null,
  appendSelectIngredientsPage: 1,
  appendSelectIngredientsPerPage: null,
  appendSelectIngredientsIncludes: null,
  appendSelectIngredientsFilterMarketId: null,
  appendSelectIngredientsFilterBranchId: null,
  appendSelectIngredientsFilterIngredientsIds: null,
  appendSelectIngredientsFilterIsGroup: null,
  appendSelectIngredientsLoading: false,
  appendSelectIngredientsSuccess: null,
  appendSelectIngredientsFailed: null,

  appendSelectGroupIngredientsSearch: null,
  appendSelectGroupIngredientsSort: null,
  appendSelectGroupIngredientsKeyBy: null,
  appendSelectGroupIngredientsPage: 1,
  appendSelectGroupIngredientsPerPage: null,
  appendSelectGroupIngredientsIncludes: null,
  appendSelectGroupIngredientsFilterMarketId: null,
  appendSelectGroupIngredientsFilterBranchId: null,
  appendSelectGroupIngredientsFilterIngredientsIds: null,
  appendSelectGroupIngredientsFilterIsGroup: null,
  appendSelectGroupIngredientsLoading: false,
  appendSelectGroupIngredientsSuccess: null,
  appendSelectGroupIngredientsFailed: null,

  fetchIngredientLoading: false,
  fetchIngredientSuccess: null,
  fetchIngredientFailed: null,

  createIngredientLoading: false,
  createIngredientSuccess: null,
  createIngredientFailed: null,

  updateIngredientLoading: false,
  updateIngredientSuccess: null,
  updateIngredientFailed: null,

  deleteIngredientLoading: false,
  deleteIngredientSuccess: null,
  deleteIngredientFailed: null,

  storeStockIngredientLoading: false,
  storeStockIngredientSuccess: null,
  storeStockIngredientFailed: null,

  reduceStockIngredientLoading: false,
  reduceStockIngredientSuccess: null,
  reduceStockIngredientFailed: null,

  isFetchIngredientsHitted: false,
  isFetchSelectIngredientsHitted: false,
  isFetchSelectGroupIngredientsHitted: false,
  isFetchOutOfStockIngredientsHitted: false,
  isAppendSelectIngredientsHitted: false,
  isAppendSelectGroupIngredientsHitted: false,
  isFetchIngredientHitted: false,
  isCreateIngredientHitted: false,
  isUpdateIngredientHitted: false,
  isDeleteIngredientHitted: false,
  isStoreStockIngredientHitted: false,
  isReduceStockIngredientHitted: false,
};

export const ingredientReducer = (state = INGREDIENT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case INGREDIENT_ACTION_TYPES.SET_INGREDIENTS:
      return { ...state, ingredients: payload };
    case INGREDIENT_ACTION_TYPES.SET_SELECT_INGREDIENTS:
      return { ...state, selectIngredients: payload };
    case INGREDIENT_ACTION_TYPES.SET_SELECT_GROUP_INGREDIENTS:
      return { ...state, selectGroupIngredients: payload };
    case INGREDIENT_ACTION_TYPES.SET_OUT_OF_STOCK_INGREDIENTS:
      return { ...state, outOfStockIngredients: payload };
    case INGREDIENT_ACTION_TYPES.SET_INGREDIENT:
      return { ...state, ingredient: payload };

    case INGREDIENT_ACTION_TYPES.SET_IS_INGREDIENTS_HAS_MORE:
      return { ...state, isIngredientsHasMore: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_SELECT_INGREDIENTS_HAS_MORE:
      return { ...state, isSelectIngredientsHasMore: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_SELECT_GROUP_INGREDIENTS_HAS_MORE:
      return { ...state, isSelectGroupIngredientsHasMore: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_OUT_OF_STOCK_INGREDIENTS_HAS_MORE:
      return { ...state, isOutOfStockIngredientsHasMore: payload };

    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_SEARCH:
      return { ...state, fetchIngredientsSearch: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_SORT:
      return { ...state, fetchIngredientsSort: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_KEY_BY:
      return { ...state, fetchIngredientsKeyBy: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_PAGE:
      return { ...state, fetchIngredientsPage: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_PER_PAGE:
      return { ...state, fetchIngredientsPerPage: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_INCLUDES:
      return { ...state, fetchIngredientsIncludes: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_FILTER_MARKET_ID:
      return { ...state, fetchIngredientsFilterMarketId: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_FILTER_BRANCH_ID:
      return { ...state, fetchIngredientsFilterBranchId: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_FILTER_IS_OUT_OF_STOCK:
      return { ...state, fetchIngredientsFilterIsOutOfStock: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_LOADING:
      return { ...state, fetchIngredientsLoading: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_SUCCESS:
      return { ...state, fetchIngredientsSuccess: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_FAILED:
      return { ...state, fetchIngredientsFailed: payload };

    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_SEARCH:
      return { ...state, fetchSelectIngredientsSearch: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_SORT:
      return { ...state, fetchSelectIngredientsSort: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_KEY_BY:
      return { ...state, fetchSelectIngredientsKeyBy: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_PAGE:
      return { ...state, fetchSelectIngredientsPage: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_PER_PAGE:
      return { ...state, fetchSelectIngredientsPerPage: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_INCLUDES:
      return { ...state, fetchSelectIngredientsIncludes: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_FILTER_MARKET_ID:
      return { ...state, fetchSelectIngredientsFilterMarketId: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_FILTER_BRANCH_ID:
      return { ...state, fetchSelectIngredientsFilterBranchId: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_FILTER_INGREDIENTS_IDS:
      return { ...state, fetchSelectIngredientsFilterIngredientsIds: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_FILTER_IS_GROUP:
      return { ...state, fetchSelectIngredientsFilterIsGroup: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_LOADING:
      return { ...state, fetchSelectIngredientsLoading: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_SUCCESS:
      return { ...state, fetchSelectIngredientsSuccess: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_FAILED:
      return { ...state, fetchSelectIngredientsFailed: payload };

    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_SEARCH:
      return { ...state, fetchSelectGroupIngredientsSearch: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_SORT:
      return { ...state, fetchSelectGroupIngredientsSort: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_KEY_BY:
      return { ...state, fetchSelectGroupIngredientsKeyBy: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_PAGE:
      return { ...state, fetchSelectGroupIngredientsPage: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_PER_PAGE:
      return { ...state, fetchSelectGroupIngredientsPerPage: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_INCLUDES:
      return { ...state, fetchSelectGroupIngredientsIncludes: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_MARKET_ID:
      return { ...state, fetchSelectGroupIngredientsFilterMarketId: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_BRANCH_ID:
      return { ...state, fetchSelectGroupIngredientsFilterBranchId: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_INGREDIENTS_IDS:
      return {
        ...state,
        fetchSelectGroupIngredientsFilterIngredientsIds: payload,
      };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_IS_GROUP:
      return { ...state, fetchSelectGroupIngredientsFilterIsGroup: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_LOADING:
      return { ...state, fetchSelectGroupIngredientsLoading: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_SUCCESS:
      return { ...state, fetchSelectGroupIngredientsSuccess: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_FAILED:
      return { ...state, fetchSelectGroupIngredientsFailed: payload };

    case INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_SEARCH:
      return { ...state, fetchOutOfStockIngredientsSearch: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_SORT:
      return { ...state, fetchOutOfStockIngredientsSort: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_KEY_BY:
      return { ...state, fetchOutOfStockIngredientsKeyBy: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_PAGE:
      return { ...state, fetchOutOfStockIngredientsPage: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_PER_PAGE:
      return { ...state, fetchOutOfStockIngredientsPerPage: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_INCLUDES:
      return { ...state, fetchOutOfStockIngredientsIncludes: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_FILTER_MARKET_ID:
      return { ...state, fetchOutOfStockIngredientsFilterMarketId: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_FILTER_BRANCH_ID:
      return { ...state, fetchOutOfStockIngredientsFilterBranchId: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_LOADING:
      return { ...state, fetchOutOfStockIngredientsLoading: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_SUCCESS:
      return { ...state, fetchOutOfStockIngredientsSuccess: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_FAILED:
      return { ...state, fetchOutOfStockIngredientsFailed: payload };

    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_SEARCH:
      return { ...state, appendSelectIngredientsSearch: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_SORT:
      return { ...state, appendSelectIngredientsSort: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_KEY_BY:
      return { ...state, appendSelectIngredientsKeyBy: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_PAGE:
      return { ...state, appendSelectIngredientsPage: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_PER_PAGE:
      return { ...state, appendSelectIngredientsPerPage: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_INCLUDES:
      return { ...state, appendSelectIngredientsIncludes: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_FILTER_MARKET_ID:
      return { ...state, appendSelectIngredientsFilterMarketId: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_FILTER_BRANCH_ID:
      return { ...state, appendSelectIngredientsFilterBranchId: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_FILTER_INGREDIENTS_IDS:
      return { ...state, appendSelectIngredientsFilterIngredientsIds: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_FILTER_IS_GROUP:
      return { ...state, appendSelectIngredientsFilterIsGroup: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_LOADING:
      return { ...state, appendSelectIngredientsLoading: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_SUCCESS:
      return { ...state, appendSelectIngredientsSuccess: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_FAILED:
      return { ...state, appendSelectIngredientsFailed: payload };

    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_SEARCH:
      return { ...state, appendSelectGroupIngredientsSearch: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_SORT:
      return { ...state, appendSelectGroupIngredientsSort: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_KEY_BY:
      return { ...state, appendSelectGroupIngredientsKeyBy: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_PAGE:
      return { ...state, appendSelectGroupIngredientsPage: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_PER_PAGE:
      return { ...state, appendSelectGroupIngredientsPerPage: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_INCLUDES:
      return { ...state, appendSelectGroupIngredientsIncludes: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_MARKET_ID:
      return { ...state, appendSelectGroupIngredientsFilterMarketId: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_BRANCH_ID:
      return { ...state, appendSelectGroupIngredientsFilterBranchId: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_INGREDIENTS_IDS:
      return {
        ...state,
        appendSelectGroupIngredientsFilterIngredientsIds: payload,
      };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_IS_GROUP:
      return { ...state, appendSelectGroupIngredientsFilterIsGroup: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_LOADING:
      return { ...state, appendSelectGroupIngredientsLoading: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_SUCCESS:
      return { ...state, appendSelectGroupIngredientsSuccess: payload };
    case INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_FAILED:
      return { ...state, appendSelectGroupIngredientsFailed: payload };

    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENT_LOADING:
      return { ...state, fetchIngredientLoading: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENT_SUCCESS:
      return { ...state, fetchIngredientSuccess: payload };
    case INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENT_FAILED:
      return { ...state, fetchIngredientFailed: payload };

    case INGREDIENT_ACTION_TYPES.SET_CREATE_INGREDIENT_LOADING:
      return { ...state, createIngredientLoading: payload };
    case INGREDIENT_ACTION_TYPES.SET_CREATE_INGREDIENT_SUCCESS:
      return { ...state, createIngredientSuccess: payload };
    case INGREDIENT_ACTION_TYPES.SET_CREATE_INGREDIENT_FAILED:
      return { ...state, createIngredientFailed: payload };

    case INGREDIENT_ACTION_TYPES.SET_UPDATE_INGREDIENT_LOADING:
      return { ...state, updateIngredientLoading: payload };
    case INGREDIENT_ACTION_TYPES.SET_UPDATE_INGREDIENT_SUCCESS:
      return { ...state, updateIngredientSuccess: payload };
    case INGREDIENT_ACTION_TYPES.SET_UPDATE_INGREDIENT_FAILED:
      return { ...state, updateIngredientFailed: payload };

    case INGREDIENT_ACTION_TYPES.SET_DELETE_INGREDIENT_LOADING:
      return { ...state, deleteIngredientLoading: payload };
    case INGREDIENT_ACTION_TYPES.SET_DELETE_INGREDIENT_SUCCESS:
      return { ...state, deleteIngredientSuccess: payload };
    case INGREDIENT_ACTION_TYPES.SET_DELETE_INGREDIENT_FAILED:
      return { ...state, deleteIngredientFailed: payload };

    case INGREDIENT_ACTION_TYPES.SET_STORE_STOCK_INGREDIENT_LOADING:
      return { ...state, storeStockIngredientLoading: payload };
    case INGREDIENT_ACTION_TYPES.SET_STORE_STOCK_INGREDIENT_SUCCESS:
      return { ...state, storeStockIngredientSuccess: payload };
    case INGREDIENT_ACTION_TYPES.SET_STORE_STOCK_INGREDIENT_FAILED:
      return { ...state, storeStockIngredientFailed: payload };

    case INGREDIENT_ACTION_TYPES.SET_REDUCE_STOCK_INGREDIENT_LOADING:
      return { ...state, reduceStockIngredientLoading: payload };
    case INGREDIENT_ACTION_TYPES.SET_REDUCE_STOCK_INGREDIENT_SUCCESS:
      return { ...state, reduceStockIngredientSuccess: payload };
    case INGREDIENT_ACTION_TYPES.SET_REDUCE_STOCK_INGREDIENT_FAILED:
      return { ...state, reduceStockIngredientFailed: payload };

    case INGREDIENT_ACTION_TYPES.APPEND_INGREDIENTS:
      return {
        ...state,
        ingredients: [...state.ingredients, ...payload],
      };
    case INGREDIENT_ACTION_TYPES.APPEND_SELECT_INGREDIENTS:
      return {
        ...state,
        selectIngredients: { ...state.selectIngredients, ...payload },
      };
    case INGREDIENT_ACTION_TYPES.APPEND_SELECT_GROUP_INGREDIENTS:
      return {
        ...state,
        selectGroupIngredients: { ...state.selectGroupIngredients, ...payload },
      };
    case INGREDIENT_ACTION_TYPES.APPEND_OUT_OF_STOCK_INGREDIENTS:
      return {
        ...state,
        outOfStockIngredients: [...state.outOfStockIngredients, ...payload],
      };

    case INGREDIENT_ACTION_TYPES.SET_IS_FETCH_INGREDIENTS_HITTED:
      return { ...state, isFetchIngredientsHitted: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_FETCH_SELECT_INGREDIENTS_HITTED:
      return { ...state, isFetchSelectIngredientsHitted: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_FETCH_SELECT_GROUP_INGREDIENTS_HITTED:
      return { ...state, isFetchSelectGroupIngredientsHitted: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_FETCH_OUT_OF_STOCK_INGREDIENTS_HITTED:
      return { ...state, isFetchOutOfStockIngredientsHitted: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_APPEND_SELECT_INGREDIENTS_HITTED:
      return { ...state, isAppendSelectIngredientsHitted: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_APPEND_SELECT_GROUP_INGREDIENTS_HITTED:
      return { ...state, isAppendSelectGroupIngredientsHitted: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_FETCH_INGREDIENT_HITTED:
      return { ...state, isFetchIngredientHitted: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_CREATE_INGREDIENT_HITTED:
      return { ...state, isCreateIngredientHitted: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_UPDATE_INGREDIENT_HITTED:
      return { ...state, isUpdateIngredientHitted: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_DELETE_INGREDIENT_HITTED:
      return { ...state, isDeleteIngredientHitted: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_STORE_STOCK_INGREDIENT_HITTED:
      return { ...state, isStoreStockIngredientHitted: payload };
    case INGREDIENT_ACTION_TYPES.SET_IS_REDUCE_STOCK_INGREDIENT_HITTED:
      return { ...state, isReduceStockIngredientHitted: payload };

    case INGREDIENT_ACTION_TYPES.RESET_INGREDIENT_REDUCER:
      return INGREDIENT_INITIAL_STATE;
    default:
      return state;
  }
};
