const EVENT_ACTION_TYPES = {
  RESET_EVENT_REDUCER: "event/RESET_EVENT_REDUCER",

  SET_SEND_EVENT_LOADING: "event/SET_SEND_EVENT_LOADING",
  SET_SEND_EVENT_SUCCESS: "event/SET_SEND_EVENT_SUCCESS",
  SET_SEND_EVENT_FAILED: "event/SET_SEND_EVENT_FAILED",

  SET_IS_SEND_EVENT_HITTED: "event/SET_IS_SEND_EVENT_HITTED",

  SEND_EVENT_START: "event/SEND_EVENT_START",
};

export default EVENT_ACTION_TYPES;
