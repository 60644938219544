import USER_MERCHANT_ACTION_TYPES from "./user-merchant.type";
import { createAction } from "../../utils/store.utils";

export const setUserMerchants = (userMerchants) =>
  createAction(USER_MERCHANT_ACTION_TYPES.SET_USER_MERCHANTS, userMerchants);
export const setSelectUserMerchants = (selectUserMerchants) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_SELECT_USER_MERCHANTS,
    selectUserMerchants
  );
export const setUserMerchant = (userMerchant) =>
  createAction(USER_MERCHANT_ACTION_TYPES.SET_USER_MERCHANT, userMerchant);

export const setIsUserMerchantsHasMore = (isUserMerchantsHasMore) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_IS_USER_MERCHANTS_HAS_MORE,
    isUserMerchantsHasMore
  );
export const setIsSelectUserMerchantsHasMore = (isSelectUserMerchantsHasMore) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_IS_SELECT_USER_MERCHANTS_HAS_MORE,
    isSelectUserMerchantsHasMore
  );

export const setFetchUserMerchantsSearch = (fetchUserMerchantsSearch) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_SEARCH,
    fetchUserMerchantsSearch
  );
export const setFetchUserMerchantsSort = (fetchUserMerchantsSort) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_SORT,
    fetchUserMerchantsSort
  );
export const setFetchUserMerchantsKeyBy = (fetchUserMerchantsKeyBy) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_KEY_BY,
    fetchUserMerchantsKeyBy
  );
export const setFetchUserMerchantsPage = (fetchUserMerchantsPage) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_PAGE,
    fetchUserMerchantsPage
  );
export const setFetchUserMerchantsPerPage = (fetchUserMerchantsPerPage) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_PER_PAGE,
    fetchUserMerchantsPerPage
  );
export const setFetchUserMerchantsIncludes = (fetchUserMerchantsIncludes) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_INCLUDES,
    fetchUserMerchantsIncludes
  );
export const setFetchUserMerchantsFilterMerchantId = (
  fetchUserMerchantsFilterMerchantId
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_FILTER_MERCHANT_ID,
    fetchUserMerchantsFilterMerchantId
  );
export const setFetchUserMerchantsFilterPermissionMerchantId = (
  fetchUserMerchantsFilterPermissionMerchantId
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_FILTER_PERMISSION_MERCHANT_ID,
    fetchUserMerchantsFilterPermissionMerchantId
  );
export const setFetchUserMerchantsFilterPermissionSubscriberId = (
  fetchUserMerchantsFilterPermissionSubscriberId
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_FILTER_PERMISSION_SUBSCRIBER_ID,
    fetchUserMerchantsFilterPermissionSubscriberId
  );
export const setFetchUserMerchantsFilterMarketId = (
  fetchUserMerchantsFilterMarketId
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_FILTER_MARKET_ID,
    fetchUserMerchantsFilterMarketId
  );
export const setFetchUserMerchantsFilterUsersIds = (
  fetchUserMerchantsFilterUsersIds
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_FILTER_USERS_IDS,
    fetchUserMerchantsFilterUsersIds
  );
export const setFetchUserMerchantsLoading = (fetchUserMerchantsLoading) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_LOADING,
    fetchUserMerchantsLoading
  );
export const setFetchUserMerchantsSuccess = (fetchUserMerchantsSuccess) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_SUCCESS,
    fetchUserMerchantsSuccess
  );
export const setFetchUserMerchantsFailed = (fetchUserMerchantsFailed) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANTS_FAILED,
    fetchUserMerchantsFailed
  );

export const setFetchSelectUserMerchantsSearch = (
  fetchSelectUserMerchantsSearch
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_SEARCH,
    fetchSelectUserMerchantsSearch
  );
export const setFetchSelectUserMerchantsSort = (fetchSelectUserMerchantsSort) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_SORT,
    fetchSelectUserMerchantsSort
  );
export const setFetchSelectUserMerchantsKeyBy = (
  fetchSelectUserMerchantsKeyBy
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_KEY_BY,
    fetchSelectUserMerchantsKeyBy
  );
export const setFetchSelectUserMerchantsPage = (fetchSelectUserMerchantsPage) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_PAGE,
    fetchSelectUserMerchantsPage
  );
export const setFetchSelectUserMerchantsPerPage = (
  fetchSelectUserMerchantsPerPage
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_PER_PAGE,
    fetchSelectUserMerchantsPerPage
  );
export const setFetchSelectUserMerchantsIncludes = (
  fetchSelectUserMerchantsIncludes
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_INCLUDES,
    fetchSelectUserMerchantsIncludes
  );
export const setFetchSelectUserMerchantsFilterMerchantId = (
  fetchSelectUserMerchantsFilterMerchantId
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_FILTER_MERCHANT_ID,
    fetchSelectUserMerchantsFilterMerchantId
  );
export const setFetchSelectUserMerchantsFilterPermissionMerchantId = (
  fetchSelectUserMerchantsFilterPermissionMerchantId
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_FILTER_PERMISSION_MERCHANT_ID,
    fetchSelectUserMerchantsFilterPermissionMerchantId
  );
export const setFetchSelectUserMerchantsFilterPermissionSubscriberId = (
  fetchSelectUserMerchantsFilterPermissionSubscriberId
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_FILTER_PERMISSION_SUBSCRIBER_ID,
    fetchSelectUserMerchantsFilterPermissionSubscriberId
  );
export const setFetchSelectUserMerchantsFilterMarketId = (
  fetchSelectUserMerchantsFilterMarketId
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_FILTER_MARKET_ID,
    fetchSelectUserMerchantsFilterMarketId
  );
export const setFetchSelectUserMerchantsFilterUsersIds = (
  fetchSelectUserMerchantsFilterUsersIds
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_FILTER_USERS_IDS,
    fetchSelectUserMerchantsFilterUsersIds
  );
export const setFetchSelectUserMerchantsLoading = (
  fetchSelectUserMerchantsLoading
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_LOADING,
    fetchSelectUserMerchantsLoading
  );
export const setFetchSelectUserMerchantsSuccess = (
  fetchSelectUserMerchantsSuccess
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_SUCCESS,
    fetchSelectUserMerchantsSuccess
  );
export const setFetchSelectUserMerchantsFailed = (
  fetchSelectUserMerchantsFailed
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_USER_MERCHANTS_FAILED,
    fetchSelectUserMerchantsFailed
  );

export const setAppendSelectUserMerchantsSearch = (
  appendSelectUserMerchantsSearch
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_SEARCH,
    appendSelectUserMerchantsSearch
  );
export const setAppendSelectUserMerchantsSort = (
  appendSelectUserMerchantsSort
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_SORT,
    appendSelectUserMerchantsSort
  );
export const setAppendSelectUserMerchantsKeyBy = (
  appendSelectUserMerchantsKeyBy
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_KEY_BY,
    appendSelectUserMerchantsKeyBy
  );
export const setAppendSelectUserMerchantsPage = (
  appendSelectUserMerchantsPage
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_PAGE,
    appendSelectUserMerchantsPage
  );
export const setAppendSelectUserMerchantsPerPage = (
  appendSelectUserMerchantsPerPage
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_PER_PAGE,
    appendSelectUserMerchantsPerPage
  );
export const setAppendSelectUserMerchantsIncludes = (
  appendSelectUserMerchantsIncludes
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_INCLUDES,
    appendSelectUserMerchantsIncludes
  );
export const setAppendSelectUserMerchantsFilterMerchantId = (
  appendSelectUserMerchantsFilterMerchantId
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_FILTER_MERCHANT_ID,
    appendSelectUserMerchantsFilterMerchantId
  );
export const setAppendSelectUserMerchantsFilterPermissionMerchantId = (
  appendSelectUserMerchantsFilterPermissionMerchantId
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_FILTER_PERMISSION_MERCHANT_ID,
    appendSelectUserMerchantsFilterPermissionMerchantId
  );
export const setAppendSelectUserMerchantsFilterPermissionSubscriberId = (
  appendSelectUserMerchantsFilterPermissionSubscriberId
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_FILTER_PERMISSION_SUBSCRIBER_ID,
    appendSelectUserMerchantsFilterPermissionSubscriberId
  );
export const setAppendSelectUserMerchantsFilterMarketId = (
  appendSelectUserMerchantsFilterMarketId
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_FILTER_MARKET_ID,
    appendSelectUserMerchantsFilterMarketId
  );
export const setAppendSelectUserMerchantsFilterUsersIds = (
  appendSelectUserMerchantsFilterUsersIds
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_FILTER_USERS_IDS,
    appendSelectUserMerchantsFilterUsersIds
  );
export const setAppendSelectUserMerchantsLoading = (
  appendSelectUserMerchantsLoading
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_LOADING,
    appendSelectUserMerchantsLoading
  );
export const setAppendSelectUserMerchantsSuccess = (
  appendSelectUserMerchantsSuccess
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_SUCCESS,
    appendSelectUserMerchantsSuccess
  );
export const setAppendSelectUserMerchantsFailed = (
  appendSelectUserMerchantsFailed
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_USER_MERCHANTS_FAILED,
    appendSelectUserMerchantsFailed
  );

export const setFetchUserMerchantLoading = (fetchUserMerchantLoading) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANT_LOADING,
    fetchUserMerchantLoading
  );
export const setFetchUserMerchantSuccess = (fetchUserMerchantSuccess) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANT_SUCCESS,
    fetchUserMerchantSuccess
  );
export const setFetchUserMerchantFailed = (fetchUserMerchantFailed) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_FETCH_USER_MERCHANT_FAILED,
    fetchUserMerchantFailed
  );

export const setCreateUserMerchantLoading = (createUserMerchantLoading) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_CREATE_USER_MERCHANT_LOADING,
    createUserMerchantLoading
  );
export const setCreateUserMerchantSuccess = (createUserMerchantSuccess) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_CREATE_USER_MERCHANT_SUCCESS,
    createUserMerchantSuccess
  );
export const setCreateUserMerchantFailed = (createUserMerchantFailed) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_CREATE_USER_MERCHANT_FAILED,
    createUserMerchantFailed
  );

export const setUpdateUserMerchantLoading = (updateUserMerchantLoading) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_UPDATE_USER_MERCHANT_LOADING,
    updateUserMerchantLoading
  );
export const setUpdateUserMerchantSuccess = (updateUserMerchantSuccess) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_UPDATE_USER_MERCHANT_SUCCESS,
    updateUserMerchantSuccess
  );
export const setUpdateUserMerchantFailed = (updateUserMerchantFailed) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_UPDATE_USER_MERCHANT_FAILED,
    updateUserMerchantFailed
  );

export const setDeleteUserMerchantLoading = (deleteUserMerchantLoading) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_DELETE_USER_MERCHANT_LOADING,
    deleteUserMerchantLoading
  );
export const setDeleteUserMerchantSuccess = (deleteUserMerchantSuccess) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_DELETE_USER_MERCHANT_SUCCESS,
    deleteUserMerchantSuccess
  );
export const setDeleteUserMerchantFailed = (deleteUserMerchantFailed) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_DELETE_USER_MERCHANT_FAILED,
    deleteUserMerchantFailed
  );

export const appendUserMerchants = (userMerchants) =>
  createAction(USER_MERCHANT_ACTION_TYPES.APPEND_USER_MERCHANTS, userMerchants);
export const appendSelectUserMerchants = (selectUserMerchants) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.APPEND_SELECT_USER_MERCHANTS,
    selectUserMerchants
  );

export const fetchUserMerchantsStart = () =>
  createAction(USER_MERCHANT_ACTION_TYPES.FETCH_USER_MERCHANTS_START);
export const fetchSelectUserMerchantsStart = () =>
  createAction(USER_MERCHANT_ACTION_TYPES.FETCH_SELECT_USER_MERCHANTS_START);
export const appendSelectUserMerchantsStart = () =>
  createAction(USER_MERCHANT_ACTION_TYPES.APPEND_SELECT_USER_MERCHANTS_START);
export const fetchUserMerchantStart = (merchantId) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.FETCH_USER_MERCHANT_START,
    merchantId
  );
export const createUserMerchantStart = (request) =>
  createAction(USER_MERCHANT_ACTION_TYPES.CREATE_USER_MERCHANT_START, request);
export const updateUserMerchantStart = (merchantId, request) =>
  createAction(USER_MERCHANT_ACTION_TYPES.UPDATE_USER_MERCHANT_START, {
    merchantId,
    request,
  });
export const deleteUserMerchantStart = (merchantId) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.DELETE_USER_MERCHANT_START,
    merchantId
  );

export const setIsFetchUserMerchantsHitted = (isFetchUserMerchantsHitted) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_IS_FETCH_USER_MERCHANTS_HITTED,
    isFetchUserMerchantsHitted
  );
export const setIsFetchSelectUserMerchantsHitted = (
  isFetchSelectUserMerchantsHitted
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_IS_FETCH_SELECT_USER_MERCHANTS_HITTED,
    isFetchSelectUserMerchantsHitted
  );
export const setIsAppendSelectUserMerchantsHitted = (
  isAppendSelectUserMerchantsHitted
) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_IS_APPEND_SELECT_USER_MERCHANTS_HITTED,
    isAppendSelectUserMerchantsHitted
  );
export const setIsFetchUserMerchantHitted = (isFetchUserMerchantHitted) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_IS_FETCH_USER_MERCHANT_HITTED,
    isFetchUserMerchantHitted
  );
export const setIsCreateUserMerchantHitted = (isCreateUserMerchantHitted) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_IS_CREATE_USER_MERCHANT_HITTED,
    isCreateUserMerchantHitted
  );
export const setIsUpdateUserMerchantHitted = (isUpdateUserMerchantHitted) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_IS_UPDATE_USER_MERCHANT_HITTED,
    isUpdateUserMerchantHitted
  );
export const setIsDeleteUserMerchantHitted = (isDeleteUserMerchantHitted) =>
  createAction(
    USER_MERCHANT_ACTION_TYPES.SET_IS_DELETE_USER_MERCHANT_HITTED,
    isDeleteUserMerchantHitted
  );

export const resetUserMerchantReducer = () =>
  createAction(USER_MERCHANT_ACTION_TYPES.RESET_USER_MERCHANT_REDUCER);
