import DRIVER_ACTION_TYPES from "./driver.type";

export const DRIVER_INITIAL_STATE = {
  drivers: [],
  driver: null,
  createDriver: null,
  updateDriver: null,

  isDriversHasMore: true,

  fetchDriversSearch: null,
  fetchDriversPage: 1,
  fetchDriversPerPage: null,
  fetchDriversIncludes: null,
  fetchDriversFilterMarketId: null,
  fetchDriversFilterGender: null,
  fetchDriversFilterStatus: null,
  fetchDriversFilterVehicleType: null,
  fetchDriversLoading: false,
  fetchDriversSuccess: null,
  fetchDriversFailed: null,

  fetchDriverLoading: false,
  fetchDriverSuccess: null,
  fetchDriverFailed: null,

  createDriverLoading: false,
  createDriverSuccess: null,
  createDriverFailed: null,

  updateDriverLoading: false,
  updateDriverSuccess: null,
  updateDriverFailed: null,

  isFetchDriversHitted: false,
  isFetchDriverHitted: false,
  isCreateDriverHitted: false,
  isUpdateDriverHitted: false,
};

export const driverReducer = (state = DRIVER_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case DRIVER_ACTION_TYPES.SET_DRIVERS:
      return { ...state, drivers: payload };
    case DRIVER_ACTION_TYPES.SET_DRIVER:
      return { ...state, driver: payload };
    case DRIVER_ACTION_TYPES.SET_CREATE_DRIVER:
      return { ...state, createDriver: payload };
    case DRIVER_ACTION_TYPES.SET_UPDATE_DRIVER:
      return { ...state, updateDriver: payload };

    case DRIVER_ACTION_TYPES.SET_IS_DRIVERS_HAS_MORE:
      return { ...state, isDriversHasMore: payload };

    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_SEARCH:
      return { ...state, fetchDriversSearch: payload };
    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_PAGE:
      return { ...state, fetchDriversPage: payload };
    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_PER_PAGE:
      return { ...state, fetchDriversPerPage: payload };
    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_INCLUDES:
      return { ...state, fetchDriversIncludes: payload };
    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_FILTER_MARKET_ID:
      return { ...state, fetchDriversFilterMarketId: payload };
    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_FILTER_GENDER:
      return { ...state, fetchDriversFilterGender: payload };
    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_FILTER_STATUS:
      return { ...state, fetchDriversFilterStatus: payload };
    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_FILTER_VEHICLE_TYPE:
      return { ...state, fetchDriversFilterVehicleType: payload };
    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_LOADING:
      return { ...state, fetchDriversLoading: payload };
    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_SUCCESS:
      return { ...state, fetchDriversSuccess: payload };
    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_FAILED:
      return { ...state, fetchDriversFailed: payload };

    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVER_LOADING:
      return { ...state, fetchDriverLoading: payload };
    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVER_SUCCESS:
      return { ...state, fetchDriverSuccess: payload };
    case DRIVER_ACTION_TYPES.SET_FETCH_DRIVER_FAILED:
      return { ...state, fetchDriverFailed: payload };

    case DRIVER_ACTION_TYPES.SET_CREATE_DRIVER_LOADING:
      return { ...state, createDriverLoading: payload };
    case DRIVER_ACTION_TYPES.SET_CREATE_DRIVER_SUCCESS:
      return { ...state, createDriverSuccess: payload };
    case DRIVER_ACTION_TYPES.SET_CREATE_DRIVER_FAILED:
      return { ...state, createDriverFailed: payload };

    case DRIVER_ACTION_TYPES.SET_UPDATE_DRIVER_LOADING:
      return { ...state, updateDriverLoading: payload };
    case DRIVER_ACTION_TYPES.SET_UPDATE_DRIVER_SUCCESS:
      return { ...state, updateDriverSuccess: payload };
    case DRIVER_ACTION_TYPES.SET_UPDATE_DRIVER_FAILED:
      return { ...state, updateDriverFailed: payload };

    case DRIVER_ACTION_TYPES.APPEND_DRIVERS:
      return { ...state, drivers: [...state.drivers, ...payload] };

    case DRIVER_ACTION_TYPES.SET_IS_FETCH_DRIVERS_HITTED:
      return { ...state, isFetchDriversHitted: payload };
    case DRIVER_ACTION_TYPES.SET_IS_FETCH_DRIVER_HITTED:
      return { ...state, isFetchDriverHitted: payload };
    case DRIVER_ACTION_TYPES.SET_IS_CREATE_DRIVER_HITTED:
      return { ...state, isCreateDriverHitted: payload };
    case DRIVER_ACTION_TYPES.SET_IS_UPDATE_DRIVER_HITTED:
      return { ...state, isUpdateDriverHitted: payload };

    case DRIVER_ACTION_TYPES.RESET_DRIVER_REDUCER:
      return DRIVER_INITIAL_STATE;
    default:
      return state;
  }
};
