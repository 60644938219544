import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PRODUCT_CATEGORY_ACTION_TYPES from "./product-category.type";

import {
  appendOrderProductCategories,
  appendProductCategories,
  appendProductProductCategories,
  appendSelectProductCategories,
  setAppendSelectProductCategoriesFailed,
  setAppendSelectProductCategoriesLoading,
  setAppendSelectProductCategoriesSuccess,
  setCreateProductCategoryFailed,
  setCreateProductCategoryLoading,
  setCreateProductCategorySuccess,
  setDeleteProductCategoryFailed,
  setDeleteProductCategoryLoading,
  setDeleteProductCategorySuccess,
  setFetchOrderProductCategoriesFailed,
  setFetchOrderProductCategoriesLoading,
  setFetchOrderProductCategoriesPage,
  setFetchOrderProductCategoriesSuccess,
  setFetchProductCategoriesFailed,
  setFetchProductCategoriesLoading,
  setFetchProductCategoriesPage,
  setFetchProductCategoriesSuccess,
  setFetchProductCategoryFailed,
  setFetchProductCategoryLoading,
  setFetchProductCategorySuccess,
  setFetchProductProductCategoriesFailed,
  setFetchProductProductCategoriesLoading,
  setFetchProductProductCategoriesPage,
  setFetchProductProductCategoriesSuccess,
  setFetchSelectProductCategoriesFailed,
  setFetchSelectProductCategoriesLoading,
  setFetchSelectProductCategoriesPage,
  setFetchSelectProductCategoriesSuccess,
  setIsAppendSelectProductCategoriesHitted,
  setIsCreateProductCategoryHitted,
  setIsDeleteProductCategoryHitted,
  setIsFetchOrderProductCategoriesHitted,
  setIsFetchProductCategoriesHitted,
  setIsFetchProductCategoryHitted,
  setIsFetchProductProductCategoriesHitted,
  setIsFetchSelectProductCategoriesHitted,
  setIsOrderProductCategoriesHasMore,
  setIsProductCategoriesHasMore,
  setIsProductProductCategoriesHasMore,
  setIsSelectProductCategoriesHasMore,
  setIsUpdateProductCategoryHitted,
  setOrderProductCategories,
  setProductCategories,
  setProductCategory,
  setProductProductCategories,
  setSelectProductCategories,
  setUpdateProductCategoryFailed,
  setUpdateProductCategoryLoading,
  setUpdateProductCategorySuccess,
} from "./product-category.action";
import {
  getAppendSelectProductCategoriesFilterBranchId,
  getAppendSelectProductCategoriesFilterMarketId,
  getAppendSelectProductCategoriesFilterProductCategoriesIds,
  getAppendSelectProductCategoriesFilterSectorId,
  getAppendSelectProductCategoriesIncludes,
  getAppendSelectProductCategoriesKeyBy,
  getAppendSelectProductCategoriesPage,
  getAppendSelectProductCategoriesPerPage,
  getAppendSelectProductCategoriesSearch,
  getAppendSelectProductCategoriesSort,
  getFetchOrderProductCategoriesFilterBranchId,
  getFetchOrderProductCategoriesFilterMarketId,
  getFetchOrderProductCategoriesFilterSectorId,
  getFetchOrderProductCategoriesIncludes,
  getFetchOrderProductCategoriesKeyBy,
  getFetchOrderProductCategoriesPage,
  getFetchOrderProductCategoriesPerPage,
  getFetchOrderProductCategoriesSearch,
  getFetchOrderProductCategoriesSort,
  getFetchProductCategoriesFilterBranchId,
  getFetchProductCategoriesFilterMarketId,
  getFetchProductCategoriesFilterSectorId,
  getFetchProductCategoriesIncludes,
  getFetchProductCategoriesKeyBy,
  getFetchProductCategoriesPage,
  getFetchProductCategoriesPerPage,
  getFetchProductCategoriesSearch,
  getFetchProductCategoriesSort,
  getFetchProductProductCategoriesFilterBranchId,
  getFetchProductProductCategoriesFilterMarketId,
  getFetchProductProductCategoriesFilterSectorId,
  getFetchProductProductCategoriesIncludes,
  getFetchProductProductCategoriesKeyBy,
  getFetchProductProductCategoriesPage,
  getFetchProductProductCategoriesPerPage,
  getFetchProductProductCategoriesSearch,
  getFetchProductProductCategoriesSort,
  getFetchSelectProductCategoriesFilterBranchId,
  getFetchSelectProductCategoriesFilterMarketId,
  getFetchSelectProductCategoriesFilterProductCategoriesIds,
  getFetchSelectProductCategoriesFilterSectorId,
  getFetchSelectProductCategoriesIncludes,
  getFetchSelectProductCategoriesKeyBy,
  getFetchSelectProductCategoriesPage,
  getFetchSelectProductCategoriesPerPage,
  getFetchSelectProductCategoriesSearch,
  getFetchSelectProductCategoriesSort,
  getIsFetchOrderProductCategoriesHitted,
  getIsFetchProductCategoriesHitted,
  getIsFetchProductProductCategoriesHitted,
  getIsFetchSelectProductCategoriesHitted,
} from "./product-category.selector";

import {
  getProductCategories,
  getProductCategory,
  createProductCategory,
  updateProductCategory,
  deleteProductCategory,
} from "../../api/product-category.api";

export function* _getProductCategories() {
  try {
    yield put(setFetchProductCategoriesLoading(true));

    const search = yield select(getFetchProductCategoriesSearch);
    const sort = yield select(getFetchProductCategoriesSort);
    const key_by = yield select(getFetchProductCategoriesKeyBy);
    const page = yield select(getFetchProductCategoriesPage);
    const per_page = yield select(getFetchProductCategoriesPerPage);
    const includes = yield select(getFetchProductCategoriesIncludes);
    const sector_id = yield select(getFetchProductCategoriesFilterSectorId);
    const market_id = yield select(getFetchProductCategoriesFilterMarketId);
    const branch_id = yield select(getFetchProductCategoriesFilterBranchId);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        sector_id,
        market_id,
        branch_id,
      },
    };

    const {
      meta: { message },
      data: { data: categories },
    } = yield call(getProductCategories, parameters);

    yield put(setIsFetchProductCategoriesHitted(true));
    yield put(setIsProductCategoriesHasMore(categories.length > 0));

    if (page > 1) {
      yield put(appendProductCategories(categories));
    } else {
      yield put(setProductCategories(categories));
    }

    yield put(setFetchProductCategoriesSuccess(message));
    yield put(setFetchProductCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchProductCategoriesFailed(error));
    yield put(setFetchProductCategoriesLoading(false));
  }
}
export function* _getSelectProductCategories() {
  try {
    yield put(setFetchSelectProductCategoriesLoading(true));

    const search = yield select(getFetchSelectProductCategoriesSearch);
    const sort = yield select(getFetchSelectProductCategoriesSort);
    const key_by = yield select(getFetchSelectProductCategoriesKeyBy);
    const page = yield select(getFetchSelectProductCategoriesPage);
    const per_page = yield select(getFetchSelectProductCategoriesPerPage);
    const includes = yield select(getFetchSelectProductCategoriesIncludes);
    const sector_id = yield select(
      getFetchSelectProductCategoriesFilterSectorId
    );
    const market_id = yield select(
      getFetchSelectProductCategoriesFilterMarketId
    );
    const branch_id = yield select(
      getFetchSelectProductCategoriesFilterBranchId
    );
    const product_categories_ids = yield select(
      getFetchSelectProductCategoriesFilterProductCategoriesIds
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        sector_id,
        market_id,
        branch_id,
        product_categories_ids,
      },
    };

    const {
      meta: { message },
      data: categories,
    } = yield call(getProductCategories, parameters);

    yield put(setIsFetchSelectProductCategoriesHitted(true));
    yield put(
      setIsSelectProductCategoriesHasMore(Object.keys(categories).length > 0)
    );

    if (page > 1) {
      yield put(appendSelectProductCategories(categories));
    } else {
      yield put(setSelectProductCategories(categories));
    }

    yield put(setFetchSelectProductCategoriesSuccess(message));
    yield put(setFetchSelectProductCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchSelectProductCategoriesFailed(error));
    yield put(setFetchSelectProductCategoriesLoading(false));
  }
}
export function* _getProductProductCategories() {
  try {
    yield put(setFetchProductProductCategoriesLoading(true));

    const search = yield select(getFetchProductProductCategoriesSearch);
    const sort = yield select(getFetchProductProductCategoriesSort);
    const key_by = yield select(getFetchProductProductCategoriesKeyBy);
    const page = yield select(getFetchProductProductCategoriesPage);
    const per_page = yield select(getFetchProductProductCategoriesPerPage);
    const includes = yield select(getFetchProductProductCategoriesIncludes);
    const sector_id = yield select(
      getFetchProductProductCategoriesFilterSectorId
    );
    const market_id = yield select(
      getFetchProductProductCategoriesFilterMarketId
    );
    const branch_id = yield select(
      getFetchProductProductCategoriesFilterBranchId
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        sector_id,
        market_id,
        branch_id,
      },
    };

    const {
      meta: { message },
      data: { data: categories },
    } = yield call(getProductCategories, parameters);

    yield put(setIsFetchProductProductCategoriesHitted(true));
    yield put(setIsProductProductCategoriesHasMore(categories.length > 0));

    if (page > 1) {
      yield put(appendProductProductCategories(categories));
    } else {
      yield put(setProductProductCategories(categories));
    }

    yield put(setFetchProductProductCategoriesSuccess(message));
    yield put(setFetchProductProductCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchProductProductCategoriesFailed(error));
    yield put(setFetchProductProductCategoriesLoading(false));
  }
}
export function* _getOrderProductCategories() {
  try {
    yield put(setFetchOrderProductCategoriesLoading(true));

    const search = yield select(getFetchOrderProductCategoriesSearch);
    const sort = yield select(getFetchOrderProductCategoriesSort);
    const key_by = yield select(getFetchOrderProductCategoriesKeyBy);
    const page = yield select(getFetchOrderProductCategoriesPage);
    const per_page = yield select(getFetchOrderProductCategoriesPerPage);
    const includes = yield select(getFetchOrderProductCategoriesIncludes);
    const sector_id = yield select(
      getFetchOrderProductCategoriesFilterSectorId
    );
    const market_id = yield select(
      getFetchOrderProductCategoriesFilterMarketId
    );
    const branch_id = yield select(
      getFetchOrderProductCategoriesFilterBranchId
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        sector_id,
        market_id,
        branch_id,
      },
    };

    const {
      meta: { message },
      data: { data: categories },
    } = yield call(getProductCategories, parameters);

    yield put(setIsFetchOrderProductCategoriesHitted(true));
    yield put(setIsOrderProductCategoriesHasMore(categories.length > 0));

    if (page > 1) {
      yield put(appendOrderProductCategories(categories));
    } else {
      yield put(setOrderProductCategories(categories));
    }

    yield put(setFetchOrderProductCategoriesSuccess(message));
    yield put(setFetchOrderProductCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchOrderProductCategoriesFailed(error));
    yield put(setFetchOrderProductCategoriesLoading(false));
  }
}
export function* _getAppendSelectProductCategories() {
  try {
    yield put(setAppendSelectProductCategoriesLoading(true));

    const search = yield select(getAppendSelectProductCategoriesSearch);
    const sort = yield select(getAppendSelectProductCategoriesSort);
    const key_by = yield select(getAppendSelectProductCategoriesKeyBy);
    const page = yield select(getAppendSelectProductCategoriesPage);
    const per_page = yield select(getAppendSelectProductCategoriesPerPage);
    const includes = yield select(getAppendSelectProductCategoriesIncludes);
    const sector_id = yield select(
      getAppendSelectProductCategoriesFilterSectorId
    );
    const market_id = yield select(
      getAppendSelectProductCategoriesFilterMarketId
    );
    const branch_id = yield select(
      getAppendSelectProductCategoriesFilterBranchId
    );
    const product_categories_ids = yield select(
      getAppendSelectProductCategoriesFilterProductCategoriesIds
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        sector_id,
        market_id,
        branch_id,
        product_categories_ids,
      },
    };

    const {
      meta: { message },
      data: categories,
    } = yield call(getProductCategories, parameters);

    yield put(setIsAppendSelectProductCategoriesHitted(true));
    yield put(appendSelectProductCategories(categories));

    yield put(setAppendSelectProductCategoriesSuccess(message));
    yield put(setAppendSelectProductCategoriesLoading(false));
  } catch (error) {
    yield put(setAppendSelectProductCategoriesFailed(error));
    yield put(setAppendSelectProductCategoriesLoading(false));
  }
}
export function* _getProductCategory({ payload: categoryId }) {
  try {
    yield put(setFetchProductCategoryLoading(true));

    const {
      meta: { message },
      data: productCategory,
    } = yield call(getProductCategory, categoryId);

    yield put(setIsFetchProductCategoryHitted(true));
    yield put(setProductCategory(productCategory));

    yield put(setFetchProductCategorySuccess(message));
    yield put(setFetchProductCategoryLoading(false));
  } catch (error) {
    yield put(setFetchProductCategoryFailed(error));
    yield put(setFetchProductCategoryLoading(false));
  }
}
export function* _createProductCategory({ payload: request }) {
  try {
    yield put(setCreateProductCategoryLoading(true));

    const {
      meta: { message },
    } = yield call(createProductCategory, request);

    yield put(setIsCreateProductCategoryHitted(true));

    const isFetchProductCategoriesHitted = yield select(
      getIsFetchProductCategoriesHitted
    );
    const isFetchSelectProductCategoriesHitted = yield select(
      getIsFetchSelectProductCategoriesHitted
    );
    const isFetchProductProductCategoriesHitted = yield select(
      getIsFetchProductProductCategoriesHitted
    );
    const isFetchOrderProductCategoriesHitted = yield select(
      getIsFetchOrderProductCategoriesHitted
    );

    if (isFetchProductCategoriesHitted) {
      yield put(setFetchProductCategoriesPage(1));
      yield call(_getProductCategories);
    }
    if (isFetchSelectProductCategoriesHitted) {
      yield put(setFetchSelectProductCategoriesPage(1));
      yield call(_getSelectProductCategories);
    }
    if (isFetchProductProductCategoriesHitted) {
      yield put(setFetchProductProductCategoriesPage(1));
      yield call(_getProductProductCategories);
    }
    if (isFetchOrderProductCategoriesHitted) {
      yield put(setFetchOrderProductCategoriesPage(1));
      yield call(_getOrderProductCategories);
    }

    yield put(setCreateProductCategorySuccess(message));
    yield put(setCreateProductCategoryLoading(false));
  } catch (error) {
    yield put(setCreateProductCategoryFailed(error));
    yield put(setCreateProductCategoryLoading(false));
  }
}
export function* _updateProductCategory({ payload: { categoryId, request } }) {
  try {
    yield put(setUpdateProductCategoryLoading(true));

    const {
      meta: { message },
    } = yield call(updateProductCategory, categoryId, request);

    yield put(setIsUpdateProductCategoryHitted(true));

    const isFetchProductCategoriesHitted = yield select(
      getIsFetchProductCategoriesHitted
    );
    const isFetchSelectProductCategoriesHitted = yield select(
      getIsFetchSelectProductCategoriesHitted
    );
    const isFetchProductProductCategoriesHitted = yield select(
      getIsFetchProductProductCategoriesHitted
    );
    const isFetchOrderProductCategoriesHitted = yield select(
      getIsFetchOrderProductCategoriesHitted
    );

    if (isFetchProductCategoriesHitted) {
      yield put(setFetchProductCategoriesPage(1));
      yield call(_getProductCategories);
    }
    if (isFetchSelectProductCategoriesHitted) {
      yield put(setFetchSelectProductCategoriesPage(1));
      yield call(_getSelectProductCategories);
    }
    if (isFetchProductProductCategoriesHitted) {
      yield put(setFetchProductProductCategoriesPage(1));
      yield call(_getProductProductCategories);
    }
    if (isFetchOrderProductCategoriesHitted) {
      yield put(setFetchOrderProductCategoriesPage(1));
      yield call(_getOrderProductCategories);
    }

    yield put(setUpdateProductCategorySuccess(message));
    yield put(setUpdateProductCategoryLoading(false));
  } catch (error) {
    yield put(setUpdateProductCategoryFailed(error));
    yield put(setUpdateProductCategoryLoading(false));
  }
}
export function* _deleteProductCategory({ payload: categoryId }) {
  try {
    yield put(setDeleteProductCategoryLoading(true));

    const {
      meta: { message },
    } = yield call(deleteProductCategory, categoryId);

    yield put(setIsDeleteProductCategoryHitted(true));

    const isFetchProductCategoriesHitted = yield select(
      getIsFetchProductCategoriesHitted
    );
    const isFetchSelectProductCategoriesHitted = yield select(
      getIsFetchSelectProductCategoriesHitted
    );
    const isFetchProductProductCategoriesHitted = yield select(
      getIsFetchProductProductCategoriesHitted
    );
    const isFetchOrderProductCategoriesHitted = yield select(
      getIsFetchOrderProductCategoriesHitted
    );

    if (isFetchProductCategoriesHitted) {
      yield put(setFetchProductCategoriesPage(1));
      yield call(_getProductCategories);
    }
    if (isFetchSelectProductCategoriesHitted) {
      yield put(setFetchSelectProductCategoriesPage(1));
      yield call(_getSelectProductCategories);
    }
    if (isFetchProductProductCategoriesHitted) {
      yield put(setFetchProductProductCategoriesPage(1));
      yield call(_getProductProductCategories);
    }
    if (isFetchOrderProductCategoriesHitted) {
      yield put(setFetchOrderProductCategoriesPage(1));
      yield call(_getOrderProductCategories);
    }

    yield put(setDeleteProductCategorySuccess(message));
    yield put(setDeleteProductCategoryLoading(false));
  } catch (error) {
    yield put(setDeleteProductCategoryFailed(error));
    yield put(setDeleteProductCategoryLoading(false));
  }
}

export function* onFetchProductCategoriesStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.FETCH_PRODUCT_CATEGORIES_START,
    _getProductCategories
  );
}
export function* onFetchSelectProductCategoriesStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.FETCH_SELECT_PRODUCT_CATEGORIES_START,
    _getSelectProductCategories
  );
}
export function* onFetchProductProductCategoriesStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.FETCH_PRODUCT_PRODUCT_CATEGORIES_START,
    _getProductProductCategories
  );
}
export function* onFetchOrderProductCategoriesStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.FETCH_ORDER_PRODUCT_CATEGORIES_START,
    _getOrderProductCategories
  );
}
export function* onAppendSelectProductCategoriesStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.APPEND_SELECT_PRODUCT_CATEGORIES_START,
    _getAppendSelectProductCategories
  );
}
export function* onFetchProductCategoryStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.FETCH_PRODUCT_CATEGORY_START,
    _getProductCategory
  );
}
export function* onCreateProductCategoryStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.CREATE_PRODUCT_CATEGORY_START,
    _createProductCategory
  );
}
export function* onUpdateProductCategoryStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.UPDATE_PRODUCT_CATEGORY_START,
    _updateProductCategory
  );
}
export function* onDeleteProductCategoryStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.DELETE_PRODUCT_CATEGORY_START,
    _deleteProductCategory
  );
}

export function* productCategorySaga() {
  yield all([
    call(onFetchProductCategoriesStart),
    call(onFetchSelectProductCategoriesStart),
    call(onFetchProductProductCategoriesStart),
    call(onFetchOrderProductCategoriesStart),
    call(onAppendSelectProductCategoriesStart),
    call(onFetchProductCategoryStart),
    call(onCreateProductCategoryStart),
    call(onUpdateProductCategoryStart),
    call(onDeleteProductCategoryStart),
  ]);
}
