import PRINTER_TYPE_ACTION_TYPES from "./printer-type.type";

export const PRINTER_TYPE_INITIAL_STATE = {
  printerTypes: [],
  selectPrinterTypes: [],
  printerType: null,

  isPrinterTypesHasMore: true,
  isSelectPrinterTypesHasMore: true,

  fetchPrinterTypesPage: 1,
  fetchPrinterTypesPerPage: null,
  fetchPrinterTypesLoading: false,
  fetchPrinterTypesSuccess: null,
  fetchPrinterTypesFailed: null,

  fetchSelectPrinterTypesPage: 1,
  fetchSelectPrinterTypesPerPage: null,
  fetchSelectPrinterTypesLoading: false,
  fetchSelectPrinterTypesSuccess: null,
  fetchSelectPrinterTypesFailed: null,

  fetchPrinterTypeLoading: false,
  fetchPrinterTypeSuccess: null,
  fetchPrinterTypeFailed: null,

  createPrinterTypeLoading: false,
  createPrinterTypeSuccess: null,
  createPrinterTypeFailed: null,

  updatePrinterTypeLoading: false,
  updatePrinterTypeSuccess: null,
  updatePrinterTypeFailed: null,

  deletePrinterTypeLoading: false,
  deletePrinterTypeSuccess: null,
  deletePrinterTypeFailed: null,

  isFetchPrinterTypesHitted: false,
  isFetchSelectPrinterTypesHitted: false,
  isFetchPrinterTypeHitted: false,
  isCreatePrinterTypeHitted: false,
  isUpdatePrinterTypeHitted: false,
  isDeletePrinterTypeHitted: false,
};

export const printerTypeReducer = (state = PRINTER_TYPE_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PRINTER_TYPE_ACTION_TYPES.SET_PRINTER_TYPES:
      return { ...state, printerTypes: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_SELECT_PRINTER_TYPES:
      return { ...state, selectPrinterTypes: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_PRINTER_TYPE:
      return { ...state, printerType: payload };

    case PRINTER_TYPE_ACTION_TYPES.SET_IS_PRINTER_TYPES_HAS_MORE:
      return { ...state, isPrinterTypesHasMore: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_IS_SELECT_PRINTER_TYPES_HAS_MORE:
      return { ...state, isSelectPrinterTypesHasMore: payload };

    case PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPES_PAGE:
      return { ...state, fetchPrinterTypesPage: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPES_PER_PAGE:
      return { ...state, fetchPrinterTypesPerPage: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPES_LOADING:
      return { ...state, fetchPrinterTypesLoading: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPES_SUCCESS:
      return { ...state, fetchPrinterTypesSuccess: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPES_FAILED:
      return { ...state, fetchPrinterTypesFailed: payload };

    case PRINTER_TYPE_ACTION_TYPES.SET_FETCH_SELECT_PRINTER_TYPES_PAGE:
      return { ...state, fetchSelectPrinterTypesPage: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_FETCH_SELECT_PRINTER_TYPES_PER_PAGE:
      return { ...state, fetchSelectPrinterTypesPerPage: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_FETCH_SELECT_PRINTER_TYPES_LOADING:
      return { ...state, fetchSelectPrinterTypesLoading: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_FETCH_SELECT_PRINTER_TYPES_SUCCESS:
      return { ...state, fetchSelectPrinterTypesSuccess: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_FETCH_SELECT_PRINTER_TYPES_FAILED:
      return { ...state, fetchSelectPrinterTypesFailed: payload };

    case PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPE_LOADING:
      return { ...state, fetchPrinterTypeLoading: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPE_SUCCESS:
      return { ...state, fetchPrinterTypeSuccess: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPE_FAILED:
      return { ...state, fetchPrinterTypeFailed: payload };

    case PRINTER_TYPE_ACTION_TYPES.SET_CREATE_PRINTER_TYPE_LOADING:
      return { ...state, createPrinterTypeLoading: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_CREATE_PRINTER_TYPE_SUCCESS:
      return { ...state, createPrinterTypeSuccess: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_CREATE_PRINTER_TYPE_FAILED:
      return { ...state, createPrinterTypeFailed: payload };

    case PRINTER_TYPE_ACTION_TYPES.SET_UPDATE_PRINTER_TYPE_LOADING:
      return { ...state, updatePrinterTypeLoading: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_UPDATE_PRINTER_TYPE_SUCCESS:
      return { ...state, updatePrinterTypeSuccess: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_UPDATE_PRINTER_TYPE_FAILED:
      return { ...state, updatePrinterTypeFailed: payload };

    case PRINTER_TYPE_ACTION_TYPES.SET_DELETE_PRINTER_TYPE_LOADING:
      return { ...state, deletePrinterTypeLoading: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_DELETE_PRINTER_TYPE_SUCCESS:
      return { ...state, deletePrinterTypeSuccess: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_DELETE_PRINTER_TYPE_FAILED:
      return { ...state, deletePrinterTypeFailed: payload };

    case PRINTER_TYPE_ACTION_TYPES.APPEND_PRINTER_TYPES:
      return { ...state, printerTypes: [...state.printerTypes, ...payload] };
    case PRINTER_TYPE_ACTION_TYPES.APPEND_SELECT_PRINTER_TYPES:
      return { ...state, selectPrinterTypes: [...state.selectPrinterTypes, ...payload] };

    case PRINTER_TYPE_ACTION_TYPES.SET_IS_FETCH_PRINTER_TYPES_HITTED:
      return { ...state, isFetchPrinterTypesHitted: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_IS_FETCH_SELECT_PRINTER_TYPES_HITTED:
      return { ...state, isFetchSelectPrinterTypesHitted: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_IS_FETCH_PRINTER_TYPE_HITTED:
      return { ...state, isFetchPrinterTypeHitted: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_IS_CREATE_PRINTER_TYPE_HITTED:
      return { ...state, isCreatePrinterTypeHitted: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_IS_UPDATE_PRINTER_TYPE_HITTED:
      return { ...state, isUpdatePrinterTypeHitted: payload };
    case PRINTER_TYPE_ACTION_TYPES.SET_IS_DELETE_PRINTER_TYPE_HITTED:
      return { ...state, isDeletePrinterTypeHitted: payload };

    case PRINTER_TYPE_ACTION_TYPES.RESET_PRINTER_TYPE_REDUCER:
      return PRINTER_TYPE_INITIAL_STATE;
    default:
      return state;
  }
};
