import { takeLatest, put, all, call, select } from "redux-saga/effects";

import SETTING_ACTION_TYPES from "./setting.type";

import {
  appendSettings,
  setCreateOrUpdateSettingFailed,
  setCreateOrUpdateSettingLoading,
  setCreateOrUpdateSettingSuccess,
  setFetchSettingFailed,
  setFetchSettingLoading,
  setFetchSettingsFailed,
  setFetchSettingsLoading,
  setFetchSettingsPage,
  setFetchSettingsSuccess,
  setFetchSettingSuccess,
  setIsCreateOrUpdateSettingHitted,
  setIsFetchSettingsHitted,
  setIsSettingsHasMore,
  setSetting,
  setSettings,
} from "./setting.action";
import {
  getFetchSettingsFilterKeys,
  getFetchSettingsKeyBy,
  getFetchSettingsPage,
  getFetchSettingsPerPage,
  getFetchSettingsSearch,
  getFetchSettingsSort,
  getIsFetchSettingHitted,
  getIsFetchSettingsHitted,
} from "./setting.selector";

import {
  getSettings,
  getSetting,
  createOrUpdateSetting,
} from "../../api/setting.api";

export function* _getSettings() {
  try {
    yield put(setFetchSettingsLoading(true));

    const search = yield select(getFetchSettingsSearch);
    const sort = yield select(getFetchSettingsSort);
    const key_by = yield select(getFetchSettingsKeyBy);
    const page = yield select(getFetchSettingsPage);
    const per_page = yield select(getFetchSettingsPerPage);
    const keys = yield select(getFetchSettingsFilterKeys);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      filter: { keys },
    };

    const {
      meta: { message },
      data: settings,
    } = yield call(getSettings, parameters);

    yield put(setIsFetchSettingsHitted(true));
    yield put(setIsSettingsHasMore(Object.keys(settings).length > 0));

    if (page > 1) {
      yield put(appendSettings(settings));
    } else {
      yield put(setSettings(settings));
    }

    yield put(setFetchSettingsSuccess(message));
    yield put(setFetchSettingsLoading(false));
  } catch (error) {
    yield put(setFetchSettingsFailed(error));
    yield put(setFetchSettingsLoading(false));
  }
}

export function* _getSetting({ payload: settingKey }) {
  try {
    yield put(setFetchSettingLoading(true));

    const {
      meta: { message },
      data: setting,
    } = yield call(getSetting, settingKey);

    yield put(getIsFetchSettingHitted(true));
    yield put(setSetting(setting));

    yield put(setFetchSettingSuccess(message));
    yield put(setFetchSettingLoading(false));
  } catch (error) {
    yield put(setFetchSettingFailed(error));
    yield put(setFetchSettingLoading(false));
  }
}

export function* _createOrUpdateSetting({ payload: request }) {
  try {
    yield put(setCreateOrUpdateSettingLoading(true));

    const {
      meta: { message },
    } = yield call(createOrUpdateSetting, request);

    yield put(setIsCreateOrUpdateSettingHitted(true));

    const isFetchSettingsHitted = yield select(getIsFetchSettingsHitted);

    if (isFetchSettingsHitted) {
      yield put(setFetchSettingsPage(1));
      yield call(_getSettings);
    }

    yield put(setCreateOrUpdateSettingSuccess(message));
    yield put(setCreateOrUpdateSettingLoading(false));
  } catch (error) {
    yield put(setCreateOrUpdateSettingFailed(error));
    yield put(setCreateOrUpdateSettingLoading(false));
  }
}

export function* onFetchSettingsStart() {
  yield takeLatest(SETTING_ACTION_TYPES.FETCH_SETTINGS_START, _getSettings);
}
export function* onFetchSettingStart() {
  yield takeLatest(SETTING_ACTION_TYPES.FETCH_SETTING_START, _getSetting);
}
export function* onCreateOrUpdateSettingStart() {
  yield takeLatest(
    SETTING_ACTION_TYPES.CREATE_OR_UPDATE_SETTING_START,
    _createOrUpdateSetting
  );
}

export function* settingSaga() {
  yield all([
    call(onFetchSettingsStart),
    call(onFetchSettingStart),
    call(onCreateOrUpdateSettingStart),
  ]);
}
