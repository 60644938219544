import CUSTOMER_ACTION_TYPES from "./customer.type";
import { createAction } from "../../utils/store.utils";

export const setCustomers = (customers) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_CUSTOMERS, customers);
export const setSearchCustomers = (searchCustomers) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_SEARCH_CUSTOMERS, searchCustomers);
export const setCustomer = (customer) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_CUSTOMER, customer);
export const setCurrentCustomer = (currentCustomer) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_CURRENT_CUSTOMER, currentCustomer);
export const setCreateCustomer = (createCustomer) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER, createCustomer);
export const setUpdateCustomer = (updateCustomer) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER, updateCustomer);

export const setIsCustomersHasMore = (isCustomersHasMore) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_IS_CUSTOMERS_HAS_MORE,
    isCustomersHasMore
  );
export const setIsSearchCustomersHasMore = (isSearchCustomersHasMore) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_IS_SEARCH_CUSTOMERS_HAS_MORE,
    isSearchCustomersHasMore
  );

export const setFetchCustomersSearch = (fetchCustomersSearch) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SEARCH,
    fetchCustomersSearch
  );
export const setFetchCustomersSort = (fetchCustomersSort) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SORT,
    fetchCustomersSort
  );
export const setFetchCustomersKeyBy = (fetchCustomersKeyBy) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_KEY_BY,
    fetchCustomersKeyBy
  );
export const setFetchCustomersPage = (fetchCustomersPage) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_PAGE,
    fetchCustomersPage
  );
export const setFetchCustomersPerPage = (fetchCustomersPerPage) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_PER_PAGE,
    fetchCustomersPerPage
  );
export const setFetchCustomersIncludes = (fetchCustomersIncludes) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_INCLUDES,
    fetchCustomersIncludes
  );
export const setFetchCustomersFilterMarketId = (fetchCustomersFilterMarketId) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_FILTER_MARKET_ID,
    fetchCustomersFilterMarketId
  );
export const setFetchCustomersLoading = (fetchCustomersLoading) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_LOADING,
    fetchCustomersLoading
  );
export const setFetchCustomersSuccess = (fetchCustomersSuccess) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SUCCESS,
    fetchCustomersSuccess
  );
export const setFetchCustomersFailed = (fetchCustomersFailed) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_FAILED,
    fetchCustomersFailed
  );

export const setFetchSearchCustomersSearch = (fetchSearchCustomersSearch) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_SEARCH,
    fetchSearchCustomersSearch
  );
export const setFetchSearchCustomersSort = (fetchSearchCustomersSort) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_SORT,
    fetchSearchCustomersSort
  );
export const setFetchSearchCustomersKeyBy = (fetchSearchCustomersKeyBy) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_KEY_BY,
    fetchSearchCustomersKeyBy
  );
export const setFetchSearchCustomersPage = (fetchSearchCustomersPage) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_PAGE,
    fetchSearchCustomersPage
  );
export const setFetchSearchCustomersPerPage = (fetchSearchCustomersPerPage) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_PER_PAGE,
    fetchSearchCustomersPerPage
  );
export const setFetchSearchCustomersIncludes = (fetchSearchCustomersIncludes) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_INCLUDES,
    fetchSearchCustomersIncludes
  );
export const setFetchSearchCustomersFilterMarketId = (
  fetchSearchCustomersFilterMarketId
) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_FILTER_MARKET_ID,
    fetchSearchCustomersFilterMarketId
  );
export const setFetchSearchCustomersLoading = (fetchSearchCustomersLoading) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_LOADING,
    fetchSearchCustomersLoading
  );
export const setFetchSearchCustomersSuccess = (fetchSearchCustomersSuccess) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_SUCCESS,
    fetchSearchCustomersSuccess
  );
export const setFetchSearchCustomersFailed = (fetchSearchCustomersFailed) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_FAILED,
    fetchSearchCustomersFailed
  );

export const setFetchCustomerLoading = (fetchCustomerLoading) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMER_LOADING,
    fetchCustomerLoading
  );
export const setFetchCustomerSuccess = (fetchCustomerSuccess) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMER_SUCCESS,
    fetchCustomerSuccess
  );
export const setFetchCustomerFailed = (fetchCustomerFailed) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMER_FAILED,
    fetchCustomerFailed
  );

export const setCreateCustomerLoading = (createCustomerLoading) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_LOADING,
    createCustomerLoading
  );
export const setCreateCustomerSuccess = (createCustomerSuccess) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_SUCCESS,
    createCustomerSuccess
  );
export const setCreateCustomerFailed = (createCustomerFailed) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_FAILED,
    createCustomerFailed
  );

export const setUpdateCustomerLoading = (updateCustomerLoading) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_LOADING,
    updateCustomerLoading
  );
export const setUpdateCustomerSuccess = (updateCustomerSuccess) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_SUCCESS,
    updateCustomerSuccess
  );
export const setUpdateCustomerFailed = (updateCustomerFailed) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_FAILED,
    updateCustomerFailed
  );

export const appendCustomers = (customers) =>
  createAction(CUSTOMER_ACTION_TYPES.APPEND_CUSTOMERS, customers);
export const appendSearchCustomers = (searchCustomers) =>
  createAction(CUSTOMER_ACTION_TYPES.APPEND_SEARCH_CUSTOMERS, searchCustomers);

export const fetchCustomersStart = () =>
  createAction(CUSTOMER_ACTION_TYPES.FETCH_CUSTOMERS_START);
export const fetchSearchCustomersStart = () =>
  createAction(CUSTOMER_ACTION_TYPES.FETCH_SEARCH_CUSTOMERS_START);
export const fetchCustomerStart = (customerId) =>
  createAction(CUSTOMER_ACTION_TYPES.FETCH_CUSTOMER_START, customerId);
export const createCustomerStart = (request) =>
  createAction(CUSTOMER_ACTION_TYPES.CREATE_CUSTOMER_START, request);
export const updateCustomerStart = (customerId, request) =>
  createAction(CUSTOMER_ACTION_TYPES.UPDATE_CUSTOMER_START, {
    customerId,
    request,
  });

export const setIsFetchCustomersHitted = (isFetchCustomersHitted) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_IS_FETCH_CUSTOMERS_HITTED,
    isFetchCustomersHitted
  );
export const setIsFetchSearchCustomersHitted = (isFetchSearchCustomersHitted) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_IS_FETCH_SEARCH_CUSTOMERS_HITTED,
    isFetchSearchCustomersHitted
  );
export const setIsFetchCustomerHitted = (isFetchCustomerHitted) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_IS_FETCH_CUSTOMER_HITTED,
    isFetchCustomerHitted
  );
export const setIsCreateCustomerHitted = (isCreateCustomerHitted) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_IS_CREATE_CUSTOMER_HITTED,
    isCreateCustomerHitted
  );
export const setIsUpdateCustomerHitted = (isUpdateCustomerHitted) =>
  createAction(
    CUSTOMER_ACTION_TYPES.SET_IS_UPDATE_CUSTOMER_HITTED,
    isUpdateCustomerHitted
  );

export const resetCustomerReducer = () =>
  createAction(CUSTOMER_ACTION_TYPES.RESET_CUSTOMER_REDUCER);
