import { PRODUCT_MODIFIER_TYPES } from "../../constants/product-modifier.constant";

import PRODUCT_MODIFIER_ACTION_TYPES from "./product-modifier.type";

export const PRODUCT_MODIFIER_INITIAL_STATE = {
  optionPriceTypes: [
    {
      label: "Fixed Price",
      value: PRODUCT_MODIFIER_TYPES.OPTION,
    },
    {
      label: "Variable Price",
      value: PRODUCT_MODIFIER_TYPES.OPTION_VARIABLE,
    },
  ],
  additionalPriceTypes: [
    {
      label: "Fixed Price",
      value: PRODUCT_MODIFIER_TYPES.ADDITIONAL,
    },
    {
      label: "Variable Price",
      value: PRODUCT_MODIFIER_TYPES.ADDITIONAL_VARIABLE,
    },
  ],
  scannedProductModifier: null,
  createProductModifier: null,
  updateProductModifier: null,
  orderVariableProductModifiers: [],
  orderVariableCartRequest: null,
  orderWeightProductModifiers: [],
  orderWeightCartRequest: null,
  orderCustomizeProductModifiers: [],

  createProductModifierLoading: false,
  createProductModifierSuccess: null,
  createProductModifierFailed: null,

  updateProductModifierLoading: false,
  updateProductModifierSuccess: null,
  updateProductModifierFailed: null,

  fetchScannedProductModifierLoading: false,
  fetchScannedProductModifierSuccess: null,
  fetchScannedProductModifierFailed: null,
};

export const productModifierReducer = (
  state = PRODUCT_MODIFIER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_SCANNED_PRODUCT_MODIFIER:
      return { ...state, scannedProductModifier: payload };
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_CREATE_PRODUCT_MODIFIER:
      return { ...state, createProductModifier: payload };
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_UPDATE_PRODUCT_MODIFIER:
      return { ...state, updateProductModifier: payload };
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_ORDER_VARIABLE_PRODUCT_MODIFIERS:
      return { ...state, orderVariableProductModifiers: payload };
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_ORDER_VARIABLE_CART_REQUEST:
      return { ...state, orderVariableCartRequest: payload };
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_ORDER_WEIGHT_PRODUCT_MODIFIERS:
      return { ...state, orderWeightProductModifiers: payload };
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_ORDER_WEIGHT_CART_REQUEST:
      return { ...state, orderWeightCartRequest: payload };
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_ORDER_CUSTOMIZE_PRODUCT_MODIFIERS:
      return { ...state, orderCustomizeProductModifiers: payload };

    case PRODUCT_MODIFIER_ACTION_TYPES.SET_CREATE_PRODUCT_MODIFIER_LOADING:
      return { ...state, createProductModifierLoading: payload };
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_CREATE_PRODUCT_MODIFIER_SUCCESS:
      return { ...state, createProductModifierSuccess: payload };
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_CREATE_PRODUCT_MODIFIER_FAILED:
      return { ...state, createProductModifierFailed: payload };

    case PRODUCT_MODIFIER_ACTION_TYPES.SET_UPDATE_PRODUCT_MODIFIER_LOADING:
      return { ...state, updateProductModifierLoading: payload };
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_UPDATE_PRODUCT_MODIFIER_SUCCESS:
      return { ...state, updateProductModifierSuccess: payload };
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_UPDATE_PRODUCT_MODIFIER_FAILED:
      return { ...state, updateProductModifierFailed: payload };

    case PRODUCT_MODIFIER_ACTION_TYPES.SET_FETCH_SCANNED_PRODUCT_MODIFIER_LOADING:
      return { ...state, fetchScannedProductModifierLoading: payload };
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_FETCH_SCANNED_PRODUCT_MODIFIER_SUCCESS:
      return { ...state, fetchScannedProductModifierSuccess: payload };
    case PRODUCT_MODIFIER_ACTION_TYPES.SET_FETCH_SCANNED_PRODUCT_MODIFIER_FAILED:
      return { ...state, fetchScannedProductModifierFailed: payload };

    case PRODUCT_MODIFIER_ACTION_TYPES.RESET_PRODUCT_MODIFIER_REDUCER:
      return PRODUCT_MODIFIER_INITIAL_STATE;
    default:
      return state;
  }
};
