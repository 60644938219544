import { createSelector } from "reselect";

const paymentDeviceSelector = ({ paymentDevice }) => paymentDevice;

export const getPaymentDeviceTargets = createSelector(
  [paymentDeviceSelector],
  ({ paymentDeviceTargets }) => paymentDeviceTargets
);

export const getPaymentDeviceCompanies = createSelector(
  [paymentDeviceSelector],
  ({ paymentDeviceCompanies }) => paymentDeviceCompanies
);

export const getPaymentDevices = createSelector([paymentDeviceSelector], ({ paymentDevices }) => paymentDevices);

export const getPaymentDevice = createSelector([paymentDeviceSelector], ({ paymentDevice }) => paymentDevice);

export const getIsPaymentDevicesHasMore = createSelector(
  [paymentDeviceSelector],
  ({ isPaymentDevicesHasMore }) => isPaymentDevicesHasMore
);

export const getFetchPaymentDevicesSearch = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDevicesSearch }) => fetchPaymentDevicesSearch
);

export const getFetchPaymentDevicesPage = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDevicesPage }) => fetchPaymentDevicesPage
);

export const getFetchPaymentDevicesPerPage = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDevicesPerPage }) => fetchPaymentDevicesPerPage
);

export const getFetchPaymentDevicesIncludes = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDevicesIncludes }) => fetchPaymentDevicesIncludes
);

export const getFetchPaymentDevicesFilterMarketId = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDevicesFilterMarketId }) => fetchPaymentDevicesFilterMarketId
);

export const getFetchPaymentDevicesFilterBranchId = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDevicesFilterBranchId }) => fetchPaymentDevicesFilterBranchId
);

export const getFetchPaymentDevicesFilterPaymentDevicesIds = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDevicesFilterPaymentDevicesIds }) => fetchPaymentDevicesFilterPaymentDevicesIds
);

export const getFetchPaymentDevicesFilterUsersIds = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDevicesFilterUsersIds }) => fetchPaymentDevicesFilterUsersIds
);

export const getFetchPaymentDevicesLoading = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDevicesLoading }) => fetchPaymentDevicesLoading
);

export const getFetchPaymentDevicesSuccess = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDevicesSuccess }) => fetchPaymentDevicesSuccess
);

export const getFetchPaymentDevicesFailed = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDevicesFailed }) => fetchPaymentDevicesFailed
);

export const getFetchPaymentDeviceLoading = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDeviceLoading }) => fetchPaymentDeviceLoading
);

export const getFetchPaymentDeviceSuccess = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDeviceSuccess }) => fetchPaymentDeviceSuccess
);

export const getFetchPaymentDeviceFailed = createSelector(
  [paymentDeviceSelector],
  ({ fetchPaymentDeviceFailed }) => fetchPaymentDeviceFailed
);

export const getCreatePaymentDeviceLoading = createSelector(
  [paymentDeviceSelector],
  ({ createPaymentDeviceLoading }) => createPaymentDeviceLoading
);

export const getCreatePaymentDeviceSuccess = createSelector(
  [paymentDeviceSelector],
  ({ createPaymentDeviceSuccess }) => createPaymentDeviceSuccess
);

export const getCreatePaymentDeviceFailed = createSelector(
  [paymentDeviceSelector],
  ({ createPaymentDeviceFailed }) => createPaymentDeviceFailed
);

export const getUpdatePaymentDeviceLoading = createSelector(
  [paymentDeviceSelector],
  ({ updatePaymentDeviceLoading }) => updatePaymentDeviceLoading
);

export const getUpdatePaymentDeviceSuccess = createSelector(
  [paymentDeviceSelector],
  ({ updatePaymentDeviceSuccess }) => updatePaymentDeviceSuccess
);

export const getUpdatePaymentDeviceFailed = createSelector(
  [paymentDeviceSelector],
  ({ updatePaymentDeviceFailed }) => updatePaymentDeviceFailed
);

export const getDeletePaymentDeviceLoading = createSelector(
  [paymentDeviceSelector],
  ({ deletePaymentDeviceLoading }) => deletePaymentDeviceLoading
);

export const getDeletePaymentDeviceSuccess = createSelector(
  [paymentDeviceSelector],
  ({ deletePaymentDeviceSuccess }) => deletePaymentDeviceSuccess
);

export const getDeletePaymentDeviceFailed = createSelector(
  [paymentDeviceSelector],
  ({ deletePaymentDeviceFailed }) => deletePaymentDeviceFailed
);

export const getIsFetchPaymentDevicesHitted = createSelector(
  [paymentDeviceSelector],
  ({ isFetchPaymentDevicesHitted }) => isFetchPaymentDevicesHitted
);

export const getIsFetchPaymentDeviceHitted = createSelector(
  [paymentDeviceSelector],
  ({ isFetchPaymentDeviceHitted }) => isFetchPaymentDeviceHitted
);

export const getIsCreatePaymentDeviceHitted = createSelector(
  [paymentDeviceSelector],
  ({ isCreatePaymentDeviceHitted }) => isCreatePaymentDeviceHitted
);

export const getIsUpdatePaymentDeviceHitted = createSelector(
  [paymentDeviceSelector],
  ({ isUpdatePaymentDeviceHitted }) => isUpdatePaymentDeviceHitted
);

export const getIsDeletePaymentDeviceHitted = createSelector(
  [paymentDeviceSelector],
  ({ isDeletePaymentDeviceHitted }) => isDeletePaymentDeviceHitted
);
