import apiService from "./api";

export const getSummaryFinancialTotal = async (parameters) =>
  (await apiService.get("/summary/financial-total", parameters)).data;
export const getSummaryFinancialChart = async (parameters) =>
  (await apiService.get("/summary/financial-chart", parameters)).data;
export const getSummaryTopProducts = async (parameters) =>
  (await apiService.get("/summary/top-product", parameters)).data;
export const getSummaryTopProductCategories = async (parameters) =>
  (await apiService.get("/summary/top-product-category", parameters)).data;
export const getSummaryLatestOrders = async (parameters) =>
  (await apiService.get("/summary/latest-order", parameters)).data;
export const getSummaryLatestRatings = async (parameters) =>
  (await apiService.get("/summary/latest-rating", parameters)).data;
export const getSummarySubscriberReport = async (parameters) =>
  (await apiService.get("/summary/subscriber-report", parameters)).data;
export const getSummaryPaymentMethods = async (parameters) =>
  (await apiService.get("/summary/payment-method", parameters)).data;
export const getSummarySubscribers = async (parameters) =>
  (await apiService.get("/summary/subscriber", parameters)).data;
export const getSummaryBranches = async (parameters) =>
  (await apiService.get("/summary/branch", parameters)).data;
export const getSummaryTotalUser = async (parameters) =>
  (await apiService.get("/summary/total/user", parameters)).data;
export const getSummaryTotalMarket = async (parameters) =>
  (await apiService.get("/summary/total/market", parameters)).data;
export const getSummaryTotalBranch = async (parameters) =>
  (await apiService.get("/summary/total/branch", parameters)).data;
