import DASHBOARD_ADMIN_ACTION_TYPES from "./dashboard-admin.type";
import { createAction } from "../../utils/store.utils";

export const setDashboardTotalMarket = (dashboardTotalMarket) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_MARKET,
    dashboardTotalMarket
  );
export const setDashboardTotalBranch = (dashboardTotalBranch) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_BRANCH,
    dashboardTotalBranch
  );
export const setDashboardTotalOrder = (dashboardTotalOrder) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_ORDER,
    dashboardTotalOrder
  );
export const setDashboardTotalRevenue = (dashboardTotalRevenue) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_REVENUE,
    dashboardTotalRevenue
  );
export const setDashboardOrdersChart = (dashboardOrdersChart) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_ORDERS_CHART,
    dashboardOrdersChart
  );
export const setDashboardLatestMarkets = (dashboardLatestMarkets) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_LATEST_MARKETS,
    dashboardLatestMarkets
  );

export const setDashboardTotalMarketLoading = (dashboardTotalMarketLoading) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_MARKET_LOADING,
    dashboardTotalMarketLoading
  );
export const setDashboardTotalBranchLoading = (dashboardTotalBranchLoading) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_BRANCH_LOADING,
    dashboardTotalBranchLoading
  );
export const setDashboardTotalOrderLoading = (dashboardTotalOrderLoading) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_ORDER_LOADING,
    dashboardTotalOrderLoading
  );
export const setDashboardTotalRevenueLoading = (dashboardTotalRevenueLoading) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_REVENUE_LOADING,
    dashboardTotalRevenueLoading
  );
export const setDashboardOrdersChartLoading = (dashboardOrdersChartLoading) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_ORDERS_CHART_LOADING,
    dashboardOrdersChartLoading
  );
export const setDashboardLatestMarketsLoading = (
  dashboardLatestMarketsLoading
) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_LATEST_MARKETS_LOADING,
    dashboardLatestMarketsLoading
  );

export const setFetchDashboardAdminFilterCreatedAtBefore = (
  fetchDashboardAdminFilterCreatedAtBefore
) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_FETCH_DASHBOARD_ADMIN_FILTER_CREATED_AT_BEFORE,
    fetchDashboardAdminFilterCreatedAtBefore
  );
export const setFetchDashboardAdminFilterCreatedAtAfter = (
  fetchDashboardAdminFilterCreatedAtAfter
) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_FETCH_DASHBOARD_ADMIN_FILTER_CREATED_AT_AFTER,
    fetchDashboardAdminFilterCreatedAtAfter
  );
export const setFetchDashboardAdminFilterCurrency = (
  fetchDashboardAdminFilterCurrency
) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_FETCH_DASHBOARD_ADMIN_FILTER_CURRENCY,
    fetchDashboardAdminFilterCurrency
  );
export const setFetchDashboardAdminLoading = (fetchDashboardAdminLoading) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_FETCH_DASHBOARD_ADMIN_LOADING,
    fetchDashboardAdminLoading
  );
export const setFetchDashboardAdminSuccess = (fetchDashboardAdminSuccess) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_FETCH_DASHBOARD_ADMIN_SUCCESS,
    fetchDashboardAdminSuccess
  );
export const setFetchDashboardAdminFailed = (fetchDashboardAdminFailed) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_FETCH_DASHBOARD_ADMIN_FAILED,
    fetchDashboardAdminFailed
  );

export const setIsFetchDashboardAdminHitted = (isFetchDashboardAdminHitted) =>
  createAction(
    DASHBOARD_ADMIN_ACTION_TYPES.SET_IS_FETCH_DASHBOARD_ADMIN_HITTED,
    isFetchDashboardAdminHitted
  );

export const fetchDashboardAdminStart = () =>
  createAction(DASHBOARD_ADMIN_ACTION_TYPES.FETCH_DASHBOARD_ADMIN_START);

export const resetDashboardAdminReducer = () =>
  createAction(DASHBOARD_ADMIN_ACTION_TYPES.RESET_DASHBOARD_ADMIN_REDUCER);
