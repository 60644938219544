import apiService from "./api";

export const getTermConditions = async (parameters) => (await apiService.get("/term-condition", parameters)).data;

export const getTermCondition = async (id) => (await apiService.get(`/term-condition/${id}`)).data;

export const createTermCondition = async (request) => (await apiService.post("/term-condition", request)).data;

export const updateTermCondition = async (id, request) =>
  (await apiService.post(`/term-condition/${id}`, request)).data;

export const deleteTermCondition = async (id) => (await apiService.delete(`/term-condition/${id}`)).data;
