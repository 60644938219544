const USER_MERCHANT_ACTION_TYPES = {
  RESET_USER_MERCHANT_REDUCER: "user-merchant/RESET_USER_MERCHANT_REDUCER",

  SET_USER_MERCHANTS: "user-merchant/SET_USER_MERCHANTS",
  SET_SELECT_USER_MERCHANTS: "user-merchant/SET_SELECT_USER_MERCHANTS",
  SET_USER_MERCHANT: "user-merchant/SET_USER_MERCHANT",

  SET_IS_USER_MERCHANTS_HAS_MORE:
    "user-merchant/SET_IS_USER_MERCHANTS_HAS_MORE",
  SET_IS_SELECT_USER_MERCHANTS_HAS_MORE:
    "user-merchant/SET_IS_SELECT_USER_MERCHANTS_HAS_MORE",

  SET_FETCH_USER_MERCHANTS_SEARCH:
    "user-merchant/SET_FETCH_USER_MERCHANTS_SEARCH",
  SET_FETCH_USER_MERCHANTS_SORT: "user-merchant/SET_FETCH_USER_MERCHANTS_SORT",
  SET_FETCH_USER_MERCHANTS_KEY_BY:
    "user-merchant/SET_FETCH_USER_MERCHANTS_KEY_BY",
  SET_FETCH_USER_MERCHANTS_PAGE: "user-merchant/SET_FETCH_USER_MERCHANTS_PAGE",
  SET_FETCH_USER_MERCHANTS_PER_PAGE:
    "user-merchant/SET_FETCH_USER_MERCHANTS_PER_PAGE",
  SET_FETCH_USER_MERCHANTS_INCLUDES:
    "user-merchant/SET_FETCH_USER_MERCHANTS_INCLUDES",
  SET_FETCH_USER_MERCHANTS_FILTER_MERCHANT_ID:
    "user-merchant/SET_FETCH_USER_MERCHANTS_FILTER_MERCHANT_ID",
  SET_FETCH_USER_MERCHANTS_FILTER_PERMISSION_MERCHANT_ID:
    "user-merchant/SET_FETCH_USER_MERCHANTS_FILTER_PERMISSION_MERCHANT_ID",
  SET_FETCH_USER_MERCHANTS_FILTER_PERMISSION_SUBSCRIBER_ID:
    "user-merchant/SET_FETCH_USER_MERCHANTS_FILTER_PERMISSION_SUBSCRIBER_ID",
  SET_FETCH_USER_MERCHANTS_FILTER_MARKET_ID:
    "user-merchant/SET_FETCH_USER_MERCHANTS_FILTER_MARKET_ID",
  SET_FETCH_USER_MERCHANTS_FILTER_USERS_IDS:
    "user-merchant/SET_FETCH_USER_MERCHANTS_FILTER_USERS_IDS",
  SET_FETCH_USER_MERCHANTS_LOADING:
    "user-merchant/SET_FETCH_USER_MERCHANTS_LOADING",
  SET_FETCH_USER_MERCHANTS_SUCCESS:
    "user-merchant/SET_FETCH_USER_MERCHANTS_SUCCESS",
  SET_FETCH_USER_MERCHANTS_FAILED:
    "user-merchant/SET_FETCH_USER_MERCHANTS_FAILED",

  SET_FETCH_SELECT_USER_MERCHANTS_SEARCH:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_SEARCH",
  SET_FETCH_SELECT_USER_MERCHANTS_SORT:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_SORT",
  SET_FETCH_SELECT_USER_MERCHANTS_KEY_BY:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_KEY_BY",
  SET_FETCH_SELECT_USER_MERCHANTS_PAGE:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_PAGE",
  SET_FETCH_SELECT_USER_MERCHANTS_PER_PAGE:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_PER_PAGE",
  SET_FETCH_SELECT_USER_MERCHANTS_INCLUDES:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_INCLUDES",
  SET_FETCH_SELECT_USER_MERCHANTS_FILTER_MERCHANT_ID:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_FILTER_MERCHANT_ID",
  SET_FETCH_SELECT_USER_MERCHANTS_FILTER_PERMISSION_MERCHANT_ID:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_FILTER_PERMISSION_MERCHANT_ID",
  SET_FETCH_SELECT_USER_MERCHANTS_FILTER_PERMISSION_SUBSCRIBER_ID:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_FILTER_PERMISSION_SUBSCRIBER_ID",
  SET_FETCH_SELECT_USER_MERCHANTS_FILTER_MARKET_ID:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_FILTER_MARKET_ID",
  SET_FETCH_SELECT_USER_MERCHANTS_FILTER_USERS_IDS:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_FILTER_USERS_IDS",
  SET_FETCH_SELECT_USER_MERCHANTS_LOADING:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_LOADING",
  SET_FETCH_SELECT_USER_MERCHANTS_SUCCESS:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_SUCCESS",
  SET_FETCH_SELECT_USER_MERCHANTS_FAILED:
    "user-merchant/SET_FETCH_SELECT_USER_MERCHANTS_FAILED",

  SET_APPEND_SELECT_USER_MERCHANTS_SEARCH:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_SEARCH",
  SET_APPEND_SELECT_USER_MERCHANTS_SORT:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_SORT",
  SET_APPEND_SELECT_USER_MERCHANTS_KEY_BY:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_KEY_BY",
  SET_APPEND_SELECT_USER_MERCHANTS_PAGE:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_PAGE",
  SET_APPEND_SELECT_USER_MERCHANTS_PER_PAGE:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_PER_PAGE",
  SET_APPEND_SELECT_USER_MERCHANTS_INCLUDES:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_INCLUDES",
  SET_APPEND_SELECT_USER_MERCHANTS_FILTER_MERCHANT_ID:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_FILTER_MERCHANT_ID",
  SET_APPEND_SELECT_USER_MERCHANTS_FILTER_PERMISSION_MERCHANT_ID:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_FILTER_PERMISSION_MERCHANT_ID",
  SET_APPEND_SELECT_USER_MERCHANTS_FILTER_PERMISSION_SUBSCRIBER_ID:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_FILTER_PERMISSION_SUBSCRIBER_ID",
  SET_APPEND_SELECT_USER_MERCHANTS_FILTER_MARKET_ID:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_FILTER_MARKET_ID",
  SET_APPEND_SELECT_USER_MERCHANTS_FILTER_USERS_IDS:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_FILTER_USERS_IDS",
  SET_APPEND_SELECT_USER_MERCHANTS_LOADING:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_LOADING",
  SET_APPEND_SELECT_USER_MERCHANTS_SUCCESS:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_SUCCESS",
  SET_APPEND_SELECT_USER_MERCHANTS_FAILED:
    "user-merchant/SET_APPEND_SELECT_USER_MERCHANTS_FAILED",

  SET_FETCH_USER_MERCHANT_LOADING:
    "user-merchant/SET_FETCH_USER_MERCHANT_LOADING",
  SET_FETCH_USER_MERCHANT_SUCCESS:
    "user-merchant/SET_FETCH_USER_MERCHANT_SUCCESS",
  SET_FETCH_USER_MERCHANT_FAILED:
    "user-merchant/SET_FETCH_USER_MERCHANT_FAILED",

  SET_CREATE_USER_MERCHANT_LOADING:
    "user-merchant/SET_CREATE_USER_MERCHANT_LOADING",
  SET_CREATE_USER_MERCHANT_SUCCESS:
    "user-merchant/SET_CREATE_USER_MERCHANT_SUCCESS",
  SET_CREATE_USER_MERCHANT_FAILED:
    "user-merchant/SET_CREATE_USER_MERCHANT_FAILED",

  SET_UPDATE_USER_MERCHANT_LOADING:
    "user-merchant/SET_UPDATE_USER_MERCHANT_LOADING",
  SET_UPDATE_USER_MERCHANT_SUCCESS:
    "user-merchant/SET_UPDATE_USER_MERCHANT_SUCCESS",
  SET_UPDATE_USER_MERCHANT_FAILED:
    "user-merchant/SET_UPDATE_USER_MERCHANT_FAILED",

  SET_DELETE_USER_MERCHANT_LOADING:
    "user-merchant/SET_DELETE_USER_MERCHANT_LOADING",
  SET_DELETE_USER_MERCHANT_SUCCESS:
    "user-merchant/SET_DELETE_USER_MERCHANT_SUCCESS",
  SET_DELETE_USER_MERCHANT_FAILED:
    "user-merchant/SET_DELETE_USER_MERCHANT_FAILED",

  APPEND_USER_MERCHANTS: "user-merchant/APPEND_USER_MERCHANTS",
  APPEND_SELECT_USER_MERCHANTS: "user-merchant/APPEND_SELECT_USER_MERCHANTS",

  SET_IS_FETCH_USER_MERCHANTS_HITTED:
    "user-merchant/SET_IS_FETCH_USER_MERCHANTS_HITTED",
  SET_IS_FETCH_SELECT_USER_MERCHANTS_HITTED:
    "user-merchant/SET_IS_FETCH_SELECT_USER_MERCHANTS_HITTED",
  SET_IS_APPEND_SELECT_USER_MERCHANTS_HITTED:
    "user-merchant/SET_IS_APPEND_SELECT_USER_MERCHANTS_HITTED",
  SET_IS_FETCH_USER_MERCHANT_HITTED:
    "user-merchant/SET_IS_FETCH_USER_MERCHANT_HITTED",
  SET_IS_CREATE_USER_MERCHANT_HITTED:
    "user-merchant/SET_IS_CREATE_USER_MERCHANT_HITTED",
  SET_IS_UPDATE_USER_MERCHANT_HITTED:
    "user-merchant/SET_IS_UPDATE_USER_MERCHANT_HITTED",
  SET_IS_DELETE_USER_MERCHANT_HITTED:
    "user-merchant/SET_IS_DELETE_USER_MERCHANT_HITTED",

  FETCH_USER_MERCHANTS_START: "user-merchant/FETCH_USER_MERCHANTS_START",
  FETCH_SELECT_USER_MERCHANTS_START:
    "user-merchant/FETCH_SELECT_USER_MERCHANTS_START",
  APPEND_SELECT_USER_MERCHANTS_START:
    "user-merchant/APPEND_SELECT_USER_MERCHANTS_START",
  FETCH_USER_MERCHANT_START: "user-merchant/FETCH_USER_MERCHANT_START",
  CREATE_USER_MERCHANT_START: "user-merchant/CREATE_USER_MERCHANT_START",
  UPDATE_USER_MERCHANT_START: "user-merchant/UPDATE_USER_MERCHANT_START",
  DELETE_USER_MERCHANT_START: "user-merchant/DELETE_USER_MERCHANT_START",
};

export default USER_MERCHANT_ACTION_TYPES;
