import apiService from "./api";

export const getCustomers = async (parameters) =>
  (await apiService.get("/v2/users/customers", parameters)).data;
export const getCustomer = async (customerId) =>
  (await apiService.get(`/v2/users/customers/${customerId}`)).data;
export const createCustomer = async (request) =>
  (await apiService.post("/v2/users/customers", request)).data;
export const updateCustomer = async (customerId, request) =>
  (await apiService.post(`/v2/users/customers/${customerId}`, request)).data;
