import APPLICATION_ACTION_TYPES from "./application.type";

export const APPLICATION_INITIAL_STATE = {
  applications: [],
  applicationExtensions: {},
  application: null,
  createApplication: null,
  updateApplication: null,

  isApplicationsHasMore: true,
  isApplicationExtensionsHasMore: true,

  fetchApplicationsSearch: null,
  fetchApplicationsKeyBy: null,
  fetchApplicationsPage: 1,
  fetchApplicationsPerPage: null,
  fetchApplicationsLoading: false,
  fetchApplicationsSuccess: null,
  fetchApplicationsFailed: null,

  fetchApplicationExtensionsSearch: null,
  fetchApplicationExtensionsKeyBy: null,
  fetchApplicationExtensionsPage: 1,
  fetchApplicationExtensionsPerPage: null,
  fetchApplicationExtensionsLoading: false,
  fetchApplicationExtensionsSuccess: null,
  fetchApplicationExtensionsFailed: null,

  fetchApplicationLoading: false,
  fetchApplicationSuccess: null,
  fetchApplicationFailed: null,

  createApplicationLoading: false,
  createApplicationSuccess: null,
  createApplicationFailed: null,

  updateApplicationLoading: false,
  updateApplicationSuccess: null,
  updateApplicationFailed: null,

  deleteApplicationLoading: false,
  deleteApplicationSuccess: null,
  deleteApplicationFailed: null,

  isFetchApplicationsHitted: false,
  isFetchApplicationExtensionsHitted: false,
  isFetchApplicationHitted: false,
  isCreateApplicationHitted: false,
  isUpdateApplicationHitted: false,
  isDeleteApplicationHitted: false,
};

export const applicationReducer = (
  state = APPLICATION_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case APPLICATION_ACTION_TYPES.SET_APPLICATIONS:
      return { ...state, applications: payload };
    case APPLICATION_ACTION_TYPES.SET_APPLICATION_EXTENSIONS:
      return { ...state, applicationExtensions: payload };
    case APPLICATION_ACTION_TYPES.SET_APPLICATION:
      return { ...state, application: payload };
    case APPLICATION_ACTION_TYPES.SET_CREATE_APPLICATION:
      return { ...state, createApplication: payload };
    case APPLICATION_ACTION_TYPES.SET_UPDATE_APPLICATION:
      return { ...state, updateApplication: payload };

    case APPLICATION_ACTION_TYPES.SET_IS_APPLICATIONS_HAS_MORE:
      return { ...state, isApplicationsHasMore: payload };
    case APPLICATION_ACTION_TYPES.SET_IS_APPLICATION_EXTENSIONS_HAS_MORE:
      return { ...state, isApplicationExtensionsHasMore: payload };

    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_SEARCH:
      return { ...state, fetchApplicationsSearch: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_KEY_BY:
      return { ...state, fetchApplicationsKeyBy: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_PAGE:
      return { ...state, fetchApplicationsPage: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_PER_PAGE:
      return { ...state, fetchApplicationsPerPage: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_LOADING:
      return { ...state, fetchApplicationsLoading: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_SUCCESS:
      return { ...state, fetchApplicationsSuccess: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_FAILED:
      return { ...state, fetchApplicationsFailed: payload };

    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_SEARCH:
      return { ...state, fetchApplicationExtensionsSearch: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_KEY_BY:
      return { ...state, fetchApplicationExtensionsKeyBy: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_PAGE:
      return { ...state, fetchApplicationExtensionsPage: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_PER_PAGE:
      return { ...state, fetchApplicationExtensionsPerPage: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_LOADING:
      return { ...state, fetchApplicationExtensionsLoading: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_SUCCESS:
      return { ...state, fetchApplicationExtensionsSuccess: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_FAILED:
      return { ...state, fetchApplicationExtensionsFailed: payload };

    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_LOADING:
      return { ...state, fetchApplicationLoading: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_SUCCESS:
      return { ...state, fetchApplicationSuccess: payload };
    case APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_FAILED:
      return { ...state, fetchApplicationFailed: payload };

    case APPLICATION_ACTION_TYPES.SET_CREATE_APPLICATION_LOADING:
      return { ...state, createApplicationLoading: payload };
    case APPLICATION_ACTION_TYPES.SET_CREATE_APPLICATION_SUCCESS:
      return { ...state, createApplicationSuccess: payload };
    case APPLICATION_ACTION_TYPES.SET_CREATE_APPLICATION_FAILED:
      return { ...state, createApplicationFailed: payload };

    case APPLICATION_ACTION_TYPES.SET_UPDATE_APPLICATION_LOADING:
      return { ...state, updateApplicationLoading: payload };
    case APPLICATION_ACTION_TYPES.SET_UPDATE_APPLICATION_SUCCESS:
      return { ...state, updateApplicationSuccess: payload };
    case APPLICATION_ACTION_TYPES.SET_UPDATE_APPLICATION_FAILED:
      return { ...state, updateApplicationFailed: payload };

    case APPLICATION_ACTION_TYPES.SET_DELETE_APPLICATION_LOADING:
      return { ...state, deleteApplicationLoading: payload };
    case APPLICATION_ACTION_TYPES.SET_DELETE_APPLICATION_SUCCESS:
      return { ...state, deleteApplicationSuccess: payload };
    case APPLICATION_ACTION_TYPES.SET_DELETE_APPLICATION_FAILED:
      return { ...state, deleteApplicationFailed: payload };

    case APPLICATION_ACTION_TYPES.APPEND_APPLICATIONS:
      return { ...state, applications: [...state.applications, ...payload] };
    case APPLICATION_ACTION_TYPES.APPEND_APPLICATION_EXTENSIONS:
      return {
        ...state,
        applicationExtensions: { ...state.applicationExtensions, ...payload },
      };

    case APPLICATION_ACTION_TYPES.SET_IS_FETCH_APPLICATIONS_HITTED:
      return { ...state, isFetchApplicationsHitted: payload };
    case APPLICATION_ACTION_TYPES.SET_IS_FETCH_APPLICATION_EXTENSIONS_HITTED:
      return { ...state, isFetchApplicationExtensionsHitted: payload };
    case APPLICATION_ACTION_TYPES.SET_IS_FETCH_APPLICATION_HITTED:
      return { ...state, isFetchApplicationHitted: payload };
    case APPLICATION_ACTION_TYPES.SET_IS_CREATE_APPLICATION_HITTED:
      return { ...state, isCreateApplicationHitted: payload };
    case APPLICATION_ACTION_TYPES.SET_IS_UPDATE_APPLICATION_HITTED:
      return { ...state, isUpdateApplicationHitted: payload };
    case APPLICATION_ACTION_TYPES.SET_IS_DELETE_APPLICATION_HITTED:
      return { ...state, isDeleteApplicationHitted: payload };

    case APPLICATION_ACTION_TYPES.RESET_APP_REDUCER:
      return APPLICATION_INITIAL_STATE;
    default:
      return state;
  }
};
