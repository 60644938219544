import INGREDIENT_HISTORY_ACTION_TYPES from "./ingredient-history.type";
import { createAction } from "../../utils/store.utils";

export const setIngredientHistories = (ingredientHistories) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.SET_INGREDIENT_HISTORIES, ingredientHistories);

export const setIngredientHistory = (ingredientHistory) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.SET_INGREDIENT_HISTORY, ingredientHistory);

export const setIsIngredientHistoriesHasMore = (isIngredientHistoriesHasMore) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.SET_IS_INGREDIENT_HISTORIES_HAS_MORE, isIngredientHistoriesHasMore);

export const setFetchIngredientHistoriesSearch = (fetchIngredientHistoriesSearch) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_SEARCH, fetchIngredientHistoriesSearch);

export const setFetchIngredientHistoriesSort = (fetchIngredientHistoriesSort) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_SORT, fetchIngredientHistoriesSort);

export const setFetchIngredientHistoriesPage = (fetchIngredientHistoriesPage) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_PAGE, fetchIngredientHistoriesPage);

export const setFetchIngredientHistoriesPerPage = (fetchIngredientHistoriesPerPage) =>
  createAction(
    INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_PER_PAGE,
    fetchIngredientHistoriesPerPage
  );

export const setFetchIngredientHistoriesLoading = (fetchIngredientHistoriesLoading) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_LOADING, fetchIngredientHistoriesLoading);

export const setFetchIngredientHistoriesSuccess = (fetchIngredientHistoriesSuccess) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_SUCCESS, fetchIngredientHistoriesSuccess);

export const setFetchIngredientHistoriesFailed = (fetchIngredientHistoriesFailed) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_FAILED, fetchIngredientHistoriesFailed);

export const setFetchIngredientHistoryLoading = (fetchIngredientHistoryLoading) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORY_LOADING, fetchIngredientHistoryLoading);

export const setFetchIngredientHistorySuccess = (fetchIngredientHistorySuccess) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORY_SUCCESS, fetchIngredientHistorySuccess);

export const setFetchIngredientHistoryFailed = (fetchIngredientHistoryFailed) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORY_FAILED, fetchIngredientHistoryFailed);

export const appendIngredientHistories = (ingredientHistories) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.APPEND_INGREDIENT_HISTORIES, ingredientHistories);

export const fetchIngredientHistoriesStart = (ingredientId) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.FETCH_INGREDIENT_HISTORIES_START, ingredientId);

export const fetchIngredientHistoryStart = (ingredientId, ingredientHistoryId) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.FETCH_INGREDIENT_HISTORY_START, { ingredientId, ingredientHistoryId });

export const setIsFetchIngredientHistoriesHitted = (isFetchIngredientHistoriesHitted) =>
  createAction(
    INGREDIENT_HISTORY_ACTION_TYPES.SET_IS_FETCH_INGREDIENT_HISTORIES_HITTED,
    isFetchIngredientHistoriesHitted
  );

export const setIsFetchIngredientHistoryHitted = (isFetchIngredientHistoryHitted) =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.SET_IS_FETCH_INGREDIENT_HISTORY_HITTED, isFetchIngredientHistoryHitted);

export const resetIngredientHistoryReducer = () =>
  createAction(INGREDIENT_HISTORY_ACTION_TYPES.RESET_INGREDIENT_HISTORY_REDUCER);
