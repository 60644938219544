import { takeLatest, put, all, call, select } from "redux-saga/effects";

import MSEGAT_ACTION_TYPES from "./msegat.type";

import {
  appendMsegats,
  setCreateOrUpdateMsegatFailed,
  setCreateOrUpdateMsegatLoading,
  setCreateOrUpdateMsegatSuccess,
  setFetchMsegatFailed,
  setFetchMsegatLoading,
  setFetchMsegatsFailed,
  setFetchMsegatsLoading,
  setFetchMsegatsSuccess,
  setFetchMsegatSuccess,
  setIsCreateOrUpdateMsegatHitted,
  setIsFetchMsegatHitted,
  setIsFetchMsegatsHitted,
  setIsMsegatsHasMore,
  setMsegat,
  setMsegats,
} from "./msegat.action";
import {
  getFetchMsegatsFilterIsActive,
  getFetchMsegatsIncludes,
  getFetchMsegatsPage,
  getFetchMsegatsPerPage,
  getFetchMsegatsSearch,
} from "./msegat.selector";

import { getMsegats, getMsegat, createOrUpdateMsegat } from "../../api/msegat.api";

export function* _getMsegats() {
  try {
    yield put(setFetchMsegatsLoading(true));

    const search = yield select(getFetchMsegatsSearch);
    const page = yield select(getFetchMsegatsPage);
    const per_page = yield select(getFetchMsegatsPerPage);
    const includes = yield select(getFetchMsegatsIncludes);
    const is_active = yield select(getFetchMsegatsFilterIsActive);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: { is_active },
    };

    const {
      meta: { message },
      data: { data: msegats },
    } = yield call(getMsegats, parameters);

    yield put(setIsFetchMsegatsHitted(true));
    yield put(setIsMsegatsHasMore(msegats.length > 0));

    if (page > 1) {
      yield put(appendMsegats(msegats));
    } else {
      yield put(setMsegats(msegats));
    }

    yield put(setFetchMsegatsSuccess(message));
    yield put(setFetchMsegatsLoading(false));
  } catch (error) {
    yield put(setFetchMsegatsFailed(error));
    yield put(setFetchMsegatsLoading(false));
  }
}

export function* _getMsegat({ payload: marketId }) {
  try {
    yield put(setFetchMsegatLoading(true));

    const {
      meta: { message },
      data: msegat,
    } = yield call(getMsegat, marketId);

    yield put(setIsFetchMsegatHitted(true));
    yield put(setMsegat(msegat));

    yield put(setFetchMsegatSuccess(message));
    yield put(setFetchMsegatLoading(false));
  } catch (error) {
    yield put(setFetchMsegatFailed(error));
    yield put(setFetchMsegatLoading(false));
  }
}

export function* _createOrUpdateMsegat({ payload: request }) {
  try {
    yield put(setCreateOrUpdateMsegatLoading(true));

    const {
      meta: { message },
      data: msegat,
    } = yield call(createOrUpdateMsegat, request);

    yield put(setIsCreateOrUpdateMsegatHitted(true));
    yield put(setMsegat(msegat));

    yield put(setCreateOrUpdateMsegatSuccess(message));
    yield put(setCreateOrUpdateMsegatLoading(false));
  } catch (error) {
    yield put(setCreateOrUpdateMsegatFailed(error));
    yield put(setCreateOrUpdateMsegatLoading(false));
  }
}

export function* onFetchMsegatsStart() {
  yield takeLatest(MSEGAT_ACTION_TYPES.FETCH_MSEGATS_START, _getMsegats);
}

export function* onFetchMsegatStart() {
  yield takeLatest(MSEGAT_ACTION_TYPES.FETCH_MSEGAT_START, _getMsegat);
}

export function* onCreateOrUpdateMsegatStart() {
  yield takeLatest(MSEGAT_ACTION_TYPES.CREATE_OR_UPDATE_MSEGAT_START, _createOrUpdateMsegat);
}

export function* msegatSaga() {
  yield all([call(onFetchMsegatsStart), call(onFetchMsegatStart), call(onCreateOrUpdateMsegatStart)]);
}
