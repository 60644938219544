import { takeLatest, put, all, call, select } from "redux-saga/effects";

import USER_ADMIN_ACTION_TYPES from "./user-admin.type";

import {
  appendUserAdmins,
  setUserAdmin,
  setUserAdmins,
  setCreateUserAdminFailed,
  setCreateUserAdminLoading,
  setCreateUserAdminSuccess,
  setDeleteUserAdminFailed,
  setDeleteUserAdminLoading,
  setDeleteUserAdminSuccess,
  setFetchUserAdminFailed,
  setFetchUserAdminLoading,
  setFetchUserAdminSuccess,
  setFetchUserAdminsFailed,
  setFetchUserAdminsLoading,
  setFetchUserAdminsPage,
  setFetchUserAdminsSuccess,
  setIsUserAdminsHasMore,
  setIsCreateUserAdminHitted,
  setIsDeleteUserAdminHitted,
  setIsFetchUserAdminHitted,
  setIsFetchUserAdminsHitted,
  setIsUpdateUserAdminHitted,
  setUpdateUserAdminFailed,
  setUpdateUserAdminLoading,
  setUpdateUserAdminSuccess,
} from "./user-admin.action";
import {
  getFetchUserAdminsFilterMarketId,
  getFetchUserAdminsFilterPermissionId,
  getFetchUserAdminsIncludes,
  getFetchUserAdminsKeyBy,
  getFetchUserAdminsPage,
  getFetchUserAdminsPerPage,
  getFetchUserAdminsSearch,
  getFetchUserAdminsSort,
  getIsFetchUserAdminsHitted,
} from "./user-admin.selector";

import {
  createUserAdmin,
  deleteUserAdmin,
  getUserAdmin,
  getUserAdmins,
  updateUserAdmin,
} from "../../api/user-admin.api";

export function* _getUserAdmins() {
  try {
    yield put(setFetchUserAdminsLoading(true));

    const search = yield select(getFetchUserAdminsSearch);
    const sort = yield select(getFetchUserAdminsSort);
    const key_by = yield select(getFetchUserAdminsKeyBy);
    const page = yield select(getFetchUserAdminsPage);
    const per_page = yield select(getFetchUserAdminsPerPage);
    const includes = yield select(getFetchUserAdminsIncludes);
    const permission_id = yield select(getFetchUserAdminsFilterPermissionId);
    const market_id = yield select(getFetchUserAdminsFilterMarketId);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { permission_id, market_id },
    };

    const {
      meta: { message },
      data: { data: userAdmins },
    } = yield call(getUserAdmins, parameters);

    yield put(setIsFetchUserAdminsHitted(true));
    yield put(setIsUserAdminsHasMore(userAdmins.length > 0));

    if (page > 1) {
      yield put(appendUserAdmins(userAdmins));
    } else {
      yield put(setUserAdmins(userAdmins));
    }

    yield put(setFetchUserAdminsSuccess(message));
    yield put(setFetchUserAdminsLoading(false));
  } catch (error) {
    yield put(setFetchUserAdminsFailed(error));
    yield put(setFetchUserAdminsLoading(false));
  }
}
export function* _getUserAdmin({ payload: adminId }) {
  try {
    yield put(setFetchUserAdminLoading(true));

    const {
      meta: { message },
      data: userAdmin,
    } = yield call(getUserAdmin, adminId);

    yield put(setIsFetchUserAdminHitted(true));
    yield put(setUserAdmin(userAdmin));

    yield put(setFetchUserAdminSuccess(message));
    yield put(setFetchUserAdminLoading(false));
  } catch (error) {
    yield put(setFetchUserAdminFailed(error));
    yield put(setFetchUserAdminLoading(false));
  }
}
export function* _createUserAdmin({ payload: request }) {
  try {
    yield put(setCreateUserAdminLoading(true));

    const {
      meta: { message },
    } = yield call(createUserAdmin, request);

    yield put(setIsCreateUserAdminHitted(true));

    const isFetchUserAdminsHitted = yield select(getIsFetchUserAdminsHitted);

    if (isFetchUserAdminsHitted) {
      yield put(setFetchUserAdminsPage(1));
      yield call(_getUserAdmins);
    }

    yield put(setCreateUserAdminSuccess(message));
    yield put(setCreateUserAdminLoading(false));
  } catch (error) {
    yield put(setCreateUserAdminFailed(error));
    yield put(setCreateUserAdminLoading(false));
  }
}
export function* _updateUserAdmin({ payload: { adminId, request } }) {
  try {
    yield put(setUpdateUserAdminLoading(true));

    const {
      meta: { message },
    } = yield call(updateUserAdmin, adminId, request);

    yield put(setIsUpdateUserAdminHitted(true));

    const isFetchUserAdminsHitted = yield select(getIsFetchUserAdminsHitted);

    if (isFetchUserAdminsHitted) {
      yield put(setFetchUserAdminsPage(1));
      yield call(_getUserAdmins);
    }

    yield put(setUpdateUserAdminSuccess(message));
    yield put(setUpdateUserAdminLoading(false));
  } catch (error) {
    yield put(setUpdateUserAdminFailed(error));
    yield put(setUpdateUserAdminLoading(false));
  }
}
export function* _deleteUserAdmin({ payload: adminId }) {
  try {
    yield put(setDeleteUserAdminLoading(true));

    const {
      meta: { message },
    } = yield call(deleteUserAdmin, adminId);

    yield put(setIsDeleteUserAdminHitted(true));

    const isFetchUserAdminsHitted = yield select(getIsFetchUserAdminsHitted);

    if (isFetchUserAdminsHitted) {
      yield put(setFetchUserAdminsPage(1));
      yield call(_getUserAdmins);
    }

    yield put(setDeleteUserAdminSuccess(message));
    yield put(setDeleteUserAdminLoading(false));
  } catch (error) {
    yield put(setDeleteUserAdminFailed(error));
    yield put(setDeleteUserAdminLoading(false));
  }
}

export function* onFetchUserAdminsStart() {
  yield takeLatest(
    USER_ADMIN_ACTION_TYPES.FETCH_USER_ADMINS_START,
    _getUserAdmins
  );
}
export function* onFetchUserAdminStart() {
  yield takeLatest(
    USER_ADMIN_ACTION_TYPES.FETCH_USER_ADMIN_START,
    _getUserAdmin
  );
}
export function* onCreateUserAdminStart() {
  yield takeLatest(
    USER_ADMIN_ACTION_TYPES.CREATE_USER_ADMIN_START,
    _createUserAdmin
  );
}
export function* onUpdateUserAdminStart() {
  yield takeLatest(
    USER_ADMIN_ACTION_TYPES.UPDATE_USER_ADMIN_START,
    _updateUserAdmin
  );
}
export function* onDeleteUserAdminStart() {
  yield takeLatest(
    USER_ADMIN_ACTION_TYPES.DELETE_USER_ADMIN_START,
    _deleteUserAdmin
  );
}

export function* userAdminSaga() {
  yield all([
    call(onFetchUserAdminsStart),
    call(onFetchUserAdminStart),
    call(onCreateUserAdminStart),
    call(onUpdateUserAdminStart),
    call(onDeleteUserAdminStart),
  ]);
}
