import apiService from "./api";

export const getIngredients = async (parameters) =>
  (await apiService.get("/v2/ingredients", parameters)).data;
export const getIngredient = async (ingredientId) =>
  (await apiService.get(`/v2/ingredients/${ingredientId}`)).data;
export const createIngredient = async (request) =>
  (await apiService.post("/v2/ingredients", request)).data;
export const updateIngredient = async (ingredientId, request) =>
  (await apiService.post(`/v2/ingredients/${ingredientId}`, request)).data;
export const deleteIngredient = async (ingredientId) =>
  (await apiService.delete(`/v2/ingredients/${ingredientId}`)).data;

export const storeStockIngredient = async (ingredientId, request) =>
  (await apiService.post(`/v2/ingredients/${ingredientId}/store`, request))
    .data;
export const reduceStockIngredient = async (ingredientId, request) =>
  (await apiService.post(`/v2/ingredients/${ingredientId}/reduce`, request))
    .data;
