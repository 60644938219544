import styled from "styled-components";

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100vw;
  height: 100vh;
  background-color: #f2f2f4;

  z-index: 1000;
`;

export const LoadingLogo = styled.div`
  margin-bottom: 1.5rem;
`;
