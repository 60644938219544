import { useTranslation } from "react-i18next";

import { LANGUAGES } from "../../constants/common.constant";

import { StatusBadgeContainer } from "./status-badge.style";

export const STATUS_BADGE_TYPES = {
  LIGHT: "LIGHT",
  PRIMARY: "PRIMARY",
  SUCCESS: "SUCCESS",
  DANGER: "DANGER",
  WARNING: "WARNING",
};

export const STATUS_BADGE_SIZES = {
  SMALL: "SMALL",
  NORMAL: "NORMAL",
};

const StatusBadge = ({
  type = STATUS_BADGE_TYPES.LIGHT,
  size = STATUS_BADGE_SIZES.NORMAL,
  isHasIcon = false,
  isTextColor = false,
  children,
  ...otherProps
}) => {
  const { i18n } = useTranslation();

  return (
    <StatusBadgeContainer
      isRtl={i18n.language === LANGUAGES.AR}
      type={type}
      size={size}
      isHasIcon={isHasIcon}
      isTextColor={isTextColor}
      {...otherProps}
    >
      {children}
    </StatusBadgeContainer>
  );
};

export default StatusBadge;
