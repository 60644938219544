import { createAction } from "../../utils/store.utils";

import SELECT_INGREDIENT_ACTION_TYPES from "./select-ingredient.type";

export const setIngredients = (ingredients) =>
  createAction(SELECT_INGREDIENT_ACTION_TYPES.SET_INGREDIENTS, ingredients);
export const setSearchIngredients = (searchIngredients) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_SEARCH_INGREDIENTS,
    searchIngredients
  );

export const appendIngredients = (ingredients) =>
  createAction(SELECT_INGREDIENT_ACTION_TYPES.APPEND_INGREDIENTS, ingredients);
export const appendSearchIngredients = (searchIngredients) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.APPEND_SEARCH_INGREDIENTS,
    searchIngredients
  );

export const setIsIngredientsHasMore = (isIngredientsHasMore) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_IS_INGREDIENTS_HAS_MORE,
    isIngredientsHasMore
  );
export const setIsSearchIngredientsHasMore = (isSearchIngredientsHasMore) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_IS_SEARCH_INGREDIENTS_HAS_MORE,
    isSearchIngredientsHasMore
  );

export const setFetchIngredientsParams = (fetchIngredientsParams) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_PARAMS,
    fetchIngredientsParams
  );
export const setFetchIngredientsLoading = (fetchIngredientsLoading) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_LOADING,
    fetchIngredientsLoading
  );
export const setFetchIngredientsSuccess = (fetchIngredientsSuccess) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_SUCCESS,
    fetchIngredientsSuccess
  );
export const setFetchIngredientsFailed = (fetchIngredientsFailed) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_FAILED,
    fetchIngredientsFailed
  );

export const setSearchIngredientsParams = (searchIngredientsParams) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_SEARCH_INGREDIENTS_PARAMS,
    searchIngredientsParams
  );
export const setSearchIngredientsLoading = (searchIngredientsLoading) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_SEARCH_INGREDIENTS_LOADING,
    searchIngredientsLoading
  );
export const setSearchIngredientsSuccess = (searchIngredientsSuccess) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_SEARCH_INGREDIENTS_SUCCESS,
    searchIngredientsSuccess
  );
export const setSearchIngredientsFailed = (searchIngredientsFailed) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_SEARCH_INGREDIENTS_FAILED,
    searchIngredientsFailed
  );

export const setAppendIngredientsParams = (appendIngredientsParams) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_APPEND_INGREDIENTS_PARAMS,
    appendIngredientsParams
  );
export const setAppendIngredientsLoading = (appendIngredientsLoading) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_APPEND_INGREDIENTS_LOADING,
    appendIngredientsLoading
  );
export const setAppendIngredientsSuccess = (appendIngredientsSuccess) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_APPEND_INGREDIENTS_SUCCESS,
    appendIngredientsSuccess
  );
export const setAppendIngredientsFailed = (appendIngredientsFailed) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_APPEND_INGREDIENTS_FAILED,
    appendIngredientsFailed
  );

export const setIsFetchIngredientsHitted = (isFetchIngredientsHitted) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_IS_FETCH_INGREDIENTS_HITTED,
    isFetchIngredientsHitted
  );
export const setIsSearchIngredientsHitted = (isSearchIngredientsHitted) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_IS_SEARCH_INGREDIENTS_HITTED,
    isSearchIngredientsHitted
  );
export const setIsAppendIngredientsHitted = (isAppendIngredientsHitted) =>
  createAction(
    SELECT_INGREDIENT_ACTION_TYPES.SET_IS_APPEND_INGREDIENTS_HITTED,
    isAppendIngredientsHitted
  );

export const fetchIngredientsStart = (params) =>
  createAction(SELECT_INGREDIENT_ACTION_TYPES.FETCH_INGREDIENTS_START, params);
export const searchIngredientsStart = (params) =>
  createAction(SELECT_INGREDIENT_ACTION_TYPES.SEARCH_INGREDIENTS_START, params);
export const appendIngredientsStart = (params) =>
  createAction(SELECT_INGREDIENT_ACTION_TYPES.APPEND_INGREDIENTS_START, params);

export const resetIngredientReducer = () =>
  createAction(SELECT_INGREDIENT_ACTION_TYPES.RESET_SELECT_INGREDIENT_REDUCER);
