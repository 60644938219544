import SECTOR_ACTION_TYPES from "./sector.type";
import { createAction } from "../../utils/store.utils";

export const setSectors = (sectors) =>
  createAction(SECTOR_ACTION_TYPES.SET_SECTORS, sectors);
export const setSelectSectors = (selectSectors) =>
  createAction(SECTOR_ACTION_TYPES.SET_SELECT_SECTORS, selectSectors);
export const setSector = (sector) =>
  createAction(SECTOR_ACTION_TYPES.SET_SECTOR, sector);

export const setIsSectorsHasMore = (isSectorsHasMore) =>
  createAction(SECTOR_ACTION_TYPES.SET_IS_SECTORS_HAS_MORE, isSectorsHasMore);
export const setIsSelectSectorsHasMore = (isSelectSectorsHasMore) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_IS_SELECT_SECTORS_HAS_MORE,
    isSelectSectorsHasMore
  );

export const setFetchSectorsSearch = (fetchSectorsSearch) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_SEARCH,
    fetchSectorsSearch
  );
export const setFetchSectorsSort = (fetchSectorsSort) =>
  createAction(SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_SORT, fetchSectorsSort);
export const setFetchSectorsKeyBy = (fetchSectorsKeyBy) =>
  createAction(SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_KEY_BY, fetchSectorsKeyBy);
export const setFetchSectorsPage = (fetchSectorsPage) =>
  createAction(SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_PAGE, fetchSectorsPage);
export const setFetchSectorsPerPage = (fetchSectorsPerPage) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_PER_PAGE,
    fetchSectorsPerPage
  );
export const setFetchSectorsIncludes = (fetchSectorsIncludes) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_INCLUDES,
    fetchSectorsIncludes
  );
export const setFetchSectorsLoading = (fetchSectorsLoading) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_LOADING,
    fetchSectorsLoading
  );
export const setFetchSectorsSuccess = (fetchSectorsSuccess) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_SUCCESS,
    fetchSectorsSuccess
  );
export const setFetchSectorsFailed = (fetchSectorsFailed) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_FAILED,
    fetchSectorsFailed
  );

export const setFetchSelectSectorsSearch = (fetchSelectSectorsSearch) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_SEARCH,
    fetchSelectSectorsSearch
  );
export const setFetchSelectSectorsSort = (fetchSelectSectorsSort) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_SORT,
    fetchSelectSectorsSort
  );
export const setFetchSelectSectorsKeyBy = (fetchSelectSectorsKeyBy) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_KEY_BY,
    fetchSelectSectorsKeyBy
  );
export const setFetchSelectSectorsPage = (fetchSelectSectorsPage) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_PAGE,
    fetchSelectSectorsPage
  );
export const setFetchSelectSectorsPerPage = (fetchSelectSectorsPerPage) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_PER_PAGE,
    fetchSelectSectorsPerPage
  );
export const setFetchSelectSectorsIncludes = (fetchSelectSectorsIncludes) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_INCLUDES,
    fetchSelectSectorsIncludes
  );
export const setFetchSelectSectorsLoading = (fetchSelectSectorsLoading) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_LOADING,
    fetchSelectSectorsLoading
  );
export const setFetchSelectSectorsSuccess = (fetchSelectSectorsSuccess) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_SUCCESS,
    fetchSelectSectorsSuccess
  );
export const setFetchSelectSectorsFailed = (fetchSelectSectorsFailed) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_FAILED,
    fetchSelectSectorsFailed
  );

export const setFetchSectorLoading = (fetchSectorLoading) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SECTOR_LOADING,
    fetchSectorLoading
  );
export const setFetchSectorSuccess = (fetchSectorSuccess) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_FETCH_SECTOR_SUCCESS,
    fetchSectorSuccess
  );
export const setFetchSectorFailed = (fetchSectorFailed) =>
  createAction(SECTOR_ACTION_TYPES.SET_FETCH_SECTOR_FAILED, fetchSectorFailed);

export const setCreateSectorLoading = (createSectorLoading) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_CREATE_SECTOR_LOADING,
    createSectorLoading
  );
export const setCreateSectorSuccess = (createSectorSuccess) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_CREATE_SECTOR_SUCCESS,
    createSectorSuccess
  );
export const setCreateSectorFailed = (createSectorFailed) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_CREATE_SECTOR_FAILED,
    createSectorFailed
  );

export const setUpdateSectorLoading = (updateSectorLoading) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_UPDATE_SECTOR_LOADING,
    updateSectorLoading
  );
export const setUpdateSectorSuccess = (updateSectorSuccess) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_UPDATE_SECTOR_SUCCESS,
    updateSectorSuccess
  );
export const setUpdateSectorFailed = (updateSectorFailed) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_UPDATE_SECTOR_FAILED,
    updateSectorFailed
  );

export const setDeleteSectorLoading = (deleteSectorLoading) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_DELETE_SECTOR_LOADING,
    deleteSectorLoading
  );
export const setDeleteSectorSuccess = (deleteSectorSuccess) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_DELETE_SECTOR_SUCCESS,
    deleteSectorSuccess
  );
export const setDeleteSectorFailed = (deleteSectorFailed) =>
  createAction(
    SECTOR_ACTION_TYPES.SET_DELETE_SECTOR_FAILED,
    deleteSectorFailed
  );

export const appendSectors = (sectors) =>
  createAction(SECTOR_ACTION_TYPES.APPEND_SECTORS, sectors);
export const appendSelectSectors = (selectSectors) =>
  createAction(SECTOR_ACTION_TYPES.APPEND_SELECT_SECTORS, selectSectors);

export const fetchSectorsStart = () =>
  createAction(SECTOR_ACTION_TYPES.FETCH_SECTORS_START);
export const fetchSelectSectorsStart = () =>
  createAction(SECTOR_ACTION_TYPES.FETCH_SELECT_SECTORS_START);
export const fetchSectorStart = (sectorId) =>
  createAction(SECTOR_ACTION_TYPES.FETCH_SECTOR_START, sectorId);
export const createSectorStart = (request) =>
  createAction(SECTOR_ACTION_TYPES.CREATE_SECTOR_START, request);
export const updateSectorStart = (sectorId, request) =>
  createAction(SECTOR_ACTION_TYPES.UPDATE_SECTOR_START, { sectorId, request });
export const deleteSectorStart = (sectorId) =>
  createAction(SECTOR_ACTION_TYPES.DELETE_SECTOR_START, sectorId);

export const setIsFetchSectorsHitted = (isFetchSectorsHitted) =>
  createAction(
    SECTOR_ACTION_TYPES.IS_FETCH_SECTORS_HITTED,
    isFetchSectorsHitted
  );
export const setIsFetchSelectSectorsHitted = (isFetchSelectSectorsHitted) =>
  createAction(
    SECTOR_ACTION_TYPES.IS_FETCH_SELECT_SECTORS_HITTED,
    isFetchSelectSectorsHitted
  );
export const setIsFetchSectorHitted = (isFetchSectorHitted) =>
  createAction(SECTOR_ACTION_TYPES.IS_FETCH_SECTOR_HITTED, isFetchSectorHitted);
export const setIsCreateSectorHitted = (isCreateSectorHitted) =>
  createAction(
    SECTOR_ACTION_TYPES.IS_CREATE_SECTOR_HITTED,
    isCreateSectorHitted
  );
export const setIsUpdateSectorHitted = (isUpdateSectorHitted) =>
  createAction(
    SECTOR_ACTION_TYPES.IS_UPDATE_SECTOR_HITTED,
    isUpdateSectorHitted
  );
export const setIsDeleteSectorHitted = (isDeleteSectorHitted) =>
  createAction(
    SECTOR_ACTION_TYPES.IS_DELETE_SECTOR_HITTED,
    isDeleteSectorHitted
  );

export const resetSectorReducer = () =>
  createAction(SECTOR_ACTION_TYPES.RESET_SECTOR_REDUCER);
