const APPLICATION_ACTION_TYPES = {
  RESET_APP_REDUCER: "application/RESET_APP_REDUCER",

  SET_APPLICATIONS: "application/SET_APPLICATIONS",
  SET_APPLICATION_EXTENSIONS: "application/SET_APPLICATION_EXTENSIONS",
  SET_APPLICATION: "application/SET_APPLICATION",
  SET_CREATE_APPLICATION: "application/SET_CREATE_APPLICATION",
  SET_UPDATE_APPLICATION: "application/SET_UPDATE_APPLICATION",

  SET_IS_APPLICATIONS_HAS_MORE: "application/SET_IS_APPLICATIONS_HAS_MORE",
  SET_IS_APPLICATION_EXTENSIONS_HAS_MORE:
    "application/SET_IS_APPLICATION_EXTENSIONS_HAS_MORE",

  SET_FETCH_APPLICATIONS_SEARCH: "application/SET_FETCH_APPLICATIONS_SEARCH",
  SET_FETCH_APPLICATIONS_KEY_BY: "application/SET_FETCH_APPLICATIONS_KEY_BY",
  SET_FETCH_APPLICATIONS_PAGE: "application/SET_FETCH_APPLICATIONS_PAGE",
  SET_FETCH_APPLICATIONS_PER_PAGE:
    "application/SET_FETCH_APPLICATIONS_PER_PAGE",
  SET_FETCH_APPLICATIONS_LOADING: "application/SET_FETCH_APPLICATIONS_LOADING",
  SET_FETCH_APPLICATIONS_SUCCESS: "application/SET_FETCH_APPLICATIONS_SUCCESS",
  SET_FETCH_APPLICATIONS_FAILED: "application/SET_FETCH_APPLICATIONS_FAILED",

  SET_FETCH_APPLICATION_EXTENSIONS_SEARCH:
    "application/SET_FETCH_APPLICATION_EXTENSIONS_SEARCH",
  SET_FETCH_APPLICATION_EXTENSIONS_KEY_BY:
    "application/SET_FETCH_APPLICATION_EXTENSIONS_KEY_BY",
  SET_FETCH_APPLICATION_EXTENSIONS_PAGE:
    "application/SET_FETCH_APPLICATION_EXTENSIONS_PAGE",
  SET_FETCH_APPLICATION_EXTENSIONS_PER_PAGE:
    "application/SET_FETCH_APPLICATION_EXTENSIONS_PER_PAGE",
  SET_FETCH_APPLICATION_EXTENSIONS_LOADING:
    "application/SET_FETCH_APPLICATION_EXTENSIONS_LOADING",
  SET_FETCH_APPLICATION_EXTENSIONS_SUCCESS:
    "application/SET_FETCH_APPLICATION_EXTENSIONS_SUCCESS",
  SET_FETCH_APPLICATION_EXTENSIONS_FAILED:
    "application/SET_FETCH_APPLICATION_EXTENSIONS_FAILED",

  SET_FETCH_APPLICATION_LOADING: "application/SET_FETCH_APPLICATION_LOADING",
  SET_FETCH_APPLICATION_SUCCESS: "application/SET_FETCH_APPLICATION_SUCCESS",
  SET_FETCH_APPLICATION_FAILED: "application/SET_FETCH_APPLICATION_FAILED",

  SET_CREATE_APPLICATION_LOADING: "application/SET_CREATE_APPLICATION_LOADING",
  SET_CREATE_APPLICATION_SUCCESS: "application/SET_CREATE_APPLICATION_SUCCESS",
  SET_CREATE_APPLICATION_FAILED: "application/SET_CREATE_APPLICATION_FAILED",

  SET_UPDATE_APPLICATION_LOADING: "application/SET_UPDATE_APPLICATION_LOADING",
  SET_UPDATE_APPLICATION_SUCCESS: "application/SET_UPDATE_APPLICATION_SUCCESS",
  SET_UPDATE_APPLICATION_FAILED: "application/SET_UPDATE_APPLICATION_FAILED",

  SET_DELETE_APPLICATION_LOADING: "application/SET_DELETE_APPLICATION_LOADING",
  SET_DELETE_APPLICATION_SUCCESS: "application/SET_DELETE_APPLICATION_SUCCESS",
  SET_DELETE_APPLICATION_FAILED: "application/SET_DELETE_APPLICATION_FAILED",

  APPEND_APPLICATIONS: "application/APPEND_APPLICATIONS",
  APPEND_APPLICATION_EXTENSIONS: "application/APPEND_APPLICATION_EXTENSIONS",

  SET_IS_FETCH_APPLICATIONS_HITTED:
    "application/SET_IS_FETCH_APPLICATIONS_HITTED",
  SET_IS_FETCH_APPLICATION_EXTENSIONS_HITTED:
    "application/SET_IS_FETCH_APPLICATION_EXTENSIONS_HITTED",
  SET_IS_FETCH_APPLICATION_HITTED:
    "application/SET_IS_FETCH_APPLICATION_HITTED",
  SET_IS_CREATE_APPLICATION_HITTED:
    "application/SET_IS_CREATE_APPLICATION_HITTED",
  SET_IS_UPDATE_APPLICATION_HITTED:
    "application/SET_IS_UPDATE_APPLICATION_HITTED",
  SET_IS_DELETE_APPLICATION_HITTED:
    "application/SET_IS_DELETE_APPLICATION_HITTED",

  FETCH_APPLICATIONS_START: "application/FETCH_APPLICATIONS_START",
  FETCH_APPLICATION_EXTENSIONS_START:
    "application/FETCH_APPLICATION_EXTENSIONS_START",
  FETCH_APPLICATION_START: "application/FETCH_APPLICATION_START",
  CREATE_APPLICATION_START: "application/CREATE_APPLICATION_START",
  UPDATE_APPLICATION_START: "application/UPDATE_APPLICATION_START",
  DELETE_APPLICATION_START: "application/DELETE_APPLICATION_START",
};

export default APPLICATION_ACTION_TYPES;
