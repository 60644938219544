import apiService from "./api";

export const createProductModifier = async (productId, request) =>
  (await apiService.post(`/product/${productId}/modifier`, request)).data;

export const updateProductModifier = async (productId, productModifierId, request) =>
  (await apiService.post(`/product/${productId}/modifier/${productModifierId}`, request)).data;

export const getProductModifierByBarcode = async (barcode) =>
  (await apiService.get(`/product-modifier/${barcode}`)).data;
