import EVENT_ACTION_TYPES from "./event.type";

export const EVENT_INITIAL_STATE = {
  sendEventLoading: false,
  sendEventSuccess: null,
  sendEventFailed: null,

  isSendEventHitted: false,
};

export const eventReducer = (state = EVENT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case EVENT_ACTION_TYPES.SET_SEND_EVENT_LOADING:
      return { ...state, sendEventLoading: payload };
    case EVENT_ACTION_TYPES.SET_SEND_EVENT_SUCCESS:
      return { ...state, sendEventSuccess: payload };
    case EVENT_ACTION_TYPES.SET_SEND_EVENT_FAILED:
      return { ...state, sendEventFailed: payload };

    case EVENT_ACTION_TYPES.SET_IS_SEND_EVENT_HITTED:
      return { ...state, isSendEventHitted: payload };

    case EVENT_ACTION_TYPES.RESET_EVENT_REDUCER:
      return EVENT_INITIAL_STATE;
    default:
      return state;
  }
};
