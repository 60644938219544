import PRODUCT_CATEGORY_SORT_ACTION_TYPES from "./product-category-sort.type";
import { createAction } from "../../utils/store.utils";

export const setCreateOrUpdateProductCategorySortLoading = (
  createOrUpdateProductCategorySortLoading
) =>
  createAction(
    PRODUCT_CATEGORY_SORT_ACTION_TYPES.SET_CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_LOADING,
    createOrUpdateProductCategorySortLoading
  );
export const setCreateOrUpdateProductCategorySortSuccess = (
  createOrUpdateProductCategorySortSuccess
) =>
  createAction(
    PRODUCT_CATEGORY_SORT_ACTION_TYPES.SET_CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_SUCCESS,
    createOrUpdateProductCategorySortSuccess
  );
export const setCreateOrUpdateProductCategorySortFailed = (
  createOrUpdateProductCategorySortFailed
) =>
  createAction(
    PRODUCT_CATEGORY_SORT_ACTION_TYPES.SET_CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_FAILED,
    createOrUpdateProductCategorySortFailed
  );

export const createOrUpdateProductCategorySortStart = (request) =>
  createAction(
    PRODUCT_CATEGORY_SORT_ACTION_TYPES.CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_START,
    request
  );

export const resetProductCategorySortReducer = () =>
  createAction(
    PRODUCT_CATEGORY_SORT_ACTION_TYPES.RESET_PRODUCT_CATEGORY_SORT_REDUCER
  );
