import apiService from "./api";

export const getMarkets = async (parameters) =>
  (await apiService.get("/v2/markets", parameters)).data;
export const getMarket = async (marketId) =>
  (await apiService.get(`/v2/markets/${marketId}`)).data;
export const createMarket = async (request) =>
  (await apiService.post(`/v2/markets`, request)).data;
export const updateMarket = async (marketId, request) =>
  (await apiService.post(`/v2/markets/${marketId}`, request)).data;
export const deleteMarket = async (marketId) =>
  (await apiService.delete(`/v2/markets/${marketId}`)).data;
