import { createSelector } from "reselect";

const taxSelector = ({ tax }) => tax;

export const getTaxValueTypes = createSelector(
  [taxSelector],
  ({ taxValueTypes }) => taxValueTypes
);
export const getTaxes = createSelector([taxSelector], ({ taxes }) => taxes);
export const getTax = createSelector([taxSelector], ({ tax }) => tax);

export const getIsTaxesHasMore = createSelector(
  [taxSelector],
  ({ isTaxesHasMore }) => isTaxesHasMore
);

export const getFetchTaxesSearch = createSelector(
  [taxSelector],
  ({ fetchTaxesSearch }) => fetchTaxesSearch
);
export const getFetchTaxesSort = createSelector(
  [taxSelector],
  ({ fetchTaxesSort }) => fetchTaxesSort
);
export const getFetchTaxesKeyBy = createSelector(
  [taxSelector],
  ({ fetchTaxesKeyBy }) => fetchTaxesKeyBy
);
export const getFetchTaxesPage = createSelector(
  [taxSelector],
  ({ fetchTaxesPage }) => fetchTaxesPage
);
export const getFetchTaxesPerPage = createSelector(
  [taxSelector],
  ({ fetchTaxesPerPage }) => fetchTaxesPerPage
);
export const getFetchTaxesIncludes = createSelector(
  [taxSelector],
  ({ fetchTaxesIncludes }) => fetchTaxesIncludes
);
export const getFetchTaxesFilterMarketId = createSelector(
  [taxSelector],
  ({ fetchTaxesFilterMarketId }) => fetchTaxesFilterMarketId
);
export const getFetchTaxesFilterBranchId = createSelector(
  [taxSelector],
  ({ fetchTaxesFilterBranchId }) => fetchTaxesFilterBranchId
);
export const getFetchTaxesLoading = createSelector(
  [taxSelector],
  ({ fetchTaxesLoading }) => fetchTaxesLoading
);
export const getFetchTaxesSuccess = createSelector(
  [taxSelector],
  ({ fetchTaxesSuccess }) => fetchTaxesSuccess
);
export const getFetchTaxesFailed = createSelector(
  [taxSelector],
  ({ fetchTaxesFailed }) => fetchTaxesFailed
);

export const getFetchTaxLoading = createSelector(
  [taxSelector],
  ({ fetchTaxLoading }) => fetchTaxLoading
);
export const getFetchTaxSuccess = createSelector(
  [taxSelector],
  ({ fetchTaxSuccess }) => fetchTaxSuccess
);
export const getFetchTaxFailed = createSelector(
  [taxSelector],
  ({ fetchTaxFailed }) => fetchTaxFailed
);

export const getCreateTaxLoading = createSelector(
  [taxSelector],
  ({ createTaxLoading }) => createTaxLoading
);
export const getCreateTaxSuccess = createSelector(
  [taxSelector],
  ({ createTaxSuccess }) => createTaxSuccess
);
export const getCreateTaxFailed = createSelector(
  [taxSelector],
  ({ createTaxFailed }) => createTaxFailed
);

export const getUpdateTaxLoading = createSelector(
  [taxSelector],
  ({ updateTaxLoading }) => updateTaxLoading
);
export const getUpdateTaxSuccess = createSelector(
  [taxSelector],
  ({ updateTaxSuccess }) => updateTaxSuccess
);
export const getUpdateTaxFailed = createSelector(
  [taxSelector],
  ({ updateTaxFailed }) => updateTaxFailed
);

export const getDeleteTaxLoading = createSelector(
  [taxSelector],
  ({ deleteTaxLoading }) => deleteTaxLoading
);
export const getDeleteTaxSuccess = createSelector(
  [taxSelector],
  ({ deleteTaxSuccess }) => deleteTaxSuccess
);
export const getDeleteTaxFailed = createSelector(
  [taxSelector],
  ({ deleteTaxFailed }) => deleteTaxFailed
);

export const getSortTaxesLoading = createSelector(
  [taxSelector],
  ({ sortTaxesLoading }) => sortTaxesLoading
);
export const getSortTaxesSuccess = createSelector(
  [taxSelector],
  ({ sortTaxesSuccess }) => sortTaxesSuccess
);
export const getSortTaxesFailed = createSelector(
  [taxSelector],
  ({ sortTaxesFailed }) => sortTaxesFailed
);

export const getIsFetchTaxesHitted = createSelector(
  [taxSelector],
  ({ isFetchTaxesHitted }) => isFetchTaxesHitted
);
export const getIsFetchTaxHitted = createSelector(
  [taxSelector],
  ({ isFetchTaxHitted }) => isFetchTaxHitted
);
export const getIsCreateTaxHitted = createSelector(
  [taxSelector],
  ({ isCreateTaxHitted }) => isCreateTaxHitted
);
export const getIsUpdateTaxHitted = createSelector(
  [taxSelector],
  ({ isUpdateTaxHitted }) => isUpdateTaxHitted
);
export const getIsDeleteTaxHitted = createSelector(
  [taxSelector],
  ({ isDeleteTaxHitted }) => isDeleteTaxHitted
);
export const getIsSortTaxesHitted = createSelector(
  [taxSelector],
  ({ isSortTaxesHitted }) => isSortTaxesHitted
);
