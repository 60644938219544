import USER_SUPPLIER_ACTION_TYPES from "./user-supplier.type";

export const USER_SUPPLIER_INITIAL_STATE = {
  userSuppliers: [],
  userSupplier: null,

  isUserSuppliersHasMore: true,

  fetchUserSuppliersSearch: null,
  fetchUserSuppliersSort: null,
  fetchUserSuppliersKeyBy: null,
  fetchUserSuppliersPage: 1,
  fetchUserSuppliersPerPage: null,
  fetchUserSuppliersIncludes: null,
  fetchUserSuppliersFilterMarketId: null,
  fetchUserSuppliersFilterBranchId: null,
  fetchUserSuppliersLoading: false,
  fetchUserSuppliersSuccess: null,
  fetchUserSuppliersFailed: null,

  fetchUserSupplierLoading: false,
  fetchUserSupplierSuccess: null,
  fetchUserSupplierFailed: null,

  createUserSupplierLoading: false,
  createUserSupplierSuccess: null,
  createUserSupplierFailed: null,

  updateUserSupplierLoading: false,
  updateUserSupplierSuccess: null,
  updateUserSupplierFailed: null,

  deleteUserSupplierLoading: false,
  deleteUserSupplierSuccess: null,
  deleteUserSupplierFailed: null,

  isFetchUserSuppliersHitted: false,
  isFetchUserSupplierHitted: false,
  isCreateUserSupplierHitted: false,
  isUpdateUserSupplierHitted: false,
  isDeleteUserSupplierHitted: false,
};

export const userSupplierReducer = (
  state = USER_SUPPLIER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case USER_SUPPLIER_ACTION_TYPES.SET_USER_SUPPLIERS:
      return { ...state, userSuppliers: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_USER_SUPPLIER:
      return { ...state, userSupplier: payload };

    case USER_SUPPLIER_ACTION_TYPES.SET_IS_USER_SUPPLIERS_HAS_MORE:
      return { ...state, isUserSuppliersHasMore: payload };

    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_SEARCH:
      return { ...state, fetchUserSuppliersSearch: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_SORT:
      return { ...state, fetchUserSuppliersSort: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_KEY_BY:
      return { ...state, fetchUserSuppliersKeyBy: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_PAGE:
      return { ...state, fetchUserSuppliersPage: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_PER_PAGE:
      return { ...state, fetchUserSuppliersPerPage: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_INCLUDES:
      return { ...state, fetchUserSuppliersIncludes: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_FILTER_MARKET_ID:
      return { ...state, fetchUserSuppliersFilterMarketId: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_FILTER_BRANCH_ID:
      return { ...state, fetchUserSuppliersFilterBranchId: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_LOADING:
      return { ...state, fetchUserSuppliersLoading: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_SUCCESS:
      return { ...state, fetchUserSuppliersSuccess: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_FAILED:
      return { ...state, fetchUserSuppliersFailed: payload };

    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIER_LOADING:
      return { ...state, fetchUserSupplierLoading: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIER_SUCCESS:
      return { ...state, fetchUserSupplierSuccess: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIER_FAILED:
      return { ...state, fetchUserSupplierFailed: payload };

    case USER_SUPPLIER_ACTION_TYPES.SET_CREATE_USER_SUPPLIER_LOADING:
      return { ...state, createUserSupplierLoading: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_CREATE_USER_SUPPLIER_SUCCESS:
      return { ...state, createUserSupplierSuccess: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_CREATE_USER_SUPPLIER_FAILED:
      return { ...state, createUserSupplierFailed: payload };

    case USER_SUPPLIER_ACTION_TYPES.SET_UPDATE_USER_SUPPLIER_LOADING:
      return { ...state, updateUserSupplierLoading: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_UPDATE_USER_SUPPLIER_SUCCESS:
      return { ...state, updateUserSupplierSuccess: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_UPDATE_USER_SUPPLIER_FAILED:
      return { ...state, updateUserSupplierFailed: payload };

    case USER_SUPPLIER_ACTION_TYPES.SET_DELETE_USER_SUPPLIER_LOADING:
      return { ...state, deleteUserSupplierLoading: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_DELETE_USER_SUPPLIER_SUCCESS:
      return { ...state, deleteUserSupplierSuccess: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_DELETE_USER_SUPPLIER_FAILED:
      return { ...state, deleteUserSupplierFailed: payload };

    case USER_SUPPLIER_ACTION_TYPES.APPEND_USER_SUPPLIERS:
      return {
        ...state,
        userSuppliers: [...state.userSuppliers, ...payload],
      };

    case USER_SUPPLIER_ACTION_TYPES.SET_IS_FETCH_USER_SUPPLIERS_HITTED:
      return { ...state, isFetchUserSuppliersHitted: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_IS_FETCH_USER_SUPPLIER_HITTED:
      return { ...state, isFetchUserSupplierHitted: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_IS_CREATE_USER_SUPPLIER_HITTED:
      return { ...state, isCreateUserSupplierHitted: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_IS_UPDATE_USER_SUPPLIER_HITTED:
      return { ...state, isUpdateUserSupplierHitted: payload };
    case USER_SUPPLIER_ACTION_TYPES.SET_IS_DELETE_USER_SUPPLIER_HITTED:
      return { ...state, isDeleteUserSupplierHitted: payload };

    case USER_SUPPLIER_ACTION_TYPES.RESET_USER_SUPPLIER_REDUCER:
      return USER_SUPPLIER_INITIAL_STATE;
    default:
      return state;
  }
};
