import { createSelector } from "reselect";

const ingredientSelector = ({ ingredient }) => ingredient;

export const getIngredients = createSelector(
  [ingredientSelector],
  ({ ingredients }) => ingredients
);
export const getSelectIngredients = createSelector(
  [ingredientSelector],
  ({ selectIngredients }) => selectIngredients
);
export const getSelectGroupIngredients = createSelector(
  [ingredientSelector],
  ({ selectGroupIngredients }) => selectGroupIngredients
);
export const getOutOfStockIngredients = createSelector(
  [ingredientSelector],
  ({ outOfStockIngredients }) => outOfStockIngredients
);
export const getIngredient = createSelector(
  [ingredientSelector],
  ({ ingredient }) => ingredient
);

export const getIsIngredientsHasMore = createSelector(
  [ingredientSelector],
  ({ isIngredientsHasMore }) => isIngredientsHasMore
);
export const getIsSelectIngredientsHasMore = createSelector(
  [ingredientSelector],
  ({ isSelectIngredientsHasMore }) => isSelectIngredientsHasMore
);
export const getIsSelectGroupIngredientsHasMore = createSelector(
  [ingredientSelector],
  ({ isSelectGroupIngredientsHasMore }) => isSelectGroupIngredientsHasMore
);
export const getIsOutOfStockIngredientsHasMore = createSelector(
  [ingredientSelector],
  ({ isOutOfStockIngredientsHasMore }) => isOutOfStockIngredientsHasMore
);

export const getFetchIngredientsSearch = createSelector(
  [ingredientSelector],
  ({ fetchIngredientsSearch }) => fetchIngredientsSearch
);
export const getFetchIngredientsSort = createSelector(
  [ingredientSelector],
  ({ fetchIngredientsSort }) => fetchIngredientsSort
);
export const getFetchIngredientsKeyBy = createSelector(
  [ingredientSelector],
  ({ fetchIngredientsKeyBy }) => fetchIngredientsKeyBy
);
export const getFetchIngredientsPage = createSelector(
  [ingredientSelector],
  ({ fetchIngredientsPage }) => fetchIngredientsPage
);
export const getFetchIngredientsPerPage = createSelector(
  [ingredientSelector],
  ({ fetchIngredientsPerPage }) => fetchIngredientsPerPage
);
export const getFetchIngredientsIncludes = createSelector(
  [ingredientSelector],
  ({ fetchIngredientsIncludes }) => fetchIngredientsIncludes
);
export const getFetchIngredientsFilterMarketId = createSelector(
  [ingredientSelector],
  ({ fetchIngredientsFilterMarketId }) => fetchIngredientsFilterMarketId
);
export const getFetchIngredientsFilterBranchId = createSelector(
  [ingredientSelector],
  ({ fetchIngredientsFilterBranchId }) => fetchIngredientsFilterBranchId
);
export const getFetchIngredientsFilterIsOutOfStock = createSelector(
  [ingredientSelector],
  ({ fetchIngredientsFilterIsOutOfStock }) => fetchIngredientsFilterIsOutOfStock
);
export const getFetchIngredientsLoading = createSelector(
  [ingredientSelector],
  ({ fetchIngredientsLoading }) => fetchIngredientsLoading
);
export const getFetchIngredientsSuccess = createSelector(
  [ingredientSelector],
  ({ fetchIngredientsSuccess }) => fetchIngredientsSuccess
);
export const getFetchIngredientsFailed = createSelector(
  [ingredientSelector],
  ({ fetchIngredientsFailed }) => fetchIngredientsFailed
);

export const getFetchSelectIngredientsSearch = createSelector(
  [ingredientSelector],
  ({ fetchSelectIngredientsSearch }) => fetchSelectIngredientsSearch
);
export const getFetchSelectIngredientsSort = createSelector(
  [ingredientSelector],
  ({ fetchSelectIngredientsSort }) => fetchSelectIngredientsSort
);
export const getFetchSelectIngredientsKeyBy = createSelector(
  [ingredientSelector],
  ({ fetchSelectIngredientsKeyBy }) => fetchSelectIngredientsKeyBy
);
export const getFetchSelectIngredientsPage = createSelector(
  [ingredientSelector],
  ({ fetchSelectIngredientsPage }) => fetchSelectIngredientsPage
);
export const getFetchSelectIngredientsPerPage = createSelector(
  [ingredientSelector],
  ({ fetchSelectIngredientsPerPage }) => fetchSelectIngredientsPerPage
);
export const getFetchSelectIngredientsIncludes = createSelector(
  [ingredientSelector],
  ({ fetchSelectIngredientsIncludes }) => fetchSelectIngredientsIncludes
);
export const getFetchSelectIngredientsFilterMarketId = createSelector(
  [ingredientSelector],
  ({ fetchSelectIngredientsFilterMarketId }) =>
    fetchSelectIngredientsFilterMarketId
);
export const getFetchSelectIngredientsFilterBranchId = createSelector(
  [ingredientSelector],
  ({ fetchSelectIngredientsFilterBranchId }) =>
    fetchSelectIngredientsFilterBranchId
);
export const getFetchSelectIngredientsFilterIngredientsIds = createSelector(
  [ingredientSelector],
  ({ fetchSelectIngredientsFilterIngredientsIds }) =>
    fetchSelectIngredientsFilterIngredientsIds
);
export const getFetchSelectIngredientsFilterIsGroup = createSelector(
  [ingredientSelector],
  ({ fetchSelectIngredientsFilterIsGroup }) =>
    fetchSelectIngredientsFilterIsGroup
);
export const getFetchSelectIngredientsLoading = createSelector(
  [ingredientSelector],
  ({ fetchSelectIngredientsLoading }) => fetchSelectIngredientsLoading
);
export const getFetchSelectIngredientsSuccess = createSelector(
  [ingredientSelector],
  ({ fetchSelectIngredientsSuccess }) => fetchSelectIngredientsSuccess
);
export const getFetchSelectIngredientsFailed = createSelector(
  [ingredientSelector],
  ({ fetchSelectIngredientsFailed }) => fetchSelectIngredientsFailed
);

export const getFetchSelectGroupIngredientsSearch = createSelector(
  [ingredientSelector],
  ({ fetchSelectGroupIngredientsSearch }) => fetchSelectGroupIngredientsSearch
);
export const getFetchSelectGroupIngredientsSort = createSelector(
  [ingredientSelector],
  ({ fetchSelectGroupIngredientsSort }) => fetchSelectGroupIngredientsSort
);
export const getFetchSelectGroupIngredientsKeyBy = createSelector(
  [ingredientSelector],
  ({ fetchSelectGroupIngredientsKeyBy }) => fetchSelectGroupIngredientsKeyBy
);
export const getFetchSelectGroupIngredientsPage = createSelector(
  [ingredientSelector],
  ({ fetchSelectGroupIngredientsPage }) => fetchSelectGroupIngredientsPage
);
export const getFetchSelectGroupIngredientsPerPage = createSelector(
  [ingredientSelector],
  ({ fetchSelectGroupIngredientsPerPage }) => fetchSelectGroupIngredientsPerPage
);
export const getFetchSelectGroupIngredientsIncludes = createSelector(
  [ingredientSelector],
  ({ fetchSelectGroupIngredientsIncludes }) =>
    fetchSelectGroupIngredientsIncludes
);
export const getFetchSelectGroupIngredientsFilterMarketId = createSelector(
  [ingredientSelector],
  ({ fetchSelectGroupIngredientsFilterMarketId }) =>
    fetchSelectGroupIngredientsFilterMarketId
);
export const getFetchSelectGroupIngredientsFilterBranchId = createSelector(
  [ingredientSelector],
  ({ fetchSelectGroupIngredientsFilterBranchId }) =>
    fetchSelectGroupIngredientsFilterBranchId
);
export const getFetchSelectGroupIngredientsFilterIngredientsIds =
  createSelector(
    [ingredientSelector],
    ({ fetchSelectGroupIngredientsFilterIngredientsIds }) =>
      fetchSelectGroupIngredientsFilterIngredientsIds
  );
export const getFetchSelectGroupIngredientsFilterIsGroup = createSelector(
  [ingredientSelector],
  ({ fetchSelectGroupIngredientsFilterIsGroup }) =>
    fetchSelectGroupIngredientsFilterIsGroup
);
export const getFetchSelectGroupIngredientsLoading = createSelector(
  [ingredientSelector],
  ({ fetchSelectGroupIngredientsLoading }) => fetchSelectGroupIngredientsLoading
);
export const getFetchSelectGroupIngredientsSuccess = createSelector(
  [ingredientSelector],
  ({ fetchSelectGroupIngredientsSuccess }) => fetchSelectGroupIngredientsSuccess
);
export const getFetchSelectGroupIngredientsFailed = createSelector(
  [ingredientSelector],
  ({ fetchSelectGroupIngredientsFailed }) => fetchSelectGroupIngredientsFailed
);

export const getFetchOutOfStockIngredientsSearch = createSelector(
  [ingredientSelector],
  ({ fetchOutOfStockIngredientsSearch }) => fetchOutOfStockIngredientsSearch
);
export const getFetchOutOfStockIngredientsSort = createSelector(
  [ingredientSelector],
  ({ fetchOutOfStockIngredientsSort }) => fetchOutOfStockIngredientsSort
);
export const getFetchOutOfStockIngredientsKeyBy = createSelector(
  [ingredientSelector],
  ({ fetchOutOfStockIngredientsKeyBy }) => fetchOutOfStockIngredientsKeyBy
);
export const getFetchOutOfStockIngredientsPage = createSelector(
  [ingredientSelector],
  ({ fetchOutOfStockIngredientsPage }) => fetchOutOfStockIngredientsPage
);
export const getFetchOutOfStockIngredientsPerPage = createSelector(
  [ingredientSelector],
  ({ fetchOutOfStockIngredientsPerPage }) => fetchOutOfStockIngredientsPerPage
);
export const getFetchOutOfStockIngredientsIncludes = createSelector(
  [ingredientSelector],
  ({ fetchOutOfStockIngredientsIncludes }) => fetchOutOfStockIngredientsIncludes
);
export const getFetchOutOfStockIngredientsFilterMarketId = createSelector(
  [ingredientSelector],
  ({ fetchOutOfStockIngredientsFilterMarketId }) =>
    fetchOutOfStockIngredientsFilterMarketId
);
export const getFetchOutOfStockIngredientsFilterBranchId = createSelector(
  [ingredientSelector],
  ({ fetchOutOfStockIngredientsFilterBranchId }) =>
    fetchOutOfStockIngredientsFilterBranchId
);
export const getFetchOutOfStockIngredientsLoading = createSelector(
  [ingredientSelector],
  ({ fetchOutOfStockIngredientsLoading }) => fetchOutOfStockIngredientsLoading
);
export const getFetchOutOfStockIngredientsSuccess = createSelector(
  [ingredientSelector],
  ({ fetchOutOfStockIngredientsSuccess }) => fetchOutOfStockIngredientsSuccess
);
export const getFetchOutOfStockIngredientsFailed = createSelector(
  [ingredientSelector],
  ({ fetchOutOfStockIngredientsFailed }) => fetchOutOfStockIngredientsFailed
);

export const getAppendSelectIngredientsSearch = createSelector(
  [ingredientSelector],
  ({ appendSelectIngredientsSearch }) => appendSelectIngredientsSearch
);
export const getAppendSelectIngredientsSort = createSelector(
  [ingredientSelector],
  ({ appendSelectIngredientsSort }) => appendSelectIngredientsSort
);
export const getAppendSelectIngredientsKeyBy = createSelector(
  [ingredientSelector],
  ({ appendSelectIngredientsKeyBy }) => appendSelectIngredientsKeyBy
);
export const getAppendSelectIngredientsPage = createSelector(
  [ingredientSelector],
  ({ appendSelectIngredientsPage }) => appendSelectIngredientsPage
);
export const getAppendSelectIngredientsPerPage = createSelector(
  [ingredientSelector],
  ({ appendSelectIngredientsPerPage }) => appendSelectIngredientsPerPage
);
export const getAppendSelectIngredientsIncludes = createSelector(
  [ingredientSelector],
  ({ appendSelectIngredientsIncludes }) => appendSelectIngredientsIncludes
);
export const getAppendSelectIngredientsFilterMarketId = createSelector(
  [ingredientSelector],
  ({ appendSelectIngredientsFilterMarketId }) =>
    appendSelectIngredientsFilterMarketId
);
export const getAppendSelectIngredientsFilterBranchId = createSelector(
  [ingredientSelector],
  ({ appendSelectIngredientsFilterBranchId }) =>
    appendSelectIngredientsFilterBranchId
);
export const getAppendSelectIngredientsFilterIngredientsIds = createSelector(
  [ingredientSelector],
  ({ appendSelectIngredientsFilterIngredientsIds }) =>
    appendSelectIngredientsFilterIngredientsIds
);
export const getAppendSelectIngredientsFilterIsGroup = createSelector(
  [ingredientSelector],
  ({ appendSelectIngredientsFilterIsGroup }) =>
    appendSelectIngredientsFilterIsGroup
);
export const getAppendSelectIngredientsLoading = createSelector(
  [ingredientSelector],
  ({ appendSelectIngredientsLoading }) => appendSelectIngredientsLoading
);
export const getAppendSelectIngredientsSuccess = createSelector(
  [ingredientSelector],
  ({ appendSelectIngredientsSuccess }) => appendSelectIngredientsSuccess
);
export const getAppendSelectIngredientsFailed = createSelector(
  [ingredientSelector],
  ({ appendSelectIngredientsFailed }) => appendSelectIngredientsFailed
);

export const getAppendSelectGroupIngredientsSearch = createSelector(
  [ingredientSelector],
  ({ appendSelectGroupIngredientsSearch }) => appendSelectGroupIngredientsSearch
);
export const getAppendSelectGroupIngredientsSort = createSelector(
  [ingredientSelector],
  ({ appendSelectGroupIngredientsSort }) => appendSelectGroupIngredientsSort
);
export const getAppendSelectGroupIngredientsKeyBy = createSelector(
  [ingredientSelector],
  ({ appendSelectGroupIngredientsKeyBy }) => appendSelectGroupIngredientsKeyBy
);
export const getAppendSelectGroupIngredientsPage = createSelector(
  [ingredientSelector],
  ({ appendSelectGroupIngredientsPage }) => appendSelectGroupIngredientsPage
);
export const getAppendSelectGroupIngredientsPerPage = createSelector(
  [ingredientSelector],
  ({ appendSelectGroupIngredientsPerPage }) =>
    appendSelectGroupIngredientsPerPage
);
export const getAppendSelectGroupIngredientsIncludes = createSelector(
  [ingredientSelector],
  ({ appendSelectGroupIngredientsIncludes }) =>
    appendSelectGroupIngredientsIncludes
);
export const getAppendSelectGroupIngredientsFilterMarketId = createSelector(
  [ingredientSelector],
  ({ appendSelectGroupIngredientsFilterMarketId }) =>
    appendSelectGroupIngredientsFilterMarketId
);
export const getAppendSelectGroupIngredientsFilterBranchId = createSelector(
  [ingredientSelector],
  ({ appendSelectGroupIngredientsFilterBranchId }) =>
    appendSelectGroupIngredientsFilterBranchId
);
export const getAppendSelectGroupIngredientsFilterIngredientsIds =
  createSelector(
    [ingredientSelector],
    ({ appendSelectGroupIngredientsFilterIngredientsIds }) =>
      appendSelectGroupIngredientsFilterIngredientsIds
  );
export const getAppendSelectGroupIngredientsFilterIsGroup = createSelector(
  [ingredientSelector],
  ({ appendSelectGroupIngredientsFilterIsGroup }) =>
    appendSelectGroupIngredientsFilterIsGroup
);
export const getAppendSelectGroupIngredientsLoading = createSelector(
  [ingredientSelector],
  ({ appendSelectGroupIngredientsLoading }) =>
    appendSelectGroupIngredientsLoading
);
export const getAppendSelectGroupIngredientsSuccess = createSelector(
  [ingredientSelector],
  ({ appendSelectGroupIngredientsSuccess }) =>
    appendSelectGroupIngredientsSuccess
);
export const getAppendSelectGroupIngredientsFailed = createSelector(
  [ingredientSelector],
  ({ appendSelectGroupIngredientsFailed }) => appendSelectGroupIngredientsFailed
);

export const getFetchIngredientLoading = createSelector(
  [ingredientSelector],
  ({ fetchIngredientLoading }) => fetchIngredientLoading
);
export const getFetchIngredientSuccess = createSelector(
  [ingredientSelector],
  ({ fetchIngredientSuccess }) => fetchIngredientSuccess
);
export const getFetchIngredientFailed = createSelector(
  [ingredientSelector],
  ({ fetchIngredientFailed }) => fetchIngredientFailed
);

export const getCreateIngredientLoading = createSelector(
  [ingredientSelector],
  ({ createIngredientLoading }) => createIngredientLoading
);
export const getCreateIngredientSuccess = createSelector(
  [ingredientSelector],
  ({ createIngredientSuccess }) => createIngredientSuccess
);
export const getCreateIngredientFailed = createSelector(
  [ingredientSelector],
  ({ createIngredientFailed }) => createIngredientFailed
);

export const getUpdateIngredientLoading = createSelector(
  [ingredientSelector],
  ({ updateIngredientLoading }) => updateIngredientLoading
);
export const getUpdateIngredientSuccess = createSelector(
  [ingredientSelector],
  ({ updateIngredientSuccess }) => updateIngredientSuccess
);
export const getUpdateIngredientFailed = createSelector(
  [ingredientSelector],
  ({ updateIngredientFailed }) => updateIngredientFailed
);

export const getDeleteIngredientLoading = createSelector(
  [ingredientSelector],
  ({ deleteIngredientLoading }) => deleteIngredientLoading
);
export const getDeleteIngredientSuccess = createSelector(
  [ingredientSelector],
  ({ deleteIngredientSuccess }) => deleteIngredientSuccess
);
export const getDeleteIngredientFailed = createSelector(
  [ingredientSelector],
  ({ deleteIngredientFailed }) => deleteIngredientFailed
);

export const getStoreStockIngredientLoading = createSelector(
  [ingredientSelector],
  ({ storeStockIngredientLoading }) => storeStockIngredientLoading
);
export const getStoreStockIngredientSuccess = createSelector(
  [ingredientSelector],
  ({ storeStockIngredientSuccess }) => storeStockIngredientSuccess
);
export const getStoreStockIngredientFailed = createSelector(
  [ingredientSelector],
  ({ storeStockIngredientFailed }) => storeStockIngredientFailed
);

export const getReduceStockIngredientLoading = createSelector(
  [ingredientSelector],
  ({ reduceStockIngredientLoading }) => reduceStockIngredientLoading
);
export const getReduceStockIngredientSuccess = createSelector(
  [ingredientSelector],
  ({ reduceStockIngredientSuccess }) => reduceStockIngredientSuccess
);
export const getReduceStockIngredientFailed = createSelector(
  [ingredientSelector],
  ({ reduceStockIngredientFailed }) => reduceStockIngredientFailed
);

export const getIsFetchIngredientsHitted = createSelector(
  [ingredientSelector],
  ({ isFetchIngredientsHitted }) => isFetchIngredientsHitted
);
export const getIsFetchSelectIngredientsHitted = createSelector(
  [ingredientSelector],
  ({ isFetchSelectIngredientsHitted }) => isFetchSelectIngredientsHitted
);
export const getIsFetchSelectGroupIngredientsHitted = createSelector(
  [ingredientSelector],
  ({ isFetchSelectGroupIngredientsHitted }) =>
    isFetchSelectGroupIngredientsHitted
);
export const getIsFetchOutOfStockIngredientsHitted = createSelector(
  [ingredientSelector],
  ({ isFetchOutOfStockIngredientsHitted }) => isFetchOutOfStockIngredientsHitted
);
export const getIsAppendSelectIngredientsHitted = createSelector(
  [ingredientSelector],
  ({ isAppendSelectIngredientsHitted }) => isAppendSelectIngredientsHitted
);
export const getIsAppendSelectGroupIngredientsHitted = createSelector(
  [ingredientSelector],
  ({ isAppendSelectGroupIngredientsHitted }) =>
    isAppendSelectGroupIngredientsHitted
);
export const getIsFetchIngredientHitted = createSelector(
  [ingredientSelector],
  ({ isFetchIngredientHitted }) => isFetchIngredientHitted
);
export const getIsCreateIngredientHitted = createSelector(
  [ingredientSelector],
  ({ isCreateIngredientHitted }) => isCreateIngredientHitted
);
export const getIsUpdateIngredientHitted = createSelector(
  [ingredientSelector],
  ({ isUpdateIngredientHitted }) => isUpdateIngredientHitted
);
export const getIsDeleteIngredientHitted = createSelector(
  [ingredientSelector],
  ({ isDeleteIngredientHitted }) => isDeleteIngredientHitted
);
export const getIsStoreStockIngredientHitted = createSelector(
  [ingredientSelector],
  ({ isStoreStockIngredientHitted }) => isStoreStockIngredientHitted
);
export const getIsReduceStockIngredientHitted = createSelector(
  [ingredientSelector],
  ({ isReduceStockIngredientHitted }) => isReduceStockIngredientHitted
);
