const USER_CUSTOMER_ACTION_TYPES = {
  RESET_USER_CUSTOMER_REDUCER: "user-customer/RESET_USER_CUSTOMER_REDUCER",

  SET_USER_CUSTOMERS: "user-customer/SET_USER_CUSTOMERS",
  SET_SEARCH_USER_CUSTOMERS: "user-customer/SET_SEARCH_USER_CUSTOMERS",
  SET_USER_CUSTOMER: "user-customer/SET_USER_CUSTOMER",
  SET_CURRENT_USER_CUSTOMER: "user-customer/SET_CURRENT_USER_CUSTOMER",
  SET_CREATE_USER_CUSTOMER: "user-customer/SET_CREATE_USER_CUSTOMER",
  SET_UPDATE_USER_CUSTOMER: "user-customer/SET_UPDATE_USER_CUSTOMER",

  SET_IS_USER_CUSTOMERS_HAS_MORE:
    "user-customer/SET_IS_USER_CUSTOMERS_HAS_MORE",
  SET_IS_SEARCH_USER_CUSTOMERS_HAS_MORE:
    "user-customer/SET_IS_SEARCH_USER_CUSTOMERS_HAS_MORE",

  SET_FETCH_USER_CUSTOMERS_SEARCH:
    "user-customer/SET_FETCH_USER_CUSTOMERS_SEARCH",
  SET_FETCH_USER_CUSTOMERS_SORT: "user-customer/SET_FETCH_USER_CUSTOMERS_SORT",
  SET_FETCH_USER_CUSTOMERS_KEY_BY:
    "user-customer/SET_FETCH_USER_CUSTOMERS_KEY_BY",
  SET_FETCH_USER_CUSTOMERS_PAGE: "user-customer/SET_FETCH_USER_CUSTOMERS_PAGE",
  SET_FETCH_USER_CUSTOMERS_PER_PAGE:
    "user-customer/SET_FETCH_USER_CUSTOMERS_PER_PAGE",
  SET_FETCH_USER_CUSTOMERS_INCLUDES:
    "user-customer/SET_FETCH_USER_CUSTOMERS_INCLUDES",
  SET_FETCH_USER_CUSTOMERS_FILTER_MARKET_ID:
    "user-customer/SET_FETCH_USER_CUSTOMERS_FILTER_MARKET_ID",
  SET_FETCH_USER_CUSTOMERS_LOADING:
    "user-customer/SET_FETCH_USER_CUSTOMERS_LOADING",
  SET_FETCH_USER_CUSTOMERS_SUCCESS:
    "user-customer/SET_FETCH_USER_CUSTOMERS_SUCCESS",
  SET_FETCH_USER_CUSTOMERS_FAILED:
    "user-customer/SET_FETCH_USER_CUSTOMERS_FAILED",

  SET_FETCH_SEARCH_USER_CUSTOMERS_SEARCH:
    "user-customer/SET_FETCH_SEARCH_USER_CUSTOMERS_SEARCH",
  SET_FETCH_SEARCH_USER_CUSTOMERS_SORT:
    "user-customer/SET_FETCH_SEARCH_USER_CUSTOMERS_SORT",
  SET_FETCH_SEARCH_USER_CUSTOMERS_KEY_BY:
    "user-customer/SET_FETCH_SEARCH_USER_CUSTOMERS_KEY_BY",
  SET_FETCH_SEARCH_USER_CUSTOMERS_PAGE:
    "user-customer/SET_FETCH_SEARCH_USER_CUSTOMERS_PAGE",
  SET_FETCH_SEARCH_USER_CUSTOMERS_PER_PAGE:
    "user-customer/SET_FETCH_SEARCH_USER_CUSTOMERS_PER_PAGE",
  SET_FETCH_SEARCH_USER_CUSTOMERS_INCLUDES:
    "user-customer/SET_FETCH_SEARCH_USER_CUSTOMERS_INCLUDES",
  SET_FETCH_SEARCH_USER_CUSTOMERS_FILTER_MARKET_ID:
    "user-customer/SET_FETCH_SEARCH_USER_CUSTOMERS_FILTER_MARKET_ID",
  SET_FETCH_SEARCH_USER_CUSTOMERS_LOADING:
    "user-customer/SET_FETCH_SEARCH_USER_CUSTOMERS_LOADING",
  SET_FETCH_SEARCH_USER_CUSTOMERS_SUCCESS:
    "user-customer/SET_FETCH_SEARCH_USER_CUSTOMERS_SUCCESS",
  SET_FETCH_SEARCH_USER_CUSTOMERS_FAILED:
    "user-customer/SET_FETCH_SEARCH_USER_CUSTOMERS_FAILED",

  SET_FETCH_USER_CUSTOMER_LOADING:
    "user-customer/SET_FETCH_USER_CUSTOMER_LOADING",
  SET_FETCH_USER_CUSTOMER_SUCCESS:
    "user-customer/SET_FETCH_USER_CUSTOMER_SUCCESS",
  SET_FETCH_USER_CUSTOMER_FAILED:
    "user-customer/SET_FETCH_USER_CUSTOMER_FAILED",

  SET_CREATE_USER_CUSTOMER_LOADING:
    "user-customer/SET_CREATE_USER_CUSTOMER_LOADING",
  SET_CREATE_USER_CUSTOMER_SUCCESS:
    "user-customer/SET_CREATE_USER_CUSTOMER_SUCCESS",
  SET_CREATE_USER_CUSTOMER_FAILED:
    "user-customer/SET_CREATE_USER_CUSTOMER_FAILED",

  SET_UPDATE_USER_CUSTOMER_LOADING:
    "user-customer/SET_UPDATE_USER_CUSTOMER_LOADING",
  SET_UPDATE_USER_CUSTOMER_SUCCESS:
    "user-customer/SET_UPDATE_USER_CUSTOMER_SUCCESS",
  SET_UPDATE_USER_CUSTOMER_FAILED:
    "user-customer/SET_UPDATE_USER_CUSTOMER_FAILED",

  SET_ADD_MARKET_USER_CUSTOMER_LOADING:
    "user-customer/SET_ADD_MARKET_USER_CUSTOMER_LOADING",
  SET_ADD_MARKET_USER_CUSTOMER_SUCCESS:
    "user-customer/SET_ADD_MARKET_USER_CUSTOMER_SUCCESS",
  SET_ADD_MARKET_USER_CUSTOMER_FAILED:
    "user-customer/SET_ADD_MARKET_USER_CUSTOMER_FAILED",

  SET_REMOVE_MARKET_USER_CUSTOMER_LOADING:
    "user-customer/SET_REMOVE_MARKET_USER_CUSTOMER_LOADING",
  SET_REMOVE_MARKET_USER_CUSTOMER_SUCCESS:
    "user-customer/SET_REMOVE_MARKET_USER_CUSTOMER_SUCCESS",
  SET_REMOVE_MARKET_USER_CUSTOMER_FAILED:
    "user-customer/SET_REMOVE_MARKET_USER_CUSTOMER_FAILED",

  APPEND_USER_CUSTOMERS: "user-customer/APPEND_USER_CUSTOMERS",
  APPEND_SEARCH_USER_CUSTOMERS: "user-customer/APPEND_SEARCH_USER_CUSTOMERS",

  SET_IS_FETCH_USER_CUSTOMERS_HITTED:
    "user-customer/SET_IS_FETCH_USER_CUSTOMERS_HITTED",
  SET_IS_FETCH_SEARCH_USER_CUSTOMERS_HITTED:
    "user-customer/SET_IS_FETCH_SEARCH_USER_CUSTOMERS_HITTED",
  SET_IS_FETCH_USER_CUSTOMER_HITTED:
    "user-customer/SET_IS_FETCH_USER_CUSTOMER_HITTED",
  SET_IS_CREATE_USER_CUSTOMER_HITTED:
    "user-customer/SET_IS_CREATE_USER_CUSTOMER_HITTED",
  SET_IS_UPDATE_USER_CUSTOMER_HITTED:
    "user-customer/SET_IS_UPDATE_USER_CUSTOMER_HITTED",
  SET_IS_ADD_MARKET_USER_CUSTOMER_HITTED:
    "user-customer/SET_IS_ADD_MARKET_USER_CUSTOMER_HITTED",
  SET_IS_REMOVE_MARKET_USER_CUSTOMER_HITTED:
    "user-customer/SET_IS_REMOVE_MARKET_USER_CUSTOMER_HITTED",

  FETCH_USER_CUSTOMERS_START: "user-customer/FETCH_USER_CUSTOMERS_START",
  FETCH_SEARCH_USER_CUSTOMERS_START:
    "user-customer/FETCH_SEARCH_USER_CUSTOMERS_START",
  FETCH_USER_CUSTOMER_START: "user-customer/FETCH_USER_CUSTOMER_START",
  CREATE_USER_CUSTOMER_START: "user-customer/CREATE_USER_CUSTOMER_START",
  UPDATE_USER_CUSTOMER_START: "user-customer/UPDATE_USER_CUSTOMER_START",
  ADD_MARKET_USER_CUSTOMER_START:
    "user-customer/ADD_MARKET_USER_CUSTOMER_START",
  REMOVE_MARKET_USER_CUSTOMER_START:
    "user-customer/REMOVE_MARKET_USER_CUSTOMER_START",
};

export default USER_CUSTOMER_ACTION_TYPES;
