import { createSelector } from "reselect";

const userMerchantSelector = ({ userMerchant }) => userMerchant;

export const getUserMerchants = createSelector(
  [userMerchantSelector],
  ({ userMerchants }) => userMerchants
);
export const getSelectUserMerchants = createSelector(
  [userMerchantSelector],
  ({ selectUserMerchants }) => selectUserMerchants
);
export const getUserMerchant = createSelector(
  [userMerchantSelector],
  ({ userMerchant }) => userMerchant
);

export const getIsUserMerchantsHasMore = createSelector(
  [userMerchantSelector],
  ({ isUserMerchantsHasMore }) => isUserMerchantsHasMore
);
export const getIsSelectUserMerchantsHasMore = createSelector(
  [userMerchantSelector],
  ({ isSelectUserMerchantsHasMore }) => isSelectUserMerchantsHasMore
);

export const getFetchUserMerchantsSearch = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsSearch }) => fetchUserMerchantsSearch
);
export const getFetchUserMerchantsSort = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsSort }) => fetchUserMerchantsSort
);
export const getFetchUserMerchantsKeyBy = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsKeyBy }) => fetchUserMerchantsKeyBy
);
export const getFetchUserMerchantsPage = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsPage }) => fetchUserMerchantsPage
);
export const getFetchUserMerchantsPerPage = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsPerPage }) => fetchUserMerchantsPerPage
);
export const getFetchUserMerchantsIncludes = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsIncludes }) => fetchUserMerchantsIncludes
);
export const getFetchUserMerchantsFilterMerchantId = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsFilterMerchantId }) => fetchUserMerchantsFilterMerchantId
);
export const getFetchUserMerchantsFilterPermissionMerchantId = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsFilterPermissionMerchantId }) =>
    fetchUserMerchantsFilterPermissionMerchantId
);
export const getFetchUserMerchantsFilterPermissionSubscriberId = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsFilterPermissionSubscriberId }) =>
    fetchUserMerchantsFilterPermissionSubscriberId
);
export const getFetchUserMerchantsFilterMarketId = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsFilterMarketId }) => fetchUserMerchantsFilterMarketId
);
export const getFetchUserMerchantsFilterUsersIds = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsFilterUsersIds }) => fetchUserMerchantsFilterUsersIds
);
export const getFetchUserMerchantsLoading = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsLoading }) => fetchUserMerchantsLoading
);
export const getFetchUserMerchantsSuccess = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsSuccess }) => fetchUserMerchantsSuccess
);
export const getFetchUserMerchantsFailed = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantsFailed }) => fetchUserMerchantsFailed
);

export const getFetchSelectUserMerchantsSearch = createSelector(
  [userMerchantSelector],
  ({ fetchSelectUserMerchantsSearch }) => fetchSelectUserMerchantsSearch
);
export const getFetchSelectUserMerchantsSort = createSelector(
  [userMerchantSelector],
  ({ fetchSelectUserMerchantsSort }) => fetchSelectUserMerchantsSort
);
export const getFetchSelectUserMerchantsKeyBy = createSelector(
  [userMerchantSelector],
  ({ fetchSelectUserMerchantsKeyBy }) => fetchSelectUserMerchantsKeyBy
);
export const getFetchSelectUserMerchantsPage = createSelector(
  [userMerchantSelector],
  ({ fetchSelectUserMerchantsPage }) => fetchSelectUserMerchantsPage
);
export const getFetchSelectUserMerchantsPerPage = createSelector(
  [userMerchantSelector],
  ({ fetchSelectUserMerchantsPerPage }) => fetchSelectUserMerchantsPerPage
);
export const getFetchSelectUserMerchantsIncludes = createSelector(
  [userMerchantSelector],
  ({ fetchSelectUserMerchantsIncludes }) => fetchSelectUserMerchantsIncludes
);
export const getFetchSelectUserMerchantsFilterMerchantId = createSelector(
  [userMerchantSelector],
  ({ fetchSelectUserMerchantsFilterMerchantId }) =>
    fetchSelectUserMerchantsFilterMerchantId
);
export const getFetchSelectUserMerchantsFilterPermissionMerchantId =
  createSelector(
    [userMerchantSelector],
    ({ fetchSelectUserMerchantsFilterPermissionMerchantId }) =>
      fetchSelectUserMerchantsFilterPermissionMerchantId
  );
export const getFetchSelectUserMerchantsFilterPermissionSubscriberId =
  createSelector(
    [userMerchantSelector],
    ({ fetchSelectUserMerchantsFilterPermissionSubscriberId }) =>
      fetchSelectUserMerchantsFilterPermissionSubscriberId
  );
export const getFetchSelectUserMerchantsFilterMarketId = createSelector(
  [userMerchantSelector],
  ({ fetchSelectUserMerchantsFilterMarketId }) =>
    fetchSelectUserMerchantsFilterMarketId
);
export const getFetchSelectUserMerchantsFilterUsersIds = createSelector(
  [userMerchantSelector],
  ({ fetchSelectUserMerchantsFilterUsersIds }) =>
    fetchSelectUserMerchantsFilterUsersIds
);
export const getFetchSelectUserMerchantsLoading = createSelector(
  [userMerchantSelector],
  ({ fetchSelectUserMerchantsLoading }) => fetchSelectUserMerchantsLoading
);
export const getFetchSelectUserMerchantsSuccess = createSelector(
  [userMerchantSelector],
  ({ fetchSelectUserMerchantsSuccess }) => fetchSelectUserMerchantsSuccess
);
export const getFetchSelectUserMerchantsFailed = createSelector(
  [userMerchantSelector],
  ({ fetchSelectUserMerchantsFailed }) => fetchSelectUserMerchantsFailed
);

export const getAppendSelectUserMerchantsSearch = createSelector(
  [userMerchantSelector],
  ({ appendSelectUserMerchantsSearch }) => appendSelectUserMerchantsSearch
);
export const getAppendSelectUserMerchantsSort = createSelector(
  [userMerchantSelector],
  ({ appendSelectUserMerchantsSort }) => appendSelectUserMerchantsSort
);
export const getAppendSelectUserMerchantsKeyBy = createSelector(
  [userMerchantSelector],
  ({ appendSelectUserMerchantsKeyBy }) => appendSelectUserMerchantsKeyBy
);
export const getAppendSelectUserMerchantsPage = createSelector(
  [userMerchantSelector],
  ({ appendSelectUserMerchantsPage }) => appendSelectUserMerchantsPage
);
export const getAppendSelectUserMerchantsPerPage = createSelector(
  [userMerchantSelector],
  ({ appendSelectUserMerchantsPerPage }) => appendSelectUserMerchantsPerPage
);
export const getAppendSelectUserMerchantsIncludes = createSelector(
  [userMerchantSelector],
  ({ appendSelectUserMerchantsIncludes }) => appendSelectUserMerchantsIncludes
);
export const getAppendSelectUserMerchantsFilterMerchantId = createSelector(
  [userMerchantSelector],
  ({ appendSelectUserMerchantsFilterMerchantId }) =>
    appendSelectUserMerchantsFilterMerchantId
);
export const getAppendSelectUserMerchantsFilterPermissionMerchantId =
  createSelector(
    [userMerchantSelector],
    ({ appendSelectUserMerchantsFilterPermissionMerchantId }) =>
      appendSelectUserMerchantsFilterPermissionMerchantId
  );
export const getAppendSelectUserMerchantsFilterPermissionSubscriberId =
  createSelector(
    [userMerchantSelector],
    ({ appendSelectUserMerchantsFilterPermissionSubscriberId }) =>
      appendSelectUserMerchantsFilterPermissionSubscriberId
  );
export const getAppendSelectUserMerchantsFilterMarketId = createSelector(
  [userMerchantSelector],
  ({ appendSelectUserMerchantsFilterMarketId }) =>
    appendSelectUserMerchantsFilterMarketId
);
export const getAppendSelectUserMerchantsFilterUsersIds = createSelector(
  [userMerchantSelector],
  ({ appendSelectUserMerchantsFilterUsersIds }) =>
    appendSelectUserMerchantsFilterUsersIds
);
export const getAppendSelectUserMerchantsLoading = createSelector(
  [userMerchantSelector],
  ({ appendSelectUserMerchantsLoading }) => appendSelectUserMerchantsLoading
);
export const getAppendSelectUserMerchantsSuccess = createSelector(
  [userMerchantSelector],
  ({ appendSelectUserMerchantsSuccess }) => appendSelectUserMerchantsSuccess
);
export const getAppendSelectUserMerchantsFailed = createSelector(
  [userMerchantSelector],
  ({ appendSelectUserMerchantsFailed }) => appendSelectUserMerchantsFailed
);

export const getFetchUserMerchantLoading = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantLoading }) => fetchUserMerchantLoading
);
export const getFetchUserMerchantSuccess = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantSuccess }) => fetchUserMerchantSuccess
);
export const getFetchUserMerchantFailed = createSelector(
  [userMerchantSelector],
  ({ fetchUserMerchantFailed }) => fetchUserMerchantFailed
);

export const getCreateUserMerchantLoading = createSelector(
  [userMerchantSelector],
  ({ createUserMerchantLoading }) => createUserMerchantLoading
);
export const getCreateUserMerchantSuccess = createSelector(
  [userMerchantSelector],
  ({ createUserMerchantSuccess }) => createUserMerchantSuccess
);
export const getCreateUserMerchantFailed = createSelector(
  [userMerchantSelector],
  ({ createUserMerchantFailed }) => createUserMerchantFailed
);

export const getUpdateUserMerchantLoading = createSelector(
  [userMerchantSelector],
  ({ updateUserMerchantLoading }) => updateUserMerchantLoading
);
export const getUpdateUserMerchantSuccess = createSelector(
  [userMerchantSelector],
  ({ updateUserMerchantSuccess }) => updateUserMerchantSuccess
);
export const getUpdateUserMerchantFailed = createSelector(
  [userMerchantSelector],
  ({ updateUserMerchantFailed }) => updateUserMerchantFailed
);

export const getDeleteUserMerchantLoading = createSelector(
  [userMerchantSelector],
  ({ deleteUserMerchantLoading }) => deleteUserMerchantLoading
);
export const getDeleteUserMerchantSuccess = createSelector(
  [userMerchantSelector],
  ({ deleteUserMerchantSuccess }) => deleteUserMerchantSuccess
);
export const getDeleteUserMerchantFailed = createSelector(
  [userMerchantSelector],
  ({ deleteUserMerchantFailed }) => deleteUserMerchantFailed
);

export const getIsFetchUserMerchantsHitted = createSelector(
  [userMerchantSelector],
  ({ isFetchUserMerchantsHitted }) => isFetchUserMerchantsHitted
);
export const getIsFetchSelectUserMerchantsHitted = createSelector(
  [userMerchantSelector],
  ({ isFetchSelectUserMerchantsHitted }) => isFetchSelectUserMerchantsHitted
);
export const getIsAppendSelectUserMerchantsHitted = createSelector(
  [userMerchantSelector],
  ({ isAppendSelectUserMerchantsHitted }) => isAppendSelectUserMerchantsHitted
);
export const getIsFetchUserMerchantHitted = createSelector(
  [userMerchantSelector],
  ({ isFetchUserMerchantHitted }) => isFetchUserMerchantHitted
);
export const getIsCreateUserMerchantHitted = createSelector(
  [userMerchantSelector],
  ({ isCreateUserMerchantHitted }) => isCreateUserMerchantHitted
);
export const getIsUpdateUserMerchantHitted = createSelector(
  [userMerchantSelector],
  ({ isUpdateUserMerchantHitted }) => isUpdateUserMerchantHitted
);
export const getIsDeleteUserMerchantHitted = createSelector(
  [userMerchantSelector],
  ({ isDeleteUserMerchantHitted }) => isDeleteUserMerchantHitted
);
