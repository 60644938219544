import { takeLatest, put, all, call, select } from "redux-saga/effects";

import DRIVER_ACTION_TYPES from "./driver.type";

import {
  appendDrivers,
  setCreateDriver,
  setCreateDriverFailed,
  setCreateDriverLoading,
  setCreateDriverSuccess,
  setDriver,
  setDrivers,
  setFetchDriverFailed,
  setFetchDriverLoading,
  setFetchDriversFailed,
  setFetchDriversLoading,
  setFetchDriversPage,
  setFetchDriversSuccess,
  setFetchDriverSuccess,
  setIsCreateDriverHitted,
  setIsDriversHasMore,
  setIsFetchDriverHitted,
  setIsFetchDriversHitted,
  setIsUpdateDriverHitted,
  setUpdateDriver,
  setUpdateDriverFailed,
  setUpdateDriverLoading,
  setUpdateDriverSuccess,
} from "./driver.action";
import {
  getFetchDriversFilterGender,
  getFetchDriversFilterMarketId,
  getFetchDriversFilterStatus,
  getFetchDriversFilterVehicleType,
  getFetchDriversIncludes,
  getFetchDriversPage,
  getFetchDriversPerPage,
  getFetchDriversSearch,
  getIsFetchDriversHitted,
} from "./driver.selector";

import {
  createDriver,
  getDriver,
  getDrivers,
  updateDriver,
} from "../../api/driver.api";

export function* _getDrivers() {
  try {
    yield put(setFetchDriversLoading(true));

    const search = yield select(getFetchDriversSearch);
    const page = yield select(getFetchDriversPage);
    const per_page = yield select(getFetchDriversPerPage);
    const includes = yield select(getFetchDriversIncludes);
    const market_id = yield select(getFetchDriversFilterMarketId);
    const gender = yield select(getFetchDriversFilterGender);
    const status = yield select(getFetchDriversFilterStatus);
    const vehicle_type = yield select(getFetchDriversFilterVehicleType);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        gender,
        status,
        vehicle_type,
      },
    };

    const {
      meta: { message },
      data: { data: drivers },
    } = yield call(getDrivers, parameters);

    yield put(setIsFetchDriversHitted(true));
    yield put(setIsDriversHasMore(drivers.length > 0));

    if (page > 1) {
      yield put(appendDrivers(drivers));
    } else {
      yield put(setDrivers(drivers));
    }

    yield put(setFetchDriversSuccess(message));
    yield put(setFetchDriversLoading(false));
  } catch (error) {
    yield put(setFetchDriversFailed(error));
    yield put(setFetchDriversLoading(false));
  }
}
export function* _getDriver({ payload: driverId }) {
  try {
    yield put(setFetchDriverLoading(true));

    const {
      meta: { message },
      data: driver,
    } = yield call(getDriver, driverId);

    yield put(setIsFetchDriverHitted(true));
    yield put(setDriver(driver));

    yield put(setFetchDriverSuccess(message));
    yield put(setFetchDriverLoading(false));
  } catch (error) {
    yield put(setFetchDriverFailed(error));
    yield put(setFetchDriverLoading(false));
  }
}
export function* _createDriver({ payload: request }) {
  try {
    yield put(setCreateDriverLoading(true));

    const {
      meta: { message },
      data: driver,
    } = yield call(createDriver, request);

    yield put(setIsCreateDriverHitted(true));
    yield put(setCreateDriver(driver));

    const isFetchDriversHitted = yield select(getIsFetchDriversHitted);

    if (isFetchDriversHitted) {
      yield put(setFetchDriversPage(1));
      yield call(_getDrivers);
    }

    yield put(setCreateDriverSuccess(message));
    yield put(setCreateDriverLoading(false));
  } catch (error) {
    yield put(setCreateDriverFailed(error));
    yield put(setCreateDriverLoading(false));
  }
}
export function* _updateDriver({ payload: { driverId, request } }) {
  try {
    yield put(setUpdateDriverLoading(true));

    const {
      meta: { message },
      data: driver,
    } = yield call(updateDriver, driverId, request);

    yield put(setIsUpdateDriverHitted(true));
    yield put(setUpdateDriver(driver));

    const isFetchDriversHitted = yield select(getIsFetchDriversHitted);

    if (isFetchDriversHitted) {
      yield put(setFetchDriversPage(1));
      yield call(_getDrivers);
    }

    yield put(setUpdateDriverSuccess(message));
    yield put(setUpdateDriverLoading(false));
  } catch (error) {
    yield put(setUpdateDriverFailed(error));
    yield put(setUpdateDriverLoading(false));
  }
}

export function* onFetchDriversStart() {
  yield takeLatest(DRIVER_ACTION_TYPES.FETCH_DRIVERS_START, _getDrivers);
}
export function* onFetchDriverStart() {
  yield takeLatest(DRIVER_ACTION_TYPES.FETCH_DRIVER_START, _getDriver);
}
export function* onCreateDriverStart() {
  yield takeLatest(DRIVER_ACTION_TYPES.CREATE_DRIVER_START, _createDriver);
}
export function* onUpdateDriverStart() {
  yield takeLatest(DRIVER_ACTION_TYPES.UPDATE_DRIVER_START, _updateDriver);
}

export function* driverSaga() {
  yield all([
    call(onFetchDriversStart),
    call(onFetchDriverStart),
    call(onCreateDriverStart),
    call(onUpdateDriverStart),
  ]);
}
