import GATEWAY_ACTION_TYPES from "./gateway.type";

export const GATEWAY_INITIAL_STATE = {
  gateway: null,
  createGateway: null,
  checkoutGateway: null,

  fetchGatewayLoading: false,
  fetchGatewaySuccess: null,
  fetchGatewayFailed: null,

  createGatewayLoading: false,
  createGatewaySuccess: null,
  createGatewayFailed: null,

  checkoutGatewayLoading: false,
  checkoutGatewaySuccess: null,
  checkoutGatewayFailed: null,

  isFetchGatewayHitted: false,
  isCreateGatewayHitted: false,
  isCheckoutGatewayHitted: false,
};

export const gatewayReducer = (state = GATEWAY_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GATEWAY_ACTION_TYPES.SET_GATEWAY:
      return { ...state, gateway: payload };
    case GATEWAY_ACTION_TYPES.SET_CREATE_GATEWAY:
      return { ...state, createGateway: payload };
    case GATEWAY_ACTION_TYPES.SET_CHECKOUT_GATEWAY:
      return { ...state, checkoutGateway: payload };

    case GATEWAY_ACTION_TYPES.SET_FETCH_GATEWAY_LOADING:
      return { ...state, fetchGatewayLoading: payload };
    case GATEWAY_ACTION_TYPES.SET_FETCH_GATEWAY_SUCCESS:
      return { ...state, fetchGatewaySuccess: payload };
    case GATEWAY_ACTION_TYPES.SET_FETCH_GATEWAY_FAILED:
      return { ...state, fetchGatewayFailed: payload };

    case GATEWAY_ACTION_TYPES.SET_CREATE_GATEWAY_LOADING:
      return { ...state, createGatewayLoading: payload };
    case GATEWAY_ACTION_TYPES.SET_CREATE_GATEWAY_SUCCESS:
      return { ...state, createGatewaySuccess: payload };
    case GATEWAY_ACTION_TYPES.SET_CREATE_GATEWAY_FAILED:
      return { ...state, createGatewayFailed: payload };

    case GATEWAY_ACTION_TYPES.SET_CHECKOUT_GATEWAY_LOADING:
      return { ...state, checkoutGatewayLoading: payload };
    case GATEWAY_ACTION_TYPES.SET_CHECKOUT_GATEWAY_SUCCESS:
      return { ...state, checkoutGatewaySuccess: payload };
    case GATEWAY_ACTION_TYPES.SET_CHECKOUT_GATEWAY_FAILED:
      return { ...state, checkoutGatewayFailed: payload };

    case GATEWAY_ACTION_TYPES.SET_IS_FETCH_GATEWAY_HITTED:
      return { ...state, isFetchGatewayHitted: payload };
    case GATEWAY_ACTION_TYPES.SET_IS_CREATE_GATEWAY_HITTED:
      return { ...state, isCreateGatewayHitted: payload };
    case GATEWAY_ACTION_TYPES.SET_IS_CHECKOUT_GATEWAY_HITTED:
      return { ...state, isCheckoutGatewayHitted: payload };

    case GATEWAY_ACTION_TYPES.RESET_GATEWAY_REDUCER:
      return GATEWAY_INITIAL_STATE;
    default:
      return state;
  }
};
