import { createAction } from "../../utils/store.utils";

import STATE_SUBSCRIPTION_ACTION_TYPES from "./state-subscription.type";

export const setSubscriptionAdminTab = (subscriptionAdminTab) =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTION_ADMIN_TAB,
    subscriptionAdminTab
  );
export const setSubscriptionSubscriberTab = (subscriptionSubscriberTab) =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTION_SUBSCRIBER_TAB,
    subscriptionSubscriberTab
  );

export const setBillingBranchesIds = (billingBranchesIds) =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_BRANCHES_IDS,
    billingBranchesIds
  );
export const setBillingPlans = (billingPlans) =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_PLANS, billingPlans);
export const setBillingPlan = (billingPlan) =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_PLAN, billingPlan);
export const setBillingCycle = (billingCycle) =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_CYCLE, billingCycle);

export const resetStateSubscriptionReducer = () =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.RESET_STATE_SUBSCRIPTION_REDUCER
  );
