import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PDF_ACTION_TYPES from "./pdf.type";

import {
  setExtendPeriodReceipt,
  setIsPdfExtendPeriodReceiptHitted,
  setIsPdfOrderNotesHitted,
  setIsPdfOrderReceiptHitted,
  setOrderNotes,
  setOrderReceipt,
  setPdfExtendPeriodReceiptFailed,
  setPdfExtendPeriodReceiptLoading,
  setPdfExtendPeriodReceiptSuccess,
  setPdfOrderNotesFailed,
  setPdfOrderNotesLoading,
  setPdfOrderNotesSuccess,
  setPdfOrderReceiptFailed,
  setPdfOrderReceiptLoading,
  setPdfOrderReceiptSuccess,
} from "./pdf.action";
import { getPdfOrderNotesIsMultiLang, getPdfOrderReceiptIsMultiLang } from "./pdf.selector";

import { getExtendPeriodReceipt, getOrderNotes, getOrderReceipt } from "../../api/pdf.api";

export function* _getPdfOrderReceipt({ payload: id }) {
  try {
    yield put(setPdfOrderReceiptLoading(true));

    const is_multi_lang = yield select(getPdfOrderReceiptIsMultiLang);

    const { config, headers, data } = yield call(getOrderReceipt, id, { is_multi_lang });
    const match = headers?.get("content-disposition")?.match(/filename="(.+)"/);
    const filename = match ? match?.[1] : null;

    const orderReceipt = {
      filename,
      url: config?.url,
      file: new Blob([data]),
    };

    yield put(setIsPdfOrderReceiptHitted(true));
    yield put(setOrderReceipt(orderReceipt));

    yield put(setPdfOrderReceiptSuccess("Export receipt success"));
    yield put(setPdfOrderReceiptLoading(false));
  } catch (error) {
    yield put(setPdfOrderReceiptFailed(error));
    yield put(setPdfOrderReceiptLoading(false));
  }
}

export function* _getPdfOrderNotes({ payload: id }) {
  try {
    yield put(setPdfOrderNotesLoading(true));

    const is_multi_lang = yield select(getPdfOrderNotesIsMultiLang);

    const { config, headers, data } = yield call(getOrderNotes, id, { is_multi_lang });
    const match = headers?.get("content-disposition")?.match(/filename="(.+)"/);
    const filename = match ? match?.[1] : null;

    const orderNotes = {
      filename,
      url: config?.url,
      file: new Blob([data]),
    };

    yield put(setIsPdfOrderNotesHitted(true));
    yield put(setOrderNotes(orderNotes));

    yield put(setPdfOrderNotesSuccess("Export notes success"));
    yield put(setPdfOrderNotesLoading(false));
  } catch (error) {
    yield put(setPdfOrderNotesFailed(error));
    yield put(setPdfOrderNotesLoading(false));
  }
}

export function* _getPdfExtendPeriodReceipt({ payload: id }) {
  try {
    yield put(setPdfExtendPeriodReceiptLoading(true));

    const { config, headers, data } = yield call(getExtendPeriodReceipt, id);
    const match = headers?.get("content-disposition")?.match(/filename="(.+)"/);
    const filename = match ? match?.[1] : null;

    const extendPeriodReceipt = {
      filename,
      url: config?.url,
      file: new Blob([data]),
    };

    yield put(setIsPdfExtendPeriodReceiptHitted(true));
    yield put(setExtendPeriodReceipt(extendPeriodReceipt));

    yield put(setPdfExtendPeriodReceiptSuccess("Export receipt success"));
    yield put(setPdfExtendPeriodReceiptLoading(false));
  } catch (error) {
    yield put(setPdfExtendPeriodReceiptFailed(error));
    yield put(setPdfExtendPeriodReceiptLoading(false));
  }
}

export function* onPdfOrderReceiptStart() {
  yield takeLatest(PDF_ACTION_TYPES.EXPORT_ORDER_RECEIPT_START, _getPdfOrderReceipt);
}

export function* onPdfOrderNotesStart() {
  yield takeLatest(PDF_ACTION_TYPES.EXPORT_ORDER_NOTES_START, _getPdfOrderNotes);
}

export function* onPdfExtendPeriodReceiptStart() {
  yield takeLatest(PDF_ACTION_TYPES.EXPORT_EXTEND_PERIOD_RECEIPT_START, _getPdfExtendPeriodReceipt);
}

export function* pdfSaga() {
  yield all([call(onPdfOrderReceiptStart), call(onPdfOrderNotesStart), call(onPdfExtendPeriodReceiptStart)]);
}
