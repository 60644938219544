import { takeLatest, put, all, call, select } from "redux-saga/effects";

import INTEGRATION_ACTION_TYPES from "./integration.type";

import {
  appendIntegrations,
  setIntegration,
  setFetchIntegrationFailed,
  setFetchIntegrationLoading,
  setFetchIntegrationSuccess,
  setFetchIntegrationsFailed,
  setFetchIntegrationsLoading,
  setFetchIntegrationsPage,
  setFetchIntegrationsSuccess,
  setIsIntegrationsHasMore,
  setIsFetchIntegrationHitted,
  setIsFetchIntegrationsHitted,
  setCreateOrUpdateIntegrationLoading,
  setIsCreateOrUpdateIntegrationHitted,
  setCreateOrUpdateIntegrationSuccess,
  setCreateOrUpdateIntegrationFailed,
  setFetchQoyodStatusLoading,
  setFetchQoyodStatusSuccess,
  setFetchQoyodStatusFailed,
  setIsFetchQoyodStatusHitted,
  setQoyodStatus,
  setSyncQoyodLoading,
  setIsSyncQoyodHitted,
  setQoyodSync,
  setSyncQoyodSuccess,
  setSyncQoyodFailed,
  setFetchGrubtechStatusLoading,
  setIsFetchGrubtechStatusHitted,
  setGrubtechStatus,
  setFetchGrubtechStatusSuccess,
  setFetchGrubtechStatusFailed,
  setSyncGrubtechLoading,
  setIsSyncGrubtechHitted,
  setGrubtechSync,
  setSyncGrubtechSuccess,
  setSyncGrubtechFailed,
  setFetchBallurhStatusLoading,
  setIsFetchBallurhStatusHitted,
  setBallurhStatus,
  setFetchBallurhStatusSuccess,
  setFetchBallurhStatusFailed,
  setGenerateBallurhLoading,
  setIsGenerateBallurhHitted,
  setBallurhGenerate,
  setGenerateBallurhSuccess,
  setGenerateBallurhFailed,
  setSyncBallurhLoading,
  setIsSyncBallurhHitted,
  setBallurhSync,
  setSyncBallurhSuccess,
  setSyncBallurhFailed,
  setFetchZatcaStatusLoading,
  setIsFetchZatcaStatusHitted,
  setZatcaStatus,
  setFetchZatcaStatusSuccess,
  setFetchZatcaStatusFailed,
  setSyncZatcaLoading,
  setIsSyncZatcaHitted,
  setZatcaSync,
  setSyncZatcaSuccess,
  setSyncZatcaFailed,
  setIntegrations,
  setRegisterZatcaLoading,
  setIsRegisterZatcaHitted,
  setZatcaRegister,
  setRegisterZatcaSuccess,
  setRegisterZatcaFailed,
} from "./integration.action";
import {
  getFetchIntegrationsFilterBranchId,
  getFetchIntegrationsFilterKeys,
  getFetchIntegrationsFilterMarketId,
  getFetchIntegrationsIncludes,
  getFetchIntegrationsKeyBy,
  getFetchIntegrationsPage,
  getFetchIntegrationsPerPage,
  getFetchIntegrationsSort,
  getIsFetchIntegrationsHitted,
} from "./integration.selector";

import {
  createOrUpdateIntegration,
  generateBallurh,
  getBallurhStatus,
  getGrubtechStatus,
  getIntegration,
  getIntegrations,
  getQoyodStatus,
  getZatcaStatus,
  registerZatca,
  syncBallurh,
  syncGrubtech,
  syncQoyod,
  syncZatca,
} from "../../api/integration.api";

export function* _getIntegrations() {
  try {
    yield put(setFetchIntegrationsLoading(true));

    const sort = yield select(getFetchIntegrationsSort);
    const key_by = yield select(getFetchIntegrationsKeyBy);
    const page = yield select(getFetchIntegrationsPage);
    const per_page = yield select(getFetchIntegrationsPerPage);
    const includes = yield select(getFetchIntegrationsIncludes);
    const market_id = yield select(getFetchIntegrationsFilterMarketId);
    const branch_id = yield select(getFetchIntegrationsFilterBranchId);
    const keys = yield select(getFetchIntegrationsFilterKeys);

    const parameters = {
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id, branch_id, keys },
    };

    const {
      meta: { message },
      data: { data: integrations },
    } = yield call(getIntegrations, parameters);

    yield put(setIsFetchIntegrationsHitted(true));
    yield put(setIsIntegrationsHasMore(integrations.length > 0));

    if (page > 1) {
      yield put(appendIntegrations(integrations));
    } else {
      yield put(setIntegrations(integrations));
    }

    yield put(setFetchIntegrationsSuccess(message));
    yield put(setFetchIntegrationsLoading(false));
  } catch (error) {
    yield put(setFetchIntegrationsFailed(error));
    yield put(setFetchIntegrationsLoading(false));
  }
}
export function* _getIntegration({ payload: integrationId }) {
  try {
    yield put(setFetchIntegrationLoading(true));

    const {
      meta: { message },
      data: integration,
    } = yield call(getIntegration, integrationId);

    yield put(setIsFetchIntegrationHitted(true));
    yield put(setIntegration(integration));

    yield put(setFetchIntegrationSuccess(message));
    yield put(setFetchIntegrationLoading(false));
  } catch (error) {
    yield put(setFetchIntegrationFailed(error));
    yield put(setFetchIntegrationLoading(false));
  }
}
export function* _createOrUpdateIntegration({ payload: request }) {
  try {
    yield put(setCreateOrUpdateIntegrationLoading(true));

    const {
      meta: { message },
    } = yield call(createOrUpdateIntegration, request);

    yield put(setIsCreateOrUpdateIntegrationHitted(true));

    const isFetchIntegrationsHitted = yield select(
      getIsFetchIntegrationsHitted
    );

    if (isFetchIntegrationsHitted) {
      yield put(setFetchIntegrationsPage(1));
      yield call(_getIntegrations);
    }

    yield put(setCreateOrUpdateIntegrationSuccess(message));
    yield put(setCreateOrUpdateIntegrationLoading(false));
  } catch (error) {
    yield put(setCreateOrUpdateIntegrationFailed(error));
    yield put(setCreateOrUpdateIntegrationLoading(false));
  }
}
export function* _getQoyodStatus({ payload: parameters }) {
  try {
    yield put(setFetchQoyodStatusLoading(true));

    const {
      meta: { message },
      data: qoyodStatus,
    } = yield call(getQoyodStatus, parameters);

    yield put(setIsFetchQoyodStatusHitted(true));
    yield put(setQoyodStatus(qoyodStatus));

    yield put(setFetchQoyodStatusSuccess(message));
    yield put(setFetchQoyodStatusLoading(false));
  } catch (error) {
    yield put(setFetchQoyodStatusFailed(error));
    yield put(setFetchQoyodStatusLoading(false));
  }
}
export function* _getSyncQoyod({ payload: request }) {
  try {
    yield put(setSyncQoyodLoading(true));

    const {
      meta: { message },
      data: qoyodSync,
    } = yield call(syncQoyod, request);

    yield put(setIsSyncQoyodHitted(true));
    yield put(setQoyodSync(qoyodSync));

    yield put(setSyncQoyodSuccess(message));
    yield put(setSyncQoyodLoading(false));
  } catch (error) {
    yield put(setSyncQoyodFailed(error));
    yield put(setSyncQoyodLoading(false));
  }
}
export function* _getGrubtechStatus({ payload: parameters }) {
  try {
    yield put(setFetchGrubtechStatusLoading(true));

    const {
      meta: { message },
      data: grubtechStatus,
    } = yield call(getGrubtechStatus, parameters);

    yield put(setIsFetchGrubtechStatusHitted(true));
    yield put(setGrubtechStatus(grubtechStatus));

    yield put(setFetchGrubtechStatusSuccess(message));
    yield put(setFetchGrubtechStatusLoading(false));
  } catch (error) {
    yield put(setFetchGrubtechStatusFailed(error));
    yield put(setFetchGrubtechStatusLoading(false));
  }
}
export function* _getSyncGrubtech({ payload: request }) {
  try {
    yield put(setSyncGrubtechLoading(true));

    const {
      meta: { message },
      data: grubtechSync,
    } = yield call(syncGrubtech, request);

    yield put(setIsSyncGrubtechHitted(true));
    yield put(setGrubtechSync(grubtechSync));

    yield put(setSyncGrubtechSuccess(message));
    yield put(setSyncGrubtechLoading(false));
  } catch (error) {
    yield put(setSyncGrubtechFailed(error));
    yield put(setSyncGrubtechLoading(false));
  }
}
export function* _getBallurhStatus({ payload: parameters }) {
  try {
    yield put(setFetchBallurhStatusLoading(true));

    const {
      meta: { message },
      data: ballurhStatus,
    } = yield call(getBallurhStatus, parameters);

    yield put(setIsFetchBallurhStatusHitted(true));
    yield put(setBallurhStatus(ballurhStatus));

    yield put(setFetchBallurhStatusSuccess(message));
    yield put(setFetchBallurhStatusLoading(false));
  } catch (error) {
    yield put(setFetchBallurhStatusFailed(error));
    yield put(setFetchBallurhStatusLoading(false));
  }
}
export function* _getGenerateBallurh({ payload: request }) {
  try {
    yield put(setGenerateBallurhLoading(true));

    const {
      meta: { message },
      data: ballurhGenerate,
    } = yield call(generateBallurh, request);

    yield put(setIsGenerateBallurhHitted(true));
    yield put(setBallurhGenerate(ballurhGenerate));

    yield put(setGenerateBallurhSuccess(message));
    yield put(setGenerateBallurhLoading(false));
  } catch (error) {
    yield put(setGenerateBallurhFailed(error));
    yield put(setGenerateBallurhLoading(false));
  }
}
export function* _getSyncBallurh({ payload: request }) {
  try {
    yield put(setSyncBallurhLoading(true));

    const {
      meta: { message },
      data: ballurhSync,
    } = yield call(syncBallurh, request);

    yield put(setIsSyncBallurhHitted(true));
    yield put(setBallurhSync(ballurhSync));

    yield put(setSyncBallurhSuccess(message));
    yield put(setSyncBallurhLoading(false));
  } catch (error) {
    yield put(setSyncBallurhFailed(error));
    yield put(setSyncBallurhLoading(false));
  }
}
export function* _getZatcaStatus({ payload: parameters }) {
  try {
    yield put(setFetchZatcaStatusLoading(true));

    const zatcaStatus = yield call(getZatcaStatus, parameters);

    yield put(setIsFetchZatcaStatusHitted(true));
    yield put(setZatcaStatus(zatcaStatus));

    yield put(setFetchZatcaStatusSuccess("Data Loaded"));
    yield put(setFetchZatcaStatusLoading(false));
  } catch (error) {
    yield put(setFetchZatcaStatusFailed(error));
    yield put(setFetchZatcaStatusLoading(false));
  }
}
export function* _getSyncZatca({ payload: request }) {
  try {
    yield put(setSyncZatcaLoading(true));

    const zatcaSync = yield call(syncZatca, request);

    yield put(setIsSyncZatcaHitted(true));
    yield put(setZatcaSync(zatcaSync));

    yield put(setSyncZatcaSuccess("Sent Success"));
    yield put(setSyncZatcaLoading(false));
  } catch (error) {
    yield put(setSyncZatcaFailed(error));
    yield put(setSyncZatcaLoading(false));
  }
}
export function* _getRegisterZatca({ payload: request }) {
  try {
    yield put(setRegisterZatcaLoading(true));

    const zatcaRegister = yield call(registerZatca, request);

    yield put(setIsRegisterZatcaHitted(true));
    yield put(setZatcaRegister(zatcaRegister));

    yield put(setRegisterZatcaSuccess("Register Success"));
    yield put(setRegisterZatcaLoading(false));
  } catch (error) {
    yield put(setRegisterZatcaFailed(error));
    yield put(setRegisterZatcaLoading(false));
  }
}

export function* onFetchIntegrationsStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_INTEGRATIONS_START,
    _getIntegrations
  );
}
export function* onFetchIntegrationStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_INTEGRATION_START,
    _getIntegration
  );
}
export function* onCreateOrUpdateIntegrationStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.CREATE_OR_UPDATE_INTEGRATION_START,
    _createOrUpdateIntegration
  );
}
export function* onFetchQoyodStatusStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_QOYOD_STATUS_START,
    _getQoyodStatus
  );
}
export function* onSyncQoyodStart() {
  yield takeLatest(INTEGRATION_ACTION_TYPES.SYNC_QOYOD_START, _getSyncQoyod);
}
export function* onFetchGrubtechStatusStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_GRUBTECH_STATUS_START,
    _getGrubtechStatus
  );
}
export function* onSyncGrubtechStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.SYNC_GRUBTECH_START,
    _getSyncGrubtech
  );
}
export function* onFetchBallurhStatusStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_BALLURH_STATUS_START,
    _getBallurhStatus
  );
}
export function* onGenerateBallurhStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.GENERATE_BALLURH_START,
    _getGenerateBallurh
  );
}
export function* onSyncBallurhStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.SYNC_BALLURH_START,
    _getSyncBallurh
  );
}
export function* onFetchZatcaStatusStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_ZATCA_STATUS_START,
    _getZatcaStatus
  );
}
export function* onSyncZatcaStart() {
  yield takeLatest(INTEGRATION_ACTION_TYPES.SYNC_ZATCA_START, _getSyncZatca);
}
export function* onRegisterZatcaStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.REGISTER_ZATCA_START,
    _getRegisterZatca
  );
}

export function* integrationSaga() {
  yield all([
    call(onFetchIntegrationsStart),
    call(onFetchIntegrationStart),
    call(onCreateOrUpdateIntegrationStart),
    call(onFetchQoyodStatusStart),
    call(onSyncQoyodStart),
    call(onFetchGrubtechStatusStart),
    call(onSyncGrubtechStart),
    call(onFetchBallurhStatusStart),
    call(onGenerateBallurhStart),
    call(onSyncBallurhStart),
    call(onFetchZatcaStatusStart),
    call(onSyncZatcaStart),
    call(onRegisterZatcaStart),
  ]);
}
