import { createSelector } from "reselect";

const permissionAdminSelector = ({ permissionAdmin }) => permissionAdmin;

export const getPermissionTab = createSelector(
  [permissionAdminSelector],
  ({ permissionTab }) => permissionTab
);
export const getPermissionTypes = createSelector(
  [permissionAdminSelector],
  ({ permissionTypes }) => permissionTypes
);
export const getPermissionAdmins = createSelector(
  [permissionAdminSelector],
  ({ permissionAdmins }) => permissionAdmins
);
export const getPermissionSubscribers = createSelector(
  [permissionAdminSelector],
  ({ permissionSubscribers }) => permissionSubscribers
);
export const getSelectPermissionAdmins = createSelector(
  [permissionAdminSelector],
  ({ selectPermissionAdmins }) => selectPermissionAdmins
);
export const getSelectPermissionSubscribers = createSelector(
  [permissionAdminSelector],
  ({ selectPermissionSubscribers }) => selectPermissionSubscribers
);
export const getPermission = createSelector(
  [permissionAdminSelector],
  ({ permission }) => permission
);

export const getIsPermissionAdminsHasMore = createSelector(
  [permissionAdminSelector],
  ({ isPermissionAdminsHasMore }) => isPermissionAdminsHasMore
);
export const getIsPermissionSubscribersHasMore = createSelector(
  [permissionAdminSelector],
  ({ isPermissionSubscribersHasMore }) => isPermissionSubscribersHasMore
);
export const getIsSelectPermissionAdminsHasMore = createSelector(
  [permissionAdminSelector],
  ({ isSelectPermissionAdminsHasMore }) => isSelectPermissionAdminsHasMore
);
export const getIsSelectPermissionSubscribersHasMore = createSelector(
  [permissionAdminSelector],
  ({ isSelectPermissionSubscribersHasMore }) =>
    isSelectPermissionSubscribersHasMore
);

export const getFetchPermissionAdminsSearch = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionAdminsSearch }) => fetchPermissionAdminsSearch
);
export const getFetchPermissionAdminsSort = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionAdminsSort }) => fetchPermissionAdminsSort
);
export const getFetchPermissionAdminsKeyBy = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionAdminsKeyBy }) => fetchPermissionAdminsKeyBy
);
export const getFetchPermissionAdminsPage = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionAdminsPage }) => fetchPermissionAdminsPage
);
export const getFetchPermissionAdminsPerPage = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionAdminsPerPage }) => fetchPermissionAdminsPerPage
);
export const getFetchPermissionAdminsIncludes = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionAdminsIncludes }) => fetchPermissionAdminsIncludes
);
export const getFetchPermissionAdminsFilterIsDefault = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionAdminsFilterIsDefault }) =>
    fetchPermissionAdminsFilterIsDefault
);
export const getFetchPermissionAdminsLoading = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionAdminsLoading }) => fetchPermissionAdminsLoading
);
export const getFetchPermissionAdminsSuccess = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionAdminsSuccess }) => fetchPermissionAdminsSuccess
);
export const getFetchPermissionAdminsFailed = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionAdminsFailed }) => fetchPermissionAdminsFailed
);

export const getFetchPermissionSubscribersSearch = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionSubscribersSearch }) => fetchPermissionSubscribersSearch
);
export const getFetchPermissionSubscribersSort = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionSubscribersSort }) => fetchPermissionSubscribersSort
);
export const getFetchPermissionSubscribersKeyBy = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionSubscribersKeyBy }) => fetchPermissionSubscribersKeyBy
);
export const getFetchPermissionSubscribersPage = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionSubscribersPage }) => fetchPermissionSubscribersPage
);
export const getFetchPermissionSubscribersPerPage = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionSubscribersPerPage }) => fetchPermissionSubscribersPerPage
);
export const getFetchPermissionSubscribersIncludes = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionSubscribersIncludes }) => fetchPermissionSubscribersIncludes
);
export const getFetchPermissionSubscribersFilterIsDefault = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionSubscribersFilterIsDefault }) =>
    fetchPermissionSubscribersFilterIsDefault
);
export const getFetchPermissionSubscribersLoading = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionSubscribersLoading }) => fetchPermissionSubscribersLoading
);
export const getFetchPermissionSubscribersSuccess = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionSubscribersSuccess }) => fetchPermissionSubscribersSuccess
);
export const getFetchPermissionSubscribersFailed = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionSubscribersFailed }) => fetchPermissionSubscribersFailed
);

export const getFetchSelectPermissionAdminsSearch = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionAdminsSearch }) => fetchSelectPermissionAdminsSearch
);
export const getFetchSelectPermissionAdminsSort = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionAdminsSort }) => fetchSelectPermissionAdminsSort
);
export const getFetchSelectPermissionAdminsKeyBy = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionAdminsKeyBy }) => fetchSelectPermissionAdminsKeyBy
);
export const getFetchSelectPermissionAdminsPage = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionAdminsPage }) => fetchSelectPermissionAdminsPage
);
export const getFetchSelectPermissionAdminsPerPage = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionAdminsPerPage }) => fetchSelectPermissionAdminsPerPage
);
export const getFetchSelectPermissionAdminsIncludes = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionAdminsIncludes }) =>
    fetchSelectPermissionAdminsIncludes
);
export const getFetchSelectPermissionAdminsFilterPermissionsIds =
  createSelector(
    [permissionAdminSelector],
    ({ fetchSelectPermissionAdminsFilterPermissionsIds }) =>
      fetchSelectPermissionAdminsFilterPermissionsIds
  );
export const getFetchSelectPermissionAdminsFilterIsDefault = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionAdminsFilterIsDefault }) =>
    fetchSelectPermissionAdminsFilterIsDefault
);
export const getFetchSelectPermissionAdminsLoading = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionAdminsLoading }) => fetchSelectPermissionAdminsLoading
);
export const getFetchSelectPermissionAdminsSuccess = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionAdminsSuccess }) => fetchSelectPermissionAdminsSuccess
);
export const getFetchSelectPermissionAdminsFailed = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionAdminsFailed }) => fetchSelectPermissionAdminsFailed
);

export const getFetchSelectPermissionSubscribersSearch = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionSubscribersSearch }) =>
    fetchSelectPermissionSubscribersSearch
);
export const getFetchSelectPermissionSubscribersSort = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionSubscribersSort }) =>
    fetchSelectPermissionSubscribersSort
);
export const getFetchSelectPermissionSubscribersKeyBy = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionSubscribersKeyBy }) =>
    fetchSelectPermissionSubscribersKeyBy
);
export const getFetchSelectPermissionSubscribersPage = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionSubscribersPage }) =>
    fetchSelectPermissionSubscribersPage
);
export const getFetchSelectPermissionSubscribersPerPage = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionSubscribersPerPage }) =>
    fetchSelectPermissionSubscribersPerPage
);
export const getFetchSelectPermissionSubscribersIncludes = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionSubscribersIncludes }) =>
    fetchSelectPermissionSubscribersIncludes
);
export const getFetchSelectPermissionSubscribersFilterPermissionsIds =
  createSelector(
    [permissionAdminSelector],
    ({ fetchSelectPermissionSubscribersFilterPermissionsIds }) =>
      fetchSelectPermissionSubscribersFilterPermissionsIds
  );
export const getFetchSelectPermissionSubscribersFilterIsDefault =
  createSelector(
    [permissionAdminSelector],
    ({ fetchSelectPermissionSubscribersFilterIsDefault }) =>
      fetchSelectPermissionSubscribersFilterIsDefault
  );
export const getFetchSelectPermissionSubscribersLoading = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionSubscribersLoading }) =>
    fetchSelectPermissionSubscribersLoading
);
export const getFetchSelectPermissionSubscribersSuccess = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionSubscribersSuccess }) =>
    fetchSelectPermissionSubscribersSuccess
);
export const getFetchSelectPermissionSubscribersFailed = createSelector(
  [permissionAdminSelector],
  ({ fetchSelectPermissionSubscribersFailed }) =>
    fetchSelectPermissionSubscribersFailed
);

export const getAppendSelectPermissionAdminsSearch = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionAdminsSearch }) => appendSelectPermissionAdminsSearch
);
export const getAppendSelectPermissionAdminsSort = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionAdminsSort }) => appendSelectPermissionAdminsSort
);
export const getAppendSelectPermissionAdminsKeyBy = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionAdminsKeyBy }) => appendSelectPermissionAdminsKeyBy
);
export const getAppendSelectPermissionAdminsPage = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionAdminsPage }) => appendSelectPermissionAdminsPage
);
export const getAppendSelectPermissionAdminsPerPage = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionAdminsPerPage }) =>
    appendSelectPermissionAdminsPerPage
);
export const getAppendSelectPermissionAdminsIncludes = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionAdminsIncludes }) =>
    appendSelectPermissionAdminsIncludes
);
export const getAppendSelectPermissionAdminsFilterPermissionsIds =
  createSelector(
    [permissionAdminSelector],
    ({ appendSelectPermissionAdminsFilterPermissionsIds }) =>
      appendSelectPermissionAdminsFilterPermissionsIds
  );
export const getAppendSelectPermissionAdminsFilterIsDefault = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionAdminsFilterIsDefault }) =>
    appendSelectPermissionAdminsFilterIsDefault
);
export const getAppendSelectPermissionAdminsLoading = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionAdminsLoading }) =>
    appendSelectPermissionAdminsLoading
);
export const getAppendSelectPermissionAdminsSuccess = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionAdminsSuccess }) =>
    appendSelectPermissionAdminsSuccess
);
export const getAppendSelectPermissionAdminsFailed = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionAdminsFailed }) => appendSelectPermissionAdminsFailed
);

export const getAppendSelectPermissionSubscribersSearch = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionSubscribersSearch }) =>
    appendSelectPermissionSubscribersSearch
);
export const getAppendSelectPermissionSubscribersSort = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionSubscribersSort }) =>
    appendSelectPermissionSubscribersSort
);
export const getAppendSelectPermissionSubscribersKeyBy = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionSubscribersKeyBy }) =>
    appendSelectPermissionSubscribersKeyBy
);
export const getAppendSelectPermissionSubscribersPage = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionSubscribersPage }) =>
    appendSelectPermissionSubscribersPage
);
export const getAppendSelectPermissionSubscribersPerPage = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionSubscribersPerPage }) =>
    appendSelectPermissionSubscribersPerPage
);
export const getAppendSelectPermissionSubscribersIncludes = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionSubscribersIncludes }) =>
    appendSelectPermissionSubscribersIncludes
);
export const getAppendSelectPermissionSubscribersFilterPermissionsIds =
  createSelector(
    [permissionAdminSelector],
    ({ appendSelectPermissionSubscribersFilterPermissionsIds }) =>
      appendSelectPermissionSubscribersFilterPermissionsIds
  );
export const getAppendSelectPermissionSubscribersFilterIsDefault =
  createSelector(
    [permissionAdminSelector],
    ({ appendSelectPermissionSubscribersFilterIsDefault }) =>
      appendSelectPermissionSubscribersFilterIsDefault
  );
export const getAppendSelectPermissionSubscribersLoading = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionSubscribersLoading }) =>
    appendSelectPermissionSubscribersLoading
);
export const getAppendSelectPermissionSubscribersSuccess = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionSubscribersSuccess }) =>
    appendSelectPermissionSubscribersSuccess
);
export const getAppendSelectPermissionSubscribersFailed = createSelector(
  [permissionAdminSelector],
  ({ appendSelectPermissionSubscribersFailed }) =>
    appendSelectPermissionSubscribersFailed
);

export const getFetchPermissionLoading = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionLoading }) => fetchPermissionLoading
);
export const getFetchPermissionSuccess = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionSuccess }) => fetchPermissionSuccess
);
export const getFetchPermissionFailed = createSelector(
  [permissionAdminSelector],
  ({ fetchPermissionFailed }) => fetchPermissionFailed
);

export const getCreatePermissionLoading = createSelector(
  [permissionAdminSelector],
  ({ createPermissionLoading }) => createPermissionLoading
);
export const getCreatePermissionSuccess = createSelector(
  [permissionAdminSelector],
  ({ createPermissionSuccess }) => createPermissionSuccess
);
export const getCreatePermissionFailed = createSelector(
  [permissionAdminSelector],
  ({ createPermissionFailed }) => createPermissionFailed
);

export const getUpdatePermissionLoading = createSelector(
  [permissionAdminSelector],
  ({ updatePermissionLoading }) => updatePermissionLoading
);
export const getUpdatePermissionSuccess = createSelector(
  [permissionAdminSelector],
  ({ updatePermissionSuccess }) => updatePermissionSuccess
);
export const getUpdatePermissionFailed = createSelector(
  [permissionAdminSelector],
  ({ updatePermissionFailed }) => updatePermissionFailed
);

export const getDeletePermissionLoading = createSelector(
  [permissionAdminSelector],
  ({ deletePermissionLoading }) => deletePermissionLoading
);
export const getDeletePermissionSuccess = createSelector(
  [permissionAdminSelector],
  ({ deletePermissionSuccess }) => deletePermissionSuccess
);
export const getDeletePermissionFailed = createSelector(
  [permissionAdminSelector],
  ({ deletePermissionFailed }) => deletePermissionFailed
);

export const getIsFetchPermissionAdminsHitted = createSelector(
  [permissionAdminSelector],
  ({ isFetchPermissionAdminsHitted }) => isFetchPermissionAdminsHitted
);
export const getIsFetchPermissionSubscribersHitted = createSelector(
  [permissionAdminSelector],
  ({ isFetchPermissionSubscribersHitted }) => isFetchPermissionSubscribersHitted
);
export const getIsFetchSelectPermissionAdminsHitted = createSelector(
  [permissionAdminSelector],
  ({ isFetchSelectPermissionAdminsHitted }) =>
    isFetchSelectPermissionAdminsHitted
);
export const getIsFetchSelectPermissionSubscribersHitted = createSelector(
  [permissionAdminSelector],
  ({ isFetchSelectPermissionSubscribersHitted }) =>
    isFetchSelectPermissionSubscribersHitted
);
export const getIsAppendSelectPermissionAdminsHitted = createSelector(
  [permissionAdminSelector],
  ({ isAppendSelectPermissionAdminsHitted }) =>
    isAppendSelectPermissionAdminsHitted
);
export const getIsAppendSelectPermissionSubscribersHitted = createSelector(
  [permissionAdminSelector],
  ({ isAppendSelectPermissionSubscribersHitted }) =>
    isAppendSelectPermissionSubscribersHitted
);
export const getIsFetchPermissionHitted = createSelector(
  [permissionAdminSelector],
  ({ isFetchPermissionHitted }) => isFetchPermissionHitted
);
export const getIsCreatePermissionHitted = createSelector(
  [permissionAdminSelector],
  ({ isCreatePermissionHitted }) => isCreatePermissionHitted
);
export const getIsUpdatePermissionHitted = createSelector(
  [permissionAdminSelector],
  ({ isUpdatePermissionHitted }) => isUpdatePermissionHitted
);
export const getIsDeletePermissionHitted = createSelector(
  [permissionAdminSelector],
  ({ isDeletePermissionHitted }) => isDeletePermissionHitted
);
