import apiService from "./api";

export const getReportTotals = async (parameters) =>
  (await apiService.get("/v2/reports/attendances/totals", parameters)).data;
export const getReportAttendances = async (parameters) =>
  (await apiService.get("/v2/reports/attendances", parameters)).data;
export const getReportAttendance = async (attendanceId, parameters) =>
  (await apiService.get(`/v2/reports/attendances/${attendanceId}`, parameters))
    .data;
export const getReportAttendanceTotals = async (attendanceId, parameters) =>
  (
    await apiService.get(
      `/v2/reports/attendances/${attendanceId}/totals`,
      parameters
    )
  ).data;
