import ANNOUNCEMENT_ACTION_TYPES from "./announcement.type";
import { createAction } from "../../utils/store.utils";

export const setAnnouncements = (announcements) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_ANNOUNCEMENTS, announcements);

export const setAnnouncement = (announcement) => createAction(ANNOUNCEMENT_ACTION_TYPES.SET_ANNOUNCEMENT, announcement);

export const setCreateAnnouncement = (announcement) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_CREATE_ANNOUNCEMENT, announcement);

export const setUpdateAnnouncement = (announcement) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_UPDATE_ANNOUNCEMENT, announcement);

export const setIsAnnouncementsHasMore = (isAnnouncementsHasMore) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_IS_ANNOUNCEMENTS_HAS_MORE, isAnnouncementsHasMore);

export const setFetchAnnouncementsSearch = (fetchAnnouncementsSearch) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_SEARCH, fetchAnnouncementsSearch);

export const setFetchAnnouncementsSort = (fetchAnnouncementsSort) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_SORT, fetchAnnouncementsSort);

export const setFetchAnnouncementsPage = (fetchAnnouncementsPage) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_PAGE, fetchAnnouncementsPage);

export const setFetchAnnouncementsPerPage = (fetchAnnouncementsPerPage) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_PER_PAGE, fetchAnnouncementsPerPage);

export const setFetchAnnouncementsFilterDisplayAt = (fetchAnnouncementsFilterDisplayAt) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_FILTER_DISPLAY_AT, fetchAnnouncementsFilterDisplayAt);

export const setFetchAnnouncementsFilterUserId = (fetchAnnouncementsFilterUserId) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_FILTER_USER_ID, fetchAnnouncementsFilterUserId);

export const setFetchAnnouncementsFilterRole = (fetchAnnouncementsFilterRole) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_FILTER_ROLE, fetchAnnouncementsFilterRole);

export const setFetchAnnouncementsFilterIsRead = (fetchAnnouncementsFilterIsRead) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_FILTER_IS_READ, fetchAnnouncementsFilterIsRead);

export const setFetchAnnouncementsLoading = (fetchAnnouncementsLoading) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_LOADING, fetchAnnouncementsLoading);

export const setFetchAnnouncementsSuccess = (fetchAnnouncementsSuccess) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_SUCCESS, fetchAnnouncementsSuccess);

export const setFetchAnnouncementsFailed = (fetchAnnouncementsFailed) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_FAILED, fetchAnnouncementsFailed);

export const setFetchAnnouncementLoading = (fetchAnnouncementLoading) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENT_LOADING, fetchAnnouncementLoading);

export const setFetchAnnouncementSuccess = (fetchAnnouncementSuccess) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENT_SUCCESS, fetchAnnouncementSuccess);

export const setFetchAnnouncementFailed = (fetchAnnouncementFailed) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENT_FAILED, fetchAnnouncementFailed);

export const setCreateAnnouncementLoading = (createAnnouncementLoading) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_CREATE_ANNOUNCEMENT_LOADING, createAnnouncementLoading);

export const setCreateAnnouncementSuccess = (createAnnouncementSuccess) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_CREATE_ANNOUNCEMENT_SUCCESS, createAnnouncementSuccess);

export const setCreateAnnouncementFailed = (createAnnouncementFailed) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_CREATE_ANNOUNCEMENT_FAILED, createAnnouncementFailed);

export const setUpdateAnnouncementLoading = (updateAnnouncementLoading) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_UPDATE_ANNOUNCEMENT_LOADING, updateAnnouncementLoading);

export const setUpdateAnnouncementSuccess = (updateAnnouncementSuccess) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_UPDATE_ANNOUNCEMENT_SUCCESS, updateAnnouncementSuccess);

export const setUpdateAnnouncementFailed = (updateAnnouncementFailed) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_UPDATE_ANNOUNCEMENT_FAILED, updateAnnouncementFailed);

export const setDeleteAnnouncementLoading = (deleteAnnouncementLoading) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_DELETE_ANNOUNCEMENT_LOADING, deleteAnnouncementLoading);

export const setDeleteAnnouncementSuccess = (deleteAnnouncementSuccess) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_DELETE_ANNOUNCEMENT_SUCCESS, deleteAnnouncementSuccess);

export const setDeleteAnnouncementFailed = (deleteAnnouncementFailed) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_DELETE_ANNOUNCEMENT_FAILED, deleteAnnouncementFailed);

export const setReadAnnouncementLoading = (readAnnouncementLoading) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_READ_ANNOUNCEMENT_LOADING, readAnnouncementLoading);

export const setReadAnnouncementSuccess = (readAnnouncementSuccess) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_READ_ANNOUNCEMENT_SUCCESS, readAnnouncementSuccess);

export const setReadAnnouncementFailed = (readAnnouncementFailed) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_READ_ANNOUNCEMENT_FAILED, readAnnouncementFailed);

export const appendAnnouncements = (announcements) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.APPEND_ANNOUNCEMENTS, announcements);

export const fetchAnnouncementsStart = () => createAction(ANNOUNCEMENT_ACTION_TYPES.FETCH_ANNOUNCEMENTS_START);

export const fetchAnnouncementStart = (id) => createAction(ANNOUNCEMENT_ACTION_TYPES.FETCH_ANNOUNCEMENT_START, id);

export const createAnnouncementStart = (request) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.CREATE_ANNOUNCEMENT_START, request);

export const updateAnnouncementStart = (id, request) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.UPDATE_ANNOUNCEMENT_START, { id, request });

export const deleteAnnouncementStart = (id) => createAction(ANNOUNCEMENT_ACTION_TYPES.DELETE_ANNOUNCEMENT_START, id);

export const readAnnouncementStart = (request) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.READ_ANNOUNCEMENT_START, request);

export const setIsFetchAnnouncementsHitted = (isFetchAnnouncementsHitted) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_IS_FETCH_ANNOUNCEMENTS_HITTED, isFetchAnnouncementsHitted);

export const setIsFetchAnnouncementHitted = (isFetchAnnouncementHitted) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_IS_FETCH_ANNOUNCEMENT_HITTED, isFetchAnnouncementHitted);

export const setIsCreateAnnouncementHitted = (isCreateAnnouncementHitted) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_IS_CREATE_ANNOUNCEMENT_HITTED, isCreateAnnouncementHitted);

export const setIsUpdateAnnouncementHitted = (isUpdateAnnouncementHitted) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_IS_UPDATE_ANNOUNCEMENT_HITTED, isUpdateAnnouncementHitted);

export const setIsDeleteAnnouncementHitted = (isDeleteAnnouncementHitted) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_IS_DELETE_ANNOUNCEMENT_HITTED, isDeleteAnnouncementHitted);

export const setIsReadAnnouncementHitted = (isReadAnnouncementHitted) =>
  createAction(ANNOUNCEMENT_ACTION_TYPES.SET_IS_READ_ANNOUNCEMENT_HITTED, isReadAnnouncementHitted);

export const resetAnnouncementReducer = () => createAction(ANNOUNCEMENT_ACTION_TYPES.RESET_ANNOUNCEMENT_REDUCER);
