import { createSelector } from "reselect";

const ingredientHistorySelector = ({ ingredientHistory }) => ingredientHistory;

export const getIngredientHistories = createSelector(
  [ingredientHistorySelector],
  ({ ingredientHistories }) => ingredientHistories
);

export const getIngredientHistory = createSelector(
  [ingredientHistorySelector],
  ({ ingredientHistory }) => ingredientHistory
);

export const getIsIngredientHistoriesHasMore = createSelector(
  [ingredientHistorySelector],
  ({ isIngredientHistoriesHasMore }) => isIngredientHistoriesHasMore
);

export const getFetchIngredientHistoriesSearch = createSelector(
  [ingredientHistorySelector],
  ({ fetchIngredientHistoriesSearch }) => fetchIngredientHistoriesSearch
);

export const getFetchIngredientHistoriesSort = createSelector(
  [ingredientHistorySelector],
  ({ fetchIngredientHistoriesSort }) => fetchIngredientHistoriesSort
);

export const getFetchIngredientHistoriesPage = createSelector(
  [ingredientHistorySelector],
  ({ fetchIngredientHistoriesPage }) => fetchIngredientHistoriesPage
);

export const getFetchIngredientHistoriesPerPage = createSelector(
  [ingredientHistorySelector],
  ({ fetchIngredientHistoriesPerPage }) => fetchIngredientHistoriesPerPage
);

export const getFetchIngredientHistoriesLoading = createSelector(
  [ingredientHistorySelector],
  ({ fetchIngredientHistoriesLoading }) => fetchIngredientHistoriesLoading
);

export const getFetchIngredientHistoriesSuccess = createSelector(
  [ingredientHistorySelector],
  ({ fetchIngredientHistoriesSuccess }) => fetchIngredientHistoriesSuccess
);

export const getFetchIngredientHistoriesFailed = createSelector(
  [ingredientHistorySelector],
  ({ fetchIngredientHistoriesFailed }) => fetchIngredientHistoriesFailed
);

export const getFetchIngredientHistoryLoading = createSelector(
  [ingredientHistorySelector],
  ({ fetchIngredientHistoryLoading }) => fetchIngredientHistoryLoading
);

export const getFetchIngredientHistorySuccess = createSelector(
  [ingredientHistorySelector],
  ({ fetchIngredientHistorySuccess }) => fetchIngredientHistorySuccess
);

export const getFetchIngredientHistoryFailed = createSelector(
  [ingredientHistorySelector],
  ({ fetchIngredientHistoryFailed }) => fetchIngredientHistoryFailed
);

export const getIsFetchIngredientHistoriesHitted = createSelector(
  [ingredientHistorySelector],
  ({ isFetchIngredientHistoriesHitted }) => isFetchIngredientHistoriesHitted
);

export const getIsFetchIngredientHistoryHitted = createSelector(
  [ingredientHistorySelector],
  ({ isFetchIngredientHistoryHitted }) => isFetchIngredientHistoryHitted
);
