import INGREDIENT_ACTION_TYPES from "./ingredient.type";
import { createAction } from "../../utils/store.utils";

export const setIngredients = (ingredients) =>
  createAction(INGREDIENT_ACTION_TYPES.SET_INGREDIENTS, ingredients);
export const setSelectIngredients = (selectIngredients) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_SELECT_INGREDIENTS,
    selectIngredients
  );
export const setSelectGroupIngredients = (selectGroupIngredients) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_SELECT_GROUP_INGREDIENTS,
    selectGroupIngredients
  );
export const setOutOfStockIngredients = (outOfStockIngredients) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_OUT_OF_STOCK_INGREDIENTS,
    outOfStockIngredients
  );
export const setIngredient = (ingredient) =>
  createAction(INGREDIENT_ACTION_TYPES.SET_INGREDIENT, ingredient);

export const setIsIngredientsHasMore = (isIngredientsHasMore) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_INGREDIENTS_HAS_MORE,
    isIngredientsHasMore
  );
export const setIsSelectIngredientsHasMore = (isSelectIngredientsHasMore) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_SELECT_INGREDIENTS_HAS_MORE,
    isSelectIngredientsHasMore
  );
export const setIsSelectGroupIngredientsHasMore = (
  isSelectGroupIngredientsHasMore
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_SELECT_GROUP_INGREDIENTS_HAS_MORE,
    isSelectGroupIngredientsHasMore
  );
export const setIsOutOfStockIngredientsHasMore = (
  isOutOfStockIngredientsHasMore
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_OUT_OF_STOCK_INGREDIENTS_HAS_MORE,
    isOutOfStockIngredientsHasMore
  );

export const setFetchIngredientsSearch = (fetchIngredientsSearch) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_SEARCH,
    fetchIngredientsSearch
  );
export const setFetchIngredientsSort = (fetchIngredientsSort) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_SORT,
    fetchIngredientsSort
  );
export const setFetchIngredientsKeyBy = (fetchIngredientsKeyBy) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_KEY_BY,
    fetchIngredientsKeyBy
  );
export const setFetchIngredientsPage = (fetchIngredientsPage) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_PAGE,
    fetchIngredientsPage
  );
export const setFetchIngredientsPerPage = (fetchIngredientsPerPage) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_PER_PAGE,
    fetchIngredientsPerPage
  );
export const setFetchIngredientsIncludes = (fetchIngredientsIncludes) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_INCLUDES,
    fetchIngredientsIncludes
  );
export const setFetchIngredientsFilterMarketId = (
  fetchIngredientsFilterMarketId
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_FILTER_MARKET_ID,
    fetchIngredientsFilterMarketId
  );
export const setFetchIngredientsFilterBranchId = (
  fetchIngredientsFilterBranchId
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_FILTER_BRANCH_ID,
    fetchIngredientsFilterBranchId
  );
export const setFetchIngredientsFilterIsOutOfStock = (
  fetchIngredientsFilterIsOutOfStock
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_FILTER_IS_OUT_OF_STOCK,
    fetchIngredientsFilterIsOutOfStock
  );
export const setFetchIngredientsLoading = (fetchIngredientsLoading) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_LOADING,
    fetchIngredientsLoading
  );
export const setFetchIngredientsSuccess = (fetchIngredientsSuccess) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_SUCCESS,
    fetchIngredientsSuccess
  );
export const setFetchIngredientsFailed = (fetchIngredientsFailed) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_FAILED,
    fetchIngredientsFailed
  );

export const setFetchSelectIngredientsSearch = (fetchSelectIngredientsSearch) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_SEARCH,
    fetchSelectIngredientsSearch
  );
export const setFetchSelectIngredientsSort = (fetchSelectIngredientsSort) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_SORT,
    fetchSelectIngredientsSort
  );
export const setFetchSelectIngredientsKeyBy = (fetchSelectIngredientsKeyBy) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_KEY_BY,
    fetchSelectIngredientsKeyBy
  );
export const setFetchSelectIngredientsPage = (fetchSelectIngredientsPage) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_PAGE,
    fetchSelectIngredientsPage
  );
export const setFetchSelectIngredientsPerPage = (
  fetchSelectIngredientsPerPage
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_PER_PAGE,
    fetchSelectIngredientsPerPage
  );
export const setFetchSelectIngredientsIncludes = (
  fetchSelectIngredientsIncludes
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_INCLUDES,
    fetchSelectIngredientsIncludes
  );
export const setFetchSelectIngredientsFilterMarketId = (
  fetchSelectIngredientsFilterMarketId
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_FILTER_MARKET_ID,
    fetchSelectIngredientsFilterMarketId
  );
export const setFetchSelectIngredientsFilterBranchId = (
  fetchSelectIngredientsFilterBranchId
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_FILTER_BRANCH_ID,
    fetchSelectIngredientsFilterBranchId
  );
export const setFetchSelectIngredientsFilterIngredientsIds = (
  fetchSelectIngredientsFilterIngredientsIds
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_FILTER_INGREDIENTS_IDS,
    fetchSelectIngredientsFilterIngredientsIds
  );
export const setFetchSelectIngredientsFilterIsGroup = (
  fetchSelectIngredientsFilterIsGroup
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_FILTER_IS_GROUP,
    fetchSelectIngredientsFilterIsGroup
  );
export const setFetchSelectIngredientsLoading = (
  fetchSelectIngredientsLoading
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_LOADING,
    fetchSelectIngredientsLoading
  );
export const setFetchSelectIngredientsSuccess = (
  fetchSelectIngredientsSuccess
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_SUCCESS,
    fetchSelectIngredientsSuccess
  );
export const setFetchSelectIngredientsFailed = (fetchSelectIngredientsFailed) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_INGREDIENTS_FAILED,
    fetchSelectIngredientsFailed
  );

export const setFetchSelectGroupIngredientsSearch = (
  fetchSelectGroupIngredientsSearch
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_SEARCH,
    fetchSelectGroupIngredientsSearch
  );
export const setFetchSelectGroupIngredientsSort = (
  fetchSelectGroupIngredientsSort
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_SORT,
    fetchSelectGroupIngredientsSort
  );
export const setFetchSelectGroupIngredientsKeyBy = (
  fetchSelectGroupIngredientsKeyBy
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_KEY_BY,
    fetchSelectGroupIngredientsKeyBy
  );
export const setFetchSelectGroupIngredientsPage = (
  fetchSelectGroupIngredientsPage
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_PAGE,
    fetchSelectGroupIngredientsPage
  );
export const setFetchSelectGroupIngredientsPerPage = (
  fetchSelectGroupIngredientsPerPage
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_PER_PAGE,
    fetchSelectGroupIngredientsPerPage
  );
export const setFetchSelectGroupIngredientsIncludes = (
  fetchSelectGroupIngredientsIncludes
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_INCLUDES,
    fetchSelectGroupIngredientsIncludes
  );
export const setFetchSelectGroupIngredientsFilterMarketId = (
  fetchSelectGroupIngredientsFilterMarketId
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_MARKET_ID,
    fetchSelectGroupIngredientsFilterMarketId
  );
export const setFetchSelectGroupIngredientsFilterBranchId = (
  fetchSelectGroupIngredientsFilterBranchId
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_BRANCH_ID,
    fetchSelectGroupIngredientsFilterBranchId
  );
export const setFetchSelectGroupIngredientsFilterIngredientsIds = (
  fetchSelectGroupIngredientsFilterIngredientsIds
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_INGREDIENTS_IDS,
    fetchSelectGroupIngredientsFilterIngredientsIds
  );
export const setFetchSelectGroupIngredientsFilterIsGroup = (
  fetchSelectGroupIngredientsFilterIsGroup
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_IS_GROUP,
    fetchSelectGroupIngredientsFilterIsGroup
  );
export const setFetchSelectGroupIngredientsLoading = (
  fetchSelectGroupIngredientsLoading
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_LOADING,
    fetchSelectGroupIngredientsLoading
  );
export const setFetchSelectGroupIngredientsSuccess = (
  fetchSelectGroupIngredientsSuccess
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_SUCCESS,
    fetchSelectGroupIngredientsSuccess
  );
export const setFetchSelectGroupIngredientsFailed = (
  fetchSelectGroupIngredientsFailed
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_SELECT_GROUP_INGREDIENTS_FAILED,
    fetchSelectGroupIngredientsFailed
  );

export const setFetchOutOfStockIngredientsSearch = (
  fetchOutOfStockIngredientsSearch
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_SEARCH,
    fetchOutOfStockIngredientsSearch
  );
export const setFetchOutOfStockIngredientsSort = (
  fetchOutOfStockIngredientsSort
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_SORT,
    fetchOutOfStockIngredientsSort
  );
export const setFetchOutOfStockIngredientsKeyBy = (
  fetchOutOfStockIngredientsKeyBy
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_KEY_BY,
    fetchOutOfStockIngredientsKeyBy
  );
export const setFetchOutOfStockIngredientsPage = (
  fetchOutOfStockIngredientsPage
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_PAGE,
    fetchOutOfStockIngredientsPage
  );
export const setFetchOutOfStockIngredientsPerPage = (
  fetchOutOfStockIngredientsPerPage
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_PER_PAGE,
    fetchOutOfStockIngredientsPerPage
  );
export const setFetchOutOfStockIngredientsIncludes = (
  fetchOutOfStockIngredientsIncludes
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_INCLUDES,
    fetchOutOfStockIngredientsIncludes
  );
export const setFetchOutOfStockIngredientsFilterMarketId = (
  fetchOutOfStockIngredientsFilterMarketId
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_FILTER_MARKET_ID,
    fetchOutOfStockIngredientsFilterMarketId
  );
export const setFetchOutOfStockIngredientsFilterBranchId = (
  fetchOutOfStockIngredientsFilterBranchId
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_FILTER_BRANCH_ID,
    fetchOutOfStockIngredientsFilterBranchId
  );
export const setFetchOutOfStockIngredientsLoading = (
  fetchOutOfStockIngredientsLoading
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_LOADING,
    fetchOutOfStockIngredientsLoading
  );
export const setFetchOutOfStockIngredientsSuccess = (
  fetchOutOfStockIngredientsSuccess
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_SUCCESS,
    fetchOutOfStockIngredientsSuccess
  );
export const setFetchOutOfStockIngredientsFailed = (
  fetchOutOfStockIngredientsFailed
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_OUT_OF_STOCK_INGREDIENTS_FAILED,
    fetchOutOfStockIngredientsFailed
  );

export const setAppendSelectIngredientsSearch = (
  appendSelectIngredientsSearch
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_SEARCH,
    appendSelectIngredientsSearch
  );
export const setAppendSelectIngredientsSort = (appendSelectIngredientsSort) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_SORT,
    appendSelectIngredientsSort
  );
export const setAppendSelectIngredientsKeyBy = (appendSelectIngredientsKeyBy) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_KEY_BY,
    appendSelectIngredientsKeyBy
  );
export const setAppendSelectIngredientsPage = (appendSelectIngredientsPage) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_PAGE,
    appendSelectIngredientsPage
  );
export const setAppendSelectIngredientsPerPage = (
  appendSelectIngredientsPerPage
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_PER_PAGE,
    appendSelectIngredientsPerPage
  );
export const setAppendSelectIngredientsIncludes = (
  appendSelectIngredientsIncludes
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_INCLUDES,
    appendSelectIngredientsIncludes
  );
export const setAppendSelectIngredientsFilterMarketId = (
  appendSelectIngredientsFilterMarketId
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_FILTER_MARKET_ID,
    appendSelectIngredientsFilterMarketId
  );
export const setAppendSelectIngredientsFilterBranchId = (
  appendSelectIngredientsFilterBranchId
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_FILTER_BRANCH_ID,
    appendSelectIngredientsFilterBranchId
  );
export const setAppendSelectIngredientsFilterIngredientsIds = (
  appendSelectIngredientsFilterIngredientsIds
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_FILTER_INGREDIENTS_IDS,
    appendSelectIngredientsFilterIngredientsIds
  );
export const setAppendSelectIngredientsFilterIsGroup = (
  appendSelectIngredientsFilterIsGroup
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_FILTER_IS_GROUP,
    appendSelectIngredientsFilterIsGroup
  );
export const setAppendSelectIngredientsLoading = (
  appendSelectIngredientsLoading
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_LOADING,
    appendSelectIngredientsLoading
  );
export const setAppendSelectIngredientsSuccess = (
  appendSelectIngredientsSuccess
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_SUCCESS,
    appendSelectIngredientsSuccess
  );
export const setAppendSelectIngredientsFailed = (
  appendSelectIngredientsFailed
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_INGREDIENTS_FAILED,
    appendSelectIngredientsFailed
  );

export const setAppendSelectGroupIngredientsSearch = (
  appendSelectGroupIngredientsSearch
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_SEARCH,
    appendSelectGroupIngredientsSearch
  );
export const setAppendSelectGroupIngredientsSort = (
  appendSelectGroupIngredientsSort
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_SORT,
    appendSelectGroupIngredientsSort
  );
export const setAppendSelectGroupIngredientsKeyBy = (
  appendSelectGroupIngredientsKeyBy
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_KEY_BY,
    appendSelectGroupIngredientsKeyBy
  );
export const setAppendSelectGroupIngredientsPage = (
  appendSelectGroupIngredientsPage
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_PAGE,
    appendSelectGroupIngredientsPage
  );
export const setAppendSelectGroupIngredientsPerPage = (
  appendSelectGroupIngredientsPerPage
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_PER_PAGE,
    appendSelectGroupIngredientsPerPage
  );
export const setAppendSelectGroupIngredientsIncludes = (
  appendSelectGroupIngredientsIncludes
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_INCLUDES,
    appendSelectGroupIngredientsIncludes
  );
export const setAppendSelectGroupIngredientsFilterMarketId = (
  appendSelectGroupIngredientsFilterMarketId
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_MARKET_ID,
    appendSelectGroupIngredientsFilterMarketId
  );
export const setAppendSelectGroupIngredientsFilterBranchId = (
  appendSelectGroupIngredientsFilterBranchId
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_BRANCH_ID,
    appendSelectGroupIngredientsFilterBranchId
  );
export const setAppendSelectGroupIngredientsFilterIngredientsIds = (
  appendSelectGroupIngredientsFilterIngredientsIds
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_INGREDIENTS_IDS,
    appendSelectGroupIngredientsFilterIngredientsIds
  );
export const setAppendSelectGroupIngredientsFilterIsGroup = (
  appendSelectGroupIngredientsFilterIsGroup
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_IS_GROUP,
    appendSelectGroupIngredientsFilterIsGroup
  );
export const setAppendSelectGroupIngredientsLoading = (
  appendSelectGroupIngredientsLoading
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_LOADING,
    appendSelectGroupIngredientsLoading
  );
export const setAppendSelectGroupIngredientsSuccess = (
  appendSelectGroupIngredientsSuccess
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_SUCCESS,
    appendSelectGroupIngredientsSuccess
  );
export const setAppendSelectGroupIngredientsFailed = (
  appendSelectGroupIngredientsFailed
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_APPEND_SELECT_GROUP_INGREDIENTS_FAILED,
    appendSelectGroupIngredientsFailed
  );

export const setFetchIngredientLoading = (fetchIngredientLoading) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENT_LOADING,
    fetchIngredientLoading
  );
export const setFetchIngredientSuccess = (fetchIngredientSuccess) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENT_SUCCESS,
    fetchIngredientSuccess
  );
export const setFetchIngredientFailed = (fetchIngredientFailed) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENT_FAILED,
    fetchIngredientFailed
  );

export const setCreateIngredientLoading = (createIngredientLoading) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_CREATE_INGREDIENT_LOADING,
    createIngredientLoading
  );
export const setCreateIngredientSuccess = (createIngredientSuccess) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_CREATE_INGREDIENT_SUCCESS,
    createIngredientSuccess
  );
export const setCreateIngredientFailed = (createIngredientFailed) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_CREATE_INGREDIENT_FAILED,
    createIngredientFailed
  );

export const setUpdateIngredientLoading = (updateIngredientLoading) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_UPDATE_INGREDIENT_LOADING,
    updateIngredientLoading
  );
export const setUpdateIngredientSuccess = (updateIngredientSuccess) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_UPDATE_INGREDIENT_SUCCESS,
    updateIngredientSuccess
  );
export const setUpdateIngredientFailed = (updateIngredientFailed) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_UPDATE_INGREDIENT_FAILED,
    updateIngredientFailed
  );

export const setStoreStockIngredientLoading = (storeStockIngredientLoading) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_STORE_STOCK_INGREDIENT_LOADING,
    storeStockIngredientLoading
  );
export const setStoreStockIngredientSuccess = (storeStockIngredientSuccess) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_STORE_STOCK_INGREDIENT_SUCCESS,
    storeStockIngredientSuccess
  );
export const setStoreStockIngredientFailed = (storeStockIngredientFailed) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_STORE_STOCK_INGREDIENT_FAILED,
    storeStockIngredientFailed
  );

export const setReduceStockIngredientLoading = (reduceStockIngredientLoading) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_REDUCE_STOCK_INGREDIENT_LOADING,
    reduceStockIngredientLoading
  );
export const setReduceStockIngredientSuccess = (reduceStockIngredientSuccess) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_REDUCE_STOCK_INGREDIENT_SUCCESS,
    reduceStockIngredientSuccess
  );
export const setReduceStockIngredientFailed = (reduceStockIngredientFailed) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_REDUCE_STOCK_INGREDIENT_FAILED,
    reduceStockIngredientFailed
  );

export const setDeleteIngredientLoading = (deleteIngredientLoading) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_DELETE_INGREDIENT_LOADING,
    deleteIngredientLoading
  );
export const setDeleteIngredientSuccess = (deleteIngredientSuccess) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_DELETE_INGREDIENT_SUCCESS,
    deleteIngredientSuccess
  );
export const setDeleteIngredientFailed = (deleteIngredientFailed) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_DELETE_INGREDIENT_FAILED,
    deleteIngredientFailed
  );

export const appendIngredients = (ingredients) =>
  createAction(INGREDIENT_ACTION_TYPES.APPEND_INGREDIENTS, ingredients);
export const appendOutOfStockIngredients = (outOfStockIngredients) =>
  createAction(
    INGREDIENT_ACTION_TYPES.APPEND_OUT_OF_STOCK_INGREDIENTS,
    outOfStockIngredients
  );
export const appendSelectIngredients = (selectIngredients) =>
  createAction(
    INGREDIENT_ACTION_TYPES.APPEND_SELECT_INGREDIENTS,
    selectIngredients
  );
export const appendSelectGroupIngredients = (selectGroupIngredients) =>
  createAction(
    INGREDIENT_ACTION_TYPES.APPEND_SELECT_GROUP_INGREDIENTS,
    selectGroupIngredients
  );

export const fetchIngredientsStart = () =>
  createAction(INGREDIENT_ACTION_TYPES.FETCH_INGREDIENTS_START);
export const fetchOutOfStockIngredientsStart = () =>
  createAction(INGREDIENT_ACTION_TYPES.FETCH_OUT_OF_STOCK_INGREDIENTS_START);
export const fetchSelectIngredientsStart = () =>
  createAction(INGREDIENT_ACTION_TYPES.FETCH_SELECT_INGREDIENTS_START);
export const fetchSelectGroupIngredientsStart = () =>
  createAction(INGREDIENT_ACTION_TYPES.FETCH_SELECT_GROUP_INGREDIENTS_START);
export const appendSelectIngredientsStart = () =>
  createAction(INGREDIENT_ACTION_TYPES.APPEND_SELECT_INGREDIENTS_START);
export const appendSelectGroupIngredientsStart = () =>
  createAction(INGREDIENT_ACTION_TYPES.APPEND_SELECT_GROUP_INGREDIENTS_START);
export const fetchIngredientStart = (ingredientId) =>
  createAction(INGREDIENT_ACTION_TYPES.FETCH_INGREDIENT_START, ingredientId);
export const createIngredientStart = (request) =>
  createAction(INGREDIENT_ACTION_TYPES.CREATE_INGREDIENT_START, request);
export const updateIngredientStart = (ingredientId, request) =>
  createAction(INGREDIENT_ACTION_TYPES.UPDATE_INGREDIENT_START, {
    ingredientId,
    request,
  });
export const deleteIngredientStart = (ingredientId) =>
  createAction(INGREDIENT_ACTION_TYPES.DELETE_INGREDIENT_START, ingredientId);
export const storeStockIngredientStart = (ingredientId, request) =>
  createAction(INGREDIENT_ACTION_TYPES.STORE_STOCK_INGREDIENT_START, {
    ingredientId,
    request,
  });
export const reduceStockIngredientStart = (ingredientId, request) =>
  createAction(INGREDIENT_ACTION_TYPES.REDUCE_STOCK_INGREDIENT_START, {
    ingredientId,
    request,
  });

export const setIsFetchIngredientsHitted = (isFetchIngredientsHitted) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_FETCH_INGREDIENTS_HITTED,
    isFetchIngredientsHitted
  );
export const setIsFetchSelectIngredientsHitted = (
  isFetchSelectIngredientsHitted
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_FETCH_SELECT_INGREDIENTS_HITTED,
    isFetchSelectIngredientsHitted
  );
export const setIsFetchSelectGroupIngredientsHitted = (
  isFetchSelectGroupIngredientsHitted
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_FETCH_SELECT_GROUP_INGREDIENTS_HITTED,
    isFetchSelectGroupIngredientsHitted
  );
export const setIsFetchOutOfStockIngredientsHitted = (
  isFetchOutOfStockIngredientsHitted
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_FETCH_OUT_OF_STOCK_INGREDIENTS_HITTED,
    isFetchOutOfStockIngredientsHitted
  );
export const setIsAppendSelectIngredientsHitted = (
  isAppendSelectIngredientsHitted
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_APPEND_SELECT_INGREDIENTS_HITTED,
    isAppendSelectIngredientsHitted
  );
export const setIsAppendSelectGroupIngredientsHitted = (
  isAppendSelectGroupIngredientsHitted
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_APPEND_SELECT_GROUP_INGREDIENTS_HITTED,
    isAppendSelectGroupIngredientsHitted
  );
export const setIsFetchIngredientHitted = (isFetchIngredientHitted) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_FETCH_INGREDIENT_HITTED,
    isFetchIngredientHitted
  );
export const setIsCreateIngredientHitted = (isCreateIngredientHitted) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_CREATE_INGREDIENT_HITTED,
    isCreateIngredientHitted
  );
export const setIsUpdateIngredientHitted = (isUpdateIngredientHitted) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_UPDATE_INGREDIENT_HITTED,
    isUpdateIngredientHitted
  );
export const setIsDeleteIngredientHitted = (isDeleteIngredientHitted) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_DELETE_INGREDIENT_HITTED,
    isDeleteIngredientHitted
  );
export const setIsStoreStockIngredientHitted = (isStoreStockIngredientHitted) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_STORE_STOCK_INGREDIENT_HITTED,
    isStoreStockIngredientHitted
  );
export const setIsReduceStockIngredientHitted = (
  isReduceStockIngredientHitted
) =>
  createAction(
    INGREDIENT_ACTION_TYPES.SET_IS_REDUCE_STOCK_INGREDIENT_HITTED,
    isReduceStockIngredientHitted
  );

export const resetIngredientReducer = () =>
  createAction(INGREDIENT_ACTION_TYPES.RESET_INGREDIENT_REDUCER);
