import { createAction } from "../../utils/store.utils";

import PERMISSION_SUBSCRIBER_ACTION_TYPES from "./permission-subscriber.type";

export const setPermissions = (permissions) =>
  createAction(PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_PERMISSIONS, permissions);
export const setSelectPermissions = (selectPermissions) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_SELECT_PERMISSIONS,
    selectPermissions
  );
export const setPermission = (permission) =>
  createAction(PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_PERMISSION, permission);

export const setIsPermissionsHasMore = (isPermissionsHasMore) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_PERMISSIONS_HAS_MORE,
    isPermissionsHasMore
  );
export const setIsSelectPermissionsHasMore = (isSelectPermissionsHasMore) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_SELECT_PERMISSIONS_HAS_MORE,
    isSelectPermissionsHasMore
  );

export const setFetchPermissionsSearch = (fetchPermissionsSearch) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_SEARCH,
    fetchPermissionsSearch
  );
export const setFetchPermissionsSort = (fetchPermissionsSort) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_SORT,
    fetchPermissionsSort
  );
export const setFetchPermissionsKeyBy = (fetchPermissionsKeyBy) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_KEY_BY,
    fetchPermissionsKeyBy
  );
export const setFetchPermissionsPage = (fetchPermissionsPage) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_PAGE,
    fetchPermissionsPage
  );
export const setFetchPermissionsPerPage = (fetchPermissionsPerPage) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_PER_PAGE,
    fetchPermissionsPerPage
  );
export const setFetchPermissionsIncludes = (fetchPermissionsIncludes) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_INCLUDES,
    fetchPermissionsIncludes
  );
export const setFetchPermissionsFilterMarketId = (
  fetchPermissionsFilterMarketId
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_FILTER_MARKET_ID,
    fetchPermissionsFilterMarketId
  );
export const setFetchPermissionsFilterIsDefault = (
  fetchPermissionsFilterIsDefault
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_FILTER_IS_DEFAULT,
    fetchPermissionsFilterIsDefault
  );
export const setFetchPermissionsLoading = (fetchPermissionsLoading) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_LOADING,
    fetchPermissionsLoading
  );
export const setFetchPermissionsSuccess = (fetchPermissionsSuccess) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_SUCCESS,
    fetchPermissionsSuccess
  );
export const setFetchPermissionsFailed = (fetchPermissionsFailed) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_FAILED,
    fetchPermissionsFailed
  );

export const setFetchSelectPermissionsSearch = (fetchSelectPermissionsSearch) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_SEARCH,
    fetchSelectPermissionsSearch
  );
export const setFetchSelectPermissionsSort = (fetchSelectPermissionsSort) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_SORT,
    fetchSelectPermissionsSort
  );
export const setFetchSelectPermissionsKeyBy = (fetchSelectPermissionsKeyBy) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_KEY_BY,
    fetchSelectPermissionsKeyBy
  );
export const setFetchSelectPermissionsPage = (fetchSelectPermissionsPage) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_PAGE,
    fetchSelectPermissionsPage
  );
export const setFetchSelectPermissionsPerPage = (
  fetchSelectPermissionsPerPage
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_PER_PAGE,
    fetchSelectPermissionsPerPage
  );
export const setFetchSelectPermissionsIncludes = (
  fetchSelectPermissionsIncludes
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_INCLUDES,
    fetchSelectPermissionsIncludes
  );
export const setFetchSelectPermissionsFilterPermissionsIds = (
  fetchSelectPermissionsFilterPermissionsIds
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_FILTER_PERMISSIONS_IDS,
    fetchSelectPermissionsFilterPermissionsIds
  );
export const setFetchSelectPermissionsFilterMarketId = (
  fetchSelectPermissionsFilterMarketId
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_FILTER_MARKET_ID,
    fetchSelectPermissionsFilterMarketId
  );
export const setFetchSelectPermissionsFilterIsDefault = (
  fetchSelectPermissionsFilterIsDefault
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_FILTER_IS_DEFAULT,
    fetchSelectPermissionsFilterIsDefault
  );
export const setFetchSelectPermissionsLoading = (
  fetchSelectPermissionsLoading
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_LOADING,
    fetchSelectPermissionsLoading
  );
export const setFetchSelectPermissionsSuccess = (
  fetchSelectPermissionsSuccess
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_SUCCESS,
    fetchSelectPermissionsSuccess
  );
export const setFetchSelectPermissionsFailed = (fetchSelectPermissionsFailed) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_FAILED,
    fetchSelectPermissionsFailed
  );

export const setAppendSelectPermissionsSearch = (
  appendSelectPermissionsSearch
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_SEARCH,
    appendSelectPermissionsSearch
  );
export const setAppendSelectPermissionsSort = (appendSelectPermissionsSort) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_SORT,
    appendSelectPermissionsSort
  );
export const setAppendSelectPermissionsKeyBy = (appendSelectPermissionsKeyBy) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_KEY_BY,
    appendSelectPermissionsKeyBy
  );
export const setAppendSelectPermissionsPage = (appendSelectPermissionsPage) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_PAGE,
    appendSelectPermissionsPage
  );
export const setAppendSelectPermissionsPerPage = (
  appendSelectPermissionsPerPage
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_PER_PAGE,
    appendSelectPermissionsPerPage
  );
export const setAppendSelectPermissionsIncludes = (
  appendSelectPermissionsIncludes
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_INCLUDES,
    appendSelectPermissionsIncludes
  );
export const setAppendSelectPermissionsFilterPermissionsIds = (
  appendSelectPermissionsFilterPermissionsIds
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_FILTER_PERMISSIONS_IDS,
    appendSelectPermissionsFilterPermissionsIds
  );
export const setAppendSelectPermissionsFilterMarketId = (
  appendSelectPermissionsFilterMarketId
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_FILTER_MARKET_ID,
    appendSelectPermissionsFilterMarketId
  );
export const setAppendSelectPermissionsFilterIsDefault = (
  appendSelectPermissionsFilterIsDefault
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_FILTER_IS_DEFAULT,
    appendSelectPermissionsFilterIsDefault
  );
export const setAppendSelectPermissionsLoading = (
  appendSelectPermissionsLoading
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_LOADING,
    appendSelectPermissionsLoading
  );
export const setAppendSelectPermissionsSuccess = (
  appendSelectPermissionsSuccess
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_SUCCESS,
    appendSelectPermissionsSuccess
  );
export const setAppendSelectPermissionsFailed = (
  appendSelectPermissionsFailed
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_FAILED,
    appendSelectPermissionsFailed
  );

export const setFetchPermissionLoading = (fetchPermissionLoading) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSION_LOADING,
    fetchPermissionLoading
  );
export const setFetchPermissionSuccess = (fetchPermissionSuccess) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSION_SUCCESS,
    fetchPermissionSuccess
  );
export const setFetchPermissionFailed = (fetchPermissionFailed) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSION_FAILED,
    fetchPermissionFailed
  );

export const setCreatePermissionLoading = (createPermissionLoading) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_CREATE_PERMISSION_LOADING,
    createPermissionLoading
  );
export const setCreatePermissionSuccess = (createPermissionSuccess) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_CREATE_PERMISSION_SUCCESS,
    createPermissionSuccess
  );
export const setCreatePermissionFailed = (createPermissionFailed) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_CREATE_PERMISSION_FAILED,
    createPermissionFailed
  );

export const setUpdatePermissionLoading = (updatePermissionLoading) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_UPDATE_PERMISSION_LOADING,
    updatePermissionLoading
  );
export const setUpdatePermissionSuccess = (updatePermissionSuccess) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_UPDATE_PERMISSION_SUCCESS,
    updatePermissionSuccess
  );
export const setUpdatePermissionFailed = (updatePermissionFailed) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_UPDATE_PERMISSION_FAILED,
    updatePermissionFailed
  );

export const setDeletePermissionLoading = (deletePermissionLoading) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_DELETE_PERMISSION_LOADING,
    deletePermissionLoading
  );
export const setDeletePermissionSuccess = (deletePermissionSuccess) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_DELETE_PERMISSION_SUCCESS,
    deletePermissionSuccess
  );
export const setDeletePermissionFailed = (deletePermissionFailed) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_DELETE_PERMISSION_FAILED,
    deletePermissionFailed
  );

export const appendPermissions = (permissions) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.APPEND_PERMISSIONS,
    permissions
  );
export const appendSelectPermissions = (selectPermissions) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.APPEND_SELECT_PERMISSIONS,
    selectPermissions
  );

export const fetchPermissionsStart = () =>
  createAction(PERMISSION_SUBSCRIBER_ACTION_TYPES.FETCH_PERMISSIONS_START);
export const fetchSelectPermissionsStart = () =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.FETCH_SELECT_PERMISSIONS_START
  );
export const appendSelectPermissionsStart = () =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.APPEND_SELECT_PERMISSIONS_START
  );
export const fetchPermissionStart = (permissionId) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.FETCH_PERMISSION_START,
    permissionId
  );
export const createPermissionStart = (request) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.CREATE_PERMISSION_START,
    request
  );
export const updatePermissionStart = (permissionId, request) =>
  createAction(PERMISSION_SUBSCRIBER_ACTION_TYPES.UPDATE_PERMISSION_START, {
    permissionId,
    request,
  });
export const deletePermissionStart = (permissionId) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.DELETE_PERMISSION_START,
    permissionId
  );

export const setIsFetchPermissionsHitted = (isFetchPermissionsHitted) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_PERMISSIONS_HITTED,
    isFetchPermissionsHitted
  );
export const setIsFetchSelectPermissionsHitted = (
  isFetchSelectPermissionsHitted
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_SELECT_PERMISSIONS_HITTED,
    isFetchSelectPermissionsHitted
  );
export const setIsAppendSelectPermissionsHitted = (
  isAppendSelectPermissionsHitted
) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_APPEND_SELECT_PERMISSIONS_HITTED,
    isAppendSelectPermissionsHitted
  );
export const setIsFetchPermissionHitted = (isFetchPermissionHitted) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_PERMISSION_HITTED,
    isFetchPermissionHitted
  );
export const setIsCreatePermissionHitted = (isCreatePermissionHitted) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_CREATE_PERMISSION_HITTED,
    isCreatePermissionHitted
  );
export const setIsUpdatePermissionHitted = (isUpdatePermissionHitted) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_UPDATE_PERMISSION_HITTED,
    isUpdatePermissionHitted
  );
export const setIsDeletePermissionHitted = (isDeletePermissionHitted) =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_DELETE_PERMISSION_HITTED,
    isDeletePermissionHitted
  );

export const resetPermissionSubscriberReducer = () =>
  createAction(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.RESET_PERMISSION_SUBSCRIBER_REDUCER
  );
