import { Outlet } from "react-router-dom";

import AuthenticationCarousel from "../../widgets/authentication-carousel-v2/authentication-carousel.widget";

import {
  AuthenticationLayoutCarousel,
  AuthenticationLayoutContainer,
  AuthenticationLayoutContent,
  AuthenticationLayoutMain,
} from "./authentication-layout.style";

const AuthenticationLayout = () => {
  return (
    <AuthenticationLayoutContainer>
      <AuthenticationLayoutContent>
        <AuthenticationLayoutMain>
          <Outlet />
        </AuthenticationLayoutMain>
        <AuthenticationLayoutCarousel>
          <AuthenticationCarousel />
        </AuthenticationLayoutCarousel>
      </AuthenticationLayoutContent>
    </AuthenticationLayoutContainer>
  );
};

export default AuthenticationLayout;
