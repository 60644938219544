import { PERMISISON_ADMIN_TYPES } from "../../constants/permission-admin.constant";

import PERMISSION_ADMIN_ACTION_TYPES from "./permission-admin.type";

export const PERMISSION_ADMIN_INITIAL_STATE = {
  permissionTab: PERMISISON_ADMIN_TYPES.ADMIN,
  permissionTypes: [
    {
      label: "Admin Permissions",
      value: PERMISISON_ADMIN_TYPES.ADMIN,
    },
    {
      label: "Subscriber Permissions",
      value: PERMISISON_ADMIN_TYPES.SUBSCRIBER,
    },
  ],

  permissionAdmins: [],
  permissionSubscribers: [],
  selectPermissionAdmins: {},
  selectPermissionSubscribers: {},
  permission: null,

  isPermissionAdminsHasMore: true,
  isPermissionSubscribersHasMore: true,
  isSelectPermissionAdminsHasMore: true,
  isSelectPermissionSubscribersHasMore: true,

  fetchPermissionAdminsSearch: null,
  fetchPermissionAdminsSort: null,
  fetchPermissionAdminsKeyBy: null,
  fetchPermissionAdminsPage: 1,
  fetchPermissionAdminsPerPage: null,
  fetchPermissionAdminsIncludes: null,
  fetchPermissionAdminsFilterIsDefault: null,
  fetchPermissionAdminsLoading: false,
  fetchPermissionAdminsSuccess: null,
  fetchPermissionAdminsFailed: null,

  fetchPermissionSubscribersSearch: null,
  fetchPermissionSubscribersSort: null,
  fetchPermissionSubscribersKeyBy: null,
  fetchPermissionSubscribersPage: 1,
  fetchPermissionSubscribersPerPage: null,
  fetchPermissionSubscribersIncludes: null,
  fetchPermissionSubscribersFilterIsDefault: null,
  fetchPermissionSubscribersLoading: false,
  fetchPermissionSubscribersSuccess: null,
  fetchPermissionSubscribersFailed: null,

  fetchSelectPermissionAdminsSearch: null,
  fetchSelectPermissionAdminsSort: null,
  fetchSelectPermissionAdminsKeyBy: null,
  fetchSelectPermissionAdminsPage: 1,
  fetchSelectPermissionAdminsPerPage: null,
  fetchSelectPermissionAdminsIncludes: null,
  fetchSelectPermissionAdminsFilterPermissionsIds: null,
  fetchSelectPermissionAdminsFilterIsDefault: null,
  fetchSelectPermissionAdminsLoading: false,
  fetchSelectPermissionAdminsSuccess: null,
  fetchSelectPermissionAdminsFailed: null,

  fetchSelectPermissionSubscribersSearch: null,
  fetchSelectPermissionSubscribersSort: null,
  fetchSelectPermissionSubscribersKeyBy: null,
  fetchSelectPermissionSubscribersPage: 1,
  fetchSelectPermissionSubscribersPerPage: null,
  fetchSelectPermissionSubscribersIncludes: null,
  fetchSelectPermissionSubscribersFilterPermissionsIds: null,
  fetchSelectPermissionSubscribersFilterIsDefault: null,
  fetchSelectPermissionSubscribersLoading: false,
  fetchSelectPermissionSubscribersSuccess: null,
  fetchSelectPermissionSubscribersFailed: null,

  appendSelectPermissionAdminsSearch: null,
  appendSelectPermissionAdminsSort: null,
  appendSelectPermissionAdminsKeyBy: null,
  appendSelectPermissionAdminsPage: 1,
  appendSelectPermissionAdminsPerPage: null,
  appendSelectPermissionAdminsIncludes: null,
  appendSelectPermissionAdminsFilterPermissionsIds: null,
  appendSelectPermissionAdminsFilterIsDefault: null,
  appendSelectPermissionAdminsLoading: false,
  appendSelectPermissionAdminsSuccess: null,
  appendSelectPermissionAdminsFailed: null,

  appendSelectPermissionSubscribersSearch: null,
  appendSelectPermissionSubscribersSort: null,
  appendSelectPermissionSubscribersKeyBy: null,
  appendSelectPermissionSubscribersPage: 1,
  appendSelectPermissionSubscribersPerPage: null,
  appendSelectPermissionSubscribersIncludes: null,
  appendSelectPermissionSubscribersFilterPermissionsIds: null,
  appendSelectPermissionSubscribersFilterIsDefault: null,
  appendSelectPermissionSubscribersLoading: false,
  appendSelectPermissionSubscribersSuccess: null,
  appendSelectPermissionSubscribersFailed: null,

  fetchPermissionLoading: false,
  fetchPermissionSuccess: null,
  fetchPermissionFailed: null,

  createPermissionLoading: false,
  createPermissionSuccess: null,
  createPermissionFailed: null,

  updatePermissionLoading: false,
  updatePermissionSuccess: null,
  updatePermissionFailed: null,

  deletePermissionLoading: false,
  deletePermissionSuccess: null,
  deletePermissionFailed: null,

  isFetchPermissionAdminsHitted: false,
  isFetchPermissionSubscribersHitted: false,
  isFetchSelectPermissionAdminsHitted: false,
  isFetchSelectPermissionSubscribersHitted: false,
  isAppendSelectPermissionAdminsHitted: false,
  isAppendSelectPermissionSubscribersHitted: false,
  isFetchPermissionHitted: false,
  isCreatePermissionHitted: false,
  isUpdatePermissionHitted: false,
  isDeletePermissionHitted: false,
};

export const permissionAdminReducer = (
  state = PERMISSION_ADMIN_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case PERMISSION_ADMIN_ACTION_TYPES.SET_PERMISSION_TAB:
      return { ...state, permissionTab: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_PERMISSION_ADMINS:
      return { ...state, permissionAdmins: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_PERMISSION_SUBSCRIBERS:
      return { ...state, permissionSubscribers: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_SELECT_PERMISSION_ADMINS:
      return { ...state, selectPermissionAdmins: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_SELECT_PERMISSION_SUBSCRIBERS:
      return { ...state, selectPermissionSubscribers: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_PERMISSION:
      return { ...state, permission: payload };

    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_PERMISSION_ADMINS_HAS_MORE:
      return { ...state, isPermissionAdminsHasMore: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE:
      return { ...state, isPermissionSubscribersHasMore: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_SELECT_PERMISSION_ADMINS_HAS_MORE:
      return { ...state, isSelectPermissionAdminsHasMore: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_SELECT_PERMISSION_SUBSCRIBERS_HAS_MORE:
      return { ...state, isSelectPermissionSubscribersHasMore: payload };

    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_SEARCH:
      return { ...state, fetchPermissionAdminsSearch: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_SORT:
      return { ...state, fetchPermissionAdminsSort: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_KEY_BY:
      return { ...state, fetchPermissionAdminsKeyBy: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_PAGE:
      return { ...state, fetchPermissionAdminsPage: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_PER_PAGE:
      return { ...state, fetchPermissionAdminsPerPage: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_INCLUDES:
      return { ...state, fetchPermissionAdminsIncludes: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_FILTER_IS_DEFAULT:
      return { ...state, fetchPermissionAdminsFilterIsDefault: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_LOADING:
      return { ...state, fetchPermissionAdminsLoading: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_SUCCESS:
      return { ...state, fetchPermissionAdminsSuccess: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_ADMINS_FAILED:
      return { ...state, fetchPermissionAdminsFailed: payload };

    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SEARCH:
      return { ...state, fetchPermissionSubscribersSearch: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SORT:
      return { ...state, fetchPermissionSubscribersSort: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_KEY_BY:
      return { ...state, fetchPermissionSubscribersKeyBy: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_PAGE:
      return { ...state, fetchPermissionSubscribersPage: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_PER_PAGE:
      return { ...state, fetchPermissionSubscribersPerPage: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_INCLUDES:
      return { ...state, fetchPermissionSubscribersIncludes: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT:
      return { ...state, fetchPermissionSubscribersFilterIsDefault: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING:
      return { ...state, fetchPermissionSubscribersLoading: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS:
      return { ...state, fetchPermissionSubscribersSuccess: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED:
      return { ...state, fetchPermissionSubscribersFailed: payload };

    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_SEARCH:
      return { ...state, fetchSelectPermissionAdminsSearch: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_SORT:
      return { ...state, fetchSelectPermissionAdminsSort: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_KEY_BY:
      return { ...state, fetchSelectPermissionAdminsKeyBy: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_PAGE:
      return { ...state, fetchSelectPermissionAdminsPage: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_PER_PAGE:
      return { ...state, fetchSelectPermissionAdminsPerPage: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_INCLUDES:
      return { ...state, fetchSelectPermissionAdminsIncludes: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_FILTER_PERMISSIONS_IDS:
      return {
        ...state,
        fetchSelectPermissionAdminsFilterPermissionsIds: payload,
      };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_FILTER_IS_DEFAULT:
      return { ...state, fetchSelectPermissionAdminsFilterIsDefault: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_LOADING:
      return { ...state, fetchSelectPermissionAdminsLoading: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_SUCCESS:
      return { ...state, fetchSelectPermissionAdminsSuccess: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_ADMINS_FAILED:
      return { ...state, fetchSelectPermissionAdminsFailed: payload };

    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SEARCH:
      return { ...state, fetchSelectPermissionSubscribersSearch: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SORT:
      return { ...state, fetchSelectPermissionSubscribersSort: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY:
      return { ...state, fetchSelectPermissionSubscribersKeyBy: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PAGE:
      return { ...state, fetchSelectPermissionSubscribersPage: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE:
      return { ...state, fetchSelectPermissionSubscribersPerPage: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES:
      return { ...state, fetchSelectPermissionSubscribersIncludes: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS:
      return {
        ...state,
        fetchSelectPermissionSubscribersFilterPermissionsIds: payload,
      };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT:
      return {
        ...state,
        fetchSelectPermissionSubscribersFilterIsDefault: payload,
      };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_LOADING:
      return { ...state, fetchSelectPermissionSubscribersLoading: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS:
      return { ...state, fetchSelectPermissionSubscribersSuccess: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FAILED:
      return { ...state, fetchSelectPermissionSubscribersFailed: payload };

    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_SEARCH:
      return { ...state, appendSelectPermissionAdminsSearch: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_SORT:
      return { ...state, appendSelectPermissionAdminsSort: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_KEY_BY:
      return { ...state, appendSelectPermissionAdminsKeyBy: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_PAGE:
      return { ...state, appendSelectPermissionAdminsPage: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_PER_PAGE:
      return { ...state, appendSelectPermissionAdminsPerPage: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_INCLUDES:
      return { ...state, appendSelectPermissionAdminsIncludes: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_FILTER_PERMISSIONS_IDS:
      return {
        ...state,
        appendSelectPermissionAdminsFilterPermissionsIds: payload,
      };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_FILTER_IS_DEFAULT:
      return { ...state, appendSelectPermissionAdminsFilterIsDefault: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_LOADING:
      return { ...state, appendSelectPermissionAdminsLoading: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_SUCCESS:
      return { ...state, appendSelectPermissionAdminsSuccess: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_ADMINS_FAILED:
      return { ...state, appendSelectPermissionAdminsFailed: payload };

    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SEARCH:
      return { ...state, appendSelectPermissionSubscribersSearch: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SORT:
      return { ...state, appendSelectPermissionSubscribersSort: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY:
      return { ...state, appendSelectPermissionSubscribersKeyBy: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PAGE:
      return { ...state, appendSelectPermissionSubscribersPage: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE:
      return { ...state, appendSelectPermissionSubscribersPerPage: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES:
      return { ...state, appendSelectPermissionSubscribersIncludes: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS:
      return {
        ...state,
        appendSelectPermissionSubscribersFilterPermissionsIds: payload,
      };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT:
      return {
        ...state,
        appendSelectPermissionSubscribersFilterIsDefault: payload,
      };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_LOADING:
      return { ...state, appendSelectPermissionSubscribersLoading: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS:
      return { ...state, appendSelectPermissionSubscribersSuccess: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FAILED:
      return { ...state, appendSelectPermissionSubscribersFailed: payload };

    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_LOADING:
      return { ...state, fetchPermissionLoading: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_SUCCESS:
      return { ...state, fetchPermissionSuccess: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_FETCH_PERMISSION_FAILED:
      return { ...state, fetchPermissionFailed: payload };

    case PERMISSION_ADMIN_ACTION_TYPES.SET_CREATE_PERMISSION_LOADING:
      return { ...state, createPermissionLoading: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_CREATE_PERMISSION_SUCCESS:
      return { ...state, createPermissionSuccess: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_CREATE_PERMISSION_FAILED:
      return { ...state, createPermissionFailed: payload };

    case PERMISSION_ADMIN_ACTION_TYPES.SET_UPDATE_PERMISSION_LOADING:
      return { ...state, updatePermissionLoading: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_UPDATE_PERMISSION_SUCCESS:
      return { ...state, updatePermissionSuccess: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_UPDATE_PERMISSION_FAILED:
      return { ...state, updatePermissionFailed: payload };

    case PERMISSION_ADMIN_ACTION_TYPES.SET_DELETE_PERMISSION_LOADING:
      return { ...state, deletePermissionLoading: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_DELETE_PERMISSION_SUCCESS:
      return { ...state, deletePermissionSuccess: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_DELETE_PERMISSION_FAILED:
      return { ...state, deletePermissionFailed: payload };

    case PERMISSION_ADMIN_ACTION_TYPES.APPEND_PERMISSION_ADMINS:
      return {
        ...state,
        permissionAdmins: [...state.permissionAdmins, ...payload],
      };
    case PERMISSION_ADMIN_ACTION_TYPES.APPEND_PERMISSION_SUBSCRIBERS:
      return {
        ...state,
        permissionSubscribers: [...state.permissionSubscribers, ...payload],
      };
    case PERMISSION_ADMIN_ACTION_TYPES.APPEND_SELECT_PERMISSION_ADMINS:
      return {
        ...state,
        selectPermissionAdmins: {
          ...state.selectPermissionAdmins,
          ...payload,
        },
      };
    case PERMISSION_ADMIN_ACTION_TYPES.APPEND_SELECT_PERMISSION_SUBSCRIBERS:
      return {
        ...state,
        selectPermissionSubscribers: {
          ...state.selectPermissionSubscribers,
          ...payload,
        },
      };

    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_PERMISSION_ADMINS_HITTED:
      return { ...state, isFetchPermissionAdminsHitted: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED:
      return { ...state, isFetchPermissionSubscribersHitted: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_SELECT_PERMISSION_ADMINS_HITTED:
      return { ...state, isFetchSelectPermissionAdminsHitted: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_SELECT_PERMISSION_SUBSCRIBERS_HITTED:
      return { ...state, isFetchSelectPermissionSubscribersHitted: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_APPEND_SELECT_PERMISSION_ADMINS_HITTED:
      return { ...state, isAppendSelectPermissionAdminsHitted: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_APPEND_SELECT_PERMISSION_SUBSCRIBERS_HITTED:
      return { ...state, isAppendSelectPermissionSubscribersHitted: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_FETCH_PERMISSION_HITTED:
      return { ...state, isFetchPermissionHitted: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_CREATE_PERMISSION_HITTED:
      return { ...state, isCreatePermissionHitted: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_UPDATE_PERMISSION_HITTED:
      return { ...state, isUpdatePermissionHitted: payload };
    case PERMISSION_ADMIN_ACTION_TYPES.SET_IS_DELETE_PERMISSION_HITTED:
      return { ...state, isDeletePermissionHitted: payload };

    case PERMISSION_ADMIN_ACTION_TYPES.RESET_PERMISSION_ADMIN_REDUCER:
      return PERMISSION_ADMIN_INITIAL_STATE;
    default:
      return state;
  }
};
