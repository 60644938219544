import SELECT_PRODUCT_ACTION_TYPES from "./select-product.type";

const SELECT_PRODUCT_INITIAL_STATE = {
  products: {},
  searchProducts: {},

  isProductsHasMore: true,
  isSearchProductsHasMore: true,

  fetchProductsParams: {},
  fetchProductsLoading: false,
  fetchProductsSuccess: null,
  fetchProductsFailed: null,

  searchProductsParams: {},
  searchProductsLoading: false,
  searchProductsSuccess: null,
  searchProductsFailed: null,

  appendProductsParams: {},
  appendProductsLoading: false,
  appendProductsSuccess: null,
  appendProductsFailed: null,

  isFetchProductsHitted: false,
  isSearchProductsHitted: false,
  isAppendProductsHitted: false,
};

export const selectProductReducer = (
  state = SELECT_PRODUCT_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_PRODUCT_ACTION_TYPES.SET_PRODUCTS:
      return { ...state, products: payload };
    case SELECT_PRODUCT_ACTION_TYPES.SET_SEARCH_PRODUCTS:
      return { ...state, searchProducts: payload };

    case SELECT_PRODUCT_ACTION_TYPES.SET_IS_PRODUCTS_HAS_MORE:
      return { ...state, isProductsHasMore: payload };
    case SELECT_PRODUCT_ACTION_TYPES.SET_IS_SEARCH_PRODUCTS_HAS_MORE:
      return { ...state, isSearchProductsHasMore: payload };

    case SELECT_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_PARAMS:
      return { ...state, fetchProductsParams: payload };
    case SELECT_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_LOADING:
      return { ...state, fetchProductsLoading: payload };
    case SELECT_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SUCCESS:
      return { ...state, fetchProductsSuccess: payload };
    case SELECT_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FAILED:
      return { ...state, fetchProductsFailed: payload };

    case SELECT_PRODUCT_ACTION_TYPES.SET_SEARCH_PRODUCTS_PARAMS:
      return { ...state, searchProductsParams: payload };
    case SELECT_PRODUCT_ACTION_TYPES.SET_SEARCH_PRODUCTS_LOADING:
      return { ...state, searchProductsLoading: payload };
    case SELECT_PRODUCT_ACTION_TYPES.SET_SEARCH_PRODUCTS_SUCCESS:
      return { ...state, searchProductsSuccess: payload };
    case SELECT_PRODUCT_ACTION_TYPES.SET_SEARCH_PRODUCTS_FAILED:
      return { ...state, searchProductsFailed: payload };

    case SELECT_PRODUCT_ACTION_TYPES.SET_APPEND_PRODUCTS_PARAMS:
      return { ...state, appendProductsParams: payload };
    case SELECT_PRODUCT_ACTION_TYPES.SET_APPEND_PRODUCTS_LOADING:
      return { ...state, appendProductsLoading: payload };
    case SELECT_PRODUCT_ACTION_TYPES.SET_APPEND_PRODUCTS_SUCCESS:
      return { ...state, appendProductsSuccess: payload };
    case SELECT_PRODUCT_ACTION_TYPES.SET_APPEND_PRODUCTS_FAILED:
      return { ...state, appendProductsFailed: payload };

    case SELECT_PRODUCT_ACTION_TYPES.SET_IS_FETCH_PRODUCTS_HITTED:
      return { ...state, isFetchProductsHitted: payload };
    case SELECT_PRODUCT_ACTION_TYPES.SET_IS_SEARCH_PRODUCTS_HITTED:
      return { ...state, isSearchProductsHitted: payload };
    case SELECT_PRODUCT_ACTION_TYPES.SET_IS_APPEND_PRODUCTS_HITTED:
      return { ...state, isAppendProductsHitted: payload };

    case SELECT_PRODUCT_ACTION_TYPES.APPEND_PRODUCTS:
      return {
        ...state,
        products: { ...state.products, ...payload },
      };
    case SELECT_PRODUCT_ACTION_TYPES.APPEND_SEARCH_PRODUCTS:
      return {
        ...state,
        searchProducts: { ...state.searchProducts, ...payload },
      };

    case SELECT_PRODUCT_ACTION_TYPES.RESET_SELECT_PRODUCT_REDUCER:
      return SELECT_PRODUCT_INITIAL_STATE;
    default:
      return state;
  }
};
