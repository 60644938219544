const RECIPE_ACTION_TYPES = {
  RESET_RECIPE_REDUCER: "recipe/RESET_RECIPE_REDUCER",

  SET_RECIPES: "recipe/SET_RECIPES",
  SET_RECIPE: "recipe/SET_RECIPE",

  SET_IS_RECIPES_HAS_MORE: "recipe/SET_IS_RECIPES_HAS_MORE",

  SET_FETCH_RECIPES_SEARCH: "recipe/SET_FETCH_RECIPES_SEARCH",
  SET_FETCH_RECIPES_PAGE: "recipe/SET_FETCH_RECIPES_PAGE",
  SET_FETCH_RECIPES_PER_PAGE: "recipe/SET_FETCH_RECIPES_PER_PAGE",
  SET_FETCH_RECIPES_INCLUDES: "recipe/SET_FETCH_RECIPES_INCLUDES",
  SET_FETCH_RECIPES_FILTER_MARKET_ID: "recipe/SET_FETCH_RECIPES_FILTER_MARKET_ID",
  SET_FETCH_RECIPES_FILTER_BRANCH_ID: "recipe/SET_FETCH_RECIPES_FILTER_BRANCH_ID",
  SET_FETCH_RECIPES_LOADING: "recipe/SET_FETCH_RECIPES_LOADING",
  SET_FETCH_RECIPES_SUCCESS: "recipe/SET_FETCH_RECIPES_SUCCESS",
  SET_FETCH_RECIPES_FAILED: "recipe/SET_FETCH_RECIPES_FAILED",

  SET_FETCH_RECIPE_LOADING: "recipe/SET_FETCH_RECIPE_LOADING",
  SET_FETCH_RECIPE_SUCCESS: "recipe/SET_FETCH_RECIPE_SUCCESS",
  SET_FETCH_RECIPE_FAILED: "recipe/SET_FETCH_RECIPE_FAILED",

  SET_CREATE_RECIPE_LOADING: "recipe/SET_CREATE_RECIPE_LOADING",
  SET_CREATE_RECIPE_SUCCESS: "recipe/SET_CREATE_RECIPE_SUCCESS",
  SET_CREATE_RECIPE_FAILED: "recipe/SET_CREATE_RECIPE_FAILED",

  SET_UPDATE_RECIPE_LOADING: "recipe/SET_UPDATE_RECIPE_LOADING",
  SET_UPDATE_RECIPE_SUCCESS: "recipe/SET_UPDATE_RECIPE_SUCCESS",
  SET_UPDATE_RECIPE_FAILED: "recipe/SET_UPDATE_RECIPE_FAILED",

  SET_DELETE_RECIPE_LOADING: "recipe/SET_DELETE_RECIPE_LOADING",
  SET_DELETE_RECIPE_SUCCESS: "recipe/SET_DELETE_RECIPE_SUCCESS",
  SET_DELETE_RECIPE_FAILED: "recipe/SET_DELETE_RECIPE_FAILED",

  APPEND_RECIPES: "recipe/APPEND_RECIPES",

  SET_IS_FETCH_RECIPES_HITTED: "recipe/SET_IS_FETCH_RECIPES_HITTED",
  SET_IS_FETCH_RECIPE_HITTED: "recipe/SET_IS_FETCH_RECIPE_HITTED",
  SET_IS_CREATE_RECIPE_HITTED: "recipe/SET_IS_CREATE_RECIPE_HITTED",
  SET_IS_UPDATE_RECIPE_HITTED: "recipe/SET_IS_UPDATE_RECIPE_HITTED",
  SET_IS_DELETE_RECIPE_HITTED: "recipe/SET_IS_DELETE_RECIPE_HITTED",

  FETCH_RECIPES_START: "recipe/FETCH_RECIPES_START",
  FETCH_RECIPE_START: "recipe/FETCH_RECIPE_START",
  CREATE_RECIPE_START: "recipe/CREATE_RECIPE_START",
  UPDATE_RECIPE_START: "recipe/UPDATE_RECIPE_START",
  DELETE_RECIPE_START: "recipe/DELETE_RECIPE_START",
};

export default RECIPE_ACTION_TYPES;
