import { TAX_VALUE_TYPES } from "../../constants/tax.constant";

import TAX_ACTION_TYPES from "./tax.type";

export const TAX_INITIAL_STATE = {
  taxValueTypes: [
    {
      label: "Percentage %",
      value: TAX_VALUE_TYPES.PERCENT,
    },
    {
      label: "Fixed Price",
      value: TAX_VALUE_TYPES.NOMINAL,
    },
  ],
  taxes: [],
  tax: null,

  isTaxesHasMore: true,

  fetchTaxesSearch: null,
  fetchTaxesSort: null,
  fetchTaxesKeyBy: null,
  fetchTaxesPage: 1,
  fetchTaxesPerPage: null,
  fetchTaxesIncludes: null,
  fetchTaxesFilterMarketId: null,
  fetchTaxesFilterBranchId: null,
  fetchTaxesLoading: false,
  fetchTaxesSuccess: null,
  fetchTaxesFailed: null,

  fetchTaxLoading: false,
  fetchTaxSuccess: null,
  fetchTaxFailed: null,

  createTaxLoading: false,
  createTaxSuccess: null,
  createTaxFailed: null,

  updateTaxLoading: false,
  updateTaxSuccess: null,
  updateTaxFailed: null,

  deleteTaxLoading: false,
  deleteTaxSuccess: null,
  deleteTaxFailed: null,

  sortTaxesLoading: false,
  sortTaxesSuccess: null,
  sortTaxesFailed: null,

  isFetchTaxesHitted: false,
  isFetchTaxHitted: false,
  isCreateTaxHitted: false,
  isUpdateTaxHitted: false,
  isDeleteTaxHitted: false,
  isSortTaxesHitted: false,
};

export const taxReducer = (state = TAX_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TAX_ACTION_TYPES.SET_TAXES:
      return { ...state, taxes: payload };
    case TAX_ACTION_TYPES.SET_TAX:
      return { ...state, tax: payload };

    case TAX_ACTION_TYPES.SET_IS_TAXES_HAS_MORE:
      return { ...state, isTaxesHasMore: payload };

    case TAX_ACTION_TYPES.SET_FETCH_TAXES_SEARCH:
      return { ...state, fetchTaxesSearch: payload };
    case TAX_ACTION_TYPES.SET_FETCH_TAXES_SORT:
      return { ...state, fetchTaxesSort: payload };
    case TAX_ACTION_TYPES.SET_FETCH_TAXES_KEY_BY:
      return { ...state, fetchTaxesKeyBy: payload };
    case TAX_ACTION_TYPES.SET_FETCH_TAXES_SEARCH:
      return { ...state, fetchTaxesSearch: payload };
    case TAX_ACTION_TYPES.SET_FETCH_TAXES_PAGE:
      return { ...state, fetchTaxesPage: payload };
    case TAX_ACTION_TYPES.SET_FETCH_TAXES_PER_PAGE:
      return { ...state, fetchTaxesPerPage: payload };
    case TAX_ACTION_TYPES.SET_FETCH_TAXES_INCLUDES:
      return { ...state, fetchTaxesIncludes: payload };
    case TAX_ACTION_TYPES.SET_FETCH_TAXES_FILTER_MARKET_ID:
      return { ...state, fetchTaxesFilterMarketId: payload };
    case TAX_ACTION_TYPES.SET_FETCH_TAXES_FILTER_BRANCH_ID:
      return { ...state, fetchTaxesFilterBranchId: payload };
    case TAX_ACTION_TYPES.SET_FETCH_TAXES_LOADING:
      return { ...state, fetchTaxesLoading: payload };
    case TAX_ACTION_TYPES.SET_FETCH_TAXES_SUCCESS:
      return { ...state, fetchTaxesSuccess: payload };
    case TAX_ACTION_TYPES.SET_FETCH_TAXES_FAILED:
      return { ...state, fetchTaxesFailed: payload };

    case TAX_ACTION_TYPES.SET_FETCH_TAX_LOADING:
      return { ...state, fetchTaxLoading: payload };
    case TAX_ACTION_TYPES.SET_FETCH_TAX_SUCCESS:
      return { ...state, fetchTaxSuccess: payload };
    case TAX_ACTION_TYPES.SET_FETCH_TAX_FAILED:
      return { ...state, fetchTaxFailed: payload };

    case TAX_ACTION_TYPES.SET_CREATE_TAX_LOADING:
      return { ...state, createTaxLoading: payload };
    case TAX_ACTION_TYPES.SET_CREATE_TAX_SUCCESS:
      return { ...state, createTaxSuccess: payload };
    case TAX_ACTION_TYPES.SET_CREATE_TAX_FAILED:
      return { ...state, createTaxFailed: payload };

    case TAX_ACTION_TYPES.SET_UPDATE_TAX_LOADING:
      return { ...state, updateTaxLoading: payload };
    case TAX_ACTION_TYPES.SET_UPDATE_TAX_SUCCESS:
      return { ...state, updateTaxSuccess: payload };
    case TAX_ACTION_TYPES.SET_UPDATE_TAX_FAILED:
      return { ...state, updateTaxFailed: payload };

    case TAX_ACTION_TYPES.SET_DELETE_TAX_LOADING:
      return { ...state, deleteTaxLoading: payload };
    case TAX_ACTION_TYPES.SET_DELETE_TAX_SUCCESS:
      return { ...state, deleteTaxSuccess: payload };
    case TAX_ACTION_TYPES.SET_DELETE_TAX_FAILED:
      return { ...state, deleteTaxFailed: payload };

    case TAX_ACTION_TYPES.SET_SORT_TAXES_LOADING:
      return { ...state, sortTaxesLoading: payload };
    case TAX_ACTION_TYPES.SET_SORT_TAXES_SUCCESS:
      return { ...state, sortTaxesSuccess: payload };
    case TAX_ACTION_TYPES.SET_SORT_TAXES_FAILED:
      return { ...state, sortTaxesFailed: payload };

    case TAX_ACTION_TYPES.APPEND_TAXES:
      return { ...state, taxes: [...state.taxes, ...payload] };

    case TAX_ACTION_TYPES.SET_IS_FETCH_TAXES_HITTED:
      return { ...state, isFetchTaxesHitted: payload };
    case TAX_ACTION_TYPES.SET_IS_FETCH_TAX_HITTED:
      return { ...state, isFetchTaxHitted: payload };
    case TAX_ACTION_TYPES.SET_IS_CREATE_TAX_HITTED:
      return { ...state, isCreateTaxHitted: payload };
    case TAX_ACTION_TYPES.SET_IS_UPDATE_TAX_HITTED:
      return { ...state, isUpdateTaxHitted: payload };
    case TAX_ACTION_TYPES.SET_IS_DELETE_TAX_HITTED:
      return { ...state, isDeleteTaxHitted: payload };
    case TAX_ACTION_TYPES.SET_IS_SORT_TAXES_HITTED:
      return { ...state, isSortTaxesHitted: payload };

    case TAX_ACTION_TYPES.RESET_TAX_REDUCER:
      return TAX_INITIAL_STATE;
    default:
      return state;
  }
};
