import { takeLatest, put, all, call, select } from "redux-saga/effects";

import ATTENDANCE_ACTION_TYPES from "./attendance.type";

import {
  appendAttendances,
  setCreateOrUpdateAttendanceFailed,
  setCreateOrUpdateAttendanceLoading,
  setCreateOrUpdateAttendanceSuccess,
  setFetchAttendanceFailed,
  setFetchAttendanceLoading,
  setFetchAttendancesFailed,
  setFetchAttendancesLoading,
  setFetchAttendancesSuccess,
  setFetchAttendanceSuccess,
  setIsAttendancesHasMore,
  setAttendance,
  setAttendances,
  setIsFetchAttendancesHitted,
  setIsFetchAttendanceHitted,
  setIsCreateOrUpdateAttendanceHitted,
} from "./attendance.action";
import {
  getFetchAttendanceBranchId,
  getFetchAttendanceMarketId,
  getFetchAttendancesFilterBranchId,
  getFetchAttendancesFilterMarketId,
  getFetchAttendancesFilterUserId,
  getFetchAttendancesIncludes,
  getFetchAttendancesPage,
  getFetchAttendancesPerPage,
  getFetchAttendancesSearch,
  getFetchAttendancesSort,
} from "./attendance.selector";

import {
  getAttendances,
  getAttendance,
  createOrUpdateAttendance,
} from "../../api/attendance.api";

export function* _getAttendances() {
  try {
    yield put(setFetchAttendancesLoading(true));

    const search = yield select(getFetchAttendancesSearch);
    const sort = yield select(getFetchAttendancesSort);
    const page = yield select(getFetchAttendancesPage);
    const per_page = yield select(getFetchAttendancesPerPage);
    const includes = yield select(getFetchAttendancesIncludes);
    const market_id = yield select(getFetchAttendancesFilterMarketId);
    const branch_id = yield select(getFetchAttendancesFilterBranchId);
    const user_id = yield select(getFetchAttendancesFilterUserId);

    const parameters = {
      search,
      sort,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        user_id,
      },
    };

    const {
      meta: { message },
      data: { data: attendances },
    } = yield call(getAttendances, parameters);

    yield put(setIsFetchAttendancesHitted(true));
    yield put(setIsAttendancesHasMore(attendances.length > 0));

    if (page > 1) {
      yield put(appendAttendances(attendances));
    } else {
      yield put(setAttendances(attendances));
    }

    yield put(setFetchAttendancesSuccess(message));
    yield put(setFetchAttendancesLoading(false));
  } catch (error) {
    yield put(setFetchAttendancesFailed(error));
    yield put(setFetchAttendancesLoading(false));
  }
}

export function* _getAttendance({ payload: id }) {
  try {
    yield put(setFetchAttendanceLoading(true));

    const market_id = yield select(getFetchAttendanceMarketId);
    const branch_id = yield select(getFetchAttendanceBranchId);

    const parameters = { market_id, branch_id };

    const {
      meta: { message },
      data: attendance,
    } = yield call(getAttendance, id, parameters);

    yield put(setIsFetchAttendanceHitted(true));
    yield put(setAttendance(attendance));

    yield put(setFetchAttendanceSuccess(message));
    yield put(setFetchAttendanceLoading(false));
  } catch (error) {
    yield put(setFetchAttendanceFailed(error));
    yield put(setFetchAttendanceLoading(false));
  }
}

export function* _createOrUpdateAttendance({ payload: request }) {
  try {
    yield put(setCreateOrUpdateAttendanceLoading(true));

    const {
      meta: { message },
      data: attendance,
    } = yield call(createOrUpdateAttendance, request);

    yield put(setIsCreateOrUpdateAttendanceHitted(true));

    yield put(setCreateOrUpdateAttendanceSuccess(message));
    yield put(setCreateOrUpdateAttendanceLoading(false));

    yield put(setAttendance(attendance));
  } catch (error) {
    yield put(setCreateOrUpdateAttendanceFailed(error));
    yield put(setCreateOrUpdateAttendanceLoading(false));
  }
}

export function* onFetchAttendancesStart() {
  yield takeLatest(
    ATTENDANCE_ACTION_TYPES.FETCH_ATTENDANCES_START,
    _getAttendances
  );
}

export function* onFetchAttendanceStart() {
  yield takeLatest(
    ATTENDANCE_ACTION_TYPES.FETCH_ATTENDANCE_START,
    _getAttendance
  );
}

export function* onCreateOrUpdateAttendanceStart() {
  yield takeLatest(
    ATTENDANCE_ACTION_TYPES.CREATE_OR_UPDATE_ATTENDANCE_START,
    _createOrUpdateAttendance
  );
}

export function* attendanceSaga() {
  yield all([
    call(onFetchAttendancesStart),
    call(onFetchAttendanceStart),
    call(onCreateOrUpdateAttendanceStart),
  ]);
}
