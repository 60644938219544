import apiService from "./api";

export const getDashboard = async (parameters) =>
  (await apiService.get("/v2/dashboards", parameters)).data;
export const getDashboardAdmin = async (parameters) =>
  (await apiService.get("/v2/dashboards/admin", parameters)).data;
export const getDashboardMerchant = async (parameters) =>
  (await apiService.get("/v2/dashboards/merchant", parameters)).data;
export const getDashboardSubscriber = async (parameters) =>
  (await apiService.get("/v2/dashboards/subscriber", parameters)).data;
