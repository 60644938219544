import DASHBOARD_MERCHANT_ACTION_TYPES from "./dashboard-merchant.type";
import { createAction } from "../../utils/store.utils";

export const setDashboardTotalMarket = (dashboardTotalMarket) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_MARKET,
    dashboardTotalMarket
  );
export const setDashboardTotalBranch = (dashboardTotalBranch) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_BRANCH,
    dashboardTotalBranch
  );
export const setDashboardTotalOrder = (dashboardTotalOrder) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_ORDER,
    dashboardTotalOrder
  );
export const setDashboardTotalRevenue = (dashboardTotalRevenue) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_REVENUE,
    dashboardTotalRevenue
  );
export const setDashboardOrdersChart = (dashboardOrdersChart) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_ORDERS_CHART,
    dashboardOrdersChart
  );
export const setDashboardLatestMarkets = (dashboardLatestMarkets) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_LATEST_MARKETS,
    dashboardLatestMarkets
  );

export const setDashboardTotalMarketLoading = (dashboardTotalMarketLoading) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_MARKET_LOADING,
    dashboardTotalMarketLoading
  );
export const setDashboardTotalBranchLoading = (dashboardTotalBranchLoading) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_BRANCH_LOADING,
    dashboardTotalBranchLoading
  );
export const setDashboardTotalOrderLoading = (dashboardTotalOrderLoading) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_ORDER_LOADING,
    dashboardTotalOrderLoading
  );
export const setDashboardTotalRevenueLoading = (dashboardTotalRevenueLoading) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_TOTAL_REVENUE_LOADING,
    dashboardTotalRevenueLoading
  );
export const setDashboardOrdersChartLoading = (dashboardOrdersChartLoading) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_ORDERS_CHART_LOADING,
    dashboardOrdersChartLoading
  );
export const setDashboardLatestMarketsLoading = (
  dashboardLatestMarketsLoading
) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_DASHBOARD_LATEST_MARKETS_LOADING,
    dashboardLatestMarketsLoading
  );

export const setFetchDashboardMerchantFilterCreatedAtBefore = (
  fetchDashboardMerchantFilterCreatedAtBefore
) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_FETCH_DASHBOARD_MERCHANT_FILTER_CREATED_AT_BEFORE,
    fetchDashboardMerchantFilterCreatedAtBefore
  );
export const setFetchDashboardMerchantFilterCreatedAtAfter = (
  fetchDashboardMerchantFilterCreatedAtAfter
) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_FETCH_DASHBOARD_MERCHANT_FILTER_CREATED_AT_AFTER,
    fetchDashboardMerchantFilterCreatedAtAfter
  );
export const setFetchDashboardMerchantFilterCurrency = (
  fetchDashboardMerchantFilterCurrency
) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_FETCH_DASHBOARD_MERCHANT_FILTER_CURRENCY,
    fetchDashboardMerchantFilterCurrency
  );
export const setFetchDashboardMerchantLoading = (
  fetchDashboardMerchantLoading
) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_FETCH_DASHBOARD_MERCHANT_LOADING,
    fetchDashboardMerchantLoading
  );
export const setFetchDashboardMerchantSuccess = (
  fetchDashboardMerchantSuccess
) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_FETCH_DASHBOARD_MERCHANT_SUCCESS,
    fetchDashboardMerchantSuccess
  );
export const setFetchDashboardMerchantFailed = (fetchDashboardMerchantFailed) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_FETCH_DASHBOARD_MERCHANT_FAILED,
    fetchDashboardMerchantFailed
  );

export const setIsFetchDashboardMerchantHitted = (
  isFetchDashboardMerchantHitted
) =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.SET_IS_FETCH_DASHBOARD_MERCHANT_HITTED,
    isFetchDashboardMerchantHitted
  );

export const fetchDashboardMerchantStart = () =>
  createAction(DASHBOARD_MERCHANT_ACTION_TYPES.FETCH_DASHBOARD_MERCHANT_START);

export const resetDashboardMerchantReducer = () =>
  createAction(
    DASHBOARD_MERCHANT_ACTION_TYPES.RESET_DASHBOARD_MERCHANT_REDUCER
  );
