import { PAYMENT_DEVICE_COMPANIES, PAYMENT_DEVICE_TARGETS } from "../../constants/payment-device.constant";

import PAYMENT_DEVICE_ACTION_TYPES from "./payment-device.type";

export const PAYMENT_DEVICE_INITIAL_STATE = {
  paymentDeviceTargets: [
    {
      label: "All Pages",
      value: PAYMENT_DEVICE_TARGETS.ALL_PAGES,
    },
    {
      label: "Customer Screen",
      value: PAYMENT_DEVICE_TARGETS.CUSTOMER_SCREEN,
    },
    {
      label: "Order",
      value: PAYMENT_DEVICE_TARGETS.ORDER,
    },
  ],
  paymentDeviceCompanies: [
    {
      label: "Near Pay",
      value: PAYMENT_DEVICE_COMPANIES.NEAR_PAY,
    },
    {
      label: "Digital Pay",
      value: PAYMENT_DEVICE_COMPANIES.DIGITAL_PAY,
    },
  ],
  paymentDevices: [],
  paymentDevice: null,

  isPaymentDevicesHasMore: true,

  fetchPaymentDevicesSearch: null,
  fetchPaymentDevicesPage: 1,
  fetchPaymentDevicesPerPage: null,
  fetchPaymentDevicesIncludes: null,
  fetchPaymentDevicesFilterMarketId: null,
  fetchPaymentDevicesFilterBranchId: null,
  fetchPaymentDevicesFilterPaymentDevicesIds: null,
  fetchPaymentDevicesFilterUsersIds: null,
  fetchPaymentDevicesLoading: false,
  fetchPaymentDevicesSuccess: null,
  fetchPaymentDevicesFailed: null,

  fetchPaymentDeviceLoading: false,
  fetchPaymentDeviceSuccess: null,
  fetchPaymentDeviceFailed: null,

  createPaymentDeviceLoading: false,
  createPaymentDeviceSuccess: null,
  createPaymentDeviceFailed: null,

  updatePaymentDeviceLoading: false,
  updatePaymentDeviceSuccess: null,
  updatePaymentDeviceFailed: null,

  deletePaymentDeviceLoading: false,
  deletePaymentDeviceSuccess: null,
  deletePaymentDeviceFailed: null,

  isFetchPaymentDevicesHitted: false,
  isFetchPaymentDeviceHitted: false,
  isCreatePaymentDeviceHitted: false,
  isUpdatePaymentDeviceHitted: false,
  isDeletePaymentDeviceHitted: false,
};

export const paymentDeviceReducer = (state = PAYMENT_DEVICE_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PAYMENT_DEVICE_ACTION_TYPES.SET_PAYMENT_DEVICES:
      return { ...state, paymentDevices: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_PAYMENT_DEVICE:
      return { ...state, paymentDevice: payload };

    case PAYMENT_DEVICE_ACTION_TYPES.SET_IS_PAYMENT_DEVICES_HAS_MORE:
      return { ...state, isPaymentDevicesHasMore: payload };

    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_SEARCH:
      return { ...state, fetchPaymentDevicesSearch: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_PAGE:
      return { ...state, fetchPaymentDevicesPage: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_PER_PAGE:
      return { ...state, fetchPaymentDevicesPerPage: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_INCLUDES:
      return { ...state, fetchPaymentDevicesIncludes: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_FILTER_MARKET_ID:
      return { ...state, fetchPaymentDevicesFilterMarketId: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_FILTER_BRANCH_ID:
      return { ...state, fetchPaymentDevicesFilterBranchId: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_FILTER_PAYMENT_DEVICES_IDS:
      return { ...state, fetchPaymentDevicesFilterPaymentDevicesIds: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_FILTER_USERS_IDS:
      return { ...state, fetchPaymentDevicesFilterUsersIds: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_LOADING:
      return { ...state, fetchPaymentDevicesLoading: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_SUCCESS:
      return { ...state, fetchPaymentDevicesSuccess: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_FAILED:
      return { ...state, fetchPaymentDevicesFailed: payload };

    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICE_LOADING:
      return { ...state, fetchPaymentDeviceLoading: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICE_SUCCESS:
      return { ...state, fetchPaymentDeviceSuccess: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICE_FAILED:
      return { ...state, fetchPaymentDeviceFailed: payload };

    case PAYMENT_DEVICE_ACTION_TYPES.SET_CREATE_PAYMENT_DEVICE_LOADING:
      return { ...state, createPaymentDeviceLoading: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_CREATE_PAYMENT_DEVICE_SUCCESS:
      return { ...state, createPaymentDeviceSuccess: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_CREATE_PAYMENT_DEVICE_FAILED:
      return { ...state, createPaymentDeviceFailed: payload };

    case PAYMENT_DEVICE_ACTION_TYPES.SET_UPDATE_PAYMENT_DEVICE_LOADING:
      return { ...state, updatePaymentDeviceLoading: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_UPDATE_PAYMENT_DEVICE_SUCCESS:
      return { ...state, updatePaymentDeviceSuccess: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_UPDATE_PAYMENT_DEVICE_FAILED:
      return { ...state, updatePaymentDeviceFailed: payload };

    case PAYMENT_DEVICE_ACTION_TYPES.SET_DELETE_PAYMENT_DEVICE_LOADING:
      return { ...state, deletePaymentDeviceLoading: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_DELETE_PAYMENT_DEVICE_SUCCESS:
      return { ...state, deletePaymentDeviceSuccess: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_DELETE_PAYMENT_DEVICE_FAILED:
      return { ...state, deletePaymentDeviceFailed: payload };

    case PAYMENT_DEVICE_ACTION_TYPES.APPEND_PAYMENT_DEVICES:
      return { ...state, paymentDevices: [...state.paymentDevices, ...payload] };

    case PAYMENT_DEVICE_ACTION_TYPES.SET_IS_FETCH_PAYMENT_DEVICES_HITTED:
      return { ...state, isFetchPaymentDevicesHitted: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_IS_FETCH_PAYMENT_DEVICE_HITTED:
      return { ...state, isFetchPaymentDeviceHitted: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_IS_CREATE_PAYMENT_DEVICE_HITTED:
      return { ...state, isCreatePaymentDeviceHitted: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_IS_UPDATE_PAYMENT_DEVICE_HITTED:
      return { ...state, isUpdatePaymentDeviceHitted: payload };
    case PAYMENT_DEVICE_ACTION_TYPES.SET_IS_DELETE_PAYMENT_DEVICE_HITTED:
      return { ...state, isDeletePaymentDeviceHitted: payload };

    case PAYMENT_DEVICE_ACTION_TYPES.RESET_PAYMENT_DEVICE_REDUCER:
      return PAYMENT_DEVICE_INITIAL_STATE;
    default:
      return state;
  }
};
