import ADMIN_ACTION_TYPES from "./admin.type";
import { createAction } from "../../utils/store.utils";

export const setAdmins = (admins) => createAction(ADMIN_ACTION_TYPES.SET_ADMINS, admins);

export const setAdmin = (admin) => createAction(ADMIN_ACTION_TYPES.SET_ADMIN, admin);

export const setIsAdminsHasMore = (isAdminsHasMore) =>
  createAction(ADMIN_ACTION_TYPES.SET_IS_ADMINS_HAS_MORE, isAdminsHasMore);

export const setFetchAdminsSearch = (fetchAdminsSearch) =>
  createAction(ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_SEARCH, fetchAdminsSearch);

export const setFetchAdminsPage = (fetchAdminsPage) =>
  createAction(ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_PAGE, fetchAdminsPage);

export const setFetchAdminsPerPage = (fetchAdminsPerPage) =>
  createAction(ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_PER_PAGE, fetchAdminsPerPage);

export const setFetchAdminsIncludes = (fetchAdminsIncludes) =>
  createAction(ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_INCLUDES, fetchAdminsIncludes);

export const setFetchAdminsFilterPermissionId = (fetchAdminsFilterPermissionId) =>
  createAction(ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_FILTER_PERMISSION_ID, fetchAdminsFilterPermissionId);

export const setFetchAdminsLoading = (fetchAdminsLoading) =>
  createAction(ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_LOADING, fetchAdminsLoading);

export const setFetchAdminsSuccess = (fetchAdminsSuccess) =>
  createAction(ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_SUCCESS, fetchAdminsSuccess);

export const setFetchAdminsFailed = (fetchAdminsFailed) =>
  createAction(ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_FAILED, fetchAdminsFailed);

export const setFetchAdminLoading = (fetchAdminLoading) =>
  createAction(ADMIN_ACTION_TYPES.SET_FETCH_ADMIN_LOADING, fetchAdminLoading);

export const setFetchAdminSuccess = (fetchAdminSuccess) =>
  createAction(ADMIN_ACTION_TYPES.SET_FETCH_ADMIN_SUCCESS, fetchAdminSuccess);

export const setFetchAdminFailed = (fetchAdminFailed) =>
  createAction(ADMIN_ACTION_TYPES.SET_FETCH_ADMIN_FAILED, fetchAdminFailed);

export const setCreateAdminLoading = (createAdminLoading) =>
  createAction(ADMIN_ACTION_TYPES.SET_CREATE_ADMIN_LOADING, createAdminLoading);

export const setCreateAdminSuccess = (createAdminSuccess) =>
  createAction(ADMIN_ACTION_TYPES.SET_CREATE_ADMIN_SUCCESS, createAdminSuccess);

export const setCreateAdminFailed = (createAdminFailed) =>
  createAction(ADMIN_ACTION_TYPES.SET_CREATE_ADMIN_FAILED, createAdminFailed);

export const setUpdateAdminLoading = (updateAdminLoading) =>
  createAction(ADMIN_ACTION_TYPES.SET_UPDATE_ADMIN_LOADING, updateAdminLoading);

export const setUpdateAdminSuccess = (updateAdminSuccess) =>
  createAction(ADMIN_ACTION_TYPES.SET_UPDATE_ADMIN_SUCCESS, updateAdminSuccess);

export const setUpdateAdminFailed = (updateAdminFailed) =>
  createAction(ADMIN_ACTION_TYPES.SET_UPDATE_ADMIN_FAILED, updateAdminFailed);

export const setDeleteAdminLoading = (deleteAdminLoading) =>
  createAction(ADMIN_ACTION_TYPES.SET_DELETE_ADMIN_LOADING, deleteAdminLoading);

export const setDeleteAdminSuccess = (deleteAdminSuccess) =>
  createAction(ADMIN_ACTION_TYPES.SET_DELETE_ADMIN_SUCCESS, deleteAdminSuccess);

export const setDeleteAdminFailed = (deleteAdminFailed) =>
  createAction(ADMIN_ACTION_TYPES.SET_DELETE_ADMIN_FAILED, deleteAdminFailed);

export const appendAdmins = (admins) => createAction(ADMIN_ACTION_TYPES.APPEND_ADMINS, admins);

export const fetchAdminsStart = () => createAction(ADMIN_ACTION_TYPES.FETCH_ADMINS_START);

export const fetchAdminStart = (id) => createAction(ADMIN_ACTION_TYPES.FETCH_ADMIN_START, id);

export const createAdminStart = (request) => createAction(ADMIN_ACTION_TYPES.CREATE_ADMIN_START, request);

export const updateAdminStart = (id, request) => createAction(ADMIN_ACTION_TYPES.UPDATE_ADMIN_START, { id, request });

export const deleteAdminStart = (id) => createAction(ADMIN_ACTION_TYPES.DELETE_ADMIN_START, id);

export const setIsFetchAdminsHitted = (isFetchAdminsHitted) =>
  createAction(ADMIN_ACTION_TYPES.SET_IS_FETCH_ADMINS_HITTED, isFetchAdminsHitted);

export const setIsFetchAdminHitted = (isFetchAdminHitted) =>
  createAction(ADMIN_ACTION_TYPES.SET_IS_FETCH_ADMIN_HITTED, isFetchAdminHitted);

export const setIsCreateAdminHitted = (isCreateAdminHitted) =>
  createAction(ADMIN_ACTION_TYPES.SET_IS_CREATE_ADMIN_HITTED, isCreateAdminHitted);

export const setIsUpdateAdminHitted = (isUpdateAdminHitted) =>
  createAction(ADMIN_ACTION_TYPES.SET_IS_UPDATE_ADMIN_HITTED, isUpdateAdminHitted);

export const setIsDeleteAdminHitted = (isDeleteAdminHitted) =>
  createAction(ADMIN_ACTION_TYPES.SET_IS_DELETE_ADMIN_HITTED, isDeleteAdminHitted);

export const resetAdminReducer = () => createAction(ADMIN_ACTION_TYPES.RESET_ADMIN_REDUCER);
