const ADVERTISEMENT_ACTION_TYPES = {
  RESET_ADVERTISEMENT_REDUCER: "advertisement/RESET_ADVERTISEMENT_REDUCER",

  SET_ADVERTISEMENTS: "advertisement/SET_ADVERTISEMENTS",
  SET_ADVERTISEMENT: "advertisement/SET_ADVERTISEMENT",

  SET_IS_ADVERTISEMENTS_HAS_MORE: "advertisement/SET_IS_ADVERTISEMENTS_HAS_MORE",

  SET_FETCH_ADVERTISEMENTS_SEARCH: "advertisement/SET_FETCH_ADVERTISEMENTS_SEARCH",
  SET_FETCH_ADVERTISEMENTS_PAGE: "advertisement/SET_FETCH_ADVERTISEMENTS_PAGE",
  SET_FETCH_ADVERTISEMENTS_PER_PAGE: "advertisement/SET_FETCH_ADVERTISEMENTS_PER_PAGE",
  SET_FETCH_ADVERTISEMENTS_INCLUDES: "advertisement/SET_FETCH_ADVERTISEMENTS_INCLUDES",
  SET_FETCH_ADVERTISEMENTS_FILTER_MARKET_ID: "advertisement/SET_FETCH_ADVERTISEMENTS_FILTER_MARKET_ID",
  SET_FETCH_ADVERTISEMENTS_FILTER_TYPE: "advertisement/SET_FETCH_ADVERTISEMENTS_FILTER_TYPE",
  SET_FETCH_ADVERTISEMENTS_FILTER_STATUSES: "advertisement/SET_FETCH_ADVERTISEMENTS_FILTER_STATUSES",
  SET_FETCH_ADVERTISEMENTS_FILTER_START_AT: "advertisement/SET_FETCH_ADVERTISEMENTS_FILTER_START_AT",
  SET_FETCH_ADVERTISEMENTS_FILTER_END_AT: "advertisement/SET_FETCH_ADVERTISEMENTS_FILTER_END_AT",
  SET_FETCH_ADVERTISEMENTS_LOADING: "advertisement/SET_FETCH_ADVERTISEMENTS_LOADING",
  SET_FETCH_ADVERTISEMENTS_SUCCESS: "advertisement/SET_FETCH_ADVERTISEMENTS_SUCCESS",
  SET_FETCH_ADVERTISEMENTS_FAILED: "advertisement/SET_FETCH_ADVERTISEMENTS_FAILED",

  SET_FETCH_ADVERTISEMENT_LOADING: "advertisement/SET_FETCH_ADVERTISEMENT_LOADING",
  SET_FETCH_ADVERTISEMENT_SUCCESS: "advertisement/SET_FETCH_ADVERTISEMENT_SUCCESS",
  SET_FETCH_ADVERTISEMENT_FAILED: "advertisement/SET_FETCH_ADVERTISEMENT_FAILED",

  SET_CREATE_ADVERTISEMENT_LOADING: "advertisement/SET_CREATE_ADVERTISEMENT_LOADING",
  SET_CREATE_ADVERTISEMENT_SUCCESS: "advertisement/SET_CREATE_ADVERTISEMENT_SUCCESS",
  SET_CREATE_ADVERTISEMENT_FAILED: "advertisement/SET_CREATE_ADVERTISEMENT_FAILED",

  SET_UPDATE_ADVERTISEMENT_LOADING: "advertisement/SET_UPDATE_ADVERTISEMENT_LOADING",
  SET_UPDATE_ADVERTISEMENT_SUCCESS: "advertisement/SET_UPDATE_ADVERTISEMENT_SUCCESS",
  SET_UPDATE_ADVERTISEMENT_FAILED: "advertisement/SET_UPDATE_ADVERTISEMENT_FAILED",

  SET_DELETE_ADVERTISEMENT_LOADING: "advertisement/SET_DELETE_ADVERTISEMENT_LOADING",
  SET_DELETE_ADVERTISEMENT_SUCCESS: "advertisement/SET_DELETE_ADVERTISEMENT_SUCCESS",
  SET_DELETE_ADVERTISEMENT_FAILED: "advertisement/SET_DELETE_ADVERTISEMENT_FAILED",

  APPEND_ADVERTISEMENTS: "advertisement/APPEND_ADVERTISEMENTS",

  SET_IS_FETCH_ADVERTISEMENTS_HITTED: "advertisement/SET_IS_FETCH_ADVERTISEMENTS_HITTED",
  SET_IS_FETCH_ADVERTISEMENT_HITTED: "advertisement/SET_IS_FETCH_ADVERTISEMENT_HITTED",
  SET_IS_CREATE_ADVERTISEMENT_HITTED: "advertisement/SET_IS_CREATE_ADVERTISEMENT_HITTED",
  SET_IS_UPDATE_ADVERTISEMENT_HITTED: "advertisement/SET_IS_UPDATE_ADVERTISEMENT_HITTED",
  SET_IS_DELETE_ADVERTISEMENT_HITTED: "advertisement/SET_IS_DELETE_ADVERTISEMENT_HITTED",

  FETCH_ADVERTISEMENTS_START: "advertisement/FETCH_ADVERTISEMENTS_START",
  FETCH_ADVERTISEMENT_START: "advertisement/FETCH_ADVERTISEMENT_START",
  CREATE_ADVERTISEMENT_START: "advertisement/CREATE_ADVERTISEMENT_START",
  UPDATE_ADVERTISEMENT_START: "advertisement/UPDATE_ADVERTISEMENT_START",
  DELETE_ADVERTISEMENT_START: "advertisement/DELETE_ADVERTISEMENT_START",
};

export default ADVERTISEMENT_ACTION_TYPES;
