import SELECT_COUNTRY_ACTION_TYPES from "./select-country.type";

const SELECT_COUNTRY_INITIAL_STATE = {
  countries: {},
  searchCountries: {},

  isCountriesHasMore: true,
  isSearchCountriesHasMore: true,

  fetchCountriesParams: {},
  fetchCountriesLoading: false,
  fetchCountriesSuccess: null,
  fetchCountriesFailed: null,

  searchCountriesParams: {},
  searchCountriesLoading: false,
  searchCountriesSuccess: null,
  searchCountriesFailed: null,

  appendCountriesParams: {},
  appendCountriesLoading: false,
  appendCountriesSuccess: null,
  appendCountriesFailed: null,

  isFetchCountriesHitted: false,
  isSearchCountriesHitted: false,
  isAppendCountriesHitted: false,
};

export const selectCountryReducer = (
  state = SELECT_COUNTRY_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_COUNTRY_ACTION_TYPES.SET_COUNTRIES:
      return { ...state, countries: payload };
    case SELECT_COUNTRY_ACTION_TYPES.SET_SEARCH_COUNTRIES:
      return { ...state, searchCountries: payload };

    case SELECT_COUNTRY_ACTION_TYPES.SET_IS_COUNTRIES_HAS_MORE:
      return { ...state, isCountriesHasMore: payload };
    case SELECT_COUNTRY_ACTION_TYPES.SET_IS_SEARCH_COUNTRIES_HAS_MORE:
      return { ...state, isSearchCountriesHasMore: payload };

    case SELECT_COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_PARAMS:
      return { ...state, fetchCountriesParams: payload };
    case SELECT_COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_LOADING:
      return { ...state, fetchCountriesLoading: payload };
    case SELECT_COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_SUCCESS:
      return { ...state, fetchCountriesSuccess: payload };
    case SELECT_COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_FAILED:
      return { ...state, fetchCountriesFailed: payload };

    case SELECT_COUNTRY_ACTION_TYPES.SET_SEARCH_COUNTRIES_PARAMS:
      return { ...state, searchCountriesParams: payload };
    case SELECT_COUNTRY_ACTION_TYPES.SET_SEARCH_COUNTRIES_LOADING:
      return { ...state, searchCountriesLoading: payload };
    case SELECT_COUNTRY_ACTION_TYPES.SET_SEARCH_COUNTRIES_SUCCESS:
      return { ...state, searchCountriesSuccess: payload };
    case SELECT_COUNTRY_ACTION_TYPES.SET_SEARCH_COUNTRIES_FAILED:
      return { ...state, searchCountriesFailed: payload };

    case SELECT_COUNTRY_ACTION_TYPES.SET_APPEND_COUNTRIES_PARAMS:
      return { ...state, appendCountriesParams: payload };
    case SELECT_COUNTRY_ACTION_TYPES.SET_APPEND_COUNTRIES_LOADING:
      return { ...state, appendCountriesLoading: payload };
    case SELECT_COUNTRY_ACTION_TYPES.SET_APPEND_COUNTRIES_SUCCESS:
      return { ...state, appendCountriesSuccess: payload };
    case SELECT_COUNTRY_ACTION_TYPES.SET_APPEND_COUNTRIES_FAILED:
      return { ...state, appendCountriesFailed: payload };

    case SELECT_COUNTRY_ACTION_TYPES.SET_IS_FETCH_COUNTRIES_HITTED:
      return { ...state, isFetchCountriesHitted: payload };
    case SELECT_COUNTRY_ACTION_TYPES.SET_IS_SEARCH_COUNTRIES_HITTED:
      return { ...state, isSearchCountriesHitted: payload };
    case SELECT_COUNTRY_ACTION_TYPES.SET_IS_APPEND_COUNTRIES_HITTED:
      return { ...state, isAppendCountriesHitted: payload };

    case SELECT_COUNTRY_ACTION_TYPES.APPEND_COUNTRIES:
      return {
        ...state,
        countries: { ...state.countries, ...payload },
      };
    case SELECT_COUNTRY_ACTION_TYPES.APPEND_SEARCH_COUNTRIES:
      return {
        ...state,
        searchCountries: { ...state.searchCountries, ...payload },
      };

    case SELECT_COUNTRY_ACTION_TYPES.RESET_SELECT_COUNTRY_REDUCER:
      return SELECT_COUNTRY_INITIAL_STATE;
    default:
      return state;
  }
};
