const COUNTRY_ACTION_TYPES = {
  RESET_COUNTRY_REDUCER: "country/RESET_COUNTRY_REDUCER",

  SET_COUNTRIES: "country/SET_COUNTRIES",
  SET_COUNTRY: "country/SET_COUNTRY",

  SET_IS_COUNTRIES_HAS_MORE: "country/SET_IS_COUNTRIES_HAS_MORE",

  SET_FETCH_COUNTRIES_SEARCH: "country/SET_FETCH_COUNTRIES_SEARCH",
  SET_FETCH_COUNTRIES_KEY_BY: "country/SET_FETCH_COUNTRIES_KEY_BY",
  SET_FETCH_COUNTRIES_PAGE: "country/SET_FETCH_COUNTRIES_PAGE",
  SET_FETCH_COUNTRIES_PER_PAGE: "country/SET_FETCH_COUNTRIES_PER_PAGE",
  SET_FETCH_COUNTRIES_INCLUDES: "country/SET_FETCH_COUNTRIES_INCLUDES",
  SET_FETCH_COUNTRIES_LOADING: "country/SET_FETCH_COUNTRIES_LOADING",
  SET_FETCH_COUNTRIES_SUCCESS: "country/SET_FETCH_COUNTRIES_SUCCESS",
  SET_FETCH_COUNTRIES_FAILED: "country/SET_FETCH_COUNTRIES_FAILED",

  SET_FETCH_COUNTRY_LOADING: "country/SET_FETCH_COUNTRY_LOADING",
  SET_FETCH_COUNTRY_SUCCESS: "country/SET_FETCH_COUNTRY_SUCCESS",
  SET_FETCH_COUNTRY_FAILED: "country/SET_FETCH_COUNTRY_FAILED",

  SET_CREATE_COUNTRY_LOADING: "country/SET_CREATE_COUNTRY_LOADING",
  SET_CREATE_COUNTRY_SUCCESS: "country/SET_CREATE_COUNTRY_SUCCESS",
  SET_CREATE_COUNTRY_FAILED: "country/SET_CREATE_COUNTRY_FAILED",

  SET_UPDATE_COUNTRY_LOADING: "country/SET_UPDATE_COUNTRY_LOADING",
  SET_UPDATE_COUNTRY_SUCCESS: "country/SET_UPDATE_COUNTRY_SUCCESS",
  SET_UPDATE_COUNTRY_FAILED: "country/SET_UPDATE_COUNTRY_FAILED",

  SET_DELETE_COUNTRY_LOADING: "country/SET_DELETE_COUNTRY_LOADING",
  SET_DELETE_COUNTRY_SUCCESS: "country/SET_DELETE_COUNTRY_SUCCESS",
  SET_DELETE_COUNTRY_FAILED: "country/SET_DELETE_COUNTRY_FAILED",

  APPEND_COUNTRIES: "country/APPEND_COUNTRIES",

  SET_IS_FETCH_COUNTRIES_HITTED: "country/SET_IS_FETCH_COUNTRIES_HITTED",
  SET_IS_FETCH_COUNTRY_HITTED: "country/SET_IS_FETCH_COUNTRY_HITTED",
  SET_IS_CREATE_COUNTRY_HITTED: "country/SET_IS_CREATE_COUNTRY_HITTED",
  SET_IS_UPDATE_COUNTRY_HITTED: "country/SET_IS_UPDATE_COUNTRY_HITTED",
  SET_IS_DELETE_COUNTRY_HITTED: "country/SET_IS_DELETE_COUNTRY_HITTED",

  FETCH_COUNTRIES_START: "country/FETCH_COUNTRIES_START",
  FETCH_COUNTRY_START: "country/FETCH_COUNTRY_START",
  CREATE_COUNTRY_START: "country/CREATE_COUNTRY_START",
  UPDATE_COUNTRY_START: "country/UPDATE_COUNTRY_START",
  DELETE_COUNTRY_START: "country/DELETE_COUNTRY_START",
};

export default COUNTRY_ACTION_TYPES;
