import { useSelector } from "react-redux";

import { getIsRightToLeft } from "../../store/global/global.selector";

import { SpinnerCircle, SpinnerContainer } from "./spinner.style";

export const SPINNER_COLORS = {
  PRIMARY: "PRIMARY",
  SUCCESS: "SUCCESS",
  DANGER: "DANGER",
  WARNING: "WARNING",
  SECONDARY: "SECONDARY",
  WHITE: "WHITE",
  LIGHT: "LIGHT",
  DARK: "DARK",
  DARK_SECONDARY: "DARK_SECONDARY",
};

export const SPINNER_SIZES = {
  SMALL: "SMALL",
  NORMAL: "NORMAL",
  LARGE: "LARGE",
};

const Spinner = ({
  spinnerColor = SPINNER_COLORS.DARK,
  spinnerSize = SPINNER_SIZES.NORMAL,
  marginTop,
  marginBottom,
  marginRight,
  marginLeft,
  ...props
}) => {
  const isRightToLeft = useSelector(getIsRightToLeft);

  return (
    <SpinnerContainer
      isRightToLeft={isRightToLeft}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      {...props}
    >
      <SpinnerCircle spinnerColor={spinnerColor} spinnerSize={spinnerSize} />
    </SpinnerContainer>
  );
};

export default Spinner;
