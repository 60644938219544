import { takeLatest, put, all, call, select } from "redux-saga/effects";

import TERM_CONDITION_ACTION_TYPES from "./term-condition.type";

import {
  appendModalTermConditions,
  appendTermConditions,
  setCreateTermConditionFailed,
  setCreateTermConditionLoading,
  setCreateTermConditionSuccess,
  setDeleteTermConditionFailed,
  setDeleteTermConditionLoading,
  setDeleteTermConditionSuccess,
  setFetchModalTermConditionsFailed,
  setFetchModalTermConditionsLoading,
  setFetchModalTermConditionsPage,
  setFetchModalTermConditionsSuccess,
  setFetchTermConditionFailed,
  setFetchTermConditionLoading,
  setFetchTermConditionsFailed,
  setFetchTermConditionsLoading,
  setFetchTermConditionsPage,
  setFetchTermConditionsSuccess,
  setFetchTermConditionSuccess,
  setIsModalTermConditionsHasMore,
  setIsTermConditionsHasMore,
  setModalTermConditions,
  setTermCondition,
  setTermConditions,
  setUpdateTermConditionFailed,
  setUpdateTermConditionLoading,
  setUpdateTermConditionSuccess,
} from "./term-condition.action";
import {
  getFetchModalTermConditionsIncludes,
  getFetchModalTermConditionsPage,
  getFetchModalTermConditionsPerPage,
  getFetchTermConditionsIncludes,
  getFetchTermConditionsPage,
  getFetchTermConditionsPerPage,
  getFetchTermConditionsSearch,
} from "./term-condition.selector";

import {
  getTermConditions,
  getTermCondition,
  createTermCondition,
  updateTermCondition,
  deleteTermCondition,
} from "../../api/term-condition.api";

export function* _getTermConditions() {
  try {
    yield put(setFetchTermConditionsLoading(true));

    const search = yield select(getFetchTermConditionsSearch);
    const page = yield select(getFetchTermConditionsPage);
    const per_page = yield select(getFetchTermConditionsPerPage);
    const includes = yield select(getFetchTermConditionsIncludes);

    const parameters = { search, page, per_page, includes };

    const {
      meta: { message },
      data: { data: termConditions },
    } = yield call(getTermConditions, parameters);

    yield put(setIsTermConditionsHasMore(termConditions.length > 0));

    if (page > 1) {
      yield put(appendTermConditions(termConditions));
    } else {
      yield put(setTermConditions(termConditions));
    }

    yield put(setFetchTermConditionsSuccess(message));
    yield put(setFetchTermConditionsLoading(false));
  } catch (error) {
    yield put(setFetchTermConditionsFailed(error));
    yield put(setFetchTermConditionsLoading(false));
  }
}

export function* _getModalTermConditions() {
  try {
    yield put(setFetchModalTermConditionsLoading(true));

    const page = yield select(getFetchModalTermConditionsPage);
    const per_page = yield select(getFetchModalTermConditionsPerPage);
    const includes = yield select(getFetchModalTermConditionsIncludes);

    const parameters = { page, per_page, includes };

    const {
      meta: { message },
      data: { data: termConditions },
    } = yield call(getTermConditions, parameters);

    yield put(setIsModalTermConditionsHasMore(termConditions.length > 0));

    if (page > 1) {
      yield put(appendModalTermConditions(termConditions));
    } else {
      yield put(setModalTermConditions(termConditions));
    }

    yield put(setFetchModalTermConditionsSuccess(message));
    yield put(setFetchModalTermConditionsLoading(false));
  } catch (error) {
    yield put(setFetchModalTermConditionsFailed(error));
    yield put(setFetchModalTermConditionsLoading(false));
  }
}

export function* _getTermCondition({ payload: id }) {
  try {
    yield put(setFetchTermConditionLoading(true));

    const {
      meta: { message },
      data: termCondition,
    } = yield call(getTermCondition, id);

    yield put(setTermCondition(termCondition));

    yield put(setFetchTermConditionSuccess(message));
    yield put(setFetchTermConditionLoading(false));
  } catch (error) {
    yield put(setFetchTermConditionFailed(error));
    yield put(setFetchTermConditionLoading(false));
  }
}

export function* _createTermCondition({ payload: request }) {
  try {
    yield put(setCreateTermConditionLoading(true));

    const {
      meta: { message },
    } = yield call(createTermCondition, request);

    yield put(setCreateTermConditionSuccess(message));
    yield put(setCreateTermConditionLoading(false));

    yield put(setFetchTermConditionsPage(1));
    yield call(_getTermConditions);
    yield put(setFetchModalTermConditionsPage(1));
    yield put(setModalTermConditions([]));
  } catch (error) {
    yield put(setCreateTermConditionFailed(error));
    yield put(setCreateTermConditionLoading(false));
  }
}

export function* _updateTermCondition({ payload: { id, request } }) {
  try {
    yield put(setUpdateTermConditionLoading(true));

    const {
      meta: { message },
    } = yield call(updateTermCondition, id, request);

    yield put(setUpdateTermConditionSuccess(message));
    yield put(setUpdateTermConditionLoading(false));

    yield put(setFetchTermConditionsPage(1));
    yield call(_getTermConditions);
    yield put(setFetchModalTermConditionsPage(1));
    yield put(setModalTermConditions([]));
  } catch (error) {
    yield put(setUpdateTermConditionFailed(error));
    yield put(setUpdateTermConditionLoading(false));
  }
}

export function* _deleteTermCondition({ payload: id }) {
  try {
    yield put(setDeleteTermConditionLoading(true));

    const {
      meta: { message },
    } = yield call(deleteTermCondition, id);

    yield put(setDeleteTermConditionSuccess(message));
    yield put(setDeleteTermConditionLoading(false));

    yield put(setFetchTermConditionsPage(1));
    yield call(_getTermConditions);
    yield put(setFetchModalTermConditionsPage(1));
    yield put(setModalTermConditions([]));
  } catch (error) {
    yield put(setDeleteTermConditionFailed(error));
    yield put(setDeleteTermConditionLoading(false));
  }
}

export function* onFetchTermConditionsStart() {
  yield takeLatest(TERM_CONDITION_ACTION_TYPES.FETCH_TERM_CONDITIONS_START, _getTermConditions);
}

export function* onFetchModalTermConditionsStart() {
  yield takeLatest(TERM_CONDITION_ACTION_TYPES.FETCH_MODAL_TERM_CONDITIONS_START, _getModalTermConditions);
}

export function* onFetchTermConditionStart() {
  yield takeLatest(TERM_CONDITION_ACTION_TYPES.FETCH_TERM_CONDITION_START, _getTermCondition);
}

export function* onCreateTermConditionStart() {
  yield takeLatest(TERM_CONDITION_ACTION_TYPES.CREATE_TERM_CONDITION_START, _createTermCondition);
}

export function* onUpdateTermConditionStart() {
  yield takeLatest(TERM_CONDITION_ACTION_TYPES.UPDATE_TERM_CONDITION_START, _updateTermCondition);
}

export function* onDeleteTermConditionStart() {
  yield takeLatest(TERM_CONDITION_ACTION_TYPES.DELETE_TERM_CONDITION_START, _deleteTermCondition);
}

export function* termConditionSaga() {
  yield all([
    call(onFetchTermConditionsStart),
    call(onFetchModalTermConditionsStart),
    call(onFetchTermConditionStart),
    call(onCreateTermConditionStart),
    call(onUpdateTermConditionStart),
    call(onDeleteTermConditionStart),
  ]);
}
