import TERM_CONDITION_ACTION_TYPES from "./term-condition.type";
import { createAction } from "../../utils/store.utils";

export const setTermConditions = (termConditions) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_TERM_CONDITIONS, termConditions);

export const setModalTermConditions = (modalTermConditions) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_MODAL_TERM_CONDITIONS, modalTermConditions);

export const setTermCondition = (termCondition) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_TERM_CONDITION, termCondition);

export const setIsTermConditionsHasMore = (isTermConditionsHasMore) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_IS_TERM_CONDITIONS_HAS_MORE, isTermConditionsHasMore);

export const setIsModalTermConditionsHasMore = (isModalTermConditionsHasMore) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_IS_MODAL_TERM_CONDITIONS_HAS_MORE, isModalTermConditionsHasMore);

export const setFetchTermConditionsSearch = (fetchTermConditionsSearch) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_SEARCH, fetchTermConditionsSearch);

export const setFetchTermConditionsPage = (fetchTermConditionsPage) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_PAGE, fetchTermConditionsPage);

export const setFetchTermConditionsPerPage = (fetchTermConditionsPerPage) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_PER_PAGE, fetchTermConditionsPerPage);

export const setFetchTermConditionsIncludes = (fetchTermConditionsIncludes) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_INCLUDES, fetchTermConditionsIncludes);

export const setFetchTermConditionsLoading = (fetchTermConditionsLoading) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_LOADING, fetchTermConditionsLoading);

export const setFetchTermConditionsSuccess = (fetchTermConditionsSuccess) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_SUCCESS, fetchTermConditionsSuccess);

export const setFetchTermConditionsFailed = (fetchTermConditionsFailed) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_FAILED, fetchTermConditionsFailed);

export const setFetchModalTermConditionsPage = (fetchModalTermConditionsPage) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_MODAL_TERM_CONDITIONS_PAGE, fetchModalTermConditionsPage);

export const setFetchModalTermConditionsPerPage = (fetchModalTermConditionsPerPage) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_MODAL_TERM_CONDITIONS_PER_PAGE, fetchModalTermConditionsPerPage);

export const setFetchModalTermConditionsIncludes = (fetchModalTermConditionsIncludes) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_MODAL_TERM_CONDITIONS_INCLUDES, fetchModalTermConditionsIncludes);

export const setFetchModalTermConditionsLoading = (fetchModalTermConditionsLoading) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_MODAL_TERM_CONDITIONS_LOADING, fetchModalTermConditionsLoading);

export const setFetchModalTermConditionsSuccess = (fetchModalTermConditionsSuccess) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_MODAL_TERM_CONDITIONS_SUCCESS, fetchModalTermConditionsSuccess);

export const setFetchModalTermConditionsFailed = (fetchModalTermConditionsFailed) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_MODAL_TERM_CONDITIONS_FAILED, fetchModalTermConditionsFailed);

export const setFetchTermConditionLoading = (fetchTermConditionLoading) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITION_LOADING, fetchTermConditionLoading);

export const setFetchTermConditionSuccess = (fetchTermConditionSuccess) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITION_SUCCESS, fetchTermConditionSuccess);

export const setFetchTermConditionFailed = (fetchTermConditionFailed) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITION_FAILED, fetchTermConditionFailed);

export const setCreateTermConditionLoading = (createTermConditionLoading) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_CREATE_TERM_CONDITION_LOADING, createTermConditionLoading);

export const setCreateTermConditionSuccess = (createTermConditionSuccess) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_CREATE_TERM_CONDITION_SUCCESS, createTermConditionSuccess);

export const setCreateTermConditionFailed = (createTermConditionFailed) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_CREATE_TERM_CONDITION_FAILED, createTermConditionFailed);

export const setUpdateTermConditionLoading = (updateTermConditionLoading) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_UPDATE_TERM_CONDITION_LOADING, updateTermConditionLoading);

export const setUpdateTermConditionSuccess = (updateTermConditionSuccess) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_UPDATE_TERM_CONDITION_SUCCESS, updateTermConditionSuccess);

export const setUpdateTermConditionFailed = (updateTermConditionFailed) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_UPDATE_TERM_CONDITION_FAILED, updateTermConditionFailed);

export const setDeleteTermConditionLoading = (deleteTermConditionLoading) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_DELETE_TERM_CONDITION_LOADING, deleteTermConditionLoading);

export const setDeleteTermConditionSuccess = (deleteTermConditionSuccess) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_DELETE_TERM_CONDITION_SUCCESS, deleteTermConditionSuccess);

export const setDeleteTermConditionFailed = (deleteTermConditionFailed) =>
  createAction(TERM_CONDITION_ACTION_TYPES.SET_DELETE_TERM_CONDITION_FAILED, deleteTermConditionFailed);

export const appendTermConditions = (termConditions) =>
  createAction(TERM_CONDITION_ACTION_TYPES.APPEND_TERM_CONDITIONS, termConditions);

export const appendModalTermConditions = (modalTermConditions) =>
  createAction(TERM_CONDITION_ACTION_TYPES.APPEND_MODAL_TERM_CONDITIONS, modalTermConditions);

export const fetchTermConditionsStart = () => createAction(TERM_CONDITION_ACTION_TYPES.FETCH_TERM_CONDITIONS_START);

export const fetchModalTermConditionsStart = () =>
  createAction(TERM_CONDITION_ACTION_TYPES.FETCH_MODAL_TERM_CONDITIONS_START);

export const fetchTermConditionStart = (id) => createAction(TERM_CONDITION_ACTION_TYPES.FETCH_TERM_CONDITION_START, id);

export const createTermConditionStart = (request) =>
  createAction(TERM_CONDITION_ACTION_TYPES.CREATE_TERM_CONDITION_START, request);

export const updateTermConditionStart = (id, request) =>
  createAction(TERM_CONDITION_ACTION_TYPES.UPDATE_TERM_CONDITION_START, { id, request });

export const deleteTermConditionStart = (id) =>
  createAction(TERM_CONDITION_ACTION_TYPES.DELETE_TERM_CONDITION_START, id);

export const resetTermConditionReducer = () => createAction(TERM_CONDITION_ACTION_TYPES.RESET_TERM_CONDITION_REDUCER);
