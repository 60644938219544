import SUMMARY_ACTION_TYPES from "./summary.type";
import { createAction } from "../../utils/store.utils";

export const setDashboardSummaryFinancialTotal = (dashboardSummaryFinancialTotal) =>
  createAction(SUMMARY_ACTION_TYPES.SET_DASHBOARD_SUMMARY_FINANCIAL_TOTAL, dashboardSummaryFinancialTotal);

export const setDashboardSummaryFinancialAllTimeTotal = (dashboardSummaryFinancialAllTimeTotal) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL,
    dashboardSummaryFinancialAllTimeTotal
  );

export const setReportSummaryFinancialTotal = (reportSummaryFinancialTotal) =>
  createAction(SUMMARY_ACTION_TYPES.SET_REPORT_SUMMARY_FINANCIAL_TOTAL, reportSummaryFinancialTotal);

export const setAccountSummaryFinancialTotal = (accountSummaryFinancialTotal) =>
  createAction(SUMMARY_ACTION_TYPES.SET_ACCOUNT_SUMMARY_FINANCIAL_TOTAL, accountSummaryFinancialTotal);

export const setSummaryFinancialChart = (summaryFinancialChart) =>
  createAction(SUMMARY_ACTION_TYPES.SET_SUMMARY_FINANCIAL_CHART, summaryFinancialChart);

export const setDashboardSummaryTopProducts = (dashboardSummaryTopProducts) =>
  createAction(SUMMARY_ACTION_TYPES.SET_DASHBOARD_SUMMARY_TOP_PRODUCTS, dashboardSummaryTopProducts);

export const setReportSummaryTopProducts = (reportSummaryTopProducts) =>
  createAction(SUMMARY_ACTION_TYPES.SET_REPORT_SUMMARY_TOP_PRODUCTS, reportSummaryTopProducts);

export const setSummaryTopProductCategories = (summaryTopProductCategories) =>
  createAction(SUMMARY_ACTION_TYPES.SET_SUMMARY_TOP_PRODUCT_CATEGORIES, summaryTopProductCategories);

export const setSummaryLatestOrders = (summaryLatestOrders) =>
  createAction(SUMMARY_ACTION_TYPES.SET_SUMMARY_LATEST_ORDERS, summaryLatestOrders);

export const setSummaryPaymentMethods = (summaryPaymentMethods) =>
  createAction(SUMMARY_ACTION_TYPES.SET_SUMMARY_PAYMENT_METHODS, summaryPaymentMethods);

export const setSummarySubscribers = (summarySubscribers) =>
  createAction(SUMMARY_ACTION_TYPES.SET_SUMMARY_SUBSCRIBERS, summarySubscribers);

export const setSummaryBranches = (summaryBranches) =>
  createAction(SUMMARY_ACTION_TYPES.SET_SUMMARY_BRANCHES, summaryBranches);

export const setSummarySubscriberReport = (summarySubscriberReport) =>
  createAction(SUMMARY_ACTION_TYPES.SET_SUMMARY_SUBSCRIBER_REPORT, summarySubscriberReport);

export const setSummarySubscriberReportBranch = (summarySubscriberReportBranch) =>
  createAction(SUMMARY_ACTION_TYPES.SET_SUMMARY_SUBSCRIBER_REPORT_BRANCH, summarySubscriberReportBranch);

export const setDashboardSummaryTotalUser = (dashboardSummaryTotalUser) =>
  createAction(SUMMARY_ACTION_TYPES.SET_DASHBOARD_SUMMARY_TOTAL_USER, dashboardSummaryTotalUser);

export const setDashboardSummaryTotalMarket = (dashboardSummaryTotalMarket) =>
  createAction(SUMMARY_ACTION_TYPES.SET_DASHBOARD_SUMMARY_TOTAL_MARKET, dashboardSummaryTotalMarket);

export const setDashboardSummaryTotalBranch = (dashboardSummaryTotalBranch) =>
  createAction(SUMMARY_ACTION_TYPES.SET_DASHBOARD_SUMMARY_TOTAL_BRANCH, dashboardSummaryTotalBranch);

export const setReportSummaryTotalUser = (reportSummaryTotalUser) =>
  createAction(SUMMARY_ACTION_TYPES.SET_REPORT_SUMMARY_TOTAL_USER, reportSummaryTotalUser);

export const setReportSummaryTotalMarket = (reportSummaryTotalMarket) =>
  createAction(SUMMARY_ACTION_TYPES.SET_REPORT_SUMMARY_TOTAL_MARKET, reportSummaryTotalMarket);

export const setReportSummaryTotalBranch = (reportSummaryTotalBranch) =>
  createAction(SUMMARY_ACTION_TYPES.SET_REPORT_SUMMARY_TOTAL_BRANCH, reportSummaryTotalBranch);

export const setIsDashboardSummaryTopProductsHasMore = (isDashboardSummaryTopProductsHasMore) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_IS_DASHBOARD_SUMMARY_TOP_PRODUCTS_HAS_MORE,
    isDashboardSummaryTopProductsHasMore
  );

export const setIsReportSummaryTopProductsHasMore = (isReportSummaryTopProductsHasMore) =>
  createAction(SUMMARY_ACTION_TYPES.SET_IS_REPORT_SUMMARY_TOP_PRODUCTS_HAS_MORE, isReportSummaryTopProductsHasMore);

export const setIsSummaryTopProductCategoriesHasMore = (isSummaryTopProductCategoriesHasMore) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_IS_SUMMARY_TOP_PRODUCT_CATEGORIES_HAS_MORE,
    isSummaryTopProductCategoriesHasMore
  );

export const setIsSummaryPaymentMethodsHasMore = (isSummaryPaymentMethodsHasMore) =>
  createAction(SUMMARY_ACTION_TYPES.SET_IS_SUMMARY_PAYMENT_METHODS_HAS_MORE, isSummaryPaymentMethodsHasMore);

export const setIsSummarySubscribersHasMore = (isSummarySubscribersHasMore) =>
  createAction(SUMMARY_ACTION_TYPES.SET_IS_SUMMARY_SUBSCRIBERS_HAS_MORE, isSummarySubscribersHasMore);

export const setIsSummaryBranchesHasMore = (isSummaryBranchesHasMore) =>
  createAction(SUMMARY_ACTION_TYPES.SET_IS_SUMMARY_BRANCHES_HAS_MORE, isSummaryBranchesHasMore);

export const setFetchDashboardSummaryFinancialTotalFilterPeriodType = (
  fetchDashboardSummaryFinancialTotalFilterPeriodType
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE,
    fetchDashboardSummaryFinancialTotalFilterPeriodType
  );

export const setFetchDashboardSummaryFinancialTotalFilterStartAt = (fetchDashboardSummaryFinancialTotalFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT,
    fetchDashboardSummaryFinancialTotalFilterStartAt
  );

export const setFetchDashboardSummaryFinancialTotalFilterEndAt = (fetchDashboardSummaryFinancialTotalFilterEndAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT,
    fetchDashboardSummaryFinancialTotalFilterEndAt
  );

export const setFetchDashboardSummaryFinancialTotalFilterMarketId = (
  fetchDashboardSummaryFinancialTotalFilterMarketId
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_MARKET_ID,
    fetchDashboardSummaryFinancialTotalFilterMarketId
  );

export const setFetchDashboardSummaryFinancialTotalFilterBranchId = (
  fetchDashboardSummaryFinancialTotalFilterBranchId
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_BRANCH_ID,
    fetchDashboardSummaryFinancialTotalFilterBranchId
  );

export const setFetchDashboardSummaryFinancialTotalFilterPaymentMethodKey = (
  fetchDashboardSummaryFinancialTotalFilterPaymentMethodKey
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY,
    fetchDashboardSummaryFinancialTotalFilterPaymentMethodKey
  );

export const setFetchDashboardSummaryFinancialTotalFilterCashierId = (
  fetchDashboardSummaryFinancialTotalFilterCashierId
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID,
    fetchDashboardSummaryFinancialTotalFilterCashierId
  );

export const setFetchDashboardSummaryFinancialTotalLoading = (fetchDashboardSummaryFinancialTotalLoading) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_LOADING,
    fetchDashboardSummaryFinancialTotalLoading
  );

export const setFetchDashboardSummaryFinancialTotalSuccess = (fetchDashboardSummaryFinancialTotalSuccess) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_SUCCESS,
    fetchDashboardSummaryFinancialTotalSuccess
  );

export const setFetchDashboardSummaryFinancialTotalFailed = (fetchDashboardSummaryFinancialTotalFailed) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FAILED,
    fetchDashboardSummaryFinancialTotalFailed
  );

export const setFetchDashboardSummaryFinancialAllTimeTotalFilterPeriodType = (
  fetchDashboardSummaryFinancialAllTimeTotalFilterPeriodType
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_PERIOD_TYPE,
    fetchDashboardSummaryFinancialAllTimeTotalFilterPeriodType
  );

export const setFetchDashboardSummaryFinancialAllTimeTotalFilterStartAt = (
  fetchDashboardSummaryFinancialAllTimeTotalFilterStartAt
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_START_AT,
    fetchDashboardSummaryFinancialAllTimeTotalFilterStartAt
  );

export const setFetchDashboardSummaryFinancialAllTimeTotalFilterEndAt = (
  fetchDashboardSummaryFinancialAllTimeTotalFilterEndAt
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_END_AT,
    fetchDashboardSummaryFinancialAllTimeTotalFilterEndAt
  );

export const setFetchDashboardSummaryFinancialAllTimeTotalFilterMarketId = (
  fetchDashboardSummaryFinancialAllTimeTotalFilterMarketId
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_MARKET_ID,
    fetchDashboardSummaryFinancialAllTimeTotalFilterMarketId
  );

export const setFetchDashboardSummaryFinancialAllTimeTotalFilterBranchId = (
  fetchDashboardSummaryFinancialAllTimeTotalFilterBranchId
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_BRANCH_ID,
    fetchDashboardSummaryFinancialAllTimeTotalFilterBranchId
  );

export const setFetchDashboardSummaryFinancialAllTimeTotalFilterPaymentMethodKey = (
  fetchDashboardSummaryFinancialAllTimeTotalFilterPaymentMethodKey
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_PAYMENT_METHOD_KEY,
    fetchDashboardSummaryFinancialAllTimeTotalFilterPaymentMethodKey
  );

export const setFetchDashboardSummaryFinancialAllTimeTotalFilterCashierId = (
  fetchDashboardSummaryFinancialAllTimeTotalFilterCashierId
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_CASHIER_ID,
    fetchDashboardSummaryFinancialAllTimeTotalFilterCashierId
  );

export const setFetchDashboardSummaryFinancialAllTimeTotalLoading = (
  fetchDashboardSummaryFinancialAllTimeTotalLoading
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_LOADING,
    fetchDashboardSummaryFinancialAllTimeTotalLoading
  );

export const setFetchDashboardSummaryFinancialAllTimeTotalSuccess = (
  fetchDashboardSummaryFinancialAllTimeTotalSuccess
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_SUCCESS,
    fetchDashboardSummaryFinancialAllTimeTotalSuccess
  );

export const setFetchDashboardSummaryFinancialAllTimeTotalFailed = (fetchDashboardSummaryFinancialAllTimeTotalFailed) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FAILED,
    fetchDashboardSummaryFinancialAllTimeTotalFailed
  );

export const setFetchReportSummaryFinancialTotalFilterPeriodType = (fetchReportSummaryFinancialTotalFilterPeriodType) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE,
    fetchReportSummaryFinancialTotalFilterPeriodType
  );

export const setFetchReportSummaryFinancialTotalFilterStartAt = (fetchReportSummaryFinancialTotalFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT,
    fetchReportSummaryFinancialTotalFilterStartAt
  );

export const setFetchReportSummaryFinancialTotalFilterEndAt = (fetchReportSummaryFinancialTotalFilterEndAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT,
    fetchReportSummaryFinancialTotalFilterEndAt
  );

export const setFetchReportSummaryFinancialTotalFilterMarketId = (fetchReportSummaryFinancialTotalFilterMarketId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_MARKET_ID,
    fetchReportSummaryFinancialTotalFilterMarketId
  );

export const setFetchReportSummaryFinancialTotalFilterBranchId = (fetchReportSummaryFinancialTotalFilterBranchId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_BRANCH_ID,
    fetchReportSummaryFinancialTotalFilterBranchId
  );

export const setFetchReportSummaryFinancialTotalFilterPaymentMethodKey = (
  fetchReportSummaryFinancialTotalFilterPaymentMethodKey
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY,
    fetchReportSummaryFinancialTotalFilterPaymentMethodKey
  );

export const setFetchReportSummaryFinancialTotalFilterCashierId = (fetchReportSummaryFinancialTotalFilterCashierId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID,
    fetchReportSummaryFinancialTotalFilterCashierId
  );

export const setFetchReportSummaryFinancialTotalLoading = (fetchReportSummaryFinancialTotalLoading) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_LOADING,
    fetchReportSummaryFinancialTotalLoading
  );

export const setFetchReportSummaryFinancialTotalSuccess = (fetchReportSummaryFinancialTotalSuccess) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_SUCCESS,
    fetchReportSummaryFinancialTotalSuccess
  );

export const setFetchReportSummaryFinancialTotalFailed = (fetchReportSummaryFinancialTotalFailed) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FAILED,
    fetchReportSummaryFinancialTotalFailed
  );

export const setFetchAccountSummaryFinancialTotalFilterPeriodType = (
  fetchAccountSummaryFinancialTotalFilterPeriodType
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE,
    fetchAccountSummaryFinancialTotalFilterPeriodType
  );

export const setFetchAccountSummaryFinancialTotalFilterStartAt = (fetchAccountSummaryFinancialTotalFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT,
    fetchAccountSummaryFinancialTotalFilterStartAt
  );

export const setFetchAccountSummaryFinancialTotalFilterEndAt = (fetchAccountSummaryFinancialTotalFilterEndAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT,
    fetchAccountSummaryFinancialTotalFilterEndAt
  );

export const setFetchAccountSummaryFinancialTotalFilterMarketId = (fetchAccountSummaryFinancialTotalFilterMarketId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_MARKET_ID,
    fetchAccountSummaryFinancialTotalFilterMarketId
  );

export const setFetchAccountSummaryFinancialTotalFilterBranchId = (fetchAccountSummaryFinancialTotalFilterBranchId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_BRANCH_ID,
    fetchAccountSummaryFinancialTotalFilterBranchId
  );

export const setFetchAccountSummaryFinancialTotalFilterPaymentMethodKey = (
  fetchAccountSummaryFinancialTotalFilterPaymentMethodKey
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY,
    fetchAccountSummaryFinancialTotalFilterPaymentMethodKey
  );

export const setFetchAccountSummaryFinancialTotalFilterCashierId = (fetchAccountSummaryFinancialTotalFilterCashierId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID,
    fetchAccountSummaryFinancialTotalFilterCashierId
  );

export const setFetchAccountSummaryFinancialTotalLoading = (fetchAccountSummaryFinancialTotalLoading) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_LOADING,
    fetchAccountSummaryFinancialTotalLoading
  );

export const setFetchAccountSummaryFinancialTotalSuccess = (fetchAccountSummaryFinancialTotalSuccess) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_SUCCESS,
    fetchAccountSummaryFinancialTotalSuccess
  );

export const setFetchAccountSummaryFinancialTotalFailed = (fetchAccountSummaryFinancialTotalFailed) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FAILED,
    fetchAccountSummaryFinancialTotalFailed
  );

export const setFetchSummaryFinancialChartFilterPeriodType = (fetchSummaryFinancialChartFilterPeriodType) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_PERIOD_TYPE,
    fetchSummaryFinancialChartFilterPeriodType
  );

export const setFetchSummaryFinancialChartFilterStartAt = (fetchSummaryFinancialChartFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_START_AT,
    fetchSummaryFinancialChartFilterStartAt
  );

export const setFetchSummaryFinancialChartFilterEndAt = (fetchSummaryFinancialChartFilterEndAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_END_AT,
    fetchSummaryFinancialChartFilterEndAt
  );

export const setFetchSummaryFinancialChartFilterMarketId = (fetchSummaryFinancialChartFilterMarketId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_MARKET_ID,
    fetchSummaryFinancialChartFilterMarketId
  );

export const setFetchSummaryFinancialChartFilterBranchId = (fetchSummaryFinancialChartFilterBranchId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_BRANCH_ID,
    fetchSummaryFinancialChartFilterBranchId
  );

export const setFetchSummaryFinancialChartLoading = (fetchSummaryFinancialChartLoading) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_LOADING, fetchSummaryFinancialChartLoading);

export const setFetchSummaryFinancialChartSuccess = (fetchSummaryFinancialChartSuccess) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_SUCCESS, fetchSummaryFinancialChartSuccess);

export const setFetchSummaryFinancialChartFailed = (fetchSummaryFinancialChartFailed) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_FAILED, fetchSummaryFinancialChartFailed);

export const setFetchDashboardSummaryTopProductsPage = (fetchDashboardSummaryTopProductsPage) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_PAGE,
    fetchDashboardSummaryTopProductsPage
  );

export const setFetchDashboardSummaryTopProductsPerPage = (fetchDashboardSummaryTopProductsPerPage) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_PER_PAGE,
    fetchDashboardSummaryTopProductsPerPage
  );

export const setFetchDashboardSummaryTopProductsFilterPeriodType = (fetchDashboardSummaryTopProductsFilterPeriodType) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_PERIOD_TYPE,
    fetchDashboardSummaryTopProductsFilterPeriodType
  );

export const setFetchDashboardSummaryTopProductsFilterStartAt = (fetchDashboardSummaryTopProductsFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_START_AT,
    fetchDashboardSummaryTopProductsFilterStartAt
  );

export const setFetchDashboardSummaryTopProductsFilterEndAt = (fetchDashboardSummaryTopProductsFilterEndAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_END_AT,
    fetchDashboardSummaryTopProductsFilterEndAt
  );

export const setFetchDashboardSummaryTopProductsFilterMarketId = (fetchDashboardSummaryTopProductsFilterMarketId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_MARKET_ID,
    fetchDashboardSummaryTopProductsFilterMarketId
  );

export const setFetchDashboardSummaryTopProductsFilterBranchId = (fetchDashboardSummaryTopProductsFilterBranchId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_BRANCH_ID,
    fetchDashboardSummaryTopProductsFilterBranchId
  );

export const setFetchDashboardSummaryTopProductsFilterPaymentMethodKey = (
  fetchDashboardSummaryTopProductsFilterPaymentMethodKey
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_PAYMENT_METHOD_KEY,
    fetchDashboardSummaryTopProductsFilterPaymentMethodKey
  );

export const setFetchDashboardSummaryTopProductsFilterCashierId = (fetchDashboardSummaryTopProductsFilterCashierId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_CASHIER_ID,
    fetchDashboardSummaryTopProductsFilterCashierId
  );

export const setFetchDashboardSummaryTopProductsLoading = (fetchDashboardSummaryTopProductsLoading) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_LOADING,
    fetchDashboardSummaryTopProductsLoading
  );

export const setFetchDashboardSummaryTopProductsSuccess = (fetchDashboardSummaryTopProductsSuccess) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_SUCCESS,
    fetchDashboardSummaryTopProductsSuccess
  );

export const setFetchDashboardSummaryTopProductsFailed = (fetchDashboardSummaryTopProductsFailed) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FAILED,
    fetchDashboardSummaryTopProductsFailed
  );

export const setFetchReportSummaryTopProductsPage = (fetchReportSummaryTopProductsPage) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_PAGE, fetchReportSummaryTopProductsPage);

export const setFetchReportSummaryTopProductsPerPage = (fetchReportSummaryTopProductsPerPage) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_PER_PAGE,
    fetchReportSummaryTopProductsPerPage
  );

export const setFetchReportSummaryTopProductsFilterPeriodType = (fetchReportSummaryTopProductsFilterPeriodType) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_PERIOD_TYPE,
    fetchReportSummaryTopProductsFilterPeriodType
  );

export const setFetchReportSummaryTopProductsFilterStartAt = (fetchReportSummaryTopProductsFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_START_AT,
    fetchReportSummaryTopProductsFilterStartAt
  );

export const setFetchReportSummaryTopProductsFilterEndAt = (fetchReportSummaryTopProductsFilterEndAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_END_AT,
    fetchReportSummaryTopProductsFilterEndAt
  );

export const setFetchReportSummaryTopProductsFilterMarketId = (fetchReportSummaryTopProductsFilterMarketId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_MARKET_ID,
    fetchReportSummaryTopProductsFilterMarketId
  );

export const setFetchReportSummaryTopProductsFilterBranchId = (fetchReportSummaryTopProductsFilterBranchId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_BRANCH_ID,
    fetchReportSummaryTopProductsFilterBranchId
  );

export const setFetchReportSummaryTopProductsFilterPaymentMethodKey = (
  fetchReportSummaryTopProductsFilterPaymentMethodKey
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_PAYMENT_METHOD_KEY,
    fetchReportSummaryTopProductsFilterPaymentMethodKey
  );

export const setFetchReportSummaryTopProductsFilterCashierId = (fetchReportSummaryTopProductsFilterCashierId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_CASHIER_ID,
    fetchReportSummaryTopProductsFilterCashierId
  );

export const setFetchReportSummaryTopProductsLoading = (fetchReportSummaryTopProductsLoading) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_LOADING,
    fetchReportSummaryTopProductsLoading
  );

export const setFetchReportSummaryTopProductsSuccess = (fetchReportSummaryTopProductsSuccess) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_SUCCESS,
    fetchReportSummaryTopProductsSuccess
  );

export const setFetchReportSummaryTopProductsFailed = (fetchReportSummaryTopProductsFailed) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FAILED, fetchReportSummaryTopProductsFailed);

export const setFetchSummaryTopProductCategoriesPage = (fetchSummaryTopProductCategoriesPage) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_PAGE, fetchSummaryTopProductCategoriesPage);

export const setFetchSummaryTopProductCategoriesPerPage = (fetchSummaryTopProductCategoriesPerPage) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_PER_PAGE, fetchSummaryTopProductCategoriesPerPage);

export const setFetchSummaryTopProductCategoriesFilterPeriodType = (fetchSummaryTopProductCategoriesFilterPeriodType) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_PERIOD_TYPE,
    fetchSummaryTopProductCategoriesFilterPeriodType
  );

export const setFetchSummaryTopProductCategoriesFilterStartAt = (fetchSummaryTopProductCategoriesFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_START_AT,
    fetchSummaryTopProductCategoriesFilterStartAt
  );

export const setFetchSummaryTopProductCategoriesFilterEndAt = (fetchSummaryTopProductCategoriesFilterEndAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_END_AT,
    fetchSummaryTopProductCategoriesFilterEndAt
  );

export const setFetchSummaryTopProductCategoriesFilterMarketId = (fetchSummaryTopProductCategoriesFilterMarketId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_MARKET_ID,
    fetchSummaryTopProductCategoriesFilterMarketId
  );

export const setFetchSummaryTopProductCategoriesFilterBranchId = (fetchSummaryTopProductCategoriesFilterBranchId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_BRANCH_ID,
    fetchSummaryTopProductCategoriesFilterBranchId
  );

export const setFetchSummaryTopProductCategoriesFilterPaymentMethodKey = (
  fetchSummaryTopProductCategoriesFilterPaymentMethodKey
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_PAYMENT_METHOD_KEY,
    fetchSummaryTopProductCategoriesFilterPaymentMethodKey
  );

export const setFetchSummaryTopProductCategoriesFilterCashierId = (fetchSummaryTopProductCategoriesFilterCashierId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_CASHIER_ID,
    fetchSummaryTopProductCategoriesFilterCashierId
  );

export const setFetchSummaryTopProductCategoriesLoading = (fetchSummaryTopProductCategoriesLoading) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_LOADING, fetchSummaryTopProductCategoriesLoading);

export const setFetchSummaryTopProductCategoriesSuccess = (fetchSummaryTopProductCategoriesSuccess) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_SUCCESS, fetchSummaryTopProductCategoriesSuccess);

export const setFetchSummaryTopProductCategoriesFailed = (fetchSummaryTopProductCategoriesFailed) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FAILED, fetchSummaryTopProductCategoriesFailed);

export const setFetchSummaryPaymentMethodsPage = (fetchSummaryPaymentMethodsPage) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_PAGE, fetchSummaryPaymentMethodsPage);

export const setFetchSummaryPaymentMethodsPerPage = (fetchSummaryPaymentMethodsPerPage) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_PER_PAGE, fetchSummaryPaymentMethodsPerPage);

export const setFetchSummaryPaymentMethodsFilterPeriodType = (fetchSummaryPaymentMethodsFilterPeriodType) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_PERIOD_TYPE,
    fetchSummaryPaymentMethodsFilterPeriodType
  );

export const setFetchSummaryPaymentMethodsFilterStartAt = (fetchSummaryPaymentMethodsFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_START_AT,
    fetchSummaryPaymentMethodsFilterStartAt
  );

export const setFetchSummaryPaymentMethodsFilterEndAt = (fetchSummaryPaymentMethodsFilterEndAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_END_AT,
    fetchSummaryPaymentMethodsFilterEndAt
  );

export const setFetchSummaryPaymentMethodsFilterMarketId = (fetchSummaryPaymentMethodsFilterMarketId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_MARKET_ID,
    fetchSummaryPaymentMethodsFilterMarketId
  );

export const setFetchSummaryPaymentMethodsFilterBranchId = (fetchSummaryPaymentMethodsFilterBranchId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_BRANCH_ID,
    fetchSummaryPaymentMethodsFilterBranchId
  );

export const setFetchSummaryPaymentMethodsFilterPaymentMethodKey = (fetchSummaryPaymentMethodsFilterPaymentMethodKey) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_PAYMENT_METHOD_KEY,
    fetchSummaryPaymentMethodsFilterPaymentMethodKey
  );

export const setFetchSummaryPaymentMethodsFilterCashierId = (fetchSummaryPaymentMethodsFilterCashierId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_CASHIER_ID,
    fetchSummaryPaymentMethodsFilterCashierId
  );

export const setFetchSummaryPaymentMethodsLoading = (fetchSummaryPaymentMethodsLoading) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_LOADING, fetchSummaryPaymentMethodsLoading);

export const setFetchSummaryPaymentMethodsSuccess = (fetchSummaryPaymentMethodsSuccess) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_SUCCESS, fetchSummaryPaymentMethodsSuccess);

export const setFetchSummaryPaymentMethodsFailed = (fetchSummaryPaymentMethodsFailed) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FAILED, fetchSummaryPaymentMethodsFailed);

export const setFetchSummarySubscribersPage = (fetchSummarySubscribersPage) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_PAGE, fetchSummarySubscribersPage);

export const setFetchSummarySubscribersPerPage = (fetchSummarySubscribersPerPage) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_PER_PAGE, fetchSummarySubscribersPerPage);

export const setFetchSummarySubscribersFilterPeriodType = (fetchSummarySubscribersFilterPeriodType) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_PERIOD_TYPE,
    fetchSummarySubscribersFilterPeriodType
  );

export const setFetchSummarySubscribersFilterStartAt = (fetchSummarySubscribersFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_START_AT,
    fetchSummarySubscribersFilterStartAt
  );

export const setFetchSummarySubscribersFilterEndAt = (fetchSummarySubscribersFilterEndAt) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_END_AT, fetchSummarySubscribersFilterEndAt);

export const setFetchSummarySubscribersFilterMarketId = (fetchSummarySubscribersFilterMarketId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_MARKET_ID,
    fetchSummarySubscribersFilterMarketId
  );

export const setFetchSummarySubscribersFilterBranchId = (fetchSummarySubscribersFilterBranchId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_BRANCH_ID,
    fetchSummarySubscribersFilterBranchId
  );

export const setFetchSummarySubscribersFilterPaymentMethodKey = (fetchSummarySubscribersFilterPaymentMethodKey) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_PAYMENT_METHOD_KEY,
    fetchSummarySubscribersFilterPaymentMethodKey
  );

export const setFetchSummarySubscribersFilterCashierId = (fetchSummarySubscribersFilterCashierId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_CASHIER_ID,
    fetchSummarySubscribersFilterCashierId
  );

export const setFetchSummarySubscribersLoading = (fetchSummarySubscribersLoading) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_LOADING, fetchSummarySubscribersLoading);

export const setFetchSummarySubscribersSuccess = (fetchSummarySubscribersSuccess) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_SUCCESS, fetchSummarySubscribersSuccess);

export const setFetchSummarySubscribersFailed = (fetchSummarySubscribersFailed) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FAILED, fetchSummarySubscribersFailed);

export const setFetchSummaryBranchesPage = (fetchSummaryBranchesPage) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_PAGE, fetchSummaryBranchesPage);

export const setFetchSummaryBranchesPerPage = (fetchSummaryBranchesPerPage) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_PER_PAGE, fetchSummaryBranchesPerPage);

export const setFetchSummaryBranchesFilterPeriodType = (fetchSummaryBranchesFilterPeriodType) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_PERIOD_TYPE,
    fetchSummaryBranchesFilterPeriodType
  );

export const setFetchSummaryBranchesFilterStartAt = (fetchSummaryBranchesFilterStartAt) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_START_AT, fetchSummaryBranchesFilterStartAt);

export const setFetchSummaryBranchesFilterEndAt = (fetchSummaryBranchesFilterEndAt) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_END_AT, fetchSummaryBranchesFilterEndAt);

export const setFetchSummaryBranchesFilterMarketId = (fetchSummaryBranchesFilterMarketId) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_MARKET_ID, fetchSummaryBranchesFilterMarketId);

export const setFetchSummaryBranchesFilterBranchId = (fetchSummaryBranchesFilterBranchId) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_BRANCH_ID, fetchSummaryBranchesFilterBranchId);

export const setFetchSummaryBranchesFilterPaymentMethodKey = (fetchSummaryBranchesFilterPaymentMethodKey) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_PAYMENT_METHOD_KEY,
    fetchSummaryBranchesFilterPaymentMethodKey
  );

export const setFetchSummaryBranchesFilterCashierId = (fetchSummaryBranchesFilterCashierId) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_CASHIER_ID, fetchSummaryBranchesFilterCashierId);

export const setFetchSummaryBranchesLoading = (fetchSummaryBranchesLoading) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_LOADING, fetchSummaryBranchesLoading);

export const setFetchSummaryBranchesSuccess = (fetchSummaryBranchesSuccess) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_SUCCESS, fetchSummaryBranchesSuccess);

export const setFetchSummaryBranchesFailed = (fetchSummaryBranchesFailed) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FAILED, fetchSummaryBranchesFailed);

export const setFetchSummaryLatestOrdersLimit = (fetchSummaryLatestOrdersLimit) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_LIMIT, fetchSummaryLatestOrdersLimit);

export const setFetchSummaryLatestOrdersIncludes = (fetchSummaryLatestOrdersIncludes) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_INCLUDES, fetchSummaryLatestOrdersIncludes);

export const setFetchSummaryLatestOrdersFilterPeriodType = (fetchSummaryLatestOrdersFilterPeriodType) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_PERIOD_TYPE,
    fetchSummaryLatestOrdersFilterPeriodType
  );

export const setFetchSummaryLatestOrdersFilterStartAt = (fetchSummaryLatestOrdersFilterStartAt) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_START_AT, fetchSummaryLatestOrdersFilterStartAt);

export const setFetchSummaryLatestOrdersFilterEndAt = (fetchSummaryLatestOrdersFilterEndAt) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_END_AT, fetchSummaryLatestOrdersFilterEndAt);

export const setFetchSummaryLatestOrdersFilterMarketId = (fetchSummaryLatestOrdersFilterMarketId) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_MARKET_ID, fetchSummaryLatestOrdersFilterMarketId);

export const setFetchSummaryLatestOrdersFilterBranchId = (fetchSummaryLatestOrdersFilterBranchId) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_BRANCH_ID, fetchSummaryLatestOrdersFilterBranchId);

export const setFetchSummaryLatestOrdersFilterPaymentMethodKey = (fetchSummaryLatestOrdersFilterPaymentMethodKey) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_PAYMENT_METHOD_KEY,
    fetchSummaryLatestOrdersFilterPaymentMethodKey
  );

export const setFetchSummaryLatestOrdersFilterCashierId = (fetchSummaryLatestOrdersFilterCashierId) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_CASHIER_ID, fetchSummaryLatestOrdersFilterCashierId);

export const setFetchSummaryLatestOrdersLoading = (fetchSummaryLatestOrdersLoading) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_LOADING, fetchSummaryLatestOrdersLoading);

export const setFetchSummaryLatestOrdersSuccess = (fetchSummaryLatestOrdersSuccess) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_SUCCESS, fetchSummaryLatestOrdersSuccess);

export const setFetchSummaryLatestOrdersFailed = (fetchSummaryLatestOrdersFailed) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FAILED, fetchSummaryLatestOrdersFailed);

export const setFetchSummarySubscriberReportFilterPeriodType = (fetchSummarySubscriberReportFilterPeriodType) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_PERIOD_TYPE,
    fetchSummarySubscriberReportFilterPeriodType
  );

export const setFetchSummarySubscriberReportFilterStartAt = (fetchSummarySubscriberReportFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_START_AT,
    fetchSummarySubscriberReportFilterStartAt
  );

export const setFetchSummarySubscriberReportFilterEndAt = (fetchSummarySubscriberReportFilterEndAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_END_AT,
    fetchSummarySubscriberReportFilterEndAt
  );

export const setFetchSummarySubscriberReportFilterMarketId = (fetchSummarySubscriberReportFilterMarketId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_MARKET_ID,
    fetchSummarySubscriberReportFilterMarketId
  );

export const setFetchSummarySubscriberReportFilterBranchId = (fetchSummarySubscriberReportFilterBranchId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_BRANCH_ID,
    fetchSummarySubscriberReportFilterBranchId
  );

export const setFetchSummarySubscriberReportFilterCashierId = (fetchSummarySubscriberReportFilterCashierId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_CASHIER_ID,
    fetchSummarySubscriberReportFilterCashierId
  );

export const setFetchSummarySubscriberReportLoading = (fetchSummarySubscriberReportLoading) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_LOADING, fetchSummarySubscriberReportLoading);

export const setFetchSummarySubscriberReportSuccess = (fetchSummarySubscriberReportSuccess) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_SUCCESS, fetchSummarySubscriberReportSuccess);

export const setFetchSummarySubscriberReportFailed = (fetchSummarySubscriberReportFailed) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FAILED, fetchSummarySubscriberReportFailed);

export const setFetchSummarySubscriberReportBranchFilterPeriodType = (
  fetchSummarySubscriberReportBranchFilterPeriodType
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_PERIOD_TYPE,
    fetchSummarySubscriberReportBranchFilterPeriodType
  );

export const setFetchSummarySubscriberReportBranchFilterStartAt = (fetchSummarySubscriberReportBranchFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_START_AT,
    fetchSummarySubscriberReportBranchFilterStartAt
  );

export const setFetchSummarySubscriberReportBranchFilterEndAt = (fetchSummarySubscriberReportBranchFilterEndAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_END_AT,
    fetchSummarySubscriberReportBranchFilterEndAt
  );

export const setFetchSummarySubscriberReportBranchFilterMarketId = (fetchSummarySubscriberReportBranchFilterMarketId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_MARKET_ID,
    fetchSummarySubscriberReportBranchFilterMarketId
  );

export const setFetchSummarySubscriberReportBranchFilterBranchId = (fetchSummarySubscriberReportBranchFilterBranchId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_BRANCH_ID,
    fetchSummarySubscriberReportBranchFilterBranchId
  );

export const setFetchSummarySubscriberReportBranchLoading = (fetchSummarySubscriberReportBranchLoading) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_LOADING,
    fetchSummarySubscriberReportBranchLoading
  );

export const setFetchSummarySubscriberReportBranchSuccess = (fetchSummarySubscriberReportBranchSuccess) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_SUCCESS,
    fetchSummarySubscriberReportBranchSuccess
  );

export const setFetchSummarySubscriberReportBranchFailed = (fetchSummarySubscriberReportBranchFailed) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FAILED,
    fetchSummarySubscriberReportBranchFailed
  );

export const setFetchDashboardSummaryTotalUserRoles = (fetchDashboardSummaryTotalUserRoles) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_ROLES, fetchDashboardSummaryTotalUserRoles);

export const setFetchDashboardSummaryTotalUserFilterStartAt = (fetchDashboardSummaryTotalUserFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_START_AT,
    fetchDashboardSummaryTotalUserFilterStartAt
  );

export const setFetchDashboardSummaryTotalUserFilterEndAt = (fetchDashboardSummaryTotalUserFilterEndAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_END_AT,
    fetchDashboardSummaryTotalUserFilterEndAt
  );

export const setFetchDashboardSummaryTotalUserFilterPhonePrefix = (fetchDashboardSummaryTotalUserFilterPhonePrefix) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_PHONE_PREFIX,
    fetchDashboardSummaryTotalUserFilterPhonePrefix
  );

export const setFetchDashboardSummaryTotalUserFilterIsActive = (fetchDashboardSummaryTotalUserFilterIsActive) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_IS_ACTIVE,
    fetchDashboardSummaryTotalUserFilterIsActive
  );

export const setFetchDashboardSummaryTotalUserLoading = (fetchDashboardSummaryTotalUserLoading) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_LOADING,
    fetchDashboardSummaryTotalUserLoading
  );

export const setFetchDashboardSummaryTotalUserSuccess = (fetchDashboardSummaryTotalUserSuccess) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_SUCCESS,
    fetchDashboardSummaryTotalUserSuccess
  );

export const setFetchDashboardSummaryTotalUserFailed = (fetchDashboardSummaryTotalUserFailed) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FAILED,
    fetchDashboardSummaryTotalUserFailed
  );

export const setFetchDashboardSummaryTotalMarketFilterSectorId = (fetchDashboardSummaryTotalMarketFilterSectorId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_FILTER_SECTOR_ID,
    fetchDashboardSummaryTotalMarketFilterSectorId
  );

export const setFetchDashboardSummaryTotalMarketFilterPhonePrefix = (
  fetchDashboardSummaryTotalMarketFilterPhonePrefix
) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_FILTER_PHONE_PREFIX,
    fetchDashboardSummaryTotalMarketFilterPhonePrefix
  );

export const setFetchDashboardSummaryTotalMarketLoading = (fetchDashboardSummaryTotalMarketLoading) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_LOADING,
    fetchDashboardSummaryTotalMarketLoading
  );

export const setFetchDashboardSummaryTotalMarketSuccess = (fetchDashboardSummaryTotalMarketSuccess) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_SUCCESS,
    fetchDashboardSummaryTotalMarketSuccess
  );

export const setFetchDashboardSummaryTotalMarketFailed = (fetchDashboardSummaryTotalMarketFailed) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_FAILED,
    fetchDashboardSummaryTotalMarketFailed
  );

export const setFetchDashboardSummaryTotalBranchFilterMarketId = (fetchDashboardSummaryTotalBranchFilterMarketId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_FILTER_MARKET_ID,
    fetchDashboardSummaryTotalBranchFilterMarketId
  );

export const setFetchDashboardSummaryTotalBranchFilterIsActive = (fetchDashboardSummaryTotalBranchFilterIsActive) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_FILTER_IS_ACTIVE,
    fetchDashboardSummaryTotalBranchFilterIsActive
  );

export const setFetchDashboardSummaryTotalBranchLoading = (fetchDashboardSummaryTotalBranchLoading) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_LOADING,
    fetchDashboardSummaryTotalBranchLoading
  );

export const setFetchDashboardSummaryTotalBranchSuccess = (fetchDashboardSummaryTotalBranchSuccess) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_SUCCESS,
    fetchDashboardSummaryTotalBranchSuccess
  );

export const setFetchDashboardSummaryTotalBranchFailed = (fetchDashboardSummaryTotalBranchFailed) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_FAILED,
    fetchDashboardSummaryTotalBranchFailed
  );

export const setFetchReportSummaryTotalUserRoles = (fetchReportSummaryTotalUserRoles) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_ROLES, fetchReportSummaryTotalUserRoles);

export const setFetchReportSummaryTotalUserFilterStartAt = (fetchReportSummaryTotalUserFilterStartAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_START_AT,
    fetchReportSummaryTotalUserFilterStartAt
  );

export const setFetchReportSummaryTotalUserFilterEndAt = (fetchReportSummaryTotalUserFilterEndAt) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_END_AT,
    fetchReportSummaryTotalUserFilterEndAt
  );

export const setFetchReportSummaryTotalUserFilterPhonePrefix = (fetchReportSummaryTotalUserFilterPhonePrefix) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_PHONE_PREFIX,
    fetchReportSummaryTotalUserFilterPhonePrefix
  );

export const setFetchReportSummaryTotalUserFilterIsActive = (fetchReportSummaryTotalUserFilterIsActive) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_IS_ACTIVE,
    fetchReportSummaryTotalUserFilterIsActive
  );

export const setFetchReportSummaryTotalUserLoading = (fetchReportSummaryTotalUserLoading) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_LOADING, fetchReportSummaryTotalUserLoading);

export const setFetchReportSummaryTotalUserSuccess = (fetchReportSummaryTotalUserSuccess) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_SUCCESS, fetchReportSummaryTotalUserSuccess);

export const setFetchReportSummaryTotalUserFailed = (fetchReportSummaryTotalUserFailed) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FAILED, fetchReportSummaryTotalUserFailed);

export const setFetchReportSummaryTotalMarketFilterSectorId = (fetchReportSummaryTotalMarketFilterSectorId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_FILTER_SECTOR_ID,
    fetchReportSummaryTotalMarketFilterSectorId
  );

export const setFetchReportSummaryTotalMarketFilterPhonePrefix = (fetchReportSummaryTotalMarketFilterPhonePrefix) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_FILTER_PHONE_PREFIX,
    fetchReportSummaryTotalMarketFilterPhonePrefix
  );

export const setFetchReportSummaryTotalMarketLoading = (fetchReportSummaryTotalMarketLoading) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_LOADING,
    fetchReportSummaryTotalMarketLoading
  );

export const setFetchReportSummaryTotalMarketSuccess = (fetchReportSummaryTotalMarketSuccess) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_SUCCESS,
    fetchReportSummaryTotalMarketSuccess
  );

export const setFetchReportSummaryTotalMarketFailed = (fetchReportSummaryTotalMarketFailed) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_FAILED, fetchReportSummaryTotalMarketFailed);

export const setFetchReportSummaryTotalBranchFilterMarketId = (fetchReportSummaryTotalBranchFilterMarketId) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_FILTER_MARKET_ID,
    fetchReportSummaryTotalBranchFilterMarketId
  );

export const setFetchReportSummaryTotalBranchFilterIsActive = (fetchReportSummaryTotalBranchFilterIsActive) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_FILTER_IS_ACTIVE,
    fetchReportSummaryTotalBranchFilterIsActive
  );

export const setFetchReportSummaryTotalBranchLoading = (fetchReportSummaryTotalBranchLoading) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_LOADING,
    fetchReportSummaryTotalBranchLoading
  );

export const setFetchReportSummaryTotalBranchSuccess = (fetchReportSummaryTotalBranchSuccess) =>
  createAction(
    SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_SUCCESS,
    fetchReportSummaryTotalBranchSuccess
  );

export const setFetchReportSummaryTotalBranchFailed = (fetchReportSummaryTotalBranchFailed) =>
  createAction(SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_FAILED, fetchReportSummaryTotalBranchFailed);

export const appendDashboardSummaryTopProducts = (dashboardSummaryTopProducts) =>
  createAction(SUMMARY_ACTION_TYPES.APPEND_DASHBOARD_SUMMARY_TOP_PRODUCTS, dashboardSummaryTopProducts);

export const appendReportSummaryTopProducts = (reportSummaryTopProducts) =>
  createAction(SUMMARY_ACTION_TYPES.APPEND_REPORT_SUMMARY_TOP_PRODUCTS, reportSummaryTopProducts);

export const appendSummaryTopProductCategories = (summaryTopProductCategories) =>
  createAction(SUMMARY_ACTION_TYPES.APPEND_SUMMARY_TOP_PRODUCT_CATEGORIES, summaryTopProductCategories);

export const appendSummaryPaymentMethods = (summaryPaymentMethods) =>
  createAction(SUMMARY_ACTION_TYPES.APPEND_SUMMARY_PAYMENT_METHODS, summaryPaymentMethods);

export const appendSummarySubscribers = (summarySubscribers) =>
  createAction(SUMMARY_ACTION_TYPES.APPEND_SUMMARY_SUBSCRIBERS, summarySubscribers);

export const appendSummaryBranches = (summaryBranches) =>
  createAction(SUMMARY_ACTION_TYPES.APPEND_SUMMARY_BRANCHES, summaryBranches);

export const fetchDashboardSummaryFinancialTotalStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_START);

export const fetchDashboardSummaryFinancialAllTimeTotalStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_START);

export const fetchReportSummaryFinancialTotalStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_START);

export const fetchAccountSummaryFinancialTotalStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_START);

export const fetchSummaryFinancialChartStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_FINANCIAL_CHART_START);

export const fetchDashboardSummaryTopProductsStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_START);

export const fetchReportSummaryTopProductsStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_REPORT_SUMMARY_TOP_PRODUCTS_START);

export const fetchSummaryTopProductCategoriesStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_TOP_PRODUCT_CATEGORIES_START);

export const fetchSummaryPaymentMethodsStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_PAYMENT_METHODS_START);

export const fetchSummarySubscribersStart = () => createAction(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_SUBSCRIBERS_START);

export const fetchSummaryBranchesStart = () => createAction(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_BRANCHES_START);

export const fetchSummaryLatestOrdersStart = () => createAction(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_LATEST_ORDERS_START);

export const fetchSummarySubscriberReportStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_SUBSCRIBER_REPORT_START);

export const fetchSummarySubscriberReportBranchStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_START);

export const fetchDashboardSummaryTotalUserStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_DASHBOARD_SUMMARY_TOTAL_USER_START);

export const fetchDashboardSummaryTotalMarketStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_START);

export const fetchDashboardSummaryTotalBranchStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_START);

export const fetchReportSummaryTotalUserStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_REPORT_SUMMARY_TOTAL_USER_START);

export const fetchReportSummaryTotalMarketStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_REPORT_SUMMARY_TOTAL_MARKET_START);

export const fetchReportSummaryTotalBranchStart = () =>
  createAction(SUMMARY_ACTION_TYPES.FETCH_REPORT_SUMMARY_TOTAL_BRANCH_START);

export const resetSummaryReducer = () => createAction(SUMMARY_ACTION_TYPES.RESET_SUMMARY_REDUCER);
