import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PAYMENT_METHOD_SETTING_ACTION_TYPES from "./payment-method-setting.type";

import {
  appendActivePaymentMethodSettings,
  appendPaymentMethodSettings,
  appendSelectPaymentMethodSettings,
  setActivePaymentMethodSettings,
  setCreatePaymentMethodSettingFailed,
  setCreatePaymentMethodSettingLoading,
  setCreatePaymentMethodSettingSuccess,
  setDeletePaymentMethodSettingFailed,
  setDeletePaymentMethodSettingLoading,
  setDeletePaymentMethodSettingSuccess,
  setFetchActivePaymentMethodSettingsFailed,
  setFetchActivePaymentMethodSettingsLoading,
  setFetchActivePaymentMethodSettingsPage,
  setFetchActivePaymentMethodSettingsSuccess,
  setFetchPaymentMethodSettingFailed,
  setFetchPaymentMethodSettingLoading,
  setFetchPaymentMethodSettingsFailed,
  setFetchPaymentMethodSettingsLoading,
  setFetchPaymentMethodSettingsPage,
  setFetchPaymentMethodSettingsSuccess,
  setFetchPaymentMethodSettingSuccess,
  setFetchSelectPaymentMethodSettingsFailed,
  setFetchSelectPaymentMethodSettingsLoading,
  setFetchSelectPaymentMethodSettingsPage,
  setFetchSelectPaymentMethodSettingsSuccess,
  setIsActivePaymentMethodSettingsHasMore,
  setIsCreatePaymentMethodSettingHitted,
  setIsDeletePaymentMethodSettingHitted,
  setIsFetchActivePaymentMethodSettingsHitted,
  setIsFetchPaymentMethodSettingHitted,
  setIsFetchPaymentMethodSettingsHitted,
  setIsFetchSelectPaymentMethodSettingsHitted,
  setIsPaymentMethodSettingsHasMore,
  setIsSelectPaymentMethodSettingsHasMore,
  setIsUpdatePaymentMethodSettingHitted,
  setPaymentMethodSetting,
  setPaymentMethodSettings,
  setSelectPaymentMethodSettings,
  setUpdatePaymentMethodSettingFailed,
  setUpdatePaymentMethodSettingLoading,
  setUpdatePaymentMethodSettingSuccess,
} from "./payment-method-setting.action";
import {
  getFetchActivePaymentMethodSettingsFilterMarketId,
  getFetchActivePaymentMethodSettingsIncludes,
  getFetchActivePaymentMethodSettingsPage,
  getFetchActivePaymentMethodSettingsPerPage,
  getFetchActivePaymentMethodSettingsSort,
  getFetchPaymentMethodSettingsFilterIsActive,
  getFetchPaymentMethodSettingsFilterMarketId,
  getFetchPaymentMethodSettingsIncludes,
  getFetchPaymentMethodSettingsPage,
  getFetchPaymentMethodSettingsPerPage,
  getFetchPaymentMethodSettingsSort,
  getFetchSelectPaymentMethodSettingsFilterIsActive,
  getFetchSelectPaymentMethodSettingsFilterMarketId,
  getFetchSelectPaymentMethodSettingsIncludes,
  getFetchSelectPaymentMethodSettingsPage,
  getFetchSelectPaymentMethodSettingsPerPage,
  getFetchSelectPaymentMethodSettingsSort,
  getIsFetchActivePaymentMethodSettingsHitted,
  getIsFetchPaymentMethodSettingsHitted,
  getIsFetchSelectPaymentMethodSettingsHitted,
} from "./payment-method-setting.selector";

import {
  getPaymentMethodSettings,
  getPaymentMethodSetting,
  createPaymentMethodSetting,
  updatePaymentMethodSetting,
  deletePaymentMethodSetting,
} from "../../api/payment-method-setting.api";

export function* _getPaymentMethodSettings() {
  try {
    yield put(setFetchPaymentMethodSettingsLoading(true));

    const sort = yield select(getFetchPaymentMethodSettingsSort);
    const page = yield select(getFetchPaymentMethodSettingsPage);
    const per_page = yield select(getFetchPaymentMethodSettingsPerPage);
    const includes = yield select(getFetchPaymentMethodSettingsIncludes);
    const market_id = yield select(getFetchPaymentMethodSettingsFilterMarketId);
    const is_active = yield select(getFetchPaymentMethodSettingsFilterIsActive);

    const parameters = {
      sort,
      page,
      per_page,
      includes,
      filter: { market_id, is_active },
    };

    const {
      meta: { message },
      data: { data: paymentMethodSettings },
    } = yield call(getPaymentMethodSettings, parameters);

    yield put(setIsFetchPaymentMethodSettingsHitted(true));
    yield put(setIsPaymentMethodSettingsHasMore(paymentMethodSettings.length > 0));

    if (page > 1) {
      yield put(appendPaymentMethodSettings(paymentMethodSettings));
    } else {
      yield put(setPaymentMethodSettings(paymentMethodSettings));
    }

    yield put(setFetchPaymentMethodSettingsSuccess(message));
    yield put(setFetchPaymentMethodSettingsLoading(false));
  } catch (error) {
    yield put(setFetchPaymentMethodSettingsFailed(error));
    yield put(setFetchPaymentMethodSettingsLoading(false));
  }
}

export function* _getSelectPaymentMethodSettings() {
  try {
    yield put(setFetchSelectPaymentMethodSettingsLoading(true));

    const sort = yield select(getFetchSelectPaymentMethodSettingsSort);
    const page = yield select(getFetchSelectPaymentMethodSettingsPage);
    const per_page = yield select(getFetchSelectPaymentMethodSettingsPerPage);
    const includes = yield select(getFetchSelectPaymentMethodSettingsIncludes);
    const market_id = yield select(getFetchSelectPaymentMethodSettingsFilterMarketId);
    const is_active = yield select(getFetchSelectPaymentMethodSettingsFilterIsActive);

    const parameters = {
      sort,
      page,
      per_page,
      includes,
      filter: { market_id, is_active },
    };

    const {
      meta: { message },
      data: { data: paymentMethodSettings },
    } = yield call(getPaymentMethodSettings, parameters);

    yield put(setIsFetchSelectPaymentMethodSettingsHitted(true));
    yield put(setIsSelectPaymentMethodSettingsHasMore(paymentMethodSettings.length > 0));

    if (page > 1) {
      yield put(appendSelectPaymentMethodSettings(paymentMethodSettings));
    } else {
      yield put(setSelectPaymentMethodSettings(paymentMethodSettings));
    }

    yield put(setFetchSelectPaymentMethodSettingsSuccess(message));
    yield put(setFetchSelectPaymentMethodSettingsLoading(false));
  } catch (error) {
    yield put(setFetchSelectPaymentMethodSettingsFailed(error));
    yield put(setFetchSelectPaymentMethodSettingsLoading(false));
  }
}

export function* _getActivePaymentMethodSettings() {
  try {
    yield put(setFetchActivePaymentMethodSettingsLoading(true));

    const sort = yield select(getFetchActivePaymentMethodSettingsSort);
    const page = yield select(getFetchActivePaymentMethodSettingsPage);
    const per_page = yield select(getFetchActivePaymentMethodSettingsPerPage);
    const includes = yield select(getFetchActivePaymentMethodSettingsIncludes);
    const market_id = yield select(getFetchActivePaymentMethodSettingsFilterMarketId);

    const parameters = {
      sort,
      page,
      per_page,
      includes,
      filter: { market_id, is_active: 1 },
    };

    const {
      meta: { message },
      data: { data: paymentMethodSettings },
    } = yield call(getPaymentMethodSettings, parameters);

    yield put(setIsFetchActivePaymentMethodSettingsHitted(true));
    yield put(setIsActivePaymentMethodSettingsHasMore(paymentMethodSettings.length > 0));

    if (page > 1) {
      yield put(appendActivePaymentMethodSettings(paymentMethodSettings));
    } else {
      yield put(setActivePaymentMethodSettings(paymentMethodSettings));
    }

    yield put(setFetchActivePaymentMethodSettingsSuccess(message));
    yield put(setFetchActivePaymentMethodSettingsLoading(false));
  } catch (error) {
    yield put(setFetchActivePaymentMethodSettingsFailed(error));
    yield put(setFetchActivePaymentMethodSettingsLoading(false));
  }
}

export function* _getPaymentMethodSetting({ payload: id }) {
  try {
    yield put(setFetchPaymentMethodSettingLoading(true));

    const {
      meta: { message },
      data: paymentMethodSetting,
    } = yield call(getPaymentMethodSetting, id);

    yield put(setIsFetchPaymentMethodSettingHitted(true));
    yield put(setPaymentMethodSetting(paymentMethodSetting));

    yield put(setFetchPaymentMethodSettingSuccess(message));
    yield put(setFetchPaymentMethodSettingLoading(false));
  } catch (error) {
    yield put(setFetchPaymentMethodSettingFailed(error));
    yield put(setFetchPaymentMethodSettingLoading(false));
  }
}

export function* _createPaymentMethodSetting({ payload: request }) {
  try {
    yield put(setCreatePaymentMethodSettingLoading(true));

    const {
      meta: { message },
    } = yield call(createPaymentMethodSetting, request);

    yield put(setIsCreatePaymentMethodSettingHitted(true));

    const isFetchPaymentMethodSettingsHitted = yield select(getIsFetchPaymentMethodSettingsHitted);
    const isFetchSelectPaymentMethodSettingsHitted = yield select(getIsFetchSelectPaymentMethodSettingsHitted);
    const isFetchActivePaymentMethodSettingsHitted = yield select(getIsFetchActivePaymentMethodSettingsHitted);

    if (isFetchPaymentMethodSettingsHitted) {
      yield put(setFetchPaymentMethodSettingsPage(1));
      yield call(_getPaymentMethodSettings);
    }
    if (isFetchSelectPaymentMethodSettingsHitted) {
      yield put(setFetchSelectPaymentMethodSettingsPage(1));
      yield call(_getSelectPaymentMethodSettings);
    }
    if (isFetchActivePaymentMethodSettingsHitted) {
      yield put(setFetchActivePaymentMethodSettingsPage(1));
      yield call(_getActivePaymentMethodSettings);
    }

    yield put(setCreatePaymentMethodSettingSuccess(message));
    yield put(setCreatePaymentMethodSettingLoading(false));
  } catch (error) {
    yield put(setCreatePaymentMethodSettingFailed(error));
    yield put(setCreatePaymentMethodSettingLoading(false));
  }
}

export function* _updatePaymentMethodSetting({ payload: { id, request } }) {
  try {
    yield put(setUpdatePaymentMethodSettingLoading(true));

    const {
      meta: { message },
    } = yield call(updatePaymentMethodSetting, id, request);

    yield put(setIsUpdatePaymentMethodSettingHitted(true));

    const isFetchPaymentMethodSettingsHitted = yield select(getIsFetchPaymentMethodSettingsHitted);
    const isFetchSelectPaymentMethodSettingsHitted = yield select(getIsFetchSelectPaymentMethodSettingsHitted);
    const isFetchActivePaymentMethodSettingsHitted = yield select(getIsFetchActivePaymentMethodSettingsHitted);

    if (isFetchPaymentMethodSettingsHitted) {
      yield put(setFetchPaymentMethodSettingsPage(1));
      yield call(_getPaymentMethodSettings);
    }
    if (isFetchSelectPaymentMethodSettingsHitted) {
      yield put(setFetchSelectPaymentMethodSettingsPage(1));
      yield call(_getSelectPaymentMethodSettings);
    }
    if (isFetchActivePaymentMethodSettingsHitted) {
      yield put(setFetchActivePaymentMethodSettingsPage(1));
      yield call(_getActivePaymentMethodSettings);
    }

    yield put(setUpdatePaymentMethodSettingSuccess(message));
    yield put(setUpdatePaymentMethodSettingLoading(false));
  } catch (error) {
    yield put(setUpdatePaymentMethodSettingFailed(error));
    yield put(setUpdatePaymentMethodSettingLoading(false));
  }
}

export function* _deletePaymentMethodSetting({ payload: id }) {
  try {
    yield put(setDeletePaymentMethodSettingLoading(true));

    const {
      meta: { message },
    } = yield call(deletePaymentMethodSetting, id);

    yield put(setIsDeletePaymentMethodSettingHitted(true));

    const isFetchPaymentMethodSettingsHitted = yield select(getIsFetchPaymentMethodSettingsHitted);
    const isFetchSelectPaymentMethodSettingsHitted = yield select(getIsFetchSelectPaymentMethodSettingsHitted);
    const isFetchActivePaymentMethodSettingsHitted = yield select(getIsFetchActivePaymentMethodSettingsHitted);

    if (isFetchPaymentMethodSettingsHitted) {
      yield put(setFetchPaymentMethodSettingsPage(1));
      yield call(_getPaymentMethodSettings);
    }
    if (isFetchSelectPaymentMethodSettingsHitted) {
      yield put(setFetchSelectPaymentMethodSettingsPage(1));
      yield call(_getSelectPaymentMethodSettings);
    }
    if (isFetchActivePaymentMethodSettingsHitted) {
      yield put(setFetchActivePaymentMethodSettingsPage(1));
      yield call(_getActivePaymentMethodSettings);
    }

    yield put(setDeletePaymentMethodSettingSuccess(message));
    yield put(setDeletePaymentMethodSettingLoading(false));
  } catch (error) {
    yield put(setDeletePaymentMethodSettingFailed(error));
    yield put(setDeletePaymentMethodSettingLoading(false));
  }
}

export function* onFetchPaymentMethodSettingsStart() {
  yield takeLatest(PAYMENT_METHOD_SETTING_ACTION_TYPES.FETCH_PAYMENT_METHOD_SETTINGS_START, _getPaymentMethodSettings);
}

export function* onFetchSelectPaymentMethodSettingsStart() {
  yield takeLatest(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.FETCH_SELECT_PAYMENT_METHOD_SETTINGS_START,
    _getSelectPaymentMethodSettings
  );
}

export function* onFetchActivePaymentMethodSettingsStart() {
  yield takeLatest(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_START,
    _getActivePaymentMethodSettings
  );
}

export function* onFetchPaymentMethodSettingStart() {
  yield takeLatest(PAYMENT_METHOD_SETTING_ACTION_TYPES.FETCH_PAYMENT_METHOD_SETTING_START, _getPaymentMethodSetting);
}

export function* onCreatePaymentMethodSettingStart() {
  yield takeLatest(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.CREATE_PAYMENT_METHOD_SETTING_START,
    _createPaymentMethodSetting
  );
}

export function* onUpdatePaymentMethodSettingStart() {
  yield takeLatest(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.UPDATE_PAYMENT_METHOD_SETTING_START,
    _updatePaymentMethodSetting
  );
}

export function* onDeletePaymentMethodSettingStart() {
  yield takeLatest(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.DELETE_PAYMENT_METHOD_SETTING_START,
    _deletePaymentMethodSetting
  );
}

export function* paymentMethodSettingSaga() {
  yield all([
    call(onFetchPaymentMethodSettingsStart),
    call(onFetchSelectPaymentMethodSettingsStart),
    call(onFetchActivePaymentMethodSettingsStart),
    call(onFetchPaymentMethodSettingStart),
    call(onCreatePaymentMethodSettingStart),
    call(onUpdatePaymentMethodSettingStart),
    call(onDeletePaymentMethodSettingStart),
  ]);
}
