import apiService from "./api";

export const getFeatures = async (parameters) =>
  (await apiService.get("/v2/features/subscriptions", parameters)).data;
export const getFeature = async (featureId) =>
  (await apiService.get(`/v2/features/subscriptions/${featureId}`)).data;
export const createFeature = async (request) =>
  (await apiService.post("/v2/features/subscriptions", request)).data;
export const updateFeature = async (featureId, request) =>
  (await apiService.post(`/v2/features/subscriptions/${featureId}`, request))
    .data;
export const deleteFeature = async (featureId) =>
  (await apiService.delete(`/v2/features/subscriptions/${featureId}`)).data;
