import FEATURE_SUBSCRIPTION_ACTION_TYPES from "./feature-subscription.type";

const FEATURE_SUBSCRIPTION_INITIAL_STATE = {
  feature: null,

  createFeatureLoading: false,
  createFeatureSuccess: null,
  createFeatureFailed: null,

  updateFeatureLoading: false,
  updateFeatureSuccess: null,
  updateFeatureFailed: null,

  deleteFeatureLoading: false,
  deleteFeatureSuccess: null,
  deleteFeatureFailed: null,

  isCreateFeatureHitted: false,
  isUpdateFeatureHitted: false,
  isDeleteFeatureHitted: false,
};

export const featureSubscriptionReducer = (
  state = FEATURE_SUBSCRIPTION_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_FEATURE:
      return { ...state, feature: payload };

    case FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_CREATE_FEATURE_LOADING:
      return { ...state, createFeatureLoading: payload };
    case FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_CREATE_FEATURE_SUCCESS:
      return { ...state, createFeatureSuccess: payload };
    case FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_CREATE_FEATURE_FAILED:
      return { ...state, createFeatureFailed: payload };

    case FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_FEATURE_LOADING:
      return { ...state, updateFeatureLoading: payload };
    case FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_FEATURE_SUCCESS:
      return { ...state, updateFeatureSuccess: payload };
    case FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_FEATURE_FAILED:
      return { ...state, updateFeatureFailed: payload };

    case FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_DELETE_FEATURE_LOADING:
      return { ...state, deleteFeatureLoading: payload };
    case FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_DELETE_FEATURE_SUCCESS:
      return { ...state, deleteFeatureSuccess: payload };
    case FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_DELETE_FEATURE_FAILED:
      return { ...state, deleteFeatureFailed: payload };

    case FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_IS_CREATE_FEATURE_HITTED:
      return { ...state, isCreateFeatureHitted: payload };
    case FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_IS_UPDATE_FEATURE_HITTED:
      return { ...state, isUpdateFeatureHitted: payload };
    case FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_IS_DELETE_FEATURE_HITTED:
      return { ...state, isDeleteFeatureHitted: payload };

    case FEATURE_SUBSCRIPTION_ACTION_TYPES.RESET_FEATURE_SUBSCRIPTION_REDUCER:
      return FEATURE_SUBSCRIPTION_INITIAL_STATE;
    default:
      return state;
  }
};
