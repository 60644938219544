import { createSelector } from "reselect";

const selectProductSelector = ({ selectProduct }) => selectProduct;

export const getProducts = createSelector(
  [selectProductSelector],
  ({ products }) => products
);
export const getSearchProducts = createSelector(
  [selectProductSelector],
  ({ searchProducts }) => searchProducts
);

export const getIsProductsHasMore = createSelector(
  [selectProductSelector],
  ({ isProductsHasMore }) => isProductsHasMore
);
export const getIsSearchProductsHasMore = createSelector(
  [selectProductSelector],
  ({ isSearchProductsHasMore }) => isSearchProductsHasMore
);

export const getFetchProductsParams = createSelector(
  [selectProductSelector],
  ({ fetchProductsParams }) => fetchProductsParams
);
export const getFetchProductsLoading = createSelector(
  [selectProductSelector],
  ({ fetchProductsLoading }) => fetchProductsLoading
);
export const getFetchProductsSuccess = createSelector(
  [selectProductSelector],
  ({ fetchProductsSuccess }) => fetchProductsSuccess
);
export const getFetchProductsFailed = createSelector(
  [selectProductSelector],
  ({ fetchProductsFailed }) => fetchProductsFailed
);

export const getSearchProductsParams = createSelector(
  [selectProductSelector],
  ({ searchProductsParams }) => searchProductsParams
);
export const getSearchProductsLoading = createSelector(
  [selectProductSelector],
  ({ searchProductsLoading }) => searchProductsLoading
);
export const getSearchProductsSuccess = createSelector(
  [selectProductSelector],
  ({ searchProductsSuccess }) => searchProductsSuccess
);
export const getSearchProductsFailed = createSelector(
  [selectProductSelector],
  ({ searchProductsFailed }) => searchProductsFailed
);

export const getAppendProductsParams = createSelector(
  [selectProductSelector],
  ({ appendProductsParams }) => appendProductsParams
);
export const getAppendProductsLoading = createSelector(
  [selectProductSelector],
  ({ appendProductsLoading }) => appendProductsLoading
);
export const getAppendProductsSuccess = createSelector(
  [selectProductSelector],
  ({ appendProductsSuccess }) => appendProductsSuccess
);
export const getAppendProductsFailed = createSelector(
  [selectProductSelector],
  ({ appendProductsFailed }) => appendProductsFailed
);

export const getIsFetchProductsHitted = createSelector(
  [selectProductSelector],
  ({ isFetchProductsHitted }) => isFetchProductsHitted
);
export const getIsSearchProductsHitted = createSelector(
  [selectProductSelector],
  ({ isSearchProductsHitted }) => isSearchProductsHitted
);
export const getIsAppendProductsHitted = createSelector(
  [selectProductSelector],
  ({ isAppendProductsHitted }) => isAppendProductsHitted
);
