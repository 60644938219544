import MSEGAT_ACTION_TYPES from "./msegat.type";

export const MSEGAT_INITIAL_STATE = {
  msegats: [],
  msegat: null,

  isMsegatsHasMore: true,

  fetchMsegatsSearch: null,
  fetchMsegatsPage: 1,
  fetchMsegatsPerPage: null,
  fetchMsegatsIncludes: null,
  fetchMsegatsFilterIsActive: null,
  fetchMsegatsLoading: false,
  fetchMsegatsSuccess: null,
  fetchMsegatsFailed: null,

  fetchMsegatLoading: false,
  fetchMsegatSuccess: null,
  fetchMsegatFailed: null,

  createOrUpdateMsegatLoading: false,
  createOrUpdateMsegatSuccess: null,
  createOrUpdateMsegatFailed: null,

  isFetchMsegatsHitted: false,
  isFetchMsegatHitted: false,
  isCreateOrUpdateMsegatHitted: false,
};

export const msegatReducer = (state = MSEGAT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case MSEGAT_ACTION_TYPES.SET_MSEGATS:
      return { ...state, msegats: payload };
    case MSEGAT_ACTION_TYPES.SET_MSEGAT:
      return { ...state, msegat: payload };

    case MSEGAT_ACTION_TYPES.SET_IS_MSEGATS_HAS_MORE:
      return { ...state, isMsegatsHasMore: payload };

    case MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_SEARCH:
      return { ...state, fetchMsegatsSearch: payload };
    case MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_PAGE:
      return { ...state, fetchMsegatsPage: payload };
    case MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_PER_PAGE:
      return { ...state, fetchMsegatsPerPage: payload };
    case MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_INCLUDES:
      return { ...state, fetchMsegatsIncludes: payload };
    case MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_FILTER_IS_ACTIVE:
      return { ...state, fetchMsegatsFilterIsActive: payload };
    case MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_LOADING:
      return { ...state, fetchMsegatsLoading: payload };
    case MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_SUCCESS:
      return { ...state, fetchMsegatsSuccess: payload };
    case MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_FAILED:
      return { ...state, fetchMsegatsFailed: payload };

    case MSEGAT_ACTION_TYPES.SET_FETCH_MSEGAT_LOADING:
      return { ...state, fetchMsegatLoading: payload };
    case MSEGAT_ACTION_TYPES.SET_FETCH_MSEGAT_SUCCESS:
      return { ...state, fetchMsegatSuccess: payload };
    case MSEGAT_ACTION_TYPES.SET_FETCH_MSEGAT_FAILED:
      return { ...state, fetchMsegatFailed: payload };

    case MSEGAT_ACTION_TYPES.SET_CREATE_OR_UPDATE_MSEGAT_LOADING:
      return { ...state, createOrUpdateMsegatLoading: payload };
    case MSEGAT_ACTION_TYPES.SET_CREATE_OR_UPDATE_MSEGAT_SUCCESS:
      return { ...state, createOrUpdateMsegatSuccess: payload };
    case MSEGAT_ACTION_TYPES.SET_CREATE_OR_UPDATE_MSEGAT_FAILED:
      return { ...state, createOrUpdateMsegatFailed: payload };

    case MSEGAT_ACTION_TYPES.APPEND_MSEGATS:
      return { ...state, msegats: [...state.msegats, ...payload] };

    case MSEGAT_ACTION_TYPES.SET_IS_FETCH_MSEGATS_HITTED:
      return { ...state, isFetchMsegatsHitted: payload };
    case MSEGAT_ACTION_TYPES.SET_IS_FETCH_MSEGAT_HITTED:
      return { ...state, isFetchMsegatHitted: payload };
    case MSEGAT_ACTION_TYPES.SET_IS_CREATE_OR_UPDATE_MSEGAT_HITTED:
      return { ...state, isCreateOrUpdateMsegatHitted: payload };

    case MSEGAT_ACTION_TYPES.RESET_MSEGAT_REDUCER:
      return MSEGAT_INITIAL_STATE;
    default:
      return state;
  }
};
