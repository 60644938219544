const DASHBOARD_MERCHANT_ACTION_TYPES = {
  RESET_DASHBOARD_MERCHANT_REDUCER:
    "dashboard-merchant/RESET_DASHBOARD_MERCHANT_REDUCER",

  SET_DASHBOARD_TOTAL_MARKET: "dashboard-merchant/SET_DASHBOARD_TOTAL_MARKET",
  SET_DASHBOARD_TOTAL_BRANCH: "dashboard-merchant/SET_DASHBOARD_TOTAL_BRANCH",
  SET_DASHBOARD_TOTAL_ORDER: "dashboard-merchant/SET_DASHBOARD_TOTAL_ORDER",
  SET_DASHBOARD_TOTAL_REVENUE: "dashboard-merchant/SET_DASHBOARD_TOTAL_REVENUE",
  SET_DASHBOARD_ORDERS_CHART: "dashboard-merchant/SET_DASHBOARD_ORDERS_CHART",
  SET_DASHBOARD_LATEST_MARKETS:
    "dashboard-merchant/SET_DASHBOARD_LATEST_MARKETS",

  SET_DASHBOARD_TOTAL_MARKET_LOADING:
    "dashboard-merchant/SET_DASHBOARD_TOTAL_MARKET_LOADING",
  SET_DASHBOARD_TOTAL_BRANCH_LOADING:
    "dashboard-merchant/SET_DASHBOARD_TOTAL_BRANCH_LOADING",
  SET_DASHBOARD_TOTAL_ORDER_LOADING:
    "dashboard-merchant/SET_DASHBOARD_TOTAL_ORDER_LOADING",
  SET_DASHBOARD_TOTAL_REVENUE_LOADING:
    "dashboard-merchant/SET_DASHBOARD_TOTAL_REVENUE_LOADING",
  SET_DASHBOARD_ORDERS_CHART_LOADING:
    "dashboard-merchant/SET_DASHBOARD_ORDERS_CHART_LOADING",
  SET_DASHBOARD_LATEST_MARKETS_LOADING:
    "dashboard-merchant/SET_DASHBOARD_LATEST_MARKETS_LOADING",

  SET_FETCH_DASHBOARD_MERCHANT_FILTER_CREATED_AT_BEFORE:
    "dashboard-merchant/SET_FETCH_DASHBOARD_MERCHANT_FILTER_CREATED_AT_BEFORE",
  SET_FETCH_DASHBOARD_MERCHANT_FILTER_CREATED_AT_AFTER:
    "dashboard-merchant/SET_FETCH_DASHBOARD_MERCHANT_FILTER_CREATED_AT_AFTER",
  SET_FETCH_DASHBOARD_MERCHANT_FILTER_CURRENCY:
    "dashboard-merchant/SET_FETCH_DASHBOARD_MERCHANT_FILTER_CURRENCY",
  SET_FETCH_DASHBOARD_MERCHANT_LOADING:
    "dashboard-merchant/SET_FETCH_DASHBOARD_MERCHANT_LOADING",
  SET_FETCH_DASHBOARD_MERCHANT_SUCCESS:
    "dashboard-merchant/SET_FETCH_DASHBOARD_MERCHANT_SUCCESS",
  SET_FETCH_DASHBOARD_MERCHANT_FAILED:
    "dashboard-merchant/SET_FETCH_DASHBOARD_MERCHANT_FAILED",

  SET_IS_FETCH_DASHBOARD_MERCHANT_HITTED:
    "dashboard-merchant/SET_IS_FETCH_DASHBOARD_MERCHANT_HITTED",

  FETCH_DASHBOARD_MERCHANT_START:
    "dashboard-merchant/FETCH_DASHBOARD_MERCHANT_START",
};

export default DASHBOARD_MERCHANT_ACTION_TYPES;
