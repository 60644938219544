const SECTOR_ACTION_TYPES = {
  RESET_SECTOR_REDUCER: "sector/RESET_SECTOR_REDUCER",

  SET_SECTORS: "sector/SET_SECTORS",
  SET_SELECT_SECTORS: "sector/SET_SELECT_SECTORS",
  SET_SECTOR: "sector/SET_SECTOR",

  SET_IS_SECTORS_HAS_MORE: "sector/SET_IS_SECTORS_HAS_MORE",
  SET_IS_SELECT_SECTORS_HAS_MORE: "sector/SET_IS_SELECT_SECTORS_HAS_MORE",

  SET_FETCH_SECTORS_SEARCH: "sector/SET_FETCH_SECTORS_SEARCH",
  SET_FETCH_SECTORS_SORT: "sector/SET_FETCH_SECTORS_SORT",
  SET_FETCH_SECTORS_KEY_BY: "sector/SET_FETCH_SECTORS_KEY_BY",
  SET_FETCH_SECTORS_PAGE: "sector/SET_FETCH_SECTORS_PAGE",
  SET_FETCH_SECTORS_PER_PAGE: "sector/SET_FETCH_SECTORS_PER_PAGE",
  SET_FETCH_SECTORS_INCLUDES: "sector/SET_FETCH_SECTORS_INCLUDES",
  SET_FETCH_SECTORS_LOADING: "sector/SET_FETCH_SECTORS_LOADING",
  SET_FETCH_SECTORS_SUCCESS: "sector/SET_FETCH_SECTORS_SUCCESS",
  SET_FETCH_SECTORS_FAILED: "sector/SET_FETCH_SECTORS_FAILED",

  SET_FETCH_SELECT_SECTORS_SEARCH: "sector/SET_FETCH_SELECT_SECTORS_SEARCH",
  SET_FETCH_SELECT_SECTORS_SORT: "sector/SET_FETCH_SELECT_SECTORS_SORT",
  SET_FETCH_SELECT_SECTORS_KEY_BY: "sector/SET_FETCH_SELECT_SECTORS_KEY_BY",
  SET_FETCH_SELECT_SECTORS_PAGE: "sector/SET_FETCH_SELECT_SECTORS_PAGE",
  SET_FETCH_SELECT_SECTORS_PER_PAGE: "sector/SET_FETCH_SELECT_SECTORS_PER_PAGE",
  SET_FETCH_SELECT_SECTORS_INCLUDES: "sector/SET_FETCH_SELECT_SECTORS_INCLUDES",
  SET_FETCH_SELECT_SECTORS_LOADING: "sector/SET_FETCH_SELECT_SECTORS_LOADING",
  SET_FETCH_SELECT_SECTORS_SUCCESS: "sector/SET_FETCH_SELECT_SECTORS_SUCCESS",
  SET_FETCH_SELECT_SECTORS_FAILED: "sector/SET_FETCH_SELECT_SECTORS_FAILED",

  SET_FETCH_SECTOR_LOADING: "sector/SET_FETCH_SECTOR_LOADING",
  SET_FETCH_SECTOR_SUCCESS: "sector/SET_FETCH_SECTOR_SUCCESS",
  SET_FETCH_SECTOR_FAILED: "sector/SET_FETCH_SECTOR_FAILED",

  SET_CREATE_SECTOR_LOADING: "sector/SET_CREATE_SECTOR_LOADING",
  SET_CREATE_SECTOR_SUCCESS: "sector/SET_CREATE_SECTOR_SUCCESS",
  SET_CREATE_SECTOR_FAILED: "sector/SET_CREATE_SECTOR_FAILED",

  SET_UPDATE_SECTOR_LOADING: "sector/SET_UPDATE_SECTOR_LOADING",
  SET_UPDATE_SECTOR_SUCCESS: "sector/SET_UPDATE_SECTOR_SUCCESS",
  SET_UPDATE_SECTOR_FAILED: "sector/SET_UPDATE_SECTOR_FAILED",

  SET_DELETE_SECTOR_LOADING: "sector/SET_DELETE_SECTOR_LOADING",
  SET_DELETE_SECTOR_SUCCESS: "sector/SET_DELETE_SECTOR_SUCCESS",
  SET_DELETE_SECTOR_FAILED: "sector/SET_DELETE_SECTOR_FAILED",

  APPEND_SECTORS: "sector/APPEND_SECTORS",
  APPEND_SELECT_SECTORS: "sector/APPEND_SELECT_SECTORS",

  IS_FETCH_SECTORS_HITTED: "sector/IS_FETCH_SECTORS_HITTED",
  IS_FETCH_SELECT_SECTORS_HITTED: "sector/IS_FETCH_SELECT_SECTORS_HITTED",
  IS_FETCH_SECTOR_HITTED: "sector/IS_FETCH_SECTOR_HITTED",
  IS_CREATE_SECTOR_HITTED: "sector/IS_CREATE_SECTOR_HITTED",
  IS_UPDATE_SECTOR_HITTED: "sector/IS_UPDATE_SECTOR_HITTED",
  IS_DELETE_SECTOR_HITTED: "sector/IS_DELETE_SECTOR_HITTED",

  FETCH_SECTORS_START: "sector/FETCH_SECTORS_START",
  FETCH_SELECT_SECTORS_START: "sector/FETCH_SELECT_SECTORS_START",
  FETCH_SECTOR_START: "sector/FETCH_SECTOR_START",
  CREATE_SECTOR_START: "sector/CREATE_SECTOR_START",
  UPDATE_SECTOR_START: "sector/UPDATE_SECTOR_START",
  DELETE_SECTOR_START: "sector/DELETE_SECTOR_START",
};

export default SECTOR_ACTION_TYPES;
