import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PERMISSION_SUBSCRIBER_ACTION_TYPES from "./permission-subscriber.type";

import {
  appendPermissions,
  appendSelectPermissions,
  setAppendSelectPermissionsFailed,
  setAppendSelectPermissionsLoading,
  setAppendSelectPermissionsSuccess,
  setCreatePermissionFailed,
  setCreatePermissionLoading,
  setCreatePermissionSuccess,
  setDeletePermissionFailed,
  setDeletePermissionLoading,
  setDeletePermissionSuccess,
  setFetchPermissionFailed,
  setFetchPermissionLoading,
  setFetchPermissionsFailed,
  setFetchPermissionsLoading,
  setFetchPermissionsPage,
  setFetchPermissionsSuccess,
  setFetchPermissionSuccess,
  setFetchSelectPermissionsFailed,
  setFetchSelectPermissionsLoading,
  setFetchSelectPermissionsPage,
  setFetchSelectPermissionsSuccess,
  setIsAppendSelectPermissionsHitted,
  setIsCreatePermissionHitted,
  setIsDeletePermissionHitted,
  setIsFetchPermissionHitted,
  setIsFetchPermissionsHitted,
  setIsFetchSelectPermissionsHitted,
  setIsPermissionsHasMore,
  setIsSelectPermissionsHasMore,
  setIsUpdatePermissionHitted,
  setPermission,
  setPermissions,
  setUpdatePermissionFailed,
  setUpdatePermissionLoading,
  setUpdatePermissionSuccess,
} from "./permission-subscriber.action";
import {
  getAppendSelectPermissionsFilterIsDefault,
  getAppendSelectPermissionsFilterMarketId,
  getAppendSelectPermissionsIncludes,
  getAppendSelectPermissionsKeyBy,
  getAppendSelectPermissionsPage,
  getAppendSelectPermissionsPerPage,
  getAppendSelectPermissionsSearch,
  getAppendSelectPermissionsSort,
  getFetchPermissionsFilterIsDefault,
  getFetchPermissionsFilterMarketId,
  getFetchPermissionsIncludes,
  getFetchPermissionsKeyBy,
  getFetchPermissionsPage,
  getFetchPermissionsPerPage,
  getFetchPermissionsSearch,
  getFetchPermissionsSort,
  getFetchSelectPermissionsFilterIsDefault,
  getFetchSelectPermissionsFilterMarketId,
  getFetchSelectPermissionsIncludes,
  getFetchSelectPermissionsKeyBy,
  getFetchSelectPermissionsPage,
  getFetchSelectPermissionsPerPage,
  getFetchSelectPermissionsSearch,
  getFetchSelectPermissionsSort,
  getIsFetchPermissionsHitted,
  getIsFetchSelectPermissionsHitted,
} from "./permission-subscriber.selector";

import {
  createPermissionSubscriber,
  deletePermissionSubscriber,
  getPermissionSubscriber,
  getPermissionSubscribers,
  updatePermissionSubscriber,
} from "../../api/permission-subscriber.api";

export function* _getPermissions() {
  try {
    yield put(setFetchPermissionsLoading(true));

    const search = yield select(getFetchPermissionsSearch);
    const sort = yield select(getFetchPermissionsSort);
    const key_by = yield select(getFetchPermissionsKeyBy);
    const page = yield select(getFetchPermissionsPage);
    const per_page = yield select(getFetchPermissionsPerPage);
    const includes = yield select(getFetchPermissionsIncludes);
    const market_id = yield select(getFetchPermissionsFilterMarketId);
    const is_default = yield select(getFetchPermissionsFilterIsDefault);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id, is_default },
    };

    const {
      meta: { message },
      data: { data: permissions },
    } = yield call(getPermissionSubscribers, parameters);

    yield put(setIsFetchPermissionsHitted(true));
    yield put(setIsPermissionsHasMore(permissions.length > 0));

    if (page > 1) {
      yield put(appendPermissions(permissions));
    } else {
      yield put(setPermissions(permissions));
    }

    yield put(setFetchPermissionsSuccess(message));
    yield put(setFetchPermissionsLoading(false));
  } catch (error) {
    yield put(setFetchPermissionsFailed(error));
    yield put(setFetchPermissionsLoading(false));
  }
}
export function* _getSelectPermissions() {
  try {
    yield put(setFetchSelectPermissionsLoading(true));

    const search = yield select(getFetchSelectPermissionsSearch);
    const sort = yield select(getFetchSelectPermissionsSort);
    const key_by = yield select(getFetchSelectPermissionsKeyBy);
    const page = yield select(getFetchSelectPermissionsPage);
    const per_page = yield select(getFetchSelectPermissionsPerPage);
    const includes = yield select(getFetchSelectPermissionsIncludes);
    const market_id = yield select(getFetchSelectPermissionsFilterMarketId);
    const is_default = yield select(getFetchSelectPermissionsFilterIsDefault);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id, is_default },
    };

    const {
      meta: { message },
      data: permissions,
    } = yield call(getPermissionSubscribers, parameters);

    yield put(setIsFetchSelectPermissionsHitted(true));
    yield put(
      setIsSelectPermissionsHasMore(Object.keys(permissions).length > 0)
    );
    yield put(appendSelectPermissions(permissions));

    yield put(setFetchSelectPermissionsSuccess(message));
    yield put(setFetchSelectPermissionsLoading(false));
  } catch (error) {
    yield put(setFetchSelectPermissionsFailed(error));
    yield put(setFetchSelectPermissionsLoading(false));
  }
}
export function* _getAppendSelectPermissions() {
  try {
    yield put(setAppendSelectPermissionsLoading(true));

    const search = yield select(getAppendSelectPermissionsSearch);
    const sort = yield select(getAppendSelectPermissionsSort);
    const key_by = yield select(getAppendSelectPermissionsKeyBy);
    const page = yield select(getAppendSelectPermissionsPage);
    const per_page = yield select(getAppendSelectPermissionsPerPage);
    const includes = yield select(getAppendSelectPermissionsIncludes);
    const market_id = yield select(getAppendSelectPermissionsFilterMarketId);
    const is_default = yield select(getAppendSelectPermissionsFilterIsDefault);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id, is_default },
    };

    const {
      meta: { message },
      data: permissions,
    } = yield call(getPermissionSubscribers, parameters);

    yield put(setIsAppendSelectPermissionsHitted(true));
    yield put(appendSelectPermissions(permissions));

    yield put(setAppendSelectPermissionsSuccess(message));
    yield put(setAppendSelectPermissionsLoading(false));
  } catch (error) {
    yield put(setAppendSelectPermissionsFailed(error));
    yield put(setAppendSelectPermissionsLoading(false));
  }
}
export function* _getPermission({ payload: permissionId }) {
  try {
    yield put(setFetchPermissionLoading(true));

    const {
      meta: { message },
      data: permission,
    } = yield call(getPermissionSubscriber, permissionId);

    yield put(setIsFetchPermissionHitted(true));
    yield put(setPermission(permission));

    yield put(setFetchPermissionSuccess(message));
    yield put(setFetchPermissionLoading(false));
  } catch (error) {
    yield put(setFetchPermissionFailed(error));
    yield put(setFetchPermissionLoading(false));
  }
}
export function* _createPermission({ payload: request }) {
  try {
    yield put(setCreatePermissionLoading(true));

    const {
      meta: { message },
    } = yield call(createPermissionSubscriber, request);

    yield put(setIsCreatePermissionHitted(true));

    const isFetchPermissionsHitted = yield select(getIsFetchPermissionsHitted);
    const isFetchSelectPermissionsHitted = yield select(
      getIsFetchSelectPermissionsHitted
    );

    if (isFetchPermissionsHitted) {
      yield put(setFetchPermissionsPage(1));
      yield call(_getPermissions);
    }
    if (isFetchSelectPermissionsHitted) {
      yield put(setFetchSelectPermissionsPage(1));
      yield call(_getSelectPermissions);
    }

    yield put(setCreatePermissionSuccess(message));
    yield put(setCreatePermissionLoading(false));
  } catch (error) {
    yield put(setCreatePermissionFailed(error));
    yield put(setCreatePermissionLoading(false));
  }
}
export function* _updatePermission({ payload: { permissionId, request } }) {
  try {
    yield put(setUpdatePermissionLoading(true));

    const {
      meta: { message },
    } = yield call(updatePermissionSubscriber, permissionId, request);

    yield put(setIsUpdatePermissionHitted(true));

    const isFetchPermissionsHitted = yield select(getIsFetchPermissionsHitted);
    const isFetchSelectPermissionsHitted = yield select(
      getIsFetchSelectPermissionsHitted
    );

    if (isFetchPermissionsHitted) {
      yield put(setFetchPermissionsPage(1));
      yield call(_getPermissions);
    }
    if (isFetchSelectPermissionsHitted) {
      yield put(setFetchSelectPermissionsPage(1));
      yield call(_getSelectPermissions);
    }

    yield put(setUpdatePermissionSuccess(message));
    yield put(setUpdatePermissionLoading(false));
  } catch (error) {
    yield put(setUpdatePermissionFailed(error));
    yield put(setUpdatePermissionLoading(false));
  }
}
export function* _deletePermission({ payload: permissionId }) {
  try {
    yield put(setDeletePermissionLoading(true));

    const {
      meta: { message },
    } = yield call(deletePermissionSubscriber, permissionId);

    yield put(setIsDeletePermissionHitted(true));

    const isFetchPermissionsHitted = yield select(getIsFetchPermissionsHitted);
    const isFetchSelectPermissionsHitted = yield select(
      getIsFetchSelectPermissionsHitted
    );

    if (isFetchPermissionsHitted) {
      yield put(setFetchPermissionsPage(1));
      yield call(_getPermissions);
    }
    if (isFetchSelectPermissionsHitted) {
      yield put(setFetchSelectPermissionsPage(1));
      yield call(_getSelectPermissions);
    }

    yield put(setDeletePermissionSuccess(message));
    yield put(setDeletePermissionLoading(false));
  } catch (error) {
    yield put(setDeletePermissionFailed(error));
    yield put(setDeletePermissionLoading(false));
  }
}

export function* onFetchPermissionsStart() {
  yield takeLatest(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.FETCH_PERMISSIONS_START,
    _getPermissions
  );
}
export function* onFetchSelectPermissionsStart() {
  yield takeLatest(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.FETCH_SELECT_PERMISSIONS_START,
    _getSelectPermissions
  );
}
export function* onAppendSelectPermissionsStart() {
  yield takeLatest(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.APPEND_SELECT_PERMISSIONS_START,
    _getAppendSelectPermissions
  );
}
export function* onFetchPermissionStart() {
  yield takeLatest(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.FETCH_PERMISSION_START,
    _getPermission
  );
}
export function* onCreatePermissionStart() {
  yield takeLatest(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.CREATE_PERMISSION_START,
    _createPermission
  );
}
export function* onUpdatePermissionStart() {
  yield takeLatest(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.UPDATE_PERMISSION_START,
    _updatePermission
  );
}
export function* onDeletePermissionStart() {
  yield takeLatest(
    PERMISSION_SUBSCRIBER_ACTION_TYPES.DELETE_PERMISSION_START,
    _deletePermission
  );
}

export function* permissionSubscriberSaga() {
  yield all([
    call(onFetchPermissionsStart),
    call(onFetchSelectPermissionsStart),
    call(onAppendSelectPermissionsStart),
    call(onFetchPermissionStart),
    call(onCreatePermissionStart),
    call(onUpdatePermissionStart),
    call(onDeletePermissionStart),
  ]);
}
