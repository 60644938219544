import INGREDIENT_HISTORY_ACTION_TYPES from "./ingredient-history.type";

export const INGREDIENT_HISTORY_INITIAL_STATE = {
  ingredientHistories: [],
  ingredientHistory: null,

  isIngredientHistoriesHasMore: true,

  fetchIngredientHistoriesSearch: null,
  fetchIngredientHistoriesSort: null,
  fetchIngredientHistoriesPage: 1,
  fetchIngredientHistoriesPerPage: null,
  fetchIngredientHistoriesIncludes: null,
  fetchIngredientHistoriesLoading: false,
  fetchIngredientHistoriesSuccess: null,
  fetchIngredientHistoriesFailed: null,

  fetchIngredientHistoryLoading: false,
  fetchIngredientHistorySuccess: null,
  fetchIngredientHistoryFailed: null,

  isFetchIngredientHistoriesHitted: false,
  isFetchIngredientHistoryHitted: false,
};

export const ingredientHistoryReducer = (state = INGREDIENT_HISTORY_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case INGREDIENT_HISTORY_ACTION_TYPES.SET_INGREDIENT_HISTORIES:
      return { ...state, ingredientHistories: payload };
    case INGREDIENT_HISTORY_ACTION_TYPES.SET_INGREDIENT_HISTORY:
      return { ...state, ingredientHistory: payload };

    case INGREDIENT_HISTORY_ACTION_TYPES.SET_IS_INGREDIENT_HISTORIES_HAS_MORE:
      return { ...state, isIngredientHistoriesHasMore: payload };

    case INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_SEARCH:
      return { ...state, fetchIngredientHistoriesSearch: payload };
    case INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_SORT:
      return { ...state, fetchIngredientHistoriesSort: payload };
    case INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_PAGE:
      return { ...state, fetchIngredientHistoriesPage: payload };
    case INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_PER_PAGE:
      return { ...state, fetchIngredientHistoriesPerPage: payload };
    case INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_LOADING:
      return { ...state, fetchIngredientHistoriesLoading: payload };
    case INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_SUCCESS:
      return { ...state, fetchIngredientHistoriesSuccess: payload };
    case INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORIES_FAILED:
      return { ...state, fetchIngredientHistoriesFailed: payload };

    case INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORY_LOADING:
      return { ...state, fetchIngredientHistoryLoading: payload };
    case INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORY_SUCCESS:
      return { ...state, fetchIngredientHistorySuccess: payload };
    case INGREDIENT_HISTORY_ACTION_TYPES.SET_FETCH_INGREDIENT_HISTORY_FAILED:
      return { ...state, fetchIngredientHistoryFailed: payload };

    case INGREDIENT_HISTORY_ACTION_TYPES.APPEND_INGREDIENT_HISTORIES:
      return { ...state, ingredientHistories: [...state.ingredientHistories, ...payload] };

    case INGREDIENT_HISTORY_ACTION_TYPES.SET_IS_FETCH_INGREDIENT_HISTORIES_HITTED:
      return { ...state, isFetchIngredientHistoriesHitted: payload };
    case INGREDIENT_HISTORY_ACTION_TYPES.SET_IS_FETCH_INGREDIENT_HISTORY_HITTED:
      return { ...state, isFetchIngredientHistoryHitted: payload };

    case INGREDIENT_HISTORY_ACTION_TYPES.RESET_INGREDIENT_HISTORY_REDUCER:
      return INGREDIENT_HISTORY_INITIAL_STATE;
    default:
      return state;
  }
};
