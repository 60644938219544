import { createSelector } from "reselect";

const sectorSelector = ({ sector }) => sector;

export const getSectors = createSelector(
  [sectorSelector],
  ({ sectors }) => sectors
);
export const getSelectSectors = createSelector(
  [sectorSelector],
  ({ selectSectors }) => selectSectors
);
export const getSector = createSelector(
  [sectorSelector],
  ({ sector }) => sector
);

export const getIsSectorsHasMore = createSelector(
  [sectorSelector],
  ({ isSectorsHasMore }) => isSectorsHasMore
);
export const getIsSelectSectorsHasMore = createSelector(
  [sectorSelector],
  ({ isSelectSectorsHasMore }) => isSelectSectorsHasMore
);

export const getFetchSectorsSearch = createSelector(
  [sectorSelector],
  ({ fetchSectorsSearch }) => fetchSectorsSearch
);
export const getFetchSectorsSort = createSelector(
  [sectorSelector],
  ({ fetchSectorsSort }) => fetchSectorsSort
);
export const getFetchSectorsKeyBy = createSelector(
  [sectorSelector],
  ({ fetchSectorsKeyBy }) => fetchSectorsKeyBy
);
export const getFetchSectorsPage = createSelector(
  [sectorSelector],
  ({ fetchSectorsPage }) => fetchSectorsPage
);
export const getFetchSectorsPerPage = createSelector(
  [sectorSelector],
  ({ fetchSectorsPerPage }) => fetchSectorsPerPage
);
export const getFetchSectorsIncludes = createSelector(
  [sectorSelector],
  ({ fetchSectorsIncludes }) => fetchSectorsIncludes
);
export const getFetchSectorsLoading = createSelector(
  [sectorSelector],
  ({ fetchSectorsLoading }) => fetchSectorsLoading
);
export const getFetchSectorsSuccess = createSelector(
  [sectorSelector],
  ({ fetchSectorsSuccess }) => fetchSectorsSuccess
);
export const getFetchSectorsFailed = createSelector(
  [sectorSelector],
  ({ fetchSectorsFailed }) => fetchSectorsFailed
);

export const getFetchSelectSectorsSearch = createSelector(
  [sectorSelector],
  ({ fetchSelectSectorsSearch }) => fetchSelectSectorsSearch
);
export const getFetchSelectSectorsSort = createSelector(
  [sectorSelector],
  ({ fetchSelectSectorsSort }) => fetchSelectSectorsSort
);
export const getFetchSelectSectorsKeyBy = createSelector(
  [sectorSelector],
  ({ fetchSelectSectorsKeyBy }) => fetchSelectSectorsKeyBy
);
export const getFetchSelectSectorsPage = createSelector(
  [sectorSelector],
  ({ fetchSelectSectorsPage }) => fetchSelectSectorsPage
);
export const getFetchSelectSectorsPerPage = createSelector(
  [sectorSelector],
  ({ fetchSelectSectorsPerPage }) => fetchSelectSectorsPerPage
);
export const getFetchSelectSectorsIncludes = createSelector(
  [sectorSelector],
  ({ fetchSelectSectorsIncludes }) => fetchSelectSectorsIncludes
);
export const getFetchSelectSectorsLoading = createSelector(
  [sectorSelector],
  ({ fetchSelectSectorsLoading }) => fetchSelectSectorsLoading
);
export const getFetchSelectSectorsSuccess = createSelector(
  [sectorSelector],
  ({ fetchSelectSectorsSuccess }) => fetchSelectSectorsSuccess
);
export const getFetchSelectSectorsFailed = createSelector(
  [sectorSelector],
  ({ fetchSelectSectorsFailed }) => fetchSelectSectorsFailed
);

export const getFetchSectorLoading = createSelector(
  [sectorSelector],
  ({ fetchSectorLoading }) => fetchSectorLoading
);
export const getFetchSectorSuccess = createSelector(
  [sectorSelector],
  ({ fetchSectorSuccess }) => fetchSectorSuccess
);
export const getFetchSectorFailed = createSelector(
  [sectorSelector],
  ({ fetchSectorFailed }) => fetchSectorFailed
);

export const getCreateSectorLoading = createSelector(
  [sectorSelector],
  ({ createSectorLoading }) => createSectorLoading
);
export const getCreateSectorSuccess = createSelector(
  [sectorSelector],
  ({ createSectorSuccess }) => createSectorSuccess
);
export const getCreateSectorFailed = createSelector(
  [sectorSelector],
  ({ createSectorFailed }) => createSectorFailed
);

export const getUpdateSectorLoading = createSelector(
  [sectorSelector],
  ({ updateSectorLoading }) => updateSectorLoading
);
export const getUpdateSectorSuccess = createSelector(
  [sectorSelector],
  ({ updateSectorSuccess }) => updateSectorSuccess
);
export const getUpdateSectorFailed = createSelector(
  [sectorSelector],
  ({ updateSectorFailed }) => updateSectorFailed
);

export const getDeleteSectorLoading = createSelector(
  [sectorSelector],
  ({ deleteSectorLoading }) => deleteSectorLoading
);
export const getDeleteSectorSuccess = createSelector(
  [sectorSelector],
  ({ deleteSectorSuccess }) => deleteSectorSuccess
);
export const getDeleteSectorFailed = createSelector(
  [sectorSelector],
  ({ deleteSectorFailed }) => deleteSectorFailed
);

export const getIsFetchSectorsHitted = createSelector(
  [sectorSelector],
  ({ isFetchSectorsHitted }) => isFetchSectorsHitted
);
export const getIsFetchSelectSectorsHitted = createSelector(
  [sectorSelector],
  ({ isFetchSelectSectorsHitted }) => isFetchSelectSectorsHitted
);
export const getIsFetchSectorHitted = createSelector(
  [sectorSelector],
  ({ isFetchSectorHitted }) => isFetchSectorHitted
);
export const getIsCreateSectorHitted = createSelector(
  [sectorSelector],
  ({ isCreateSectorHitted }) => isCreateSectorHitted
);
export const getIsUpdateSectorHitted = createSelector(
  [sectorSelector],
  ({ isUpdateSectorHitted }) => isUpdateSectorHitted
);
export const getIsDeleteSectorHitted = createSelector(
  [sectorSelector],
  ({ isDeleteSectorHitted }) => isDeleteSectorHitted
);
