import RESTOCK_REQUEST_ACTION_TYPES from "./restock-request.type";
import { createAction } from "../../utils/store.utils";

export const setRestockRequests = (restockRequests) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_RESTOCK_REQUESTS,
    restockRequests
  );
export const setRestockRequest = (restockRequest) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_RESTOCK_REQUEST,
    restockRequest
  );

export const setIsRestockRequestsHasMore = (isRestockRequestsHasMore) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_IS_RESTOCK_REQUESTS_HAS_MORE,
    isRestockRequestsHasMore
  );

export const setFetchRestockRequestsSearch = (fetchRestockRequestsSearch) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_SEARCH,
    fetchRestockRequestsSearch
  );
export const setFetchRestockRequestsPage = (fetchRestockRequestsPage) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_PAGE,
    fetchRestockRequestsPage
  );
export const setFetchRestockRequestsPerPage = (fetchRestockRequestsPerPage) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_PER_PAGE,
    fetchRestockRequestsPerPage
  );
export const setFetchRestockRequestsIncludes = (fetchRestockRequestsIncludes) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_INCLUDES,
    fetchRestockRequestsIncludes
  );
export const setFetchRestockRequestsFilterMarketId = (
  fetchRestockRequestsFilterMarketId
) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_FILTER_MARKET_ID,
    fetchRestockRequestsFilterMarketId
  );
export const setFetchRestockRequestsFilterBranchId = (
  fetchRestockRequestsFilterBranchId
) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_FILTER_BRANCH_ID,
    fetchRestockRequestsFilterBranchId
  );
export const setFetchRestockRequestsFilterStatuses = (
  fetchRestockRequestsFilterStatuses
) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_FILTER_STATUSES,
    fetchRestockRequestsFilterStatuses
  );
export const setFetchRestockRequestsLoading = (fetchRestockRequestsLoading) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_LOADING,
    fetchRestockRequestsLoading
  );
export const setFetchRestockRequestsSuccess = (fetchRestockRequestsSuccess) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_SUCCESS,
    fetchRestockRequestsSuccess
  );
export const setFetchRestockRequestsFailed = (fetchRestockRequestsFailed) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_FAILED,
    fetchRestockRequestsFailed
  );

export const setFetchRestockRequestLoading = (fetchRestockRequestLoading) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUEST_LOADING,
    fetchRestockRequestLoading
  );
export const setFetchRestockRequestSuccess = (fetchRestockRequestSuccess) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUEST_SUCCESS,
    fetchRestockRequestSuccess
  );
export const setFetchRestockRequestFailed = (fetchRestockRequestFailed) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUEST_FAILED,
    fetchRestockRequestFailed
  );

export const setCreateRestockRequestLoading = (createRestockRequestLoading) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_CREATE_RESTOCK_REQUEST_LOADING,
    createRestockRequestLoading
  );
export const setCreateRestockRequestSuccess = (createRestockRequestSuccess) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_CREATE_RESTOCK_REQUEST_SUCCESS,
    createRestockRequestSuccess
  );
export const setCreateRestockRequestFailed = (createRestockRequestFailed) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_CREATE_RESTOCK_REQUEST_FAILED,
    createRestockRequestFailed
  );

export const setUpdateRestockRequestLoading = (updateRestockRequestLoading) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_UPDATE_RESTOCK_REQUEST_LOADING,
    updateRestockRequestLoading
  );
export const setUpdateRestockRequestSuccess = (updateRestockRequestSuccess) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_UPDATE_RESTOCK_REQUEST_SUCCESS,
    updateRestockRequestSuccess
  );
export const setUpdateRestockRequestFailed = (updateRestockRequestFailed) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_UPDATE_RESTOCK_REQUEST_FAILED,
    updateRestockRequestFailed
  );

export const setDeleteRestockRequestLoading = (deleteRestockRequestLoading) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_DELETE_RESTOCK_REQUEST_LOADING,
    deleteRestockRequestLoading
  );
export const setDeleteRestockRequestSuccess = (deleteRestockRequestSuccess) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_DELETE_RESTOCK_REQUEST_SUCCESS,
    deleteRestockRequestSuccess
  );
export const setDeleteRestockRequestFailed = (deleteRestockRequestFailed) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.SET_DELETE_RESTOCK_REQUEST_FAILED,
    deleteRestockRequestFailed
  );

export const appendRestockRequests = (restockRequests) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.APPEND_RESTOCK_REQUESTS,
    restockRequests
  );

export const fetchRestockRequestsStart = () =>
  createAction(RESTOCK_REQUEST_ACTION_TYPES.FETCH_RESTOCK_REQUESTS_START);
export const fetchRestockRequestStart = (restockRequestId) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.FETCH_RESTOCK_REQUEST_START,
    restockRequestId
  );
export const createRestockRequestStart = (request) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.CREATE_RESTOCK_REQUEST_START,
    request
  );
export const updateRestockRequestStart = (restockRequestId, request) =>
  createAction(RESTOCK_REQUEST_ACTION_TYPES.UPDATE_RESTOCK_REQUEST_START, {
    restockRequestId,
    request,
  });
export const deleteRestockRequestStart = (restockRequestId) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.DELETE_RESTOCK_REQUEST_START,
    restockRequestId
  );

export const setIsFetchRestockRequestsHitted = (isFetchRestockRequestsHitted) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.IS_FETCH_RESTOCK_REQUESTS_HITTED,
    isFetchRestockRequestsHitted
  );
export const setIsFetchRestockRequestHitted = (isFetchRestockRequestHitted) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.IS_FETCH_RESTOCK_REQUEST_HITTED,
    isFetchRestockRequestHitted
  );
export const setIsCreateRestockRequestHitted = (isCreateRestockRequestHitted) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.IS_CREATE_RESTOCK_REQUEST_HITTED,
    isCreateRestockRequestHitted
  );
export const setIsUpdateRestockRequestHitted = (isUpdateRestockRequestHitted) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.IS_UPDATE_RESTOCK_REQUEST_HITTED,
    isUpdateRestockRequestHitted
  );
export const setIsDeleteRestockRequestHitted = (isDeleteRestockRequestHitted) =>
  createAction(
    RESTOCK_REQUEST_ACTION_TYPES.IS_DELETE_RESTOCK_REQUEST_HITTED,
    isDeleteRestockRequestHitted
  );

export const resetRestockRequestReducer = () =>
  createAction(RESTOCK_REQUEST_ACTION_TYPES.RESET_RESTOCK_REQUEST_REDUCER);
