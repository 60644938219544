import SELECT_PRODUCT_CATEGORY_ACTION_TYPES from "./select-product-category.type";

const SELECT_PRODUCT_CATEGORY_INITIAL_STATE = {
  productCategories: {},
  searchProductCategories: {},

  isProductCategoriesHasMore: true,
  isSearchProductCategoriesHasMore: true,

  fetchProductCategoriesParams: {},
  fetchProductCategoriesLoading: false,
  fetchProductCategoriesSuccess: null,
  fetchProductCategoriesFailed: null,

  searchProductCategoriesParams: {},
  searchProductCategoriesLoading: false,
  searchProductCategoriesSuccess: null,
  searchProductCategoriesFailed: null,

  appendProductCategoriesParams: {},
  appendProductCategoriesLoading: false,
  appendProductCategoriesSuccess: null,
  appendProductCategoriesFailed: null,

  isFetchProductCategoriesHitted: false,
  isSearchProductCategoriesHitted: false,
  isAppendProductCategoriesHitted: false,
};

export const selectProductCategoryReducer = (
  state = SELECT_PRODUCT_CATEGORY_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_PRODUCT_CATEGORIES:
      return { ...state, productCategories: payload };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_SEARCH_PRODUCT_CATEGORIES:
      return { ...state, searchProductCategories: payload };

    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_PRODUCT_CATEGORIES_HAS_MORE:
      return { ...state, isProductCategoriesHasMore: payload };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_SEARCH_PRODUCT_CATEGORIES_HAS_MORE:
      return { ...state, isSearchProductCategoriesHasMore: payload };

    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_PARAMS:
      return { ...state, fetchProductCategoriesParams: payload };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_LOADING:
      return { ...state, fetchProductCategoriesLoading: payload };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, fetchProductCategoriesSuccess: payload };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FAILED:
      return { ...state, fetchProductCategoriesFailed: payload };

    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_SEARCH_PRODUCT_CATEGORIES_PARAMS:
      return { ...state, searchProductCategoriesParams: payload };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_SEARCH_PRODUCT_CATEGORIES_LOADING:
      return { ...state, searchProductCategoriesLoading: payload };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_SEARCH_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, searchProductCategoriesSuccess: payload };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_SEARCH_PRODUCT_CATEGORIES_FAILED:
      return { ...state, searchProductCategoriesFailed: payload };

    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_PRODUCT_CATEGORIES_PARAMS:
      return { ...state, appendProductCategoriesParams: payload };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_PRODUCT_CATEGORIES_LOADING:
      return { ...state, appendProductCategoriesLoading: payload };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, appendProductCategoriesSuccess: payload };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_PRODUCT_CATEGORIES_FAILED:
      return { ...state, appendProductCategoriesFailed: payload };

    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_FETCH_PRODUCT_CATEGORIES_HITTED:
      return { ...state, isFetchProductCategoriesHitted: payload };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_SEARCH_PRODUCT_CATEGORIES_HITTED:
      return { ...state, isSearchProductCategoriesHitted: payload };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_APPEND_PRODUCT_CATEGORIES_HITTED:
      return { ...state, isAppendProductCategoriesHitted: payload };

    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.APPEND_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategories: { ...state.productCategories, ...payload },
      };
    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.APPEND_SEARCH_PRODUCT_CATEGORIES:
      return {
        ...state,
        searchProductCategories: {
          ...state.searchProductCategories,
          ...payload,
        },
      };

    case SELECT_PRODUCT_CATEGORY_ACTION_TYPES.RESET_SELECT_PRODUCT_CATEGORY_REDUCER:
      return SELECT_PRODUCT_CATEGORY_INITIAL_STATE;
    default:
      return state;
  }
};
