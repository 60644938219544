import { createSelector } from "reselect";

const printerSelector = ({ printer }) => printer;

export const getPrinterTargets = createSelector([printerSelector], ({ printerTargets }) => printerTargets);

export const getPrinterServiceTypes = createSelector(
  [printerSelector],
  ({ printerServiceTypes }) => printerServiceTypes
);

export const getPrinters = createSelector([printerSelector], ({ printers }) => printers);

export const getPrinter = createSelector([printerSelector], ({ printer }) => printer);

export const getIsPrintersHasMore = createSelector([printerSelector], ({ isPrintersHasMore }) => isPrintersHasMore);

export const getFetchPrintersSearch = createSelector(
  [printerSelector],
  ({ fetchPrintersSearch }) => fetchPrintersSearch
);

export const getFetchPrintersPage = createSelector([printerSelector], ({ fetchPrintersPage }) => fetchPrintersPage);

export const getFetchPrintersPerPage = createSelector(
  [printerSelector],
  ({ fetchPrintersPerPage }) => fetchPrintersPerPage
);

export const getFetchPrintersIncludes = createSelector(
  [printerSelector],
  ({ fetchPrintersIncludes }) => fetchPrintersIncludes
);

export const getFetchPrintersFilterMarketId = createSelector(
  [printerSelector],
  ({ fetchPrintersFilterMarketId }) => fetchPrintersFilterMarketId
);

export const getFetchPrintersFilterBranchId = createSelector(
  [printerSelector],
  ({ fetchPrintersFilterBranchId }) => fetchPrintersFilterBranchId
);

export const getFetchPrintersFilterPrinterTypeId = createSelector(
  [printerSelector],
  ({ fetchPrintersFilterPrinterTypeId }) => fetchPrintersFilterPrinterTypeId
);

export const getFetchPrintersFilterIsActive = createSelector(
  [printerSelector],
  ({ fetchPrintersFilterIsActive }) => fetchPrintersFilterIsActive
);

export const getFetchPrintersLoading = createSelector(
  [printerSelector],
  ({ fetchPrintersLoading }) => fetchPrintersLoading
);

export const getFetchPrintersSuccess = createSelector(
  [printerSelector],
  ({ fetchPrintersSuccess }) => fetchPrintersSuccess
);

export const getFetchPrintersFailed = createSelector(
  [printerSelector],
  ({ fetchPrintersFailed }) => fetchPrintersFailed
);

export const getFetchPrinterLoading = createSelector(
  [printerSelector],
  ({ fetchPrinterLoading }) => fetchPrinterLoading
);

export const getFetchPrinterSuccess = createSelector(
  [printerSelector],
  ({ fetchPrinterSuccess }) => fetchPrinterSuccess
);

export const getFetchPrinterFailed = createSelector([printerSelector], ({ fetchPrinterFailed }) => fetchPrinterFailed);

export const getCreatePrinterLoading = createSelector(
  [printerSelector],
  ({ createPrinterLoading }) => createPrinterLoading
);

export const getCreatePrinterSuccess = createSelector(
  [printerSelector],
  ({ createPrinterSuccess }) => createPrinterSuccess
);

export const getCreatePrinterFailed = createSelector(
  [printerSelector],
  ({ createPrinterFailed }) => createPrinterFailed
);

export const getUpdatePrinterLoading = createSelector(
  [printerSelector],
  ({ updatePrinterLoading }) => updatePrinterLoading
);

export const getUpdatePrinterSuccess = createSelector(
  [printerSelector],
  ({ updatePrinterSuccess }) => updatePrinterSuccess
);

export const getUpdatePrinterFailed = createSelector(
  [printerSelector],
  ({ updatePrinterFailed }) => updatePrinterFailed
);

export const getDeletePrinterLoading = createSelector(
  [printerSelector],
  ({ deletePrinterLoading }) => deletePrinterLoading
);

export const getDeletePrinterSuccess = createSelector(
  [printerSelector],
  ({ deletePrinterSuccess }) => deletePrinterSuccess
);

export const getDeletePrinterFailed = createSelector(
  [printerSelector],
  ({ deletePrinterFailed }) => deletePrinterFailed
);
