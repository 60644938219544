import { takeLatest, put, all, call, select } from "redux-saga/effects";

import TABLE_ACTION_TYPES from "./table.type";

import {
  appendOrderTables,
  appendSelectTables,
  appendTables,
  setCreateTableFailed,
  setCreateTableLoading,
  setCreateTableSuccess,
  setDeleteTableFailed,
  setDeleteTableLoading,
  setDeleteTableSuccess,
  setFetchOrderTablesFailed,
  setFetchOrderTablesLoading,
  setFetchOrderTablesPage,
  setFetchOrderTablesSuccess,
  setFetchSelectTablesFailed,
  setFetchSelectTablesLoading,
  setFetchSelectTablesPage,
  setFetchSelectTablesSuccess,
  setFetchTableFailed,
  setFetchTableLoading,
  setFetchTablesFailed,
  setFetchTablesLoading,
  setFetchTablesPage,
  setFetchTablesSuccess,
  setFetchTableSuccess,
  setIsCreateTableHitted,
  setIsDeleteTableHitted,
  setIsFetchOrderTablesHitted,
  setIsFetchSelectTablesHitted,
  setIsFetchTableHitted,
  setIsFetchTablesHitted,
  setIsMultipleCreateTableHitted,
  setIsOrderTablesHasMore,
  setIsSelectTablesHasMore,
  setIsTablesHasMore,
  setIsUpdateTableHitted,
  setMultipleCreateTableFailed,
  setMultipleCreateTableLoading,
  setMultipleCreateTableSuccess,
  setOrderTables,
  setSelectTables,
  setTable,
  setTables,
  setUpdateTableFailed,
  setUpdateTableLoading,
  setUpdateTableSuccess,
} from "./table.action";
import {
  getFetchOrderTablesFilterBranchId,
  getFetchOrderTablesFilterMarketId,
  getFetchOrderTablesFilterStatus,
  getFetchOrderTablesIncludes,
  getFetchOrderTablesPage,
  getFetchOrderTablesPerPage,
  getFetchOrderTablesSearch,
  getFetchSelectTablesFilterBranchId,
  getFetchSelectTablesFilterMarketId,
  getFetchSelectTablesFilterStatus,
  getFetchSelectTablesFilterTablesIds,
  getFetchSelectTablesIncludes,
  getFetchSelectTablesPage,
  getFetchSelectTablesPerPage,
  getFetchSelectTablesSearch,
  getFetchTablesFilterBranchId,
  getFetchTablesFilterMarketId,
  getFetchTablesFilterStatus,
  getFetchTablesIncludes,
  getFetchTablesPage,
  getFetchTablesPerPage,
  getFetchTablesSearch,
  getIsFetchOrderTablesHitted,
  getIsFetchSelectTablesHitted,
  getIsFetchTablesHitted,
} from "./table.selector";

import {
  getTables,
  getTable,
  createTable,
  updateTable,
  deleteTable,
  multipleCreateTable,
} from "../../api/table.api";

export function* _getTables() {
  try {
    yield put(setFetchTablesLoading(true));

    const search = yield select(getFetchTablesSearch);
    const page = yield select(getFetchTablesPage);
    const per_page = yield select(getFetchTablesPerPage);
    const includes = yield select(getFetchTablesIncludes);
    const market_id = yield select(getFetchTablesFilterMarketId);
    const branch_id = yield select(getFetchTablesFilterBranchId);
    const status = yield select(getFetchTablesFilterStatus);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        status,
      },
    };

    const {
      meta: { message },
      data: { data: tables },
    } = yield call(getTables, parameters);

    yield put(setIsFetchTablesHitted(true));
    yield put(setIsTablesHasMore(tables.length > 0));

    if (page > 1) {
      yield put(appendTables(tables));
    } else {
      yield put(setTables(tables));
    }

    yield put(setFetchTablesSuccess(message));
    yield put(setFetchTablesLoading(false));
  } catch (error) {
    yield put(setFetchTablesFailed(error));
    yield put(setFetchTablesLoading(false));
  }
}
export function* _getOrderTables() {
  try {
    yield put(setFetchOrderTablesLoading(true));

    const search = yield select(getFetchOrderTablesSearch);
    const page = yield select(getFetchOrderTablesPage);
    const per_page = yield select(getFetchOrderTablesPerPage);
    const includes = yield select(getFetchOrderTablesIncludes);
    const market_id = yield select(getFetchOrderTablesFilterMarketId);
    const branch_id = yield select(getFetchOrderTablesFilterBranchId);
    const status = yield select(getFetchOrderTablesFilterStatus);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        status,
      },
    };

    const {
      meta: { message },
      data: { data: tables },
    } = yield call(getTables, parameters);

    yield put(setIsFetchOrderTablesHitted(true));
    yield put(setIsOrderTablesHasMore(tables.length > 0));

    if (page > 1) {
      yield put(appendOrderTables(tables));
    } else {
      yield put(setOrderTables(tables));
    }

    yield put(setFetchOrderTablesSuccess(message));
    yield put(setFetchOrderTablesLoading(false));
  } catch (error) {
    yield put(setFetchOrderTablesFailed(error));
    yield put(setFetchOrderTablesLoading(false));
  }
}
export function* _getSelectTables() {
  try {
    yield put(setFetchSelectTablesLoading(true));

    const search = yield select(getFetchSelectTablesSearch);
    const page = yield select(getFetchSelectTablesPage);
    const per_page = yield select(getFetchSelectTablesPerPage);
    const includes = yield select(getFetchSelectTablesIncludes);
    const market_id = yield select(getFetchSelectTablesFilterMarketId);
    const branch_id = yield select(getFetchSelectTablesFilterBranchId);
    const tables_ids = yield select(getFetchSelectTablesFilterTablesIds);
    const status = yield select(getFetchSelectTablesFilterStatus);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        tables_ids,
        status,
      },
    };

    const {
      meta: { message },
      data: { data: tables },
    } = yield call(getTables, parameters);

    yield put(setIsFetchSelectTablesHitted(true));
    yield put(setIsSelectTablesHasMore(tables.length > 0));

    if (page > 1) {
      yield put(appendSelectTables(tables));
    } else {
      yield put(setSelectTables(tables));
    }

    yield put(setFetchSelectTablesSuccess(message));
    yield put(setFetchSelectTablesLoading(false));
  } catch (error) {
    yield put(setFetchSelectTablesFailed(error));
    yield put(setFetchSelectTablesLoading(false));
  }
}
export function* _getTable({ payload: tableId }) {
  try {
    yield put(setFetchTableLoading(true));

    const {
      meta: { message },
      data: table,
    } = yield call(getTable, tableId);

    yield put(setIsFetchTableHitted(true));
    yield put(setTable(table));

    yield put(setFetchTableSuccess(message));
    yield put(setFetchTableLoading(false));
  } catch (error) {
    yield put(setFetchTableFailed(error));
    yield put(setFetchTableLoading(false));
  }
}
export function* _createTable({ payload: request }) {
  try {
    yield put(setCreateTableLoading(true));

    const {
      meta: { message },
    } = yield call(createTable, request);

    yield put(setIsCreateTableHitted(true));

    const isFetchTablesHitted = yield select(getIsFetchTablesHitted);
    const isFetchOrderTablesHitted = yield select(getIsFetchOrderTablesHitted);
    const isFetchSelectTablesHitted = yield select(
      getIsFetchSelectTablesHitted
    );

    if (isFetchTablesHitted) {
      yield put(setFetchTablesPage(1));
      yield call(_getTables);
    }
    if (isFetchOrderTablesHitted) {
      yield put(setFetchOrderTablesPage(1));
      yield call(_getOrderTables);
    }
    if (isFetchSelectTablesHitted) {
      yield put(setFetchSelectTablesPage(1));
      yield call(_getSelectTables);
    }

    yield put(setCreateTableSuccess(message));
    yield put(setCreateTableLoading(false));
  } catch (error) {
    yield put(setCreateTableFailed(error));
    yield put(setCreateTableLoading(false));
  }
}
export function* _updateTable({ payload: { tableId, request } }) {
  try {
    yield put(setUpdateTableLoading(true));

    const {
      meta: { message },
    } = yield call(updateTable, tableId, request);

    yield put(setIsUpdateTableHitted(true));

    const isFetchTablesHitted = yield select(getIsFetchTablesHitted);
    const isFetchOrderTablesHitted = yield select(getIsFetchOrderTablesHitted);
    const isFetchSelectTablesHitted = yield select(
      getIsFetchSelectTablesHitted
    );

    if (isFetchTablesHitted) {
      yield put(setFetchTablesPage(1));
      yield call(_getTables);
    }
    if (isFetchOrderTablesHitted) {
      yield put(setFetchOrderTablesPage(1));
      yield call(_getOrderTables);
    }
    if (isFetchSelectTablesHitted) {
      yield put(setFetchSelectTablesPage(1));
      yield call(_getSelectTables);
    }

    yield put(setUpdateTableSuccess(message));
    yield put(setUpdateTableLoading(false));
  } catch (error) {
    yield put(setUpdateTableFailed(error));
    yield put(setUpdateTableLoading(false));
  }
}
export function* _deleteTable({ payload: tableId }) {
  try {
    yield put(setDeleteTableLoading(true));

    const {
      meta: { message },
    } = yield call(deleteTable, tableId);

    yield put(setIsDeleteTableHitted(true));

    const isFetchTablesHitted = yield select(getIsFetchTablesHitted);
    const isFetchOrderTablesHitted = yield select(getIsFetchOrderTablesHitted);
    const isFetchSelectTablesHitted = yield select(
      getIsFetchSelectTablesHitted
    );

    if (isFetchTablesHitted) {
      yield put(setFetchTablesPage(1));
      yield call(_getTables);
    }
    if (isFetchOrderTablesHitted) {
      yield put(setFetchOrderTablesPage(1));
      yield call(_getOrderTables);
    }
    if (isFetchSelectTablesHitted) {
      yield put(setFetchSelectTablesPage(1));
      yield call(_getSelectTables);
    }

    yield put(setDeleteTableSuccess(message));
    yield put(setDeleteTableLoading(false));
  } catch (error) {
    yield put(setDeleteTableFailed(error));
    yield put(setDeleteTableLoading(false));
  }
}
export function* _multipleCreateTable({ payload: request }) {
  try {
    yield put(setMultipleCreateTableLoading(true));

    const {
      meta: { message },
    } = yield call(multipleCreateTable, request);

    yield put(setIsMultipleCreateTableHitted(true));

    const isFetchTablesHitted = yield select(getIsFetchTablesHitted);
    const isFetchOrderTablesHitted = yield select(getIsFetchOrderTablesHitted);
    const isFetchSelectTablesHitted = yield select(
      getIsFetchSelectTablesHitted
    );

    if (isFetchTablesHitted) {
      yield put(setFetchTablesPage(1));
      yield call(_getTables);
    }
    if (isFetchOrderTablesHitted) {
      yield put(setFetchOrderTablesPage(1));
      yield call(_getOrderTables);
    }
    if (isFetchSelectTablesHitted) {
      yield put(setFetchSelectTablesPage(1));
      yield call(_getSelectTables);
    }

    yield put(setMultipleCreateTableSuccess(message));
    yield put(setMultipleCreateTableLoading(false));
  } catch (error) {
    yield put(setMultipleCreateTableFailed(error));
    yield put(setMultipleCreateTableLoading(false));
  }
}

export function* onFetchTablesStart() {
  yield takeLatest(TABLE_ACTION_TYPES.FETCH_TABLES_START, _getTables);
}
export function* onFetchOrderTablesStart() {
  yield takeLatest(
    TABLE_ACTION_TYPES.FETCH_ORDER_TABLES_START,
    _getOrderTables
  );
}
export function* onFetchSelectTablesStart() {
  yield takeLatest(
    TABLE_ACTION_TYPES.FETCH_SELECT_TABLES_START,
    _getSelectTables
  );
}
export function* onFetchTableStart() {
  yield takeLatest(TABLE_ACTION_TYPES.FETCH_TABLE_START, _getTable);
}
export function* onCreateTableStart() {
  yield takeLatest(TABLE_ACTION_TYPES.CREATE_TABLE_START, _createTable);
}
export function* onUpdateTableStart() {
  yield takeLatest(TABLE_ACTION_TYPES.UPDATE_TABLE_START, _updateTable);
}
export function* onDeleteTableStart() {
  yield takeLatest(TABLE_ACTION_TYPES.DELETE_TABLE_START, _deleteTable);
}
export function* onMultipleCreateTableStart() {
  yield takeLatest(
    TABLE_ACTION_TYPES.MULTIPLE_CREATE_TABLE_START,
    _multipleCreateTable
  );
}

export function* tableSaga() {
  yield all([
    call(onFetchTablesStart),
    call(onFetchOrderTablesStart),
    call(onFetchSelectTablesStart),
    call(onFetchTableStart),
    call(onCreateTableStart),
    call(onUpdateTableStart),
    call(onDeleteTableStart),
    call(onMultipleCreateTableStart),
  ]);
}
