import { createSelector } from "reselect";

const printerTypeSelector = ({ printerType }) => printerType;

export const getPrinterTypes = createSelector([printerTypeSelector], ({ printerTypes }) => printerTypes);
export const getSelectPrinterTypes = createSelector(
  [printerTypeSelector],
  ({ selectPrinterTypes }) => selectPrinterTypes
);
export const getPrinterType = createSelector([printerTypeSelector], ({ printerType }) => printerType);

export const getIsPrinterTypesHasMore = createSelector(
  [printerTypeSelector],
  ({ isPrinterTypesHasMore }) => isPrinterTypesHasMore
);
export const getIsSelectPrinterTypesHasMore = createSelector(
  [printerTypeSelector],
  ({ isSelectPrinterTypesHasMore }) => isSelectPrinterTypesHasMore
);

export const getFetchPrinterTypesPage = createSelector(
  [printerTypeSelector],
  ({ fetchPrinterTypesPage }) => fetchPrinterTypesPage
);
export const getFetchPrinterTypesPerPage = createSelector(
  [printerTypeSelector],
  ({ fetchPrinterTypesPerPage }) => fetchPrinterTypesPerPage
);
export const getFetchPrinterTypesLoading = createSelector(
  [printerTypeSelector],
  ({ fetchPrinterTypesLoading }) => fetchPrinterTypesLoading
);
export const getFetchPrinterTypesSuccess = createSelector(
  [printerTypeSelector],
  ({ fetchPrinterTypesSuccess }) => fetchPrinterTypesSuccess
);
export const getFetchPrinterTypesFailed = createSelector(
  [printerTypeSelector],
  ({ fetchPrinterTypesFailed }) => fetchPrinterTypesFailed
);

export const getFetchSelectPrinterTypesPage = createSelector(
  [printerTypeSelector],
  ({ fetchSelectPrinterTypesPage }) => fetchSelectPrinterTypesPage
);
export const getFetchSelectPrinterTypesPerPage = createSelector(
  [printerTypeSelector],
  ({ fetchSelectPrinterTypesPerPage }) => fetchSelectPrinterTypesPerPage
);
export const getFetchSelectPrinterTypesLoading = createSelector(
  [printerTypeSelector],
  ({ fetchSelectPrinterTypesLoading }) => fetchSelectPrinterTypesLoading
);
export const getFetchSelectPrinterTypesSuccess = createSelector(
  [printerTypeSelector],
  ({ fetchSelectPrinterTypesSuccess }) => fetchSelectPrinterTypesSuccess
);
export const getFetchSelectPrinterTypesFailed = createSelector(
  [printerTypeSelector],
  ({ fetchSelectPrinterTypesFailed }) => fetchSelectPrinterTypesFailed
);

export const getFetchPrinterTypeLoading = createSelector(
  [printerTypeSelector],
  ({ fetchPrinterTypeLoading }) => fetchPrinterTypeLoading
);
export const getFetchPrinterTypeSuccess = createSelector(
  [printerTypeSelector],
  ({ fetchPrinterTypeSuccess }) => fetchPrinterTypeSuccess
);
export const getFetchPrinterTypeFailed = createSelector(
  [printerTypeSelector],
  ({ fetchPrinterTypeFailed }) => fetchPrinterTypeFailed
);

export const getCreatePrinterTypeLoading = createSelector(
  [printerTypeSelector],
  ({ createPrinterTypeLoading }) => createPrinterTypeLoading
);
export const getCreatePrinterTypeSuccess = createSelector(
  [printerTypeSelector],
  ({ createPrinterTypeSuccess }) => createPrinterTypeSuccess
);
export const getCreatePrinterTypeFailed = createSelector(
  [printerTypeSelector],
  ({ createPrinterTypeFailed }) => createPrinterTypeFailed
);

export const getUpdatePrinterTypeLoading = createSelector(
  [printerTypeSelector],
  ({ updatePrinterTypeLoading }) => updatePrinterTypeLoading
);
export const getUpdatePrinterTypeSuccess = createSelector(
  [printerTypeSelector],
  ({ updatePrinterTypeSuccess }) => updatePrinterTypeSuccess
);
export const getUpdatePrinterTypeFailed = createSelector(
  [printerTypeSelector],
  ({ updatePrinterTypeFailed }) => updatePrinterTypeFailed
);

export const getDeletePrinterTypeLoading = createSelector(
  [printerTypeSelector],
  ({ deletePrinterTypeLoading }) => deletePrinterTypeLoading
);
export const getDeletePrinterTypeSuccess = createSelector(
  [printerTypeSelector],
  ({ deletePrinterTypeSuccess }) => deletePrinterTypeSuccess
);
export const getDeletePrinterTypeFailed = createSelector(
  [printerTypeSelector],
  ({ deletePrinterTypeFailed }) => deletePrinterTypeFailed
);

export const getIsFetchPrinterTypesHitted = createSelector(
  [printerTypeSelector],
  ({ isFetchPrinterTypesHitted }) => isFetchPrinterTypesHitted
);
export const getIsFetchSelectPrinterTypesHitted = createSelector(
  [printerTypeSelector],
  ({ isFetchSelectPrinterTypesHitted }) => isFetchSelectPrinterTypesHitted
);
export const getIsFetchPrinterTypeHitted = createSelector(
  [printerTypeSelector],
  ({ isFetchPrinterTypeHitted }) => isFetchPrinterTypeHitted
);
export const getIsCreatePrinterTypeHitted = createSelector(
  [printerTypeSelector],
  ({ isCreatePrinterTypeHitted }) => isCreatePrinterTypeHitted
);
export const getIsUpdatePrinterTypeHitted = createSelector(
  [printerTypeSelector],
  ({ isUpdatePrinterTypeHitted }) => isUpdatePrinterTypeHitted
);
export const getIsDeletePrinterTypeHitted = createSelector(
  [printerTypeSelector],
  ({ isDeletePrinterTypeHitted }) => isDeletePrinterTypeHitted
);
