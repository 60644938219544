import { takeLatest, put, all, call, select } from "redux-saga/effects";

import SUBSCRIBER_ACTION_TYPES from "./subscriber.type";

import {
  appendSelectSubscribers,
  appendSubscribers,
  setCreateSubscriberFailed,
  setCreateSubscriberLoading,
  setCreateSubscriberSuccess,
  setDeleteSubscriberFailed,
  setDeleteSubscriberLoading,
  setDeleteSubscriberSuccess,
  setFetchSelectSubscribersFailed,
  setFetchSelectSubscribersLoading,
  setFetchSelectSubscribersPage,
  setFetchSelectSubscribersSuccess,
  setFetchSubscriberFailed,
  setFetchSubscriberLoading,
  setFetchSubscriberReportFailed,
  setFetchSubscriberReportLoading,
  setFetchSubscriberReportSuccess,
  setFetchSubscribersFailed,
  setFetchSubscribersLoading,
  setFetchSubscribersPage,
  setFetchSubscribersSuccess,
  setFetchSubscriberSuccess,
  setIsCreateSubscriberHitted,
  setIsDeleteSubscriberHitted,
  setIsFetchSelectSubscribersHitted,
  setIsFetchSubscriberHitted,
  setIsFetchSubscriberReportHitted,
  setIsFetchSubscribersHitted,
  setIsSelectSubscribersHasMore,
  setIsSubscribersHasMore,
  setIsUpdateSubscriberHitted,
  setSelectSubscribers,
  setSubscriber,
  setSubscriberReport,
  setSubscribers,
  setUpdateSubscriberFailed,
  setUpdateSubscriberLoading,
  setUpdateSubscriberSuccess,
} from "./subscriber.action";
import {
  getFetchSelectSubscribersFilterBranchId,
  getFetchSelectSubscribersFilterMarketId,
  getFetchSelectSubscribersFilterPermissionId,
  getFetchSelectSubscribersIncludes,
  getFetchSelectSubscribersPage,
  getFetchSelectSubscribersPerPage,
  getFetchSelectSubscribersSearch,
  getFetchSubscriberReportEndAt,
  getFetchSubscriberReportStartAt,
  getFetchSubscribersFilterBranchId,
  getFetchSubscribersFilterMarketId,
  getFetchSubscribersFilterPermissionId,
  getFetchSubscribersIncludes,
  getFetchSubscribersPage,
  getFetchSubscribersPerPage,
  getFetchSubscribersSearch,
  getIsFetchSelectSubscribersHitted,
  getIsFetchSubscribersHitted,
} from "./subscriber.selector";

import {
  getSubscribers,
  getSubscriber,
  createSubscriber,
  updateSubscriber,
  deleteSubscriber,
  getSubscriberReport,
} from "../../api/subscriber.api";

export function* _getSubscribers() {
  try {
    yield put(setFetchSubscribersLoading(true));

    const search = yield select(getFetchSubscribersSearch);
    const page = yield select(getFetchSubscribersPage);
    const per_page = yield select(getFetchSubscribersPerPage);
    const includes = yield select(getFetchSubscribersIncludes);
    const market_id = yield select(getFetchSubscribersFilterMarketId);
    const branch_id = yield select(getFetchSubscribersFilterBranchId);
    const permission_id = yield select(getFetchSubscribersFilterPermissionId);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        permission_id,
      },
    };

    const {
      meta: { message },
      data: { data: subscribers },
    } = yield call(getSubscribers, parameters);

    yield put(setIsFetchSubscribersHitted(true));
    yield put(setIsSubscribersHasMore(subscribers.length > 0));

    if (page > 1) {
      yield put(appendSubscribers(subscribers));
    } else {
      yield put(setSubscribers(subscribers));
    }

    yield put(setFetchSubscribersSuccess(message));
    yield put(setFetchSubscribersLoading(false));
  } catch (error) {
    yield put(setFetchSubscribersFailed(error));
    yield put(setFetchSubscribersLoading(false));
  }
}

export function* _getSelectSubscribers() {
  try {
    yield put(setFetchSelectSubscribersLoading(true));

    const search = yield select(getFetchSelectSubscribersSearch);
    const page = yield select(getFetchSelectSubscribersPage);
    const per_page = yield select(getFetchSelectSubscribersPerPage);
    const includes = yield select(getFetchSelectSubscribersIncludes);
    const market_id = yield select(getFetchSelectSubscribersFilterMarketId);
    const branch_id = yield select(getFetchSelectSubscribersFilterBranchId);
    const permission_id = yield select(getFetchSelectSubscribersFilterPermissionId);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        permission_id,
      },
    };

    const {
      meta: { message },
      data: { data: subscribers },
    } = yield call(getSubscribers, parameters);

    yield put(setIsFetchSelectSubscribersHitted(true));
    yield put(setIsSelectSubscribersHasMore(subscribers.length > 0));

    if (page > 1) {
      yield put(appendSelectSubscribers(subscribers));
    } else {
      yield put(setSelectSubscribers(subscribers));
    }

    yield put(setFetchSelectSubscribersSuccess(message));
    yield put(setFetchSelectSubscribersLoading(false));
  } catch (error) {
    yield put(setFetchSelectSubscribersFailed(error));
    yield put(setFetchSelectSubscribersLoading(false));
  }
}

export function* _getSubscriber({ payload: id }) {
  try {
    yield put(setFetchSubscriberLoading(true));

    const {
      meta: { message },
      data: subscriber,
    } = yield call(getSubscriber, id);

    yield put(setIsFetchSubscriberHitted(true));
    yield put(setSubscriber(subscriber));

    yield put(setFetchSubscriberSuccess(message));
    yield put(setFetchSubscriberLoading(false));
  } catch (error) {
    yield put(setFetchSubscriberFailed(error));
    yield put(setFetchSubscriberLoading(false));
  }
}

export function* _getSubscriberReport({ payload: id }) {
  try {
    yield put(setFetchSubscriberReportLoading(true));

    const start_at = yield select(getFetchSubscriberReportStartAt);
    const end_at = yield select(getFetchSubscriberReportEndAt);

    const parameters = { filter: { start_at, end_at } };

    const {
      meta: { message },
      data: subscriberReport,
    } = yield call(getSubscriberReport, id, parameters);

    yield put(setIsFetchSubscriberReportHitted(true));
    yield put(setSubscriberReport(subscriberReport));

    yield put(setFetchSubscriberReportSuccess(message));
    yield put(setFetchSubscriberReportLoading(false));
  } catch (error) {
    yield put(setFetchSubscriberReportFailed(error));
    yield put(setFetchSubscriberReportLoading(false));
  }
}

export function* _createSubscriber({ payload: request }) {
  try {
    yield put(setCreateSubscriberLoading(true));

    const {
      meta: { message },
    } = yield call(createSubscriber, request);

    yield put(setIsCreateSubscriberHitted(true));

    const isFetchSubscribersHitted = yield select(getIsFetchSubscribersHitted);
    const isFetchSelectSubscribersHitted = yield select(getIsFetchSelectSubscribersHitted);

    if (isFetchSubscribersHitted) {
      yield put(setFetchSubscribersPage(1));
      yield call(_getSubscribers);
    }
    if (isFetchSelectSubscribersHitted) {
      yield put(setFetchSelectSubscribersPage(1));
      yield call(_getSelectSubscribers);
    }

    yield put(setCreateSubscriberSuccess(message));
    yield put(setCreateSubscriberLoading(false));
  } catch (error) {
    yield put(setCreateSubscriberFailed(error));
    yield put(setCreateSubscriberLoading(false));
  }
}

export function* _updateSubscriber({ payload: { id, request } }) {
  try {
    yield put(setUpdateSubscriberLoading(true));

    const {
      meta: { message },
    } = yield call(updateSubscriber, id, request);

    yield put(setIsUpdateSubscriberHitted(true));

    const isFetchSubscribersHitted = yield select(getIsFetchSubscribersHitted);
    const isFetchSelectSubscribersHitted = yield select(getIsFetchSelectSubscribersHitted);

    if (isFetchSubscribersHitted) {
      yield put(setFetchSubscribersPage(1));
      yield call(_getSubscribers);
    }
    if (isFetchSelectSubscribersHitted) {
      yield put(setFetchSelectSubscribersPage(1));
      yield call(_getSelectSubscribers);
    }

    yield put(setUpdateSubscriberSuccess(message));
    yield put(setUpdateSubscriberLoading(false));
  } catch (error) {
    yield put(setUpdateSubscriberFailed(error));
    yield put(setUpdateSubscriberLoading(false));
  }
}

export function* _deleteSubscriber({ payload: id }) {
  try {
    yield put(setDeleteSubscriberLoading(true));

    const {
      meta: { message },
    } = yield call(deleteSubscriber, id);

    yield put(setIsDeleteSubscriberHitted(true));

    const isFetchSubscribersHitted = yield select(getIsFetchSubscribersHitted);
    const isFetchSelectSubscribersHitted = yield select(getIsFetchSelectSubscribersHitted);

    if (isFetchSubscribersHitted) {
      yield put(setFetchSubscribersPage(1));
      yield call(_getSubscribers);
    }
    if (isFetchSelectSubscribersHitted) {
      yield put(setFetchSelectSubscribersPage(1));
      yield call(_getSelectSubscribers);
    }

    yield put(setDeleteSubscriberSuccess(message));
    yield put(setDeleteSubscriberLoading(false));
  } catch (error) {
    yield put(setDeleteSubscriberFailed(error));
    yield put(setDeleteSubscriberLoading(false));
  }
}

export function* onFetchSubscribersStart() {
  yield takeLatest(SUBSCRIBER_ACTION_TYPES.FETCH_SUBSCRIBERS_START, _getSubscribers);
}

export function* onFetchSelectSubscribersStart() {
  yield takeLatest(SUBSCRIBER_ACTION_TYPES.FETCH_SELECT_SUBSCRIBERS_START, _getSelectSubscribers);
}

export function* onFetchSubscriberStart() {
  yield takeLatest(SUBSCRIBER_ACTION_TYPES.FETCH_SUBSCRIBER_START, _getSubscriber);
}

export function* onFetchSubscriberReportStart() {
  yield takeLatest(SUBSCRIBER_ACTION_TYPES.FETCH_SUBSCRIBER_REPORT_START, _getSubscriberReport);
}

export function* onCreateSubscriberStart() {
  yield takeLatest(SUBSCRIBER_ACTION_TYPES.CREATE_SUBSCRIBER_START, _createSubscriber);
}

export function* onUpdateSubscriberStart() {
  yield takeLatest(SUBSCRIBER_ACTION_TYPES.UPDATE_SUBSCRIBER_START, _updateSubscriber);
}

export function* onDeleteSubscriberStart() {
  yield takeLatest(SUBSCRIBER_ACTION_TYPES.DELETE_SUBSCRIBER_START, _deleteSubscriber);
}

export function* subscriberSaga() {
  yield all([
    call(onFetchSubscribersStart),
    call(onFetchSelectSubscribersStart),
    call(onFetchSubscriberStart),
    call(onFetchSubscriberReportStart),
    call(onCreateSubscriberStart),
    call(onUpdateSubscriberStart),
    call(onDeleteSubscriberStart),
  ]);
}
