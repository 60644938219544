import { takeLatest, put, all, call, select } from "redux-saga/effects";

import BRANCH_ACTION_TYPES from "./branch.type";

import {
  appendBranches,
  appendSubscriptionBranches,
  setBranch,
  setBranches,
  setCreateBranchFailed,
  setCreateBranchLoading,
  setCreateBranchSuccess,
  setDeleteBranchFailed,
  setDeleteBranchLoading,
  setDeleteBranchSuccess,
  setFetchBranchesFailed,
  setFetchBranchesLoading,
  setFetchBranchesPage,
  setFetchBranchesSuccess,
  setFetchBranchFailed,
  setFetchBranchLoading,
  setFetchBranchSuccess,
  setFetchSubscriptionBranchesFailed,
  setFetchSubscriptionBranchesLoading,
  setFetchSubscriptionBranchesPage,
  setFetchSubscriptionBranchesSuccess,
  setFetchSubscriptionBranchFailed,
  setFetchSubscriptionBranchLoading,
  setFetchSubscriptionBranchSuccess,
  setIsBranchesHasMore,
  setIsCreateBranchHitted,
  setIsDeleteBranchHitted,
  setIsFetchBranchesHitted,
  setIsFetchBranchHitted,
  setIsFetchSubscriptionBranchesHitted,
  setIsFetchSubscriptionBranchHitted,
  setIsSubscriptionBranchesHasMore,
  setIsUpdateBranchHitted,
  setSubscriptionBranch,
  setSubscriptionBranches,
  setUpdateBranchFailed,
  setUpdateBranchLoading,
  setUpdateBranchSuccess,
} from "./branch.action";
import {
  getFetchBranchesFilterIsActive,
  getFetchBranchesFilterMarketId,
  getFetchBranchesIncludes,
  getFetchBranchesPage,
  getFetchBranchesPerPage,
  getFetchBranchesSearch,
  getFetchSubscriptionBranchesFilterIsActive,
  getFetchSubscriptionBranchesFilterMarketId,
  getFetchSubscriptionBranchesIncludes,
  getFetchSubscriptionBranchesPage,
  getFetchSubscriptionBranchesPerPage,
  getIsFetchBranchesHitted,
  getIsFetchSubscriptionBranchesHitted,
} from "./branch.selector";
import { getCurrentBranchId } from "../global/global.selector";
import { setCurrentBranch } from "../global/global.action";

import {
  getBranches,
  getBranch,
  createBranch,
  updateBranch,
  deleteBranch,
} from "../../api/branch.api";
import { getLocation } from "../../api/mapbox.api";

export function* _getBranches() {
  try {
    yield put(setFetchBranchesLoading(true));

    const search = yield select(getFetchBranchesSearch);
    const page = yield select(getFetchBranchesPage);
    const per_page = yield select(getFetchBranchesPerPage);
    const includes = yield select(getFetchBranchesIncludes);
    const market_id = yield select(getFetchBranchesFilterMarketId);
    const is_active = yield select(getFetchBranchesFilterIsActive);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: { market_id, is_active },
    };

    const {
      meta: { message },
      data: { data: branches },
    } = yield call(getBranches, parameters);

    yield put(setIsFetchBranchesHitted(true));
    yield put(setIsBranchesHasMore(branches.length > 0));

    if (page > 1) {
      yield put(appendBranches(branches));
    } else {
      yield put(setBranches(branches));
    }

    yield put(setFetchBranchesSuccess(message));
    yield put(setFetchBranchesLoading(false));
  } catch (error) {
    yield put(setFetchBranchesFailed(error));
    yield put(setFetchBranchesLoading(false));
  }
}
export function* _getBranch({ payload: branchId }) {
  try {
    yield put(setFetchBranchLoading(true));

    const {
      meta: { message },
      data: branch,
    } = yield call(getBranch, branchId);

    yield put(setIsFetchBranchHitted(true));
    yield put(setBranch(branch));

    yield put(setFetchBranchSuccess(message));
    yield put(setFetchBranchLoading(false));
  } catch (error) {
    yield put(setFetchBranchFailed(error));
    yield put(setFetchBranchLoading(false));
  }
}
export function* _getSubscriptionBranches() {
  try {
    yield put(setFetchSubscriptionBranchesLoading(true));

    const page = yield select(getFetchSubscriptionBranchesPage);
    const per_page = yield select(getFetchSubscriptionBranchesPerPage);
    const includes = yield select(getFetchSubscriptionBranchesIncludes);
    const market_id = yield select(getFetchSubscriptionBranchesFilterMarketId);
    const is_active = yield select(getFetchSubscriptionBranchesFilterIsActive);

    const parameters = {
      page,
      per_page,
      includes,
      filter: { market_id, is_active },
    };

    const {
      meta: { message },
      data: { data: branches },
    } = yield call(getBranches, parameters);

    yield put(setIsFetchSubscriptionBranchesHitted(true));
    yield put(setIsSubscriptionBranchesHasMore(branches.length > 0));

    if (page > 1) {
      yield put(appendSubscriptionBranches(branches));
    } else {
      yield put(setSubscriptionBranches(branches));
    }

    yield put(setFetchSubscriptionBranchesSuccess(message));
    yield put(setFetchSubscriptionBranchesLoading(false));
  } catch (error) {
    yield put(setFetchSubscriptionBranchesFailed(error));
    yield put(setFetchSubscriptionBranchesLoading(false));
  }
}
export function* _getSubscriptionBranch({ payload: branchId }) {
  try {
    yield put(setFetchSubscriptionBranchLoading(true));

    const {
      meta: { message },
      data: branch,
    } = yield call(getBranch, branchId);

    yield put(setIsFetchSubscriptionBranchHitted(true));
    yield put(setSubscriptionBranch(branch));

    yield put(setFetchSubscriptionBranchSuccess(message));
    yield put(setFetchSubscriptionBranchLoading(false));
  } catch (error) {
    yield put(setFetchSubscriptionBranchFailed(error));
    yield put(setFetchSubscriptionBranchLoading(false));
  }
}
export function* _createBranch({ payload: request }) {
  try {
    yield put(setCreateBranchLoading(true));

    const { longitude, latitude } = request;

    if (![longitude, latitude].includes(undefined)) {
      const { features } = yield call(getLocation, longitude, latitude);
      const [feature] = features ?? [];
      const { place_name } = feature ?? {};

      request.address = place_name;
    }

    const {
      meta: { message },
      data: branch,
    } = yield call(createBranch, request);

    yield put(setIsCreateBranchHitted(true));
    yield put(setBranch(branch));

    const isFetchBranchesHitted = yield select(getIsFetchBranchesHitted);
    const isFetchSubscriptionBranchesHitted = yield select(
      getIsFetchSubscriptionBranchesHitted
    );

    if (isFetchBranchesHitted) {
      yield put(setFetchBranchesPage(1));
      yield call(_getBranches);
    }
    if (isFetchSubscriptionBranchesHitted) {
      yield put(setFetchSubscriptionBranchesPage(1));
      yield call(_getSubscriptionBranches);
    }

    yield put(setCreateBranchSuccess(message));
    yield put(setCreateBranchLoading(false));
  } catch (error) {
    yield put(setCreateBranchFailed(error));
    yield put(setCreateBranchLoading(false));
  }
}
export function* _updateBranch({ payload: { branchId, request } }) {
  try {
    yield put(setUpdateBranchLoading(true));

    const { longitude, latitude } = request;

    if (![longitude, latitude].includes(undefined)) {
      const { features } = yield call(getLocation, longitude, latitude);
      const [feature] = features ?? [];
      const { place_name } = feature ?? {};

      request.address = place_name;
    }

    const {
      meta: { message },
      data: branch,
    } = yield call(updateBranch, branchId, request);

    yield put(setIsUpdateBranchHitted(true));
    yield put(setBranch(branch));

    const isFetchBranchesHitted = yield select(getIsFetchBranchesHitted);
    const isFetchSubscriptionBranchesHitted = yield select(
      getIsFetchSubscriptionBranchesHitted
    );

    if (isFetchBranchesHitted) {
      yield put(setFetchBranchesPage(1));
      yield call(_getBranches);
    }
    if (isFetchSubscriptionBranchesHitted) {
      yield put(setFetchSubscriptionBranchesPage(1));
      yield call(_getSubscriptionBranches);
    }

    const currentBranchId = yield select(getCurrentBranchId);
    if (branchId === currentBranchId) yield put(setCurrentBranch(branch));

    yield put(setUpdateBranchSuccess(message));
    yield put(setUpdateBranchLoading(false));
  } catch (error) {
    yield put(setUpdateBranchFailed(error));
    yield put(setUpdateBranchLoading(false));
  }
}
export function* _deleteBranch({ payload: branchId }) {
  try {
    yield put(setDeleteBranchLoading(true));

    const {
      meta: { message },
    } = yield call(deleteBranch, branchId);

    yield put(setIsDeleteBranchHitted(true));

    const isFetchBranchesHitted = yield select(getIsFetchBranchesHitted);
    const isFetchSubscriptionBranchesHitted = yield select(
      getIsFetchSubscriptionBranchesHitted
    );

    if (isFetchBranchesHitted) {
      yield put(setFetchBranchesPage(1));
      yield call(_getBranches);
    }
    if (isFetchSubscriptionBranchesHitted) {
      yield put(setFetchSubscriptionBranchesPage(1));
      yield call(_getSubscriptionBranches);
    }

    yield put(setDeleteBranchSuccess(message));
    yield put(setDeleteBranchLoading(false));
  } catch (error) {
    yield put(setDeleteBranchFailed(error));
    yield put(setDeleteBranchLoading(false));
  }
}

export function* onFetchBranchesStart() {
  yield takeLatest(BRANCH_ACTION_TYPES.FETCH_BRANCHES_START, _getBranches);
}
export function* onFetchBranchStart() {
  yield takeLatest(BRANCH_ACTION_TYPES.FETCH_BRANCH_START, _getBranch);
}
export function* onFetchSubscriptionBranchesStart() {
  yield takeLatest(
    BRANCH_ACTION_TYPES.FETCH_SUBSCRIPTION_BRANCHES_START,
    _getSubscriptionBranches
  );
}
export function* onFetchSubscriptionBranchStart() {
  yield takeLatest(
    BRANCH_ACTION_TYPES.FETCH_SUBSCRIPTION_BRANCH_START,
    _getSubscriptionBranch
  );
}
export function* onCreateBranchStart() {
  yield takeLatest(BRANCH_ACTION_TYPES.CREATE_BRANCH_START, _createBranch);
}
export function* onUpdateBranchStart() {
  yield takeLatest(BRANCH_ACTION_TYPES.UPDATE_BRANCH_START, _updateBranch);
}
export function* onDeleteBranchStart() {
  yield takeLatest(BRANCH_ACTION_TYPES.DELETE_BRANCH_START, _deleteBranch);
}

export function* branchSaga() {
  yield all([
    call(onFetchBranchesStart),
    call(onFetchBranchStart),
    call(onFetchSubscriptionBranchesStart),
    call(onFetchSubscriptionBranchStart),
    call(onCreateBranchStart),
    call(onUpdateBranchStart),
    call(onDeleteBranchStart),
  ]);
}
