const KITCHEN_GROUP_ACTION_TYPES = {
  RESET_KITCHEN_GROUP_REDUCER: "kitchen-group/RESET_KITCHEN_GROUP_REDUCER",

  SET_KITCHEN_GROUPS: "kitchen-group/SET_KITCHEN_GROUPS",
  SET_SELECT_KITCHEN_GROUPS: "kitchen-group/SET_SELECT_KITCHEN_GROUPS",
  SET_KITCHEN_GROUP: "kitchen-group/SET_KITCHEN_GROUP",

  SET_IS_KITCHEN_GROUPS_HAS_MORE: "kitchen-group/SET_IS_KITCHEN_GROUPS_HAS_MORE",
  SET_IS_SELECT_KITCHEN_GROUPS_HAS_MORE: "kitchen-group/SET_IS_SELECT_KITCHEN_GROUPS_HAS_MORE",

  SET_FETCH_KITCHEN_GROUPS_SEARCH: "kitchen-group/SET_FETCH_KITCHEN_GROUPS_SEARCH",
  SET_FETCH_KITCHEN_GROUPS_PAGE: "kitchen-group/SET_FETCH_KITCHEN_GROUPS_PAGE",
  SET_FETCH_KITCHEN_GROUPS_PER_PAGE: "kitchen-group/SET_FETCH_KITCHEN_GROUPS_PER_PAGE",
  SET_FETCH_KITCHEN_GROUPS_INCLUDES: "kitchen-group/SET_FETCH_KITCHEN_GROUPS_INCLUDES",
  SET_FETCH_KITCHEN_GROUPS_FILTER_MARKET_ID: "kitchen-group/SET_FETCH_KITCHEN_GROUPS_FILTER_MARKET_ID",
  SET_FETCH_KITCHEN_GROUPS_FILTER_BRANCH_ID: "kitchen-group/SET_FETCH_KITCHEN_GROUPS_FILTER_BRANCH_ID",
  SET_FETCH_KITCHEN_GROUPS_LOADING: "kitchen-group/SET_FETCH_KITCHEN_GROUPS_LOADING",
  SET_FETCH_KITCHEN_GROUPS_SUCCESS: "kitchen-group/SET_FETCH_KITCHEN_GROUPS_SUCCESS",
  SET_FETCH_KITCHEN_GROUPS_FAILED: "kitchen-group/SET_FETCH_KITCHEN_GROUPS_FAILED",

  SET_FETCH_SELECT_KITCHEN_GROUPS_PAGE: "kitchen-group/SET_FETCH_SELECT_KITCHEN_GROUPS_PAGE",
  SET_FETCH_SELECT_KITCHEN_GROUPS_PER_PAGE: "kitchen-group/SET_FETCH_SELECT_KITCHEN_GROUPS_PER_PAGE",
  SET_FETCH_SELECT_KITCHEN_GROUPS_INCLUDES: "kitchen-group/SET_FETCH_SELECT_KITCHEN_GROUPS_INCLUDES",
  SET_FETCH_SELECT_KITCHEN_GROUPS_FILTER_MARKET_ID: "kitchen-group/SET_FETCH_SELECT_KITCHEN_GROUPS_FILTER_MARKET_ID",
  SET_FETCH_SELECT_KITCHEN_GROUPS_FILTER_BRANCH_ID: "kitchen-group/SET_FETCH_SELECT_KITCHEN_GROUPS_FILTER_BRANCH_ID",
  SET_FETCH_SELECT_KITCHEN_GROUPS_LOADING: "kitchen-group/SET_FETCH_SELECT_KITCHEN_GROUPS_LOADING",
  SET_FETCH_SELECT_KITCHEN_GROUPS_SUCCESS: "kitchen-group/SET_FETCH_SELECT_KITCHEN_GROUPS_SUCCESS",
  SET_FETCH_SELECT_KITCHEN_GROUPS_FAILED: "kitchen-group/SET_FETCH_SELECT_KITCHEN_GROUPS_FAILED",

  SET_FETCH_KITCHEN_GROUP_LOADING: "kitchen-group/SET_FETCH_KITCHEN_GROUP_LOADING",
  SET_FETCH_KITCHEN_GROUP_SUCCESS: "kitchen-group/SET_FETCH_KITCHEN_GROUP_SUCCESS",
  SET_FETCH_KITCHEN_GROUP_FAILED: "kitchen-group/SET_FETCH_KITCHEN_GROUP_FAILED",

  SET_CREATE_KITCHEN_GROUP_LOADING: "kitchen-group/SET_CREATE_KITCHEN_GROUP_LOADING",
  SET_CREATE_KITCHEN_GROUP_SUCCESS: "kitchen-group/SET_CREATE_KITCHEN_GROUP_SUCCESS",
  SET_CREATE_KITCHEN_GROUP_FAILED: "kitchen-group/SET_CREATE_KITCHEN_GROUP_FAILED",

  SET_UPDATE_KITCHEN_GROUP_LOADING: "kitchen-group/SET_UPDATE_KITCHEN_GROUP_LOADING",
  SET_UPDATE_KITCHEN_GROUP_SUCCESS: "kitchen-group/SET_UPDATE_KITCHEN_GROUP_SUCCESS",
  SET_UPDATE_KITCHEN_GROUP_FAILED: "kitchen-group/SET_UPDATE_KITCHEN_GROUP_FAILED",

  SET_DELETE_KITCHEN_GROUP_LOADING: "kitchen-group/SET_DELETE_KITCHEN_GROUP_LOADING",
  SET_DELETE_KITCHEN_GROUP_SUCCESS: "kitchen-group/SET_DELETE_KITCHEN_GROUP_SUCCESS",
  SET_DELETE_KITCHEN_GROUP_FAILED: "kitchen-group/SET_DELETE_KITCHEN_GROUP_FAILED",

  APPEND_KITCHEN_GROUPS: "kitchen-group/APPEND_KITCHEN_GROUPS",
  APPEND_SELECT_KITCHEN_GROUPS: "kitchen-group/APPEND_SELECT_KITCHEN_GROUPS",

  SET_IS_FETCH_KITCHEN_GROUPS_HITTED: "kitchen-group/SET_IS_FETCH_KITCHEN_GROUPS_HITTED",
  SET_IS_FETCH_SELECT_KITCHEN_GROUPS_HITTED: "kitchen-group/SET_IS_FETCH_SELECT_KITCHEN_GROUPS_HITTED",
  SET_IS_FETCH_KITCHEN_GROUP_HITTED: "kitchen-group/SET_IS_FETCH_KITCHEN_GROUP_HITTED",
  SET_IS_CREATE_KITCHEN_GROUP_HITTED: "kitchen-group/SET_IS_CREATE_KITCHEN_GROUP_HITTED",
  SET_IS_UPDATE_KITCHEN_GROUP_HITTED: "kitchen-group/SET_IS_UPDATE_KITCHEN_GROUP_HITTED",
  SET_IS_DELETE_KITCHEN_GROUP_HITTED: "kitchen-group/SET_IS_DELETE_KITCHEN_GROUP_HITTED",

  FETCH_KITCHEN_GROUPS_START: "kitchen-group/FETCH_KITCHEN_GROUPS_START",
  FETCH_SELECT_KITCHEN_GROUPS_START: "kitchen-group/FETCH_SELECT_KITCHEN_GROUPS_START",
  FETCH_KITCHEN_GROUP_START: "kitchen-group/FETCH_KITCHEN_GROUP_START",
  CREATE_KITCHEN_GROUP_START: "kitchen-group/CREATE_KITCHEN_GROUP_START",
  UPDATE_KITCHEN_GROUP_START: "kitchen-group/UPDATE_KITCHEN_GROUP_START",
  DELETE_KITCHEN_GROUP_START: "kitchen-group/DELETE_KITCHEN_GROUP_START",
};

export default KITCHEN_GROUP_ACTION_TYPES;
