import USER_SUBSCRIBER_ACTION_TYPES from "./user-subscriber.type";
import { createAction } from "../../utils/store.utils";

export const setUserSubscribers = (userSubscribers) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_USER_SUBSCRIBERS,
    userSubscribers
  );
export const setUserSubscriber = (userSubscriber) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_USER_SUBSCRIBER,
    userSubscriber
  );

export const setIsUserSubscribersHasMore = (isUserSubscribersHasMore) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_IS_USER_SUBSCRIBERS_HAS_MORE,
    isUserSubscribersHasMore
  );

export const setFetchUserSubscribersSearch = (fetchUserSubscribersSearch) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_SEARCH,
    fetchUserSubscribersSearch
  );
export const setFetchUserSubscribersSort = (fetchUserSubscribersSort) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_SORT,
    fetchUserSubscribersSort
  );
export const setFetchUserSubscribersKeyBy = (fetchUserSubscribersKeyBy) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_KEY_BY,
    fetchUserSubscribersKeyBy
  );
export const setFetchUserSubscribersPage = (fetchUserSubscribersPage) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_PAGE,
    fetchUserSubscribersPage
  );
export const setFetchUserSubscribersPerPage = (fetchUserSubscribersPerPage) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_PER_PAGE,
    fetchUserSubscribersPerPage
  );
export const setFetchUserSubscribersIncludes = (fetchUserSubscribersIncludes) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_INCLUDES,
    fetchUserSubscribersIncludes
  );
export const setFetchUserSubscribersFilterMarketId = (
  fetchUserSubscribersFilterMarketId
) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_FILTER_MARKET_ID,
    fetchUserSubscribersFilterMarketId
  );
export const setFetchUserSubscribersFilterBranchId = (
  fetchUserSubscribersFilterBranchId
) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_FILTER_BRANCH_ID,
    fetchUserSubscribersFilterBranchId
  );
export const setFetchUserSubscribersFilterPermissionId = (
  fetchUserSubscribersFilterPermissionId
) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_FILTER_PERMISSION_ID,
    fetchUserSubscribersFilterPermissionId
  );
export const setFetchUserSubscribersLoading = (fetchUserSubscribersLoading) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_LOADING,
    fetchUserSubscribersLoading
  );
export const setFetchUserSubscribersSuccess = (fetchUserSubscribersSuccess) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_SUCCESS,
    fetchUserSubscribersSuccess
  );
export const setFetchUserSubscribersFailed = (fetchUserSubscribersFailed) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_FAILED,
    fetchUserSubscribersFailed
  );

export const setFetchUserSubscriberLoading = (fetchUserSubscriberLoading) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBER_LOADING,
    fetchUserSubscriberLoading
  );
export const setFetchUserSubscriberSuccess = (fetchUserSubscriberSuccess) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBER_SUCCESS,
    fetchUserSubscriberSuccess
  );
export const setFetchUserSubscriberFailed = (fetchUserSubscriberFailed) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBER_FAILED,
    fetchUserSubscriberFailed
  );

export const setCreateUserSubscriberLoading = (createUserSubscriberLoading) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_CREATE_USER_SUBSCRIBER_LOADING,
    createUserSubscriberLoading
  );
export const setCreateUserSubscriberSuccess = (createUserSubscriberSuccess) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_CREATE_USER_SUBSCRIBER_SUCCESS,
    createUserSubscriberSuccess
  );
export const setCreateUserSubscriberFailed = (createUserSubscriberFailed) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_CREATE_USER_SUBSCRIBER_FAILED,
    createUserSubscriberFailed
  );

export const setUpdateUserSubscriberLoading = (updateUserSubscriberLoading) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_UPDATE_USER_SUBSCRIBER_LOADING,
    updateUserSubscriberLoading
  );
export const setUpdateUserSubscriberSuccess = (updateUserSubscriberSuccess) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_UPDATE_USER_SUBSCRIBER_SUCCESS,
    updateUserSubscriberSuccess
  );
export const setUpdateUserSubscriberFailed = (updateUserSubscriberFailed) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_UPDATE_USER_SUBSCRIBER_FAILED,
    updateUserSubscriberFailed
  );

export const setDeleteUserSubscriberLoading = (deleteUserSubscriberLoading) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_DELETE_USER_SUBSCRIBER_LOADING,
    deleteUserSubscriberLoading
  );
export const setDeleteUserSubscriberSuccess = (deleteUserSubscriberSuccess) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_DELETE_USER_SUBSCRIBER_SUCCESS,
    deleteUserSubscriberSuccess
  );
export const setDeleteUserSubscriberFailed = (deleteUserSubscriberFailed) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_DELETE_USER_SUBSCRIBER_FAILED,
    deleteUserSubscriberFailed
  );

export const appendUserSubscribers = (userSubscribers) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.APPEND_USER_SUBSCRIBERS,
    userSubscribers
  );

export const fetchUserSubscribersStart = () =>
  createAction(USER_SUBSCRIBER_ACTION_TYPES.FETCH_USER_SUBSCRIBERS_START);
export const fetchUserSubscriberStart = (subscriberId) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.FETCH_USER_SUBSCRIBER_START,
    subscriberId
  );
export const createUserSubscriberStart = (request) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.CREATE_USER_SUBSCRIBER_START,
    request
  );
export const updateUserSubscriberStart = (subscriberId, request) =>
  createAction(USER_SUBSCRIBER_ACTION_TYPES.UPDATE_USER_SUBSCRIBER_START, {
    subscriberId,
    request,
  });
export const deleteUserSubscriberStart = (subscriberId) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.DELETE_USER_SUBSCRIBER_START,
    subscriberId
  );

export const setIsFetchUserSubscribersHitted = (isFetchUserSubscribersHitted) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_USER_SUBSCRIBERS_HITTED,
    isFetchUserSubscribersHitted
  );
export const setIsFetchUserSubscriberHitted = (isFetchUserSubscriberHitted) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_USER_SUBSCRIBER_HITTED,
    isFetchUserSubscriberHitted
  );
export const setIsCreateUserSubscriberHitted = (isCreateUserSubscriberHitted) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_IS_CREATE_USER_SUBSCRIBER_HITTED,
    isCreateUserSubscriberHitted
  );
export const setIsUpdateUserSubscriberHitted = (isUpdateUserSubscriberHitted) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_IS_UPDATE_USER_SUBSCRIBER_HITTED,
    isUpdateUserSubscriberHitted
  );
export const setIsDeleteUserSubscriberHitted = (isDeleteUserSubscriberHitted) =>
  createAction(
    USER_SUBSCRIBER_ACTION_TYPES.SET_IS_DELETE_USER_SUBSCRIBER_HITTED,
    isDeleteUserSubscriberHitted
  );

export const resetUserSubscriberReducer = () =>
  createAction(USER_SUBSCRIBER_ACTION_TYPES.RESET_USER_SUBSCRIBER_REDUCER);
