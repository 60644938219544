const TERM_CONDITION_ACTION_TYPES = {
  RESET_TERM_CONDITION_REDUCER: "term-condition/RESET_TERM_CONDITION_REDUCER",

  SET_TERM_CONDITIONS: "term-condition/SET_TERM_CONDITIONS",
  SET_MODAL_TERM_CONDITIONS: "term-condition/SET_MODAL_TERM_CONDITIONS",
  SET_TERM_CONDITION: "term-condition/SET_TERM_CONDITION",

  SET_IS_TERM_CONDITIONS_HAS_MORE: "term-condition/SET_IS_TERM_CONDITIONS_HAS_MORE",
  SET_IS_MODAL_TERM_CONDITIONS_HAS_MORE: "term-condition/SET_IS_MODAL_TERM_CONDITIONS_HAS_MORE",

  SET_FETCH_TERM_CONDITIONS_SEARCH: "term-condition/SET_FETCH_TERM_CONDITIONS_SEARCH",
  SET_FETCH_TERM_CONDITIONS_PAGE: "term-condition/SET_FETCH_TERM_CONDITIONS_PAGE",
  SET_FETCH_TERM_CONDITIONS_PER_PAGE: "term-condition/SET_FETCH_TERM_CONDITIONS_PER_PAGE",
  SET_FETCH_TERM_CONDITIONS_INCLUDES: "term-condition/SET_FETCH_TERM_CONDITIONS_INCLUDES",
  SET_FETCH_TERM_CONDITIONS_LOADING: "term-condition/SET_FETCH_TERM_CONDITIONS_LOADING",
  SET_FETCH_TERM_CONDITIONS_SUCCESS: "term-condition/SET_FETCH_TERM_CONDITIONS_SUCCESS",
  SET_FETCH_TERM_CONDITIONS_FAILED: "term-condition/SET_FETCH_TERM_CONDITIONS_FAILED",

  SET_FETCH_MODAL_TERM_CONDITIONS_PAGE: "term-condition/SET_FETCH_MODAL_TERM_CONDITIONS_PAGE",
  SET_FETCH_MODAL_TERM_CONDITIONS_PER_PAGE: "term-condition/SET_FETCH_MODAL_TERM_CONDITIONS_PER_PAGE",
  SET_FETCH_MODAL_TERM_CONDITIONS_INCLUDES: "term-condition/SET_FETCH_MODAL_TERM_CONDITIONS_INCLUDES",
  SET_FETCH_MODAL_TERM_CONDITIONS_LOADING: "term-condition/SET_FETCH_MODAL_TERM_CONDITIONS_LOADING",
  SET_FETCH_MODAL_TERM_CONDITIONS_SUCCESS: "term-condition/SET_FETCH_MODAL_TERM_CONDITIONS_SUCCESS",
  SET_FETCH_MODAL_TERM_CONDITIONS_FAILED: "term-condition/SET_FETCH_MODAL_TERM_CONDITIONS_FAILED",

  SET_FETCH_TERM_CONDITION_LOADING: "term-condition/SET_FETCH_TERM_CONDITION_LOADING",
  SET_FETCH_TERM_CONDITION_SUCCESS: "term-condition/SET_FETCH_TERM_CONDITION_SUCCESS",
  SET_FETCH_TERM_CONDITION_FAILED: "term-condition/SET_FETCH_TERM_CONDITION_FAILED",

  SET_CREATE_TERM_CONDITION_LOADING: "term-condition/SET_CREATE_TERM_CONDITION_LOADING",
  SET_CREATE_TERM_CONDITION_SUCCESS: "term-condition/SET_CREATE_TERM_CONDITION_SUCCESS",
  SET_CREATE_TERM_CONDITION_FAILED: "term-condition/SET_CREATE_TERM_CONDITION_FAILED",

  SET_UPDATE_TERM_CONDITION_LOADING: "term-condition/SET_UPDATE_TERM_CONDITION_LOADING",
  SET_UPDATE_TERM_CONDITION_SUCCESS: "term-condition/SET_UPDATE_TERM_CONDITION_SUCCESS",
  SET_UPDATE_TERM_CONDITION_FAILED: "term-condition/SET_UPDATE_TERM_CONDITION_FAILED",

  SET_DELETE_TERM_CONDITION_LOADING: "term-condition/SET_DELETE_TERM_CONDITION_LOADING",
  SET_DELETE_TERM_CONDITION_SUCCESS: "term-condition/SET_DELETE_TERM_CONDITION_SUCCESS",
  SET_DELETE_TERM_CONDITION_FAILED: "term-condition/SET_DELETE_TERM_CONDITION_FAILED",

  APPEND_TERM_CONDITIONS: "term-condition/APPEND_TERM_CONDITIONS",
  APPEND_MODAL_TERM_CONDITIONS: "term-condition/APPEND_MODAL_TERM_CONDITIONS",

  FETCH_TERM_CONDITIONS_START: "term-condition/FETCH_TERM_CONDITIONS_START",
  FETCH_MODAL_TERM_CONDITIONS_START: "term-condition/FETCH_MODAL_TERM_CONDITIONS_START",
  FETCH_TERM_CONDITION_START: "term-condition/FETCH_TERM_CONDITION_START",
  CREATE_TERM_CONDITION_START: "term-condition/CREATE_TERM_CONDITION_START",
  UPDATE_TERM_CONDITION_START: "term-condition/UPDATE_TERM_CONDITION_START",
  DELETE_TERM_CONDITION_START: "term-condition/DELETE_TERM_CONDITION_START",
};

export default TERM_CONDITION_ACTION_TYPES;
