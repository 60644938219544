import apiService from "./api";

export const getCountries = async (parameters) =>
  (await apiService.get("/v2/public/countries", parameters)).data;
export const getCountry = async (countryId) =>
  (await apiService.get(`/v2/public/countries/${countryId}`)).data;
export const createCountry = async (request) =>
  (await apiService.post("/v2/countries", request)).data;
export const updateCountry = async (countryId, request) =>
  (await apiService.post(`/v2/countries/${countryId}`, request)).data;
export const deleteCountry = async (countryId) =>
  (await apiService.delete(`/v2/countries/${countryId}`)).data;
