import { takeLatest, put, all, call, select } from "redux-saga/effects";

import USER_MERCHANT_ACTION_TYPES from "./user-merchant.type";

import {
  appendUserMerchants,
  setUserMerchant,
  setUserMerchants,
  setCreateUserMerchantFailed,
  setCreateUserMerchantLoading,
  setCreateUserMerchantSuccess,
  setDeleteUserMerchantFailed,
  setDeleteUserMerchantLoading,
  setDeleteUserMerchantSuccess,
  setFetchUserMerchantFailed,
  setFetchUserMerchantLoading,
  setFetchUserMerchantSuccess,
  setFetchUserMerchantsFailed,
  setFetchUserMerchantsLoading,
  setFetchUserMerchantsPage,
  setFetchUserMerchantsSuccess,
  setIsUserMerchantsHasMore,
  setIsCreateUserMerchantHitted,
  setIsDeleteUserMerchantHitted,
  setIsFetchUserMerchantHitted,
  setIsFetchUserMerchantsHitted,
  setIsUpdateUserMerchantHitted,
  setUpdateUserMerchantFailed,
  setUpdateUserMerchantLoading,
  setUpdateUserMerchantSuccess,
  setFetchSelectUserMerchantsLoading,
  setIsFetchSelectUserMerchantsHitted,
  setFetchSelectUserMerchantsSuccess,
  setFetchSelectUserMerchantsFailed,
  setIsSelectUserMerchantsHasMore,
  appendSelectUserMerchants,
  setSelectUserMerchants,
  setIsAppendSelectUserMerchantsHitted,
  setAppendSelectUserMerchantsLoading,
  setAppendSelectUserMerchantsSuccess,
  setAppendSelectUserMerchantsFailed,
} from "./user-merchant.action";
import {
  getAppendSelectUserMerchantsFilterMarketId,
  getAppendSelectUserMerchantsFilterMerchantId,
  getAppendSelectUserMerchantsFilterPermissionMerchantId,
  getAppendSelectUserMerchantsFilterPermissionSubscriberId,
  getAppendSelectUserMerchantsFilterUsersIds,
  getAppendSelectUserMerchantsIncludes,
  getAppendSelectUserMerchantsKeyBy,
  getAppendSelectUserMerchantsPage,
  getAppendSelectUserMerchantsPerPage,
  getAppendSelectUserMerchantsSearch,
  getAppendSelectUserMerchantsSort,
  getFetchSelectUserMerchantsFilterMarketId,
  getFetchSelectUserMerchantsFilterMerchantId,
  getFetchSelectUserMerchantsFilterPermissionMerchantId,
  getFetchSelectUserMerchantsFilterPermissionSubscriberId,
  getFetchSelectUserMerchantsFilterUsersIds,
  getFetchSelectUserMerchantsIncludes,
  getFetchSelectUserMerchantsKeyBy,
  getFetchSelectUserMerchantsPage,
  getFetchSelectUserMerchantsPerPage,
  getFetchSelectUserMerchantsSearch,
  getFetchSelectUserMerchantsSort,
  getFetchUserMerchantsFilterMarketId,
  getFetchUserMerchantsFilterMerchantId,
  getFetchUserMerchantsFilterPermissionId,
  getFetchUserMerchantsFilterPermissionMerchantId,
  getFetchUserMerchantsFilterPermissionSubscriberId,
  getFetchUserMerchantsFilterUsersIds,
  getFetchUserMerchantsIncludes,
  getFetchUserMerchantsKeyBy,
  getFetchUserMerchantsPage,
  getFetchUserMerchantsPerPage,
  getFetchUserMerchantsSearch,
  getFetchUserMerchantsSort,
  getIsFetchUserMerchantsHitted,
} from "./user-merchant.selector";

import {
  createUserMerchant,
  deleteUserMerchant,
  getUserMerchant,
  getUserMerchants,
  updateUserMerchant,
} from "../../api/user-merchant.api";

export function* _getUserMerchants() {
  try {
    yield put(setFetchUserMerchantsLoading(true));

    const search = yield select(getFetchUserMerchantsSearch);
    const sort = yield select(getFetchUserMerchantsSort);
    const key_by = yield select(getFetchUserMerchantsKeyBy);
    const page = yield select(getFetchUserMerchantsPage);
    const per_page = yield select(getFetchUserMerchantsPerPage);
    const includes = yield select(getFetchUserMerchantsIncludes);
    const merchant_id = yield select(getFetchUserMerchantsFilterMerchantId);
    const permission_merchant_id = yield select(
      getFetchUserMerchantsFilterPermissionMerchantId
    );
    const permission_subscriber_id = yield select(
      getFetchUserMerchantsFilterPermissionSubscriberId
    );
    const market_id = yield select(getFetchUserMerchantsFilterMarketId);
    const users_ids = yield select(getFetchUserMerchantsFilterUsersIds);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        merchant_id,
        permission_merchant_id,
        permission_subscriber_id,
        market_id,
        users_ids,
      },
    };

    const {
      meta: { message },
      data: { data: userMerchants },
    } = yield call(getUserMerchants, parameters);

    yield put(setIsFetchUserMerchantsHitted(true));
    yield put(setIsUserMerchantsHasMore(userMerchants.length > 0));

    if (page > 1) {
      yield put(appendUserMerchants(userMerchants));
    } else {
      yield put(setUserMerchants(userMerchants));
    }

    yield put(setFetchUserMerchantsSuccess(message));
    yield put(setFetchUserMerchantsLoading(false));
  } catch (error) {
    yield put(setFetchUserMerchantsFailed(error));
    yield put(setFetchUserMerchantsLoading(false));
  }
}
export function* _getSelectUserMerchants() {
  try {
    yield put(setFetchSelectUserMerchantsLoading(true));

    const search = yield select(getFetchSelectUserMerchantsSearch);
    const sort = yield select(getFetchSelectUserMerchantsSort);
    const key_by = yield select(getFetchSelectUserMerchantsKeyBy);
    const page = yield select(getFetchSelectUserMerchantsPage);
    const per_page = yield select(getFetchSelectUserMerchantsPerPage);
    const includes = yield select(getFetchSelectUserMerchantsIncludes);
    const merchant_id = yield select(
      getFetchSelectUserMerchantsFilterMerchantId
    );
    const permission_merchant_id = yield select(
      getFetchSelectUserMerchantsFilterPermissionMerchantId
    );
    const permission_subscriber_id = yield select(
      getFetchSelectUserMerchantsFilterPermissionSubscriberId
    );
    const market_id = yield select(getFetchSelectUserMerchantsFilterMarketId);
    const users_ids = yield select(getFetchSelectUserMerchantsFilterUsersIds);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        merchant_id,
        permission_merchant_id,
        permission_subscriber_id,
        market_id,
        users_ids,
      },
    };

    const {
      meta: { message },
      data: userMerchants,
    } = yield call(getUserMerchants, parameters);

    yield put(setIsFetchSelectUserMerchantsHitted(true));
    yield put(
      setIsSelectUserMerchantsHasMore(Object.keys(userMerchants).length > 0)
    );

    if (page > 1) {
      yield put(appendSelectUserMerchants(userMerchants));
    } else {
      yield put(setSelectUserMerchants(userMerchants));
    }

    yield put(setFetchSelectUserMerchantsSuccess(message));
    yield put(setFetchSelectUserMerchantsLoading(false));
  } catch (error) {
    yield put(setFetchSelectUserMerchantsFailed(error));
    yield put(setFetchSelectUserMerchantsLoading(false));
  }
}
export function* _getAppendSelectUserMerchants() {
  try {
    yield put(setAppendSelectUserMerchantsLoading(true));

    const search = yield select(getAppendSelectUserMerchantsSearch);
    const sort = yield select(getAppendSelectUserMerchantsSort);
    const key_by = yield select(getAppendSelectUserMerchantsKeyBy);
    const page = yield select(getAppendSelectUserMerchantsPage);
    const per_page = yield select(getAppendSelectUserMerchantsPerPage);
    const includes = yield select(getAppendSelectUserMerchantsIncludes);
    const merchant_id = yield select(
      getAppendSelectUserMerchantsFilterMerchantId
    );
    const permission_merchant_id = yield select(
      getAppendSelectUserMerchantsFilterPermissionMerchantId
    );
    const permission_subscriber_id = yield select(
      getAppendSelectUserMerchantsFilterPermissionSubscriberId
    );
    const market_id = yield select(getAppendSelectUserMerchantsFilterMarketId);
    const users_ids = yield select(getAppendSelectUserMerchantsFilterUsersIds);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        merchant_id,
        permission_merchant_id,
        permission_subscriber_id,
        market_id,
        users_ids,
      },
    };

    const {
      meta: { message },
      data: userMerchants,
    } = yield call(getUserMerchants, parameters);

    yield put(setIsAppendSelectUserMerchantsHitted(true));
    yield put(appendSelectUserMerchants(userMerchants));

    yield put(setAppendSelectUserMerchantsSuccess(message));
    yield put(setAppendSelectUserMerchantsLoading(false));
  } catch (error) {
    yield put(setAppendSelectUserMerchantsFailed(error));
    yield put(setAppendSelectUserMerchantsLoading(false));
  }
}
export function* _getUserMerchant({ payload: merchantId }) {
  try {
    yield put(setFetchUserMerchantLoading(true));

    const {
      meta: { message },
      data: userMerchant,
    } = yield call(getUserMerchant, merchantId);

    yield put(setIsFetchUserMerchantHitted(true));
    yield put(setUserMerchant(userMerchant));

    yield put(setFetchUserMerchantSuccess(message));
    yield put(setFetchUserMerchantLoading(false));
  } catch (error) {
    yield put(setFetchUserMerchantFailed(error));
    yield put(setFetchUserMerchantLoading(false));
  }
}
export function* _createUserMerchant({ payload: request }) {
  try {
    yield put(setCreateUserMerchantLoading(true));

    const {
      meta: { message },
    } = yield call(createUserMerchant, request);

    yield put(setIsCreateUserMerchantHitted(true));

    const isFetchUserMerchantsHitted = yield select(
      getIsFetchUserMerchantsHitted
    );

    if (isFetchUserMerchantsHitted) {
      yield put(setFetchUserMerchantsPage(1));
      yield call(_getUserMerchants);
    }

    yield put(setCreateUserMerchantSuccess(message));
    yield put(setCreateUserMerchantLoading(false));
  } catch (error) {
    yield put(setCreateUserMerchantFailed(error));
    yield put(setCreateUserMerchantLoading(false));
  }
}
export function* _updateUserMerchant({ payload: { merchantId, request } }) {
  try {
    yield put(setUpdateUserMerchantLoading(true));

    const {
      meta: { message },
    } = yield call(updateUserMerchant, merchantId, request);

    yield put(setIsUpdateUserMerchantHitted(true));

    const isFetchUserMerchantsHitted = yield select(
      getIsFetchUserMerchantsHitted
    );

    if (isFetchUserMerchantsHitted) {
      yield put(setFetchUserMerchantsPage(1));
      yield call(_getUserMerchants);
    }

    yield put(setUpdateUserMerchantSuccess(message));
    yield put(setUpdateUserMerchantLoading(false));
  } catch (error) {
    yield put(setUpdateUserMerchantFailed(error));
    yield put(setUpdateUserMerchantLoading(false));
  }
}
export function* _deleteUserMerchant({ payload: merchantId }) {
  try {
    yield put(setDeleteUserMerchantLoading(true));

    const {
      meta: { message },
    } = yield call(deleteUserMerchant, merchantId);

    yield put(setIsDeleteUserMerchantHitted(true));

    const isFetchUserMerchantsHitted = yield select(
      getIsFetchUserMerchantsHitted
    );

    if (isFetchUserMerchantsHitted) {
      yield put(setFetchUserMerchantsPage(1));
      yield call(_getUserMerchants);
    }

    yield put(setDeleteUserMerchantSuccess(message));
    yield put(setDeleteUserMerchantLoading(false));
  } catch (error) {
    yield put(setDeleteUserMerchantFailed(error));
    yield put(setDeleteUserMerchantLoading(false));
  }
}

export function* onFetchUserMerchantsStart() {
  yield takeLatest(
    USER_MERCHANT_ACTION_TYPES.FETCH_USER_MERCHANTS_START,
    _getUserMerchants
  );
}
export function* onFetchSelectUserMerchantsStart() {
  yield takeLatest(
    USER_MERCHANT_ACTION_TYPES.FETCH_SELECT_USER_MERCHANTS_START,
    _getSelectUserMerchants
  );
}
export function* onAppendSelectUserMerchantsStart() {
  yield takeLatest(
    USER_MERCHANT_ACTION_TYPES.APPEND_SELECT_USER_MERCHANTS_START,
    _getAppendSelectUserMerchants
  );
}
export function* onFetchUserMerchantStart() {
  yield takeLatest(
    USER_MERCHANT_ACTION_TYPES.FETCH_USER_MERCHANT_START,
    _getUserMerchant
  );
}
export function* onCreateUserMerchantStart() {
  yield takeLatest(
    USER_MERCHANT_ACTION_TYPES.CREATE_USER_MERCHANT_START,
    _createUserMerchant
  );
}
export function* onUpdateUserMerchantStart() {
  yield takeLatest(
    USER_MERCHANT_ACTION_TYPES.UPDATE_USER_MERCHANT_START,
    _updateUserMerchant
  );
}
export function* onDeleteUserMerchantStart() {
  yield takeLatest(
    USER_MERCHANT_ACTION_TYPES.DELETE_USER_MERCHANT_START,
    _deleteUserMerchant
  );
}

export function* userMerchantSaga() {
  yield all([
    call(onFetchUserMerchantsStart),
    call(onFetchSelectUserMerchantsStart),
    call(onAppendSelectUserMerchantsStart),
    call(onFetchUserMerchantStart),
    call(onCreateUserMerchantStart),
    call(onUpdateUserMerchantStart),
    call(onDeleteUserMerchantStart),
  ]);
}
