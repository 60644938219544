import { createAction } from "../../utils/store.utils";

import SELECT_CUSTOMER_ACTION_TYPES from "./select-customer.type";

export const setCustomers = (customers) =>
  createAction(SELECT_CUSTOMER_ACTION_TYPES.SET_CUSTOMERS, customers);
export const setSearchCustomers = (searchCustomers) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_SEARCH_CUSTOMERS,
    searchCustomers
  );

export const appendCustomers = (customers) =>
  createAction(SELECT_CUSTOMER_ACTION_TYPES.APPEND_CUSTOMERS, customers);
export const appendSearchCustomers = (searchCustomers) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.APPEND_SEARCH_CUSTOMERS,
    searchCustomers
  );

export const setIsCustomersHasMore = (isCustomersHasMore) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_IS_CUSTOMERS_HAS_MORE,
    isCustomersHasMore
  );
export const setIsSearchCustomersHasMore = (isSearchCustomersHasMore) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_IS_SEARCH_CUSTOMERS_HAS_MORE,
    isSearchCustomersHasMore
  );

export const setFetchCustomersParams = (fetchCustomersParams) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_PARAMS,
    fetchCustomersParams
  );
export const setFetchCustomersLoading = (fetchCustomersLoading) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_LOADING,
    fetchCustomersLoading
  );
export const setFetchCustomersSuccess = (fetchCustomersSuccess) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SUCCESS,
    fetchCustomersSuccess
  );
export const setFetchCustomersFailed = (fetchCustomersFailed) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_FAILED,
    fetchCustomersFailed
  );

export const setSearchCustomersParams = (searchCustomersParams) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_SEARCH_CUSTOMERS_PARAMS,
    searchCustomersParams
  );
export const setSearchCustomersLoading = (searchCustomersLoading) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_SEARCH_CUSTOMERS_LOADING,
    searchCustomersLoading
  );
export const setSearchCustomersSuccess = (searchCustomersSuccess) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_SEARCH_CUSTOMERS_SUCCESS,
    searchCustomersSuccess
  );
export const setSearchCustomersFailed = (searchCustomersFailed) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_SEARCH_CUSTOMERS_FAILED,
    searchCustomersFailed
  );

export const setAppendCustomersParams = (appendCustomersParams) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_APPEND_CUSTOMERS_PARAMS,
    appendCustomersParams
  );
export const setAppendCustomersLoading = (appendCustomersLoading) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_APPEND_CUSTOMERS_LOADING,
    appendCustomersLoading
  );
export const setAppendCustomersSuccess = (appendCustomersSuccess) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_APPEND_CUSTOMERS_SUCCESS,
    appendCustomersSuccess
  );
export const setAppendCustomersFailed = (appendCustomersFailed) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_APPEND_CUSTOMERS_FAILED,
    appendCustomersFailed
  );

export const setIsFetchCustomersHitted = (isFetchCustomersHitted) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_IS_FETCH_CUSTOMERS_HITTED,
    isFetchCustomersHitted
  );
export const setIsSearchCustomersHitted = (isSearchCustomersHitted) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_IS_SEARCH_CUSTOMERS_HITTED,
    isSearchCustomersHitted
  );
export const setIsAppendCustomersHitted = (isAppendCustomersHitted) =>
  createAction(
    SELECT_CUSTOMER_ACTION_TYPES.SET_IS_APPEND_CUSTOMERS_HITTED,
    isAppendCustomersHitted
  );

export const fetchCustomersStart = (params) =>
  createAction(SELECT_CUSTOMER_ACTION_TYPES.FETCH_CUSTOMERS_START, params);
export const searchCustomersStart = (params) =>
  createAction(SELECT_CUSTOMER_ACTION_TYPES.SEARCH_CUSTOMERS_START, params);
export const appendCustomersStart = (params) =>
  createAction(SELECT_CUSTOMER_ACTION_TYPES.APPEND_CUSTOMERS_START, params);

export const resetCustomerReducer = () =>
  createAction(SELECT_CUSTOMER_ACTION_TYPES.RESET_SELECT_CUSTOMER_REDUCER);
