import apiService from "./api";

export const getPermissionMerchants = async (parameters) =>
  (await apiService.get("/v2/permissions/merchants", parameters)).data;
export const getPermissionMerchant = async (permissionId) =>
  (await apiService.get(`/v2/permissions/merchants/${permissionId}`)).data;
export const createPermissionMerchant = async (request) =>
  (await apiService.post("/v2/permissions/merchants", request)).data;
export const updatePermissionMerchant = async (permissionId, request) =>
  (await apiService.post(`/v2/permissions/merchants/${permissionId}`, request))
    .data;
export const deletePermissionMerchant = async (permissionId) =>
  (await apiService.delete(`/v2/permissions/merchants/${permissionId}`)).data;
