import { createAction } from "../../utils/store.utils";

import KITCHEN_ACTION_TYPES from "./kitchen.type";

export const setFirstProcessOrders = (firstProcessOrders) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FIRST_PROCESS_ORDERS, firstProcessOrders);
export const setSecondProcessOrders = (secondProcessOrders) =>
  createAction(KITCHEN_ACTION_TYPES.SET_SECOND_PROCESS_ORDERS, secondProcessOrders);
export const setThirdProcessOrders = (thirdProcessOrders) =>
  createAction(KITCHEN_ACTION_TYPES.SET_THIRD_PROCESS_ORDERS, thirdProcessOrders);

export const setProcessOrders = (processOrders) => createAction(KITCHEN_ACTION_TYPES.SET_PROCESS_ORDERS, processOrders);
export const setCompletedOrders = (completedOrders) =>
  createAction(KITCHEN_ACTION_TYPES.SET_COMPLETED_ORDERS, completedOrders);

export const setIsProcessOrdersHasMore = (isProcessOrdersHasMore) =>
  createAction(KITCHEN_ACTION_TYPES.SET_IS_PROCESS_ORDERS_HAS_MORE, isProcessOrdersHasMore);
export const setIsCompletedOrdersHasMore = (isCompletedOrdersHasMore) =>
  createAction(KITCHEN_ACTION_TYPES.SET_IS_COMPLETED_ORDERS_HAS_MORE, isCompletedOrdersHasMore);

export const setFetchProcessOrdersSearch = (fetchProcessOrdersSearch) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_SEARCH, fetchProcessOrdersSearch);
export const setFetchProcessOrdersSort = (fetchProcessOrdersSort) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_SORT, fetchProcessOrdersSort);
export const setFetchProcessOrdersPage = (fetchProcessOrdersPage) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_PAGE, fetchProcessOrdersPage);
export const setFetchProcessOrdersPerPage = (fetchProcessOrdersPerPage) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_PER_PAGE, fetchProcessOrdersPerPage);
export const setFetchProcessOrdersIncludes = (fetchProcessOrdersIncludes) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_INCLUDES, fetchProcessOrdersIncludes);
export const setFetchProcessOrdersFilterPeriodType = (fetchProcessOrdersFilterPeriodType) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_PERIOD_TYPE, fetchProcessOrdersFilterPeriodType);
export const setFetchProcessOrdersFilterStartAt = (fetchProcessOrdersFilterStartAt) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_START_AT, fetchProcessOrdersFilterStartAt);
export const setFetchProcessOrdersFilterEndAt = (fetchProcessOrdersFilterEndAt) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_END_AT, fetchProcessOrdersFilterEndAt);
export const setFetchProcessOrdersFilterMarketId = (fetchProcessOrdersFilterMarketId) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_MARKET_ID, fetchProcessOrdersFilterMarketId);
export const setFetchProcessOrdersFilterBranchId = (fetchProcessOrdersFilterBranchId) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_BRANCH_ID, fetchProcessOrdersFilterBranchId);
export const setFetchProcessOrdersFilterProductCategoriesIds = (fetchProcessOrdersFilterProductCategoriesIds) =>
  createAction(
    KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS,
    fetchProcessOrdersFilterProductCategoriesIds
  );
export const setFetchProcessOrdersFilterSources = (fetchProcessOrdersFilterSources) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_SOURCES, fetchProcessOrdersFilterSources);
export const setFetchProcessOrdersFilterTypes = (fetchProcessOrdersFilterTypes) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_TYPES, fetchProcessOrdersFilterTypes);
export const setFetchProcessOrdersFilterTableId = (fetchProcessOrdersFilterTableId) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_TABLE_ID, fetchProcessOrdersFilterTableId);
export const setFetchProcessOrdersFilterCashierId = (fetchProcessOrdersFilterCashierId) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_CASHIER_ID, fetchProcessOrdersFilterCashierId);
export const setFetchProcessOrdersLoading = (fetchProcessOrdersLoading) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_LOADING, fetchProcessOrdersLoading);
export const setFetchProcessOrdersSuccess = (fetchProcessOrdersSuccess) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_SUCCESS, fetchProcessOrdersSuccess);
export const setFetchProcessOrdersFailed = (fetchProcessOrdersFailed) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FAILED, fetchProcessOrdersFailed);

export const setFetchCompletedOrdersSearch = (fetchCompletedOrdersSearch) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_SEARCH, fetchCompletedOrdersSearch);
export const setFetchCompletedOrdersSort = (fetchCompletedOrdersSort) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_SORT, fetchCompletedOrdersSort);
export const setFetchCompletedOrdersPage = (fetchCompletedOrdersPage) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_PAGE, fetchCompletedOrdersPage);
export const setFetchCompletedOrdersPerPage = (fetchCompletedOrdersPerPage) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_PER_PAGE, fetchCompletedOrdersPerPage);
export const setFetchCompletedOrdersIncludes = (fetchCompletedOrdersIncludes) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_INCLUDES, fetchCompletedOrdersIncludes);
export const setFetchCompletedOrdersFilterPeriodType = (fetchCompletedOrdersFilterPeriodType) =>
  createAction(
    KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_PERIOD_TYPE,
    fetchCompletedOrdersFilterPeriodType
  );
export const setFetchCompletedOrdersFilterStartAt = (fetchCompletedOrdersFilterStartAt) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_START_AT, fetchCompletedOrdersFilterStartAt);
export const setFetchCompletedOrdersFilterEndAt = (fetchCompletedOrdersFilterEndAt) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_END_AT, fetchCompletedOrdersFilterEndAt);
export const setFetchCompletedOrdersFilterMarketId = (fetchCompletedOrdersFilterMarketId) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_MARKET_ID, fetchCompletedOrdersFilterMarketId);
export const setFetchCompletedOrdersFilterBranchId = (fetchCompletedOrdersFilterBranchId) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_BRANCH_ID, fetchCompletedOrdersFilterBranchId);
export const setFetchCompletedOrdersFilterProductCategoriesIds = (fetchCompletedOrdersFilterProductCategoriesIds) =>
  createAction(
    KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS,
    fetchCompletedOrdersFilterProductCategoriesIds
  );
export const setFetchCompletedOrdersFilterSources = (fetchCompletedOrdersFilterSources) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_SOURCES, fetchCompletedOrdersFilterSources);
export const setFetchCompletedOrdersFilterTypes = (fetchCompletedOrdersFilterTypes) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_TYPES, fetchCompletedOrdersFilterTypes);
export const setFetchCompletedOrdersFilterTableId = (fetchCompletedOrdersFilterTableId) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_TABLE_ID, fetchCompletedOrdersFilterTableId);
export const setFetchCompletedOrdersFilterCashierId = (fetchCompletedOrdersFilterCashierId) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_CASHIER_ID, fetchCompletedOrdersFilterCashierId);
export const setFetchCompletedOrdersLoading = (fetchCompletedOrdersLoading) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_LOADING, fetchCompletedOrdersLoading);
export const setFetchCompletedOrdersSuccess = (fetchCompletedOrdersSuccess) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_SUCCESS, fetchCompletedOrdersSuccess);
export const setFetchCompletedOrdersFailed = (fetchCompletedOrdersFailed) =>
  createAction(KITCHEN_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FAILED, fetchCompletedOrdersFailed);

export const appendFirstProcessOrders = (firstProcessOrders) =>
  createAction(KITCHEN_ACTION_TYPES.APPEND_FIRST_PROCESS_ORDERS, firstProcessOrders);
export const appendSecondProcessOrders = (secondProcessOrders) =>
  createAction(KITCHEN_ACTION_TYPES.APPEND_SECOND_PROCESS_ORDERS, secondProcessOrders);
export const appendThirdProcessOrders = (thirdProcessOrders) =>
  createAction(KITCHEN_ACTION_TYPES.APPEND_THIRD_PROCESS_ORDERS, thirdProcessOrders);

export const appendProcessOrders = (processOrders) =>
  createAction(KITCHEN_ACTION_TYPES.APPEND_PROCESS_ORDERS, processOrders);
export const appendCompletedOrders = (completedOrders) =>
  createAction(KITCHEN_ACTION_TYPES.APPEND_COMPLETED_ORDERS, completedOrders);

export const removeFirstProcessOrder = (orderId) =>
  createAction(KITCHEN_ACTION_TYPES.REMOVE_FIRST_PROCESS_ORDER, orderId);
export const removeSecondProcessOrder = (orderId) =>
  createAction(KITCHEN_ACTION_TYPES.REMOVE_SECOND_PROCESS_ORDER, orderId);
export const removeThirdProcessOrder = (orderId) =>
  createAction(KITCHEN_ACTION_TYPES.REMOVE_THIRD_PROCESS_ORDER, orderId);

export const fetchProcessOrdersStart = () => createAction(KITCHEN_ACTION_TYPES.FETCH_PROCESS_ORDERS_START);
export const fetchCompletedOrdersStart = () => createAction(KITCHEN_ACTION_TYPES.FETCH_COMPLETED_ORDERS_START);

export const setIsFetchProcessOrdersHitted = (isFetchProcessOrdersHitted) =>
  createAction(KITCHEN_ACTION_TYPES.SET_IS_FETCH_PROCESS_ORDERS_HITTED, isFetchProcessOrdersHitted);
export const setIsFetchCompletedOrdersHitted = (isFetchCompletedOrdersHitted) =>
  createAction(KITCHEN_ACTION_TYPES.SET_IS_FETCH_COMPLETED_ORDERS_HITTED, isFetchCompletedOrdersHitted);

export const resetOrderReducer = () => createAction(KITCHEN_ACTION_TYPES.RESET_ORDER_REDUCER);
