import { takeLatest, put, all, call, select } from "redux-saga/effects";

import KITCHEN_GROUP_ACTION_TYPES from "./kitchen-group.type";

import {
  appendKitchenGroups,
  appendSelectKitchenGroups,
  setCreateKitchenGroupFailed,
  setCreateKitchenGroupLoading,
  setCreateKitchenGroupSuccess,
  setDeleteKitchenGroupFailed,
  setDeleteKitchenGroupLoading,
  setDeleteKitchenGroupSuccess,
  setFetchKitchenGroupFailed,
  setFetchKitchenGroupLoading,
  setFetchKitchenGroupsFailed,
  setFetchKitchenGroupsLoading,
  setFetchKitchenGroupsPage,
  setFetchKitchenGroupsSuccess,
  setFetchKitchenGroupSuccess,
  setFetchSelectKitchenGroupsFailed,
  setFetchSelectKitchenGroupsLoading,
  setFetchSelectKitchenGroupsPage,
  setFetchSelectKitchenGroupsSuccess,
  setIsCreateKitchenGroupHitted,
  setIsDeleteKitchenGroupHitted,
  setIsFetchKitchenGroupHitted,
  setIsFetchKitchenGroupsHitted,
  setIsFetchSelectKitchenGroupsHitted,
  setIsKitchenGroupsHasMore,
  setIsSelectKitchenGroupsHasMore,
  setIsUpdateKitchenGroupHitted,
  setKitchenGroup,
  setKitchenGroups,
  setSelectKitchenGroups,
  setUpdateKitchenGroupFailed,
  setUpdateKitchenGroupLoading,
  setUpdateKitchenGroupSuccess,
} from "./kitchen-group.action";
import {
  getFetchKitchenGroupsFilterBranchId,
  getFetchKitchenGroupsFilterMarketId,
  getFetchKitchenGroupsIncludes,
  getFetchKitchenGroupsPage,
  getFetchKitchenGroupsPerPage,
  getFetchKitchenGroupsSearch,
  getFetchSelectKitchenGroupsFilterBranchId,
  getFetchSelectKitchenGroupsFilterMarketId,
  getFetchSelectKitchenGroupsIncludes,
  getFetchSelectKitchenGroupsPage,
  getFetchSelectKitchenGroupsPerPage,
  getIsFetchKitchenGroupsHitted,
  getIsFetchSelectKitchenGroupsHitted,
} from "./kitchen-group.selector";

import {
  getKitchenGroups,
  getKitchenGroup,
  createKitchenGroup,
  updateKitchenGroup,
  deleteKitchenGroup,
} from "../../api/kitchen-group.api";

export function* _getKitchenGroups() {
  try {
    yield put(setFetchKitchenGroupsLoading(true));

    const search = yield select(getFetchKitchenGroupsSearch);
    const page = yield select(getFetchKitchenGroupsPage);
    const per_page = yield select(getFetchKitchenGroupsPerPage);
    const includes = yield select(getFetchKitchenGroupsIncludes);
    const market_id = yield select(getFetchKitchenGroupsFilterMarketId);
    const branch_id = yield select(getFetchKitchenGroupsFilterBranchId);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
      },
    };

    const {
      meta: { message },
      data: { data: kitchenGroups },
    } = yield call(getKitchenGroups, parameters);

    yield put(setIsFetchKitchenGroupsHitted(true));
    yield put(setIsKitchenGroupsHasMore(kitchenGroups.length > 0));

    if (page > 1) {
      yield put(appendKitchenGroups(kitchenGroups));
    } else {
      yield put(setKitchenGroups(kitchenGroups));
    }

    yield put(setFetchKitchenGroupsSuccess(message));
    yield put(setFetchKitchenGroupsLoading(false));
  } catch (error) {
    yield put(setFetchKitchenGroupsFailed(error));
    yield put(setFetchKitchenGroupsLoading(false));
  }
}

export function* _getSelectKitchenGroups() {
  try {
    yield put(setFetchSelectKitchenGroupsLoading(true));

    const page = yield select(getFetchSelectKitchenGroupsPage);
    const per_page = yield select(getFetchSelectKitchenGroupsPerPage);
    const includes = yield select(getFetchSelectKitchenGroupsIncludes);
    const market_id = yield select(getFetchSelectKitchenGroupsFilterMarketId);
    const branch_id = yield select(getFetchSelectKitchenGroupsFilterBranchId);

    const parameters = {
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
      },
    };

    const {
      meta: { message },
      data: { data: kitchenGroups },
    } = yield call(getKitchenGroups, parameters);

    yield put(setIsFetchSelectKitchenGroupsHitted(true));
    yield put(setIsSelectKitchenGroupsHasMore(kitchenGroups.length > 0));

    if (page > 1) {
      yield put(appendSelectKitchenGroups(kitchenGroups));
    } else {
      yield put(setSelectKitchenGroups(kitchenGroups));
    }

    yield put(setFetchSelectKitchenGroupsSuccess(message));
    yield put(setFetchSelectKitchenGroupsLoading(false));
  } catch (error) {
    yield put(setFetchSelectKitchenGroupsFailed(error));
    yield put(setFetchSelectKitchenGroupsLoading(false));
  }
}

export function* _getKitchenGroup({ payload: id }) {
  try {
    yield put(setFetchKitchenGroupLoading(true));

    const {
      meta: { message },
      data: kitchenGroup,
    } = yield call(getKitchenGroup, id);

    yield put(setIsFetchKitchenGroupHitted(true));
    yield put(setKitchenGroup(kitchenGroup));

    yield put(setFetchKitchenGroupSuccess(message));
    yield put(setFetchKitchenGroupLoading(false));
  } catch (error) {
    yield put(setFetchKitchenGroupFailed(error));
    yield put(setFetchKitchenGroupLoading(false));
  }
}

export function* _createKitchenGroup({ payload: request }) {
  try {
    yield put(setCreateKitchenGroupLoading(true));

    const {
      meta: { message },
    } = yield call(createKitchenGroup, request);

    yield put(setIsCreateKitchenGroupHitted(true));

    const isFetchKitchenGroupsHitted = yield select(getIsFetchKitchenGroupsHitted);
    const isFetchSelectKitchenGroupsHitted = yield select(getIsFetchSelectKitchenGroupsHitted);

    if (isFetchKitchenGroupsHitted) {
      yield put(setFetchKitchenGroupsPage(1));
      yield call(_getKitchenGroups);
    }
    if (isFetchSelectKitchenGroupsHitted) {
      yield put(setFetchSelectKitchenGroupsPage(1));
      yield call(_getSelectKitchenGroups);
    }

    yield put(setCreateKitchenGroupSuccess(message));
    yield put(setCreateKitchenGroupLoading(false));
  } catch (error) {
    yield put(setCreateKitchenGroupFailed(error));
    yield put(setCreateKitchenGroupLoading(false));
  }
}

export function* _updateKitchenGroup({ payload: { id, request } }) {
  try {
    yield put(setUpdateKitchenGroupLoading(true));

    const {
      meta: { message },
    } = yield call(updateKitchenGroup, id, request);

    yield put(setIsUpdateKitchenGroupHitted(true));

    const isFetchKitchenGroupsHitted = yield select(getIsFetchKitchenGroupsHitted);
    const isFetchSelectKitchenGroupsHitted = yield select(getIsFetchSelectKitchenGroupsHitted);

    if (isFetchKitchenGroupsHitted) {
      yield put(setFetchKitchenGroupsPage(1));
      yield call(_getKitchenGroups);
    }
    if (isFetchSelectKitchenGroupsHitted) {
      yield put(setFetchSelectKitchenGroupsPage(1));
      yield call(_getSelectKitchenGroups);
    }

    yield put(setUpdateKitchenGroupSuccess(message));
    yield put(setUpdateKitchenGroupLoading(false));
  } catch (error) {
    yield put(setUpdateKitchenGroupFailed(error));
    yield put(setUpdateKitchenGroupLoading(false));
  }
}

export function* _deleteKitchenGroup({ payload: id }) {
  try {
    yield put(setDeleteKitchenGroupLoading(true));

    const {
      meta: { message },
    } = yield call(deleteKitchenGroup, id);

    yield put(setIsDeleteKitchenGroupHitted(true));

    const isFetchKitchenGroupsHitted = yield select(getIsFetchKitchenGroupsHitted);
    const isFetchSelectKitchenGroupsHitted = yield select(getIsFetchSelectKitchenGroupsHitted);

    if (isFetchKitchenGroupsHitted) {
      yield put(setFetchKitchenGroupsPage(1));
      yield call(_getKitchenGroups);
    }
    if (isFetchSelectKitchenGroupsHitted) {
      yield put(setFetchSelectKitchenGroupsPage(1));
      yield call(_getSelectKitchenGroups);
    }

    yield put(setDeleteKitchenGroupSuccess(message));
    yield put(setDeleteKitchenGroupLoading(false));
  } catch (error) {
    yield put(setDeleteKitchenGroupFailed(error));
    yield put(setDeleteKitchenGroupLoading(false));
  }
}

export function* onFetchKitchenGroupsStart() {
  yield takeLatest(KITCHEN_GROUP_ACTION_TYPES.FETCH_KITCHEN_GROUPS_START, _getKitchenGroups);
}

export function* onFetchSelectKitchenGroupsStart() {
  yield takeLatest(KITCHEN_GROUP_ACTION_TYPES.FETCH_SELECT_KITCHEN_GROUPS_START, _getSelectKitchenGroups);
}

export function* onFetchKitchenGroupStart() {
  yield takeLatest(KITCHEN_GROUP_ACTION_TYPES.FETCH_KITCHEN_GROUP_START, _getKitchenGroup);
}

export function* onCreateKitchenGroupStart() {
  yield takeLatest(KITCHEN_GROUP_ACTION_TYPES.CREATE_KITCHEN_GROUP_START, _createKitchenGroup);
}

export function* onUpdateKitchenGroupStart() {
  yield takeLatest(KITCHEN_GROUP_ACTION_TYPES.UPDATE_KITCHEN_GROUP_START, _updateKitchenGroup);
}

export function* onDeleteKitchenGroupStart() {
  yield takeLatest(KITCHEN_GROUP_ACTION_TYPES.DELETE_KITCHEN_GROUP_START, _deleteKitchenGroup);
}

export function* kitchenGroupSaga() {
  yield all([
    call(onFetchKitchenGroupsStart),
    call(onFetchSelectKitchenGroupsStart),
    call(onFetchKitchenGroupStart),
    call(onCreateKitchenGroupStart),
    call(onUpdateKitchenGroupStart),
    call(onDeleteKitchenGroupStart),
  ]);
}
