import EVENT_ACTION_TYPES from "./event.type";
import { createAction } from "../../utils/store.utils";

export const setSendEventLoading = (sendEventLoading) =>
  createAction(EVENT_ACTION_TYPES.SET_SEND_EVENT_LOADING, sendEventLoading);

export const setSendEventSuccess = (sendEventSuccess) =>
  createAction(EVENT_ACTION_TYPES.SET_SEND_EVENT_SUCCESS, sendEventSuccess);

export const setSendEventFailed = (sendEventFailed) =>
  createAction(EVENT_ACTION_TYPES.SET_SEND_EVENT_FAILED, sendEventFailed);

export const sendEventStart = (request) => createAction(EVENT_ACTION_TYPES.SEND_EVENT_START, request);

export const setIsSendEventHitted = (isSendEventHitted) =>
  createAction(EVENT_ACTION_TYPES.SET_IS_SEND_EVENT_HITTED, isSendEventHitted);

export const resetEventReducer = () => createAction(EVENT_ACTION_TYPES.RESET_EVENT_REDUCER);
