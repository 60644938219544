import { createSelector } from "reselect";

const permissionMerchantSelector = ({ permissionMerchant }) =>
  permissionMerchant;

export const getPermissionTab = createSelector(
  [permissionMerchantSelector],
  ({ permissionTab }) => permissionTab
);
export const getPermissionTypes = createSelector(
  [permissionMerchantSelector],
  ({ permissionTypes }) => permissionTypes
);
export const getPermissionMerchants = createSelector(
  [permissionMerchantSelector],
  ({ permissionMerchants }) => permissionMerchants
);
export const getPermissionSubscribers = createSelector(
  [permissionMerchantSelector],
  ({ permissionSubscribers }) => permissionSubscribers
);
export const getSelectPermissionMerchants = createSelector(
  [permissionMerchantSelector],
  ({ selectPermissionMerchants }) => selectPermissionMerchants
);
export const getSelectPermissionSubscribers = createSelector(
  [permissionMerchantSelector],
  ({ selectPermissionSubscribers }) => selectPermissionSubscribers
);
export const getPermission = createSelector(
  [permissionMerchantSelector],
  ({ permission }) => permission
);

export const getIsPermissionMerchantsHasMore = createSelector(
  [permissionMerchantSelector],
  ({ isPermissionMerchantsHasMore }) => isPermissionMerchantsHasMore
);
export const getIsPermissionSubscribersHasMore = createSelector(
  [permissionMerchantSelector],
  ({ isPermissionSubscribersHasMore }) => isPermissionSubscribersHasMore
);
export const getIsSelectPermissionMerchantsHasMore = createSelector(
  [permissionMerchantSelector],
  ({ isSelectPermissionMerchantsHasMore }) => isSelectPermissionMerchantsHasMore
);
export const getIsSelectPermissionSubscribersHasMore = createSelector(
  [permissionMerchantSelector],
  ({ isSelectPermissionSubscribersHasMore }) =>
    isSelectPermissionSubscribersHasMore
);

export const getFetchPermissionMerchantsSearch = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionMerchantsSearch }) => fetchPermissionMerchantsSearch
);
export const getFetchPermissionMerchantsSort = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionMerchantsSort }) => fetchPermissionMerchantsSort
);
export const getFetchPermissionMerchantsKeyBy = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionMerchantsKeyBy }) => fetchPermissionMerchantsKeyBy
);
export const getFetchPermissionMerchantsPage = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionMerchantsPage }) => fetchPermissionMerchantsPage
);
export const getFetchPermissionMerchantsPerPage = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionMerchantsPerPage }) => fetchPermissionMerchantsPerPage
);
export const getFetchPermissionMerchantsIncludes = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionMerchantsIncludes }) => fetchPermissionMerchantsIncludes
);
export const getFetchPermissionMerchantsFilterMerchantId = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionMerchantsFilterMerchantId }) =>
    fetchPermissionMerchantsFilterMerchantId
);
export const getFetchPermissionMerchantsFilterIsDefault = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionMerchantsFilterIsDefault }) =>
    fetchPermissionMerchantsFilterIsDefault
);
export const getFetchPermissionMerchantsLoading = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionMerchantsLoading }) => fetchPermissionMerchantsLoading
);
export const getFetchPermissionMerchantsSuccess = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionMerchantsSuccess }) => fetchPermissionMerchantsSuccess
);
export const getFetchPermissionMerchantsFailed = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionMerchantsFailed }) => fetchPermissionMerchantsFailed
);

export const getFetchPermissionSubscribersSearch = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionSubscribersSearch }) => fetchPermissionSubscribersSearch
);
export const getFetchPermissionSubscribersSort = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionSubscribersSort }) => fetchPermissionSubscribersSort
);
export const getFetchPermissionSubscribersKeyBy = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionSubscribersKeyBy }) => fetchPermissionSubscribersKeyBy
);
export const getFetchPermissionSubscribersPage = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionSubscribersPage }) => fetchPermissionSubscribersPage
);
export const getFetchPermissionSubscribersPerPage = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionSubscribersPerPage }) => fetchPermissionSubscribersPerPage
);
export const getFetchPermissionSubscribersIncludes = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionSubscribersIncludes }) => fetchPermissionSubscribersIncludes
);
export const getFetchPermissionSubscribersFilterMerchantId = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionSubscribersFilterMerchantId }) =>
    fetchPermissionSubscribersFilterMerchantId
);
export const getFetchPermissionSubscribersFilterIsDefault = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionSubscribersFilterIsDefault }) =>
    fetchPermissionSubscribersFilterIsDefault
);
export const getFetchPermissionSubscribersLoading = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionSubscribersLoading }) => fetchPermissionSubscribersLoading
);
export const getFetchPermissionSubscribersSuccess = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionSubscribersSuccess }) => fetchPermissionSubscribersSuccess
);
export const getFetchPermissionSubscribersFailed = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionSubscribersFailed }) => fetchPermissionSubscribersFailed
);

export const getFetchSelectPermissionMerchantsSearch = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionMerchantsSearch }) =>
    fetchSelectPermissionMerchantsSearch
);
export const getFetchSelectPermissionMerchantsSort = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionMerchantsSort }) => fetchSelectPermissionMerchantsSort
);
export const getFetchSelectPermissionMerchantsKeyBy = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionMerchantsKeyBy }) =>
    fetchSelectPermissionMerchantsKeyBy
);
export const getFetchSelectPermissionMerchantsPage = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionMerchantsPage }) => fetchSelectPermissionMerchantsPage
);
export const getFetchSelectPermissionMerchantsPerPage = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionMerchantsPerPage }) =>
    fetchSelectPermissionMerchantsPerPage
);
export const getFetchSelectPermissionMerchantsIncludes = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionMerchantsIncludes }) =>
    fetchSelectPermissionMerchantsIncludes
);
export const getFetchSelectPermissionMerchantsFilterMerchantId = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionMerchantsFilterMerchantId }) =>
    fetchSelectPermissionMerchantsFilterMerchantId
);
export const getFetchSelectPermissionMerchantsFilterPermissionsIds =
  createSelector(
    [permissionMerchantSelector],
    ({ fetchSelectPermissionMerchantsFilterPermissionsIds }) =>
      fetchSelectPermissionMerchantsFilterPermissionsIds
  );
export const getFetchSelectPermissionMerchantsFilterIsDefault = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionMerchantsFilterIsDefault }) =>
    fetchSelectPermissionMerchantsFilterIsDefault
);
export const getFetchSelectPermissionMerchantsLoading = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionMerchantsLoading }) =>
    fetchSelectPermissionMerchantsLoading
);
export const getFetchSelectPermissionMerchantsSuccess = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionMerchantsSuccess }) =>
    fetchSelectPermissionMerchantsSuccess
);
export const getFetchSelectPermissionMerchantsFailed = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionMerchantsFailed }) =>
    fetchSelectPermissionMerchantsFailed
);

export const getFetchSelectPermissionSubscribersSearch = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionSubscribersSearch }) =>
    fetchSelectPermissionSubscribersSearch
);
export const getFetchSelectPermissionSubscribersSort = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionSubscribersSort }) =>
    fetchSelectPermissionSubscribersSort
);
export const getFetchSelectPermissionSubscribersKeyBy = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionSubscribersKeyBy }) =>
    fetchSelectPermissionSubscribersKeyBy
);
export const getFetchSelectPermissionSubscribersPage = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionSubscribersPage }) =>
    fetchSelectPermissionSubscribersPage
);
export const getFetchSelectPermissionSubscribersPerPage = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionSubscribersPerPage }) =>
    fetchSelectPermissionSubscribersPerPage
);
export const getFetchSelectPermissionSubscribersIncludes = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionSubscribersIncludes }) =>
    fetchSelectPermissionSubscribersIncludes
);
export const getFetchSelectPermissionSubscribersFilterMerchantId =
  createSelector(
    [permissionMerchantSelector],
    ({ fetchSelectPermissionSubscribersFilterMerchantId }) =>
      fetchSelectPermissionSubscribersFilterMerchantId
  );
export const getFetchSelectPermissionSubscribersFilterPermissionsIds =
  createSelector(
    [permissionMerchantSelector],
    ({ fetchSelectPermissionSubscribersFilterPermissionsIds }) =>
      fetchSelectPermissionSubscribersFilterPermissionsIds
  );
export const getFetchSelectPermissionSubscribersFilterIsDefault =
  createSelector(
    [permissionMerchantSelector],
    ({ fetchSelectPermissionSubscribersFilterIsDefault }) =>
      fetchSelectPermissionSubscribersFilterIsDefault
  );
export const getFetchSelectPermissionSubscribersLoading = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionSubscribersLoading }) =>
    fetchSelectPermissionSubscribersLoading
);
export const getFetchSelectPermissionSubscribersSuccess = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionSubscribersSuccess }) =>
    fetchSelectPermissionSubscribersSuccess
);
export const getFetchSelectPermissionSubscribersFailed = createSelector(
  [permissionMerchantSelector],
  ({ fetchSelectPermissionSubscribersFailed }) =>
    fetchSelectPermissionSubscribersFailed
);

export const getAppendSelectPermissionMerchantsSearch = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionMerchantsSearch }) =>
    appendSelectPermissionMerchantsSearch
);
export const getAppendSelectPermissionMerchantsSort = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionMerchantsSort }) =>
    appendSelectPermissionMerchantsSort
);
export const getAppendSelectPermissionMerchantsKeyBy = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionMerchantsKeyBy }) =>
    appendSelectPermissionMerchantsKeyBy
);
export const getAppendSelectPermissionMerchantsPage = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionMerchantsPage }) =>
    appendSelectPermissionMerchantsPage
);
export const getAppendSelectPermissionMerchantsPerPage = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionMerchantsPerPage }) =>
    appendSelectPermissionMerchantsPerPage
);
export const getAppendSelectPermissionMerchantsIncludes = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionMerchantsIncludes }) =>
    appendSelectPermissionMerchantsIncludes
);
export const getAppendSelectPermissionMerchantsFilterMerchantId =
  createSelector(
    [permissionMerchantSelector],
    ({ appendSelectPermissionMerchantsFilterMerchantId }) =>
      appendSelectPermissionMerchantsFilterMerchantId
  );
export const getAppendSelectPermissionMerchantsFilterPermissionsIds =
  createSelector(
    [permissionMerchantSelector],
    ({ appendSelectPermissionMerchantsFilterPermissionsIds }) =>
      appendSelectPermissionMerchantsFilterPermissionsIds
  );
export const getAppendSelectPermissionMerchantsFilterIsDefault = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionMerchantsFilterIsDefault }) =>
    appendSelectPermissionMerchantsFilterIsDefault
);
export const getAppendSelectPermissionMerchantsLoading = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionMerchantsLoading }) =>
    appendSelectPermissionMerchantsLoading
);
export const getAppendSelectPermissionMerchantsSuccess = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionMerchantsSuccess }) =>
    appendSelectPermissionMerchantsSuccess
);
export const getAppendSelectPermissionMerchantsFailed = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionMerchantsFailed }) =>
    appendSelectPermissionMerchantsFailed
);

export const getAppendSelectPermissionSubscribersSearch = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionSubscribersSearch }) =>
    appendSelectPermissionSubscribersSearch
);
export const getAppendSelectPermissionSubscribersSort = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionSubscribersSort }) =>
    appendSelectPermissionSubscribersSort
);
export const getAppendSelectPermissionSubscribersKeyBy = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionSubscribersKeyBy }) =>
    appendSelectPermissionSubscribersKeyBy
);
export const getAppendSelectPermissionSubscribersPage = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionSubscribersPage }) =>
    appendSelectPermissionSubscribersPage
);
export const getAppendSelectPermissionSubscribersPerPage = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionSubscribersPerPage }) =>
    appendSelectPermissionSubscribersPerPage
);
export const getAppendSelectPermissionSubscribersIncludes = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionSubscribersIncludes }) =>
    appendSelectPermissionSubscribersIncludes
);
export const getAppendSelectPermissionSubscribersFilterMerchantId =
  createSelector(
    [permissionMerchantSelector],
    ({ appendSelectPermissionSubscribersFilterMerchantId }) =>
      appendSelectPermissionSubscribersFilterMerchantId
  );
export const getAppendSelectPermissionSubscribersFilterPermissionsIds =
  createSelector(
    [permissionMerchantSelector],
    ({ appendSelectPermissionSubscribersFilterPermissionsIds }) =>
      appendSelectPermissionSubscribersFilterPermissionsIds
  );
export const getAppendSelectPermissionSubscribersFilterIsDefault =
  createSelector(
    [permissionMerchantSelector],
    ({ appendSelectPermissionSubscribersFilterIsDefault }) =>
      appendSelectPermissionSubscribersFilterIsDefault
  );
export const getAppendSelectPermissionSubscribersLoading = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionSubscribersLoading }) =>
    appendSelectPermissionSubscribersLoading
);
export const getAppendSelectPermissionSubscribersSuccess = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionSubscribersSuccess }) =>
    appendSelectPermissionSubscribersSuccess
);
export const getAppendSelectPermissionSubscribersFailed = createSelector(
  [permissionMerchantSelector],
  ({ appendSelectPermissionSubscribersFailed }) =>
    appendSelectPermissionSubscribersFailed
);

export const getFetchPermissionLoading = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionLoading }) => fetchPermissionLoading
);
export const getFetchPermissionSuccess = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionSuccess }) => fetchPermissionSuccess
);
export const getFetchPermissionFailed = createSelector(
  [permissionMerchantSelector],
  ({ fetchPermissionFailed }) => fetchPermissionFailed
);

export const getCreatePermissionLoading = createSelector(
  [permissionMerchantSelector],
  ({ createPermissionLoading }) => createPermissionLoading
);
export const getCreatePermissionSuccess = createSelector(
  [permissionMerchantSelector],
  ({ createPermissionSuccess }) => createPermissionSuccess
);
export const getCreatePermissionFailed = createSelector(
  [permissionMerchantSelector],
  ({ createPermissionFailed }) => createPermissionFailed
);

export const getUpdatePermissionLoading = createSelector(
  [permissionMerchantSelector],
  ({ updatePermissionLoading }) => updatePermissionLoading
);
export const getUpdatePermissionSuccess = createSelector(
  [permissionMerchantSelector],
  ({ updatePermissionSuccess }) => updatePermissionSuccess
);
export const getUpdatePermissionFailed = createSelector(
  [permissionMerchantSelector],
  ({ updatePermissionFailed }) => updatePermissionFailed
);

export const getDeletePermissionLoading = createSelector(
  [permissionMerchantSelector],
  ({ deletePermissionLoading }) => deletePermissionLoading
);
export const getDeletePermissionSuccess = createSelector(
  [permissionMerchantSelector],
  ({ deletePermissionSuccess }) => deletePermissionSuccess
);
export const getDeletePermissionFailed = createSelector(
  [permissionMerchantSelector],
  ({ deletePermissionFailed }) => deletePermissionFailed
);

export const getIsFetchPermissionMerchantsHitted = createSelector(
  [permissionMerchantSelector],
  ({ isFetchPermissionMerchantsHitted }) => isFetchPermissionMerchantsHitted
);
export const getIsFetchPermissionSubscribersHitted = createSelector(
  [permissionMerchantSelector],
  ({ isFetchPermissionSubscribersHitted }) => isFetchPermissionSubscribersHitted
);
export const getIsFetchSelectPermissionMerchantsHitted = createSelector(
  [permissionMerchantSelector],
  ({ isFetchSelectPermissionMerchantsHitted }) =>
    isFetchSelectPermissionMerchantsHitted
);
export const getIsFetchSelectPermissionSubscribersHitted = createSelector(
  [permissionMerchantSelector],
  ({ isFetchSelectPermissionSubscribersHitted }) =>
    isFetchSelectPermissionSubscribersHitted
);
export const getIsAppendSelectPermissionMerchantsHitted = createSelector(
  [permissionMerchantSelector],
  ({ isAppendSelectPermissionMerchantsHitted }) =>
    isAppendSelectPermissionMerchantsHitted
);
export const getIsAppendSelectPermissionSubscribersHitted = createSelector(
  [permissionMerchantSelector],
  ({ isAppendSelectPermissionSubscribersHitted }) =>
    isAppendSelectPermissionSubscribersHitted
);
export const getIsFetchPermissionHitted = createSelector(
  [permissionMerchantSelector],
  ({ isFetchPermissionHitted }) => isFetchPermissionHitted
);
export const getIsCreatePermissionHitted = createSelector(
  [permissionMerchantSelector],
  ({ isCreatePermissionHitted }) => isCreatePermissionHitted
);
export const getIsUpdatePermissionHitted = createSelector(
  [permissionMerchantSelector],
  ({ isUpdatePermissionHitted }) => isUpdatePermissionHitted
);
export const getIsDeletePermissionHitted = createSelector(
  [permissionMerchantSelector],
  ({ isDeletePermissionHitted }) => isDeletePermissionHitted
);
