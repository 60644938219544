import SELECT_CASHIER_ACTION_TYPES from "./select-cashier.type";

const SELECT_CASHIER_INITIAL_STATE = {
  cashiers: {},
  searchCashiers: {},

  isCashiersHasMore: true,
  isSearchCashiersHasMore: true,

  fetchCashiersParams: {},
  fetchCashiersLoading: false,
  fetchCashiersSuccess: null,
  fetchCashiersFailed: null,

  searchCashiersParams: {},
  searchCashiersLoading: false,
  searchCashiersSuccess: null,
  searchCashiersFailed: null,

  appendCashiersParams: {},
  appendCashiersLoading: false,
  appendCashiersSuccess: null,
  appendCashiersFailed: null,

  isFetchCashiersHitted: false,
  isSearchCashiersHitted: false,
  isAppendCashiersHitted: false,
};

export const selectCashierReducer = (
  state = SELECT_CASHIER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_CASHIER_ACTION_TYPES.SET_CASHIERS:
      return { ...state, cashiers: payload };
    case SELECT_CASHIER_ACTION_TYPES.SET_SEARCH_CASHIERS:
      return { ...state, searchCashiers: payload };

    case SELECT_CASHIER_ACTION_TYPES.SET_IS_CASHIERS_HAS_MORE:
      return { ...state, isCashiersHasMore: payload };
    case SELECT_CASHIER_ACTION_TYPES.SET_IS_SEARCH_CASHIERS_HAS_MORE:
      return { ...state, isSearchCashiersHasMore: payload };

    case SELECT_CASHIER_ACTION_TYPES.SET_FETCH_CASHIERS_PARAMS:
      return { ...state, fetchCashiersParams: payload };
    case SELECT_CASHIER_ACTION_TYPES.SET_FETCH_CASHIERS_LOADING:
      return { ...state, fetchCashiersLoading: payload };
    case SELECT_CASHIER_ACTION_TYPES.SET_FETCH_CASHIERS_SUCCESS:
      return { ...state, fetchCashiersSuccess: payload };
    case SELECT_CASHIER_ACTION_TYPES.SET_FETCH_CASHIERS_FAILED:
      return { ...state, fetchCashiersFailed: payload };

    case SELECT_CASHIER_ACTION_TYPES.SET_SEARCH_CASHIERS_PARAMS:
      return { ...state, searchCashiersParams: payload };
    case SELECT_CASHIER_ACTION_TYPES.SET_SEARCH_CASHIERS_LOADING:
      return { ...state, searchCashiersLoading: payload };
    case SELECT_CASHIER_ACTION_TYPES.SET_SEARCH_CASHIERS_SUCCESS:
      return { ...state, searchCashiersSuccess: payload };
    case SELECT_CASHIER_ACTION_TYPES.SET_SEARCH_CASHIERS_FAILED:
      return { ...state, searchCashiersFailed: payload };

    case SELECT_CASHIER_ACTION_TYPES.SET_APPEND_CASHIERS_PARAMS:
      return { ...state, appendCashiersParams: payload };
    case SELECT_CASHIER_ACTION_TYPES.SET_APPEND_CASHIERS_LOADING:
      return { ...state, appendCashiersLoading: payload };
    case SELECT_CASHIER_ACTION_TYPES.SET_APPEND_CASHIERS_SUCCESS:
      return { ...state, appendCashiersSuccess: payload };
    case SELECT_CASHIER_ACTION_TYPES.SET_APPEND_CASHIERS_FAILED:
      return { ...state, appendCashiersFailed: payload };

    case SELECT_CASHIER_ACTION_TYPES.SET_IS_FETCH_CASHIERS_HITTED:
      return { ...state, isFetchCashiersHitted: payload };
    case SELECT_CASHIER_ACTION_TYPES.SET_IS_SEARCH_CASHIERS_HITTED:
      return { ...state, isSearchCashiersHitted: payload };
    case SELECT_CASHIER_ACTION_TYPES.SET_IS_APPEND_CASHIERS_HITTED:
      return { ...state, isAppendCashiersHitted: payload };

    case SELECT_CASHIER_ACTION_TYPES.APPEND_CASHIERS:
      return {
        ...state,
        cashiers: { ...state.cashiers, ...payload },
      };
    case SELECT_CASHIER_ACTION_TYPES.APPEND_SEARCH_CASHIERS:
      return {
        ...state,
        searchCashiers: { ...state.searchCashiers, ...payload },
      };

    case SELECT_CASHIER_ACTION_TYPES.RESET_SELECT_CASHIER_REDUCER:
      return SELECT_CASHIER_INITIAL_STATE;
    default:
      return state;
  }
};
