import {
  ORDER_SERVICE_TYPES,
  ORDER_SOURCES,
  ORDER_TABLE_COLUMNS,
} from "../../constants/order.constant";

import ORDER_ACTION_TYPES from "./order.type";

export const ORDER_INITIAL_STATE = {
  orderTableColumns: {
    [ORDER_TABLE_COLUMNS.NUMBER]: true,
    [ORDER_TABLE_COLUMNS.CUSTOMER]: true,
    [ORDER_TABLE_COLUMNS.SOURCE]: true,
    [ORDER_TABLE_COLUMNS.TYPE]: true,
    [ORDER_TABLE_COLUMNS.TOTAL]: true,
    [ORDER_TABLE_COLUMNS.STATUS]: true,
  },
  orderSources: [
    {
      label: "POS App",
      value: ORDER_SOURCES.ADMIN_APP,
    },
    {
      label: "POS Web",
      value: ORDER_SOURCES.ADMIN_WEB,
    },
  ],
  orderServiceTypes: [
    {
      label: "Dining",
      value: ORDER_SERVICE_TYPES.DINING,
    },
    {
      label: "Take Away",
      value: ORDER_SERVICE_TYPES.TAKE_AWAY,
    },
    {
      label: "Drive Through",
      value: ORDER_SERVICE_TYPES.DRIVE_THROUGH,
    },
    {
      label: "Delivery",
      value: ORDER_SERVICE_TYPES.DELIVERY,
    },
  ],
  orderTab: null,

  orders: [],
  waitingOrders: [],
  processOrders: [],
  completedOrders: [],
  reportOrders: [],
  cashierOrders: [],
  customerOrders: [],
  latestOrders: [],
  tableOrders: [],

  order: null,
  customerOrder: null,
  createOrder: null,
  updateOrder: null,
  calculateOrder: null,
  checkoutOrder: null,
  calculateCheckoutOrder: null,
  returnOrder: null,
  calculateReturnOrder: null,

  isOrdersHasMore: true,
  isWaitingOrdersHasMore: true,
  isProcessOrdersHasMore: true,
  isCompletedOrdersHasMore: true,
  isReportOrdersHasMore: true,
  isCashierOrdersHasMore: true,
  isCustomerOrdersHasMore: true,
  isLatestOrdersHasMore: true,

  fetchOrdersSearch: null,
  fetchOrdersSort: null,
  fetchOrdersPage: 1,
  fetchOrdersPerPage: null,
  fetchOrdersIncludes: null,
  fetchOrdersFilterPeriodType: null,
  fetchOrdersFilterStartAt: null,
  fetchOrdersFilterEndAt: null,
  fetchOrdersFilterTimePeriod: null,
  fetchOrdersFilterCreatedAtBefore: null,
  fetchOrdersFilterCreatedAtAfter: null,
  fetchOrdersFilterMarketId: null,
  fetchOrdersFilterBranchId: null,
  fetchOrdersFilterProductCategoriesIds: null,
  fetchOrdersFilterSources: null,
  fetchOrdersFilterTypes: null,
  fetchOrdersFilterStatuses: null,
  fetchOrdersFilterTableId: null,
  fetchOrdersFilterCashierId: null,
  fetchOrdersLoading: false,
  fetchOrdersSuccess: null,
  fetchOrdersFailed: null,

  fetchWaitingOrdersSearch: null,
  fetchWaitingOrdersSort: null,
  fetchWaitingOrdersPage: 1,
  fetchWaitingOrdersPerPage: null,
  fetchWaitingOrdersIncludes: null,
  fetchWaitingOrdersFilterPeriodType: null,
  fetchWaitingOrdersFilterStartAt: null,
  fetchWaitingOrdersFilterEndAt: null,
  fetchWaitingOrdersFilterTimePeriod: null,
  fetchWaitingOrdersFilterCreatedAtBefore: null,
  fetchWaitingOrdersFilterCreatedAtAfter: null,
  fetchWaitingOrdersFilterMarketId: null,
  fetchWaitingOrdersFilterBranchId: null,
  fetchWaitingOrdersFilterProductCategoriesIds: null,
  fetchWaitingOrdersFilterSources: null,
  fetchWaitingOrdersFilterTypes: null,
  fetchWaitingOrdersFilterTableId: null,
  fetchWaitingOrdersFilterCashierId: null,
  fetchWaitingOrdersLoading: false,
  fetchWaitingOrdersSuccess: null,
  fetchWaitingOrdersFailed: null,

  fetchProcessOrdersSearch: null,
  fetchProcessOrdersSort: null,
  fetchProcessOrdersPage: 1,
  fetchProcessOrdersPerPage: null,
  fetchProcessOrdersIncludes: null,
  fetchProcessOrdersFilterPeriodType: null,
  fetchProcessOrdersFilterStartAt: null,
  fetchProcessOrdersFilterEndAt: null,
  fetchProcessOrdersFilterTimePeriod: null,
  fetchProcessOrdersFilterCreatedAtBefore: null,
  fetchProcessOrdersFilterCreatedAtAfter: null,
  fetchProcessOrdersFilterMarketId: null,
  fetchProcessOrdersFilterBranchId: null,
  fetchProcessOrdersFilterProductCategoriesIds: null,
  fetchProcessOrdersFilterSources: null,
  fetchProcessOrdersFilterTypes: null,
  fetchProcessOrdersFilterTableId: null,
  fetchProcessOrdersFilterCashierId: null,
  fetchProcessOrdersLoading: false,
  fetchProcessOrdersSuccess: null,
  fetchProcessOrdersFailed: null,

  fetchCompletedOrdersSearch: null,
  fetchCompletedOrdersSort: null,
  fetchCompletedOrdersPage: 1,
  fetchCompletedOrdersPerPage: null,
  fetchCompletedOrdersIncludes: null,
  fetchCompletedOrdersFilterPeriodType: null,
  fetchCompletedOrdersFilterStartAt: null,
  fetchCompletedOrdersFilterEndAt: null,
  fetchCompletedOrdersFilterTimePeriod: null,
  fetchCompletedOrdersFilterCreatedAtBefore: null,
  fetchCompletedOrdersFilterCreatedAtAfter: null,
  fetchCompletedOrdersFilterMarketId: null,
  fetchCompletedOrdersFilterBranchId: null,
  fetchCompletedOrdersFilterProductCategoriesIds: null,
  fetchCompletedOrdersFilterSources: null,
  fetchCompletedOrdersFilterTypes: null,
  fetchCompletedOrdersFilterTableId: null,
  fetchCompletedOrdersFilterCashierId: null,
  fetchCompletedOrdersLoading: false,
  fetchCompletedOrdersSuccess: null,
  fetchCompletedOrdersFailed: null,

  fetchReportOrdersSearch: null,
  fetchReportOrdersSort: null,
  fetchReportOrdersPage: 1,
  fetchReportOrdersPerPage: null,
  fetchReportOrdersIncludes: null,
  fetchReportOrdersFilterPeriodType: null,
  fetchReportOrdersFilterStartAt: null,
  fetchReportOrdersFilterEndAt: null,
  fetchReportOrdersFilterTimePeriod: null,
  fetchReportOrdersFilterCreatedAtBefore: null,
  fetchReportOrdersFilterCreatedAtAfter: null,
  fetchReportOrdersFilterMarketId: null,
  fetchReportOrdersFilterBranchId: null,
  fetchReportOrdersFilterPaymentMethodKey: null,
  fetchReportOrdersFilterProductCategoriesIds: null,
  fetchReportOrdersFilterSources: null,
  fetchReportOrdersFilterTypes: null,
  fetchReportOrdersFilterStatuses: null,
  fetchReportOrdersFilterTableId: null,
  fetchReportOrdersFilterCashierId: null,
  fetchReportOrdersLoading: false,
  fetchReportOrdersSuccess: null,
  fetchReportOrdersFailed: null,

  fetchCashierOrdersSearch: null,
  fetchCashierOrdersSort: null,
  fetchCashierOrdersPage: 1,
  fetchCashierOrdersPerPage: null,
  fetchCashierOrdersIncludes: null,
  fetchCashierOrdersFilterPeriodType: null,
  fetchCashierOrdersFilterStartAt: null,
  fetchCashierOrdersFilterEndAt: null,
  fetchCashierOrdersFilterTimePeriod: null,
  fetchCashierOrdersFilterCreatedAtBefore: null,
  fetchCashierOrdersFilterCreatedAtAfter: null,
  fetchCashierOrdersFilterMarketId: null,
  fetchCashierOrdersFilterBranchId: null,
  fetchCashierOrdersFilterPaymentMethodKey: null,
  fetchCashierOrdersFilterProductCategoriesIds: null,
  fetchCashierOrdersFilterSources: null,
  fetchCashierOrdersFilterTypes: null,
  fetchCashierOrdersFilterStatuses: null,
  fetchCashierOrdersFilterTableId: null,
  fetchCashierOrdersFilterCashierId: null,
  fetchCashierOrdersLoading: false,
  fetchCashierOrdersSuccess: null,
  fetchCashierOrdersFailed: null,

  fetchCustomerOrdersSearch: null,
  fetchCustomerOrdersSort: null,
  fetchCustomerOrdersPage: 1,
  fetchCustomerOrdersPerPage: null,
  fetchCustomerOrdersIncludes: null,
  fetchCustomerOrdersFilterPeriodType: null,
  fetchCustomerOrdersFilterStartAt: null,
  fetchCustomerOrdersFilterEndAt: null,
  fetchCustomerOrdersFilterTimePeriod: null,
  fetchCustomerOrdersFilterCreatedAtBefore: null,
  fetchCustomerOrdersFilterCreatedAtAfter: null,
  fetchCustomerOrdersFilterMarketId: null,
  fetchCustomerOrdersFilterBranchId: null,
  fetchCustomerOrdersFilterPaymentMethodKey: null,
  fetchCustomerOrdersFilterCustomerId: null,
  fetchCustomerOrdersFilterProductCategoriesIds: null,
  fetchCustomerOrdersFilterSources: null,
  fetchCustomerOrdersFilterTypes: null,
  fetchCustomerOrdersFilterStatuses: null,
  fetchCustomerOrdersFilterTableId: null,
  fetchCustomerOrdersFilterCashierId: null,
  fetchCustomerOrdersLoading: false,
  fetchCustomerOrdersSuccess: null,
  fetchCustomerOrdersFailed: null,

  fetchLatestOrdersSort: null,
  fetchLatestOrdersPage: 1,
  fetchLatestOrdersPerPage: null,
  fetchLatestOrdersIncludes: null,
  fetchLatestOrdersFilterPeriodType: null,
  fetchLatestOrdersFilterStartAt: null,
  fetchLatestOrdersFilterEndAt: null,
  fetchLatestOrdersFilterTimePeriod: null,
  fetchLatestOrdersFilterCreatedAtBefore: null,
  fetchLatestOrdersFilterCreatedAtAfter: null,
  fetchLatestOrdersFilterMarketId: null,
  fetchLatestOrdersFilterBranchId: null,
  fetchLatestOrdersFilterProductCategoriesIds: null,
  fetchLatestOrdersFilterSources: null,
  fetchLatestOrdersFilterTypes: null,
  fetchLatestOrdersFilterStatuses: null,
  fetchLatestOrdersFilterTableId: null,
  fetchLatestOrdersFilterCashierId: null,
  fetchLatestOrdersLoading: false,
  fetchLatestOrdersSuccess: null,
  fetchLatestOrdersFailed: null,

  fetchOrderLoading: false,
  fetchOrderSuccess: null,
  fetchOrderFailed: null,

  fetchCustomerOrderLoading: false,
  fetchCustomerOrderSuccess: null,
  fetchCustomerOrderFailed: null,

  createOrderLoading: false,
  createOrderSuccess: null,
  createOrderFailed: null,

  updateOrderLoading: false,
  updateOrderSuccess: null,
  updateOrderFailed: null,

  deleteOrderLoading: false,
  deleteOrderSuccess: null,
  deleteOrderFailed: null,

  calculateOrderLoading: false,
  calculateOrderSuccess: null,
  calculateOrderFailed: null,

  checkoutOrderLoading: false,
  checkoutOrderSuccess: null,
  checkoutOrderFailed: null,

  calculateCheckoutOrderLoading: false,
  calculateCheckoutOrderSuccess: null,
  calculateCheckoutOrderFailed: null,

  returnOrderLoading: false,
  returnOrderSuccess: null,
  returnOrderFailed: null,

  calculateReturnOrderLoading: false,
  calculateReturnOrderSuccess: null,
  calculateReturnOrderFailed: null,

  isFetchOrdersHitted: false,
  isFetchWaitingOrdersHitted: false,
  isFetchProcessOrdersHitted: false,
  isFetchCompletedOrdersHitted: false,
  isFetchReportOrdersHitted: false,
  isFetchCashierOrdersHitted: false,
  isFetchCustomerOrdersHitted: false,
  isFetchLatestOrdersHitted: false,
  isFetchOrderHitted: false,
  isFetchCustomerOrderHitted: false,
  isCreateOrderHitted: false,
  isUpdateOrderHitted: false,
  isDeleteOrderHitted: false,
  isCalculateOrderHitted: false,
  isCheckoutOrderHitted: false,
  isCalculateCheckoutOrderHitted: false,
  isReturnOrderHitted: false,
  isCalculateReturnOrderHitted: false,
};

export const orderReducer = (state = ORDER_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDER_ACTION_TYPES.SET_ORDER_TABLE_COLUMNS:
      return { ...state, orderTableColumns: payload };

    case ORDER_ACTION_TYPES.SET_ORDER_TAB:
      return { ...state, orderTab: payload };
    case ORDER_ACTION_TYPES.SET_ORDERS:
      return { ...state, orders: payload };
    case ORDER_ACTION_TYPES.SET_WAITING_ORDERS:
      return { ...state, waitingOrders: payload };
    case ORDER_ACTION_TYPES.SET_PROCESS_ORDERS:
      return { ...state, processOrders: payload };
    case ORDER_ACTION_TYPES.SET_COMPLETED_ORDERS:
      return { ...state, completedOrders: payload };
    case ORDER_ACTION_TYPES.SET_REPORT_ORDERS:
      return { ...state, reportOrders: payload };
    case ORDER_ACTION_TYPES.SET_CASHIER_ORDERS:
      return { ...state, cashierOrders: payload };
    case ORDER_ACTION_TYPES.SET_CUSTOMER_ORDERS:
      return { ...state, customerOrders: payload };
    case ORDER_ACTION_TYPES.SET_LATEST_ORDERS:
      return { ...state, latestOrders: payload };
    case ORDER_ACTION_TYPES.SET_TABLE_ORDERS:
      return { ...state, tableOrders: payload };

    case ORDER_ACTION_TYPES.SET_ORDER:
      return { ...state, order: payload };
    case ORDER_ACTION_TYPES.SET_CUSTOMER_ORDER:
      return { ...state, customerOrder: payload };
    case ORDER_ACTION_TYPES.SET_CREATE_ORDER:
      return { ...state, createOrder: payload };
    case ORDER_ACTION_TYPES.SET_UPDATE_ORDER:
      return { ...state, updateOrder: payload };
    case ORDER_ACTION_TYPES.SET_CALCULATE_ORDER:
      return { ...state, calculateOrder: payload };
    case ORDER_ACTION_TYPES.SET_CHECKOUT_ORDER:
      return { ...state, checkoutOrder: payload };
    case ORDER_ACTION_TYPES.SET_CALCULATE_CHECKOUT_ORDER:
      return { ...state, calculateCheckoutOrder: payload };
    case ORDER_ACTION_TYPES.SET_RETURN_ORDER:
      return { ...state, returnOrder: payload };
    case ORDER_ACTION_TYPES.SET_CALCULATE_RETURN_ORDER:
      return { ...state, calculateReturnOrder: payload };

    case ORDER_ACTION_TYPES.SET_IS_ORDERS_HAS_MORE:
      return { ...state, isOrdersHasMore: payload };
    case ORDER_ACTION_TYPES.SET_IS_WAITING_ORDERS_HAS_MORE:
      return { ...state, isWaitingOrdersHasMore: payload };
    case ORDER_ACTION_TYPES.SET_IS_PROCESS_ORDERS_HAS_MORE:
      return { ...state, isProcessOrdersHasMore: payload };
    case ORDER_ACTION_TYPES.SET_IS_COMPLETED_ORDERS_HAS_MORE:
      return { ...state, isCompletedOrdersHasMore: payload };
    case ORDER_ACTION_TYPES.SET_IS_REPORT_ORDERS_HAS_MORE:
      return { ...state, isReportOrdersHasMore: payload };
    case ORDER_ACTION_TYPES.SET_IS_CASHIER_ORDERS_HAS_MORE:
      return { ...state, isCashierOrdersHasMore: payload };
    case ORDER_ACTION_TYPES.SET_IS_CUSTOMER_ORDERS_HAS_MORE:
      return { ...state, isCustomerOrdersHasMore: payload };
    case ORDER_ACTION_TYPES.SET_IS_LATEST_ORDERS_HAS_MORE:
      return { ...state, isLatestOrdersHasMore: payload };

    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_SEARCH:
      return { ...state, fetchOrdersSearch: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_SORT:
      return { ...state, fetchOrdersSort: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_PAGE:
      return { ...state, fetchOrdersPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_PER_PAGE:
      return { ...state, fetchOrdersPerPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_INCLUDES:
      return { ...state, fetchOrdersIncludes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_PERIOD_TYPE:
      return { ...state, fetchOrdersFilterPeriodType: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_START_AT:
      return { ...state, fetchOrdersFilterStartAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_END_AT:
      return { ...state, fetchOrdersFilterEndAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_TIME_PERIOD:
      return { ...state, fetchOrdersFilterTimePeriod: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchOrdersFilterCreatedAtBefore: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchOrdersFilterCreatedAtAfter: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_MARKET_ID:
      return { ...state, fetchOrdersFilterMarketId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_BRANCH_ID:
      return { ...state, fetchOrdersFilterBranchId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
      return { ...state, fetchOrdersFilterProductCategoriesIds: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_SOURCES:
      return { ...state, fetchOrdersFilterSources: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_TYPES:
      return { ...state, fetchOrdersFilterTypes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_STATUSES:
      return { ...state, fetchOrdersFilterStatuses: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_TABLE_ID:
      return { ...state, fetchOrdersFilterTableId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_CASHIER_ID:
      return { ...state, fetchOrdersFilterCashierId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_LOADING:
      return { ...state, fetchOrdersLoading: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_SUCCESS:
      return { ...state, fetchOrdersSuccess: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FAILED:
      return { ...state, fetchOrdersFailed: payload };

    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_SEARCH:
      return { ...state, fetchWaitingOrdersSearch: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_SORT:
      return { ...state, fetchWaitingOrdersSort: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_PAGE:
      return { ...state, fetchWaitingOrdersPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_PER_PAGE:
      return { ...state, fetchWaitingOrdersPerPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_INCLUDES:
      return { ...state, fetchWaitingOrdersIncludes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_PERIOD_TYPE:
      return { ...state, fetchWaitingOrdersFilterPeriodType: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_START_AT:
      return { ...state, fetchWaitingOrdersFilterStartAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_END_AT:
      return { ...state, fetchWaitingOrdersFilterEndAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_TIME_PERIOD:
      return { ...state, fetchWaitingOrdersFilterTimePeriod: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchWaitingOrdersFilterCreatedAtBefore: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchWaitingOrdersFilterCreatedAtAfter: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_MARKET_ID:
      return { ...state, fetchWaitingOrdersFilterMarketId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_BRANCH_ID:
      return { ...state, fetchWaitingOrdersFilterBranchId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
      return {
        ...state,
        fetchWaitingOrdersFilterProductCategoriesIds: payload,
      };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_SOURCES:
      return { ...state, fetchWaitingOrdersFilterSources: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_TYPES:
      return { ...state, fetchWaitingOrdersFilterTypes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_TABLE_ID:
      return { ...state, fetchWaitingOrdersFilterTableId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_CASHIER_ID:
      return { ...state, fetchWaitingOrdersFilterCashierId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_LOADING:
      return { ...state, fetchWaitingOrdersLoading: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_SUCCESS:
      return { ...state, fetchWaitingOrdersSuccess: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FAILED:
      return { ...state, fetchWaitingOrdersFailed: payload };

    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_SEARCH:
      return { ...state, fetchProcessOrdersSearch: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_SORT:
      return { ...state, fetchProcessOrdersSort: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_PAGE:
      return { ...state, fetchProcessOrdersPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_PER_PAGE:
      return { ...state, fetchProcessOrdersPerPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_INCLUDES:
      return { ...state, fetchProcessOrdersIncludes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_PERIOD_TYPE:
      return { ...state, fetchProcessOrdersFilterPeriodType: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_START_AT:
      return { ...state, fetchProcessOrdersFilterStartAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_END_AT:
      return { ...state, fetchProcessOrdersFilterEndAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_TIME_PERIOD:
      return { ...state, fetchProcessOrdersFilterTimePeriod: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchProcessOrdersFilterCreatedAtBefore: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchProcessOrdersFilterCreatedAtAfter: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_MARKET_ID:
      return { ...state, fetchProcessOrdersFilterMarketId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_BRANCH_ID:
      return { ...state, fetchProcessOrdersFilterBranchId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
      return {
        ...state,
        fetchProcessOrdersFilterProductCategoriesIds: payload,
      };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_SOURCES:
      return { ...state, fetchProcessOrdersFilterSources: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_TYPES:
      return { ...state, fetchProcessOrdersFilterTypes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_TABLE_ID:
      return { ...state, fetchProcessOrdersFilterTableId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_CASHIER_ID:
      return { ...state, fetchProcessOrdersFilterCashierId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_LOADING:
      return { ...state, fetchProcessOrdersLoading: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_SUCCESS:
      return { ...state, fetchProcessOrdersSuccess: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FAILED:
      return { ...state, fetchProcessOrdersFailed: payload };

    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_SEARCH:
      return { ...state, fetchCompletedOrdersSearch: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_SORT:
      return { ...state, fetchCompletedOrdersSort: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_PAGE:
      return { ...state, fetchCompletedOrdersPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_PER_PAGE:
      return { ...state, fetchCompletedOrdersPerPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_INCLUDES:
      return { ...state, fetchCompletedOrdersIncludes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_PERIOD_TYPE:
      return { ...state, fetchCompletedOrdersFilterPeriodType: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_START_AT:
      return { ...state, fetchCompletedOrdersFilterStartAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_END_AT:
      return { ...state, fetchCompletedOrdersFilterEndAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_TIME_PERIOD:
      return { ...state, fetchCompletedOrdersFilterTimePeriod: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchCompletedOrdersFilterCreatedAtBefore: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchCompletedOrdersFilterCreatedAtAfter: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_MARKET_ID:
      return { ...state, fetchCompletedOrdersFilterMarketId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_BRANCH_ID:
      return { ...state, fetchCompletedOrdersFilterBranchId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
      return {
        ...state,
        fetchCompletedOrdersFilterProductCategoriesIds: payload,
      };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_SOURCES:
      return { ...state, fetchCompletedOrdersFilterSources: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_TYPES:
      return { ...state, fetchCompletedOrdersFilterTypes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_TABLE_ID:
      return { ...state, fetchCompletedOrdersFilterTableId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_CASHIER_ID:
      return { ...state, fetchCompletedOrdersFilterCashierId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_LOADING:
      return { ...state, fetchCompletedOrdersLoading: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_SUCCESS:
      return { ...state, fetchCompletedOrdersSuccess: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FAILED:
      return { ...state, fetchCompletedOrdersFailed: payload };

    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_SEARCH:
      return { ...state, fetchReportOrdersSearch: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_SORT:
      return { ...state, fetchReportOrdersSort: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_PAGE:
      return { ...state, fetchReportOrdersPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_PER_PAGE:
      return { ...state, fetchReportOrdersPerPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_INCLUDES:
      return { ...state, fetchReportOrdersIncludes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_PERIOD_TYPE:
      return { ...state, fetchReportOrdersFilterPeriodType: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_START_AT:
      return { ...state, fetchReportOrdersFilterStartAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_END_AT:
      return { ...state, fetchReportOrdersFilterEndAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_TIME_PERIOD:
      return { ...state, fetchReportOrdersFilterTimePeriod: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchReportOrdersFilterCreatedAtBefore: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchReportOrdersFilterCreatedAtAfter: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_MARKET_ID:
      return { ...state, fetchReportOrdersFilterMarketId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_BRANCH_ID:
      return { ...state, fetchReportOrdersFilterBranchId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchReportOrdersFilterPaymentMethodKey: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
      return { ...state, fetchReportOrdersFilterProductCategoriesIds: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_SOURCES:
      return { ...state, fetchReportOrdersFilterSources: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_TYPES:
      return { ...state, fetchReportOrdersFilterTypes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_STATUSES:
      return { ...state, fetchReportOrdersFilterStatuses: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_TABLE_ID:
      return { ...state, fetchReportOrdersFilterTableId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_CASHIER_ID:
      return { ...state, fetchReportOrdersFilterCashierId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_LOADING:
      return { ...state, fetchReportOrdersLoading: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_SUCCESS:
      return { ...state, fetchReportOrdersSuccess: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FAILED:
      return { ...state, fetchReportOrdersFailed: payload };

    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_SEARCH:
      return { ...state, fetchCashierOrdersSearch: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_SORT:
      return { ...state, fetchCashierOrdersSort: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_PAGE:
      return { ...state, fetchCashierOrdersPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_PER_PAGE:
      return { ...state, fetchCashierOrdersPerPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_INCLUDES:
      return { ...state, fetchCashierOrdersIncludes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_PERIOD_TYPE:
      return { ...state, fetchCashierOrdersFilterPeriodType: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_START_AT:
      return { ...state, fetchCashierOrdersFilterStartAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_END_AT:
      return { ...state, fetchCashierOrdersFilterEndAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_TIME_PERIOD:
      return { ...state, fetchCashierOrdersFilterTimePeriod: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchCashierOrdersFilterCreatedAtBefore: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchCashierOrdersFilterCreatedAtAfter: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_MARKET_ID:
      return { ...state, fetchCashierOrdersFilterMarketId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_BRANCH_ID:
      return { ...state, fetchCashierOrdersFilterBranchId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchCashierOrdersFilterPaymentMethodKey: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
      return {
        ...state,
        fetchCashierOrdersFilterProductCategoriesIds: payload,
      };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_SOURCES:
      return { ...state, fetchCashierOrdersFilterSources: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_TYPES:
      return { ...state, fetchCashierOrdersFilterTypes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_STATUSES:
      return { ...state, fetchCashierOrdersFilterStatuses: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_TABLE_ID:
      return { ...state, fetchCashierOrdersFilterTableId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_CASHIER_ID:
      return { ...state, fetchCashierOrdersFilterCashierId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_LOADING:
      return { ...state, fetchCashierOrdersLoading: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_SUCCESS:
      return { ...state, fetchCashierOrdersSuccess: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FAILED:
      return { ...state, fetchCashierOrdersFailed: payload };

    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_SEARCH:
      return { ...state, fetchCustomerOrdersSearch: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_SORT:
      return { ...state, fetchCustomerOrdersSort: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_PAGE:
      return { ...state, fetchCustomerOrdersPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_PER_PAGE:
      return { ...state, fetchCustomerOrdersPerPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_INCLUDES:
      return { ...state, fetchCustomerOrdersIncludes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_PERIOD_TYPE:
      return { ...state, fetchCustomerOrdersFilterPeriodType: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_START_AT:
      return { ...state, fetchCustomerOrdersFilterStartAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_END_AT:
      return { ...state, fetchCustomerOrdersFilterEndAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_TIME_PERIOD:
      return { ...state, fetchCustomerOrdersFilterTimePeriod: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchCustomerOrdersFilterCreatedAtBefore: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchCustomerOrdersFilterCreatedAtAfter: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_MARKET_ID:
      return { ...state, fetchCustomerOrdersFilterMarketId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_BRANCH_ID:
      return { ...state, fetchCustomerOrdersFilterBranchId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchCustomerOrdersFilterPaymentMethodKey: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_CUSTOMER_ID:
      return { ...state, fetchCustomerOrdersFilterCustomerId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
      return {
        ...state,
        fetchCustomerOrdersFilterProductCategoriesIds: payload,
      };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_SOURCES:
      return { ...state, fetchCustomerOrdersFilterSources: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_TYPES:
      return { ...state, fetchCustomerOrdersFilterTypes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_STATUSES:
      return { ...state, fetchCustomerOrdersFilterStatuses: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_TABLE_ID:
      return { ...state, fetchCustomerOrdersFilterTableId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_CASHIER_ID:
      return { ...state, fetchCustomerOrdersFilterCashierId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_LOADING:
      return { ...state, fetchCustomerOrdersLoading: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_SUCCESS:
      return { ...state, fetchCustomerOrdersSuccess: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FAILED:
      return { ...state, fetchCustomerOrdersFailed: payload };

    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_SORT:
      return { ...state, fetchLatestOrdersSort: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_PAGE:
      return { ...state, fetchLatestOrdersPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_PER_PAGE:
      return { ...state, fetchLatestOrdersPerPage: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_INCLUDES:
      return { ...state, fetchLatestOrdersIncludes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_PERIOD_TYPE:
      return { ...state, fetchLatestOrdersFilterPeriodType: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_START_AT:
      return { ...state, fetchLatestOrdersFilterStartAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_END_AT:
      return { ...state, fetchLatestOrdersFilterEndAt: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_TIME_PERIOD:
      return { ...state, fetchLatestOrdersFilterTimePeriod: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchLatestOrdersFilterCreatedAtBefore: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchLatestOrdersFilterCreatedAtAfter: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_MARKET_ID:
      return { ...state, fetchLatestOrdersFilterMarketId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_BRANCH_ID:
      return { ...state, fetchLatestOrdersFilterBranchId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
      return { ...state, fetchLatestOrdersFilterProductCategoriesIds: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_SOURCES:
      return { ...state, fetchLatestOrdersFilterSources: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_TYPES:
      return { ...state, fetchLatestOrdersFilterTypes: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_STATUSES:
      return { ...state, fetchLatestOrdersFilterStatuses: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_TABLE_ID:
      return { ...state, fetchLatestOrdersFilterTableId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_CASHIER_ID:
      return { ...state, fetchLatestOrdersFilterCashierId: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_LOADING:
      return { ...state, fetchLatestOrdersLoading: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_SUCCESS:
      return { ...state, fetchLatestOrdersSuccess: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FAILED:
      return { ...state, fetchLatestOrdersFailed: payload };

    case ORDER_ACTION_TYPES.SET_FETCH_ORDER_LOADING:
      return { ...state, fetchOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDER_SUCCESS:
      return { ...state, fetchOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_ORDER_FAILED:
      return { ...state, fetchOrderFailed: payload };

    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDER_LOADING:
      return { ...state, fetchCustomerOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDER_SUCCESS:
      return { ...state, fetchCustomerOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDER_FAILED:
      return { ...state, fetchCustomerOrderFailed: payload };

    case ORDER_ACTION_TYPES.SET_CREATE_ORDER_LOADING:
      return { ...state, createOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_CREATE_ORDER_SUCCESS:
      return { ...state, createOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_CREATE_ORDER_FAILED:
      return { ...state, createOrderFailed: payload };

    case ORDER_ACTION_TYPES.SET_UPDATE_ORDER_LOADING:
      return { ...state, updateOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_UPDATE_ORDER_SUCCESS:
      return { ...state, updateOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_UPDATE_ORDER_FAILED:
      return { ...state, updateOrderFailed: payload };

    case ORDER_ACTION_TYPES.SET_DELETE_ORDER_LOADING:
      return { ...state, deleteOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_DELETE_ORDER_SUCCESS:
      return { ...state, deleteOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_DELETE_ORDER_FAILED:
      return { ...state, deleteOrderFailed: payload };

    case ORDER_ACTION_TYPES.SET_CALCULATE_ORDER_LOADING:
      return { ...state, calculateOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_CALCULATE_ORDER_SUCCESS:
      return { ...state, calculateOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_CALCULATE_ORDER_FAILED:
      return { ...state, calculateOrderFailed: payload };

    case ORDER_ACTION_TYPES.SET_CHECKOUT_ORDER_LOADING:
      return { ...state, checkoutOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_CHECKOUT_ORDER_SUCCESS:
      return { ...state, checkoutOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_CHECKOUT_ORDER_FAILED:
      return { ...state, checkoutOrderFailed: payload };

    case ORDER_ACTION_TYPES.SET_CALCULATE_CHECKOUT_ORDER_LOADING:
      return { ...state, calculateCheckoutOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_CALCULATE_CHECKOUT_ORDER_SUCCESS:
      return { ...state, calculateCheckoutOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_CALCULATE_CHECKOUT_ORDER_FAILED:
      return { ...state, calculateCheckoutOrderFailed: payload };

    case ORDER_ACTION_TYPES.SET_RETURN_ORDER_LOADING:
      return { ...state, returnOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_RETURN_ORDER_SUCCESS:
      return { ...state, returnOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_RETURN_ORDER_FAILED:
      return { ...state, returnOrderFailed: payload };

    case ORDER_ACTION_TYPES.SET_CALCULATE_RETURN_ORDER_LOADING:
      return { ...state, calculateReturnOrderLoading: payload };
    case ORDER_ACTION_TYPES.SET_CALCULATE_RETURN_ORDER_SUCCESS:
      return { ...state, calculateReturnOrderSuccess: payload };
    case ORDER_ACTION_TYPES.SET_CALCULATE_RETURN_ORDER_FAILED:
      return { ...state, calculateReturnOrderFailed: payload };

    case ORDER_ACTION_TYPES.APPEND_ORDERS:
      return { ...state, orders: [...state.orders, ...payload] };
    case ORDER_ACTION_TYPES.APPEND_WAITING_ORDERS:
      return { ...state, waitingOrders: [...state.waitingOrders, ...payload] };
    case ORDER_ACTION_TYPES.APPEND_PROCESS_ORDERS:
      return { ...state, processOrders: [...state.processOrders, ...payload] };
    case ORDER_ACTION_TYPES.APPEND_COMPLETED_ORDERS:
      return {
        ...state,
        completedOrders: [...state.completedOrders, ...payload],
      };
    case ORDER_ACTION_TYPES.APPEND_REPORT_ORDERS:
      return { ...state, reportOrders: [...state.reportOrders, ...payload] };
    case ORDER_ACTION_TYPES.APPEND_CASHIER_ORDERS:
      return { ...state, cashierOrders: [...state.cashierOrders, ...payload] };
    case ORDER_ACTION_TYPES.APPEND_CUSTOMER_ORDERS:
      return {
        ...state,
        customerOrders: [...state.customerOrders, ...payload],
      };
    case ORDER_ACTION_TYPES.APPEND_LATEST_ORDERS:
      return { ...state, latestOrders: [...state.latestOrders, ...payload] };

    case ORDER_ACTION_TYPES.SET_IS_FETCH_ORDERS_HITTED:
      return { ...state, isFetchOrdersHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_FETCH_WAITING_ORDERS_HITTED:
      return { ...state, isFetchWaitingOrdersHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_FETCH_PROCESS_ORDERS_HITTED:
      return { ...state, isFetchProcessOrdersHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_FETCH_COMPLETED_ORDERS_HITTED:
      return { ...state, isFetchCompletedOrdersHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_FETCH_REPORT_ORDERS_HITTED:
      return { ...state, isFetchReportOrdersHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_FETCH_CASHIER_ORDERS_HITTED:
      return { ...state, isFetchCashierOrdersHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_FETCH_CUSTOMER_ORDERS_HITTED:
      return { ...state, isFetchCustomerOrdersHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_FETCH_LATEST_ORDERS_HITTED:
      return { ...state, isFetchLatestOrdersHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_FETCH_ORDER_HITTED:
      return { ...state, isFetchOrderHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_FETCH_CUSTOMER_ORDER_HITTED:
      return { ...state, isFetchCustomerOrderHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_CREATE_ORDER_HITTED:
      return { ...state, isCreateOrderHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_UPDATE_ORDER_HITTED:
      return { ...state, isUpdateOrderHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_DELETE_ORDER_HITTED:
      return { ...state, isDeleteOrderHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_CALCULATE_ORDER_HITTED:
      return { ...state, isCalculateOrderHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_CHECKOUT_ORDER_HITTED:
      return { ...state, isCheckoutOrderHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_CALCULATE_CHECKOUT_ORDER_HITTED:
      return { ...state, isCalculateCheckoutOrderHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_RETURN_ORDER_HITTED:
      return { ...state, isReturnOrderHitted: payload };
    case ORDER_ACTION_TYPES.SET_IS_CALCULATE_RETURN_ORDER_HITTED:
      return { ...state, isCalculateReturnOrderHitted: payload };

    case ORDER_ACTION_TYPES.RESET_ORDER_REDUCER:
      return ORDER_INITIAL_STATE;
    default:
      return state;
  }
};
