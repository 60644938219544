import apiService from "./api";

export const getProducts = async (parameters) =>
  (await apiService.get("/v2/products", parameters)).data;
export const getProduct = async (productId) =>
  (await apiService.get(`/v2/products/${productId}`)).data;
export const createProduct = async (request) =>
  (await apiService.post("/v2/products", request)).data;
export const updateProduct = async (productId, request) =>
  (await apiService.post(`/v2/products/${productId}`, request)).data;
export const deleteProduct = async (productId) =>
  (await apiService.delete(`/v2/products/${productId}`)).data;

export const multiCreateProducts = async (request) =>
  (await apiService.post("/v2/products/multi", request)).data;
export const multiDeleteProducts = async (request) =>
  (await apiService.delete("/v2/products/multi", request)).data;

export const quickCreateProduct = async (request) =>
  (await apiService.post("/v2/products/quick", request)).data;
export const quickUpdateProduct = async (productId, request) =>
  (await apiService.post(`/v2/products/quick/${productId}`, request)).data;
export const quickMultiCreateProducts = async (request) =>
  (await apiService.post("/v2/products/quick/multi", request)).data;
