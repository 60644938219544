import { takeLatest, put, all, call, select } from "redux-saga/effects";

import {
  createFeature,
  deleteFeature,
  updateFeature,
} from "../../api/feature-subscription.api";

import {
  setFetchGlobalFeatureSubscriptionsPage,
  setFetchGlobalSubscriptionsPage,
} from "../global/global.action";
import {
  getIsFetchGlobalFeatureSubscriptionsHitted,
  getIsFetchGlobalSubscriptionsHitted,
} from "../global/global.selector";
import {
  _getGlobalFeatureSubscriptions,
  _getGlobalSubscriptions,
} from "../global/global.saga";

import FEATURE_SUBSCRIPTION_ACTION_TYPES from "./feature-subscription.type";
import {
  setCreateFeatureFailed,
  setCreateFeatureLoading,
  setCreateFeatureSuccess,
  setDeleteFeatureFailed,
  setDeleteFeatureLoading,
  setDeleteFeatureSuccess,
  setIsCreateFeatureHitted,
  setIsDeleteFeatureHitted,
  setIsUpdateFeatureHitted,
  setUpdateFeatureFailed,
  setUpdateFeatureLoading,
  setUpdateFeatureSuccess,
} from "./feature-subscription.action";

export function* _createFeature({ payload: request }) {
  try {
    yield put(setCreateFeatureLoading(true));

    const {
      meta: { message },
    } = yield call(createFeature, request);

    yield put(setIsCreateFeatureHitted(true));

    const isFetchGlobalFeatureSubscriptionsHitted = yield select(
      getIsFetchGlobalFeatureSubscriptionsHitted
    );
    const isFetchGlobalSubscriptionsHitted = yield select(
      getIsFetchGlobalSubscriptionsHitted
    );

    if (isFetchGlobalFeatureSubscriptionsHitted) {
      yield put(setFetchGlobalFeatureSubscriptionsPage(1));
      yield call(_getGlobalFeatureSubscriptions);
    }
    if (isFetchGlobalSubscriptionsHitted) {
      yield put(setFetchGlobalSubscriptionsPage(1));
      yield call(_getGlobalSubscriptions);
    }

    yield put(setCreateFeatureSuccess(message));
    yield put(setCreateFeatureLoading(false));
  } catch (error) {
    yield put(setCreateFeatureFailed(error));
    yield put(setCreateFeatureLoading(false));
  }
}
export function* _updateFeature({ payload: { featureId, request } }) {
  try {
    yield put(setUpdateFeatureLoading(true));

    const {
      meta: { message },
    } = yield call(updateFeature, featureId, request);

    yield put(setIsUpdateFeatureHitted(true));

    const isFetchGlobalFeatureSubscriptionsHitted = yield select(
      getIsFetchGlobalFeatureSubscriptionsHitted
    );
    const isFetchGlobalSubscriptionsHitted = yield select(
      getIsFetchGlobalSubscriptionsHitted
    );

    if (isFetchGlobalFeatureSubscriptionsHitted) {
      yield put(setFetchGlobalFeatureSubscriptionsPage(1));
      yield call(_getGlobalFeatureSubscriptions);
    }
    if (isFetchGlobalSubscriptionsHitted) {
      yield put(setFetchGlobalSubscriptionsPage(1));
      yield call(_getGlobalSubscriptions);
    }

    yield put(setUpdateFeatureSuccess(message));
    yield put(setUpdateFeatureLoading(false));
  } catch (error) {
    yield put(setUpdateFeatureFailed(error));
    yield put(setUpdateFeatureLoading(false));
  }
}
export function* _deleteFeature({ payload: featureId }) {
  try {
    yield put(setDeleteFeatureLoading(true));

    const {
      meta: { message },
    } = yield call(deleteFeature, featureId);

    yield put(setIsDeleteFeatureHitted(true));

    const isFetchGlobalFeatureSubscriptionsHitted = yield select(
      getIsFetchGlobalFeatureSubscriptionsHitted
    );
    const isFetchGlobalSubscriptionsHitted = yield select(
      getIsFetchGlobalSubscriptionsHitted
    );

    if (isFetchGlobalFeatureSubscriptionsHitted) {
      yield put(setFetchGlobalFeatureSubscriptionsPage(1));
      yield call(_getGlobalFeatureSubscriptions);
    }
    if (isFetchGlobalSubscriptionsHitted) {
      yield put(setFetchGlobalSubscriptionsPage(1));
      yield call(_getGlobalSubscriptions);
    }

    yield put(setDeleteFeatureSuccess(message));
    yield put(setDeleteFeatureLoading(false));
  } catch (error) {
    yield put(setDeleteFeatureFailed(error));
    yield put(setDeleteFeatureLoading(false));
  }
}

export function* onCreateFeatureStart() {
  yield takeLatest(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.CREATE_FEATURE_START,
    _createFeature
  );
}
export function* onUpdateFeatureStart() {
  yield takeLatest(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.UPDATE_FEATURE_START,
    _updateFeature
  );
}
export function* onDeleteFeatureStart() {
  yield takeLatest(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.DELETE_FEATURE_START,
    _deleteFeature
  );
}

export function* featureSubscriptionSaga() {
  yield all([
    call(onCreateFeatureStart),
    call(onUpdateFeatureStart),
    call(onDeleteFeatureStart),
  ]);
}
