import PRODUCT_ACTION_TYPES from "./product-quick.type";
import { createAction } from "../../utils/store.utils";

export const setCreateProduct = (createProduct) =>
  createAction(PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT, createProduct);
export const setUpdateProduct = (updateProduct) =>
  createAction(PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT, updateProduct);

export const setCreateProductLoading = (createProductLoading) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT_LOADING,
    createProductLoading
  );
export const setCreateProductSuccess = (createProductSuccess) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT_SUCCESS,
    createProductSuccess
  );
export const setCreateProductFailed = (createProductFailed) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT_FAILED,
    createProductFailed
  );

export const setUpdateProductLoading = (updateProductLoading) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT_LOADING,
    updateProductLoading
  );
export const setUpdateProductSuccess = (updateProductSuccess) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT_SUCCESS,
    updateProductSuccess
  );
export const setUpdateProductFailed = (updateProductFailed) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT_FAILED,
    updateProductFailed
  );

export const setCreateProductsLoading = (createProductsLoading) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCTS_LOADING,
    createProductsLoading
  );
export const setCreateProductsSuccess = (createProductsSuccess) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCTS_SUCCESS,
    createProductsSuccess
  );
export const setCreateProductsFailed = (createProductsFailed) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCTS_FAILED,
    createProductsFailed
  );

export const setIsCreateProductHitted = (isCreateProductHitted) =>
  createAction(
    PRODUCT_ACTION_TYPES.IS_CREATE_PRODUCT_HITTED,
    isCreateProductHitted
  );
export const setIsUpdateProductHitted = (isUpdateProductHitted) =>
  createAction(
    PRODUCT_ACTION_TYPES.IS_UPDATE_PRODUCT_HITTED,
    isUpdateProductHitted
  );
export const setIsCreateProductsHitted = (isCreateProductsHitted) =>
  createAction(
    PRODUCT_ACTION_TYPES.IS_CREATE_PRODUCTS_HITTED,
    isCreateProductsHitted
  );

export const createProductStart = (request) =>
  createAction(PRODUCT_ACTION_TYPES.CREATE_PRODUCT_START, request);
export const updateProductStart = (productId, request) =>
  createAction(PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_START, {
    productId,
    request,
  });
export const createProductsStart = (request) =>
  createAction(PRODUCT_ACTION_TYPES.CREATE_PRODUCTS_START, request);

export const resetProductReducer = () =>
  createAction(PRODUCT_ACTION_TYPES.RESET_PRODUCT_REDUCER);
