import TOP_UP_ACTION_TYPES from "./top-up.type";
import { createAction } from "../../utils/store.utils";

export const setTopUps = (topUps) => createAction(TOP_UP_ACTION_TYPES.SET_TOP_UPS, topUps);

export const setTopUp = (topUp) => createAction(TOP_UP_ACTION_TYPES.SET_TOP_UP, topUp);

export const setIsTopUpsHasMore = (isTopUpsHasMore) =>
  createAction(TOP_UP_ACTION_TYPES.SET_IS_TOP_UPS_HAS_MORE, isTopUpsHasMore);

export const setFetchTopUpsSearch = (fetchTopUpsSearch) =>
  createAction(TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_SEARCH, fetchTopUpsSearch);

export const setFetchTopUpsPage = (fetchTopUpsPage) =>
  createAction(TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_PAGE, fetchTopUpsPage);

export const setFetchTopUpsPerPage = (fetchTopUpsPerPage) =>
  createAction(TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_PER_PAGE, fetchTopUpsPerPage);

export const setFetchTopUpsIncludes = (fetchTopUpsIncludes) =>
  createAction(TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_INCLUDES, fetchTopUpsIncludes);

export const setFetchTopUpsFilterMarketId = (fetchTopUpsFilterMarketId) =>
  createAction(TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_FILTER_MARKET_ID, fetchTopUpsFilterMarketId);

export const setFetchTopUpsFilterCreatedBy = (fetchTopUpsFilterCreatedBy) =>
  createAction(TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_FILTER_CREATED_BY, fetchTopUpsFilterCreatedBy);

export const setFetchTopUpsLoading = (fetchTopUpsLoading) =>
  createAction(TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_LOADING, fetchTopUpsLoading);

export const setFetchTopUpsSuccess = (fetchTopUpsSuccess) =>
  createAction(TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_SUCCESS, fetchTopUpsSuccess);

export const setFetchTopUpsFailed = (fetchTopUpsFailed) =>
  createAction(TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_FAILED, fetchTopUpsFailed);

export const setFetchTopUpLoading = (fetchTopUpLoading) =>
  createAction(TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UP_LOADING, fetchTopUpLoading);

export const setFetchTopUpSuccess = (fetchTopUpSuccess) =>
  createAction(TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UP_SUCCESS, fetchTopUpSuccess);

export const setFetchTopUpFailed = (fetchTopUpFailed) =>
  createAction(TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UP_FAILED, fetchTopUpFailed);

export const setCreateTopUpLoading = (createTopUpLoading) =>
  createAction(TOP_UP_ACTION_TYPES.SET_CREATE_TOP_UP_LOADING, createTopUpLoading);

export const setCreateTopUpSuccess = (createTopUpSuccess) =>
  createAction(TOP_UP_ACTION_TYPES.SET_CREATE_TOP_UP_SUCCESS, createTopUpSuccess);

export const setCreateTopUpFailed = (createTopUpFailed) =>
  createAction(TOP_UP_ACTION_TYPES.SET_CREATE_TOP_UP_FAILED, createTopUpFailed);

export const setUpdateTopUpLoading = (updateTopUpLoading) =>
  createAction(TOP_UP_ACTION_TYPES.SET_UPDATE_TOP_UP_LOADING, updateTopUpLoading);

export const setUpdateTopUpSuccess = (updateTopUpSuccess) =>
  createAction(TOP_UP_ACTION_TYPES.SET_UPDATE_TOP_UP_SUCCESS, updateTopUpSuccess);

export const setUpdateTopUpFailed = (updateTopUpFailed) =>
  createAction(TOP_UP_ACTION_TYPES.SET_UPDATE_TOP_UP_FAILED, updateTopUpFailed);

export const setDeleteTopUpLoading = (deleteTopUpLoading) =>
  createAction(TOP_UP_ACTION_TYPES.SET_DELETE_TOP_UP_LOADING, deleteTopUpLoading);

export const setDeleteTopUpSuccess = (deleteTopUpSuccess) =>
  createAction(TOP_UP_ACTION_TYPES.SET_DELETE_TOP_UP_SUCCESS, deleteTopUpSuccess);

export const setDeleteTopUpFailed = (deleteTopUpFailed) =>
  createAction(TOP_UP_ACTION_TYPES.SET_DELETE_TOP_UP_FAILED, deleteTopUpFailed);

export const appendTopUps = (topUps) => createAction(TOP_UP_ACTION_TYPES.APPEND_TOP_UPS, topUps);

export const fetchTopUpsStart = () => createAction(TOP_UP_ACTION_TYPES.FETCH_TOP_UPS_START);

export const fetchTopUpStart = (id) => createAction(TOP_UP_ACTION_TYPES.FETCH_TOP_UP_START, id);

export const createTopUpStart = (request) => createAction(TOP_UP_ACTION_TYPES.CREATE_TOP_UP_START, request);

export const updateTopUpStart = (id, request) => createAction(TOP_UP_ACTION_TYPES.UPDATE_TOP_UP_START, { id, request });

export const deleteTopUpStart = (id) => createAction(TOP_UP_ACTION_TYPES.DELETE_TOP_UP_START, id);

export const resetTopUpReducer = () => createAction(TOP_UP_ACTION_TYPES.RESET_TOP_UP_REDUCER);
