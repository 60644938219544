const SETTING_ACTION_TYPES = {
  RESET_SETTING_REDUCER: "setting/RESET_SETTING_REDUCER",

  SET_SETTINGS: "setting/SET_SETTINGS",
  SET_SETTING: "setting/SET_SETTING",

  SET_IS_SETTINGS_HAS_MORE: "setting/SET_IS_SETTINGS_HAS_MORE",

  SET_FETCH_SETTINGS_SEARCH: "setting/SET_FETCH_SETTINGS_SEARCH",
  SET_FETCH_SETTINGS_SORT: "setting/SET_FETCH_SETTINGS_SORT",
  SET_FETCH_SETTINGS_KEY_BY: "setting/SET_FETCH_SETTINGS_KEY_BY",
  SET_FETCH_SETTINGS_PAGE: "setting/SET_FETCH_SETTINGS_PAGE",
  SET_FETCH_SETTINGS_PER_PAGE: "setting/SET_FETCH_SETTINGS_PER_PAGE",
  SET_FETCH_SETTINGS_FILTER_KEYS: "setting/SET_FETCH_SETTINGS_FILTER_KEYS",
  SET_FETCH_SETTINGS_LOADING: "setting/SET_FETCH_SETTINGS_LOADING",
  SET_FETCH_SETTINGS_SUCCESS: "setting/SET_FETCH_SETTINGS_SUCCESS",
  SET_FETCH_SETTINGS_FAILED: "setting/SET_FETCH_SETTINGS_FAILED",

  SET_FETCH_SETTING_LOADING: "setting/SET_FETCH_SETTING_LOADING",
  SET_FETCH_SETTING_SUCCESS: "setting/SET_FETCH_SETTING_SUCCESS",
  SET_FETCH_SETTING_FAILED: "setting/SET_FETCH_SETTING_FAILED",

  SET_CREATE_OR_UPDATE_SETTING_LOADING:
    "setting/SET_CREATE_OR_UPDATE_SETTING_LOADING",
  SET_CREATE_OR_UPDATE_SETTING_SUCCESS:
    "setting/SET_CREATE_OR_UPDATE_SETTING_SUCCESS",
  SET_CREATE_OR_UPDATE_SETTING_FAILED:
    "setting/SET_CREATE_OR_UPDATE_SETTING_FAILED",

  APPEND_SETTINGS: "setting/APPEND_SETTINGS",

  SET_IS_FETCH_SETTINGS_HITTED: "setting/SET_IS_FETCH_SETTINGS_HITTED",
  SET_IS_FETCH_SETTING_HITTED: "setting/SET_IS_FETCH_SETTING_HITTED",
  SET_IS_CREATE_OR_UPDATE_SETTING_HITTED:
    "setting/SET_IS_CREATE_OR_UPDATE_SETTING_HITTED",

  FETCH_SETTINGS_START: "setting/FETCH_SETTINGS_START",
  FETCH_SETTING_START: "setting/FETCH_SETTING_START",
  CREATE_OR_UPDATE_SETTING_START: "setting/CREATE_OR_UPDATE_SETTING_START",
};

export default SETTING_ACTION_TYPES;
