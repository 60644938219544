import PERMISSION_SUBSCRIBER_ACTION_TYPES from "./permission-subscriber.type";

export const PERMISSION_SUBSCRIBER_INITIAL_STATE = {
  permissions: [],
  selectPermissions: [],
  permission: null,

  isPermissionsHasMore: true,
  isSelectPermissionsHasMore: true,

  fetchPermissionsSearch: null,
  fetchPermissionsSort: null,
  fetchPermissionsKeyBy: null,
  fetchPermissionsPage: 1,
  fetchPermissionsPerPage: null,
  fetchPermissionsIncludes: null,
  fetchPermissionsFilterMarketId: null,
  fetchPermissionsFilterIsDefault: null,
  fetchPermissionsLoading: false,
  fetchPermissionsSuccess: null,
  fetchPermissionsFailed: null,

  fetchSelectPermissionsSearch: null,
  fetchSelectPermissionsSort: null,
  fetchSelectPermissionsKeyBy: null,
  fetchSelectPermissionsPage: 1,
  fetchSelectPermissionsPerPage: null,
  fetchSelectPermissionsIncludes: null,
  fetchSelectPermissionsFilterPermissionsIds: null,
  fetchSelectPermissionsFilterMarketId: null,
  fetchSelectPermissionsFilterIsDefault: null,
  fetchSelectPermissionsLoading: false,
  fetchSelectPermissionsSuccess: null,
  fetchSelectPermissionsFailed: null,

  appendSelectPermissionsSearch: null,
  appendSelectPermissionsSort: null,
  appendSelectPermissionsKeyBy: null,
  appendSelectPermissionsPage: 1,
  appendSelectPermissionsPerPage: null,
  appendSelectPermissionsIncludes: null,
  appendSelectPermissionsFilterPermissionsIds: null,
  appendSelectPermissionsFilterMarketId: null,
  appendSelectPermissionsFilterIsDefault: null,
  appendSelectPermissionsLoading: false,
  appendSelectPermissionsSuccess: null,
  appendSelectPermissionsFailed: null,

  fetchPermissionLoading: false,
  fetchPermissionSuccess: null,
  fetchPermissionFailed: null,

  createPermissionLoading: false,
  createPermissionSuccess: null,
  createPermissionFailed: null,

  updatePermissionLoading: false,
  updatePermissionSuccess: null,
  updatePermissionFailed: null,

  deletePermissionLoading: false,
  deletePermissionSuccess: null,
  deletePermissionFailed: null,

  isFetchPermissionsHitted: false,
  isFetchSelectPermissionsHitted: false,
  isAppendSelectPermissionsHitted: false,
  isFetchPermissionHitted: false,
  isCreatePermissionHitted: false,
  isUpdatePermissionHitted: false,
  isDeletePermissionHitted: false,
};

export const permissionSubscriberReducer = (
  state = PERMISSION_SUBSCRIBER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_PERMISSIONS:
      return { ...state, permissions: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_SELECT_PERMISSIONS:
      return { ...state, selectPermissions: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_PERMISSION:
      return { ...state, permission: payload };

    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_PERMISSIONS_HAS_MORE:
      return { ...state, isPermissionsHasMore: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_SELECT_PERMISSIONS_HAS_MORE:
      return { ...state, isSelectPermissionsHasMore: payload };

    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_SEARCH:
      return { ...state, fetchPermissionsSearch: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_SORT:
      return { ...state, fetchPermissionsSort: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_KEY_BY:
      return { ...state, fetchPermissionsKeyBy: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_PAGE:
      return { ...state, fetchPermissionsPage: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_PER_PAGE:
      return { ...state, fetchPermissionsPerPage: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_INCLUDES:
      return { ...state, fetchPermissionsIncludes: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_FILTER_MARKET_ID:
      return { ...state, fetchPermissionsFilterMarketId: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_FILTER_IS_DEFAULT:
      return { ...state, fetchPermissionsFilterIsDefault: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_LOADING:
      return { ...state, fetchPermissionsLoading: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_SUCCESS:
      return { ...state, fetchPermissionsSuccess: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSIONS_FAILED:
      return { ...state, fetchPermissionsFailed: payload };

    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_SEARCH:
      return { ...state, fetchSelectPermissionsSearch: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_SORT:
      return { ...state, fetchSelectPermissionsSort: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_KEY_BY:
      return { ...state, fetchSelectPermissionsKeyBy: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_PAGE:
      return { ...state, fetchSelectPermissionsPage: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_PER_PAGE:
      return { ...state, fetchSelectPermissionsPerPage: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_INCLUDES:
      return { ...state, fetchSelectPermissionsIncludes: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_FILTER_PERMISSIONS_IDS:
      return {
        ...state,
        fetchSelectPermissionsFilterPermissionsIds: payload,
      };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_FILTER_MARKET_ID:
      return {
        ...state,
        fetchSelectPermissionsFilterMarketId: payload,
      };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_FILTER_IS_DEFAULT:
      return {
        ...state,
        fetchSelectPermissionsFilterIsDefault: payload,
      };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_LOADING:
      return { ...state, fetchSelectPermissionsLoading: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_SUCCESS:
      return { ...state, fetchSelectPermissionsSuccess: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_FAILED:
      return { ...state, fetchSelectPermissionsFailed: payload };

    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_SEARCH:
      return { ...state, appendSelectPermissionsSearch: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_SORT:
      return { ...state, appendSelectPermissionsSort: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_KEY_BY:
      return { ...state, appendSelectPermissionsKeyBy: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_PAGE:
      return { ...state, appendSelectPermissionsPage: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_PER_PAGE:
      return { ...state, appendSelectPermissionsPerPage: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_INCLUDES:
      return { ...state, appendSelectPermissionsIncludes: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_FILTER_PERMISSIONS_IDS:
      return {
        ...state,
        appendSelectPermissionsFilterPermissionsIds: payload,
      };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_FILTER_MARKET_ID:
      return {
        ...state,
        appendSelectPermissionsFilterMarketId: payload,
      };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_FILTER_IS_DEFAULT:
      return {
        ...state,
        appendSelectPermissionsFilterIsDefault: payload,
      };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_LOADING:
      return { ...state, appendSelectPermissionsLoading: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_SUCCESS:
      return { ...state, appendSelectPermissionsSuccess: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SELECT_PERMISSIONS_FAILED:
      return { ...state, appendSelectPermissionsFailed: payload };

    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSION_LOADING:
      return { ...state, fetchPermissionLoading: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSION_SUCCESS:
      return { ...state, fetchPermissionSuccess: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_FETCH_PERMISSION_FAILED:
      return { ...state, fetchPermissionFailed: payload };

    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_CREATE_PERMISSION_LOADING:
      return { ...state, createPermissionLoading: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_CREATE_PERMISSION_SUCCESS:
      return { ...state, createPermissionSuccess: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_CREATE_PERMISSION_FAILED:
      return { ...state, createPermissionFailed: payload };

    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_UPDATE_PERMISSION_LOADING:
      return { ...state, updatePermissionLoading: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_UPDATE_PERMISSION_SUCCESS:
      return { ...state, updatePermissionSuccess: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_UPDATE_PERMISSION_FAILED:
      return { ...state, updatePermissionFailed: payload };

    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_DELETE_PERMISSION_LOADING:
      return { ...state, deletePermissionLoading: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_DELETE_PERMISSION_SUCCESS:
      return { ...state, deletePermissionSuccess: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_DELETE_PERMISSION_FAILED:
      return { ...state, deletePermissionFailed: payload };

    case PERMISSION_SUBSCRIBER_ACTION_TYPES.APPEND_PERMISSIONS:
      return {
        ...state,
        permissions: [...state.permissions, ...payload],
      };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.APPEND_SELECT_PERMISSIONS:
      return {
        ...state,
        selectPermissions: {
          ...state.selectPermissions,
          ...payload,
        },
      };

    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_PERMISSIONS_HITTED:
      return { ...state, isFetchPermissionsHitted: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_SELECT_PERMISSIONS_HITTED:
      return { ...state, isFetchSelectPermissionsHitted: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_APPEND_SELECT_PERMISSIONS_HITTED:
      return { ...state, isAppendSelectPermissionsHitted: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_PERMISSION_HITTED:
      return { ...state, isFetchPermissionHitted: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_CREATE_PERMISSION_HITTED:
      return { ...state, isCreatePermissionHitted: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_UPDATE_PERMISSION_HITTED:
      return { ...state, isUpdatePermissionHitted: payload };
    case PERMISSION_SUBSCRIBER_ACTION_TYPES.SET_IS_DELETE_PERMISSION_HITTED:
      return { ...state, isDeletePermissionHitted: payload };

    case PERMISSION_SUBSCRIBER_ACTION_TYPES.RESET_PERMISSION_SUBSCRIBER_REDUCER:
      return PERMISSION_SUBSCRIBER_INITIAL_STATE;
    default:
      return state;
  }
};
