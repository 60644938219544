import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PRODUCT_CATEGORY_SORT_ACTION_TYPES from "./product-category-sort.type";

import {
  setFetchOrderProductCategoriesPage,
  setFetchProductProductCategoriesPage,
  setFetchSelectProductCategoriesPage,
} from "../product-category/product-category.action";
import {
  getIsFetchOrderProductCategoriesHitted,
  getIsFetchProductProductCategoriesHitted,
  getIsFetchSelectProductCategoriesHitted,
} from "../product-category/product-category.selector";
import {
  _getOrderProductCategories,
  _getProductProductCategories,
  _getSelectProductCategories,
} from "../product-category/product-category.saga";
import {
  setCreateOrUpdateProductCategorySortFailed,
  setCreateOrUpdateProductCategorySortLoading,
  setCreateOrUpdateProductCategorySortSuccess,
} from "./product-category-sort.action";

import { createOrUpdateProductCategorySort } from "../../api/product-category-sort.api";

export function* _createOrUpdateProductCategorySort({ payload: request }) {
  try {
    yield put(setCreateOrUpdateProductCategorySortLoading(true));

    const {
      meta: { message },
    } = yield call(createOrUpdateProductCategorySort, request);

    const isFetchSelectProductCategoriesHitted = yield select(
      getIsFetchSelectProductCategoriesHitted
    );
    const isFetchProductProductCategoriesHitted = yield select(
      getIsFetchProductProductCategoriesHitted
    );
    const isFetchOrderProductCategoriesHitted = yield select(
      getIsFetchOrderProductCategoriesHitted
    );

    if (isFetchSelectProductCategoriesHitted) {
      yield put(setFetchSelectProductCategoriesPage(1));
      yield call(_getSelectProductCategories);
    }
    if (isFetchProductProductCategoriesHitted) {
      yield put(setFetchProductProductCategoriesPage(1));
      yield call(_getProductProductCategories);
    }
    if (isFetchOrderProductCategoriesHitted) {
      yield put(setFetchOrderProductCategoriesPage(1));
      yield call(_getOrderProductCategories);
    }

    yield put(setCreateOrUpdateProductCategorySortLoading(false));
    yield put(setCreateOrUpdateProductCategorySortSuccess(message));
  } catch (error) {
    yield put(setCreateOrUpdateProductCategorySortFailed(error));
    yield put(setCreateOrUpdateProductCategorySortLoading(false));
  }
}

export function* onCreateOrUpdateProductCategorySortStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_SORT_ACTION_TYPES.CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_START,
    _createOrUpdateProductCategorySort
  );
}

export function* productCategorySortSaga() {
  yield all([call(onCreateOrUpdateProductCategorySortStart)]);
}
