import MERCHANT_ACTION_TYPES from "./merchant.type";
import { createAction } from "../../utils/store.utils";

export const setMerchants = (merchants) =>
  createAction(MERCHANT_ACTION_TYPES.SET_MERCHANTS, merchants);
export const setSelectMerchants = (selectMerchants) =>
  createAction(MERCHANT_ACTION_TYPES.SET_SELECT_MERCHANTS, selectMerchants);
export const setMerchant = (merchant) =>
  createAction(MERCHANT_ACTION_TYPES.SET_MERCHANT, merchant);

export const setIsMerchantsHasMore = (isMerchantsHasMore) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_IS_MERCHANTS_HAS_MORE,
    isMerchantsHasMore
  );
export const setIsSelectMerchantsHasMore = (isSelectMerchantsHasMore) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_IS_SELECT_MERCHANTS_HAS_MORE,
    isSelectMerchantsHasMore
  );

export const setFetchMerchantsSearch = (fetchMerchantsSearch) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_SEARCH,
    fetchMerchantsSearch
  );
export const setFetchMerchantsSort = (fetchMerchantsSort) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_SORT,
    fetchMerchantsSort
  );
export const setFetchMerchantsKeyBy = (fetchMerchantsKeyBy) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_KEY_BY,
    fetchMerchantsKeyBy
  );
export const setFetchMerchantsPage = (fetchMerchantsPage) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_PAGE,
    fetchMerchantsPage
  );
export const setFetchMerchantsPerPage = (fetchMerchantsPerPage) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_PER_PAGE,
    fetchMerchantsPerPage
  );
export const setFetchMerchantsIncludes = (fetchMerchantsIncludes) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_INCLUDES,
    fetchMerchantsIncludes
  );
export const setFetchMerchantsFilterMerchantsIds = (
  fetchMerchantsFilterMerchantsIds
) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_FILTER_MERCHANTS_IDS,
    fetchMerchantsFilterMerchantsIds
  );
export const setFetchMerchantsLoading = (fetchMerchantsLoading) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_LOADING,
    fetchMerchantsLoading
  );
export const setFetchMerchantsSuccess = (fetchMerchantsSuccess) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_SUCCESS,
    fetchMerchantsSuccess
  );
export const setFetchMerchantsFailed = (fetchMerchantsFailed) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_FAILED,
    fetchMerchantsFailed
  );

export const setFetchSelectMerchantsSearch = (fetchSelectMerchantsSearch) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_SEARCH,
    fetchSelectMerchantsSearch
  );
export const setFetchSelectMerchantsSort = (fetchSelectMerchantsSort) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_SORT,
    fetchSelectMerchantsSort
  );
export const setFetchSelectMerchantsKeyBy = (fetchSelectMerchantsKeyBy) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_KEY_BY,
    fetchSelectMerchantsKeyBy
  );
export const setFetchSelectMerchantsPage = (fetchSelectMerchantsPage) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_PAGE,
    fetchSelectMerchantsPage
  );
export const setFetchSelectMerchantsPerPage = (fetchSelectMerchantsPerPage) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_PER_PAGE,
    fetchSelectMerchantsPerPage
  );
export const setFetchSelectMerchantsIncludes = (fetchSelectMerchantsIncludes) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_INCLUDES,
    fetchSelectMerchantsIncludes
  );
export const setFetchSelectMerchantsFilterMerchantsIds = (
  fetchSelectMerchantsFilterMerchantsIds
) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_FILTER_MERCHANTS_IDS,
    fetchSelectMerchantsFilterMerchantsIds
  );
export const setFetchSelectMerchantsLoading = (fetchSelectMerchantsLoading) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_LOADING,
    fetchSelectMerchantsLoading
  );
export const setFetchSelectMerchantsSuccess = (fetchSelectMerchantsSuccess) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_SUCCESS,
    fetchSelectMerchantsSuccess
  );
export const setFetchSelectMerchantsFailed = (fetchSelectMerchantsFailed) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_FAILED,
    fetchSelectMerchantsFailed
  );

export const setAppendSelectMerchantsSearch = (appendSelectMerchantsSearch) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_SEARCH,
    appendSelectMerchantsSearch
  );
export const setAppendSelectMerchantsSort = (appendSelectMerchantsSort) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_SORT,
    appendSelectMerchantsSort
  );
export const setAppendSelectMerchantsKeyBy = (appendSelectMerchantsKeyBy) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_KEY_BY,
    appendSelectMerchantsKeyBy
  );
export const setAppendSelectMerchantsPage = (appendSelectMerchantsPage) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_PAGE,
    appendSelectMerchantsPage
  );
export const setAppendSelectMerchantsPerPage = (appendSelectMerchantsPerPage) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_PER_PAGE,
    appendSelectMerchantsPerPage
  );
export const setAppendSelectMerchantsIncludes = (
  appendSelectMerchantsIncludes
) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_INCLUDES,
    appendSelectMerchantsIncludes
  );
export const setAppendSelectMerchantsFilterMerchantsIds = (
  appendSelectMerchantsFilterMerchantsIds
) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_FILTER_MERCHANTS_IDS,
    appendSelectMerchantsFilterMerchantsIds
  );
export const setAppendSelectMerchantsLoading = (appendSelectMerchantsLoading) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_LOADING,
    appendSelectMerchantsLoading
  );
export const setAppendSelectMerchantsSuccess = (appendSelectMerchantsSuccess) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_SUCCESS,
    appendSelectMerchantsSuccess
  );
export const setAppendSelectMerchantsFailed = (appendSelectMerchantsFailed) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_FAILED,
    appendSelectMerchantsFailed
  );

export const setFetchMerchantLoading = (fetchMerchantLoading) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANT_LOADING,
    fetchMerchantLoading
  );
export const setFetchMerchantSuccess = (fetchMerchantSuccess) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANT_SUCCESS,
    fetchMerchantSuccess
  );
export const setFetchMerchantFailed = (fetchMerchantFailed) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANT_FAILED,
    fetchMerchantFailed
  );

export const setCreateMerchantLoading = (createMerchantLoading) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_CREATE_MERCHANT_LOADING,
    createMerchantLoading
  );
export const setCreateMerchantSuccess = (createMerchantSuccess) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_CREATE_MERCHANT_SUCCESS,
    createMerchantSuccess
  );
export const setCreateMerchantFailed = (createMerchantFailed) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_CREATE_MERCHANT_FAILED,
    createMerchantFailed
  );

export const setUpdateMerchantLoading = (updateMerchantLoading) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_UPDATE_MERCHANT_LOADING,
    updateMerchantLoading
  );
export const setUpdateMerchantSuccess = (updateMerchantSuccess) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_UPDATE_MERCHANT_SUCCESS,
    updateMerchantSuccess
  );
export const setUpdateMerchantFailed = (updateMerchantFailed) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_UPDATE_MERCHANT_FAILED,
    updateMerchantFailed
  );

export const setDeleteMerchantLoading = (deleteMerchantLoading) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_DELETE_MERCHANT_LOADING,
    deleteMerchantLoading
  );
export const setDeleteMerchantSuccess = (deleteMerchantSuccess) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_DELETE_MERCHANT_SUCCESS,
    deleteMerchantSuccess
  );
export const setDeleteMerchantFailed = (deleteMerchantFailed) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_DELETE_MERCHANT_FAILED,
    deleteMerchantFailed
  );

export const appendMerchants = (merchants) =>
  createAction(MERCHANT_ACTION_TYPES.APPEND_MERCHANTS, merchants);
export const appendSelectMerchants = (selectMerchants) =>
  createAction(MERCHANT_ACTION_TYPES.APPEND_SELECT_MERCHANTS, selectMerchants);

export const fetchMerchantsStart = () =>
  createAction(MERCHANT_ACTION_TYPES.FETCH_MERCHANTS_START);
export const fetchSelectMerchantsStart = () =>
  createAction(MERCHANT_ACTION_TYPES.FETCH_SELECT_MERCHANTS_START);
export const appendSelectMerchantsStart = () =>
  createAction(MERCHANT_ACTION_TYPES.APPEND_SELECT_MERCHANTS_START);
export const fetchMerchantStart = (merchantId) =>
  createAction(MERCHANT_ACTION_TYPES.FETCH_MERCHANT_START, merchantId);
export const createMerchantStart = (request) =>
  createAction(MERCHANT_ACTION_TYPES.CREATE_MERCHANT_START, request);
export const updateMerchantStart = (merchantId, request) =>
  createAction(MERCHANT_ACTION_TYPES.UPDATE_MERCHANT_START, {
    merchantId,
    request,
  });
export const deleteMerchantStart = (merchantId) =>
  createAction(MERCHANT_ACTION_TYPES.DELETE_MERCHANT_START, merchantId);

export const setIsFetchMerchantsHitted = (isFetchMerchantsHitted) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_IS_FETCH_MERCHANTS_HITTED,
    isFetchMerchantsHitted
  );
export const setIsFetchSelectMerchantsHitted = (isFetchSelectMerchantsHitted) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_IS_FETCH_SELECT_MERCHANTS_HITTED,
    isFetchSelectMerchantsHitted
  );
export const setIsAppendSelectMerchantsHitted = (
  isAppendSelectMerchantsHitted
) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_IS_APPEND_SELECT_MERCHANTS_HITTED,
    isAppendSelectMerchantsHitted
  );
export const setIsFetchMerchantHitted = (isFetchMerchantHitted) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_IS_FETCH_MERCHANT_HITTED,
    isFetchMerchantHitted
  );
export const setIsCreateMerchantHitted = (isCreateMerchantHitted) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_IS_CREATE_MERCHANT_HITTED,
    isCreateMerchantHitted
  );
export const setIsUpdateMerchantHitted = (isUpdateMerchantHitted) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_IS_UPDATE_MERCHANT_HITTED,
    isUpdateMerchantHitted
  );
export const setIsDeleteMerchantHitted = (isDeleteMerchantHitted) =>
  createAction(
    MERCHANT_ACTION_TYPES.SET_IS_DELETE_MERCHANT_HITTED,
    isDeleteMerchantHitted
  );

export const resetMerchantReducer = () =>
  createAction(MERCHANT_ACTION_TYPES.RESET_MERCHANT_REDUCER);
