import { takeLatest, put, all, call } from "redux-saga/effects";

import WATHQ_ACTION_TYPES from "./wathq.type";

import { setFetchWathqFailed, setFetchWathqLoading, setFetchWathqSuccess, setWathq } from "./wathq.action";

import { getWathq } from "../../api/wathq.api";

export function* _getWathq({ payload: crn }) {
  try {
    yield put(setFetchWathqLoading(true));

    const {
      meta: { message },
      data: wathq,
    } = yield call(getWathq, crn);

    yield put(setWathq(wathq));

    yield put(setFetchWathqSuccess(message));
    yield put(setFetchWathqLoading(false));
  } catch (error) {
    yield put(setWathq(null));

    yield put(setFetchWathqFailed(error));
    yield put(setFetchWathqLoading(false));
  }
}

export function* onFetchWathqStart() {
  yield takeLatest(WATHQ_ACTION_TYPES.FETCH_WATHQ_START, _getWathq);
}

export function* wathqSaga() {
  yield all([call(onFetchWathqStart)]);
}
