import SELECT_INGREDIENT_ACTION_TYPES from "./select-ingredient.type";

const SELECT_INGREDIENT_INITIAL_STATE = {
  ingredients: {},
  searchIngredients: {},

  isIngredientsHasMore: true,
  isSearchIngredientsHasMore: true,

  fetchIngredientsParams: {},
  fetchIngredientsLoading: false,
  fetchIngredientsSuccess: null,
  fetchIngredientsFailed: null,

  searchIngredientsParams: {},
  searchIngredientsLoading: false,
  searchIngredientsSuccess: null,
  searchIngredientsFailed: null,

  appendIngredientsParams: {},
  appendIngredientsLoading: false,
  appendIngredientsSuccess: null,
  appendIngredientsFailed: null,

  isFetchIngredientsHitted: false,
  isSearchIngredientsHitted: false,
  isAppendIngredientsHitted: false,
};

export const selectIngredientReducer = (
  state = SELECT_INGREDIENT_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_INGREDIENT_ACTION_TYPES.SET_INGREDIENTS:
      return { ...state, ingredients: payload };
    case SELECT_INGREDIENT_ACTION_TYPES.SET_SEARCH_INGREDIENTS:
      return { ...state, searchIngredients: payload };

    case SELECT_INGREDIENT_ACTION_TYPES.SET_IS_INGREDIENTS_HAS_MORE:
      return { ...state, isIngredientsHasMore: payload };
    case SELECT_INGREDIENT_ACTION_TYPES.SET_IS_SEARCH_INGREDIENTS_HAS_MORE:
      return { ...state, isSearchIngredientsHasMore: payload };

    case SELECT_INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_PARAMS:
      return { ...state, fetchIngredientsParams: payload };
    case SELECT_INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_LOADING:
      return { ...state, fetchIngredientsLoading: payload };
    case SELECT_INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_SUCCESS:
      return { ...state, fetchIngredientsSuccess: payload };
    case SELECT_INGREDIENT_ACTION_TYPES.SET_FETCH_INGREDIENTS_FAILED:
      return { ...state, fetchIngredientsFailed: payload };

    case SELECT_INGREDIENT_ACTION_TYPES.SET_SEARCH_INGREDIENTS_PARAMS:
      return { ...state, searchIngredientsParams: payload };
    case SELECT_INGREDIENT_ACTION_TYPES.SET_SEARCH_INGREDIENTS_LOADING:
      return { ...state, searchIngredientsLoading: payload };
    case SELECT_INGREDIENT_ACTION_TYPES.SET_SEARCH_INGREDIENTS_SUCCESS:
      return { ...state, searchIngredientsSuccess: payload };
    case SELECT_INGREDIENT_ACTION_TYPES.SET_SEARCH_INGREDIENTS_FAILED:
      return { ...state, searchIngredientsFailed: payload };

    case SELECT_INGREDIENT_ACTION_TYPES.SET_APPEND_INGREDIENTS_PARAMS:
      return { ...state, appendIngredientsParams: payload };
    case SELECT_INGREDIENT_ACTION_TYPES.SET_APPEND_INGREDIENTS_LOADING:
      return { ...state, appendIngredientsLoading: payload };
    case SELECT_INGREDIENT_ACTION_TYPES.SET_APPEND_INGREDIENTS_SUCCESS:
      return { ...state, appendIngredientsSuccess: payload };
    case SELECT_INGREDIENT_ACTION_TYPES.SET_APPEND_INGREDIENTS_FAILED:
      return { ...state, appendIngredientsFailed: payload };

    case SELECT_INGREDIENT_ACTION_TYPES.SET_IS_FETCH_INGREDIENTS_HITTED:
      return { ...state, isFetchIngredientsHitted: payload };
    case SELECT_INGREDIENT_ACTION_TYPES.SET_IS_SEARCH_INGREDIENTS_HITTED:
      return { ...state, isSearchIngredientsHitted: payload };
    case SELECT_INGREDIENT_ACTION_TYPES.SET_IS_APPEND_INGREDIENTS_HITTED:
      return { ...state, isAppendIngredientsHitted: payload };

    case SELECT_INGREDIENT_ACTION_TYPES.APPEND_INGREDIENTS:
      return {
        ...state,
        ingredients: { ...state.ingredients, ...payload },
      };
    case SELECT_INGREDIENT_ACTION_TYPES.APPEND_SEARCH_INGREDIENTS:
      return {
        ...state,
        searchIngredients: { ...state.searchIngredients, ...payload },
      };

    case SELECT_INGREDIENT_ACTION_TYPES.RESET_SELECT_INGREDIENT_REDUCER:
      return SELECT_INGREDIENT_INITIAL_STATE;
    default:
      return state;
  }
};
