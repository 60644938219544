export const PRODUCT_MODIFIER_INCLUDES = {
  INGREDIENTS: "ingredients",
  PRICES: "prices",
};

export const PRODUCT_MODIFIER_TYPES = {
  OPTION: "OPTION",
  OPTION_VARIABLE: "OPTION_VARIABLE",
  ADDITIONAL: "ADDITIONAL",
  ADDITIONAL_VARIABLE: "ADDITIONAL_VARIABLE",
};
