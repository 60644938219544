import ORDER_PRODUCT_ACTION_TYPES from "./order-product.type";

export const ORDER_PRODUCT_INITIAL_STATE = {
  orderProduct: null,

  fetchOrderProductLoading: false,
  fetchOrderProductSuccess: null,
  fetchOrderProductFailed: null,

  updateOrderProductLoading: false,
  updateOrderProductSuccess: null,
  updateOrderProductFailed: null,

  isFetchOrderProductHitted: false,
  isUpdateOrderProductHitted: false,
};

export const orderProductReducer = (state = ORDER_PRODUCT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDER_PRODUCT_ACTION_TYPES.SET_ORDER_PRODUCT:
      return { ...state, orderProduct: payload };

    case ORDER_PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_LOADING:
      return { ...state, fetchOrderProductLoading: payload };
    case ORDER_PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_SUCCESS:
      return { ...state, fetchOrderProductSuccess: payload };
    case ORDER_PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_FAILED:
      return { ...state, fetchOrderProductFailed: payload };

    case ORDER_PRODUCT_ACTION_TYPES.SET_UPDATE_ORDER_PRODUCT_LOADING:
      return { ...state, updateOrderProductLoading: payload };
    case ORDER_PRODUCT_ACTION_TYPES.SET_UPDATE_ORDER_PRODUCT_SUCCESS:
      return { ...state, updateOrderProductSuccess: payload };
    case ORDER_PRODUCT_ACTION_TYPES.SET_UPDATE_ORDER_PRODUCT_FAILED:
      return { ...state, updateOrderProductFailed: payload };

    case ORDER_PRODUCT_ACTION_TYPES.SET_IS_FETCH_ORDER_PRODUCT_HITTED:
      return { ...state, isFetchOrderProductHitted: payload };
    case ORDER_PRODUCT_ACTION_TYPES.SET_IS_UPDATE_ORDER_PRODUCT_HITTED:
      return { ...state, isUpdateOrderProductHitted: payload };

    case ORDER_PRODUCT_ACTION_TYPES.RESET_ORDER_PRODUCT_REDUCER:
      return ORDER_PRODUCT_INITIAL_STATE;
    default:
      return state;
  }
};
