import enJson from "../locales/en.json";
import arJson from "../locales/ar.json";

import { ReactComponent as EnglishSvg } from "../assets/images/flags/flag.svg";
import { ReactComponent as SaudiArabiaSvg } from "../assets/images/flags/saudi-arabia.svg";

export const CURRENCY_SHORT = "SR";

export const GENDERS = {
  MALE: "MALE",
  FEMALE: "FEMALE",
};

export const LANGUAGES = { EN: "en", AR: "ar" };
export const LANGUAGE_JSONS = {
  [LANGUAGES.EN]: enJson,
  [LANGUAGES.AR]: arJson,
};
export const LANGUAGE_DATAS = {
  [LANGUAGES.EN]: {
    code: LANGUAGES.EN,
    flag: <EnglishSvg />,
    name: "English",
  },
  [LANGUAGES.AR]: {
    code: LANGUAGES.AR,
    flag: <SaudiArabiaSvg />,
    name: "العربية",
  },
};

export const SCREEN_BREAKPOINTS = {
  SMALL: 576,
  MEDIUM: 768,
  LARGE: 992,
  EXTRA_LARGE: 1200,
  EXTRA_EXTRA_LARGE: 1400,
};

export const TIME_PERIODS = {
  ALL_TIME: "ALL_TIME",
  CUSTOM: "CUSTOM",

  LAST_DAY: "LAST_DAY",
  LAST_WEEK: "LAST_WEEK",
  LAST_MONTH: "LAST_MONTH",
  LAST_YEAR: "LAST_YEAR",
};
