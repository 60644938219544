const BRANCH_ACTION_TYPES = {
  RESET_BRANCH_REDUCER: "branch/RESET_BRANCH_REDUCER",

  SET_IS_MODAL_AUTO_CLOSE: "branch/SET_IS_MODAL_AUTO_CLOSE",

  SET_BRANCHES: "branch/SET_BRANCHES",
  SET_BRANCH: "branch/SET_BRANCH",

  SET_SUBSCRIPTION_BRANCHES: "branch/SET_SUBSCRIPTION_BRANCHES",
  SET_SUBSCRIPTION_BRANCH: "branch/SET_SUBSCRIPTION_BRANCH",

  SET_IS_BRANCHES_HAS_MORE: "branch/SET_IS_BRANCHES_HAS_MORE",
  SET_IS_SUBSCRIPTION_BRANCHES_HAS_MORE:
    "branch/SET_IS_SUBSCRIPTION_BRANCHES_HAS_MORE",

  SET_FETCH_BRANCHES_SEARCH: "branch/SET_FETCH_BRANCHES_SEARCH",
  SET_FETCH_BRANCHES_PAGE: "branch/SET_FETCH_BRANCHES_PAGE",
  SET_FETCH_BRANCHES_PER_PAGE: "branch/SET_FETCH_BRANCHES_PER_PAGE",
  SET_FETCH_BRANCHES_INCLUDES: "branch/SET_FETCH_BRANCHES_INCLUDES",
  SET_FETCH_BRANCHES_FILTER_MARKET_ID:
    "branch/SET_FETCH_BRANCHES_FILTER_MARKET_ID",
  SET_FETCH_BRANCHES_FILTER_IS_ACTIVE:
    "branch/SET_FETCH_BRANCHES_FILTER_IS_ACTIVE",
  SET_FETCH_BRANCHES_LOADING: "branch/SET_FETCH_BRANCHES_LOADING",
  SET_FETCH_BRANCHES_SUCCESS: "branch/SET_FETCH_BRANCHES_SUCCESS",
  SET_FETCH_BRANCHES_FAILED: "branch/SET_FETCH_BRANCHES_FAILED",

  SET_FETCH_SUBSCRIPTION_BRANCHES_SEARCH:
    "branch/SET_FETCH_SUBSCRIPTION_BRANCHES_SEARCH",
  SET_FETCH_SUBSCRIPTION_BRANCHES_PAGE:
    "branch/SET_FETCH_SUBSCRIPTION_BRANCHES_PAGE",
  SET_FETCH_SUBSCRIPTION_BRANCHES_PER_PAGE:
    "branch/SET_FETCH_SUBSCRIPTION_BRANCHES_PER_PAGE",
  SET_FETCH_SUBSCRIPTION_BRANCHES_INCLUDES:
    "branch/SET_FETCH_SUBSCRIPTION_BRANCHES_INCLUDES",
  SET_FETCH_SUBSCRIPTION_BRANCHES_FILTER_MARKET_ID:
    "branch/SET_FETCH_SUBSCRIPTION_BRANCHES_FILTER_MARKET_ID",
  SET_FETCH_SUBSCRIPTION_BRANCHES_FILTER_IS_ACTIVE:
    "branch/SET_FETCH_SUBSCRIPTION_BRANCHES_FILTER_IS_ACTIVE",
  SET_FETCH_SUBSCRIPTION_BRANCHES_LOADING:
    "branch/SET_FETCH_SUBSCRIPTION_BRANCHES_LOADING",
  SET_FETCH_SUBSCRIPTION_BRANCHES_SUCCESS:
    "branch/SET_FETCH_SUBSCRIPTION_BRANCHES_SUCCESS",
  SET_FETCH_SUBSCRIPTION_BRANCHES_FAILED:
    "branch/SET_FETCH_SUBSCRIPTION_BRANCHES_FAILED",

  SET_FETCH_BRANCH_LOADING: "branch/SET_FETCH_BRANCH_LOADING",
  SET_FETCH_BRANCH_SUCCESS: "branch/SET_FETCH_BRANCH_SUCCESS",
  SET_FETCH_BRANCH_FAILED: "branch/SET_FETCH_BRANCH_FAILED",

  SET_FETCH_SUBSCRIPTION_BRANCH_LOADING:
    "branch/SET_FETCH_SUBSCRIPTION_BRANCH_LOADING",
  SET_FETCH_SUBSCRIPTION_BRANCH_SUCCESS:
    "branch/SET_FETCH_SUBSCRIPTION_BRANCH_SUCCESS",
  SET_FETCH_SUBSCRIPTION_BRANCH_FAILED:
    "branch/SET_FETCH_SUBSCRIPTION_BRANCH_FAILED",

  SET_CREATE_BRANCH_LOADING: "branch/SET_CREATE_BRANCH_LOADING",
  SET_CREATE_BRANCH_SUCCESS: "branch/SET_CREATE_BRANCH_SUCCESS",
  SET_CREATE_BRANCH_FAILED: "branch/SET_CREATE_BRANCH_FAILED",

  SET_UPDATE_BRANCH_LOADING: "branch/SET_UPDATE_BRANCH_LOADING",
  SET_UPDATE_BRANCH_SUCCESS: "branch/SET_UPDATE_BRANCH_SUCCESS",
  SET_UPDATE_BRANCH_FAILED: "branch/SET_UPDATE_BRANCH_FAILED",

  SET_DELETE_BRANCH_LOADING: "branch/SET_DELETE_BRANCH_LOADING",
  SET_DELETE_BRANCH_SUCCESS: "branch/SET_DELETE_BRANCH_SUCCESS",
  SET_DELETE_BRANCH_FAILED: "branch/SET_DELETE_BRANCH_FAILED",

  APPEND_BRANCHES: "branch/APPEND_BRANCHES",
  APPEND_SUBSCRIPTION_BRANCHES: "branch/APPEND_SUBSCRIPTION_BRANCHES",

  SET_IS_FETCH_BRANCHES_HITTED: "branch/SET_IS_FETCH_BRANCHES_HITTED",
  SET_IS_FETCH_SUBSCRIPTION_BRANCHES_HITTED:
    "branch/SET_IS_FETCH_SUBSCRIPTION_BRANCHES_HITTED",
  SET_IS_FETCH_BRANCH_HITTED: "branch/SET_IS_FETCH_BRANCH_HITTED",
  SET_IS_FETCH_SUBSCRIPTION_BRANCH_HITTED:
    "branch/SET_IS_FETCH_SUBSCRIPTION_BRANCH_HITTED",
  SET_IS_CREATE_BRANCH_HITTED: "branch/SET_IS_CREATE_BRANCH_HITTED",
  SET_IS_UPDATE_BRANCH_HITTED: "branch/SET_IS_UPDATE_BRANCH_HITTED",
  SET_IS_DELETE_BRANCH_HITTED: "branch/SET_IS_DELETE_BRANCH_HITTED",

  FETCH_BRANCHES_START: "branch/FETCH_BRANCHES_START",
  FETCH_SUBSCRIPTION_BRANCHES_START: "branch/FETCH_SUBSCRIPTION_BRANCHES_START",
  FETCH_BRANCH_START: "branch/FETCH_BRANCH_START",
  FETCH_SUBSCRIPTION_BRANCH_START: "branch/FETCH_SUBSCRIPTION_BRANCH_START",
  CREATE_BRANCH_START: "branch/CREATE_BRANCH_START",
  UPDATE_BRANCH_START: "branch/UPDATE_BRANCH_START",
  DELETE_BRANCH_START: "branch/DELETE_BRANCH_START",
};

export default BRANCH_ACTION_TYPES;
