import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { saveAs } from "file-saver";

import { getFileNameUrl } from "../../utils/formatter.utils";

import EXPORT_EXCEL_ACTION_TYPES from "./export-excel.type";

import {
  setExportExcelReports,
  setExportExcelReportsFailed,
  setExportExcelReportsLoading,
  setExportExcelReportsSuccess,
  setIsExportExcelReportsHitted,
} from "./export-excel.action";

import { exportExcelReports } from "../../api/export.api";

export function* _exportExcelReports({ payload: request }) {
  try {
    yield put(setExportExcelReportsLoading(true));

    const {
      meta: { message },
      data: excelReports,
    } = yield call(exportExcelReports, request);

    yield put(setIsExportExcelReportsHitted(true));
    yield put(setExportExcelReports(`${excelReports}?time=${Date.now()}`));

    saveAs(excelReports, getFileNameUrl(excelReports));

    yield put(setExportExcelReportsSuccess(message));
    yield put(setExportExcelReportsLoading(false));
  } catch (error) {
    yield put(setExportExcelReportsFailed(error));
    yield put(setExportExcelReportsLoading(false));
  }
}

export function* onExportExcelReportsStart() {
  yield takeLatest(
    EXPORT_EXCEL_ACTION_TYPES.EXPORT_EXCEL_REPORTS_START,
    _exportExcelReports
  );
}

export function* exportExcelSaga() {
  yield all([call(onExportExcelReportsStart)]);
}
