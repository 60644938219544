const RESTOCK_REQUEST_ACTION_TYPES = {
  RESET_RESTOCK_REQUEST_REDUCER:
    "restock-request/RESET_RESTOCK_REQUEST_REDUCER",

  SET_RESTOCK_REQUESTS: "restock-request/SET_RESTOCK_REQUESTS",
  SET_RESTOCK_REQUEST: "restock-request/SET_RESTOCK_REQUEST",

  SET_IS_RESTOCK_REQUESTS_HAS_MORE:
    "restock-request/SET_IS_RESTOCK_REQUESTS_HAS_MORE",

  SET_FETCH_RESTOCK_REQUESTS_SEARCH:
    "restock-request/SET_FETCH_RESTOCK_REQUESTS_SEARCH",
  SET_FETCH_RESTOCK_REQUESTS_SORT:
    "restock-request/SET_FETCH_RESTOCK_REQUESTS_SORT",
  SET_FETCH_RESTOCK_REQUESTS_KEY_BY:
    "restock-request/SET_FETCH_RESTOCK_REQUESTS_KEY_BY",
  SET_FETCH_RESTOCK_REQUESTS_PAGE:
    "restock-request/SET_FETCH_RESTOCK_REQUESTS_PAGE",
  SET_FETCH_RESTOCK_REQUESTS_PER_PAGE:
    "restock-request/SET_FETCH_RESTOCK_REQUESTS_PER_PAGE",
  SET_FETCH_RESTOCK_REQUESTS_INCLUDES:
    "restock-request/SET_FETCH_RESTOCK_REQUESTS_INCLUDES",
  SET_FETCH_RESTOCK_REQUESTS_FILTER_MARKET_ID:
    "restock-request/SET_FETCH_RESTOCK_REQUESTS_FILTER_MARKET_ID",
  SET_FETCH_RESTOCK_REQUESTS_FILTER_BRANCH_ID:
    "restock-request/SET_FETCH_RESTOCK_REQUESTS_FILTER_BRANCH_ID",
  SET_FETCH_RESTOCK_REQUESTS_FILTER_STATUSES:
    "restock-request/SET_FETCH_RESTOCK_REQUESTS_FILTER_STATUSES",
  SET_FETCH_RESTOCK_REQUESTS_LOADING:
    "restock-request/SET_FETCH_RESTOCK_REQUESTS_LOADING",
  SET_FETCH_RESTOCK_REQUESTS_SUCCESS:
    "restock-request/SET_FETCH_RESTOCK_REQUESTS_SUCCESS",
  SET_FETCH_RESTOCK_REQUESTS_FAILED:
    "restock-request/SET_FETCH_RESTOCK_REQUESTS_FAILED",

  SET_FETCH_RESTOCK_REQUEST_LOADING:
    "restock-request/SET_FETCH_RESTOCK_REQUEST_LOADING",
  SET_FETCH_RESTOCK_REQUEST_SUCCESS:
    "restock-request/SET_FETCH_RESTOCK_REQUEST_SUCCESS",
  SET_FETCH_RESTOCK_REQUEST_FAILED:
    "restock-request/SET_FETCH_RESTOCK_REQUEST_FAILED",

  SET_CREATE_RESTOCK_REQUEST_LOADING:
    "restock-request/SET_CREATE_RESTOCK_REQUEST_LOADING",
  SET_CREATE_RESTOCK_REQUEST_SUCCESS:
    "restock-request/SET_CREATE_RESTOCK_REQUEST_SUCCESS",
  SET_CREATE_RESTOCK_REQUEST_FAILED:
    "restock-request/SET_CREATE_RESTOCK_REQUEST_FAILED",

  SET_UPDATE_RESTOCK_REQUEST_LOADING:
    "restock-request/SET_UPDATE_RESTOCK_REQUEST_LOADING",
  SET_UPDATE_RESTOCK_REQUEST_SUCCESS:
    "restock-request/SET_UPDATE_RESTOCK_REQUEST_SUCCESS",
  SET_UPDATE_RESTOCK_REQUEST_FAILED:
    "restock-request/SET_UPDATE_RESTOCK_REQUEST_FAILED",

  SET_DELETE_RESTOCK_REQUEST_LOADING:
    "restock-request/SET_DELETE_RESTOCK_REQUEST_LOADING",
  SET_DELETE_RESTOCK_REQUEST_SUCCESS:
    "restock-request/SET_DELETE_RESTOCK_REQUEST_SUCCESS",
  SET_DELETE_RESTOCK_REQUEST_FAILED:
    "restock-request/SET_DELETE_RESTOCK_REQUEST_FAILED",

  APPEND_RESTOCK_REQUESTS: "restock-request/APPEND_RESTOCK_REQUESTS",

  IS_FETCH_RESTOCK_REQUESTS_HITTED:
    "restock-request/IS_FETCH_RESTOCK_REQUESTS_HITTED",
  IS_FETCH_RESTOCK_REQUEST_HITTED:
    "restock-request/IS_FETCH_RESTOCK_REQUEST_HITTED",
  IS_CREATE_RESTOCK_REQUEST_HITTED:
    "restock-request/IS_CREATE_RESTOCK_REQUEST_HITTED",
  IS_UPDATE_RESTOCK_REQUEST_HITTED:
    "restock-request/IS_UPDATE_RESTOCK_REQUEST_HITTED",
  IS_DELETE_RESTOCK_REQUEST_HITTED:
    "restock-request/IS_DELETE_RESTOCK_REQUEST_HITTED",

  FETCH_RESTOCK_REQUESTS_START: "restock-request/FETCH_RESTOCK_REQUESTS_START",
  FETCH_RESTOCK_REQUEST_START: "restock-request/FETCH_RESTOCK_REQUEST_START",
  CREATE_RESTOCK_REQUEST_START: "restock-request/CREATE_RESTOCK_REQUEST_START",
  UPDATE_RESTOCK_REQUEST_START: "restock-request/UPDATE_RESTOCK_REQUEST_START",
  DELETE_RESTOCK_REQUEST_START: "restock-request/DELETE_RESTOCK_REQUEST_START",
};

export default RESTOCK_REQUEST_ACTION_TYPES;
