const SECTOR_CATEGORY_ACTION_TYPES = {
  RESET_SECTOR_CATEGORY_REDUCER:
    "sector-category/RESET_SECTOR_CATEGORY_REDUCER",

  SET_SECTOR_CATEGORIES: "sector-category/SET_SECTOR_CATEGORIES",
  SET_SECTOR_CATEGORY: "sector-category/SET_SECTOR_CATEGORY",

  SET_IS_SECTOR_CATEGORIES_HAS_MORE:
    "sector-category/SET_IS_SECTOR_CATEGORIES_HAS_MORE",

  SET_FETCH_SECTOR_CATEGORIES_SEARCH:
    "sector-category/SET_FETCH_SECTOR_CATEGORIES_SEARCH",
  SET_FETCH_SECTOR_CATEGORIES_SORT:
    "sector-category/SET_FETCH_SECTOR_CATEGORIES_SORT",
  SET_FETCH_SECTOR_CATEGORIES_KEY_BY:
    "sector-category/SET_FETCH_SECTOR_CATEGORIES_KEY_BY",
  SET_FETCH_SECTOR_CATEGORIES_PAGE:
    "sector-category/SET_FETCH_SECTOR_CATEGORIES_PAGE",
  SET_FETCH_SECTOR_CATEGORIES_PER_PAGE:
    "sector-category/SET_FETCH_SECTOR_CATEGORIES_PER_PAGE",
  SET_FETCH_SECTOR_CATEGORIES_INCLUDES:
    "sector-category/SET_FETCH_SECTOR_CATEGORIES_INCLUDES",
  SET_FETCH_SECTOR_CATEGORIES_LOADING:
    "sector-category/SET_FETCH_SECTOR_CATEGORIES_LOADING",
  SET_FETCH_SECTOR_CATEGORIES_SUCCESS:
    "sector-category/SET_FETCH_SECTOR_CATEGORIES_SUCCESS",
  SET_FETCH_SECTOR_CATEGORIES_FAILED:
    "sector-category/SET_FETCH_SECTOR_CATEGORIES_FAILED",

  SET_FETCH_SECTOR_CATEGORY_LOADING:
    "sector-category/SET_FETCH_SECTOR_CATEGORY_LOADING",
  SET_FETCH_SECTOR_CATEGORY_SUCCESS:
    "sector-category/SET_FETCH_SECTOR_CATEGORY_SUCCESS",
  SET_FETCH_SECTOR_CATEGORY_FAILED:
    "sector-category/SET_FETCH_SECTOR_CATEGORY_FAILED",

  IS_FETCH_SECTOR_CATEGORIES_HITTED:
    "sector-category/IS_FETCH_SECTOR_CATEGORIES_HITTED",
  IS_FETCH_SECTOR_CATEGORY_HITTED:
    "sector-category/IS_FETCH_SECTOR_CATEGORY_HITTED",

  APPEND_SECTOR_CATEGORIES: "sector-category/APPEND_SECTOR_CATEGORIES",

  FETCH_SECTOR_CATEGORIES_START:
    "sector-category/FETCH_SECTOR_CATEGORIES_START",
  FETCH_SECTOR_CATEGORY_START: "sector-category/FETCH_SECTOR_CATEGORY_START",
};

export default SECTOR_CATEGORY_ACTION_TYPES;
