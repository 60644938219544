import apiService from "./api";

export const getTopUps = async (parameters) => (await apiService.get("/top-up", parameters)).data;

export const getTopUp = async (id) => (await apiService.get(`/top-up/${id}`)).data;

export const createTopUp = async (request) => (await apiService.post("/top-up", request)).data;

export const updateTopUp = async (id, request) => (await apiService.post(`/top-up/${id}`, request)).data;

export const deleteTopUp = async (id) => (await apiService.delete(`/top-up/${id}`)).data;
