const PAYMENT_METHOD_ACTION_TYPES = {
  RESET_PAYMENT_METHOD_REDUCER: "payment-method/RESET_PAYMENT_METHOD_REDUCER",

  SET_PAYMENT_METHODS: "payment-method/SET_PAYMENT_METHODS",
  SET_SELECT_PAYMENT_METHODS: "payment-method/SET_SELECT_PAYMENT_METHODS",
  SET_PAYMENT_METHOD: "payment-method/SET_SELECT_PAYMENT_METHOD",

  SET_IS_PAYMENT_METHODS_HAS_MORE: "payment-method/SET_IS_PAYMENT_METHODS_HAS_MORE",
  SET_IS_SELECT_PAYMENT_METHODS_HAS_MORE: "payment-method/SET_IS_SELECT_PAYMENT_METHODS_HAS_MORE",

  SET_FETCH_PAYMENT_METHODS_PAGE: "payment-method/SET_FETCH_PAYMENT_METHODS_PAGE",
  SET_FETCH_PAYMENT_METHODS_PER_PAGE: "payment-method/SET_FETCH_PAYMENT_METHODS_PER_PAGE",
  SET_FETCH_PAYMENT_METHODS_LOADING: "payment-method/SET_FETCH_PAYMENT_METHODS_LOADING",
  SET_FETCH_PAYMENT_METHODS_SUCCESS: "payment-method/SET_FETCH_PAYMENT_METHODS_SUCCESS",
  SET_FETCH_PAYMENT_METHODS_FAILED: "payment-method/SET_FETCH_PAYMENT_METHODS_FAILED",

  SET_FETCH_SELECT_PAYMENT_METHODS_PAGE: "payment-method/SET_FETCH_SELECT_PAYMENT_METHODS_PAGE",
  SET_FETCH_SELECT_PAYMENT_METHODS_PER_PAGE: "payment-method/SET_FETCH_SELECT_PAYMENT_METHODS_PER_PAGE",
  SET_FETCH_SELECT_PAYMENT_METHODS_LOADING: "payment-method/SET_FETCH_SELECT_PAYMENT_METHODS_LOADING",
  SET_FETCH_SELECT_PAYMENT_METHODS_SUCCESS: "payment-method/SET_FETCH_SELECT_PAYMENT_METHODS_SUCCESS",
  SET_FETCH_SELECT_PAYMENT_METHODS_FAILED: "payment-method/SET_FETCH_SELECT_PAYMENT_METHODS_FAILED",

  SET_CREATE_PAYMENT_METHOD_LOADING: "payment-method/SET_CREATE_PAYMENT_METHOD_LOADING",
  SET_CREATE_PAYMENT_METHOD_SUCCESS: "payment-method/SET_CREATE_PAYMENT_METHOD_SUCCESS",
  SET_CREATE_PAYMENT_METHOD_FAILED: "payment-method/SET_CREATE_PAYMENT_METHOD_FAILED",

  SET_UPDATE_PAYMENT_METHOD_LOADING: "payment-method/SET_UPDATE_PAYMENT_METHOD_LOADING",
  SET_UPDATE_PAYMENT_METHOD_SUCCESS: "payment-method/SET_UPDATE_PAYMENT_METHOD_SUCCESS",
  SET_UPDATE_PAYMENT_METHOD_FAILED: "payment-method/SET_UPDATE_PAYMENT_METHOD_FAILED",

  SET_DELETE_PAYMENT_METHOD_LOADING: "payment-method/SET_DELETE_PAYMENT_METHOD_LOADING",
  SET_DELETE_PAYMENT_METHOD_SUCCESS: "payment-method/SET_DELETE_PAYMENT_METHOD_SUCCESS",
  SET_DELETE_PAYMENT_METHOD_FAILED: "payment-method/SET_DELETE_PAYMENT_METHOD_FAILED",

  APPEND_PAYMENT_METHODS: "payment-method/APPEND_PAYMENT_METHODS",
  APPEND_SELECT_PAYMENT_METHODS: "payment-method/APPEND_SELECT_PAYMENT_METHODS",

  SET_IS_FETCH_PAYMENT_METHODS_HITTED: "payment-method/SET_IS_FETCH_PAYMENT_METHODS_HITTED",
  SET_IS_FETCH_SELECT_PAYMENT_METHODS_HITTED: "payment-method/SET_IS_FETCH_SELECT_PAYMENT_METHODS_HITTED",
  SET_IS_CREATE_PAYMENT_METHOD_HITTED: "payment-method/SET_IS_CREATE_PAYMENT_METHOD_HITTED",
  SET_IS_UPDATE_PAYMENT_METHOD_HITTED: "payment-method/SET_IS_UPDATE_PAYMENT_METHOD_HITTED",
  SET_IS_DELETE_PAYMENT_METHOD_HITTED: "payment-method/SET_IS_DELETE_PAYMENT_METHOD_HITTED",

  FETCH_PAYMENT_METHODS_START: "payment-method/FETCH_PAYMENT_METHODS_START",
  FETCH_SELECT_PAYMENT_METHODS_START: "payment-method/FETCH_SELECT_PAYMENT_METHODS_START",
  CREATE_PAYMENT_METHOD_START: "payment-method/CREATE_PAYMENT_METHOD_START",
  UPDATE_PAYMENT_METHOD_START: "payment-method/UPDATE_PAYMENT_METHOD_START",
  DELETE_PAYMENT_METHOD_START: "payment-method/DELETE_PAYMENT_METHOD_START",
};

export default PAYMENT_METHOD_ACTION_TYPES;
