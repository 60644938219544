import { createSelector } from "reselect";

const productCategorySelector = ({ productCategory }) => productCategory;

export const getProductCategories = createSelector(
  [productCategorySelector],
  ({ productCategories }) => productCategories
);
export const getSelectProductCategories = createSelector(
  [productCategorySelector],
  ({ selectProductCategories }) => selectProductCategories
);
export const getProductProductCategories = createSelector(
  [productCategorySelector],
  ({ productProductCategories }) => productProductCategories
);
export const getOrderProductCategories = createSelector(
  [productCategorySelector],
  ({ orderProductCategories }) => orderProductCategories
);
export const getProductCategory = createSelector(
  [productCategorySelector],
  ({ productCategory }) => productCategory
);

export const getIsProductCategoriesHasMore = createSelector(
  [productCategorySelector],
  ({ isProductCategoriesHasMore }) => isProductCategoriesHasMore
);
export const getIsSelectProductCategoriesHasMore = createSelector(
  [productCategorySelector],
  ({ isSelectProductCategoriesHasMore }) => isSelectProductCategoriesHasMore
);
export const getIsProductProductCategoriesHasMore = createSelector(
  [productCategorySelector],
  ({ isProductProductCategoriesHasMore }) => isProductProductCategoriesHasMore
);
export const getIsOrderProductCategoriesHasMore = createSelector(
  [productCategorySelector],
  ({ isOrderProductCategoriesHasMore }) => isOrderProductCategoriesHasMore
);

export const getFetchProductCategoriesSearch = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesSearch }) => fetchProductCategoriesSearch
);
export const getFetchProductCategoriesSort = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesSort }) => fetchProductCategoriesSort
);
export const getFetchProductCategoriesKeyBy = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesKeyBy }) => fetchProductCategoriesKeyBy
);
export const getFetchProductCategoriesPage = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesPage }) => fetchProductCategoriesPage
);
export const getFetchProductCategoriesPerPage = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesPerPage }) => fetchProductCategoriesPerPage
);
export const getFetchProductCategoriesIncludes = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesIncludes }) => fetchProductCategoriesIncludes
);
export const getFetchProductCategoriesFilterSectorId = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesFilterSectorId }) =>
    fetchProductCategoriesFilterSectorId
);
export const getFetchProductCategoriesFilterMarketId = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesFilterMarketId }) =>
    fetchProductCategoriesFilterMarketId
);
export const getFetchProductCategoriesFilterBranchId = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesFilterBranchId }) =>
    fetchProductCategoriesFilterBranchId
);
export const getFetchProductCategoriesLoading = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesLoading }) => fetchProductCategoriesLoading
);
export const getFetchProductCategoriesSuccess = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesSuccess }) => fetchProductCategoriesSuccess
);
export const getFetchProductCategoriesFailed = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoriesFailed }) => fetchProductCategoriesFailed
);

export const getFetchSelectProductCategoriesSearch = createSelector(
  [productCategorySelector],
  ({ fetchSelectProductCategoriesSearch }) => fetchSelectProductCategoriesSearch
);
export const getFetchSelectProductCategoriesSort = createSelector(
  [productCategorySelector],
  ({ fetchSelectProductCategoriesSort }) => fetchSelectProductCategoriesSort
);
export const getFetchSelectProductCategoriesKeyBy = createSelector(
  [productCategorySelector],
  ({ fetchSelectProductCategoriesKeyBy }) => fetchSelectProductCategoriesKeyBy
);
export const getFetchSelectProductCategoriesPage = createSelector(
  [productCategorySelector],
  ({ fetchSelectProductCategoriesPage }) => fetchSelectProductCategoriesPage
);
export const getFetchSelectProductCategoriesPerPage = createSelector(
  [productCategorySelector],
  ({ fetchSelectProductCategoriesPerPage }) =>
    fetchSelectProductCategoriesPerPage
);
export const getFetchSelectProductCategoriesIncludes = createSelector(
  [productCategorySelector],
  ({ fetchSelectProductCategoriesIncludes }) =>
    fetchSelectProductCategoriesIncludes
);
export const getFetchSelectProductCategoriesFilterSectorId = createSelector(
  [productCategorySelector],
  ({ fetchSelectProductCategoriesFilterSectorId }) =>
    fetchSelectProductCategoriesFilterSectorId
);
export const getFetchSelectProductCategoriesFilterMarketId = createSelector(
  [productCategorySelector],
  ({ fetchSelectProductCategoriesFilterMarketId }) =>
    fetchSelectProductCategoriesFilterMarketId
);
export const getFetchSelectProductCategoriesFilterBranchId = createSelector(
  [productCategorySelector],
  ({ fetchSelectProductCategoriesFilterBranchId }) =>
    fetchSelectProductCategoriesFilterBranchId
);
export const getFetchSelectProductCategoriesFilterProductCategoriesIds =
  createSelector(
    [productCategorySelector],
    ({ fetchSelectProductCategoriesFilterProductCategoriesIds }) =>
      fetchSelectProductCategoriesFilterProductCategoriesIds
  );
export const getFetchSelectProductCategoriesLoading = createSelector(
  [productCategorySelector],
  ({ fetchSelectProductCategoriesLoading }) =>
    fetchSelectProductCategoriesLoading
);
export const getFetchSelectProductCategoriesSuccess = createSelector(
  [productCategorySelector],
  ({ fetchSelectProductCategoriesSuccess }) =>
    fetchSelectProductCategoriesSuccess
);
export const getFetchSelectProductCategoriesFailed = createSelector(
  [productCategorySelector],
  ({ fetchSelectProductCategoriesFailed }) => fetchSelectProductCategoriesFailed
);

export const getFetchProductProductCategoriesSearch = createSelector(
  [productCategorySelector],
  ({ fetchProductProductCategoriesSearch }) =>
    fetchProductProductCategoriesSearch
);
export const getFetchProductProductCategoriesSort = createSelector(
  [productCategorySelector],
  ({ fetchProductProductCategoriesSort }) => fetchProductProductCategoriesSort
);
export const getFetchProductProductCategoriesKeyBy = createSelector(
  [productCategorySelector],
  ({ fetchProductProductCategoriesKeyBy }) => fetchProductProductCategoriesKeyBy
);
export const getFetchProductProductCategoriesPage = createSelector(
  [productCategorySelector],
  ({ fetchProductProductCategoriesPage }) => fetchProductProductCategoriesPage
);
export const getFetchProductProductCategoriesPerPage = createSelector(
  [productCategorySelector],
  ({ fetchProductProductCategoriesPerPage }) =>
    fetchProductProductCategoriesPerPage
);
export const getFetchProductProductCategoriesIncludes = createSelector(
  [productCategorySelector],
  ({ fetchProductProductCategoriesIncludes }) =>
    fetchProductProductCategoriesIncludes
);
export const getFetchProductProductCategoriesFilterSectorId = createSelector(
  [productCategorySelector],
  ({ fetchProductProductCategoriesFilterSectorId }) =>
    fetchProductProductCategoriesFilterSectorId
);
export const getFetchProductProductCategoriesFilterMarketId = createSelector(
  [productCategorySelector],
  ({ fetchProductProductCategoriesFilterMarketId }) =>
    fetchProductProductCategoriesFilterMarketId
);
export const getFetchProductProductCategoriesFilterBranchId = createSelector(
  [productCategorySelector],
  ({ fetchProductProductCategoriesFilterBranchId }) =>
    fetchProductProductCategoriesFilterBranchId
);
export const getFetchProductProductCategoriesLoading = createSelector(
  [productCategorySelector],
  ({ fetchProductProductCategoriesLoading }) =>
    fetchProductProductCategoriesLoading
);
export const getFetchProductProductCategoriesSuccess = createSelector(
  [productCategorySelector],
  ({ fetchProductProductCategoriesSuccess }) =>
    fetchProductProductCategoriesSuccess
);
export const getFetchProductProductCategoriesFailed = createSelector(
  [productCategorySelector],
  ({ fetchProductProductCategoriesFailed }) =>
    fetchProductProductCategoriesFailed
);

export const getFetchOrderProductCategoriesSearch = createSelector(
  [productCategorySelector],
  ({ fetchOrderProductCategoriesSearch }) => fetchOrderProductCategoriesSearch
);
export const getFetchOrderProductCategoriesSort = createSelector(
  [productCategorySelector],
  ({ fetchOrderProductCategoriesSort }) => fetchOrderProductCategoriesSort
);
export const getFetchOrderProductCategoriesKeyBy = createSelector(
  [productCategorySelector],
  ({ fetchOrderProductCategoriesKeyBy }) => fetchOrderProductCategoriesKeyBy
);
export const getFetchOrderProductCategoriesPage = createSelector(
  [productCategorySelector],
  ({ fetchOrderProductCategoriesPage }) => fetchOrderProductCategoriesPage
);
export const getFetchOrderProductCategoriesPerPage = createSelector(
  [productCategorySelector],
  ({ fetchOrderProductCategoriesPerPage }) => fetchOrderProductCategoriesPerPage
);
export const getFetchOrderProductCategoriesIncludes = createSelector(
  [productCategorySelector],
  ({ fetchOrderProductCategoriesIncludes }) =>
    fetchOrderProductCategoriesIncludes
);
export const getFetchOrderProductCategoriesFilterSectorId = createSelector(
  [productCategorySelector],
  ({ fetchOrderProductCategoriesFilterSectorId }) =>
    fetchOrderProductCategoriesFilterSectorId
);
export const getFetchOrderProductCategoriesFilterMarketId = createSelector(
  [productCategorySelector],
  ({ fetchOrderProductCategoriesFilterMarketId }) =>
    fetchOrderProductCategoriesFilterMarketId
);
export const getFetchOrderProductCategoriesFilterBranchId = createSelector(
  [productCategorySelector],
  ({ fetchOrderProductCategoriesFilterBranchId }) =>
    fetchOrderProductCategoriesFilterBranchId
);
export const getFetchOrderProductCategoriesLoading = createSelector(
  [productCategorySelector],
  ({ fetchOrderProductCategoriesLoading }) => fetchOrderProductCategoriesLoading
);
export const getFetchOrderProductCategoriesSuccess = createSelector(
  [productCategorySelector],
  ({ fetchOrderProductCategoriesSuccess }) => fetchOrderProductCategoriesSuccess
);
export const getFetchOrderProductCategoriesFailed = createSelector(
  [productCategorySelector],
  ({ fetchOrderProductCategoriesFailed }) => fetchOrderProductCategoriesFailed
);

export const getAppendSelectProductCategoriesSearch = createSelector(
  [productCategorySelector],
  ({ appendSelectProductCategoriesSearch }) =>
    appendSelectProductCategoriesSearch
);
export const getAppendSelectProductCategoriesSort = createSelector(
  [productCategorySelector],
  ({ appendSelectProductCategoriesSort }) => appendSelectProductCategoriesSort
);
export const getAppendSelectProductCategoriesKeyBy = createSelector(
  [productCategorySelector],
  ({ appendSelectProductCategoriesKeyBy }) => appendSelectProductCategoriesKeyBy
);
export const getAppendSelectProductCategoriesPage = createSelector(
  [productCategorySelector],
  ({ appendSelectProductCategoriesPage }) => appendSelectProductCategoriesPage
);
export const getAppendSelectProductCategoriesPerPage = createSelector(
  [productCategorySelector],
  ({ appendSelectProductCategoriesPerPage }) =>
    appendSelectProductCategoriesPerPage
);
export const getAppendSelectProductCategoriesIncludes = createSelector(
  [productCategorySelector],
  ({ appendSelectProductCategoriesIncludes }) =>
    appendSelectProductCategoriesIncludes
);
export const getAppendSelectProductCategoriesFilterSectorId = createSelector(
  [productCategorySelector],
  ({ appendSelectProductCategoriesFilterSectorId }) =>
    appendSelectProductCategoriesFilterSectorId
);
export const getAppendSelectProductCategoriesFilterMarketId = createSelector(
  [productCategorySelector],
  ({ appendSelectProductCategoriesFilterMarketId }) =>
    appendSelectProductCategoriesFilterMarketId
);
export const getAppendSelectProductCategoriesFilterBranchId = createSelector(
  [productCategorySelector],
  ({ appendSelectProductCategoriesFilterBranchId }) =>
    appendSelectProductCategoriesFilterBranchId
);
export const getAppendSelectProductCategoriesFilterProductCategoriesIds =
  createSelector(
    [productCategorySelector],
    ({ appendSelectProductCategoriesFilterProductCategoriesIds }) =>
      appendSelectProductCategoriesFilterProductCategoriesIds
  );
export const getAppendSelectProductCategoriesLoading = createSelector(
  [productCategorySelector],
  ({ appendSelectProductCategoriesLoading }) =>
    appendSelectProductCategoriesLoading
);
export const getAppendSelectProductCategoriesSuccess = createSelector(
  [productCategorySelector],
  ({ appendSelectProductCategoriesSuccess }) =>
    appendSelectProductCategoriesSuccess
);
export const getAppendSelectProductCategoriesFailed = createSelector(
  [productCategorySelector],
  ({ appendSelectProductCategoriesFailed }) =>
    appendSelectProductCategoriesFailed
);

export const getFetchProductCategoryLoading = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoryLoading }) => fetchProductCategoryLoading
);
export const getFetchProductCategorySuccess = createSelector(
  [productCategorySelector],
  ({ fetchProductCategorySuccess }) => fetchProductCategorySuccess
);
export const getFetchProductCategoryFailed = createSelector(
  [productCategorySelector],
  ({ fetchProductCategoryFailed }) => fetchProductCategoryFailed
);

export const getCreateProductCategoryLoading = createSelector(
  [productCategorySelector],
  ({ createProductCategoryLoading }) => createProductCategoryLoading
);
export const getCreateProductCategorySuccess = createSelector(
  [productCategorySelector],
  ({ createProductCategorySuccess }) => createProductCategorySuccess
);
export const getCreateProductCategoryFailed = createSelector(
  [productCategorySelector],
  ({ createProductCategoryFailed }) => createProductCategoryFailed
);

export const getUpdateProductCategoryLoading = createSelector(
  [productCategorySelector],
  ({ updateProductCategoryLoading }) => updateProductCategoryLoading
);
export const getUpdateProductCategorySuccess = createSelector(
  [productCategorySelector],
  ({ updateProductCategorySuccess }) => updateProductCategorySuccess
);
export const getUpdateProductCategoryFailed = createSelector(
  [productCategorySelector],
  ({ updateProductCategoryFailed }) => updateProductCategoryFailed
);

export const getDeleteProductCategoryLoading = createSelector(
  [productCategorySelector],
  ({ deleteProductCategoryLoading }) => deleteProductCategoryLoading
);
export const getDeleteProductCategorySuccess = createSelector(
  [productCategorySelector],
  ({ deleteProductCategorySuccess }) => deleteProductCategorySuccess
);
export const getDeleteProductCategoryFailed = createSelector(
  [productCategorySelector],
  ({ deleteProductCategoryFailed }) => deleteProductCategoryFailed
);

export const getIsFetchProductCategoriesHitted = createSelector(
  [productCategorySelector],
  ({ isFetchProductCategoriesHitted }) => isFetchProductCategoriesHitted
);
export const getIsFetchSelectProductCategoriesHitted = createSelector(
  [productCategorySelector],
  ({ isFetchSelectProductCategoriesHitted }) =>
    isFetchSelectProductCategoriesHitted
);
export const getIsFetchProductProductCategoriesHitted = createSelector(
  [productCategorySelector],
  ({ isFetchProductProductCategoriesHitted }) =>
    isFetchProductProductCategoriesHitted
);
export const getIsFetchOrderProductCategoriesHitted = createSelector(
  [productCategorySelector],
  ({ isFetchOrderProductCategoriesHitted }) =>
    isFetchOrderProductCategoriesHitted
);
export const getIsAppendSelectProductCategoriesHitted = createSelector(
  [productCategorySelector],
  ({ isAppendSelectProductCategoriesHitted }) =>
    isAppendSelectProductCategoriesHitted
);
export const getIsFetchProductCategoryHitted = createSelector(
  [productCategorySelector],
  ({ isFetchProductCategoryHitted }) => isFetchProductCategoryHitted
);
export const getIsCreateProductCategoryHitted = createSelector(
  [productCategorySelector],
  ({ isCreateProductCategoryHitted }) => isCreateProductCategoryHitted
);
export const getIsUpdateProductCategoryHitted = createSelector(
  [productCategorySelector],
  ({ isUpdateProductCategoryHitted }) => isUpdateProductCategoryHitted
);
export const getIsDeleteProductCategoryHitted = createSelector(
  [productCategorySelector],
  ({ isDeleteProductCategoryHitted }) => isDeleteProductCategoryHitted
);
