import SUBSCRIBER_ACTION_TYPES from "./subscriber.type";
import { createAction } from "../../utils/store.utils";

export const setSubscribers = (subscribers) => createAction(SUBSCRIBER_ACTION_TYPES.SET_SUBSCRIBERS, subscribers);

export const setSelectSubscribers = (selectSubscribers) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_SELECT_SUBSCRIBERS, selectSubscribers);

export const setSubscriber = (subscriber) => createAction(SUBSCRIBER_ACTION_TYPES.SET_SUBSCRIBER, subscriber);

export const setSubscriberReport = (subscriberReport) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_SUBSCRIBER_REPORT, subscriberReport);

export const setIsSubscribersHasMore = (isSubscribersHasMore) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_IS_SUBSCRIBERS_HAS_MORE, isSubscribersHasMore);

export const setFetchSubscribersSearch = (fetchSubscribersSearch) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_SEARCH, fetchSubscribersSearch);

export const setFetchSubscribersPage = (fetchSubscribersPage) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_PAGE, fetchSubscribersPage);

export const setFetchSubscribersPerPage = (fetchSubscribersPerPage) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_PER_PAGE, fetchSubscribersPerPage);

export const setFetchSubscribersIncludes = (fetchSubscribersIncludes) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_INCLUDES, fetchSubscribersIncludes);

export const setFetchSubscribersFilterMarketId = (fetchSubscribersFilterMarketId) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_FILTER_MARKET_ID, fetchSubscribersFilterMarketId);

export const setFetchSubscribersFilterBranchId = (fetchSubscribersFilterBranchId) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_FILTER_BRANCH_ID, fetchSubscribersFilterBranchId);

export const setFetchSubscribersFilterPermissionId = (fetchSubscribersFilterPermissionId) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_FILTER_PERMISSION_ID, fetchSubscribersFilterPermissionId);

export const setFetchSubscribersLoading = (fetchSubscribersLoading) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_LOADING, fetchSubscribersLoading);

export const setFetchSubscribersSuccess = (fetchSubscribersSuccess) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_SUCCESS, fetchSubscribersSuccess);

export const setFetchSubscribersFailed = (fetchSubscribersFailed) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_FAILED, fetchSubscribersFailed);

export const setIsSelectSubscribersHasMore = (isSubscribersHasMore) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_IS_SUBSCRIBERS_HAS_MORE, isSubscribersHasMore);

export const setFetchSelectSubscribersSearch = (fetchSelectSubscribersSearch) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_SEARCH, fetchSelectSubscribersSearch);

export const setFetchSelectSubscribersPage = (fetchSelectSubscribersPage) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_PAGE, fetchSelectSubscribersPage);

export const setFetchSelectSubscribersPerPage = (fetchSelectSubscribersPerPage) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_PER_PAGE, fetchSelectSubscribersPerPage);

export const setFetchSelectSubscribersIncludes = (fetchSelectSubscribersIncludes) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_INCLUDES, fetchSelectSubscribersIncludes);

export const setFetchSelectSubscribersFilterMarketId = (fetchSelectSubscribersFilterMarketId) =>
  createAction(
    SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_FILTER_MARKET_ID,
    fetchSelectSubscribersFilterMarketId
  );

export const setFetchSelectSubscribersFilterBranchId = (fetchSelectSubscribersFilterBranchId) =>
  createAction(
    SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_FILTER_BRANCH_ID,
    fetchSelectSubscribersFilterBranchId
  );

export const setFetchSelectSubscribersFilterPermissionId = (fetchSelectSubscribersFilterPermissionId) =>
  createAction(
    SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_FILTER_PERMISSION_ID,
    fetchSelectSubscribersFilterPermissionId
  );

export const setFetchSelectSubscribersLoading = (fetchSelectSubscribersLoading) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_LOADING, fetchSelectSubscribersLoading);

export const setFetchSelectSubscribersSuccess = (fetchSelectSubscribersSuccess) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_SUCCESS, fetchSelectSubscribersSuccess);

export const setFetchSelectSubscribersFailed = (fetchSelectSubscribersFailed) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_FAILED, fetchSelectSubscribersFailed);

export const setFetchSubscriberReportStartAt = (fetchSubscriberReportStartAt) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_REPORT_START_AT, fetchSubscriberReportStartAt);

export const setFetchSubscriberReportEndAt = (fetchSubscriberReportEndAt) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_REPORT_END_AT, fetchSubscriberReportEndAt);

export const setFetchSubscriberReportLoading = (fetchSubscriberReportLoading) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_REPORT_LOADING, fetchSubscriberReportLoading);

export const setFetchSubscriberReportSuccess = (fetchSubscriberReportSuccess) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_REPORT_SUCCESS, fetchSubscriberReportSuccess);

export const setFetchSubscriberReportFailed = (fetchSubscriberReportFailed) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_REPORT_FAILED, fetchSubscriberReportFailed);

export const setFetchSubscriberLoading = (fetchSubscriberLoading) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_LOADING, fetchSubscriberLoading);

export const setFetchSubscriberSuccess = (fetchSubscriberSuccess) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_SUCCESS, fetchSubscriberSuccess);

export const setFetchSubscriberFailed = (fetchSubscriberFailed) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_FAILED, fetchSubscriberFailed);

export const setCreateSubscriberLoading = (createSubscriberLoading) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_CREATE_SUBSCRIBER_LOADING, createSubscriberLoading);

export const setCreateSubscriberSuccess = (createSubscriberSuccess) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_CREATE_SUBSCRIBER_SUCCESS, createSubscriberSuccess);

export const setCreateSubscriberFailed = (createSubscriberFailed) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_CREATE_SUBSCRIBER_FAILED, createSubscriberFailed);

export const setUpdateSubscriberLoading = (updateSubscriberLoading) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_UPDATE_SUBSCRIBER_LOADING, updateSubscriberLoading);

export const setUpdateSubscriberSuccess = (updateSubscriberSuccess) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_UPDATE_SUBSCRIBER_SUCCESS, updateSubscriberSuccess);

export const setUpdateSubscriberFailed = (updateSubscriberFailed) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_UPDATE_SUBSCRIBER_FAILED, updateSubscriberFailed);

export const setDeleteSubscriberLoading = (deleteSubscriberLoading) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_DELETE_SUBSCRIBER_LOADING, deleteSubscriberLoading);

export const setDeleteSubscriberSuccess = (deleteSubscriberSuccess) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_DELETE_SUBSCRIBER_SUCCESS, deleteSubscriberSuccess);

export const setDeleteSubscriberFailed = (deleteSubscriberFailed) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_DELETE_SUBSCRIBER_FAILED, deleteSubscriberFailed);

export const appendSubscribers = (subscribers) => createAction(SUBSCRIBER_ACTION_TYPES.APPEND_SUBSCRIBERS, subscribers);

export const appendSelectSubscribers = (selectSubscribers) =>
  createAction(SUBSCRIBER_ACTION_TYPES.APPEND_SELECT_SUBSCRIBERS, selectSubscribers);

export const fetchSubscribersStart = () => createAction(SUBSCRIBER_ACTION_TYPES.FETCH_SUBSCRIBERS_START);

export const fetchSelectSubscribersStart = () => createAction(SUBSCRIBER_ACTION_TYPES.FETCH_SELECT_SUBSCRIBERS_START);

export const fetchSubscriberStart = (id) => createAction(SUBSCRIBER_ACTION_TYPES.FETCH_SUBSCRIBER_START, id);

export const fetchSubscriberReportStart = (id) =>
  createAction(SUBSCRIBER_ACTION_TYPES.FETCH_SUBSCRIBER_REPORT_START, id);

export const createSubscriberStart = (request) =>
  createAction(SUBSCRIBER_ACTION_TYPES.CREATE_SUBSCRIBER_START, request);

export const updateSubscriberStart = (id, request) =>
  createAction(SUBSCRIBER_ACTION_TYPES.UPDATE_SUBSCRIBER_START, { id, request });

export const deleteSubscriberStart = (id) => createAction(SUBSCRIBER_ACTION_TYPES.DELETE_SUBSCRIBER_START, id);

export const setIsFetchSubscribersHitted = (isFetchSubscribersHitted) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_SUBSCRIBERS_HITTED, isFetchSubscribersHitted);

export const setIsFetchSelectSubscribersHitted = (isFetchSelectSubscribersHitted) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_SELECT_SUBSCRIBERS_HITTED, isFetchSelectSubscribersHitted);

export const setIsFetchSubscriberReportHitted = (isFetchSubscriberReportHitted) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_SUBSCRIBER_REPORT_HITTED, isFetchSubscriberReportHitted);

export const setIsFetchSubscriberHitted = (isFetchSubscriberHitted) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_SUBSCRIBER_HITTED, isFetchSubscriberHitted);

export const setIsCreateSubscriberHitted = (isCreateSubscriberHitted) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_IS_CREATE_SUBSCRIBER_HITTED, isCreateSubscriberHitted);

export const setIsUpdateSubscriberHitted = (isUpdateSubscriberHitted) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_IS_UPDATE_SUBSCRIBER_HITTED, isUpdateSubscriberHitted);

export const setIsDeleteSubscriberHitted = (isDeleteSubscriberHitted) =>
  createAction(SUBSCRIBER_ACTION_TYPES.SET_IS_DELETE_SUBSCRIBER_HITTED, isDeleteSubscriberHitted);

export const resetSubscriberReducer = () => createAction(SUBSCRIBER_ACTION_TYPES.RESET_SUBSCRIBER_REDUCER);
