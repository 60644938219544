import { createSelector } from "reselect";

const countrySelector = ({ country }) => country;

export const getCountries = createSelector(
  [countrySelector],
  ({ countries }) => countries
);
export const getCountry = createSelector(
  [countrySelector],
  ({ country }) => country
);

export const getIsCountriesHasMore = createSelector(
  [countrySelector],
  ({ isCountriesHasMore }) => isCountriesHasMore
);

export const getFetchCountriesSearch = createSelector(
  [countrySelector],
  ({ fetchCountriesSearch }) => fetchCountriesSearch
);
export const getFetchCountriesKeyBy = createSelector(
  [countrySelector],
  ({ fetchCountriesKeyBy }) => fetchCountriesKeyBy
);
export const getFetchCountriesPage = createSelector(
  [countrySelector],
  ({ fetchCountriesPage }) => fetchCountriesPage
);
export const getFetchCountriesPerPage = createSelector(
  [countrySelector],
  ({ fetchCountriesPerPage }) => fetchCountriesPerPage
);
export const getFetchCountriesIncludes = createSelector(
  [countrySelector],
  ({ fetchCountriesIncludes }) => fetchCountriesIncludes
);
export const getFetchCountriesLoading = createSelector(
  [countrySelector],
  ({ fetchCountriesLoading }) => fetchCountriesLoading
);
export const getFetchCountriesSuccess = createSelector(
  [countrySelector],
  ({ fetchCountriesSuccess }) => fetchCountriesSuccess
);
export const getFetchCountriesFailed = createSelector(
  [countrySelector],
  ({ fetchCountriesFailed }) => fetchCountriesFailed
);

export const getFetchCountryLoading = createSelector(
  [countrySelector],
  ({ fetchCountryLoading }) => fetchCountryLoading
);
export const getFetchCountrySuccess = createSelector(
  [countrySelector],
  ({ fetchCountrySuccess }) => fetchCountrySuccess
);
export const getFetchCountryFailed = createSelector(
  [countrySelector],
  ({ fetchCountryFailed }) => fetchCountryFailed
);

export const getCreateCountryLoading = createSelector(
  [countrySelector],
  ({ createCountryLoading }) => createCountryLoading
);
export const getCreateCountrySuccess = createSelector(
  [countrySelector],
  ({ createCountrySuccess }) => createCountrySuccess
);
export const getCreateCountryFailed = createSelector(
  [countrySelector],
  ({ createCountryFailed }) => createCountryFailed
);

export const getUpdateCountryLoading = createSelector(
  [countrySelector],
  ({ updateCountryLoading }) => updateCountryLoading
);
export const getUpdateCountrySuccess = createSelector(
  [countrySelector],
  ({ updateCountrySuccess }) => updateCountrySuccess
);
export const getUpdateCountryFailed = createSelector(
  [countrySelector],
  ({ updateCountryFailed }) => updateCountryFailed
);

export const getDeleteCountryLoading = createSelector(
  [countrySelector],
  ({ deleteCountryLoading }) => deleteCountryLoading
);
export const getDeleteCountrySuccess = createSelector(
  [countrySelector],
  ({ deleteCountrySuccess }) => deleteCountrySuccess
);
export const getDeleteCountryFailed = createSelector(
  [countrySelector],
  ({ deleteCountryFailed }) => deleteCountryFailed
);

export const getIsFetchCountriesHitted = createSelector(
  [countrySelector],
  ({ isFetchCountriesHitted }) => isFetchCountriesHitted
);
export const getIsFetchCountryHitted = createSelector(
  [countrySelector],
  ({ isFetchCountryHitted }) => isFetchCountryHitted
);
export const getIsCreateCountryHitted = createSelector(
  [countrySelector],
  ({ isCreateCountryHitted }) => isCreateCountryHitted
);
export const getIsUpdateCountryHitted = createSelector(
  [countrySelector],
  ({ isUpdateCountryHitted }) => isUpdateCountryHitted
);
export const getIsDeleteCountryHitted = createSelector(
  [countrySelector],
  ({ isDeleteCountryHitted }) => isDeleteCountryHitted
);
