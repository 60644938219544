import apiService from "./api";

export const getSubscriptions = async (parameters) =>
  (await apiService.get("/v2/subscriptions", parameters)).data;
export const getSubscription = async (subscriptionKey) =>
  (await apiService.get(`/v2/subscriptions/${subscriptionKey}`)).data;
export const createSubscription = async (request) =>
  (await apiService.post("/v2/subscriptions", request)).data;
export const updateSubscription = async (subscriptionKey, request) =>
  (await apiService.post(`/v2/subscriptions/${subscriptionKey}`, request)).data;
export const deleteSubscription = async (subscriptionKey) =>
  (await apiService.delete(`/v2/subscriptions/${subscriptionKey}`)).data;
