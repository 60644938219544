import { getRemovedDuplicates } from "../../utils/formatter.utils";

import MARKET_ACTION_TYPES from "./market.type";

export const MARKET_INITIAL_STATE = {
  marketTableColumns: {},

  countriesByPhonePrefix: {
    966: {
      flag: "🇸🇦",
      country: "Saudi Arabia",
      phone_prefix: "966",
      start: "5",
      max: 8,
      currency: "SAR",
    },
    971: {
      flag: "🇦🇪",
      country: "United Arab Emirates",
      phone_prefix: "971",
      currency: "AED",
    },
    968: {
      flag: "🇴🇲",
      country: "Oman",
      phone_prefix: "968",
      currency: "OMR",
    },
    965: {
      flag: "🇰🇼",
      country: "Kuwait",
      phone_prefix: "965",
      currency: "KWD",
    },
    974: {
      flag: "🇶🇦",
      country: "Qatar",
      phone_prefix: "974",
      currency: "QAR",
    },
    973: {
      flag: "🇧🇭",
      country: "Bahrain",
      phone_prefix: "973",
      currency: "BHD",
    },
    20: {
      flag: "🇪🇬",
      country: "Egypt",
      phone_prefix: "20",
      currency: "EGP",
    },
    967: {
      flag: "🇾🇪",
      country: "Yemen",
      phone_prefix: "967",
      currency: "YER",
    },
    62: {
      flag: "🇮🇩",
      country: "Indonesia",
      phone_prefix: "62",
      currency: "IDR",
    },
  },
  isModalAutoClose: true,
  markets: [],
  selectMarkets: [],
  subscriptionMarkets: [],
  market: null,
  subscriptionMarket: null,

  isMarketsHasMore: true,
  isSelectMarketsHasMore: true,
  isSubscriptionMarketsHasMore: true,

  fetchMarketsSearch: null,
  fetchMarketsSort: null,
  fetchMarketsKeyBy: null,
  fetchMarketsPage: 1,
  fetchMarketsPerPage: null,
  fetchMarketsIncludes: null,
  fetchMarketsFilterMarketsIds: null,
  fetchMarketsFilterSectorId: null,
  fetchMarketsFilterMerchantId: null,
  fetchMarketsFilterResellerId: null,
  fetchMarketsFilterCreatedBy: null,
  fetchMarketsLoading: false,
  fetchMarketsSuccess: null,
  fetchMarketsFailed: null,

  fetchSelectMarketsSearch: null,
  fetchSelectMarketsSort: null,
  fetchSelectMarketsKeyBy: null,
  fetchSelectMarketsPage: 1,
  fetchSelectMarketsPerPage: null,
  fetchSelectMarketsIncludes: null,
  fetchSelectMarketsFilterMarketsIds: null,
  fetchSelectMarketsFilterSectorId: null,
  fetchSelectMarketsFilterMerchantId: null,
  fetchSelectMarketsFilterResellerId: null,
  fetchSelectMarketsFilterCreatedBy: null,
  fetchSelectMarketsLoading: false,
  fetchSelectMarketsSuccess: null,
  fetchSelectMarketsFailed: null,

  appendSelectMarketsSearch: null,
  appendSelectMarketsSort: null,
  appendSelectMarketsKeyBy: null,
  appendSelectMarketsPage: 1,
  appendSelectMarketsPerPage: null,
  appendSelectMarketsIncludes: null,
  appendSelectMarketsFilterMarketsIds: null,
  appendSelectMarketsFilterSectorId: null,
  appendSelectMarketsFilterMerchantId: null,
  appendSelectMarketsFilterResellerId: null,
  appendSelectMarketsFilterCreatedBy: null,
  appendSelectMarketsLoading: false,
  appendSelectMarketsSuccess: null,
  appendSelectMarketsFailed: null,

  fetchSubscriptionMarketsSearch: null,
  fetchSubscriptionMarketsSort: null,
  fetchSubscriptionMarketsKeyBy: null,
  fetchSubscriptionMarketsPage: 1,
  fetchSubscriptionMarketsPerPage: null,
  fetchSubscriptionMarketsIncludes: null,
  fetchSubscriptionMarketsFilterMarketsIds: null,
  fetchSubscriptionMarketsFilterSectorId: null,
  fetchSubscriptionMarketsFilterMerchantId: null,
  fetchSubscriptionMarketsFilterResellerId: null,
  fetchSubscriptionMarketsFilterCreatedBy: null,
  fetchSubscriptionMarketsLoading: false,
  fetchSubscriptionMarketsSuccess: null,
  fetchSubscriptionMarketsFailed: null,

  fetchMarketLoading: false,
  fetchMarketSuccess: null,
  fetchMarketFailed: null,

  createMarketLoading: false,
  createMarketSuccess: null,
  createMarketFailed: null,

  updateMarketLoading: false,
  updateMarketSuccess: null,
  updateMarketFailed: null,

  deleteMarketLoading: false,
  deleteMarketSuccess: null,
  deleteMarketFailed: null,

  sendVerificationMarketLoading: false,
  sendVerificationMarketSuccess: null,
  sendVerificationMarketFailed: null,

  verifyVerificationMarketLoading: false,
  verifyVerificationMarketSuccess: null,
  verifyVerificationMarketFailed: null,

  isFetchMarketsHitted: false,
  isFetchSelectMarketsHitted: false,
  isFetchSubscriptionMarketsHitted: false,
  isFetchMarketHitted: false,
  isUpdateMarketHitted: false,
  isCreateMarketHitted: false,
  isDeleteMarketHitted: false,
  isSendVerificationMarketHitted: false,
  isVerifyVerificationMarketHitted: false,
  isAppendSelectMarketsHitted: false,
};

export const marketReducer = (state = MARKET_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case MARKET_ACTION_TYPES.SET_MARKET_TABLE_COLUMNS:
      return { ...state, marketTableColumns: payload };

    case MARKET_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE:
      return { ...state, isModalAutoClose: payload };
    case MARKET_ACTION_TYPES.SET_MARKETS:
      return { ...state, markets: payload };
    case MARKET_ACTION_TYPES.SET_SELECT_MARKETS:
      return { ...state, selectMarkets: payload };
    case MARKET_ACTION_TYPES.SET_SUBSCRIPTION_MARKETS:
      return { ...state, subscriptionMarkets: payload };
    case MARKET_ACTION_TYPES.SET_MARKET:
      return { ...state, market: payload };
    case MARKET_ACTION_TYPES.SET_SUBSCRIPTION_MARKET:
      return { ...state, subscriptionMarket: payload };

    case MARKET_ACTION_TYPES.SET_IS_MARKETS_HAS_MORE:
      return { ...state, isMarketsHasMore: payload };
    case MARKET_ACTION_TYPES.SET_IS_SELECT_MARKETS_HAS_MORE:
      return { ...state, isSelectMarketsHasMore: payload };
    case MARKET_ACTION_TYPES.SET_IS_SUBSCRIPTION_MARKETS_HAS_MORE:
      return { ...state, isSubscriptionMarketsHasMore: payload };

    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_SEARCH:
      return { ...state, fetchMarketsSearch: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_SORT:
      return { ...state, fetchMarketsSort: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_KEY_BY:
      return { ...state, fetchMarketsKeyBy: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_PAGE:
      return { ...state, fetchMarketsPage: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_PER_PAGE:
      return { ...state, fetchMarketsPerPage: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_INCLUDES:
      return { ...state, fetchMarketsIncludes: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FILTER_MARKETS_IDS:
      return { ...state, fetchMarketsFilterMarketsIds: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FILTER_SECTOR_ID:
      return { ...state, fetchMarketsFilterSectorId: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FILTER_MERCHANT_ID:
      return { ...state, fetchMarketsFilterMerchantId: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FILTER_RESELLER_ID:
      return { ...state, fetchMarketsFilterResellerId: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FILTER_CREATED_BY:
      return { ...state, fetchMarketsFilterCreatedBy: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_LOADING:
      return { ...state, fetchMarketsLoading: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_SUCCESS:
      return { ...state, fetchMarketsSuccess: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FAILED:
      return { ...state, fetchMarketsFailed: payload };

    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_SEARCH:
      return { ...state, fetchSelectMarketsSearch: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_SORT:
      return { ...state, fetchSelectMarketsSort: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_KEY_BY:
      return { ...state, fetchSelectMarketsKeyBy: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_PAGE:
      return { ...state, fetchSelectMarketsPage: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_PER_PAGE:
      return { ...state, fetchSelectMarketsPerPage: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_INCLUDES:
      return { ...state, fetchSelectMarketsIncludes: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_FILTER_MARKETS_IDS:
      return { ...state, fetchSelectMarketsFilterMarketsIds: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_FILTER_SECTOR_ID:
      return { ...state, fetchSelectMarketsFilterSectorId: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_FILTER_MERCHANT_ID:
      return { ...state, fetchSelectMarketsFilterMerchantId: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_FILTER_RESELLER_ID:
      return { ...state, fetchSelectMarketsFilterResellerId: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_FILTER_CREATED_BY:
      return { ...state, fetchSelectMarketsFilterCreatedBy: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_LOADING:
      return { ...state, fetchSelectMarketsLoading: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_SUCCESS:
      return { ...state, fetchSelectMarketsSuccess: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_FAILED:
      return { ...state, fetchSelectMarketsFailed: payload };

    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_SEARCH:
      return { ...state, appendSelectMarketsSearch: payload };
    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_SORT:
      return { ...state, appendSelectMarketsSort: payload };
    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_KEY_BY:
      return { ...state, appendSelectMarketsKeyBy: payload };
    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_PAGE:
      return { ...state, appendSelectMarketsPage: payload };
    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_PER_PAGE:
      return { ...state, appendSelectMarketsPerPage: payload };
    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_INCLUDES:
      return { ...state, appendSelectMarketsIncludes: payload };
    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_FILTER_MARKETS_IDS:
      return { ...state, appendSelectMarketsFilterMarketsIds: payload };
    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_FILTER_SECTOR_ID:
      return { ...state, appendSelectMarketsFilterSectorId: payload };
    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_FILTER_MERCHANT_ID:
      return { ...state, appendSelectMarketsFilterMerchantId: payload };
    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_FILTER_RESELLER_ID:
      return { ...state, appendSelectMarketsFilterResellerId: payload };
    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_FILTER_CREATED_BY:
      return { ...state, appendSelectMarketsFilterCreatedBy: payload };
    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_LOADING:
      return { ...state, appendSelectMarketsLoading: payload };
    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_SUCCESS:
      return { ...state, appendSelectMarketsSuccess: payload };
    case MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_FAILED:
      return { ...state, appendSelectMarketsFailed: payload };

    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_SEARCH:
      return { ...state, fetchSubscriptionMarketsSearch: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_SORT:
      return { ...state, fetchSubscriptionMarketsSort: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_KEY_BY:
      return { ...state, fetchSubscriptionMarketsKeyBy: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_PAGE:
      return { ...state, fetchSubscriptionMarketsPage: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_PER_PAGE:
      return { ...state, fetchSubscriptionMarketsPerPage: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_INCLUDES:
      return { ...state, fetchSubscriptionMarketsIncludes: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_MARKETS_IDS:
      return { ...state, fetchSubscriptionMarketsFilterMarketsIds: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_SECTOR_ID:
      return { ...state, fetchSubscriptionMarketsFilterSectorId: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_MERCHANT_ID:
      return { ...state, fetchSubscriptionMarketsFilterMerchantId: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_RESELLER_ID:
      return { ...state, fetchSubscriptionMarketsFilterResellerId: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_CREATED_BY:
      return { ...state, fetchSubscriptionMarketsFilterCreatedBy: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_LOADING:
      return { ...state, fetchSubscriptionMarketsLoading: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_SUCCESS:
      return { ...state, fetchSubscriptionMarketsSuccess: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_FAILED:
      return { ...state, fetchSubscriptionMarketsFailed: payload };

    case MARKET_ACTION_TYPES.SET_FETCH_MARKET_LOADING:
      return { ...state, fetchMarketLoading: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKET_SUCCESS:
      return { ...state, fetchMarketSuccess: payload };
    case MARKET_ACTION_TYPES.SET_FETCH_MARKET_FAILED:
      return { ...state, fetchMarketFailed: payload };

    case MARKET_ACTION_TYPES.SET_CREATE_MARKET_LOADING:
      return { ...state, createMarketLoading: payload };
    case MARKET_ACTION_TYPES.SET_CREATE_MARKET_SUCCESS:
      return { ...state, createMarketSuccess: payload };
    case MARKET_ACTION_TYPES.SET_CREATE_MARKET_FAILED:
      return { ...state, createMarketFailed: payload };

    case MARKET_ACTION_TYPES.SET_UPDATE_MARKET_LOADING:
      return { ...state, updateMarketLoading: payload };
    case MARKET_ACTION_TYPES.SET_UPDATE_MARKET_SUCCESS:
      return { ...state, updateMarketSuccess: payload };
    case MARKET_ACTION_TYPES.SET_UPDATE_MARKET_FAILED:
      return { ...state, updateMarketFailed: payload };

    case MARKET_ACTION_TYPES.SET_DELETE_MARKET_LOADING:
      return { ...state, deleteMarketLoading: payload };
    case MARKET_ACTION_TYPES.SET_DELETE_MARKET_SUCCESS:
      return { ...state, deleteMarketSuccess: payload };
    case MARKET_ACTION_TYPES.SET_DELETE_MARKET_FAILED:
      return { ...state, deleteMarketFailed: payload };

    case MARKET_ACTION_TYPES.SET_SEND_VERIFICATION_MARKET_LOADING:
      return { ...state, sendVerificationMarketLoading: payload };
    case MARKET_ACTION_TYPES.SET_SEND_VERIFICATION_MARKET_SUCCESS:
      return { ...state, sendVerificationMarketSuccess: payload };
    case MARKET_ACTION_TYPES.SET_SEND_VERIFICATION_MARKET_FAILED:
      return { ...state, sendVerificationMarketFailed: payload };

    case MARKET_ACTION_TYPES.SET_VERIFY_VERIFICATION_MARKET_LOADING:
      return { ...state, verifyVerificationMarketLoading: payload };
    case MARKET_ACTION_TYPES.SET_VERIFY_VERIFICATION_MARKET_SUCCESS:
      return { ...state, verifyVerificationMarketSuccess: payload };
    case MARKET_ACTION_TYPES.SET_VERIFY_VERIFICATION_MARKET_FAILED:
      return { ...state, verifyVerificationMarketFailed: payload };

    case MARKET_ACTION_TYPES.APPEND_MARKETS:
      return {
        ...state,
        markets: [...state.markets, ...payload],
      };
    case MARKET_ACTION_TYPES.APPEND_SELECT_MARKETS:
      return {
        ...state,
        selectMarkets: getRemovedDuplicates(
          [...state.selectMarkets, ...payload],
          "id"
        ),
      };
    case MARKET_ACTION_TYPES.APPEND_SUBSCRIPTION_MARKETS:
      return {
        ...state,
        subscriptionMarkets: getRemovedDuplicates(
          [...state.subscriptionMarkets, ...payload],
          "id"
        ),
      };

    case MARKET_ACTION_TYPES.SET_IS_FETCH_MARKETS_HITTED:
      return { ...state, isFetchMarketsHitted: payload };
    case MARKET_ACTION_TYPES.SET_IS_FETCH_SELECT_MARKETS_HITTED:
      return { ...state, isFetchSelectMarketsHitted: payload };
    case MARKET_ACTION_TYPES.SET_IS_FETCH_SUBSCRIPTION_MARKETS_HITTED:
      return { ...state, isFetchSubscriptionMarketsHitted: payload };
    case MARKET_ACTION_TYPES.SET_IS_FETCH_MARKET_HITTED:
      return { ...state, isFetchMarketHitted: payload };
    case MARKET_ACTION_TYPES.SET_IS_CREATE_MARKET_HITTED:
      return { ...state, isUpdateMarketHitted: payload };
    case MARKET_ACTION_TYPES.SET_IS_UPDATE_MARKET_HITTED:
      return { ...state, isCreateMarketHitted: payload };
    case MARKET_ACTION_TYPES.SET_IS_DELETE_MARKET_HITTED:
      return { ...state, isDeleteMarketHitted: payload };
    case MARKET_ACTION_TYPES.SET_IS_SEND_VERIFICATION_MARKET_HITTED:
      return { ...state, isSendVerificationMarketHitted: payload };
    case MARKET_ACTION_TYPES.SET_IS_VERIFY_VERIFICATION_MARKET_HITTED:
      return { ...state, isVerifyVerificationMarketHitted: payload };
    case MARKET_ACTION_TYPES.SET_IS_APPEND_SELECT_MARKETS_HITTED:
      return { ...state, isAppendSelectMarketsHitted: payload };

    case MARKET_ACTION_TYPES.RESET_MARKET_REDUCER:
      return MARKET_INITIAL_STATE;
    default:
      return state;
  }
};
