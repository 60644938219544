import apiService from "./api";

export const getUserMerchants = async (parameters) =>
  (await apiService.get("/v2/users/merchants", parameters)).data;
export const getUserMerchant = async (merchantId) =>
  (await apiService.get(`/v2/users/merchants/${merchantId}`)).data;
export const createUserMerchant = async (request) =>
  (await apiService.post("/v2/users/merchants", request)).data;
export const updateUserMerchant = async (merchantId, request) =>
  (await apiService.post(`/v2/users/merchants/${merchantId}`, request)).data;
export const deleteUserMerchant = async (merchantId) =>
  (await apiService.delete(`/v2/users/merchants/${merchantId}`)).data;
