import { takeLatest, put, all, call, select } from "redux-saga/effects";

import DASHBOARD_ACTION_TYPES from "./dashboard.type";

import {
  setDashboard,
  setFetchDashboardFailed,
  setFetchDashboardLoading,
  setFetchDashboardSuccess,
  setIsFetchDashboardHitted,
} from "./dashboard.action";
import {
  getFetchDashboardFilterBranchId,
  getFetchDashboardFilterCreatedAtAfter,
  getFetchDashboardFilterCreatedAtBefore,
  getFetchDashboardFilterCurrency,
  getFetchDashboardFilterMarketId,
  getFetchDashboardFilterTimePeriod,
} from "./dashboard.selector";

import { getDashboard } from "../../api/dashboard.api";

export function* _getDashboard() {
  try {
    yield put(setFetchDashboardLoading(true));

    const market_id = yield select(getFetchDashboardFilterMarketId);
    const branch_id = yield select(getFetchDashboardFilterBranchId);
    const time_period = yield select(getFetchDashboardFilterTimePeriod);
    const created_at_before = yield select(getFetchDashboardFilterCreatedAtBefore);
    const created_at_after = yield select(getFetchDashboardFilterCreatedAtAfter);
    const currency = yield select(getFetchDashboardFilterCurrency);

    const parameters = {
      filter: {
        market_id,
        branch_id,
        time_period,
        created_at_before,
        created_at_after,
        currency,
      },
    };

    const {
      meta: { message },
      data: dashboard,
    } = yield call(getDashboard, parameters);

    yield put(setIsFetchDashboardHitted(true));
    yield put(setDashboard(dashboard));

    yield put(setFetchDashboardSuccess(message));
    yield put(setFetchDashboardLoading(false));
  } catch (error) {
    yield put(setFetchDashboardFailed(error));
    yield put(setFetchDashboardLoading(false));
  }
}

export function* onFetchDashboardStart() {
  yield takeLatest(DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_START, _getDashboard);
}

export function* dashboardSaga() {
  yield all([call(onFetchDashboardStart)]);
}
