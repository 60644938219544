const DRIVER_ACTION_TYPES = {
  RESET_DRIVER_REDUCER: "driver/RESET_DRIVER_REDUCER",

  SET_DRIVERS: "driver/SET_DRIVERS",
  SET_DRIVER: "driver/SET_DRIVER",
  SET_CREATE_DRIVER: "driver/SET_CREATE_DRIVER",
  SET_UPDATE_DRIVER: "driver/SET_UPDATE_DRIVER",

  SET_IS_DRIVERS_HAS_MORE: "driver/SET_IS_DRIVERS_HAS_MORE",

  SET_FETCH_DRIVERS_SEARCH: "driver/SET_FETCH_DRIVERS_SEARCH",
  SET_FETCH_DRIVERS_PAGE: "driver/SET_FETCH_DRIVERS_PAGE",
  SET_FETCH_DRIVERS_PER_PAGE: "driver/SET_FETCH_DRIVERS_PER_PAGE",
  SET_FETCH_DRIVERS_INCLUDES: "driver/SET_FETCH_DRIVERS_INCLUDES",
  SET_FETCH_DRIVERS_FILTER_MARKET_ID:
    "driver/SET_FETCH_DRIVERS_FILTER_MARKET_ID",
  SET_FETCH_DRIVERS_FILTER_GENDER: "driver/SET_FETCH_DRIVERS_FILTER_GENDER",
  SET_FETCH_DRIVERS_FILTER_STATUS: "driver/SET_FETCH_DRIVERS_FILTER_STATUS",
  SET_FETCH_DRIVERS_FILTER_VEHICLE_TYPE:
    "driver/SET_FETCH_DRIVERS_FILTER_VEHICLE_TYPE",
  SET_FETCH_DRIVERS_LOADING: "driver/SET_FETCH_DRIVERS_LOADING",
  SET_FETCH_DRIVERS_SUCCESS: "driver/SET_FETCH_DRIVERS_SUCCESS",
  SET_FETCH_DRIVERS_FAILED: "driver/SET_FETCH_DRIVERS_FAILED",

  SET_FETCH_DRIVER_LOADING: "driver/SET_FETCH_DRIVER_LOADING",
  SET_FETCH_DRIVER_SUCCESS: "driver/SET_FETCH_DRIVER_SUCCESS",
  SET_FETCH_DRIVER_FAILED: "driver/SET_FETCH_DRIVER_FAILED",

  SET_CREATE_DRIVER_LOADING: "driver/SET_CREATE_DRIVER_LOADING",
  SET_CREATE_DRIVER_SUCCESS: "driver/SET_CREATE_DRIVER_SUCCESS",
  SET_CREATE_DRIVER_FAILED: "driver/SET_CREATE_DRIVER_FAILED",

  SET_UPDATE_DRIVER_LOADING: "driver/SET_UPDATE_DRIVER_LOADING",
  SET_UPDATE_DRIVER_SUCCESS: "driver/SET_UPDATE_DRIVER_SUCCESS",
  SET_UPDATE_DRIVER_FAILED: "driver/SET_UPDATE_DRIVER_FAILED",

  APPEND_DRIVERS: "driver/APPEND_DRIVERS",

  SET_IS_FETCH_DRIVERS_HITTED: "driver/SET_IS_FETCH_DRIVERS_HITTED",
  SET_IS_FETCH_DRIVER_HITTED: "driver/SET_IS_FETCH_DRIVER_HITTED",
  SET_IS_CREATE_DRIVER_HITTED: "driver/SET_IS_CREATE_DRIVER_HITTED",
  SET_IS_UPDATE_DRIVER_HITTED: "driver/SET_IS_UPDATE_DRIVER_HITTED",

  FETCH_DRIVERS_START: "driver/FETCH_DRIVERS_START",
  FETCH_DRIVER_START: "driver/FETCH_DRIVER_START",
  CREATE_DRIVER_START: "driver/CREATE_DRIVER_START",
  UPDATE_DRIVER_START: "driver/UPDATE_DRIVER_START",
};

export default DRIVER_ACTION_TYPES;
