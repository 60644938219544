import USER_SUBSCRIBER_ACTION_TYPES from "./user-subscriber.type";

export const USER_SUBSCRIBER_INITIAL_STATE = {
  userSubscribers: [],
  userSubscriber: null,

  isUserSubscribersHasMore: true,

  fetchUserSubscribersSearch: null,
  fetchUserSubscribersSort: null,
  fetchUserSubscribersKeyBy: null,
  fetchUserSubscribersPage: 1,
  fetchUserSubscribersPerPage: null,
  fetchUserSubscribersIncludes: null,
  fetchUserSubscribersFilterMarketId: null,
  fetchUserSubscribersFilterBranchId: null,
  fetchUserSubscribersFilterPermissionId: null,
  fetchUserSubscribersLoading: false,
  fetchUserSubscribersSuccess: null,
  fetchUserSubscribersFailed: null,

  fetchUserSubscriberLoading: false,
  fetchUserSubscriberSuccess: null,
  fetchUserSubscriberFailed: null,

  createUserSubscriberLoading: false,
  createUserSubscriberSuccess: null,
  createUserSubscriberFailed: null,

  updateUserSubscriberLoading: false,
  updateUserSubscriberSuccess: null,
  updateUserSubscriberFailed: null,

  deleteUserSubscriberLoading: false,
  deleteUserSubscriberSuccess: null,
  deleteUserSubscriberFailed: null,

  isFetchUserSubscribersHitted: false,
  isFetchUserSubscriberHitted: false,
  isCreateUserSubscriberHitted: false,
  isUpdateUserSubscriberHitted: false,
  isDeleteUserSubscriberHitted: false,
};

export const userSubscriberReducer = (
  state = USER_SUBSCRIBER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case USER_SUBSCRIBER_ACTION_TYPES.SET_USER_SUBSCRIBERS:
      return { ...state, userSubscribers: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_USER_SUBSCRIBER:
      return { ...state, userSubscriber: payload };

    case USER_SUBSCRIBER_ACTION_TYPES.SET_IS_USER_SUBSCRIBERS_HAS_MORE:
      return { ...state, isUserSubscribersHasMore: payload };

    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_SEARCH:
      return { ...state, fetchUserSubscribersSearch: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_SORT:
      return { ...state, fetchUserSubscribersSort: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_KEY_BY:
      return { ...state, fetchUserSubscribersKeyBy: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_PAGE:
      return { ...state, fetchUserSubscribersPage: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_PER_PAGE:
      return { ...state, fetchUserSubscribersPerPage: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_INCLUDES:
      return { ...state, fetchUserSubscribersIncludes: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_FILTER_MARKET_ID:
      return { ...state, fetchUserSubscribersFilterMarketId: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_FILTER_BRANCH_ID:
      return { ...state, fetchUserSubscribersFilterBranchId: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_FILTER_PERMISSION_ID:
      return { ...state, fetchUserSubscribersFilterPermissionId: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_LOADING:
      return { ...state, fetchUserSubscribersLoading: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_SUCCESS:
      return { ...state, fetchUserSubscribersSuccess: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBERS_FAILED:
      return { ...state, fetchUserSubscribersFailed: payload };

    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBER_LOADING:
      return { ...state, fetchUserSubscriberLoading: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBER_SUCCESS:
      return { ...state, fetchUserSubscriberSuccess: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_FETCH_USER_SUBSCRIBER_FAILED:
      return { ...state, fetchUserSubscriberFailed: payload };

    case USER_SUBSCRIBER_ACTION_TYPES.SET_CREATE_USER_SUBSCRIBER_LOADING:
      return { ...state, createUserSubscriberLoading: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_CREATE_USER_SUBSCRIBER_SUCCESS:
      return { ...state, createUserSubscriberSuccess: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_CREATE_USER_SUBSCRIBER_FAILED:
      return { ...state, createUserSubscriberFailed: payload };

    case USER_SUBSCRIBER_ACTION_TYPES.SET_UPDATE_USER_SUBSCRIBER_LOADING:
      return { ...state, updateUserSubscriberLoading: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_UPDATE_USER_SUBSCRIBER_SUCCESS:
      return { ...state, updateUserSubscriberSuccess: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_UPDATE_USER_SUBSCRIBER_FAILED:
      return { ...state, updateUserSubscriberFailed: payload };

    case USER_SUBSCRIBER_ACTION_TYPES.SET_DELETE_USER_SUBSCRIBER_LOADING:
      return { ...state, deleteUserSubscriberLoading: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_DELETE_USER_SUBSCRIBER_SUCCESS:
      return { ...state, deleteUserSubscriberSuccess: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_DELETE_USER_SUBSCRIBER_FAILED:
      return { ...state, deleteUserSubscriberFailed: payload };

    case USER_SUBSCRIBER_ACTION_TYPES.APPEND_USER_SUBSCRIBERS:
      return {
        ...state,
        userSubscribers: [...state.userSubscribers, ...payload],
      };

    case USER_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_USER_SUBSCRIBERS_HITTED:
      return { ...state, isFetchUserSubscribersHitted: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_USER_SUBSCRIBER_HITTED:
      return { ...state, isFetchUserSubscriberHitted: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_IS_CREATE_USER_SUBSCRIBER_HITTED:
      return { ...state, isCreateUserSubscriberHitted: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_IS_UPDATE_USER_SUBSCRIBER_HITTED:
      return { ...state, isUpdateUserSubscriberHitted: payload };
    case USER_SUBSCRIBER_ACTION_TYPES.SET_IS_DELETE_USER_SUBSCRIBER_HITTED:
      return { ...state, isDeleteUserSubscriberHitted: payload };

    case USER_SUBSCRIBER_ACTION_TYPES.RESET_USER_SUBSCRIBER_REDUCER:
      return USER_SUBSCRIBER_INITIAL_STATE;
    default:
      return state;
  }
};
