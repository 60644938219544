import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PRINTER_ACTION_TYPES from "./printer.type";

import {
  appendPrinters,
  setCreatePrinterFailed,
  setCreatePrinterLoading,
  setCreatePrinterSuccess,
  setDeletePrinterFailed,
  setDeletePrinterLoading,
  setDeletePrinterSuccess,
  setFetchPrinterFailed,
  setFetchPrinterLoading,
  setFetchPrintersFailed,
  setFetchPrintersLoading,
  setFetchPrintersPage,
  setFetchPrintersSuccess,
  setFetchPrinterSuccess,
  setIsPrintersHasMore,
  setPrinter,
  setPrinters,
  setUpdatePrinterFailed,
  setUpdatePrinterLoading,
  setUpdatePrinterSuccess,
} from "./printer.action";
import {
  getFetchPrintersFilterBranchId,
  getFetchPrintersFilterIsActive,
  getFetchPrintersFilterMarketId,
  getFetchPrintersFilterPrinterTypeId,
  getFetchPrintersIncludes,
  getFetchPrintersPage,
  getFetchPrintersPerPage,
  getFetchPrintersSearch,
} from "./printer.selector";

import { getPrinters, getPrinter, createPrinter, updatePrinter, deletePrinter } from "../../api/printer.api";

export function* _getPrinters() {
  try {
    yield put(setFetchPrintersLoading(true));

    const search = yield select(getFetchPrintersSearch);
    const page = yield select(getFetchPrintersPage);
    const per_page = yield select(getFetchPrintersPerPage);
    const includes = yield select(getFetchPrintersIncludes);
    const market_id = yield select(getFetchPrintersFilterMarketId);
    const branch_id = yield select(getFetchPrintersFilterBranchId);
    const printer_type_id = yield select(getFetchPrintersFilterPrinterTypeId);
    const is_active = yield select(getFetchPrintersFilterIsActive);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        printer_type_id,
        is_active,
      },
    };

    const {
      meta: { message },
      data: { data: printers },
    } = yield call(getPrinters, parameters);

    yield put(setIsPrintersHasMore(printers.length > 0));

    if (page > 1) {
      yield put(appendPrinters(printers));
    } else {
      yield put(setPrinters(printers));
    }

    yield put(setFetchPrintersSuccess(message));
    yield put(setFetchPrintersLoading(false));
  } catch (error) {
    yield put(setFetchPrintersFailed(error));
    yield put(setFetchPrintersLoading(false));
  }
}

export function* _getPrinter({ payload: id }) {
  try {
    yield put(setFetchPrinterLoading(true));

    const {
      meta: { message },
      data: printer,
    } = yield call(getPrinter, id);

    yield put(setPrinter(printer));

    yield put(setFetchPrinterSuccess(message));
    yield put(setFetchPrinterLoading(false));
  } catch (error) {
    yield put(setFetchPrinterFailed(error));
    yield put(setFetchPrinterLoading(false));
  }
}

export function* _createPrinter({ payload: request }) {
  try {
    yield put(setCreatePrinterLoading(true));

    const {
      meta: { message },
    } = yield call(createPrinter, request);

    yield put(setCreatePrinterSuccess(message));
    yield put(setCreatePrinterLoading(false));

    yield put(setFetchPrintersPage(1));
    yield call(_getPrinters);
  } catch (error) {
    yield put(setCreatePrinterFailed(error));
    yield put(setCreatePrinterLoading(false));
  }
}

export function* _updatePrinter({ payload: { id, request } }) {
  try {
    yield put(setUpdatePrinterLoading(true));

    const {
      meta: { message },
    } = yield call(updatePrinter, id, request);

    yield put(setUpdatePrinterSuccess(message));
    yield put(setUpdatePrinterLoading(false));

    yield put(setFetchPrintersPage(1));
    yield call(_getPrinters);
  } catch (error) {
    yield put(setUpdatePrinterFailed(error));
    yield put(setUpdatePrinterLoading(false));
  }
}

export function* _deletePrinter({ payload: id }) {
  try {
    yield put(setDeletePrinterLoading(true));

    const {
      meta: { message },
    } = yield call(deletePrinter, id);

    yield put(setDeletePrinterSuccess(message));
    yield put(setDeletePrinterLoading(false));

    yield put(setFetchPrintersPage(1));
    yield call(_getPrinters);
  } catch (error) {
    yield put(setDeletePrinterFailed(error));
    yield put(setDeletePrinterLoading(false));
  }
}

export function* onFetchPrintersStart() {
  yield takeLatest(PRINTER_ACTION_TYPES.FETCH_PRINTERS_START, _getPrinters);
}

export function* onFetchPrinterStart() {
  yield takeLatest(PRINTER_ACTION_TYPES.FETCH_PRINTER_START, _getPrinter);
}

export function* onCreatePrinterStart() {
  yield takeLatest(PRINTER_ACTION_TYPES.CREATE_PRINTER_START, _createPrinter);
}

export function* onUpdatePrinterStart() {
  yield takeLatest(PRINTER_ACTION_TYPES.UPDATE_PRINTER_START, _updatePrinter);
}

export function* onDeletePrinterStart() {
  yield takeLatest(PRINTER_ACTION_TYPES.DELETE_PRINTER_START, _deletePrinter);
}

export function* printerSaga() {
  yield all([
    call(onFetchPrintersStart),
    call(onFetchPrinterStart),
    call(onCreatePrinterStart),
    call(onUpdatePrinterStart),
    call(onDeletePrinterStart),
  ]);
}
