import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PURCHASE_ACTION_TYPES from "./purchase.type";

import {
  appendPurchases,
  setCalculatePurchase,
  setCalculatePurchaseFailed,
  setCalculatePurchaseLoading,
  setCalculatePurchaseSuccess,
  setCreatePurchaseFailed,
  setCreatePurchaseLoading,
  setCreatePurchaseSuccess,
  setDeletePurchaseFailed,
  setDeletePurchaseLoading,
  setDeletePurchaseSuccess,
  setFetchPurchaseFailed,
  setFetchPurchaseLoading,
  setFetchPurchasesFailed,
  setFetchPurchasesLoading,
  setFetchPurchasesPage,
  setFetchPurchasesSuccess,
  setFetchPurchaseSuccess,
  setIsCalculatePurchaseHitted,
  setIsCreatePurchaseHitted,
  setIsDeletePurchaseHitted,
  setIsFetchPurchaseHitted,
  setIsFetchPurchasesHitted,
  setIsPurchasesHasMore,
  setIsUpdatePurchaseHitted,
  setPurchase,
  setPurchases,
  setUpdatePurchaseFailed,
  setUpdatePurchaseLoading,
  setUpdatePurchaseSuccess,
} from "./purchase.action";
import {
  getFetchPurchasesFilterBranchId,
  getFetchPurchasesFilterMarketId,
  getFetchPurchasesFilterSupplierId,
  getFetchPurchasesIncludes,
  getFetchPurchasesKeyBy,
  getFetchPurchasesPage,
  getFetchPurchasesPerPage,
  getFetchPurchasesSearch,
  getFetchPurchasesSort,
  getIsFetchPurchasesHitted,
} from "./purchase.selector";

import {
  getPurchases,
  getPurchase,
  createPurchase,
  updatePurchase,
  deletePurchase,
  calculatePurchase,
} from "../../api/purchase.api";

export function* _getPurchases() {
  try {
    yield put(setFetchPurchasesLoading(true));

    const search = yield select(getFetchPurchasesSearch);
    const sort = yield select(getFetchPurchasesSort);
    const key_by = yield select(getFetchPurchasesKeyBy);
    const page = yield select(getFetchPurchasesPage);
    const per_page = yield select(getFetchPurchasesPerPage);
    const includes = yield select(getFetchPurchasesIncludes);
    const market_id = yield select(getFetchPurchasesFilterMarketId);
    const branch_id = yield select(getFetchPurchasesFilterBranchId);
    const supplier_id = yield select(getFetchPurchasesFilterSupplierId);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        supplier_id,
      },
    };

    const {
      meta: { message },
      data: { data: purchases },
    } = yield call(getPurchases, parameters);

    yield put(setIsFetchPurchasesHitted(true));
    yield put(setIsPurchasesHasMore(purchases.length > 0));

    if (page > 1) {
      yield put(appendPurchases(purchases));
    } else {
      yield put(setPurchases(purchases));
    }

    yield put(setFetchPurchasesSuccess(message));
    yield put(setFetchPurchasesLoading(false));
  } catch (error) {
    yield put(setFetchPurchasesFailed(error));
    yield put(setFetchPurchasesLoading(false));
  }
}
export function* _getPurchase({ payload: purchaseId }) {
  try {
    yield put(setFetchPurchaseLoading(true));

    const {
      meta: { message },
      data: purchase,
    } = yield call(getPurchase, purchaseId);

    yield put(setIsFetchPurchaseHitted(true));
    yield put(setPurchase(purchase));

    yield put(setFetchPurchaseSuccess(message));
    yield put(setFetchPurchaseLoading(false));
  } catch (error) {
    yield put(setFetchPurchaseFailed(error));
    yield put(setFetchPurchaseLoading(false));
  }
}
export function* _createPurchase({ payload: request }) {
  try {
    yield put(setCreatePurchaseLoading(true));

    const {
      meta: { message },
    } = yield call(createPurchase, request);

    yield put(setIsCreatePurchaseHitted(true));

    const isFetchPurchasesHitted = yield select(getIsFetchPurchasesHitted);

    if (isFetchPurchasesHitted) {
      yield put(setFetchPurchasesPage(1));
      yield call(_getPurchases);
    }

    yield put(setCreatePurchaseSuccess(message));
    yield put(setCreatePurchaseLoading(false));
  } catch (error) {
    yield put(setCreatePurchaseFailed(error));
    yield put(setCreatePurchaseLoading(false));
  }
}
export function* _updatePurchase({ payload: { purchaseId, request } }) {
  try {
    yield put(setUpdatePurchaseLoading(true));

    const {
      meta: { message },
    } = yield call(updatePurchase, purchaseId, request);

    yield put(setIsUpdatePurchaseHitted(true));

    const isFetchPurchasesHitted = yield select(getIsFetchPurchasesHitted);

    if (isFetchPurchasesHitted) {
      yield put(setFetchPurchasesPage(1));
      yield call(_getPurchases);
    }

    yield put(setUpdatePurchaseSuccess(message));
    yield put(setUpdatePurchaseLoading(false));
  } catch (error) {
    yield put(setUpdatePurchaseFailed(error));
    yield put(setUpdatePurchaseLoading(false));
  }
}
export function* _deletePurchase({ payload: purchaseId }) {
  try {
    yield put(setDeletePurchaseLoading(true));

    const {
      meta: { message },
    } = yield call(deletePurchase, purchaseId);

    yield put(setIsDeletePurchaseHitted(true));

    const isFetchPurchasesHitted = yield select(getIsFetchPurchasesHitted);

    if (isFetchPurchasesHitted) {
      yield put(setFetchPurchasesPage(1));
      yield call(_getPurchases);
    }

    yield put(setDeletePurchaseSuccess(message));
    yield put(setDeletePurchaseLoading(false));
  } catch (error) {
    yield put(setDeletePurchaseFailed(error));
    yield put(setDeletePurchaseLoading(false));
  }
}
export function* _calculatePurchase({ payload: request }) {
  try {
    yield put(setCalculatePurchaseLoading(true));

    const {
      meta: { message },
      data: purchase,
    } = yield call(calculatePurchase, request);

    yield put(setIsCalculatePurchaseHitted(true));
    yield put(setCalculatePurchase(purchase));

    yield put(setCalculatePurchaseSuccess(message));
    yield put(setCalculatePurchaseLoading(false));
  } catch (error) {
    yield put(setCalculatePurchaseFailed(error));
    yield put(setCalculatePurchaseLoading(false));
  }
}

export function* onFetchPurchasesStart() {
  yield takeLatest(PURCHASE_ACTION_TYPES.FETCH_PURCHASES_START, _getPurchases);
}
export function* onFetchPurchaseStart() {
  yield takeLatest(PURCHASE_ACTION_TYPES.FETCH_PURCHASE_START, _getPurchase);
}
export function* onCreatePurchaseStart() {
  yield takeLatest(
    PURCHASE_ACTION_TYPES.CREATE_PURCHASE_START,
    _createPurchase
  );
}
export function* onUpdatePurchaseStart() {
  yield takeLatest(
    PURCHASE_ACTION_TYPES.UPDATE_PURCHASE_START,
    _updatePurchase
  );
}
export function* onDeletePurchaseStart() {
  yield takeLatest(
    PURCHASE_ACTION_TYPES.DELETE_PURCHASE_START,
    _deletePurchase
  );
}
export function* onCalculatePurchaseStart() {
  yield takeLatest(
    PURCHASE_ACTION_TYPES.CALCULATE_PURCHASE_START,
    _calculatePurchase
  );
}

export function* purchaseSaga() {
  yield all([
    call(onFetchPurchasesStart),
    call(onFetchPurchaseStart),
    call(onCreatePurchaseStart),
    call(onUpdatePurchaseStart),
    call(onDeletePurchaseStart),
    call(onCalculatePurchaseStart),
  ]);
}
