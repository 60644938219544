const PRINTER_TYPE_ACTION_TYPES = {
  RESET_PRINTER_TYPE_REDUCER: "printer-type/RESET_PRINTER_TYPE_REDUCER",

  SET_PRINTER_TYPES: "printer-type/SET_PRINTER_TYPES",
  SET_SELECT_PRINTER_TYPES: "printer-type/SET_SELECT_PRINTER_TYPES",
  SET_PRINTER_TYPE: "printer-type/SET_PRINTER_TYPE",

  SET_IS_PRINTER_TYPES_HAS_MORE: "printer-type/SET_IS_PRINTER_TYPES_HAS_MORE",
  SET_IS_SELECT_PRINTER_TYPES_HAS_MORE: "printer-type/SET_IS_SELECT_PRINTER_TYPES_HAS_MORE",

  SET_FETCH_PRINTER_TYPES_PAGE: "printer-type/SET_FETCH_PRINTER_TYPES_PAGE",
  SET_FETCH_PRINTER_TYPES_PER_PAGE: "printer-type/SET_FETCH_PRINTER_TYPES_PER_PAGE",
  SET_FETCH_PRINTER_TYPES_LOADING: "printer-type/SET_FETCH_PRINTER_TYPES_LOADING",
  SET_FETCH_PRINTER_TYPES_SUCCESS: "printer-type/SET_FETCH_PRINTER_TYPES_SUCCESS",
  SET_FETCH_PRINTER_TYPES_FAILED: "printer-type/SET_FETCH_PRINTER_TYPES_FAILED",

  SET_FETCH_SELECT_PRINTER_TYPES_PAGE: "printer-type/SET_FETCH_SELECT_PRINTER_TYPES_PAGE",
  SET_FETCH_SELECT_PRINTER_TYPES_PER_PAGE: "printer-type/SET_FETCH_SELECT_PRINTER_TYPES_PER_PAGE",
  SET_FETCH_SELECT_PRINTER_TYPES_LOADING: "printer-type/SET_FETCH_SELECT_PRINTER_TYPES_LOADING",
  SET_FETCH_SELECT_PRINTER_TYPES_SUCCESS: "printer-type/SET_FETCH_SELECT_PRINTER_TYPES_SUCCESS",
  SET_FETCH_SELECT_PRINTER_TYPES_FAILED: "printer-type/SET_FETCH_SELECT_PRINTER_TYPES_FAILED",

  SET_FETCH_PRINTER_TYPE_LOADING: "printer-type/SET_FETCH_PRINTER_TYPE_LOADING",
  SET_FETCH_PRINTER_TYPE_SUCCESS: "printer-type/SET_FETCH_PRINTER_TYPE_SUCCESS",
  SET_FETCH_PRINTER_TYPE_FAILED: "printer-type/SET_FETCH_PRINTER_TYPE_FAILED",

  SET_CREATE_PRINTER_TYPE_LOADING: "printer-type/SET_CREATE_PRINTER_TYPE_LOADING",
  SET_CREATE_PRINTER_TYPE_SUCCESS: "printer-type/SET_CREATE_PRINTER_TYPE_SUCCESS",
  SET_CREATE_PRINTER_TYPE_FAILED: "printer-type/SET_CREATE_PRINTER_TYPE_FAILED",

  SET_UPDATE_PRINTER_TYPE_LOADING: "printer-type/SET_UPDATE_PRINTER_TYPE_LOADING",
  SET_UPDATE_PRINTER_TYPE_SUCCESS: "printer-type/SET_UPDATE_PRINTER_TYPE_SUCCESS",
  SET_UPDATE_PRINTER_TYPE_FAILED: "printer-type/SET_UPDATE_PRINTER_TYPE_FAILED",

  SET_DELETE_PRINTER_TYPE_LOADING: "printer-type/SET_DELETE_PRINTER_TYPE_LOADING",
  SET_DELETE_PRINTER_TYPE_SUCCESS: "printer-type/SET_DELETE_PRINTER_TYPE_SUCCESS",
  SET_DELETE_PRINTER_TYPE_FAILED: "printer-type/SET_DELETE_PRINTER_TYPE_FAILED",

  APPEND_PRINTER_TYPES: "printer-type/APPEND_PRINTER_TYPES",
  APPEND_SELECT_PRINTER_TYPES: "printer-type/APPEND_SELECT_PRINTER_TYPES",

  SET_IS_FETCH_PRINTER_TYPES_HITTED: "printer-type/SET_IS_FETCH_PRINTER_TYPES_HITTED",
  SET_IS_FETCH_SELECT_PRINTER_TYPES_HITTED: "printer-type/SET_IS_FETCH_SELECT_PRINTER_TYPES_HITTED",
  SET_IS_FETCH_PRINTER_TYPE_HITTED: "printer-type/SET_IS_FETCH_PRINTER_TYPE_HITTED",
  SET_IS_CREATE_PRINTER_TYPE_HITTED: "printer-type/SET_IS_CREATE_PRINTER_TYPE_HITTED",
  SET_IS_UPDATE_PRINTER_TYPE_HITTED: "printer-type/SET_IS_UPDATE_PRINTER_TYPE_HITTED",
  SET_IS_DELETE_PRINTER_TYPE_HITTED: "printer-type/SET_IS_DELETE_PRINTER_TYPE_HITTED",

  FETCH_PRINTER_TYPES_START: "printer-type/FETCH_PRINTER_TYPES_START",
  FETCH_SELECT_PRINTER_TYPES_START: "printer-type/FETCH_SELECT_PRINTER_TYPES_START",
  FETCH_PRINTER_TYPE_START: "printer-type/FETCH_PRINTER_TYPE_START",
  CREATE_PRINTER_TYPE_START: "printer-type/CREATE_PRINTER_TYPE_START",
  UPDATE_PRINTER_TYPE_START: "printer-type/UPDATE_PRINTER_TYPE_START",
  DELETE_PRINTER_TYPE_START: "printer-type/DELETE_PRINTER_TYPE_START",
};

export default PRINTER_TYPE_ACTION_TYPES;
