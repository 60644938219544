const SELECT_SUPPLIER_ACTION_TYPES = {
  RESET_SELECT_SUPPLIER_REDUCER:
    "select-supplier/RESET_SELECT_SUPPLIER_REDUCER",

  SET_SUPPLIERS: "select-supplier/SET_SUPPLIERS",
  SET_SEARCH_SUPPLIERS: "select-supplier/SET_SEARCH_SUPPLIERS",

  APPEND_SUPPLIERS: "select-supplier/APPEND_SUPPLIERS",
  APPEND_SEARCH_SUPPLIERS: "select-supplier/APPEND_SEARCH_SUPPLIERS",

  SET_IS_SUPPLIERS_HAS_MORE: "select-supplier/SET_IS_SUPPLIERS_HAS_MORE",
  SET_IS_SEARCH_SUPPLIERS_HAS_MORE:
    "select-supplier/SET_IS_SEARCH_SUPPLIERS_HAS_MORE",

  SET_FETCH_SUPPLIERS_PARAMS: "select-supplier/SET_FETCH_SUPPLIERS_PARAMS",
  SET_FETCH_SUPPLIERS_LOADING: "select-supplier/SET_FETCH_SUPPLIERS_LOADING",
  SET_FETCH_SUPPLIERS_SUCCESS: "select-supplier/SET_FETCH_SUPPLIERS_SUCCESS",
  SET_FETCH_SUPPLIERS_FAILED: "select-supplier/SET_FETCH_SUPPLIERS_FAILED",

  SET_SEARCH_SUPPLIERS_PARAMS: "select-supplier/SET_SEARCH_SUPPLIERS_PARAMS",
  SET_SEARCH_SUPPLIERS_LOADING: "select-supplier/SET_SEARCH_SUPPLIERS_LOADING",
  SET_SEARCH_SUPPLIERS_SUCCESS: "select-supplier/SET_SEARCH_SUPPLIERS_SUCCESS",
  SET_SEARCH_SUPPLIERS_FAILED: "select-supplier/SET_SEARCH_SUPPLIERS_FAILED",

  SET_APPEND_SUPPLIERS_PARAMS: "select-supplier/SET_APPEND_SUPPLIERS_PARAMS",
  SET_APPEND_SUPPLIERS_LOADING: "select-supplier/SET_APPEND_SUPPLIERS_LOADING",
  SET_APPEND_SUPPLIERS_SUCCESS: "select-supplier/SET_APPEND_SUPPLIERS_SUCCESS",
  SET_APPEND_SUPPLIERS_FAILED: "select-supplier/SET_APPEND_SUPPLIERS_FAILED",

  SET_IS_FETCH_SUPPLIERS_HITTED:
    "select-supplier/SET_IS_FETCH_SUPPLIERS_HITTED",
  SET_IS_SEARCH_SUPPLIERS_HITTED:
    "select-supplier/SET_IS_SEARCH_SUPPLIERS_HITTED",
  SET_IS_APPEND_SUPPLIERS_HITTED:
    "select-supplier/SET_IS_APPEND_SUPPLIERS_HITTED",

  FETCH_SUPPLIERS_START: "select-supplier/FETCH_SUPPLIERS_START",
  SEARCH_SUPPLIERS_START: "select-supplier/SEARCH_SUPPLIERS_START",
  APPEND_SUPPLIERS_START: "select-supplier/APPEND_SUPPLIERS_START",
};

export default SELECT_SUPPLIER_ACTION_TYPES;
