import { takeLatest, put, all, call, select } from "redux-saga/effects";

import RESTOCK_REQUEST_ACTION_TYPES from "./restock-request.type";

import {
  appendRestockRequests,
  setCreateRestockRequestFailed,
  setCreateRestockRequestLoading,
  setCreateRestockRequestSuccess,
  setDeleteRestockRequestFailed,
  setDeleteRestockRequestLoading,
  setDeleteRestockRequestSuccess,
  setFetchRestockRequestFailed,
  setFetchRestockRequestLoading,
  setFetchRestockRequestsFailed,
  setFetchRestockRequestsLoading,
  setFetchRestockRequestsPage,
  setFetchRestockRequestsSuccess,
  setFetchRestockRequestSuccess,
  setIsCreateRestockRequestHitted,
  setIsDeleteRestockRequestHitted,
  setIsFetchRestockRequestHitted,
  setIsFetchRestockRequestsHitted,
  setIsRestockRequestsHasMore,
  setIsUpdateRestockRequestHitted,
  setRestockRequest,
  setRestockRequests,
  setUpdateRestockRequestFailed,
  setUpdateRestockRequestLoading,
  setUpdateRestockRequestSuccess,
} from "./restock-request.action";
import {
  getFetchRestockRequestsPage,
  getFetchRestockRequestsPerPage,
  getFetchRestockRequestsSearch,
  getFetchRestockRequestsIncludes,
  getFetchRestockRequestsFilterMarketId,
  getFetchRestockRequestsFilterBranchId,
  getFetchRestockRequestsFilterStatuses,
  getFetchRestockRequestsSort,
  getFetchRestockRequestsKeyBy,
  getIsFetchRestockRequestsHitted,
} from "./restock-request.selector";

import {
  getRestockRequests,
  getRestockRequest,
  createRestockRequest,
  updateRestockRequest,
  deleteRestockRequest,
} from "../../api/restock-request.api";

export function* _getRestockRequests() {
  try {
    yield put(setFetchRestockRequestsLoading(true));

    const search = yield select(getFetchRestockRequestsSearch);
    const sort = yield select(getFetchRestockRequestsSort);
    const key_by = yield select(getFetchRestockRequestsKeyBy);
    const page = yield select(getFetchRestockRequestsPage);
    const per_page = yield select(getFetchRestockRequestsPerPage);
    const includes = yield select(getFetchRestockRequestsIncludes);
    const market_id = yield select(getFetchRestockRequestsFilterMarketId);
    const branch_id = yield select(getFetchRestockRequestsFilterBranchId);
    const statuses = yield select(getFetchRestockRequestsFilterStatuses);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id, branch_id, statuses },
    };

    const {
      meta: { message },
      data: { data: restockRequests },
    } = yield call(getRestockRequests, parameters);

    yield put(setIsFetchRestockRequestsHitted(true));
    yield put(setIsRestockRequestsHasMore(restockRequests.length > 0));

    if (page > 1) {
      yield put(appendRestockRequests(restockRequests));
    } else {
      yield put(setRestockRequests(restockRequests));
    }

    yield put(setFetchRestockRequestsSuccess(message));
    yield put(setFetchRestockRequestsLoading(false));
  } catch (error) {
    yield put(setFetchRestockRequestsFailed(error));
    yield put(setFetchRestockRequestsLoading(false));
  }
}
export function* _getRestockRequest({ payload: restockRequestId }) {
  try {
    yield put(setFetchRestockRequestLoading(true));

    const {
      meta: { message },
      data: restockRequest,
    } = yield call(getRestockRequest, restockRequestId);

    yield put(setIsFetchRestockRequestHitted(true));
    yield put(setRestockRequest(restockRequest));

    yield put(setFetchRestockRequestSuccess(message));
    yield put(setFetchRestockRequestLoading(false));
  } catch (error) {
    yield put(setFetchRestockRequestFailed(error));
    yield put(setFetchRestockRequestLoading(false));
  }
}
export function* _createRestockRequest({ payload: request }) {
  try {
    yield put(setCreateRestockRequestLoading(true));

    const {
      meta: { message },
    } = yield call(createRestockRequest, request);

    yield put(setIsCreateRestockRequestHitted(true));

    const isFetchRestockRequestsHitted = yield select(
      getIsFetchRestockRequestsHitted
    );

    if (isFetchRestockRequestsHitted) {
      yield put(setFetchRestockRequestsPage(1));
      yield call(_getRestockRequests);
    }

    yield put(setCreateRestockRequestSuccess(message));
    yield put(setCreateRestockRequestLoading(false));
  } catch (error) {
    yield put(setCreateRestockRequestFailed(error));
    yield put(setCreateRestockRequestLoading(false));
  }
}
export function* _updateRestockRequest({
  payload: { restockRequestId, request },
}) {
  try {
    yield put(setUpdateRestockRequestLoading(true));

    const {
      meta: { message },
    } = yield call(updateRestockRequest, restockRequestId, request);

    yield put(setIsUpdateRestockRequestHitted(true));

    const isFetchRestockRequestsHitted = yield select(
      getIsFetchRestockRequestsHitted
    );

    if (isFetchRestockRequestsHitted) {
      yield put(setFetchRestockRequestsPage(1));
      yield call(_getRestockRequests);
    }

    yield put(setUpdateRestockRequestSuccess(message));
    yield put(setUpdateRestockRequestLoading(false));
  } catch (error) {
    yield put(setUpdateRestockRequestFailed(error));
    yield put(setUpdateRestockRequestLoading(false));
  }
}
export function* _deleteRestockRequest({ payload: restockRequestId }) {
  try {
    yield put(setDeleteRestockRequestLoading(true));

    const {
      meta: { message },
    } = yield call(deleteRestockRequest, restockRequestId);

    yield put(setIsDeleteRestockRequestHitted(true));

    const isFetchRestockRequestsHitted = yield select(
      getIsFetchRestockRequestsHitted
    );

    if (isFetchRestockRequestsHitted) {
      yield put(setFetchRestockRequestsPage(1));
      yield call(_getRestockRequests);
    }

    yield put(setDeleteRestockRequestSuccess(message));
    yield put(setDeleteRestockRequestLoading(false));
  } catch (error) {
    yield put(setDeleteRestockRequestFailed(error));
    yield put(setDeleteRestockRequestLoading(false));
  }
}

export function* onFetchRestockRequestsStart() {
  yield takeLatest(
    RESTOCK_REQUEST_ACTION_TYPES.FETCH_RESTOCK_REQUESTS_START,
    _getRestockRequests
  );
}
export function* onFetchRestockRequestStart() {
  yield takeLatest(
    RESTOCK_REQUEST_ACTION_TYPES.FETCH_RESTOCK_REQUEST_START,
    _getRestockRequest
  );
}
export function* onCreateRestockRequestStart() {
  yield takeLatest(
    RESTOCK_REQUEST_ACTION_TYPES.CREATE_RESTOCK_REQUEST_START,
    _createRestockRequest
  );
}
export function* onUpdateRestockRequestStart() {
  yield takeLatest(
    RESTOCK_REQUEST_ACTION_TYPES.UPDATE_RESTOCK_REQUEST_START,
    _updateRestockRequest
  );
}
export function* onDeleteRestockRequestStart() {
  yield takeLatest(
    RESTOCK_REQUEST_ACTION_TYPES.DELETE_RESTOCK_REQUEST_START,
    _deleteRestockRequest
  );
}

export function* restockRequestSaga() {
  yield all([
    call(onFetchRestockRequestsStart),
    call(onFetchRestockRequestStart),
    call(onCreateRestockRequestStart),
    call(onUpdateRestockRequestStart),
    call(onDeleteRestockRequestStart),
  ]);
}
