import { isEmpty, isValidUrl } from "./validation.utils";

export const createAction = (type, payload) => ({ type, payload });

export const getParamsWithDefault = (params = {}) => {
  if (isEmpty(params?.page)) {
    params.page = 1;
  }
  if (isEmpty(params?.per_page)) {
    params.per_page = 20;
  }
  if (isEmpty(params?.filter)) {
    params.filter = {};
  }

  return params;
};

export const getValidUrls = (urls) => {
  if (!Array.isArray(urls)) return [];
  return urls.filter((url) => isValidUrl(url));
};

export const getValidFiles = (files) => {
  if (!Array.isArray(files)) return [];
  return files.filter((file) => file instanceof File);
};
