import KITCHEN_GROUP_ACTION_TYPES from "./kitchen-group.type";
import { createAction } from "../../utils/store.utils";

export const setKitchenGroups = (kitchenGroups) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_KITCHEN_GROUPS, kitchenGroups);

export const setSelectKitchenGroups = (selectKitchenGroups) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_SELECT_KITCHEN_GROUPS, selectKitchenGroups);

export const setKitchenGroup = (kitchenGroup) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_KITCHEN_GROUP, kitchenGroup);

export const setIsKitchenGroupsHasMore = (isKitchenGroupsHasMore) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_IS_KITCHEN_GROUPS_HAS_MORE, isKitchenGroupsHasMore);

export const setIsSelectKitchenGroupsHasMore = (isSelectKitchenGroupsHasMore) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_IS_SELECT_KITCHEN_GROUPS_HAS_MORE, isSelectKitchenGroupsHasMore);

export const setFetchKitchenGroupsSearch = (fetchKitchenGroupsSearch) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_SEARCH, fetchKitchenGroupsSearch);

export const setFetchKitchenGroupsPage = (fetchKitchenGroupsPage) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_PAGE, fetchKitchenGroupsPage);

export const setFetchKitchenGroupsPerPage = (fetchKitchenGroupsPerPage) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_PER_PAGE, fetchKitchenGroupsPerPage);

export const setFetchKitchenGroupsIncludes = (fetchKitchenGroupsIncludes) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_INCLUDES, fetchKitchenGroupsIncludes);

export const setFetchKitchenGroupsFilterMarketId = (fetchKitchenGroupsFilterMarketId) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_FILTER_MARKET_ID, fetchKitchenGroupsFilterMarketId);

export const setFetchKitchenGroupsFilterBranchId = (fetchKitchenGroupsFilterBranchId) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_FILTER_BRANCH_ID, fetchKitchenGroupsFilterBranchId);

export const setFetchKitchenGroupsLoading = (fetchKitchenGroupsLoading) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_LOADING, fetchKitchenGroupsLoading);

export const setFetchKitchenGroupsSuccess = (fetchKitchenGroupsSuccess) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_SUCCESS, fetchKitchenGroupsSuccess);

export const setFetchKitchenGroupsFailed = (fetchKitchenGroupsFailed) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_FAILED, fetchKitchenGroupsFailed);

export const setFetchSelectKitchenGroupsPage = (fetchSelectKitchenGroupsPage) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_PAGE, fetchSelectKitchenGroupsPage);

export const setFetchSelectKitchenGroupsPerPage = (fetchSelectKitchenGroupsPerPage) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_PER_PAGE, fetchSelectKitchenGroupsPerPage);

export const setFetchSelectKitchenGroupsIncludes = (fetchSelectKitchenGroupsIncludes) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_INCLUDES, fetchSelectKitchenGroupsIncludes);

export const setFetchSelectKitchenGroupsFilterMarketId = (fetchSelectKitchenGroupsFilterMarketId) =>
  createAction(
    KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_FILTER_MARKET_ID,
    fetchSelectKitchenGroupsFilterMarketId
  );

export const setFetchSelectKitchenGroupsFilterBranchId = (fetchSelectKitchenGroupsFilterBranchId) =>
  createAction(
    KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_FILTER_BRANCH_ID,
    fetchSelectKitchenGroupsFilterBranchId
  );

export const setFetchSelectKitchenGroupsLoading = (fetchSelectKitchenGroupsLoading) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_LOADING, fetchSelectKitchenGroupsLoading);

export const setFetchSelectKitchenGroupsSuccess = (fetchSelectKitchenGroupsSuccess) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_SUCCESS, fetchSelectKitchenGroupsSuccess);

export const setFetchSelectKitchenGroupsFailed = (fetchSelectKitchenGroupsFailed) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_FAILED, fetchSelectKitchenGroupsFailed);

export const setFetchKitchenGroupLoading = (fetchKitchenGroupLoading) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUP_LOADING, fetchKitchenGroupLoading);

export const setFetchKitchenGroupSuccess = (fetchKitchenGroupSuccess) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUP_SUCCESS, fetchKitchenGroupSuccess);

export const setFetchKitchenGroupFailed = (fetchKitchenGroupFailed) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUP_FAILED, fetchKitchenGroupFailed);

export const setCreateKitchenGroupLoading = (createKitchenGroupLoading) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_CREATE_KITCHEN_GROUP_LOADING, createKitchenGroupLoading);

export const setCreateKitchenGroupSuccess = (createKitchenGroupSuccess) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_CREATE_KITCHEN_GROUP_SUCCESS, createKitchenGroupSuccess);

export const setCreateKitchenGroupFailed = (createKitchenGroupFailed) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_CREATE_KITCHEN_GROUP_FAILED, createKitchenGroupFailed);

export const setUpdateKitchenGroupLoading = (updateKitchenGroupLoading) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_UPDATE_KITCHEN_GROUP_LOADING, updateKitchenGroupLoading);

export const setUpdateKitchenGroupSuccess = (updateKitchenGroupSuccess) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_UPDATE_KITCHEN_GROUP_SUCCESS, updateKitchenGroupSuccess);

export const setUpdateKitchenGroupFailed = (updateKitchenGroupFailed) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_UPDATE_KITCHEN_GROUP_FAILED, updateKitchenGroupFailed);

export const setDeleteKitchenGroupLoading = (deleteKitchenGroupLoading) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_DELETE_KITCHEN_GROUP_LOADING, deleteKitchenGroupLoading);

export const setDeleteKitchenGroupSuccess = (deleteKitchenGroupSuccess) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_DELETE_KITCHEN_GROUP_SUCCESS, deleteKitchenGroupSuccess);

export const setDeleteKitchenGroupFailed = (deleteKitchenGroupFailed) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_DELETE_KITCHEN_GROUP_FAILED, deleteKitchenGroupFailed);

export const appendKitchenGroups = (kitchenGroups) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.APPEND_KITCHEN_GROUPS, kitchenGroups);

export const appendSelectKitchenGroups = (selectKitchenGroups) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.APPEND_SELECT_KITCHEN_GROUPS, selectKitchenGroups);

export const fetchKitchenGroupsStart = () => createAction(KITCHEN_GROUP_ACTION_TYPES.FETCH_KITCHEN_GROUPS_START);

export const fetchSelectKitchenGroupsStart = () =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.FETCH_SELECT_KITCHEN_GROUPS_START);

export const fetchKitchenGroupStart = (id) => createAction(KITCHEN_GROUP_ACTION_TYPES.FETCH_KITCHEN_GROUP_START, id);

export const createKitchenGroupStart = (request) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.CREATE_KITCHEN_GROUP_START, request);

export const updateKitchenGroupStart = (id, request) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.UPDATE_KITCHEN_GROUP_START, { id, request });

export const deleteKitchenGroupStart = (id) => createAction(KITCHEN_GROUP_ACTION_TYPES.DELETE_KITCHEN_GROUP_START, id);

export const setIsFetchKitchenGroupsHitted = (isFetchKitchenGroupsHitted) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_IS_FETCH_KITCHEN_GROUPS_HITTED, isFetchKitchenGroupsHitted);

export const setIsFetchSelectKitchenGroupsHitted = (isFetchSelectKitchenGroupsHitted) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_IS_FETCH_SELECT_KITCHEN_GROUPS_HITTED, isFetchSelectKitchenGroupsHitted);

export const setIsFetchKitchenGroupHitted = (isFetchKitchenGroupHitted) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_IS_FETCH_KITCHEN_GROUP_HITTED, isFetchKitchenGroupHitted);

export const setIsCreateKitchenGroupHitted = (isCreateKitchenGroupHitted) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_IS_CREATE_KITCHEN_GROUP_HITTED, isCreateKitchenGroupHitted);

export const setIsUpdateKitchenGroupHitted = (isUpdateKitchenGroupHitted) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_IS_UPDATE_KITCHEN_GROUP_HITTED, isUpdateKitchenGroupHitted);

export const setIsDeleteKitchenGroupHitted = (isDeleteKitchenGroupHitted) =>
  createAction(KITCHEN_GROUP_ACTION_TYPES.SET_IS_DELETE_KITCHEN_GROUP_HITTED, isDeleteKitchenGroupHitted);

export const resetKitchenGroupReducer = () => createAction(KITCHEN_GROUP_ACTION_TYPES.RESET_KITCHEN_GROUP_REDUCER);
