import SUBSCRIBER_ACTION_TYPES from "./subscriber.type";

export const SUBSCRIBER_INITIAL_STATE = {
  subscribers: [],
  selectSubscribers: [],
  subscriber: null,
  subscriberReport: null,

  isSubscribersHasMore: true,
  isSelectSubscribersHasMore: true,

  fetchSubscribersSearch: null,
  fetchSubscribersPage: 1,
  fetchSubscribersPerPage: null,
  fetchSubscribersIncludes: null,
  fetchSubscribersFilterMarketId: null,
  fetchSubscribersFilterBranchId: null,
  fetchSubscribersFilterPermissionId: null,
  fetchSubscribersLoading: false,
  fetchSubscribersSuccess: null,
  fetchSubscribersFailed: null,

  fetchSelectSubscribersSearch: null,
  fetchSelectSubscribersPage: 1,
  fetchSelectSubscribersPerPage: null,
  fetchSelectSubscribersIncludes: null,
  fetchSelectSubscribersFilterMarketId: null,
  fetchSelectSubscribersFilterBranchId: null,
  fetchSelectSubscribersFilterPermissionId: null,
  fetchSelectSubscribersLoading: false,
  fetchSelectSubscribersSuccess: null,
  fetchSelectSubscribersFailed: null,

  fetchSubscriberLoading: false,
  fetchSubscriberSuccess: null,
  fetchSubscriberFailed: null,

  fetchSubscriberReportStartAt: false,
  fetchSubscriberReportEndAt: false,
  fetchSubscriberReportLoading: false,
  fetchSubscriberReportSuccess: null,
  fetchSubscriberReportFailed: null,

  createSubscriberLoading: false,
  createSubscriberSuccess: null,
  createSubscriberFailed: null,

  updateSubscriberLoading: false,
  updateSubscriberSuccess: null,
  updateSubscriberFailed: null,

  deleteSubscriberLoading: false,
  deleteSubscriberSuccess: null,
  deleteSubscriberFailed: null,

  isFetchSubscribersHitted: false,
  isFetchSelectSubscribersHitted: false,
  isFetchSubscriberReportHitted: false,
  isFetchSubscriberHitted: false,
  isCreateSubscriberHitted: false,
  isUpdateSubscriberHitted: false,
  isDeleteSubscriberHitted: false,
};

export const subscriberReducer = (state = SUBSCRIBER_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SUBSCRIBER_ACTION_TYPES.SET_SUBSCRIBERS:
      return { ...state, subscribers: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_SELECT_SUBSCRIBERS:
      return { ...state, selectSubscribers: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_SUBSCRIBER:
      return { ...state, subscriber: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_SUBSCRIBER_REPORT:
      return { ...state, subscriberReport: payload };

    case SUBSCRIBER_ACTION_TYPES.SET_IS_SUBSCRIBERS_HAS_MORE:
      return { ...state, isSubscribersHasMore: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_IS_SELECT_SUBSCRIBERS_HAS_MORE:
      return { ...state, isSelectSubscribersHasMore: payload };

    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_SEARCH:
      return { ...state, fetchSubscribersSearch: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_PAGE:
      return { ...state, fetchSubscribersPage: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_PER_PAGE:
      return { ...state, fetchSubscribersPerPage: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_INCLUDES:
      return { ...state, fetchSubscribersIncludes: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_FILTER_MARKET_ID:
      return { ...state, fetchSubscribersFilterMarketId: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_FILTER_BRANCH_ID:
      return { ...state, fetchSubscribersFilterBranchId: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_FILTER_PERMISSION_ID:
      return { ...state, fetchSubscribersFilterPermissionId: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_LOADING:
      return { ...state, fetchSubscribersLoading: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_SUCCESS:
      return { ...state, fetchSubscribersSuccess: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_FAILED:
      return { ...state, fetchSubscribersFailed: payload };

    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_SEARCH:
      return { ...state, fetchSelectSubscribersSearch: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_PAGE:
      return { ...state, fetchSelectSubscribersPage: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_PER_PAGE:
      return { ...state, fetchSelectSubscribersPerPage: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_INCLUDES:
      return { ...state, fetchSelectSubscribersIncludes: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_FILTER_MARKET_ID:
      return { ...state, fetchSelectSubscribersFilterMarketId: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_FILTER_BRANCH_ID:
      return { ...state, fetchSelectSubscribersFilterBranchId: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_FILTER_PERMISSION_ID:
      return { ...state, fetchSelectSubscribersFilterPermissionId: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_LOADING:
      return { ...state, fetchSelectSubscribersLoading: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_SUCCESS:
      return { ...state, fetchSelectSubscribersSuccess: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SELECT_SUBSCRIBERS_FAILED:
      return { ...state, fetchSelectSubscribersFailed: payload };

    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_LOADING:
      return { ...state, fetchSubscriberLoading: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_SUCCESS:
      return { ...state, fetchSubscriberSuccess: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_FAILED:
      return { ...state, fetchSubscriberFailed: payload };

    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_REPORT_START_AT:
      return { ...state, fetchSubscriberReportStartAt: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_REPORT_END_AT:
      return { ...state, fetchSubscriberReportEndAt: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_REPORT_LOADING:
      return { ...state, fetchSubscriberReportLoading: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_REPORT_SUCCESS:
      return { ...state, fetchSubscriberReportSuccess: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBER_REPORT_FAILED:
      return { ...state, fetchSubscriberReportFailed: payload };

    case SUBSCRIBER_ACTION_TYPES.SET_CREATE_SUBSCRIBER_LOADING:
      return { ...state, createSubscriberLoading: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_CREATE_SUBSCRIBER_SUCCESS:
      return { ...state, createSubscriberSuccess: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_CREATE_SUBSCRIBER_FAILED:
      return { ...state, createSubscriberFailed: payload };

    case SUBSCRIBER_ACTION_TYPES.SET_UPDATE_SUBSCRIBER_LOADING:
      return { ...state, updateSubscriberLoading: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_UPDATE_SUBSCRIBER_SUCCESS:
      return { ...state, updateSubscriberSuccess: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_UPDATE_SUBSCRIBER_FAILED:
      return { ...state, updateSubscriberFailed: payload };

    case SUBSCRIBER_ACTION_TYPES.SET_DELETE_SUBSCRIBER_LOADING:
      return { ...state, deleteSubscriberLoading: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_DELETE_SUBSCRIBER_SUCCESS:
      return { ...state, deleteSubscriberSuccess: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_DELETE_SUBSCRIBER_FAILED:
      return { ...state, deleteSubscriberFailed: payload };

    case SUBSCRIBER_ACTION_TYPES.APPEND_SUBSCRIBERS:
      return { ...state, subscribers: [...state.subscribers, ...payload] };
    case SUBSCRIBER_ACTION_TYPES.APPEND_SELECT_SUBSCRIBERS:
      return { ...state, selectSubscribers: [...state.selectSubscribers, ...payload] };

    case SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_SUBSCRIBERS_HITTED:
      return { ...state, isFetchSubscribersHitted: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_SELECT_SUBSCRIBERS_HITTED:
      return { ...state, isFetchSelectSubscribersHitted: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_SUBSCRIBER_REPORT_HITTED:
      return { ...state, isFetchSubscriberReportHitted: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_SUBSCRIBER_HITTED:
      return { ...state, isFetchSubscriberHitted: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_IS_CREATE_SUBSCRIBER_HITTED:
      return { ...state, isCreateSubscriberHitted: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_IS_UPDATE_SUBSCRIBER_HITTED:
      return { ...state, isUpdateSubscriberHitted: payload };
    case SUBSCRIBER_ACTION_TYPES.SET_IS_DELETE_SUBSCRIBER_HITTED:
      return { ...state, isDeleteSubscriberHitted: payload };

    case SUBSCRIBER_ACTION_TYPES.RESET_SUBSCRIBER_REDUCER:
      return SUBSCRIBER_INITIAL_STATE;
    default:
      return state;
  }
};
