export const ADVERTISEMENT_INCLUDES = {
  MARKET: "market",
  APPROVER: "approver",
  RECEIVERS: "receivers",
  TRANSACTION: "transaction",
};

export const ADVERTISEMENT_TYPES = {
  BANNER: "BANNER",
  SMS: "SMS",
  WHATSAPP: "WHATSAPP",
};

export const ADVERTISEMENT_STATUSES = {
  PROCESS: "PROCESS",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  SUCCESS: "SUCCESS",
};
