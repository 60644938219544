const PRINTER_ACTION_TYPES = {
  RESET_PRINTER_REDUCER: "printer/RESET_PRINTER_REDUCER",

  SET_PRINTERS: "printer/SET_PRINTERS",
  SET_PRINTER: "printer/SET_PRINTER",

  SET_IS_PRINTERS_HAS_MORE: "printer/SET_IS_PRINTERS_HAS_MORE",

  SET_FETCH_PRINTERS_SEARCH: "printer/SET_FETCH_PRINTERS_SEARCH",
  SET_FETCH_PRINTERS_PAGE: "printer/SET_FETCH_PRINTERS_PAGE",
  SET_FETCH_PRINTERS_PER_PAGE: "printer/SET_FETCH_PRINTERS_PER_PAGE",
  SET_FETCH_PRINTERS_INCLUDES: "printer/SET_FETCH_PRINTERS_INCLUDES",
  SET_FETCH_PRINTERS_FILTER_MARKET_ID: "printer/SET_FETCH_PRINTERS_FILTER_MARKET_ID",
  SET_FETCH_PRINTERS_FILTER_BRANCH_ID: "printer/SET_FETCH_PRINTERS_FILTER_BRANCH_ID",
  SET_FETCH_PRINTERS_FILTER_PRINTER_TYPE_ID: "printer/SET_FETCH_PRINTERS_FILTER_PRINTER_TYPE_ID",
  SET_FETCH_PRINTERS_FILTER_IS_ACTIVE: "printer/SET_FETCH_PRINTERS_FILTER_IS_ACTIVE",
  SET_FETCH_PRINTERS_LOADING: "printer/SET_FETCH_PRINTERS_LOADING",
  SET_FETCH_PRINTERS_SUCCESS: "printer/SET_FETCH_PRINTERS_SUCCESS",
  SET_FETCH_PRINTERS_FAILED: "printer/SET_FETCH_PRINTERS_FAILED",

  SET_FETCH_PRINTER_LOADING: "printer/SET_FETCH_PRINTER_LOADING",
  SET_FETCH_PRINTER_SUCCESS: "printer/SET_FETCH_PRINTER_SUCCESS",
  SET_FETCH_PRINTER_FAILED: "printer/SET_FETCH_PRINTER_FAILED",

  SET_CREATE_PRINTER_LOADING: "printer/SET_CREATE_PRINTER_LOADING",
  SET_CREATE_PRINTER_SUCCESS: "printer/SET_CREATE_PRINTER_SUCCESS",
  SET_CREATE_PRINTER_FAILED: "printer/SET_CREATE_PRINTER_FAILED",

  SET_UPDATE_PRINTER_LOADING: "printer/SET_UPDATE_PRINTER_LOADING",
  SET_UPDATE_PRINTER_SUCCESS: "printer/SET_UPDATE_PRINTER_SUCCESS",
  SET_UPDATE_PRINTER_FAILED: "printer/SET_UPDATE_PRINTER_FAILED",

  SET_DELETE_PRINTER_LOADING: "printer/SET_DELETE_PRINTER_LOADING",
  SET_DELETE_PRINTER_SUCCESS: "printer/SET_DELETE_PRINTER_SUCCESS",
  SET_DELETE_PRINTER_FAILED: "printer/SET_DELETE_PRINTER_FAILED",

  APPEND_PRINTERS: "printer/APPEND_PRINTERS",

  FETCH_PRINTERS_START: "printer/FETCH_PRINTERS_START",
  FETCH_PRINTER_START: "printer/FETCH_PRINTER_START",
  CREATE_PRINTER_START: "printer/CREATE_PRINTER_START",
  UPDATE_PRINTER_START: "printer/UPDATE_PRINTER_START",
  DELETE_PRINTER_START: "printer/DELETE_PRINTER_START",
};

export default PRINTER_ACTION_TYPES;
