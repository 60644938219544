const SELECT_COUNTRY_ACTION_TYPES = {
  RESET_SELECT_COUNTRY_REDUCER: "select-country/RESET_SELECT_COUNTRY_REDUCER",

  SET_COUNTRIES: "select-country/SET_COUNTRIES",
  SET_SEARCH_COUNTRIES: "select-country/SET_SEARCH_COUNTRIES",

  APPEND_COUNTRIES: "select-country/APPEND_COUNTRIES",
  APPEND_SEARCH_COUNTRIES: "select-country/APPEND_SEARCH_COUNTRIES",

  SET_IS_COUNTRIES_HAS_MORE: "select-country/SET_IS_COUNTRIES_HAS_MORE",
  SET_IS_SEARCH_COUNTRIES_HAS_MORE:
    "select-country/SET_IS_SEARCH_COUNTRIES_HAS_MORE",

  SET_FETCH_COUNTRIES_PARAMS: "select-country/SET_FETCH_COUNTRIES_PARAMS",
  SET_FETCH_COUNTRIES_LOADING: "select-country/SET_FETCH_COUNTRIES_LOADING",
  SET_FETCH_COUNTRIES_SUCCESS: "select-country/SET_FETCH_COUNTRIES_SUCCESS",
  SET_FETCH_COUNTRIES_FAILED: "select-country/SET_FETCH_COUNTRIES_FAILED",

  SET_SEARCH_COUNTRIES_PARAMS: "select-country/SET_SEARCH_COUNTRIES_PARAMS",
  SET_SEARCH_COUNTRIES_LOADING: "select-country/SET_SEARCH_COUNTRIES_LOADING",
  SET_SEARCH_COUNTRIES_SUCCESS: "select-country/SET_SEARCH_COUNTRIES_SUCCESS",
  SET_SEARCH_COUNTRIES_FAILED: "select-country/SET_SEARCH_COUNTRIES_FAILED",

  SET_APPEND_COUNTRIES_PARAMS: "select-country/SET_APPEND_COUNTRIES_PARAMS",
  SET_APPEND_COUNTRIES_LOADING: "select-country/SET_APPEND_COUNTRIES_LOADING",
  SET_APPEND_COUNTRIES_SUCCESS: "select-country/SET_APPEND_COUNTRIES_SUCCESS",
  SET_APPEND_COUNTRIES_FAILED: "select-country/SET_APPEND_COUNTRIES_FAILED",

  SET_IS_FETCH_COUNTRIES_HITTED: "select-country/SET_IS_FETCH_COUNTRIES_HITTED",
  SET_IS_SEARCH_COUNTRIES_HITTED:
    "select-country/SET_IS_SEARCH_COUNTRIES_HITTED",
  SET_IS_APPEND_COUNTRIES_HITTED:
    "select-country/SET_IS_APPEND_COUNTRIES_HITTED",

  FETCH_COUNTRIES_START: "select-country/FETCH_COUNTRIES_START",
  SEARCH_COUNTRIES_START: "select-country/SEARCH_COUNTRIES_START",
  APPEND_COUNTRIES_START: "select-country/APPEND_COUNTRIES_START",
};

export default SELECT_COUNTRY_ACTION_TYPES;
