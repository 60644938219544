import apiService from "./api";

export const getSubscribers = async (parameters) => (await apiService.get("/subscriber", parameters)).data;

export const getSubscriber = async (id) => (await apiService.get(`/subscriber/${id}`)).data;

export const createSubscriber = async (request) => (await apiService.post("/subscriber", request)).data;

export const updateSubscriber = async (id, request) => (await apiService.post(`/subscriber/${id}`, request)).data;

export const deleteSubscriber = async (id) => (await apiService.delete(`/subscriber/${id}`)).data;

export const getSubscriberReport = async (id, parameters) =>
  (await apiService.get(`/subscriber/${id}/report`, parameters)).data;
