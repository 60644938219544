const INGREDIENT_ACTION_TYPES = {
  RESET_INGREDIENT_REDUCER: "ingredient/RESET_INGREDIENT_REDUCER",

  SET_INGREDIENTS: "ingredient/SET_INGREDIENTS",
  SET_SELECT_INGREDIENTS: "ingredient/SET_SELECT_INGREDIENTS",
  SET_SELECT_GROUP_INGREDIENTS: "ingredient/SET_SELECT_GROUP_INGREDIENTS",
  SET_OUT_OF_STOCK_INGREDIENTS: "ingredient/SET_OUT_OF_STOCK_INGREDIENTS",
  SET_INGREDIENT: "ingredient/SET_INGREDIENT",

  SET_IS_INGREDIENTS_HAS_MORE: "ingredient/SET_IS_INGREDIENTS_HAS_MORE",
  SET_IS_SELECT_INGREDIENTS_HAS_MORE:
    "ingredient/SET_IS_SELECT_INGREDIENTS_HAS_MORE",
  SET_IS_SELECT_GROUP_INGREDIENTS_HAS_MORE:
    "ingredient/SET_IS_SELECT_GROUP_INGREDIENTS_HAS_MORE",
  SET_IS_OUT_OF_STOCK_INGREDIENTS_HAS_MORE:
    "ingredient/SET_IS_OUT_OF_STOCK_INGREDIENTS_HAS_MORE",

  SET_FETCH_INGREDIENTS_SEARCH: "ingredient/SET_FETCH_INGREDIENTS_SEARCH",
  SET_FETCH_INGREDIENTS_SORT: "ingredient/SET_FETCH_INGREDIENTS_SORT",
  SET_FETCH_INGREDIENTS_KEY_BY: "ingredient/SET_FETCH_INGREDIENTS_KEY_BY",
  SET_FETCH_INGREDIENTS_PAGE: "ingredient/SET_FETCH_INGREDIENTS_PAGE",
  SET_FETCH_INGREDIENTS_PER_PAGE: "ingredient/SET_FETCH_INGREDIENTS_PER_PAGE",
  SET_FETCH_INGREDIENTS_INCLUDES: "ingredient/SET_FETCH_INGREDIENTS_INCLUDES",
  SET_FETCH_INGREDIENTS_FILTER_MARKET_ID:
    "ingredient/SET_FETCH_INGREDIENTS_FILTER_MARKET_ID",
  SET_FETCH_INGREDIENTS_FILTER_BRANCH_ID:
    "ingredient/SET_FETCH_INGREDIENTS_FILTER_BRANCH_ID",
  SET_FETCH_INGREDIENTS_FILTER_IS_OUT_OF_STOCK:
    "ingredient/SET_FETCH_INGREDIENTS_FILTER_IS_OUT_OF_STOCK",
  SET_FETCH_INGREDIENTS_LOADING: "ingredient/SET_FETCH_INGREDIENTS_LOADING",
  SET_FETCH_INGREDIENTS_SUCCESS: "ingredient/SET_FETCH_INGREDIENTS_SUCCESS",
  SET_FETCH_INGREDIENTS_FAILED: "ingredient/SET_FETCH_INGREDIENTS_FAILED",

  SET_FETCH_SELECT_INGREDIENTS_SEARCH:
    "ingredient/SET_FETCH_SELECT_INGREDIENTS_SEARCH",
  SET_FETCH_SELECT_INGREDIENTS_SORT:
    "ingredient/SET_FETCH_SELECT_INGREDIENTS_SORT",
  SET_FETCH_SELECT_INGREDIENTS_KEY_BY:
    "ingredient/SET_FETCH_SELECT_INGREDIENTS_KEY_BY",
  SET_FETCH_SELECT_INGREDIENTS_PAGE:
    "ingredient/SET_FETCH_SELECT_INGREDIENTS_PAGE",
  SET_FETCH_SELECT_INGREDIENTS_PER_PAGE:
    "ingredient/SET_FETCH_SELECT_INGREDIENTS_PER_PAGE",
  SET_FETCH_SELECT_INGREDIENTS_INCLUDES:
    "ingredient/SET_FETCH_SELECT_INGREDIENTS_INCLUDES",
  SET_FETCH_SELECT_INGREDIENTS_FILTER_MARKET_ID:
    "ingredient/SET_FETCH_SELECT_INGREDIENTS_FILTER_MARKET_ID",
  SET_FETCH_SELECT_INGREDIENTS_FILTER_BRANCH_ID:
    "ingredient/SET_FETCH_SELECT_INGREDIENTS_FILTER_BRANCH_ID",
  SET_FETCH_SELECT_INGREDIENTS_FILTER_INGREDIENTS_IDS:
    "ingredient/SET_FETCH_SELECT_INGREDIENTS_FILTER_INGREDIENTS_IDS",
  SET_FETCH_SELECT_INGREDIENTS_FILTER_IS_GROUP:
    "ingredient/SET_FETCH_SELECT_INGREDIENTS_FILTER_IS_GROUP",
  SET_FETCH_SELECT_INGREDIENTS_LOADING:
    "ingredient/SET_FETCH_SELECT_INGREDIENTS_LOADING",
  SET_FETCH_SELECT_INGREDIENTS_SUCCESS:
    "ingredient/SET_FETCH_SELECT_INGREDIENTS_SUCCESS",
  SET_FETCH_SELECT_INGREDIENTS_FAILED:
    "ingredient/SET_FETCH_SELECT_INGREDIENTS_FAILED",

  SET_FETCH_SELECT_GROUP_INGREDIENTS_SEARCH:
    "ingredient/SET_FETCH_SELECT_GROUP_INGREDIENTS_SEARCH",
  SET_FETCH_SELECT_GROUP_INGREDIENTS_SORT:
    "ingredient/SET_FETCH_SELECT_GROUP_INGREDIENTS_SORT",
  SET_FETCH_SELECT_GROUP_INGREDIENTS_KEY_BY:
    "ingredient/SET_FETCH_SELECT_GROUP_INGREDIENTS_KEY_BY",
  SET_FETCH_SELECT_GROUP_INGREDIENTS_PAGE:
    "ingredient/SET_FETCH_SELECT_GROUP_INGREDIENTS_PAGE",
  SET_FETCH_SELECT_GROUP_INGREDIENTS_PER_PAGE:
    "ingredient/SET_FETCH_SELECT_GROUP_INGREDIENTS_PER_PAGE",
  SET_FETCH_SELECT_GROUP_INGREDIENTS_INCLUDES:
    "ingredient/SET_FETCH_SELECT_GROUP_INGREDIENTS_INCLUDES",
  SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_MARKET_ID:
    "ingredient/SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_MARKET_ID",
  SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_BRANCH_ID:
    "ingredient/SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_BRANCH_ID",
  SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_INGREDIENTS_IDS:
    "ingredient/SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_INGREDIENTS_IDS",
  SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_IS_GROUP:
    "ingredient/SET_FETCH_SELECT_GROUP_INGREDIENTS_FILTER_IS_GROUP",
  SET_FETCH_SELECT_GROUP_INGREDIENTS_LOADING:
    "ingredient/SET_FETCH_SELECT_GROUP_INGREDIENTS_LOADING",
  SET_FETCH_SELECT_GROUP_INGREDIENTS_SUCCESS:
    "ingredient/SET_FETCH_SELECT_GROUP_INGREDIENTS_SUCCESS",
  SET_FETCH_SELECT_GROUP_INGREDIENTS_FAILED:
    "ingredient/SET_FETCH_SELECT_GROUP_INGREDIENTS_FAILED",

  SET_FETCH_OUT_OF_STOCK_INGREDIENTS_SEARCH:
    "ingredient/SET_FETCH_OUT_OF_STOCK_INGREDIENTS_SEARCH",
  SET_FETCH_OUT_OF_STOCK_INGREDIENTS_SORT:
    "ingredient/SET_FETCH_OUT_OF_STOCK_INGREDIENTS_SORT",
  SET_FETCH_OUT_OF_STOCK_INGREDIENTS_KEY_BY:
    "ingredient/SET_FETCH_OUT_OF_STOCK_INGREDIENTS_KEY_BY",
  SET_FETCH_OUT_OF_STOCK_INGREDIENTS_PAGE:
    "ingredient/SET_FETCH_OUT_OF_STOCK_INGREDIENTS_PAGE",
  SET_FETCH_OUT_OF_STOCK_INGREDIENTS_PER_PAGE:
    "ingredient/SET_FETCH_OUT_OF_STOCK_INGREDIENTS_PER_PAGE",
  SET_FETCH_OUT_OF_STOCK_INGREDIENTS_INCLUDES:
    "ingredient/SET_FETCH_OUT_OF_STOCK_INGREDIENTS_INCLUDES",
  SET_FETCH_OUT_OF_STOCK_INGREDIENTS_FILTER_MARKET_ID:
    "ingredient/SET_FETCH_OUT_OF_STOCK_INGREDIENTS_FILTER_MARKET_ID",
  SET_FETCH_OUT_OF_STOCK_INGREDIENTS_FILTER_BRANCH_ID:
    "ingredient/SET_FETCH_OUT_OF_STOCK_INGREDIENTS_FILTER_BRANCH_ID",
  SET_FETCH_OUT_OF_STOCK_INGREDIENTS_LOADING:
    "ingredient/SET_FETCH_OUT_OF_STOCK_INGREDIENTS_LOADING",
  SET_FETCH_OUT_OF_STOCK_INGREDIENTS_SUCCESS:
    "ingredient/SET_FETCH_OUT_OF_STOCK_INGREDIENTS_SUCCESS",
  SET_FETCH_OUT_OF_STOCK_INGREDIENTS_FAILED:
    "ingredient/SET_FETCH_OUT_OF_STOCK_INGREDIENTS_FAILED",

  SET_APPEND_SELECT_INGREDIENTS_SEARCH:
    "ingredient/SET_APPEND_SELECT_INGREDIENTS_SEARCH",
  SET_APPEND_SELECT_INGREDIENTS_SORT:
    "ingredient/SET_APPEND_SELECT_INGREDIENTS_SORT",
  SET_APPEND_SELECT_INGREDIENTS_KEY_BY:
    "ingredient/SET_APPEND_SELECT_INGREDIENTS_KEY_BY",
  SET_APPEND_SELECT_INGREDIENTS_PAGE:
    "ingredient/SET_APPEND_SELECT_INGREDIENTS_PAGE",
  SET_APPEND_SELECT_INGREDIENTS_PER_PAGE:
    "ingredient/SET_APPEND_SELECT_INGREDIENTS_PER_PAGE",
  SET_APPEND_SELECT_INGREDIENTS_INCLUDES:
    "ingredient/SET_APPEND_SELECT_INGREDIENTS_INCLUDES",
  SET_APPEND_SELECT_INGREDIENTS_FILTER_MARKET_ID:
    "ingredient/SET_APPEND_SELECT_INGREDIENTS_FILTER_MARKET_ID",
  SET_APPEND_SELECT_INGREDIENTS_FILTER_BRANCH_ID:
    "ingredient/SET_APPEND_SELECT_INGREDIENTS_FILTER_BRANCH_ID",
  SET_APPEND_SELECT_INGREDIENTS_FILTER_INGREDIENTS_IDS:
    "ingredient/SET_APPEND_SELECT_INGREDIENTS_FILTER_INGREDIENTS_IDS",
  SET_APPEND_SELECT_INGREDIENTS_FILTER_IS_GROUP:
    "ingredient/SET_APPEND_SELECT_INGREDIENTS_FILTER_IS_GROUP",
  SET_APPEND_SELECT_INGREDIENTS_LOADING:
    "ingredient/SET_APPEND_SELECT_INGREDIENTS_LOADING",
  SET_APPEND_SELECT_INGREDIENTS_SUCCESS:
    "ingredient/SET_APPEND_SELECT_INGREDIENTS_SUCCESS",
  SET_APPEND_SELECT_INGREDIENTS_FAILED:
    "ingredient/SET_APPEND_SELECT_INGREDIENTS_FAILED",

  SET_APPEND_SELECT_GROUP_INGREDIENTS_SEARCH:
    "ingredient/SET_APPEND_SELECT_GROUP_INGREDIENTS_SEARCH",
  SET_APPEND_SELECT_GROUP_INGREDIENTS_SORT:
    "ingredient/SET_APPEND_SELECT_GROUP_INGREDIENTS_SORT",
  SET_APPEND_SELECT_GROUP_INGREDIENTS_KEY_BY:
    "ingredient/SET_APPEND_SELECT_GROUP_INGREDIENTS_KEY_BY",
  SET_APPEND_SELECT_GROUP_INGREDIENTS_PAGE:
    "ingredient/SET_APPEND_SELECT_GROUP_INGREDIENTS_PAGE",
  SET_APPEND_SELECT_GROUP_INGREDIENTS_PER_PAGE:
    "ingredient/SET_APPEND_SELECT_GROUP_INGREDIENTS_PER_PAGE",
  SET_APPEND_SELECT_GROUP_INGREDIENTS_INCLUDES:
    "ingredient/SET_APPEND_SELECT_GROUP_INGREDIENTS_INCLUDES",
  SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_MARKET_ID:
    "ingredient/SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_MARKET_ID",
  SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_BRANCH_ID:
    "ingredient/SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_BRANCH_ID",
  SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_INGREDIENTS_IDS:
    "ingredient/SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_INGREDIENTS_IDS",
  SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_IS_GROUP:
    "ingredient/SET_APPEND_SELECT_GROUP_INGREDIENTS_FILTER_IS_GROUP",
  SET_APPEND_SELECT_GROUP_INGREDIENTS_LOADING:
    "ingredient/SET_APPEND_SELECT_GROUP_INGREDIENTS_LOADING",
  SET_APPEND_SELECT_GROUP_INGREDIENTS_SUCCESS:
    "ingredient/SET_APPEND_SELECT_GROUP_INGREDIENTS_SUCCESS",
  SET_APPEND_SELECT_GROUP_INGREDIENTS_FAILED:
    "ingredient/SET_APPEND_SELECT_GROUP_INGREDIENTS_FAILED",

  SET_FETCH_INGREDIENT_LOADING: "ingredient/SET_FETCH_INGREDIENT_LOADING",
  SET_FETCH_INGREDIENT_SUCCESS: "ingredient/SET_FETCH_INGREDIENT_SUCCESS",
  SET_FETCH_INGREDIENT_FAILED: "ingredient/SET_FETCH_INGREDIENT_FAILED",

  SET_CREATE_INGREDIENT_LOADING: "ingredient/SET_CREATE_INGREDIENT_LOADING",
  SET_CREATE_INGREDIENT_SUCCESS: "ingredient/SET_CREATE_INGREDIENT_SUCCESS",
  SET_CREATE_INGREDIENT_FAILED: "ingredient/SET_CREATE_INGREDIENT_FAILED",

  SET_UPDATE_INGREDIENT_LOADING: "ingredient/SET_UPDATE_INGREDIENT_LOADING",
  SET_UPDATE_INGREDIENT_SUCCESS: "ingredient/SET_UPDATE_INGREDIENT_SUCCESS",
  SET_UPDATE_INGREDIENT_FAILED: "ingredient/SET_UPDATE_INGREDIENT_FAILED",

  SET_DELETE_INGREDIENT_LOADING: "ingredient/SET_DELETE_INGREDIENT_LOADING",
  SET_DELETE_INGREDIENT_SUCCESS: "ingredient/SET_DELETE_INGREDIENT_SUCCESS",
  SET_DELETE_INGREDIENT_FAILED: "ingredient/SET_DELETE_INGREDIENT_FAILED",

  SET_STORE_STOCK_INGREDIENT_LOADING:
    "ingredient/SET_STORE_STOCK_INGREDIENT_LOADING",
  SET_STORE_STOCK_INGREDIENT_SUCCESS:
    "ingredient/SET_STORE_STOCK_INGREDIENT_SUCCESS",
  SET_STORE_STOCK_INGREDIENT_FAILED:
    "ingredient/SET_STORE_STOCK_INGREDIENT_FAILED",

  SET_REDUCE_STOCK_INGREDIENT_LOADING:
    "ingredient/SET_REDUCE_STOCK_INGREDIENT_LOADING",
  SET_REDUCE_STOCK_INGREDIENT_SUCCESS:
    "ingredient/SET_REDUCE_STOCK_INGREDIENT_SUCCESS",
  SET_REDUCE_STOCK_INGREDIENT_FAILED:
    "ingredient/SET_REDUCE_STOCK_INGREDIENT_FAILED",

  APPEND_INGREDIENTS: "ingredient/APPEND_INGREDIENTS",
  APPEND_SELECT_INGREDIENTS: "ingredient/APPEND_SELECT_INGREDIENTS",
  APPEND_SELECT_GROUP_INGREDIENTS: "ingredient/APPEND_SELECT_GROUP_INGREDIENTS",
  APPEND_OUT_OF_STOCK_INGREDIENTS: "ingredient/APPEND_OUT_OF_STOCK_INGREDIENTS",

  SET_IS_FETCH_INGREDIENTS_HITTED: "ingredient/SET_IS_FETCH_INGREDIENTS_HITTED",
  SET_IS_FETCH_SELECT_INGREDIENTS_HITTED:
    "ingredient/SET_IS_FETCH_SELECT_INGREDIENTS_HITTED",
  SET_IS_FETCH_SELECT_GROUP_INGREDIENTS_HITTED:
    "ingredient/SET_IS_FETCH_SELECT_GROUP_INGREDIENTS_HITTED",
  SET_IS_FETCH_OUT_OF_STOCK_INGREDIENTS_HITTED:
    "ingredient/SET_IS_FETCH_OUT_OF_STOCK_INGREDIENTS_HITTED",
  SET_IS_APPEND_SELECT_INGREDIENTS_HITTED:
    "ingredient/SET_IS_APPEND_SELECT_INGREDIENTS_HITTED",
  SET_IS_APPEND_SELECT_GROUP_INGREDIENTS_HITTED:
    "ingredient/SET_IS_APPEND_SELECT_GROUP_INGREDIENTS_HITTED",
  SET_IS_FETCH_INGREDIENT_HITTED: "ingredient/SET_IS_FETCH_INGREDIENT_HITTED",
  SET_IS_CREATE_INGREDIENT_HITTED: "ingredient/SET_IS_CREATE_INGREDIENT_HITTED",
  SET_IS_UPDATE_INGREDIENT_HITTED: "ingredient/SET_IS_UPDATE_INGREDIENT_HITTED",
  SET_IS_DELETE_INGREDIENT_HITTED: "ingredient/SET_IS_DELETE_INGREDIENT_HITTED",
  SET_IS_STORE_STOCK_INGREDIENT_HITTED:
    "ingredient/SET_IS_STORE_STOCK_INGREDIENT_HITTED",
  SET_IS_REDUCE_STOCK_INGREDIENT_HITTED:
    "ingredient/SET_IS_REDUCE_STOCK_INGREDIENT_HITTED",

  FETCH_INGREDIENTS_START: "ingredient/FETCH_INGREDIENTS_START",
  FETCH_SELECT_INGREDIENTS_START: "ingredient/FETCH_SELECT_INGREDIENTS_START",
  FETCH_SELECT_GROUP_INGREDIENTS_START:
    "ingredient/FETCH_SELECT_GROUP_INGREDIENTS_START",
  FETCH_OUT_OF_STOCK_INGREDIENTS_START:
    "ingredient/FETCH_OUT_OF_STOCK_INGREDIENTS_START",
  APPEND_SELECT_INGREDIENTS_START: "ingredient/APPEND_SELECT_INGREDIENTS_START",
  APPEND_SELECT_GROUP_INGREDIENTS_START:
    "ingredient/APPEND_SELECT_GROUP_INGREDIENTS_START",
  FETCH_INGREDIENT_START: "ingredient/FETCH_INGREDIENT_START",
  CREATE_INGREDIENT_START: "ingredient/CREATE_INGREDIENT_START",
  UPDATE_INGREDIENT_START: "ingredient/UPDATE_INGREDIENT_START",
  DELETE_INGREDIENT_START: "ingredient/DELETE_INGREDIENT_START",
  STORE_STOCK_INGREDIENT_START: "ingredient/STORE_STOCK_INGREDIENT_START",
  REDUCE_STOCK_INGREDIENT_START: "ingredient/REDUCE_STOCK_INGREDIENT_START",
};

export default INGREDIENT_ACTION_TYPES;
