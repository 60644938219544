import REPORT_ACTION_TYPES from "./report.type";
import { createAction } from "../../utils/store.utils";

export const setPrevReportTotals = (prevReportTotals) =>
  createAction(REPORT_ACTION_TYPES.SET_PREV_REPORT_TOTALS, prevReportTotals);
export const setNextReportTotals = (nextReportTotals) =>
  createAction(REPORT_ACTION_TYPES.SET_NEXT_REPORT_TOTALS, nextReportTotals);

export const setReportCategories = (reportCategories) =>
  createAction(REPORT_ACTION_TYPES.SET_REPORT_CATEGORIES, reportCategories);
export const setReportProducts = (reportProducts) =>
  createAction(REPORT_ACTION_TYPES.SET_REPORT_PRODUCTS, reportProducts);
export const setReportCashiers = (reportCashiers) =>
  createAction(REPORT_ACTION_TYPES.SET_REPORT_CASHIERS, reportCashiers);
export const setReportPayments = (reportPayments) =>
  createAction(REPORT_ACTION_TYPES.SET_REPORT_PAYMENTS, reportPayments);
export const setReportTables = (reportTables) =>
  createAction(REPORT_ACTION_TYPES.SET_REPORT_TABLES, reportTables);

export const setIsReportCategoriesHasMore = (isReportCategoriesHasMore) =>
  createAction(
    REPORT_ACTION_TYPES.SET_IS_REPORT_CATEGORIES_HAS_MORE,
    isReportCategoriesHasMore
  );
export const setIsReportProductsHasMore = (isReportProductsHasMore) =>
  createAction(
    REPORT_ACTION_TYPES.SET_IS_REPORT_PRODUCTS_HAS_MORE,
    isReportProductsHasMore
  );
export const setIsReportCashiersHasMore = (isReportCashiersHasMore) =>
  createAction(
    REPORT_ACTION_TYPES.SET_IS_REPORT_CASHIERS_HAS_MORE,
    isReportCashiersHasMore
  );
export const setIsReportPaymentsHasMore = (isReportPaymentsHasMore) =>
  createAction(
    REPORT_ACTION_TYPES.SET_IS_REPORT_PAYMENTS_HAS_MORE,
    isReportPaymentsHasMore
  );
export const setIsReportTablesHasMore = (isReportTablesHasMore) =>
  createAction(
    REPORT_ACTION_TYPES.SET_IS_REPORT_TABLES_HAS_MORE,
    isReportTablesHasMore
  );

export const setFetchReportsFilterMarketId = (fetchReportsFilterMarketId) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_MARKET_ID,
    fetchReportsFilterMarketId
  );
export const setFetchReportsFilterBranchId = (fetchReportsFilterBranchId) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_BRANCH_ID,
    fetchReportsFilterBranchId
  );
export const setFetchReportsFilterCashierId = (fetchReportsFilterCashierId) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_CASHIER_ID,
    fetchReportsFilterCashierId
  );
export const setFetchReportsFilterCustomerId = (fetchReportsFilterCustomerId) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_CUSTOMER_ID,
    fetchReportsFilterCustomerId
  );
export const setFetchReportsFilterCreatedAtAfter = (
  fetchReportsFilterCreatedAtAfter
) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_CREATED_AT_AFTER,
    fetchReportsFilterCreatedAtAfter
  );
export const setFetchReportsFilterCreatedAtBefore = (
  fetchReportsFilterCreatedAtBefore
) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_CREATED_AT_BEFORE,
    fetchReportsFilterCreatedAtBefore
  );
export const setFetchReportsFilterOrderStatuses = (
  fetchReportsFilterOrderStatuses
) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_ORDER_STATUSES,
    fetchReportsFilterOrderStatuses
  );
export const setFetchReportsFilterTransactionStatuses = (
  fetchReportsFilterTransactionStatuses
) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_TRANSACTION_STATUSES,
    fetchReportsFilterTransactionStatuses
  );

export const setFetchPrevReportTotalsFilterCreatedAtAfter = (
  fetchPrevReportTotalsFilterCreatedAtAfter
) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_PREV_REPORT_TOTALS_FILTER_CREATED_AT_AFTER,
    fetchPrevReportTotalsFilterCreatedAtAfter
  );
export const setFetchPrevReportTotalsFilterCreatedAtBefore = (
  fetchPrevReportTotalsFilterCreatedAtBefore
) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_PREV_REPORT_TOTALS_FILTER_CREATED_AT_BEFORE,
    fetchPrevReportTotalsFilterCreatedAtBefore
  );
export const setFetchPrevReportTotalsLoading = (fetchPrevReportTotalsLoading) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_PREV_REPORT_TOTALS_LOADING,
    fetchPrevReportTotalsLoading
  );
export const setFetchPrevReportTotalsSuccess = (fetchPrevReportTotalsSuccess) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_PREV_REPORT_TOTALS_SUCCESS,
    fetchPrevReportTotalsSuccess
  );
export const setFetchPrevReportTotalsFailed = (fetchPrevReportTotalsFailed) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_PREV_REPORT_TOTALS_FAILED,
    fetchPrevReportTotalsFailed
  );

export const setFetchNextReportTotalsFilterCreatedAtAfter = (
  fetchNextReportTotalsFilterCreatedAtAfter
) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_NEXT_REPORT_TOTALS_FILTER_CREATED_AT_AFTER,
    fetchNextReportTotalsFilterCreatedAtAfter
  );
export const setFetchNextReportTotalsFilterCreatedAtBefore = (
  fetchNextReportTotalsFilterCreatedAtBefore
) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_NEXT_REPORT_TOTALS_FILTER_CREATED_AT_BEFORE,
    fetchNextReportTotalsFilterCreatedAtBefore
  );
export const setFetchNextReportTotalsLoading = (fetchNextReportTotalsLoading) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_NEXT_REPORT_TOTALS_LOADING,
    fetchNextReportTotalsLoading
  );
export const setFetchNextReportTotalsSuccess = (fetchNextReportTotalsSuccess) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_NEXT_REPORT_TOTALS_SUCCESS,
    fetchNextReportTotalsSuccess
  );
export const setFetchNextReportTotalsFailed = (fetchNextReportTotalsFailed) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_NEXT_REPORT_TOTALS_FAILED,
    fetchNextReportTotalsFailed
  );

export const setFetchReportCategoriesSearch = (fetchReportCategoriesSearch) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_SEARCH,
    fetchReportCategoriesSearch
  );
export const setFetchReportCategoriesSort = (fetchReportCategoriesSort) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_SORT,
    fetchReportCategoriesSort
  );
export const setFetchReportCategoriesKeyBY = (fetchReportCategoriesKeyBy) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_KEY_BY,
    fetchReportCategoriesKeyBy
  );
export const setFetchReportCategoriesPage = (fetchReportCategoriesPage) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_PAGE,
    fetchReportCategoriesPage
  );
export const setFetchReportCategoriesPerPage = (fetchReportCategoriesPerPage) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_PER_PAGE,
    fetchReportCategoriesPerPage
  );
export const setFetchReportCategoriesFilterProductStatuses = (
  fetchReportCategoriesFilterProductStatuses
) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_FILTER_PRODUCT_STATUSES,
    fetchReportCategoriesFilterProductStatuses
  );
export const setFetchReportCategoriesLoading = (fetchReportCategoriesLoading) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_LOADING,
    fetchReportCategoriesLoading
  );
export const setFetchReportCategoriesSuccess = (fetchReportCategoriesSuccess) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_SUCCESS,
    fetchReportCategoriesSuccess
  );
export const setFetchReportCategoriesFailed = (fetchReportCategoriesFailed) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_FAILED,
    fetchReportCategoriesFailed
  );

export const setFetchReportProductsSearch = (fetchReportProductsSearch) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_SEARCH,
    fetchReportProductsSearch
  );
export const setFetchReportProductsSort = (fetchReportProductsSort) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_SORT,
    fetchReportProductsSort
  );
export const setFetchReportProductsKeyBY = (fetchReportProductsKeyBy) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_KEY_BY,
    fetchReportProductsKeyBy
  );
export const setFetchReportProductsPage = (fetchReportProductsPage) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_PAGE,
    fetchReportProductsPage
  );
export const setFetchReportProductsPerPage = (fetchReportProductsPerPage) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_PER_PAGE,
    fetchReportProductsPerPage
  );
export const setFetchReportProductsFilterProductStatuses = (
  fetchReportProductsFilterProductStatuses
) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_FILTER_PRODUCT_STATUSES,
    fetchReportProductsFilterProductStatuses
  );
export const setFetchReportProductsLoading = (fetchReportProductsLoading) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_LOADING,
    fetchReportProductsLoading
  );
export const setFetchReportProductsSuccess = (fetchReportProductsSuccess) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_SUCCESS,
    fetchReportProductsSuccess
  );
export const setFetchReportProductsFailed = (fetchReportProductsFailed) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_FAILED,
    fetchReportProductsFailed
  );

export const setFetchReportCashiersSearch = (fetchReportCashiersSearch) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_SEARCH,
    fetchReportCashiersSearch
  );
export const setFetchReportCashiersSort = (fetchReportCashiersSort) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_SORT,
    fetchReportCashiersSort
  );
export const setFetchReportCashiersKeyBY = (fetchReportCashiersKeyBy) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_KEY_BY,
    fetchReportCashiersKeyBy
  );
export const setFetchReportCashiersPage = (fetchReportCashiersPage) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_PAGE,
    fetchReportCashiersPage
  );
export const setFetchReportCashiersPerPage = (fetchReportCashiersPerPage) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_PER_PAGE,
    fetchReportCashiersPerPage
  );
export const setFetchReportCashiersLoading = (fetchReportCashiersLoading) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_LOADING,
    fetchReportCashiersLoading
  );
export const setFetchReportCashiersSuccess = (fetchReportCashiersSuccess) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_SUCCESS,
    fetchReportCashiersSuccess
  );
export const setFetchReportCashiersFailed = (fetchReportCashiersFailed) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_FAILED,
    fetchReportCashiersFailed
  );

export const setFetchReportPaymentsSearch = (fetchReportPaymentsSearch) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_SEARCH,
    fetchReportPaymentsSearch
  );
export const setFetchReportPaymentsSort = (fetchReportPaymentsSort) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_SORT,
    fetchReportPaymentsSort
  );
export const setFetchReportPaymentsKeyBY = (fetchReportPaymentsKeyBy) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_KEY_BY,
    fetchReportPaymentsKeyBy
  );
export const setFetchReportPaymentsPage = (fetchReportPaymentsPage) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_PAGE,
    fetchReportPaymentsPage
  );
export const setFetchReportPaymentsPerPage = (fetchReportPaymentsPerPage) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_PER_PAGE,
    fetchReportPaymentsPerPage
  );
export const setFetchReportPaymentsFilterPaymentStatuses = (
  fetchReportPaymentsFilterPaymentStatuses
) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_FILTER_PAYMENT_STATUSES,
    fetchReportPaymentsFilterPaymentStatuses
  );
export const setFetchReportPaymentsLoading = (fetchReportPaymentsLoading) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_LOADING,
    fetchReportPaymentsLoading
  );
export const setFetchReportPaymentsSuccess = (fetchReportPaymentsSuccess) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_SUCCESS,
    fetchReportPaymentsSuccess
  );
export const setFetchReportPaymentsFailed = (fetchReportPaymentsFailed) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_FAILED,
    fetchReportPaymentsFailed
  );

export const setFetchReportTablesSearch = (fetchReportTablesSearch) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_SEARCH,
    fetchReportTablesSearch
  );
export const setFetchReportTablesSort = (fetchReportTablesSort) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_SORT,
    fetchReportTablesSort
  );
export const setFetchReportTablesKeyBY = (fetchReportTablesKeyBy) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_KEY_BY,
    fetchReportTablesKeyBy
  );
export const setFetchReportTablesPage = (fetchReportTablesPage) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_PAGE,
    fetchReportTablesPage
  );
export const setFetchReportTablesPerPage = (fetchReportTablesPerPage) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_PER_PAGE,
    fetchReportTablesPerPage
  );
export const setFetchReportTablesLoading = (fetchReportTablesLoading) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_LOADING,
    fetchReportTablesLoading
  );
export const setFetchReportTablesSuccess = (fetchReportTablesSuccess) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_SUCCESS,
    fetchReportTablesSuccess
  );
export const setFetchReportTablesFailed = (fetchReportTablesFailed) =>
  createAction(
    REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_FAILED,
    fetchReportTablesFailed
  );

export const appendReportCategories = (reportCategories) =>
  createAction(REPORT_ACTION_TYPES.APPEND_REPORT_CATEGORIES, reportCategories);
export const appendReportProducts = (reportProducts) =>
  createAction(REPORT_ACTION_TYPES.APPEND_REPORT_PRODUCTS, reportProducts);
export const appendReportCashiers = (reportCashiers) =>
  createAction(REPORT_ACTION_TYPES.APPEND_REPORT_CASHIERS, reportCashiers);
export const appendReportPayments = (reportPayments) =>
  createAction(REPORT_ACTION_TYPES.APPEND_REPORT_PAYMENTS, reportPayments);
export const appendReportTables = (reportTables) =>
  createAction(REPORT_ACTION_TYPES.APPEND_REPORT_TABLES, reportTables);

export const fetchPrevReportTotalsStart = () =>
  createAction(REPORT_ACTION_TYPES.FETCH_PREV_REPORT_TOTALS_START);
export const fetchNextReportTotalsStart = () =>
  createAction(REPORT_ACTION_TYPES.FETCH_NEXT_REPORT_TOTALS_START);
export const fetchReportCategoriesStart = () =>
  createAction(REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_START);
export const fetchReportProductsStart = () =>
  createAction(REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_START);
export const fetchReportCashiersStart = () =>
  createAction(REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_START);
export const fetchReportPaymentsStart = () =>
  createAction(REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_START);
export const fetchReportTablesStart = () =>
  createAction(REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_START);

export const setIsFetchPrevReportTotalsHitted = (
  isFetchPrevReportTotalsHitted
) =>
  createAction(
    REPORT_ACTION_TYPES.SET_IS_FETCH_PREV_REPORT_TOTALS_HITTED,
    isFetchPrevReportTotalsHitted
  );
export const setIsFetchNextReportTotalsHitted = (
  isFetchNextReportTotalsHitted
) =>
  createAction(
    REPORT_ACTION_TYPES.SET_IS_FETCH_NEXT_REPORT_TOTALS_HITTED,
    isFetchNextReportTotalsHitted
  );
export const setIsFetchReportCategoriesHitted = (
  isFetchReportCategoriesHitted
) =>
  createAction(
    REPORT_ACTION_TYPES.SET_IS_FETCH_REPORT_CATEGORIES_HITTED,
    isFetchReportCategoriesHitted
  );
export const setIsFetchReportProductsHitted = (isFetchReportProductsHitted) =>
  createAction(
    REPORT_ACTION_TYPES.SET_IS_FETCH_REPORT_PRODUCTS_HITTED,
    isFetchReportProductsHitted
  );
export const setIsFetchReportCashiersHitted = (isFetchReportCashiersHitted) =>
  createAction(
    REPORT_ACTION_TYPES.SET_IS_FETCH_REPORT_CASHIERS_HITTED,
    isFetchReportCashiersHitted
  );
export const setIsFetchReportPaymentsHitted = (isFetchReportPaymentsHitted) =>
  createAction(
    REPORT_ACTION_TYPES.SET_IS_FETCH_REPORT_PAYMENTS_HITTED,
    isFetchReportPaymentsHitted
  );
export const setIsFetchReportTablesHitted = (isFetchReportTablesHitted) =>
  createAction(
    REPORT_ACTION_TYPES.SET_IS_FETCH_REPORT_TABLES_HITTED,
    isFetchReportTablesHitted
  );

export const resetReportReducer = () =>
  createAction(REPORT_ACTION_TYPES.RESET_REPORT_REDUCER);
