import { ADVERTISEMENT_TYPES } from "../../constants/advertisement.constant";

import ADVERTISEMENT_ACTION_TYPES from "./advertisement.type";

export const ADVERTISEMENT_INITIAL_STATE = {
  advertisementTypes: [
    {
      label: "Whatsapp",
      value: ADVERTISEMENT_TYPES.WHATSAPP,
    },
    {
      label: "SMS",
      value: ADVERTISEMENT_TYPES.SMS,
    },
  ],
  advertisements: [],
  advertisement: null,

  isAdvertisementsHasMore: true,

  fetchAdvertisementsSearch: null,
  fetchAdvertisementsPage: 1,
  fetchAdvertisementsPerPage: null,
  fetchAdvertisementsIncludes: null,
  fetchAdvertisementsFilterMarketId: null,
  fetchAdvertisementsFilterType: null,
  fetchAdvertisementsFilterStatuses: null,
  fetchAdvertisementsFilterStartAt: null,
  fetchAdvertisementsFilterEndAt: null,
  fetchAdvertisementsLoading: false,
  fetchAdvertisementsSuccess: null,
  fetchAdvertisementsFailed: null,

  fetchAdvertisementLoading: false,
  fetchAdvertisementSuccess: null,
  fetchAdvertisementFailed: null,

  createAdvertisementLoading: false,
  createAdvertisementSuccess: null,
  createAdvertisementFailed: null,

  updateAdvertisementLoading: false,
  updateAdvertisementSuccess: null,
  updateAdvertisementFailed: null,

  deleteAdvertisementLoading: false,
  deleteAdvertisementSuccess: null,
  deleteAdvertisementFailed: null,

  isFetchAdvertisementsHitted: false,
  isFetchAdvertisementHitted: false,
  isCreateAdvertisementHitted: false,
  isUpdateAdvertisementHitted: false,
  isDeleteAdvertisementHitted: false,
};

export const advertisementReducer = (state = ADVERTISEMENT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADVERTISEMENT_ACTION_TYPES.SET_ADVERTISEMENTS:
      return { ...state, advertisements: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_ADVERTISEMENT:
      return { ...state, advertisement: payload };

    case ADVERTISEMENT_ACTION_TYPES.SET_IS_ADVERTISEMENTS_HAS_MORE:
      return { ...state, isAdvertisementsHasMore: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_SEARCH:
      return { ...state, fetchAdvertisementsSearch: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_PAGE:
      return { ...state, fetchAdvertisementsPage: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_PER_PAGE:
      return { ...state, fetchAdvertisementsPerPage: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_INCLUDES:
      return { ...state, fetchAdvertisementsIncludes: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_FILTER_MARKET_ID:
      return { ...state, fetchAdvertisementsFilterMarketId: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_FILTER_TYPE:
      return { ...state, fetchAdvertisementsFilterType: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_FILTER_STATUSES:
      return { ...state, fetchAdvertisementsFilterStatuses: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_FILTER_START_AT:
      return { ...state, fetchAdvertisementsFilterStartAt: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_FILTER_END_AT:
      return { ...state, fetchAdvertisementsFilterEndAt: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_LOADING:
      return { ...state, fetchAdvertisementsLoading: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_SUCCESS:
      return { ...state, fetchAdvertisementsSuccess: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_FAILED:
      return { ...state, fetchAdvertisementsFailed: payload };

    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENT_LOADING:
      return { ...state, fetchAdvertisementLoading: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENT_SUCCESS:
      return { ...state, fetchAdvertisementSuccess: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENT_FAILED:
      return { ...state, fetchAdvertisementFailed: payload };

    case ADVERTISEMENT_ACTION_TYPES.SET_CREATE_ADVERTISEMENT_LOADING:
      return { ...state, createAdvertisementLoading: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_CREATE_ADVERTISEMENT_SUCCESS:
      return { ...state, createAdvertisementSuccess: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_CREATE_ADVERTISEMENT_FAILED:
      return { ...state, createAdvertisementFailed: payload };

    case ADVERTISEMENT_ACTION_TYPES.SET_UPDATE_ADVERTISEMENT_LOADING:
      return { ...state, updateAdvertisementLoading: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_UPDATE_ADVERTISEMENT_SUCCESS:
      return { ...state, updateAdvertisementSuccess: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_UPDATE_ADVERTISEMENT_FAILED:
      return { ...state, updateAdvertisementFailed: payload };

    case ADVERTISEMENT_ACTION_TYPES.SET_DELETE_ADVERTISEMENT_LOADING:
      return { ...state, deleteAdvertisementLoading: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_DELETE_ADVERTISEMENT_SUCCESS:
      return { ...state, deleteAdvertisementSuccess: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_DELETE_ADVERTISEMENT_FAILED:
      return { ...state, deleteAdvertisementFailed: payload };

    case ADVERTISEMENT_ACTION_TYPES.APPEND_ADVERTISEMENTS:
      return { ...state, advertisements: [...state.advertisements, ...payload] };

    case ADVERTISEMENT_ACTION_TYPES.SET_IS_FETCH_ADVERTISEMENTS_HITTED:
      return { ...state, isFetchAdvertisementsHitted: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_IS_FETCH_ADVERTISEMENT_HITTED:
      return { ...state, isFetchAdvertisementHitted: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_IS_CREATE_ADVERTISEMENT_HITTED:
      return { ...state, isCreateAdvertisementHitted: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_IS_UPDATE_ADVERTISEMENT_HITTED:
      return { ...state, isUpdateAdvertisementHitted: payload };
    case ADVERTISEMENT_ACTION_TYPES.SET_IS_DELETE_ADVERTISEMENT_HITTED:
      return { ...state, isDeleteAdvertisementHitted: payload };

    case ADVERTISEMENT_ACTION_TYPES.RESET_ADVERTISEMENT_REDUCER:
      return ADVERTISEMENT_INITIAL_STATE;
    default:
      return state;
  }
};
