const USER_ADMIN_ACTION_TYPES = {
  RESET_USER_ADMIN_REDUCER: "user-admin/RESET_USER_ADMIN_REDUCER",

  SET_USER_ADMINS: "user-admin/SET_USER_ADMINS",
  SET_USER_ADMIN: "user-admin/SET_USER_ADMIN",

  SET_IS_USER_ADMINS_HAS_MORE: "user-admin/SET_IS_USER_ADMINS_HAS_MORE",

  SET_FETCH_USER_ADMINS_SEARCH: "user-admin/SET_FETCH_USER_ADMINS_SEARCH",
  SET_FETCH_USER_ADMINS_SORT: "user-admin/SET_FETCH_USER_ADMINS_SORT",
  SET_FETCH_USER_ADMINS_KEY_BY: "user-admin/SET_FETCH_USER_ADMINS_KEY_BY",
  SET_FETCH_USER_ADMINS_PAGE: "user-admin/SET_FETCH_USER_ADMINS_PAGE",
  SET_FETCH_USER_ADMINS_PER_PAGE: "user-admin/SET_FETCH_USER_ADMINS_PER_PAGE",
  SET_FETCH_USER_ADMINS_INCLUDES: "user-admin/SET_FETCH_USER_ADMINS_INCLUDES",
  SET_FETCH_USER_ADMINS_FILTER_PERMISSION_ID:
    "user-admin/SET_FETCH_USER_ADMINS_FILTER_PERMISSION_ID",
  SET_FETCH_USER_ADMINS_FILTER_MARKET_ID:
    "user-admin/SET_FETCH_USER_ADMINS_FILTER_MARKET_ID",
  SET_FETCH_USER_ADMINS_LOADING: "user-admin/SET_FETCH_USER_ADMINS_LOADING",
  SET_FETCH_USER_ADMINS_SUCCESS: "user-admin/SET_FETCH_USER_ADMINS_SUCCESS",
  SET_FETCH_USER_ADMINS_FAILED: "user-admin/SET_FETCH_USER_ADMINS_FAILED",

  SET_FETCH_USER_ADMIN_LOADING: "user-admin/SET_FETCH_USER_ADMIN_LOADING",
  SET_FETCH_USER_ADMIN_SUCCESS: "user-admin/SET_FETCH_USER_ADMIN_SUCCESS",
  SET_FETCH_USER_ADMIN_FAILED: "user-admin/SET_FETCH_USER_ADMIN_FAILED",

  SET_CREATE_USER_ADMIN_LOADING: "user-admin/SET_CREATE_USER_ADMIN_LOADING",
  SET_CREATE_USER_ADMIN_SUCCESS: "user-admin/SET_CREATE_USER_ADMIN_SUCCESS",
  SET_CREATE_USER_ADMIN_FAILED: "user-admin/SET_CREATE_USER_ADMIN_FAILED",

  SET_UPDATE_USER_ADMIN_LOADING: "user-admin/SET_UPDATE_USER_ADMIN_LOADING",
  SET_UPDATE_USER_ADMIN_SUCCESS: "user-admin/SET_UPDATE_USER_ADMIN_SUCCESS",
  SET_UPDATE_USER_ADMIN_FAILED: "user-admin/SET_UPDATE_USER_ADMIN_FAILED",

  SET_DELETE_USER_ADMIN_LOADING: "user-admin/SET_DELETE_USER_ADMIN_LOADING",
  SET_DELETE_USER_ADMIN_SUCCESS: "user-admin/SET_DELETE_USER_ADMIN_SUCCESS",
  SET_DELETE_USER_ADMIN_FAILED: "user-admin/SET_DELETE_USER_ADMIN_FAILED",

  APPEND_USER_ADMINS: "user-admin/APPEND_USER_ADMINS",

  SET_IS_FETCH_USER_ADMINS_HITTED: "user-admin/SET_IS_FETCH_USER_ADMINS_HITTED",
  SET_IS_FETCH_USER_ADMIN_HITTED: "user-admin/SET_IS_FETCH_USER_ADMIN_HITTED",
  SET_IS_CREATE_USER_ADMIN_HITTED: "user-admin/SET_IS_CREATE_USER_ADMIN_HITTED",
  SET_IS_UPDATE_USER_ADMIN_HITTED: "user-admin/SET_IS_UPDATE_USER_ADMIN_HITTED",
  SET_IS_DELETE_USER_ADMIN_HITTED: "user-admin/SET_IS_DELETE_USER_ADMIN_HITTED",

  FETCH_USER_ADMINS_START: "user-admin/FETCH_USER_ADMINS_START",
  FETCH_USER_ADMIN_START: "user-admin/FETCH_USER_ADMIN_START",
  CREATE_USER_ADMIN_START: "user-admin/CREATE_USER_ADMIN_START",
  UPDATE_USER_ADMIN_START: "user-admin/UPDATE_USER_ADMIN_START",
  DELETE_USER_ADMIN_START: "user-admin/DELETE_USER_ADMIN_START",
};

export default USER_ADMIN_ACTION_TYPES;
