import { takeLatest, put, all, call, select } from "redux-saga/effects";

import USER_CUSTOMER_ACTION_TYPES from "./user-customer.type";

import {
  appendUserCustomers,
  setUserCustomer,
  setUserCustomers,
  setCreateUserCustomerFailed,
  setCreateUserCustomerLoading,
  setCreateUserCustomerSuccess,
  setFetchUserCustomerFailed,
  setFetchUserCustomerLoading,
  setFetchUserCustomerSuccess,
  setFetchUserCustomersFailed,
  setFetchUserCustomersLoading,
  setFetchUserCustomersPage,
  setFetchUserCustomersSuccess,
  setIsUserCustomersHasMore,
  setIsCreateUserCustomerHitted,
  setIsFetchUserCustomerHitted,
  setIsFetchUserCustomersHitted,
  setIsUpdateUserCustomerHitted,
  setUpdateUserCustomerFailed,
  setUpdateUserCustomerLoading,
  setUpdateUserCustomerSuccess,
  setAddMarketUserCustomerLoading,
  setAddMarketUserCustomerSuccess,
  setAddMarketUserCustomerFailed,
  setIsAddMarketUserCustomerHitted,
  setRemoveMarketUserCustomerLoading,
  setRemoveMarketUserCustomerSuccess,
  setRemoveMarketUserCustomerFailed,
  setIsRemoveMarketUserCustomerHitted,
  setFetchSearchUserCustomersLoading,
  setIsFetchSearchUserCustomersHitted,
  setIsSearchUserCustomersHasMore,
  appendSearchUserCustomers,
  setSearchUserCustomers,
  setFetchSearchUserCustomersSuccess,
  setFetchSearchUserCustomersFailed,
  setCreateUserCustomer,
  setUpdateUserCustomer,
} from "./user-customer.action";
import {
  getFetchSearchUserCustomersFilterMarketId,
  getFetchSearchUserCustomersIncludes,
  getFetchSearchUserCustomersKeyBy,
  getFetchSearchUserCustomersPage,
  getFetchSearchUserCustomersPerPage,
  getFetchSearchUserCustomersSearch,
  getFetchSearchUserCustomersSort,
  getFetchUserCustomersFilterMarketId,
  getFetchUserCustomersIncludes,
  getFetchUserCustomersKeyBy,
  getFetchUserCustomersPage,
  getFetchUserCustomersPerPage,
  getFetchUserCustomersSearch,
  getFetchUserCustomersSort,
  getIsFetchUserCustomersHitted,
} from "./user-customer.selector";

import {
  addMarketUserCustomer,
  createUserCustomer,
  getUserCustomer,
  getUserCustomers,
  removeMarketUserCustomer,
  updateUserCustomer,
} from "../../api/user-customer.api";

export function* _getUserCustomers() {
  try {
    yield put(setFetchUserCustomersLoading(true));

    const search = yield select(getFetchUserCustomersSearch);
    const sort = yield select(getFetchUserCustomersSort);
    const key_by = yield select(getFetchUserCustomersKeyBy);
    const page = yield select(getFetchUserCustomersPage);
    const per_page = yield select(getFetchUserCustomersPerPage);
    const includes = yield select(getFetchUserCustomersIncludes);
    const market_id = yield select(getFetchUserCustomersFilterMarketId);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id },
    };

    const {
      meta: { message },
      data: { data: userCustomers },
    } = yield call(getUserCustomers, parameters);

    yield put(setIsFetchUserCustomersHitted(true));
    yield put(setIsUserCustomersHasMore(userCustomers.length > 0));

    if (page > 1) {
      yield put(appendUserCustomers(userCustomers));
    } else {
      yield put(setUserCustomers(userCustomers));
    }

    yield put(setFetchUserCustomersSuccess(message));
    yield put(setFetchUserCustomersLoading(false));
  } catch (error) {
    yield put(setFetchUserCustomersFailed(error));
    yield put(setFetchUserCustomersLoading(false));
  }
}
export function* _getSearchUserCustomers() {
  try {
    yield put(setFetchSearchUserCustomersLoading(true));

    const search = yield select(getFetchSearchUserCustomersSearch);
    const sort = yield select(getFetchSearchUserCustomersSort);
    const key_by = yield select(getFetchSearchUserCustomersKeyBy);
    const page = yield select(getFetchSearchUserCustomersPage);
    const per_page = yield select(getFetchSearchUserCustomersPerPage);
    const includes = yield select(getFetchSearchUserCustomersIncludes);
    const market_id = yield select(getFetchSearchUserCustomersFilterMarketId);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id },
    };

    const {
      meta: { message },
      data: { data: userCustomers },
    } = yield call(getUserCustomers, parameters);

    yield put(setIsFetchSearchUserCustomersHitted(true));
    yield put(setIsSearchUserCustomersHasMore(userCustomers.length > 0));

    if (page > 1) {
      yield put(appendSearchUserCustomers(userCustomers));
    } else {
      yield put(setSearchUserCustomers(userCustomers));
    }

    yield put(setFetchSearchUserCustomersSuccess(message));
    yield put(setFetchSearchUserCustomersLoading(false));
  } catch (error) {
    yield put(setFetchSearchUserCustomersFailed(error));
    yield put(setFetchSearchUserCustomersLoading(false));
  }
}
export function* _getUserCustomer({ payload: customerId }) {
  try {
    yield put(setFetchUserCustomerLoading(true));

    const {
      meta: { message },
      data: userCustomer,
    } = yield call(getUserCustomer, customerId);

    yield put(setIsFetchUserCustomerHitted(true));
    yield put(setUserCustomer(userCustomer));

    yield put(setFetchUserCustomerSuccess(message));
    yield put(setFetchUserCustomerLoading(false));
  } catch (error) {
    yield put(setFetchUserCustomerFailed(error));
    yield put(setFetchUserCustomerLoading(false));
  }
}
export function* _createUserCustomer({ payload: request }) {
  try {
    yield put(setCreateUserCustomerLoading(true));

    const {
      meta: { message },
      data: userCustomer,
    } = yield call(createUserCustomer, request);

    yield put(setIsCreateUserCustomerHitted(true));
    yield put(setCreateUserCustomer(userCustomer));

    const isFetchUserCustomersHitted = yield select(
      getIsFetchUserCustomersHitted
    );

    if (isFetchUserCustomersHitted) {
      yield put(setFetchUserCustomersPage(1));
      yield call(_getUserCustomers);
    }

    yield put(setCreateUserCustomerSuccess(message));
    yield put(setCreateUserCustomerLoading(false));
  } catch (error) {
    yield put(setCreateUserCustomerFailed(error));
    yield put(setCreateUserCustomerLoading(false));
  }
}
export function* _updateUserCustomer({ payload: { customerId, request } }) {
  try {
    yield put(setUpdateUserCustomerLoading(true));

    const {
      meta: { message },
      data: userCustomer,
    } = yield call(updateUserCustomer, customerId, request);

    yield put(setIsUpdateUserCustomerHitted(true));
    yield put(setUpdateUserCustomer(userCustomer));

    const isFetchUserCustomersHitted = yield select(
      getIsFetchUserCustomersHitted
    );

    if (isFetchUserCustomersHitted) {
      yield put(setFetchUserCustomersPage(1));
      yield call(_getUserCustomers);
    }

    yield put(setUpdateUserCustomerSuccess(message));
    yield put(setUpdateUserCustomerLoading(false));
  } catch (error) {
    yield put(setUpdateUserCustomerFailed(error));
    yield put(setUpdateUserCustomerLoading(false));
  }
}
export function* _addMarketUserCustomer({ payload: { customerId, marketId } }) {
  try {
    yield put(setAddMarketUserCustomerLoading(true));

    const {
      meta: { message },
    } = yield call(addMarketUserCustomer, customerId, marketId);

    yield put(setIsAddMarketUserCustomerHitted(true));

    const isFetchUserCustomersHitted = yield select(
      getIsFetchUserCustomersHitted
    );

    if (isFetchUserCustomersHitted) {
      yield put(setFetchUserCustomersPage(1));
      yield call(_getUserCustomers);
    }

    yield put(setAddMarketUserCustomerSuccess(message));
    yield put(setAddMarketUserCustomerLoading(false));
  } catch (error) {
    yield put(setAddMarketUserCustomerFailed(error));
    yield put(setAddMarketUserCustomerLoading(false));
  }
}
export function* _removeMarketUserCustomer({
  payload: { customerId, marketId },
}) {
  try {
    yield put(setRemoveMarketUserCustomerLoading(true));

    const {
      meta: { message },
    } = yield call(removeMarketUserCustomer, customerId, marketId);

    yield put(setIsRemoveMarketUserCustomerHitted(true));

    const isFetchUserCustomersHitted = yield select(
      getIsFetchUserCustomersHitted
    );

    if (isFetchUserCustomersHitted) {
      yield put(setFetchUserCustomersPage(1));
      yield call(_getUserCustomers);
    }

    yield put(setRemoveMarketUserCustomerSuccess(message));
    yield put(setRemoveMarketUserCustomerLoading(false));
  } catch (error) {
    yield put(setRemoveMarketUserCustomerFailed(error));
    yield put(setRemoveMarketUserCustomerLoading(false));
  }
}

export function* onFetchUserCustomersStart() {
  yield takeLatest(
    USER_CUSTOMER_ACTION_TYPES.FETCH_USER_CUSTOMERS_START,
    _getUserCustomers
  );
}
export function* onFetchSearchUserCustomersStart() {
  yield takeLatest(
    USER_CUSTOMER_ACTION_TYPES.FETCH_SEARCH_USER_CUSTOMERS_START,
    _getSearchUserCustomers
  );
}
export function* onFetchUserCustomerStart() {
  yield takeLatest(
    USER_CUSTOMER_ACTION_TYPES.FETCH_USER_CUSTOMER_START,
    _getUserCustomer
  );
}
export function* onCreateUserCustomerStart() {
  yield takeLatest(
    USER_CUSTOMER_ACTION_TYPES.CREATE_USER_CUSTOMER_START,
    _createUserCustomer
  );
}
export function* onUpdateUserCustomerStart() {
  yield takeLatest(
    USER_CUSTOMER_ACTION_TYPES.UPDATE_USER_CUSTOMER_START,
    _updateUserCustomer
  );
}
export function* onAddMarketUserCustomerStart() {
  yield takeLatest(
    USER_CUSTOMER_ACTION_TYPES.ADD_MARKET_USER_CUSTOMER_START,
    _addMarketUserCustomer
  );
}
export function* onRemoveMarketUserCustomerStart() {
  yield takeLatest(
    USER_CUSTOMER_ACTION_TYPES.REMOVE_MARKET_USER_CUSTOMER_START,
    _removeMarketUserCustomer
  );
}

export function* userCustomerSaga() {
  yield all([
    call(onFetchUserCustomersStart),
    call(onFetchSearchUserCustomersStart),
    call(onFetchUserCustomerStart),
    call(onCreateUserCustomerStart),
    call(onUpdateUserCustomerStart),
    call(onAddMarketUserCustomerStart),
    call(onRemoveMarketUserCustomerStart),
  ]);
}
