import ADVERTISEMENT_ACTION_TYPES from "./advertisement.type";
import { createAction } from "../../utils/store.utils";

export const setAdvertisements = (advertisements) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_ADVERTISEMENTS, advertisements);

export const setAdvertisement = (advertisement) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_ADVERTISEMENT, advertisement);

export const setIsAdvertisementsHasMore = (isAdvertisementsHasMore) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_IS_ADVERTISEMENTS_HAS_MORE, isAdvertisementsHasMore);

export const setFetchAdvertisementsSearch = (fetchAdvertisementsSearch) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_SEARCH, fetchAdvertisementsSearch);

export const setFetchAdvertisementsPage = (fetchAdvertisementsPage) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_PAGE, fetchAdvertisementsPage);

export const setFetchAdvertisementsPerPage = (fetchAdvertisementsPerPage) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_PER_PAGE, fetchAdvertisementsPerPage);

export const setFetchAdvertisementsIncludes = (fetchAdvertisementsIncludes) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_INCLUDES, fetchAdvertisementsIncludes);

export const setFetchAdvertisementsFilterMarketId = (fetchAdvertisementsFilterMarketId) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_FILTER_MARKET_ID, fetchAdvertisementsFilterMarketId);

export const setFetchAdvertisementsFilterType = (fetchAdvertisementsFilterType) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_FILTER_TYPE, fetchAdvertisementsFilterType);

export const setFetchAdvertisementsFilterStatuses = (fetchAdvertisementsFilterStatuses) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_FILTER_STATUSES, fetchAdvertisementsFilterStatuses);

export const setFetchAdvertisementsFilterStartAt = (fetchAdvertisementsFilterStartAt) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_FILTER_START_AT, fetchAdvertisementsFilterStartAt);

export const setFetchAdvertisementsFilterEndAt = (fetchAdvertisementsFilterEndAt) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_FILTER_END_AT, fetchAdvertisementsFilterEndAt);

export const setFetchAdvertisementsLoading = (fetchAdvertisementsLoading) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_LOADING, fetchAdvertisementsLoading);

export const setFetchAdvertisementsSuccess = (fetchAdvertisementsSuccess) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_SUCCESS, fetchAdvertisementsSuccess);

export const setFetchAdvertisementsFailed = (fetchAdvertisementsFailed) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENTS_FAILED, fetchAdvertisementsFailed);

export const setFetchAdvertisementLoading = (fetchAdvertisementLoading) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENT_LOADING, fetchAdvertisementLoading);

export const setFetchAdvertisementSuccess = (fetchAdvertisementSuccess) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENT_SUCCESS, fetchAdvertisementSuccess);

export const setFetchAdvertisementFailed = (fetchAdvertisementFailed) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_FETCH_ADVERTISEMENT_FAILED, fetchAdvertisementFailed);

export const setCreateAdvertisementLoading = (createAdvertisementLoading) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_CREATE_ADVERTISEMENT_LOADING, createAdvertisementLoading);

export const setCreateAdvertisementSuccess = (createAdvertisementSuccess) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_CREATE_ADVERTISEMENT_SUCCESS, createAdvertisementSuccess);

export const setCreateAdvertisementFailed = (createAdvertisementFailed) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_CREATE_ADVERTISEMENT_FAILED, createAdvertisementFailed);

export const setUpdateAdvertisementLoading = (updateAdvertisementLoading) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_UPDATE_ADVERTISEMENT_LOADING, updateAdvertisementLoading);

export const setUpdateAdvertisementSuccess = (updateAdvertisementSuccess) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_UPDATE_ADVERTISEMENT_SUCCESS, updateAdvertisementSuccess);

export const setUpdateAdvertisementFailed = (updateAdvertisementFailed) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_UPDATE_ADVERTISEMENT_FAILED, updateAdvertisementFailed);

export const setDeleteAdvertisementLoading = (deleteAdvertisementLoading) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_DELETE_ADVERTISEMENT_LOADING, deleteAdvertisementLoading);

export const setDeleteAdvertisementSuccess = (deleteAdvertisementSuccess) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_DELETE_ADVERTISEMENT_SUCCESS, deleteAdvertisementSuccess);

export const setDeleteAdvertisementFailed = (deleteAdvertisementFailed) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_DELETE_ADVERTISEMENT_FAILED, deleteAdvertisementFailed);

export const appendAdvertisements = (advertisements) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.APPEND_ADVERTISEMENTS, advertisements);

export const fetchAdvertisementsStart = () => createAction(ADVERTISEMENT_ACTION_TYPES.FETCH_ADVERTISEMENTS_START);

export const fetchAdvertisementStart = (id) => createAction(ADVERTISEMENT_ACTION_TYPES.FETCH_ADVERTISEMENT_START, id);

export const createAdvertisementStart = (request) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.CREATE_ADVERTISEMENT_START, request);

export const updateAdvertisementStart = (id, request) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.UPDATE_ADVERTISEMENT_START, { id, request });

export const deleteAdvertisementStart = (id) => createAction(ADVERTISEMENT_ACTION_TYPES.DELETE_ADVERTISEMENT_START, id);

export const setIsFetchAdvertisementsHitted = (isFetchAdvertisementsHitted) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_IS_FETCH_ADVERTISEMENTS_HITTED, isFetchAdvertisementsHitted);

export const setIsFetchAdvertisementHitted = (isFetchAdvertisementHitted) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_IS_FETCH_ADVERTISEMENT_HITTED, isFetchAdvertisementHitted);

export const setIsCreateAdvertisementHitted = (isCreateAdvertisementHitted) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_IS_CREATE_ADVERTISEMENT_HITTED, isCreateAdvertisementHitted);

export const setIsUpdateAdvertisementHitted = (isUpdateAdvertisementHitted) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_IS_UPDATE_ADVERTISEMENT_HITTED, isUpdateAdvertisementHitted);

export const setIsDeleteAdvertisementHitted = (isDeleteAdvertisementHitted) =>
  createAction(ADVERTISEMENT_ACTION_TYPES.SET_IS_DELETE_ADVERTISEMENT_HITTED, isDeleteAdvertisementHitted);

export const resetAdvertisementReducer = () => createAction(ADVERTISEMENT_ACTION_TYPES.RESET_ADVERTISEMENT_REDUCER);
