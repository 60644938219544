import { createSelector } from "reselect";

const dashboardMerchantSelector = ({ dashboardMerchant }) => dashboardMerchant;

export const getDashboardTotalMarket = createSelector(
  [dashboardMerchantSelector],
  ({ dashboardTotalMarket }) => dashboardTotalMarket
);
export const getDashboardTotalBranch = createSelector(
  [dashboardMerchantSelector],
  ({ dashboardTotalBranch }) => dashboardTotalBranch
);
export const getDashboardTotalOrder = createSelector(
  [dashboardMerchantSelector],
  ({ dashboardTotalOrder }) => dashboardTotalOrder
);
export const getDashboardTotalRevenue = createSelector(
  [dashboardMerchantSelector],
  ({ dashboardTotalRevenue }) => dashboardTotalRevenue
);
export const getDashboardOrdersChart = createSelector(
  [dashboardMerchantSelector],
  ({ dashboardOrdersChart }) => dashboardOrdersChart
);
export const getDashboardLatestMarkets = createSelector(
  [dashboardMerchantSelector],
  ({ dashboardLatestMarkets }) => dashboardLatestMarkets
);

export const getDashboardTotalMarketLoading = createSelector(
  [dashboardMerchantSelector],
  ({ dashboardTotalMarketLoading }) => dashboardTotalMarketLoading
);
export const getDashboardTotalBranchLoading = createSelector(
  [dashboardMerchantSelector],
  ({ dashboardTotalBranchLoading }) => dashboardTotalBranchLoading
);
export const getDashboardTotalOrderLoading = createSelector(
  [dashboardMerchantSelector],
  ({ dashboardTotalOrderLoading }) => dashboardTotalOrderLoading
);
export const getDashboardTotalRevenueLoading = createSelector(
  [dashboardMerchantSelector],
  ({ dashboardTotalRevenueLoading }) => dashboardTotalRevenueLoading
);
export const getDashboardOrdersChartLoading = createSelector(
  [dashboardMerchantSelector],
  ({ dashboardOrdersChartLoading }) => dashboardOrdersChartLoading
);
export const getDashboardLatestMarketsLoading = createSelector(
  [dashboardMerchantSelector],
  ({ dashboardLatestMarketsLoading }) => dashboardLatestMarketsLoading
);

export const getFetchDashboardMerchantFilterCreatedAtBefore = createSelector(
  [dashboardMerchantSelector],
  ({ fetchDashboardMerchantFilterCreatedAtBefore }) =>
    fetchDashboardMerchantFilterCreatedAtBefore
);
export const getFetchDashboardMerchantFilterCreatedAtAfter = createSelector(
  [dashboardMerchantSelector],
  ({ fetchDashboardMerchantFilterCreatedAtAfter }) =>
    fetchDashboardMerchantFilterCreatedAtAfter
);
export const getFetchDashboardMerchantFilterCurrency = createSelector(
  [dashboardMerchantSelector],
  ({ fetchDashboardMerchantFilterCurrency }) =>
    fetchDashboardMerchantFilterCurrency
);
export const getFetchDashboardMerchantLoading = createSelector(
  [dashboardMerchantSelector],
  ({ fetchDashboardMerchantLoading }) => fetchDashboardMerchantLoading
);
export const getFetchDashboardMerchantSuccess = createSelector(
  [dashboardMerchantSelector],
  ({ fetchDashboardMerchantSuccess }) => fetchDashboardMerchantSuccess
);
export const getFetchDashboardMerchantFailed = createSelector(
  [dashboardMerchantSelector],
  ({ fetchDashboardMerchantFailed }) => fetchDashboardMerchantFailed
);

export const getIsFetchDashboardMerchantHitted = createSelector(
  [dashboardMerchantSelector],
  ({ isFetchDashboardMerchantHitted }) => isFetchDashboardMerchantHitted
);
