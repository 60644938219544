import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { saveAs } from "file-saver";

import { getFileBlobFromUrl } from "../../utils/export.utils";
import { getFileNameUrl } from "../../utils/formatter.utils";

import EXPORT_PDF_ACTION_TYPES from "./export-pdf.type";

import {
  setExportPdfExtendInvoiceBlob,
  setExportPdfExtendInvoiceFailed,
  setExportPdfExtendInvoiceLoading,
  setExportPdfExtendInvoiceName,
  setExportPdfExtendInvoiceSuccess,
  setExportPdfExtendInvoiceUrl,
  setExportPdfOrderInvoiceBlob,
  setExportPdfOrderInvoiceFailed,
  setExportPdfOrderInvoiceLoading,
  setExportPdfOrderInvoiceName,
  setExportPdfOrderInvoiceSuccess,
  setExportPdfOrderInvoiceUrl,
  setExportPdfOrderKitchenBlob,
  setExportPdfOrderKitchenFailed,
  setExportPdfOrderKitchenLoading,
  setExportPdfOrderKitchenName,
  setExportPdfOrderKitchenSuccess,
  setExportPdfOrderKitchenUrl,
  setIsExportPdfExtendInvoiceHitted,
  setIsExportPdfOrderInvoiceHitted,
  setIsExportPdfOrderKitchenHitted,
} from "./export-pdf.action";

import {
  exportPdfExtendInvoice,
  exportPdfOrderInvoice,
  exportPdfOrderKitchen,
} from "../../api/export.api";

export function* _exportPdfOrderInvoice({ payload: { orderId, request } }) {
  try {
    yield put(setExportPdfOrderInvoiceLoading(true));

    const {
      meta: { message },
      data: pdfOrderInvoice,
    } = yield call(exportPdfOrderInvoice, orderId, request);

    const pdfOrderInvoiceUrl = `${pdfOrderInvoice}?time=${Date.now()}`;
    const pdfOrderInvoiceBlob = yield call(getFileBlobFromUrl, pdfOrderInvoice);

    yield put(setIsExportPdfOrderInvoiceHitted(true));
    yield put(setExportPdfOrderInvoiceUrl(pdfOrderInvoiceUrl));
    yield put(setExportPdfOrderInvoiceBlob(pdfOrderInvoiceBlob));
    yield put(setExportPdfOrderInvoiceName(getFileNameUrl(pdfOrderInvoice)));

    yield put(setExportPdfOrderInvoiceSuccess(message));
    yield put(setExportPdfOrderInvoiceLoading(false));
  } catch (error) {
    yield put(setExportPdfOrderInvoiceFailed(error));
    yield put(setExportPdfOrderInvoiceLoading(false));
  }
}
export function* _exportPdfOrderKitchen({ payload: { orderId, request } }) {
  try {
    yield put(setExportPdfOrderKitchenLoading(true));

    const {
      meta: { message },
      data: pdfOrderKitchen,
    } = yield call(exportPdfOrderKitchen, orderId, request);

    const pdfOrderKitchenUrl = `${pdfOrderKitchen}?time=${Date.now()}`;
    const pdfOrderKitchenBlob = yield call(getFileBlobFromUrl, pdfOrderKitchen);

    yield put(setIsExportPdfOrderKitchenHitted(true));
    yield put(setExportPdfOrderKitchenUrl(pdfOrderKitchenUrl));
    yield put(setExportPdfOrderKitchenBlob(pdfOrderKitchenBlob));
    yield put(setExportPdfOrderKitchenName(getFileNameUrl(pdfOrderKitchen)));

    yield put(setExportPdfOrderKitchenSuccess(message));
    yield put(setExportPdfOrderKitchenLoading(false));
  } catch (error) {
    yield put(setExportPdfOrderKitchenFailed(error));
    yield put(setExportPdfOrderKitchenLoading(false));
  }
}
export function* _exportPdfExtendInvoice({ payload: { extendId, request } }) {
  try {
    yield put(setExportPdfExtendInvoiceLoading(true));

    const {
      meta: { message },
      data: pdfExtendInvoice,
    } = yield call(exportPdfExtendInvoice, extendId, request);

    const pdfExtendInvoiceUrl = `${pdfExtendInvoice}?time=${Date.now()}`;
    const pdfExtendInvoiceBlob = yield call(
      getFileBlobFromUrl,
      pdfExtendInvoice
    );

    yield put(setIsExportPdfExtendInvoiceHitted(true));
    yield put(setExportPdfExtendInvoiceUrl(pdfExtendInvoiceUrl));
    yield put(setExportPdfExtendInvoiceBlob(pdfExtendInvoiceBlob));
    yield put(setExportPdfExtendInvoiceName(getFileNameUrl(pdfExtendInvoice)));

    yield put(setExportPdfExtendInvoiceSuccess(message));
    yield put(setExportPdfExtendInvoiceLoading(false));
  } catch (error) {
    yield put(setExportPdfExtendInvoiceFailed(error));
    yield put(setExportPdfExtendInvoiceLoading(false));
  }
}

export function* onExportPdfOrderInvoiceStart() {
  yield takeLatest(
    EXPORT_PDF_ACTION_TYPES.EXPORT_PDF_ORDER_INVOICE_START,
    _exportPdfOrderInvoice
  );
}
export function* onExportPdfOrderKitchenStart() {
  yield takeLatest(
    EXPORT_PDF_ACTION_TYPES.EXPORT_PDF_ORDER_KITCHEN_START,
    _exportPdfOrderKitchen
  );
}
export function* onExportPdfExtendInvoiceStart() {
  yield takeLatest(
    EXPORT_PDF_ACTION_TYPES.EXPORT_PDF_EXTEND_INVOICE_START,
    _exportPdfExtendInvoice
  );
}

export function* exportPdfSaga() {
  yield all([
    call(onExportPdfOrderInvoiceStart),
    call(onExportPdfOrderKitchenStart),
    call(onExportPdfExtendInvoiceStart),
  ]);
}
