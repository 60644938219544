import { createAction } from "../../utils/store.utils";

import SELECT_COUNTRY_ACTION_TYPES from "./select-country.type";

export const setCountries = (countries) =>
  createAction(SELECT_COUNTRY_ACTION_TYPES.SET_COUNTRIES, countries);
export const setSearchCountries = (searchCountries) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_SEARCH_COUNTRIES,
    searchCountries
  );

export const appendCountries = (countries) =>
  createAction(SELECT_COUNTRY_ACTION_TYPES.APPEND_COUNTRIES, countries);
export const appendSearchCountries = (searchCountries) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.APPEND_SEARCH_COUNTRIES,
    searchCountries
  );

export const setIsCountriesHasMore = (isCountriesHasMore) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_IS_COUNTRIES_HAS_MORE,
    isCountriesHasMore
  );
export const setIsSearchCountriesHasMore = (isSearchCountriesHasMore) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_IS_SEARCH_COUNTRIES_HAS_MORE,
    isSearchCountriesHasMore
  );

export const setFetchCountriesParams = (fetchCountriesParams) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_PARAMS,
    fetchCountriesParams
  );
export const setFetchCountriesLoading = (fetchCountriesLoading) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_LOADING,
    fetchCountriesLoading
  );
export const setFetchCountriesSuccess = (fetchCountriesSuccess) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_SUCCESS,
    fetchCountriesSuccess
  );
export const setFetchCountriesFailed = (fetchCountriesFailed) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_FAILED,
    fetchCountriesFailed
  );

export const setSearchCountriesParams = (searchCountriesParams) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_SEARCH_COUNTRIES_PARAMS,
    searchCountriesParams
  );
export const setSearchCountriesLoading = (searchCountriesLoading) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_SEARCH_COUNTRIES_LOADING,
    searchCountriesLoading
  );
export const setSearchCountriesSuccess = (searchCountriesSuccess) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_SEARCH_COUNTRIES_SUCCESS,
    searchCountriesSuccess
  );
export const setSearchCountriesFailed = (searchCountriesFailed) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_SEARCH_COUNTRIES_FAILED,
    searchCountriesFailed
  );

export const setAppendCountriesParams = (appendCountriesParams) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_APPEND_COUNTRIES_PARAMS,
    appendCountriesParams
  );
export const setAppendCountriesLoading = (appendCountriesLoading) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_APPEND_COUNTRIES_LOADING,
    appendCountriesLoading
  );
export const setAppendCountriesSuccess = (appendCountriesSuccess) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_APPEND_COUNTRIES_SUCCESS,
    appendCountriesSuccess
  );
export const setAppendCountriesFailed = (appendCountriesFailed) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_APPEND_COUNTRIES_FAILED,
    appendCountriesFailed
  );

export const setIsFetchCountriesHitted = (isFetchCountriesHitted) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_IS_FETCH_COUNTRIES_HITTED,
    isFetchCountriesHitted
  );
export const setIsSearchCountriesHitted = (isSearchCountriesHitted) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_IS_SEARCH_COUNTRIES_HITTED,
    isSearchCountriesHitted
  );
export const setIsAppendCountriesHitted = (isAppendCountriesHitted) =>
  createAction(
    SELECT_COUNTRY_ACTION_TYPES.SET_IS_APPEND_COUNTRIES_HITTED,
    isAppendCountriesHitted
  );

export const fetchCountriesStart = (params) =>
  createAction(SELECT_COUNTRY_ACTION_TYPES.FETCH_COUNTRIES_START, params);
export const searchCountriesStart = (params) =>
  createAction(SELECT_COUNTRY_ACTION_TYPES.SEARCH_COUNTRIES_START, params);
export const appendCountriesStart = (params) =>
  createAction(SELECT_COUNTRY_ACTION_TYPES.APPEND_COUNTRIES_START, params);

export const resetCountryReducer = () =>
  createAction(SELECT_COUNTRY_ACTION_TYPES.RESET_SELECT_COUNTRY_REDUCER);
