import EXPORT_PDF_ACTION_TYPES from "./export-pdf.type";
import { createAction } from "../../utils/store.utils";

export const setExportPdfOrderInvoiceUrl = (exportPdfOrderInvoiceUrl) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_URL,
    exportPdfOrderInvoiceUrl
  );
export const setExportPdfOrderInvoiceBlob = (exportPdfOrderInvoiceBlob) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_BLOB,
    exportPdfOrderInvoiceBlob
  );
export const setExportPdfOrderInvoiceName = (exportPdfOrderInvoiceName) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_NAME,
    exportPdfOrderInvoiceName
  );

export const setExportPdfOrderKitchenUrl = (exportPdfOrderKitchenUrl) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_URL,
    exportPdfOrderKitchenUrl
  );
export const setExportPdfOrderKitchenBlob = (exportPdfOrderKitchenBlob) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_BLOB,
    exportPdfOrderKitchenBlob
  );
export const setExportPdfOrderKitchenName = (exportPdfOrderKitchenName) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_NAME,
    exportPdfOrderKitchenName
  );

export const setExportPdfExtendInvoiceUrl = (exportPdfExtendInvoiceUrl) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_EXTEND_INVOICE_URL,
    exportPdfExtendInvoiceUrl
  );
export const setExportPdfExtendInvoiceBlob = (exportPdfExtendInvoiceBlob) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_EXTEND_INVOICE_BLOB,
    exportPdfExtendInvoiceBlob
  );
export const setExportPdfExtendInvoiceName = (exportPdfExtendInvoiceName) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_EXTEND_INVOICE_NAME,
    exportPdfExtendInvoiceName
  );

export const setExportPdfOrderInvoiceLoading = (exportPdfOrderInvoiceLoading) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_LOADING,
    exportPdfOrderInvoiceLoading
  );
export const setExportPdfOrderInvoiceSuccess = (exportPdfOrderInvoiceSuccess) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_SUCCESS,
    exportPdfOrderInvoiceSuccess
  );
export const setExportPdfOrderInvoiceFailed = (exportPdfOrderInvoiceFailed) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_FAILED,
    exportPdfOrderInvoiceFailed
  );

export const setExportPdfOrderKitchenLoading = (exportPdfOrderKitchenLoading) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_LOADING,
    exportPdfOrderKitchenLoading
  );
export const setExportPdfOrderKitchenSuccess = (exportPdfOrderKitchenSuccess) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_SUCCESS,
    exportPdfOrderKitchenSuccess
  );
export const setExportPdfOrderKitchenFailed = (exportPdfOrderKitchenFailed) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_FAILED,
    exportPdfOrderKitchenFailed
  );

export const setExportPdfExtendInvoiceLoading = (
  exportPdfExtendInvoiceLoading
) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_EXTEND_INVOICE_LOADING,
    exportPdfExtendInvoiceLoading
  );
export const setExportPdfExtendInvoiceSuccess = (
  exportPdfExtendInvoiceSuccess
) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_EXTEND_INVOICE_SUCCESS,
    exportPdfExtendInvoiceSuccess
  );
export const setExportPdfExtendInvoiceFailed = (exportPdfExtendInvoiceFailed) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_EXTEND_INVOICE_FAILED,
    exportPdfExtendInvoiceFailed
  );

export const exportPdfOrderInvoiceStart = (orderId, request) =>
  createAction(EXPORT_PDF_ACTION_TYPES.EXPORT_PDF_ORDER_INVOICE_START, {
    orderId,
    request,
  });
export const exportPdfOrderKitchenStart = (orderId, request) =>
  createAction(EXPORT_PDF_ACTION_TYPES.EXPORT_PDF_ORDER_KITCHEN_START, {
    orderId,
    request,
  });
export const exportPdfExtendInvoiceStart = (extendId, request) =>
  createAction(EXPORT_PDF_ACTION_TYPES.EXPORT_PDF_EXTEND_INVOICE_START, {
    extendId,
    request,
  });

export const setIsExportPdfOrderInvoiceHitted = (
  isExportPdfOrderInvoiceHitted
) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_IS_EXPORT_PDF_ORDER_INVOICE_HITTED,
    isExportPdfOrderInvoiceHitted
  );
export const setIsExportPdfOrderKitchenHitted = (
  isExportPdfOrderKitchenHitted
) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_IS_EXPORT_PDF_ORDER_KITCHEN_HITTED,
    isExportPdfOrderKitchenHitted
  );
export const setIsExportPdfExtendInvoiceHitted = (
  isExportPdfExtendInvoiceHitted
) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_IS_EXPORT_PDF_EXTEND_INVOICE_HITTED,
    isExportPdfExtendInvoiceHitted
  );

export const resetExportPdfReducer = () =>
  createAction(EXPORT_PDF_ACTION_TYPES.RESET_EXPORT_PDF_REDUCER);
