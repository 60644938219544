import { takeLatest, put, all, call, select } from "redux-saga/effects";

import WHATSAPP_ACTION_TYPES from "./whatsapp.type";

import {
  appendWhatsapps,
  setCreateOrUpdateWhatsappFailed,
  setCreateOrUpdateWhatsappLoading,
  setCreateOrUpdateWhatsappSuccess,
  setFetchWhatsappFailed,
  setFetchWhatsappLoading,
  setFetchWhatsappsFailed,
  setFetchWhatsappsLoading,
  setFetchWhatsappsSuccess,
  setFetchWhatsappSuccess,
  setIsCreateOrUpdateWhatsappHitted,
  setIsFetchWhatsappHitted,
  setIsFetchWhatsappsHitted,
  setIsWhatsappsHasMore,
  setWhatsapp,
  setWhatsapps,
} from "./whatsapp.action";
import {
  getFetchWhatsappIsQrCode,
  getFetchWhatsappsFilterIsActive,
  getFetchWhatsappsIncludes,
  getFetchWhatsappsPage,
  getFetchWhatsappsPerPage,
} from "./whatsapp.selector";

import { getWhatsapps, getWhatsapp, createOrUpdateWhatsapp } from "../../api/whatsapp.api";

export function* _getWhatsapps() {
  try {
    yield put(setFetchWhatsappsLoading(true));

    const page = yield select(getFetchWhatsappsPage);
    const per_page = yield select(getFetchWhatsappsPerPage);
    const includes = yield select(getFetchWhatsappsIncludes);
    const is_active = yield select(getFetchWhatsappsFilterIsActive);

    const parameters = {
      page,
      per_page,
      includes,
      filter: { is_active },
    };

    const {
      meta: { message },
      data: { data: whatsapps },
    } = yield call(getWhatsapps, parameters);

    yield put(setIsFetchWhatsappsHitted(true));
    yield put(setIsWhatsappsHasMore(whatsapps.length > 0));

    if (page > 1) {
      yield put(appendWhatsapps(whatsapps));
    } else {
      yield put(setWhatsapps(whatsapps));
    }

    yield put(setFetchWhatsappsSuccess(message));
    yield put(setFetchWhatsappsLoading(false));
  } catch (error) {
    yield put(setFetchWhatsappsFailed(error));
    yield put(setFetchWhatsappsLoading(false));
  }
}

export function* _getWhatsapp({ payload: marketId }) {
  try {
    yield put(setFetchWhatsappLoading(true));

    const is_qr_code = yield select(getFetchWhatsappIsQrCode);
    const parameters = { is_qr_code };

    const {
      meta: { message },
      data: whatsapp,
    } = yield call(getWhatsapp, marketId, parameters);

    yield put(setIsFetchWhatsappHitted(true));
    yield put(setWhatsapp(whatsapp));

    yield put(setFetchWhatsappSuccess(message));
    yield put(setFetchWhatsappLoading(false));
  } catch (error) {
    yield put(setFetchWhatsappFailed(error));
    yield put(setFetchWhatsappLoading(false));
  }
}

export function* _createOrUpdateWhatsapp({ payload: request }) {
  try {
    yield put(setCreateOrUpdateWhatsappLoading(true));

    const {
      meta: { message },
      data: whatsapp,
    } = yield call(createOrUpdateWhatsapp, request);

    yield put(setIsCreateOrUpdateWhatsappHitted(true));
    yield put(setWhatsapp(whatsapp));

    yield put(setCreateOrUpdateWhatsappSuccess(message));
    yield put(setCreateOrUpdateWhatsappLoading(false));
  } catch (error) {
    yield put(setCreateOrUpdateWhatsappFailed(error));
    yield put(setCreateOrUpdateWhatsappLoading(false));
  }
}

export function* onFetchWhatsappsStart() {
  yield takeLatest(WHATSAPP_ACTION_TYPES.FETCH_WHATSAPPS_START, _getWhatsapps);
}

export function* onFetchWhatsappStart() {
  yield takeLatest(WHATSAPP_ACTION_TYPES.FETCH_WHATSAPP_START, _getWhatsapp);
}

export function* onCreateOrUpdateWhatsappStart() {
  yield takeLatest(WHATSAPP_ACTION_TYPES.CREATE_OR_UPDATE_WHATSAPP_START, _createOrUpdateWhatsapp);
}

export function* whatsappSaga() {
  yield all([call(onFetchWhatsappsStart), call(onFetchWhatsappStart), call(onCreateOrUpdateWhatsappStart)]);
}
