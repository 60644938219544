export const TAX_INCLUDES = {
  MARKET: "market",
  BRANCH: "branch",
  PRODUCTS: "products",
  FEES: "fees",
};

export const TAX_VALUE_TYPES = {
  PERCENT: "PERCENT",
  NOMINAL: "NOMINAL",
};
