import BRANCH_ACTION_TYPES from "./branch.type";
import { createAction } from "../../utils/store.utils";

export const setIsModalAutoClose = (isModalAutoClose) =>
  createAction(BRANCH_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE, isModalAutoClose);

export const setBranches = (branches) =>
  createAction(BRANCH_ACTION_TYPES.SET_BRANCHES, branches);
export const setBranch = (branch) =>
  createAction(BRANCH_ACTION_TYPES.SET_BRANCH, branch);
export const setSubscriptionBranches = (subscriptionBranches) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_SUBSCRIPTION_BRANCHES,
    subscriptionBranches
  );
export const setSubscriptionBranch = (subscriptionBranch) =>
  createAction(BRANCH_ACTION_TYPES.SET_SUBSCRIPTION_BRANCH, subscriptionBranch);

export const setIsBranchesHasMore = (isBranchesHasMore) =>
  createAction(BRANCH_ACTION_TYPES.SET_IS_BRANCHES_HAS_MORE, isBranchesHasMore);
export const setIsSubscriptionBranchesHasMore = (
  isSubscriptionBranchesHasMore
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_SUBSCRIPTION_BRANCHES_HAS_MORE,
    isSubscriptionBranchesHasMore
  );

export const setFetchBranchesSearch = (fetchBranchesSearch) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SEARCH,
    fetchBranchesSearch
  );
export const setFetchBranchesPage = (fetchBranchesPage) =>
  createAction(BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PAGE, fetchBranchesPage);
export const setFetchBranchesPerPage = (fetchBranchesPerPage) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PER_PAGE,
    fetchBranchesPerPage
  );
export const setFetchBranchesIncludes = (fetchBranchesIncludes) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_INCLUDES,
    fetchBranchesIncludes
  );
export const setFetchBranchesFilterMarketId = (fetchBranchesFilterMarketId) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FILTER_MARKET_ID,
    fetchBranchesFilterMarketId
  );
export const setFetchBranchesFilterIsActive = (fetchBranchesFilterIsActive) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FILTER_IS_ACTIVE,
    fetchBranchesFilterIsActive
  );
export const setFetchBranchesLoading = (fetchBranchesLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_LOADING,
    fetchBranchesLoading
  );
export const setFetchBranchesSuccess = (fetchBranchesSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SUCCESS,
    fetchBranchesSuccess
  );
export const setFetchBranchesFailed = (fetchBranchesFailed) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FAILED,
    fetchBranchesFailed
  );

export const setFetchBranchLoading = (fetchBranchLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_LOADING,
    fetchBranchLoading
  );
export const setFetchBranchSuccess = (fetchBranchSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_SUCCESS,
    fetchBranchSuccess
  );
export const setFetchBranchFailed = (fetchBranchFailed) =>
  createAction(BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_FAILED, fetchBranchFailed);

export const setFetchSubscriptionBranchesPage = (
  fetchSubscriptionBranchesPage
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_PAGE,
    fetchSubscriptionBranchesPage
  );
export const setFetchSubscriptionBranchesPerPage = (
  fetchSubscriptionBranchesPerPage
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_PER_PAGE,
    fetchSubscriptionBranchesPerPage
  );
export const setFetchSubscriptionBranchesIncludes = (
  fetchSubscriptionBranchesIncludes
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_INCLUDES,
    fetchSubscriptionBranchesIncludes
  );
export const setFetchSubscriptionBranchesFilterMarketId = (
  fetchSubscriptionBranchesFilterMarketId
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_FILTER_MARKET_ID,
    fetchSubscriptionBranchesFilterMarketId
  );
export const setFetchSubscriptionBranchesFilterIsActive = (
  fetchSubscriptionBranchesFilterIsActive
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_FILTER_IS_ACTIVE,
    fetchSubscriptionBranchesFilterIsActive
  );
export const setFetchSubscriptionBranchesLoading = (
  fetchSubscriptionBranchesLoading
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_LOADING,
    fetchSubscriptionBranchesLoading
  );
export const setFetchSubscriptionBranchesSuccess = (
  fetchSubscriptionBranchesSuccess
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_SUCCESS,
    fetchSubscriptionBranchesSuccess
  );
export const setFetchSubscriptionBranchesFailed = (
  fetchSubscriptionBranchesFailed
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_FAILED,
    fetchSubscriptionBranchesFailed
  );

export const setFetchSubscriptionBranchLoading = (
  fetchSubscriptionBranchLoading
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCH_LOADING,
    fetchSubscriptionBranchLoading
  );
export const setFetchSubscriptionBranchSuccess = (
  fetchSubscriptionBranchSuccess
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCH_SUCCESS,
    fetchSubscriptionBranchSuccess
  );
export const setFetchSubscriptionBranchFailed = (
  fetchSubscriptionBranchFailed
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCH_FAILED,
    fetchSubscriptionBranchFailed
  );

export const setCreateBranchLoading = (createBranchLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_CREATE_BRANCH_LOADING,
    createBranchLoading
  );
export const setCreateBranchSuccess = (createBranchSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_CREATE_BRANCH_SUCCESS,
    createBranchSuccess
  );
export const setCreateBranchFailed = (createBranchFailed) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_CREATE_BRANCH_FAILED,
    createBranchFailed
  );

export const setUpdateBranchLoading = (updateBranchLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_UPDATE_BRANCH_LOADING,
    updateBranchLoading
  );
export const setUpdateBranchSuccess = (updateBranchSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_UPDATE_BRANCH_SUCCESS,
    updateBranchSuccess
  );
export const setUpdateBranchFailed = (updateBranchFailed) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_UPDATE_BRANCH_FAILED,
    updateBranchFailed
  );

export const setDeleteBranchLoading = (deleteBranchLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_DELETE_BRANCH_LOADING,
    deleteBranchLoading
  );
export const setDeleteBranchSuccess = (deleteBranchSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_DELETE_BRANCH_SUCCESS,
    deleteBranchSuccess
  );
export const setDeleteBranchFailed = (deleteBranchFailed) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_DELETE_BRANCH_FAILED,
    deleteBranchFailed
  );

export const appendBranches = (branches) =>
  createAction(BRANCH_ACTION_TYPES.APPEND_BRANCHES, branches);
export const appendSubscriptionBranches = (SubscriptionBranches) =>
  createAction(
    BRANCH_ACTION_TYPES.APPEND_SUBSCRIPTION_BRANCHES,
    SubscriptionBranches
  );

export const fetchBranchesStart = () =>
  createAction(BRANCH_ACTION_TYPES.FETCH_BRANCHES_START);
export const fetchBranchStart = (branchId) =>
  createAction(BRANCH_ACTION_TYPES.FETCH_BRANCH_START, branchId);
export const fetchSubscriptionBranchesStart = () =>
  createAction(BRANCH_ACTION_TYPES.FETCH_SUBSCRIPTION_BRANCHES_START);
export const fetchSubscriptionBranchStart = (branchId) =>
  createAction(BRANCH_ACTION_TYPES.FETCH_SUBSCRIPTION_BRANCH_START, branchId);
export const createBranchStart = (request) =>
  createAction(BRANCH_ACTION_TYPES.CREATE_BRANCH_START, request);
export const updateBranchStart = (branchId, request) =>
  createAction(BRANCH_ACTION_TYPES.UPDATE_BRANCH_START, { branchId, request });
export const deleteBranchStart = (branchId) =>
  createAction(BRANCH_ACTION_TYPES.DELETE_BRANCH_START, branchId);

export const setIsFetchBranchesHitted = (isFetchBranchesHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCHES_HITTED,
    isFetchBranchesHitted
  );
export const setIsFetchBranchHitted = (isFetchBranchHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCH_HITTED,
    isFetchBranchHitted
  );
export const setIsFetchSubscriptionBranchesHitted = (
  isFetchSubscriptionBranchesHitted
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_FETCH_SUBSCRIPTION_BRANCHES_HITTED,
    isFetchSubscriptionBranchesHitted
  );
export const setIsFetchSubscriptionBranchHitted = (
  isFetchSubscriptionBranchHitted
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_FETCH_SUBSCRIPTION_BRANCH_HITTED,
    isFetchSubscriptionBranchHitted
  );
export const setIsCreateBranchHitted = (isCreateBranchHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_CREATE_BRANCH_HITTED,
    isCreateBranchHitted
  );
export const setIsUpdateBranchHitted = (isUpdateBranchHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_UPDATE_BRANCH_HITTED,
    isUpdateBranchHitted
  );
export const setIsDeleteBranchHitted = (isDeleteBranchHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_DELETE_BRANCH_HITTED,
    isDeleteBranchHitted
  );

export const resetBranchReducer = () =>
  createAction(BRANCH_ACTION_TYPES.RESET_BRANCH_REDUCER);
