const MSEGAT_ACTION_TYPES = {
  RESET_MSEGAT_REDUCER: "msegat/RESET_MSEGAT_REDUCER",

  SET_MSEGATS: "msegat/SET_MSEGATS",
  SET_MSEGAT: "msegat/SET_MSEGAT",

  SET_IS_MSEGATS_HAS_MORE: "msegat/SET_IS_MSEGATS_HAS_MORE",

  SET_FETCH_MSEGATS_SEARCH: "msegat/SET_FETCH_MSEGATS_SEARCH",
  SET_FETCH_MSEGATS_PAGE: "msegat/SET_FETCH_MSEGATS_PAGE",
  SET_FETCH_MSEGATS_PER_PAGE: "msegat/SET_FETCH_MSEGATS_PER_PAGE",
  SET_FETCH_MSEGATS_INCLUDES: "msegat/SET_FETCH_MSEGATS_INCLUDES",
  SET_FETCH_MSEGATS_FILTER_IS_ACTIVE: "msegat/SET_FETCH_MSEGATS_FILTER_IS_ACTIVE",
  SET_FETCH_MSEGATS_LOADING: "msegat/SET_FETCH_MSEGATS_LOADING",
  SET_FETCH_MSEGATS_SUCCESS: "msegat/SET_FETCH_MSEGATS_SUCCESS",
  SET_FETCH_MSEGATS_FAILED: "msegat/SET_FETCH_MSEGATS_FAILED",

  SET_FETCH_MSEGAT_LOADING: "msegat/SET_FETCH_MSEGAT_LOADING",
  SET_FETCH_MSEGAT_SUCCESS: "msegat/SET_FETCH_MSEGAT_SUCCESS",
  SET_FETCH_MSEGAT_FAILED: "msegat/SET_FETCH_MSEGAT_FAILED",

  SET_CREATE_OR_UPDATE_MSEGAT_LOADING: "msegat/SET_CREATE_OR_UPDATE_MSEGAT_LOADING",
  SET_CREATE_OR_UPDATE_MSEGAT_SUCCESS: "msegat/SET_CREATE_OR_UPDATE_MSEGAT_SUCCESS",
  SET_CREATE_OR_UPDATE_MSEGAT_FAILED: "msegat/SET_CREATE_OR_UPDATE_MSEGAT_FAILED",

  APPEND_MSEGATS: "msegat/APPEND_MSEGATS",

  SET_IS_FETCH_MSEGATS_HITTED: "msegat/SET_IS_FETCH_MSEGATS_HITTED",
  SET_IS_FETCH_MSEGAT_HITTED: "msegat/SET_IS_FETCH_MSEGAT_HITTED",
  SET_IS_CREATE_OR_UPDATE_MSEGAT_HITTED: "msegat/SET_IS_CREATE_OR_UPDATE_MSEGAT_HITTED",

  FETCH_MSEGATS_START: "msegat/FETCH_MSEGATS_START",
  FETCH_MSEGAT_START: "msegat/FETCH_MSEGAT_START",
  CREATE_OR_UPDATE_MSEGAT_START: "msegat/CREATE_OR_UPDATE_MSEGAT_START",
};

export default MSEGAT_ACTION_TYPES;
