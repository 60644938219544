const PRODUCT_ACTION_TYPES = {
  RESET_PRODUCT_REDUCER: "product-quick/RESET_PRODUCT_REDUCER",

  SET_CREATE_PRODUCT: "product-quick/SET_CREATE_PRODUCT",
  SET_UPDATE_PRODUCT: "product-quick/SET_UPDATE_PRODUCT",

  SET_CREATE_PRODUCT_LOADING: "product-quick/SET_CREATE_PRODUCT_LOADING",
  SET_CREATE_PRODUCT_SUCCESS: "product-quick/SET_CREATE_PRODUCT_SUCCESS",
  SET_CREATE_PRODUCT_FAILED: "product-quick/SET_CREATE_PRODUCT_FAILED",

  SET_UPDATE_PRODUCT_LOADING: "product-quick/SET_UPDATE_PRODUCT_LOADING",
  SET_UPDATE_PRODUCT_SUCCESS: "product-quick/SET_UPDATE_PRODUCT_SUCCESS",
  SET_UPDATE_PRODUCT_FAILED: "product-quick/SET_UPDATE_PRODUCT_FAILED",

  SET_CREATE_PRODUCTS_LOADING: "product-quick/SET_CREATE_PRODUCTS_LOADING",
  SET_CREATE_PRODUCTS_SUCCESS: "product-quick/SET_CREATE_PRODUCTS_SUCCESS",
  SET_CREATE_PRODUCTS_FAILED: "product-quick/SET_CREATE_PRODUCTS_FAILED",

  IS_CREATE_PRODUCT_HITTED: "product-quick/IS_CREATE_PRODUCT_HITTED",
  IS_UPDATE_PRODUCT_HITTED: "product-quick/IS_UPDATE_PRODUCT_HITTED",
  IS_CREATE_PRODUCTS_HITTED: "product-quick/IS_CREATE_PRODUCTS_HITTED",

  CREATE_PRODUCT_START: "product-quick/CREATE_PRODUCT_START",
  UPDATE_PRODUCT_START: "product-quick/UPDATE_PRODUCT_START",
  CREATE_PRODUCTS_START: "product-quick/CREATE_PRODUCTS_START",
};

export default PRODUCT_ACTION_TYPES;
