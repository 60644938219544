import apiService from "./api";

export const getUserAdmins = async (parameters) =>
  (await apiService.get("/v2/users/admins", parameters)).data;
export const getUserAdmin = async (adminId) =>
  (await apiService.get(`/v2/users/admins/${adminId}`)).data;
export const createUserAdmin = async (request) =>
  (await apiService.post("/v2/users/admins", request)).data;
export const updateUserAdmin = async (adminId, request) =>
  (await apiService.post(`/v2/users/admins/${adminId}`, request)).data;
export const deleteUserAdmin = async (adminId) =>
  (await apiService.delete(`/v2/users/admins/${adminId}`)).data;
