import { createSelector } from "reselect";

const pdfSelector = ({ pdf }) => pdf;

export const getOrderReceipt = createSelector([pdfSelector], ({ orderReceipt }) => orderReceipt);
export const getOrderNotes = createSelector([pdfSelector], ({ orderNotes }) => orderNotes);
export const getExtendPeriodReceipt = createSelector([pdfSelector], ({ extendPeriodReceipt }) => extendPeriodReceipt);

export const getPdfOrderReceiptIsMultiLang = createSelector(
  [pdfSelector],
  ({ pdfOrderReceiptIsMultiLang }) => pdfOrderReceiptIsMultiLang
);
export const getPdfOrderReceiptLoading = createSelector(
  [pdfSelector],
  ({ pdfOrderReceiptLoading }) => pdfOrderReceiptLoading
);
export const getPdfOrderReceiptSuccess = createSelector(
  [pdfSelector],
  ({ pdfOrderReceiptSuccess }) => pdfOrderReceiptSuccess
);
export const getPdfOrderReceiptFailed = createSelector(
  [pdfSelector],
  ({ pdfOrderReceiptFailed }) => pdfOrderReceiptFailed
);

export const getPdfOrderNotesIsMultiLang = createSelector(
  [pdfSelector],
  ({ pdfOrderNotesIsMultiLang }) => pdfOrderNotesIsMultiLang
);
export const getPdfOrderNotesLoading = createSelector(
  [pdfSelector],
  ({ pdfOrderNotesLoading }) => pdfOrderNotesLoading
);
export const getPdfOrderNotesSuccess = createSelector(
  [pdfSelector],
  ({ pdfOrderNotesSuccess }) => pdfOrderNotesSuccess
);
export const getPdfOrderNotesFailed = createSelector([pdfSelector], ({ pdfOrderNotesFailed }) => pdfOrderNotesFailed);

export const getPdfExtendPeriodReceiptLoading = createSelector(
  [pdfSelector],
  ({ pdfExtendPeriodReceiptLoading }) => pdfExtendPeriodReceiptLoading
);
export const getPdfExtendPeriodReceiptSuccess = createSelector(
  [pdfSelector],
  ({ pdfExtendPeriodReceiptSuccess }) => pdfExtendPeriodReceiptSuccess
);
export const getPdfExtendPeriodReceiptFailed = createSelector(
  [pdfSelector],
  ({ pdfExtendPeriodReceiptFailed }) => pdfExtendPeriodReceiptFailed
);

export const getIsPdfOrderReceiptHitted = createSelector(
  [pdfSelector],
  ({ isPdfOrderReceiptHitted }) => isPdfOrderReceiptHitted
);
export const getIsPdfOrderNotesHitted = createSelector(
  [pdfSelector],
  ({ isPdfOrderNotesHitted }) => isPdfOrderNotesHitted
);
export const getIsPdfExtendPeriodReceiptHitted = createSelector(
  [pdfSelector],
  ({ isPdfExtendPeriodReceiptHitted }) => isPdfExtendPeriodReceiptHitted
);
