import { createSelector } from "reselect";

const advertisementSelector = ({ advertisement }) => advertisement;

export const getAdvertisementTypes = createSelector(
  [advertisementSelector],
  ({ advertisementTypes }) => advertisementTypes
);

export const getAdvertisements = createSelector([advertisementSelector], ({ advertisements }) => advertisements);

export const getAdvertisement = createSelector([advertisementSelector], ({ advertisement }) => advertisement);

export const getIsAdvertisementsHasMore = createSelector(
  [advertisementSelector],
  ({ isAdvertisementsHasMore }) => isAdvertisementsHasMore
);

export const getFetchAdvertisementsSearch = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementsSearch }) => fetchAdvertisementsSearch
);

export const getFetchAdvertisementsPage = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementsPage }) => fetchAdvertisementsPage
);

export const getFetchAdvertisementsPerPage = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementsPerPage }) => fetchAdvertisementsPerPage
);

export const getFetchAdvertisementsIncludes = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementsIncludes }) => fetchAdvertisementsIncludes
);

export const getFetchAdvertisementsFilterMarketId = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementsFilterMarketId }) => fetchAdvertisementsFilterMarketId
);

export const getFetchAdvertisementsFilterType = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementsFilterType }) => fetchAdvertisementsFilterType
);

export const getFetchAdvertisementsFilterStatuses = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementsFilterStatuses }) => fetchAdvertisementsFilterStatuses
);

export const getFetchAdvertisementsFilterStartAt = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementsFilterStartAt }) => fetchAdvertisementsFilterStartAt
);

export const getFetchAdvertisementsFilterEndAt = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementsFilterEndAt }) => fetchAdvertisementsFilterEndAt
);

export const getFetchAdvertisementsLoading = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementsLoading }) => fetchAdvertisementsLoading
);

export const getFetchAdvertisementsSuccess = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementsSuccess }) => fetchAdvertisementsSuccess
);

export const getFetchAdvertisementsFailed = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementsFailed }) => fetchAdvertisementsFailed
);

export const getFetchAdvertisementLoading = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementLoading }) => fetchAdvertisementLoading
);

export const getFetchAdvertisementSuccess = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementSuccess }) => fetchAdvertisementSuccess
);

export const getFetchAdvertisementFailed = createSelector(
  [advertisementSelector],
  ({ fetchAdvertisementFailed }) => fetchAdvertisementFailed
);

export const getCreateAdvertisementLoading = createSelector(
  [advertisementSelector],
  ({ createAdvertisementLoading }) => createAdvertisementLoading
);

export const getCreateAdvertisementSuccess = createSelector(
  [advertisementSelector],
  ({ createAdvertisementSuccess }) => createAdvertisementSuccess
);

export const getCreateAdvertisementFailed = createSelector(
  [advertisementSelector],
  ({ createAdvertisementFailed }) => createAdvertisementFailed
);

export const getUpdateAdvertisementLoading = createSelector(
  [advertisementSelector],
  ({ updateAdvertisementLoading }) => updateAdvertisementLoading
);

export const getUpdateAdvertisementSuccess = createSelector(
  [advertisementSelector],
  ({ updateAdvertisementSuccess }) => updateAdvertisementSuccess
);

export const getUpdateAdvertisementFailed = createSelector(
  [advertisementSelector],
  ({ updateAdvertisementFailed }) => updateAdvertisementFailed
);

export const getDeleteAdvertisementLoading = createSelector(
  [advertisementSelector],
  ({ deleteAdvertisementLoading }) => deleteAdvertisementLoading
);

export const getDeleteAdvertisementSuccess = createSelector(
  [advertisementSelector],
  ({ deleteAdvertisementSuccess }) => deleteAdvertisementSuccess
);

export const getDeleteAdvertisementFailed = createSelector(
  [advertisementSelector],
  ({ deleteAdvertisementFailed }) => deleteAdvertisementFailed
);

export const getIsFetchAdvertisementsHitted = createSelector(
  [advertisementSelector],
  ({ isFetchAdvertisementsHitted }) => isFetchAdvertisementsHitted
);

export const getIsFetchAdvertisementHitted = createSelector(
  [advertisementSelector],
  ({ isFetchAdvertisementHitted }) => isFetchAdvertisementHitted
);

export const getIsCreateAdvertisementHitted = createSelector(
  [advertisementSelector],
  ({ isCreateAdvertisementHitted }) => isCreateAdvertisementHitted
);

export const getIsUpdateAdvertisementHitted = createSelector(
  [advertisementSelector],
  ({ isUpdateAdvertisementHitted }) => isUpdateAdvertisementHitted
);

export const getIsDeleteAdvertisementHitted = createSelector(
  [advertisementSelector],
  ({ isDeleteAdvertisementHitted }) => isDeleteAdvertisementHitted
);
