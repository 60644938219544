import { takeLatest, put, all, call } from "redux-saga/effects";

import EVENT_ACTION_TYPES from "./event.type";

import { setIsSendEventHitted, setSendEventFailed, setSendEventLoading, setSendEventSuccess } from "./event.action";

import { sendEvent } from "../../api/event.api";

export function* _sendEvent({ payload: request }) {
  try {
    yield put(setSendEventLoading(true));

    const {
      meta: { message },
    } = yield call(sendEvent, request);

    yield put(setIsSendEventHitted(true));

    yield put(setSendEventSuccess(message));
    yield put(setSendEventLoading(false));
  } catch (error) {
    yield put(setSendEventFailed(error));
    yield put(setSendEventLoading(false));
  }
}

export function* onSendEventStart() {
  yield takeLatest(EVENT_ACTION_TYPES.SEND_EVENT_START, _sendEvent);
}

export function* eventSaga() {
  yield all([call(onSendEventStart)]);
}
