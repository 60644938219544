import apiService from "./api";

export const uploadFile = async (request) =>
  (await apiService.post("/v2/file/upload", request)).data;
export const uploadFiles = async (request) =>
  (await apiService.post("/v2/files/upload", request)).data;
export const deleteFile = async (request) =>
  (await apiService.delete("/v2/file/delete", request)).data;
export const deleteFiles = async (request) =>
  (await apiService.delete("/v2/files/delete", request)).data;
