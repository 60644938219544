import apiService from "./api";

export const getExtendPeriods = async (parameters) =>
  (await apiService.get("/v2/extend-periods", parameters)).data;
export const getExtendPeriod = async (extendId) =>
  (await apiService.get(`/v2/extend-periods/${extendId}`)).data;
export const createExtendPeriod = async (request) =>
  (await apiService.post("/v2/extend-periods", request)).data;
export const updateExtendPeriod = async (extendId, request) =>
  (await apiService.post(`/v2/extend-periods/${extendId}`, request)).data;
export const customExtendPeriod = async (request) =>
  (await apiService.post("/v2/extend-periods/custom", request)).data;
export const stopExtendPeriod = async (request) =>
  (await apiService.post("/v2/extend-periods/stop", request)).data;

export const calculateCreateExtendPeriod = async (request) =>
  (await apiService.post("/v2/extend-periods/calculate", request)).data;
export const calculateUpdateExtendPeriod = async (extendId, request) =>
  (await apiService.post(`/v2/extend-periods/${extendId}/calculate`, request))
    .data;
