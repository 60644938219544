import TAX_ACTION_TYPES from "./tax.type";
import { createAction } from "../../utils/store.utils";

export const setTaxes = (taxes) =>
  createAction(TAX_ACTION_TYPES.SET_TAXES, taxes);
export const setTax = (tax) => createAction(TAX_ACTION_TYPES.SET_TAX, tax);

export const setIsTaxesHasMore = (isTaxesHasMore) =>
  createAction(TAX_ACTION_TYPES.SET_IS_TAXES_HAS_MORE, isTaxesHasMore);

export const setFetchTaxesSearch = (fetchTaxesSearch) =>
  createAction(TAX_ACTION_TYPES.SET_FETCH_TAXES_SEARCH, fetchTaxesSearch);
export const setFetchTaxesSort = (fetchTaxesSort) =>
  createAction(TAX_ACTION_TYPES.SET_FETCH_TAXES_SORT, fetchTaxesSort);
export const setFetchTaxesKeyBy = (fetchTaxesKeyBy) =>
  createAction(TAX_ACTION_TYPES.SET_FETCH_TAXES_KEY_BY, fetchTaxesKeyBy);
export const setFetchTaxesPage = (fetchTaxesPage) =>
  createAction(TAX_ACTION_TYPES.SET_FETCH_TAXES_PAGE, fetchTaxesPage);
export const setFetchTaxesPerPage = (fetchTaxesPerPage) =>
  createAction(TAX_ACTION_TYPES.SET_FETCH_TAXES_PER_PAGE, fetchTaxesPerPage);
export const setFetchTaxesIncludes = (fetchTaxesIncludes) =>
  createAction(TAX_ACTION_TYPES.SET_FETCH_TAXES_INCLUDES, fetchTaxesIncludes);
export const setFetchTaxesFilterMarketId = (fetchTaxesFilterMarketId) =>
  createAction(
    TAX_ACTION_TYPES.SET_FETCH_TAXES_FILTER_MARKET_ID,
    fetchTaxesFilterMarketId
  );
export const setFetchTaxesFilterBranchId = (fetchTaxesFilterBranchId) =>
  createAction(
    TAX_ACTION_TYPES.SET_FETCH_TAXES_FILTER_BRANCH_ID,
    fetchTaxesFilterBranchId
  );
export const setFetchTaxesLoading = (fetchTaxesLoading) =>
  createAction(TAX_ACTION_TYPES.SET_FETCH_TAXES_LOADING, fetchTaxesLoading);
export const setFetchTaxesSuccess = (fetchTaxesSuccess) =>
  createAction(TAX_ACTION_TYPES.SET_FETCH_TAXES_SUCCESS, fetchTaxesSuccess);
export const setFetchTaxesFailed = (fetchTaxesFailed) =>
  createAction(TAX_ACTION_TYPES.SET_FETCH_TAXES_FAILED, fetchTaxesFailed);

export const setFetchTaxLoading = (fetchTaxLoading) =>
  createAction(TAX_ACTION_TYPES.SET_FETCH_TAX_LOADING, fetchTaxLoading);
export const setFetchTaxSuccess = (fetchTaxSuccess) =>
  createAction(TAX_ACTION_TYPES.SET_FETCH_TAX_SUCCESS, fetchTaxSuccess);
export const setFetchTaxFailed = (fetchTaxFailed) =>
  createAction(TAX_ACTION_TYPES.SET_FETCH_TAX_FAILED, fetchTaxFailed);

export const setCreateTaxLoading = (createTaxLoading) =>
  createAction(TAX_ACTION_TYPES.SET_CREATE_TAX_LOADING, createTaxLoading);
export const setCreateTaxSuccess = (createTaxSuccess) =>
  createAction(TAX_ACTION_TYPES.SET_CREATE_TAX_SUCCESS, createTaxSuccess);
export const setCreateTaxFailed = (createTaxFailed) =>
  createAction(TAX_ACTION_TYPES.SET_CREATE_TAX_FAILED, createTaxFailed);

export const setUpdateTaxLoading = (updateTaxLoading) =>
  createAction(TAX_ACTION_TYPES.SET_UPDATE_TAX_LOADING, updateTaxLoading);
export const setUpdateTaxSuccess = (updateTaxSuccess) =>
  createAction(TAX_ACTION_TYPES.SET_UPDATE_TAX_SUCCESS, updateTaxSuccess);
export const setUpdateTaxFailed = (updateTaxFailed) =>
  createAction(TAX_ACTION_TYPES.SET_UPDATE_TAX_FAILED, updateTaxFailed);

export const setDeleteTaxLoading = (deleteTaxLoading) =>
  createAction(TAX_ACTION_TYPES.SET_DELETE_TAX_LOADING, deleteTaxLoading);
export const setDeleteTaxSuccess = (deleteTaxSuccess) =>
  createAction(TAX_ACTION_TYPES.SET_DELETE_TAX_SUCCESS, deleteTaxSuccess);
export const setDeleteTaxFailed = (deleteTaxFailed) =>
  createAction(TAX_ACTION_TYPES.SET_DELETE_TAX_FAILED, deleteTaxFailed);

export const setSortTaxesLoading = (sortTaxesLoading) =>
  createAction(TAX_ACTION_TYPES.SET_SORT_TAXES_LOADING, sortTaxesLoading);
export const setSortTaxesSuccess = (sortTaxesSuccess) =>
  createAction(TAX_ACTION_TYPES.SET_SORT_TAXES_SUCCESS, sortTaxesSuccess);
export const setSortTaxesFailed = (sortTaxesFailed) =>
  createAction(TAX_ACTION_TYPES.SET_SORT_TAXES_FAILED, sortTaxesFailed);

export const appendTaxes = (taxes) =>
  createAction(TAX_ACTION_TYPES.APPEND_TAXES, taxes);
export const fetchTaxesStart = () =>
  createAction(TAX_ACTION_TYPES.FETCH_TAXES_START);
export const fetchTaxStart = (taxId) =>
  createAction(TAX_ACTION_TYPES.FETCH_TAX_START, taxId);
export const createTaxStart = (request) =>
  createAction(TAX_ACTION_TYPES.CREATE_TAX_START, request);
export const updateTaxStart = (taxId, request) =>
  createAction(TAX_ACTION_TYPES.UPDATE_TAX_START, { taxId, request });
export const deleteTaxStart = (taxId) =>
  createAction(TAX_ACTION_TYPES.DELETE_TAX_START, taxId);
export const sortTaxesStart = (request) =>
  createAction(TAX_ACTION_TYPES.SORT_TAXES_START, request);

export const setIsFetchTaxesHitted = (isFetchTaxesHitted) =>
  createAction(TAX_ACTION_TYPES.SET_IS_FETCH_TAXES_HITTED, isFetchTaxesHitted);
export const setIsFetchTaxHitted = (isFetchTaxHitted) =>
  createAction(TAX_ACTION_TYPES.SET_IS_FETCH_TAX_HITTED, isFetchTaxHitted);
export const setIsCreateTaxHitted = (isCreateTaxHitted) =>
  createAction(TAX_ACTION_TYPES.SET_IS_CREATE_TAX_HITTED, isCreateTaxHitted);
export const setIsUpdateTaxHitted = (isUpdateTaxHitted) =>
  createAction(TAX_ACTION_TYPES.SET_IS_UPDATE_TAX_HITTED, isUpdateTaxHitted);
export const setIsDeleteTaxHitted = (isDeleteTaxHitted) =>
  createAction(TAX_ACTION_TYPES.SET_IS_DELETE_TAX_HITTED, isDeleteTaxHitted);
export const setIsSortTaxesHitted = (isSortTaxesHitted) =>
  createAction(TAX_ACTION_TYPES.SET_IS_SORT_TAXES_HITTED, isSortTaxesHitted);

export const resetTaxReducer = () =>
  createAction(TAX_ACTION_TYPES.RESET_TAX_REDUCER);
