import MSEGAT_ACTION_TYPES from "./msegat.type";
import { createAction } from "../../utils/store.utils";

export const setMsegats = (msegats) => createAction(MSEGAT_ACTION_TYPES.SET_MSEGATS, msegats);

export const setMsegat = (msegat) => createAction(MSEGAT_ACTION_TYPES.SET_MSEGAT, msegat);

export const setIsMsegatsHasMore = (isMsegatsHasMore) =>
  createAction(MSEGAT_ACTION_TYPES.SET_IS_MSEGATS_HAS_MORE, isMsegatsHasMore);

export const setFetchMsegatsSearch = (fetchMsegatsSearch) =>
  createAction(MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_SEARCH, fetchMsegatsSearch);

export const setFetchMsegatsPage = (fetchMsegatsPage) =>
  createAction(MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_PAGE, fetchMsegatsPage);

export const setFetchMsegatsPerPage = (fetchMsegatsPerPage) =>
  createAction(MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_PER_PAGE, fetchMsegatsPerPage);

export const setFetchMsegatsIncludes = (fetchMsegatsIncludes) =>
  createAction(MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_INCLUDES, fetchMsegatsIncludes);

export const setFetchMsegatsFilterIsActive = (fetchMsegatsFilterIsActive) =>
  createAction(MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_FILTER_IS_ACTIVE, fetchMsegatsFilterIsActive);

export const setFetchMsegatsLoading = (fetchMsegatsLoading) =>
  createAction(MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_LOADING, fetchMsegatsLoading);

export const setFetchMsegatsSuccess = (fetchMsegatsSuccess) =>
  createAction(MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_SUCCESS, fetchMsegatsSuccess);

export const setFetchMsegatsFailed = (fetchMsegatsFailed) =>
  createAction(MSEGAT_ACTION_TYPES.SET_FETCH_MSEGATS_FAILED, fetchMsegatsFailed);

export const setFetchMsegatLoading = (fetchMsegatLoading) =>
  createAction(MSEGAT_ACTION_TYPES.SET_FETCH_MSEGAT_LOADING, fetchMsegatLoading);

export const setFetchMsegatSuccess = (fetchMsegatSuccess) =>
  createAction(MSEGAT_ACTION_TYPES.SET_FETCH_MSEGAT_SUCCESS, fetchMsegatSuccess);

export const setFetchMsegatFailed = (fetchMsegatFailed) =>
  createAction(MSEGAT_ACTION_TYPES.SET_FETCH_MSEGAT_FAILED, fetchMsegatFailed);

export const setCreateOrUpdateMsegatLoading = (createOrUpdateMsegatLoading) =>
  createAction(MSEGAT_ACTION_TYPES.SET_CREATE_OR_UPDATE_MSEGAT_LOADING, createOrUpdateMsegatLoading);

export const setCreateOrUpdateMsegatSuccess = (createOrUpdateMsegatSuccess) =>
  createAction(MSEGAT_ACTION_TYPES.SET_CREATE_OR_UPDATE_MSEGAT_SUCCESS, createOrUpdateMsegatSuccess);

export const setCreateOrUpdateMsegatFailed = (createOrUpdateMsegatFailed) =>
  createAction(MSEGAT_ACTION_TYPES.SET_CREATE_OR_UPDATE_MSEGAT_FAILED, createOrUpdateMsegatFailed);

export const appendMsegats = (msegats) => createAction(MSEGAT_ACTION_TYPES.APPEND_MSEGATS, msegats);

export const fetchMsegatsStart = () => createAction(MSEGAT_ACTION_TYPES.FETCH_MSEGATS_START);

export const fetchMsegatStart = (marketId) => createAction(MSEGAT_ACTION_TYPES.FETCH_MSEGAT_START, marketId);

export const createOrUpdateMsegatStart = (request) =>
  createAction(MSEGAT_ACTION_TYPES.CREATE_OR_UPDATE_MSEGAT_START, request);

export const setIsFetchMsegatsHitted = (isFetchMsegatsHitted) =>
  createAction(MSEGAT_ACTION_TYPES.SET_IS_FETCH_MSEGATS_HITTED, isFetchMsegatsHitted);

export const setIsFetchMsegatHitted = (isFetchMsegatHitted) =>
  createAction(MSEGAT_ACTION_TYPES.SET_IS_FETCH_MSEGAT_HITTED, isFetchMsegatHitted);

export const setIsCreateOrUpdateMsegatHitted = (isCreateOrUpdateMsegatHitted) =>
  createAction(MSEGAT_ACTION_TYPES.SET_IS_CREATE_OR_UPDATE_MSEGAT_HITTED, isCreateOrUpdateMsegatHitted);

export const resetMsegatReducer = () => createAction(MSEGAT_ACTION_TYPES.RESET_MSEGAT_REDUCER);
