const MERCHANT_ACTION_TYPES = {
  RESET_MERCHANT_REDUCER: "merchant/RESET_MERCHANT_REDUCER",

  SET_MERCHANTS: "merchant/SET_MERCHANTS",
  SET_SELECT_MERCHANTS: "merchant/SET_SELECT_MERCHANTS",
  SET_MERCHANT: "merchant/SET_MERCHANT",

  SET_IS_MERCHANTS_HAS_MORE: "merchant/SET_IS_MERCHANTS_HAS_MORE",
  SET_IS_SELECT_MERCHANTS_HAS_MORE: "merchant/SET_IS_SELECT_MERCHANTS_HAS_MORE",

  SET_FETCH_MERCHANTS_SEARCH: "merchant/SET_FETCH_MERCHANTS_SEARCH",
  SET_FETCH_MERCHANTS_SORT: "merchant/SET_FETCH_MERCHANTS_SORT",
  SET_FETCH_MERCHANTS_KEY_BY: "merchant/SET_FETCH_MERCHANTS_KEY_BY",
  SET_FETCH_MERCHANTS_PAGE: "merchant/SET_FETCH_MERCHANTS_PAGE",
  SET_FETCH_MERCHANTS_PER_PAGE: "merchant/SET_FETCH_MERCHANTS_PER_PAGE",
  SET_FETCH_MERCHANTS_INCLUDES: "merchant/SET_FETCH_MERCHANTS_INCLUDES",
  SET_FETCH_MERCHANTS_FILTER_MERCHANTS_IDS:
    "merchant/SET_FETCH_MERCHANTS_FILTER_MERCHANTS_IDS",
  SET_FETCH_MERCHANTS_LOADING: "merchant/SET_FETCH_MERCHANTS_LOADING",
  SET_FETCH_MERCHANTS_SUCCESS: "merchant/SET_FETCH_MERCHANTS_SUCCESS",
  SET_FETCH_MERCHANTS_FAILED: "merchant/SET_FETCH_MERCHANTS_FAILED",

  SET_FETCH_SELECT_MERCHANTS_SEARCH:
    "merchant/SET_FETCH_SELECT_MERCHANTS_SEARCH",
  SET_FETCH_SELECT_MERCHANTS_SORT: "merchant/SET_FETCH_SELECT_MERCHANTS_SORT",
  SET_FETCH_SELECT_MERCHANTS_KEY_BY:
    "merchant/SET_FETCH_SELECT_MERCHANTS_KEY_BY",
  SET_FETCH_SELECT_MERCHANTS_PAGE: "merchant/SET_FETCH_SELECT_MERCHANTS_PAGE",
  SET_FETCH_SELECT_MERCHANTS_PER_PAGE:
    "merchant/SET_FETCH_SELECT_MERCHANTS_PER_PAGE",
  SET_FETCH_SELECT_MERCHANTS_INCLUDES:
    "merchant/SET_FETCH_SELECT_MERCHANTS_INCLUDES",
  SET_FETCH_SELECT_MERCHANTS_FILTER_MERCHANTS_IDS:
    "merchant/SET_FETCH_SELECT_MERCHANTS_FILTER_MERCHANTS_IDS",
  SET_FETCH_SELECT_MERCHANTS_LOADING:
    "merchant/SET_FETCH_SELECT_MERCHANTS_LOADING",
  SET_FETCH_SELECT_MERCHANTS_SUCCESS:
    "merchant/SET_FETCH_SELECT_MERCHANTS_SUCCESS",
  SET_FETCH_SELECT_MERCHANTS_FAILED:
    "merchant/SET_FETCH_SELECT_MERCHANTS_FAILED",

  SET_APPEND_SELECT_MERCHANTS_SEARCH:
    "merchant/SET_APPEND_SELECT_MERCHANTS_SEARCH",
  SET_APPEND_SELECT_MERCHANTS_SORT: "merchant/SET_APPEND_SELECT_MERCHANTS_SORT",
  SET_APPEND_SELECT_MERCHANTS_KEY_BY:
    "merchant/SET_APPEND_SELECT_MERCHANTS_KEY_BY",
  SET_APPEND_SELECT_MERCHANTS_PAGE: "merchant/SET_APPEND_SELECT_MERCHANTS_PAGE",
  SET_APPEND_SELECT_MERCHANTS_PER_PAGE:
    "merchant/SET_APPEND_SELECT_MERCHANTS_PER_PAGE",
  SET_APPEND_SELECT_MERCHANTS_INCLUDES:
    "merchant/SET_APPEND_SELECT_MERCHANTS_INCLUDES",
  SET_APPEND_SELECT_MERCHANTS_FILTER_MERCHANTS_IDS:
    "merchant/SET_APPEND_SELECT_MERCHANTS_FILTER_MERCHANTS_IDS",
  SET_APPEND_SELECT_MERCHANTS_LOADING:
    "merchant/SET_APPEND_SELECT_MERCHANTS_LOADING",
  SET_APPEND_SELECT_MERCHANTS_SUCCESS:
    "merchant/SET_APPEND_SELECT_MERCHANTS_SUCCESS",
  SET_APPEND_SELECT_MERCHANTS_FAILED:
    "merchant/SET_APPEND_SELECT_MERCHANTS_FAILED",

  SET_FETCH_MERCHANT_LOADING: "merchant/SET_FETCH_MERCHANT_LOADING",
  SET_FETCH_MERCHANT_SUCCESS: "merchant/SET_FETCH_MERCHANT_SUCCESS",
  SET_FETCH_MERCHANT_FAILED: "merchant/SET_FETCH_MERCHANT_FAILED",

  SET_CREATE_MERCHANT_LOADING: "merchant/SET_CREATE_MERCHANT_LOADING",
  SET_CREATE_MERCHANT_SUCCESS: "merchant/SET_CREATE_MERCHANT_SUCCESS",
  SET_CREATE_MERCHANT_FAILED: "merchant/SET_CREATE_MERCHANT_FAILED",

  SET_UPDATE_MERCHANT_LOADING: "merchant/SET_UPDATE_MERCHANT_LOADING",
  SET_UPDATE_MERCHANT_SUCCESS: "merchant/SET_UPDATE_MERCHANT_SUCCESS",
  SET_UPDATE_MERCHANT_FAILED: "merchant/SET_UPDATE_MERCHANT_FAILED",

  SET_DELETE_MERCHANT_LOADING: "merchant/SET_DELETE_MERCHANT_LOADING",
  SET_DELETE_MERCHANT_SUCCESS: "merchant/SET_DELETE_MERCHANT_SUCCESS",
  SET_DELETE_MERCHANT_FAILED: "merchant/SET_DELETE_MERCHANT_FAILED",

  APPEND_MERCHANTS: "merchant/APPEND_MERCHANTS",
  APPEND_SELECT_MERCHANTS: "merchant/APPEND_SELECT_MERCHANTS",

  SET_IS_FETCH_MERCHANTS_HITTED: "merchant/SET_IS_FETCH_MERCHANTS_HITTED",
  SET_IS_FETCH_SELECT_MERCHANTS_HITTED:
    "merchant/SET_IS_FETCH_SELECT_MERCHANTS_HITTED",
  SET_IS_APPEND_SELECT_MERCHANTS_HITTED:
    "merchant/SET_IS_APPEND_SELECT_MERCHANTS_HITTED",
  SET_IS_FETCH_MERCHANT_HITTED: "merchant/SET_IS_FETCH_MERCHANT_HITTED",
  SET_IS_CREATE_MERCHANT_HITTED: "merchant/SET_IS_CREATE_MERCHANT_HITTED",
  SET_IS_UPDATE_MERCHANT_HITTED: "merchant/SET_IS_UPDATE_MERCHANT_HITTED",
  SET_IS_DELETE_MERCHANT_HITTED: "merchant/SET_IS_DELETE_MERCHANT_HITTED",

  FETCH_MERCHANTS_START: "merchant/FETCH_MERCHANTS_START",
  FETCH_SELECT_MERCHANTS_START: "merchant/FETCH_SELECT_MERCHANTS_START",
  APPEND_SELECT_MERCHANTS_START: "merchant/APPEND_SELECT_MERCHANTS_START",
  FETCH_MERCHANT_START: "merchant/FETCH_MERCHANT_START",
  CREATE_MERCHANT_START: "merchant/CREATE_MERCHANT_START",
  UPDATE_MERCHANT_START: "merchant/UPDATE_MERCHANT_START",
  DELETE_MERCHANT_START: "merchant/DELETE_MERCHANT_START",
};

export default MERCHANT_ACTION_TYPES;
