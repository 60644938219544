import DISCOUNT_ACTION_TYPES from "./discount.type";
import { createAction } from "../../utils/store.utils";

export const setEditModifierIndex = (editModifierIndex) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_EDIT_MODIFIER_INDEX,
    editModifierIndex
  );
export const setEditModifier = (editModifier) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_EDIT_MODIFIER, editModifier);

export const setDiscountTab = (discountTab) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_DISCOUNT_TAB, discountTab);
export const setDiscountCreateTab = (discountCreateTab) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_DISCOUNT_CREATE_TAB,
    discountCreateTab
  );
export const setDiscountEditTab = (discountEditTab) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_DISCOUNT_EDIT_TAB, discountEditTab);

export const setDiscounts = (discounts) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_DISCOUNTS, discounts);
export const setOrderDiscounts = (orderDiscounts) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_ORDER_DISCOUNTS, orderDiscounts);
export const setDiscount = (discount) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_DISCOUNT, discount);
export const setCreateDiscount = (createDiscount) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_CREATE_DISCOUNT, createDiscount);
export const setUpdateDiscount = (updateDiscount) =>
  createAction(DISCOUNT_ACTION_TYPES.SET_UPDATE_DISCOUNT, updateDiscount);
export const setVerifyCouponDiscount = (verifyCouponDiscount) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT,
    verifyCouponDiscount
  );
export const setVerifyLoyaProDiscount = (verifyLoyaProDiscount) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_VERIFY_LOYAPRO_DISCOUNT,
    verifyLoyaProDiscount
  );

export const setIsDiscountsHasMore = (isDiscountsHasMore) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_IS_DISCOUNTS_HAS_MORE,
    isDiscountsHasMore
  );
export const setIsOrderDiscountsHasMore = (isOrderDiscountsHasMore) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_IS_ORDER_DISCOUNTS_HAS_MORE,
    isOrderDiscountsHasMore
  );

export const setFetchDiscountsSearch = (fetchDiscountsSearch) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_SEARCH,
    fetchDiscountsSearch
  );
export const setFetchDiscountsPage = (fetchDiscountsPage) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_PAGE,
    fetchDiscountsPage
  );
export const setFetchDiscountsPerPage = (fetchDiscountsPerPage) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_PER_PAGE,
    fetchDiscountsPerPage
  );
export const setFetchDiscountsIncludes = (fetchDiscountsIncludes) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_INCLUDES,
    fetchDiscountsIncludes
  );
export const setFetchDiscountsFilterMarketId = (fetchDiscountsFilterMarketId) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_MARKET_ID,
    fetchDiscountsFilterMarketId
  );
export const setFetchDiscountsFilterBranchId = (fetchDiscountsFilterBranchId) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_BRANCH_ID,
    fetchDiscountsFilterBranchId
  );
export const setFetchDiscountsFilterTypes = (fetchDiscountsFilterTypes) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_TYPES,
    fetchDiscountsFilterTypes
  );
export const setFetchDiscountsFilterExpiredAt = (
  fetchDiscountsFilterExpiredAt
) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_EXPIRED_AT,
    fetchDiscountsFilterExpiredAt
  );
export const setFetchDiscountsLoading = (fetchDiscountsLoading) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_LOADING,
    fetchDiscountsLoading
  );
export const setFetchDiscountsSuccess = (fetchDiscountsSuccess) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_SUCCESS,
    fetchDiscountsSuccess
  );
export const setFetchDiscountsFailed = (fetchDiscountsFailed) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FAILED,
    fetchDiscountsFailed
  );

export const setFetchOrderDiscountsSearch = (fetchOrderDiscountsSearch) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_SEARCH,
    fetchOrderDiscountsSearch
  );
export const setFetchOrderDiscountsPage = (fetchOrderDiscountsPage) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_PAGE,
    fetchOrderDiscountsPage
  );
export const setFetchOrderDiscountsPerPage = (fetchOrderDiscountsPerPage) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_PER_PAGE,
    fetchOrderDiscountsPerPage
  );
export const setFetchOrderDiscountsIncludes = (fetchOrderDiscountsIncludes) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_INCLUDES,
    fetchOrderDiscountsIncludes
  );
export const setFetchOrderDiscountsFilterMarketId = (
  fetchOrderDiscountsFilterMarketId
) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_FILTER_MARKET_ID,
    fetchOrderDiscountsFilterMarketId
  );
export const setFetchOrderDiscountsFilterBranchId = (
  fetchOrderDiscountsFilterBranchId
) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_FILTER_BRANCH_ID,
    fetchOrderDiscountsFilterBranchId
  );
export const setFetchOrderDiscountsFilterTypes = (
  fetchOrderDiscountsFilterTypes
) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_FILTER_TYPES,
    fetchOrderDiscountsFilterTypes
  );
export const setFetchOrderDiscountsFilterExpiredAt = (
  fetchOrderDiscountsFilterExpiredAt
) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_FILTER_EXPIRED_AT,
    fetchOrderDiscountsFilterExpiredAt
  );
export const setFetchOrderDiscountsLoading = (fetchOrderDiscountsLoading) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_LOADING,
    fetchOrderDiscountsLoading
  );
export const setFetchOrderDiscountsSuccess = (fetchOrderDiscountsSuccess) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_SUCCESS,
    fetchOrderDiscountsSuccess
  );
export const setFetchOrderDiscountsFailed = (fetchOrderDiscountsFailed) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_FAILED,
    fetchOrderDiscountsFailed
  );

export const setFetchDiscountLoading = (fetchDiscountLoading) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNT_LOADING,
    fetchDiscountLoading
  );
export const setFetchDiscountSuccess = (fetchDiscountSuccess) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNT_SUCCESS,
    fetchDiscountSuccess
  );
export const setFetchDiscountFailed = (fetchDiscountFailed) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNT_FAILED,
    fetchDiscountFailed
  );

export const setCreateDiscountLoading = (createDiscountLoading) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_CREATE_DISCOUNT_LOADING,
    createDiscountLoading
  );
export const setCreateDiscountSuccess = (createDiscountSuccess) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_CREATE_DISCOUNT_SUCCESS,
    createDiscountSuccess
  );
export const setCreateDiscountFailed = (createDiscountFailed) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_CREATE_DISCOUNT_FAILED,
    createDiscountFailed
  );

export const setUpdateDiscountLoading = (updateDiscountLoading) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_UPDATE_DISCOUNT_LOADING,
    updateDiscountLoading
  );
export const setUpdateDiscountSuccess = (updateDiscountSuccess) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_UPDATE_DISCOUNT_SUCCESS,
    updateDiscountSuccess
  );
export const setUpdateDiscountFailed = (updateDiscountFailed) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_UPDATE_DISCOUNT_FAILED,
    updateDiscountFailed
  );

export const setDeleteDiscountLoading = (deleteDiscountLoading) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_DELETE_DISCOUNT_LOADING,
    deleteDiscountLoading
  );
export const setDeleteDiscountSuccess = (deleteDiscountSuccess) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_DELETE_DISCOUNT_SUCCESS,
    deleteDiscountSuccess
  );
export const setDeleteDiscountFailed = (deleteDiscountFailed) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_DELETE_DISCOUNT_FAILED,
    deleteDiscountFailed
  );

export const setVerifyCouponDiscountLoading = (verifyCouponDiscountLoading) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT_LOADING,
    verifyCouponDiscountLoading
  );
export const setVerifyCouponDiscountSuccess = (verifyCouponDiscountSuccess) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT_SUCCESS,
    verifyCouponDiscountSuccess
  );
export const setVerifyCouponDiscountFailed = (verifyCouponDiscountFailed) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT_FAILED,
    verifyCouponDiscountFailed
  );

export const setVerifyLoyaProDiscountLoading = (verifyLoyaProDiscountLoading) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_VERIFY_LOYAPRO_DISCOUNT_LOADING,
    verifyLoyaProDiscountLoading
  );
export const setVerifyLoyaProDiscountSuccess = (verifyLoyaProDiscountSuccess) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_VERIFY_LOYAPRO_DISCOUNT_SUCCESS,
    verifyLoyaProDiscountSuccess
  );
export const setVerifyLoyaProDiscountFailed = (verifyLoyaProDiscountFailed) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_VERIFY_LOYAPRO_DISCOUNT_FAILED,
    verifyLoyaProDiscountFailed
  );

export const appendDiscounts = (discounts) =>
  createAction(DISCOUNT_ACTION_TYPES.APPEND_DISCOUNTS, discounts);
export const appendOrderDiscounts = (orderDiscounts) =>
  createAction(DISCOUNT_ACTION_TYPES.APPEND_ORDER_DISCOUNTS, orderDiscounts);

export const fetchDiscountsStart = () =>
  createAction(DISCOUNT_ACTION_TYPES.FETCH_DISCOUNTS_START);
export const fetchOrderDiscountsStart = () =>
  createAction(DISCOUNT_ACTION_TYPES.FETCH_ORDER_DISCOUNTS_START);
export const fetchDiscountStart = (discountId) =>
  createAction(DISCOUNT_ACTION_TYPES.FETCH_DISCOUNT_START, discountId);
export const createDiscountStart = (request) =>
  createAction(DISCOUNT_ACTION_TYPES.CREATE_DISCOUNT_START, request);
export const updateDiscountStart = (discountId, request) =>
  createAction(DISCOUNT_ACTION_TYPES.UPDATE_DISCOUNT_START, {
    discountId,
    request,
  });
export const deleteDiscountStart = (discountId) =>
  createAction(DISCOUNT_ACTION_TYPES.DELETE_DISCOUNT_START, discountId);
export const verifyCouponDiscountStart = (request) =>
  createAction(DISCOUNT_ACTION_TYPES.VERIFY_COUPON_DISCOUNT_START, request);
export const verifyLoyaProDiscountStart = (request) =>
  createAction(DISCOUNT_ACTION_TYPES.VERIFY_LOYAPRO_DISCOUNT_START, request);

export const setIsFetchDiscountsHitted = (isFetchDiscountsHitted) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_IS_FETCH_DISCOUNTS_HITTED,
    isFetchDiscountsHitted
  );
export const setIsFetchOrderDiscountsHitted = (isFetchOrderDiscountsHitted) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_IS_FETCH_ORDER_DISCOUNTS_HITTED,
    isFetchOrderDiscountsHitted
  );
export const setIsFetchDiscountHitted = (isFetchDiscountHitted) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_IS_FETCH_DISCOUNT_HITTED,
    isFetchDiscountHitted
  );
export const setIsCreateDiscountHitted = (isCreateDiscountHitted) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_IS_CREATE_DISCOUNT_HITTED,
    isCreateDiscountHitted
  );
export const setIsUpdateDiscountHitted = (isUpdateDiscountHitted) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_IS_UPDATE_DISCOUNT_HITTED,
    isUpdateDiscountHitted
  );
export const setIsDeleteDiscountHitted = (isDeleteDiscountHitted) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_IS_DELETE_DISCOUNT_HITTED,
    isDeleteDiscountHitted
  );
export const setIsVerifyCouponDiscountHitted = (isVerifyCouponDiscountHitted) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_IS_VERIFY_COUPON_DISCOUNT_HITTED,
    isVerifyCouponDiscountHitted
  );
export const setIsVerifyLoyaProDiscountHitted = (
  isVerifyLoyaProDiscountHitted
) =>
  createAction(
    DISCOUNT_ACTION_TYPES.SET_IS_VERIFY_LOYAPRO_DISCOUNT_HITTED,
    isVerifyLoyaProDiscountHitted
  );

export const resetDiscountReducer = () =>
  createAction(DISCOUNT_ACTION_TYPES.RESET_DISCOUNT_REDUCER);
