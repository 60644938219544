import USER_SUPPLIER_ACTION_TYPES from "./user-supplier.type";
import { createAction } from "../../utils/store.utils";

export const setUserSuppliers = (userSuppliers) =>
  createAction(USER_SUPPLIER_ACTION_TYPES.SET_USER_SUPPLIERS, userSuppliers);
export const setUserSupplier = (userSupplier) =>
  createAction(USER_SUPPLIER_ACTION_TYPES.SET_USER_SUPPLIER, userSupplier);

export const setIsUserSuppliersHasMore = (isUserSuppliersHasMore) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_IS_USER_SUPPLIERS_HAS_MORE,
    isUserSuppliersHasMore
  );

export const setFetchUserSuppliersSearch = (fetchUserSuppliersSearch) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_SEARCH,
    fetchUserSuppliersSearch
  );
export const setFetchUserSuppliersSort = (fetchUserSuppliersSort) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_SORT,
    fetchUserSuppliersSort
  );
export const setFetchUserSuppliersKeyBy = (fetchUserSuppliersKeyBy) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_KEY_BY,
    fetchUserSuppliersKeyBy
  );
export const setFetchUserSuppliersPage = (fetchUserSuppliersPage) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_PAGE,
    fetchUserSuppliersPage
  );
export const setFetchUserSuppliersPerPage = (fetchUserSuppliersPerPage) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_PER_PAGE,
    fetchUserSuppliersPerPage
  );
export const setFetchUserSuppliersIncludes = (fetchUserSuppliersIncludes) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_INCLUDES,
    fetchUserSuppliersIncludes
  );
export const setFetchUserSuppliersFilterMarketId = (
  fetchUserSuppliersFilterMarketId
) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_FILTER_MARKET_ID,
    fetchUserSuppliersFilterMarketId
  );
export const setFetchUserSuppliersFilterBranchId = (
  fetchUserSuppliersFilterBranchId
) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_FILTER_BRANCH_ID,
    fetchUserSuppliersFilterBranchId
  );
export const setFetchUserSuppliersLoading = (fetchUserSuppliersLoading) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_LOADING,
    fetchUserSuppliersLoading
  );
export const setFetchUserSuppliersSuccess = (fetchUserSuppliersSuccess) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_SUCCESS,
    fetchUserSuppliersSuccess
  );
export const setFetchUserSuppliersFailed = (fetchUserSuppliersFailed) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIERS_FAILED,
    fetchUserSuppliersFailed
  );

export const setFetchUserSupplierLoading = (fetchUserSupplierLoading) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIER_LOADING,
    fetchUserSupplierLoading
  );
export const setFetchUserSupplierSuccess = (fetchUserSupplierSuccess) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIER_SUCCESS,
    fetchUserSupplierSuccess
  );
export const setFetchUserSupplierFailed = (fetchUserSupplierFailed) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_FETCH_USER_SUPPLIER_FAILED,
    fetchUserSupplierFailed
  );

export const setCreateUserSupplierLoading = (createUserSupplierLoading) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_CREATE_USER_SUPPLIER_LOADING,
    createUserSupplierLoading
  );
export const setCreateUserSupplierSuccess = (createUserSupplierSuccess) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_CREATE_USER_SUPPLIER_SUCCESS,
    createUserSupplierSuccess
  );
export const setCreateUserSupplierFailed = (createUserSupplierFailed) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_CREATE_USER_SUPPLIER_FAILED,
    createUserSupplierFailed
  );

export const setUpdateUserSupplierLoading = (updateUserSupplierLoading) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_UPDATE_USER_SUPPLIER_LOADING,
    updateUserSupplierLoading
  );
export const setUpdateUserSupplierSuccess = (updateUserSupplierSuccess) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_UPDATE_USER_SUPPLIER_SUCCESS,
    updateUserSupplierSuccess
  );
export const setUpdateUserSupplierFailed = (updateUserSupplierFailed) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_UPDATE_USER_SUPPLIER_FAILED,
    updateUserSupplierFailed
  );

export const setDeleteUserSupplierLoading = (deleteUserSupplierLoading) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_DELETE_USER_SUPPLIER_LOADING,
    deleteUserSupplierLoading
  );
export const setDeleteUserSupplierSuccess = (deleteUserSupplierSuccess) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_DELETE_USER_SUPPLIER_SUCCESS,
    deleteUserSupplierSuccess
  );
export const setDeleteUserSupplierFailed = (deleteUserSupplierFailed) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_DELETE_USER_SUPPLIER_FAILED,
    deleteUserSupplierFailed
  );

export const appendUserSuppliers = (userSuppliers) =>
  createAction(USER_SUPPLIER_ACTION_TYPES.APPEND_USER_SUPPLIERS, userSuppliers);

export const fetchUserSuppliersStart = () =>
  createAction(USER_SUPPLIER_ACTION_TYPES.FETCH_USER_SUPPLIERS_START);
export const fetchUserSupplierStart = (supplierId) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.FETCH_USER_SUPPLIER_START,
    supplierId
  );
export const createUserSupplierStart = (request) =>
  createAction(USER_SUPPLIER_ACTION_TYPES.CREATE_USER_SUPPLIER_START, request);
export const updateUserSupplierStart = (supplierId, request) =>
  createAction(USER_SUPPLIER_ACTION_TYPES.UPDATE_USER_SUPPLIER_START, {
    supplierId,
    request,
  });
export const deleteUserSupplierStart = (supplierId) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.DELETE_USER_SUPPLIER_START,
    supplierId
  );

export const setIsFetchUserSuppliersHitted = (isFetchUserSuppliersHitted) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_IS_FETCH_USER_SUPPLIERS_HITTED,
    isFetchUserSuppliersHitted
  );
export const setIsFetchUserSupplierHitted = (isFetchUserSupplierHitted) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_IS_FETCH_USER_SUPPLIER_HITTED,
    isFetchUserSupplierHitted
  );
export const setIsCreateUserSupplierHitted = (isCreateUserSupplierHitted) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_IS_CREATE_USER_SUPPLIER_HITTED,
    isCreateUserSupplierHitted
  );
export const setIsUpdateUserSupplierHitted = (isUpdateUserSupplierHitted) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_IS_UPDATE_USER_SUPPLIER_HITTED,
    isUpdateUserSupplierHitted
  );
export const setIsDeleteUserSupplierHitted = (isDeleteUserSupplierHitted) =>
  createAction(
    USER_SUPPLIER_ACTION_TYPES.SET_IS_DELETE_USER_SUPPLIER_HITTED,
    isDeleteUserSupplierHitted
  );

export const resetUserSupplierReducer = () =>
  createAction(USER_SUPPLIER_ACTION_TYPES.RESET_USER_SUPPLIER_REDUCER);
