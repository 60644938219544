import { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";

import { isEmpty } from "../../utils/validation.utils";

import {
  AuthenticationCarouselContainer,
  AuthenticationCarouselImage,
} from "./authentication-carousel.style";

import Carousel1 from "../../assets/images/carousel/v2/1.png";
import Carousel2 from "../../assets/images/carousel/v2/2.png";
import Carousel3 from "../../assets/images/carousel/v2/3.png";
import Carousel4 from "../../assets/images/carousel/v2/4.png";

const defaultSizes = { width: 0, height: 0 };
const carouselImages = [Carousel1, Carousel2, Carousel3, Carousel4];

const AuthenticationCarousel = () => {
  const containerRef = useRef();

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSizes, setActiveSizes] = useState(defaultSizes);

  const { width, height } = activeSizes;

  const handleCarouselChange = (activeIndex) => setActiveIndex(activeIndex);

  useEffect(() => {
    const handleWindowResize = () => {
      const images = containerRef?.current?.querySelectorAll("img");
      const image = images?.[activeIndex]?.getBoundingClientRect?.();
      const { width, height } = image ?? {};

      const activeSizes = { ...defaultSizes };
      if (!isEmpty(width)) activeSizes.width = width;
      if (!isEmpty(height)) activeSizes.height = height;

      setActiveSizes(activeSizes);
    };

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return (
    <AuthenticationCarouselContainer
      ref={containerRef}
      width={width}
      height={height}
    >
      <Carousel
        autoPlay={true}
        swipeable={true}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        onChange={handleCarouselChange}
      >
        {carouselImages.map((carouselImage, index) => (
          <AuthenticationCarouselImage key={index} src={carouselImage} />
        ))}
      </Carousel>
    </AuthenticationCarouselContainer>
  );
};

export default AuthenticationCarousel;
