const SELECT_INGREDIENT_ACTION_TYPES = {
  RESET_SELECT_INGREDIENT_REDUCER:
    "select-ingredient/RESET_SELECT_INGREDIENT_REDUCER",

  SET_INGREDIENTS: "select-ingredient/SET_INGREDIENTS",
  SET_SEARCH_INGREDIENTS: "select-ingredient/SET_SEARCH_INGREDIENTS",

  APPEND_INGREDIENTS: "select-ingredient/APPEND_INGREDIENTS",
  APPEND_SEARCH_INGREDIENTS: "select-ingredient/APPEND_SEARCH_INGREDIENTS",

  SET_IS_INGREDIENTS_HAS_MORE: "select-ingredient/SET_IS_INGREDIENTS_HAS_MORE",
  SET_IS_SEARCH_INGREDIENTS_HAS_MORE:
    "select-ingredient/SET_IS_SEARCH_INGREDIENTS_HAS_MORE",

  SET_FETCH_INGREDIENTS_PARAMS:
    "select-ingredient/SET_FETCH_INGREDIENTS_PARAMS",
  SET_FETCH_INGREDIENTS_LOADING:
    "select-ingredient/SET_FETCH_INGREDIENTS_LOADING",
  SET_FETCH_INGREDIENTS_SUCCESS:
    "select-ingredient/SET_FETCH_INGREDIENTS_SUCCESS",
  SET_FETCH_INGREDIENTS_FAILED:
    "select-ingredient/SET_FETCH_INGREDIENTS_FAILED",

  SET_SEARCH_INGREDIENTS_PARAMS:
    "select-ingredient/SET_SEARCH_INGREDIENTS_PARAMS",
  SET_SEARCH_INGREDIENTS_LOADING:
    "select-ingredient/SET_SEARCH_INGREDIENTS_LOADING",
  SET_SEARCH_INGREDIENTS_SUCCESS:
    "select-ingredient/SET_SEARCH_INGREDIENTS_SUCCESS",
  SET_SEARCH_INGREDIENTS_FAILED:
    "select-ingredient/SET_SEARCH_INGREDIENTS_FAILED",

  SET_APPEND_INGREDIENTS_PARAMS:
    "select-ingredient/SET_APPEND_INGREDIENTS_PARAMS",
  SET_APPEND_INGREDIENTS_LOADING:
    "select-ingredient/SET_APPEND_INGREDIENTS_LOADING",
  SET_APPEND_INGREDIENTS_SUCCESS:
    "select-ingredient/SET_APPEND_INGREDIENTS_SUCCESS",
  SET_APPEND_INGREDIENTS_FAILED:
    "select-ingredient/SET_APPEND_INGREDIENTS_FAILED",

  SET_IS_FETCH_INGREDIENTS_HITTED:
    "select-ingredient/SET_IS_FETCH_INGREDIENTS_HITTED",
  SET_IS_SEARCH_INGREDIENTS_HITTED:
    "select-ingredient/SET_IS_SEARCH_INGREDIENTS_HITTED",
  SET_IS_APPEND_INGREDIENTS_HITTED:
    "select-ingredient/SET_IS_APPEND_INGREDIENTS_HITTED",

  FETCH_INGREDIENTS_START: "select-ingredient/FETCH_INGREDIENTS_START",
  SEARCH_INGREDIENTS_START: "select-ingredient/SEARCH_INGREDIENTS_START",
  APPEND_INGREDIENTS_START: "select-ingredient/APPEND_INGREDIENTS_START",
};

export default SELECT_INGREDIENT_ACTION_TYPES;
