import { createSelector } from "reselect";

const paymentMethodSettingSelector = ({ paymentMethodSetting }) =>
  paymentMethodSetting;

export const getIsModalAutoClose = createSelector(
  [paymentMethodSettingSelector],
  ({ isModalAutoClose }) => isModalAutoClose
);

export const getPaymentMethodSettingValueTypes = createSelector(
  [paymentMethodSettingSelector],
  ({ paymentMethodSettingValueTypes }) => paymentMethodSettingValueTypes
);

export const getPaymentMethodSettings = createSelector(
  [paymentMethodSettingSelector],
  ({ paymentMethodSettings }) => paymentMethodSettings
);

export const getSelectPaymentMethodSettings = createSelector(
  [paymentMethodSettingSelector],
  ({ selectPaymentMethodSettings }) => selectPaymentMethodSettings
);

export const getActivePaymentMethodSettings = createSelector(
  [paymentMethodSettingSelector],
  ({ activePaymentMethodSettings }) => activePaymentMethodSettings
);

export const getPaymentMethodSetting = createSelector(
  [paymentMethodSettingSelector],
  ({ paymentMethodSetting }) => paymentMethodSetting
);

export const getIsPaymentMethodSettingsHasMore = createSelector(
  [paymentMethodSettingSelector],
  ({ isPaymentMethodSettingsHasMore }) => isPaymentMethodSettingsHasMore
);

export const getIsSelectPaymentMethodSettingsHasMore = createSelector(
  [paymentMethodSettingSelector],
  ({ isSelectPaymentMethodSettingsHasMore }) =>
    isSelectPaymentMethodSettingsHasMore
);

export const getIsActivePaymentMethodSettingsHasMore = createSelector(
  [paymentMethodSettingSelector],
  ({ isActivePaymentMethodSettingsHasMore }) =>
    isActivePaymentMethodSettingsHasMore
);

export const getFetchPaymentMethodSettingsSort = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsSort }) => fetchPaymentMethodSettingsSort
);

export const getFetchPaymentMethodSettingsPage = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsPage }) => fetchPaymentMethodSettingsPage
);

export const getFetchPaymentMethodSettingsPerPage = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsPerPage }) => fetchPaymentMethodSettingsPerPage
);

export const getFetchPaymentMethodSettingsIncludes = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsIncludes }) => fetchPaymentMethodSettingsIncludes
);

export const getFetchPaymentMethodSettingsFilterMarketId = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsFilterMarketId }) =>
    fetchPaymentMethodSettingsFilterMarketId
);

export const getFetchPaymentMethodSettingsFilterIsActive = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsFilterIsActive }) =>
    fetchPaymentMethodSettingsFilterIsActive
);

export const getFetchPaymentMethodSettingsLoading = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsLoading }) => fetchPaymentMethodSettingsLoading
);

export const getFetchPaymentMethodSettingsSuccess = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsSuccess }) => fetchPaymentMethodSettingsSuccess
);

export const getFetchPaymentMethodSettingsFailed = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingsFailed }) => fetchPaymentMethodSettingsFailed
);

export const getFetchSelectPaymentMethodSettingsSort = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchSelectPaymentMethodSettingsSort }) =>
    fetchSelectPaymentMethodSettingsSort
);

export const getFetchSelectPaymentMethodSettingsPage = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchSelectPaymentMethodSettingsPage }) =>
    fetchSelectPaymentMethodSettingsPage
);

export const getFetchSelectPaymentMethodSettingsPerPage = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchSelectPaymentMethodSettingsPerPage }) =>
    fetchSelectPaymentMethodSettingsPerPage
);

export const getFetchSelectPaymentMethodSettingsIncludes = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchSelectPaymentMethodSettingsIncludes }) =>
    fetchSelectPaymentMethodSettingsIncludes
);

export const getFetchSelectPaymentMethodSettingsFilterMarketId = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchSelectPaymentMethodSettingsFilterMarketId }) =>
    fetchSelectPaymentMethodSettingsFilterMarketId
);

export const getFetchSelectPaymentMethodSettingsFilterIsActive = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchSelectPaymentMethodSettingsFilterIsActive }) =>
    fetchSelectPaymentMethodSettingsFilterIsActive
);

export const getFetchSelectPaymentMethodSettingsLoading = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchSelectPaymentMethodSettingsLoading }) =>
    fetchSelectPaymentMethodSettingsLoading
);

export const getFetchSelectPaymentMethodSettingsSuccess = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchSelectPaymentMethodSettingsSuccess }) =>
    fetchSelectPaymentMethodSettingsSuccess
);

export const getFetchSelectPaymentMethodSettingsFailed = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchSelectPaymentMethodSettingsFailed }) =>
    fetchSelectPaymentMethodSettingsFailed
);

export const getFetchActivePaymentMethodSettingsSort = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchActivePaymentMethodSettingsSort }) =>
    fetchActivePaymentMethodSettingsSort
);

export const getFetchActivePaymentMethodSettingsPage = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchActivePaymentMethodSettingsPage }) =>
    fetchActivePaymentMethodSettingsPage
);

export const getFetchActivePaymentMethodSettingsPerPage = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchActivePaymentMethodSettingsPerPage }) =>
    fetchActivePaymentMethodSettingsPerPage
);

export const getFetchActivePaymentMethodSettingsIncludes = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchActivePaymentMethodSettingsIncludes }) =>
    fetchActivePaymentMethodSettingsIncludes
);

export const getFetchActivePaymentMethodSettingsFilterMarketId = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchActivePaymentMethodSettingsFilterMarketId }) =>
    fetchActivePaymentMethodSettingsFilterMarketId
);

export const getFetchActivePaymentMethodSettingsLoading = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchActivePaymentMethodSettingsLoading }) =>
    fetchActivePaymentMethodSettingsLoading
);

export const getFetchActivePaymentMethodSettingsSuccess = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchActivePaymentMethodSettingsSuccess }) =>
    fetchActivePaymentMethodSettingsSuccess
);

export const getFetchActivePaymentMethodSettingsFailed = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchActivePaymentMethodSettingsFailed }) =>
    fetchActivePaymentMethodSettingsFailed
);

export const getFetchPaymentMethodSettingLoading = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingLoading }) => fetchPaymentMethodSettingLoading
);

export const getFetchPaymentMethodSettingSuccess = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingSuccess }) => fetchPaymentMethodSettingSuccess
);

export const getFetchPaymentMethodSettingFailed = createSelector(
  [paymentMethodSettingSelector],
  ({ fetchPaymentMethodSettingFailed }) => fetchPaymentMethodSettingFailed
);

export const getCreatePaymentMethodSettingLoading = createSelector(
  [paymentMethodSettingSelector],
  ({ createPaymentMethodSettingLoading }) => createPaymentMethodSettingLoading
);

export const getCreatePaymentMethodSettingSuccess = createSelector(
  [paymentMethodSettingSelector],
  ({ createPaymentMethodSettingSuccess }) => createPaymentMethodSettingSuccess
);

export const getCreatePaymentMethodSettingFailed = createSelector(
  [paymentMethodSettingSelector],
  ({ createPaymentMethodSettingFailed }) => createPaymentMethodSettingFailed
);

export const getUpdatePaymentMethodSettingLoading = createSelector(
  [paymentMethodSettingSelector],
  ({ updatePaymentMethodSettingLoading }) => updatePaymentMethodSettingLoading
);

export const getUpdatePaymentMethodSettingSuccess = createSelector(
  [paymentMethodSettingSelector],
  ({ updatePaymentMethodSettingSuccess }) => updatePaymentMethodSettingSuccess
);

export const getUpdatePaymentMethodSettingFailed = createSelector(
  [paymentMethodSettingSelector],
  ({ updatePaymentMethodSettingFailed }) => updatePaymentMethodSettingFailed
);

export const getDeletePaymentMethodSettingLoading = createSelector(
  [paymentMethodSettingSelector],
  ({ deletePaymentMethodSettingLoading }) => deletePaymentMethodSettingLoading
);

export const getDeletePaymentMethodSettingSuccess = createSelector(
  [paymentMethodSettingSelector],
  ({ deletePaymentMethodSettingSuccess }) => deletePaymentMethodSettingSuccess
);

export const getDeletePaymentMethodSettingFailed = createSelector(
  [paymentMethodSettingSelector],
  ({ deletePaymentMethodSettingFailed }) => deletePaymentMethodSettingFailed
);

export const getIsFetchPaymentMethodSettingsHitted = createSelector(
  [paymentMethodSettingSelector],
  ({ isFetchPaymentMethodSettingsHitted }) => isFetchPaymentMethodSettingsHitted
);

export const getIsFetchSelectPaymentMethodSettingsHitted = createSelector(
  [paymentMethodSettingSelector],
  ({ isFetchSelectPaymentMethodSettingsHitted }) =>
    isFetchSelectPaymentMethodSettingsHitted
);

export const getIsFetchActivePaymentMethodSettingsHitted = createSelector(
  [paymentMethodSettingSelector],
  ({ isFetchActivePaymentMethodSettingsHitted }) =>
    isFetchActivePaymentMethodSettingsHitted
);

export const getIsFetchPaymentMethodSettingHitted = createSelector(
  [paymentMethodSettingSelector],
  ({ isFetchPaymentMethodSettingHitted }) => isFetchPaymentMethodSettingHitted
);

export const getIsCreatePaymentMethodSettingHitted = createSelector(
  [paymentMethodSettingSelector],
  ({ isCreatePaymentMethodSettingHitted }) => isCreatePaymentMethodSettingHitted
);

export const getIsUpdatePaymentMethodSettingHitted = createSelector(
  [paymentMethodSettingSelector],
  ({ isUpdatePaymentMethodSettingHitted }) => isUpdatePaymentMethodSettingHitted
);

export const getIsDeletePaymentMethodSettingHitted = createSelector(
  [paymentMethodSettingSelector],
  ({ isDeletePaymentMethodSettingHitted }) => isDeletePaymentMethodSettingHitted
);
