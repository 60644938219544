import ORDER_PRODUCT_ACTION_TYPES from "./order-product.type";
import { createAction } from "../../utils/store.utils";

export const setOrderProduct = (orderProduct) =>
  createAction(ORDER_PRODUCT_ACTION_TYPES.SET_ORDER_PRODUCT, orderProduct);

export const setFetchOrderProductLoading = (fetchOrderProductLoading) =>
  createAction(ORDER_PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_LOADING, fetchOrderProductLoading);

export const setFetchOrderProductSuccess = (fetchOrderProductSuccess) =>
  createAction(ORDER_PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_SUCCESS, fetchOrderProductSuccess);

export const setFetchOrderProductFailed = (fetchOrderProductFailed) =>
  createAction(ORDER_PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_FAILED, fetchOrderProductFailed);

export const setUpdateOrderProductLoading = (updateOrderProductLoading) =>
  createAction(ORDER_PRODUCT_ACTION_TYPES.SET_UPDATE_ORDER_PRODUCT_LOADING, updateOrderProductLoading);

export const setUpdateOrderProductSuccess = (updateOrderProductSuccess) =>
  createAction(ORDER_PRODUCT_ACTION_TYPES.SET_UPDATE_ORDER_PRODUCT_SUCCESS, updateOrderProductSuccess);

export const setUpdateOrderProductFailed = (updateOrderProductFailed) =>
  createAction(ORDER_PRODUCT_ACTION_TYPES.SET_UPDATE_ORDER_PRODUCT_FAILED, updateOrderProductFailed);

export const fetchOrderProductStart = (orderId, orderProductId) =>
  createAction(ORDER_PRODUCT_ACTION_TYPES.FETCH_ORDER_PRODUCT_START, { orderId, orderProductId });

export const updateOrderProductStart = (orderId, orderProductId, request) =>
  createAction(ORDER_PRODUCT_ACTION_TYPES.UPDATE_ORDER_PRODUCT_START, { orderId, orderProductId, request });

export const setIsFetchOrderProductHitted = (isFetchOrderProductHitted) =>
  createAction(ORDER_PRODUCT_ACTION_TYPES.SET_IS_FETCH_ORDER_PRODUCT_HITTED, isFetchOrderProductHitted);

export const setIsUpdateOrderProductHitted = (isUpdateOrderProductHitted) =>
  createAction(ORDER_PRODUCT_ACTION_TYPES.SET_IS_UPDATE_ORDER_PRODUCT_HITTED, isUpdateOrderProductHitted);

export const resetOrderProductReducer = () => createAction(ORDER_PRODUCT_ACTION_TYPES.RESET_ORDER_PRODUCT_REDUCER);
