import apiService from "./api";

export const getAnnouncements = async (parameters) => (await apiService.get("/v2/announcements", parameters)).data;

export const getAnnouncement = async (id) => (await apiService.get(`/v2/announcements/${id}`)).data;

export const createAnnouncement = async (request) => (await apiService.post("/v2/announcements", request)).data;

export const updateAnnouncement = async (id, request) =>
  (await apiService.post(`/v2/announcements/${id}`, request)).data;

export const deleteAnnouncement = async (id) => (await apiService.delete(`/v2/announcements/${id}`)).data;

export const readAnnouncement = async (request) => (await apiService.post(`/v2/announcements/read`, request)).data;
