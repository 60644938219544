import { takeLatest, put, all, call, select } from "redux-saga/effects";

import SUPPLIER_ACTION_TYPES from "./supplier.type";

import {
  appendSelectSuppliers,
  appendSuppliers,
  setCreateSupplierFailed,
  setCreateSupplierLoading,
  setCreateSupplierSuccess,
  setDeleteSupplierFailed,
  setDeleteSupplierLoading,
  setDeleteSupplierSuccess,
  setFetchSelectSuppliersFailed,
  setFetchSelectSuppliersLoading,
  setFetchSelectSuppliersPage,
  setFetchSelectSuppliersSuccess,
  setFetchSupplierFailed,
  setFetchSupplierLoading,
  setFetchSuppliersFailed,
  setFetchSuppliersLoading,
  setFetchSuppliersPage,
  setFetchSuppliersSuccess,
  setFetchSupplierSuccess,
  setIsCreateSupplierHitted,
  setIsDeleteSupplierHitted,
  setIsFetchSelectSuppliersHitted,
  setIsFetchSupplierHitted,
  setIsFetchSuppliersHitted,
  setIsSelectSuppliersHasMore,
  setIsSuppliersHasMore,
  setIsUpdateSupplierHitted,
  setSupplier,
  setSuppliers,
  setUpdateSupplierFailed,
  setUpdateSupplierLoading,
  setUpdateSupplierSuccess,
} from "./supplier.action";
import {
  getFetchSelectSuppliersFilterBranchId,
  getFetchSelectSuppliersFilterMarketId,
  getFetchSelectSuppliersFilterSuppliersIds,
  getFetchSelectSuppliersIncludes,
  getFetchSelectSuppliersPage,
  getFetchSelectSuppliersPerPage,
  getFetchSelectSuppliersSearch,
  getFetchSuppliersFilterBranchId,
  getFetchSuppliersFilterMarketId,
  getFetchSuppliersFilterSuppliersIds,
  getFetchSuppliersIncludes,
  getFetchSuppliersPage,
  getFetchSuppliersPerPage,
  getFetchSuppliersSearch,
  getIsFetchSelectSuppliersHitted,
  getIsFetchSuppliersHitted,
} from "./supplier.selector";

import { getSuppliers, getSupplier, createSupplier, updateSupplier, deleteSupplier } from "../../api/supplier.api";

export function* _getSuppliers() {
  try {
    yield put(setFetchSuppliersLoading(true));

    const search = yield select(getFetchSuppliersSearch);
    const page = yield select(getFetchSuppliersPage);
    const per_page = yield select(getFetchSuppliersPerPage);
    const includes = yield select(getFetchSuppliersIncludes);
    const market_id = yield select(getFetchSuppliersFilterMarketId);
    const branch_id = yield select(getFetchSuppliersFilterBranchId);
    const suppliers_ids = yield select(getFetchSuppliersFilterSuppliersIds);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        suppliers_ids,
      },
    };

    const {
      meta: { message },
      data: { data: suppliers },
    } = yield call(getSuppliers, parameters);

    yield put(setIsFetchSuppliersHitted(true));
    yield put(setIsSuppliersHasMore(suppliers.length > 0));

    if (page > 1) {
      yield put(appendSuppliers(suppliers));
    } else {
      yield put(setSuppliers(suppliers));
    }

    yield put(setFetchSuppliersSuccess(message));
    yield put(setFetchSuppliersLoading(false));
  } catch (error) {
    yield put(setFetchSuppliersFailed(error));
    yield put(setFetchSuppliersLoading(false));
  }
}

export function* _getSelectSuppliers() {
  try {
    yield put(setFetchSelectSuppliersLoading(true));

    const search = yield select(getFetchSelectSuppliersSearch);
    const page = yield select(getFetchSelectSuppliersPage);
    const per_page = yield select(getFetchSelectSuppliersPerPage);
    const includes = yield select(getFetchSelectSuppliersIncludes);
    const market_id = yield select(getFetchSelectSuppliersFilterMarketId);
    const branch_id = yield select(getFetchSelectSuppliersFilterBranchId);
    const suppliers_ids = yield select(getFetchSelectSuppliersFilterSuppliersIds);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        suppliers_ids,
      },
    };

    const {
      meta: { message },
      data: { data: suppliers },
    } = yield call(getSuppliers, parameters);

    yield put(setIsFetchSelectSuppliersHitted(true));
    yield put(setIsSelectSuppliersHasMore(suppliers.length > 0));
    yield put(appendSelectSuppliers(suppliers));

    yield put(setFetchSelectSuppliersSuccess(message));
    yield put(setFetchSelectSuppliersLoading(false));
  } catch (error) {
    yield put(setFetchSelectSuppliersFailed(error));
    yield put(setFetchSelectSuppliersLoading(false));
  }
}

export function* _getSupplier({ payload: id }) {
  try {
    yield put(setFetchSupplierLoading(true));

    const {
      meta: { message },
      data: supplier,
    } = yield call(getSupplier, id);

    yield put(setIsFetchSupplierHitted(true));
    yield put(setSupplier(supplier));

    yield put(setFetchSupplierSuccess(message));
    yield put(setFetchSupplierLoading(false));
  } catch (error) {
    yield put(setFetchSupplierFailed(error));
    yield put(setFetchSupplierLoading(false));
  }
}

export function* _createSupplier({ payload: request }) {
  try {
    yield put(setCreateSupplierLoading(true));

    const {
      meta: { message },
    } = yield call(createSupplier, request);

    yield put(setIsCreateSupplierHitted(true));

    const isFetchSuppliersHitted = yield select(getIsFetchSuppliersHitted);
    const isFetchSelectSuppliersHitted = yield select(getIsFetchSelectSuppliersHitted);

    if (isFetchSuppliersHitted) {
      yield put(setFetchSuppliersPage(1));
      yield call(_getSuppliers);
    }
    if (isFetchSelectSuppliersHitted) {
      yield put(setFetchSelectSuppliersPage(1));
      yield put(_getSelectSuppliers);
    }

    yield put(setCreateSupplierSuccess(message));
    yield put(setCreateSupplierLoading(false));
  } catch (error) {
    yield put(setCreateSupplierFailed(error));
    yield put(setCreateSupplierLoading(false));
  }
}

export function* _updateSupplier({ payload: { id, request } }) {
  try {
    yield put(setUpdateSupplierLoading(true));

    const {
      meta: { message },
    } = yield call(updateSupplier, id, request);

    yield put(setIsUpdateSupplierHitted(true));

    const isFetchSuppliersHitted = yield select(getIsFetchSuppliersHitted);
    const isFetchSelectSuppliersHitted = yield select(getIsFetchSelectSuppliersHitted);

    if (isFetchSuppliersHitted) {
      yield put(setFetchSuppliersPage(1));
      yield call(_getSuppliers);
    }
    if (isFetchSelectSuppliersHitted) {
      yield put(setFetchSelectSuppliersPage(1));
      yield put(_getSelectSuppliers);
    }

    yield put(setUpdateSupplierSuccess(message));
    yield put(setUpdateSupplierLoading(false));
  } catch (error) {
    yield put(setUpdateSupplierFailed(error));
    yield put(setUpdateSupplierLoading(false));
  }
}

export function* _deleteSupplier({ payload: id }) {
  try {
    yield put(setDeleteSupplierLoading(true));

    const {
      meta: { message },
    } = yield call(deleteSupplier, id);

    yield put(setIsDeleteSupplierHitted(true));

    const isFetchSuppliersHitted = yield select(getIsFetchSuppliersHitted);
    const isFetchSelectSuppliersHitted = yield select(getIsFetchSelectSuppliersHitted);

    if (isFetchSuppliersHitted) {
      yield put(setFetchSuppliersPage(1));
      yield call(_getSuppliers);
    }
    if (isFetchSelectSuppliersHitted) {
      yield put(setFetchSelectSuppliersPage(1));
      yield put(_getSelectSuppliers);
    }

    yield put(setDeleteSupplierSuccess(message));
    yield put(setDeleteSupplierLoading(false));
  } catch (error) {
    yield put(setDeleteSupplierFailed(error));
    yield put(setDeleteSupplierLoading(false));
  }
}

export function* onFetchSuppliersStart() {
  yield takeLatest(SUPPLIER_ACTION_TYPES.FETCH_SUPPLIERS_START, _getSuppliers);
}

export function* onFetchSelectSuppliersStart() {
  yield takeLatest(SUPPLIER_ACTION_TYPES.FETCH_SELECT_SUPPLIERS_START, _getSelectSuppliers);
}

export function* onFetchSupplierStart() {
  yield takeLatest(SUPPLIER_ACTION_TYPES.FETCH_SUPPLIER_START, _getSupplier);
}

export function* onCreateSupplierStart() {
  yield takeLatest(SUPPLIER_ACTION_TYPES.CREATE_SUPPLIER_START, _createSupplier);
}

export function* onUpdateSupplierStart() {
  yield takeLatest(SUPPLIER_ACTION_TYPES.UPDATE_SUPPLIER_START, _updateSupplier);
}

export function* onDeleteSupplierStart() {
  yield takeLatest(SUPPLIER_ACTION_TYPES.DELETE_SUPPLIER_START, _deleteSupplier);
}

export function* supplierSaga() {
  yield all([
    call(onFetchSuppliersStart),
    call(onFetchSelectSuppliersStart),
    call(onFetchSupplierStart),
    call(onCreateSupplierStart),
    call(onUpdateSupplierStart),
    call(onDeleteSupplierStart),
  ]);
}
