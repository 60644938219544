import APPLICATION_ACTION_TYPES from "./application.type";
import { createAction } from "../../utils/store.utils";

export const setApplications = (applications) =>
  createAction(APPLICATION_ACTION_TYPES.SET_APPLICATIONS, applications);
export const setApplicationExtensions = (applicationExtensions) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_APPLICATION_EXTENSIONS,
    applicationExtensions
  );
export const setApplication = (application) =>
  createAction(APPLICATION_ACTION_TYPES.SET_APPLICATION, application);
export const setCreateApplication = (createApplication) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_CREATE_APPLICATION,
    createApplication
  );
export const setUpdateApplication = (updateApplication) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_UPDATE_APPLICATION,
    updateApplication
  );

export const setIsApplicationsHasMore = (isApplicationsHasMore) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_IS_APPLICATIONS_HAS_MORE,
    isApplicationsHasMore
  );
export const setIsApplicationExtensionsHasMore = (
  isApplicationExtensionsHasMore
) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_IS_APPLICATION_EXTENSIONS_HAS_MORE,
    isApplicationExtensionsHasMore
  );

export const setFetchApplicationsSearch = (fetchApplicationsSearch) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_SEARCH,
    fetchApplicationsSearch
  );
export const setFetchApplicationsKeyBy = (fetchApplicationsKeyBy) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_KEY_BY,
    fetchApplicationsKeyBy
  );
export const setFetchApplicationsPage = (fetchApplicationsPage) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_PAGE,
    fetchApplicationsPage
  );
export const setFetchApplicationsPerPage = (fetchApplicationsPerPage) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_PER_PAGE,
    fetchApplicationsPerPage
  );
export const setFetchApplicationsLoading = (fetchApplicationsLoading) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_LOADING,
    fetchApplicationsLoading
  );
export const setFetchApplicationsSuccess = (fetchApplicationsSuccess) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_SUCCESS,
    fetchApplicationsSuccess
  );
export const setFetchApplicationsFailed = (fetchApplicationsFailed) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATIONS_FAILED,
    fetchApplicationsFailed
  );

export const setFetchApplicationExtensionsSearch = (
  fetchApplicationExtensionsSearch
) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_SEARCH,
    fetchApplicationExtensionsSearch
  );
export const setFetchApplicationExtensionsKeyBy = (
  fetchApplicationExtensionsKeyBy
) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_KEY_BY,
    fetchApplicationExtensionsKeyBy
  );
export const setFetchApplicationExtensionsPage = (
  fetchApplicationExtensionsPage
) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_PAGE,
    fetchApplicationExtensionsPage
  );
export const setFetchApplicationExtensionsPerPage = (
  fetchApplicationExtensionsPerPage
) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_PER_PAGE,
    fetchApplicationExtensionsPerPage
  );
export const setFetchApplicationExtensionsLoading = (
  fetchApplicationExtensionsLoading
) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_LOADING,
    fetchApplicationExtensionsLoading
  );
export const setFetchApplicationExtensionsSuccess = (
  fetchApplicationExtensionsSuccess
) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_SUCCESS,
    fetchApplicationExtensionsSuccess
  );
export const setFetchApplicationExtensionsFailed = (
  fetchApplicationExtensionsFailed
) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_EXTENSIONS_FAILED,
    fetchApplicationExtensionsFailed
  );

export const setFetchApplicationLoading = (fetchApplicationLoading) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_LOADING,
    fetchApplicationLoading
  );
export const setFetchApplicationSuccess = (fetchApplicationSuccess) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_SUCCESS,
    fetchApplicationSuccess
  );
export const setFetchApplicationFailed = (fetchApplicationFailed) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_FETCH_APPLICATION_FAILED,
    fetchApplicationFailed
  );

export const setCreateApplicationLoading = (createApplicationLoading) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_CREATE_APPLICATION_LOADING,
    createApplicationLoading
  );
export const setCreateApplicationSuccess = (createApplicationSuccess) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_CREATE_APPLICATION_SUCCESS,
    createApplicationSuccess
  );
export const setCreateApplicationFailed = (createApplicationFailed) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_CREATE_APPLICATION_FAILED,
    createApplicationFailed
  );

export const setUpdateApplicationLoading = (updateApplicationLoading) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_UPDATE_APPLICATION_LOADING,
    updateApplicationLoading
  );
export const setUpdateApplicationSuccess = (updateApplicationSuccess) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_UPDATE_APPLICATION_SUCCESS,
    updateApplicationSuccess
  );
export const setUpdateApplicationFailed = (updateApplicationFailed) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_UPDATE_APPLICATION_FAILED,
    updateApplicationFailed
  );

export const setDeleteApplicationLoading = (deleteApplicationLoading) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_DELETE_APPLICATION_LOADING,
    deleteApplicationLoading
  );
export const setDeleteApplicationSuccess = (deleteApplicationSuccess) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_DELETE_APPLICATION_SUCCESS,
    deleteApplicationSuccess
  );
export const setDeleteApplicationFailed = (deleteApplicationFailed) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_DELETE_APPLICATION_FAILED,
    deleteApplicationFailed
  );

export const appendApplications = (applications) =>
  createAction(APPLICATION_ACTION_TYPES.APPEND_APPLICATIONS, applications);
export const appendApplicationExtensions = (applicationExtensions) =>
  createAction(
    APPLICATION_ACTION_TYPES.APPEND_APPLICATION_EXTENSIONS,
    applicationExtensions
  );

export const fetchApplicationsStart = () =>
  createAction(APPLICATION_ACTION_TYPES.FETCH_APPLICATIONS_START);
export const fetchApplicationExtensionsStart = () =>
  createAction(APPLICATION_ACTION_TYPES.FETCH_APPLICATION_EXTENSIONS_START);
export const fetchApplicationStart = (applicationKey) =>
  createAction(
    APPLICATION_ACTION_TYPES.FETCH_APPLICATION_START,
    applicationKey
  );
export const createApplicationStart = (request) =>
  createAction(APPLICATION_ACTION_TYPES.CREATE_APPLICATION_START, request);
export const updateApplicationStart = (applicationKey, request) =>
  createAction(APPLICATION_ACTION_TYPES.UPDATE_APPLICATION_START, {
    applicationKey,
    request,
  });
export const deleteApplicationStart = (applicationKey) =>
  createAction(
    APPLICATION_ACTION_TYPES.DELETE_APPLICATION_START,
    applicationKey
  );

export const setIsFetchApplicationsHitted = (isFetchApplicationsHitted) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_IS_FETCH_APPLICATIONS_HITTED,
    isFetchApplicationsHitted
  );
export const setIsFetchApplicationExtensionsHitted = (
  isFetchApplicationExtensionsHitted
) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_IS_FETCH_APPLICATION_EXTENSIONS_HITTED,
    isFetchApplicationExtensionsHitted
  );
export const setIsFetchApplicationHitted = (isFetchApplicationHitted) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_IS_FETCH_APPLICATION_HITTED,
    isFetchApplicationHitted
  );
export const setIsCreateApplicationHitted = (isCreateApplicationHitted) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_IS_CREATE_APPLICATION_HITTED,
    isCreateApplicationHitted
  );
export const setIsUpdateApplicationHitted = (isUpdateApplicationHitted) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_IS_UPDATE_APPLICATION_HITTED,
    isUpdateApplicationHitted
  );
export const setIsDeleteApplicationHitted = (isDeleteApplicationHitted) =>
  createAction(
    APPLICATION_ACTION_TYPES.SET_IS_DELETE_APPLICATION_HITTED,
    isDeleteApplicationHitted
  );

export const resetAppReducer = () =>
  createAction(APPLICATION_ACTION_TYPES.RESET_APP_REDUCER);
