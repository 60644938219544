import PAYMENT_METHOD_SETTING_ACTION_TYPES from "./payment-method-setting.type";
import { createAction } from "../../utils/store.utils";

export const setIsModalAutoClose = (isModalAutoClose) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE,
    isModalAutoClose
  );

export const setPaymentMethodSettings = (paymentMethodSettings) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_PAYMENT_METHOD_SETTINGS,
    paymentMethodSettings
  );

export const setSelectPaymentMethodSettings = (selectPaymentMethodSettings) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_SELECT_PAYMENT_METHOD_SETTINGS,
    selectPaymentMethodSettings
  );

export const setActivePaymentMethodSettings = (activePaymentMethodSettings) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_ACTIVE_PAYMENT_METHOD_SETTINGS,
    activePaymentMethodSettings
  );

export const setPaymentMethodSetting = (paymentMethodSetting) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_PAYMENT_METHOD_SETTING,
    paymentMethodSetting
  );

export const setIsPaymentMethodSettingsHasMore = (
  isPaymentMethodSettingsHasMore
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_PAYMENT_METHOD_SETTINGS_HAS_MORE,
    isPaymentMethodSettingsHasMore
  );

export const setIsSelectPaymentMethodSettingsHasMore = (
  isSelectPaymentMethodSettingsHasMore
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_SELECT_PAYMENT_METHOD_SETTINGS_HAS_MORE,
    isSelectPaymentMethodSettingsHasMore
  );

export const setIsActivePaymentMethodSettingsHasMore = (
  isActivePaymentMethodSettingsHasMore
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_ACTIVE_PAYMENT_METHOD_SETTINGS_HAS_MORE,
    isActivePaymentMethodSettingsHasMore
  );

export const setFetchPaymentMethodSettingsSort = (
  fetchPaymentMethodSettingsSort
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_SORT,
    fetchPaymentMethodSettingsSort
  );

export const setFetchPaymentMethodSettingsPage = (
  fetchPaymentMethodSettingsPage
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_PAGE,
    fetchPaymentMethodSettingsPage
  );

export const setFetchPaymentMethodSettingsPerPage = (
  fetchPaymentMethodSettingsPerPage
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_PER_PAGE,
    fetchPaymentMethodSettingsPerPage
  );

export const setFetchPaymentMethodSettingsIncludes = (
  fetchPaymentMethodSettingsIncludes
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_INCLUDES,
    fetchPaymentMethodSettingsIncludes
  );

export const setFetchPaymentMethodSettingsFilterMarketId = (
  fetchPaymentMethodSettingsFilterMarketId
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID,
    fetchPaymentMethodSettingsFilterMarketId
  );

export const setFetchPaymentMethodSettingsFilterIsActive = (
  fetchPaymentMethodSettingsFilterIsActive
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FILTER_IS_ACTIVE,
    fetchPaymentMethodSettingsFilterIsActive
  );

export const setFetchPaymentMethodSettingsLoading = (
  fetchPaymentMethodSettingsLoading
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_LOADING,
    fetchPaymentMethodSettingsLoading
  );

export const setFetchPaymentMethodSettingsSuccess = (
  fetchPaymentMethodSettingsSuccess
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS,
    fetchPaymentMethodSettingsSuccess
  );

export const setFetchPaymentMethodSettingsFailed = (
  fetchPaymentMethodSettingsFailed
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTINGS_FAILED,
    fetchPaymentMethodSettingsFailed
  );

export const setFetchSelectPaymentMethodSettingsSort = (
  fetchSelectPaymentMethodSettingsSort
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_SORT,
    fetchSelectPaymentMethodSettingsSort
  );

export const setFetchSelectPaymentMethodSettingsPage = (
  fetchSelectPaymentMethodSettingsPage
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_PAGE,
    fetchSelectPaymentMethodSettingsPage
  );

export const setFetchSelectPaymentMethodSettingsPerPage = (
  fetchSelectPaymentMethodSettingsPerPage
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_PER_PAGE,
    fetchSelectPaymentMethodSettingsPerPage
  );

export const setFetchSelectPaymentMethodSettingsIncludes = (
  fetchSelectPaymentMethodSettingsIncludes
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_INCLUDES,
    fetchSelectPaymentMethodSettingsIncludes
  );

export const setFetchSelectPaymentMethodSettingsFilterMarketId = (
  fetchSelectPaymentMethodSettingsFilterMarketId
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID,
    fetchSelectPaymentMethodSettingsFilterMarketId
  );

export const setFetchSelectPaymentMethodSettingsFilterIsActive = (
  fetchSelectPaymentMethodSettingsFilterIsActive
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_FILTER_IS_ACTIVE,
    fetchSelectPaymentMethodSettingsFilterIsActive
  );

export const setFetchSelectPaymentMethodSettingsLoading = (
  fetchSelectPaymentMethodSettingsLoading
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_LOADING,
    fetchSelectPaymentMethodSettingsLoading
  );

export const setFetchSelectPaymentMethodSettingsSuccess = (
  fetchSelectPaymentMethodSettingsSuccess
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_SUCCESS,
    fetchSelectPaymentMethodSettingsSuccess
  );

export const setFetchSelectPaymentMethodSettingsFailed = (
  fetchSelectPaymentMethodSettingsFailed
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_FAILED,
    fetchSelectPaymentMethodSettingsFailed
  );

export const setFetchActivePaymentMethodSettingsSort = (
  fetchActivePaymentMethodSettingsSort
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_SORT,
    fetchActivePaymentMethodSettingsSort
  );

export const setFetchActivePaymentMethodSettingsPage = (
  fetchActivePaymentMethodSettingsPage
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_PAGE,
    fetchActivePaymentMethodSettingsPage
  );

export const setFetchActivePaymentMethodSettingsPerPage = (
  fetchActivePaymentMethodSettingsPerPage
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_PER_PAGE,
    fetchActivePaymentMethodSettingsPerPage
  );

export const setFetchActivePaymentMethodSettingsIncludes = (
  fetchActivePaymentMethodSettingsIncludes
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_INCLUDES,
    fetchActivePaymentMethodSettingsIncludes
  );

export const setFetchActivePaymentMethodSettingsFilterMarketId = (
  fetchActivePaymentMethodSettingsFilterMarketId
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_FILTER_MARKET_ID,
    fetchActivePaymentMethodSettingsFilterMarketId
  );

export const setFetchActivePaymentMethodSettingsLoading = (
  fetchActivePaymentMethodSettingsLoading
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_LOADING,
    fetchActivePaymentMethodSettingsLoading
  );

export const setFetchActivePaymentMethodSettingsSuccess = (
  fetchActivePaymentMethodSettingsSuccess
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_SUCCESS,
    fetchActivePaymentMethodSettingsSuccess
  );

export const setFetchActivePaymentMethodSettingsFailed = (
  fetchActivePaymentMethodSettingsFailed
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_FAILED,
    fetchActivePaymentMethodSettingsFailed
  );

export const setFetchPaymentMethodSettingLoading = (
  fetchPaymentMethodSettingLoading
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTING_LOADING,
    fetchPaymentMethodSettingLoading
  );

export const setFetchPaymentMethodSettingSuccess = (
  fetchPaymentMethodSettingSuccess
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTING_SUCCESS,
    fetchPaymentMethodSettingSuccess
  );

export const setFetchPaymentMethodSettingFailed = (
  fetchPaymentMethodSettingFailed
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_FETCH_PAYMENT_METHOD_SETTING_FAILED,
    fetchPaymentMethodSettingFailed
  );

export const setCreatePaymentMethodSettingLoading = (
  createPaymentMethodSettingLoading
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_CREATE_PAYMENT_METHOD_SETTING_LOADING,
    createPaymentMethodSettingLoading
  );

export const setCreatePaymentMethodSettingSuccess = (
  createPaymentMethodSettingSuccess
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_CREATE_PAYMENT_METHOD_SETTING_SUCCESS,
    createPaymentMethodSettingSuccess
  );

export const setCreatePaymentMethodSettingFailed = (
  createPaymentMethodSettingFailed
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_CREATE_PAYMENT_METHOD_SETTING_FAILED,
    createPaymentMethodSettingFailed
  );

export const setUpdatePaymentMethodSettingLoading = (
  updatePaymentMethodSettingLoading
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_UPDATE_PAYMENT_METHOD_SETTING_LOADING,
    updatePaymentMethodSettingLoading
  );

export const setUpdatePaymentMethodSettingSuccess = (
  updatePaymentMethodSettingSuccess
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_UPDATE_PAYMENT_METHOD_SETTING_SUCCESS,
    updatePaymentMethodSettingSuccess
  );

export const setUpdatePaymentMethodSettingFailed = (
  updatePaymentMethodSettingFailed
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_UPDATE_PAYMENT_METHOD_SETTING_FAILED,
    updatePaymentMethodSettingFailed
  );

export const setDeletePaymentMethodSettingLoading = (
  deletePaymentMethodSettingLoading
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_DELETE_PAYMENT_METHOD_SETTING_LOADING,
    deletePaymentMethodSettingLoading
  );

export const setDeletePaymentMethodSettingSuccess = (
  deletePaymentMethodSettingSuccess
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_DELETE_PAYMENT_METHOD_SETTING_SUCCESS,
    deletePaymentMethodSettingSuccess
  );

export const setDeletePaymentMethodSettingFailed = (
  deletePaymentMethodSettingFailed
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_DELETE_PAYMENT_METHOD_SETTING_FAILED,
    deletePaymentMethodSettingFailed
  );

export const appendPaymentMethodSettings = (paymentMethodSettings) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.APPEND_PAYMENT_METHOD_SETTINGS,
    paymentMethodSettings
  );

export const appendSelectPaymentMethodSettings = (
  selectPaymentMethodSettings
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.APPEND_SELECT_PAYMENT_METHOD_SETTINGS,
    selectPaymentMethodSettings
  );

export const appendActivePaymentMethodSettings = (
  activePaymentMethodSettings
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.APPEND_ACTIVE_PAYMENT_METHOD_SETTINGS,
    activePaymentMethodSettings
  );

export const fetchPaymentMethodSettingsStart = () =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.FETCH_PAYMENT_METHOD_SETTINGS_START
  );

export const fetchSelectPaymentMethodSettingsStart = () =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.FETCH_SELECT_PAYMENT_METHOD_SETTINGS_START
  );

export const fetchActivePaymentMethodSettingsStart = () =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_START
  );

export const fetchPaymentMethodSettingStart = (id) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.FETCH_PAYMENT_METHOD_SETTING_START,
    id
  );

export const createPaymentMethodSettingStart = (request) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.CREATE_PAYMENT_METHOD_SETTING_START,
    request
  );

export const updatePaymentMethodSettingStart = (id, request) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.UPDATE_PAYMENT_METHOD_SETTING_START,
    { id, request }
  );

export const deletePaymentMethodSettingStart = (id) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.DELETE_PAYMENT_METHOD_SETTING_START,
    id
  );

export const setIsFetchPaymentMethodSettingsHitted = (
  isFetchPaymentMethodSettingsHitted
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_FETCH_PAYMENT_METHOD_SETTINGS_HITTED,
    isFetchPaymentMethodSettingsHitted
  );

export const setIsFetchSelectPaymentMethodSettingsHitted = (
  isFetchSelectPaymentMethodSettingsHitted
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_FETCH_SELECT_PAYMENT_METHOD_SETTINGS_HITTED,
    isFetchSelectPaymentMethodSettingsHitted
  );

export const setIsFetchActivePaymentMethodSettingsHitted = (
  isFetchActivePaymentMethodSettingsHitted
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_FETCH_ACTIVE_PAYMENT_METHOD_SETTINGS_HITTED,
    isFetchActivePaymentMethodSettingsHitted
  );

export const setIsFetchPaymentMethodSettingHitted = (
  isFetchPaymentMethodSettingHitted
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_FETCH_PAYMENT_METHOD_SETTING_HITTED,
    isFetchPaymentMethodSettingHitted
  );

export const setIsCreatePaymentMethodSettingHitted = (
  isCreatePaymentMethodSettingHitted
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_CREATE_PAYMENT_METHOD_SETTING_HITTED,
    isCreatePaymentMethodSettingHitted
  );

export const setIsUpdatePaymentMethodSettingHitted = (
  isUpdatePaymentMethodSettingHitted
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_UPDATE_PAYMENT_METHOD_SETTING_HITTED,
    isUpdatePaymentMethodSettingHitted
  );

export const setIsDeletePaymentMethodSettingHitted = (
  isDeletePaymentMethodSettingHitted
) =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.SET_IS_DELETE_PAYMENT_METHOD_SETTING_HITTED,
    isDeletePaymentMethodSettingHitted
  );

export const resetPaymentMethodSettingReducer = () =>
  createAction(
    PAYMENT_METHOD_SETTING_ACTION_TYPES.RESET_PAYMENT_METHOD_SETTING_REDUCER
  );
