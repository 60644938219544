const ATTENDANCE_ACTION_TYPES = {
  RESET_ATTENDANCE_REDUCER: "attendance/RESET_ATTENDANCE_REDUCER",

  SET_ATTENDANCES: "attendance/SET_ATTENDANCES",
  SET_ATTENDANCE: "attendance/SET_ATTENDANCE",

  SET_IS_ATTENDANCES_HAS_MORE: "attendance/SET_IS_ATTENDANCES_HAS_MORE",

  SET_FETCH_ATTENDANCES_SEARCH: "attendance/SET_FETCH_ATTENDANCES_SEARCH",
  SET_FETCH_ATTENDANCES_SORT: "attendance/SET_FETCH_ATTENDANCES_SORT",
  SET_FETCH_ATTENDANCES_PAGE: "attendance/SET_FETCH_ATTENDANCES_PAGE",
  SET_FETCH_ATTENDANCES_PER_PAGE: "attendance/SET_FETCH_ATTENDANCES_PER_PAGE",
  SET_FETCH_ATTENDANCES_INCLUDES: "attendance/SET_FETCH_ATTENDANCES_INCLUDES",
  SET_FETCH_ATTENDANCES_FILTER_MARKET_ID: "attendance/SET_FETCH_ATTENDANCES_FILTER_MARKET_ID",
  SET_FETCH_ATTENDANCES_FILTER_BRANCH_ID: "attendance/SET_FETCH_ATTENDANCES_FILTER_BRANCH_ID",
  SET_FETCH_ATTENDANCES_FILTER_USER_ID: "attendance/SET_FETCH_ATTENDANCES_FILTER_USER_ID",
  SET_FETCH_ATTENDANCES_LOADING: "attendance/SET_FETCH_ATTENDANCES_LOADING",
  SET_FETCH_ATTENDANCES_SUCCESS: "attendance/SET_FETCH_ATTENDANCES_SUCCESS",
  SET_FETCH_ATTENDANCES_FAILED: "attendance/SET_FETCH_ATTENDANCES_FAILED",

  SET_FETCH_ATTENDANCE_MARKET_ID: "attendance/SET_FETCH_ATTENDANCE_MARKET_ID",
  SET_FETCH_ATTENDANCE_BRANCH_ID: "attendance/SET_FETCH_ATTENDANCE_BRANCH_ID",
  SET_FETCH_ATTENDANCE_LOADING: "attendance/SET_FETCH_ATTENDANCE_LOADING",
  SET_FETCH_ATTENDANCE_SUCCESS: "attendance/SET_FETCH_ATTENDANCE_SUCCESS",
  SET_FETCH_ATTENDANCE_FAILED: "attendance/SET_FETCH_ATTENDANCE_FAILED",

  SET_CREATE_OR_UPDATE_ATTENDANCE_LOADING: "attendance/SET_CREATE_OR_UPDATE_ATTENDANCE_LOADING",
  SET_CREATE_OR_UPDATE_ATTENDANCE_SUCCESS: "attendance/SET_CREATE_OR_UPDATE_ATTENDANCE_SUCCESS",
  SET_CREATE_OR_UPDATE_ATTENDANCE_FAILED: "attendance/SET_CREATE_OR_UPDATE_ATTENDANCE_FAILED",

  APPEND_ATTENDANCES: "attendance/APPEND_ATTENDANCES",

  IS_FETCH_ATTENDANCES_HITTED: "attendance/IS_FETCH_ATTENDANCES_HITTED",
  IS_FETCH_ATTENDANCE_HITTED: "attendance/IS_FETCH_ATTENDANCE_HITTED",
  IS_CREATE_OR_UPDATE_ATTENDANCE_HITTED: "attendance/IS_CREATE_OR_UPDATE_ATTENDANCE_HITTED",

  FETCH_ATTENDANCES_START: "attendance/FETCH_ATTENDANCES_START",
  FETCH_ATTENDANCE_START: "attendance/FETCH_ATTENDANCE_START",
  CREATE_OR_UPDATE_ATTENDANCE_START: "attendance/CREATE_OR_UPDATE_ATTENDANCE_START",
};

export default ATTENDANCE_ACTION_TYPES;
