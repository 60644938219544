import apiService from "./api";

export const getRecipes = async (parameters) => (await apiService.get("/recipe", parameters)).data;

export const getRecipe = async (id) => (await apiService.get(`/recipe/${id}`)).data;

export const createRecipe = async (request) => (await apiService.post("/recipe", request)).data;

export const updateRecipe = async (id, request) => (await apiService.post(`/recipe/${id}`, request)).data;

export const deleteRecipe = async (id) => (await apiService.delete(`/recipe/${id}`)).data;
