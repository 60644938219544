import { createAction } from "../../utils/store.utils";

import SELECT_PRODUCT_CATEGORY_ACTION_TYPES from "./select-product-category.type";

export const setProductCategories = (productCategories) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_PRODUCT_CATEGORIES,
    productCategories
  );
export const setSearchProductCategories = (searchProductCategories) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_SEARCH_PRODUCT_CATEGORIES,
    searchProductCategories
  );

export const appendProductCategories = (productCategories) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.APPEND_PRODUCT_CATEGORIES,
    productCategories
  );
export const appendSearchProductCategories = (searchProductCategories) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.APPEND_SEARCH_PRODUCT_CATEGORIES,
    searchProductCategories
  );

export const setIsProductCategoriesHasMore = (isProductCategoriesHasMore) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_PRODUCT_CATEGORIES_HAS_MORE,
    isProductCategoriesHasMore
  );
export const setIsSearchProductCategoriesHasMore = (
  isSearchProductCategoriesHasMore
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_SEARCH_PRODUCT_CATEGORIES_HAS_MORE,
    isSearchProductCategoriesHasMore
  );

export const setFetchProductCategoriesParams = (fetchProductCategoriesParams) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_PARAMS,
    fetchProductCategoriesParams
  );
export const setFetchProductCategoriesLoading = (
  fetchProductCategoriesLoading
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_LOADING,
    fetchProductCategoriesLoading
  );
export const setFetchProductCategoriesSuccess = (
  fetchProductCategoriesSuccess
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SUCCESS,
    fetchProductCategoriesSuccess
  );
export const setFetchProductCategoriesFailed = (fetchProductCategoriesFailed) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FAILED,
    fetchProductCategoriesFailed
  );

export const setSearchProductCategoriesParams = (
  searchProductCategoriesParams
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_SEARCH_PRODUCT_CATEGORIES_PARAMS,
    searchProductCategoriesParams
  );
export const setSearchProductCategoriesLoading = (
  searchProductCategoriesLoading
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_SEARCH_PRODUCT_CATEGORIES_LOADING,
    searchProductCategoriesLoading
  );
export const setSearchProductCategoriesSuccess = (
  searchProductCategoriesSuccess
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_SEARCH_PRODUCT_CATEGORIES_SUCCESS,
    searchProductCategoriesSuccess
  );
export const setSearchProductCategoriesFailed = (
  searchProductCategoriesFailed
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_SEARCH_PRODUCT_CATEGORIES_FAILED,
    searchProductCategoriesFailed
  );

export const setAppendProductCategoriesParams = (
  appendProductCategoriesParams
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_PRODUCT_CATEGORIES_PARAMS,
    appendProductCategoriesParams
  );
export const setAppendProductCategoriesLoading = (
  appendProductCategoriesLoading
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_PRODUCT_CATEGORIES_LOADING,
    appendProductCategoriesLoading
  );
export const setAppendProductCategoriesSuccess = (
  appendProductCategoriesSuccess
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_PRODUCT_CATEGORIES_SUCCESS,
    appendProductCategoriesSuccess
  );
export const setAppendProductCategoriesFailed = (
  appendProductCategoriesFailed
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_PRODUCT_CATEGORIES_FAILED,
    appendProductCategoriesFailed
  );

export const setIsFetchProductCategoriesHitted = (
  isFetchProductCategoriesHitted
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_FETCH_PRODUCT_CATEGORIES_HITTED,
    isFetchProductCategoriesHitted
  );
export const setIsSearchProductCategoriesHitted = (
  isSearchProductCategoriesHitted
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_SEARCH_PRODUCT_CATEGORIES_HITTED,
    isSearchProductCategoriesHitted
  );
export const setIsAppendProductCategoriesHitted = (
  isAppendProductCategoriesHitted
) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_APPEND_PRODUCT_CATEGORIES_HITTED,
    isAppendProductCategoriesHitted
  );

export const fetchProductCategoriesStart = (params) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.FETCH_PRODUCT_CATEGORIES_START,
    params
  );
export const searchProductCategoriesStart = (params) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SEARCH_PRODUCT_CATEGORIES_START,
    params
  );
export const appendProductCategoriesStart = (params) =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.APPEND_PRODUCT_CATEGORIES_START,
    params
  );

export const resetProductCategoryReducer = () =>
  createAction(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.RESET_SELECT_PRODUCT_CATEGORY_REDUCER
  );
