import { getRemovedDuplicates } from "../../utils/formatter.utils";

import PURCHASE_ACTION_TYPES from "./purchase.type";

export const PURCHASE_INITIAL_STATE = {
  purchases: [],
  purchase: null,
  calculatePurchase: null,

  isPurchasesHasMore: true,

  fetchPurchasesSearch: null,
  fetchPurchasesSort: null,
  fetchPurchasesKeyBy: null,
  fetchPurchasesPage: 1,
  fetchPurchasesPerPage: null,
  fetchPurchasesIncludes: null,
  fetchPurchasesFilterMarketId: null,
  fetchPurchasesFilterBranchId: null,
  fetchPurchasesFilterSupplierId: null,
  fetchPurchasesLoading: false,
  fetchPurchasesSuccess: null,
  fetchPurchasesFailed: null,

  fetchPurchaseLoading: false,
  fetchPurchaseSuccess: null,
  fetchPurchaseFailed: null,

  createPurchaseLoading: false,
  createPurchaseSuccess: null,
  createPurchaseFailed: null,

  updatePurchaseLoading: false,
  updatePurchaseSuccess: null,
  updatePurchaseFailed: null,

  deletePurchaseLoading: false,
  deletePurchaseSuccess: null,
  deletePurchaseFailed: null,

  calculatePurchaseLoading: false,
  calculatePurchaseSuccess: null,
  calculatePurchaseFailed: null,

  isFetchPurchasesHitted: false,
  isFetchPurchaseHitted: false,
  isCreatePurchaseHitted: false,
  isUpdatePurchaseHitted: false,
  isDeletePurchaseHitted: false,
  isCalculatePurchaseHitted: false,
};

export const purchaseReducer = (state = PURCHASE_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PURCHASE_ACTION_TYPES.SET_PURCHASES:
      return { ...state, purchases: payload };
    case PURCHASE_ACTION_TYPES.SET_PURCHASE:
      return { ...state, purchase: payload };
    case PURCHASE_ACTION_TYPES.SET_CALCULATE_PURCHASE:
      return { ...state, calculatePurchase: payload };

    case PURCHASE_ACTION_TYPES.SET_IS_PURCHASES_HAS_MORE:
      return { ...state, isPurchasesHasMore: payload };

    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_SEARCH:
      return { ...state, fetchPurchasesSearch: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_SORT:
      return { ...state, fetchPurchasesSort: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_KEY_BY:
      return { ...state, fetchPurchasesKeyBy: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_PAGE:
      return { ...state, fetchPurchasesPage: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_PER_PAGE:
      return { ...state, fetchPurchasesPerPage: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_INCLUDES:
      return { ...state, fetchPurchasesIncludes: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_FILTER_MARKET_ID:
      return { ...state, fetchPurchasesFilterMarketId: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_FILTER_BRANCH_ID:
      return { ...state, fetchPurchasesFilterBranchId: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_FILTER_SUPPLIER_ID:
      return { ...state, fetchPurchasesFilterSupplierId: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_LOADING:
      return { ...state, fetchPurchasesLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_SUCCESS:
      return { ...state, fetchPurchasesSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_FAILED:
      return { ...state, fetchPurchasesFailed: payload };

    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASE_LOADING:
      return { ...state, fetchPurchaseLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASE_SUCCESS:
      return { ...state, fetchPurchaseSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASE_FAILED:
      return { ...state, fetchPurchaseFailed: payload };

    case PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE_LOADING:
      return { ...state, createPurchaseLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE_SUCCESS:
      return { ...state, createPurchaseSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE_FAILED:
      return { ...state, createPurchaseFailed: payload };

    case PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE_LOADING:
      return { ...state, updatePurchaseLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE_SUCCESS:
      return { ...state, updatePurchaseSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE_FAILED:
      return { ...state, updatePurchaseFailed: payload };

    case PURCHASE_ACTION_TYPES.SET_DELETE_PURCHASE_LOADING:
      return { ...state, deletePurchaseLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_DELETE_PURCHASE_SUCCESS:
      return { ...state, deletePurchaseSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_DELETE_PURCHASE_FAILED:
      return { ...state, deletePurchaseFailed: payload };

    case PURCHASE_ACTION_TYPES.SET_CALCULATE_PURCHASE_LOADING:
      return { ...state, calculatePurchaseLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_CALCULATE_PURCHASE_SUCCESS:
      return { ...state, calculatePurchaseSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_CALCULATE_PURCHASE_FAILED:
      return { ...state, calculatePurchaseFailed: payload };

    case PURCHASE_ACTION_TYPES.APPEND_PURCHASES:
      return {
        ...state,
        purchases: getRemovedDuplicates([...state.purchases, ...payload], "id"),
      };

    case PURCHASE_ACTION_TYPES.SET_IS_FETCH_PURCHASES_HITTED:
      return { ...state, isFetchPurchasesHitted: payload };
    case PURCHASE_ACTION_TYPES.SET_IS_FETCH_PURCHASE_HITTED:
      return { ...state, isFetchPurchaseHitted: payload };
    case PURCHASE_ACTION_TYPES.SET_IS_CREATE_PURCHASE_HITTED:
      return { ...state, isCreatePurchaseHitted: payload };
    case PURCHASE_ACTION_TYPES.SET_IS_UPDATE_PURCHASE_HITTED:
      return { ...state, isUpdatePurchaseHitted: payload };
    case PURCHASE_ACTION_TYPES.SET_IS_DELETE_PURCHASE_HITTED:
      return { ...state, isDeletePurchaseHitted: payload };
    case PURCHASE_ACTION_TYPES.SET_IS_CALCULATE_PURCHASE_HITTED:
      return { ...state, isCalculatePurchaseHitted: payload };

    case PURCHASE_ACTION_TYPES.RESET_PURCHASE_REDUCER:
      return PURCHASE_INITIAL_STATE;
    default:
      return state;
  }
};
