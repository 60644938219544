import apiService from "./api";

export const getPaymentMethods = async (parameters) =>
  (await apiService.get("/v2/payment-methods", parameters)).data;
export const getPaymentMethod = async (key) =>
  (await apiService.get(`/v2/payment-methods/${key}`)).data;
export const createPaymentMethod = async (request) =>
  (await apiService.post("/v2/payment-methods", request)).data;
export const updatePaymentMethod = async (methodKey, request) =>
  (await apiService.post(`/v2/payment-methods/${methodKey}`, request)).data;
export const deletePaymentMethod = async (methodKey) =>
  (await apiService.delete(`/v2/payment-methods/${methodKey}`)).data;
