import ATTENDANCE_ACTION_TYPES from "./attendance.type";
import { createAction } from "../../utils/store.utils";

export const setAttendances = (attendances) => createAction(ATTENDANCE_ACTION_TYPES.SET_ATTENDANCES, attendances);

export const setAttendance = (attendance) => createAction(ATTENDANCE_ACTION_TYPES.SET_ATTENDANCE, attendance);

export const setIsAttendancesHasMore = (isAttendancesHasMore) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_IS_ATTENDANCES_HAS_MORE, isAttendancesHasMore);

export const setFetchAttendancesSearch = (fetchAttendancesSearch) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_SEARCH, fetchAttendancesSearch);

export const setFetchAttendancesSort = (fetchAttendancesSort) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_SORT, fetchAttendancesSort);

export const setFetchAttendancesPage = (fetchAttendancesPage) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_PAGE, fetchAttendancesPage);

export const setFetchAttendancesPerPage = (fetchAttendancesPerPage) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_PER_PAGE, fetchAttendancesPerPage);

export const setFetchAttendancesIncludes = (fetchAttendancesIncludes) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_INCLUDES, fetchAttendancesIncludes);

export const setFetchAttendancesFilterMarketId = (fetchAttendancesFilterMarketId) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_FILTER_MARKET_ID, fetchAttendancesFilterMarketId);

export const setFetchAttendancesFilterBranchId = (fetchAttendancesFilterBranchId) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_FILTER_BRANCH_ID, fetchAttendancesFilterBranchId);

export const setFetchAttendancesFilterUserId = (fetchAttendancesFilterUserId) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_FILTER_USER_ID, fetchAttendancesFilterUserId);

export const setFetchAttendancesLoading = (fetchAttendancesLoading) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_LOADING, fetchAttendancesLoading);

export const setFetchAttendancesSuccess = (fetchAttendancesSuccess) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_SUCCESS, fetchAttendancesSuccess);

export const setFetchAttendancesFailed = (fetchAttendancesFailed) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_FAILED, fetchAttendancesFailed);

export const setFetchAttendanceMarketId = (fetchAttendanceMarketId) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCE_MARKET_ID, fetchAttendanceMarketId);

export const setFetchAttendanceBranchId = (fetchAttendanceBranchId) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCE_BRANCH_ID, fetchAttendanceBranchId);

export const setFetchAttendanceLoading = (fetchAttendanceLoading) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCE_LOADING, fetchAttendanceLoading);

export const setFetchAttendanceSuccess = (fetchAttendanceSuccess) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCE_SUCCESS, fetchAttendanceSuccess);

export const setFetchAttendanceFailed = (fetchAttendanceFailed) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCE_FAILED, fetchAttendanceFailed);

export const setCreateOrUpdateAttendanceLoading = (createOrUpdateAttendanceLoading) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_CREATE_OR_UPDATE_ATTENDANCE_LOADING, createOrUpdateAttendanceLoading);

export const setCreateOrUpdateAttendanceSuccess = (createOrUpdateAttendanceSuccess) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_CREATE_OR_UPDATE_ATTENDANCE_SUCCESS, createOrUpdateAttendanceSuccess);

export const setCreateOrUpdateAttendanceFailed = (createOrUpdateAttendanceFailed) =>
  createAction(ATTENDANCE_ACTION_TYPES.SET_CREATE_OR_UPDATE_ATTENDANCE_FAILED, createOrUpdateAttendanceFailed);

export const appendAttendances = (attendances) => createAction(ATTENDANCE_ACTION_TYPES.APPEND_ATTENDANCES, attendances);

export const fetchAttendancesStart = () => createAction(ATTENDANCE_ACTION_TYPES.FETCH_ATTENDANCES_START);

export const fetchAttendanceStart = (id) => createAction(ATTENDANCE_ACTION_TYPES.FETCH_ATTENDANCE_START, id);

export const createOrUpdateAttendanceStart = (request) =>
  createAction(ATTENDANCE_ACTION_TYPES.CREATE_OR_UPDATE_ATTENDANCE_START, request);

export const setIsFetchAttendancesHitted = (isFetchAttendancesHitted) =>
  createAction(ATTENDANCE_ACTION_TYPES.IS_FETCH_ATTENDANCES_HITTED, isFetchAttendancesHitted);

export const setIsFetchAttendanceHitted = (isFetchAttendanceHitted) =>
  createAction(ATTENDANCE_ACTION_TYPES.IS_FETCH_ATTENDANCE_HITTED, isFetchAttendanceHitted);

export const setIsCreateOrUpdateAttendanceHitted = (isCreateOrUpdateAttendanceHitted) =>
  createAction(ATTENDANCE_ACTION_TYPES.IS_CREATE_OR_UPDATE_ATTENDANCE_HITTED, isCreateOrUpdateAttendanceHitted);

export const resetAttendanceReducer = () => createAction(ATTENDANCE_ACTION_TYPES.RESET_ATTENDANCE_REDUCER);
