import { takeLatest, put, all, call, select } from "redux-saga/effects";

import ORDER_PRODUCT_ACTION_TYPES from "./order-product.type";

import {
  setFetchOrderProductFailed,
  setFetchOrderProductLoading,
  setFetchOrderProductSuccess,
  setIsFetchOrderProductHitted,
  setIsUpdateOrderProductHitted,
  setOrderProduct,
  setUpdateOrderProductFailed,
  setUpdateOrderProductLoading,
  setUpdateOrderProductSuccess,
} from "./order-product.action";
import {
  setFetchCompletedOrdersPage,
  setFetchLatestOrdersPage,
  setFetchOrdersPage,
  setFetchProcessOrdersPage,
  setFetchReportOrdersPage,
  setFetchWaitingOrdersPage,
} from "../order/order.action";
import {
  getIsFetchOrdersHitted,
  getIsFetchCompletedOrdersHitted,
  getIsFetchProcessOrdersHitted,
  getIsFetchWaitingOrdersHitted,
  getIsFetchReportOrdersHitted,
  getIsFetchLatestOrdersHitted,
} from "../order/order.selector";
import {
  _getCompletedOrders,
  _getLatestOrders,
  _getOrders,
  _getProcessOrders,
  _getReportOrders,
  _getWaitingOrders,
} from "../order/order.saga";
import {
  setFetchCompletedOrdersPage as setFetchKitchenCompletedOrdersPage,
  setFetchProcessOrdersPage as setFetchKitchenProcessOrdersPage,
} from "../kitchen/kitchen.action";
import {
  getIsFetchCompletedOrdersHitted as getIsFetchKitchenCompletedOrdersHitted,
  getIsFetchProcessOrdersHitted as getIsFetchKitchenProcessOrdersHitted,
} from "../kitchen/kitchen.selector";
import {
  _getCompletedOrders as getKitchenCompletedOrders,
  _getProcessOrders as getKitchenProcessOrders,
} from "../kitchen/kitchen.saga";

import { getOrderProduct, updateOrderProduct } from "../../api/order-product.api";

export function* _getOrderProduct({ payload: { orderId, orderProductId } }) {
  try {
    yield put(setFetchOrderProductLoading(true));

    const {
      meta: { message },
      data: orderProduct,
    } = yield call(getOrderProduct, orderId, orderProductId);

    yield put(setIsFetchOrderProductHitted(true));
    yield put(setOrderProduct(orderProduct));

    yield put(setFetchOrderProductSuccess(message));
    yield put(setFetchOrderProductLoading(false));
  } catch (error) {
    yield put(setFetchOrderProductFailed(error));
    yield put(setFetchOrderProductLoading(false));
  }
}

export function* _updateOrderProduct({ payload: { orderId, orderProductId, request } }) {
  try {
    yield put(setUpdateOrderProductLoading(true));

    const {
      meta: { message },
    } = yield call(updateOrderProduct, orderId, orderProductId, request);

    yield put(setIsUpdateOrderProductHitted(true));

    const isFetchOrdersHitted = yield select(getIsFetchOrdersHitted);
    const isFetchCompletedOrdersHitted = yield select(getIsFetchCompletedOrdersHitted);
    const isFetchProcessOrdersHitted = yield select(getIsFetchProcessOrdersHitted);
    const isFetchWaitingOrdersHitted = yield select(getIsFetchWaitingOrdersHitted);
    const isFetchReportOrdersHitted = yield select(getIsFetchReportOrdersHitted);
    const isFetchLatestOrdersHitted = yield select(getIsFetchLatestOrdersHitted);
    const isFetchKitchenCompletedOrdersHitted = yield select(getIsFetchKitchenCompletedOrdersHitted);
    const isFetchKitchenProcessOrdersHitted = yield select(getIsFetchKitchenProcessOrdersHitted);

    if (isFetchOrdersHitted) {
      yield put(setFetchOrdersPage(1));
      yield call(_getOrders);
    }
    if (isFetchCompletedOrdersHitted) {
      yield put(setFetchCompletedOrdersPage(1));
      yield call(_getCompletedOrders);
    }
    if (isFetchProcessOrdersHitted) {
      yield put(setFetchProcessOrdersPage(1));
      yield call(_getProcessOrders);
    }
    if (isFetchWaitingOrdersHitted) {
      yield put(setFetchWaitingOrdersPage(1));
      yield call(_getWaitingOrders);
    }
    if (isFetchReportOrdersHitted) {
      yield put(setFetchReportOrdersPage(1));
      yield call(_getReportOrders);
    }
    if (isFetchLatestOrdersHitted) {
      yield put(setFetchLatestOrdersPage(1));
      yield call(_getLatestOrders);
    }
    // if (isFetchKitchenCompletedOrdersHitted) {
    //   yield put(setFetchKitchenCompletedOrdersPage(1));
    //   yield call(getKitchenCompletedOrders);
    // }
    // if (isFetchKitchenProcessOrdersHitted) {
    //   yield put(setFetchKitchenProcessOrdersPage(1));
    //   yield call(getKitchenProcessOrders);
    // }

    yield put(setUpdateOrderProductSuccess(message));
    yield put(setUpdateOrderProductLoading(false));
  } catch (error) {
    yield put(setUpdateOrderProductFailed(error));
    yield put(setUpdateOrderProductLoading(false));
  }
}

export function* onFetchOrderProductStart() {
  yield takeLatest(ORDER_PRODUCT_ACTION_TYPES.FETCH_ORDER_PRODUCT_START, _getOrderProduct);
}

export function* onUpdateOrderProductStart() {
  yield takeLatest(ORDER_PRODUCT_ACTION_TYPES.UPDATE_ORDER_PRODUCT_START, _updateOrderProduct);
}

export function* orderProductSaga() {
  yield all([call(onFetchOrderProductStart), call(onUpdateOrderProductStart)]);
}
