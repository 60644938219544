import { createSelector } from "reselect";

const dashboardAdminSelector = ({ dashboardAdmin }) => dashboardAdmin;

export const getDashboardTotalMarket = createSelector(
  [dashboardAdminSelector],
  ({ dashboardTotalMarket }) => dashboardTotalMarket
);
export const getDashboardTotalBranch = createSelector(
  [dashboardAdminSelector],
  ({ dashboardTotalBranch }) => dashboardTotalBranch
);
export const getDashboardTotalOrder = createSelector(
  [dashboardAdminSelector],
  ({ dashboardTotalOrder }) => dashboardTotalOrder
);
export const getDashboardTotalRevenue = createSelector(
  [dashboardAdminSelector],
  ({ dashboardTotalRevenue }) => dashboardTotalRevenue
);
export const getDashboardOrdersChart = createSelector(
  [dashboardAdminSelector],
  ({ dashboardOrdersChart }) => dashboardOrdersChart
);
export const getDashboardLatestMarkets = createSelector(
  [dashboardAdminSelector],
  ({ dashboardLatestMarkets }) => dashboardLatestMarkets
);

export const getDashboardTotalMarketLoading = createSelector(
  [dashboardAdminSelector],
  ({ dashboardTotalMarketLoading }) => dashboardTotalMarketLoading
);
export const getDashboardTotalBranchLoading = createSelector(
  [dashboardAdminSelector],
  ({ dashboardTotalBranchLoading }) => dashboardTotalBranchLoading
);
export const getDashboardTotalOrderLoading = createSelector(
  [dashboardAdminSelector],
  ({ dashboardTotalOrderLoading }) => dashboardTotalOrderLoading
);
export const getDashboardTotalRevenueLoading = createSelector(
  [dashboardAdminSelector],
  ({ dashboardTotalRevenueLoading }) => dashboardTotalRevenueLoading
);
export const getDashboardOrdersChartLoading = createSelector(
  [dashboardAdminSelector],
  ({ dashboardOrdersChartLoading }) => dashboardOrdersChartLoading
);
export const getDashboardLatestMarketsLoading = createSelector(
  [dashboardAdminSelector],
  ({ dashboardLatestMarketsLoading }) => dashboardLatestMarketsLoading
);

export const getFetchDashboardAdminFilterCreatedAtBefore = createSelector(
  [dashboardAdminSelector],
  ({ fetchDashboardAdminFilterCreatedAtBefore }) =>
    fetchDashboardAdminFilterCreatedAtBefore
);
export const getFetchDashboardAdminFilterCreatedAtAfter = createSelector(
  [dashboardAdminSelector],
  ({ fetchDashboardAdminFilterCreatedAtAfter }) =>
    fetchDashboardAdminFilterCreatedAtAfter
);
export const getFetchDashboardAdminFilterCurrency = createSelector(
  [dashboardAdminSelector],
  ({ fetchDashboardAdminFilterCurrency }) => fetchDashboardAdminFilterCurrency
);
export const getFetchDashboardAdminLoading = createSelector(
  [dashboardAdminSelector],
  ({ fetchDashboardAdminLoading }) => fetchDashboardAdminLoading
);
export const getFetchDashboardAdminSuccess = createSelector(
  [dashboardAdminSelector],
  ({ fetchDashboardAdminSuccess }) => fetchDashboardAdminSuccess
);
export const getFetchDashboardAdminFailed = createSelector(
  [dashboardAdminSelector],
  ({ fetchDashboardAdminFailed }) => fetchDashboardAdminFailed
);

export const getIsFetchDashboardAdminHitted = createSelector(
  [dashboardAdminSelector],
  ({ isFetchDashboardAdminHitted }) => isFetchDashboardAdminHitted
);
