import { getRemovedDuplicates } from "../../utils/formatter.utils";

import TABLE_ACTION_TYPES from "./table.type";

export const TABLE_INITIAL_STATE = {
  isModalAutoClose: true,

  tables: [],
  orderTables: [],
  selectTables: [],
  table: null,
  orderTable: null,

  isTablesHasMore: true,
  isOrderTablesHasMore: true,
  isSelectTablesHasMore: true,

  fetchTablesSearch: null,
  fetchTablesPage: 1,
  fetchTablesPerPage: null,
  fetchTablesIncludes: null,
  fetchTablesFilterMarketId: null,
  fetchTablesFilterBranchId: null,
  fetchTablesFilterStatus: null,
  fetchTablesLoading: false,
  fetchTablesSuccess: null,
  fetchTablesFailed: null,

  fetchOrderTablesSearch: 1,
  fetchOrderTablesPage: 1,
  fetchOrderTablesPerPage: null,
  fetchOrderTablesIncludes: null,
  fetchOrderTablesFilterMarketId: null,
  fetchOrderTablesFilterBranchId: null,
  fetchOrderTablesFilterStatus: null,
  fetchOrderTablesLoading: false,
  fetchOrderTablesSuccess: null,
  fetchOrderTablesFailed: null,

  fetchSelectTablesSearch: 1,
  fetchSelectTablesPage: 1,
  fetchSelectTablesPerPage: null,
  fetchSelectTablesIncludes: null,
  fetchSelectTablesFilterMarketId: null,
  fetchSelectTablesFilterBranchId: null,
  fetchSelectTablesFilterTablesIds: null,
  fetchSelectTablesFilterStatus: null,
  fetchSelectTablesLoading: false,
  fetchSelectTablesSuccess: null,
  fetchSelectTablesFailed: null,

  fetchTableLoading: false,
  fetchTableSuccess: null,
  fetchTableFailed: null,

  createTableLoading: false,
  createTableSuccess: null,
  createTableFailed: null,

  updateTableLoading: false,
  updateTableSuccess: null,
  updateTableFailed: null,

  deleteTableLoading: false,
  deleteTableSuccess: null,
  deleteTableFailed: null,

  multipleCreateTableLoading: false,
  multipleCreateTableSuccess: null,
  multipleCreateTableFailed: null,

  isFetchTablesHitted: false,
  isFetchOrderTablesHitted: false,
  isFetchSelectTablesHitted: false,
  isFetchTableHitted: false,
  isCreateTableHitted: false,
  isUpdateTableHitted: false,
  isDeleteTableHitted: false,
  isMultipleCreateTableHitted: false,
};

export const tableReducer = (state = TABLE_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TABLE_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE:
      return { ...state, isModalAutoClose: payload };
    case TABLE_ACTION_TYPES.SET_TABLES:
      return { ...state, tables: payload };
    case TABLE_ACTION_TYPES.SET_ORDER_TABLES:
      return { ...state, orderTables: payload };
    case TABLE_ACTION_TYPES.SET_SELECT_TABLES:
      return { ...state, selectTables: payload };
    case TABLE_ACTION_TYPES.SET_TABLE:
      return { ...state, table: payload };
    case TABLE_ACTION_TYPES.SET_ORDER_TABLE:
      return { ...state, orderTable: payload };

    case TABLE_ACTION_TYPES.SET_IS_TABLES_HAS_MORE:
      return { ...state, isTablesHasMore: payload };
    case TABLE_ACTION_TYPES.SET_IS_ORDER_TABLES_HAS_MORE:
      return { ...state, isOrderTablesHasMore: payload };
    case TABLE_ACTION_TYPES.SET_IS_SELECT_TABLES_HAS_MORE:
      return { ...state, isSelectTablesHasMore: payload };

    case TABLE_ACTION_TYPES.SET_FETCH_TABLES_SEARCH:
      return { ...state, fetchTablesSearch: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLES_PAGE:
      return { ...state, fetchTablesPage: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLES_PER_PAGE:
      return { ...state, fetchTablesPerPage: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLES_INCLUDES:
      return { ...state, fetchTablesIncludes: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLES_FILTER_MARKET_ID:
      return { ...state, fetchTablesFilterMarketId: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLES_FILTER_BRANCH_ID:
      return { ...state, fetchTablesFilterBranchId: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLES_FILTER_STATUS:
      return { ...state, fetchTablesFilterStatus: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLES_LOADING:
      return { ...state, fetchTablesLoading: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLES_SUCCESS:
      return { ...state, fetchTablesSuccess: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLES_FAILED:
      return { ...state, fetchTablesFailed: payload };

    case TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_SEARCH:
      return { ...state, fetchOrderTablesSearch: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_PAGE:
      return { ...state, fetchOrderTablesPage: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_PER_PAGE:
      return { ...state, fetchOrderTablesPerPage: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_INCLUDES:
      return { ...state, fetchOrderTablesIncludes: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_FILTER_MARKET_ID:
      return { ...state, fetchOrderTablesFilterMarketId: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_FILTER_BRANCH_ID:
      return { ...state, fetchOrderTablesFilterBranchId: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_FILTER_STATUS:
      return { ...state, fetchOrderTablesFilterStatus: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_LOADING:
      return { ...state, fetchOrderTablesLoading: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_SUCCESS:
      return { ...state, fetchOrderTablesSuccess: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_FAILED:
      return { ...state, fetchOrderTablesFailed: payload };

    case TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_SEARCH:
      return { ...state, fetchSelectTablesSearch: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_PAGE:
      return { ...state, fetchSelectTablesPage: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_PER_PAGE:
      return { ...state, fetchSelectTablesPerPage: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_INCLUDES:
      return { ...state, fetchSelectTablesIncludes: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_FILTER_MARKET_ID:
      return { ...state, fetchSelectTablesFilterMarketId: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_FILTER_BRANCH_ID:
      return { ...state, fetchSelectTablesFilterBranchId: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_FILTER_TABLES_IDS:
      return { ...state, fetchSelectTablesFilterTablesIds: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_FILTER_STATUS:
      return { ...state, fetchSelectTablesFilterStatus: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_LOADING:
      return { ...state, fetchSelectTablesLoading: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_SUCCESS:
      return { ...state, fetchSelectTablesSuccess: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_FAILED:
      return { ...state, fetchSelectTablesFailed: payload };

    case TABLE_ACTION_TYPES.SET_FETCH_TABLE_LOADING:
      return { ...state, fetchTableLoading: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLE_SUCCESS:
      return { ...state, fetchTableSuccess: payload };
    case TABLE_ACTION_TYPES.SET_FETCH_TABLE_FAILED:
      return { ...state, fetchTableFailed: payload };

    case TABLE_ACTION_TYPES.SET_CREATE_TABLE_LOADING:
      return { ...state, createTableLoading: payload };
    case TABLE_ACTION_TYPES.SET_CREATE_TABLE_SUCCESS:
      return { ...state, createTableSuccess: payload };
    case TABLE_ACTION_TYPES.SET_CREATE_TABLE_FAILED:
      return { ...state, createTableFailed: payload };

    case TABLE_ACTION_TYPES.SET_UPDATE_TABLE_LOADING:
      return { ...state, updateTableLoading: payload };
    case TABLE_ACTION_TYPES.SET_UPDATE_TABLE_SUCCESS:
      return { ...state, updateTableSuccess: payload };
    case TABLE_ACTION_TYPES.SET_UPDATE_TABLE_FAILED:
      return { ...state, updateTableFailed: payload };

    case TABLE_ACTION_TYPES.SET_DELETE_TABLE_LOADING:
      return { ...state, deleteTableLoading: payload };
    case TABLE_ACTION_TYPES.SET_DELETE_TABLE_SUCCESS:
      return { ...state, deleteTableSuccess: payload };
    case TABLE_ACTION_TYPES.SET_DELETE_TABLE_FAILED:
      return { ...state, deleteTableFailed: payload };

    case TABLE_ACTION_TYPES.SET_MULTIPLE_CREATE_TABLE_LOADING:
      return { ...state, multipleCreateTableLoading: payload };
    case TABLE_ACTION_TYPES.SET_MULTIPLE_CREATE_TABLE_SUCCESS:
      return { ...state, multipleCreateTableSuccess: payload };
    case TABLE_ACTION_TYPES.SET_MULTIPLE_CREATE_TABLE_FAILED:
      return { ...state, multipleCreateTableFailed: payload };

    case TABLE_ACTION_TYPES.APPEND_TABLES:
      return {
        ...state,
        tables: getRemovedDuplicates([...state.tables, ...payload], "id"),
      };
    case TABLE_ACTION_TYPES.APPEND_ORDER_TABLES:
      return {
        ...state,
        orderTables: getRemovedDuplicates(
          [...payload, ...state.orderTables],
          "id"
        ),
      };
    case TABLE_ACTION_TYPES.APPEND_SELECT_TABLES:
      return {
        ...state,
        selectTables: getRemovedDuplicates(
          [...payload, ...state.selectTables],
          "id"
        ),
      };

    case TABLE_ACTION_TYPES.SET_IS_FETCH_TABLES_HITTED:
      return { ...state, isFetchTablesHitted: payload };
    case TABLE_ACTION_TYPES.SET_IS_FETCH_ORDER_TABLES_HITTED:
      return { ...state, isFetchOrderTablesHitted: payload };
    case TABLE_ACTION_TYPES.SET_IS_FETCH_SELECT_TABLES_HITTED:
      return { ...state, isFetchSelectTablesHitted: payload };
    case TABLE_ACTION_TYPES.SET_IS_FETCH_TABLE_HITTED:
      return { ...state, isFetchTableHitted: payload };
    case TABLE_ACTION_TYPES.SET_IS_CREATE_TABLE_HITTED:
      return { ...state, isCreateTableHitted: payload };
    case TABLE_ACTION_TYPES.SET_IS_UPDATE_TABLE_HITTED:
      return { ...state, isUpdateTableHitted: payload };
    case TABLE_ACTION_TYPES.SET_IS_DELETE_TABLE_HITTED:
      return { ...state, isDeleteTableHitted: payload };
    case TABLE_ACTION_TYPES.SET_IS_MULTIPLE_CREATE_TABLE_HITTED:
      return { ...state, isMultipleCreateTableHitted: payload };

    case TABLE_ACTION_TYPES.RESET_TABLE_REDUCER:
      return TABLE_INITIAL_STATE;
    default:
      return state;
  }
};
