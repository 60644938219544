import apiService from "./api";

export const getApplications = async (parameters) =>
  (await apiService.get("/v2/applications", parameters)).data;
export const getApplication = async (applicationKey) =>
  (await apiService.get(`/v2/applications/${applicationKey}`)).data;
export const createApplication = async (request) =>
  (await apiService.post("/v2/applications", request)).data;
export const updateApplication = async (applicationKey, request) =>
  (await apiService.post(`/v2/applications/${applicationKey}`, request)).data;
export const deleteApplication = async (applicationKey) =>
  (await apiService.delete(`/v2/applications/${applicationKey}`)).data;
