import { createSelector } from "reselect";

const whatsappSelector = ({ whatsapp }) => whatsapp;

export const getWhatsapps = createSelector([whatsappSelector], ({ whatsapps }) => whatsapps);
export const getWhatsapp = createSelector([whatsappSelector], ({ whatsapp }) => whatsapp);

export const getIsWhatsappsHasMore = createSelector([whatsappSelector], ({ isWhatsappsHasMore }) => isWhatsappsHasMore);

export const getFetchWhatsappsPage = createSelector([whatsappSelector], ({ fetchWhatsappsPage }) => fetchWhatsappsPage);
export const getFetchWhatsappsPerPage = createSelector(
  [whatsappSelector],
  ({ fetchWhatsappsPerPage }) => fetchWhatsappsPerPage
);
export const getFetchWhatsappsIncludes = createSelector(
  [whatsappSelector],
  ({ fetchWhatsappsIncludes }) => fetchWhatsappsIncludes
);
export const getFetchWhatsappsFilterIsActive = createSelector(
  [whatsappSelector],
  ({ fetchWhatsappsFilterIsActive }) => fetchWhatsappsFilterIsActive
);
export const getFetchWhatsappsLoading = createSelector(
  [whatsappSelector],
  ({ fetchWhatsappsLoading }) => fetchWhatsappsLoading
);
export const getFetchWhatsappsSuccess = createSelector(
  [whatsappSelector],
  ({ fetchWhatsappsSuccess }) => fetchWhatsappsSuccess
);
export const getFetchWhatsappsFailed = createSelector(
  [whatsappSelector],
  ({ fetchWhatsappsFailed }) => fetchWhatsappsFailed
);

export const getFetchWhatsappIsQrCode = createSelector(
  [whatsappSelector],
  ({ fetchWhatsappIsQrCode }) => fetchWhatsappIsQrCode
);
export const getFetchWhatsappLoading = createSelector(
  [whatsappSelector],
  ({ fetchWhatsappLoading }) => fetchWhatsappLoading
);
export const getFetchWhatsappSuccess = createSelector(
  [whatsappSelector],
  ({ fetchWhatsappSuccess }) => fetchWhatsappSuccess
);
export const getFetchWhatsappFailed = createSelector(
  [whatsappSelector],
  ({ fetchWhatsappFailed }) => fetchWhatsappFailed
);

export const getCreateOrUpdateWhatsappLoading = createSelector(
  [whatsappSelector],
  ({ createOrUpdateWhatsappLoading }) => createOrUpdateWhatsappLoading
);
export const getCreateOrUpdateWhatsappSuccess = createSelector(
  [whatsappSelector],
  ({ createOrUpdateWhatsappSuccess }) => createOrUpdateWhatsappSuccess
);
export const getCreateOrUpdateWhatsappFailed = createSelector(
  [whatsappSelector],
  ({ createOrUpdateWhatsappFailed }) => createOrUpdateWhatsappFailed
);

export const getIsFetchWhatsappsHitted = createSelector(
  [whatsappSelector],
  ({ isFetchWhatsappsHitted }) => isFetchWhatsappsHitted
);
export const getIsFetchWhatsappHitted = createSelector(
  [whatsappSelector],
  ({ isFetchWhatsappHitted }) => isFetchWhatsappHitted
);
export const getIsCreateOrUpdateWhatsappHitted = createSelector(
  [whatsappSelector],
  ({ isCreateOrUpdateWhatsappHitted }) => isCreateOrUpdateWhatsappHitted
);
