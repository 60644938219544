import USER_ADMIN_ACTION_TYPES from "./user-admin.type";
import { createAction } from "../../utils/store.utils";

export const setUserAdmins = (userAdmins) =>
  createAction(USER_ADMIN_ACTION_TYPES.SET_USER_ADMINS, userAdmins);
export const setUserAdmin = (userAdmin) =>
  createAction(USER_ADMIN_ACTION_TYPES.SET_USER_ADMIN, userAdmin);

export const setIsUserAdminsHasMore = (isUserAdminsHasMore) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_IS_USER_ADMINS_HAS_MORE,
    isUserAdminsHasMore
  );

export const setFetchUserAdminsSearch = (fetchUserAdminsSearch) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_SEARCH,
    fetchUserAdminsSearch
  );
export const setFetchUserAdminsSort = (fetchUserAdminsSort) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_SORT,
    fetchUserAdminsSort
  );
export const setFetchUserAdminsKeyBy = (fetchUserAdminsKeyBy) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_KEY_BY,
    fetchUserAdminsKeyBy
  );
export const setFetchUserAdminsPage = (fetchUserAdminsPage) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_PAGE,
    fetchUserAdminsPage
  );
export const setFetchUserAdminsPerPage = (fetchUserAdminsPerPage) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_PER_PAGE,
    fetchUserAdminsPerPage
  );
export const setFetchUserAdminsIncludes = (fetchUserAdminsIncludes) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_INCLUDES,
    fetchUserAdminsIncludes
  );
export const setFetchUserAdminsFilterPermissionId = (
  fetchUserAdminsFilterPermissionId
) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_FILTER_PERMISSION_ID,
    fetchUserAdminsFilterPermissionId
  );
export const setFetchUserAdminsFilterMarketId = (
  fetchUserAdminsFilterMarketId
) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_FILTER_MARKET_ID,
    fetchUserAdminsFilterMarketId
  );
export const setFetchUserAdminsLoading = (fetchUserAdminsLoading) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_LOADING,
    fetchUserAdminsLoading
  );
export const setFetchUserAdminsSuccess = (fetchUserAdminsSuccess) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_SUCCESS,
    fetchUserAdminsSuccess
  );
export const setFetchUserAdminsFailed = (fetchUserAdminsFailed) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_FAILED,
    fetchUserAdminsFailed
  );

export const setFetchUserAdminLoading = (fetchUserAdminLoading) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMIN_LOADING,
    fetchUserAdminLoading
  );
export const setFetchUserAdminSuccess = (fetchUserAdminSuccess) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMIN_SUCCESS,
    fetchUserAdminSuccess
  );
export const setFetchUserAdminFailed = (fetchUserAdminFailed) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMIN_FAILED,
    fetchUserAdminFailed
  );

export const setCreateUserAdminLoading = (createUserAdminLoading) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_CREATE_USER_ADMIN_LOADING,
    createUserAdminLoading
  );
export const setCreateUserAdminSuccess = (createUserAdminSuccess) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_CREATE_USER_ADMIN_SUCCESS,
    createUserAdminSuccess
  );
export const setCreateUserAdminFailed = (createUserAdminFailed) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_CREATE_USER_ADMIN_FAILED,
    createUserAdminFailed
  );

export const setUpdateUserAdminLoading = (updateUserAdminLoading) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_UPDATE_USER_ADMIN_LOADING,
    updateUserAdminLoading
  );
export const setUpdateUserAdminSuccess = (updateUserAdminSuccess) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_UPDATE_USER_ADMIN_SUCCESS,
    updateUserAdminSuccess
  );
export const setUpdateUserAdminFailed = (updateUserAdminFailed) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_UPDATE_USER_ADMIN_FAILED,
    updateUserAdminFailed
  );

export const setDeleteUserAdminLoading = (deleteUserAdminLoading) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_DELETE_USER_ADMIN_LOADING,
    deleteUserAdminLoading
  );
export const setDeleteUserAdminSuccess = (deleteUserAdminSuccess) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_DELETE_USER_ADMIN_SUCCESS,
    deleteUserAdminSuccess
  );
export const setDeleteUserAdminFailed = (deleteUserAdminFailed) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_DELETE_USER_ADMIN_FAILED,
    deleteUserAdminFailed
  );

export const appendUserAdmins = (userAdmins) =>
  createAction(USER_ADMIN_ACTION_TYPES.APPEND_USER_ADMINS, userAdmins);

export const fetchUserAdminsStart = () =>
  createAction(USER_ADMIN_ACTION_TYPES.FETCH_USER_ADMINS_START);
export const fetchUserAdminStart = (adminId) =>
  createAction(USER_ADMIN_ACTION_TYPES.FETCH_USER_ADMIN_START, adminId);
export const createUserAdminStart = (request) =>
  createAction(USER_ADMIN_ACTION_TYPES.CREATE_USER_ADMIN_START, request);
export const updateUserAdminStart = (adminId, request) =>
  createAction(USER_ADMIN_ACTION_TYPES.UPDATE_USER_ADMIN_START, {
    adminId,
    request,
  });
export const deleteUserAdminStart = (adminId) =>
  createAction(USER_ADMIN_ACTION_TYPES.DELETE_USER_ADMIN_START, adminId);

export const setIsFetchUserAdminsHitted = (isFetchUserAdminsHitted) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_IS_FETCH_USER_ADMINS_HITTED,
    isFetchUserAdminsHitted
  );
export const setIsFetchUserAdminHitted = (isFetchUserAdminHitted) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_IS_FETCH_USER_ADMIN_HITTED,
    isFetchUserAdminHitted
  );
export const setIsCreateUserAdminHitted = (isCreateUserAdminHitted) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_IS_CREATE_USER_ADMIN_HITTED,
    isCreateUserAdminHitted
  );
export const setIsUpdateUserAdminHitted = (isUpdateUserAdminHitted) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_IS_UPDATE_USER_ADMIN_HITTED,
    isUpdateUserAdminHitted
  );
export const setIsDeleteUserAdminHitted = (isDeleteUserAdminHitted) =>
  createAction(
    USER_ADMIN_ACTION_TYPES.SET_IS_DELETE_USER_ADMIN_HITTED,
    isDeleteUserAdminHitted
  );

export const resetUserAdminReducer = () =>
  createAction(USER_ADMIN_ACTION_TYPES.RESET_USER_ADMIN_REDUCER);
