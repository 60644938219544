import SUPPLIER_ACTION_TYPES from "./supplier.type";
import { createAction } from "../../utils/store.utils";

export const setSuppliers = (suppliers) => createAction(SUPPLIER_ACTION_TYPES.SET_SUPPLIERS, suppliers);

export const setSelectSuppliers = (selectSuppliers) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_SELECT_SUPPLIERS, selectSuppliers);

export const setSupplier = (supplier) => createAction(SUPPLIER_ACTION_TYPES.SET_SUPPLIER, supplier);

export const setIsSuppliersHasMore = (isSuppliersHasMore) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_IS_SUPPLIERS_HAS_MORE, isSuppliersHasMore);

export const setFetchSuppliersSearch = (fetchSuppliersSearch) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_SEARCH, fetchSuppliersSearch);

export const setFetchSuppliersPage = (fetchSuppliersPage) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_PAGE, fetchSuppliersPage);

export const setFetchSuppliersPerPage = (fetchSuppliersPerPage) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_PER_PAGE, fetchSuppliersPerPage);

export const setFetchSuppliersIncludes = (fetchSuppliersIncludes) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_INCLUDES, fetchSuppliersIncludes);

export const setFetchSuppliersFilterMarketId = (fetchSuppliersFilterMarketId) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_FILTER_MARKET_ID, fetchSuppliersFilterMarketId);

export const setFetchSuppliersFilterBranchId = (fetchSuppliersFilterBranchId) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_FILTER_BRANCH_ID, fetchSuppliersFilterBranchId);

export const setFetchSuppliersFilterSuppliersIds = (fetchSuppliersFilterSuppliersIds) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_FILTER_SUPPLIERS_IDS, fetchSuppliersFilterSuppliersIds);

export const setFetchSuppliersLoading = (fetchSuppliersLoading) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_LOADING, fetchSuppliersLoading);

export const setFetchSuppliersSuccess = (fetchSuppliersSuccess) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_SUCCESS, fetchSuppliersSuccess);

export const setFetchSuppliersFailed = (fetchSuppliersFailed) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_FAILED, fetchSuppliersFailed);

export const setIsSelectSuppliersHasMore = (isSuppliersHasMore) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_IS_SUPPLIERS_HAS_MORE, isSuppliersHasMore);

export const setFetchSelectSuppliersSearch = (fetchSelectSuppliersSearch) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_SEARCH, fetchSelectSuppliersSearch);

export const setFetchSelectSuppliersPage = (fetchSelectSuppliersPage) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_PAGE, fetchSelectSuppliersPage);

export const setFetchSelectSuppliersPerPage = (fetchSelectSuppliersPerPage) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_PER_PAGE, fetchSelectSuppliersPerPage);

export const setFetchSelectSuppliersIncludes = (fetchSelectSuppliersIncludes) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_INCLUDES, fetchSelectSuppliersIncludes);

export const setFetchSelectSuppliersFilterMarketId = (fetchSelectSuppliersFilterMarketId) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_FILTER_MARKET_ID, fetchSelectSuppliersFilterMarketId);

export const setFetchSelectSuppliersFilterBranchId = (fetchSelectSuppliersFilterBranchId) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_FILTER_BRANCH_ID, fetchSelectSuppliersFilterBranchId);

export const setFetchSelectSuppliersFilterSuppliersIds = (fetchSelectSuppliersFilterSuppliersIds) =>
  createAction(
    SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_FILTER_SUPPLIERS_IDS,
    fetchSelectSuppliersFilterSuppliersIds
  );

export const setFetchSelectSuppliersLoading = (fetchSelectSuppliersLoading) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_LOADING, fetchSelectSuppliersLoading);

export const setFetchSelectSuppliersSuccess = (fetchSelectSuppliersSuccess) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_SUCCESS, fetchSelectSuppliersSuccess);

export const setFetchSelectSuppliersFailed = (fetchSelectSuppliersFailed) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_FAILED, fetchSelectSuppliersFailed);

export const setFetchSupplierLoading = (fetchSupplierLoading) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIER_LOADING, fetchSupplierLoading);

export const setFetchSupplierSuccess = (fetchSupplierSuccess) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIER_SUCCESS, fetchSupplierSuccess);

export const setFetchSupplierFailed = (fetchSupplierFailed) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIER_FAILED, fetchSupplierFailed);

export const setCreateSupplierLoading = (createSupplierLoading) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_CREATE_SUPPLIER_LOADING, createSupplierLoading);

export const setCreateSupplierSuccess = (createSupplierSuccess) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_CREATE_SUPPLIER_SUCCESS, createSupplierSuccess);

export const setCreateSupplierFailed = (createSupplierFailed) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_CREATE_SUPPLIER_FAILED, createSupplierFailed);

export const setUpdateSupplierLoading = (updateSupplierLoading) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_UPDATE_SUPPLIER_LOADING, updateSupplierLoading);

export const setUpdateSupplierSuccess = (updateSupplierSuccess) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_UPDATE_SUPPLIER_SUCCESS, updateSupplierSuccess);

export const setUpdateSupplierFailed = (updateSupplierFailed) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_UPDATE_SUPPLIER_FAILED, updateSupplierFailed);

export const setDeleteSupplierLoading = (deleteSupplierLoading) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_DELETE_SUPPLIER_LOADING, deleteSupplierLoading);

export const setDeleteSupplierSuccess = (deleteSupplierSuccess) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_DELETE_SUPPLIER_SUCCESS, deleteSupplierSuccess);

export const setDeleteSupplierFailed = (deleteSupplierFailed) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_DELETE_SUPPLIER_FAILED, deleteSupplierFailed);

export const appendSuppliers = (suppliers) => createAction(SUPPLIER_ACTION_TYPES.APPEND_SUPPLIERS, suppliers);

export const appendSelectSuppliers = (selectSuppliers) =>
  createAction(SUPPLIER_ACTION_TYPES.APPEND_SELECT_SUPPLIERS, selectSuppliers);

export const fetchSuppliersStart = () => createAction(SUPPLIER_ACTION_TYPES.FETCH_SUPPLIERS_START);

export const fetchSelectSuppliersStart = () => createAction(SUPPLIER_ACTION_TYPES.FETCH_SELECT_SUPPLIERS_START);

export const fetchSupplierStart = (id) => createAction(SUPPLIER_ACTION_TYPES.FETCH_SUPPLIER_START, id);

export const createSupplierStart = (request) => createAction(SUPPLIER_ACTION_TYPES.CREATE_SUPPLIER_START, request);

export const updateSupplierStart = (id, request) =>
  createAction(SUPPLIER_ACTION_TYPES.UPDATE_SUPPLIER_START, { id, request });

export const deleteSupplierStart = (id) => createAction(SUPPLIER_ACTION_TYPES.DELETE_SUPPLIER_START, id);

export const setIsFetchSuppliersHitted = (isFetchSuppliersHitted) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_IS_FETCH_SUPPLIERS_HITTED, isFetchSuppliersHitted);

export const setIsFetchSelectSuppliersHitted = (isFetchSelectSuppliersHitted) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_IS_FETCH_SELECT_SUPPLIERS_HITTED, isFetchSelectSuppliersHitted);

export const setIsFetchSupplierHitted = (isFetchSupplierHitted) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_IS_FETCH_SUPPLIER_HITTED, isFetchSupplierHitted);

export const setIsCreateSupplierHitted = (isCreateSupplierHitted) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_IS_CREATE_SUPPLIER_HITTED, isCreateSupplierHitted);

export const setIsUpdateSupplierHitted = (isUpdateSupplierHitted) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_IS_UPDATE_SUPPLIER_HITTED, isUpdateSupplierHitted);

export const setIsDeleteSupplierHitted = (isDeleteSupplierHitted) =>
  createAction(SUPPLIER_ACTION_TYPES.SET_IS_DELETE_SUPPLIER_HITTED, isDeleteSupplierHitted);

export const resetSupplierReducer = () => createAction(SUPPLIER_ACTION_TYPES.RESET_SUPPLIER_REDUCER);
