const PERMISSION_ADMIN_ACTION_TYPES = {
  RESET_PERMISSION_ADMIN_REDUCER:
    "permission-admin/RESET_PERMISSION_ADMIN_REDUCER",

  SET_PERMISSION_TAB: "permission-admin/SET_PERMISSION_TAB",
  SET_PERMISSION_TYPES: "permission-admin/SET_PERMISSION_TYPES",

  SET_PERMISSION_ADMINS: "permission-admin/SET_PERMISSION_ADMINS",
  SET_PERMISSION_SUBSCRIBERS: "permission-admin/SET_PERMISSION_SUBSCRIBERS",
  SET_SELECT_PERMISSION_ADMINS: "permission-admin/SET_SELECT_PERMISSION_ADMINS",
  SET_SELECT_PERMISSION_SUBSCRIBERS:
    "permission-admin/SET_SELECT_PERMISSION_SUBSCRIBERS",
  SET_PERMISSION: "permission-admin/SET_PERMISSION",

  SET_IS_PERMISSION_ADMINS_HAS_MORE:
    "permission-admin/SET_IS_PERMISSION_ADMINS_HAS_MORE",
  SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE:
    "permission-admin/SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE",
  SET_IS_SELECT_PERMISSION_ADMINS_HAS_MORE:
    "permission-admin/SET_IS_SELECT_PERMISSION_ADMINS_HAS_MORE",
  SET_IS_SELECT_PERMISSION_SUBSCRIBERS_HAS_MORE:
    "permission-admin/SET_IS_SELECT_PERMISSION_SUBSCRIBERS_HAS_MORE",

  SET_FETCH_PERMISSION_ADMINS_SEARCH:
    "permission-admin/SET_FETCH_PERMISSION_ADMINS_SEARCH",
  SET_FETCH_PERMISSION_ADMINS_SORT:
    "permission-admin/SET_FETCH_PERMISSION_ADMINS_SORT",
  SET_FETCH_PERMISSION_ADMINS_KEY_BY:
    "permission-admin/SET_FETCH_PERMISSION_ADMINS_KEY_BY",
  SET_FETCH_PERMISSION_ADMINS_PAGE:
    "permission-admin/SET_FETCH_PERMISSION_ADMINS_PAGE",
  SET_FETCH_PERMISSION_ADMINS_PER_PAGE:
    "permission-admin/SET_FETCH_PERMISSION_ADMINS_PER_PAGE",
  SET_FETCH_PERMISSION_ADMINS_INCLUDES:
    "permission-admin/SET_FETCH_PERMISSION_ADMINS_INCLUDES",
  SET_FETCH_PERMISSION_ADMINS_FILTER_IS_DEFAULT:
    "permission-admin/SET_FETCH_PERMISSION_ADMINS_FILTER_IS_DEFAULT",
  SET_FETCH_PERMISSION_ADMINS_LOADING:
    "permission-admin/SET_FETCH_PERMISSION_ADMINS_LOADING",
  SET_FETCH_PERMISSION_ADMINS_SUCCESS:
    "permission-admin/SET_FETCH_PERMISSION_ADMINS_SUCCESS",
  SET_FETCH_PERMISSION_ADMINS_FAILED:
    "permission-admin/SET_FETCH_PERMISSION_ADMINS_FAILED",

  SET_FETCH_PERMISSION_SUBSCRIBERS_SEARCH:
    "permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_SEARCH",
  SET_FETCH_PERMISSION_SUBSCRIBERS_SORT:
    "permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_SORT",
  SET_FETCH_PERMISSION_SUBSCRIBERS_KEY_BY:
    "permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_KEY_BY",
  SET_FETCH_PERMISSION_SUBSCRIBERS_PAGE:
    "permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_PAGE",
  SET_FETCH_PERMISSION_SUBSCRIBERS_PER_PAGE:
    "permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_PER_PAGE",
  SET_FETCH_PERMISSION_SUBSCRIBERS_INCLUDES:
    "permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_INCLUDES",
  SET_FETCH_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT:
    "permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT",
  SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING:
    "permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING",
  SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS:
    "permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS",
  SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED:
    "permission-admin/SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED",

  SET_FETCH_SELECT_PERMISSION_ADMINS_SEARCH:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_ADMINS_SEARCH",
  SET_FETCH_SELECT_PERMISSION_ADMINS_SORT:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_ADMINS_SORT",
  SET_FETCH_SELECT_PERMISSION_ADMINS_KEY_BY:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_ADMINS_KEY_BY",
  SET_FETCH_SELECT_PERMISSION_ADMINS_PAGE:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_ADMINS_PAGE",
  SET_FETCH_SELECT_PERMISSION_ADMINS_PER_PAGE:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_ADMINS_PER_PAGE",
  SET_FETCH_SELECT_PERMISSION_ADMINS_INCLUDES:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_ADMINS_INCLUDES",
  SET_FETCH_SELECT_PERMISSION_ADMINS_FILTER_PERMISSIONS_IDS:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_ADMINS_FILTER_PERMISSIONS_IDS",
  SET_FETCH_SELECT_PERMISSION_ADMINS_FILTER_IS_DEFAULT:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_ADMINS_FILTER_IS_DEFAULT",
  SET_FETCH_SELECT_PERMISSION_ADMINS_LOADING:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_ADMINS_LOADING",
  SET_FETCH_SELECT_PERMISSION_ADMINS_SUCCESS:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_ADMINS_SUCCESS",
  SET_FETCH_SELECT_PERMISSION_ADMINS_FAILED:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_ADMINS_FAILED",

  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SEARCH:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SEARCH",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SORT:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SORT",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PAGE:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PAGE",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_LOADING:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_LOADING",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FAILED:
    "permission-admin/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FAILED",

  SET_APPEND_SELECT_PERMISSION_ADMINS_SEARCH:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_ADMINS_SEARCH",
  SET_APPEND_SELECT_PERMISSION_ADMINS_SORT:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_ADMINS_SORT",
  SET_APPEND_SELECT_PERMISSION_ADMINS_KEY_BY:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_ADMINS_KEY_BY",
  SET_APPEND_SELECT_PERMISSION_ADMINS_PAGE:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_ADMINS_PAGE",
  SET_APPEND_SELECT_PERMISSION_ADMINS_PER_PAGE:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_ADMINS_PER_PAGE",
  SET_APPEND_SELECT_PERMISSION_ADMINS_INCLUDES:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_ADMINS_INCLUDES",
  SET_APPEND_SELECT_PERMISSION_ADMINS_FILTER_PERMISSIONS_IDS:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_ADMINS_FILTER_PERMISSIONS_IDS",
  SET_APPEND_SELECT_PERMISSION_ADMINS_FILTER_IS_DEFAULT:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_ADMINS_FILTER_IS_DEFAULT",
  SET_APPEND_SELECT_PERMISSION_ADMINS_LOADING:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_ADMINS_LOADING",
  SET_APPEND_SELECT_PERMISSION_ADMINS_SUCCESS:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_ADMINS_SUCCESS",
  SET_APPEND_SELECT_PERMISSION_ADMINS_FAILED:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_ADMINS_FAILED",

  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SEARCH:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SEARCH",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SORT:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SORT",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PAGE:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PAGE",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_LOADING:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_LOADING",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FAILED:
    "permission-admin/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FAILED",

  SET_FETCH_PERMISSION_LOADING: "permission-admin/SET_FETCH_PERMISSION_LOADING",
  SET_FETCH_PERMISSION_SUCCESS: "permission-admin/SET_FETCH_PERMISSION_SUCCESS",
  SET_FETCH_PERMISSION_FAILED: "permission-admin/SET_FETCH_PERMISSION_FAILED",

  SET_CREATE_PERMISSION_LOADING:
    "permission-admin/SET_CREATE_PERMISSION_LOADING",
  SET_CREATE_PERMISSION_SUCCESS:
    "permission-admin/SET_CREATE_PERMISSION_SUCCESS",
  SET_CREATE_PERMISSION_FAILED: "permission-admin/SET_CREATE_PERMISSION_FAILED",

  SET_UPDATE_PERMISSION_LOADING:
    "permission-admin/SET_UPDATE_PERMISSION_LOADING",
  SET_UPDATE_PERMISSION_SUCCESS:
    "permission-admin/SET_UPDATE_PERMISSION_SUCCESS",
  SET_UPDATE_PERMISSION_FAILED: "permission-admin/SET_UPDATE_PERMISSION_FAILED",

  SET_DELETE_PERMISSION_LOADING:
    "permission-admin/SET_DELETE_PERMISSION_LOADING",
  SET_DELETE_PERMISSION_SUCCESS:
    "permission-admin/SET_DELETE_PERMISSION_SUCCESS",
  SET_DELETE_PERMISSION_FAILED: "permission-admin/SET_DELETE_PERMISSION_FAILED",

  APPEND_PERMISSION_ADMINS: "permission-admin/APPEND_PERMISSION_ADMINS",
  APPEND_PERMISSION_SUBSCRIBERS:
    "permission-admin/APPEND_PERMISSION_SUBSCRIBERS",
  APPEND_SELECT_PERMISSION_ADMINS:
    "permission-admin/APPEND_SELECT_PERMISSION_ADMINS",
  APPEND_SELECT_PERMISSION_SUBSCRIBERS:
    "permission-admin/APPEND_SELECT_PERMISSION_SUBSCRIBERS",

  SET_IS_FETCH_PERMISSION_ADMINS_HITTED:
    "permission-admin/SET_IS_FETCH_PERMISSION_ADMINS_HITTED",
  SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED:
    "permission-admin/SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED",
  SET_IS_FETCH_SELECT_PERMISSION_ADMINS_HITTED:
    "permission-admin/SET_IS_FETCH_SELECT_PERMISSION_ADMINS_HITTED",
  SET_IS_FETCH_SELECT_PERMISSION_SUBSCRIBERS_HITTED:
    "permission-admin/SET_IS_FETCH_SELECT_PERMISSION_SUBSCRIBERS_HITTED",
  SET_IS_APPEND_SELECT_PERMISSION_ADMINS_HITTED:
    "permission-admin/SET_IS_APPEND_SELECT_PERMISSION_ADMINS_HITTED",
  SET_IS_APPEND_SELECT_PERMISSION_SUBSCRIBERS_HITTED:
    "permission-admin/SET_IS_APPEND_SELECT_PERMISSION_SUBSCRIBERS_HITTED",

  SET_IS_FETCH_PERMISSION_HITTED:
    "permission-admin/SET_IS_FETCH_PERMISSION_HITTED",
  SET_IS_CREATE_PERMISSION_HITTED:
    "permission-admin/SET_IS_CREATE_PERMISSION_HITTED",
  SET_IS_UPDATE_PERMISSION_HITTED:
    "permission-admin/SET_IS_UPDATE_PERMISSION_HITTED",
  SET_IS_DELETE_PERMISSION_HITTED:
    "permission-admin/SET_IS_DELETE_PERMISSION_HITTED",

  FETCH_PERMISSION_ADMINS_START:
    "permission-admin/FETCH_PERMISSION_ADMINS_START",
  FETCH_PERMISSION_SUBSCRIBERS_START:
    "permission-admin/FETCH_PERMISSION_SUBSCRIBERS_START",
  FETCH_SELECT_PERMISSION_ADMINS_START:
    "permission-admin/FETCH_SELECT_PERMISSION_ADMINS_START",
  FETCH_SELECT_PERMISSION_SUBSCRIBERS_START:
    "permission-admin/FETCH_SELECT_PERMISSION_SUBSCRIBERS_START",
  APPEND_SELECT_PERMISSION_ADMINS_START:
    "permission-admin/APPEND_SELECT_PERMISSION_ADMINS_TYPE_ADMIN_START",
  APPEND_SELECT_PERMISSION_SUBSCRIBERS_START:
    "permission-admin/APPEND_SELECT_PERMISSION_SUBSCRIBERS_START",

  FETCH_PERMISSION_START: "permission-admin/FETCH_PERMISSION_START",
  CREATE_PERMISSION_START: "permission-admin/CREATE_PERMISSION_START",
  UPDATE_PERMISSION_START: "permission-admin/UPDATE_PERMISSION_START",
  DELETE_PERMISSION_START: "permission-admin/DELETE_PERMISSION_START",
};

export default PERMISSION_ADMIN_ACTION_TYPES;
