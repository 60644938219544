import { PERMISISON_MERCHANT_TYPES } from "../../constants/permission-merchant.constant";

import PERMISSION_MERCHANT_ACTION_TYPES from "./permission-merchant.type";

export const PERMISSION_MERCHANT_INITIAL_STATE = {
  permissionTab: PERMISISON_MERCHANT_TYPES.MERCHANT,
  permissionTypes: [
    {
      label: "Merchant Permissions",
      value: PERMISISON_MERCHANT_TYPES.MERCHANT,
    },
    {
      label: "Subscriber Permissions",
      value: PERMISISON_MERCHANT_TYPES.SUBSCRIBER,
    },
  ],

  permissionMerchants: [],
  permissionSubscribers: [],
  selectPermissionMerchants: {},
  selectPermissionSubscribers: {},
  permission: null,

  isPermissionMerchantsHasMore: true,
  isPermissionSubscribersHasMore: true,
  isSelectPermissionMerchantsHasMore: true,
  isSelectPermissionSubscribersHasMore: true,

  fetchPermissionMerchantsSearch: null,
  fetchPermissionMerchantsSort: null,
  fetchPermissionMerchantsKeyBy: null,
  fetchPermissionMerchantsPage: 1,
  fetchPermissionMerchantsPerPage: null,
  fetchPermissionMerchantsIncludes: null,
  fetchPermissionMerchantsFilterMerchantId: null,
  fetchPermissionMerchantsFilterIsDefault: null,
  fetchPermissionMerchantsLoading: false,
  fetchPermissionMerchantsSuccess: null,
  fetchPermissionMerchantsFailed: null,

  fetchPermissionSubscribersSearch: null,
  fetchPermissionSubscribersSort: null,
  fetchPermissionSubscribersKeyBy: null,
  fetchPermissionSubscribersPage: 1,
  fetchPermissionSubscribersPerPage: null,
  fetchPermissionSubscribersIncludes: null,
  fetchPermissionSubscribersFilterMerchantId: null,
  fetchPermissionSubscribersFilterIsDefault: null,
  fetchPermissionSubscribersLoading: false,
  fetchPermissionSubscribersSuccess: null,
  fetchPermissionSubscribersFailed: null,

  fetchSelectPermissionMerchantsSearch: null,
  fetchSelectPermissionMerchantsSort: null,
  fetchSelectPermissionMerchantsKeyBy: null,
  fetchSelectPermissionMerchantsPage: 1,
  fetchSelectPermissionMerchantsPerPage: null,
  fetchSelectPermissionMerchantsIncludes: null,
  fetchSelectPermissionMerchantsFilterMerchantId: null,
  fetchSelectPermissionMerchantsFilterPermissionsIds: null,
  fetchSelectPermissionMerchantsFilterIsDefault: null,
  fetchSelectPermissionMerchantsLoading: false,
  fetchSelectPermissionMerchantsSuccess: null,
  fetchSelectPermissionMerchantsFailed: null,

  fetchSelectPermissionSubscribersSearch: null,
  fetchSelectPermissionSubscribersSort: null,
  fetchSelectPermissionSubscribersKeyBy: null,
  fetchSelectPermissionSubscribersPage: 1,
  fetchSelectPermissionSubscribersPerPage: null,
  fetchSelectPermissionSubscribersIncludes: null,
  fetchSelectPermissionSubscribersFilterMerchantId: null,
  fetchSelectPermissionSubscribersFilterPermissionsIds: null,
  fetchSelectPermissionSubscribersFilterIsDefault: null,
  fetchSelectPermissionSubscribersLoading: false,
  fetchSelectPermissionSubscribersSuccess: null,
  fetchSelectPermissionSubscribersFailed: null,

  appendSelectPermissionMerchantsSearch: null,
  appendSelectPermissionMerchantsSort: null,
  appendSelectPermissionMerchantsKeyBy: null,
  appendSelectPermissionMerchantsPage: 1,
  appendSelectPermissionMerchantsPerPage: null,
  appendSelectPermissionMerchantsIncludes: null,
  appendSelectPermissionMerchantsFilterMerchantId: null,
  appendSelectPermissionMerchantsFilterPermissionsIds: null,
  appendSelectPermissionMerchantsFilterIsDefault: null,
  appendSelectPermissionMerchantsLoading: false,
  appendSelectPermissionMerchantsSuccess: null,
  appendSelectPermissionMerchantsFailed: null,

  appendSelectPermissionSubscribersSearch: null,
  appendSelectPermissionSubscribersSort: null,
  appendSelectPermissionSubscribersKeyBy: null,
  appendSelectPermissionSubscribersPage: 1,
  appendSelectPermissionSubscribersPerPage: null,
  appendSelectPermissionSubscribersIncludes: null,
  appendSelectPermissionSubscribersFilterMerchantId: null,
  appendSelectPermissionSubscribersFilterPermissionsIds: null,
  appendSelectPermissionSubscribersFilterIsDefault: null,
  appendSelectPermissionSubscribersLoading: false,
  appendSelectPermissionSubscribersSuccess: null,
  appendSelectPermissionSubscribersFailed: null,

  fetchPermissionLoading: false,
  fetchPermissionSuccess: null,
  fetchPermissionFailed: null,

  createPermissionLoading: false,
  createPermissionSuccess: null,
  createPermissionFailed: null,

  updatePermissionLoading: false,
  updatePermissionSuccess: null,
  updatePermissionFailed: null,

  deletePermissionLoading: false,
  deletePermissionSuccess: null,
  deletePermissionFailed: null,

  isFetchPermissionMerchantsHitted: false,
  isFetchPermissionSubscribersHitted: false,
  isFetchSelectPermissionMerchantsHitted: false,
  isFetchSelectPermissionSubscribersHitted: false,
  isAppendSelectPermissionMerchantsHitted: false,
  isAppendSelectPermissionSubscribersHitted: false,
  isFetchPermissionHitted: false,
  isCreatePermissionHitted: false,
  isUpdatePermissionHitted: false,
  isDeletePermissionHitted: false,
};

export const permissionMerchantReducer = (
  state = PERMISSION_MERCHANT_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_PERMISSION_TAB:
      return { ...state, permissionTab: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_PERMISSION_MERCHANTS:
      return { ...state, permissionMerchants: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_PERMISSION_SUBSCRIBERS:
      return { ...state, permissionSubscribers: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_SELECT_PERMISSION_MERCHANTS:
      return { ...state, selectPermissionMerchants: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_SELECT_PERMISSION_SUBSCRIBERS:
      return { ...state, selectPermissionSubscribers: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_PERMISSION:
      return { ...state, permission: payload };

    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_PERMISSION_MERCHANTS_HAS_MORE:
      return { ...state, isPermissionMerchantsHasMore: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE:
      return { ...state, isPermissionSubscribersHasMore: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_SELECT_PERMISSION_MERCHANTS_HAS_MORE:
      return { ...state, isSelectPermissionMerchantsHasMore: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_SELECT_PERMISSION_SUBSCRIBERS_HAS_MORE:
      return { ...state, isSelectPermissionSubscribersHasMore: payload };

    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_SEARCH:
      return { ...state, fetchPermissionMerchantsSearch: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_SORT:
      return { ...state, fetchPermissionMerchantsSort: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_KEY_BY:
      return { ...state, fetchPermissionMerchantsKeyBy: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_PAGE:
      return { ...state, fetchPermissionMerchantsPage: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_PER_PAGE:
      return { ...state, fetchPermissionMerchantsPerPage: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_INCLUDES:
      return { ...state, fetchPermissionMerchantsIncludes: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_FILTER_MERCHANT_ID:
      return { ...state, fetchPermissionMerchantsFilterMerchantId: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_FILTER_IS_DEFAULT:
      return { ...state, fetchPermissionMerchantsFilterIsDefault: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_LOADING:
      return { ...state, fetchPermissionMerchantsLoading: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_SUCCESS:
      return { ...state, fetchPermissionMerchantsSuccess: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_FAILED:
      return { ...state, fetchPermissionMerchantsFailed: payload };

    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SEARCH:
      return { ...state, fetchPermissionSubscribersSearch: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SORT:
      return { ...state, fetchPermissionSubscribersSort: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_KEY_BY:
      return { ...state, fetchPermissionSubscribersKeyBy: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_PAGE:
      return { ...state, fetchPermissionSubscribersPage: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_PER_PAGE:
      return { ...state, fetchPermissionSubscribersPerPage: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_INCLUDES:
      return { ...state, fetchPermissionSubscribersIncludes: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FILTER_MERCHANT_ID:
      return { ...state, fetchPermissionSubscribersFilterMerchantId: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT:
      return { ...state, fetchPermissionSubscribersFilterIsDefault: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING:
      return { ...state, fetchPermissionSubscribersLoading: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS:
      return { ...state, fetchPermissionSubscribersSuccess: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED:
      return { ...state, fetchPermissionSubscribersFailed: payload };

    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_SEARCH:
      return { ...state, fetchSelectPermissionMerchantsSearch: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_SORT:
      return { ...state, fetchSelectPermissionMerchantsSort: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_KEY_BY:
      return { ...state, fetchSelectPermissionMerchantsKeyBy: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_PAGE:
      return { ...state, fetchSelectPermissionMerchantsPage: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_PER_PAGE:
      return { ...state, fetchSelectPermissionMerchantsPerPage: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_INCLUDES:
      return { ...state, fetchSelectPermissionMerchantsIncludes: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_FILTER_MERCHANT_ID:
      return {
        ...state,
        fetchSelectPermissionMerchantsFilterMerchantId: payload,
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_FILTER_PERMISSIONS_IDS:
      return {
        ...state,
        fetchSelectPermissionMerchantsFilterPermissionsIds: payload,
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_FILTER_IS_DEFAULT:
      return {
        ...state,
        fetchSelectPermissionMerchantsFilterIsDefault: payload,
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_LOADING:
      return { ...state, fetchSelectPermissionMerchantsLoading: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_SUCCESS:
      return { ...state, fetchSelectPermissionMerchantsSuccess: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_FAILED:
      return { ...state, fetchSelectPermissionMerchantsFailed: payload };

    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SEARCH:
      return { ...state, fetchSelectPermissionSubscribersSearch: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SORT:
      return { ...state, fetchSelectPermissionSubscribersSort: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY:
      return { ...state, fetchSelectPermissionSubscribersKeyBy: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PAGE:
      return { ...state, fetchSelectPermissionSubscribersPage: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE:
      return { ...state, fetchSelectPermissionSubscribersPerPage: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES:
      return { ...state, fetchSelectPermissionSubscribersIncludes: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_MERCHANT_ID:
      return {
        ...state,
        fetchSelectPermissionSubscribersFilterMerchantId: payload,
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS:
      return {
        ...state,
        fetchSelectPermissionSubscribersFilterPermissionsIds: payload,
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT:
      return {
        ...state,
        fetchSelectPermissionSubscribersFilterIsDefault: payload,
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_LOADING:
      return { ...state, fetchSelectPermissionSubscribersLoading: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS:
      return { ...state, fetchSelectPermissionSubscribersSuccess: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FAILED:
      return { ...state, fetchSelectPermissionSubscribersFailed: payload };

    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_SEARCH:
      return { ...state, appendSelectPermissionMerchantsSearch: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_SORT:
      return { ...state, appendSelectPermissionMerchantsSort: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_KEY_BY:
      return { ...state, appendSelectPermissionMerchantsKeyBy: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_PAGE:
      return { ...state, appendSelectPermissionMerchantsPage: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_PER_PAGE:
      return { ...state, appendSelectPermissionMerchantsPerPage: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_INCLUDES:
      return { ...state, appendSelectPermissionMerchantsIncludes: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_FILTER_MERCHANT_ID:
      return {
        ...state,
        appendSelectPermissionMerchantsFilterMerchantId: payload,
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_FILTER_PERMISSIONS_IDS:
      return {
        ...state,
        appendSelectPermissionMerchantsFilterPermissionsIds: payload,
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_FILTER_IS_DEFAULT:
      return {
        ...state,
        appendSelectPermissionMerchantsFilterIsDefault: payload,
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_LOADING:
      return { ...state, appendSelectPermissionMerchantsLoading: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_SUCCESS:
      return { ...state, appendSelectPermissionMerchantsSuccess: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_FAILED:
      return { ...state, appendSelectPermissionMerchantsFailed: payload };

    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SEARCH:
      return { ...state, appendSelectPermissionSubscribersSearch: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SORT:
      return { ...state, appendSelectPermissionSubscribersSort: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY:
      return { ...state, appendSelectPermissionSubscribersKeyBy: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PAGE:
      return { ...state, appendSelectPermissionSubscribersPage: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE:
      return { ...state, appendSelectPermissionSubscribersPerPage: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES:
      return { ...state, appendSelectPermissionSubscribersIncludes: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_MERCHANT_ID:
      return {
        ...state,
        appendSelectPermissionSubscribersFilterMerchantId: payload,
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS:
      return {
        ...state,
        appendSelectPermissionSubscribersFilterPermissionsIds: payload,
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT:
      return {
        ...state,
        appendSelectPermissionSubscribersFilterIsDefault: payload,
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_LOADING:
      return { ...state, appendSelectPermissionSubscribersLoading: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS:
      return { ...state, appendSelectPermissionSubscribersSuccess: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FAILED:
      return { ...state, appendSelectPermissionSubscribersFailed: payload };

    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_LOADING:
      return { ...state, fetchPermissionLoading: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUCCESS:
      return { ...state, fetchPermissionSuccess: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_FAILED:
      return { ...state, fetchPermissionFailed: payload };

    case PERMISSION_MERCHANT_ACTION_TYPES.SET_CREATE_PERMISSION_LOADING:
      return { ...state, createPermissionLoading: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_CREATE_PERMISSION_SUCCESS:
      return { ...state, createPermissionSuccess: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_CREATE_PERMISSION_FAILED:
      return { ...state, createPermissionFailed: payload };

    case PERMISSION_MERCHANT_ACTION_TYPES.SET_UPDATE_PERMISSION_LOADING:
      return { ...state, updatePermissionLoading: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_UPDATE_PERMISSION_SUCCESS:
      return { ...state, updatePermissionSuccess: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_UPDATE_PERMISSION_FAILED:
      return { ...state, updatePermissionFailed: payload };

    case PERMISSION_MERCHANT_ACTION_TYPES.SET_DELETE_PERMISSION_LOADING:
      return { ...state, deletePermissionLoading: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_DELETE_PERMISSION_SUCCESS:
      return { ...state, deletePermissionSuccess: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_DELETE_PERMISSION_FAILED:
      return { ...state, deletePermissionFailed: payload };

    case PERMISSION_MERCHANT_ACTION_TYPES.APPEND_PERMISSION_MERCHANTS:
      return {
        ...state,
        permissionMerchants: [...state.permissionMerchants, ...payload],
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.APPEND_PERMISSION_SUBSCRIBERS:
      return {
        ...state,
        permissionSubscribers: [...state.permissionSubscribers, ...payload],
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.APPEND_SELECT_PERMISSION_MERCHANTS:
      return {
        ...state,
        selectPermissionMerchants: {
          ...state.selectPermissionMerchants,
          ...payload,
        },
      };
    case PERMISSION_MERCHANT_ACTION_TYPES.APPEND_SELECT_PERMISSION_SUBSCRIBERS:
      return {
        ...state,
        selectPermissionSubscribers: {
          ...state.selectPermissionSubscribers,
          ...payload,
        },
      };

    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_PERMISSION_MERCHANTS_HITTED:
      return { ...state, isFetchPermissionMerchantsHitted: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED:
      return { ...state, isFetchPermissionSubscribersHitted: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_SELECT_PERMISSION_MERCHANTS_HITTED:
      return { ...state, isFetchSelectPermissionMerchantsHitted: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_SELECT_PERMISSION_SUBSCRIBERS_HITTED:
      return { ...state, isFetchSelectPermissionSubscribersHitted: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_APPEND_SELECT_PERMISSION_MERCHANTS_HITTED:
      return { ...state, isAppendSelectPermissionMerchantsHitted: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_APPEND_SELECT_PERMISSION_SUBSCRIBERS_HITTED:
      return { ...state, isAppendSelectPermissionSubscribersHitted: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_PERMISSION_HITTED:
      return { ...state, isFetchPermissionHitted: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_CREATE_PERMISSION_HITTED:
      return { ...state, isCreatePermissionHitted: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_UPDATE_PERMISSION_HITTED:
      return { ...state, isUpdatePermissionHitted: payload };
    case PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_DELETE_PERMISSION_HITTED:
      return { ...state, isDeletePermissionHitted: payload };

    case PERMISSION_MERCHANT_ACTION_TYPES.RESET_PERMISSION_MERCHANT_REDUCER:
      return PERMISSION_MERCHANT_INITIAL_STATE;
    default:
      return state;
  }
};
