const SUPPLIER_ACTION_TYPES = {
  RESET_SUPPLIER_REDUCER: "supplier/RESET_SUPPLIER_REDUCER",

  SET_SUPPLIERS: "supplier/SET_SUPPLIERS",
  SET_SELECT_SUPPLIERS: "supplier/SET_SELECT_SUPPLIERS",
  SET_SUPPLIER: "supplier/SET_SUPPLIER",

  SET_IS_SUPPLIERS_HAS_MORE: "supplier/SET_IS_SUPPLIERS_HAS_MORE",
  SET_IS_SELECT_SUPPLIERS_HAS_MORE: "supplier/SET_IS_SELECT_SUPPLIERS_HAS_MORE",

  SET_FETCH_SUPPLIERS_SEARCH: "supplier/SET_FETCH_SUPPLIERS_SEARCH",
  SET_FETCH_SUPPLIERS_PAGE: "supplier/SET_FETCH_SUPPLIERS_PAGE",
  SET_FETCH_SUPPLIERS_PER_PAGE: "supplier/SET_FETCH_SUPPLIERS_PER_PAGE",
  SET_FETCH_SUPPLIERS_INCLUDES: "supplier/SET_FETCH_SUPPLIERS_INCLUDES",
  SET_FETCH_SUPPLIERS_FILTER_MARKET_ID: "supplier/SET_FETCH_SUPPLIERS_FILTER_MARKET_ID",
  SET_FETCH_SUPPLIERS_FILTER_BRANCH_ID: "supplier/SET_FETCH_SUPPLIERS_FILTER_BRANCH_ID",
  SET_FETCH_SUPPLIERS_FILTER_SUPPLIERS_IDS: "supplier/SET_FETCH_SUPPLIERS_FILTER_SUPPLIERS_IDS",
  SET_FETCH_SUPPLIERS_LOADING: "supplier/SET_FETCH_SUPPLIERS_LOADING",
  SET_FETCH_SUPPLIERS_SUCCESS: "supplier/SET_FETCH_SUPPLIERS_SUCCESS",
  SET_FETCH_SUPPLIERS_FAILED: "supplier/SET_FETCH_SUPPLIERS_FAILED",

  SET_FETCH_SELECT_SUPPLIERS_SEARCH: "supplier/SET_FETCH_SELECT_SUPPLIERS_SEARCH",
  SET_FETCH_SELECT_SUPPLIERS_PAGE: "supplier/SET_FETCH_SELECT_SUPPLIERS_PAGE",
  SET_FETCH_SELECT_SUPPLIERS_PER_PAGE: "supplier/SET_FETCH_SELECT_SUPPLIERS_PER_PAGE",
  SET_FETCH_SELECT_SUPPLIERS_INCLUDES: "supplier/SET_FETCH_SELECT_SUPPLIERS_INCLUDES",
  SET_FETCH_SELECT_SUPPLIERS_FILTER_MARKET_ID: "supplier/SET_FETCH_SELECT_SUPPLIERS_FILTER_MARKET_ID",
  SET_FETCH_SELECT_SUPPLIERS_FILTER_BRANCH_ID: "supplier/SET_FETCH_SELECT_SUPPLIERS_FILTER_BRANCH_ID",
  SET_FETCH_SELECT_SUPPLIERS_FILTER_SUPPLIERS_IDS: "supplier/SET_FETCH_SELECT_SUPPLIERS_FILTER_SUPPLIERS_IDS",
  SET_FETCH_SELECT_SUPPLIERS_LOADING: "supplier/SET_FETCH_SELECT_SUPPLIERS_LOADING",
  SET_FETCH_SELECT_SUPPLIERS_SUCCESS: "supplier/SET_FETCH_SELECT_SUPPLIERS_SUCCESS",
  SET_FETCH_SELECT_SUPPLIERS_FAILED: "supplier/SET_FETCH_SELECT_SUPPLIERS_FAILED",

  SET_FETCH_SUPPLIER_LOADING: "supplier/SET_FETCH_SUPPLIER_LOADING",
  SET_FETCH_SUPPLIER_SUCCESS: "supplier/SET_FETCH_SUPPLIER_SUCCESS",
  SET_FETCH_SUPPLIER_FAILED: "supplier/SET_FETCH_SUPPLIER_FAILED",

  SET_CREATE_SUPPLIER_LOADING: "supplier/SET_CREATE_SUPPLIER_LOADING",
  SET_CREATE_SUPPLIER_SUCCESS: "supplier/SET_CREATE_SUPPLIER_SUCCESS",
  SET_CREATE_SUPPLIER_FAILED: "supplier/SET_CREATE_SUPPLIER_FAILED",

  SET_UPDATE_SUPPLIER_LOADING: "supplier/SET_UPDATE_SUPPLIER_LOADING",
  SET_UPDATE_SUPPLIER_SUCCESS: "supplier/SET_UPDATE_SUPPLIER_SUCCESS",
  SET_UPDATE_SUPPLIER_FAILED: "supplier/SET_UPDATE_SUPPLIER_FAILED",

  SET_DELETE_SUPPLIER_LOADING: "supplier/SET_DELETE_SUPPLIER_LOADING",
  SET_DELETE_SUPPLIER_SUCCESS: "supplier/SET_DELETE_SUPPLIER_SUCCESS",
  SET_DELETE_SUPPLIER_FAILED: "supplier/SET_DELETE_SUPPLIER_FAILED",

  APPEND_SUPPLIERS: "supplier/APPEND_SUPPLIERS",
  APPEND_SELECT_SUPPLIERS: "supplier/APPEND_SELECT_SUPPLIERS",

  SET_IS_FETCH_SUPPLIERS_HITTED: "supplier/SET_IS_FETCH_SUPPLIERS_HITTED",
  SET_IS_FETCH_SELECT_SUPPLIERS_HITTED: "supplier/SET_IS_FETCH_SELECT_SUPPLIERS_HITTED",
  SET_IS_FETCH_SUPPLIER_HITTED: "supplier/SET_IS_FETCH_SUPPLIER_HITTED",
  SET_IS_CREATE_SUPPLIER_HITTED: "supplier/SET_IS_CREATE_SUPPLIER_HITTED",
  SET_IS_UPDATE_SUPPLIER_HITTED: "supplier/SET_IS_UPDATE_SUPPLIER_HITTED",
  SET_IS_DELETE_SUPPLIER_HITTED: "supplier/SET_IS_DELETE_SUPPLIER_HITTED",

  FETCH_SUPPLIERS_START: "supplier/FETCH_SUPPLIERS_START",
  FETCH_SELECT_SUPPLIERS_START: "supplier/FETCH_SELECT_SUPPLIERS_START",
  FETCH_SUPPLIER_START: "supplier/FETCH_SUPPLIER_START",
  CREATE_SUPPLIER_START: "supplier/CREATE_SUPPLIER_START",
  UPDATE_SUPPLIER_START: "supplier/UPDATE_SUPPLIER_START",
  DELETE_SUPPLIER_START: "supplier/DELETE_SUPPLIER_START",
};

export default SUPPLIER_ACTION_TYPES;
