const SELECT_CUSTOMER_ACTION_TYPES = {
  RESET_SELECT_CUSTOMER_REDUCER:
    "select-customer/RESET_SELECT_CUSTOMER_REDUCER",

  SET_CUSTOMERS: "select-customer/SET_CUSTOMERS",
  SET_SEARCH_CUSTOMERS: "select-customer/SET_SEARCH_CUSTOMERS",

  APPEND_CUSTOMERS: "select-customer/APPEND_CUSTOMERS",
  APPEND_SEARCH_CUSTOMERS: "select-customer/APPEND_SEARCH_CUSTOMERS",

  SET_IS_CUSTOMERS_HAS_MORE: "select-customer/SET_IS_CUSTOMERS_HAS_MORE",
  SET_IS_SEARCH_CUSTOMERS_HAS_MORE:
    "select-customer/SET_IS_SEARCH_CUSTOMERS_HAS_MORE",

  SET_FETCH_CUSTOMERS_PARAMS: "select-customer/SET_FETCH_CUSTOMERS_PARAMS",
  SET_FETCH_CUSTOMERS_LOADING: "select-customer/SET_FETCH_CUSTOMERS_LOADING",
  SET_FETCH_CUSTOMERS_SUCCESS: "select-customer/SET_FETCH_CUSTOMERS_SUCCESS",
  SET_FETCH_CUSTOMERS_FAILED: "select-customer/SET_FETCH_CUSTOMERS_FAILED",

  SET_SEARCH_CUSTOMERS_PARAMS: "select-customer/SET_SEARCH_CUSTOMERS_PARAMS",
  SET_SEARCH_CUSTOMERS_LOADING: "select-customer/SET_SEARCH_CUSTOMERS_LOADING",
  SET_SEARCH_CUSTOMERS_SUCCESS: "select-customer/SET_SEARCH_CUSTOMERS_SUCCESS",
  SET_SEARCH_CUSTOMERS_FAILED: "select-customer/SET_SEARCH_CUSTOMERS_FAILED",

  SET_APPEND_CUSTOMERS_PARAMS: "select-customer/SET_APPEND_CUSTOMERS_PARAMS",
  SET_APPEND_CUSTOMERS_LOADING: "select-customer/SET_APPEND_CUSTOMERS_LOADING",
  SET_APPEND_CUSTOMERS_SUCCESS: "select-customer/SET_APPEND_CUSTOMERS_SUCCESS",
  SET_APPEND_CUSTOMERS_FAILED: "select-customer/SET_APPEND_CUSTOMERS_FAILED",

  SET_IS_FETCH_CUSTOMERS_HITTED:
    "select-customer/SET_IS_FETCH_CUSTOMERS_HITTED",
  SET_IS_SEARCH_CUSTOMERS_HITTED:
    "select-customer/SET_IS_SEARCH_CUSTOMERS_HITTED",
  SET_IS_APPEND_CUSTOMERS_HITTED:
    "select-customer/SET_IS_APPEND_CUSTOMERS_HITTED",

  FETCH_CUSTOMERS_START: "select-customer/FETCH_CUSTOMERS_START",
  SEARCH_CUSTOMERS_START: "select-customer/SEARCH_CUSTOMERS_START",
  APPEND_CUSTOMERS_START: "select-customer/APPEND_CUSTOMERS_START",
};

export default SELECT_CUSTOMER_ACTION_TYPES;
