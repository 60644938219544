import DASHBOARD_ACTION_TYPES from "./dashboard.type";
import { createAction } from "../../utils/store.utils";

export const setDashboard = (dashboard) =>
  createAction(DASHBOARD_ACTION_TYPES.SET_DASHBOARD, dashboard);
export const setDashboardSubscriber = (dashboardSubscriber) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_DASHBOARD_SUBSCRIBER,
    dashboardSubscriber
  );

export const setFetchDashboardFilterMarketId = (fetchDashboardFilterMarketId) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_MARKET_ID,
    fetchDashboardFilterMarketId
  );
export const setFetchDashboardFilterBranchId = (fetchDashboardFilterBranchId) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_BRANCH_ID,
    fetchDashboardFilterBranchId
  );
export const setFetchDashboardFilterTimePeriod = (
  fetchDashboardFilterTimePeriod
) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_TIME_PERIOD,
    fetchDashboardFilterTimePeriod
  );
export const setFetchDashboardFilterCreatedAtBefore = (
  fetchDashboardFilterCreatedAtBefore
) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_CREATED_AT_BEFORE,
    fetchDashboardFilterCreatedAtBefore
  );
export const setFetchDashboardFilterCreatedAtAfter = (
  fetchDashboardFilterCreatedAtAfter
) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_CREATED_AT_AFTER,
    fetchDashboardFilterCreatedAtAfter
  );
export const setFetchDashboardFilterCurrency = (fetchDashboardFilterCurrency) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_CURRENCY,
    fetchDashboardFilterCurrency
  );
export const setFetchDashboardLoading = (fetchDashboardLoading) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_LOADING,
    fetchDashboardLoading
  );
export const setFetchDashboardSuccess = (fetchDashboardSuccess) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_SUCCESS,
    fetchDashboardSuccess
  );
export const setFetchDashboardFailed = (fetchDashboardFailed) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FAILED,
    fetchDashboardFailed
  );

export const setFetchDashboardSubscriberFilterMarketId = (
  fetchDashboardSubscriberFilterMarketId
) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_MARKET_ID,
    fetchDashboardSubscriberFilterMarketId
  );
export const setFetchDashboardSubscriberFilterBranchId = (
  fetchDashboardSubscriberFilterBranchId
) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_BRANCH_ID,
    fetchDashboardSubscriberFilterBranchId
  );
export const setFetchDashboardSubscriberFilterOrderStatuses = (
  fetchDashboardSubscriberFilterOrderStatuses
) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_ORDER_STATUSES,
    fetchDashboardSubscriberFilterOrderStatuses
  );
export const setFetchDashboardSubscriberFilterTransactionStatuses = (
  fetchDashboardSubscriberFilterTransactionStatuses
) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_TRANSACTION_STATUSES,
    fetchDashboardSubscriberFilterTransactionStatuses
  );
export const setFetchDashboardSubscriberFilterStartAt = (
  fetchDashboardSubscriberFilterStartAt
) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_START_AT,
    fetchDashboardSubscriberFilterStartAt
  );
export const setFetchDashboardSubscriberFilterEndAt = (
  fetchDashboardSubscriberFilterEndAt
) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_END_AT,
    fetchDashboardSubscriberFilterEndAt
  );
export const setFetchDashboardSubscriberLoading = (
  fetchDashboardSubscriberLoading
) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_LOADING,
    fetchDashboardSubscriberLoading
  );
export const setFetchDashboardSubscriberSuccess = (
  fetchDashboardSubscriberSuccess
) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_SUCCESS,
    fetchDashboardSubscriberSuccess
  );
export const setFetchDashboardSubscriberFailed = (
  fetchDashboardSubscriberFailed
) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FAILED,
    fetchDashboardSubscriberFailed
  );

export const setIsFetchDashboardHitted = (isFetchDashboardHitted) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_IS_FETCH_DASHBOARD_HITTED,
    isFetchDashboardHitted
  );
export const setIsFetchDashboardSubscriberHitted = (
  isFetchDashboardSubscriberHitted
) =>
  createAction(
    DASHBOARD_ACTION_TYPES.SET_IS_FETCH_DASHBOARD_SUBSCRIBER_HITTED,
    isFetchDashboardSubscriberHitted
  );

export const fetchDashboardStart = () =>
  createAction(DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_START);
export const fetchDashboardSubscriberStart = () =>
  createAction(DASHBOARD_ACTION_TYPES.FETCH_DASHBOARD_SUBSCRIBER_START);

export const resetDashboardReducer = () =>
  createAction(DASHBOARD_ACTION_TYPES.RESET_DASHBOARD_REDUCER);
