import { createSelector } from "reselect";

const discountSelector = ({ discount }) => discount;

export const getEditModifierIndex = createSelector(
  [discountSelector],
  ({ editModifierIndex }) => editModifierIndex
);
export const getEditModifier = createSelector(
  [discountSelector],
  ({ editModifier }) => editModifier
);
export const getDiscountTypes = createSelector(
  [discountSelector],
  ({ discountTypes }) => discountTypes
);
export const getDiscountValueTypes = createSelector(
  [discountSelector],
  ({ discountValueTypes }) => discountValueTypes
);

export const getDiscountTab = createSelector(
  [discountSelector],
  ({ discountTab }) => discountTab
);
export const getDiscountCreateTab = createSelector(
  [discountSelector],
  ({ discountCreateTab }) => discountCreateTab
);
export const getDiscountEditTab = createSelector(
  [discountSelector],
  ({ discountEditTab }) => discountEditTab
);

export const getDiscounts = createSelector(
  [discountSelector],
  ({ discounts }) => discounts
);
export const getOrderDiscounts = createSelector(
  [discountSelector],
  ({ orderDiscounts }) => orderDiscounts
);
export const getDiscount = createSelector(
  [discountSelector],
  ({ discount }) => discount
);
export const getCreateDiscount = createSelector(
  [discountSelector],
  ({ createDiscount }) => createDiscount
);
export const getUpdateDiscount = createSelector(
  [discountSelector],
  ({ updateDiscount }) => updateDiscount
);
export const getVerifyCouponDiscount = createSelector(
  [discountSelector],
  ({ verifyCouponDiscount }) => verifyCouponDiscount
);
export const getVerifyLoyaProDiscount = createSelector(
  [discountSelector],
  ({ verifyLoyaProDiscount }) => verifyLoyaProDiscount
);

export const getIsDiscountsHasMore = createSelector(
  [discountSelector],
  ({ isDiscountsHasMore }) => isDiscountsHasMore
);
export const getIsOrderDiscountsHasMore = createSelector(
  [discountSelector],
  ({ isOrderDiscountsHasMore }) => isOrderDiscountsHasMore
);

export const getFetchDiscountsSearch = createSelector(
  [discountSelector],
  ({ fetchDiscountsSearch }) => fetchDiscountsSearch
);
export const getFetchDiscountsPage = createSelector(
  [discountSelector],
  ({ fetchDiscountsPage }) => fetchDiscountsPage
);
export const getFetchDiscountsPerPage = createSelector(
  [discountSelector],
  ({ fetchDiscountsPerPage }) => fetchDiscountsPerPage
);
export const getFetchDiscountsIncludes = createSelector(
  [discountSelector],
  ({ fetchDiscountsIncludes }) => fetchDiscountsIncludes
);
export const getFetchDiscountsFilterMarketId = createSelector(
  [discountSelector],
  ({ fetchDiscountsFilterMarketId }) => fetchDiscountsFilterMarketId
);
export const getFetchDiscountsFilterBranchId = createSelector(
  [discountSelector],
  ({ fetchDiscountsFilterBranchId }) => fetchDiscountsFilterBranchId
);
export const getFetchDiscountsFilterTypes = createSelector(
  [discountSelector],
  ({ fetchDiscountsFilterTypes }) => fetchDiscountsFilterTypes
);
export const getFetchDiscountsFilterExpiredAt = createSelector(
  [discountSelector],
  ({ fetchDiscountsFilterExpiredAt }) => fetchDiscountsFilterExpiredAt
);
export const getFetchDiscountsLoading = createSelector(
  [discountSelector],
  ({ fetchDiscountsLoading }) => fetchDiscountsLoading
);
export const getFetchDiscountsSuccess = createSelector(
  [discountSelector],
  ({ fetchDiscountsSuccess }) => fetchDiscountsSuccess
);
export const getFetchDiscountsFailed = createSelector(
  [discountSelector],
  ({ fetchDiscountsFailed }) => fetchDiscountsFailed
);

export const getFetchOrderDiscountsSearch = createSelector(
  [discountSelector],
  ({ fetchOrderDiscountsSearch }) => fetchOrderDiscountsSearch
);
export const getFetchOrderDiscountsPage = createSelector(
  [discountSelector],
  ({ fetchOrderDiscountsPage }) => fetchOrderDiscountsPage
);
export const getFetchOrderDiscountsPerPage = createSelector(
  [discountSelector],
  ({ fetchOrderDiscountsPerPage }) => fetchOrderDiscountsPerPage
);
export const getFetchOrderDiscountsIncludes = createSelector(
  [discountSelector],
  ({ fetchOrderDiscountsIncludes }) => fetchOrderDiscountsIncludes
);
export const getFetchOrderDiscountsFilterMarketId = createSelector(
  [discountSelector],
  ({ fetchOrderDiscountsFilterMarketId }) => fetchOrderDiscountsFilterMarketId
);
export const getFetchOrderDiscountsFilterBranchId = createSelector(
  [discountSelector],
  ({ fetchOrderDiscountsFilterBranchId }) => fetchOrderDiscountsFilterBranchId
);
export const getFetchOrderDiscountsFilterTypes = createSelector(
  [discountSelector],
  ({ fetchOrderDiscountsFilterTypes }) => fetchOrderDiscountsFilterTypes
);
export const getFetchOrderDiscountsFilterExpiredAt = createSelector(
  [discountSelector],
  ({ fetchOrderDiscountsFilterExpiredAt }) => fetchOrderDiscountsFilterExpiredAt
);
export const getFetchOrderDiscountsLoading = createSelector(
  [discountSelector],
  ({ fetchOrderDiscountsLoading }) => fetchOrderDiscountsLoading
);
export const getFetchOrderDiscountsSuccess = createSelector(
  [discountSelector],
  ({ fetchOrderDiscountsSuccess }) => fetchOrderDiscountsSuccess
);
export const getFetchOrderDiscountsFailed = createSelector(
  [discountSelector],
  ({ fetchOrderDiscountsFailed }) => fetchOrderDiscountsFailed
);

export const getFetchDiscountLoading = createSelector(
  [discountSelector],
  ({ fetchDiscountLoading }) => fetchDiscountLoading
);
export const getFetchDiscountSuccess = createSelector(
  [discountSelector],
  ({ fetchDiscountSuccess }) => fetchDiscountSuccess
);
export const getFetchDiscountFailed = createSelector(
  [discountSelector],
  ({ fetchDiscountFailed }) => fetchDiscountFailed
);

export const getCreateDiscountLoading = createSelector(
  [discountSelector],
  ({ createDiscountLoading }) => createDiscountLoading
);
export const getCreateDiscountSuccess = createSelector(
  [discountSelector],
  ({ createDiscountSuccess }) => createDiscountSuccess
);
export const getCreateDiscountFailed = createSelector(
  [discountSelector],
  ({ createDiscountFailed }) => createDiscountFailed
);

export const getUpdateDiscountLoading = createSelector(
  [discountSelector],
  ({ updateDiscountLoading }) => updateDiscountLoading
);
export const getUpdateDiscountSuccess = createSelector(
  [discountSelector],
  ({ updateDiscountSuccess }) => updateDiscountSuccess
);
export const getUpdateDiscountFailed = createSelector(
  [discountSelector],
  ({ updateDiscountFailed }) => updateDiscountFailed
);

export const getDeleteDiscountLoading = createSelector(
  [discountSelector],
  ({ deleteDiscountLoading }) => deleteDiscountLoading
);
export const getDeleteDiscountSuccess = createSelector(
  [discountSelector],
  ({ deleteDiscountSuccess }) => deleteDiscountSuccess
);
export const getDeleteDiscountFailed = createSelector(
  [discountSelector],
  ({ deleteDiscountFailed }) => deleteDiscountFailed
);

export const getVerifyCouponDiscountLoading = createSelector(
  [discountSelector],
  ({ verifyCouponDiscountLoading }) => verifyCouponDiscountLoading
);
export const getVerifyCouponDiscountSuccess = createSelector(
  [discountSelector],
  ({ verifyCouponDiscountSuccess }) => verifyCouponDiscountSuccess
);
export const getVerifyCouponDiscountFailed = createSelector(
  [discountSelector],
  ({ verifyCouponDiscountFailed }) => verifyCouponDiscountFailed
);

export const getVerifyLoyaProDiscountLoading = createSelector(
  [discountSelector],
  ({ verifyLoyaProDiscountLoading }) => verifyLoyaProDiscountLoading
);
export const getVerifyLoyaProDiscountSuccess = createSelector(
  [discountSelector],
  ({ verifyLoyaProDiscountSuccess }) => verifyLoyaProDiscountSuccess
);
export const getVerifyLoyaProDiscountFailed = createSelector(
  [discountSelector],
  ({ verifyLoyaProDiscountFailed }) => verifyLoyaProDiscountFailed
);

export const getIsFetchDiscountsHitted = createSelector(
  [discountSelector],
  ({ isFetchDiscountsHitted }) => isFetchDiscountsHitted
);
export const getIsFetchOrderDiscountsHitted = createSelector(
  [discountSelector],
  ({ isFetchOrderDiscountsHitted }) => isFetchOrderDiscountsHitted
);
export const getIsFetchDiscountHitted = createSelector(
  [discountSelector],
  ({ isFetchDiscountHitted }) => isFetchDiscountHitted
);
export const getIsCreateDiscountHitted = createSelector(
  [discountSelector],
  ({ isCreateDiscountHitted }) => isCreateDiscountHitted
);
export const getIsUpdateDiscountHitted = createSelector(
  [discountSelector],
  ({ isUpdateDiscountHitted }) => isUpdateDiscountHitted
);
export const getIsDeleteDiscountHitted = createSelector(
  [discountSelector],
  ({ isDeleteDiscountHitted }) => isDeleteDiscountHitted
);
export const getIsVerifyCouponDiscountHitted = createSelector(
  [discountSelector],
  ({ isVerifyCouponDiscountHitted }) => isVerifyCouponDiscountHitted
);
export const getIsVerifyLoyaProDiscountHitted = createSelector(
  [discountSelector],
  ({ isVerifyLoyaProDiscountHitted }) => isVerifyLoyaProDiscountHitted
);
