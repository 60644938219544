const PRODUCT_CATEGORY_SORT_ACTION_TYPES = {
  RESET_PRODUCT_CATEGORY_SORT_REDUCER: "product-category-sort/RESET_PRODUCT_CATEGORY_SORT_REDUCER",
  SET_CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_LOADING:
    "product-category-sort/SET_CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_LOADING",
  SET_CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_SUCCESS:
    "product-category-sort/SET_CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_SUCCESS",
  SET_CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_FAILED:
    "product-category-sort/SET_CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_FAILED",
  CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_START: "product-category-sort/CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_START",
};

export default PRODUCT_CATEGORY_SORT_ACTION_TYPES;
