import { createSelector } from "reselect";

const topUpSelector = ({ topUp }) => topUp;

export const getTopUps = createSelector([topUpSelector], ({ topUps }) => topUps);

export const getTopUp = createSelector([topUpSelector], ({ topUp }) => topUp);

export const getIsTopUpsHasMore = createSelector([topUpSelector], ({ isTopUpsHasMore }) => isTopUpsHasMore);

export const getFetchTopUpsSearch = createSelector([topUpSelector], ({ fetchTopUpsSearch }) => fetchTopUpsSearch);

export const getFetchTopUpsPage = createSelector([topUpSelector], ({ fetchTopUpsPage }) => fetchTopUpsPage);

export const getFetchTopUpsPerPage = createSelector([topUpSelector], ({ fetchTopUpsPerPage }) => fetchTopUpsPerPage);

export const getFetchTopUpsIncludes = createSelector([topUpSelector], ({ fetchTopUpsIncludes }) => fetchTopUpsIncludes);

export const getFetchTopUpsFilterMarketId = createSelector(
  [topUpSelector],
  ({ fetchTopUpsFilterMarketId }) => fetchTopUpsFilterMarketId
);

export const getFetchTopUpsFilterCreatedBy = createSelector(
  [topUpSelector],
  ({ fetchTopUpsFilterCreatedBy }) => fetchTopUpsFilterCreatedBy
);

export const getFetchTopUpsLoading = createSelector([topUpSelector], ({ fetchTopUpsLoading }) => fetchTopUpsLoading);

export const getFetchTopUpsSuccess = createSelector([topUpSelector], ({ fetchTopUpsSuccess }) => fetchTopUpsSuccess);

export const getFetchTopUpsFailed = createSelector([topUpSelector], ({ fetchTopUpsFailed }) => fetchTopUpsFailed);

export const getFetchTopUpLoading = createSelector([topUpSelector], ({ fetchTopUpLoading }) => fetchTopUpLoading);

export const getFetchTopUpSuccess = createSelector([topUpSelector], ({ fetchTopUpSuccess }) => fetchTopUpSuccess);

export const getFetchTopUpFailed = createSelector([topUpSelector], ({ fetchTopUpFailed }) => fetchTopUpFailed);

export const getCreateTopUpLoading = createSelector([topUpSelector], ({ createTopUpLoading }) => createTopUpLoading);

export const getCreateTopUpSuccess = createSelector([topUpSelector], ({ createTopUpSuccess }) => createTopUpSuccess);

export const getCreateTopUpFailed = createSelector([topUpSelector], ({ createTopUpFailed }) => createTopUpFailed);

export const getUpdateTopUpLoading = createSelector([topUpSelector], ({ updateTopUpLoading }) => updateTopUpLoading);

export const getUpdateTopUpSuccess = createSelector([topUpSelector], ({ updateTopUpSuccess }) => updateTopUpSuccess);

export const getUpdateTopUpFailed = createSelector([topUpSelector], ({ updateTopUpFailed }) => updateTopUpFailed);

export const getDeleteTopUpLoading = createSelector([topUpSelector], ({ deleteTopUpLoading }) => deleteTopUpLoading);

export const getDeleteTopUpSuccess = createSelector([topUpSelector], ({ deleteTopUpSuccess }) => deleteTopUpSuccess);

export const getDeleteTopUpFailed = createSelector([topUpSelector], ({ deleteTopUpFailed }) => deleteTopUpFailed);
