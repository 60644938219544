const TAX_ACTION_TYPES = {
  RESET_TAX_REDUCER: "tax/RESET_TAX_REDUCER",

  SET_TAXES: "tax/SET_TAXES",
  SET_TAX: "tax/SET_TAX",

  SET_IS_TAXES_HAS_MORE: "tax/SET_IS_TAXES_HAS_MORE",

  SET_FETCH_TAXES_SEARCH: "tax/SET_FETCH_TAXES_SEARCH",
  SET_FETCH_TAXES_SORT: "tax/SET_FETCH_TAXES_SORT",
  SET_FETCH_TAXES_KEY_BY: "tax/SET_FETCH_TAXES_KEY_BY",
  SET_FETCH_TAXES_PAGE: "tax/SET_FETCH_TAXES_PAGE",
  SET_FETCH_TAXES_PER_PAGE: "tax/SET_FETCH_TAXES_PER_PAGE",
  SET_FETCH_TAXES_INCLUDES: "tax/SET_FETCH_TAXES_INCLUDES",
  SET_FETCH_TAXES_FILTER_MARKET_ID: "tax/SET_FETCH_TAXES_FILTER_MARKET_ID",
  SET_FETCH_TAXES_FILTER_BRANCH_ID: "tax/SET_FETCH_TAXES_FILTER_BRANCH_ID",
  SET_FETCH_TAXES_LOADING: "tax/SET_FETCH_TAXES_LOADING",
  SET_FETCH_TAXES_SUCCESS: "tax/SET_FETCH_TAXES_SUCCESS",
  SET_FETCH_TAXES_FAILED: "tax/SET_FETCH_TAXES_FAILED",

  SET_FETCH_TAX_LOADING: "tax/SET_FETCH_TAX_LOADING",
  SET_FETCH_TAX_SUCCESS: "tax/SET_FETCH_TAX_SUCCESS",
  SET_FETCH_TAX_FAILED: "tax/SET_FETCH_TAX_FAILED",

  SET_CREATE_TAX_LOADING: "tax/SET_CREATE_TAX_LOADING",
  SET_CREATE_TAX_SUCCESS: "tax/SET_CREATE_TAX_SUCCESS",
  SET_CREATE_TAX_FAILED: "tax/SET_CREATE_TAX_FAILED",

  SET_UPDATE_TAX_LOADING: "tax/SET_UPDATE_TAX_LOADING",
  SET_UPDATE_TAX_SUCCESS: "tax/SET_UPDATE_TAX_SUCCESS",
  SET_UPDATE_TAX_FAILED: "tax/SET_UPDATE_TAX_FAILED",

  SET_DELETE_TAX_LOADING: "tax/SET_DELETE_TAX_LOADING",
  SET_DELETE_TAX_SUCCESS: "tax/SET_DELETE_TAX_SUCCESS",
  SET_DELETE_TAX_FAILED: "tax/SET_DELETE_TAX_FAILED",

  SET_SORT_TAXES_LOADING: "tax/SET_SORT_TAXES_LOADING",
  SET_SORT_TAXES_SUCCESS: "tax/SET_SORT_TAXES_SUCCESS",
  SET_SORT_TAXES_FAILED: "tax/SET_SORT_TAXES_FAILED",

  APPEND_TAXES: "tax/APPEND_TAXES",

  SET_IS_FETCH_TAXES_HITTED: "tax/SET_IS_FETCH_TAXES_HITTED",
  SET_IS_FETCH_TAX_HITTED: "tax/SET_IS_FETCH_TAX_HITTED",
  SET_IS_CREATE_TAX_HITTED: "tax/SET_IS_CREATE_TAX_HITTED",
  SET_IS_UPDATE_TAX_HITTED: "tax/SET_IS_UPDATE_TAX_HITTED",
  SET_IS_DELETE_TAX_HITTED: "tax/SET_IS_DELETE_TAX_HITTED",
  SET_IS_SORT_TAXES_HITTED: "tax/SET_IS_SORT_TAXES_HITTED",

  FETCH_TAXES_START: "tax/FETCH_TAXES_START",
  FETCH_TAX_START: "tax/FETCH_TAX_START",
  CREATE_TAX_START: "tax/CREATE_TAX_START",
  UPDATE_TAX_START: "tax/UPDATE_TAX_START",
  DELETE_TAX_START: "tax/DELETE_TAX_START",
  SORT_TAXES_START: "tax/SORT_TAXES_START",
};

export default TAX_ACTION_TYPES;
