const ADMIN_ACTION_TYPES = {
  RESET_ADMIN_REDUCER: "admin/RESET_ADMIN_REDUCER",

  SET_ADMINS: "admin/SET_ADMINS",
  SET_ADMIN: "admin/SET_ADMIN",

  SET_IS_ADMINS_HAS_MORE: "admin/SET_IS_ADMINS_HAS_MORE",

  SET_FETCH_ADMINS_SEARCH: "admin/SET_FETCH_ADMINS_SEARCH",
  SET_FETCH_ADMINS_PAGE: "admin/SET_FETCH_ADMINS_PAGE",
  SET_FETCH_ADMINS_PER_PAGE: "admin/SET_FETCH_ADMINS_PER_PAGE",
  SET_FETCH_ADMINS_INCLUDES: "admin/SET_FETCH_ADMINS_INCLUDES",
  SET_FETCH_ADMINS_FILTER_PERMISSION_ID: "admin/SET_FETCH_ADMINS_FILTER_PERMISSION_ID",
  SET_FETCH_ADMINS_LOADING: "admin/SET_FETCH_ADMINS_LOADING",
  SET_FETCH_ADMINS_SUCCESS: "admin/SET_FETCH_ADMINS_SUCCESS",
  SET_FETCH_ADMINS_FAILED: "admin/SET_FETCH_ADMINS_FAILED",

  SET_FETCH_ADMIN_LOADING: "admin/SET_FETCH_ADMIN_LOADING",
  SET_FETCH_ADMIN_SUCCESS: "admin/SET_FETCH_ADMIN_SUCCESS",
  SET_FETCH_ADMIN_FAILED: "admin/SET_FETCH_ADMIN_FAILED",

  SET_CREATE_ADMIN_LOADING: "admin/SET_CREATE_ADMIN_LOADING",
  SET_CREATE_ADMIN_SUCCESS: "admin/SET_CREATE_ADMIN_SUCCESS",
  SET_CREATE_ADMIN_FAILED: "admin/SET_CREATE_ADMIN_FAILED",

  SET_UPDATE_ADMIN_LOADING: "admin/SET_UPDATE_ADMIN_LOADING",
  SET_UPDATE_ADMIN_SUCCESS: "admin/SET_UPDATE_ADMIN_SUCCESS",
  SET_UPDATE_ADMIN_FAILED: "admin/SET_UPDATE_ADMIN_FAILED",

  SET_DELETE_ADMIN_LOADING: "admin/SET_DELETE_ADMIN_LOADING",
  SET_DELETE_ADMIN_SUCCESS: "admin/SET_DELETE_ADMIN_SUCCESS",
  SET_DELETE_ADMIN_FAILED: "admin/SET_DELETE_ADMIN_FAILED",

  APPEND_ADMINS: "admin/APPEND_ADMINS",

  SET_IS_FETCH_ADMINS_HITTED: "admin/SET_IS_FETCH_ADMINS_HITTED",
  SET_IS_FETCH_ADMIN_HITTED: "admin/SET_IS_FETCH_ADMIN_HITTED",
  SET_IS_CREATE_ADMIN_HITTED: "admin/SET_IS_CREATE_ADMIN_HITTED",
  SET_IS_UPDATE_ADMIN_HITTED: "admin/SET_IS_UPDATE_ADMIN_HITTED",
  SET_IS_DELETE_ADMIN_HITTED: "admin/SET_IS_DELETE_ADMIN_HITTED",

  FETCH_ADMINS_START: "admin/FETCH_ADMINS_START",
  FETCH_ADMIN_START: "admin/FETCH_ADMIN_START",
  CREATE_ADMIN_START: "admin/CREATE_ADMIN_START",
  UPDATE_ADMIN_START: "admin/UPDATE_ADMIN_START",
  DELETE_ADMIN_START: "admin/DELETE_ADMIN_START",
};

export default ADMIN_ACTION_TYPES;
