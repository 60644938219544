import apiService from "./api";

export const getAdvertisements = async (parameters) => (await apiService.get("/advertisement", parameters)).data;

export const getAdvertisement = async (id) => (await apiService.get(`/advertisement/${id}`)).data;

export const createAdvertisement = async (request) => (await apiService.post("/advertisement", request)).data;

export const updateAdvertisement = async (id, request) => (await apiService.post(`/advertisement/${id}`, request)).data;

export const deleteAdvertisement = async (id) => (await apiService.delete(`/advertisement/${id}`)).data;
