import apiService from "./api";

export const getPermissionAdmins = async (parameters) =>
  (await apiService.get("/v2/permissions/admins", parameters)).data;
export const getPermissionAdmin = async (permissionId) =>
  (await apiService.get(`/v2/permissions/admins/${permissionId}`)).data;
export const createPermissionAdmin = async (request) =>
  (await apiService.post("/v2/permissions/admins", request)).data;
export const updatePermissionAdmin = async (permissionId, request) =>
  (await apiService.post(`/v2/permissions/admins/${permissionId}`, request))
    .data;
export const deletePermissionAdmin = async (permissionId) =>
  (await apiService.delete(`/v2/permissions/admins/${permissionId}`)).data;
