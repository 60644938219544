export const PAYMENT_DEVICE_INCLUDES = {
  MARKET: "market",
  BRANCH: "branch",
  USERS: "users",
};

export const PAYMENT_DEVICE_TARGETS = {
  ALL_PAGES: "ALL_PAGES",
  CUSTOMER_SCREEN: "CUSTOMER_SCREEN",
  ORDER: "ORDER",
};

export const PAYMENT_DEVICE_COMPANIES = {
  NEAR_PAY: "NEAR_PAY",
  DIGITAL_PAY: "DIGITAL_PAY",
};
