import apiService from "./api";

export const getOrders = async (parameters) =>
  (await apiService.get("/v2/orders", parameters)).data;
export const getOrder = async (orderId) =>
  (await apiService.get(`/v2/orders/${orderId}`)).data;
export const calculateOrder = async (request, params) =>
  (await apiService.post("/v2/orders/calc", request, params)).data;
export const createOrder = async (request, params) =>
  (await apiService.post("/v2/orders", request, params)).data;
export const updateOrder = async (orderId, request, params) =>
  (await apiService.post(`/v2/orders/${orderId}`, request, params)).data;
export const deleteOrder = async (orderId) =>
  (await apiService.delete(`/order/${orderId}`)).data;
export const checkoutOrder = async (orderId, request, params) =>
  (await apiService.post(`/v2/orders/${orderId}/checkout`, request, params))
    .data;
export const returnOrder = async (orderId, request, params) =>
  (await apiService.post(`/v2/orders/${orderId}/return`, request, params)).data;
