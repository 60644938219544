import { takeLatest, put, all, call, select } from "redux-saga/effects";

import TOP_UP_ACTION_TYPES from "./top-up.type";

import {
  appendTopUps,
  setCreateTopUpFailed,
  setCreateTopUpLoading,
  setCreateTopUpSuccess,
  setDeleteTopUpFailed,
  setDeleteTopUpLoading,
  setDeleteTopUpSuccess,
  setFetchTopUpFailed,
  setFetchTopUpLoading,
  setFetchTopUpsFailed,
  setFetchTopUpsLoading,
  setFetchTopUpsPage,
  setFetchTopUpsSuccess,
  setFetchTopUpSuccess,
  setIsTopUpsHasMore,
  setTopUp,
  setTopUps,
  setUpdateTopUpFailed,
  setUpdateTopUpLoading,
  setUpdateTopUpSuccess,
} from "./top-up.action";
import {
  getFetchTopUpsFilterCreatedBy,
  getFetchTopUpsFilterMarketId,
  getFetchTopUpsIncludes,
  getFetchTopUpsPage,
  getFetchTopUpsPerPage,
  getFetchTopUpsSearch,
} from "./top-up.selector";

import { getTopUps, getTopUp, createTopUp, updateTopUp, deleteTopUp } from "../../api/top-up.api";

export function* _getTopUps() {
  try {
    yield put(setFetchTopUpsLoading(true));

    const search = yield select(getFetchTopUpsSearch);
    const page = yield select(getFetchTopUpsPage);
    const per_page = yield select(getFetchTopUpsPerPage);
    const includes = yield select(getFetchTopUpsIncludes);
    const market_id = yield select(getFetchTopUpsFilterMarketId);
    const created_by = yield select(getFetchTopUpsFilterCreatedBy);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: { market_id, created_by },
    };

    const {
      meta: { message },
      data: { data: topUps },
    } = yield call(getTopUps, parameters);

    yield put(setIsTopUpsHasMore(topUps.length > 0));

    if (page > 1) {
      yield put(appendTopUps(topUps));
    } else {
      yield put(setTopUps(topUps));
    }

    yield put(setFetchTopUpsSuccess(message));
    yield put(setFetchTopUpsLoading(false));
  } catch (error) {
    yield put(setFetchTopUpsFailed(error));
    yield put(setFetchTopUpsLoading(false));
  }
}

export function* _getTopUp({ payload: id }) {
  try {
    yield put(setFetchTopUpLoading(true));

    const {
      meta: { message },
      data: topUp,
    } = yield call(getTopUp, id);

    yield put(setTopUp(topUp));

    yield put(setFetchTopUpSuccess(message));
    yield put(setFetchTopUpLoading(false));
  } catch (error) {
    yield put(setFetchTopUpFailed(error));
    yield put(setFetchTopUpLoading(false));
  }
}

export function* _createTopUp({ payload: request }) {
  try {
    yield put(setCreateTopUpLoading(true));

    const {
      meta: { message },
      data: topUp,
    } = yield call(createTopUp, request);

    yield put(setTopUp(topUp));

    yield put(setCreateTopUpSuccess(message));
    yield put(setCreateTopUpLoading(false));

    // yield put(setFetchTopUpsPage(1));
    // yield call(_getTopUps);
  } catch (error) {
    yield put(setCreateTopUpFailed(error));
    yield put(setCreateTopUpLoading(false));
  }
}

export function* _updateTopUp({ payload: { id, request } }) {
  try {
    yield put(setUpdateTopUpLoading(true));

    const {
      meta: { message },
    } = yield call(updateTopUp, id, request);

    yield put(setUpdateTopUpSuccess(message));
    yield put(setUpdateTopUpLoading(false));

    // yield put(setFetchTopUpsPage(1));
    // yield call(_getTopUps);
  } catch (error) {
    yield put(setUpdateTopUpFailed(error));
    yield put(setUpdateTopUpLoading(false));
  }
}

export function* _deleteTopUp({ payload: id }) {
  try {
    yield put(setDeleteTopUpLoading(true));

    const {
      meta: { message },
    } = yield call(deleteTopUp, id);

    yield put(setDeleteTopUpSuccess(message));
    yield put(setDeleteTopUpLoading(false));

    // yield put(setFetchTopUpsPage(1));
    // yield call(_getTopUps);
  } catch (error) {
    yield put(setDeleteTopUpFailed(error));
    yield put(setDeleteTopUpLoading(false));
  }
}

export function* onFetchTopUpsStart() {
  yield takeLatest(TOP_UP_ACTION_TYPES.FETCH_TOP_UPS_START, _getTopUps);
}

export function* onFetchTopUpStart() {
  yield takeLatest(TOP_UP_ACTION_TYPES.FETCH_TOP_UP_START, _getTopUp);
}

export function* onCreateTopUpStart() {
  yield takeLatest(TOP_UP_ACTION_TYPES.CREATE_TOP_UP_START, _createTopUp);
}

export function* onUpdateTopUpStart() {
  yield takeLatest(TOP_UP_ACTION_TYPES.UPDATE_TOP_UP_START, _updateTopUp);
}

export function* onDeleteTopUpStart() {
  yield takeLatest(TOP_UP_ACTION_TYPES.DELETE_TOP_UP_START, _deleteTopUp);
}

export function* topUpSaga() {
  yield all([
    call(onFetchTopUpsStart),
    call(onFetchTopUpStart),
    call(onCreateTopUpStart),
    call(onUpdateTopUpStart),
    call(onDeleteTopUpStart),
  ]);
}
