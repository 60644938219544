import { createSelector } from "reselect";

const settingSelector = ({ setting }) => setting;

export const getSettings = createSelector(
  [settingSelector],
  ({ settings }) => settings
);
export const getSetting = createSelector(
  [settingSelector],
  ({ setting }) => setting
);

export const getIsSettingsHasMore = createSelector(
  [settingSelector],
  ({ isSettingsHasMore }) => isSettingsHasMore
);

export const getFetchSettingsSearch = createSelector(
  [settingSelector],
  ({ fetchSettingsSearch }) => fetchSettingsSearch
);
export const getFetchSettingsSort = createSelector(
  [settingSelector],
  ({ fetchSettingsSort }) => fetchSettingsSort
);
export const getFetchSettingsKeyBy = createSelector(
  [settingSelector],
  ({ fetchSettingsKeyBy }) => fetchSettingsKeyBy
);
export const getFetchSettingsPage = createSelector(
  [settingSelector],
  ({ fetchSettingsPage }) => fetchSettingsPage
);
export const getFetchSettingsPerPage = createSelector(
  [settingSelector],
  ({ fetchSettingsPerPage }) => fetchSettingsPerPage
);
export const getFetchSettingsFilterKeys = createSelector(
  [settingSelector],
  ({ fetchSettingsFilterKeys }) => fetchSettingsFilterKeys
);
export const getFetchSettingsLoading = createSelector(
  [settingSelector],
  ({ fetchSettingsLoading }) => fetchSettingsLoading
);
export const getFetchSettingsSuccess = createSelector(
  [settingSelector],
  ({ fetchSettingsSuccess }) => fetchSettingsSuccess
);
export const getFetchSettingsFailed = createSelector(
  [settingSelector],
  ({ fetchSettingsFailed }) => fetchSettingsFailed
);

export const getFetchSettingLoading = createSelector(
  [settingSelector],
  ({ fetchSettingLoading }) => fetchSettingLoading
);
export const getFetchSettingSuccess = createSelector(
  [settingSelector],
  ({ fetchSettingSuccess }) => fetchSettingSuccess
);
export const getFetchSettingFailed = createSelector(
  [settingSelector],
  ({ fetchSettingFailed }) => fetchSettingFailed
);

export const getCreateOrUpdateSettingLoading = createSelector(
  [settingSelector],
  ({ createOrUpdateSettingLoading }) => createOrUpdateSettingLoading
);
export const getCreateOrUpdateSettingSuccess = createSelector(
  [settingSelector],
  ({ createOrUpdateSettingSuccess }) => createOrUpdateSettingSuccess
);
export const getCreateOrUpdateSettingFailed = createSelector(
  [settingSelector],
  ({ createOrUpdateSettingFailed }) => createOrUpdateSettingFailed
);

export const getIsFetchSettingsHitted = createSelector(
  [settingSelector],
  ({ isFetchSettingsHitted }) => isFetchSettingsHitted
);
export const getIsFetchSettingHitted = createSelector(
  [settingSelector],
  ({ isFetchSettingHitted }) => isFetchSettingHitted
);
export const getIsCreateOrUpdateSettingHitted = createSelector(
  [settingSelector],
  ({ isCreateOrUpdateSettingHitted }) => isCreateOrUpdateSettingHitted
);
