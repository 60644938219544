import { takeLatest, put, all, call, select } from "redux-saga/effects";

import ANNOUNCEMENT_ACTION_TYPES from "./announcement.type";

import {
  appendAnnouncements,
  setAnnouncement,
  setAnnouncements,
  setCreateAnnouncementFailed,
  setCreateAnnouncementLoading,
  setCreateAnnouncementSuccess,
  setDeleteAnnouncementFailed,
  setDeleteAnnouncementLoading,
  setDeleteAnnouncementSuccess,
  setFetchAnnouncementFailed,
  setFetchAnnouncementLoading,
  setFetchAnnouncementSuccess,
  setFetchAnnouncementsFailed,
  setFetchAnnouncementsLoading,
  setFetchAnnouncementsPage,
  setFetchAnnouncementsSuccess,
  setIsAnnouncementsHasMore,
  setIsCreateAnnouncementHitted,
  setIsDeleteAnnouncementHitted,
  setIsFetchAnnouncementHitted,
  setIsFetchAnnouncementsHitted,
  setIsReadAnnouncementHitted,
  setIsUpdateAnnouncementHitted,
  setReadAnnouncementFailed,
  setReadAnnouncementLoading,
  setReadAnnouncementSuccess,
  setUpdateAnnouncementFailed,
  setUpdateAnnouncementLoading,
  setUpdateAnnouncementSuccess,
} from "./announcement.action";
import {
  getFetchAnnouncementsFilterDisplayAt,
  getFetchAnnouncementsFilterIsRead,
  getFetchAnnouncementsFilterRole,
  getFetchAnnouncementsFilterUserId,
  getFetchAnnouncementsPage,
  getFetchAnnouncementsPerPage,
  getFetchAnnouncementsSearch,
  getFetchAnnouncementsSort,
  getIsFetchAnnouncementsHitted,
} from "./announcement.selector";

import {
  createAnnouncement,
  deleteAnnouncement,
  getAnnouncement,
  getAnnouncements,
  readAnnouncement,
  updateAnnouncement,
} from "../../api/announcement.api";

export function* _getAnnouncements() {
  try {
    yield put(setFetchAnnouncementsLoading(true));

    const search = yield select(getFetchAnnouncementsSearch);
    const sort = yield select(getFetchAnnouncementsSort);
    const page = yield select(getFetchAnnouncementsPage);
    const per_page = yield select(getFetchAnnouncementsPerPage);
    const display_at = yield select(getFetchAnnouncementsFilterDisplayAt);
    const user_id = yield select(getFetchAnnouncementsFilterUserId);
    const role = yield select(getFetchAnnouncementsFilterRole);
    const is_read = yield select(getFetchAnnouncementsFilterIsRead);

    const parameters = {
      search,
      sort,
      page,
      per_page,
      filter: {
        display_at,
        user_id,
        role,
        is_read,
      },
    };

    const {
      meta: { message },
      data: { data: announcements },
    } = yield call(getAnnouncements, parameters);

    yield put(setIsFetchAnnouncementsHitted(true));
    yield put(setIsAnnouncementsHasMore(announcements.length > 0));

    if (page > 1) {
      yield put(appendAnnouncements(announcements));
    } else {
      yield put(setAnnouncements(announcements));
    }

    yield put(setFetchAnnouncementsSuccess(message));
    yield put(setFetchAnnouncementsLoading(false));
  } catch (error) {
    yield put(setFetchAnnouncementsFailed(error));
    yield put(setFetchAnnouncementsLoading(false));
  }
}

export function* _getAnnouncement({ payload: id }) {
  try {
    yield put(setFetchAnnouncementLoading(true));

    const {
      meta: { message },
      data: announcement,
    } = yield call(getAnnouncement, id);

    yield put(setIsFetchAnnouncementHitted(true));
    yield put(setAnnouncement(announcement));

    yield put(setFetchAnnouncementSuccess(message));
    yield put(setFetchAnnouncementLoading(false));
  } catch (error) {
    yield put(setFetchAnnouncementFailed(error));
    yield put(setFetchAnnouncementLoading(false));
  }
}

export function* _createAnnouncement({ payload: request }) {
  try {
    yield put(setCreateAnnouncementLoading(true));

    const {
      meta: { message },
    } = yield call(createAnnouncement, request);

    yield put(setIsCreateAnnouncementHitted(true));

    const isFetchAnnouncementsHitted = yield select(getIsFetchAnnouncementsHitted);

    if (isFetchAnnouncementsHitted) {
      yield put(setFetchAnnouncementsPage(1));
      yield call(_getAnnouncements);
    }

    yield put(setCreateAnnouncementSuccess(message));
    yield put(setCreateAnnouncementLoading(false));
  } catch (error) {
    yield put(setCreateAnnouncementFailed(error));
    yield put(setCreateAnnouncementLoading(false));
  }
}

export function* _updateAnnouncement({ payload: { id, request } }) {
  try {
    yield put(setUpdateAnnouncementLoading(true));

    const {
      meta: { message },
    } = yield call(updateAnnouncement, id, request);

    yield put(setIsUpdateAnnouncementHitted(true));

    const isFetchAnnouncementsHitted = yield select(getIsFetchAnnouncementsHitted);

    if (isFetchAnnouncementsHitted) {
      yield put(setFetchAnnouncementsPage(1));
      yield call(_getAnnouncements);
    }

    yield put(setUpdateAnnouncementSuccess(message));
    yield put(setUpdateAnnouncementLoading(false));
  } catch (error) {
    yield put(setUpdateAnnouncementFailed(error));
    yield put(setUpdateAnnouncementLoading(false));
  }
}

export function* _deleteAnnouncement({ payload: id }) {
  try {
    yield put(setDeleteAnnouncementLoading(true));

    const {
      meta: { message },
    } = yield call(deleteAnnouncement, id);

    yield put(setIsDeleteAnnouncementHitted(true));

    const isFetchAnnouncementsHitted = yield select(getIsFetchAnnouncementsHitted);

    if (isFetchAnnouncementsHitted) {
      yield put(setFetchAnnouncementsPage(1));
      yield call(_getAnnouncements);
    }

    yield put(setDeleteAnnouncementSuccess(message));
    yield put(setDeleteAnnouncementLoading(false));
  } catch (error) {
    yield put(setDeleteAnnouncementFailed(error));
    yield put(setDeleteAnnouncementLoading(false));
  }
}

export function* _readAnnouncement({ payload: request }) {
  try {
    yield put(setReadAnnouncementLoading(true));

    const {
      meta: { message },
    } = yield call(readAnnouncement, request);

    yield put(setIsReadAnnouncementHitted(true));

    yield put(setReadAnnouncementSuccess(message));
    yield put(setReadAnnouncementLoading(false));
  } catch (error) {
    yield put(setReadAnnouncementFailed(error));
    yield put(setReadAnnouncementLoading(false));
  }
}

export function* onFetchAnnouncementsStart() {
  yield takeLatest(ANNOUNCEMENT_ACTION_TYPES.FETCH_ANNOUNCEMENTS_START, _getAnnouncements);
}

export function* onFetchAnnouncementStart() {
  yield takeLatest(ANNOUNCEMENT_ACTION_TYPES.FETCH_ANNOUNCEMENT_START, _getAnnouncement);
}

export function* onCreateAnnouncementStart() {
  yield takeLatest(ANNOUNCEMENT_ACTION_TYPES.CREATE_ANNOUNCEMENT_START, _createAnnouncement);
}

export function* onUpdateAnnouncementStart() {
  yield takeLatest(ANNOUNCEMENT_ACTION_TYPES.UPDATE_ANNOUNCEMENT_START, _updateAnnouncement);
}

export function* onDeleteAnnouncementStart() {
  yield takeLatest(ANNOUNCEMENT_ACTION_TYPES.DELETE_ANNOUNCEMENT_START, _deleteAnnouncement);
}

export function* onReadAnnouncementStart() {
  yield takeLatest(ANNOUNCEMENT_ACTION_TYPES.READ_ANNOUNCEMENT_START, _readAnnouncement);
}

export function* announcementSaga() {
  yield all([
    call(onFetchAnnouncementsStart),
    call(onFetchAnnouncementStart),
    call(onCreateAnnouncementStart),
    call(onUpdateAnnouncementStart),
    call(onDeleteAnnouncementStart),
    call(onReadAnnouncementStart),
  ]);
}
