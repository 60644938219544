import apiService from "./api";

export const getRestockRequests = async (parameters) => (await apiService.get("/restock-request", parameters)).data;

export const getRestockRequest = async (id) => (await apiService.get(`/restock-request/${id}`)).data;

export const createRestockRequest = async (request) => (await apiService.post("/restock-request", request)).data;

export const updateRestockRequest = async (id, request) =>
  (await apiService.post(`/restock-request/${id}`, request)).data;

export const deleteRestockRequest = async (id) => (await apiService.delete(`/restock-request/${id}`)).data;
