import { getRemovedDuplicates } from "../../utils/formatter.utils";

import PRODUCT_CATEGORY_ACTION_TYPES from "./product-category.type";

export const PRODUCT_CATEGORY_INITIAL_STATE = {
  productCategories: [],
  selectProductCategories: {},
  productProductCategories: [],
  orderProductCategories: [],
  productCategory: null,

  isProductCategoriesHasMore: true,
  isSelectProductCategoriesHasMore: true,
  isProductProductCategoriesHasMore: true,
  isOrderProductCategoriesHasMore: true,

  fetchProductCategoriesSearch: null,
  fetchProductCategoriesSort: null,
  fetchProductCategoriesKeyBy: null,
  fetchProductCategoriesPage: 1,
  fetchProductCategoriesPerPage: null,
  fetchProductCategoriesIncludes: null,
  fetchProductCategoriesFilterSectorId: null,
  fetchProductCategoriesFilterMarketId: null,
  fetchProductCategoriesFilterBranchId: null,
  fetchProductCategoriesLoading: false,
  fetchProductCategoriesSuccess: null,
  fetchProductCategoriesFailed: null,

  fetchSelectProductCategoriesSearch: null,
  fetchSelectProductCategoriesSort: null,
  fetchSelectProductCategoriesKeyBy: null,
  fetchSelectProductCategoriesPage: 1,
  fetchSelectProductCategoriesPerPage: null,
  fetchSelectProductCategoriesIncludes: null,
  fetchSelectProductCategoriesFilterSectorId: null,
  fetchSelectProductCategoriesFilterMarketId: null,
  fetchSelectProductCategoriesFilterBranchId: null,
  fetchSelectProductCategoriesFilterProductCategoriesIds: null,
  fetchSelectProductCategoriesLoading: false,
  fetchSelectProductCategoriesSuccess: null,
  fetchSelectProductCategoriesFailed: null,

  fetchProductProductCategoriesSearch: null,
  fetchProductProductCategoriesSort: null,
  fetchProductProductCategoriesKeyBy: null,
  fetchProductProductCategoriesPage: 1,
  fetchProductProductCategoriesPerPage: null,
  fetchProductProductCategoriesIncludes: null,
  fetchProductProductCategoriesFilterSectorId: null,
  fetchProductProductCategoriesFilterMarketId: null,
  fetchProductProductCategoriesFilterBranchId: null,
  fetchProductProductCategoriesLoading: false,
  fetchProductProductCategoriesSuccess: null,
  fetchProductProductCategoriesFailed: null,

  fetchOrderProductCategoriesSearch: null,
  fetchOrderProductCategoriesSort: null,
  fetchOrderProductCategoriesKeyBy: null,
  fetchOrderProductCategoriesPage: 1,
  fetchOrderProductCategoriesPerPage: null,
  fetchOrderProductCategoriesIncludes: null,
  fetchOrderProductCategoriesFilterSectorId: null,
  fetchOrderProductCategoriesFilterMarketId: null,
  fetchOrderProductCategoriesFilterBranchId: null,
  fetchOrderProductCategoriesLoading: false,
  fetchOrderProductCategoriesSuccess: null,
  fetchOrderProductCategoriesFailed: null,

  appendSelectProductCategoriesSearch: null,
  appendSelectProductCategoriesSort: null,
  appendSelectProductCategoriesKeyBy: null,
  appendSelectProductCategoriesPage: 1,
  appendSelectProductCategoriesPerPage: null,
  appendSelectProductCategoriesIncludes: null,
  appendSelectProductCategoriesFilterSectorId: null,
  appendSelectProductCategoriesFilterMarketId: null,
  appendSelectProductCategoriesFilterBranchId: null,
  appendSelectProductCategoriesFilterProductCategoriesIds: null,
  appendSelectProductCategoriesLoading: false,
  appendSelectProductCategoriesSuccess: null,
  appendSelectProductCategoriesFailed: null,

  fetchProductCategoryLoading: false,
  fetchProductCategorySuccess: null,
  fetchProductCategoryFailed: null,

  createProductCategoryLoading: false,
  createProductCategorySuccess: null,
  createProductCategoryFailed: null,

  updateProductCategoryLoading: false,
  updateProductCategorySuccess: null,
  updateProductCategoryFailed: null,

  deleteProductCategoryLoading: false,
  deleteProductCategorySuccess: null,
  deleteProductCategoryFailed: null,

  isFetchProductCategoriesHitted: false,
  isFetchSelectProductCategoriesHitted: false,
  isFetchProductProductCategoriesHitted: false,
  isFetchOrderProductCategoriesHitted: false,
  isAppendSelectProductCategoriesHitted: false,
  isFetchProductCategoryHitted: false,
  isCreateProductCategoryHitted: false,
  isUpdateProductCategoryHitted: false,
  isDeleteProductCategoryHitted: false,
};

export const productCategoryReducer = (
  state = PRODUCT_CATEGORY_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_PRODUCT_CATEGORIES:
      return { ...state, productCategories: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_SELECT_PRODUCT_CATEGORIES:
      return { ...state, selectProductCategories: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_PRODUCT_PRODUCT_CATEGORIES:
      return { ...state, productProductCategories: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_ORDER_PRODUCT_CATEGORIES:
      return { ...state, orderProductCategories: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_PRODUCT_CATEGORY:
      return { ...state, productCategory: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_PRODUCT_CATEGORIES_HAS_MORE:
      return { ...state, isProductCategoriesHasMore: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_SELECT_PRODUCT_CATEGORIES_HAS_MORE:
      return { ...state, isSelectProductCategoriesHasMore: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_PRODUCT_PRODUCT_CATEGORIES_HAS_MORE:
      return { ...state, isProductProductCategoriesHasMore: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_ORDER_PRODUCT_CATEGORIES_HAS_MORE:
      return { ...state, isOrderProductCategoriesHasMore: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SEARCH:
      return { ...state, fetchProductCategoriesSearch: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SORT:
      return { ...state, fetchProductCategoriesSort: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_KEY_BY:
      return { ...state, fetchProductCategoriesKeyBy: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_PAGE:
      return { ...state, fetchProductCategoriesPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_PER_PAGE:
      return { ...state, fetchProductCategoriesPerPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_INCLUDES:
      return { ...state, fetchProductCategoriesIncludes: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
      return { ...state, fetchProductCategoriesFilterSectorId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
      return { ...state, fetchProductCategoriesFilterMarketId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
      return { ...state, fetchProductCategoriesFilterBranchId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_LOADING:
      return { ...state, fetchProductCategoriesLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, fetchProductCategoriesSuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FAILED:
      return { ...state, fetchProductCategoriesFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_SEARCH:
      return { ...state, fetchSelectProductCategoriesSearch: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_SORT:
      return { ...state, fetchSelectProductCategoriesSort: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_KEY_BY:
      return { ...state, fetchSelectProductCategoriesKeyBy: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_PAGE:
      return { ...state, fetchSelectProductCategoriesPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_PER_PAGE:
      return { ...state, fetchSelectProductCategoriesPerPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_INCLUDES:
      return { ...state, fetchSelectProductCategoriesIncludes: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
      return { ...state, fetchSelectProductCategoriesFilterSectorId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
      return { ...state, fetchSelectProductCategoriesFilterMarketId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
      return { ...state, fetchSelectProductCategoriesFilterBranchId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_PRODUCT_CATEGORIES_IDS:
      return {
        ...state,
        fetchSelectProductCategoriesFilterProductCategoriesIds: payload,
      };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_LOADING:
      return { ...state, fetchSelectProductCategoriesLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, fetchSelectProductCategoriesSuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FAILED:
      return { ...state, fetchSelectProductCategoriesFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_PRODUCT_CATEGORIES_SEARCH:
      return { ...state, fetchProductProductCategoriesSearch: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_PRODUCT_CATEGORIES_SORT:
      return { ...state, fetchProductProductCategoriesSort: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_PRODUCT_CATEGORIES_KEY_BY:
      return { ...state, fetchProductProductCategoriesKeyBy: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_PRODUCT_CATEGORIES_PAGE:
      return { ...state, fetchProductProductCategoriesPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_PRODUCT_CATEGORIES_PER_PAGE:
      return { ...state, fetchProductProductCategoriesPerPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_PRODUCT_CATEGORIES_INCLUDES:
      return { ...state, fetchProductProductCategoriesIncludes: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
      return { ...state, fetchProductProductCategoriesFilterSectorId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
      return { ...state, fetchProductProductCategoriesFilterMarketId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
      return { ...state, fetchProductProductCategoriesFilterBranchId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_PRODUCT_CATEGORIES_LOADING:
      return { ...state, fetchProductProductCategoriesLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, fetchProductProductCategoriesSuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_PRODUCT_CATEGORIES_FAILED:
      return { ...state, fetchProductProductCategoriesFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_CATEGORIES_SEARCH:
      return { ...state, fetchOrderProductCategoriesSearch: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_CATEGORIES_SORT:
      return { ...state, fetchOrderProductCategoriesSort: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_CATEGORIES_KEY_BY:
      return { ...state, fetchOrderProductCategoriesKeyBy: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_CATEGORIES_PAGE:
      return { ...state, fetchOrderProductCategoriesPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_CATEGORIES_PER_PAGE:
      return { ...state, fetchOrderProductCategoriesPerPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_CATEGORIES_INCLUDES:
      return { ...state, fetchOrderProductCategoriesIncludes: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
      return { ...state, fetchOrderProductCategoriesFilterSectorId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
      return { ...state, fetchOrderProductCategoriesFilterMarketId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
      return { ...state, fetchOrderProductCategoriesFilterBranchId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_CATEGORIES_LOADING:
      return { ...state, fetchOrderProductCategoriesLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, fetchOrderProductCategoriesSuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_ORDER_PRODUCT_CATEGORIES_FAILED:
      return { ...state, fetchOrderProductCategoriesFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_SEARCH:
      return { ...state, appendSelectProductCategoriesSearch: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_SORT:
      return { ...state, appendSelectProductCategoriesSort: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_KEY_BY:
      return { ...state, appendSelectProductCategoriesKeyBy: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_PAGE:
      return { ...state, appendSelectProductCategoriesPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_PER_PAGE:
      return { ...state, appendSelectProductCategoriesPerPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_INCLUDES:
      return { ...state, appendSelectProductCategoriesIncludes: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
      return { ...state, appendSelectProductCategoriesFilterSectorId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
      return { ...state, appendSelectProductCategoriesFilterMarketId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
      return { ...state, appendSelectProductCategoriesFilterBranchId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_PRODUCT_CATEGORIES_IDS:
      return {
        ...state,
        appendSelectProductCategoriesFilterProductCategoriesIds: payload,
      };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_LOADING:
      return { ...state, appendSelectProductCategoriesLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, appendSelectProductCategoriesSuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FAILED:
      return { ...state, appendSelectProductCategoriesFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_LOADING:
      return { ...state, fetchProductCategoryLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, fetchProductCategorySuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_FAILED:
      return { ...state, fetchProductCategoryFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_CREATE_PRODUCT_CATEGORY_LOADING:
      return { ...state, createProductCategoryLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_CREATE_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, createProductCategorySuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_CREATE_PRODUCT_CATEGORY_FAILED:
      return { ...state, createProductCategoryFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_UPDATE_PRODUCT_CATEGORY_LOADING:
      return { ...state, updateProductCategoryLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_UPDATE_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, updateProductCategorySuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_UPDATE_PRODUCT_CATEGORY_FAILED:
      return { ...state, updateProductCategoryFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_DELETE_PRODUCT_CATEGORY_LOADING:
      return { ...state, deleteProductCategoryLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_DELETE_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, deleteProductCategorySuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_DELETE_PRODUCT_CATEGORY_FAILED:
      return { ...state, deleteProductCategoryFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.APPEND_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategories: [...state.productCategories, ...payload],
      };
    case PRODUCT_CATEGORY_ACTION_TYPES.APPEND_SELECT_PRODUCT_CATEGORIES:
      return {
        ...state,
        selectProductCategories: {
          ...state.selectProductCategories,
          ...payload,
        },
      };
    case PRODUCT_CATEGORY_ACTION_TYPES.APPEND_PRODUCT_PRODUCT_CATEGORIES:
      return {
        ...state,
        productProductCategories: [
          ...state.productProductCategories,
          ...payload,
        ],
      };
    case PRODUCT_CATEGORY_ACTION_TYPES.APPEND_ORDER_PRODUCT_CATEGORIES:
      return {
        ...state,
        orderProductCategories: [...state.orderProductCategories, ...payload],
      };

    case PRODUCT_CATEGORY_ACTION_TYPES.IS_FETCH_PRODUCT_CATEGORIES_HITTED:
      return { ...state, isFetchProductCategoriesHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_FETCH_SELECT_PRODUCT_CATEGORIES_HITTED:
      return { ...state, isFetchSelectProductCategoriesHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_FETCH_PRODUCT_PRODUCT_CATEGORIES_HITTED:
      return { ...state, isFetchProductProductCategoriesHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_FETCH_ORDER_PRODUCT_CATEGORIES_HITTED:
      return { ...state, isFetchOrderProductCategoriesHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_APPEND_SELECT_PRODUCT_CATEGORIES_HITTED:
      return { ...state, isAppendSelectProductCategoriesHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_FETCH_PRODUCT_CATEGORY_HITTED:
      return { ...state, isFetchProductCategoryHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_CREATE_PRODUCT_CATEGORY_HITTED:
      return { ...state, isCreateProductCategoryHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_UPDATE_PRODUCT_CATEGORY_HITTED:
      return { ...state, isUpdateProductCategoryHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_DELETE_PRODUCT_CATEGORY_HITTED:
      return { ...state, isDeleteProductCategoryHitted: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.RESET_PRODUCT_CATEGORY_REDUCER:
      return PRODUCT_CATEGORY_INITIAL_STATE;
    default:
      return state;
  }
};
