import { createSelector } from "reselect";

const authenticationSelector = ({ authentication }) => authentication;

export const getUser = createSelector(
  [authenticationSelector],
  ({ user }) => user
);

export const getAuthCheckLoading = createSelector(
  [authenticationSelector],
  ({ authCheckLoading }) => authCheckLoading
);
export const getAuthCheckSuccess = createSelector(
  [authenticationSelector],
  ({ authCheckSuccess }) => authCheckSuccess
);
export const getAuthCheckFailed = createSelector(
  [authenticationSelector],
  ({ authCheckFailed }) => authCheckFailed
);

export const getFetchMeLoading = createSelector(
  [authenticationSelector],
  ({ fetchMeLoading }) => fetchMeLoading
);
export const getFetchMeSuccess = createSelector(
  [authenticationSelector],
  ({ fetchMeSuccess }) => fetchMeSuccess
);
export const getFetchMeFailed = createSelector(
  [authenticationSelector],
  ({ fetchMeFailed }) => fetchMeFailed
);

export const getUpdateMeLoading = createSelector(
  [authenticationSelector],
  ({ updateMeLoading }) => updateMeLoading
);
export const getUpdateMeSuccess = createSelector(
  [authenticationSelector],
  ({ updateMeSuccess }) => updateMeSuccess
);
export const getUpdateMeFailed = createSelector(
  [authenticationSelector],
  ({ updateMeFailed }) => updateMeFailed
);

export const getSignInLoading = createSelector(
  [authenticationSelector],
  ({ signInLoading }) => signInLoading
);
export const getSignInSuccess = createSelector(
  [authenticationSelector],
  ({ signInSuccess }) => signInSuccess
);
export const getSignInFailed = createSelector(
  [authenticationSelector],
  ({ signInFailed }) => signInFailed
);

export const getSignUpLoading = createSelector(
  [authenticationSelector],
  ({ signUpLoading }) => signUpLoading
);
export const getSignUpSuccess = createSelector(
  [authenticationSelector],
  ({ signUpSuccess }) => signUpSuccess
);
export const getSignUpFailed = createSelector(
  [authenticationSelector],
  ({ signUpFailed }) => signUpFailed
);

export const getSignOutLoading = createSelector(
  [authenticationSelector],
  ({ signOutLoading }) => signOutLoading
);
export const getSignOutSuccess = createSelector(
  [authenticationSelector],
  ({ signOutSuccess }) => signOutSuccess
);
export const getSignOutFailed = createSelector(
  [authenticationSelector],
  ({ signOutFailed }) => signOutFailed
);

export const getIsAuthCheckHitted = createSelector(
  [authenticationSelector],
  ({ isAuthCheckHitted }) => isAuthCheckHitted
);
export const getIsFetchMeHitted = createSelector(
  [authenticationSelector],
  ({ isFetchMeHitted }) => isFetchMeHitted
);
export const getIsUpdateMeHitted = createSelector(
  [authenticationSelector],
  ({ isUpdateMeHitted }) => isUpdateMeHitted
);
export const getIsSignInHitted = createSelector(
  [authenticationSelector],
  ({ isSignInHitted }) => isSignInHitted
);
export const getIsSignUpHitted = createSelector(
  [authenticationSelector],
  ({ isSignUpHitted }) => isSignUpHitted
);
export const getIsSignOutHitted = createSelector(
  [authenticationSelector],
  ({ isSignOutHitted }) => isSignOutHitted
);
