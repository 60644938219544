import { createSelector } from "reselect";

const dashboardSubscriberSelector = ({ dashboardSubscriber }) =>
  dashboardSubscriber;

export const getDashboard = createSelector(
  [dashboardSubscriberSelector],
  ({ dashboard }) => dashboard
);

export const getFetchDashboardFilterMarketId = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardFilterMarketId }) => fetchDashboardFilterMarketId
);
export const getFetchDashboardFilterBranchId = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardFilterBranchId }) => fetchDashboardFilterBranchId
);
export const getFetchDashboardFilterOrderStatuses = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardFilterOrderStatuses }) => fetchDashboardFilterOrderStatuses
);
export const getFetchDashboardFilterTransactionStatuses = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardFilterTransactionStatuses }) =>
    fetchDashboardFilterTransactionStatuses
);
export const getFetchDashboardFilterPeriodicity = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardFilterPeriodicity }) => fetchDashboardFilterPeriodicity
);
export const getFetchDashboardLoading = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardLoading }) => fetchDashboardLoading
);
export const getFetchDashboardSuccess = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardSuccess }) => fetchDashboardSuccess
);
export const getFetchDashboardFailed = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardFailed }) => fetchDashboardFailed
);

export const getIsFetchDashboardHitted = createSelector(
  [dashboardSubscriberSelector],
  ({ isFetchDashboardHitted }) => isFetchDashboardHitted
);
