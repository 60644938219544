import { takeLatest, put, all, call, select } from "redux-saga/effects";

import CARD_ACTION_TYPES from "./card.type";

import {
  setCreateOrUpdateCardFailed,
  setCreateOrUpdateCardLoading,
  setCreateOrUpdateCardSuccess,
  setFetchCardsFailed,
  setFetchCardsLoading,
  setFetchCardsSuccess,
  setCards,
  setFetchCardLoading,
  setCard,
  setFetchCardSuccess,
  setFetchCardFailed,
  setIsFetchCardsHitted,
  setIsFetchCardHitted,
  setIsCreateOrUpdateCardHitted,
} from "./card.action";
import {
  getFetchCardsFilterIsVerified,
  getFetchCardsFilterTransferType,
  getFetchCardsIncludes,
  getFetchCardsPage,
  getFetchCardsPerPage,
  getFetchCardsSearch,
  getFetchCardsSort,
} from "./card.selector";

import { createOrUpdateCard, getCard, getCards } from "../../api/card.api";

export function* _getCards() {
  try {
    yield put(setFetchCardsLoading(true));

    const search = yield select(getFetchCardsSearch);
    const sort = yield select(getFetchCardsSort);
    const page = yield select(getFetchCardsPage);
    const per_page = yield select(getFetchCardsPerPage);
    const includes = yield select(getFetchCardsIncludes);
    const transfer_type = yield select(getFetchCardsFilterTransferType);
    const is_verified = yield select(getFetchCardsFilterIsVerified);

    const parameters = {
      search,
      sort,
      page,
      per_page,
      includes,
      filter: { transfer_type, is_verified },
    };

    const {
      meta: { message },
      data: { data: cards },
    } = yield call(getCards, parameters);

    yield put(setIsFetchCardsHitted(true));
    yield put(setCards(cards));

    yield put(setFetchCardsSuccess(message));
    yield put(setFetchCardsLoading(false));
  } catch (error) {
    yield put(setFetchCardsFailed(error));
    yield put(setFetchCardsLoading(false));
  }
}

export function* _getCard({ payload: marketId }) {
  try {
    yield put(setFetchCardLoading(true));

    const {
      meta: { message },
      data: card,
    } = yield call(getCard, marketId);

    yield put(setIsFetchCardHitted(true));
    yield put(setCard(card));

    yield put(setFetchCardSuccess(message));
    yield put(setFetchCardLoading(false));
  } catch (error) {
    yield put(setFetchCardFailed(error));
    yield put(setFetchCardLoading(false));
  }
}

export function* _createOrUpdateCard({ payload: request }) {
  try {
    yield put(setCreateOrUpdateCardLoading(true));

    const {
      meta: { message },
      data: card,
    } = yield call(createOrUpdateCard, request);

    yield put(setIsCreateOrUpdateCardHitted(true));
    yield put(setCard(card));

    yield put(setCreateOrUpdateCardSuccess(message));
    yield put(setCreateOrUpdateCardLoading(false));
  } catch (error) {
    yield put(setCreateOrUpdateCardFailed(error));
    yield put(setCreateOrUpdateCardLoading(false));
  }
}

export function* onFetchCardsStart() {
  yield takeLatest(CARD_ACTION_TYPES.FETCH_CARDS_START, _getCards);
}

export function* onFetchCardStart() {
  yield takeLatest(CARD_ACTION_TYPES.FETCH_CARD_START, _getCard);
}

export function* onCreateOrUpdateCardStart() {
  yield takeLatest(CARD_ACTION_TYPES.CREATE_OR_UPDATE_CARD_START, _createOrUpdateCard);
}

export function* cardSaga() {
  yield all([call(onFetchCardsStart), call(onFetchCardStart), call(onCreateOrUpdateCardStart)]);
}
