import apiService from "./api";

export const getDrivers = async (parameters) =>
  (await apiService.get("/v2/drivers", parameters)).data;
export const getDriver = async (driverId) =>
  (await apiService.get(`/v2/drivers/${driverId}`)).data;
export const createDriver = async (request) =>
  (await apiService.post("/v2/drivers", request)).data;
export const updateDriver = async (driverId, request) =>
  (await apiService.post(`/v2/drivers/${driverId}`, request)).data;
