const CUSTOMER_ACTION_TYPES = {
  RESET_CUSTOMER_REDUCER: "customer/RESET_CUSTOMER_REDUCER",

  SET_CUSTOMERS: "customer/SET_CUSTOMERS",
  SET_SEARCH_CUSTOMERS: "customer/SET_SEARCH_CUSTOMERS",
  SET_CUSTOMER: "customer/SET_CUSTOMER",
  SET_CURRENT_CUSTOMER: "customer/SET_CURRENT_CUSTOMER",
  SET_CREATE_CUSTOMER: "customer/SET_CREATE_CUSTOMER",
  SET_UPDATE_CUSTOMER: "customer/SET_UPDATE_CUSTOMER",

  SET_IS_CUSTOMERS_HAS_MORE: "customer/SET_IS_CUSTOMERS_HAS_MORE",
  SET_IS_SEARCH_CUSTOMERS_HAS_MORE: "customer/SET_IS_SEARCH_CUSTOMERS_HAS_MORE",

  SET_FETCH_CUSTOMERS_SEARCH: "customer/SET_FETCH_CUSTOMERS_SEARCH",
  SET_FETCH_CUSTOMERS_SORT: "customer/SET_FETCH_CUSTOMERS_SORT",
  SET_FETCH_CUSTOMERS_KEY_BY: "customer/SET_FETCH_CUSTOMERS_KEY_BY",
  SET_FETCH_CUSTOMERS_PAGE: "customer/SET_FETCH_CUSTOMERS_PAGE",
  SET_FETCH_CUSTOMERS_PER_PAGE: "customer/SET_FETCH_CUSTOMERS_PER_PAGE",
  SET_FETCH_CUSTOMERS_INCLUDES: "customer/SET_FETCH_CUSTOMERS_INCLUDES",
  SET_FETCH_CUSTOMERS_FILTER_MARKET_ID:
    "customer/SET_FETCH_CUSTOMERS_FILTER_MARKET_ID",
  SET_FETCH_CUSTOMERS_LOADING: "customer/SET_FETCH_CUSTOMERS_LOADING",
  SET_FETCH_CUSTOMERS_SUCCESS: "customer/SET_FETCH_CUSTOMERS_SUCCESS",
  SET_FETCH_CUSTOMERS_FAILED: "customer/SET_FETCH_CUSTOMERS_FAILED",

  SET_FETCH_SEARCH_CUSTOMERS_SEARCH:
    "customer/SET_FETCH_SEARCH_CUSTOMERS_SEARCH",
  SET_FETCH_SEARCH_CUSTOMERS_SORT: "customer/SET_FETCH_SEARCH_CUSTOMERS_SORT",
  SET_FETCH_SEARCH_CUSTOMERS_KEY_BY:
    "customer/SET_FETCH_SEARCH_CUSTOMERS_KEY_BY",
  SET_FETCH_SEARCH_CUSTOMERS_PAGE: "customer/SET_FETCH_SEARCH_CUSTOMERS_PAGE",
  SET_FETCH_SEARCH_CUSTOMERS_PER_PAGE:
    "customer/SET_FETCH_SEARCH_CUSTOMERS_PER_PAGE",
  SET_FETCH_SEARCH_CUSTOMERS_INCLUDES:
    "customer/SET_FETCH_SEARCH_CUSTOMERS_INCLUDES",
  SET_FETCH_SEARCH_CUSTOMERS_FILTER_MARKET_ID:
    "customer/SET_FETCH_SEARCH_CUSTOMERS_FILTER_MARKET_ID",
  SET_FETCH_SEARCH_CUSTOMERS_LOADING:
    "customer/SET_FETCH_SEARCH_CUSTOMERS_LOADING",
  SET_FETCH_SEARCH_CUSTOMERS_SUCCESS:
    "customer/SET_FETCH_SEARCH_CUSTOMERS_SUCCESS",
  SET_FETCH_SEARCH_CUSTOMERS_FAILED:
    "customer/SET_FETCH_SEARCH_CUSTOMERS_FAILED",

  SET_FETCH_CUSTOMER_LOADING: "customer/SET_FETCH_CUSTOMER_LOADING",
  SET_FETCH_CUSTOMER_SUCCESS: "customer/SET_FETCH_CUSTOMER_SUCCESS",
  SET_FETCH_CUSTOMER_FAILED: "customer/SET_FETCH_CUSTOMER_FAILED",

  SET_CREATE_CUSTOMER_LOADING: "customer/SET_CREATE_CUSTOMER_LOADING",
  SET_CREATE_CUSTOMER_SUCCESS: "customer/SET_CREATE_CUSTOMER_SUCCESS",
  SET_CREATE_CUSTOMER_FAILED: "customer/SET_CREATE_CUSTOMER_FAILED",

  SET_UPDATE_CUSTOMER_LOADING: "customer/SET_UPDATE_CUSTOMER_LOADING",
  SET_UPDATE_CUSTOMER_SUCCESS: "customer/SET_UPDATE_CUSTOMER_SUCCESS",
  SET_UPDATE_CUSTOMER_FAILED: "customer/SET_UPDATE_CUSTOMER_FAILED",

  APPEND_CUSTOMERS: "customer/APPEND_CUSTOMERS",
  APPEND_SEARCH_CUSTOMERS: "customer/APPEND_SEARCH_CUSTOMERS",

  SET_IS_FETCH_CUSTOMERS_HITTED: "customer/SET_IS_FETCH_CUSTOMERS_HITTED",
  SET_IS_FETCH_SEARCH_CUSTOMERS_HITTED:
    "customer/SET_IS_FETCH_SEARCH_CUSTOMERS_HITTED",
  SET_IS_FETCH_CUSTOMER_HITTED: "customer/SET_IS_FETCH_CUSTOMER_HITTED",
  SET_IS_CREATE_CUSTOMER_HITTED: "customer/SET_IS_CREATE_CUSTOMER_HITTED",
  SET_IS_UPDATE_CUSTOMER_HITTED: "customer/SET_IS_UPDATE_CUSTOMER_HITTED",

  FETCH_CUSTOMERS_START: "customer/FETCH_CUSTOMERS_START",
  FETCH_SEARCH_CUSTOMERS_START: "customer/FETCH_SEARCH_CUSTOMERS_START",
  FETCH_CUSTOMER_START: "customer/FETCH_CUSTOMER_START",
  CREATE_CUSTOMER_START: "customer/CREATE_CUSTOMER_START",
  UPDATE_CUSTOMER_START: "customer/UPDATE_CUSTOMER_START",
};

export default CUSTOMER_ACTION_TYPES;
