import { takeLatest, put, all, call } from "redux-saga/effects";

import PRODUCT_MODIFIER_ACTION_TYPES from "./product-modifier.type";

import {
  setCreateProductModifier,
  setCreateProductModifierFailed,
  setCreateProductModifierLoading,
  setCreateProductModifierSuccess,
  setFetchScannedProductModifierFailed,
  setFetchScannedProductModifierLoading,
  setFetchScannedProductModifierSuccess,
  setScannedProductModifier,
  setUpdateProductModifier,
  setUpdateProductModifierFailed,
  setUpdateProductModifierLoading,
  setUpdateProductModifierSuccess,
} from "./product-modifier.action";

import {
  createProductModifier,
  getProductModifierByBarcode,
  updateProductModifier,
} from "../../api/product-modifier.api";

export function* _getScannedProductModifier({ payload: barcode }) {
  try {
    yield put(setFetchScannedProductModifierLoading(true));

    const {
      meta: { message },
      data: productModifier,
    } = yield call(getProductModifierByBarcode, barcode);

    yield put(setScannedProductModifier(productModifier));

    yield put(setFetchScannedProductModifierSuccess(message));
    yield put(setFetchScannedProductModifierLoading(false));
  } catch (error) {
    yield put(setFetchScannedProductModifierFailed(error));
    yield put(setFetchScannedProductModifierLoading(false));
  }
}

export function* _createProductModifier({ payload: { productId, request } }) {
  try {
    yield put(setCreateProductModifierLoading(true));

    const {
      meta: { message },
      data: productModifier,
    } = yield call(createProductModifier, productId, request);

    yield put(setCreateProductModifier(productModifier));

    yield put(setCreateProductModifierSuccess(message));
    yield put(setCreateProductModifierLoading(false));
  } catch (error) {
    yield put(setCreateProductModifierFailed(error));
    yield put(setCreateProductModifierLoading(false));
  }
}

export function* _updateProductModifier({ payload: { productId, productModifierId, request } }) {
  try {
    yield put(setUpdateProductModifierLoading(true));

    const {
      meta: { message },
      data: productModifier,
    } = yield call(updateProductModifier, productId, productModifierId, request);

    yield put(setUpdateProductModifier(productModifier));

    yield put(setUpdateProductModifierSuccess(message));
    yield put(setUpdateProductModifierLoading(false));
  } catch (error) {
    yield put(setUpdateProductModifierFailed(error));
    yield put(setUpdateProductModifierLoading(false));
  }
}

export function* onFetchScannedProductModifierStart() {
  yield takeLatest(PRODUCT_MODIFIER_ACTION_TYPES.FETCH_SCANNED_PRODUCT_MODIFIER_START, _getScannedProductModifier);
}

export function* onCreateProductModifierStart() {
  yield takeLatest(PRODUCT_MODIFIER_ACTION_TYPES.CREATE_PRODUCT_MODIFIER_START, _createProductModifier);
}

export function* onUpdateProductModifierStart() {
  yield takeLatest(PRODUCT_MODIFIER_ACTION_TYPES.UPDATE_PRODUCT_MODIFIER_START, _updateProductModifier);
}

export function* productModifierSaga() {
  yield all([
    call(onFetchScannedProductModifierStart),
    call(onCreateProductModifierStart),
    call(onUpdateProductModifierStart),
  ]);
}
