import { takeLatest, put, all, call, select } from "redux-saga/effects";

import TAX_ACTION_TYPES from "./tax.type";

import {
  appendTaxes,
  setCreateTaxFailed,
  setCreateTaxLoading,
  setCreateTaxSuccess,
  setDeleteTaxFailed,
  setDeleteTaxLoading,
  setDeleteTaxSuccess,
  setFetchTaxesFailed,
  setFetchTaxesLoading,
  setFetchTaxesPage,
  setFetchTaxesSuccess,
  setFetchTaxFailed,
  setFetchTaxLoading,
  setFetchTaxSuccess,
  setIsCreateTaxHitted,
  setIsFetchTaxesHitted,
  setIsFetchTaxHitted,
  setIsSortTaxesHitted,
  setIsTaxesHasMore,
  setSortTaxesFailed,
  setSortTaxesLoading,
  setSortTaxesSuccess,
  setTax,
  setTaxes,
  setUpdateTaxFailed,
  setUpdateTaxLoading,
  setUpdateTaxSuccess,
} from "./tax.action";
import {
  getFetchTaxesFilterBranchId,
  getFetchTaxesFilterMarketId,
  getFetchTaxesIncludes,
  getFetchTaxesPage,
  getFetchTaxesPerPage,
  getFetchTaxesSearch,
  getIsFetchTaxesHitted,
} from "./tax.selector";

import {
  getTaxes,
  getTax,
  createTax,
  updateTax,
  deleteTax,
  sortTaxes,
} from "../../api/tax.api";

export function* _getTaxes() {
  try {
    yield put(setFetchTaxesLoading(true));

    const search = yield select(getFetchTaxesSearch);
    const page = yield select(getFetchTaxesPage);
    const per_page = yield select(getFetchTaxesPerPage);
    const includes = yield select(getFetchTaxesIncludes);
    const market_id = yield select(getFetchTaxesFilterMarketId);
    const branch_id = yield select(getFetchTaxesFilterBranchId);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
      },
    };

    const {
      meta: { message },
      data: { data: taxes },
    } = yield call(getTaxes, parameters);

    yield put(setIsFetchTaxesHitted(true));
    yield put(setIsTaxesHasMore(taxes.length > 0));

    if (page > 1) {
      yield put(appendTaxes(taxes));
    } else {
      yield put(setTaxes(taxes));
    }

    yield put(setFetchTaxesSuccess(message));
    yield put(setFetchTaxesLoading(false));
  } catch (error) {
    yield put(setFetchTaxesFailed(error));
    yield put(setFetchTaxesLoading(false));
  }
}
export function* _getTax({ payload: taxId }) {
  try {
    yield put(setFetchTaxLoading(true));

    const {
      meta: { message },
      data: tax,
    } = yield call(getTax, taxId);

    yield put(setIsFetchTaxHitted(true));
    yield put(setTax(tax));

    yield put(setFetchTaxSuccess(message));
    yield put(setFetchTaxLoading(false));
  } catch (error) {
    yield put(setFetchTaxFailed(error));
    yield put(setFetchTaxLoading(false));
  }
}
export function* _createTax({ payload: request }) {
  try {
    yield put(setCreateTaxLoading(true));

    const {
      meta: { message },
    } = yield call(createTax, request);

    yield put(setIsCreateTaxHitted(true));

    const isFetchTaxesHitted = yield select(getIsFetchTaxesHitted);

    if (isFetchTaxesHitted) {
      yield put(setFetchTaxesPage(1));
      yield call(_getTaxes);
    }

    yield put(setCreateTaxSuccess(message));
    yield put(setCreateTaxLoading(false));
  } catch (error) {
    yield put(setCreateTaxFailed(error));
    yield put(setCreateTaxLoading(false));
  }
}
export function* _updateTax({ payload: { taxId, request } }) {
  try {
    yield put(setUpdateTaxLoading(true));

    const {
      meta: { message },
    } = yield call(updateTax, taxId, request);

    const isFetchTaxesHitted = yield select(getIsFetchTaxesHitted);

    if (isFetchTaxesHitted) {
      yield put(setFetchTaxesPage(1));
      yield call(_getTaxes);
    }

    yield put(setUpdateTaxSuccess(message));
    yield put(setUpdateTaxLoading(false));
  } catch (error) {
    yield put(setUpdateTaxFailed(error));
    yield put(setUpdateTaxLoading(false));
  }
}
export function* _deleteTax({ payload: taxId }) {
  try {
    yield put(setDeleteTaxLoading(true));

    const {
      meta: { message },
    } = yield call(deleteTax, taxId);

    const isFetchTaxesHitted = yield select(getIsFetchTaxesHitted);

    if (isFetchTaxesHitted) {
      yield put(setFetchTaxesPage(1));
      yield call(_getTaxes);
    }

    yield put(setDeleteTaxSuccess(message));
    yield put(setDeleteTaxLoading(false));
  } catch (error) {
    yield put(setDeleteTaxFailed(error));
    yield put(setDeleteTaxLoading(false));
  }
}
export function* _sortTaxes({ payload: request }) {
  try {
    yield put(setSortTaxesLoading(true));

    const {
      meta: { message },
    } = yield call(sortTaxes, request);

    yield put(setIsSortTaxesHitted(true));

    const isFetchTaxesHitted = yield select(getIsFetchTaxesHitted);

    if (isFetchTaxesHitted) {
      yield put(setFetchTaxesPage(1));
      yield call(_getTaxes);
    }

    yield put(setSortTaxesSuccess(message));
    yield put(setSortTaxesLoading(false));
  } catch (error) {
    yield put(setSortTaxesFailed(error));
    yield put(setSortTaxesLoading(false));
  }
}

export function* onFetchTaxesStart() {
  yield takeLatest(TAX_ACTION_TYPES.FETCH_TAXES_START, _getTaxes);
}
export function* onFetchTaxStart() {
  yield takeLatest(TAX_ACTION_TYPES.FETCH_TAX_START, _getTax);
}
export function* onCreateTaxStart() {
  yield takeLatest(TAX_ACTION_TYPES.CREATE_TAX_START, _createTax);
}
export function* onUpdateTaxStart() {
  yield takeLatest(TAX_ACTION_TYPES.UPDATE_TAX_START, _updateTax);
}
export function* onDeleteTaxStart() {
  yield takeLatest(TAX_ACTION_TYPES.DELETE_TAX_START, _deleteTax);
}
export function* onSortTaxesStart() {
  yield takeLatest(TAX_ACTION_TYPES.SORT_TAXES_START, _sortTaxes);
}

export function* taxSaga() {
  yield all([
    call(onFetchTaxesStart),
    call(onFetchTaxStart),
    call(onCreateTaxStart),
    call(onUpdateTaxStart),
    call(onDeleteTaxStart),
    call(onSortTaxesStart),
  ]);
}
