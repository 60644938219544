import WATHQ_ACTION_TYPES from "./wathq.type";

export const WATHQ_INITIAL_STATE = {
  wathq: null,
  fetchWathqLoading: false,
  fetchWathqSuccess: null,
  fetchWathqFailed: null,
};

export const wathqReducer = (state = WATHQ_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case WATHQ_ACTION_TYPES.SET_WATHQ:
      return { ...state, wathq: payload };
    case WATHQ_ACTION_TYPES.SET_FETCH_WATHQ_LOADING:
      return { ...state, fetchWathqLoading: payload };
    case WATHQ_ACTION_TYPES.SET_FETCH_WATHQ_SUCCESS:
      return { ...state, fetchWathqSuccess: payload };
    case WATHQ_ACTION_TYPES.SET_FETCH_WATHQ_FAILED:
      return { ...state, fetchWathqFailed: payload };
    case WATHQ_ACTION_TYPES.RESET_WATHQ_REDUCER:
      return WATHQ_INITIAL_STATE;
    default:
      return state;
  }
};
