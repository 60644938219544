import { takeLatest, put, all, call, select } from "redux-saga/effects";

import USER_SUPPLIER_ACTION_TYPES from "./user-supplier.type";

import {
  appendUserSuppliers,
  setUserSupplier,
  setUserSuppliers,
  setCreateUserSupplierFailed,
  setCreateUserSupplierLoading,
  setCreateUserSupplierSuccess,
  setDeleteUserSupplierFailed,
  setDeleteUserSupplierLoading,
  setDeleteUserSupplierSuccess,
  setFetchUserSupplierFailed,
  setFetchUserSupplierLoading,
  setFetchUserSupplierSuccess,
  setFetchUserSuppliersFailed,
  setFetchUserSuppliersLoading,
  setFetchUserSuppliersPage,
  setFetchUserSuppliersSuccess,
  setIsUserSuppliersHasMore,
  setIsCreateUserSupplierHitted,
  setIsDeleteUserSupplierHitted,
  setIsFetchUserSupplierHitted,
  setIsFetchUserSuppliersHitted,
  setIsUpdateUserSupplierHitted,
  setUpdateUserSupplierFailed,
  setUpdateUserSupplierLoading,
  setUpdateUserSupplierSuccess,
} from "./user-supplier.action";
import {
  getFetchUserSuppliersFilterBranchId,
  getFetchUserSuppliersFilterMarketId,
  getFetchUserSuppliersIncludes,
  getFetchUserSuppliersKeyBy,
  getFetchUserSuppliersPage,
  getFetchUserSuppliersPerPage,
  getFetchUserSuppliersSearch,
  getFetchUserSuppliersSort,
  getIsFetchUserSuppliersHitted,
} from "./user-supplier.selector";

import {
  createUserSupplier,
  deleteUserSupplier,
  getUserSupplier,
  getUserSuppliers,
  updateUserSupplier,
} from "../../api/user-supplier.api";

export function* _getUserSuppliers() {
  try {
    yield put(setFetchUserSuppliersLoading(true));

    const search = yield select(getFetchUserSuppliersSearch);
    const sort = yield select(getFetchUserSuppliersSort);
    const key_by = yield select(getFetchUserSuppliersKeyBy);
    const page = yield select(getFetchUserSuppliersPage);
    const per_page = yield select(getFetchUserSuppliersPerPage);
    const includes = yield select(getFetchUserSuppliersIncludes);
    const market_id = yield select(getFetchUserSuppliersFilterMarketId);
    const branch_id = yield select(getFetchUserSuppliersFilterBranchId);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id, branch_id },
    };

    const {
      meta: { message },
      data: { data: userSuppliers },
    } = yield call(getUserSuppliers, parameters);

    yield put(setIsFetchUserSuppliersHitted(true));
    yield put(setIsUserSuppliersHasMore(userSuppliers.length > 0));

    if (page > 1) {
      yield put(appendUserSuppliers(userSuppliers));
    } else {
      yield put(setUserSuppliers(userSuppliers));
    }

    yield put(setFetchUserSuppliersSuccess(message));
    yield put(setFetchUserSuppliersLoading(false));
  } catch (error) {
    yield put(setFetchUserSuppliersFailed(error));
    yield put(setFetchUserSuppliersLoading(false));
  }
}
export function* _getUserSupplier({ payload: supplierId }) {
  try {
    yield put(setFetchUserSupplierLoading(true));

    const {
      meta: { message },
      data: userSupplier,
    } = yield call(getUserSupplier, supplierId);

    yield put(setIsFetchUserSupplierHitted(true));
    yield put(setUserSupplier(userSupplier));

    yield put(setFetchUserSupplierSuccess(message));
    yield put(setFetchUserSupplierLoading(false));
  } catch (error) {
    yield put(setFetchUserSupplierFailed(error));
    yield put(setFetchUserSupplierLoading(false));
  }
}
export function* _createUserSupplier({ payload: request }) {
  try {
    yield put(setCreateUserSupplierLoading(true));

    const {
      meta: { message },
    } = yield call(createUserSupplier, request);

    yield put(setIsCreateUserSupplierHitted(true));

    const isFetchUserSuppliersHitted = yield select(
      getIsFetchUserSuppliersHitted
    );

    if (isFetchUserSuppliersHitted) {
      yield put(setFetchUserSuppliersPage(1));
      yield call(_getUserSuppliers);
    }

    yield put(setCreateUserSupplierSuccess(message));
    yield put(setCreateUserSupplierLoading(false));
  } catch (error) {
    yield put(setCreateUserSupplierFailed(error));
    yield put(setCreateUserSupplierLoading(false));
  }
}
export function* _updateUserSupplier({ payload: { supplierId, request } }) {
  try {
    yield put(setUpdateUserSupplierLoading(true));

    const {
      meta: { message },
    } = yield call(updateUserSupplier, supplierId, request);

    yield put(setIsUpdateUserSupplierHitted(true));

    const isFetchUserSuppliersHitted = yield select(
      getIsFetchUserSuppliersHitted
    );

    if (isFetchUserSuppliersHitted) {
      yield put(setFetchUserSuppliersPage(1));
      yield call(_getUserSuppliers);
    }

    yield put(setUpdateUserSupplierSuccess(message));
    yield put(setUpdateUserSupplierLoading(false));
  } catch (error) {
    yield put(setUpdateUserSupplierFailed(error));
    yield put(setUpdateUserSupplierLoading(false));
  }
}
export function* _deleteUserSupplier({ payload: supplierId }) {
  try {
    yield put(setDeleteUserSupplierLoading(true));

    const {
      meta: { message },
    } = yield call(deleteUserSupplier, supplierId);

    yield put(setIsDeleteUserSupplierHitted(true));

    const isFetchUserSuppliersHitted = yield select(
      getIsFetchUserSuppliersHitted
    );

    if (isFetchUserSuppliersHitted) {
      yield put(setFetchUserSuppliersPage(1));
      yield call(_getUserSuppliers);
    }

    yield put(setDeleteUserSupplierSuccess(message));
    yield put(setDeleteUserSupplierLoading(false));
  } catch (error) {
    yield put(setDeleteUserSupplierFailed(error));
    yield put(setDeleteUserSupplierLoading(false));
  }
}

export function* onFetchUserSuppliersStart() {
  yield takeLatest(
    USER_SUPPLIER_ACTION_TYPES.FETCH_USER_SUPPLIERS_START,
    _getUserSuppliers
  );
}
export function* onFetchUserSupplierStart() {
  yield takeLatest(
    USER_SUPPLIER_ACTION_TYPES.FETCH_USER_SUPPLIER_START,
    _getUserSupplier
  );
}
export function* onCreateUserSupplierStart() {
  yield takeLatest(
    USER_SUPPLIER_ACTION_TYPES.CREATE_USER_SUPPLIER_START,
    _createUserSupplier
  );
}
export function* onUpdateUserSupplierStart() {
  yield takeLatest(
    USER_SUPPLIER_ACTION_TYPES.UPDATE_USER_SUPPLIER_START,
    _updateUserSupplier
  );
}
export function* onDeleteUserSupplierStart() {
  yield takeLatest(
    USER_SUPPLIER_ACTION_TYPES.DELETE_USER_SUPPLIER_START,
    _deleteUserSupplier
  );
}

export function* userSupplierSaga() {
  yield all([
    call(onFetchUserSuppliersStart),
    call(onFetchUserSupplierStart),
    call(onCreateUserSupplierStart),
    call(onUpdateUserSupplierStart),
    call(onDeleteUserSupplierStart),
  ]);
}
