import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PAYMENT_DEVICE_ACTION_TYPES from "./payment-device.type";

import {
  appendPaymentDevices,
  setCreatePaymentDeviceFailed,
  setCreatePaymentDeviceLoading,
  setCreatePaymentDeviceSuccess,
  setDeletePaymentDeviceFailed,
  setDeletePaymentDeviceLoading,
  setDeletePaymentDeviceSuccess,
  setFetchPaymentDeviceFailed,
  setFetchPaymentDeviceLoading,
  setFetchPaymentDevicesFailed,
  setFetchPaymentDevicesLoading,
  setFetchPaymentDevicesPage,
  setFetchPaymentDevicesSuccess,
  setFetchPaymentDeviceSuccess,
  setIsCreatePaymentDeviceHitted,
  setIsDeletePaymentDeviceHitted,
  setIsFetchPaymentDeviceHitted,
  setIsFetchPaymentDevicesHitted,
  setIsPaymentDevicesHasMore,
  setIsUpdatePaymentDeviceHitted,
  setPaymentDevice,
  setPaymentDevices,
  setUpdatePaymentDeviceFailed,
  setUpdatePaymentDeviceLoading,
  setUpdatePaymentDeviceSuccess,
} from "./payment-device.action";
import {
  getFetchPaymentDevicesFilterBranchId,
  getFetchPaymentDevicesFilterMarketId,
  getFetchPaymentDevicesFilterPaymentDevicesIds,
  getFetchPaymentDevicesFilterUsersIds,
  getFetchPaymentDevicesIncludes,
  getFetchPaymentDevicesPage,
  getFetchPaymentDevicesPerPage,
  getFetchPaymentDevicesSearch,
  getIsFetchPaymentDevicesHitted,
} from "./payment-device.selector";

import {
  getPaymentDevices,
  getPaymentDevice,
  createPaymentDevice,
  updatePaymentDevice,
  deletePaymentDevice,
} from "../../api/payment-device.api";

export function* _getPaymentDevices() {
  try {
    yield put(setFetchPaymentDevicesLoading(true));

    const search = yield select(getFetchPaymentDevicesSearch);
    const page = yield select(getFetchPaymentDevicesPage);
    const per_page = yield select(getFetchPaymentDevicesPerPage);
    const includes = yield select(getFetchPaymentDevicesIncludes);
    const market_id = yield select(getFetchPaymentDevicesFilterMarketId);
    const branch_id = yield select(getFetchPaymentDevicesFilterBranchId);
    const payment_devices_ids = yield select(getFetchPaymentDevicesFilterPaymentDevicesIds);
    const users_ids = yield select(getFetchPaymentDevicesFilterUsersIds);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        payment_devices_ids,
        users_ids,
      },
    };

    const {
      meta: { message },
      data: { data: paymentDevices },
    } = yield call(getPaymentDevices, parameters);

    yield put(setIsFetchPaymentDevicesHitted(true));
    yield put(setIsPaymentDevicesHasMore(paymentDevices.length > 0));

    if (page > 1) {
      yield put(appendPaymentDevices(paymentDevices));
    } else {
      yield put(setPaymentDevices(paymentDevices));
    }

    yield put(setFetchPaymentDevicesSuccess(message));
    yield put(setFetchPaymentDevicesLoading(false));
  } catch (error) {
    yield put(setFetchPaymentDevicesFailed(error));
    yield put(setFetchPaymentDevicesLoading(false));
  }
}

export function* _getPaymentDevice({ payload: id }) {
  try {
    yield put(setFetchPaymentDeviceLoading(true));

    const {
      meta: { message },
      data: paymentDevice,
    } = yield call(getPaymentDevice, id);

    yield put(setIsFetchPaymentDeviceHitted(true));
    yield put(setPaymentDevice(paymentDevice));

    yield put(setFetchPaymentDeviceSuccess(message));
    yield put(setFetchPaymentDeviceLoading(false));
  } catch (error) {
    yield put(setFetchPaymentDeviceFailed(error));
    yield put(setFetchPaymentDeviceLoading(false));
  }
}

export function* _createPaymentDevice({ payload: request }) {
  try {
    yield put(setCreatePaymentDeviceLoading(true));

    const {
      meta: { message },
    } = yield call(createPaymentDevice, request);

    yield put(setIsCreatePaymentDeviceHitted(true));

    const isFetchPaymentDevicesHitted = yield select(getIsFetchPaymentDevicesHitted);

    if (isFetchPaymentDevicesHitted) {
      yield put(setFetchPaymentDevicesPage(1));
      yield call(_getPaymentDevices);
    }

    yield put(setCreatePaymentDeviceSuccess(message));
    yield put(setCreatePaymentDeviceLoading(false));
  } catch (error) {
    yield put(setCreatePaymentDeviceFailed(error));
    yield put(setCreatePaymentDeviceLoading(false));
  }
}

export function* _updatePaymentDevice({ payload: { id, request } }) {
  try {
    yield put(setUpdatePaymentDeviceLoading(true));

    const {
      meta: { message },
    } = yield call(updatePaymentDevice, id, request);

    yield put(setIsUpdatePaymentDeviceHitted(true));

    const isFetchPaymentDevicesHitted = yield select(getIsFetchPaymentDevicesHitted);

    if (isFetchPaymentDevicesHitted) {
      yield put(setFetchPaymentDevicesPage(1));
      yield call(_getPaymentDevices);
    }

    yield put(setUpdatePaymentDeviceSuccess(message));
    yield put(setUpdatePaymentDeviceLoading(false));
  } catch (error) {
    yield put(setUpdatePaymentDeviceFailed(error));
    yield put(setUpdatePaymentDeviceLoading(false));
  }
}

export function* _deletePaymentDevice({ payload: id }) {
  try {
    yield put(setDeletePaymentDeviceLoading(true));

    const {
      meta: { message },
    } = yield call(deletePaymentDevice, id);

    yield put(setIsDeletePaymentDeviceHitted(true));

    const isFetchPaymentDevicesHitted = yield select(getIsFetchPaymentDevicesHitted);

    if (isFetchPaymentDevicesHitted) {
      yield put(setFetchPaymentDevicesPage(1));
      yield call(_getPaymentDevices);
    }

    yield put(setDeletePaymentDeviceSuccess(message));
    yield put(setDeletePaymentDeviceLoading(false));
  } catch (error) {
    yield put(setDeletePaymentDeviceFailed(error));
    yield put(setDeletePaymentDeviceLoading(false));
  }
}

export function* onFetchPaymentDevicesStart() {
  yield takeLatest(PAYMENT_DEVICE_ACTION_TYPES.FETCH_PAYMENT_DEVICES_START, _getPaymentDevices);
}

export function* onFetchPaymentDeviceStart() {
  yield takeLatest(PAYMENT_DEVICE_ACTION_TYPES.FETCH_PAYMENT_DEVICE_START, _getPaymentDevice);
}

export function* onCreatePaymentDeviceStart() {
  yield takeLatest(PAYMENT_DEVICE_ACTION_TYPES.CREATE_PAYMENT_DEVICE_START, _createPaymentDevice);
}

export function* onUpdatePaymentDeviceStart() {
  yield takeLatest(PAYMENT_DEVICE_ACTION_TYPES.UPDATE_PAYMENT_DEVICE_START, _updatePaymentDevice);
}

export function* onDeletePaymentDeviceStart() {
  yield takeLatest(PAYMENT_DEVICE_ACTION_TYPES.DELETE_PAYMENT_DEVICE_START, _deletePaymentDevice);
}

export function* paymentDeviceSaga() {
  yield all([
    call(onFetchPaymentDevicesStart),
    call(onFetchPaymentDeviceStart),
    call(onCreatePaymentDeviceStart),
    call(onUpdatePaymentDeviceStart),
    call(onDeletePaymentDeviceStart),
  ]);
}
