import HEADER_BRANCH_ACTION_TYPES from "./header-branch.type";

const HEADER_BRANCH_INITIAL_STATE = {
  branches: {},
  searchBranches: {},

  isBranchesHasMore: true,
  isSearchBranchesHasMore: true,

  fetchBranchesParams: {},
  fetchBranchesLoading: false,
  fetchBranchesSuccess: null,
  fetchBranchesFailed: null,

  searchBranchesParams: {},
  searchBranchesLoading: false,
  searchBranchesSuccess: null,
  searchBranchesFailed: null,

  appendBranchesParams: {},
  appendBranchesLoading: false,
  appendBranchesSuccess: null,
  appendBranchesFailed: null,

  isFetchBranchesHitted: false,
  isSearchBranchesHitted: false,
  isAppendBranchesHitted: false,
};

export const headerBranchReducer = (
  state = HEADER_BRANCH_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case HEADER_BRANCH_ACTION_TYPES.SET_BRANCHES:
      return { ...state, branches: payload };
    case HEADER_BRANCH_ACTION_TYPES.SET_SEARCH_BRANCHES:
      return { ...state, searchBranches: payload };

    case HEADER_BRANCH_ACTION_TYPES.SET_IS_BRANCHES_HAS_MORE:
      return { ...state, isBranchesHasMore: payload };
    case HEADER_BRANCH_ACTION_TYPES.SET_IS_SEARCH_BRANCHES_HAS_MORE:
      return { ...state, isSearchBranchesHasMore: payload };

    case HEADER_BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PARAMS:
      return { ...state, fetchBranchesParams: payload };
    case HEADER_BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_LOADING:
      return { ...state, fetchBranchesLoading: payload };
    case HEADER_BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SUCCESS:
      return { ...state, fetchBranchesSuccess: payload };
    case HEADER_BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FAILED:
      return { ...state, fetchBranchesFailed: payload };

    case HEADER_BRANCH_ACTION_TYPES.SET_SEARCH_BRANCHES_PARAMS:
      return { ...state, searchBranchesParams: payload };
    case HEADER_BRANCH_ACTION_TYPES.SET_SEARCH_BRANCHES_LOADING:
      return { ...state, searchBranchesLoading: payload };
    case HEADER_BRANCH_ACTION_TYPES.SET_SEARCH_BRANCHES_SUCCESS:
      return { ...state, searchBranchesSuccess: payload };
    case HEADER_BRANCH_ACTION_TYPES.SET_SEARCH_BRANCHES_FAILED:
      return { ...state, searchBranchesFailed: payload };

    case HEADER_BRANCH_ACTION_TYPES.SET_APPEND_BRANCHES_PARAMS:
      return { ...state, appendBranchesParams: payload };
    case HEADER_BRANCH_ACTION_TYPES.SET_APPEND_BRANCHES_LOADING:
      return { ...state, appendBranchesLoading: payload };
    case HEADER_BRANCH_ACTION_TYPES.SET_APPEND_BRANCHES_SUCCESS:
      return { ...state, appendBranchesSuccess: payload };
    case HEADER_BRANCH_ACTION_TYPES.SET_APPEND_BRANCHES_FAILED:
      return { ...state, appendBranchesFailed: payload };

    case HEADER_BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCHES_HITTED:
      return { ...state, isFetchBranchesHitted: payload };
    case HEADER_BRANCH_ACTION_TYPES.SET_IS_SEARCH_BRANCHES_HITTED:
      return { ...state, isSearchBranchesHitted: payload };
    case HEADER_BRANCH_ACTION_TYPES.SET_IS_APPEND_BRANCHES_HITTED:
      return { ...state, isAppendBranchesHitted: payload };

    case HEADER_BRANCH_ACTION_TYPES.APPEND_BRANCHES:
      return {
        ...state,
        branches: { ...state.branches, ...payload },
      };
    case HEADER_BRANCH_ACTION_TYPES.APPEND_SEARCH_BRANCHES:
      return {
        ...state,
        searchBranches: { ...state.searchBranches, ...payload },
      };

    case HEADER_BRANCH_ACTION_TYPES.RESET_HEADER_BRANCH_REDUCER:
      return HEADER_BRANCH_INITIAL_STATE;
    default:
      return state;
  }
};
