import SUBSCRIPTION_ACTION_TYPES from "./subscription.type";

export const SUBSCRIPTION_INITIAL_STATE = {
  subscription: null,

  fetchSubscriptionLoading: false,
  fetchSubscriptionSuccess: null,
  fetchSubscriptionFailed: null,

  updateSubscriptionLoading: false,
  updateSubscriptionSuccess: null,
  updateSubscriptionFailed: null,

  isFetchSubscriptionHitted: false,
  isUpdateSubscriptionHitted: false,
};

export const subscriptionReducer = (
  state = SUBSCRIPTION_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTION:
      return { ...state, subscription: payload };

    case SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_LOADING:
      return { ...state, fetchSubscriptionLoading: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_SUCCESS:
      return { ...state, fetchSubscriptionSuccess: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_FAILED:
      return { ...state, fetchSubscriptionFailed: payload };

    case SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_SUBSCRIPTION_LOADING:
      return { ...state, updateSubscriptionLoading: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_SUBSCRIPTION_SUCCESS:
      return { ...state, updateSubscriptionSuccess: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_SUBSCRIPTION_FAILED:
      return { ...state, updateSubscriptionFailed: payload };

    case SUBSCRIPTION_ACTION_TYPES.SET_IS_FETCH_SUBSCRIPTION_HITTED:
      return { ...state, isFetchSubscriptionHitted: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_IS_UPDATE_SUBSCRIPTION_HITTED:
      return { ...state, isUpdateSubscriptionHitted: payload };

    case SUBSCRIPTION_ACTION_TYPES.RESET_SUBSCRIPTION_REDUCER:
      return SUBSCRIPTION_INITIAL_STATE;
    default:
      return state;
  }
};
