import USER_DRIVER_ACTION_TYPES from "./user-driver.type";

export const USER_DRIVER_INITIAL_STATE = {
  userDrivers: [],
  userDriver: null,

  isUserDriversHasMore: true,

  fetchUserDriversSearch: null,
  fetchUserDriversSort: null,
  fetchUserDriversKeyBy: null,
  fetchUserDriversPage: 1,
  fetchUserDriversPerPage: null,
  fetchUserDriversIncludes: null,
  fetchUserDriversFilterGender: null,
  fetchUserDriversFilterStatus: null,
  fetchUserDriversFilterVehicleType: null,
  fetchUserDriversFilterMarketId: null,
  fetchUserDriversLoading: false,
  fetchUserDriversSuccess: null,
  fetchUserDriversFailed: null,

  fetchUserDriverLoading: false,
  fetchUserDriverSuccess: null,
  fetchUserDriverFailed: null,

  createUserDriverLoading: false,
  createUserDriverSuccess: null,
  createUserDriverFailed: null,

  updateUserDriverLoading: false,
  updateUserDriverSuccess: null,
  updateUserDriverFailed: null,

  addMarketUserDriverLoading: false,
  addMarketUserDriverSuccess: null,
  addMarketUserDriverFailed: null,

  removeMarketUserDriverLoading: false,
  removeMarketUserDriverSuccess: null,
  removeMarketUserDriverFailed: null,

  isFetchUserDriversHitted: false,
  isFetchUserDriverHitted: false,
  isCreateUserDriverHitted: false,
  isUpdateUserDriverHitted: false,
  isAddMarketUserDriverHitted: false,
  isRemoveMarketUserDriverHitted: false,
};

export const userDriverReducer = (
  state = USER_DRIVER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case USER_DRIVER_ACTION_TYPES.SET_USER_DRIVERS:
      return { ...state, userDrivers: payload };
    case USER_DRIVER_ACTION_TYPES.SET_USER_DRIVER:
      return { ...state, userDriver: payload };

    case USER_DRIVER_ACTION_TYPES.SET_IS_USER_DRIVERS_HAS_MORE:
      return { ...state, isUserDriversHasMore: payload };

    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_SEARCH:
      return { ...state, fetchUserDriversSearch: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_SORT:
      return { ...state, fetchUserDriversSort: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_KEY_BY:
      return { ...state, fetchUserDriversKeyBy: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_PAGE:
      return { ...state, fetchUserDriversPage: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_PER_PAGE:
      return { ...state, fetchUserDriversPerPage: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_INCLUDES:
      return { ...state, fetchUserDriversIncludes: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_FILTER_GENDER:
      return { ...state, fetchUserDriversFilterGender: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_FILTER_STATUS:
      return { ...state, fetchUserDriversFilterStatus: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_FILTER_VEHICLE_TYPE:
      return { ...state, fetchUserDriversFilterVehicleType: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_FILTER_MARKET_ID:
      return { ...state, fetchUserDriversFilterMarketId: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_LOADING:
      return { ...state, fetchUserDriversLoading: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_SUCCESS:
      return { ...state, fetchUserDriversSuccess: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_FAILED:
      return { ...state, fetchUserDriversFailed: payload };

    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVER_LOADING:
      return { ...state, fetchUserDriverLoading: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVER_SUCCESS:
      return { ...state, fetchUserDriverSuccess: payload };
    case USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVER_FAILED:
      return { ...state, fetchUserDriverFailed: payload };

    case USER_DRIVER_ACTION_TYPES.SET_CREATE_USER_DRIVER_LOADING:
      return { ...state, createUserDriverLoading: payload };
    case USER_DRIVER_ACTION_TYPES.SET_CREATE_USER_DRIVER_SUCCESS:
      return { ...state, createUserDriverSuccess: payload };
    case USER_DRIVER_ACTION_TYPES.SET_CREATE_USER_DRIVER_FAILED:
      return { ...state, createUserDriverFailed: payload };

    case USER_DRIVER_ACTION_TYPES.SET_UPDATE_USER_DRIVER_LOADING:
      return { ...state, updateUserDriverLoading: payload };
    case USER_DRIVER_ACTION_TYPES.SET_UPDATE_USER_DRIVER_SUCCESS:
      return { ...state, updateUserDriverSuccess: payload };
    case USER_DRIVER_ACTION_TYPES.SET_UPDATE_USER_DRIVER_FAILED:
      return { ...state, updateUserDriverFailed: payload };

    case USER_DRIVER_ACTION_TYPES.SET_ADD_MARKET_USER_DRIVER_LOADING:
      return { ...state, addMarketUserDriverLoading: payload };
    case USER_DRIVER_ACTION_TYPES.SET_ADD_MARKET_USER_DRIVER_SUCCESS:
      return { ...state, addMarketUserDriverSuccess: payload };
    case USER_DRIVER_ACTION_TYPES.SET_ADD_MARKET_USER_DRIVER_FAILED:
      return { ...state, addMarketUserDriverFailed: payload };

    case USER_DRIVER_ACTION_TYPES.SET_REMOVE_MARKET_USER_DRIVER_LOADING:
      return { ...state, removeMarketUserDriverLoading: payload };
    case USER_DRIVER_ACTION_TYPES.SET_REMOVE_MARKET_USER_DRIVER_SUCCESS:
      return { ...state, removeMarketUserDriverSuccess: payload };
    case USER_DRIVER_ACTION_TYPES.SET_REMOVE_MARKET_USER_DRIVER_FAILED:
      return { ...state, removeMarketUserDriverFailed: payload };

    case USER_DRIVER_ACTION_TYPES.APPEND_USER_DRIVERS:
      return { ...state, userDrivers: [...state.userDrivers, ...payload] };

    case USER_DRIVER_ACTION_TYPES.SET_IS_FETCH_USER_DRIVERS_HITTED:
      return { ...state, isFetchUserDriversHitted: payload };
    case USER_DRIVER_ACTION_TYPES.SET_IS_FETCH_USER_DRIVER_HITTED:
      return { ...state, isFetchUserDriverHitted: payload };
    case USER_DRIVER_ACTION_TYPES.SET_IS_CREATE_USER_DRIVER_HITTED:
      return { ...state, isCreateUserDriverHitted: payload };
    case USER_DRIVER_ACTION_TYPES.SET_IS_UPDATE_USER_DRIVER_HITTED:
      return { ...state, isUpdateUserDriverHitted: payload };
    case USER_DRIVER_ACTION_TYPES.SET_IS_ADD_MARKET_USER_DRIVER_HITTED:
      return { ...state, isAddMarketUserDriverHitted: payload };
    case USER_DRIVER_ACTION_TYPES.SET_IS_REMOVE_MARKET_USER_DRIVER_HITTED:
      return { ...state, isRemoveMarketUserDriverHitted: payload };

    case USER_DRIVER_ACTION_TYPES.RESET_USER_DRIVER_REDUCER:
      return USER_DRIVER_INITIAL_STATE;
    default:
      return state;
  }
};
