import WHATSAPP_ACTION_TYPES from "./whatsapp.type";
import { createAction } from "../../utils/store.utils";

export const setWhatsapps = (whatsapps) => createAction(WHATSAPP_ACTION_TYPES.SET_WHATSAPPS, whatsapps);
export const setWhatsapp = (whatsapp) => createAction(WHATSAPP_ACTION_TYPES.SET_WHATSAPP, whatsapp);

export const setIsWhatsappsHasMore = (isWhatsappsHasMore) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_IS_WHATSAPPS_HAS_MORE, isWhatsappsHasMore);

export const setFetchWhatsappsPage = (fetchWhatsappsPage) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_PAGE, fetchWhatsappsPage);
export const setFetchWhatsappsPerPage = (fetchWhatsappsPerPage) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_PER_PAGE, fetchWhatsappsPerPage);
export const setFetchWhatsappsIncludes = (fetchWhatsappsIncludes) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_INCLUDES, fetchWhatsappsIncludes);
export const setFetchWhatsappsFilterIsActive = (fetchWhatsappsFilterIsActive) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_FILTER_IS_ACTIVE, fetchWhatsappsFilterIsActive);
export const setFetchWhatsappsLoading = (fetchWhatsappsLoading) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_LOADING, fetchWhatsappsLoading);
export const setFetchWhatsappsSuccess = (fetchWhatsappsSuccess) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_SUCCESS, fetchWhatsappsSuccess);
export const setFetchWhatsappsFailed = (fetchWhatsappsFailed) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_FAILED, fetchWhatsappsFailed);

export const setFetchWhatsappIsQrCode = (fetchWhatsappIsQrCode) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPP_IS_QR_CODE, fetchWhatsappIsQrCode);
export const setFetchWhatsappLoading = (fetchWhatsappLoading) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPP_LOADING, fetchWhatsappLoading);
export const setFetchWhatsappSuccess = (fetchWhatsappSuccess) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPP_SUCCESS, fetchWhatsappSuccess);
export const setFetchWhatsappFailed = (fetchWhatsappFailed) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPP_FAILED, fetchWhatsappFailed);

export const setCreateOrUpdateWhatsappLoading = (createOrUpdateWhatsappLoading) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_CREATE_OR_UPDATE_WHATSAPP_LOADING, createOrUpdateWhatsappLoading);
export const setCreateOrUpdateWhatsappSuccess = (createOrUpdateWhatsappSuccess) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_CREATE_OR_UPDATE_WHATSAPP_SUCCESS, createOrUpdateWhatsappSuccess);
export const setCreateOrUpdateWhatsappFailed = (createOrUpdateWhatsappFailed) =>
  createAction(WHATSAPP_ACTION_TYPES.SET_CREATE_OR_UPDATE_WHATSAPP_FAILED, createOrUpdateWhatsappFailed);

export const appendWhatsapps = (whatsapps) => createAction(WHATSAPP_ACTION_TYPES.APPEND_WHATSAPPS, whatsapps);

export const setIsFetchWhatsappsHitted = (isFetchWhatsappsHitted) =>
  createAction(WHATSAPP_ACTION_TYPES.IS_FETCH_WHATSAPPS_HITTED, isFetchWhatsappsHitted);
export const setIsFetchWhatsappHitted = (isFetchWhatsappHitted) =>
  createAction(WHATSAPP_ACTION_TYPES.IS_FETCH_WHATSAPP_HITTED, isFetchWhatsappHitted);
export const setIsCreateOrUpdateWhatsappHitted = (isCreateOrUpdateWhatsappHitted) =>
  createAction(WHATSAPP_ACTION_TYPES.IS_CREATE_OR_UPDATE_WHATSAPP_HITTED, isCreateOrUpdateWhatsappHitted);

export const fetchWhatsappsStart = () => createAction(WHATSAPP_ACTION_TYPES.FETCH_WHATSAPPS_START);
export const fetchWhatsappStart = (marketId) => createAction(WHATSAPP_ACTION_TYPES.FETCH_WHATSAPP_START, marketId);
export const createOrUpdateWhatsappStart = (request) =>
  createAction(WHATSAPP_ACTION_TYPES.CREATE_OR_UPDATE_WHATSAPP_START, request);

export const resetWhatsappReducer = () => createAction(WHATSAPP_ACTION_TYPES.RESET_WHATSAPP_REDUCER);
