import { createSelector } from "reselect";

const kitchenGroupSelector = ({ kitchenGroup }) => kitchenGroup;

export const getKitchenGroups = createSelector([kitchenGroupSelector], ({ kitchenGroups }) => kitchenGroups);

export const getSelectKitchenGroups = createSelector(
  [kitchenGroupSelector],
  ({ selectKitchenGroups }) => selectKitchenGroups
);

export const getKitchenGroup = createSelector([kitchenGroupSelector], ({ kitchenGroup }) => kitchenGroup);

export const getIsKitchenGroupsHasMore = createSelector(
  [kitchenGroupSelector],
  ({ isKitchenGroupsHasMore }) => isKitchenGroupsHasMore
);

export const getIsSelectKitchenGroupsHasMore = createSelector(
  [kitchenGroupSelector],
  ({ isSelectKitchenGroupsHasMore }) => isSelectKitchenGroupsHasMore
);

export const getFetchKitchenGroupsSearch = createSelector(
  [kitchenGroupSelector],
  ({ fetchKitchenGroupsSearch }) => fetchKitchenGroupsSearch
);

export const getFetchKitchenGroupsPage = createSelector(
  [kitchenGroupSelector],
  ({ fetchKitchenGroupsPage }) => fetchKitchenGroupsPage
);

export const getFetchKitchenGroupsPerPage = createSelector(
  [kitchenGroupSelector],
  ({ fetchKitchenGroupsPerPage }) => fetchKitchenGroupsPerPage
);

export const getFetchKitchenGroupsIncludes = createSelector(
  [kitchenGroupSelector],
  ({ fetchKitchenGroupsIncludes }) => fetchKitchenGroupsIncludes
);

export const getFetchKitchenGroupsFilterMarketId = createSelector(
  [kitchenGroupSelector],
  ({ fetchKitchenGroupsFilterMarketId }) => fetchKitchenGroupsFilterMarketId
);

export const getFetchKitchenGroupsFilterBranchId = createSelector(
  [kitchenGroupSelector],
  ({ fetchKitchenGroupsFilterBranchId }) => fetchKitchenGroupsFilterBranchId
);

export const getFetchKitchenGroupsLoading = createSelector(
  [kitchenGroupSelector],
  ({ fetchKitchenGroupsLoading }) => fetchKitchenGroupsLoading
);

export const getFetchKitchenGroupsSuccess = createSelector(
  [kitchenGroupSelector],
  ({ fetchKitchenGroupsSuccess }) => fetchKitchenGroupsSuccess
);

export const getFetchKitchenGroupsFailed = createSelector(
  [kitchenGroupSelector],
  ({ fetchKitchenGroupsFailed }) => fetchKitchenGroupsFailed
);

export const getFetchSelectKitchenGroupsPage = createSelector(
  [kitchenGroupSelector],
  ({ fetchSelectKitchenGroupsPage }) => fetchSelectKitchenGroupsPage
);

export const getFetchSelectKitchenGroupsPerPage = createSelector(
  [kitchenGroupSelector],
  ({ fetchSelectKitchenGroupsPerPage }) => fetchSelectKitchenGroupsPerPage
);

export const getFetchSelectKitchenGroupsIncludes = createSelector(
  [kitchenGroupSelector],
  ({ fetchSelectKitchenGroupsIncludes }) => fetchSelectKitchenGroupsIncludes
);

export const getFetchSelectKitchenGroupsFilterMarketId = createSelector(
  [kitchenGroupSelector],
  ({ fetchSelectKitchenGroupsFilterMarketId }) => fetchSelectKitchenGroupsFilterMarketId
);

export const getFetchSelectKitchenGroupsFilterBranchId = createSelector(
  [kitchenGroupSelector],
  ({ fetchSelectKitchenGroupsFilterBranchId }) => fetchSelectKitchenGroupsFilterBranchId
);

export const getFetchSelectKitchenGroupsLoading = createSelector(
  [kitchenGroupSelector],
  ({ fetchSelectKitchenGroupsLoading }) => fetchSelectKitchenGroupsLoading
);

export const getFetchSelectKitchenGroupsSuccess = createSelector(
  [kitchenGroupSelector],
  ({ fetchSelectKitchenGroupsSuccess }) => fetchSelectKitchenGroupsSuccess
);

export const getFetchSelectKitchenGroupsFailed = createSelector(
  [kitchenGroupSelector],
  ({ fetchSelectKitchenGroupsFailed }) => fetchSelectKitchenGroupsFailed
);

export const getFetchKitchenGroupLoading = createSelector(
  [kitchenGroupSelector],
  ({ fetchKitchenGroupLoading }) => fetchKitchenGroupLoading
);

export const getFetchKitchenGroupSuccess = createSelector(
  [kitchenGroupSelector],
  ({ fetchKitchenGroupSuccess }) => fetchKitchenGroupSuccess
);

export const getFetchKitchenGroupFailed = createSelector(
  [kitchenGroupSelector],
  ({ fetchKitchenGroupFailed }) => fetchKitchenGroupFailed
);

export const getCreateKitchenGroupLoading = createSelector(
  [kitchenGroupSelector],
  ({ createKitchenGroupLoading }) => createKitchenGroupLoading
);

export const getCreateKitchenGroupSuccess = createSelector(
  [kitchenGroupSelector],
  ({ createKitchenGroupSuccess }) => createKitchenGroupSuccess
);

export const getCreateKitchenGroupFailed = createSelector(
  [kitchenGroupSelector],
  ({ createKitchenGroupFailed }) => createKitchenGroupFailed
);

export const getUpdateKitchenGroupLoading = createSelector(
  [kitchenGroupSelector],
  ({ updateKitchenGroupLoading }) => updateKitchenGroupLoading
);

export const getUpdateKitchenGroupSuccess = createSelector(
  [kitchenGroupSelector],
  ({ updateKitchenGroupSuccess }) => updateKitchenGroupSuccess
);

export const getUpdateKitchenGroupFailed = createSelector(
  [kitchenGroupSelector],
  ({ updateKitchenGroupFailed }) => updateKitchenGroupFailed
);

export const getDeleteKitchenGroupLoading = createSelector(
  [kitchenGroupSelector],
  ({ deleteKitchenGroupLoading }) => deleteKitchenGroupLoading
);

export const getDeleteKitchenGroupSuccess = createSelector(
  [kitchenGroupSelector],
  ({ deleteKitchenGroupSuccess }) => deleteKitchenGroupSuccess
);

export const getDeleteKitchenGroupFailed = createSelector(
  [kitchenGroupSelector],
  ({ deleteKitchenGroupFailed }) => deleteKitchenGroupFailed
);

export const getIsFetchKitchenGroupsHitted = createSelector(
  [kitchenGroupSelector],
  ({ isFetchKitchenGroupsHitted }) => isFetchKitchenGroupsHitted
);

export const getIsFetchSelectKitchenGroupsHitted = createSelector(
  [kitchenGroupSelector],
  ({ isFetchSelectKitchenGroupsHitted }) => isFetchSelectKitchenGroupsHitted
);

export const getIsFetchKitchenGroupHitted = createSelector(
  [kitchenGroupSelector],
  ({ isFetchKitchenGroupHitted }) => isFetchKitchenGroupHitted
);

export const getIsCreateKitchenGroupHitted = createSelector(
  [kitchenGroupSelector],
  ({ isCreateKitchenGroupHitted }) => isCreateKitchenGroupHitted
);

export const getIsUpdateKitchenGroupHitted = createSelector(
  [kitchenGroupSelector],
  ({ isUpdateKitchenGroupHitted }) => isUpdateKitchenGroupHitted
);

export const getIsDeleteKitchenGroupHitted = createSelector(
  [kitchenGroupSelector],
  ({ isDeleteKitchenGroupHitted }) => isDeleteKitchenGroupHitted
);
