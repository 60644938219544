import SETTING_ACTION_TYPES from "./setting.type";
import { createAction } from "../../utils/store.utils";

export const setSettings = (settings) =>
  createAction(SETTING_ACTION_TYPES.SET_SETTINGS, settings);
export const setSetting = (setting) =>
  createAction(SETTING_ACTION_TYPES.SET_SETTING, setting);

export const setIsSettingsHasMore = (isSettingsHasMore) =>
  createAction(
    SETTING_ACTION_TYPES.SET_IS_SETTINGS_HAS_MORE,
    isSettingsHasMore
  );

export const setFetchSettingsSearch = (fetchSettingsSearch) =>
  createAction(
    SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_SEARCH,
    fetchSettingsSearch
  );
export const setFetchSettingsSort = (fetchSettingsSort) =>
  createAction(SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_SORT, fetchSettingsSort);
export const setFetchSettingsKeyBy = (fetchSettingsKeyBy) =>
  createAction(
    SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_KEY_BY,
    fetchSettingsKeyBy
  );
export const setFetchSettingsPage = (fetchSettingsPage) =>
  createAction(SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_PAGE, fetchSettingsPage);
export const setFetchSettingsPerPage = (fetchSettingsPerPage) =>
  createAction(
    SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_PER_PAGE,
    fetchSettingsPerPage
  );
export const setFetchSettingsFilterKeys = (fetchSettingsFilterKeys) =>
  createAction(
    SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_FILTER_KEYS,
    fetchSettingsFilterKeys
  );
export const setFetchSettingsLoading = (fetchSettingsLoading) =>
  createAction(
    SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_LOADING,
    fetchSettingsLoading
  );
export const setFetchSettingsSuccess = (fetchSettingsSuccess) =>
  createAction(
    SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_SUCCESS,
    fetchSettingsSuccess
  );
export const setFetchSettingsFailed = (fetchSettingsFailed) =>
  createAction(
    SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_FAILED,
    fetchSettingsFailed
  );

export const setFetchSettingLoading = (fetchSettingLoading) =>
  createAction(
    SETTING_ACTION_TYPES.SET_FETCH_SETTING_LOADING,
    fetchSettingLoading
  );
export const setFetchSettingSuccess = (fetchSettingSuccess) =>
  createAction(
    SETTING_ACTION_TYPES.SET_FETCH_SETTING_SUCCESS,
    fetchSettingSuccess
  );
export const setFetchSettingFailed = (fetchSettingFailed) =>
  createAction(
    SETTING_ACTION_TYPES.SET_FETCH_SETTING_FAILED,
    fetchSettingFailed
  );

export const setCreateOrUpdateSettingLoading = (createOrUpdateSettingLoading) =>
  createAction(
    SETTING_ACTION_TYPES.SET_CREATE_OR_UPDATE_SETTING_LOADING,
    createOrUpdateSettingLoading
  );
export const setCreateOrUpdateSettingSuccess = (createOrUpdateSettingSuccess) =>
  createAction(
    SETTING_ACTION_TYPES.SET_CREATE_OR_UPDATE_SETTING_SUCCESS,
    createOrUpdateSettingSuccess
  );
export const setCreateOrUpdateSettingFailed = (createOrUpdateSettingFailed) =>
  createAction(
    SETTING_ACTION_TYPES.SET_CREATE_OR_UPDATE_SETTING_FAILED,
    createOrUpdateSettingFailed
  );

export const appendSettings = (settings) =>
  createAction(SETTING_ACTION_TYPES.APPEND_SETTINGS, settings);

export const fetchSettingsStart = () =>
  createAction(SETTING_ACTION_TYPES.FETCH_SETTINGS_START);
export const fetchSettingStart = (settingKey) =>
  createAction(SETTING_ACTION_TYPES.FETCH_SETTING_START, settingKey);
export const createOrUpdateSettingStart = (request) =>
  createAction(SETTING_ACTION_TYPES.CREATE_OR_UPDATE_SETTING_START, request);

export const setIsFetchSettingsHitted = (isFetchSettingsHitted) =>
  createAction(
    SETTING_ACTION_TYPES.SET_IS_FETCH_SETTINGS_HITTED,
    isFetchSettingsHitted
  );
export const setIsFetchSettingHitted = (isFetchSettingHitted) =>
  createAction(
    SETTING_ACTION_TYPES.SET_IS_FETCH_SETTING_HITTED,
    isFetchSettingHitted
  );
export const setIsCreateOrUpdateSettingHitted = (
  isCreateOrUpdateSettingHitted
) =>
  createAction(
    SETTING_ACTION_TYPES.SET_IS_CREATE_OR_UPDATE_SETTING_HITTED,
    isCreateOrUpdateSettingHitted
  );

export const resetSettingReducer = () =>
  createAction(SETTING_ACTION_TYPES.RESET_SETTING_REDUCER);
