const DISCOUNT_ACTION_TYPES = {
  RESET_DISCOUNT_REDUCER: "discount/RESET_DISCOUNT_REDUCER",

  SET_EDIT_MODIFIER_INDEX: "discount/SET_EDIT_MODIFIER_INDEX",
  SET_EDIT_MODIFIER: "discount/SET_EDIT_MODIFIER",

  SET_DISCOUNT_TAB: "discount/SET_DISCOUNT_TAB",
  SET_DISCOUNT_CREATE_TAB: "discount/SET_DISCOUNT_CREATE_TAB",
  SET_DISCOUNT_EDIT_TAB: "discount/SET_DISCOUNT_EDIT_TAB",

  SET_DISCOUNTS: "discount/SET_DISCOUNTS",
  SET_ORDER_DISCOUNTS: "discount/SET_ORDER_DISCOUNTS",
  SET_DISCOUNT: "discount/SET_DISCOUNT",
  SET_CREATE_DISCOUNT: "discount/SET_CREATE_DISCOUNT",
  SET_UPDATE_DISCOUNT: "discount/SET_UPDATE_DISCOUNT",
  SET_VERIFY_COUPON_DISCOUNT: "discount/SET_VERIFY_COUPON_DISCOUNT",
  SET_VERIFY_LOYAPRO_DISCOUNT: "discount/SET_VERIFY_LOYAPRO_DISCOUNT",

  SET_IS_DISCOUNTS_HAS_MORE: "discount/SET_IS_DISCOUNTS_HAS_MORE",
  SET_IS_ORDER_DISCOUNTS_HAS_MORE: "discount/SET_IS_ORDER_DISCOUNTS_HAS_MORE",

  SET_FETCH_DISCOUNTS_SEARCH: "discount/SET_FETCH_DISCOUNTS_SEARCH",
  SET_FETCH_DISCOUNTS_PAGE: "discount/SET_FETCH_DISCOUNTS_PAGE",
  SET_FETCH_DISCOUNTS_PER_PAGE: "discount/SET_FETCH_DISCOUNTS_PER_PAGE",
  SET_FETCH_DISCOUNTS_INCLUDES: "discount/SET_FETCH_DISCOUNTS_INCLUDES",
  SET_FETCH_DISCOUNTS_FILTER_MARKET_ID:
    "discount/SET_FETCH_DISCOUNTS_FILTER_MARKET_ID",
  SET_FETCH_DISCOUNTS_FILTER_BRANCH_ID:
    "discount/SET_FETCH_DISCOUNTS_FILTER_BRANCH_ID",
  SET_FETCH_DISCOUNTS_FILTER_TYPES: "discount/SET_FETCH_DISCOUNTS_FILTER_TYPES",
  SET_FETCH_DISCOUNTS_FILTER_EXPIRED_AT:
    "discount/SET_FETCH_DISCOUNTS_FILTER_EXPIRED_AT",
  SET_FETCH_DISCOUNTS_LOADING: "discount/SET_FETCH_DISCOUNTS_LOADING",
  SET_FETCH_DISCOUNTS_SUCCESS: "discount/SET_FETCH_DISCOUNTS_SUCCESS",
  SET_FETCH_DISCOUNTS_FAILED: "discount/SET_FETCH_DISCOUNTS_FAILED",

  SET_FETCH_ORDER_DISCOUNTS_SEARCH: "discount/SET_FETCH_ORDER_DISCOUNTS_SEARCH",
  SET_FETCH_ORDER_DISCOUNTS_PAGE: "discount/SET_FETCH_ORDER_DISCOUNTS_PAGE",
  SET_FETCH_ORDER_DISCOUNTS_PER_PAGE:
    "discount/SET_FETCH_ORDER_DISCOUNTS_PER_PAGE",
  SET_FETCH_ORDER_DISCOUNTS_INCLUDES:
    "discount/SET_FETCH_ORDER_DISCOUNTS_INCLUDES",
  SET_FETCH_ORDER_DISCOUNTS_FILTER_MARKET_ID:
    "discount/SET_FETCH_ORDER_DISCOUNTS_FILTER_MARKET_ID",
  SET_FETCH_ORDER_DISCOUNTS_FILTER_BRANCH_ID:
    "discount/SET_FETCH_ORDER_DISCOUNTS_FILTER_BRANCH_ID",
  SET_FETCH_ORDER_DISCOUNTS_FILTER_TYPES:
    "discount/SET_FETCH_ORDER_DISCOUNTS_FILTER_TYPES",
  SET_FETCH_ORDER_DISCOUNTS_FILTER_EXPIRED_AT:
    "discount/SET_FETCH_ORDER_DISCOUNTS_FILTER_EXPIRED_AT",
  SET_FETCH_ORDER_DISCOUNTS_LOADING:
    "discount/SET_FETCH_ORDER_DISCOUNTS_LOADING",
  SET_FETCH_ORDER_DISCOUNTS_SUCCESS:
    "discount/SET_FETCH_ORDER_DISCOUNTS_SUCCESS",
  SET_FETCH_ORDER_DISCOUNTS_FAILED: "discount/SET_FETCH_ORDER_DISCOUNTS_FAILED",

  SET_FETCH_DISCOUNT_LOADING: "discount/SET_FETCH_DISCOUNT_LOADING",
  SET_FETCH_DISCOUNT_SUCCESS: "discount/SET_FETCH_DISCOUNT_SUCCESS",
  SET_FETCH_DISCOUNT_FAILED: "discount/SET_FETCH_DISCOUNT_FAILED",

  SET_CREATE_DISCOUNT_LOADING: "discount/SET_CREATE_DISCOUNT_LOADING",
  SET_CREATE_DISCOUNT_SUCCESS: "discount/SET_CREATE_DISCOUNT_SUCCESS",
  SET_CREATE_DISCOUNT_FAILED: "discount/SET_CREATE_DISCOUNT_FAILED",

  SET_UPDATE_DISCOUNT_LOADING: "discount/SET_UPDATE_DISCOUNT_LOADING",
  SET_UPDATE_DISCOUNT_SUCCESS: "discount/SET_UPDATE_DISCOUNT_SUCCESS",
  SET_UPDATE_DISCOUNT_FAILED: "discount/SET_UPDATE_DISCOUNT_FAILED",

  SET_DELETE_DISCOUNT_LOADING: "discount/SET_DELETE_DISCOUNT_LOADING",
  SET_DELETE_DISCOUNT_SUCCESS: "discount/SET_DELETE_DISCOUNT_SUCCESS",
  SET_DELETE_DISCOUNT_FAILED: "discount/SET_DELETE_DISCOUNT_FAILED",

  SET_VERIFY_COUPON_DISCOUNT_LOADING:
    "discount/SET_VERIFY_COUPON_DISCOUNT_LOADING",
  SET_VERIFY_COUPON_DISCOUNT_SUCCESS:
    "discount/SET_VERIFY_COUPON_DISCOUNT_SUCCESS",
  SET_VERIFY_COUPON_DISCOUNT_FAILED:
    "discount/SET_VERIFY_COUPON_DISCOUNT_FAILED",

  SET_VERIFY_LOYAPRO_DISCOUNT_LOADING:
    "discount/SET_VERIFY_LOYAPRO_DISCOUNT_LOADING",
  SET_VERIFY_LOYAPRO_DISCOUNT_SUCCESS:
    "discount/SET_VERIFY_LOYAPRO_DISCOUNT_SUCCESS",
  SET_VERIFY_LOYAPRO_DISCOUNT_FAILED:
    "discount/SET_VERIFY_LOYAPRO_DISCOUNT_FAILED",

  APPEND_DISCOUNTS: "discount/APPEND_DISCOUNTS",
  APPEND_ORDER_DISCOUNTS: "discount/APPEND_ORDER_DISCOUNTS",

  SET_IS_FETCH_DISCOUNTS_HITTED: "discount/SET_IS_FETCH_DISCOUNTS_HITTED",
  SET_IS_FETCH_ORDER_DISCOUNTS_HITTED:
    "discount/SET_IS_FETCH_ORDER_DISCOUNTS_HITTED",
  SET_IS_FETCH_DISCOUNT_HITTED: "discount/SET_IS_FETCH_DISCOUNT_HITTED",
  SET_IS_CREATE_DISCOUNT_HITTED: "discount/SET_IS_CREATE_DISCOUNT_HITTED",
  SET_IS_UPDATE_DISCOUNT_HITTED: "discount/SET_IS_UPDATE_DISCOUNT_HITTED",
  SET_IS_DELETE_DISCOUNT_HITTED: "discount/SET_IS_DELETE_DISCOUNT_HITTED",
  SET_IS_VERIFY_COUPON_DISCOUNT_HITTED:
    "discount/SET_IS_VERIFY_COUPON_DISCOUNT_HITTED",
  SET_IS_VERIFY_LOYAPRO_DISCOUNT_HITTED:
    "discount/SET_IS_VERIFY_LOYAPRO_DISCOUNT_HITTED",

  FETCH_DISCOUNTS_START: "discount/FETCH_DISCOUNTS_START",
  FETCH_ORDER_DISCOUNTS_START: "discount/FETCH_ORDER_DISCOUNTS_START",
  FETCH_DISCOUNT_START: "discount/FETCH_DISCOUNT_START",
  CREATE_DISCOUNT_START: "discount/CREATE_DISCOUNT_START",
  UPDATE_DISCOUNT_START: "discount/UPDATE_DISCOUNT_START",
  DELETE_DISCOUNT_START: "discount/DELETE_DISCOUNT_START",
  VERIFY_COUPON_DISCOUNT_START: "discount/VERIFY_COUPON_DISCOUNT_START",
  VERIFY_LOYAPRO_DISCOUNT_START: "discount/VERIFY_LOYAPRO_DISCOUNT_START",
};

export default DISCOUNT_ACTION_TYPES;
