import { getRemovedDuplicates } from "../../utils/formatter.utils";

import SECTOR_ACTION_TYPES from "./sector.type";

export const SECTOR_INITIAL_STATE = {
  sectors: [],
  selectSectors: [],
  sector: null,

  isSectorsHasMore: true,
  isSelectSectorsHasMore: true,

  fetchSectorsSearch: null,
  fetchSectorsSort: null,
  fetchSectorsKeyBy: null,
  fetchSectorsPage: 1,
  fetchSectorsPerPage: null,
  fetchSectorsIncludes: null,
  fetchSectorsLoading: false,
  fetchSectorsSuccess: null,
  fetchSectorsFailed: null,

  fetchSelectSectorsSearch: null,
  fetchSelectSectorsSort: null,
  fetchSelectSectorsKeyBy: null,
  fetchSelectSectorsPage: 1,
  fetchSelectSectorsPerPage: null,
  fetchSelectSectorsIncludes: null,
  fetchSelectSectorsLoading: false,
  fetchSelectSectorsSuccess: null,
  fetchSelectSectorsFailed: null,

  fetchSectorLoading: false,
  fetchSectorSuccess: null,
  fetchSectorFailed: null,

  createSectorLoading: false,
  createSectorSuccess: null,
  createSectorFailed: null,

  updateSectorLoading: false,
  updateSectorSuccess: null,
  updateSectorFailed: null,

  deleteSectorLoading: false,
  deleteSectorSuccess: null,
  deleteSectorFailed: null,

  isFetchSectorsHitted: false,
  isFetchSelectSectorsHitted: false,
  isFetchSectorHitted: false,
  isCreateSectorHitted: false,
  isUpdateSectorHitted: false,
  isDeleteSectorHitted: false,
};

export const sectorReducer = (state = SECTOR_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SECTOR_ACTION_TYPES.SET_SECTORS:
      return { ...state, sectors: payload };
    case SECTOR_ACTION_TYPES.SET_SELECT_SECTORS:
      return { ...state, selectSectors: payload };
    case SECTOR_ACTION_TYPES.SET_SECTOR:
      return { ...state, sector: payload };

    case SECTOR_ACTION_TYPES.SET_IS_SECTORS_HAS_MORE:
      return { ...state, isSectorsHasMore: payload };
    case SECTOR_ACTION_TYPES.SET_IS_SELECT_SECTORS_HAS_MORE:
      return { ...state, isSelectSectorsHasMore: payload };

    case SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_SEARCH:
      return { ...state, fetchSectorsSearch: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_SORT:
      return { ...state, fetchSectorsSort: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_KEY_BY:
      return { ...state, fetchSectorsKeyBy: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_PAGE:
      return { ...state, fetchSectorsPage: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_PER_PAGE:
      return { ...state, fetchSectorsPerPage: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_INCLUDES:
      return { ...state, fetchSectorsIncludes: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_LOADING:
      return { ...state, fetchSectorsLoading: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_SUCCESS:
      return { ...state, fetchSectorsSuccess: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_FAILED:
      return { ...state, fetchSectorsFailed: payload };

    case SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_SEARCH:
      return { ...state, fetchSelectSectorsSearch: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_SORT:
      return { ...state, fetchSelectSectorsSort: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_KEY_BY:
      return { ...state, fetchSelectSectorsKeyBy: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_PAGE:
      return { ...state, fetchSelectSectorsPage: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_PER_PAGE:
      return { ...state, fetchSelectSectorsPerPage: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_INCLUDES:
      return { ...state, fetchSelectSectorsIncludes: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_LOADING:
      return { ...state, fetchSelectSectorsLoading: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_SUCCESS:
      return { ...state, fetchSelectSectorsSuccess: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SELECT_SECTORS_FAILED:
      return { ...state, fetchSelectSectorsFailed: payload };

    case SECTOR_ACTION_TYPES.SET_FETCH_SECTOR_LOADING:
      return { ...state, fetchSectorLoading: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SECTOR_SUCCESS:
      return { ...state, fetchSectorSuccess: payload };
    case SECTOR_ACTION_TYPES.SET_FETCH_SECTOR_FAILED:
      return { ...state, fetchSectorFailed: payload };

    case SECTOR_ACTION_TYPES.SET_CREATE_SECTOR_LOADING:
      return { ...state, createSectorLoading: payload };
    case SECTOR_ACTION_TYPES.SET_CREATE_SECTOR_SUCCESS:
      return { ...state, createSectorSuccess: payload };
    case SECTOR_ACTION_TYPES.SET_CREATE_SECTOR_FAILED:
      return { ...state, createSectorFailed: payload };

    case SECTOR_ACTION_TYPES.SET_UPDATE_SECTOR_LOADING:
      return { ...state, updateSectorLoading: payload };
    case SECTOR_ACTION_TYPES.SET_UPDATE_SECTOR_SUCCESS:
      return { ...state, updateSectorSuccess: payload };
    case SECTOR_ACTION_TYPES.SET_UPDATE_SECTOR_FAILED:
      return { ...state, updateSectorFailed: payload };

    case SECTOR_ACTION_TYPES.SET_DELETE_SECTOR_LOADING:
      return { ...state, deleteSectorLoading: payload };
    case SECTOR_ACTION_TYPES.SET_DELETE_SECTOR_SUCCESS:
      return { ...state, deleteSectorSuccess: payload };
    case SECTOR_ACTION_TYPES.SET_DELETE_SECTOR_FAILED:
      return { ...state, deleteSectorFailed: payload };

    case SECTOR_ACTION_TYPES.APPEND_SECTORS:
      return {
        ...state,
        sectors: getRemovedDuplicates([...state.sectors, ...payload], "id"),
      };
    case SECTOR_ACTION_TYPES.APPEND_SELECT_SECTORS:
      return {
        ...state,
        selectSectors: getRemovedDuplicates(
          [...state.selectSectors, ...payload],
          "id"
        ),
      };

    case SECTOR_ACTION_TYPES.IS_FETCH_SECTORS_HITTED:
      return { ...state, isFetchSectorsHitted: payload };
    case SECTOR_ACTION_TYPES.IS_FETCH_SELECT_SECTORS_HITTED:
      return { ...state, isFetchSelectSectorsHitted: payload };
    case SECTOR_ACTION_TYPES.IS_FETCH_SECTOR_HITTED:
      return { ...state, isFetchSectorHitted: payload };
    case SECTOR_ACTION_TYPES.IS_CREATE_SECTOR_HITTED:
      return { ...state, isCreateSectorHitted: payload };
    case SECTOR_ACTION_TYPES.IS_UPDATE_SECTOR_HITTED:
      return { ...state, isUpdateSectorHitted: payload };
    case SECTOR_ACTION_TYPES.IS_DELETE_SECTOR_HITTED:
      return { ...state, isDeleteSectorHitted: payload };

    case SECTOR_ACTION_TYPES.RESET_SECTOR_REDUCER:
      return SECTOR_INITIAL_STATE;
    default:
      return state;
  }
};
