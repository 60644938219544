import BRANCH_ACTION_TYPES from "./branch.type";

export const BRANCH_INITIAL_STATE = {
  isModalAutoClose: true,

  branches: [],
  branch: null,
  subscriptionBranches: [],
  subscriptionBranch: null,

  isBranchesHasMore: true,
  isSubscriptionBranchesHasMore: true,

  fetchBranchesSearch: null,
  fetchBranchesPage: 1,
  fetchBranchesPerPage: null,
  fetchBranchesIncludes: null,
  fetchBranchesFilterMarketId: null,
  fetchBranchesFilterIsActive: null,
  fetchBranchesLoading: false,
  fetchBranchesSuccess: null,
  fetchBranchesFailed: null,

  fetchSubscriptionBranchesPage: 1,
  fetchSubscriptionBranchesPerPage: null,
  fetchSubscriptionBranchesIncludes: null,
  fetchSubscriptionBranchesFilterMarketId: null,
  fetchSubscriptionBranchesFilterIsActive: null,
  fetchSubscriptionBranchesLoading: false,
  fetchSubscriptionBranchesSuccess: null,
  fetchSubscriptionBranchesFailed: null,

  fetchBranchLoading: false,
  fetchBranchSuccess: null,
  fetchBranchFailed: null,

  fetchSubscriptionBranchLoading: false,
  fetchSubscriptionBranchSuccess: null,
  fetchSubscriptionBranchFailed: null,

  createBranchLoading: false,
  createBranchSuccess: null,
  createBranchFailed: null,

  updateBranchLoading: false,
  updateBranchSuccess: null,
  updateBranchFailed: null,

  deleteBranchLoading: false,
  deleteBranchSuccess: null,
  deleteBranchFailed: null,

  isFetchBranchesHitted: false,
  isFetchSubscriptionBranchesHitted: false,
  isFetchBranchHitted: false,
  isFetchSubscriptionBranchHitted: false,
  isCreateBranchHitted: false,
  isUpdateBranchHitted: false,
  isDeleteBranchHitted: false,
};

export const branchReducer = (state = BRANCH_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case BRANCH_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE:
      return { ...state, isModalAutoClose: payload };

    case BRANCH_ACTION_TYPES.SET_BRANCHES:
      return { ...state, branches: payload };
    case BRANCH_ACTION_TYPES.SET_BRANCH:
      return { ...state, branch: payload };
    case BRANCH_ACTION_TYPES.SET_SUBSCRIPTION_BRANCHES:
      return { ...state, subscriptionBranches: payload };
    case BRANCH_ACTION_TYPES.SET_SUBSCRIPTION_BRANCH:
      return { ...state, subscriptionBranch: payload };

    case BRANCH_ACTION_TYPES.SET_IS_BRANCHES_HAS_MORE:
      return { ...state, isBranchesHasMore: payload };
    case BRANCH_ACTION_TYPES.SET_IS_SUBSCRIPTION_BRANCHES_HAS_MORE:
      return { ...state, isSubscriptionBranchesHasMore: payload };

    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SEARCH:
      return { ...state, fetchBranchesSearch: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PAGE:
      return { ...state, fetchBranchesPage: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PER_PAGE:
      return { ...state, fetchBranchesPerPage: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_INCLUDES:
      return { ...state, fetchBranchesIncludes: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FILTER_MARKET_ID:
      return { ...state, fetchBranchesFilterMarketId: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FILTER_IS_ACTIVE:
      return { ...state, fetchBranchesFilterIsActive: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_LOADING:
      return { ...state, fetchBranchesLoading: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SUCCESS:
      return { ...state, fetchBranchesSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FAILED:
      return { ...state, fetchBranchesFailed: payload };

    case BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_PAGE:
      return { ...state, fetchSubscriptionBranchesPage: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_PER_PAGE:
      return { ...state, fetchSubscriptionBranchesPerPage: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_INCLUDES:
      return { ...state, fetchSubscriptionBranchesIncludes: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_FILTER_MARKET_ID:
      return { ...state, fetchSubscriptionBranchesFilterMarketId: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_FILTER_IS_ACTIVE:
      return { ...state, fetchSubscriptionBranchesFilterIsActive: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_LOADING:
      return { ...state, fetchSubscriptionBranchesLoading: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_SUCCESS:
      return { ...state, fetchSubscriptionBranchesSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCHES_FAILED:
      return { ...state, fetchSubscriptionBranchesFailed: payload };

    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_LOADING:
      return { ...state, fetchBranchLoading: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_SUCCESS:
      return { ...state, fetchBranchSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_FAILED:
      return { ...state, fetchBranchFailed: payload };

    case BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCH_LOADING:
      return { ...state, fetchSubscriptionBranchLoading: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCH_SUCCESS:
      return { ...state, fetchSubscriptionBranchSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_BRANCH_FAILED:
      return { ...state, fetchSubscriptionBranchFailed: payload };

    case BRANCH_ACTION_TYPES.SET_CREATE_BRANCH_LOADING:
      return { ...state, createBranchLoading: payload };
    case BRANCH_ACTION_TYPES.SET_CREATE_BRANCH_SUCCESS:
      return { ...state, createBranchSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_CREATE_BRANCH_FAILED:
      return { ...state, createBranchFailed: payload };

    case BRANCH_ACTION_TYPES.SET_UPDATE_BRANCH_LOADING:
      return { ...state, updateBranchLoading: payload };
    case BRANCH_ACTION_TYPES.SET_UPDATE_BRANCH_SUCCESS:
      return { ...state, updateBranchSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_UPDATE_BRANCH_FAILED:
      return { ...state, updateBranchFailed: payload };

    case BRANCH_ACTION_TYPES.SET_DELETE_BRANCH_LOADING:
      return { ...state, deleteBranchLoading: payload };
    case BRANCH_ACTION_TYPES.SET_DELETE_BRANCH_SUCCESS:
      return { ...state, deleteBranchSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_DELETE_BRANCH_FAILED:
      return { ...state, deleteBranchFailed: payload };

    case BRANCH_ACTION_TYPES.APPEND_BRANCHES:
      return { ...state, branches: [...state.branches, ...payload] };
    case BRANCH_ACTION_TYPES.APPEND_SUBSCRIPTION_BRANCHES:
      return {
        ...state,
        subscriptionBranches: [...state.subscriptionBranches, ...payload],
      };

    case BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCHES_HITTED:
      return { ...state, isFetchBranchesHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_FETCH_SUBSCRIPTION_BRANCHES_HITTED:
      return { ...state, isFetchSubscriptionBranchesHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCH_HITTED:
      return { ...state, isFetchBranchHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_FETCH_SUBSCRIPTION_BRANCH_HITTED:
      return { ...state, isFetchSubscriptionBranchHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_CREATE_BRANCH_HITTED:
      return { ...state, isCreateBranchHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_UPDATE_BRANCH_HITTED:
      return { ...state, isUpdateBranchHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_DELETE_BRANCH_HITTED:
      return { ...state, isDeleteBranchHitted: payload };

    case BRANCH_ACTION_TYPES.RESET_BRANCH_REDUCER:
      return BRANCH_INITIAL_STATE;
    default:
      return state;
  }
};
