const SELECT_MARKET_ACTION_TYPES = {
  RESET_SELECT_MARKET_REDUCER: "select-market/RESET_SELECT_MARKET_REDUCER",

  SET_MARKETS: "select-market/SET_MARKETS",
  SET_SEARCH_MARKETS: "select-market/SET_SEARCH_MARKETS",

  APPEND_MARKETS: "select-market/APPEND_MARKETS",
  APPEND_SEARCH_MARKETS: "select-market/APPEND_SEARCH_MARKETS",

  SET_IS_MARKETS_HAS_MORE: "select-market/SET_IS_MARKETS_HAS_MORE",
  SET_IS_SEARCH_MARKETS_HAS_MORE:
    "select-market/SET_IS_SEARCH_MARKETS_HAS_MORE",

  SET_FETCH_MARKETS_PARAMS: "select-market/SET_FETCH_MARKETS_PARAMS",
  SET_FETCH_MARKETS_LOADING: "select-market/SET_FETCH_MARKETS_LOADING",
  SET_FETCH_MARKETS_SUCCESS: "select-market/SET_FETCH_MARKETS_SUCCESS",
  SET_FETCH_MARKETS_FAILED: "select-market/SET_FETCH_MARKETS_FAILED",

  SET_SEARCH_MARKETS_PARAMS: "select-market/SET_SEARCH_MARKETS_PARAMS",
  SET_SEARCH_MARKETS_LOADING: "select-market/SET_SEARCH_MARKETS_LOADING",
  SET_SEARCH_MARKETS_SUCCESS: "select-market/SET_SEARCH_MARKETS_SUCCESS",
  SET_SEARCH_MARKETS_FAILED: "select-market/SET_SEARCH_MARKETS_FAILED",

  SET_APPEND_MARKETS_PARAMS: "select-market/SET_APPEND_MARKETS_PARAMS",
  SET_APPEND_MARKETS_LOADING: "select-market/SET_APPEND_MARKETS_LOADING",
  SET_APPEND_MARKETS_SUCCESS: "select-market/SET_APPEND_MARKETS_SUCCESS",
  SET_APPEND_MARKETS_FAILED: "select-market/SET_APPEND_MARKETS_FAILED",

  SET_IS_FETCH_MARKETS_HITTED: "select-market/SET_IS_FETCH_MARKETS_HITTED",
  SET_IS_SEARCH_MARKETS_HITTED: "select-market/SET_IS_SEARCH_MARKETS_HITTED",
  SET_IS_APPEND_MARKETS_HITTED: "select-market/SET_IS_APPEND_MARKETS_HITTED",

  FETCH_MARKETS_START: "select-market/FETCH_MARKETS_START",
  SEARCH_MARKETS_START: "select-market/SEARCH_MARKETS_START",
  APPEND_MARKETS_START: "select-market/APPEND_MARKETS_START",
};

export default SELECT_MARKET_ACTION_TYPES;
