import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { UPLOAD_TYPES } from "../../constants/upload.constant";

import { getValidFiles, getValidUrls } from "../../utils/store.utils";
import { isEmpty } from "../../utils/validation.utils";

import PRODUCT_ACTION_TYPES from "./product-quick.type";

import {
  setCreateProduct,
  setCreateProductFailed,
  setCreateProductLoading,
  setCreateProductsFailed,
  setCreateProductsLoading,
  setCreateProductsSuccess,
  setCreateProductSuccess,
  setIsCreateProductHitted,
  setIsCreateProductsHitted,
  setIsUpdateProductHitted,
  setUpdateProduct,
  setUpdateProductFailed,
  setUpdateProductLoading,
  setUpdateProductSuccess,
} from "./product-quick.action";
import {
  setFetchOrderProductsPage,
  setFetchProductsPage,
} from "../product/product.action";
import {
  getIsFetchOrderProductsHitted,
  getIsFetchProductsHitted,
} from "../product/product.selector";
import { _getOrderProducts, _getProducts } from "../product/product.saga";
import {
  getFetchProductsParams,
  getIsFetchProductsHitted as getIsFetchSelectProductsHitted,
} from "../select-product/select-product.selector";
import { _getFetchProducts as _getSelectProducts } from "../select-product/select-product.saga";

import {
  quickCreateProduct,
  quickMultiCreateProducts,
  quickUpdateProduct,
} from "../../api/product.api";
import { uploadFiles } from "../../api/upload.api";

export function* _createProduct({ payload: request }) {
  try {
    yield put(setCreateProductLoading(true));

    const payload = { ...request };
    if (!isEmpty(payload?.images)) {
      const files = getValidFiles(payload?.images);

      if (!isEmpty(files)) {
        const prevUrls = getValidUrls(payload.images);
        const request = { type: UPLOAD_TYPES.PRODUCT, files };

        const { data: imageUrls } = yield call(uploadFiles, request);

        payload.images = [...prevUrls, ...imageUrls];
      }
    }

    const {
      meta: { message },
      data: product,
    } = yield call(quickCreateProduct, payload);

    yield put(setIsCreateProductHitted(true));
    yield put(setCreateProduct(product));

    const isFetchProductsHitted = yield select(getIsFetchProductsHitted);
    const isFetchOrderProductsHitted = yield select(
      getIsFetchOrderProductsHitted
    );
    const isFetchSelectProductsHitted = yield select(
      getIsFetchSelectProductsHitted
    );

    if (isFetchProductsHitted) {
      yield put(setFetchProductsPage(1));
      yield call(_getProducts);
    }
    if (isFetchOrderProductsHitted) {
      yield put(setFetchOrderProductsPage(1));
      yield call(_getOrderProducts);
    }
    if (isFetchSelectProductsHitted) {
      const params = yield select(getFetchProductsParams);
      yield call(_getSelectProducts, { payload: { ...params, page: 1 } });
    }

    yield put(setCreateProductSuccess(message));
    yield put(setCreateProductLoading(false));
  } catch (error) {
    yield put(setCreateProductFailed(error));
    yield put(setCreateProductLoading(false));
  }
}
export function* _updateProduct({ payload: { productId, request } }) {
  try {
    yield put(setUpdateProductLoading(true));

    const payload = { ...request };
    if (!isEmpty(payload?.images)) {
      const files = getValidFiles(payload?.images);

      if (!isEmpty(files)) {
        const prevUrls = getValidUrls(payload.images);
        const request = { type: UPLOAD_TYPES.PRODUCT, files };

        const { data: imageUrls } = yield call(uploadFiles, request);

        payload.images = [...prevUrls, ...imageUrls];
      }
    }

    const {
      meta: { message },
      data: product,
    } = yield call(quickUpdateProduct, productId, payload);

    yield put(setIsUpdateProductHitted(true));
    yield put(setUpdateProduct(product));

    const isFetchProductsHitted = yield select(getIsFetchProductsHitted);
    const isFetchOrderProductsHitted = yield select(
      getIsFetchOrderProductsHitted
    );
    const isFetchSelectProductsHitted = yield select(
      getIsFetchSelectProductsHitted
    );

    if (isFetchProductsHitted) {
      yield put(setFetchProductsPage(1));
      yield call(_getProducts);
    }
    if (isFetchOrderProductsHitted) {
      yield put(setFetchOrderProductsPage(1));
      yield call(_getOrderProducts);
    }
    if (isFetchSelectProductsHitted) {
      const params = yield select(getFetchProductsParams);
      yield call(_getSelectProducts, { payload: { ...params, page: 1 } });
    }

    yield put(setUpdateProductSuccess(message));
    yield put(setUpdateProductLoading(false));
  } catch (error) {
    yield put(setUpdateProductFailed(error));
    yield put(setUpdateProductLoading(false));
  }
}
export function* _createProducts({ payload: request }) {
  try {
    yield put(setCreateProductsLoading(true));

    const payload = { ...request };
    if (!isEmpty(payload?.images)) {
      const files = getValidFiles(payload?.images);

      if (!isEmpty(files)) {
        const prevUrls = getValidUrls(payload.images);
        const request = { type: UPLOAD_TYPES.PRODUCT, files };

        const { data: imageUrls } = yield call(uploadFiles, request);

        payload.images = [...prevUrls, ...imageUrls];
      }
    }

    const {
      meta: { message },
    } = yield call(quickMultiCreateProducts, payload);

    yield put(setIsCreateProductsHitted(true));

    const isFetchProductsHitted = yield select(getIsFetchProductsHitted);
    const isFetchOrderProductsHitted = yield select(
      getIsFetchOrderProductsHitted
    );
    const isFetchSelectProductsHitted = yield select(
      getIsFetchSelectProductsHitted
    );

    if (isFetchProductsHitted) {
      yield put(setFetchProductsPage(1));
      yield call(_getProducts);
    }
    if (isFetchOrderProductsHitted) {
      yield put(setFetchOrderProductsPage(1));
      yield call(_getOrderProducts);
    }
    if (isFetchSelectProductsHitted) {
      const params = yield select(getFetchProductsParams);
      yield call(_getSelectProducts, { payload: { ...params, page: 1 } });
    }

    yield put(setCreateProductsSuccess(message));
    yield put(setCreateProductsLoading(false));
  } catch (error) {
    yield put(setCreateProductsFailed(error));
    yield put(setCreateProductsLoading(false));
  }
}

export function* onCreateProductStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.CREATE_PRODUCT_START, _createProduct);
}
export function* onUpdateProductStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_START, _updateProduct);
}
export function* onCreateProductsStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.CREATE_PRODUCTS_START, _createProducts);
}

export function* productQuickSaga() {
  yield all([
    call(onCreateProductStart),
    call(onUpdateProductStart),
    call(onCreateProductsStart),
  ]);
}
