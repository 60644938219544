import { createAction } from "../../utils/store.utils";

import FORM_CUSTOMER_ACTION_TYPES from "./form-customer.type";

export const setSearchInputValues = (searchInputValues) =>
  createAction(
    FORM_CUSTOMER_ACTION_TYPES.SEARCH_INPUT_VALUES,
    searchInputValues
  );
export const setCreateInputValues = (createInputValues) =>
  createAction(
    FORM_CUSTOMER_ACTION_TYPES.CREATE_INPUT_VALUES,
    createInputValues
  );
export const setEditInputValues = (editInputValues) =>
  createAction(FORM_CUSTOMER_ACTION_TYPES.EDIT_INPUT_VALUES, editInputValues);

export const resetFormCustomerReducer = () =>
  createAction(FORM_CUSTOMER_ACTION_TYPES.RESET_FORM_CUSTOMER_REDUCER);
