import { getRemovedDuplicates } from "../../utils/formatter.utils";

import ATTENDANCE_ACTION_TYPES from "./attendance.type";

export const ATTENDANCE_INITIAL_STATE = {
  attendances: [],
  attendance: null,

  isAttendancesHasMore: true,

  fetchAttendancesSearch: null,
  fetchAttendancesSort: null,
  fetchAttendancesPage: 1,
  fetchAttendancesPerPage: null,
  fetchAttendancesIncludes: null,
  fetchAttendancesFilterMarketId: null,
  fetchAttendancesFilterBranchId: null,
  fetchAttendancesFilterUserId: null,
  fetchAttendancesLoading: false,
  fetchAttendancesSuccess: null,
  fetchAttendancesFailed: null,

  fetchAttendanceMarketId: null,
  fetchAttendanceBranchId: null,
  fetchAttendanceLoading: false,
  fetchAttendanceSuccess: null,
  fetchAttendanceFailed: null,

  createOrUpdateAttendanceLoading: false,
  createOrUpdateAttendanceSuccess: null,
  createOrUpdateAttendanceFailed: null,

  isFetchAttendancesHitted: false,
  isFetchAttendanceHitted: false,
  isCreateOrUpdateAttendanceHitted: false,
};

export const attendanceReducer = (state = ATTENDANCE_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ATTENDANCE_ACTION_TYPES.SET_ATTENDANCES:
      return { ...state, attendances: payload };
    case ATTENDANCE_ACTION_TYPES.SET_ATTENDANCE:
      return { ...state, attendance: payload };

    case ATTENDANCE_ACTION_TYPES.SET_IS_ATTENDANCES_HAS_MORE:
      return { ...state, isAttendancesHasMore: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_SEARCH:
      return { ...state, fetchAttendancesSearch: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_SORT:
      return { ...state, fetchAttendancesSort: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_PAGE:
      return { ...state, fetchAttendancesPage: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_PER_PAGE:
      return { ...state, fetchAttendancesPerPage: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_INCLUDES:
      return { ...state, fetchAttendancesIncludes: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_FILTER_MARKET_ID:
      return { ...state, fetchAttendancesFilterMarketId: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_FILTER_BRANCH_ID:
      return { ...state, fetchAttendancesFilterBranchId: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_FILTER_USER_ID:
      return { ...state, fetchAttendancesFilterUserId: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_LOADING:
      return { ...state, fetchAttendancesLoading: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_SUCCESS:
      return { ...state, fetchAttendancesSuccess: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCES_FAILED:
      return { ...state, fetchAttendancesFailed: payload };

    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCE_MARKET_ID:
      return { ...state, fetchAttendanceMarketId: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCE_BRANCH_ID:
      return { ...state, fetchAttendanceBranchId: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCE_LOADING:
      return { ...state, fetchAttendanceLoading: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCE_SUCCESS:
      return { ...state, fetchAttendanceSuccess: payload };
    case ATTENDANCE_ACTION_TYPES.SET_FETCH_ATTENDANCE_FAILED:
      return { ...state, fetchAttendanceFailed: payload };

    case ATTENDANCE_ACTION_TYPES.SET_CREATE_OR_UPDATE_ATTENDANCE_LOADING:
      return { ...state, createOrUpdateAttendanceLoading: payload };
    case ATTENDANCE_ACTION_TYPES.SET_CREATE_OR_UPDATE_ATTENDANCE_SUCCESS:
      return { ...state, createOrUpdateAttendanceSuccess: payload };
    case ATTENDANCE_ACTION_TYPES.SET_CREATE_OR_UPDATE_ATTENDANCE_FAILED:
      return { ...state, createOrUpdateAttendanceFailed: payload };

    case ATTENDANCE_ACTION_TYPES.APPEND_ATTENDANCES:
      return { ...state, attendances: getRemovedDuplicates([...state.attendances, ...payload], 'id') };

    case ATTENDANCE_ACTION_TYPES.IS_FETCH_ATTENDANCES_HITTED:
      return { ...state, isFetchAttendancesHitted: payload };
    case ATTENDANCE_ACTION_TYPES.IS_FETCH_ATTENDANCE_HITTED:
      return { ...state, isFetchAttendanceHitted: payload };
    case ATTENDANCE_ACTION_TYPES.IS_CREATE_OR_UPDATE_ATTENDANCE_HITTED:
      return { ...state, isCreateOrUpdateAttendanceHitted: payload };

    case ATTENDANCE_ACTION_TYPES.RESET_ATTENDANCE_REDUCER:
      return ATTENDANCE_INITIAL_STATE;
    default:
      return state;
  }
};
