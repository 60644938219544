import EXPORT_ACTION_TYPES from "./export.type";
import { createAction } from "../../utils/store.utils";

export const setReportOrderFileUrl = (reportOrderFileUrl) =>
  createAction(EXPORT_ACTION_TYPES.SET_REPORT_ORDER_FILE_URL, reportOrderFileUrl);
export const setReportOrderFileName = (reportOrderFileName) =>
  createAction(EXPORT_ACTION_TYPES.SET_REPORT_ORDER_FILE_NAME, reportOrderFileName);
export const setReportSummaryFileUrl = (reportSummaryFileUrl) =>
  createAction(EXPORT_ACTION_TYPES.SET_REPORT_SUMMARY_FILE_URL, reportSummaryFileUrl);
export const setReportSummaryFileName = (reportSummaryFileName) =>
  createAction(EXPORT_ACTION_TYPES.SET_REPORT_SUMMARY_FILE_NAME, reportSummaryFileName);

export const setCustomerReportUrlFile = (customerReportUrlFile) =>
  createAction(EXPORT_ACTION_TYPES.SET_CUSTOMER_REPORT_URL_FILE, customerReportUrlFile);
export const setCustomerReportFileName = (customerReportFileName) =>
  createAction(EXPORT_ACTION_TYPES.SET_CUSTOMER_REPORT_FILE_NAME, customerReportFileName);
export const setIngredientReportUrlFile = (ingredientReportUrlFile) =>
  createAction(EXPORT_ACTION_TYPES.SET_INGREDIENT_REPORT_URL_FILE, ingredientReportUrlFile);
export const setIngredientReportFileName = (ingredientReportFileName) =>
  createAction(EXPORT_ACTION_TYPES.SET_INGREDIENT_REPORT_FILE_NAME, ingredientReportFileName);
export const setIngredientHistoryReportUrlFile = (ingredientHistoryReportUrlFile) =>
  createAction(EXPORT_ACTION_TYPES.SET_INGREDIENT_HISTORY_REPORT_URL_FILE, ingredientHistoryReportUrlFile);
export const setIngredientHistoryReportFileName = (ingredientHistoryReportFileName) =>
  createAction(EXPORT_ACTION_TYPES.SET_INGREDIENT_HISTORY_REPORT_FILE_NAME, ingredientHistoryReportFileName);
export const setTableQrCodeUrlFile = (tableQrCodeUrlFile) =>
  createAction(EXPORT_ACTION_TYPES.SET_TABLE_QRCODE_URL_FILE, tableQrCodeUrlFile);
export const setTableQrCodeFileName = (tableQrCodeFileName) =>
  createAction(EXPORT_ACTION_TYPES.SET_TABLE_QRCODE_FILE_NAME, tableQrCodeFileName);
export const setProductBarcodeUrlFile = (productBarcodeUrlFile) =>
  createAction(EXPORT_ACTION_TYPES.SET_PRODUCT_BARCODE_URL_FILE, productBarcodeUrlFile);
export const setProductBarcodeFileName = (productBarcodeFileName) =>
  createAction(EXPORT_ACTION_TYPES.SET_PRODUCT_BARCODE_FILE_NAME, productBarcodeFileName);
export const setSubscriptionReceiptUrlFile = (subscriptionReceiptUrlFile) =>
  createAction(EXPORT_ACTION_TYPES.SET_SUBSCRIPTION_RECEIPT_URL_FILE, subscriptionReceiptUrlFile);
export const setSubscriptionReceiptFileName = (subscriptionReceiptFileName) =>
  createAction(EXPORT_ACTION_TYPES.SET_SUBSCRIPTION_RECEIPT_FILE_NAME, subscriptionReceiptFileName);

export const setExportReportOrderFilterMarketId = (exportReportOrderFilterMarketId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_MARKET_ID, exportReportOrderFilterMarketId);
export const setExportReportOrderFilterBranchId = (exportReportOrderFilterBranchId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_BRANCH_ID, exportReportOrderFilterBranchId);
export const setExportReportOrderFilterPaymentMethodKey = (exportReportOrderFilterPaymentMethodKey) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_PAYMENT_METHOD_KEY,
    exportReportOrderFilterPaymentMethodKey
  );
export const setExportReportOrderFilterCashierId = (exportReportOrderFilterCashierId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_CASHIER_ID, exportReportOrderFilterCashierId);
export const setExportReportOrderFilterCustomerId = (exportReportOrderFilterCustomerId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_CUSTOMER_ID, exportReportOrderFilterCustomerId);
export const setExportReportOrderFilterCreatedAtBefore = (exportReportOrderFilterCreatedAtBefore) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_CREATED_AT_BEFORE,
    exportReportOrderFilterCreatedAtBefore
  );
export const setExportReportOrderFilterCreatedAtAfter = (exportReportOrderFilterCreatedAtAfter) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_CREATED_AT_AFTER,
    exportReportOrderFilterCreatedAtAfter
  );
export const setExportReportOrderLoading = (exportReportOrderLoading) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_LOADING, exportReportOrderLoading);
export const setExportReportOrderSuccess = (exportReportOrderSuccess) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_SUCCESS, exportReportOrderSuccess);
export const setExportReportOrderFailed = (exportReportOrderFailed) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FAILED, exportReportOrderFailed);

export const setExportReportSummaryFilterMarketId = (exportReportSummaryFilterMarketId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_MARKET_ID, exportReportSummaryFilterMarketId);
export const setExportReportSummaryFilterBranchId = (exportReportSummaryFilterBranchId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_BRANCH_ID, exportReportSummaryFilterBranchId);
export const setExportReportSummaryFilterPaymentMethodKey = (exportReportSummaryFilterPaymentMethodKey) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_PAYMENT_METHOD_KEY,
    exportReportSummaryFilterPaymentMethodKey
  );
export const setExportReportSummaryFilterCashierId = (exportReportSummaryFilterCashierId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_CASHIER_ID, exportReportSummaryFilterCashierId);
export const setExportReportSummaryFilterCustomerId = (exportReportSummaryFilterCustomerId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_CUSTOMER_ID, exportReportSummaryFilterCustomerId);
export const setExportReportSummaryFilterCreatedAtBefore = (exportReportSummaryFilterCreatedAtBefore) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_CREATED_AT_BEFORE,
    exportReportSummaryFilterCreatedAtBefore
  );
export const setExportReportSummaryFilterCreatedAtAfter = (exportReportSummaryFilterCreatedAtAfter) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_CREATED_AT_AFTER,
    exportReportSummaryFilterCreatedAtAfter
  );
export const setExportReportSummaryLoading = (exportReportSummaryLoading) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_LOADING, exportReportSummaryLoading);
export const setExportReportSummarySuccess = (exportReportSummarySuccess) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_SUCCESS, exportReportSummarySuccess);
export const setExportReportSummaryFailed = (exportReportSummaryFailed) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FAILED, exportReportSummaryFailed);

export const setExportCustomerReportFilterMarketId = (exportCustomerReportFilterMarketId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_CUSTOMER_REPORT_FILTER_MARKET_ID, exportCustomerReportFilterMarketId);
export const setExportCustomerReportFilterCustomersIds = (exportCustomerReportFilterCustomersIds) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_CUSTOMER_REPORT_FILTER_CUSTOMERS_IDS,
    exportCustomerReportFilterCustomersIds
  );
export const setExportCustomerReportLoading = (exportCustomerReportLoading) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_CUSTOMER_REPORT_LOADING, exportCustomerReportLoading);
export const setExportCustomerReportSuccess = (exportCustomerReportSuccess) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_CUSTOMER_REPORT_SUCCESS, exportCustomerReportSuccess);
export const setExportCustomerReportFailed = (exportCustomerReportFailed) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_CUSTOMER_REPORT_FAILED, exportCustomerReportFailed);

export const setExportIngredientReportFilterPeriodType = (exportIngredientReportFilterPeriodType) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FILTER_PERIOD_TYPE,
    exportIngredientReportFilterPeriodType
  );
export const setExportIngredientReportFilterStartAt = (exportIngredientReportFilterStartAt) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FILTER_START_AT, exportIngredientReportFilterStartAt);
export const setExportIngredientReportFilterEndAt = (exportIngredientReportFilterEndAt) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FILTER_END_AT, exportIngredientReportFilterEndAt);
export const setExportIngredientReportFilterMarketId = (exportIngredientReportFilterMarketId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FILTER_MARKET_ID, exportIngredientReportFilterMarketId);
export const setExportIngredientReportFilterBranchId = (exportIngredientReportFilterBranchId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FILTER_BRANCH_ID, exportIngredientReportFilterBranchId);
export const setExportIngredientReportFilterIngredientsIds = (exportIngredientReportFilterIngredientsIds) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FILTER_INGREDIENTS_IDS,
    exportIngredientReportFilterIngredientsIds
  );
export const setExportIngredientReportLoading = (exportIngredientReportLoading) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_LOADING, exportIngredientReportLoading);
export const setExportIngredientReportSuccess = (exportIngredientReportSuccess) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_SUCCESS, exportIngredientReportSuccess);
export const setExportIngredientReportFailed = (exportIngredientReportFailed) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FAILED, exportIngredientReportFailed);

export const setExportIngredientHistoryReportFilterPeriodType = (exportIngredientHistoryReportFilterPeriodType) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_PERIOD_TYPE,
    exportIngredientHistoryReportFilterPeriodType
  );
export const setExportIngredientHistoryReportFilterStartAt = (exportIngredientHistoryReportFilterStartAt) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_START_AT,
    exportIngredientHistoryReportFilterStartAt
  );
export const setExportIngredientHistoryReportFilterEndAt = (exportIngredientHistoryReportFilterEndAt) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_END_AT,
    exportIngredientHistoryReportFilterEndAt
  );
export const setExportIngredientHistoryReportFilterMarketId = (exportIngredientHistoryReportFilterMarketId) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_MARKET_ID,
    exportIngredientHistoryReportFilterMarketId
  );
export const setExportIngredientHistoryReportFilterBranchId = (exportIngredientHistoryReportFilterBranchId) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_BRANCH_ID,
    exportIngredientHistoryReportFilterBranchId
  );
export const setExportIngredientHistoryReportFilterIngredientsIds = (
  exportIngredientHistoryReportFilterIngredientsIds
) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_INGREDIENTS_IDS,
    exportIngredientHistoryReportFilterIngredientsIds
  );
export const setExportIngredientHistoryReportLoading = (exportIngredientHistoryReportLoading) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_LOADING, exportIngredientHistoryReportLoading);
export const setExportIngredientHistoryReportSuccess = (exportIngredientHistoryReportSuccess) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_SUCCESS, exportIngredientHistoryReportSuccess);
export const setExportIngredientHistoryReportFailed = (exportIngredientHistoryReportFailed) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FAILED, exportIngredientHistoryReportFailed);

export const setExportTableQrCodeFilterMarketId = (exportTableQrCodeFilterMarketId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_TABLE_QRCODE_FILTER_MARKET_ID, exportTableQrCodeFilterMarketId);
export const setExportTableQrCodeFilterBranchId = (exportTableQrCodeFilterBranchId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_TABLE_QRCODE_FILTER_BRANCH_ID, exportTableQrCodeFilterBranchId);
export const setExportTableQrCodeFilterTablesIds = (exportTableQrCodeFilterTablesIds) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_TABLE_QRCODE_FILTER_TABLES_IDS, exportTableQrCodeFilterTablesIds);
export const setExportTableQrCodeLoading = (exportTableQrCodeLoading) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_TABLE_QRCODE_LOADING, exportTableQrCodeLoading);
export const setExportTableQrCodeSuccess = (exportTableQrCodeSuccess) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_TABLE_QRCODE_SUCCESS, exportTableQrCodeSuccess);
export const setExportTableQrCodeFailed = (exportTableQrCodeFailed) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_TABLE_QRCODE_FAILED, exportTableQrCodeFailed);

export const setExportProductBarcodeFilterMarketId = (exportProductBarcodeFilterMarketId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_PRODUCT_BARCODE_FILTER_MARKET_ID, exportProductBarcodeFilterMarketId);
export const setExportProductBarcodeFilterBranchId = (exportProductBarcodeFilterBranchId) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_PRODUCT_BARCODE_FILTER_BRANCH_ID, exportProductBarcodeFilterBranchId);
export const setExportProductBarcodeFilterProductsIds = (exportProductBarcodeFilterProductsIds) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_EXPORT_PRODUCT_BARCODE_FILTER_PRODUCTS_IDS,
    exportProductBarcodeFilterProductsIds
  );
export const setExportProductBarcodeLoading = (exportProductBarcodeLoading) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_PRODUCT_BARCODE_LOADING, exportProductBarcodeLoading);
export const setExportProductBarcodeSuccess = (exportProductBarcodeSuccess) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_PRODUCT_BARCODE_SUCCESS, exportProductBarcodeSuccess);
export const setExportProductBarcodeFailed = (exportProductBarcodeFailed) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_PRODUCT_BARCODE_FAILED, exportProductBarcodeFailed);

export const setExportSubscriptionReceiptLoading = (exportSubscriptionReceiptLoading) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_SUBSCRIPTION_RECEIPT_LOADING, exportSubscriptionReceiptLoading);
export const setExportSubscriptionReceiptSuccess = (exportSubscriptionReceiptSuccess) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_SUBSCRIPTION_RECEIPT_SUCCESS, exportSubscriptionReceiptSuccess);
export const setExportSubscriptionReceiptFailed = (exportSubscriptionReceiptFailed) =>
  createAction(EXPORT_ACTION_TYPES.SET_EXPORT_SUBSCRIPTION_RECEIPT_FAILED, exportSubscriptionReceiptFailed);

export const exportReportOrderStart = () => createAction(EXPORT_ACTION_TYPES.EXPORT_REPORT_ORDER_START);
export const exportReportSummaryStart = () => createAction(EXPORT_ACTION_TYPES.EXPORT_REPORT_SUMMARY_START);
export const exportCustomerReportStart = () => createAction(EXPORT_ACTION_TYPES.EXPORT_CUSTOMER_REPORT_START);
export const exportIngredientReportStart = () => createAction(EXPORT_ACTION_TYPES.EXPORT_INGREDIENT_REPORT_START);
export const exportIngredientHistoryReportStart = () =>
  createAction(EXPORT_ACTION_TYPES.EXPORT_INGREDIENT_HISTORY_REPORT_START);
export const exportTableQrCodeStart = () => createAction(EXPORT_ACTION_TYPES.EXPORT_TABLE_QRCODE_START);
export const exportProductBarcodeStart = () => createAction(EXPORT_ACTION_TYPES.EXPORT_PRODUCT_BARCODE_START);
export const exportSubscriptionReceiptStart = (id) =>
  createAction(EXPORT_ACTION_TYPES.EXPORT_SUBSCRIPTION_RECEIPT_START, id);

export const setIsExportReportOrderHitted = (isExportReportOrderHitted) =>
  createAction(EXPORT_ACTION_TYPES.SET_IS_EXPORT_REPORT_ORDER_HITTED, isExportReportOrderHitted);
export const setIsExportReportSummaryHitted = (isExportReportSummaryHitted) =>
  createAction(EXPORT_ACTION_TYPES.SET_IS_EXPORT_REPORT_SUMMARY_HITTED, isExportReportSummaryHitted);
export const setIsExportCustomerReportHitted = (isExportCustomerReportHitted) =>
  createAction(EXPORT_ACTION_TYPES.SET_IS_EXPORT_CUSTOMER_REPORT_HITTED, isExportCustomerReportHitted);
export const setIsExportIngredientReportHitted = (isExportIngredientReportHitted) =>
  createAction(EXPORT_ACTION_TYPES.SET_IS_EXPORT_INGREDIENT_REPORT_HITTED, isExportIngredientReportHitted);
export const setIsExportIngredientHistoryReportHitted = (isExportIngredientHistoryReportHitted) =>
  createAction(
    EXPORT_ACTION_TYPES.SET_IS_EXPORT_INGREDIENT_HISTORY_REPORT_HITTED,
    isExportIngredientHistoryReportHitted
  );
export const setIsExportTableQrCodeHitted = (isExportTableQrCodeHitted) =>
  createAction(EXPORT_ACTION_TYPES.SET_IS_EXPORT_TABLE_QRCODE_HITTED, isExportTableQrCodeHitted);
export const setIsExportProductBarcodeHitted = (isExportProductBarcodeHitted) =>
  createAction(EXPORT_ACTION_TYPES.SET_IS_EXPORT_PRODUCT_BARCODE_HITTED, isExportProductBarcodeHitted);
export const setIsExportSubscriptionReceiptHitted = (isExportSubscriptionReceiptHitted) =>
  createAction(EXPORT_ACTION_TYPES.SET_IS_EXPORT_SUBSCRIPTION_RECEIPT_HITTED, isExportSubscriptionReceiptHitted);

export const resetExportReducer = () => createAction(EXPORT_ACTION_TYPES.RESET_EXPORT_REDUCER);
