import { createAction } from "../../utils/store.utils";

import SUBSCRIPTION_ACTION_TYPES from "./subscription.type";

export const setSubscription = (subscription) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTION, subscription);

export const setIsSubscriptionsHasMore = (isSubscriptionsHasMore) =>
  createAction(
    SUBSCRIPTION_ACTION_TYPES.SET_IS_SUBSCRIPTIONS_HAS_MORE,
    isSubscriptionsHasMore
  );

export const setFetchSubscriptionLoading = (fetchSubscriptionLoading) =>
  createAction(
    SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_LOADING,
    fetchSubscriptionLoading
  );
export const setFetchSubscriptionSuccess = (fetchSubscriptionSuccess) =>
  createAction(
    SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_SUCCESS,
    fetchSubscriptionSuccess
  );
export const setFetchSubscriptionFailed = (fetchSubscriptionFailed) =>
  createAction(
    SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_FAILED,
    fetchSubscriptionFailed
  );

export const setUpdateSubscriptionLoading = (updateSubscriptionLoading) =>
  createAction(
    SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_SUBSCRIPTION_LOADING,
    updateSubscriptionLoading
  );
export const setUpdateSubscriptionSuccess = (updateSubscriptionSuccess) =>
  createAction(
    SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_SUBSCRIPTION_SUCCESS,
    updateSubscriptionSuccess
  );
export const setUpdateSubscriptionFailed = (updateSubscriptionFailed) =>
  createAction(
    SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_SUBSCRIPTION_FAILED,
    updateSubscriptionFailed
  );

export const fetchSubscriptionStart = (subscriptionKey) =>
  createAction(
    SUBSCRIPTION_ACTION_TYPES.FETCH_SUBSCRIPTION_START,
    subscriptionKey
  );
export const updateSubscriptionStart = (subscriptionKey, request) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.UPDATE_SUBSCRIPTION_START, {
    subscriptionKey,
    request,
  });

export const setIsFetchSubscriptionHitted = (isFetchSubscriptionHitted) =>
  createAction(
    SUBSCRIPTION_ACTION_TYPES.SET_IS_FETCH_SUBSCRIPTION_HITTED,
    isFetchSubscriptionHitted
  );
export const setIsUpdateSubscriptionHitted = (isUpdateSubscriptionHitted) =>
  createAction(
    SUBSCRIPTION_ACTION_TYPES.SET_IS_UPDATE_SUBSCRIPTION_HITTED,
    isUpdateSubscriptionHitted
  );

export const resetSubscriptionReducer = () =>
  createAction(SUBSCRIPTION_ACTION_TYPES.RESET_SUBSCRIPTION_REDUCER);
