import { createSelector } from "reselect";

const userSubscriberSelector = ({ userSubscriber }) => userSubscriber;

export const getUserSubscribers = createSelector(
  [userSubscriberSelector],
  ({ userSubscribers }) => userSubscribers
);
export const getUserSubscriber = createSelector(
  [userSubscriberSelector],
  ({ userSubscriber }) => userSubscriber
);

export const getIsUserSubscribersHasMore = createSelector(
  [userSubscriberSelector],
  ({ isUserSubscribersHasMore }) => isUserSubscribersHasMore
);

export const getFetchUserSubscribersSearch = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscribersSearch }) => fetchUserSubscribersSearch
);
export const getFetchUserSubscribersSort = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscribersSort }) => fetchUserSubscribersSort
);
export const getFetchUserSubscribersKeyBy = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscribersKeyBy }) => fetchUserSubscribersKeyBy
);
export const getFetchUserSubscribersPage = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscribersPage }) => fetchUserSubscribersPage
);
export const getFetchUserSubscribersPerPage = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscribersPerPage }) => fetchUserSubscribersPerPage
);
export const getFetchUserSubscribersIncludes = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscribersIncludes }) => fetchUserSubscribersIncludes
);
export const getFetchUserSubscribersFilterMarketId = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscribersFilterMarketId }) => fetchUserSubscribersFilterMarketId
);
export const getFetchUserSubscribersFilterBranchId = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscribersFilterBranchId }) => fetchUserSubscribersFilterBranchId
);
export const getFetchUserSubscribersFilterPermissionId = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscribersFilterPermissionId }) =>
    fetchUserSubscribersFilterPermissionId
);
export const getFetchUserSubscribersLoading = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscribersLoading }) => fetchUserSubscribersLoading
);
export const getFetchUserSubscribersSuccess = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscribersSuccess }) => fetchUserSubscribersSuccess
);
export const getFetchUserSubscribersFailed = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscribersFailed }) => fetchUserSubscribersFailed
);

export const getFetchUserSubscriberLoading = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscriberLoading }) => fetchUserSubscriberLoading
);
export const getFetchUserSubscriberSuccess = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscriberSuccess }) => fetchUserSubscriberSuccess
);
export const getFetchUserSubscriberFailed = createSelector(
  [userSubscriberSelector],
  ({ fetchUserSubscriberFailed }) => fetchUserSubscriberFailed
);

export const getCreateUserSubscriberLoading = createSelector(
  [userSubscriberSelector],
  ({ createUserSubscriberLoading }) => createUserSubscriberLoading
);
export const getCreateUserSubscriberSuccess = createSelector(
  [userSubscriberSelector],
  ({ createUserSubscriberSuccess }) => createUserSubscriberSuccess
);
export const getCreateUserSubscriberFailed = createSelector(
  [userSubscriberSelector],
  ({ createUserSubscriberFailed }) => createUserSubscriberFailed
);

export const getUpdateUserSubscriberLoading = createSelector(
  [userSubscriberSelector],
  ({ updateUserSubscriberLoading }) => updateUserSubscriberLoading
);
export const getUpdateUserSubscriberSuccess = createSelector(
  [userSubscriberSelector],
  ({ updateUserSubscriberSuccess }) => updateUserSubscriberSuccess
);
export const getUpdateUserSubscriberFailed = createSelector(
  [userSubscriberSelector],
  ({ updateUserSubscriberFailed }) => updateUserSubscriberFailed
);

export const getDeleteUserSubscriberLoading = createSelector(
  [userSubscriberSelector],
  ({ deleteUserSubscriberLoading }) => deleteUserSubscriberLoading
);
export const getDeleteUserSubscriberSuccess = createSelector(
  [userSubscriberSelector],
  ({ deleteUserSubscriberSuccess }) => deleteUserSubscriberSuccess
);
export const getDeleteUserSubscriberFailed = createSelector(
  [userSubscriberSelector],
  ({ deleteUserSubscriberFailed }) => deleteUserSubscriberFailed
);

export const getIsFetchUserSubscribersHitted = createSelector(
  [userSubscriberSelector],
  ({ isFetchUserSubscribersHitted }) => isFetchUserSubscribersHitted
);
export const getIsFetchUserSubscriberHitted = createSelector(
  [userSubscriberSelector],
  ({ isFetchUserSubscriberHitted }) => isFetchUserSubscriberHitted
);
export const getIsCreateUserSubscriberHitted = createSelector(
  [userSubscriberSelector],
  ({ isCreateUserSubscriberHitted }) => isCreateUserSubscriberHitted
);
export const getIsUpdateUserSubscriberHitted = createSelector(
  [userSubscriberSelector],
  ({ isUpdateUserSubscriberHitted }) => isUpdateUserSubscriberHitted
);
export const getIsDeleteUserSubscriberHitted = createSelector(
  [userSubscriberSelector],
  ({ isDeleteUserSubscriberHitted }) => isDeleteUserSubscriberHitted
);
