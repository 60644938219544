import { takeLatest, put, all, call } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getUserSuppliers } from "../../api/user-supplier.api";

import SELECT_SUPPLIER_ACTION_TYPES from "./select-supplier.type";
import {
  appendSuppliers,
  appendSearchSuppliers,
  setAppendSuppliersFailed,
  setAppendSuppliersLoading,
  setAppendSuppliersSuccess,
  setFetchSuppliersFailed,
  setFetchSuppliersLoading,
  setFetchSuppliersSuccess,
  setIsAppendSuppliersHitted,
  setIsFetchSuppliersHitted,
  setIsSuppliersHasMore,
  setIsSearchSuppliersHasMore,
  setIsSearchSuppliersHitted,
  setSuppliers,
  setSearchSuppliers,
  setSearchSuppliersFailed,
  setSearchSuppliersLoading,
  setSearchSuppliersSuccess,
} from "./select-supplier.action";

export function* _getFetchSuppliers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setFetchSuppliersLoading(true));

    const {
      meta: { message },
      data: users,
    } = yield call(getUserSuppliers, parameters);

    yield put(setIsFetchSuppliersHitted(true));
    yield put(setIsSuppliersHasMore(Object.keys(users).length > 0));

    if (parameters.page > 1) {
      yield put(appendSuppliers(users));
    } else {
      yield put(setSuppliers(users));
    }

    yield put(setFetchSuppliersSuccess(message));
    yield put(setFetchSuppliersLoading(false));
  } catch (error) {
    yield put(setFetchSuppliersFailed(error));
    yield put(setFetchSuppliersLoading(false));
  }
}
export function* _getSearchSuppliers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setSearchSuppliersLoading(true));

    const {
      meta: { message },
      data: users,
    } = yield call(getUserSuppliers, parameters);

    yield put(setIsSearchSuppliersHitted(true));
    yield put(setIsSearchSuppliersHasMore(Object.keys(users).length > 0));

    if (parameters.page > 1) {
      yield put(appendSearchSuppliers(users));
    } else {
      yield put(setSearchSuppliers(users));
    }

    yield put(setSearchSuppliersSuccess(message));
    yield put(setSearchSuppliersLoading(false));
  } catch (error) {
    yield put(setSearchSuppliersFailed(error));
    yield put(setSearchSuppliersLoading(false));
  }
}
export function* _getAppendSuppliers({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setAppendSuppliersLoading(true));

    const {
      meta: { message },
      data: users,
    } = yield call(getUserSuppliers, parameters);

    yield put(setIsAppendSuppliersHitted(true));
    yield put(appendSuppliers(users));

    yield put(setAppendSuppliersSuccess(message));
    yield put(setAppendSuppliersLoading(false));
  } catch (error) {
    yield put(setAppendSuppliersFailed(error));
    yield put(setAppendSuppliersLoading(false));
  }
}

export function* onFetchSuppliersStart() {
  yield takeLatest(
    SELECT_SUPPLIER_ACTION_TYPES.FETCH_SUPPLIERS_START,
    _getFetchSuppliers
  );
}
export function* onSearchSuppliersStart() {
  yield takeLatest(
    SELECT_SUPPLIER_ACTION_TYPES.SEARCH_SUPPLIERS_START,
    _getSearchSuppliers
  );
}
export function* onAppendSuppliersStart() {
  yield takeLatest(
    SELECT_SUPPLIER_ACTION_TYPES.APPEND_SUPPLIERS_START,
    _getAppendSuppliers
  );
}

export function* selectSupplierSaga() {
  yield all([
    call(onFetchSuppliersStart),
    call(onSearchSuppliersStart),
    call(onAppendSuppliersStart),
  ]);
}
