import { createSelector } from "reselect";

const paymentMethodSelector = ({ paymentMethod }) => paymentMethod;

export const getPaymentMethods = createSelector([paymentMethodSelector], ({ paymentMethods }) => paymentMethods);
export const getSelectPaymentMethods = createSelector(
  [paymentMethodSelector],
  ({ selectPaymentMethods }) => selectPaymentMethods
);
export const getPaymentMethod = createSelector([paymentMethodSelector], ({ paymentMethod }) => paymentMethod);

export const getIsPaymentMethodsHasMore = createSelector(
  [paymentMethodSelector],
  ({ isPaymentMethodsHasMore }) => isPaymentMethodsHasMore
);
export const getIsSelectPaymentMethodsHasMore = createSelector(
  [paymentMethodSelector],
  ({ isSelectPaymentMethodsHasMore }) => isSelectPaymentMethodsHasMore
);

export const getFetchPaymentMethodsPage = createSelector(
  [paymentMethodSelector],
  ({ fetchPaymentMethodsPage }) => fetchPaymentMethodsPage
);
export const getFetchPaymentMethodsPerPage = createSelector(
  [paymentMethodSelector],
  ({ fetchPaymentMethodsPerPage }) => fetchPaymentMethodsPerPage
);
export const getFetchPaymentMethodsLoading = createSelector(
  [paymentMethodSelector],
  ({ fetchPaymentMethodsLoading }) => fetchPaymentMethodsLoading
);
export const getFetchPaymentMethodsSuccess = createSelector(
  [paymentMethodSelector],
  ({ fetchPaymentMethodsSuccess }) => fetchPaymentMethodsSuccess
);
export const getFetchPaymentMethodsFailed = createSelector(
  [paymentMethodSelector],
  ({ fetchPaymentMethodsFailed }) => fetchPaymentMethodsFailed
);

export const getFetchSelectPaymentMethodsPage = createSelector(
  [paymentMethodSelector],
  ({ fetchSelectPaymentMethodsPage }) => fetchSelectPaymentMethodsPage
);
export const getFetchSelectPaymentMethodsPerPage = createSelector(
  [paymentMethodSelector],
  ({ fetchSelectPaymentMethodsPerPage }) => fetchSelectPaymentMethodsPerPage
);
export const getFetchSelectPaymentMethodsLoading = createSelector(
  [paymentMethodSelector],
  ({ fetchSelectPaymentMethodsLoading }) => fetchSelectPaymentMethodsLoading
);
export const getFetchSelectPaymentMethodsSuccess = createSelector(
  [paymentMethodSelector],
  ({ fetchSelectPaymentMethodsSuccess }) => fetchSelectPaymentMethodsSuccess
);
export const getFetchSelectPaymentMethodsFailed = createSelector(
  [paymentMethodSelector],
  ({ fetchSelectPaymentMethodsFailed }) => fetchSelectPaymentMethodsFailed
);

export const getCreatePaymentMethodLoading = createSelector(
  [paymentMethodSelector],
  ({ createPaymentMethodLoading }) => createPaymentMethodLoading
);
export const getCreatePaymentMethodSuccess = createSelector(
  [paymentMethodSelector],
  ({ createPaymentMethodSuccess }) => createPaymentMethodSuccess
);
export const getCreatePaymentMethodFailed = createSelector(
  [paymentMethodSelector],
  ({ createPaymentMethodFailed }) => createPaymentMethodFailed
);

export const getUpdatePaymentMethodLoading = createSelector(
  [paymentMethodSelector],
  ({ updatePaymentMethodLoading }) => updatePaymentMethodLoading
);
export const getUpdatePaymentMethodSuccess = createSelector(
  [paymentMethodSelector],
  ({ updatePaymentMethodSuccess }) => updatePaymentMethodSuccess
);
export const getUpdatePaymentMethodFailed = createSelector(
  [paymentMethodSelector],
  ({ updatePaymentMethodFailed }) => updatePaymentMethodFailed
);

export const getDeletePaymentMethodLoading = createSelector(
  [paymentMethodSelector],
  ({ deletePaymentMethodLoading }) => deletePaymentMethodLoading
);
export const getDeletePaymentMethodSuccess = createSelector(
  [paymentMethodSelector],
  ({ deletePaymentMethodSuccess }) => deletePaymentMethodSuccess
);
export const getDeletePaymentMethodFailed = createSelector(
  [paymentMethodSelector],
  ({ deletePaymentMethodFailed }) => deletePaymentMethodFailed
);

export const getIsFetchPaymentMethodsHitted = createSelector(
  [paymentMethodSelector],
  ({ isFetchPaymentMethodsHitted }) => isFetchPaymentMethodsHitted
);
export const getIsFetchSelectPaymentMethodsHitted = createSelector(
  [paymentMethodSelector],
  ({ isFetchSelectPaymentMethodsHitted }) => isFetchSelectPaymentMethodsHitted
);
export const getIsCreatePaymentMethodHitted = createSelector(
  [paymentMethodSelector],
  ({ isCreatePaymentMethodHitted }) => isCreatePaymentMethodHitted
);
export const getIsUpdatePaymentMethodHitted = createSelector(
  [paymentMethodSelector],
  ({ isUpdatePaymentMethodHitted }) => isUpdatePaymentMethodHitted
);
export const getIsDeletePaymentMethodHitted = createSelector(
  [paymentMethodSelector],
  ({ isDeletePaymentMethodHitted }) => isDeletePaymentMethodHitted
);
