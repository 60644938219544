import SCHEDULE_ACTION_TYPES from "./schedule.type";

export const SCHEDULE_INITIAL_STATE = {
  schedules: [],

  fetchSchedulesIncludes: null,
  fetchSchedulesFilterMarketId: null,
  fetchSchedulesFilterBranchId: null,
  fetchSchedulesLoading: false,
  fetchSchedulesSuccess: null,
  fetchSchedulesFailed: null,

  createOrUpdateScheduleLoading: false,
  createOrUpdateScheduleSuccess: null,
  createOrUpdateScheduleFailed: null,

  isFetchSchedulesHitted: false,
  isCreateOrUpdateScheduleHitted: false,
};

export const scheduleReducer = (state = SCHEDULE_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SCHEDULE_ACTION_TYPES.SET_SCHEDULES:
      return { ...state, schedules: payload };

    case SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_INCLUDES:
      return { ...state, fetchSchedulesIncludes: payload };
    case SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_FILTER_MARKET_ID:
      return { ...state, fetchSchedulesFilterMarketId: payload };
    case SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_FILTER_BRANCH_ID:
      return { ...state, fetchSchedulesFilterBranchId: payload };
    case SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_LOADING:
      return { ...state, fetchSchedulesLoading: payload };
    case SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_SUCCESS:
      return { ...state, fetchSchedulesSuccess: payload };
    case SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_FAILED:
      return { ...state, fetchSchedulesFailed: payload };

    case SCHEDULE_ACTION_TYPES.SET_CREATE_OR_UPDATE_SCHEDULE_LOADING:
      return { ...state, createOrUpdateScheduleLoading: payload };
    case SCHEDULE_ACTION_TYPES.SET_CREATE_OR_UPDATE_SCHEDULE_SUCCESS:
      return { ...state, createOrUpdateScheduleSuccess: payload };
    case SCHEDULE_ACTION_TYPES.SET_CREATE_OR_UPDATE_SCHEDULE_FAILED:
      return { ...state, createOrUpdateScheduleFailed: payload };

    case SCHEDULE_ACTION_TYPES.SET_IS_FETCH_SCHEDULES_HITTED:
      return { ...state, isFetchSchedulesHitted: payload };
    case SCHEDULE_ACTION_TYPES.SET_IS_CREATE_OR_UPDATE_SCHEDULE_HITTED:
      return { ...state, isCreateOrUpdateScheduleHitted: payload };

    case SCHEDULE_ACTION_TYPES.RESET_SCHEDULE_REDUCER:
      return SCHEDULE_INITIAL_STATE;
    default:
      return state;
  }
};
