import CARD_ACTION_TYPES from "./card.type";
import { createAction } from "../../utils/store.utils";

export const setCards = (cards) => createAction(CARD_ACTION_TYPES.SET_CARDS, cards);

export const setCard = (card) => createAction(CARD_ACTION_TYPES.SET_CARD, card);

export const setFetchCardsSearch = (fetchCardsSearch) =>
  createAction(CARD_ACTION_TYPES.SET_FETCH_CARDS_SEARCH, fetchCardsSearch);

export const setFetchCardsSort = (fetchCardsSort) =>
  createAction(CARD_ACTION_TYPES.SET_FETCH_CARDS_SORT, fetchCardsSort);

export const setFetchCardsPage = (fetchCardsPage) =>
  createAction(CARD_ACTION_TYPES.SET_FETCH_CARDS_PAGE, fetchCardsPage);

export const setFetchCardsPerPage = (fetchCardsPerPage) =>
  createAction(CARD_ACTION_TYPES.SET_FETCH_CARDS_PER_PAGE, fetchCardsPerPage);

export const setFetchCardsIncludes = (fetchCardsIncludes) =>
  createAction(CARD_ACTION_TYPES.SET_FETCH_CARDS_INCLUDES, fetchCardsIncludes);

export const setFetchCardsFilterTransferType = (fetchCardsFilterTransferType) =>
  createAction(CARD_ACTION_TYPES.SET_FETCH_CARDS_FILTER_TRANSFER_TYPE, fetchCardsFilterTransferType);

export const setFetchCardsFilterIsVerified = (fetchCardsFilterIsVerified) =>
  createAction(CARD_ACTION_TYPES.SET_FETCH_CARDS_FILTER_IS_VERIFIED, fetchCardsFilterIsVerified);

export const setFetchCardsLoading = (fetchCardsLoading) =>
  createAction(CARD_ACTION_TYPES.SET_FETCH_CARDS_LOADING, fetchCardsLoading);

export const setFetchCardsSuccess = (fetchCardsSuccess) =>
  createAction(CARD_ACTION_TYPES.SET_FETCH_CARDS_SUCCESS, fetchCardsSuccess);

export const setFetchCardsFailed = (fetchCardsFailed) =>
  createAction(CARD_ACTION_TYPES.SET_FETCH_CARDS_FAILED, fetchCardsFailed);

export const setFetchCardLoading = (fetchCardLoading) =>
  createAction(CARD_ACTION_TYPES.SET_FETCH_CARD_LOADING, fetchCardLoading);

export const setFetchCardSuccess = (fetchCardSuccess) =>
  createAction(CARD_ACTION_TYPES.SET_FETCH_CARD_SUCCESS, fetchCardSuccess);

export const setFetchCardFailed = (fetchCardFailed) =>
  createAction(CARD_ACTION_TYPES.SET_FETCH_CARD_FAILED, fetchCardFailed);

export const setCreateOrUpdateCardLoading = (createOrUpdateCardLoading) =>
  createAction(CARD_ACTION_TYPES.SET_CREATE_OR_UPDATE_CARD_LOADING, createOrUpdateCardLoading);

export const setCreateOrUpdateCardSuccess = (createOrUpdateCardSuccess) =>
  createAction(CARD_ACTION_TYPES.SET_CREATE_OR_UPDATE_CARD_SUCCESS, createOrUpdateCardSuccess);

export const setCreateOrUpdateCardFailed = (createOrUpdateCardFailed) =>
  createAction(CARD_ACTION_TYPES.SET_CREATE_OR_UPDATE_CARD_FAILED, createOrUpdateCardFailed);

export const appendCards = (cards) => createAction(CARD_ACTION_TYPES.APPEND_CARDS, cards);

export const fetchCardsStart = () => createAction(CARD_ACTION_TYPES.FETCH_CARDS_START);

export const fetchCardStart = (marketId) => createAction(CARD_ACTION_TYPES.FETCH_CARD_START, marketId);

export const createOrUpdateCardStart = (request) =>
  createAction(CARD_ACTION_TYPES.CREATE_OR_UPDATE_CARD_START, request);

export const setIsFetchCardsHitted = (isFetchCardsHitted) =>
  createAction(CARD_ACTION_TYPES.SET_IS_FETCH_CARDS_HITTED, isFetchCardsHitted);

export const setIsFetchCardHitted = (isFetchCardHitted) =>
  createAction(CARD_ACTION_TYPES.SET_IS_FETCH_CARD_HITTED, isFetchCardHitted);

export const setIsCreateOrUpdateCardHitted = (isCreateOrUpdateCardHitted) =>
  createAction(CARD_ACTION_TYPES.SET_IS_CREATE_OR_UPDATE_CARD_HITTED, isCreateOrUpdateCardHitted);

export const resetCardReducer = () => createAction(CARD_ACTION_TYPES.RESET_CARD_REDUCER);
