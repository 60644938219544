import PRODUCT_CATEGORY_SORT_ACTION_TYPES from "./product-category-sort.type";

export const PRODUCT_CATEGORY_SORT_INITIAL_STATE = {
  createOrUpdateProductCategorySortLoading: false,
  createOrUpdateProductCategorySortSuccess: null,
  createOrUpdateProductCategorySortFailed: null,
};

export const productCategorySortReducer = (state = PRODUCT_CATEGORY_SORT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_CATEGORY_SORT_ACTION_TYPES.SET_CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_LOADING:
      return { ...state, createOrUpdateProductCategorySortLoading: payload };
    case PRODUCT_CATEGORY_SORT_ACTION_TYPES.SET_CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_SUCCESS:
      return { ...state, createOrUpdateProductCategorySortSuccess: payload };
    case PRODUCT_CATEGORY_SORT_ACTION_TYPES.SET_CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_FAILED:
      return { ...state, createOrUpdateProductCategorySortFailed: payload };
    case PRODUCT_CATEGORY_SORT_ACTION_TYPES.RESET_PRODUCT_CATEGORY_SORT_REDUCER:
      return PRODUCT_CATEGORY_SORT_INITIAL_STATE;
    default:
      return state;
  }
};
