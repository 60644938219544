import PRINTER_ACTION_TYPES from "./printer.type";
import { createAction } from "../../utils/store.utils";

export const setPrinters = (printers) => createAction(PRINTER_ACTION_TYPES.SET_PRINTERS, printers);

export const setPrinter = (printer) => createAction(PRINTER_ACTION_TYPES.SET_PRINTER, printer);

export const setIsPrintersHasMore = (isPrintersHasMore) =>
  createAction(PRINTER_ACTION_TYPES.SET_IS_PRINTERS_HAS_MORE, isPrintersHasMore);

export const setFetchPrintersSearch = (fetchPrintersSearch) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_SEARCH, fetchPrintersSearch);

export const setFetchPrintersPage = (fetchPrintersPage) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_PAGE, fetchPrintersPage);

export const setFetchPrintersPerPage = (fetchPrintersPerPage) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_PER_PAGE, fetchPrintersPerPage);

export const setFetchPrintersIncludes = (fetchPrintersIncludes) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_INCLUDES, fetchPrintersIncludes);

export const setFetchPrintersFilterMarketId = (fetchPrintersFilterMarketId) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_FILTER_MARKET_ID, fetchPrintersFilterMarketId);

export const setFetchPrintersFilterBranchId = (fetchPrintersFilterBranchId) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_FILTER_BRANCH_ID, fetchPrintersFilterBranchId);

export const setFetchPrintersFilterPrinterTypeId = (fetchPrintersFilterPrinterTypeId) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_FILTER_PRINTER_TYPE_ID, fetchPrintersFilterPrinterTypeId);

export const setFetchPrintersFilterIsActive = (fetchPrintersFilterIsActive) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_FILTER_IS_ACTIVE, fetchPrintersFilterIsActive);

export const setFetchPrintersLoading = (fetchPrintersLoading) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_LOADING, fetchPrintersLoading);

export const setFetchPrintersSuccess = (fetchPrintersSuccess) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_SUCCESS, fetchPrintersSuccess);

export const setFetchPrintersFailed = (fetchPrintersFailed) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_FAILED, fetchPrintersFailed);

export const setFetchPrinterLoading = (fetchPrinterLoading) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTER_LOADING, fetchPrinterLoading);

export const setFetchPrinterSuccess = (fetchPrinterSuccess) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTER_SUCCESS, fetchPrinterSuccess);

export const setFetchPrinterFailed = (fetchPrinterFailed) =>
  createAction(PRINTER_ACTION_TYPES.SET_FETCH_PRINTER_FAILED, fetchPrinterFailed);

export const setCreatePrinterLoading = (createPrinterLoading) =>
  createAction(PRINTER_ACTION_TYPES.SET_CREATE_PRINTER_LOADING, createPrinterLoading);

export const setCreatePrinterSuccess = (createPrinterSuccess) =>
  createAction(PRINTER_ACTION_TYPES.SET_CREATE_PRINTER_SUCCESS, createPrinterSuccess);

export const setCreatePrinterFailed = (createPrinterFailed) =>
  createAction(PRINTER_ACTION_TYPES.SET_CREATE_PRINTER_FAILED, createPrinterFailed);

export const setUpdatePrinterLoading = (updatePrinterLoading) =>
  createAction(PRINTER_ACTION_TYPES.SET_UPDATE_PRINTER_LOADING, updatePrinterLoading);

export const setUpdatePrinterSuccess = (updatePrinterSuccess) =>
  createAction(PRINTER_ACTION_TYPES.SET_UPDATE_PRINTER_SUCCESS, updatePrinterSuccess);

export const setUpdatePrinterFailed = (updatePrinterFailed) =>
  createAction(PRINTER_ACTION_TYPES.SET_UPDATE_PRINTER_FAILED, updatePrinterFailed);

export const setDeletePrinterLoading = (deletePrinterLoading) =>
  createAction(PRINTER_ACTION_TYPES.SET_DELETE_PRINTER_LOADING, deletePrinterLoading);

export const setDeletePrinterSuccess = (deletePrinterSuccess) =>
  createAction(PRINTER_ACTION_TYPES.SET_DELETE_PRINTER_SUCCESS, deletePrinterSuccess);

export const setDeletePrinterFailed = (deletePrinterFailed) =>
  createAction(PRINTER_ACTION_TYPES.SET_DELETE_PRINTER_FAILED, deletePrinterFailed);

export const appendPrinters = (printers) => createAction(PRINTER_ACTION_TYPES.APPEND_PRINTERS, printers);

export const fetchPrintersStart = () => createAction(PRINTER_ACTION_TYPES.FETCH_PRINTERS_START);

export const fetchPrinterStart = (id) => createAction(PRINTER_ACTION_TYPES.FETCH_PRINTER_START, id);

export const createPrinterStart = (request) => createAction(PRINTER_ACTION_TYPES.CREATE_PRINTER_START, request);

export const updatePrinterStart = (id, request) =>
  createAction(PRINTER_ACTION_TYPES.UPDATE_PRINTER_START, { id, request });

export const deletePrinterStart = (id) => createAction(PRINTER_ACTION_TYPES.DELETE_PRINTER_START, id);

export const resetPrinterReducer = () => createAction(PRINTER_ACTION_TYPES.RESET_PRINTER_REDUCER);
