import { takeLatest, put, all, call, select } from "redux-saga/effects";

import APPLICATION_ACTION_TYPES from "./application.type";

import {
  appendApplicationExtensions,
  appendApplications,
  setApplication,
  setApplicationExtensions,
  setApplications,
  setCreateApplication,
  setCreateApplicationFailed,
  setCreateApplicationLoading,
  setCreateApplicationSuccess,
  setDeleteApplicationFailed,
  setDeleteApplicationLoading,
  setDeleteApplicationSuccess,
  setFetchApplicationExtensionsFailed,
  setFetchApplicationExtensionsLoading,
  setFetchApplicationExtensionsPage,
  setFetchApplicationExtensionsSuccess,
  setFetchApplicationFailed,
  setFetchApplicationLoading,
  setFetchApplicationSuccess,
  setFetchApplicationsFailed,
  setFetchApplicationsLoading,
  setFetchApplicationsPage,
  setFetchApplicationsSuccess,
  setIsApplicationExtensionsHasMore,
  setIsApplicationsHasMore,
  setIsCreateApplicationHitted,
  setIsDeleteApplicationHitted,
  setIsFetchApplicationExtensionsHitted,
  setIsFetchApplicationHitted,
  setIsFetchApplicationsHitted,
  setUpdateApplication,
  setUpdateApplicationFailed,
  setUpdateApplicationLoading,
  setUpdateApplicationSuccess,
} from "./application.action";
import {
  getFetchApplicationExtensionsKeyBy,
  getFetchApplicationExtensionsPage,
  getFetchApplicationExtensionsPerPage,
  getFetchApplicationExtensionsSearch,
  getFetchApplicationsKeyBy,
  getFetchApplicationsPage,
  getFetchApplicationsPerPage,
  getFetchApplicationsSearch,
  getIsFetchApplicationExtensionsHitted,
  getIsFetchApplicationsHitted,
} from "./application.selector";

import {
  createApplication,
  deleteApplication,
  getApplication,
  getApplications,
  updateApplication,
} from "../../api/application.api";

export function* _getApplications() {
  try {
    yield put(setFetchApplicationsLoading(true));

    const search = yield select(getFetchApplicationsSearch);
    const key_by = yield select(getFetchApplicationsKeyBy);
    const page = yield select(getFetchApplicationsPage);
    const per_page = yield select(getFetchApplicationsPerPage);

    const parameters = { search, key_by, page, per_page };

    const {
      meta: { message },
      data: { data: applications },
    } = yield call(getApplications, parameters);

    yield put(setIsFetchApplicationsHitted(true));
    yield put(setIsApplicationsHasMore(applications.length > 0));

    if (page > 1) {
      yield put(appendApplications(applications));
    } else {
      yield put(setApplications(applications));
    }

    yield put(setFetchApplicationsSuccess(message));
    yield put(setFetchApplicationsLoading(false));
  } catch (error) {
    yield put(setFetchApplicationsFailed(error));
    yield put(setFetchApplicationsLoading(false));
  }
}
export function* _getApplicationExtensions() {
  try {
    yield put(setFetchApplicationExtensionsLoading(true));

    const search = yield select(getFetchApplicationExtensionsSearch);
    const key_by = yield select(getFetchApplicationExtensionsKeyBy);
    const page = yield select(getFetchApplicationExtensionsPage);
    const per_page = yield select(getFetchApplicationExtensionsPerPage);

    const parameters = { search, key_by, page, per_page };

    const {
      meta: { message },
      data: applications,
    } = yield call(getApplications, parameters);

    yield put(setIsFetchApplicationExtensionsHitted(true));
    yield put(
      setIsApplicationExtensionsHasMore(Object.keys(applications).length > 0)
    );

    if (page > 1) {
      yield put(appendApplicationExtensions(applications));
    } else {
      yield put(setApplicationExtensions(applications));
    }

    yield put(setFetchApplicationExtensionsSuccess(message));
    yield put(setFetchApplicationExtensionsLoading(false));
  } catch (error) {
    yield put(setFetchApplicationExtensionsFailed(error));
    yield put(setFetchApplicationExtensionsLoading(false));
  }
}
export function* _getApplication({ payload: applicationKey }) {
  try {
    yield put(setFetchApplicationLoading(true));

    const {
      meta: { message },
      data: application,
    } = yield call(getApplication, applicationKey);

    yield put(setIsFetchApplicationHitted(true));
    yield put(setApplication(application));

    yield put(setFetchApplicationSuccess(message));
    yield put(setFetchApplicationLoading(false));
  } catch (error) {
    yield put(setFetchApplicationFailed(error));
    yield put(setFetchApplicationLoading(false));
  }
}
export function* _createApplication({ payload: request }) {
  try {
    yield put(setCreateApplicationLoading(true));

    const {
      meta: { message },
      data: application,
    } = yield call(createApplication, request);

    yield put(setIsCreateApplicationHitted(true));
    yield put(setCreateApplication(application));

    const isFetchApplicationsHitted = yield select(
      getIsFetchApplicationsHitted
    );
    const isFetchApplicationExtensionsHitted = yield select(
      getIsFetchApplicationExtensionsHitted
    );

    if (isFetchApplicationsHitted) {
      yield put(setFetchApplicationsPage(1));
      yield call(_getApplications);
    }
    if (isFetchApplicationExtensionsHitted) {
      yield put(setFetchApplicationExtensionsPage(1));
      yield call(_getApplicationExtensions);
    }

    yield put(setCreateApplicationSuccess(message));
    yield put(setCreateApplicationLoading(false));
  } catch (error) {
    yield put(setCreateApplicationFailed(error));
    yield put(setCreateApplicationLoading(false));
  }
}
export function* _updateApplication({ payload: { applicationKey, request } }) {
  try {
    yield put(setUpdateApplicationLoading(true));

    const {
      meta: { message },
      data: application,
    } = yield call(updateApplication, applicationKey, request);

    yield put(setIsCreateApplicationHitted(true));
    yield put(setUpdateApplication(application));

    const isFetchApplicationsHitted = yield select(
      getIsFetchApplicationsHitted
    );
    const isFetchApplicationExtensionsHitted = yield select(
      getIsFetchApplicationExtensionsHitted
    );

    if (isFetchApplicationsHitted) {
      yield put(setFetchApplicationsPage(1));
      yield call(_getApplications);
    }
    if (isFetchApplicationExtensionsHitted) {
      yield put(setFetchApplicationExtensionsPage(1));
      yield call(_getApplicationExtensions);
    }

    yield put(setUpdateApplicationSuccess(message));
    yield put(setUpdateApplicationLoading(false));
  } catch (error) {
    yield put(setUpdateApplicationFailed(error));
    yield put(setUpdateApplicationLoading(false));
  }
}
export function* _deleteApplication({ payload: applicationKey }) {
  try {
    yield put(setDeleteApplicationLoading(true));

    const {
      meta: { message },
    } = yield call(deleteApplication, applicationKey);

    yield put(setIsDeleteApplicationHitted(true));

    const isFetchApplicationsHitted = yield select(
      getIsFetchApplicationsHitted
    );
    const isFetchApplicationExtensionsHitted = yield select(
      getIsFetchApplicationExtensionsHitted
    );

    if (isFetchApplicationsHitted) {
      yield put(setFetchApplicationsPage(1));
      yield call(_getApplications);
    }
    if (isFetchApplicationExtensionsHitted) {
      yield put(setFetchApplicationExtensionsPage(1));
      yield call(_getApplicationExtensions);
    }

    yield put(setDeleteApplicationSuccess(message));
    yield put(setDeleteApplicationLoading(false));
  } catch (error) {
    yield put(setDeleteApplicationFailed(error));
    yield put(setDeleteApplicationLoading(false));
  }
}

export function* onFetchApplicationsStart() {
  yield takeLatest(
    APPLICATION_ACTION_TYPES.FETCH_APPLICATIONS_START,
    _getApplications
  );
}
export function* onFetchApplicationExtensionsStart() {
  yield takeLatest(
    APPLICATION_ACTION_TYPES.FETCH_APPLICATION_EXTENSIONS_START,
    _getApplicationExtensions
  );
}
export function* onFetchApplicationStart() {
  yield takeLatest(
    APPLICATION_ACTION_TYPES.FETCH_APPLICATION_START,
    _getApplication
  );
}
export function* onCreateApplicationStart() {
  yield takeLatest(
    APPLICATION_ACTION_TYPES.CREATE_APPLICATION_START,
    _createApplication
  );
}
export function* onUpdateApplicationStart() {
  yield takeLatest(
    APPLICATION_ACTION_TYPES.UPDATE_APPLICATION_START,
    _updateApplication
  );
}
export function* onDeleteApplicationStart() {
  yield takeLatest(
    APPLICATION_ACTION_TYPES.DELETE_APPLICATION_START,
    _deleteApplication
  );
}

export function* applicationSaga() {
  yield all([
    call(onFetchApplicationsStart),
    call(onFetchApplicationExtensionsStart),
    call(onFetchApplicationStart),
    call(onCreateApplicationStart),
    call(onUpdateApplicationStart),
    call(onDeleteApplicationStart),
  ]);
}
