import PRODUCT_MODIFIER_ACTION_TYPES from "./product-modifier.type";
import { createAction } from "../../utils/store.utils";

export const setScannedProductModifier = (scannedProductModifier) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_SCANNED_PRODUCT_MODIFIER, scannedProductModifier);

export const setCreateProductModifier = (createProductModifier) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_CREATE_PRODUCT_MODIFIER, createProductModifier);

export const setUpdateProductModifier = (updateProductModifier) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_UPDATE_PRODUCT_MODIFIER, updateProductModifier);

export const setOrderVariableProductModifiers = (orderVariableProductModifiers) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_ORDER_VARIABLE_PRODUCT_MODIFIERS, orderVariableProductModifiers);

export const setOrderVariableCartRequest = (orderVariableCartRequest) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_ORDER_VARIABLE_CART_REQUEST, orderVariableCartRequest);

export const setOrderWeightProductModifiers = (orderWeightProductModifiers) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_ORDER_WEIGHT_PRODUCT_MODIFIERS, orderWeightProductModifiers);

export const setOrderWeightCartRequest = (orderWeightCartRequest) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_ORDER_WEIGHT_CART_REQUEST, orderWeightCartRequest);

export const setOrderCustomizeProductModifiers = (orderCustomizeProductModifiers) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_ORDER_CUSTOMIZE_PRODUCT_MODIFIERS, orderCustomizeProductModifiers);

export const setCreateProductModifierLoading = (createProductModifierLoading) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_CREATE_PRODUCT_MODIFIER_LOADING, createProductModifierLoading);

export const setCreateProductModifierSuccess = (createProductModifierSuccess) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_CREATE_PRODUCT_MODIFIER_SUCCESS, createProductModifierSuccess);

export const setCreateProductModifierFailed = (createProductModifierFailed) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_CREATE_PRODUCT_MODIFIER_FAILED, createProductModifierFailed);

export const setUpdateProductModifierLoading = (updateProductModifierLoading) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_UPDATE_PRODUCT_MODIFIER_LOADING, updateProductModifierLoading);

export const setUpdateProductModifierSuccess = (updateProductModifierSuccess) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_UPDATE_PRODUCT_MODIFIER_SUCCESS, updateProductModifierSuccess);

export const setUpdateProductModifierFailed = (updateProductModifierFailed) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.SET_UPDATE_PRODUCT_MODIFIER_FAILED, updateProductModifierFailed);

export const setFetchScannedProductModifierLoading = (fetchScannedProductModifierLoading) =>
  createAction(
    PRODUCT_MODIFIER_ACTION_TYPES.SET_FETCH_SCANNED_PRODUCT_MODIFIER_LOADING,
    fetchScannedProductModifierLoading
  );

export const setFetchScannedProductModifierSuccess = (fetchScannedProductModifierSuccess) =>
  createAction(
    PRODUCT_MODIFIER_ACTION_TYPES.SET_FETCH_SCANNED_PRODUCT_MODIFIER_SUCCESS,
    fetchScannedProductModifierSuccess
  );

export const setFetchScannedProductModifierFailed = (fetchScannedProductModifierFailed) =>
  createAction(
    PRODUCT_MODIFIER_ACTION_TYPES.SET_FETCH_SCANNED_PRODUCT_MODIFIER_FAILED,
    fetchScannedProductModifierFailed
  );

export const createProductModifierStart = (productId, request) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.CREATE_PRODUCT_MODIFIER_START, { productId, request });

export const updateProductModifierStart = (productId, productModifierId, request) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.UPDATE_PRODUCT_MODIFIER_START, { productId, productModifierId, request });

export const fetchScannedProductModifierStart = (barcode) =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.FETCH_SCANNED_PRODUCT_MODIFIER_START, barcode);

export const resetProductModifierReducer = () =>
  createAction(PRODUCT_MODIFIER_ACTION_TYPES.RESET_PRODUCT_MODIFIER_REDUCER);
