const SELECT_PRODUCT_CATEGORY_ACTION_TYPES = {
  RESET_SELECT_PRODUCT_CATEGORY_REDUCER:
    "select-product-category/RESET_SELECT_PRODUCT_CATEGORY_REDUCER",

  SET_PRODUCT_CATEGORIES: "select-product-category/SET_PRODUCT_CATEGORIES",
  SET_SEARCH_PRODUCT_CATEGORIES:
    "select-product-category/SET_SEARCH_PRODUCT_CATEGORIES",

  APPEND_PRODUCT_CATEGORIES:
    "select-product-category/APPEND_PRODUCT_CATEGORIES",
  APPEND_SEARCH_PRODUCT_CATEGORIES:
    "select-product-category/APPEND_SEARCH_PRODUCT_CATEGORIES",

  SET_IS_PRODUCT_CATEGORIES_HAS_MORE:
    "select-product-category/SET_IS_PRODUCT_CATEGORIES_HAS_MORE",
  SET_IS_SEARCH_PRODUCT_CATEGORIES_HAS_MORE:
    "select-product-category/SET_IS_SEARCH_PRODUCT_CATEGORIES_HAS_MORE",

  SET_FETCH_PRODUCT_CATEGORIES_PARAMS:
    "select-product-category/SET_FETCH_PRODUCT_CATEGORIES_PARAMS",
  SET_FETCH_PRODUCT_CATEGORIES_LOADING:
    "select-product-category/SET_FETCH_PRODUCT_CATEGORIES_LOADING",
  SET_FETCH_PRODUCT_CATEGORIES_SUCCESS:
    "select-product-category/SET_FETCH_PRODUCT_CATEGORIES_SUCCESS",
  SET_FETCH_PRODUCT_CATEGORIES_FAILED:
    "select-product-category/SET_FETCH_PRODUCT_CATEGORIES_FAILED",

  SET_SEARCH_PRODUCT_CATEGORIES_PARAMS:
    "select-product-category/SET_SEARCH_PRODUCT_CATEGORIES_PARAMS",
  SET_SEARCH_PRODUCT_CATEGORIES_LOADING:
    "select-product-category/SET_SEARCH_PRODUCT_CATEGORIES_LOADING",
  SET_SEARCH_PRODUCT_CATEGORIES_SUCCESS:
    "select-product-category/SET_SEARCH_PRODUCT_CATEGORIES_SUCCESS",
  SET_SEARCH_PRODUCT_CATEGORIES_FAILED:
    "select-product-category/SET_SEARCH_PRODUCT_CATEGORIES_FAILED",

  SET_APPEND_PRODUCT_CATEGORIES_PARAMS:
    "select-product-category/SET_APPEND_PRODUCT_CATEGORIES_PARAMS",
  SET_APPEND_PRODUCT_CATEGORIES_LOADING:
    "select-product-category/SET_APPEND_PRODUCT_CATEGORIES_LOADING",
  SET_APPEND_PRODUCT_CATEGORIES_SUCCESS:
    "select-product-category/SET_APPEND_PRODUCT_CATEGORIES_SUCCESS",
  SET_APPEND_PRODUCT_CATEGORIES_FAILED:
    "select-product-category/SET_APPEND_PRODUCT_CATEGORIES_FAILED",

  SET_IS_FETCH_PRODUCT_CATEGORIES_HITTED:
    "select-product-category/SET_IS_FETCH_PRODUCT_CATEGORIES_HITTED",
  SET_IS_SEARCH_PRODUCT_CATEGORIES_HITTED:
    "select-product-category/SET_IS_SEARCH_PRODUCT_CATEGORIES_HITTED",
  SET_IS_APPEND_PRODUCT_CATEGORIES_HITTED:
    "select-product-category/SET_IS_APPEND_PRODUCT_CATEGORIES_HITTED",

  FETCH_PRODUCT_CATEGORIES_START:
    "select-product-category/FETCH_PRODUCT_CATEGORIES_START",
  SEARCH_PRODUCT_CATEGORIES_START:
    "select-product-category/SEARCH_PRODUCT_CATEGORIES_START",
  APPEND_PRODUCT_CATEGORIES_START:
    "select-product-category/APPEND_PRODUCT_CATEGORIES_START",
};

export default SELECT_PRODUCT_CATEGORY_ACTION_TYPES;
