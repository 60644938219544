import apiService from "./api";

export const getPrinters = async (parameters) => (await apiService.get("/printer", parameters)).data;

export const getPrinter = async (id) => (await apiService.get(`/printer/${id}`)).data;

export const createPrinter = async (request) => (await apiService.post("/printer", request)).data;

export const updatePrinter = async (id, request) => (await apiService.post(`/printer/${id}`, request)).data;

export const deletePrinter = async (id) => (await apiService.delete(`/printer/${id}`)).data;
