import { PRINTER_SERVICE_TYPES, PRINTER_TARGETS } from "../../constants/printer.constant";

import PRINTER_ACTION_TYPES from "./printer.type";

export const PRINTER_INITIAL_STATE = {
  printerTargets: [
    {
      label: "All Features",
      value: PRINTER_TARGETS.ALL_FEATURES,
    },
    {
      label: "Order",
      value: PRINTER_TARGETS.ORDER,
    },
    {
      label: "Kitchen",
      value: PRINTER_TARGETS.KITCHEN,
    },
  ],
  printerServiceTypes: [
    {
      label: "All Service Types",
      value: PRINTER_SERVICE_TYPES.ALL_SERVICE_TYPES,
    },
    {
      label: "Take Away",
      value: PRINTER_SERVICE_TYPES.TAKE_AWAY,
    },
    {
      label: "Dining",
      value: PRINTER_SERVICE_TYPES.DINING,
    },
  ],
  printers: [],
  printer: null,

  isPrintersHasMore: true,

  fetchPrintersSearch: null,
  fetchPrintersPage: 1,
  fetchPrintersPerPage: null,
  fetchPrintersIncludes: null,
  fetchPrintersFilterMarketId: null,
  fetchPrintersFilterBranchId: null,
  fetchPrintersFilterPrinterTypeId: null,
  fetchPrintersFilterIsActive: null,

  fetchPrintersLoading: false,
  fetchPrintersSuccess: null,
  fetchPrintersFailed: null,

  fetchPrinterLoading: false,
  fetchPrinterSuccess: null,
  fetchPrinterFailed: null,

  createPrinterLoading: false,
  createPrinterSuccess: null,
  createPrinterFailed: null,

  updatePrinterLoading: false,
  updatePrinterSuccess: null,
  updatePrinterFailed: null,

  deletePrinterLoading: false,
  deletePrinterSuccess: null,
  deletePrinterFailed: null,
};

export const printerReducer = (state = PRINTER_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PRINTER_ACTION_TYPES.SET_PRINTERS:
      return { ...state, printers: payload };
    case PRINTER_ACTION_TYPES.SET_PRINTER:
      return { ...state, printer: payload };

    case PRINTER_ACTION_TYPES.SET_IS_PRINTERS_HAS_MORE:
      return { ...state, isPrintersHasMore: payload };

    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_SEARCH:
      return { ...state, fetchPrintersSearch: payload };
    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_PAGE:
      return { ...state, fetchPrintersPage: payload };
    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_PER_PAGE:
      return { ...state, fetchPrintersPerPage: payload };
    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_INCLUDES:
      return { ...state, fetchPrintersIncludes: payload };
    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_FILTER_MARKET_ID:
      return { ...state, fetchPrintersFilterMarketId: payload };
    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_FILTER_BRANCH_ID:
      return { ...state, fetchPrintersFilterBranchId: payload };
    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_FILTER_PRINTER_TYPE_ID:
      return { ...state, fetchPrintersFilterPrinterTypeId: payload };
    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_FILTER_IS_ACTIVE:
      return { ...state, fetchPrintersFilterIsActive: payload };
    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_LOADING:
      return { ...state, fetchPrintersLoading: payload };
    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_SUCCESS:
      return { ...state, fetchPrintersSuccess: payload };
    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTERS_FAILED:
      return { ...state, fetchPrintersFailed: payload };

    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTER_LOADING:
      return { ...state, fetchPrinterLoading: payload };
    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTER_SUCCESS:
      return { ...state, fetchPrinterSuccess: payload };
    case PRINTER_ACTION_TYPES.SET_FETCH_PRINTER_FAILED:
      return { ...state, fetchPrinterFailed: payload };

    case PRINTER_ACTION_TYPES.SET_CREATE_PRINTER_LOADING:
      return { ...state, createPrinterLoading: payload };
    case PRINTER_ACTION_TYPES.SET_CREATE_PRINTER_SUCCESS:
      return { ...state, createPrinterSuccess: payload };
    case PRINTER_ACTION_TYPES.SET_CREATE_PRINTER_FAILED:
      return { ...state, createPrinterFailed: payload };

    case PRINTER_ACTION_TYPES.SET_UPDATE_PRINTER_LOADING:
      return { ...state, updatePrinterLoading: payload };
    case PRINTER_ACTION_TYPES.SET_UPDATE_PRINTER_SUCCESS:
      return { ...state, updatePrinterSuccess: payload };
    case PRINTER_ACTION_TYPES.SET_UPDATE_PRINTER_FAILED:
      return { ...state, updatePrinterFailed: payload };

    case PRINTER_ACTION_TYPES.SET_DELETE_PRINTER_LOADING:
      return { ...state, deletePrinterLoading: payload };
    case PRINTER_ACTION_TYPES.SET_DELETE_PRINTER_SUCCESS:
      return { ...state, deletePrinterSuccess: payload };
    case PRINTER_ACTION_TYPES.SET_DELETE_PRINTER_FAILED:
      return { ...state, deletePrinterFailed: payload };

    case PRINTER_ACTION_TYPES.APPEND_PRINTERS:
      return { ...state, printers: [...state.printers, ...payload] };

    case PRINTER_ACTION_TYPES.RESET_PRINTER_REDUCER:
      return PRINTER_INITIAL_STATE;
    default:
      return state;
  }
};
