const HEADER_BRANCH_ACTION_TYPES = {
  RESET_HEADER_BRANCH_REDUCER: "header-branch/RESET_HEADER_BRANCH_REDUCER",

  SET_BRANCHES: "header-branch/SET_BRANCHES",
  SET_SEARCH_BRANCHES: "header-branch/SET_SEARCH_BRANCHES",

  APPEND_BRANCHES: "header-branch/APPEND_BRANCHES",
  APPEND_SEARCH_BRANCHES: "header-branch/APPEND_SEARCH_BRANCHES",

  SET_IS_BRANCHES_HAS_MORE: "header-branch/SET_IS_BRANCHES_HAS_MORE",
  SET_IS_SEARCH_BRANCHES_HAS_MORE:
    "header-branch/SET_IS_SEARCH_BRANCHES_HAS_MORE",

  SET_FETCH_BRANCHES_PARAMS: "header-branch/SET_FETCH_BRANCHES_PARAMS",
  SET_FETCH_BRANCHES_LOADING: "header-branch/SET_FETCH_BRANCHES_LOADING",
  SET_FETCH_BRANCHES_SUCCESS: "header-branch/SET_FETCH_BRANCHES_SUCCESS",
  SET_FETCH_BRANCHES_FAILED: "header-branch/SET_FETCH_BRANCHES_FAILED",

  SET_SEARCH_BRANCHES_PARAMS: "header-branch/SET_SEARCH_BRANCHES_PARAMS",
  SET_SEARCH_BRANCHES_LOADING: "header-branch/SET_SEARCH_BRANCHES_LOADING",
  SET_SEARCH_BRANCHES_SUCCESS: "header-branch/SET_SEARCH_BRANCHES_SUCCESS",
  SET_SEARCH_BRANCHES_FAILED: "header-branch/SET_SEARCH_BRANCHES_FAILED",

  SET_APPEND_BRANCHES_PARAMS: "header-branch/SET_APPEND_BRANCHES_PARAMS",
  SET_APPEND_BRANCHES_LOADING: "header-branch/SET_APPEND_BRANCHES_LOADING",
  SET_APPEND_BRANCHES_SUCCESS: "header-branch/SET_APPEND_BRANCHES_SUCCESS",
  SET_APPEND_BRANCHES_FAILED: "header-branch/SET_APPEND_BRANCHES_FAILED",

  SET_IS_FETCH_BRANCHES_HITTED: "header-branch/SET_IS_FETCH_BRANCHES_HITTED",
  SET_IS_SEARCH_BRANCHES_HITTED: "header-branch/SET_IS_SEARCH_BRANCHES_HITTED",
  SET_IS_APPEND_BRANCHES_HITTED: "header-branch/SET_IS_APPEND_BRANCHES_HITTED",

  FETCH_BRANCHES_START: "header-branch/FETCH_BRANCHES_START",
  SEARCH_BRANCHES_START: "header-branch/SEARCH_BRANCHES_START",
  APPEND_BRANCHES_START: "header-branch/APPEND_BRANCHES_START",
};

export default HEADER_BRANCH_ACTION_TYPES;
