const REPORT_MORE_ACTION_TYPES = {
  RESET_REPORT_MORE_REDUCER: "report-more/RESET_REPORT_MORE_REDUCER",

  SET_REPORT_MORE_CATEGORIES: "report-more/SET_REPORT_MORE_CATEGORIES",
  SET_REPORT_MORE_PRODUCTS: "report-more/SET_REPORT_MORE_PRODUCTS",
  SET_REPORT_MORE_CASHIERS: "report-more/SET_REPORT_MORE_CASHIERS",
  SET_REPORT_MORE_PAYMENTS: "report-more/SET_REPORT_MORE_PAYMENTS",
  SET_REPORT_MORE_TABLES: "report-more/SET_REPORT_MORE_TABLES",

  SET_IS_REPORT_MORE_CATEGORIES_HAS_MORE:
    "report-more/SET_IS_REPORT_MORE_CATEGORIES_HAS_MORE",
  SET_IS_REPORT_MORE_PRODUCTS_HAS_MORE:
    "report-more/SET_IS_REPORT_MORE_PRODUCTS_HAS_MORE",
  SET_IS_REPORT_MORE_CASHIERS_HAS_MORE:
    "report-more/SET_IS_REPORT_MORE_CASHIERS_HAS_MORE",
  SET_IS_REPORT_MORE_PAYMENTS_HAS_MORE:
    "report-more/SET_IS_REPORT_MORE_PAYMENTS_HAS_MORE",
  SET_IS_REPORT_MORE_TABLES_HAS_MORE:
    "report-more/SET_IS_REPORT_MORE_TABLES_HAS_MORE",

  FETCH_REPORT_MORE_CATEGORIES_PARAMS:
    "report-more/FETCH_REPORT_MORE_CATEGORIES_PARAMS",
  FETCH_REPORT_MORE_CATEGORIES_LOADING:
    "report-more/FETCH_REPORT_MORE_CATEGORIES_LOADING",
  FETCH_REPORT_MORE_CATEGORIES_SUCCESS:
    "report-more/FETCH_REPORT_MORE_CATEGORIES_SUCCESS",
  FETCH_REPORT_MORE_CATEGORIES_FAILED:
    "report-more/FETCH_REPORT_MORE_CATEGORIES_FAILED",

  FETCH_REPORT_MORE_PRODUCTS_PARAMS:
    "report-more/FETCH_REPORT_MORE_PRODUCTS_PARAMS",
  FETCH_REPORT_MORE_PRODUCTS_LOADING:
    "report-more/FETCH_REPORT_MORE_PRODUCTS_LOADING",
  FETCH_REPORT_MORE_PRODUCTS_SUCCESS:
    "report-more/FETCH_REPORT_MORE_PRODUCTS_SUCCESS",
  FETCH_REPORT_MORE_PRODUCTS_FAILED:
    "report-more/FETCH_REPORT_MORE_PRODUCTS_FAILED",

  FETCH_REPORT_MORE_CASHIERS_PARAMS:
    "report-more/FETCH_REPORT_MORE_CASHIERS_PARAMS",
  FETCH_REPORT_MORE_CASHIERS_LOADING:
    "report-more/FETCH_REPORT_MORE_CASHIERS_LOADING",
  FETCH_REPORT_MORE_CASHIERS_SUCCESS:
    "report-more/FETCH_REPORT_MORE_CASHIERS_SUCCESS",
  FETCH_REPORT_MORE_CASHIERS_FAILED:
    "report-more/FETCH_REPORT_MORE_CASHIERS_FAILED",

  FETCH_REPORT_MORE_PAYMENTS_PARAMS:
    "report-more/FETCH_REPORT_MORE_PAYMENTS_PARAMS",
  FETCH_REPORT_MORE_PAYMENTS_LOADING:
    "report-more/FETCH_REPORT_MORE_PAYMENTS_LOADING",
  FETCH_REPORT_MORE_PAYMENTS_SUCCESS:
    "report-more/FETCH_REPORT_MORE_PAYMENTS_SUCCESS",
  FETCH_REPORT_MORE_PAYMENTS_FAILED:
    "report-more/FETCH_REPORT_MORE_PAYMENTS_FAILED",

  FETCH_REPORT_MORE_TABLES_PARAMS:
    "report-more/FETCH_REPORT_MORE_TABLES_PARAMS",
  FETCH_REPORT_MORE_TABLES_LOADING:
    "report-more/FETCH_REPORT_MORE_TABLES_LOADING",
  FETCH_REPORT_MORE_TABLES_SUCCESS:
    "report-more/FETCH_REPORT_MORE_TABLES_SUCCESS",
  FETCH_REPORT_MORE_TABLES_FAILED:
    "report-more/FETCH_REPORT_MORE_TABLES_FAILED",

  APPEND_REPORT_MORE_CATEGORIES: "report-more/APPEND_REPORT_MORE_CATEGORIES",
  APPEND_REPORT_MORE_PRODUCTS: "report-more/APPEND_REPORT_MORE_PRODUCTS",
  APPEND_REPORT_MORE_CASHIERS: "report-more/APPEND_REPORT_MORE_CASHIERS",
  APPEND_REPORT_MORE_PAYMENTS: "report-more/APPEND_REPORT_MORE_PAYMENTS",
  APPEND_REPORT_MORE_TABLES: "report-more/APPEND_REPORT_MORE_TABLES",

  SET_IS_FETCH_REPORT_MORE_CATEGORIES_HITTED:
    "report-more/SET_IS_FETCH_REPORT_MORE_CATEGORIES_HITTED",
  SET_IS_FETCH_REPORT_MORE_PRODUCTS_HITTED:
    "report-more/SET_IS_FETCH_REPORT_MORE_PRODUCTS_HITTED",
  SET_IS_FETCH_REPORT_MORE_CASHIERS_HITTED:
    "report-more/SET_IS_FETCH_REPORT_MORE_CASHIERS_HITTED",
  SET_IS_FETCH_REPORT_MORE_PAYMENTS_HITTED:
    "report-more/SET_IS_FETCH_REPORT_MORE_PAYMENTS_HITTED",
  SET_IS_FETCH_REPORT_MORE_TABLES_HITTED:
    "report-more/SET_IS_FETCH_REPORT_MORE_TABLES_HITTED",

  FETCH_REPORT_MORE_CATEGORIES_START:
    "report-more/FETCH_REPORT_MORE_CATEGORIES_START",
  FETCH_REPORT_MORE_PRODUCTS_START:
    "report-more/FETCH_REPORT_MORE_PRODUCTS_START",
  FETCH_REPORT_MORE_CASHIERS_START:
    "report-more/FETCH_REPORT_MORE_CASHIERS_START",
  FETCH_REPORT_MORE_PAYMENTS_START:
    "report-more/FETCH_REPORT_MORE_PAYMENTS_START",
  FETCH_REPORT_MORE_TABLES_START: "report-more/FETCH_REPORT_MORE_TABLES_START",
};

export default REPORT_MORE_ACTION_TYPES;
