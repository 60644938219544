import PRODUCT_ACTION_TYPES from "./product.type";

export const PRODUCT_INITIAL_STATE = {
  selectedProducts: {},
  isSelectProducts: false,

  editModifierIndex: null,
  editModifierModel: null,

  products: [],
  orderProducts: [],
  product: null,

  isProductsHasMore: true,
  isOrderProductsHasMore: true,

  fetchProductsSearch: null,
  fetchProductsSort: null,
  fetchProductsKeyBy: null,
  fetchProductsPage: 1,
  fetchProductsPerPage: null,
  fetchProductsIncludes: null,
  fetchProductsFilterMarketId: null,
  fetchProductsFilterBranchId: null,
  fetchProductsFilterProductCategoryId: null,
  fetchProductsFilterProductsIds: null,
  fetchProductsFilterIsActive: null,
  fetchProductsLoading: false,
  fetchProductsSuccess: null,
  fetchProductsFailed: null,

  fetchOrderProductsSearch: null,
  fetchOrderProductsSort: null,
  fetchOrderProductsKeyBy: null,
  fetchOrderProductsPage: 1,
  fetchOrderProductsPerPage: null,
  fetchOrderProductsIncludes: null,
  fetchOrderProductsFilterMarketId: null,
  fetchOrderProductsFilterBranchId: null,
  fetchOrderProductsFilterProductCategoryId: null,
  fetchOrderProductsFilterProductsIds: null,
  fetchOrderProductsFilterIsActive: null,
  fetchOrderProductsLoading: false,
  fetchOrderProductsSuccess: null,
  fetchOrderProductsFailed: null,

  fetchProductLoading: false,
  fetchProductSuccess: null,
  fetchProductFailed: null,

  createProductLoading: false,
  createProductSuccess: null,
  createProductFailed: null,

  updateProductLoading: false,
  updateProductSuccess: null,
  updateProductFailed: null,

  deleteProductLoading: false,
  deleteProductSuccess: null,
  deleteProductFailed: null,

  multipleCreateProductsLoading: false,
  multipleCreateProductsSuccess: null,
  multipleCreateProductsFailed: null,

  multipleDeleteProductsLoading: false,
  multipleDeleteProductsSuccess: null,
  multipleDeleteProductsFailed: null,

  isFetchProductsHitted: false,
  isFetchOrderProductsHitted: false,
  isFetchProductHitted: false,
  isCreateProductHitted: false,
  isUpdateProductHitted: false,
  isDeleteProductHitted: false,
  isMultipleCreateProductsHitted: false,
  isMultipleDeleteProductsHitted: false,
};

export const productReducer = (state = PRODUCT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_ACTION_TYPES.SET_SELECTED_PRODUCTS:
      return { ...state, selectedProducts: payload };
    case PRODUCT_ACTION_TYPES.SET_IS_SELECT_PRODUCTS:
      return { ...state, isSelectProducts: payload };

    case PRODUCT_ACTION_TYPES.SET_EDIT_MODIFIER_INDEX:
      return { ...state, editModifierIndex: payload };
    case PRODUCT_ACTION_TYPES.SET_EDIT_MODIFIER_MODEL:
      return { ...state, editModifierModel: payload };

    case PRODUCT_ACTION_TYPES.SET_PRODUCTS:
      return { ...state, products: payload };
    case PRODUCT_ACTION_TYPES.SET_ORDER_PRODUCTS:
      return { ...state, orderProducts: payload };
    case PRODUCT_ACTION_TYPES.SET_PRODUCT:
      return { ...state, product: payload };

    case PRODUCT_ACTION_TYPES.SET_IS_PRODUCTS_HAS_MORE:
      return { ...state, isProductsHasMore: payload };
    case PRODUCT_ACTION_TYPES.SET_IS_ORDER_PRODUCTS_HAS_MORE:
      return { ...state, isOrderProductsHasMore: payload };

    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SEARCH:
      return { ...state, fetchProductsSearch: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SORT:
      return { ...state, fetchProductsSort: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_KEY_BY:
      return { ...state, fetchProductsKeyBy: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_PAGE:
      return { ...state, fetchProductsPage: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_PER_PAGE:
      return { ...state, fetchProductsPerPage: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_INCLUDES:
      return { ...state, fetchProductsIncludes: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_MARKET_ID:
      return { ...state, fetchProductsFilterMarketId: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_BRANCH_ID:
      return { ...state, fetchProductsFilterBranchId: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_PRODUCT_CATEGORY_ID:
      return { ...state, fetchProductsFilterProductCategoryId: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_PRODUCTS_IDS:
      return { ...state, fetchProductsFilterProductsIds: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_IS_ACTIVE:
      return { ...state, fetchProductsFilterIsActive: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_LOADING:
      return { ...state, fetchProductsLoading: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SUCCESS:
      return { ...state, fetchProductsSuccess: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FAILED:
      return { ...state, fetchProductsFailed: payload };

    case PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_SEARCH:
      return { ...state, fetchOrderProductsSearch: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_SORT:
      return { ...state, fetchOrderProductsSort: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_PAGE:
      return { ...state, fetchOrderProductsPage: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_PER_PAGE:
      return { ...state, fetchOrderProductsPerPage: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_INCLUDES:
      return { ...state, fetchOrderProductsIncludes: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_FILTER_MARKET_ID:
      return { ...state, fetchOrderProductsFilterMarketId: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_FILTER_BRANCH_ID:
      return { ...state, fetchOrderProductsFilterBranchId: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_FILTER_PRODUCT_CATEGORY_ID:
      return { ...state, fetchOrderProductsFilterProductCategoryId: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_FILTER_PRODUCTS_IDS:
      return { ...state, fetchOrderProductsFilterProductsIds: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_FILTER_IS_ACTIVE:
      return { ...state, fetchOrderProductsFilterIsActive: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_LOADING:
      return { ...state, fetchOrderProductsLoading: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_SUCCESS:
      return { ...state, fetchOrderProductsSuccess: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_FAILED:
      return { ...state, fetchOrderProductsFailed: payload };

    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCT_LOADING:
      return { ...state, fetchProductLoading: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCT_SUCCESS:
      return { ...state, fetchProductSuccess: payload };
    case PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCT_FAILED:
      return { ...state, fetchProductFailed: payload };

    case PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT_LOADING:
      return { ...state, createProductLoading: payload };
    case PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT_SUCCESS:
      return { ...state, createProductSuccess: payload };
    case PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT_FAILED:
      return { ...state, createProductFailed: payload };

    case PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT_LOADING:
      return { ...state, updateProductLoading: payload };
    case PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT_SUCCESS:
      return { ...state, updateProductSuccess: payload };
    case PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT_FAILED:
      return { ...state, updateProductFailed: payload };

    case PRODUCT_ACTION_TYPES.SET_DELETE_PRODUCT_LOADING:
      return { ...state, deleteProductLoading: payload };
    case PRODUCT_ACTION_TYPES.SET_DELETE_PRODUCT_SUCCESS:
      return { ...state, deleteProductSuccess: payload };
    case PRODUCT_ACTION_TYPES.SET_DELETE_PRODUCT_FAILED:
      return { ...state, deleteProductFailed: payload };

    case PRODUCT_ACTION_TYPES.SET_MULTIPLE_CREATE_PRODUCTS_LOADING:
      return { ...state, multipleCreateProductsLoading: payload };
    case PRODUCT_ACTION_TYPES.SET_MULTIPLE_CREATE_PRODUCTS_SUCCESS:
      return { ...state, multipleCreateProductsSuccess: payload };
    case PRODUCT_ACTION_TYPES.SET_MULTIPLE_CREATE_PRODUCTS_FAILED:
      return { ...state, multipleCreateProductsFailed: payload };

    case PRODUCT_ACTION_TYPES.SET_MULTIPLE_DELETE_PRODUCTS_LOADING:
      return { ...state, multipleDeleteProductsLoading: payload };
    case PRODUCT_ACTION_TYPES.SET_MULTIPLE_DELETE_PRODUCTS_SUCCESS:
      return { ...state, multipleDeleteProductsSuccess: payload };
    case PRODUCT_ACTION_TYPES.SET_MULTIPLE_DELETE_PRODUCTS_FAILED:
      return { ...state, multipleDeleteProductsFailed: payload };

    case PRODUCT_ACTION_TYPES.APPEND_PRODUCTS:
      return {
        ...state,
        products: [...state.products, ...payload],
      };
    case PRODUCT_ACTION_TYPES.APPEND_ORDER_PRODUCTS:
      return {
        ...state,
        orderProducts: [...state.orderProducts, ...payload],
      };

    case PRODUCT_ACTION_TYPES.IS_FETCH_PRODUCTS_HITTED:
      return { ...state, isFetchProductsHitted: payload };
    case PRODUCT_ACTION_TYPES.IS_FETCH_ORDER_PRODUCTS_HITTED:
      return { ...state, isFetchOrderProductsHitted: payload };
    case PRODUCT_ACTION_TYPES.IS_FETCH_PRODUCT_HITTED:
      return { ...state, isFetchProductHitted: payload };
    case PRODUCT_ACTION_TYPES.IS_CREATE_PRODUCT_HITTED:
      return { ...state, isCreateProductHitted: payload };
    case PRODUCT_ACTION_TYPES.IS_UPDATE_PRODUCT_HITTED:
      return { ...state, isUpdateProductHitted: payload };
    case PRODUCT_ACTION_TYPES.IS_DELETE_PRODUCT_HITTED:
      return { ...state, isDeleteProductHitted: payload };
    case PRODUCT_ACTION_TYPES.IS_MULTIPLE_CREATE_PRODUCTS_HITTED:
      return { ...state, isMultipleCreateProductsHitted: payload };
    case PRODUCT_ACTION_TYPES.IS_MULTIPLE_DELETE_PRODUCTS_HITTED:
      return { ...state, isMultipleDeleteProductsHitted: payload };

    case PRODUCT_ACTION_TYPES.RESET_PRODUCT_REDUCER:
      return PRODUCT_INITIAL_STATE;
    default:
      return state;
  }
};
