const DASHBOARD_ADMIN_ACTION_TYPES = {
  RESET_DASHBOARD_ADMIN_REDUCER:
    "dashboard-admin/RESET_DASHBOARD_ADMIN_REDUCER",

  SET_DASHBOARD_TOTAL_MARKET: "dashboard-admin/SET_DASHBOARD_TOTAL_MARKET",
  SET_DASHBOARD_TOTAL_BRANCH: "dashboard-admin/SET_DASHBOARD_TOTAL_BRANCH",
  SET_DASHBOARD_TOTAL_ORDER: "dashboard-admin/SET_DASHBOARD_TOTAL_ORDER",
  SET_DASHBOARD_TOTAL_REVENUE: "dashboard-admin/SET_DASHBOARD_TOTAL_REVENUE",
  SET_DASHBOARD_ORDERS_CHART: "dashboard-admin/SET_DASHBOARD_ORDERS_CHART",
  SET_DASHBOARD_LATEST_MARKETS: "dashboard-admin/SET_DASHBOARD_LATEST_MARKETS",

  SET_DASHBOARD_TOTAL_MARKET_LOADING:
    "dashboard-admin/SET_DASHBOARD_TOTAL_MARKET_LOADING",
  SET_DASHBOARD_TOTAL_BRANCH_LOADING:
    "dashboard-admin/SET_DASHBOARD_TOTAL_BRANCH_LOADING",
  SET_DASHBOARD_TOTAL_ORDER_LOADING:
    "dashboard-admin/SET_DASHBOARD_TOTAL_ORDER_LOADING",
  SET_DASHBOARD_TOTAL_REVENUE_LOADING:
    "dashboard-admin/SET_DASHBOARD_TOTAL_REVENUE_LOADING",
  SET_DASHBOARD_ORDERS_CHART_LOADING:
    "dashboard-admin/SET_DASHBOARD_ORDERS_CHART_LOADING",
  SET_DASHBOARD_LATEST_MARKETS_LOADING:
    "dashboard-admin/SET_DASHBOARD_LATEST_MARKETS_LOADING",

  SET_FETCH_DASHBOARD_ADMIN_FILTER_CREATED_AT_BEFORE:
    "dashboard-admin/SET_FETCH_DASHBOARD_ADMIN_FILTER_CREATED_AT_BEFORE",
  SET_FETCH_DASHBOARD_ADMIN_FILTER_CREATED_AT_AFTER:
    "dashboard-admin/SET_FETCH_DASHBOARD_ADMIN_FILTER_CREATED_AT_AFTER",
  SET_FETCH_DASHBOARD_ADMIN_FILTER_CURRENCY:
    "dashboard-admin/SET_FETCH_DASHBOARD_ADMIN_FILTER_CURRENCY",
  SET_FETCH_DASHBOARD_ADMIN_LOADING:
    "dashboard-admin/SET_FETCH_DASHBOARD_ADMIN_LOADING",
  SET_FETCH_DASHBOARD_ADMIN_SUCCESS:
    "dashboard-admin/SET_FETCH_DASHBOARD_ADMIN_SUCCESS",
  SET_FETCH_DASHBOARD_ADMIN_FAILED:
    "dashboard-admin/SET_FETCH_DASHBOARD_ADMIN_FAILED",

  SET_IS_FETCH_DASHBOARD_ADMIN_HITTED:
    "dashboard-admin/SET_IS_FETCH_DASHBOARD_ADMIN_HITTED",

  FETCH_DASHBOARD_ADMIN_START: "dashboard-admin/FETCH_DASHBOARD_ADMIN_START",
};

export default DASHBOARD_ADMIN_ACTION_TYPES;
