import ADMIN_ACTION_TYPES from "./admin.type";

export const ADMIN_INITIAL_STATE = {
  admins: [],
  admin: null,

  isAdminsHasMore: true,

  fetchAdminsSearch: null,
  fetchAdminsPage: 1,
  fetchAdminsPerPage: null,
  fetchAdminsIncludes: null,
  fetchAdminsFilterPermissionId: null,
  fetchAdminsLoading: false,
  fetchAdminsSuccess: null,
  fetchAdminsFailed: null,

  fetchAdminLoading: false,
  fetchAdminSuccess: null,
  fetchAdminFailed: null,

  createAdminLoading: false,
  createAdminSuccess: null,
  createAdminFailed: null,

  updateAdminLoading: false,
  updateAdminSuccess: null,
  updateAdminFailed: null,

  deleteAdminLoading: false,
  deleteAdminSuccess: null,
  deleteAdminFailed: null,

  isFetchAdminsHitted: false,
  isFetchAdminHitted: false,
  isCreateAdminHitted: false,
  isUpdateAdminHitted: false,
  isDeleteAdminHitted: false,
};

export const adminReducer = (state = ADMIN_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADMIN_ACTION_TYPES.SET_ADMINS:
      return { ...state, admins: payload };
    case ADMIN_ACTION_TYPES.SET_ADMIN:
      return { ...state, admin: payload };

    case ADMIN_ACTION_TYPES.SET_IS_ADMINS_HAS_MORE:
      return { ...state, isAdminsHasMore: payload };

    case ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_SEARCH:
      return { ...state, fetchAdminsSearch: payload };
    case ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_PAGE:
      return { ...state, fetchAdminsPage: payload };
    case ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_PER_PAGE:
      return { ...state, fetchAdminsPerPage: payload };
    case ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_INCLUDES:
      return { ...state, fetchAdminsIncludes: payload };
    case ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_FILTER_PERMISSION_ID:
      return { ...state, fetchAdminsFilterPermissionId: payload };
    case ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_LOADING:
      return { ...state, fetchAdminsLoading: payload };
    case ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_SUCCESS:
      return { ...state, fetchAdminsSuccess: payload };
    case ADMIN_ACTION_TYPES.SET_FETCH_ADMINS_FAILED:
      return { ...state, fetchAdminsFailed: payload };

    case ADMIN_ACTION_TYPES.SET_FETCH_ADMIN_LOADING:
      return { ...state, fetchAdminLoading: payload };
    case ADMIN_ACTION_TYPES.SET_FETCH_ADMIN_SUCCESS:
      return { ...state, fetchAdminSuccess: payload };
    case ADMIN_ACTION_TYPES.SET_FETCH_ADMIN_FAILED:
      return { ...state, fetchAdminFailed: payload };

    case ADMIN_ACTION_TYPES.SET_CREATE_ADMIN_LOADING:
      return { ...state, createAdminLoading: payload };
    case ADMIN_ACTION_TYPES.SET_CREATE_ADMIN_SUCCESS:
      return { ...state, createAdminSuccess: payload };
    case ADMIN_ACTION_TYPES.SET_CREATE_ADMIN_FAILED:
      return { ...state, createAdminFailed: payload };

    case ADMIN_ACTION_TYPES.SET_UPDATE_ADMIN_LOADING:
      return { ...state, updateAdminLoading: payload };
    case ADMIN_ACTION_TYPES.SET_UPDATE_ADMIN_SUCCESS:
      return { ...state, updateAdminSuccess: payload };
    case ADMIN_ACTION_TYPES.SET_UPDATE_ADMIN_FAILED:
      return { ...state, updateAdminFailed: payload };

    case ADMIN_ACTION_TYPES.SET_DELETE_ADMIN_LOADING:
      return { ...state, deleteAdminLoading: payload };
    case ADMIN_ACTION_TYPES.SET_DELETE_ADMIN_SUCCESS:
      return { ...state, deleteAdminSuccess: payload };
    case ADMIN_ACTION_TYPES.SET_DELETE_ADMIN_FAILED:
      return { ...state, deleteAdminFailed: payload };

    case ADMIN_ACTION_TYPES.APPEND_ADMINS:
      return { ...state, admins: [...state.admins, ...payload] };

    case ADMIN_ACTION_TYPES.SET_IS_FETCH_ADMINS_HITTED:
      return { ...state, isFetchAdminsHitted: payload };
    case ADMIN_ACTION_TYPES.SET_IS_FETCH_ADMIN_HITTED:
      return { ...state, isFetchAdminHitted: payload };
    case ADMIN_ACTION_TYPES.SET_IS_CREATE_ADMIN_HITTED:
      return { ...state, isCreateAdminHitted: payload };
    case ADMIN_ACTION_TYPES.SET_IS_UPDATE_ADMIN_HITTED:
      return { ...state, isUpdateAdminHitted: payload };
    case ADMIN_ACTION_TYPES.SET_IS_DELETE_ADMIN_HITTED:
      return { ...state, isDeleteAdminHitted: payload };

    case ADMIN_ACTION_TYPES.RESET_ADMIN_REDUCER:
      return ADMIN_INITIAL_STATE;
    default:
      return state;
  }
};
