import { createSelector } from "reselect";

const reportSelector = ({ report }) => report;

export const getPrevReportTotals = createSelector(
  [reportSelector],
  ({ prevReportTotals }) => prevReportTotals
);
export const getNextReportTotals = createSelector(
  [reportSelector],
  ({ nextReportTotals }) => nextReportTotals
);

export const getReportCategories = createSelector(
  [reportSelector],
  ({ reportCategories }) => reportCategories
);
export const getReportProducts = createSelector(
  [reportSelector],
  ({ reportProducts }) => reportProducts
);
export const getReportCashiers = createSelector(
  [reportSelector],
  ({ reportCashiers }) => reportCashiers
);
export const getReportPayments = createSelector(
  [reportSelector],
  ({ reportPayments }) => reportPayments
);
export const getReportTables = createSelector(
  [reportSelector],
  ({ reportTables }) => reportTables
);

export const getIsReportCategoriesHasMore = createSelector(
  [reportSelector],
  ({ isReportCategoriesHasMore }) => isReportCategoriesHasMore
);
export const getIsReportProductsHasMore = createSelector(
  [reportSelector],
  ({ isReportProductsHasMore }) => isReportProductsHasMore
);
export const getIsReportCashiersHasMore = createSelector(
  [reportSelector],
  ({ isReportCashiersHasMore }) => isReportCashiersHasMore
);
export const getIsReportPaymentsHasMore = createSelector(
  [reportSelector],
  ({ isReportPaymentsHasMore }) => isReportPaymentsHasMore
);
export const getIsReportTablesHasMore = createSelector(
  [reportSelector],
  ({ isReportTablesHasMore }) => isReportTablesHasMore
);

export const getFetchReportsFilterMarketId = createSelector(
  [reportSelector],
  ({ fetchReportsFilterMarketId }) => fetchReportsFilterMarketId
);
export const getFetchReportsFilterBranchId = createSelector(
  [reportSelector],
  ({ fetchReportsFilterBranchId }) => fetchReportsFilterBranchId
);
export const getFetchReportsFilterCashierId = createSelector(
  [reportSelector],
  ({ fetchReportsFilterCashierId }) => fetchReportsFilterCashierId
);
export const getFetchReportsFilterCustomerId = createSelector(
  [reportSelector],
  ({ fetchReportsFilterCustomerId }) => fetchReportsFilterCustomerId
);
export const getFetchReportsFilterCreatedAtAfter = createSelector(
  [reportSelector],
  ({ fetchReportsFilterCreatedAtAfter }) => fetchReportsFilterCreatedAtAfter
);
export const getFetchReportsFilterCreatedAtBefore = createSelector(
  [reportSelector],
  ({ fetchReportsFilterCreatedAtBefore }) => fetchReportsFilterCreatedAtBefore
);
export const getFetchReportsFilterOrderStatuses = createSelector(
  [reportSelector],
  ({ fetchReportsFilterOrderStatuses }) => fetchReportsFilterOrderStatuses
);
export const getFetchReportsFilterTransactionStatuses = createSelector(
  [reportSelector],
  ({ fetchReportsFilterTransactionStatuses }) =>
    fetchReportsFilterTransactionStatuses
);

export const getFetchPrevReportTotalsFilterCreatedAtAfter = createSelector(
  [reportSelector],
  ({ fetchPrevReportTotalsFilterCreatedAtAfter }) =>
    fetchPrevReportTotalsFilterCreatedAtAfter
);
export const getFetchPrevReportTotalsFilterCreatedAtBefore = createSelector(
  [reportSelector],
  ({ fetchPrevReportTotalsFilterCreatedAtBefore }) =>
    fetchPrevReportTotalsFilterCreatedAtBefore
);
export const getFetchPrevReportTotalsLoading = createSelector(
  [reportSelector],
  ({ fetchPrevReportTotalsLoading }) => fetchPrevReportTotalsLoading
);
export const getFetchPrevReportTotalsSuccess = createSelector(
  [reportSelector],
  ({ fetchPrevReportTotalsSuccess }) => fetchPrevReportTotalsSuccess
);
export const getFetchPrevReportTotalsFailed = createSelector(
  [reportSelector],
  ({ fetchPrevReportTotalsFailed }) => fetchPrevReportTotalsFailed
);

export const getFetchNextReportTotalsFilterCreatedAtAfter = createSelector(
  [reportSelector],
  ({ fetchNextReportTotalsFilterCreatedAtAfter }) =>
    fetchNextReportTotalsFilterCreatedAtAfter
);
export const getFetchNextReportTotalsFilterCreatedAtBefore = createSelector(
  [reportSelector],
  ({ fetchNextReportTotalsFilterCreatedAtBefore }) =>
    fetchNextReportTotalsFilterCreatedAtBefore
);
export const getFetchNextReportTotalsLoading = createSelector(
  [reportSelector],
  ({ fetchNextReportTotalsLoading }) => fetchNextReportTotalsLoading
);
export const getFetchNextReportTotalsSuccess = createSelector(
  [reportSelector],
  ({ fetchNextReportTotalsSuccess }) => fetchNextReportTotalsSuccess
);
export const getFetchNextReportTotalsFailed = createSelector(
  [reportSelector],
  ({ fetchNextReportTotalsFailed }) => fetchNextReportTotalsFailed
);

export const getFetchReportCategoriesSearch = createSelector(
  [reportSelector],
  ({ fetchReportCategoriesSearch }) => fetchReportCategoriesSearch
);
export const getFetchReportCategoriesSort = createSelector(
  [reportSelector],
  ({ fetchReportCategoriesSort }) => fetchReportCategoriesSort
);
export const getFetchReportCategoriesKeyBy = createSelector(
  [reportSelector],
  ({ fetchReportCategoriesKeyBy }) => fetchReportCategoriesKeyBy
);
export const getFetchReportCategoriesPage = createSelector(
  [reportSelector],
  ({ fetchReportCategoriesPage }) => fetchReportCategoriesPage
);
export const getFetchReportCategoriesPerPage = createSelector(
  [reportSelector],
  ({ fetchReportCategoriesPerPage }) => fetchReportCategoriesPerPage
);
export const getFetchReportCategoriesFilterProductStatuses = createSelector(
  [reportSelector],
  ({ fetchReportCategoriesFilterProductStatuses }) =>
    fetchReportCategoriesFilterProductStatuses
);
export const getFetchReportCategoriesLoading = createSelector(
  [reportSelector],
  ({ fetchReportCategoriesLoading }) => fetchReportCategoriesLoading
);
export const getFetchReportCategoriesSuccess = createSelector(
  [reportSelector],
  ({ fetchReportCategoriesSuccess }) => fetchReportCategoriesSuccess
);
export const getFetchReportCategoriesFailed = createSelector(
  [reportSelector],
  ({ fetchReportCategoriesFailed }) => fetchReportCategoriesFailed
);

export const getFetchReportProductsSearch = createSelector(
  [reportSelector],
  ({ fetchReportProductsSearch }) => fetchReportProductsSearch
);
export const getFetchReportProductsSort = createSelector(
  [reportSelector],
  ({ fetchReportProductsSort }) => fetchReportProductsSort
);
export const getFetchReportProductsKeyBy = createSelector(
  [reportSelector],
  ({ fetchReportProductsKeyBy }) => fetchReportProductsKeyBy
);
export const getFetchReportProductsPage = createSelector(
  [reportSelector],
  ({ fetchReportProductsPage }) => fetchReportProductsPage
);
export const getFetchReportProductsPerPage = createSelector(
  [reportSelector],
  ({ fetchReportProductsPerPage }) => fetchReportProductsPerPage
);
export const getFetchReportProductsFilterProductStatuses = createSelector(
  [reportSelector],
  ({ fetchReportProductsFilterProductStatuses }) =>
    fetchReportProductsFilterProductStatuses
);
export const getFetchReportProductsLoading = createSelector(
  [reportSelector],
  ({ fetchReportProductsLoading }) => fetchReportProductsLoading
);
export const getFetchReportProductsSuccess = createSelector(
  [reportSelector],
  ({ fetchReportProductsSuccess }) => fetchReportProductsSuccess
);
export const getFetchReportProductsFailed = createSelector(
  [reportSelector],
  ({ fetchReportProductsFailed }) => fetchReportProductsFailed
);

export const getFetchReportCashiersSearch = createSelector(
  [reportSelector],
  ({ fetchReportCashiersSearch }) => fetchReportCashiersSearch
);
export const getFetchReportCashiersSort = createSelector(
  [reportSelector],
  ({ fetchReportCashiersSort }) => fetchReportCashiersSort
);
export const getFetchReportCashiersKeyBy = createSelector(
  [reportSelector],
  ({ fetchReportCashiersKeyBy }) => fetchReportCashiersKeyBy
);
export const getFetchReportCashiersPage = createSelector(
  [reportSelector],
  ({ fetchReportCashiersPage }) => fetchReportCashiersPage
);
export const getFetchReportCashiersPerPage = createSelector(
  [reportSelector],
  ({ fetchReportCashiersPerPage }) => fetchReportCashiersPerPage
);
export const getFetchReportCashiersLoading = createSelector(
  [reportSelector],
  ({ fetchReportCashiersLoading }) => fetchReportCashiersLoading
);
export const getFetchReportCashiersSuccess = createSelector(
  [reportSelector],
  ({ fetchReportCashiersSuccess }) => fetchReportCashiersSuccess
);
export const getFetchReportCashiersFailed = createSelector(
  [reportSelector],
  ({ fetchReportCashiersFailed }) => fetchReportCashiersFailed
);

export const getFetchReportPaymentsSearch = createSelector(
  [reportSelector],
  ({ fetchReportPaymentsSearch }) => fetchReportPaymentsSearch
);
export const getFetchReportPaymentsSort = createSelector(
  [reportSelector],
  ({ fetchReportPaymentsSort }) => fetchReportPaymentsSort
);
export const getFetchReportPaymentsKeyBy = createSelector(
  [reportSelector],
  ({ fetchReportPaymentsKeyBy }) => fetchReportPaymentsKeyBy
);
export const getFetchReportPaymentsPage = createSelector(
  [reportSelector],
  ({ fetchReportPaymentsPage }) => fetchReportPaymentsPage
);
export const getFetchReportPaymentsPerPage = createSelector(
  [reportSelector],
  ({ fetchReportPaymentsPerPage }) => fetchReportPaymentsPerPage
);
export const getFetchReportPaymentsFilterPaymentStatuses = createSelector(
  [reportSelector],
  ({ fetchReportPaymentsFilterPaymentStatuses }) =>
    fetchReportPaymentsFilterPaymentStatuses
);
export const getFetchReportPaymentsLoading = createSelector(
  [reportSelector],
  ({ fetchReportPaymentsLoading }) => fetchReportPaymentsLoading
);
export const getFetchReportPaymentsSuccess = createSelector(
  [reportSelector],
  ({ fetchReportPaymentsSuccess }) => fetchReportPaymentsSuccess
);
export const getFetchReportPaymentsFailed = createSelector(
  [reportSelector],
  ({ fetchReportPaymentsFailed }) => fetchReportPaymentsFailed
);

export const getFetchReportTablesSearch = createSelector(
  [reportSelector],
  ({ fetchReportTablesSearch }) => fetchReportTablesSearch
);
export const getFetchReportTablesSort = createSelector(
  [reportSelector],
  ({ fetchReportTablesSort }) => fetchReportTablesSort
);
export const getFetchReportTablesKeyBy = createSelector(
  [reportSelector],
  ({ fetchReportTablesKeyBy }) => fetchReportTablesKeyBy
);
export const getFetchReportTablesPage = createSelector(
  [reportSelector],
  ({ fetchReportTablesPage }) => fetchReportTablesPage
);
export const getFetchReportTablesPerPage = createSelector(
  [reportSelector],
  ({ fetchReportTablesPerPage }) => fetchReportTablesPerPage
);
export const getFetchReportTablesLoading = createSelector(
  [reportSelector],
  ({ fetchReportTablesLoading }) => fetchReportTablesLoading
);
export const getFetchReportTablesSuccess = createSelector(
  [reportSelector],
  ({ fetchReportTablesSuccess }) => fetchReportTablesSuccess
);
export const getFetchReportTablesFailed = createSelector(
  [reportSelector],
  ({ fetchReportTablesFailed }) => fetchReportTablesFailed
);

export const getIsFetchPrevReportTotalsHitted = createSelector(
  [reportSelector],
  ({ isFetchPrevReportTotalsHitted }) => isFetchPrevReportTotalsHitted
);
export const getIsFetchNextReportTotalsHitted = createSelector(
  [reportSelector],
  ({ isFetchNextReportTotalsHitted }) => isFetchNextReportTotalsHitted
);
export const getIsFetchReportCategoriesHitted = createSelector(
  [reportSelector],
  ({ isFetchReportCategoriesHitted }) => isFetchReportCategoriesHitted
);
export const getIsFetchReportProductsHitted = createSelector(
  [reportSelector],
  ({ isFetchReportProductsHitted }) => isFetchReportProductsHitted
);
export const getIsFetchReportCashiersHitted = createSelector(
  [reportSelector],
  ({ isFetchReportCashiersHitted }) => isFetchReportCashiersHitted
);
export const getIsFetchReportPaymentsHitted = createSelector(
  [reportSelector],
  ({ isFetchReportPaymentsHitted }) => isFetchReportPaymentsHitted
);
export const getIsFetchReportTablesHitted = createSelector(
  [reportSelector],
  ({ isFetchReportTablesHitted }) => isFetchReportTablesHitted
);
