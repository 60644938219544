import PRINTER_TYPE_ACTION_TYPES from "./printer-type.type";
import { createAction } from "../../utils/store.utils";

export const setPrinterTypes = (printerTypes) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_PRINTER_TYPES, printerTypes);
export const setSelectPrinterTypes = (selectPrinterTypes) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_SELECT_PRINTER_TYPES, selectPrinterTypes);
export const setPrinterType = (printerType) => createAction(PRINTER_TYPE_ACTION_TYPES.SET_PRINTER_TYPE, printerType);

export const setIsPrinterTypesHasMore = (isPrinterTypesHasMore) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_IS_PRINTER_TYPES_HAS_MORE, isPrinterTypesHasMore);
export const setIsSelectPrinterTypesHasMore = (isSelectPrinterTypesHasMore) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_IS_SELECT_PRINTER_TYPES_HAS_MORE, isSelectPrinterTypesHasMore);

export const setFetchPrinterTypesPage = (fetchPrinterTypesPage) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPES_PAGE, fetchPrinterTypesPage);
export const setFetchPrinterTypesPerPage = (fetchPrinterTypesPerPage) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPES_PER_PAGE, fetchPrinterTypesPerPage);
export const setFetchPrinterTypesLoading = (fetchPrinterTypesLoading) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPES_LOADING, fetchPrinterTypesLoading);
export const setFetchPrinterTypesSuccess = (fetchPrinterTypesSuccess) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPES_SUCCESS, fetchPrinterTypesSuccess);
export const setFetchPrinterTypesFailed = (fetchPrinterTypesFailed) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPES_FAILED, fetchPrinterTypesFailed);

export const setFetchSelectPrinterTypesPage = (fetchSelectPrinterTypesPage) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_FETCH_SELECT_PRINTER_TYPES_PAGE, fetchSelectPrinterTypesPage);
export const setFetchSelectPrinterTypesPerPage = (fetchSelectPrinterTypesPerPage) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_FETCH_SELECT_PRINTER_TYPES_PER_PAGE, fetchSelectPrinterTypesPerPage);
export const setFetchSelectPrinterTypesLoading = (fetchSelectPrinterTypesLoading) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_FETCH_SELECT_PRINTER_TYPES_LOADING, fetchSelectPrinterTypesLoading);
export const setFetchSelectPrinterTypesSuccess = (fetchSelectPrinterTypesSuccess) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_FETCH_SELECT_PRINTER_TYPES_SUCCESS, fetchSelectPrinterTypesSuccess);
export const setFetchSelectPrinterTypesFailed = (fetchSelectPrinterTypesFailed) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_FETCH_SELECT_PRINTER_TYPES_FAILED, fetchSelectPrinterTypesFailed);

export const setFetchPrinterTypeLoading = (fetchPrinterTypeLoading) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPE_LOADING, fetchPrinterTypeLoading);
export const setFetchPrinterTypeSuccess = (fetchPrinterTypeSuccess) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPE_SUCCESS, fetchPrinterTypeSuccess);
export const setFetchPrinterTypeFailed = (fetchPrinterTypeFailed) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_FETCH_PRINTER_TYPE_FAILED, fetchPrinterTypeFailed);

export const setCreatePrinterTypeLoading = (createPrinterTypeLoading) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_CREATE_PRINTER_TYPE_LOADING, createPrinterTypeLoading);
export const setCreatePrinterTypeSuccess = (createPrinterTypeSuccess) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_CREATE_PRINTER_TYPE_SUCCESS, createPrinterTypeSuccess);
export const setCreatePrinterTypeFailed = (createPrinterTypeFailed) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_CREATE_PRINTER_TYPE_FAILED, createPrinterTypeFailed);

export const setUpdatePrinterTypeLoading = (updatePrinterTypeLoading) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_UPDATE_PRINTER_TYPE_LOADING, updatePrinterTypeLoading);
export const setUpdatePrinterTypeSuccess = (updatePrinterTypeSuccess) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_UPDATE_PRINTER_TYPE_SUCCESS, updatePrinterTypeSuccess);
export const setUpdatePrinterTypeFailed = (updatePrinterTypeFailed) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_UPDATE_PRINTER_TYPE_FAILED, updatePrinterTypeFailed);

export const setDeletePrinterTypeLoading = (deletePrinterTypeLoading) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_DELETE_PRINTER_TYPE_LOADING, deletePrinterTypeLoading);
export const setDeletePrinterTypeSuccess = (deletePrinterTypeSuccess) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_DELETE_PRINTER_TYPE_SUCCESS, deletePrinterTypeSuccess);
export const setDeletePrinterTypeFailed = (deletePrinterTypeFailed) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_DELETE_PRINTER_TYPE_FAILED, deletePrinterTypeFailed);

export const appendSelectPrinterTypes = (selectPrinterTypes) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.APPEND_SELECT_PRINTER_TYPES, selectPrinterTypes);
export const appendPrinterTypes = (printerTypes) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.APPEND_PRINTER_TYPES, printerTypes);

export const setIsFetchPrinterTypesHitted = (isFetchPrinterTypesHitted) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_IS_FETCH_PRINTER_TYPES_HITTED, isFetchPrinterTypesHitted);
export const setIsFetchSelectPrinterTypesHitted = (isFetchSelectPrinterTypesHitted) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_IS_FETCH_SELECT_PRINTER_TYPES_HITTED, isFetchSelectPrinterTypesHitted);
export const setIsFetchPrinterTypeHitted = (isFetchPrinterTypeHitted) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_IS_FETCH_PRINTER_TYPE_HITTED, isFetchPrinterTypeHitted);
export const setIsCreatePrinterTypeHitted = (isCreatePrinterTypeHitted) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_IS_CREATE_PRINTER_TYPE_HITTED, isCreatePrinterTypeHitted);
export const setIsUpdatePrinterTypeHitted = (isUpdatePrinterTypeHitted) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_IS_UPDATE_PRINTER_TYPE_HITTED, isUpdatePrinterTypeHitted);
export const setIsDeletePrinterTypeHitted = (isDeletePrinterTypeHitted) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.SET_IS_DELETE_PRINTER_TYPE_HITTED, isDeletePrinterTypeHitted);

export const fetchPrinterTypesStart = () => createAction(PRINTER_TYPE_ACTION_TYPES.FETCH_PRINTER_TYPES_START);
export const fetchSelectPrinterTypesStart = () =>
  createAction(PRINTER_TYPE_ACTION_TYPES.FETCH_SELECT_PRINTER_TYPES_START);
export const fetchPrinterTypeStart = (id) => createAction(PRINTER_TYPE_ACTION_TYPES.FETCH_PRINTER_TYPE_START, id);
export const createPrinterTypeStart = (request) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.CREATE_PRINTER_TYPE_START, request);
export const updatePrinterTypeStart = (id, request) =>
  createAction(PRINTER_TYPE_ACTION_TYPES.UPDATE_PRINTER_TYPE_START, { id, request });
export const deletePrinterTypeStart = (id) => createAction(PRINTER_TYPE_ACTION_TYPES.DELETE_PRINTER_TYPE_START, id);

export const resetPrinterTypeReducer = () => createAction(PRINTER_TYPE_ACTION_TYPES.RESET_PRINTER_TYPE_REDUCER);
