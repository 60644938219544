import TABLE_ACTION_TYPES from "./table.type";
import { createAction } from "../../utils/store.utils";

export const setIsModalAutoClose = (isModalAutoClose) =>
  createAction(TABLE_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE, isModalAutoClose);

export const setTables = (tables) =>
  createAction(TABLE_ACTION_TYPES.SET_TABLES, tables);
export const setOrderTables = (orderTables) =>
  createAction(TABLE_ACTION_TYPES.SET_ORDER_TABLES, orderTables);
export const setSelectTables = (selectTables) =>
  createAction(TABLE_ACTION_TYPES.SET_SELECT_TABLES, selectTables);
export const setTable = (table) =>
  createAction(TABLE_ACTION_TYPES.SET_TABLE, table);
export const setOrderTable = (orderTable) =>
  createAction(TABLE_ACTION_TYPES.SET_ORDER_TABLE, orderTable);

export const setIsTablesHasMore = (isTablesHasMore) =>
  createAction(TABLE_ACTION_TYPES.SET_IS_TABLES_HAS_MORE, isTablesHasMore);
export const setIsOrderTablesHasMore = (isOrderTablesHasMore) =>
  createAction(
    TABLE_ACTION_TYPES.SET_IS_ORDER_TABLES_HAS_MORE,
    isOrderTablesHasMore
  );
export const setIsSelectTablesHasMore = (isSelectTablesHasMore) =>
  createAction(
    TABLE_ACTION_TYPES.SET_IS_SELECT_TABLES_HAS_MORE,
    isSelectTablesHasMore
  );

export const setFetchTablesSearch = (fetchTablesSearch) =>
  createAction(TABLE_ACTION_TYPES.SET_FETCH_TABLES_SEARCH, fetchTablesSearch);
export const setFetchTablesPage = (fetchTablesPage) =>
  createAction(TABLE_ACTION_TYPES.SET_FETCH_TABLES_PAGE, fetchTablesPage);
export const setFetchTablesPerPage = (fetchTablesPerPage) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_TABLES_PER_PAGE,
    fetchTablesPerPage
  );
export const setFetchTablesIncludes = (fetchTablesIncludes) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_TABLES_INCLUDES,
    fetchTablesIncludes
  );
export const setFetchTablesFilterMarketId = (fetchTablesFilterMarketId) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_TABLES_FILTER_MARKET_ID,
    fetchTablesFilterMarketId
  );
export const setFetchTablesFilterBranchId = (fetchTablesFilterBranchId) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_TABLES_FILTER_BRANCH_ID,
    fetchTablesFilterBranchId
  );
export const setFetchTablesFilterStatus = (fetchTablesFilterStatus) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_TABLES_FILTER_STATUS,
    fetchTablesFilterStatus
  );
export const setFetchTablesLoading = (fetchTablesLoading) =>
  createAction(TABLE_ACTION_TYPES.SET_FETCH_TABLES_LOADING, fetchTablesLoading);
export const setFetchTablesSuccess = (fetchTablesSuccess) =>
  createAction(TABLE_ACTION_TYPES.SET_FETCH_TABLES_SUCCESS, fetchTablesSuccess);
export const setFetchTablesFailed = (fetchTablesFailed) =>
  createAction(TABLE_ACTION_TYPES.SET_FETCH_TABLES_FAILED, fetchTablesFailed);

export const setFetchOrderTablesSearch = (fetchOrderTablesSearch) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_SEARCH,
    fetchOrderTablesSearch
  );
export const setFetchOrderTablesPage = (fetchOrderTablesPage) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_PAGE,
    fetchOrderTablesPage
  );
export const setFetchOrderTablesPerPage = (fetchOrderTablesPerPage) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_PER_PAGE,
    fetchOrderTablesPerPage
  );
export const setFetchOrderTablesIncludes = (fetchOrderTablesIncludes) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_INCLUDES,
    fetchOrderTablesIncludes
  );
export const setFetchOrderTablesFilterMarketId = (
  fetchOrderTablesFilterMarketId
) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_FILTER_MARKET_ID,
    fetchOrderTablesFilterMarketId
  );
export const setFetchOrderTablesFilterBranchId = (
  fetchOrderTablesFilterBranchId
) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_FILTER_BRANCH_ID,
    fetchOrderTablesFilterBranchId
  );
export const setFetchOrderTablesFilterStatus = (fetchOrderTablesFilterStatus) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_FILTER_STATUS,
    fetchOrderTablesFilterStatus
  );
export const setFetchOrderTablesLoading = (fetchOrderTablesLoading) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_LOADING,
    fetchOrderTablesLoading
  );
export const setFetchOrderTablesSuccess = (fetchOrderTablesSuccess) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_SUCCESS,
    fetchOrderTablesSuccess
  );
export const setFetchOrderTablesFailed = (fetchOrderTablesFailed) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_ORDER_TABLES_FAILED,
    fetchOrderTablesFailed
  );

export const setFetchSelectTablesSearch = (fetchSelectTablesSearch) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_SEARCH,
    fetchSelectTablesSearch
  );
export const setFetchSelectTablesPage = (fetchSelectTablesPage) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_PAGE,
    fetchSelectTablesPage
  );
export const setFetchSelectTablesPerPage = (fetchSelectTablesPerPage) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_PER_PAGE,
    fetchSelectTablesPerPage
  );
export const setFetchSelectTablesIncludes = (fetchSelectTablesIncludes) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_INCLUDES,
    fetchSelectTablesIncludes
  );
export const setFetchSelectTablesFilterMarketId = (
  fetchSelectTablesFilterMarketId
) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_FILTER_MARKET_ID,
    fetchSelectTablesFilterMarketId
  );
export const setFetchSelectTablesFilterBranchId = (
  fetchSelectTablesFilterBranchId
) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_FILTER_BRANCH_ID,
    fetchSelectTablesFilterBranchId
  );
export const setFetchSelectTablesFilterTablesIds = (
  fetchSelectTablesFilterTablesIds
) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_FILTER_TABLES_IDS,
    fetchSelectTablesFilterTablesIds
  );
export const setFetchSelectTablesFilterStatus = (
  fetchSelectTablesFilterStatus
) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_FILTER_STATUS,
    fetchSelectTablesFilterStatus
  );
export const setFetchSelectTablesLoading = (fetchSelectTablesLoading) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_LOADING,
    fetchSelectTablesLoading
  );
export const setFetchSelectTablesSuccess = (fetchSelectTablesSuccess) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_SUCCESS,
    fetchSelectTablesSuccess
  );
export const setFetchSelectTablesFailed = (fetchSelectTablesFailed) =>
  createAction(
    TABLE_ACTION_TYPES.SET_FETCH_SELECT_TABLES_FAILED,
    fetchSelectTablesFailed
  );

export const setFetchTableLoading = (fetchTableLoading) =>
  createAction(TABLE_ACTION_TYPES.SET_FETCH_TABLE_LOADING, fetchTableLoading);
export const setFetchTableSuccess = (fetchTableSuccess) =>
  createAction(TABLE_ACTION_TYPES.SET_FETCH_TABLE_SUCCESS, fetchTableSuccess);
export const setFetchTableFailed = (fetchTableFailed) =>
  createAction(TABLE_ACTION_TYPES.SET_FETCH_TABLE_FAILED, fetchTableFailed);

export const setCreateTableLoading = (createTableLoading) =>
  createAction(TABLE_ACTION_TYPES.SET_CREATE_TABLE_LOADING, createTableLoading);
export const setCreateTableSuccess = (createTableSuccess) =>
  createAction(TABLE_ACTION_TYPES.SET_CREATE_TABLE_SUCCESS, createTableSuccess);
export const setCreateTableFailed = (createTableFailed) =>
  createAction(TABLE_ACTION_TYPES.SET_CREATE_TABLE_FAILED, createTableFailed);

export const setUpdateTableLoading = (updateTableLoading) =>
  createAction(TABLE_ACTION_TYPES.SET_UPDATE_TABLE_LOADING, updateTableLoading);
export const setUpdateTableSuccess = (updateTableSuccess) =>
  createAction(TABLE_ACTION_TYPES.SET_UPDATE_TABLE_SUCCESS, updateTableSuccess);
export const setUpdateTableFailed = (updateTableFailed) =>
  createAction(TABLE_ACTION_TYPES.SET_UPDATE_TABLE_FAILED, updateTableFailed);

export const setDeleteTableLoading = (deleteTableLoading) =>
  createAction(TABLE_ACTION_TYPES.SET_DELETE_TABLE_LOADING, deleteTableLoading);
export const setDeleteTableSuccess = (deleteTableSuccess) =>
  createAction(TABLE_ACTION_TYPES.SET_DELETE_TABLE_SUCCESS, deleteTableSuccess);
export const setDeleteTableFailed = (deleteTableFailed) =>
  createAction(TABLE_ACTION_TYPES.SET_DELETE_TABLE_FAILED, deleteTableFailed);

export const setMultipleCreateTableLoading = (multipleCreateTableLoading) =>
  createAction(
    TABLE_ACTION_TYPES.SET_MULTIPLE_CREATE_TABLE_LOADING,
    multipleCreateTableLoading
  );
export const setMultipleCreateTableSuccess = (multipleCreateTableSuccess) =>
  createAction(
    TABLE_ACTION_TYPES.SET_MULTIPLE_CREATE_TABLE_SUCCESS,
    multipleCreateTableSuccess
  );
export const setMultipleCreateTableFailed = (multipleCreateTableFailed) =>
  createAction(
    TABLE_ACTION_TYPES.SET_MULTIPLE_CREATE_TABLE_FAILED,
    multipleCreateTableFailed
  );

export const appendTables = (tables) =>
  createAction(TABLE_ACTION_TYPES.APPEND_TABLES, tables);
export const appendOrderTables = (orderTables) =>
  createAction(TABLE_ACTION_TYPES.APPEND_ORDER_TABLES, orderTables);
export const appendSelectTables = (selectTables) =>
  createAction(TABLE_ACTION_TYPES.APPEND_SELECT_TABLES, selectTables);
export const fetchTablesStart = () =>
  createAction(TABLE_ACTION_TYPES.FETCH_TABLES_START);
export const fetchOrderTablesStart = () =>
  createAction(TABLE_ACTION_TYPES.FETCH_ORDER_TABLES_START);
export const fetchSelectTablesStart = () =>
  createAction(TABLE_ACTION_TYPES.FETCH_SELECT_TABLES_START);
export const fetchTableStart = (tableId) =>
  createAction(TABLE_ACTION_TYPES.FETCH_TABLE_START, tableId);
export const createTableStart = (request) =>
  createAction(TABLE_ACTION_TYPES.CREATE_TABLE_START, request);
export const updateTableStart = (tableId, request) =>
  createAction(TABLE_ACTION_TYPES.UPDATE_TABLE_START, { tableId, request });
export const deleteTableStart = (tableId) =>
  createAction(TABLE_ACTION_TYPES.DELETE_TABLE_START, tableId);
export const multipleCreateTableStart = (request) =>
  createAction(TABLE_ACTION_TYPES.MULTIPLE_CREATE_TABLE_START, request);

export const setIsFetchTablesHitted = (isFetchTablesHitted) =>
  createAction(
    TABLE_ACTION_TYPES.SET_IS_FETCH_TABLES_HITTED,
    isFetchTablesHitted
  );
export const setIsFetchOrderTablesHitted = (isFetchOrderTablesHitted) =>
  createAction(
    TABLE_ACTION_TYPES.SET_IS_FETCH_ORDER_TABLES_HITTED,
    isFetchOrderTablesHitted
  );
export const setIsFetchSelectTablesHitted = (isFetchSelectTablesHitted) =>
  createAction(
    TABLE_ACTION_TYPES.SET_IS_FETCH_SELECT_TABLES_HITTED,
    isFetchSelectTablesHitted
  );
export const setIsFetchTableHitted = (isFetchTableHitted) =>
  createAction(
    TABLE_ACTION_TYPES.SET_IS_FETCH_TABLE_HITTED,
    isFetchTableHitted
  );
export const setIsCreateTableHitted = (isCreateTableHitted) =>
  createAction(
    TABLE_ACTION_TYPES.SET_IS_CREATE_TABLE_HITTED,
    isCreateTableHitted
  );
export const setIsUpdateTableHitted = (isUpdateTableHitted) =>
  createAction(
    TABLE_ACTION_TYPES.SET_IS_UPDATE_TABLE_HITTED,
    isUpdateTableHitted
  );
export const setIsDeleteTableHitted = (isDeleteTableHitted) =>
  createAction(
    TABLE_ACTION_TYPES.SET_IS_DELETE_TABLE_HITTED,
    isDeleteTableHitted
  );
export const setIsMultipleCreateTableHitted = (isMultipleCreateTableHitted) =>
  createAction(
    TABLE_ACTION_TYPES.SET_IS_MULTIPLE_CREATE_TABLE_HITTED,
    isMultipleCreateTableHitted
  );

export const resetTableReducer = () =>
  createAction(TABLE_ACTION_TYPES.RESET_TABLE_REDUCER);
