import DASHBOARD_SUBSCRIBER_ACTION_TYPES from "./dashboard-subscriber.type";

export const DASHBOARD_SUBSCRIBER_INITIAL_STATE = {
  dashboard: null,

  fetchDashboardFilterMarketId: null,
  fetchDashboardFilterBranchId: null,
  fetchDashboardFilterOrderStatuses: null,
  fetchDashboardFilterTransactionStatuses: null,
  fetchDashboardFilterPeriodicity: null,
  fetchDashboardLoading: null,
  fetchDashboardSuccess: null,
  fetchDashboardFailed: null,

  isFetchDashboardHitted: false,
};

export const dashboardSubscriberReducer = (
  state = DASHBOARD_SUBSCRIBER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD:
      return { ...state, dashboard: payload };

    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_MARKET_ID:
      return { ...state, fetchDashboardFilterMarketId: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_BRANCH_ID:
      return { ...state, fetchDashboardFilterBranchId: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_ORDER_STATUSES:
      return { ...state, fetchDashboardFilterOrderStatuses: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_TRANSACTION_STATUSES:
      return { ...state, fetchDashboardFilterTransactionStatuses: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_PERIODICITY:
      return { ...state, fetchDashboardFilterPeriodicity: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_LOADING:
      return { ...state, fetchDashboardLoading: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUCCESS:
      return { ...state, fetchDashboardSuccess: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_FAILED:
      return { ...state, fetchDashboardFailed: payload };

    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_DASHBOARD_HITTED:
      return { ...state, isFetchDashboardHitted: payload };

    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.RESET_DASHBOARD_SUBSCRIBER_REDUCER:
      return DASHBOARD_SUBSCRIBER_INITIAL_STATE;
    default:
      return state;
  }
};
