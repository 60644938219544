const FEATURE_SUBSCRIPTION_ACTION_TYPES = {
  RESET_FEATURE_SUBSCRIPTION_REDUCER:
    "feature-subscription/RESET_FEATURE_SUBSCRIPTION_REDUCER",

  SET_FEATURE: "feature-subscription/SET_FEATURE",

  SET_CREATE_FEATURE_LOADING: "feature-subscription/SET_CREATE_FEATURE_LOADING",
  SET_CREATE_FEATURE_SUCCESS: "feature-subscription/SET_CREATE_FEATURE_SUCCESS",
  SET_CREATE_FEATURE_FAILED: "feature-subscription/SET_CREATE_FEATURE_FAILED",

  SET_UPDATE_FEATURE_LOADING: "feature-subscription/SET_UPDATE_FEATURE_LOADING",
  SET_UPDATE_FEATURE_SUCCESS: "feature-subscription/SET_UPDATE_FEATURE_SUCCESS",
  SET_UPDATE_FEATURE_FAILED: "feature-subscription/SET_UPDATE_FEATURE_FAILED",

  SET_DELETE_FEATURE_LOADING: "feature-subscription/SET_DELETE_FEATURE_LOADING",
  SET_DELETE_FEATURE_SUCCESS: "feature-subscription/SET_DELETE_FEATURE_SUCCESS",
  SET_DELETE_FEATURE_FAILED: "feature-subscription/SET_DELETE_FEATURE_FAILED",

  SET_IS_CREATE_FEATURE_HITTED:
    "feature-subscription/SET_IS_CREATE_FEATURE_HITTED",
  SET_IS_UPDATE_FEATURE_HITTED:
    "feature-subscription/SET_IS_UPDATE_FEATURE_HITTED",
  SET_IS_DELETE_FEATURE_HITTED:
    "feature-subscription/SET_IS_DELETE_FEATURE_HITTED",

  CREATE_FEATURE_START: "feature-subscription/CREATE_FEATURE_START",
  UPDATE_FEATURE_START: "feature-subscription/UPDATE_FEATURE_START",
  DELETE_FEATURE_START: "feature-subscription/DELETE_FEATURE_START",
};

export default FEATURE_SUBSCRIPTION_ACTION_TYPES;
