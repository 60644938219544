import { createSelector } from "reselect";

const userAdminSelector = ({ userAdmin }) => userAdmin;

export const getUserAdmins = createSelector(
  [userAdminSelector],
  ({ userAdmins }) => userAdmins
);
export const getUserAdmin = createSelector(
  [userAdminSelector],
  ({ userAdmin }) => userAdmin
);

export const getIsUserAdminsHasMore = createSelector(
  [userAdminSelector],
  ({ isUserAdminsHasMore }) => isUserAdminsHasMore
);

export const getFetchUserAdminsSearch = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminsSearch }) => fetchUserAdminsSearch
);
export const getFetchUserAdminsSort = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminsSort }) => fetchUserAdminsSort
);
export const getFetchUserAdminsKeyBy = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminsKeyBy }) => fetchUserAdminsKeyBy
);
export const getFetchUserAdminsPage = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminsPage }) => fetchUserAdminsPage
);
export const getFetchUserAdminsPerPage = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminsPerPage }) => fetchUserAdminsPerPage
);
export const getFetchUserAdminsIncludes = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminsIncludes }) => fetchUserAdminsIncludes
);
export const getFetchUserAdminsFilterPermissionId = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminsFilterPermissionId }) => fetchUserAdminsFilterPermissionId
);
export const getFetchUserAdminsFilterMarketId = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminsFilterMarketId }) => fetchUserAdminsFilterMarketId
);
export const getFetchUserAdminsLoading = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminsLoading }) => fetchUserAdminsLoading
);
export const getFetchUserAdminsSuccess = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminsSuccess }) => fetchUserAdminsSuccess
);
export const getFetchUserAdminsFailed = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminsFailed }) => fetchUserAdminsFailed
);

export const getFetchUserAdminLoading = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminLoading }) => fetchUserAdminLoading
);
export const getFetchUserAdminSuccess = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminSuccess }) => fetchUserAdminSuccess
);
export const getFetchUserAdminFailed = createSelector(
  [userAdminSelector],
  ({ fetchUserAdminFailed }) => fetchUserAdminFailed
);

export const getCreateUserAdminLoading = createSelector(
  [userAdminSelector],
  ({ createUserAdminLoading }) => createUserAdminLoading
);
export const getCreateUserAdminSuccess = createSelector(
  [userAdminSelector],
  ({ createUserAdminSuccess }) => createUserAdminSuccess
);
export const getCreateUserAdminFailed = createSelector(
  [userAdminSelector],
  ({ createUserAdminFailed }) => createUserAdminFailed
);

export const getUpdateUserAdminLoading = createSelector(
  [userAdminSelector],
  ({ updateUserAdminLoading }) => updateUserAdminLoading
);
export const getUpdateUserAdminSuccess = createSelector(
  [userAdminSelector],
  ({ updateUserAdminSuccess }) => updateUserAdminSuccess
);
export const getUpdateUserAdminFailed = createSelector(
  [userAdminSelector],
  ({ updateUserAdminFailed }) => updateUserAdminFailed
);

export const getDeleteUserAdminLoading = createSelector(
  [userAdminSelector],
  ({ deleteUserAdminLoading }) => deleteUserAdminLoading
);
export const getDeleteUserAdminSuccess = createSelector(
  [userAdminSelector],
  ({ deleteUserAdminSuccess }) => deleteUserAdminSuccess
);
export const getDeleteUserAdminFailed = createSelector(
  [userAdminSelector],
  ({ deleteUserAdminFailed }) => deleteUserAdminFailed
);

export const getIsFetchUserAdminsHitted = createSelector(
  [userAdminSelector],
  ({ isFetchUserAdminsHitted }) => isFetchUserAdminsHitted
);
export const getIsFetchUserAdminHitted = createSelector(
  [userAdminSelector],
  ({ isFetchUserAdminHitted }) => isFetchUserAdminHitted
);
export const getIsCreateUserAdminHitted = createSelector(
  [userAdminSelector],
  ({ isCreateUserAdminHitted }) => isCreateUserAdminHitted
);
export const getIsUpdateUserAdminHitted = createSelector(
  [userAdminSelector],
  ({ isUpdateUserAdminHitted }) => isUpdateUserAdminHitted
);
export const getIsDeleteUserAdminHitted = createSelector(
  [userAdminSelector],
  ({ isDeleteUserAdminHitted }) => isDeleteUserAdminHitted
);
