import { takeLatest, put, all, call, select } from "redux-saga/effects";

import DASHBOARD_ADMIN_ACTION_TYPES from "./dashboard-admin.type";

import {
  setFetchDashboardAdminFailed,
  setFetchDashboardAdminLoading,
  setFetchDashboardAdminSuccess,
  setIsFetchDashboardAdminHitted,
} from "./dashboard-admin.action";
import {
  getFetchDashboardAdminFilterCreatedAtAfter,
  getFetchDashboardAdminFilterCreatedAtBefore,
  getFetchDashboardAdminFilterCurrency,
} from "./dashboard-admin.selector";

import { getDashboardAdmin } from "../../api/dashboard.api";

export function* _getDashboardAdmin() {
  try {
    yield put(setFetchDashboardAdminLoading(true));

    const created_at_before = yield select(
      getFetchDashboardAdminFilterCreatedAtBefore
    );
    const created_at_after = yield select(
      getFetchDashboardAdminFilterCreatedAtAfter
    );
    const currency = yield select(getFetchDashboardAdminFilterCurrency);

    const parameters = {
      filter: {
        created_at_before,
        created_at_after,
        currency,
      },
    };

    const {
      meta: { message },
    } = yield call(getDashboardAdmin, parameters);

    yield put(setIsFetchDashboardAdminHitted(true));

    yield put(setFetchDashboardAdminSuccess(message));
    yield put(setFetchDashboardAdminLoading(false));
  } catch (error) {
    yield put(setFetchDashboardAdminFailed(error));
    yield put(setFetchDashboardAdminLoading(false));
  }
}

export function* onFetchDashboardAdminStart() {
  yield takeLatest(
    DASHBOARD_ADMIN_ACTION_TYPES.FETCH_DASHBOARD_ADMIN_START,
    _getDashboardAdmin
  );
}

export function* dashboardAdminSaga() {
  yield all([call(onFetchDashboardAdminStart)]);
}
