import apiService from "./api";

export const getTables = async (parameters) =>
  (await apiService.get("/v2/tables", parameters)).data;
export const getTable = async (tableId) =>
  (await apiService.get(`/v2/tables/${tableId}`)).data;
export const createTable = async (request) =>
  (await apiService.post("/v2/tables", request)).data;
export const updateTable = async (tableId, request) =>
  (await apiService.post(`/v2/tables/${tableId}`, request)).data;
export const deleteTable = async (tableId) =>
  (await apiService.delete(`/v2/tables/${tableId}`)).data;

export const multipleCreateTable = async (request) =>
  (await apiService.post("/v2/tables/multiple", request)).data;
