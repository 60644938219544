import TOP_UP_ACTION_TYPES from "./top-up.type";

export const TOP_UP_INITIAL_STATE = {
  topUps: [],
  topUp: null,
  isTopUpsHasMore: true,
  fetchTopUpsSearch: null,
  fetchTopUpsPage: 1,
  fetchTopUpsPerPage: null,
  fetchTopUpsIncludes: null,
  fetchTopUpsFilterMarketId: null,
  fetchTopUpsFilterCreatedBy: null,
  fetchTopUpsLoading: false,
  fetchTopUpsSuccess: null,
  fetchTopUpsFailed: null,
  fetchTopUpLoading: false,
  fetchTopUpSuccess: null,
  fetchTopUpFailed: null,
  createTopUpLoading: false,
  createTopUpSuccess: null,
  createTopUpFailed: null,
  updateTopUpLoading: false,
  updateTopUpSuccess: null,
  updateTopUpFailed: null,
  deleteTopUpLoading: false,
  deleteTopUpSuccess: null,
  deleteTopUpFailed: null,
};

export const topUpReducer = (state = TOP_UP_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOP_UP_ACTION_TYPES.SET_TOP_UPS:
      return { ...state, topUps: payload };
    case TOP_UP_ACTION_TYPES.SET_TOP_UP:
      return { ...state, topUp: payload };
    case TOP_UP_ACTION_TYPES.SET_IS_TOP_UPS_HAS_MORE:
      return { ...state, isTopUpsHasMore: payload };
    case TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_SEARCH:
      return { ...state, fetchTopUpsSearch: payload };
    case TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_PAGE:
      return { ...state, fetchTopUpsPage: payload };
    case TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_PER_PAGE:
      return { ...state, fetchTopUpsPerPage: payload };
    case TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_INCLUDES:
      return { ...state, fetchTopUpsIncludes: payload };
    case TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_FILTER_MARKET_ID:
      return { ...state, fetchTopUpsFilterMarketId: payload };
    case TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_FILTER_CREATED_BY:
      return { ...state, fetchTopUpsFilterCreatedBy: payload };
    case TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_LOADING:
      return { ...state, fetchTopUpsLoading: payload };
    case TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_SUCCESS:
      return { ...state, fetchTopUpsSuccess: payload };
    case TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UPS_FAILED:
      return { ...state, fetchTopUpsFailed: payload };
    case TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UP_LOADING:
      return { ...state, fetchTopUpLoading: payload };
    case TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UP_SUCCESS:
      return { ...state, fetchTopUpSuccess: payload };
    case TOP_UP_ACTION_TYPES.SET_FETCH_TOP_UP_FAILED:
      return { ...state, fetchTopUpFailed: payload };
    case TOP_UP_ACTION_TYPES.SET_CREATE_TOP_UP_LOADING:
      return { ...state, createTopUpLoading: payload };
    case TOP_UP_ACTION_TYPES.SET_CREATE_TOP_UP_SUCCESS:
      return { ...state, createTopUpSuccess: payload };
    case TOP_UP_ACTION_TYPES.SET_CREATE_TOP_UP_FAILED:
      return { ...state, createTopUpFailed: payload };
    case TOP_UP_ACTION_TYPES.SET_UPDATE_TOP_UP_LOADING:
      return { ...state, updateTopUpLoading: payload };
    case TOP_UP_ACTION_TYPES.SET_UPDATE_TOP_UP_SUCCESS:
      return { ...state, updateTopUpSuccess: payload };
    case TOP_UP_ACTION_TYPES.SET_UPDATE_TOP_UP_FAILED:
      return { ...state, updateTopUpFailed: payload };
    case TOP_UP_ACTION_TYPES.SET_DELETE_TOP_UP_LOADING:
      return { ...state, deleteTopUpLoading: payload };
    case TOP_UP_ACTION_TYPES.SET_DELETE_TOP_UP_SUCCESS:
      return { ...state, deleteTopUpSuccess: payload };
    case TOP_UP_ACTION_TYPES.SET_DELETE_TOP_UP_FAILED:
      return { ...state, deleteTopUpFailed: payload };
    case TOP_UP_ACTION_TYPES.APPEND_TOP_UPS:
      return { ...state, topUps: [...state.topUps, ...payload] };
    case TOP_UP_ACTION_TYPES.RESET_TOP_UP_REDUCER:
      return TOP_UP_INITIAL_STATE;
    default:
      return state;
  }
};
