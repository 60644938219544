import SECTOR_CATEGORY_ACTION_TYPES from "./sector-category.type";
import { createAction } from "../../utils/store.utils";

export const setSectorCategories = (sectorCategories) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_SECTOR_CATEGORIES,
    sectorCategories
  );
export const setSectorCategory = (sectorCategory) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_SECTOR_CATEGORY,
    sectorCategory
  );

export const setIsSectorCategoriesHasMore = (isSectorCategoriesHasMore) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_IS_SECTOR_CATEGORIES_HAS_MORE,
    isSectorCategoriesHasMore
  );

export const setFetchSectorCategoriesSearch = (fetchSectorCategoriesSearch) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_SEARCH,
    fetchSectorCategoriesSearch
  );
export const setFetchSectorCategoriesSort = (fetchSectorCategoriesSort) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_SORT,
    fetchSectorCategoriesSort
  );
export const setFetchSectorCategoriesKeyBy = (fetchSectorCategoriesKeyBy) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_KEY_BY,
    fetchSectorCategoriesKeyBy
  );
export const setFetchSectorCategoriesPage = (fetchSectorCategoriesPage) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_PAGE,
    fetchSectorCategoriesPage
  );
export const setFetchSectorCategoriesPerPage = (fetchSectorCategoriesPerPage) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_PER_PAGE,
    fetchSectorCategoriesPerPage
  );
export const setFetchSectorCategoriesIncludes = (
  fetchSectorCategoriesIncludes
) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_INCLUDES,
    fetchSectorCategoriesIncludes
  );
export const setFetchSectorCategoriesLoading = (fetchSectorCategoriesLoading) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_LOADING,
    fetchSectorCategoriesLoading
  );
export const setFetchSectorCategoriesSuccess = (fetchSectorCategoriesSuccess) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_SUCCESS,
    fetchSectorCategoriesSuccess
  );
export const setFetchSectorCategoriesFailed = (fetchSectorCategoriesFailed) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_FAILED,
    fetchSectorCategoriesFailed
  );

export const setFetchSectorCategoryLoading = (fetchSectorCategoryLoading) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORY_LOADING,
    fetchSectorCategoryLoading
  );
export const setFetchSectorCategorySuccess = (fetchSectorCategorySuccess) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORY_SUCCESS,
    fetchSectorCategorySuccess
  );
export const setFetchSectorCategoryFailed = (fetchSectorCategoryFailed) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORY_FAILED,
    fetchSectorCategoryFailed
  );

export const appendSectorCategories = (sectorCategories) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.APPEND_SECTOR_CATEGORIES,
    sectorCategories
  );

export const fetchSectorCategoriesStart = (sectorId) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.FETCH_SECTOR_CATEGORIES_START,
    sectorId
  );
export const fetchSectorCategoryStart = (sectorId, categoryId) =>
  createAction(SECTOR_CATEGORY_ACTION_TYPES.FETCH_SECTOR_CATEGORY_START, {
    sectorId,
    categoryId,
  });

export const setIsFetchSectorCategoriesHitted = (
  isFetchSectorCategoriesHitted
) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.IS_FETCH_SECTOR_CATEGORIES_HITTED,
    isFetchSectorCategoriesHitted
  );
export const setIsFetchSectorCategoryHitted = (isFetchSectorCategoryHitted) =>
  createAction(
    SECTOR_CATEGORY_ACTION_TYPES.IS_FETCH_SECTOR_CATEGORY_HITTED,
    isFetchSectorCategoryHitted
  );

export const resetSectorCategoryReducer = () =>
  createAction(SECTOR_CATEGORY_ACTION_TYPES.RESET_SECTOR_CATEGORY_REDUCER);
