import { createSelector } from "reselect";

const msegatSelector = ({ msegat }) => msegat;

export const getMsegats = createSelector([msegatSelector], ({ msegats }) => msegats);

export const getMsegat = createSelector([msegatSelector], ({ msegat }) => msegat);

export const getIsMsegatsHasMore = createSelector([msegatSelector], ({ isMsegatsHasMore }) => isMsegatsHasMore);

export const getFetchMsegatsSearch = createSelector([msegatSelector], ({ fetchMsegatsSearch }) => fetchMsegatsSearch);

export const getFetchMsegatsPage = createSelector([msegatSelector], ({ fetchMsegatsPage }) => fetchMsegatsPage);

export const getFetchMsegatsPerPage = createSelector(
  [msegatSelector],
  ({ fetchMsegatsPerPage }) => fetchMsegatsPerPage
);

export const getFetchMsegatsIncludes = createSelector(
  [msegatSelector],
  ({ fetchMsegatsIncludes }) => fetchMsegatsIncludes
);

export const getFetchMsegatsFilterIsActive = createSelector(
  [msegatSelector],
  ({ fetchMsegatsFilterIsActive }) => fetchMsegatsFilterIsActive
);

export const getFetchMsegatsLoading = createSelector(
  [msegatSelector],
  ({ fetchMsegatsLoading }) => fetchMsegatsLoading
);

export const getFetchMsegatsSuccess = createSelector(
  [msegatSelector],
  ({ fetchMsegatsSuccess }) => fetchMsegatsSuccess
);

export const getFetchMsegatsFailed = createSelector([msegatSelector], ({ fetchMsegatsFailed }) => fetchMsegatsFailed);

export const getFetchMsegatLoading = createSelector([msegatSelector], ({ fetchMsegatLoading }) => fetchMsegatLoading);

export const getFetchMsegatSuccess = createSelector([msegatSelector], ({ fetchMsegatSuccess }) => fetchMsegatSuccess);

export const getFetchMsegatFailed = createSelector([msegatSelector], ({ fetchMsegatFailed }) => fetchMsegatFailed);

export const getCreateOrUpdateMsegatLoading = createSelector(
  [msegatSelector],
  ({ createOrUpdateMsegatLoading }) => createOrUpdateMsegatLoading
);

export const getCreateOrUpdateMsegatSuccess = createSelector(
  [msegatSelector],
  ({ createOrUpdateMsegatSuccess }) => createOrUpdateMsegatSuccess
);

export const getCreateOrUpdateMsegatFailed = createSelector(
  [msegatSelector],
  ({ createOrUpdateMsegatFailed }) => createOrUpdateMsegatFailed
);

export const getIsFetchMsegatsHitted = createSelector(
  [msegatSelector],
  ({ isFetchMsegatsHitted }) => isFetchMsegatsHitted
);

export const getIsFetchMsegatHitted = createSelector(
  [msegatSelector],
  ({ isFetchMsegatHitted }) => isFetchMsegatHitted
);

export const getIsCreateOrUpdateMsegatHitted = createSelector(
  [msegatSelector],
  ({ isCreateOrUpdateMsegatHitted }) => isCreateOrUpdateMsegatHitted
);
