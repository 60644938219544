import apiService from "./api";

export const getIntegrations = async (parameters) =>
  (await apiService.get("/v2/integrations", parameters)).data;
export const getIntegration = async (integrationId) =>
  (await apiService.get(`/v2/integrations/${integrationId}`)).data;
export const createOrUpdateIntegration = async (request) =>
  (await apiService.post("/v2/integrations", request)).data;

export const getQoyodStatus = async (parameters) =>
  (await apiService.get("/v2/integrations/qoyod/status", parameters)).data;
export const syncQoyod = async (request) =>
  (await apiService.post("/v2/integrations/qoyod/sync", request)).data;
export const getGrubtechStatus = async (parameters) =>
  (await apiService.get("/v2/integrations/grubtech/status", parameters)).data;
export const syncGrubtech = async (request) =>
  (await apiService.post("/v2/integrations/grubtech/sync", request)).data;
export const getBallurhStatus = async (parameters) =>
  (await apiService.get("/v2/integrations/ballurh/status", parameters)).data;
export const generateBallurh = async (request) =>
  (await apiService.post("/v2/integrations/ballurh/generate", request)).data;
export const syncBallurh = async (request) =>
  (await apiService.post("/v2/integrations/ballurh/sync", request)).data;
export const getZatcaStatus = async (parameters) =>
  (await apiService.get("/v2/integrations/zatca/status", parameters)).data;
export const syncZatca = async (request) =>
  (await apiService.post("/v2/integrations/zatca/sync", request)).data;
export const registerZatca = async (request) =>
  (await apiService.post("/v2/integrations/zatca/register", request)).data;
