import { createAction } from "../../utils/store.utils";

import DASHBOARD_SUBSCRIBER_ACTION_TYPES from "./dashboard-subscriber.type";

export const setDashboard = (dashboard) =>
  createAction(DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD, dashboard);

export const setFetchDashboardFilterMarketId = (fetchDashboardFilterMarketId) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_MARKET_ID,
    fetchDashboardFilterMarketId
  );
export const setFetchDashboardFilterBranchId = (fetchDashboardFilterBranchId) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_BRANCH_ID,
    fetchDashboardFilterBranchId
  );
export const setFetchDashboardFilterOrderStatuses = (
  fetchDashboardFilterOrderStatuses
) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_ORDER_STATUSES,
    fetchDashboardFilterOrderStatuses
  );
export const setFetchDashboardFilterTransactionStatuses = (
  fetchDashboardFilterTransactionStatuses
) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_TRANSACTION_STATUSES,
    fetchDashboardFilterTransactionStatuses
  );
export const setFetchDashboardFilterPeriodicity = (
  fetchDashboardFilterPeriodicity
) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_PERIODICITY,
    fetchDashboardFilterPeriodicity
  );
export const setFetchDashboardLoading = (fetchDashboardLoading) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_LOADING,
    fetchDashboardLoading
  );
export const setFetchDashboardSuccess = (fetchDashboardSuccess) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUCCESS,
    fetchDashboardSuccess
  );
export const setFetchDashboardFailed = (fetchDashboardFailed) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_FAILED,
    fetchDashboardFailed
  );

export const setIsFetchDashboardHitted = (isFetchDashboardHitted) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_DASHBOARD_HITTED,
    isFetchDashboardHitted
  );

export const fetchDashboardStart = () =>
  createAction(DASHBOARD_SUBSCRIBER_ACTION_TYPES.FETCH_DASHBOARD_START);

export const resetDashboardSubscriberReducer = () =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.RESET_DASHBOARD_SUBSCRIBER_REDUCER
  );
