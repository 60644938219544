import { isEmpty, isValidJson } from "./validation.utils";

export const getAuthToken = () => localStorage.getItem("authToken");
export const setAuthToken = (token) => {
  localStorage.setItem("authToken", token);
  // window.dispatchEvent(new Event("localStorage"));
};
export const clearAuthToken = () => {
  localStorage.removeItem("authToken");
  // window.dispatchEvent(new Event("localStorage"));
};

export const getIpAddress = () => localStorage.getItem("ipAddress");
export const setIpAddress = (token) => {
  localStorage.setItem("ipAddress", token);
  window.dispatchEvent(new Event("localStorage"));
};
export const clearIpAddress = () => {
  localStorage.removeItem("ipAddress");
  window.dispatchEvent(new Event("localStorage"));
};

export const getIsDontShowGettingStarted = () =>
  localStorage.getItem("isDontShowGettingStarted");
export const setIsDontShowGettingStarted = (isDontShowGettingStarted) => {
  localStorage.setItem("isDontShowGettingStarted", isDontShowGettingStarted);
  window.dispatchEvent(new Event("localStorage"));
};
export const clearIsDontShowGettingStarted = () => {
  localStorage.removeItem("isDontShowGettingStarted");
  window.dispatchEvent(new Event("localStorage"));
};

export const getLazyRetry = () => sessionStorage.getItem("lazyRetry");
export const setLazyRetry = (lazyRetry) => {
  sessionStorage.setItem("lazyRetry", lazyRetry);
  window.dispatchEvent(new Event("localStorage"));
};
export const clearLazyRetry = () => {
  sessionStorage.removeItem("lazyRetry");
  window.dispatchEvent(new Event("localStorage"));
};

const getStorageKeys = () => {
  const storageKeys = localStorage.getItem("LOCAL_STORAGE_KEYS");
  return !isEmpty(storageKeys) && isValidJson(storageKeys)
    ? JSON.parse(storageKeys)
    : [];
};
const addStorageKey = (storageKey) =>
  localStorage.setItem(
    "LOCAL_STORAGE_KEYS",
    JSON.stringify([...getStorageKeys(), storageKey])
  );
const popStorageKey = (storageKey) =>
  localStorage.setItem(
    "LOCAL_STORAGE_KEYS",
    JSON.stringify(getStorageKeys().filter((key) => key !== storageKey))
  );

export const clearStorageKeys = () => {
  getStorageKeys().forEach((storageKey) => localStorage.removeItem(storageKey));
  localStorage.setItem("LOCAL_STORAGE_KEYS", JSON.stringify([]));
};

export const getItem = (key) => localStorage.getItem(key);
export const setItem = (key, value) => {
  localStorage.setItem(key, value);
  addStorageKey(key);

  window.dispatchEvent(new Event("localStorage"));
};
export const clearItem = (key) => {
  localStorage.removeItem(key);
  popStorageKey(key);

  window.dispatchEvent(new Event("localStorage"));
};
