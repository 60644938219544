import SELECT_CUSTOMER_ACTION_TYPES from "./select-customer.type";

const SELECT_CUSTOMER_INITIAL_STATE = {
  customers: {},
  searchCustomers: {},

  isCustomersHasMore: true,
  isSearchCustomersHasMore: true,

  fetchCustomersParams: {},
  fetchCustomersLoading: false,
  fetchCustomersSuccess: null,
  fetchCustomersFailed: null,

  searchCustomersParams: {},
  searchCustomersLoading: false,
  searchCustomersSuccess: null,
  searchCustomersFailed: null,

  appendCustomersParams: {},
  appendCustomersLoading: false,
  appendCustomersSuccess: null,
  appendCustomersFailed: null,

  isFetchCustomersHitted: false,
  isSearchCustomersHitted: false,
  isAppendCustomersHitted: false,
};

export const selectCustomerReducer = (
  state = SELECT_CUSTOMER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_CUSTOMER_ACTION_TYPES.SET_CUSTOMERS:
      return { ...state, customers: payload };
    case SELECT_CUSTOMER_ACTION_TYPES.SET_SEARCH_CUSTOMERS:
      return { ...state, searchCustomers: payload };

    case SELECT_CUSTOMER_ACTION_TYPES.SET_IS_CUSTOMERS_HAS_MORE:
      return { ...state, isCustomersHasMore: payload };
    case SELECT_CUSTOMER_ACTION_TYPES.SET_IS_SEARCH_CUSTOMERS_HAS_MORE:
      return { ...state, isSearchCustomersHasMore: payload };

    case SELECT_CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_PARAMS:
      return { ...state, fetchCustomersParams: payload };
    case SELECT_CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_LOADING:
      return { ...state, fetchCustomersLoading: payload };
    case SELECT_CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SUCCESS:
      return { ...state, fetchCustomersSuccess: payload };
    case SELECT_CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_FAILED:
      return { ...state, fetchCustomersFailed: payload };

    case SELECT_CUSTOMER_ACTION_TYPES.SET_SEARCH_CUSTOMERS_PARAMS:
      return { ...state, searchCustomersParams: payload };
    case SELECT_CUSTOMER_ACTION_TYPES.SET_SEARCH_CUSTOMERS_LOADING:
      return { ...state, searchCustomersLoading: payload };
    case SELECT_CUSTOMER_ACTION_TYPES.SET_SEARCH_CUSTOMERS_SUCCESS:
      return { ...state, searchCustomersSuccess: payload };
    case SELECT_CUSTOMER_ACTION_TYPES.SET_SEARCH_CUSTOMERS_FAILED:
      return { ...state, searchCustomersFailed: payload };

    case SELECT_CUSTOMER_ACTION_TYPES.SET_APPEND_CUSTOMERS_PARAMS:
      return { ...state, appendCustomersParams: payload };
    case SELECT_CUSTOMER_ACTION_TYPES.SET_APPEND_CUSTOMERS_LOADING:
      return { ...state, appendCustomersLoading: payload };
    case SELECT_CUSTOMER_ACTION_TYPES.SET_APPEND_CUSTOMERS_SUCCESS:
      return { ...state, appendCustomersSuccess: payload };
    case SELECT_CUSTOMER_ACTION_TYPES.SET_APPEND_CUSTOMERS_FAILED:
      return { ...state, appendCustomersFailed: payload };

    case SELECT_CUSTOMER_ACTION_TYPES.SET_IS_FETCH_CUSTOMERS_HITTED:
      return { ...state, isFetchCustomersHitted: payload };
    case SELECT_CUSTOMER_ACTION_TYPES.SET_IS_SEARCH_CUSTOMERS_HITTED:
      return { ...state, isSearchCustomersHitted: payload };
    case SELECT_CUSTOMER_ACTION_TYPES.SET_IS_APPEND_CUSTOMERS_HITTED:
      return { ...state, isAppendCustomersHitted: payload };

    case SELECT_CUSTOMER_ACTION_TYPES.APPEND_CUSTOMERS:
      return {
        ...state,
        customers: { ...state.customers, ...payload },
      };
    case SELECT_CUSTOMER_ACTION_TYPES.APPEND_SEARCH_CUSTOMERS:
      return {
        ...state,
        searchCustomers: { ...state.searchCustomers, ...payload },
      };

    case SELECT_CUSTOMER_ACTION_TYPES.RESET_SELECT_CUSTOMER_REDUCER:
      return SELECT_CUSTOMER_INITIAL_STATE;
    default:
      return state;
  }
};
