import apiService from "./api";

export const getKitchenGroups = async (parameters) => (await apiService.get("/kitchen-group", parameters)).data;

export const getKitchenGroup = async (id) => (await apiService.get(`/kitchen-group/${id}`)).data;

export const createKitchenGroup = async (request) => (await apiService.post("/kitchen-group", request)).data;

export const updateKitchenGroup = async (id, request) => (await apiService.post(`/kitchen-group/${id}`, request)).data;

export const deleteKitchenGroup = async (id) => (await apiService.delete(`/kitchen-group/${id}`)).data;
