import { createSelector } from "reselect";

const subscriberSelector = ({ subscriber }) => subscriber;

export const getSubscribers = createSelector([subscriberSelector], ({ subscribers }) => subscribers);

export const getSelectSubscribers = createSelector([subscriberSelector], ({ selectSubscribers }) => selectSubscribers);

export const getSubscriber = createSelector([subscriberSelector], ({ subscriber }) => subscriber);

export const getSubscriberReport = createSelector([subscriberSelector], ({ subscriberReport }) => subscriberReport);

export const getIsSubscribersHasMore = createSelector(
  [subscriberSelector],
  ({ isSubscribersHasMore }) => isSubscribersHasMore
);

export const getIsSelectSubscribersHasMore = createSelector(
  [subscriberSelector],
  ({ isSelectSubscribersHasMore }) => isSelectSubscribersHasMore
);

export const getFetchSubscribersSearch = createSelector(
  [subscriberSelector],
  ({ fetchSubscribersSearch }) => fetchSubscribersSearch
);

export const getFetchSubscribersPage = createSelector(
  [subscriberSelector],
  ({ fetchSubscribersPage }) => fetchSubscribersPage
);

export const getFetchSubscribersPerPage = createSelector(
  [subscriberSelector],
  ({ fetchSubscribersPerPage }) => fetchSubscribersPerPage
);

export const getFetchSubscribersIncludes = createSelector(
  [subscriberSelector],
  ({ fetchSubscribersIncludes }) => fetchSubscribersIncludes
);

export const getFetchSubscribersFilterMarketId = createSelector(
  [subscriberSelector],
  ({ fetchSubscribersFilterMarketId }) => fetchSubscribersFilterMarketId
);

export const getFetchSubscribersFilterBranchId = createSelector(
  [subscriberSelector],
  ({ fetchSubscribersFilterBranchId }) => fetchSubscribersFilterBranchId
);

export const getFetchSubscribersFilterPermissionId = createSelector(
  [subscriberSelector],
  ({ fetchSubscribersFilterPermissionId }) => fetchSubscribersFilterPermissionId
);

export const getFetchSubscribersLoading = createSelector(
  [subscriberSelector],
  ({ fetchSubscribersLoading }) => fetchSubscribersLoading
);

export const getFetchSubscribersSuccess = createSelector(
  [subscriberSelector],
  ({ fetchSubscribersSuccess }) => fetchSubscribersSuccess
);

export const getFetchSubscribersFailed = createSelector(
  [subscriberSelector],
  ({ fetchSubscribersFailed }) => fetchSubscribersFailed
);

export const getFetchSelectSubscribersSearch = createSelector(
  [subscriberSelector],
  ({ fetchSelectSubscribersSearch }) => fetchSelectSubscribersSearch
);

export const getFetchSelectSubscribersPage = createSelector(
  [subscriberSelector],
  ({ fetchSelectSubscribersPage }) => fetchSelectSubscribersPage
);

export const getFetchSelectSubscribersPerPage = createSelector(
  [subscriberSelector],
  ({ fetchSelectSubscribersPerPage }) => fetchSelectSubscribersPerPage
);

export const getFetchSelectSubscribersIncludes = createSelector(
  [subscriberSelector],
  ({ fetchSelectSubscribersIncludes }) => fetchSelectSubscribersIncludes
);

export const getFetchSelectSubscribersFilterMarketId = createSelector(
  [subscriberSelector],
  ({ fetchSelectSubscribersFilterMarketId }) => fetchSelectSubscribersFilterMarketId
);

export const getFetchSelectSubscribersFilterBranchId = createSelector(
  [subscriberSelector],
  ({ fetchSelectSubscribersFilterBranchId }) => fetchSelectSubscribersFilterBranchId
);

export const getFetchSelectSubscribersFilterPermissionId = createSelector(
  [subscriberSelector],
  ({ fetchSelectSubscribersFilterPermissionId }) => fetchSelectSubscribersFilterPermissionId
);

export const getFetchSelectSubscribersLoading = createSelector(
  [subscriberSelector],
  ({ fetchSelectSubscribersLoading }) => fetchSelectSubscribersLoading
);

export const getFetchSelectSubscribersSuccess = createSelector(
  [subscriberSelector],
  ({ fetchSelectSubscribersSuccess }) => fetchSelectSubscribersSuccess
);

export const getFetchSelectSubscribersFailed = createSelector(
  [subscriberSelector],
  ({ fetchSelectSubscribersFailed }) => fetchSelectSubscribersFailed
);

export const getFetchSubscriberLoading = createSelector(
  [subscriberSelector],
  ({ fetchSubscriberLoading }) => fetchSubscriberLoading
);

export const getFetchSubscriberSuccess = createSelector(
  [subscriberSelector],
  ({ fetchSubscriberSuccess }) => fetchSubscriberSuccess
);

export const getFetchSubscriberFailed = createSelector(
  [subscriberSelector],
  ({ fetchSubscriberFailed }) => fetchSubscriberFailed
);

export const getFetchSubscriberReportStartAt = createSelector(
  [subscriberSelector],
  ({ fetchSubscriberReportStartAt }) => fetchSubscriberReportStartAt
);

export const getFetchSubscriberReportEndAt = createSelector(
  [subscriberSelector],
  ({ fetchSubscriberReportEndAt }) => fetchSubscriberReportEndAt
);

export const getFetchSubscriberReportLoading = createSelector(
  [subscriberSelector],
  ({ fetchSubscriberReportLoading }) => fetchSubscriberReportLoading
);

export const getFetchSubscriberReportSuccess = createSelector(
  [subscriberSelector],
  ({ fetchSubscriberReportSuccess }) => fetchSubscriberReportSuccess
);

export const getFetchSubscriberReportFailed = createSelector(
  [subscriberSelector],
  ({ fetchSubscriberReportFailed }) => fetchSubscriberReportFailed
);

export const getCreateSubscriberLoading = createSelector(
  [subscriberSelector],
  ({ createSubscriberLoading }) => createSubscriberLoading
);

export const getCreateSubscriberSuccess = createSelector(
  [subscriberSelector],
  ({ createSubscriberSuccess }) => createSubscriberSuccess
);

export const getCreateSubscriberFailed = createSelector(
  [subscriberSelector],
  ({ createSubscriberFailed }) => createSubscriberFailed
);

export const getUpdateSubscriberLoading = createSelector(
  [subscriberSelector],
  ({ updateSubscriberLoading }) => updateSubscriberLoading
);

export const getUpdateSubscriberSuccess = createSelector(
  [subscriberSelector],
  ({ updateSubscriberSuccess }) => updateSubscriberSuccess
);

export const getUpdateSubscriberFailed = createSelector(
  [subscriberSelector],
  ({ updateSubscriberFailed }) => updateSubscriberFailed
);

export const getDeleteSubscriberLoading = createSelector(
  [subscriberSelector],
  ({ deleteSubscriberLoading }) => deleteSubscriberLoading
);

export const getDeleteSubscriberSuccess = createSelector(
  [subscriberSelector],
  ({ deleteSubscriberSuccess }) => deleteSubscriberSuccess
);

export const getDeleteSubscriberFailed = createSelector(
  [subscriberSelector],
  ({ deleteSubscriberFailed }) => deleteSubscriberFailed
);

export const getIsFetchSubscribersHitted = createSelector(
  [subscriberSelector],
  ({ isFetchSubscribersHitted }) => isFetchSubscribersHitted
);

export const getIsFetchSelectSubscribersHitted = createSelector(
  [subscriberSelector],
  ({ isFetchSelectSubscribersHitted }) => isFetchSelectSubscribersHitted
);

export const getIsFetchSubscriberReportHitted = createSelector(
  [subscriberSelector],
  ({ isFetchSubscriberReportHitted }) => isFetchSubscriberReportHitted
);

export const getIsFetchSubscriberHitted = createSelector(
  [subscriberSelector],
  ({ isFetchSubscriberHitted }) => isFetchSubscriberHitted
);

export const getIsCreateSubscriberHitted = createSelector(
  [subscriberSelector],
  ({ isCreateSubscriberHitted }) => isCreateSubscriberHitted
);

export const getIsUpdateSubscriberHitted = createSelector(
  [subscriberSelector],
  ({ isUpdateSubscriberHitted }) => isUpdateSubscriberHitted
);

export const getIsDeleteSubscriberHitted = createSelector(
  [subscriberSelector],
  ({ isDeleteSubscriberHitted }) => isDeleteSubscriberHitted
);
