const CARD_ACTION_TYPES = {
  RESET_CARD_REDUCER: "card/RESET_CARD_REDUCER",

  SET_CARDS: "card/SET_CARDS",
  SET_CARD: "card/SET_CARD",

  SET_IS_CARDS_HAS_MORE: "card/SET_IS_CARDS_HAS_MORE",

  SET_FETCH_CARDS_SEARCH: "card/SET_FETCH_CARDS_SEARCH",
  SET_FETCH_CARDS_SORT: "card/SET_FETCH_CARDS_SORT",
  SET_FETCH_CARDS_PAGE: "card/SET_FETCH_CARDS_PAGE",
  SET_FETCH_CARDS_PER_PAGE: "card/SET_FETCH_CARDS_PER_PAGE",
  SET_FETCH_CARDS_INCLUDES: "card/SET_FETCH_CARDS_INCLUDES",
  SET_FETCH_CARDS_FILTER_TRANSFER_TYPE: "card/SET_FETCH_CARDS_FILTER_TRANSFER_TYPE",
  SET_FETCH_CARDS_FILTER_IS_VERIFIED: "card/SET_FETCH_CARDS_FILTER_IS_VERIFIED",
  SET_FETCH_CARDS_LOADING: "card/SET_FETCH_CARDS_LOADING",
  SET_FETCH_CARDS_SUCCESS: "card/SET_FETCH_CARDS_SUCCESS",
  SET_FETCH_CARDS_FAILED: "card/SET_FETCH_CARDS_FAILED",

  SET_FETCH_CARD_LOADING: "card/SET_FETCH_CARD_LOADING",
  SET_FETCH_CARD_SUCCESS: "card/SET_FETCH_CARD_SUCCESS",
  SET_FETCH_CARD_FAILED: "card/SET_FETCH_CARD_FAILED",

  SET_CREATE_OR_UPDATE_CARD_LOADING: "card/SET_CREATE_OR_UPDATE_CARD_LOADING",
  SET_CREATE_OR_UPDATE_CARD_SUCCESS: "card/SET_CREATE_OR_UPDATE_CARD_SUCCESS",
  SET_CREATE_OR_UPDATE_CARD_FAILED: "card/SET_CREATE_OR_UPDATE_CARD_FAILED",

  APPEND_CARDS: "card/APPEND_CARDS",

  SET_IS_FETCH_CARDS_HITTED: "card/SET_IS_FETCH_CARDS_HITTED",
  SET_IS_FETCH_CARD_HITTED: "card/SET_IS_FETCH_CARD_HITTED",
  SET_IS_CREATE_OR_UPDATE_CARD_HITTED: "card/SET_IS_CREATE_OR_UPDATE_CARD_HITTED",

  FETCH_CARDS_START: "card/FETCH_CARDS_START",
  FETCH_CARD_START: "card/FETCH_CARD_START",
  CREATE_OR_UPDATE_CARD_START: "card/CREATE_OR_UPDATE_CARD_START",
};

export default CARD_ACTION_TYPES;
