import { takeLatest, put, all, call } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getMarkets } from "../../api/market.api";

import SELECT_MARKET_ACTION_TYPES from "./select-market.type";
import {
  appendMarkets,
  appendSearchMarkets,
  setAppendMarketsFailed,
  setAppendMarketsLoading,
  setAppendMarketsSuccess,
  setFetchMarketsFailed,
  setFetchMarketsLoading,
  setFetchMarketsSuccess,
  setIsAppendMarketsHitted,
  setIsFetchMarketsHitted,
  setIsMarketsHasMore,
  setIsSearchMarketsHasMore,
  setIsSearchMarketsHitted,
  setMarkets,
  setSearchMarkets,
  setSearchMarketsFailed,
  setSearchMarketsLoading,
  setSearchMarketsSuccess,
} from "./select-market.action";

export function* _getFetchMarkets({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setFetchMarketsLoading(true));

    const {
      meta: { message },
      data: markets,
    } = yield call(getMarkets, parameters);

    yield put(setIsFetchMarketsHitted(true));
    yield put(setIsMarketsHasMore(Object.keys(markets).length > 0));

    if (parameters.page > 1) {
      yield put(appendMarkets(markets));
    } else {
      yield put(setMarkets(markets));
    }

    yield put(setFetchMarketsSuccess(message));
    yield put(setFetchMarketsLoading(false));
  } catch (error) {
    yield put(setFetchMarketsFailed(error));
    yield put(setFetchMarketsLoading(false));
  }
}
export function* _getSearchMarkets({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setSearchMarketsLoading(true));

    const {
      meta: { message },
      data: markets,
    } = yield call(getMarkets, parameters);

    yield put(setIsSearchMarketsHitted(true));
    yield put(setIsSearchMarketsHasMore(Object.keys(markets).length > 0));

    if (parameters.page > 1) {
      yield put(appendSearchMarkets(markets));
    } else {
      yield put(setSearchMarkets(markets));
    }

    yield put(setSearchMarketsSuccess(message));
    yield put(setSearchMarketsLoading(false));
  } catch (error) {
    yield put(setSearchMarketsFailed(error));
    yield put(setSearchMarketsLoading(false));
  }
}
export function* _getAppendMarkets({ payload: params }) {
  try {
    const parameters = getParamsWithDefault(params);

    yield put(setAppendMarketsLoading(true));

    const {
      meta: { message },
      data: markets,
    } = yield call(getMarkets, parameters);

    yield put(setIsAppendMarketsHitted(true));
    yield put(appendMarkets(markets));

    yield put(setAppendMarketsSuccess(message));
    yield put(setAppendMarketsLoading(false));
  } catch (error) {
    yield put(setAppendMarketsFailed(error));
    yield put(setAppendMarketsLoading(false));
  }
}

export function* onFetchMarketsStart() {
  yield takeLatest(
    SELECT_MARKET_ACTION_TYPES.FETCH_MARKETS_START,
    _getFetchMarkets
  );
}
export function* onSearchMarketsStart() {
  yield takeLatest(
    SELECT_MARKET_ACTION_TYPES.SEARCH_MARKETS_START,
    _getSearchMarkets
  );
}
export function* onAppendMarketsStart() {
  yield takeLatest(
    SELECT_MARKET_ACTION_TYPES.APPEND_MARKETS_START,
    _getAppendMarkets
  );
}

export function* selectMarketSaga() {
  yield all([
    call(onFetchMarketsStart),
    call(onSearchMarketsStart),
    call(onAppendMarketsStart),
  ]);
}
