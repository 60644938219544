import PRODUCT_ACTION_TYPES from "./product-quick.type";

export const PRODUCT_INITIAL_STATE = {
  createProduct: null,
  updateProduct: null,

  createProductLoading: false,
  createProductSuccess: null,
  createProductFailed: null,

  updateProductLoading: false,
  updateProductSuccess: null,
  updateProductFailed: null,

  createProductsLoading: false,
  createProductsSuccess: null,
  createProductsFailed: null,

  isCreateProductHitted: false,
  isUpdateProductHitted: false,
  isCreateProductsHitted: false,
};

export const productQuickReducer = (state = PRODUCT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT:
      return { ...state, createProduct: payload };
    case PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT:
      return { ...state, updateProduct: payload };

    case PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT_LOADING:
      return { ...state, createProductLoading: payload };
    case PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT_SUCCESS:
      return { ...state, createProductSuccess: payload };
    case PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT_FAILED:
      return { ...state, createProductFailed: payload };

    case PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT_LOADING:
      return { ...state, updateProductLoading: payload };
    case PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT_SUCCESS:
      return { ...state, updateProductSuccess: payload };
    case PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT_FAILED:
      return { ...state, updateProductFailed: payload };

    case PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCTS_LOADING:
      return { ...state, createProductsLoading: payload };
    case PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCTS_SUCCESS:
      return { ...state, createProductsSuccess: payload };
    case PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCTS_FAILED:
      return { ...state, createProductsFailed: payload };

    case PRODUCT_ACTION_TYPES.IS_CREATE_PRODUCT_HITTED:
      return { ...state, isCreateProductHitted: payload };
    case PRODUCT_ACTION_TYPES.IS_UPDATE_PRODUCT_HITTED:
      return { ...state, isUpdateProductHitted: payload };
    case PRODUCT_ACTION_TYPES.IS_CREATE_PRODUCTS_HITTED:
      return { ...state, isCreateProductsHitted: payload };

    case PRODUCT_ACTION_TYPES.RESET_PRODUCT_REDUCER:
      return PRODUCT_INITIAL_STATE;
    default:
      return state;
  }
};
