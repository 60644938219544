import { createSelector } from "reselect";

const announcementSelector = ({ announcement }) => announcement;

export const getAnnouncements = createSelector([announcementSelector], ({ announcements }) => announcements);

export const getAnnouncement = createSelector([announcementSelector], ({ announcement }) => announcement);

export const getCreateAnnouncement = createSelector(
  [announcementSelector],
  ({ createAnnouncement }) => createAnnouncement
);

export const getUpdateAnnouncement = createSelector(
  [announcementSelector],
  ({ updateAnnouncement }) => updateAnnouncement
);

export const getIsAnnouncementsHasMore = createSelector(
  [announcementSelector],
  ({ isAnnouncementsHasMore }) => isAnnouncementsHasMore
);

export const getFetchAnnouncementsSearch = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementsSearch }) => fetchAnnouncementsSearch
);

export const getFetchAnnouncementsSort = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementsSort }) => fetchAnnouncementsSort
);

export const getFetchAnnouncementsPage = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementsPage }) => fetchAnnouncementsPage
);

export const getFetchAnnouncementsPerPage = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementsPerPage }) => fetchAnnouncementsPerPage
);

export const getFetchAnnouncementsFilterDisplayAt = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementsFilterDisplayAt }) => fetchAnnouncementsFilterDisplayAt
);

export const getFetchAnnouncementsFilterUserId = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementsFilterUserId }) => fetchAnnouncementsFilterUserId
);

export const getFetchAnnouncementsFilterRole = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementsFilterRole }) => fetchAnnouncementsFilterRole
);

export const getFetchAnnouncementsFilterIsRead = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementsFilterIsRead }) => fetchAnnouncementsFilterIsRead
);

export const getFetchAnnouncementsLoading = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementsLoading }) => fetchAnnouncementsLoading
);

export const getFetchAnnouncementsSuccess = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementsSuccess }) => fetchAnnouncementsSuccess
);

export const getFetchAnnouncementsFailed = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementsFailed }) => fetchAnnouncementsFailed
);

export const getFetchAnnouncementLoading = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementLoading }) => fetchAnnouncementLoading
);

export const getFetchAnnouncementSuccess = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementSuccess }) => fetchAnnouncementSuccess
);

export const getFetchAnnouncementFailed = createSelector(
  [announcementSelector],
  ({ fetchAnnouncementFailed }) => fetchAnnouncementFailed
);

export const getCreateAnnouncementLoading = createSelector(
  [announcementSelector],
  ({ createAnnouncementLoading }) => createAnnouncementLoading
);

export const getCreateAnnouncementSuccess = createSelector(
  [announcementSelector],
  ({ createAnnouncementSuccess }) => createAnnouncementSuccess
);

export const getCreateAnnouncementFailed = createSelector(
  [announcementSelector],
  ({ createAnnouncementFailed }) => createAnnouncementFailed
);

export const getUpdateAnnouncementLoading = createSelector(
  [announcementSelector],
  ({ updateAnnouncementLoading }) => updateAnnouncementLoading
);

export const getUpdateAnnouncementSuccess = createSelector(
  [announcementSelector],
  ({ updateAnnouncementSuccess }) => updateAnnouncementSuccess
);

export const getUpdateAnnouncementFailed = createSelector(
  [announcementSelector],
  ({ updateAnnouncementFailed }) => updateAnnouncementFailed
);

export const getDeleteAnnouncementLoading = createSelector(
  [announcementSelector],
  ({ deleteAnnouncementLoading }) => deleteAnnouncementLoading
);

export const getDeleteAnnouncementSuccess = createSelector(
  [announcementSelector],
  ({ deleteAnnouncementSuccess }) => deleteAnnouncementSuccess
);

export const getDeleteAnnouncementFailed = createSelector(
  [announcementSelector],
  ({ deleteAnnouncementFailed }) => deleteAnnouncementFailed
);

export const getReadAnnouncementLoading = createSelector(
  [announcementSelector],
  ({ readAnnouncementLoading }) => readAnnouncementLoading
);

export const getReadAnnouncementSuccess = createSelector(
  [announcementSelector],
  ({ readAnnouncementSuccess }) => readAnnouncementSuccess
);

export const getReadAnnouncementFailed = createSelector(
  [announcementSelector],
  ({ readAnnouncementFailed }) => readAnnouncementFailed
);

export const getIsFetchAnnouncementsHitted = createSelector(
  [announcementSelector],
  ({ isFetchAnnouncementsHitted }) => isFetchAnnouncementsHitted
);

export const getIsFetchAnnouncementHitted = createSelector(
  [announcementSelector],
  ({ isFetchAnnouncementHitted }) => isFetchAnnouncementHitted
);

export const getIsCreateAnnouncementHitted = createSelector(
  [announcementSelector],
  ({ isCreateAnnouncementHitted }) => isCreateAnnouncementHitted
);

export const getIsUpdateAnnouncementHitted = createSelector(
  [announcementSelector],
  ({ isUpdateAnnouncementHitted }) => isUpdateAnnouncementHitted
);

export const getIsDeleteAnnouncementHitted = createSelector(
  [announcementSelector],
  ({ isDeleteAnnouncementHitted }) => isDeleteAnnouncementHitted
);

export const getIsReadAnnouncementHitted = createSelector(
  [announcementSelector],
  ({ isReadAnnouncementHitted }) => isReadAnnouncementHitted
);
