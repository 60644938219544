const USER_DRIVER_ACTION_TYPES = {
  RESET_USER_DRIVER_REDUCER: "user-driver/RESET_USER_DRIVER_REDUCER",

  SET_USER_DRIVERS: "user-driver/SET_USER_DRIVERS",
  SET_USER_DRIVER: "user-driver/SET_USER_DRIVER",

  SET_IS_USER_DRIVERS_HAS_MORE: "user-driver/SET_IS_USER_DRIVERS_HAS_MORE",

  SET_FETCH_USER_DRIVERS_SEARCH: "user-driver/SET_FETCH_USER_DRIVERS_SEARCH",
  SET_FETCH_USER_DRIVERS_SORT: "user-driver/SET_FETCH_USER_DRIVERS_SORT",
  SET_FETCH_USER_DRIVERS_KEY_BY: "user-driver/SET_FETCH_USER_DRIVERS_KEY_BY",
  SET_FETCH_USER_DRIVERS_PAGE: "user-driver/SET_FETCH_USER_DRIVERS_PAGE",
  SET_FETCH_USER_DRIVERS_PER_PAGE:
    "user-driver/SET_FETCH_USER_DRIVERS_PER_PAGE",
  SET_FETCH_USER_DRIVERS_INCLUDES:
    "user-driver/SET_FETCH_USER_DRIVERS_INCLUDES",
  SET_FETCH_USER_DRIVERS_FILTER_GENDER:
    "user-driver/SET_FETCH_USER_DRIVERS_FILTER_GENDER",
  SET_FETCH_USER_DRIVERS_FILTER_STATUS:
    "user-driver/SET_FETCH_USER_DRIVERS_FILTER_STATUS",
  SET_FETCH_USER_DRIVERS_FILTER_VEHICLE_TYPE:
    "user-driver/SET_FETCH_USER_DRIVERS_FILTER_VEHICLE_TYPE",
  SET_FETCH_USER_DRIVERS_FILTER_MARKET_ID:
    "user-driver/SET_FETCH_USER_DRIVERS_FILTER_MARKET_ID",
  SET_FETCH_USER_DRIVERS_LOADING: "user-driver/SET_FETCH_USER_DRIVERS_LOADING",
  SET_FETCH_USER_DRIVERS_SUCCESS: "user-driver/SET_FETCH_USER_DRIVERS_SUCCESS",
  SET_FETCH_USER_DRIVERS_FAILED: "user-driver/SET_FETCH_USER_DRIVERS_FAILED",

  SET_FETCH_USER_DRIVER_LOADING: "user-driver/SET_FETCH_USER_DRIVER_LOADING",
  SET_FETCH_USER_DRIVER_SUCCESS: "user-driver/SET_FETCH_USER_DRIVER_SUCCESS",
  SET_FETCH_USER_DRIVER_FAILED: "user-driver/SET_FETCH_USER_DRIVER_FAILED",

  SET_CREATE_USER_DRIVER_LOADING: "user-driver/SET_CREATE_USER_DRIVER_LOADING",
  SET_CREATE_USER_DRIVER_SUCCESS: "user-driver/SET_CREATE_USER_DRIVER_SUCCESS",
  SET_CREATE_USER_DRIVER_FAILED: "user-driver/SET_CREATE_USER_DRIVER_FAILED",

  SET_UPDATE_USER_DRIVER_LOADING: "user-driver/SET_UPDATE_USER_DRIVER_LOADING",
  SET_UPDATE_USER_DRIVER_SUCCESS: "user-driver/SET_UPDATE_USER_DRIVER_SUCCESS",
  SET_UPDATE_USER_DRIVER_FAILED: "user-driver/SET_UPDATE_USER_DRIVER_FAILED",

  SET_ADD_MARKET_USER_DRIVER_LOADING:
    "user-driver/SET_ADD_MARKET_USER_DRIVER_LOADING",
  SET_ADD_MARKET_USER_DRIVER_SUCCESS:
    "user-driver/SET_ADD_MARKET_USER_DRIVER_SUCCESS",
  SET_ADD_MARKET_USER_DRIVER_FAILED:
    "user-driver/SET_ADD_MARKET_USER_DRIVER_FAILED",

  SET_REMOVE_MARKET_USER_DRIVER_LOADING:
    "user-driver/SET_REMOVE_MARKET_USER_DRIVER_LOADING",
  SET_REMOVE_MARKET_USER_DRIVER_SUCCESS:
    "user-driver/SET_REMOVE_MARKET_USER_DRIVER_SUCCESS",
  SET_REMOVE_MARKET_USER_DRIVER_FAILED:
    "user-driver/SET_REMOVE_MARKET_USER_DRIVER_FAILED",

  APPEND_USER_DRIVERS: "user-driver/APPEND_USER_DRIVERS",

  SET_IS_FETCH_USER_DRIVERS_HITTED:
    "user-driver/SET_IS_FETCH_USER_DRIVERS_HITTED",
  SET_IS_FETCH_USER_DRIVER_HITTED:
    "user-driver/SET_IS_FETCH_USER_DRIVER_HITTED",
  SET_IS_CREATE_USER_DRIVER_HITTED:
    "user-driver/SET_IS_CREATE_USER_DRIVER_HITTED",
  SET_IS_UPDATE_USER_DRIVER_HITTED:
    "user-driver/SET_IS_UPDATE_USER_DRIVER_HITTED",
  SET_IS_ADD_MARKET_USER_DRIVER_HITTED:
    "user-driver/SET_IS_ADD_MARKET_USER_DRIVER_HITTED",
  SET_IS_REMOVE_MARKET_USER_DRIVER_HITTED:
    "user-driver/SET_IS_REMOVE_MARKET_USER_DRIVER_HITTED",

  FETCH_USER_DRIVERS_START: "user-driver/FETCH_USER_DRIVERS_START",
  FETCH_USER_DRIVER_START: "user-driver/FETCH_USER_DRIVER_START",
  CREATE_USER_DRIVER_START: "user-driver/CREATE_USER_DRIVER_START",
  UPDATE_USER_DRIVER_START: "user-driver/UPDATE_USER_DRIVER_START",
  ADD_MARKET_USER_DRIVER_START: "user-driver/ADD_MARKET_USER_DRIVER_START",
  REMOVE_MARKET_USER_DRIVER_START:
    "user-driver/REMOVE_MARKET_USER_DRIVER_START",
};

export default USER_DRIVER_ACTION_TYPES;
