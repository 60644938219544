import apiService from "./api";

export const getPaymentMethodSettings = async (parameters) =>
  (await apiService.get("/v2/payment-methods/settings", parameters)).data;
export const getPaymentMethodSetting = async (settingKey) =>
  (await apiService.get(`/v2/payment-methods/settings/${settingKey}`)).data;
export const createPaymentMethodSetting = async (request) =>
  (await apiService.post("/v2/payment-methods/settings", request)).data;
export const updatePaymentMethodSetting = async (settingKey, request) =>
  (await apiService.post(`/v2/payment-methods/settings/${settingKey}`, request))
    .data;
export const deletePaymentMethodSetting = async (settingKey) =>
  (await apiService.delete(`/v2/payment-methods/settings/${settingKey}`)).data;
