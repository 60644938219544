import WHATSAPP_ACTION_TYPES from "./whatsapp.type";

export const WHATSAPP_INITIAL_STATE = {
  whatsapps: [],
  whatsapp: null,

  isWhatsappsHasMore: true,

  fetchWhatsappsPage: 1,
  fetchWhatsappsPerPage: null,
  fetchWhatsappsIncludes: null,
  fetchWhatsappsFilterIsActive: null,
  fetchWhatsappsLoading: false,
  fetchWhatsappsSuccess: null,
  fetchWhatsappsFailed: null,

  fetchWhatsappIsQrCode: null,
  fetchWhatsappLoading: false,
  fetchWhatsappSuccess: null,
  fetchWhatsappFailed: null,

  createOrUpdateWhatsappLoading: false,
  createOrUpdateWhatsappSuccess: null,
  createOrUpdateWhatsappFailed: null,

  isFetchWhatsappsHitted: false,
  isFetchWhatsappHitted: false,
  isCreateOrUpdateWhatsappHitted: false,
};

export const whatsappReducer = (state = WHATSAPP_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case WHATSAPP_ACTION_TYPES.SET_WHATSAPPS:
      return { ...state, whatsapps: payload };
    case WHATSAPP_ACTION_TYPES.SET_WHATSAPP:
      return { ...state, whatsapp: payload };

    case WHATSAPP_ACTION_TYPES.SET_IS_WHATSAPPS_HAS_MORE:
      return { ...state, isWhatsappsHasMore: payload };

    case WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_PAGE:
      return { ...state, fetchWhatsappsPage: payload };
    case WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_PER_PAGE:
      return { ...state, fetchWhatsappsPerPage: payload };
    case WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_INCLUDES:
      return { ...state, fetchWhatsappsIncludes: payload };
    case WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_FILTER_IS_ACTIVE:
      return { ...state, fetchWhatsappsFilterIsActive: payload };
    case WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_LOADING:
      return { ...state, fetchWhatsappsLoading: payload };
    case WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_SUCCESS:
      return { ...state, fetchWhatsappsSuccess: payload };
    case WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPPS_FAILED:
      return { ...state, fetchWhatsappsFailed: payload };

    case WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPP_IS_QR_CODE:
      return { ...state, fetchWhatsappIsQrCode: payload };
    case WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPP_LOADING:
      return { ...state, fetchWhatsappLoading: payload };
    case WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPP_SUCCESS:
      return { ...state, fetchWhatsappSuccess: payload };
    case WHATSAPP_ACTION_TYPES.SET_FETCH_WHATSAPP_FAILED:
      return { ...state, fetchWhatsappFailed: payload };

    case WHATSAPP_ACTION_TYPES.SET_CREATE_OR_UPDATE_WHATSAPP_LOADING:
      return { ...state, createOrUpdateWhatsappLoading: payload };
    case WHATSAPP_ACTION_TYPES.SET_CREATE_OR_UPDATE_WHATSAPP_SUCCESS:
      return { ...state, createOrUpdateWhatsappSuccess: payload };
    case WHATSAPP_ACTION_TYPES.SET_CREATE_OR_UPDATE_WHATSAPP_FAILED:
      return { ...state, createOrUpdateWhatsappFailed: payload };

    case WHATSAPP_ACTION_TYPES.APPEND_WHATSAPPS:
      return { ...state, whatsapps: [...state.whatsapps, ...payload] };

    case WHATSAPP_ACTION_TYPES.IS_FETCH_WHATSAPPS_HITTED:
      return { ...state, isFetchWhatsappsHitted: payload };
    case WHATSAPP_ACTION_TYPES.IS_FETCH_WHATSAPP_HITTED:
      return { ...state, isFetchWhatsappHitted: payload };
    case WHATSAPP_ACTION_TYPES.IS_CREATE_OR_UPDATE_WHATSAPP_HITTED:
      return { ...state, isCreateOrUpdateWhatsappHitted: payload };

    case WHATSAPP_ACTION_TYPES.RESET_WHATSAPP_REDUCER:
      return WHATSAPP_INITIAL_STATE;
    default:
      return state;
  }
};
