const PERMISSION_SUBSCRIBER_ACTION_TYPES = {
  RESET_PERMISSION_SUBSCRIBER_REDUCER:
    "permission-subscriber/RESET_PERMISSION_SUBSCRIBER_REDUCER",

  SET_PERMISSIONS: "permission-subscriber/SET_PERMISSIONS",
  SET_SELECT_PERMISSIONS: "permission-subscriber/SET_SELECT_PERMISSIONS",
  SET_PERMISSION: "permission-subscriber/SET_PERMISSION",

  SET_IS_PERMISSIONS_HAS_MORE:
    "permission-subscriber/SET_IS_PERMISSIONS_HAS_MORE",
  SET_IS_SELECT_PERMISSIONS_HAS_MORE:
    "permission-subscriber/SET_IS_SELECT_PERMISSIONS_HAS_MORE",

  SET_FETCH_PERMISSIONS_SEARCH:
    "permission-subscriber/SET_FETCH_PERMISSIONS_SEARCH",
  SET_FETCH_PERMISSIONS_SORT:
    "permission-subscriber/SET_FETCH_PERMISSIONS_SORT",
  SET_FETCH_PERMISSIONS_KEY_BY:
    "permission-subscriber/SET_FETCH_PERMISSIONS_KEY_BY",
  SET_FETCH_PERMISSIONS_PAGE:
    "permission-subscriber/SET_FETCH_PERMISSIONS_PAGE",
  SET_FETCH_PERMISSIONS_PER_PAGE:
    "permission-subscriber/SET_FETCH_PERMISSIONS_PER_PAGE",
  SET_FETCH_PERMISSIONS_INCLUDES:
    "permission-subscriber/SET_FETCH_PERMISSIONS_INCLUDES",
  SET_FETCH_PERMISSIONS_FILTER_MARKET_ID:
    "permission-subscriber/SET_FETCH_PERMISSIONS_FILTER_MARKET_ID",
  SET_FETCH_PERMISSIONS_FILTER_IS_DEFAULT:
    "permission-subscriber/SET_FETCH_PERMISSIONS_FILTER_IS_DEFAULT",
  SET_FETCH_PERMISSIONS_LOADING:
    "permission-subscriber/SET_FETCH_PERMISSIONS_LOADING",
  SET_FETCH_PERMISSIONS_SUCCESS:
    "permission-subscriber/SET_FETCH_PERMISSIONS_SUCCESS",
  SET_FETCH_PERMISSIONS_FAILED:
    "permission-subscriber/SET_FETCH_PERMISSIONS_FAILED",

  SET_FETCH_SELECT_PERMISSIONS_SEARCH:
    "permission-subscriber/SET_FETCH_SELECT_PERMISSIONS_SEARCH",
  SET_FETCH_SELECT_PERMISSIONS_SORT:
    "permission-subscriber/SET_FETCH_SELECT_PERMISSIONS_SORT",
  SET_FETCH_SELECT_PERMISSIONS_KEY_BY:
    "permission-subscriber/SET_FETCH_SELECT_PERMISSIONS_KEY_BY",
  SET_FETCH_SELECT_PERMISSIONS_PAGE:
    "permission-subscriber/SET_FETCH_SELECT_PERMISSIONS_PAGE",
  SET_FETCH_SELECT_PERMISSIONS_PER_PAGE:
    "permission-subscriber/SET_FETCH_SELECT_PERMISSIONS_PER_PAGE",
  SET_FETCH_SELECT_PERMISSIONS_INCLUDES:
    "permission-subscriber/SET_FETCH_SELECT_PERMISSIONS_INCLUDES",
  SET_FETCH_SELECT_PERMISSIONS_FILTER_PERMISSIONS_IDS:
    "permission-subscriber/SET_FETCH_SELECT_PERMISSIONS_FILTER_PERMISSIONS_IDS",
  SET_FETCH_SELECT_PERMISSIONS_FILTER_MARKET_ID:
    "permission-subscriber/SET_FETCH_SELECT_PERMISSIONS_FILTER_MARKET_ID",
  SET_FETCH_SELECT_PERMISSIONS_FILTER_IS_DEFAULT:
    "permission-subscriber/SET_FETCH_SELECT_PERMISSIONS_FILTER_IS_DEFAULT",
  SET_FETCH_SELECT_PERMISSIONS_LOADING:
    "permission-subscriber/SET_FETCH_SELECT_PERMISSIONS_LOADING",
  SET_FETCH_SELECT_PERMISSIONS_SUCCESS:
    "permission-subscriber/SET_FETCH_SELECT_PERMISSIONS_SUCCESS",
  SET_FETCH_SELECT_PERMISSIONS_FAILED:
    "permission-subscriber/SET_FETCH_SELECT_PERMISSIONS_FAILED",

  SET_APPEND_SELECT_PERMISSIONS_SEARCH:
    "permission-subscriber/SET_APPEND_SELECT_PERMISSIONS_SEARCH",
  SET_APPEND_SELECT_PERMISSIONS_SORT:
    "permission-subscriber/SET_APPEND_SELECT_PERMISSIONS_SORT",
  SET_APPEND_SELECT_PERMISSIONS_KEY_BY:
    "permission-subscriber/SET_APPEND_SELECT_PERMISSIONS_KEY_BY",
  SET_APPEND_SELECT_PERMISSIONS_PAGE:
    "permission-subscriber/SET_APPEND_SELECT_PERMISSIONS_PAGE",
  SET_APPEND_SELECT_PERMISSIONS_PER_PAGE:
    "permission-subscriber/SET_APPEND_SELECT_PERMISSIONS_PER_PAGE",
  SET_APPEND_SELECT_PERMISSIONS_INCLUDES:
    "permission-subscriber/SET_APPEND_SELECT_PERMISSIONS_INCLUDES",
  SET_APPEND_SELECT_PERMISSIONS_FILTER_PERMISSIONS_IDS:
    "permission-subscriber/SET_APPEND_SELECT_PERMISSIONS_FILTER_PERMISSIONS_IDS",
  SET_APPEND_SELECT_PERMISSIONS_FILTER_MARKET_ID:
    "permission-subscriber/SET_APPEND_SELECT_PERMISSIONS_FILTER_MARKET_ID",
  SET_APPEND_SELECT_PERMISSIONS_FILTER_IS_DEFAULT:
    "permission-subscriber/SET_APPEND_SELECT_PERMISSIONS_FILTER_IS_DEFAULT",
  SET_APPEND_SELECT_PERMISSIONS_LOADING:
    "permission-subscriber/SET_APPEND_SELECT_PERMISSIONS_LOADING",
  SET_APPEND_SELECT_PERMISSIONS_SUCCESS:
    "permission-subscriber/SET_APPEND_SELECT_PERMISSIONS_SUCCESS",
  SET_APPEND_SELECT_PERMISSIONS_FAILED:
    "permission-subscriber/SET_APPEND_SELECT_PERMISSIONS_FAILED",

  SET_FETCH_PERMISSION_LOADING:
    "permission-subscriber/SET_FETCH_PERMISSION_LOADING",
  SET_FETCH_PERMISSION_SUCCESS:
    "permission-subscriber/SET_FETCH_PERMISSION_SUCCESS",
  SET_FETCH_PERMISSION_FAILED:
    "permission-subscriber/SET_FETCH_PERMISSION_FAILED",

  SET_CREATE_PERMISSION_LOADING:
    "permission-subscriber/SET_CREATE_PERMISSION_LOADING",
  SET_CREATE_PERMISSION_SUCCESS:
    "permission-subscriber/SET_CREATE_PERMISSION_SUCCESS",
  SET_CREATE_PERMISSION_FAILED:
    "permission-subscriber/SET_CREATE_PERMISSION_FAILED",

  SET_UPDATE_PERMISSION_LOADING:
    "permission-subscriber/SET_UPDATE_PERMISSION_LOADING",
  SET_UPDATE_PERMISSION_SUCCESS:
    "permission-subscriber/SET_UPDATE_PERMISSION_SUCCESS",
  SET_UPDATE_PERMISSION_FAILED:
    "permission-subscriber/SET_UPDATE_PERMISSION_FAILED",

  SET_DELETE_PERMISSION_LOADING:
    "permission-subscriber/SET_DELETE_PERMISSION_LOADING",
  SET_DELETE_PERMISSION_SUCCESS:
    "permission-subscriber/SET_DELETE_PERMISSION_SUCCESS",
  SET_DELETE_PERMISSION_FAILED:
    "permission-subscriber/SET_DELETE_PERMISSION_FAILED",

  APPEND_PERMISSIONS: "permission-subscriber/APPEND_PERMISSIONS",
  APPEND_SELECT_PERMISSIONS: "permission-subscriber/APPEND_SELECT_PERMISSIONS",

  SET_IS_FETCH_PERMISSIONS_HITTED:
    "permission-subscriber/SET_IS_FETCH_PERMISSIONS_HITTED",
  SET_IS_FETCH_SELECT_PERMISSIONS_HITTED:
    "permission-subscriber/SET_IS_FETCH_SELECT_PERMISSIONS_HITTED",
  SET_IS_APPEND_SELECT_PERMISSIONS_HITTED:
    "permission-subscriber/SET_IS_APPEND_SELECT_PERMISSIONS_HITTED",
  SET_IS_FETCH_PERMISSION_HITTED:
    "permission-subscriber/SET_IS_FETCH_PERMISSION_HITTED",
  SET_IS_CREATE_PERMISSION_HITTED:
    "permission-subscriber/SET_IS_CREATE_PERMISSION_HITTED",
  SET_IS_UPDATE_PERMISSION_HITTED:
    "permission-subscriber/SET_IS_UPDATE_PERMISSION_HITTED",
  SET_IS_DELETE_PERMISSION_HITTED:
    "permission-subscriber/SET_IS_DELETE_PERMISSION_HITTED",

  FETCH_PERMISSIONS_START: "permission-subscriber/FETCH_PERMISSIONS_START",
  FETCH_SELECT_PERMISSIONS_START:
    "permission-subscriber/FETCH_SELECT_PERMISSIONS_START",
  APPEND_SELECT_PERMISSIONS_START:
    "permission-subscriber/APPEND_SELECT_PERMISSIONS_START",
  FETCH_PERMISSION_START: "permission-subscriber/FETCH_PERMISSION_START",
  CREATE_PERMISSION_START: "permission-subscriber/CREATE_PERMISSION_START",
  UPDATE_PERMISSION_START: "permission-subscriber/UPDATE_PERMISSION_START",
  DELETE_PERMISSION_START: "permission-subscriber/DELETE_PERMISSION_START",
};

export default PERMISSION_SUBSCRIBER_ACTION_TYPES;
