import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { saveAs } from "file-saver";

import { getFileNameUrl } from "../../utils/formatter.utils";

import EXPORT_ACTION_TYPES from "./export.type";

import {
  setExportCustomerReportFailed,
  setExportCustomerReportLoading,
  setExportCustomerReportSuccess,
  setExportIngredientHistoryReportFailed,
  setExportIngredientHistoryReportLoading,
  setExportIngredientHistoryReportSuccess,
  setExportIngredientReportFailed,
  setExportIngredientReportLoading,
  setExportIngredientReportSuccess,
  setExportProductBarcodeFailed,
  setExportProductBarcodeLoading,
  setExportProductBarcodeSuccess,
  setExportReportOrderFailed,
  setExportReportOrderLoading,
  setExportReportOrderSuccess,
  setExportReportSummaryFailed,
  setExportReportSummaryLoading,
  setExportReportSummarySuccess,
  setExportSubscriptionReceiptFailed,
  setExportSubscriptionReceiptLoading,
  setExportSubscriptionReceiptSuccess,
  setExportTableQrCodeFailed,
  setExportTableQrCodeLoading,
  setExportTableQrCodeSuccess,
  setIsExportCustomerReportHitted,
  setIsExportIngredientHistoryReportHitted,
  setIsExportIngredientReportHitted,
  setIsExportProductBarcodeHitted,
  setIsExportReportOrderHitted,
  setIsExportReportSummaryHitted,
  setIsExportSubscriptionReceiptHitted,
  setIsExportTableQrCodeHitted,
} from "./export.action";
import {
  getExportCustomerReportFilterCustomersIds,
  getExportCustomerReportFilterMarketId,
  getExportIngredientHistoryReportFilterBranchId,
  getExportIngredientHistoryReportFilterEndAt,
  getExportIngredientHistoryReportFilterIngredientsIds,
  getExportIngredientHistoryReportFilterMarketId,
  getExportIngredientHistoryReportFilterPeriodType,
  getExportIngredientHistoryReportFilterStartAt,
  getExportIngredientReportFilterBranchId,
  getExportIngredientReportFilterEndAt,
  getExportIngredientReportFilterIngredientsIds,
  getExportIngredientReportFilterMarketId,
  getExportIngredientReportFilterPeriodType,
  getExportIngredientReportFilterStartAt,
  getExportProductBarcodeFilterBranchId,
  getExportProductBarcodeFilterMarketId,
  getExportProductBarcodeFilterProductsIds,
  getExportReportOrderFilterBranchId,
  getExportReportOrderFilterCashierId,
  getExportReportOrderFilterCreatedAtAfter,
  getExportReportOrderFilterCreatedAtBefore,
  getExportReportOrderFilterCustomerId,
  getExportReportOrderFilterMarketId,
  getExportReportOrderFilterPaymentMethodKey,
  getExportReportSummaryFilterBranchId,
  getExportReportSummaryFilterCashierId,
  getExportReportSummaryFilterCreatedAtAfter,
  getExportReportSummaryFilterCreatedAtBefore,
  getExportReportSummaryFilterCustomerId,
  getExportReportSummaryFilterMarketId,
  getExportReportSummaryFilterPaymentMethodKey,
  getExportTableQrCodeFilterBranchId,
  getExportTableQrCodeFilterMarketId,
  getExportTableQrCodeFilterTablesIds,
} from "./export.selector";

import {
  downloadCustomerReport,
  downloadIngredientHistoryReport,
  downloadIngredientReport,
  downloadProductBarcode,
  downloadReportOrder,
  downloadReportSummary,
  downloadSubscriptionReceipt,
  downloadTableQrCode,
} from "../../api/export.api";

export function* _downloadReportOrder() {
  try {
    yield put(setExportReportOrderLoading(true));

    const market_id = yield select(getExportReportOrderFilterMarketId);
    const branch_id = yield select(getExportReportOrderFilterBranchId);
    const payment_method_key = yield select(
      getExportReportOrderFilterPaymentMethodKey
    );
    const cashier_id = yield select(getExportReportOrderFilterCashierId);
    const customer_id = yield select(getExportReportOrderFilterCustomerId);
    const created_at_before = yield select(
      getExportReportOrderFilterCreatedAtBefore
    );
    const created_at_after = yield select(
      getExportReportOrderFilterCreatedAtAfter
    );

    const parameters = {
      filter: {
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
        customer_id,
        created_at_before,
        created_at_after,
      },
    };

    const {
      meta: { message },
      data: fileUrl,
    } = yield call(downloadReportOrder, parameters);

    yield call(saveAs, fileUrl, getFileNameUrl(fileUrl));

    yield put(setIsExportReportOrderHitted(true));

    yield put(setExportReportOrderSuccess(message));
    yield put(setExportReportOrderLoading(false));
  } catch (error) {
    yield put(setExportReportOrderFailed(error));
    yield put(setExportReportOrderLoading(false));
  }
}
export function* _downloadReportSummary() {
  try {
    yield put(setExportReportSummaryLoading(true));

    const market_id = yield select(getExportReportSummaryFilterMarketId);
    const branch_id = yield select(getExportReportSummaryFilterBranchId);
    const payment_method_key = yield select(
      getExportReportSummaryFilterPaymentMethodKey
    );
    const cashier_id = yield select(getExportReportSummaryFilterCashierId);
    const customer_id = yield select(getExportReportSummaryFilterCustomerId);
    const created_at_before = yield select(
      getExportReportSummaryFilterCreatedAtBefore
    );
    const created_at_after = yield select(
      getExportReportSummaryFilterCreatedAtAfter
    );

    const parameters = {
      filter: {
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
        customer_id,
        created_at_before,
        created_at_after,
      },
    };

    const {
      meta: { message },
      data: fileUrl,
    } = yield call(downloadReportSummary, parameters);

    yield call(saveAs, fileUrl, getFileNameUrl(fileUrl));

    yield put(setIsExportReportSummaryHitted(true));

    yield put(setExportReportSummarySuccess(message));
    yield put(setExportReportSummaryLoading(false));
  } catch (error) {
    yield put(setExportReportSummaryFailed(error));
    yield put(setExportReportSummaryLoading(false));
  }
}
export function* _downloadCustomerReport() {
  try {
    yield put(setExportCustomerReportLoading(true));

    const market_id = yield select(getExportCustomerReportFilterMarketId);
    const customers_ids = yield select(
      getExportCustomerReportFilterCustomersIds
    );

    const parameters = { filter: { market_id, customers_ids } };

    const response = yield call(downloadCustomerReport, parameters);
    const filename = response.headers
      ?.get("content-disposition")
      ?.split(";")
      ?.find((n) => n.includes("filename="))
      ?.replace("filename=", "")
      ?.trim();

    yield call(saveAs, new Blob([response.data]), filename ?? "report.xlsx");

    yield put(setIsExportCustomerReportHitted(true));

    yield put(setExportCustomerReportSuccess("Export customer report success"));
    yield put(setExportCustomerReportLoading(false));
  } catch (error) {
    yield put(setExportCustomerReportFailed(error));
    yield put(setExportCustomerReportLoading(false));
  }
}
export function* _downloadIngredientReport() {
  try {
    yield put(setExportIngredientReportLoading(true));

    const period_type = yield select(getExportIngredientReportFilterPeriodType);
    const market_id = yield select(getExportIngredientReportFilterMarketId);
    const branch_id = yield select(getExportIngredientReportFilterBranchId);
    const ingredients_ids = yield select(
      getExportIngredientReportFilterIngredientsIds
    );
    const start_at = yield select(getExportIngredientReportFilterStartAt);
    const end_at = yield select(getExportIngredientReportFilterEndAt);

    const parameters = {
      filter: {
        period_type,
        market_id,
        branch_id,
        ingredients_ids,
        start_at,
        end_at,
      },
    };

    const response = yield call(downloadIngredientReport, parameters);
    const filename = response.headers
      ?.get("content-disposition")
      ?.split(";")
      ?.find((n) => n.includes("filename="))
      ?.replace("filename=", "")
      ?.trim();

    yield call(saveAs, new Blob([response.data]), filename ?? "report.xlsx");

    yield put(setIsExportIngredientReportHitted(true));

    yield put(
      setExportIngredientReportSuccess("Export ingredient report success")
    );
    yield put(setExportIngredientReportLoading(false));
  } catch (error) {
    yield put(setExportIngredientReportFailed(error));
    yield put(setExportIngredientReportLoading(false));
  }
}
export function* _downloadIngredientHistoryReport() {
  try {
    yield put(setExportIngredientHistoryReportLoading(true));

    const period_type = yield select(
      getExportIngredientHistoryReportFilterPeriodType
    );
    const market_id = yield select(
      getExportIngredientHistoryReportFilterMarketId
    );
    const branch_id = yield select(
      getExportIngredientHistoryReportFilterBranchId
    );
    const ingredients_ids = yield select(
      getExportIngredientHistoryReportFilterIngredientsIds
    );
    const start_at = yield select(
      getExportIngredientHistoryReportFilterStartAt
    );
    const end_at = yield select(getExportIngredientHistoryReportFilterEndAt);

    const parameters = {
      filter: {
        period_type,
        market_id,
        branch_id,
        ingredients_ids,
        start_at,
        end_at,
      },
    };

    const response = yield call(downloadIngredientHistoryReport, parameters);
    const filename = response.headers
      ?.get("content-disposition")
      ?.split(";")
      ?.find((n) => n.includes("filename="))
      ?.replace("filename=", "")
      ?.trim();

    yield call(saveAs, new Blob([response.data]), filename ?? "report.xlsx");

    yield put(setIsExportIngredientHistoryReportHitted(true));

    yield put(
      setExportIngredientHistoryReportSuccess(
        "Export ingredient history report success"
      )
    );
    yield put(setExportIngredientHistoryReportLoading(false));
  } catch (error) {
    yield put(setExportIngredientHistoryReportFailed(error));
    yield put(setExportIngredientHistoryReportLoading(false));
  }
}
export function* _downloadTableQrCode() {
  try {
    yield put(setExportTableQrCodeLoading(true));

    const market_id = yield select(getExportTableQrCodeFilterMarketId);
    const branch_id = yield select(getExportTableQrCodeFilterBranchId);
    const tables_ids = yield select(getExportTableQrCodeFilterTablesIds);

    const parameters = {
      filter: {
        market_id,
        branch_id,
        tables_ids,
      },
    };

    const {
      meta: { message },
      data: fileUrl,
    } = yield call(downloadTableQrCode, parameters);

    yield call(saveAs, fileUrl, getFileNameUrl(fileUrl));

    yield put(setIsExportTableQrCodeHitted(true));

    yield put(setExportTableQrCodeSuccess(message));
    yield put(setExportTableQrCodeLoading(false));
  } catch (error) {
    yield put(setExportTableQrCodeFailed(error));
    yield put(setExportTableQrCodeLoading(false));
  }
}
export function* _downloadProductBarcode() {
  try {
    yield put(setExportProductBarcodeLoading(true));

    const market_id = yield select(getExportProductBarcodeFilterMarketId);
    const branch_id = yield select(getExportProductBarcodeFilterBranchId);
    const products_ids = yield select(getExportProductBarcodeFilterProductsIds);

    const parameters = {
      filter: {
        market_id,
        branch_id,
        products_ids,
      },
    };

    const {
      meta: { message },
      data: fileUrl,
    } = yield call(downloadProductBarcode, parameters);

    yield call(saveAs, fileUrl, getFileNameUrl(fileUrl));

    yield put(setIsExportProductBarcodeHitted(true));

    yield put(setExportProductBarcodeSuccess(message));
    yield put(setExportProductBarcodeLoading(false));
  } catch (error) {
    yield put(setExportProductBarcodeFailed(error));
    yield put(setExportProductBarcodeLoading(false));
  }
}
export function* _downloadSubscriptionReceipt({ payload: id }) {
  try {
    yield put(setExportSubscriptionReceiptLoading(true));

    const response = yield call(downloadSubscriptionReceipt, id);
    // const filename = response.headers
    //   ?.get("content-disposition")
    //   ?.split(";")
    //   ?.find((n) => n.includes("filename="))
    //   ?.replace("filename=", "")
    //   ?.trim();

    yield call(saveAs, new Blob([response.data]), "receipt.pdf");

    yield put(setIsExportSubscriptionReceiptHitted(true));

    yield put(setExportSubscriptionReceiptSuccess("Export receipt success"));
    yield put(setExportSubscriptionReceiptLoading(false));
  } catch (error) {
    yield put(setExportSubscriptionReceiptFailed(error));
    yield put(setExportSubscriptionReceiptLoading(false));
  }
}

export function* onExportReportOrderStart() {
  yield takeLatest(
    EXPORT_ACTION_TYPES.EXPORT_REPORT_ORDER_START,
    _downloadReportOrder
  );
}
export function* onExportReportSummaryStart() {
  yield takeLatest(
    EXPORT_ACTION_TYPES.EXPORT_REPORT_SUMMARY_START,
    _downloadReportSummary
  );
}
export function* onExportCustomerReportStart() {
  yield takeLatest(
    EXPORT_ACTION_TYPES.EXPORT_CUSTOMER_REPORT_START,
    _downloadCustomerReport
  );
}
export function* onExportIngredientReportStart() {
  yield takeLatest(
    EXPORT_ACTION_TYPES.EXPORT_INGREDIENT_REPORT_START,
    _downloadIngredientReport
  );
}
export function* onExportIngredientHistoryReportStart() {
  yield takeLatest(
    EXPORT_ACTION_TYPES.EXPORT_INGREDIENT_HISTORY_REPORT_START,
    _downloadIngredientHistoryReport
  );
}
export function* onExportTableQrCodeStart() {
  yield takeLatest(
    EXPORT_ACTION_TYPES.EXPORT_TABLE_QRCODE_START,
    _downloadTableQrCode
  );
}
export function* onExportProductBarcodeStart() {
  yield takeLatest(
    EXPORT_ACTION_TYPES.EXPORT_PRODUCT_BARCODE_START,
    _downloadProductBarcode
  );
}
export function* onExportSubscriptionReceiptStart() {
  yield takeLatest(
    EXPORT_ACTION_TYPES.EXPORT_SUBSCRIPTION_RECEIPT_START,
    _downloadSubscriptionReceipt
  );
}

export function* exportSaga() {
  yield all([
    call(onExportReportOrderStart),
    call(onExportReportSummaryStart),
    call(onExportCustomerReportStart),
    call(onExportIngredientReportStart),
    call(onExportIngredientHistoryReportStart),
    call(onExportTableQrCodeStart),
    call(onExportProductBarcodeStart),
    call(onExportSubscriptionReceiptStart),
  ]);
}
