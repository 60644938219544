const KITCHEN_ACTION_TYPES = {
  RESET_KITCHEN_REDUCER: "kitchen/RESET_KITCHEN_REDUCER",

  SET_FIRST_PROCESS_ORDERS: "kitchen/SET_FIRST_PROCESS_ORDERS",
  SET_SECOND_PROCESS_ORDERS: "kitchen/SET_SECOND_PROCESS_ORDERS",
  SET_THIRD_PROCESS_ORDERS: "kitchen/SET_THIRD_PROCESS_ORDERS",

  SET_PROCESS_ORDERS: "kitchen/SET_PROCESS_ORDERS",
  SET_COMPLETED_ORDERS: "kitchen/SET_COMPLETED_ORDERS",

  SET_IS_PROCESS_ORDERS_HAS_MORE: "kitchen/SET_IS_PROCESS_ORDERS_HAS_MORE",
  SET_IS_COMPLETED_ORDERS_HAS_MORE: "kitchen/SET_IS_COMPLETED_ORDERS_HAS_MORE",

  SET_FETCH_PROCESS_ORDERS_SEARCH: "kitchen/SET_FETCH_PROCESS_ORDERS_SEARCH",
  SET_FETCH_PROCESS_ORDERS_SORT: "kitchen/SET_FETCH_PROCESS_ORDERS_SORT",
  SET_FETCH_PROCESS_ORDERS_PAGE: "kitchen/SET_FETCH_PROCESS_ORDERS_PAGE",
  SET_FETCH_PROCESS_ORDERS_PER_PAGE: "kitchen/SET_FETCH_PROCESS_ORDERS_PER_PAGE",
  SET_FETCH_PROCESS_ORDERS_INCLUDES: "kitchen/SET_FETCH_PROCESS_ORDERS_INCLUDES",
  SET_FETCH_PROCESS_ORDERS_FILTER_PERIOD_TYPE: "kitchen/SET_FETCH_PROCESS_ORDERS_FILTER_PERIOD_TYPE",
  SET_FETCH_PROCESS_ORDERS_FILTER_START_AT: "kitchen/SET_FETCH_PROCESS_ORDERS_FILTER_START_AT",
  SET_FETCH_PROCESS_ORDERS_FILTER_END_AT: "kitchen/SET_FETCH_PROCESS_ORDERS_FILTER_END_AT",
  SET_FETCH_PROCESS_ORDERS_FILTER_MARKET_ID: "kitchen/SET_FETCH_PROCESS_ORDERS_FILTER_MARKET_ID",
  SET_FETCH_PROCESS_ORDERS_FILTER_BRANCH_ID: "kitchen/SET_FETCH_PROCESS_ORDERS_FILTER_BRANCH_ID",
  SET_FETCH_PROCESS_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
    "kitchen/SET_FETCH_PROCESS_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS",
  SET_FETCH_PROCESS_ORDERS_FILTER_SOURCES: "kitchen/SET_FETCH_PROCESS_ORDERS_FILTER_SOURCES",
  SET_FETCH_PROCESS_ORDERS_FILTER_TYPES: "kitchen/SET_FETCH_PROCESS_ORDERS_FILTER_TYPES",
  SET_FETCH_PROCESS_ORDERS_FILTER_TABLE_ID: "kitchen/SET_FETCH_PROCESS_ORDERS_FILTER_TABLE_ID",
  SET_FETCH_PROCESS_ORDERS_FILTER_CASHIER_ID: "kitchen/SET_FETCH_PROCESS_ORDERS_FILTER_CASHIER_ID",
  SET_FETCH_PROCESS_ORDERS_LOADING: "kitchen/SET_FETCH_PROCESS_ORDERS_LOADING",
  SET_FETCH_PROCESS_ORDERS_SUCCESS: "kitchen/SET_FETCH_PROCESS_ORDERS_SUCCESS",
  SET_FETCH_PROCESS_ORDERS_FAILED: "kitchen/SET_FETCH_PROCESS_ORDERS_FAILED",

  SET_FETCH_COMPLETED_ORDERS_SEARCH: "kitchen/SET_FETCH_COMPLETED_ORDERS_SEARCH",
  SET_FETCH_COMPLETED_ORDERS_SORT: "kitchen/SET_FETCH_COMPLETED_ORDERS_SORT",
  SET_FETCH_COMPLETED_ORDERS_PAGE: "kitchen/SET_FETCH_COMPLETED_ORDERS_PAGE",
  SET_FETCH_COMPLETED_ORDERS_PER_PAGE: "kitchen/SET_FETCH_COMPLETED_ORDERS_PER_PAGE",
  SET_FETCH_COMPLETED_ORDERS_INCLUDES: "kitchen/SET_FETCH_COMPLETED_ORDERS_INCLUDES",
  SET_FETCH_COMPLETED_ORDERS_FILTER_PERIOD_TYPE: "kitchen/SET_FETCH_COMPLETED_ORDERS_FILTER_PERIOD_TYPE",
  SET_FETCH_COMPLETED_ORDERS_FILTER_START_AT: "kitchen/SET_FETCH_COMPLETED_ORDERS_FILTER_START_AT",
  SET_FETCH_COMPLETED_ORDERS_FILTER_END_AT: "kitchen/SET_FETCH_COMPLETED_ORDERS_FILTER_END_AT",
  SET_FETCH_COMPLETED_ORDERS_FILTER_MARKET_ID: "kitchen/SET_FETCH_COMPLETED_ORDERS_FILTER_MARKET_ID",
  SET_FETCH_COMPLETED_ORDERS_FILTER_BRANCH_ID: "kitchen/SET_FETCH_COMPLETED_ORDERS_FILTER_BRANCH_ID",
  SET_FETCH_COMPLETED_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS:
    "kitchen/SET_FETCH_COMPLETED_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS",
  SET_FETCH_COMPLETED_ORDERS_FILTER_SOURCES: "kitchen/SET_FETCH_COMPLETED_ORDERS_FILTER_SOURCES",
  SET_FETCH_COMPLETED_ORDERS_FILTER_TYPES: "kitchen/SET_FETCH_COMPLETED_ORDERS_FILTER_TYPES",
  SET_FETCH_COMPLETED_ORDERS_FILTER_TABLE_ID: "kitchen/SET_FETCH_COMPLETED_ORDERS_FILTER_TABLE_ID",
  SET_FETCH_COMPLETED_ORDERS_FILTER_CASHIER_ID: "kitchen/SET_FETCH_COMPLETED_ORDERS_FILTER_CASHIER_ID",
  SET_FETCH_COMPLETED_ORDERS_LOADING: "kitchen/SET_FETCH_COMPLETED_ORDERS_LOADING",
  SET_FETCH_COMPLETED_ORDERS_SUCCESS: "kitchen/SET_FETCH_COMPLETED_ORDERS_SUCCESS",
  SET_FETCH_COMPLETED_ORDERS_FAILED: "kitchen/SET_FETCH_COMPLETED_ORDERS_FAILED",

  APPEND_FIRST_PROCESS_ORDERS: "kitchen/APPEND_FIRST_PROCESS_ORDERS",
  APPEND_SECOND_PROCESS_ORDERS: "kitchen/APPEND_SECOND_PROCESS_ORDERS",
  APPEND_THIRD_PROCESS_ORDERS: "kitchen/APPEND_THIRD_PROCESS_ORDERS",

  APPEND_PROCESS_ORDERS: "kitchen/APPEND_PROCESS_ORDERS",
  APPEND_COMPLETED_ORDERS: "kitchen/APPEND_COMPLETED_ORDERS",

  REMOVE_FIRST_PROCESS_ORDER: "kitchen/REMOVE_FIRST_PROCESS_ORDER",
  REMOVE_SECOND_PROCESS_ORDER: "kitchen/REMOVE_SECOND_PROCESS_ORDER",
  REMOVE_THIRD_PROCESS_ORDER: "kitchen/REMOVE_THIRD_PROCESS_ORDER",

  SET_IS_FETCH_PROCESS_ORDERS_HITTED: "kitchen/SET_IS_FETCH_PROCESS_ORDERS_HITTED",
  SET_IS_FETCH_COMPLETED_ORDERS_HITTED: "kitchen/SET_IS_FETCH_COMPLETED_ORDERS_HITTED",

  FETCH_PROCESS_ORDERS_START: "kitchen/FETCH_PROCESS_ORDERS_START",
  FETCH_COMPLETED_ORDERS_START: "kitchen/FETCH_COMPLETED_ORDERS_START",
};

export default KITCHEN_ACTION_TYPES;
