import MARKET_ACTION_TYPES from "./market.type";
import { createAction } from "../../utils/store.utils";

export const setMarketTableColumns = (marketTableColumns) =>
  createAction(
    MARKET_ACTION_TYPES.SET_MARKET_TABLE_COLUMNS,
    marketTableColumns
  );

export const setIsModalAutoClose = (isModalAutoClose) =>
  createAction(MARKET_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE, isModalAutoClose);

export const setMarkets = (markets) =>
  createAction(MARKET_ACTION_TYPES.SET_MARKETS, markets);
export const setSelectMarkets = (selectMarkets) =>
  createAction(MARKET_ACTION_TYPES.SET_SELECT_MARKETS, selectMarkets);
export const setSubscriptionMarkets = (subscriptionMarkets) =>
  createAction(
    MARKET_ACTION_TYPES.SET_SUBSCRIPTION_MARKETS,
    subscriptionMarkets
  );
export const setMarket = (market) =>
  createAction(MARKET_ACTION_TYPES.SET_MARKET, market);
export const setSubscriptionMarket = (subscriptionMarket) =>
  createAction(MARKET_ACTION_TYPES.SET_SUBSCRIPTION_MARKET, subscriptionMarket);

export const setIsMarketsHasMore = (isMarketsHasMore) =>
  createAction(MARKET_ACTION_TYPES.SET_IS_MARKETS_HAS_MORE, isMarketsHasMore);
export const setIsSelectMarketsHasMore = (isSelectMarketsHasMore) =>
  createAction(
    MARKET_ACTION_TYPES.SET_IS_SELECT_MARKETS_HAS_MORE,
    isSelectMarketsHasMore
  );
export const setIsSubscriptionMarketsHasMore = (isSubscriptionMarketsHasMore) =>
  createAction(
    MARKET_ACTION_TYPES.SET_IS_SUBSCRIPTION_MARKETS_HAS_MORE,
    isSubscriptionMarketsHasMore
  );

export const setFetchMarketsSearch = (fetchMarketsSearch) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_MARKETS_SEARCH,
    fetchMarketsSearch
  );
export const setFetchMarketsSort = (fetchMarketsSort) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKETS_SORT, fetchMarketsSort);
export const setFetchMarketsKeyBy = (fetchMarketsKeyBy) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKETS_KEY_BY, fetchMarketsKeyBy);
export const setFetchMarketsPage = (fetchMarketsPage) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKETS_PAGE, fetchMarketsPage);
export const setFetchMarketsPerPage = (fetchMarketsPerPage) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_MARKETS_PER_PAGE,
    fetchMarketsPerPage
  );
export const setFetchMarketsIncludes = (fetchMarketsIncludes) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_MARKETS_INCLUDES,
    fetchMarketsIncludes
  );
export const setFetchMarketsFilterMarketsIds = (fetchMarketsFilterMarketsIds) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FILTER_MARKETS_IDS,
    fetchMarketsFilterMarketsIds
  );
export const setFetchMarketsFilterSectorId = (fetchMarketsFilterSectorId) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FILTER_SECTOR_ID,
    fetchMarketsFilterSectorId
  );
export const setFetchMarketsFilterMerchantId = (fetchMarketsFilterMerchantId) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FILTER_MERCHANT_ID,
    fetchMarketsFilterMerchantId
  );
export const setFetchMarketsFilterResellerId = (fetchMarketsFilterResellerId) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FILTER_RESELLER_ID,
    fetchMarketsFilterResellerId
  );
export const setFetchMarketsFilterCreatedBy = (fetchMarketsFilterCreatedBy) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FILTER_CREATED_BY,
    fetchMarketsFilterCreatedBy
  );
export const setFetchMarketsLoading = (fetchMarketsLoading) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_MARKETS_LOADING,
    fetchMarketsLoading
  );
export const setFetchMarketsSuccess = (fetchMarketsSuccess) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_MARKETS_SUCCESS,
    fetchMarketsSuccess
  );
export const setFetchMarketsFailed = (fetchMarketsFailed) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FAILED,
    fetchMarketsFailed
  );

export const setFetchSelectMarketsSearch = (fetchSelectMarketsSearch) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_SEARCH,
    fetchSelectMarketsSearch
  );
export const setFetchSelectMarketsSort = (fetchSelectMarketsSort) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_SORT,
    fetchSelectMarketsSort
  );
export const setFetchSelectMarketsKeyBy = (fetchSelectMarketsKeyBy) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_KEY_BY,
    fetchSelectMarketsKeyBy
  );
export const setFetchSelectMarketsPage = (fetchSelectMarketsPage) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_PAGE,
    fetchSelectMarketsPage
  );
export const setFetchSelectMarketsPerPage = (fetchSelectMarketsPerPage) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_PER_PAGE,
    fetchSelectMarketsPerPage
  );
export const setFetchSelectMarketsIncludes = (fetchSelectMarketsIncludes) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_INCLUDES,
    fetchSelectMarketsIncludes
  );
export const setFetchSelectMarketsFilterMarketsIds = (
  fetchSelectMarketsFilterMarketsIds
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_FILTER_MARKETS_IDS,
    fetchSelectMarketsFilterMarketsIds
  );
export const setFetchSelectMarketsFilterSectorId = (
  fetchSelectMarketsFilterSectorId
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_FILTER_SECTOR_ID,
    fetchSelectMarketsFilterSectorId
  );
export const setFetchSelectMarketsFilterMerchantId = (
  fetchSelectMarketsFilterMerchantId
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_FILTER_MERCHANT_ID,
    fetchSelectMarketsFilterMerchantId
  );
export const setFetchSelectMarketsFilterResellerId = (
  fetchSelectMarketsFilterResellerId
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_FILTER_RESELLER_ID,
    fetchSelectMarketsFilterResellerId
  );
export const setFetchSelectMarketsFilterCreatedBy = (
  fetchSelectMarketsFilterCreatedBy
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_FILTER_CREATED_BY,
    fetchSelectMarketsFilterCreatedBy
  );
export const setFetchSelectMarketsLoading = (fetchSelectMarketsLoading) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_LOADING,
    fetchSelectMarketsLoading
  );
export const setFetchSelectMarketsSuccess = (fetchSelectMarketsSuccess) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_SUCCESS,
    fetchSelectMarketsSuccess
  );
export const setFetchSelectMarketsFailed = (fetchSelectMarketsFailed) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SELECT_MARKETS_FAILED,
    fetchSelectMarketsFailed
  );

export const setFetchSubscriptionMarketsSearch = (
  fetchSubscriptionMarketsSearch
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_SEARCH,
    fetchSubscriptionMarketsSearch
  );
export const setFetchSubscriptionMarketsSort = (fetchSubscriptionMarketsSort) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_SORT,
    fetchSubscriptionMarketsSort
  );
export const setFetchSubscriptionMarketsKeyBy = (
  fetchSubscriptionMarketsKeyBy
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_KEY_BY,
    fetchSubscriptionMarketsKeyBy
  );
export const setFetchSubscriptionMarketsPage = (fetchSubscriptionMarketsPage) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_PAGE,
    fetchSubscriptionMarketsPage
  );
export const setFetchSubscriptionMarketsPerPage = (
  fetchSubscriptionMarketsPerPage
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_PER_PAGE,
    fetchSubscriptionMarketsPerPage
  );
export const setFetchSubscriptionMarketsIncludes = (
  fetchSubscriptionMarketsIncludes
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_INCLUDES,
    fetchSubscriptionMarketsIncludes
  );
export const setFetchSubscriptionMarketsFilterMarketsIds = (
  fetchSubscriptionMarketsFilterMarketsIds
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_MARKETS_IDS,
    fetchSubscriptionMarketsFilterMarketsIds
  );
export const setFetchSubscriptionMarketsFilterSectorId = (
  fetchSubscriptionMarketsFilterSectorId
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_SECTOR_ID,
    fetchSubscriptionMarketsFilterSectorId
  );
export const setFetchSubscriptionMarketsFilterMerchantId = (
  fetchSubscriptionMarketsFilterMerchantId
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_MERCHANT_ID,
    fetchSubscriptionMarketsFilterMerchantId
  );
export const setFetchSubscriptionMarketsFilterResellerId = (
  fetchSubscriptionMarketsFilterResellerId
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_RESELLER_ID,
    fetchSubscriptionMarketsFilterResellerId
  );
export const setFetchSubscriptionMarketsFilterCreatedBy = (
  fetchSubscriptionMarketsFilterCreatedBy
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_CREATED_BY,
    fetchSubscriptionMarketsFilterCreatedBy
  );
export const setFetchSubscriptionMarketsLoading = (
  fetchSubscriptionMarketsLoading
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_LOADING,
    fetchSubscriptionMarketsLoading
  );
export const setFetchSubscriptionMarketsSuccess = (
  fetchSubscriptionMarketsSuccess
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_SUCCESS,
    fetchSubscriptionMarketsSuccess
  );
export const setFetchSubscriptionMarketsFailed = (
  fetchSubscriptionMarketsFailed
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_MARKETS_FAILED,
    fetchSubscriptionMarketsFailed
  );

export const setAppendSelectMarketsSearch = (appendSelectMarketsSearch) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_SEARCH,
    appendSelectMarketsSearch
  );
export const setAppendSelectMarketsSort = (appendSelectMarketsSort) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_SORT,
    appendSelectMarketsSort
  );
export const setAppendSelectMarketsKeyBy = (appendSelectMarketsKeyBy) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_KEY_BY,
    appendSelectMarketsKeyBy
  );
export const setAppendSelectMarketsPage = (appendSelectMarketsPage) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_PAGE,
    appendSelectMarketsPage
  );
export const setAppendSelectMarketsPerPage = (appendSelectMarketsPerPage) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_PER_PAGE,
    appendSelectMarketsPerPage
  );
export const setAppendSelectMarketsIncludes = (appendSelectMarketsIncludes) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_INCLUDES,
    appendSelectMarketsIncludes
  );
export const setAppendSelectMarketsFilterMarketsIds = (
  appendSelectMarketsFilterMarketsIds
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_FILTER_MARKETS_IDS,
    appendSelectMarketsFilterMarketsIds
  );
export const setAppendSelectMarketsFilterSectorId = (
  appendSelectMarketsFilterSectorId
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_FILTER_SECTOR_ID,
    appendSelectMarketsFilterSectorId
  );
export const setAppendSelectMarketsFilterMerchantId = (
  appendSelectMarketsFilterMerchantId
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_FILTER_MERCHANT_ID,
    appendSelectMarketsFilterMerchantId
  );
export const setAppendSelectMarketsFilterResellerId = (
  appendSelectMarketsFilterResellerId
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_FILTER_RESELLER_ID,
    appendSelectMarketsFilterResellerId
  );
export const setAppendSelectMarketsFilterCreatedBy = (
  appendSelectMarketsFilterCreatedBy
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_FILTER_CREATED_BY,
    appendSelectMarketsFilterCreatedBy
  );
export const setAppendSelectMarketsLoading = (appendSelectMarketsLoading) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_LOADING,
    appendSelectMarketsLoading
  );
export const setAppendSelectMarketsSuccess = (appendSelectMarketsSuccess) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_SUCCESS,
    appendSelectMarketsSuccess
  );
export const setAppendSelectMarketsFailed = (appendSelectMarketsFailed) =>
  createAction(
    MARKET_ACTION_TYPES.SET_APPEND_SELECT_MARKETS_FAILED,
    appendSelectMarketsFailed
  );

export const setFetchMarketLoading = (fetchMarketLoading) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_MARKET_LOADING,
    fetchMarketLoading
  );
export const setFetchMarketSuccess = (fetchMarketSuccess) =>
  createAction(
    MARKET_ACTION_TYPES.SET_FETCH_MARKET_SUCCESS,
    fetchMarketSuccess
  );
export const setFetchMarketFailed = (fetchMarketFailed) =>
  createAction(MARKET_ACTION_TYPES.SET_FETCH_MARKET_FAILED, fetchMarketFailed);

export const setCreateMarketLoading = (createMarketLoading) =>
  createAction(
    MARKET_ACTION_TYPES.SET_CREATE_MARKET_LOADING,
    createMarketLoading
  );
export const setCreateMarketSuccess = (createMarketSuccess) =>
  createAction(
    MARKET_ACTION_TYPES.SET_CREATE_MARKET_SUCCESS,
    createMarketSuccess
  );
export const setCreateMarketFailed = (createMarketFailed) =>
  createAction(
    MARKET_ACTION_TYPES.SET_CREATE_MARKET_FAILED,
    createMarketFailed
  );

export const setUpdateMarketLoading = (updateMarketLoading) =>
  createAction(
    MARKET_ACTION_TYPES.SET_UPDATE_MARKET_LOADING,
    updateMarketLoading
  );
export const setUpdateMarketSuccess = (updateMarketSuccess) =>
  createAction(
    MARKET_ACTION_TYPES.SET_UPDATE_MARKET_SUCCESS,
    updateMarketSuccess
  );
export const setUpdateMarketFailed = (updateMarketFailed) =>
  createAction(
    MARKET_ACTION_TYPES.SET_UPDATE_MARKET_FAILED,
    updateMarketFailed
  );

export const setDeleteMarketLoading = (deleteMarketLoading) =>
  createAction(
    MARKET_ACTION_TYPES.SET_DELETE_MARKET_LOADING,
    deleteMarketLoading
  );
export const setDeleteMarketSuccess = (deleteMarketSuccess) =>
  createAction(
    MARKET_ACTION_TYPES.SET_DELETE_MARKET_SUCCESS,
    deleteMarketSuccess
  );
export const setDeleteMarketFailed = (deleteMarketFailed) =>
  createAction(
    MARKET_ACTION_TYPES.SET_DELETE_MARKET_FAILED,
    deleteMarketFailed
  );

export const setSendVerificationMarketLoading = (
  sendVerificationMarketLoading
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_SEND_VERIFICATION_MARKET_LOADING,
    sendVerificationMarketLoading
  );
export const setSendVerificationMarketSuccess = (
  sendVerificationMarketSuccess
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_SEND_VERIFICATION_MARKET_SUCCESS,
    sendVerificationMarketSuccess
  );
export const setSendVerificationMarketFailed = (sendVerificationMarketFailed) =>
  createAction(
    MARKET_ACTION_TYPES.SET_SEND_VERIFICATION_MARKET_FAILED,
    sendVerificationMarketFailed
  );

export const setVerifyVerificationMarketLoading = (
  verifyVerificationMarketLoading
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_VERIFY_VERIFICATION_MARKET_LOADING,
    verifyVerificationMarketLoading
  );
export const setVerifyVerificationMarketSuccess = (
  verifyVerificationMarketSuccess
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_VERIFY_VERIFICATION_MARKET_SUCCESS,
    verifyVerificationMarketSuccess
  );
export const setVerifyVerificationMarketFailed = (
  verifyVerificationMarketFailed
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_VERIFY_VERIFICATION_MARKET_FAILED,
    verifyVerificationMarketFailed
  );

export const appendMarkets = (markets) =>
  createAction(MARKET_ACTION_TYPES.APPEND_MARKETS, markets);
export const appendSelectMarkets = (selectMarkets) =>
  createAction(MARKET_ACTION_TYPES.APPEND_SELECT_MARKETS, selectMarkets);
export const appendSubscriptionMarkets = (subscriptionMarkets) =>
  createAction(
    MARKET_ACTION_TYPES.APPEND_SUBSCRIPTION_MARKETS,
    subscriptionMarkets
  );

export const fetchMarketsStart = () =>
  createAction(MARKET_ACTION_TYPES.FETCH_MARKETS_START);
export const fetchSelectMarketsStart = () =>
  createAction(MARKET_ACTION_TYPES.FETCH_SELECT_MARKETS_START);
export const fetchSubscriptionMarketsStart = () =>
  createAction(MARKET_ACTION_TYPES.FETCH_SUBSCRIPTION_MARKETS_START);
export const fetchMarketStart = (marketId) =>
  createAction(MARKET_ACTION_TYPES.FETCH_MARKET_START, marketId);
export const createMarketStart = (request) =>
  createAction(MARKET_ACTION_TYPES.CREATE_MARKET_START, request);
export const updateMarketStart = (marketId, request) =>
  createAction(MARKET_ACTION_TYPES.UPDATE_MARKET_START, { marketId, request });
export const deleteMarketStart = (marketId) =>
  createAction(MARKET_ACTION_TYPES.DELETE_MARKET_START, marketId);
export const sendVerificationMarketStart = (request) =>
  createAction(MARKET_ACTION_TYPES.SEND_VERIFICATION_MARKET_START, request);
export const verifyVerificationMarketStart = (request) =>
  createAction(MARKET_ACTION_TYPES.VERIFY_VERIFICATION_MARKET_START, request);
export const appendSelectMarketsStart = () =>
  createAction(MARKET_ACTION_TYPES.APPEND_SELECT_MARKETS_START);

export const setIsFetchMarketsHitted = (isFetchMarketsHitted) =>
  createAction(
    MARKET_ACTION_TYPES.SET_IS_FETCH_MARKETS_HITTED,
    isFetchMarketsHitted
  );
export const setIsFetchSelectMarketsHitted = (isFetchSelectMarketsHitted) =>
  createAction(
    MARKET_ACTION_TYPES.SET_IS_FETCH_SELECT_MARKETS_HITTED,
    isFetchSelectMarketsHitted
  );
export const setIsFetchSubscriptionMarketsHitted = (
  isFetchSubscriptionMarketsHitted
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_IS_FETCH_SUBSCRIPTION_MARKETS_HITTED,
    isFetchSubscriptionMarketsHitted
  );
export const setIsFetchMarketHitted = (isFetchMarketHitted) =>
  createAction(
    MARKET_ACTION_TYPES.SET_IS_FETCH_MARKET_HITTED,
    isFetchMarketHitted
  );
export const setIsCreateMarketHitted = (isCreateMarketHitted) =>
  createAction(
    MARKET_ACTION_TYPES.SET_IS_CREATE_MARKET_HITTED,
    isCreateMarketHitted
  );
export const setIsUpdateMarketHitted = (isUpdateMarketHitted) =>
  createAction(
    MARKET_ACTION_TYPES.SET_IS_UPDATE_MARKET_HITTED,
    isUpdateMarketHitted
  );
export const setIsDeleteMarketHitted = (isDeleteMarketHitted) =>
  createAction(
    MARKET_ACTION_TYPES.SET_IS_DELETE_MARKET_HITTED,
    isDeleteMarketHitted
  );
export const setIsSendVerificationMarketHitted = (
  isSendVerificationMarketHitted
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_IS_SEND_VERIFICATION_MARKET_HITTED,
    isSendVerificationMarketHitted
  );
export const setIsVerifyVerificationMarketHitted = (
  isVerifyVerificationMarketHitted
) =>
  createAction(
    MARKET_ACTION_TYPES.SET_IS_VERIFY_VERIFICATION_MARKET_HITTED,
    isVerifyVerificationMarketHitted
  );
export const setIsAppendSelectMarketsHitted = (isAppendSelectMarketsHitted) =>
  createAction(
    MARKET_ACTION_TYPES.SET_IS_APPEND_SELECT_MARKETS_HITTED,
    isAppendSelectMarketsHitted
  );

export const resetMarketReducer = () =>
  createAction(MARKET_ACTION_TYPES.RESET_MARKET_REDUCER);
