import DRIVER_ACTION_TYPES from "./driver.type";
import { createAction } from "../../utils/store.utils";

export const setDrivers = (drivers) =>
  createAction(DRIVER_ACTION_TYPES.SET_DRIVERS, drivers);
export const setDriver = (driver) =>
  createAction(DRIVER_ACTION_TYPES.SET_DRIVER, driver);
export const setCreateDriver = (createDriver) =>
  createAction(DRIVER_ACTION_TYPES.SET_CREATE_DRIVER, createDriver);
export const setUpdateDriver = (updateDriver) =>
  createAction(DRIVER_ACTION_TYPES.SET_UPDATE_DRIVER, updateDriver);

export const setIsDriversHasMore = (isDriversHasMore) =>
  createAction(DRIVER_ACTION_TYPES.SET_IS_DRIVERS_HAS_MORE, isDriversHasMore);

export const setFetchDriversSearch = (fetchDriversSearch) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_SEARCH,
    fetchDriversSearch
  );
export const setFetchDriversPage = (fetchDriversPage) =>
  createAction(DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_PAGE, fetchDriversPage);
export const setFetchDriversPerPage = (fetchDriversPerPage) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_PER_PAGE,
    fetchDriversPerPage
  );
export const setFetchDriversIncludes = (fetchDriversIncludes) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_INCLUDES,
    fetchDriversIncludes
  );
export const setFetchDriversFilterMarketId = (fetchDriversFilterMarketId) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_FILTER_MARKET_ID,
    fetchDriversFilterMarketId
  );
export const setFetchDriversFilterGender = (fetchDriversFilterGender) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_FILTER_GENDER,
    fetchDriversFilterGender
  );
export const setFetchDriversFilterStatus = (fetchDriversFilterStatus) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_FILTER_STATUS,
    fetchDriversFilterStatus
  );
export const setFetchDriversFilterVehicleType = (
  fetchDriversFilterVehicleType
) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_FILTER_VEHICLE_TYPE,
    fetchDriversFilterVehicleType
  );
export const setFetchDriversLoading = (fetchDriversLoading) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_LOADING,
    fetchDriversLoading
  );
export const setFetchDriversSuccess = (fetchDriversSuccess) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_SUCCESS,
    fetchDriversSuccess
  );
export const setFetchDriversFailed = (fetchDriversFailed) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_FETCH_DRIVERS_FAILED,
    fetchDriversFailed
  );

export const setFetchDriverLoading = (fetchDriverLoading) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_FETCH_DRIVER_LOADING,
    fetchDriverLoading
  );
export const setFetchDriverSuccess = (fetchDriverSuccess) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_FETCH_DRIVER_SUCCESS,
    fetchDriverSuccess
  );
export const setFetchDriverFailed = (fetchDriverFailed) =>
  createAction(DRIVER_ACTION_TYPES.SET_FETCH_DRIVER_FAILED, fetchDriverFailed);

export const setCreateDriverLoading = (createDriverLoading) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_CREATE_DRIVER_LOADING,
    createDriverLoading
  );
export const setCreateDriverSuccess = (createDriverSuccess) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_CREATE_DRIVER_SUCCESS,
    createDriverSuccess
  );
export const setCreateDriverFailed = (createDriverFailed) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_CREATE_DRIVER_FAILED,
    createDriverFailed
  );

export const setUpdateDriverLoading = (updateDriverLoading) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_UPDATE_DRIVER_LOADING,
    updateDriverLoading
  );
export const setUpdateDriverSuccess = (updateDriverSuccess) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_UPDATE_DRIVER_SUCCESS,
    updateDriverSuccess
  );
export const setUpdateDriverFailed = (updateDriverFailed) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_UPDATE_DRIVER_FAILED,
    updateDriverFailed
  );

export const appendDrivers = (drivers) =>
  createAction(DRIVER_ACTION_TYPES.APPEND_DRIVERS, drivers);

export const fetchDriversStart = () =>
  createAction(DRIVER_ACTION_TYPES.FETCH_DRIVERS_START);
export const fetchDriverStart = (driverId) =>
  createAction(DRIVER_ACTION_TYPES.FETCH_DRIVER_START, driverId);
export const createDriverStart = (request) =>
  createAction(DRIVER_ACTION_TYPES.CREATE_DRIVER_START, request);
export const updateDriverStart = (driverId, request) =>
  createAction(DRIVER_ACTION_TYPES.UPDATE_DRIVER_START, { driverId, request });

export const setIsFetchDriversHitted = (isFetchDriversHitted) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_IS_FETCH_DRIVERS_HITTED,
    isFetchDriversHitted
  );
export const setIsFetchDriverHitted = (isFetchDriverHitted) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_IS_FETCH_DRIVER_HITTED,
    isFetchDriverHitted
  );
export const setIsCreateDriverHitted = (isCreateDriverHitted) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_IS_CREATE_DRIVER_HITTED,
    isCreateDriverHitted
  );
export const setIsUpdateDriverHitted = (isUpdateDriverHitted) =>
  createAction(
    DRIVER_ACTION_TYPES.SET_IS_UPDATE_DRIVER_HITTED,
    isUpdateDriverHitted
  );

export const resetDriverReducer = () =>
  createAction(DRIVER_ACTION_TYPES.RESET_DRIVER_REDUCER);
