import { createSelector } from "reselect";

const summarySelector = ({ summary }) => summary;

export const getDashboardSummaryFinancialTotal = createSelector(
  [summarySelector],
  ({ dashboardSummaryFinancialTotal }) => dashboardSummaryFinancialTotal
);

export const getDashboardSummaryFinancialAllTimeTotal = createSelector(
  [summarySelector],
  ({ dashboardSummaryFinancialAllTimeTotal }) => dashboardSummaryFinancialAllTimeTotal
);

export const getReportSummaryFinancialTotal = createSelector(
  [summarySelector],
  ({ reportSummaryFinancialTotal }) => reportSummaryFinancialTotal
);

export const getAccountSummaryFinancialTotal = createSelector(
  [summarySelector],
  ({ accountSummaryFinancialTotal }) => accountSummaryFinancialTotal
);

export const getSummaryFinancialChart = createSelector(
  [summarySelector],
  ({ summaryFinancialChart }) => summaryFinancialChart
);

export const getDashboardSummaryTopProducts = createSelector(
  [summarySelector],
  ({ dashboardSummaryTopProducts }) => dashboardSummaryTopProducts
);

export const getReportSummaryTopProducts = createSelector(
  [summarySelector],
  ({ reportSummaryTopProducts }) => reportSummaryTopProducts
);

export const getSummaryTopProductCategories = createSelector(
  [summarySelector],
  ({ summaryTopProductCategories }) => summaryTopProductCategories
);

export const getSummaryLatestOrders = createSelector(
  [summarySelector],
  ({ summaryLatestOrders }) => summaryLatestOrders
);

export const getSummaryPaymentMethods = createSelector(
  [summarySelector],
  ({ summaryPaymentMethods }) => summaryPaymentMethods
);

export const getSummarySubscribers = createSelector([summarySelector], ({ summarySubscribers }) => summarySubscribers);

export const getSummaryBranches = createSelector([summarySelector], ({ summaryBranches }) => summaryBranches);

export const getSummarySubscriberReport = createSelector(
  [summarySelector],
  ({ summarySubscriberReport }) => summarySubscriberReport
);

export const getSummarySubscriberReportBranch = createSelector(
  [summarySelector],
  ({ summarySubscriberReportBranch }) => summarySubscriberReportBranch
);

export const getDashboardSummaryTotalUser = createSelector(
  [summarySelector],
  ({ dashboardSummaryTotalUser }) => dashboardSummaryTotalUser
);

export const getDashboardSummaryTotalMarket = createSelector(
  [summarySelector],
  ({ dashboardSummaryTotalMarket }) => dashboardSummaryTotalMarket
);

export const getDashboardSummaryTotalBranch = createSelector(
  [summarySelector],
  ({ dashboardSummaryTotalBranch }) => dashboardSummaryTotalBranch
);

export const getReportSummaryTotalUser = createSelector(
  [summarySelector],
  ({ reportSummaryTotalUser }) => reportSummaryTotalUser
);

export const getReportSummaryTotalMarket = createSelector(
  [summarySelector],
  ({ reportSummaryTotalMarket }) => reportSummaryTotalMarket
);

export const getReportSummaryTotalBranch = createSelector(
  [summarySelector],
  ({ reportSummaryTotalBranch }) => reportSummaryTotalBranch
);

export const getIsDashboardSummaryTopProductsHasMore = createSelector(
  [summarySelector],
  ({ isDashboardSummaryTopProductsHasMore }) => isDashboardSummaryTopProductsHasMore
);

export const getIsReportSummaryTopProductsHasMore = createSelector(
  [summarySelector],
  ({ isReportSummaryTopProductsHasMore }) => isReportSummaryTopProductsHasMore
);

export const getIsSummaryTopProductCategoriesHasMore = createSelector(
  [summarySelector],
  ({ isSummaryTopProductCategoriesHasMore }) => isSummaryTopProductCategoriesHasMore
);

export const getIsSummaryPaymentMethodsHasMore = createSelector(
  [summarySelector],
  ({ isSummaryPaymentMethodsHasMore }) => isSummaryPaymentMethodsHasMore
);

export const getIsSummarySubscribersHasMore = createSelector(
  [summarySelector],
  ({ isSummarySubscribersHasMore }) => isSummarySubscribersHasMore
);

export const getIsSummaryBranchesHasMore = createSelector(
  [summarySelector],
  ({ isSummaryBranchesHasMore }) => isSummaryBranchesHasMore
);

export const getFetchDashboardSummaryFinancialTotalFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialTotalFilterPeriodType }) => fetchDashboardSummaryFinancialTotalFilterPeriodType
);

export const getFetchDashboardSummaryFinancialTotalFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialTotalFilterStartAt }) => fetchDashboardSummaryFinancialTotalFilterStartAt
);

export const getFetchDashboardSummaryFinancialTotalFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialTotalFilterEndAt }) => fetchDashboardSummaryFinancialTotalFilterEndAt
);

export const getFetchDashboardSummaryFinancialTotalFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialTotalFilterMarketId }) => fetchDashboardSummaryFinancialTotalFilterMarketId
);

export const getFetchDashboardSummaryFinancialTotalFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialTotalFilterBranchId }) => fetchDashboardSummaryFinancialTotalFilterBranchId
);

export const getFetchDashboardSummaryFinancialTotalFilterPaymentMethodKey = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialTotalFilterPaymentMethodKey }) =>
    fetchDashboardSummaryFinancialTotalFilterPaymentMethodKey
);

export const getFetchDashboardSummaryFinancialTotalFilterCashierId = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialTotalFilterCashierId }) => fetchDashboardSummaryFinancialTotalFilterCashierId
);

export const getFetchDashboardSummaryFinancialTotalLoading = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialTotalLoading }) => fetchDashboardSummaryFinancialTotalLoading
);

export const getFetchDashboardSummaryFinancialTotalSuccess = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialTotalSuccess }) => fetchDashboardSummaryFinancialTotalSuccess
);

export const getFetchDashboardSummaryFinancialTotalFailed = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialTotalFailed }) => fetchDashboardSummaryFinancialTotalFailed
);

export const getFetchDashboardSummaryFinancialAllTimeTotalFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialAllTimeTotalFilterPeriodType }) =>
    fetchDashboardSummaryFinancialAllTimeTotalFilterPeriodType
);

export const getFetchDashboardSummaryFinancialAllTimeTotalFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialAllTimeTotalFilterStartAt }) =>
    fetchDashboardSummaryFinancialAllTimeTotalFilterStartAt
);

export const getFetchDashboardSummaryFinancialAllTimeTotalFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialAllTimeTotalFilterEndAt }) => fetchDashboardSummaryFinancialAllTimeTotalFilterEndAt
);

export const getFetchDashboardSummaryFinancialAllTimeTotalFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialAllTimeTotalFilterMarketId }) =>
    fetchDashboardSummaryFinancialAllTimeTotalFilterMarketId
);

export const getFetchDashboardSummaryFinancialAllTimeTotalFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialAllTimeTotalFilterBranchId }) =>
    fetchDashboardSummaryFinancialAllTimeTotalFilterBranchId
);

export const getFetchDashboardSummaryFinancialAllTimeTotalFilterPaymentMethodKey = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialAllTimeTotalFilterPaymentMethodKey }) =>
    fetchDashboardSummaryFinancialAllTimeTotalFilterPaymentMethodKey
);

export const getFetchDashboardSummaryFinancialAllTimeTotalFilterCashierId = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialAllTimeTotalFilterCashierId }) =>
    fetchDashboardSummaryFinancialAllTimeTotalFilterCashierId
);

export const getFetchDashboardSummaryFinancialAllTimeTotalLoading = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialAllTimeTotalLoading }) => fetchDashboardSummaryFinancialAllTimeTotalLoading
);

export const getFetchDashboardSummaryFinancialAllTimeTotalSuccess = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialAllTimeTotalSuccess }) => fetchDashboardSummaryFinancialAllTimeTotalSuccess
);

export const getFetchDashboardSummaryFinancialAllTimeTotalFailed = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryFinancialAllTimeTotalFailed }) => fetchDashboardSummaryFinancialAllTimeTotalFailed
);

export const getFetchReportSummaryFinancialTotalFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchReportSummaryFinancialTotalFilterPeriodType }) => fetchReportSummaryFinancialTotalFilterPeriodType
);

export const getFetchReportSummaryFinancialTotalFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchReportSummaryFinancialTotalFilterStartAt }) => fetchReportSummaryFinancialTotalFilterStartAt
);

export const getFetchReportSummaryFinancialTotalFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchReportSummaryFinancialTotalFilterEndAt }) => fetchReportSummaryFinancialTotalFilterEndAt
);

export const getFetchReportSummaryFinancialTotalFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchReportSummaryFinancialTotalFilterMarketId }) => fetchReportSummaryFinancialTotalFilterMarketId
);

export const getFetchReportSummaryFinancialTotalFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchReportSummaryFinancialTotalFilterBranchId }) => fetchReportSummaryFinancialTotalFilterBranchId
);

export const getFetchReportSummaryFinancialTotalFilterPaymentMethodKey = createSelector(
  [summarySelector],
  ({ fetchReportSummaryFinancialTotalFilterPaymentMethodKey }) => fetchReportSummaryFinancialTotalFilterPaymentMethodKey
);

export const getFetchReportSummaryFinancialTotalFilterCashierId = createSelector(
  [summarySelector],
  ({ fetchReportSummaryFinancialTotalFilterCashierId }) => fetchReportSummaryFinancialTotalFilterCashierId
);

export const getFetchReportSummaryFinancialTotalLoading = createSelector(
  [summarySelector],
  ({ fetchReportSummaryFinancialTotalLoading }) => fetchReportSummaryFinancialTotalLoading
);

export const getFetchReportSummaryFinancialTotalSuccess = createSelector(
  [summarySelector],
  ({ fetchReportSummaryFinancialTotalSuccess }) => fetchReportSummaryFinancialTotalSuccess
);

export const getFetchReportSummaryFinancialTotalFailed = createSelector(
  [summarySelector],
  ({ fetchReportSummaryFinancialTotalFailed }) => fetchReportSummaryFinancialTotalFailed
);

export const getFetchAccountSummaryFinancialTotalFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchAccountSummaryFinancialTotalFilterPeriodType }) => fetchAccountSummaryFinancialTotalFilterPeriodType
);

export const getFetchAccountSummaryFinancialTotalFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchAccountSummaryFinancialTotalFilterStartAt }) => fetchAccountSummaryFinancialTotalFilterStartAt
);

export const getFetchAccountSummaryFinancialTotalFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchAccountSummaryFinancialTotalFilterEndAt }) => fetchAccountSummaryFinancialTotalFilterEndAt
);

export const getFetchAccountSummaryFinancialTotalFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchAccountSummaryFinancialTotalFilterMarketId }) => fetchAccountSummaryFinancialTotalFilterMarketId
);

export const getFetchAccountSummaryFinancialTotalFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchAccountSummaryFinancialTotalFilterBranchId }) => fetchAccountSummaryFinancialTotalFilterBranchId
);

export const getFetchAccountSummaryFinancialTotalFilterPaymentMethodKey = createSelector(
  [summarySelector],
  ({ fetchAccountSummaryFinancialTotalFilterPaymentMethodKey }) =>
    fetchAccountSummaryFinancialTotalFilterPaymentMethodKey
);

export const getFetchAccountSummaryFinancialTotalFilterCashierId = createSelector(
  [summarySelector],
  ({ fetchAccountSummaryFinancialTotalFilterCashierId }) => fetchAccountSummaryFinancialTotalFilterCashierId
);

export const getFetchAccountSummaryFinancialTotalLoading = createSelector(
  [summarySelector],
  ({ fetchAccountSummaryFinancialTotalLoading }) => fetchAccountSummaryFinancialTotalLoading
);

export const getFetchAccountSummaryFinancialTotalSuccess = createSelector(
  [summarySelector],
  ({ fetchAccountSummaryFinancialTotalSuccess }) => fetchAccountSummaryFinancialTotalSuccess
);

export const getFetchAccountSummaryFinancialTotalFailed = createSelector(
  [summarySelector],
  ({ fetchAccountSummaryFinancialTotalFailed }) => fetchAccountSummaryFinancialTotalFailed
);

export const getFetchSummaryFinancialChartFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialChartFilterPeriodType }) => fetchSummaryFinancialChartFilterPeriodType
);

export const getFetchSummaryFinancialChartFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialChartFilterStartAt }) => fetchSummaryFinancialChartFilterStartAt
);

export const getFetchSummaryFinancialChartFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialChartFilterEndAt }) => fetchSummaryFinancialChartFilterEndAt
);

export const getFetchSummaryFinancialChartFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialChartFilterMarketId }) => fetchSummaryFinancialChartFilterMarketId
);

export const getFetchSummaryFinancialChartFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialChartFilterBranchId }) => fetchSummaryFinancialChartFilterBranchId
);

export const getFetchSummaryFinancialChartLoading = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialChartLoading }) => fetchSummaryFinancialChartLoading
);

export const getFetchSummaryFinancialChartSuccess = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialChartSuccess }) => fetchSummaryFinancialChartSuccess
);

export const getFetchSummaryFinancialChartFailed = createSelector(
  [summarySelector],
  ({ fetchSummaryFinancialChartFailed }) => fetchSummaryFinancialChartFailed
);

export const getFetchDashboardSummaryTopProductsPage = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTopProductsPage }) => fetchDashboardSummaryTopProductsPage
);

export const getFetchDashboardSummaryTopProductsPerPage = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTopProductsPerPage }) => fetchDashboardSummaryTopProductsPerPage
);

export const getFetchDashboardSummaryTopProductsFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTopProductsFilterPeriodType }) => fetchDashboardSummaryTopProductsFilterPeriodType
);

export const getFetchDashboardSummaryTopProductsFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTopProductsFilterStartAt }) => fetchDashboardSummaryTopProductsFilterStartAt
);

export const getFetchDashboardSummaryTopProductsFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTopProductsFilterEndAt }) => fetchDashboardSummaryTopProductsFilterEndAt
);

export const getFetchDashboardSummaryTopProductsFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTopProductsFilterMarketId }) => fetchDashboardSummaryTopProductsFilterMarketId
);

export const getFetchDashboardSummaryTopProductsFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTopProductsFilterBranchId }) => fetchDashboardSummaryTopProductsFilterBranchId
);

export const getFetchDashboardSummaryTopProductsFilterPaymentMethodKey = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTopProductsFilterPaymentMethodKey }) => fetchDashboardSummaryTopProductsFilterPaymentMethodKey
);

export const getFetchDashboardSummaryTopProductsFilterCashierId = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTopProductsFilterCashierId }) => fetchDashboardSummaryTopProductsFilterCashierId
);

export const getFetchDashboardSummaryTopProductsLoading = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTopProductsLoading }) => fetchDashboardSummaryTopProductsLoading
);

export const getFetchDashboardSummaryTopProductsSuccess = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTopProductsSuccess }) => fetchDashboardSummaryTopProductsSuccess
);

export const getFetchDashboardSummaryTopProductsFailed = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTopProductsFailed }) => fetchDashboardSummaryTopProductsFailed
);

export const getFetchReportSummaryTopProductsPage = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTopProductsPage }) => fetchReportSummaryTopProductsPage
);

export const getFetchReportSummaryTopProductsPerPage = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTopProductsPerPage }) => fetchReportSummaryTopProductsPerPage
);

export const getFetchReportSummaryTopProductsFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTopProductsFilterPeriodType }) => fetchReportSummaryTopProductsFilterPeriodType
);

export const getFetchReportSummaryTopProductsFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTopProductsFilterStartAt }) => fetchReportSummaryTopProductsFilterStartAt
);

export const getFetchReportSummaryTopProductsFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTopProductsFilterEndAt }) => fetchReportSummaryTopProductsFilterEndAt
);

export const getFetchReportSummaryTopProductsFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTopProductsFilterMarketId }) => fetchReportSummaryTopProductsFilterMarketId
);

export const getFetchReportSummaryTopProductsFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTopProductsFilterBranchId }) => fetchReportSummaryTopProductsFilterBranchId
);

export const getFetchReportSummaryTopProductsFilterPaymentMethodKey = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTopProductsFilterPaymentMethodKey }) => fetchReportSummaryTopProductsFilterPaymentMethodKey
);

export const getFetchReportSummaryTopProductsFilterCashierId = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTopProductsFilterCashierId }) => fetchReportSummaryTopProductsFilterCashierId
);

export const getFetchReportSummaryTopProductsLoading = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTopProductsLoading }) => fetchReportSummaryTopProductsLoading
);

export const getFetchReportSummaryTopProductsSuccess = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTopProductsSuccess }) => fetchReportSummaryTopProductsSuccess
);

export const getFetchReportSummaryTopProductsFailed = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTopProductsFailed }) => fetchReportSummaryTopProductsFailed
);

export const getFetchSummaryTopProductCategoriesPage = createSelector(
  [summarySelector],
  ({ fetchSummaryTopProductCategoriesPage }) => fetchSummaryTopProductCategoriesPage
);

export const getFetchSummaryTopProductCategoriesPerPage = createSelector(
  [summarySelector],
  ({ fetchSummaryTopProductCategoriesPerPage }) => fetchSummaryTopProductCategoriesPerPage
);

export const getFetchSummaryTopProductCategoriesFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchSummaryTopProductCategoriesFilterPeriodType }) => fetchSummaryTopProductCategoriesFilterPeriodType
);

export const getFetchSummaryTopProductCategoriesFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchSummaryTopProductCategoriesFilterStartAt }) => fetchSummaryTopProductCategoriesFilterStartAt
);

export const getFetchSummaryTopProductCategoriesFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchSummaryTopProductCategoriesFilterEndAt }) => fetchSummaryTopProductCategoriesFilterEndAt
);

export const getFetchSummaryTopProductCategoriesFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchSummaryTopProductCategoriesFilterMarketId }) => fetchSummaryTopProductCategoriesFilterMarketId
);

export const getFetchSummaryTopProductCategoriesFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchSummaryTopProductCategoriesFilterBranchId }) => fetchSummaryTopProductCategoriesFilterBranchId
);

export const getFetchSummaryTopProductCategoriesFilterPaymentMethodKey = createSelector(
  [summarySelector],
  ({ fetchSummaryTopProductCategoriesFilterPaymentMethodKey }) => fetchSummaryTopProductCategoriesFilterPaymentMethodKey
);

export const getFetchSummaryTopProductCategoriesFilterCashierId = createSelector(
  [summarySelector],
  ({ fetchSummaryTopProductCategoriesFilterCashierId }) => fetchSummaryTopProductCategoriesFilterCashierId
);

export const getFetchSummaryTopProductCategoriesLoading = createSelector(
  [summarySelector],
  ({ fetchSummaryTopProductCategoriesLoading }) => fetchSummaryTopProductCategoriesLoading
);

export const getFetchSummaryTopProductCategoriesSuccess = createSelector(
  [summarySelector],
  ({ fetchSummaryTopProductCategoriesSuccess }) => fetchSummaryTopProductCategoriesSuccess
);

export const getFetchSummaryTopProductCategoriesFailed = createSelector(
  [summarySelector],
  ({ fetchSummaryTopProductCategoriesFailed }) => fetchSummaryTopProductCategoriesFailed
);

export const getFetchSummaryPaymentMethodsPage = createSelector(
  [summarySelector],
  ({ fetchSummaryPaymentMethodsPage }) => fetchSummaryPaymentMethodsPage
);

export const getFetchSummaryPaymentMethodsPerPage = createSelector(
  [summarySelector],
  ({ fetchSummaryPaymentMethodsPerPage }) => fetchSummaryPaymentMethodsPerPage
);

export const getFetchSummaryPaymentMethodsFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchSummaryPaymentMethodsFilterPeriodType }) => fetchSummaryPaymentMethodsFilterPeriodType
);

export const getFetchSummaryPaymentMethodsFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchSummaryPaymentMethodsFilterStartAt }) => fetchSummaryPaymentMethodsFilterStartAt
);

export const getFetchSummaryPaymentMethodsFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchSummaryPaymentMethodsFilterEndAt }) => fetchSummaryPaymentMethodsFilterEndAt
);

export const getFetchSummaryPaymentMethodsFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchSummaryPaymentMethodsFilterMarketId }) => fetchSummaryPaymentMethodsFilterMarketId
);

export const getFetchSummaryPaymentMethodsFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchSummaryPaymentMethodsFilterBranchId }) => fetchSummaryPaymentMethodsFilterBranchId
);

export const getFetchSummaryPaymentMethodsFilterPaymentMethodKey = createSelector(
  [summarySelector],
  ({ fetchSummaryPaymentMethodsFilterPaymentMethodKey }) => fetchSummaryPaymentMethodsFilterPaymentMethodKey
);

export const getFetchSummaryPaymentMethodsFilterCashierId = createSelector(
  [summarySelector],
  ({ fetchSummaryPaymentMethodsFilterCashierId }) => fetchSummaryPaymentMethodsFilterCashierId
);

export const getFetchSummaryPaymentMethodsLoading = createSelector(
  [summarySelector],
  ({ fetchSummaryPaymentMethodsLoading }) => fetchSummaryPaymentMethodsLoading
);

export const getFetchSummaryPaymentMethodsSuccess = createSelector(
  [summarySelector],
  ({ fetchSummaryPaymentMethodsSuccess }) => fetchSummaryPaymentMethodsSuccess
);

export const getFetchSummaryPaymentMethodsFailed = createSelector(
  [summarySelector],
  ({ fetchSummaryPaymentMethodsFailed }) => fetchSummaryPaymentMethodsFailed
);

export const getFetchSummarySubscribersPage = createSelector(
  [summarySelector],
  ({ fetchSummarySubscribersPage }) => fetchSummarySubscribersPage
);

export const getFetchSummarySubscribersPerPage = createSelector(
  [summarySelector],
  ({ fetchSummarySubscribersPerPage }) => fetchSummarySubscribersPerPage
);

export const getFetchSummarySubscribersFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchSummarySubscribersFilterPeriodType }) => fetchSummarySubscribersFilterPeriodType
);

export const getFetchSummarySubscribersFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchSummarySubscribersFilterStartAt }) => fetchSummarySubscribersFilterStartAt
);

export const getFetchSummarySubscribersFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchSummarySubscribersFilterEndAt }) => fetchSummarySubscribersFilterEndAt
);

export const getFetchSummarySubscribersFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchSummarySubscribersFilterMarketId }) => fetchSummarySubscribersFilterMarketId
);

export const getFetchSummarySubscribersFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchSummarySubscribersFilterBranchId }) => fetchSummarySubscribersFilterBranchId
);

export const getFetchSummarySubscribersFilterPaymentMethodKey = createSelector(
  [summarySelector],
  ({ fetchSummarySubscribersFilterPaymentMethodKey }) => fetchSummarySubscribersFilterPaymentMethodKey
);

export const getFetchSummarySubscribersFilterCashierId = createSelector(
  [summarySelector],
  ({ fetchSummarySubscribersFilterCashierId }) => fetchSummarySubscribersFilterCashierId
);

export const getFetchSummarySubscribersLoading = createSelector(
  [summarySelector],
  ({ fetchSummarySubscribersLoading }) => fetchSummarySubscribersLoading
);

export const getFetchSummarySubscribersSuccess = createSelector(
  [summarySelector],
  ({ fetchSummarySubscribersSuccess }) => fetchSummarySubscribersSuccess
);

export const getFetchSummarySubscribersFailed = createSelector(
  [summarySelector],
  ({ fetchSummarySubscribersFailed }) => fetchSummarySubscribersFailed
);

export const getFetchSummaryBranchesPage = createSelector(
  [summarySelector],
  ({ fetchSummaryBranchesPage }) => fetchSummaryBranchesPage
);

export const getFetchSummaryBranchesPerPage = createSelector(
  [summarySelector],
  ({ fetchSummaryBranchesPerPage }) => fetchSummaryBranchesPerPage
);

export const getFetchSummaryBranchesFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchSummaryBranchesFilterPeriodType }) => fetchSummaryBranchesFilterPeriodType
);

export const getFetchSummaryBranchesFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchSummaryBranchesFilterStartAt }) => fetchSummaryBranchesFilterStartAt
);

export const getFetchSummaryBranchesFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchSummaryBranchesFilterEndAt }) => fetchSummaryBranchesFilterEndAt
);

export const getFetchSummaryBranchesFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchSummaryBranchesFilterMarketId }) => fetchSummaryBranchesFilterMarketId
);

export const getFetchSummaryBranchesFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchSummaryBranchesFilterBranchId }) => fetchSummaryBranchesFilterBranchId
);

export const getFetchSummaryBranchesFilterPaymentMethodKey = createSelector(
  [summarySelector],
  ({ fetchSummaryBranchesFilterPaymentMethodKey }) => fetchSummaryBranchesFilterPaymentMethodKey
);

export const getFetchSummaryBranchesFilterCashierId = createSelector(
  [summarySelector],
  ({ fetchSummaryBranchesFilterCashierId }) => fetchSummaryBranchesFilterCashierId
);

export const getFetchSummaryBranchesLoading = createSelector(
  [summarySelector],
  ({ fetchSummaryBranchesLoading }) => fetchSummaryBranchesLoading
);

export const getFetchSummaryBranchesSuccess = createSelector(
  [summarySelector],
  ({ fetchSummaryBranchesSuccess }) => fetchSummaryBranchesSuccess
);

export const getFetchSummaryBranchesFailed = createSelector(
  [summarySelector],
  ({ fetchSummaryBranchesFailed }) => fetchSummaryBranchesFailed
);

export const getFetchSummaryLatestOrdersLimit = createSelector(
  [summarySelector],
  ({ fetchSummaryLatestOrdersLimit }) => fetchSummaryLatestOrdersLimit
);

export const getFetchSummaryLatestOrdersIncludes = createSelector(
  [summarySelector],
  ({ fetchSummaryLatestOrdersIncludes }) => fetchSummaryLatestOrdersIncludes
);

export const getFetchSummaryLatestOrdersFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchSummaryLatestOrdersFilterPeriodType }) => fetchSummaryLatestOrdersFilterPeriodType
);

export const getFetchSummaryLatestOrdersFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchSummaryLatestOrdersFilterStartAt }) => fetchSummaryLatestOrdersFilterStartAt
);

export const getFetchSummaryLatestOrdersFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchSummaryLatestOrdersFilterEndAt }) => fetchSummaryLatestOrdersFilterEndAt
);

export const getFetchSummaryLatestOrdersFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchSummaryLatestOrdersFilterMarketId }) => fetchSummaryLatestOrdersFilterMarketId
);

export const getFetchSummaryLatestOrdersFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchSummaryLatestOrdersFilterBranchId }) => fetchSummaryLatestOrdersFilterBranchId
);

export const getFetchSummaryLatestOrdersFilterPaymentMethodKey = createSelector(
  [summarySelector],
  ({ fetchSummaryLatestOrdersFilterPaymentMethodKey }) => fetchSummaryLatestOrdersFilterPaymentMethodKey
);

export const getFetchSummaryLatestOrdersFilterCashierId = createSelector(
  [summarySelector],
  ({ fetchSummaryLatestOrdersFilterCashierId }) => fetchSummaryLatestOrdersFilterCashierId
);

export const getFetchSummaryLatestOrdersLoading = createSelector(
  [summarySelector],
  ({ fetchSummaryLatestOrdersLoading }) => fetchSummaryLatestOrdersLoading
);

export const getFetchSummaryLatestOrdersSuccess = createSelector(
  [summarySelector],
  ({ fetchSummaryLatestOrdersSuccess }) => fetchSummaryLatestOrdersSuccess
);

export const getFetchSummaryLatestOrdersFailed = createSelector(
  [summarySelector],
  ({ fetchSummaryLatestOrdersFailed }) => fetchSummaryLatestOrdersFailed
);

export const getFetchSummarySubscriberReportFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportFilterPeriodType }) => fetchSummarySubscriberReportFilterPeriodType
);

export const getFetchSummarySubscriberReportFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportFilterStartAt }) => fetchSummarySubscriberReportFilterStartAt
);

export const getFetchSummarySubscriberReportFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportFilterEndAt }) => fetchSummarySubscriberReportFilterEndAt
);

export const getFetchSummarySubscriberReportFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportFilterMarketId }) => fetchSummarySubscriberReportFilterMarketId
);

export const getFetchSummarySubscriberReportFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportFilterBranchId }) => fetchSummarySubscriberReportFilterBranchId
);

export const getFetchSummarySubscriberReportFilterCashierId = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportFilterCashierId }) => fetchSummarySubscriberReportFilterCashierId
);

export const getFetchSummarySubscriberReportLoading = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportLoading }) => fetchSummarySubscriberReportLoading
);

export const getFetchSummarySubscriberReportSuccess = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportSuccess }) => fetchSummarySubscriberReportSuccess
);

export const getFetchSummarySubscriberReportFailed = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportFailed }) => fetchSummarySubscriberReportFailed
);

export const getFetchSummarySubscriberReportBranchFilterPeriodType = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportBranchFilterPeriodType }) => fetchSummarySubscriberReportBranchFilterPeriodType
);

export const getFetchSummarySubscriberReportBranchFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportBranchFilterStartAt }) => fetchSummarySubscriberReportBranchFilterStartAt
);

export const getFetchSummarySubscriberReportBranchFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportBranchFilterEndAt }) => fetchSummarySubscriberReportBranchFilterEndAt
);

export const getFetchSummarySubscriberReportBranchFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportBranchFilterMarketId }) => fetchSummarySubscriberReportBranchFilterMarketId
);

export const getFetchSummarySubscriberReportBranchFilterBranchId = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportBranchFilterBranchId }) => fetchSummarySubscriberReportBranchFilterBranchId
);

export const getFetchSummarySubscriberReportBranchLoading = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportBranchLoading }) => fetchSummarySubscriberReportBranchLoading
);

export const getFetchSummarySubscriberReportBranchSuccess = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportBranchSuccess }) => fetchSummarySubscriberReportBranchSuccess
);

export const getFetchSummarySubscriberReportBranchFailed = createSelector(
  [summarySelector],
  ({ fetchSummarySubscriberReportBranchFailed }) => fetchSummarySubscriberReportBranchFailed
);

export const getFetchDashboardSummaryTotalUserRoles = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalUserRoles }) => fetchDashboardSummaryTotalUserRoles
);

export const getFetchDashboardSummaryTotalUserFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalUserFilterStartAt }) => fetchDashboardSummaryTotalUserFilterStartAt
);

export const getFetchDashboardSummaryTotalUserFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalUserFilterEndAt }) => fetchDashboardSummaryTotalUserFilterEndAt
);

export const getFetchDashboardSummaryTotalUserFilterPhonePrefix = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalUserFilterPhonePrefix }) => fetchDashboardSummaryTotalUserFilterPhonePrefix
);

export const getFetchDashboardSummaryTotalUserFilterIsActive = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalUserFilterIsActive }) => fetchDashboardSummaryTotalUserFilterIsActive
);

export const getFetchDashboardSummaryTotalUserLoading = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalUserLoading }) => fetchDashboardSummaryTotalUserLoading
);

export const getFetchDashboardSummaryTotalUserSuccess = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalUserSuccess }) => fetchDashboardSummaryTotalUserSuccess
);

export const getFetchDashboardSummaryTotalUserFailed = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalUserFailed }) => fetchDashboardSummaryTotalUserFailed
);

export const getFetchDashboardSummaryTotalMarketFilterSectorId = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalMarketFilterSectorId }) => fetchDashboardSummaryTotalMarketFilterSectorId
);

export const getFetchDashboardSummaryTotalMarketFilterPhonePrefix = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalMarketFilterPhonePrefix }) => fetchDashboardSummaryTotalMarketFilterPhonePrefix
);

export const getFetchDashboardSummaryTotalMarketLoading = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalMarketLoading }) => fetchDashboardSummaryTotalMarketLoading
);

export const getFetchDashboardSummaryTotalMarketSuccess = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalMarketSuccess }) => fetchDashboardSummaryTotalMarketSuccess
);

export const getFetchDashboardSummaryTotalMarketFailed = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalMarketFailed }) => fetchDashboardSummaryTotalMarketFailed
);

export const getFetchDashboardSummaryTotalBranchFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalBranchFilterMarketId }) => fetchDashboardSummaryTotalBranchFilterMarketId
);

export const getFetchDashboardSummaryTotalBranchFilterIsActive = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalBranchFilterIsActive }) => fetchDashboardSummaryTotalBranchFilterIsActive
);

export const getFetchDashboardSummaryTotalBranchLoading = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalBranchLoading }) => fetchDashboardSummaryTotalBranchLoading
);

export const getFetchDashboardSummaryTotalBranchSuccess = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalBranchSuccess }) => fetchDashboardSummaryTotalBranchSuccess
);

export const getFetchDashboardSummaryTotalBranchFailed = createSelector(
  [summarySelector],
  ({ fetchDashboardSummaryTotalBranchFailed }) => fetchDashboardSummaryTotalBranchFailed
);

export const getFetchReportSummaryTotalUserRoles = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalUserRoles }) => fetchReportSummaryTotalUserRoles
);

export const getFetchReportSummaryTotalUserFilterStartAt = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalUserFilterStartAt }) => fetchReportSummaryTotalUserFilterStartAt
);

export const getFetchReportSummaryTotalUserFilterEndAt = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalUserFilterEndAt }) => fetchReportSummaryTotalUserFilterEndAt
);

export const getFetchReportSummaryTotalUserFilterPhonePrefix = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalUserFilterPhonePrefix }) => fetchReportSummaryTotalUserFilterPhonePrefix
);

export const getFetchReportSummaryTotalUserFilterIsActive = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalUserFilterIsActive }) => fetchReportSummaryTotalUserFilterIsActive
);

export const getFetchReportSummaryTotalUserLoading = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalUserLoading }) => fetchReportSummaryTotalUserLoading
);

export const getFetchReportSummaryTotalUserSuccess = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalUserSuccess }) => fetchReportSummaryTotalUserSuccess
);

export const getFetchReportSummaryTotalUserFailed = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalUserFailed }) => fetchReportSummaryTotalUserFailed
);

export const getFetchReportSummaryTotalMarketFilterSectorId = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalMarketFilterSectorId }) => fetchReportSummaryTotalMarketFilterSectorId
);

export const getFetchReportSummaryTotalMarketFilterPhonePrefix = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalMarketFilterPhonePrefix }) => fetchReportSummaryTotalMarketFilterPhonePrefix
);

export const getFetchReportSummaryTotalMarketLoading = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalMarketLoading }) => fetchReportSummaryTotalMarketLoading
);

export const getFetchReportSummaryTotalMarketSuccess = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalMarketSuccess }) => fetchReportSummaryTotalMarketSuccess
);

export const getFetchReportSummaryTotalMarketFailed = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalMarketFailed }) => fetchReportSummaryTotalMarketFailed
);

export const getFetchReportSummaryTotalBranchFilterMarketId = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalBranchFilterMarketId }) => fetchReportSummaryTotalBranchFilterMarketId
);

export const getFetchReportSummaryTotalBranchFilterIsActive = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalBranchFilterIsActive }) => fetchReportSummaryTotalBranchFilterIsActive
);

export const getFetchReportSummaryTotalBranchLoading = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalBranchLoading }) => fetchReportSummaryTotalBranchLoading
);

export const getFetchReportSummaryTotalBranchSuccess = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalBranchSuccess }) => fetchReportSummaryTotalBranchSuccess
);

export const getFetchReportSummaryTotalBranchFailed = createSelector(
  [summarySelector],
  ({ fetchReportSummaryTotalBranchFailed }) => fetchReportSummaryTotalBranchFailed
);
