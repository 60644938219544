import { createSelector } from "reselect";

const branchSelector = ({ branch }) => branch;

export const getIsModalAutoClose = createSelector(
  [branchSelector],
  ({ isModalAutoClose }) => isModalAutoClose
);

export const getBranches = createSelector(
  [branchSelector],
  ({ branches }) => branches
);
export const getBranch = createSelector(
  [branchSelector],
  ({ branch }) => branch
);
export const getSubscriptionBranches = createSelector(
  [branchSelector],
  ({ subscriptionBranches }) => subscriptionBranches
);
export const getSubscriptionBranch = createSelector(
  [branchSelector],
  ({ subscriptionBranch }) => subscriptionBranch
);

export const getIsBranchesHasMore = createSelector(
  [branchSelector],
  ({ isBranchesHasMore }) => isBranchesHasMore
);
export const getIsSubscriptionBranchesHasMore = createSelector(
  [branchSelector],
  ({ isSubscriptionBranchesHasMore }) => isSubscriptionBranchesHasMore
);

export const getFetchBranchesSearch = createSelector(
  [branchSelector],
  ({ fetchBranchesSearch }) => fetchBranchesSearch
);
export const getFetchBranchesPage = createSelector(
  [branchSelector],
  ({ fetchBranchesPage }) => fetchBranchesPage
);
export const getFetchBranchesPerPage = createSelector(
  [branchSelector],
  ({ fetchBranchesPerPage }) => fetchBranchesPerPage
);
export const getFetchBranchesIncludes = createSelector(
  [branchSelector],
  ({ fetchBranchesIncludes }) => fetchBranchesIncludes
);
export const getFetchBranchesFilterMarketId = createSelector(
  [branchSelector],
  ({ fetchBranchesFilterMarketId }) => fetchBranchesFilterMarketId
);
export const getFetchBranchesFilterIsActive = createSelector(
  [branchSelector],
  ({ fetchBranchesFilterIsActive }) => fetchBranchesFilterIsActive
);
export const getFetchBranchesLoading = createSelector(
  [branchSelector],
  ({ fetchBranchesLoading }) => fetchBranchesLoading
);
export const getFetchBranchesSuccess = createSelector(
  [branchSelector],
  ({ fetchBranchesSuccess }) => fetchBranchesSuccess
);
export const getFetchBranchesFailed = createSelector(
  [branchSelector],
  ({ fetchBranchesFailed }) => fetchBranchesFailed
);

export const getFetchBranchLoading = createSelector(
  [branchSelector],
  ({ fetchBranchLoading }) => fetchBranchLoading
);
export const getFetchBranchSuccess = createSelector(
  [branchSelector],
  ({ fetchBranchSuccess }) => fetchBranchSuccess
);
export const getFetchBranchFailed = createSelector(
  [branchSelector],
  ({ fetchBranchFailed }) => fetchBranchFailed
);

export const getFetchSubscriptionBranchesPage = createSelector(
  [branchSelector],
  ({ fetchSubscriptionBranchesPage }) => fetchSubscriptionBranchesPage
);
export const getFetchSubscriptionBranchesPerPage = createSelector(
  [branchSelector],
  ({ fetchSubscriptionBranchesPerPage }) => fetchSubscriptionBranchesPerPage
);
export const getFetchSubscriptionBranchesIncludes = createSelector(
  [branchSelector],
  ({ fetchSubscriptionBranchesIncludes }) => fetchSubscriptionBranchesIncludes
);
export const getFetchSubscriptionBranchesFilterMarketId = createSelector(
  [branchSelector],
  ({ fetchSubscriptionBranchesFilterMarketId }) =>
    fetchSubscriptionBranchesFilterMarketId
);
export const getFetchSubscriptionBranchesFilterIsActive = createSelector(
  [branchSelector],
  ({ fetchSubscriptionBranchesFilterIsActive }) =>
    fetchSubscriptionBranchesFilterIsActive
);
export const getFetchSubscriptionBranchesLoading = createSelector(
  [branchSelector],
  ({ fetchSubscriptionBranchesLoading }) => fetchSubscriptionBranchesLoading
);
export const getFetchSubscriptionBranchesSuccess = createSelector(
  [branchSelector],
  ({ fetchSubscriptionBranchesSuccess }) => fetchSubscriptionBranchesSuccess
);
export const getFetchSubscriptionBranchesFailed = createSelector(
  [branchSelector],
  ({ fetchSubscriptionBranchesFailed }) => fetchSubscriptionBranchesFailed
);

export const getFetchSubscriptionBranchLoading = createSelector(
  [branchSelector],
  ({ fetchSubscriptionBranchLoading }) => fetchSubscriptionBranchLoading
);
export const getFetchSubscriptionBranchSuccess = createSelector(
  [branchSelector],
  ({ fetchSubscriptionBranchSuccess }) => fetchSubscriptionBranchSuccess
);
export const getFetchSubscriptionBranchFailed = createSelector(
  [branchSelector],
  ({ fetchSubscriptionBranchFailed }) => fetchSubscriptionBranchFailed
);

export const getCreateBranchLoading = createSelector(
  [branchSelector],
  ({ createBranchLoading }) => createBranchLoading
);
export const getCreateBranchSuccess = createSelector(
  [branchSelector],
  ({ createBranchSuccess }) => createBranchSuccess
);
export const getCreateBranchFailed = createSelector(
  [branchSelector],
  ({ createBranchFailed }) => createBranchFailed
);

export const getUpdateBranchLoading = createSelector(
  [branchSelector],
  ({ updateBranchLoading }) => updateBranchLoading
);
export const getUpdateBranchSuccess = createSelector(
  [branchSelector],
  ({ updateBranchSuccess }) => updateBranchSuccess
);
export const getUpdateBranchFailed = createSelector(
  [branchSelector],
  ({ updateBranchFailed }) => updateBranchFailed
);

export const getDeleteBranchLoading = createSelector(
  [branchSelector],
  ({ deleteBranchLoading }) => deleteBranchLoading
);
export const getDeleteBranchSuccess = createSelector(
  [branchSelector],
  ({ deleteBranchSuccess }) => deleteBranchSuccess
);
export const getDeleteBranchFailed = createSelector(
  [branchSelector],
  ({ deleteBranchFailed }) => deleteBranchFailed
);

export const getIsFetchBranchesHitted = createSelector(
  [branchSelector],
  ({ isFetchBranchesHitted }) => isFetchBranchesHitted
);
export const getIsFetchBranchHitted = createSelector(
  [branchSelector],
  ({ isFetchBranchHitted }) => isFetchBranchHitted
);
export const getIsFetchSubscriptionBranchesHitted = createSelector(
  [branchSelector],
  ({ isFetchSubscriptionBranchesHitted }) => isFetchSubscriptionBranchesHitted
);
export const getIsFetchSubscriptionBranchHitted = createSelector(
  [branchSelector],
  ({ isFetchSubscriptionBranchHitted }) => isFetchSubscriptionBranchHitted
);
export const getIsCreateBranchHitted = createSelector(
  [branchSelector],
  ({ isCreateBranchHitted }) => isCreateBranchHitted
);
export const getIsUpdateBranchHitted = createSelector(
  [branchSelector],
  ({ isUpdateBranchHitted }) => isUpdateBranchHitted
);
export const getIsDeleteBranchHitted = createSelector(
  [branchSelector],
  ({ isDeleteBranchHitted }) => isDeleteBranchHitted
);
