import styled from "styled-components";

export const AuthenticationLayoutContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  width: 100vw;
  min-height: 100vh;
  padding: 2.5rem 2rem;
  background-color: white;
`;

export const AuthenticationLayoutContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 4rem;

  width: 100%;
  max-width: 1200px;

  @media (max-width: 1200px) {
    column-gap: 3rem;
  }
`;

export const AuthenticationLayoutMain = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  max-width: 780px;
`;

export const AuthenticationLayoutCarousel = styled.div`
  /* position: sticky;
  top: 2rem; */

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 992px) {
    display: none;
  }
`;
