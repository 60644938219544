import PERMISSION_ACTION_TYPES from "./permission.type";
import { createAction } from "../../utils/store.utils";

export const setPermissions = (permissions) =>
  createAction(PERMISSION_ACTION_TYPES.SET_PERMISSIONS, permissions);
export const setSelectPermissions = (selectPermissions) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_SELECT_PERMISSIONS,
    selectPermissions
  );
export const setPermission = (permission) =>
  createAction(PERMISSION_ACTION_TYPES.SET_PERMISSION, permission);

export const setIsPermissionsHasMore = (isPermissionsHasMore) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_IS_PERMISSIONS_HAS_MORE,
    isPermissionsHasMore
  );
export const setIsSelectPermissionsHasMore = (isSelectPermissionsHasMore) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_IS_SELECT_PERMISSIONS_HAS_MORE,
    isSelectPermissionsHasMore
  );

export const setFetchPermissionsSearch = (fetchPermissionsSearch) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_PERMISSIONS_SEARCH,
    fetchPermissionsSearch
  );
export const setFetchPermissionsPage = (fetchPermissionsPage) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_PERMISSIONS_PAGE,
    fetchPermissionsPage
  );
export const setFetchPermissionsPerPage = (fetchPermissionsPerPage) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_PERMISSIONS_PER_PAGE,
    fetchPermissionsPerPage
  );
export const setFetchPermissionsIncludes = (fetchPermissionsIncludes) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_PERMISSIONS_INCLUDES,
    fetchPermissionsIncludes
  );
export const setFetchPermissionsFilterMarketId = (
  fetchPermissionsFilterMarketId
) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_PERMISSIONS_FILTER_MARKET_ID,
    fetchPermissionsFilterMarketId
  );
export const setFetchPermissionsLoading = (fetchPermissionsLoading) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_PERMISSIONS_LOADING,
    fetchPermissionsLoading
  );
export const setFetchPermissionsSuccess = (fetchPermissionsSuccess) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_PERMISSIONS_SUCCESS,
    fetchPermissionsSuccess
  );
export const setFetchPermissionsFailed = (fetchPermissionsFailed) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_PERMISSIONS_FAILED,
    fetchPermissionsFailed
  );

export const setFetchSelectPermissionsPage = (fetchSelectPermissionsPage) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_PAGE,
    fetchSelectPermissionsPage
  );
export const setFetchSelectPermissionsPerPage = (
  fetchSelectPermissionsPerPage
) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_PER_PAGE,
    fetchSelectPermissionsPerPage
  );
export const setFetchSelectPermissionsIncludes = (
  fetchSelectPermissionsIncludes
) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_INCLUDES,
    fetchSelectPermissionsIncludes
  );
export const setFetchSelectPermissionsFilterMarketId = (
  fetchSelectPermissionsFilterMarketId
) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_FILTER_MARKET_ID,
    fetchSelectPermissionsFilterMarketId
  );
export const setFetchSelectPermissionsLoading = (
  fetchSelectPermissionsLoading
) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_LOADING,
    fetchSelectPermissionsLoading
  );
export const setFetchSelectPermissionsSuccess = (
  fetchSelectPermissionsSuccess
) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_SUCCESS,
    fetchSelectPermissionsSuccess
  );
export const setFetchSelectPermissionsFailed = (fetchSelectPermissionsFailed) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_SELECT_PERMISSIONS_FAILED,
    fetchSelectPermissionsFailed
  );

export const setFetchPermissionLoading = (fetchPermissionLoading) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_PERMISSION_LOADING,
    fetchPermissionLoading
  );
export const setFetchPermissionSuccess = (fetchPermissionSuccess) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_PERMISSION_SUCCESS,
    fetchPermissionSuccess
  );
export const setFetchPermissionFailed = (fetchPermissionFailed) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_FETCH_PERMISSION_FAILED,
    fetchPermissionFailed
  );

export const setCreatePermissionLoading = (createPermissionLoading) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_CREATE_PERMISSION_LOADING,
    createPermissionLoading
  );
export const setCreatePermissionSuccess = (createPermissionSuccess) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_CREATE_PERMISSION_SUCCESS,
    createPermissionSuccess
  );
export const setCreatePermissionFailed = (createPermissionFailed) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_CREATE_PERMISSION_FAILED,
    createPermissionFailed
  );

export const setUpdatePermissionLoading = (updatePermissionLoading) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_UPDATE_PERMISSION_LOADING,
    updatePermissionLoading
  );
export const setUpdatePermissionSuccess = (updatePermissionSuccess) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_UPDATE_PERMISSION_SUCCESS,
    updatePermissionSuccess
  );
export const setUpdatePermissionFailed = (updatePermissionFailed) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_UPDATE_PERMISSION_FAILED,
    updatePermissionFailed
  );

export const setDeletePermissionLoading = (deletePermissionLoading) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_DELETE_PERMISSION_LOADING,
    deletePermissionLoading
  );
export const setDeletePermissionSuccess = (deletePermissionSuccess) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_DELETE_PERMISSION_SUCCESS,
    deletePermissionSuccess
  );
export const setDeletePermissionFailed = (deletePermissionFailed) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_DELETE_PERMISSION_FAILED,
    deletePermissionFailed
  );

export const appendPermissions = (permissions) =>
  createAction(PERMISSION_ACTION_TYPES.APPEND_PERMISSIONS, permissions);
export const appendSelectPermissions = (selectPermissions) =>
  createAction(
    PERMISSION_ACTION_TYPES.APPEND_SELECT_PERMISSIONS,
    selectPermissions
  );

export const fetchPermissionsStart = () =>
  createAction(PERMISSION_ACTION_TYPES.FETCH_PERMISSIONS_START);
export const fetchSelectPermissionsStart = () =>
  createAction(PERMISSION_ACTION_TYPES.FETCH_SELECT_PERMISSIONS_START);
export const fetchPermissionStart = (permissionId) =>
  createAction(PERMISSION_ACTION_TYPES.FETCH_PERMISSION_START, permissionId);
export const createPermissionStart = (request) =>
  createAction(PERMISSION_ACTION_TYPES.CREATE_PERMISSION_START, request);
export const updatePermissionStart = (permissionId, request) =>
  createAction(PERMISSION_ACTION_TYPES.UPDATE_PERMISSION_START, {
    permissionId,
    request,
  });
export const deletePermissionStart = (permissionId) =>
  createAction(PERMISSION_ACTION_TYPES.DELETE_PERMISSION_START, permissionId);

export const setIsFetchPermissionsHitted = (isFetchPermissionsHitted) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_IS_FETCH_PERMISSIONS_HITTED,
    isFetchPermissionsHitted
  );
export const setIsFetchSelectPermissionsHitted = (
  isFetchSelectPermissionsHitted
) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_IS_FETCH_SELECT_PERMISSIONS_HITTED,
    isFetchSelectPermissionsHitted
  );
export const setIsFetchPermissionHitted = (isFetchPermissionHitted) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_IS_FETCH_PERMISSION_HITTED,
    isFetchPermissionHitted
  );
export const setIsCreatePermissionHitted = (isCreatePermissionHitted) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_IS_CREATE_PERMISSION_HITTED,
    isCreatePermissionHitted
  );
export const setIsUpdatePermissionHitted = (isUpdatePermissionHitted) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_IS_UPDATE_PERMISSION_HITTED,
    isUpdatePermissionHitted
  );
export const setIsDeletePermissionHitted = (isDeletePermissionHitted) =>
  createAction(
    PERMISSION_ACTION_TYPES.SET_IS_DELETE_PERMISSION_HITTED,
    isDeletePermissionHitted
  );

export const resetPermissionReducer = () =>
  createAction(PERMISSION_ACTION_TYPES.RESET_PERMISSION_REDUCER);
