import EXPORT_ACTION_TYPES from "./export.type";

export const EXPORT_INITIAL_STATE = {
  reportOrderFileUrl: null,
  reportOrderFileName: null,
  reportSummaryUrlFile: null,
  reportSummaryFileName: null,
  customerReportUrlFile: null,
  customerReportFileName: null,
  ingredientReportUrlFile: null,
  ingredientReportFileName: null,
  ingredientHistoryReportUrlFile: null,
  ingredientHistoryReportFileName: null,
  tableQrCodeUrlFile: null,
  tableQrCodeFileName: null,
  productBarcodeUrlFile: null,
  productBarcodeFileName: null,
  subscriptionReceiptUrlFile: null,
  subscriptionReceiptFileName: null,

  exportReportOrderFilterMarketId: null,
  exportReportOrderFilterBranchId: null,
  exportReportOrderFilterPaymentMethodKey: null,
  exportReportOrderFilterCashierId: null,
  exportReportOrderFilterCustomerId: null,
  exportReportOrderFilterCreatedAtBefore: null,
  exportReportOrderFilterCreatedAtAfter: null,
  exportReportOrderLoading: false,
  exportReportOrderSuccess: null,
  exportReportOrderFailed: null,

  exportReportSummaryFilterMarketId: null,
  exportReportSummaryFilterBranchId: null,
  exportReportSummaryFilterPaymentMethodKey: null,
  exportReportSummaryFilterCashierId: null,
  exportReportSummaryFilterCustomerId: null,
  exportReportSummaryFilterCreatedAtBefore: null,
  exportReportSummaryFilterCreatedAtAfter: null,
  exportReportSummaryLoading: false,
  exportReportSummarySuccess: null,
  exportReportSummaryFailed: null,

  exportCustomerReportFilterMarketId: null,
  exportCustomerReportFilterCustomersIds: null,
  exportCustomerReportLoading: false,
  exportCustomerReportSuccess: null,
  exportCustomerReportFailed: null,

  exportIngredientReportFilterPeriodType: null,
  exportIngredientReportFilterStartAt: null,
  exportIngredientReportFilterEndAt: null,
  exportIngredientReportFilterMarketId: null,
  exportIngredientReportFilterBranchId: null,
  exportIngredientReportFilterIngredientsIds: null,
  exportIngredientReportLoading: false,
  exportIngredientReportSuccess: null,
  exportIngredientReportFailed: null,

  exportIngredientHistoryReportFilterPeriodType: null,
  exportIngredientHistoryReportFilterStartAt: null,
  exportIngredientHistoryReportFilterEndAt: null,
  exportIngredientHistoryReportFilterMarketId: null,
  exportIngredientHistoryReportFilterBranchId: null,
  exportIngredientHistoryReportFilterIngredientsIds: null,
  exportIngredientHistoryReportLoading: false,
  exportIngredientHistoryReportSuccess: null,
  exportIngredientHistoryReportFailed: null,

  exportTableQrCodeFilterMarketId: null,
  exportTableQrCodeFilterBranchId: null,
  exportTableQrCodeFilterTablesIds: null,
  exportTableQrCodeLoading: false,
  exportTableQrCodeSuccess: null,
  exportTableQrCodeFailed: null,

  exportProductBarcodeFilterMarketId: null,
  exportProductBarcodeFilterBranchId: null,
  exportProductBarcodeFilterProductsIds: null,
  exportProductBarcodeLoading: false,
  exportProductBarcodeSuccess: null,
  exportProductBarcodeFailed: null,

  exportSubscriptionReceiptLoading: false,
  exportSubscriptionReceiptSuccess: null,
  exportSubscriptionReceiptFailed: null,

  isExportReportOrderHitted: false,
  isExportReportSummaryHitted: false,
  isExportCustomerReportHitted: false,
  isExportIngredientReportHitted: false,
  isExportIngredientHistoryReportHitted: false,
  isExportTableQrCodeHitted: false,
  isExportProductBarcodeHitted: false,
  isExportSubscriptionReceiptHitted: false,
};

export const exportReducer = (state = EXPORT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case EXPORT_ACTION_TYPES.SET_REPORT_ORDER_FILE_URL:
      return { ...state, reportOrderFileUrl: payload };
    case EXPORT_ACTION_TYPES.SET_REPORT_ORDER_FILE_NAME:
      return { ...state, reportOrderFileName: payload };
    case EXPORT_ACTION_TYPES.SET_REPORT_SUMMARY_FILE_URL:
      return { ...state, reportSummaryFileUrl: payload };
    case EXPORT_ACTION_TYPES.SET_REPORT_SUMMARY_FILE_NAME:
      return { ...state, reportSummaryFileName: payload };
    case EXPORT_ACTION_TYPES.SET_CUSTOMER_REPORT_URL_FILE:
      return { ...state, customerReportUrlFile: payload };
    case EXPORT_ACTION_TYPES.SET_CUSTOMER_REPORT_FILE_NAME:
      return { ...state, customerReportFileName: payload };
    case EXPORT_ACTION_TYPES.SET_INGREDIENT_REPORT_URL_FILE:
      return { ...state, ingredientReportUrlFile: payload };
    case EXPORT_ACTION_TYPES.SET_INGREDIENT_REPORT_FILE_NAME:
      return { ...state, ingredientReportFileName: payload };
    case EXPORT_ACTION_TYPES.SET_INGREDIENT_HISTORY_REPORT_URL_FILE:
      return { ...state, ingredientHistoryReportUrlFile: payload };
    case EXPORT_ACTION_TYPES.SET_INGREDIENT_HISTORY_REPORT_FILE_NAME:
      return { ...state, ingredientHistoryReportFileName: payload };
    case EXPORT_ACTION_TYPES.SET_TABLE_QRCODE_URL_FILE:
      return { ...state, tableQrCodeUrlFile: payload };
    case EXPORT_ACTION_TYPES.SET_TABLE_QRCODE_FILE_NAME:
      return { ...state, tableQrCodeFileName: payload };
    case EXPORT_ACTION_TYPES.SET_PRODUCT_BARCODE_URL_FILE:
      return { ...state, productBarcodeUrlFile: payload };
    case EXPORT_ACTION_TYPES.SET_PRODUCT_BARCODE_FILE_NAME:
      return { ...state, productBarcodeFileName: payload };
    case EXPORT_ACTION_TYPES.SET_SUBSCRIPTION_RECEIPT_URL_FILE:
      return { ...state, subscriptionReceiptUrlFile: payload };
    case EXPORT_ACTION_TYPES.SET_SUBSCRIPTION_RECEIPT_FILE_NAME:
      return { ...state, subscriptionReceiptFileName: payload };

    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_MARKET_ID:
      return { ...state, exportReportOrderFilterMarketId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_BRANCH_ID:
      return { ...state, exportReportOrderFilterBranchId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, exportReportOrderFilterPaymentMethodKey: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_CASHIER_ID:
      return { ...state, exportReportOrderFilterCashierId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_CUSTOMER_ID:
      return { ...state, exportReportOrderFilterCustomerId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_CREATED_AT_BEFORE:
      return { ...state, exportReportOrderFilterCreatedAtBefore: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FILTER_CREATED_AT_AFTER:
      return { ...state, exportReportOrderFilterCreatedAtAfter: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_LOADING:
      return { ...state, exportReportOrderLoading: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_SUCCESS:
      return { ...state, exportReportOrderSuccess: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_ORDER_FAILED:
      return { ...state, exportReportOrderFailed: payload };

    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_MARKET_ID:
      return { ...state, exportReportSummaryFilterMarketId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_BRANCH_ID:
      return { ...state, exportReportSummaryFilterBranchId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, exportReportSummaryFilterPaymentMethodKey: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_CASHIER_ID:
      return { ...state, exportReportSummaryFilterCashierId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_CUSTOMER_ID:
      return { ...state, exportReportSummaryFilterCustomerId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_CREATED_AT_BEFORE:
      return { ...state, exportReportSummaryFilterCreatedAtBefore: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FILTER_CREATED_AT_AFTER:
      return { ...state, exportReportSummaryFilterCreatedAtAfter: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_LOADING:
      return { ...state, exportReportSummaryLoading: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_SUCCESS:
      return { ...state, exportReportSummarySuccess: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_REPORT_SUMMARY_FAILED:
      return { ...state, exportReportSummaryFailed: payload };

    case EXPORT_ACTION_TYPES.SET_EXPORT_CUSTOMER_REPORT_FILTER_MARKET_ID:
      return { ...state, exportCustomerReportFilterMarketId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_CUSTOMER_REPORT_FILTER_CUSTOMERS_IDS:
      return { ...state, exportCustomerReportFilterCustomersIds: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_CUSTOMER_REPORT_LOADING:
      return { ...state, exportCustomerReportLoading: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_CUSTOMER_REPORT_SUCCESS:
      return { ...state, exportCustomerReportSuccess: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_CUSTOMER_REPORT_FAILED:
      return { ...state, exportCustomerReportFailed: payload };

    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FILTER_PERIOD_TYPE:
      return { ...state, exportIngredientReportFilterPeriodType: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FILTER_START_AT:
      return { ...state, exportIngredientReportFilterStartAt: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FILTER_END_AT:
      return { ...state, exportIngredientReportFilterEndAt: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FILTER_MARKET_ID:
      return { ...state, exportIngredientReportFilterMarketId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FILTER_BRANCH_ID:
      return { ...state, exportIngredientReportFilterBranchId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FILTER_INGREDIENTS_IDS:
      return { ...state, exportIngredientReportFilterIngredientsIds: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_LOADING:
      return { ...state, exportIngredientReportLoading: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_SUCCESS:
      return { ...state, exportIngredientReportSuccess: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_REPORT_FAILED:
      return { ...state, exportIngredientReportFailed: payload };

    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_PERIOD_TYPE:
      return { ...state, exportIngredientHistoryReportFilterPeriodType: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_START_AT:
      return { ...state, exportIngredientHistoryReportFilterStartAt: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_END_AT:
      return { ...state, exportIngredientHistoryReportFilterEndAt: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_MARKET_ID:
      return { ...state, exportIngredientHistoryReportFilterMarketId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_BRANCH_ID:
      return { ...state, exportIngredientHistoryReportFilterBranchId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FILTER_INGREDIENTS_IDS:
      return { ...state, exportIngredientHistoryReportFilterIngredientsIds: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_LOADING:
      return { ...state, exportIngredientHistoryReportLoading: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_SUCCESS:
      return { ...state, exportIngredientHistoryReportSuccess: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_INGREDIENT_HISTORY_REPORT_FAILED:
      return { ...state, exportIngredientHistoryReportFailed: payload };

    case EXPORT_ACTION_TYPES.SET_EXPORT_TABLE_QRCODE_FILTER_MARKET_ID:
      return { ...state, exportTableQrCodeFilterMarketId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_TABLE_QRCODE_FILTER_BRANCH_ID:
      return { ...state, exportTableQrCodeFilterBranchId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_TABLE_QRCODE_FILTER_TABLES_IDS:
      return { ...state, exportTableQrCodeFilterTablesIds: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_TABLE_QRCODE_LOADING:
      return { ...state, exportTableQrCodeLoading: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_TABLE_QRCODE_SUCCESS:
      return { ...state, exportTableQrCodeSuccess: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_TABLE_QRCODE_FAILED:
      return { ...state, exportTableQrCodeFailed: payload };

    case EXPORT_ACTION_TYPES.SET_EXPORT_PRODUCT_BARCODE_FILTER_MARKET_ID:
      return { ...state, exportProductBarcodeFilterMarketId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_PRODUCT_BARCODE_FILTER_BRANCH_ID:
      return { ...state, exportProductBarcodeFilterBranchId: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_PRODUCT_BARCODE_FILTER_PRODUCTS_IDS:
      return { ...state, exportProductBarcodeFilterProductsIds: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_PRODUCT_BARCODE_LOADING:
      return { ...state, exportProductBarcodeLoading: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_PRODUCT_BARCODE_SUCCESS:
      return { ...state, exportProductBarcodeSuccess: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_PRODUCT_BARCODE_FAILED:
      return { ...state, exportProductBarcodeFailed: payload };

    case EXPORT_ACTION_TYPES.SET_EXPORT_SUBSCRIPTION_RECEIPT_LOADING:
      return { ...state, exportSubscriptionReceiptLoading: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_SUBSCRIPTION_RECEIPT_SUCCESS:
      return { ...state, exportSubscriptionReceiptSuccess: payload };
    case EXPORT_ACTION_TYPES.SET_EXPORT_SUBSCRIPTION_RECEIPT_FAILED:
      return { ...state, exportSubscriptionReceiptFailed: payload };

    case EXPORT_ACTION_TYPES.SET_IS_EXPORT_REPORT_ORDER_HITTED:
      return { ...state, isExportReportOrderHitted: payload };
    case EXPORT_ACTION_TYPES.SET_IS_EXPORT_REPORT_SUMMARY_HITTED:
      return { ...state, isExportReportSummaryHitted: payload };
    case EXPORT_ACTION_TYPES.SET_IS_EXPORT_CUSTOMER_REPORT_HITTED:
      return { ...state, isExportCustomerReportHitted: payload };
    case EXPORT_ACTION_TYPES.SET_IS_EXPORT_INGREDIENT_REPORT_HITTED:
      return { ...state, isExportIngredientReportHitted: payload };
    case EXPORT_ACTION_TYPES.SET_IS_EXPORT_INGREDIENT_HISTORY_REPORT_HITTED:
      return { ...state, isExportIngredientHistoryReportHitted: payload };
    case EXPORT_ACTION_TYPES.SET_IS_EXPORT_TABLE_QRCODE_HITTED:
      return { ...state, isExportTableQrCodeHitted: payload };
    case EXPORT_ACTION_TYPES.SET_IS_EXPORT_PRODUCT_BARCODE_HITTED:
      return { ...state, isExportProductBarcodeHitted: payload };
    case EXPORT_ACTION_TYPES.SET_IS_EXPORT_SUBSCRIPTION_RECEIPT_HITTED:
      return { ...state, isExportSubscriptionReceiptHitted: payload };

    case EXPORT_ACTION_TYPES.RESET_EXPORT_REDUCER:
      return EXPORT_INITIAL_STATE;
    default:
      return state;
  }
};
