const SUMMARY_ACTION_TYPES = {
  RESET_SUMMARY_REDUCER: "summary/RESET_SUMMARY_REDUCER",

  SET_DASHBOARD_SUMMARY_FINANCIAL_TOTAL: "summary/SET_DASHBOARD_SUMMARY_FINANCIAL_TOTAL",
  SET_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL: "summary/SET_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL",
  SET_REPORT_SUMMARY_FINANCIAL_TOTAL: "summary/SET_REPORT_SUMMARY_FINANCIAL_TOTAL",
  SET_ACCOUNT_SUMMARY_FINANCIAL_TOTAL: "summary/SET_ACCOUNT_SUMMARY_FINANCIAL_TOTAL",
  SET_SUMMARY_FINANCIAL_CHART: "summary/SET_SUMMARY_FINANCIAL_CHART",
  SET_DASHBOARD_SUMMARY_TOP_PRODUCTS: "summary/SET_DASHBOARD_SUMMARY_TOP_PRODUCTS",
  SET_REPORT_SUMMARY_TOP_PRODUCTS: "summary/SET_REPORT_SUMMARY_TOP_PRODUCTS",
  SET_SUMMARY_TOP_PRODUCT_CATEGORIES: "summary/SET_SUMMARY_TOP_PRODUCT_CATEGORIES",
  SET_SUMMARY_LATEST_ORDERS: "summary/SET_SUMMARY_LATEST_ORDERS",
  SET_SUMMARY_SUBSCRIBER_REPORT: "summary/SET_SUMMARY_SUBSCRIBER_REPORT",
  SET_SUMMARY_SUBSCRIBER_REPORT_BRANCH: "summary/SET_SUMMARY_SUBSCRIBER_REPORT_BRANCH",
  SET_SUMMARY_PAYMENT_METHODS: "summary/SET_SUMMARY_PAYMENT_METHODS",
  SET_SUMMARY_SUBSCRIBERS: "summary/SET_SUMMARY_SUBSCRIBERS",
  SET_SUMMARY_BRANCHES: "summary/SET_SUMMARY_BRANCHES",

  SET_DASHBOARD_SUMMARY_TOTAL_USER: "summary/SET_DASHBOARD_SUMMARY_TOTAL_USER",
  SET_DASHBOARD_SUMMARY_TOTAL_MARKET: "summary/SET_DASHBOARD_SUMMARY_TOTAL_MARKET",
  SET_DASHBOARD_SUMMARY_TOTAL_BRANCH: "summary/SET_DASHBOARD_SUMMARY_TOTAL_BRANCH",
  SET_REPORT_SUMMARY_TOTAL_USER: "summary/SET_REPORT_SUMMARY_TOTAL_USER",
  SET_REPORT_SUMMARY_TOTAL_MARKET: "summary/SET_REPORT_SUMMARY_TOTAL_MARKET",
  SET_REPORT_SUMMARY_TOTAL_BRANCH: "summary/SET_REPORT_SUMMARY_TOTAL_BRANCH",

  SET_IS_DASHBOARD_SUMMARY_TOP_PRODUCTS_HAS_MORE: "summary/SET_IS_DASHBOARD_SUMMARY_TOP_PRODUCTS_HAS_MORE",
  SET_IS_REPORT_SUMMARY_TOP_PRODUCTS_HAS_MORE: "summary/SET_IS_REPORT_SUMMARY_TOP_PRODUCTS_HAS_MORE",
  SET_IS_SUMMARY_TOP_PRODUCT_CATEGORIES_HAS_MORE: "summary/SET_IS_SUMMARY_TOP_PRODUCT_CATEGORIES_HAS_MORE",
  SET_IS_SUMMARY_PAYMENT_METHODS_HAS_MORE: "summary/SET_IS_SUMMARY_PAYMENT_METHODS_HAS_MORE",
  SET_IS_SUMMARY_SUBSCRIBERS_HAS_MORE: "summary/SET_IS_SUMMARY_SUBSCRIBERS_HAS_MORE",
  SET_IS_SUMMARY_BRANCHES_HAS_MORE: "summary/SET_IS_SUMMARY_BRANCHES_HAS_MORE",

  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_MARKET_ID:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_MARKET_ID",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_BRANCH_ID:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_BRANCH_ID",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_LOADING: "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_LOADING",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_SUCCESS: "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_SUCCESS",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FAILED: "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FAILED",

  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_PERIOD_TYPE:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_PERIOD_TYPE",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_START_AT:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_START_AT",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_END_AT:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_END_AT",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_MARKET_ID:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_MARKET_ID",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_BRANCH_ID:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_BRANCH_ID",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_PAYMENT_METHOD_KEY:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_CASHIER_ID:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_CASHIER_ID",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_LOADING:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_LOADING",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_SUCCESS:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_SUCCESS",
  SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FAILED:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FAILED",

  SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE:
    "summary/SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE",
  SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT:
    "summary/SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT",
  SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT:
    "summary/SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT",
  SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_MARKET_ID:
    "summary/SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_MARKET_ID",
  SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_BRANCH_ID:
    "summary/SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_BRANCH_ID",
  SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY:
    "summary/SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID:
    "summary/SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID",
  SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_LOADING: "summary/SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_LOADING",
  SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_SUCCESS: "summary/SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_SUCCESS",
  SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FAILED: "summary/SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FAILED",

  SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE:
    "summary/SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE",
  SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT:
    "summary/SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT",
  SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT:
    "summary/SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT",
  SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_MARKET_ID:
    "summary/SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_MARKET_ID",
  SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_BRANCH_ID:
    "summary/SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_BRANCH_ID",
  SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY:
    "summary/SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID:
    "summary/SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID",
  SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_LOADING: "summary/SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_LOADING",
  SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_SUCCESS: "summary/SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_SUCCESS",
  SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FAILED: "summary/SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FAILED",

  SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_PERIOD_TYPE: "summary/SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_PERIOD_TYPE",
  SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_START_AT: "summary/SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_START_AT",
  SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_END_AT: "summary/SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_END_AT",
  SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_MARKET_ID: "summary/SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_MARKET_ID",
  SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_BRANCH_ID: "summary/SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_BRANCH_ID",
  SET_FETCH_SUMMARY_FINANCIAL_CHART_LOADING: "summary/SET_FETCH_SUMMARY_FINANCIAL_CHART_LOADING",
  SET_FETCH_SUMMARY_FINANCIAL_CHART_SUCCESS: "summary/SET_FETCH_SUMMARY_FINANCIAL_CHART_SUCCESS",
  SET_FETCH_SUMMARY_FINANCIAL_CHART_FAILED: "summary/SET_FETCH_SUMMARY_FINANCIAL_CHART_FAILED",

  SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_PAGE: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_PAGE",
  SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_PER_PAGE: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_PER_PAGE",
  SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_PERIOD_TYPE:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_PERIOD_TYPE",
  SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_START_AT:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_START_AT",
  SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_END_AT:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_END_AT",
  SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_MARKET_ID:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_MARKET_ID",
  SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_BRANCH_ID:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_BRANCH_ID",
  SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_PAYMENT_METHOD_KEY:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_CASHIER_ID:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_CASHIER_ID",
  SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_LOADING: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_LOADING",
  SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_SUCCESS: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_SUCCESS",
  SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FAILED: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FAILED",

  SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_PAGE: "summary/SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_PAGE",
  SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_PER_PAGE: "summary/SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_PER_PAGE",
  SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_PERIOD_TYPE:
    "summary/SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_PERIOD_TYPE",
  SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_START_AT:
    "summary/SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_START_AT",
  SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_END_AT: "summary/SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_END_AT",
  SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_MARKET_ID:
    "summary/SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_MARKET_ID",
  SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_BRANCH_ID:
    "summary/SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_BRANCH_ID",
  SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_PAYMENT_METHOD_KEY:
    "summary/SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_CASHIER_ID:
    "summary/SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_CASHIER_ID",
  SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_LOADING: "summary/SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_LOADING",
  SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_SUCCESS: "summary/SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_SUCCESS",
  SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FAILED: "summary/SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FAILED",

  SET_FETCH_TOP_PRODUCT_CATEGORIES_PAGE: "summary/SET_FETCH_TOP_PRODUCT_CATEGORIES_PAGE",
  SET_FETCH_TOP_PRODUCT_CATEGORIES_PER_PAGE: "summary/SET_FETCH_TOP_PRODUCT_CATEGORIES_PER_PAGE",
  SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_PERIOD_TYPE: "summary/SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_PERIOD_TYPE",
  SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_START_AT: "summary/SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_START_AT",
  SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_END_AT: "summary/SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_END_AT",
  SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_MARKET_ID: "summary/SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_MARKET_ID",
  SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_BRANCH_ID: "summary/SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_BRANCH_ID",
  SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_PAYMENT_METHOD_KEY:
    "summary/SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_CASHIER_ID: "summary/SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_CASHIER_ID",
  SET_FETCH_TOP_PRODUCT_CATEGORIES_LOADING: "summary/SET_FETCH_TOP_PRODUCT_CATEGORIES_LOADING",
  SET_FETCH_TOP_PRODUCT_CATEGORIES_SUCCESS: "summary/SET_FETCH_TOP_PRODUCT_CATEGORIES_SUCCESS",
  SET_FETCH_TOP_PRODUCT_CATEGORIES_FAILED: "summary/SET_FETCH_TOP_PRODUCT_CATEGORIES_FAILED",

  SET_FETCH_LATEST_ORDERS_LIMIT: "summary/SET_FETCH_LATEST_ORDERS_LIMIT",
  SET_FETCH_LATEST_ORDERS_INCLUDES: "summary/SET_FETCH_LATEST_ORDERS_INCLUDES",
  SET_FETCH_LATEST_ORDERS_FILTER_PERIOD_TYPE: "summary/SET_FETCH_LATEST_ORDERS_FILTER_PERIOD_TYPE",
  SET_FETCH_LATEST_ORDERS_FILTER_START_AT: "summary/SET_FETCH_LATEST_ORDERS_FILTER_START_AT",
  SET_FETCH_LATEST_ORDERS_FILTER_END_AT: "summary/SET_FETCH_LATEST_ORDERS_FILTER_END_AT",
  SET_FETCH_LATEST_ORDERS_FILTER_MARKET_ID: "summary/SET_FETCH_LATEST_ORDERS_FILTER_MARKET_ID",
  SET_FETCH_LATEST_ORDERS_FILTER_BRANCH_ID: "summary/SET_FETCH_LATEST_ORDERS_FILTER_BRANCH_ID",
  SET_FETCH_LATEST_ORDERS_FILTER_PAYMENT_METHOD_KEY: "summary/SET_FETCH_LATEST_ORDERS_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_LATEST_ORDERS_FILTER_CASHIER_ID: "summary/SET_FETCH_LATEST_ORDERS_FILTER_CASHIER_ID",
  SET_FETCH_LATEST_ORDERS_LOADING: "summary/SET_FETCH_LATEST_ORDERS_LOADING",
  SET_FETCH_LATEST_ORDERS_SUCCESS: "summary/SET_FETCH_LATEST_ORDERS_SUCCESS",
  SET_FETCH_LATEST_ORDERS_FAILED: "summary/SET_FETCH_LATEST_ORDERS_FAILED",

  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_PERIOD_TYPE:
    "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_PERIOD_TYPE",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_START_AT: "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_START_AT",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_END_AT: "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_END_AT",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_MARKET_ID: "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_MARKET_ID",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_BRANCH_ID: "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_BRANCH_ID",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_CASHIER_ID:
    "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_CASHIER_ID",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_LOADING: "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_LOADING",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_SUCCESS: "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_SUCCESS",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FAILED: "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FAILED",

  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_PERIOD_TYPE:
    "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_PERIOD_TYPE",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_START_AT:
    "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_START_AT",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_END_AT:
    "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_END_AT",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_MARKET_ID:
    "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_MARKET_ID",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_BRANCH_ID:
    "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_BRANCH_ID",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_LOADING: "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_LOADING",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_SUCCESS: "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_SUCCESS",
  SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FAILED: "summary/SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FAILED",

  SET_FETCH_SUMMARY_PAYMENT_METHODS_PAGE: "summary/SET_FETCH_SUMMARY_PAYMENT_METHODS_PAGE",
  SET_FETCH_SUMMARY_PAYMENT_METHODS_PER_PAGE: "summary/SET_FETCH_SUMMARY_PAYMENT_METHODS_PER_PAGE",
  SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_PERIOD_TYPE: "summary/SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_PERIOD_TYPE",
  SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_START_AT: "summary/SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_START_AT",
  SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_END_AT: "summary/SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_END_AT",
  SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_MARKET_ID: "summary/SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_MARKET_ID",
  SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_BRANCH_ID: "summary/SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_BRANCH_ID",
  SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_PAYMENT_METHOD_KEY:
    "summary/SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_CASHIER_ID: "summary/SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_CASHIER_ID",
  SET_FETCH_SUMMARY_PAYMENT_METHODS_LOADING: "summary/SET_FETCH_SUMMARY_PAYMENT_METHODS_LOADING",
  SET_FETCH_SUMMARY_PAYMENT_METHODS_SUCCESS: "summary/SET_FETCH_SUMMARY_PAYMENT_METHODS_SUCCESS",
  SET_FETCH_SUMMARY_PAYMENT_METHODS_FAILED: "summary/SET_FETCH_SUMMARY_PAYMENT_METHODS_FAILED",

  SET_FETCH_SUMMARY_SUBSCRIBERS_PAGE: "summary/SET_FETCH_SUMMARY_SUBSCRIBERS_PAGE",
  SET_FETCH_SUMMARY_SUBSCRIBERS_PER_PAGE: "summary/SET_FETCH_SUMMARY_SUBSCRIBERS_PER_PAGE",
  SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_PERIOD_TYPE: "summary/SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_PERIOD_TYPE",
  SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_START_AT: "summary/SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_START_AT",
  SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_END_AT: "summary/SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_END_AT",
  SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_MARKET_ID: "summary/SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_MARKET_ID",
  SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_BRANCH_ID: "summary/SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_BRANCH_ID",
  SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_PAYMENT_METHOD_KEY:
    "summary/SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_CASHIER_ID: "summary/SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_CASHIER_ID",
  SET_FETCH_SUMMARY_SUBSCRIBERS_LOADING: "summary/SET_FETCH_SUMMARY_SUBSCRIBERS_LOADING",
  SET_FETCH_SUMMARY_SUBSCRIBERS_SUCCESS: "summary/SET_FETCH_SUMMARY_SUBSCRIBERS_SUCCESS",
  SET_FETCH_SUMMARY_SUBSCRIBERS_FAILED: "summary/SET_FETCH_SUMMARY_SUBSCRIBERS_FAILED",

  SET_FETCH_SUMMARY_BRANCHES_PAGE: "summary/SET_FETCH_SUMMARY_BRANCHES_PAGE",
  SET_FETCH_SUMMARY_BRANCHES_PER_PAGE: "summary/SET_FETCH_SUMMARY_BRANCHES_PER_PAGE",
  SET_FETCH_SUMMARY_BRANCHES_FILTER_PERIOD_TYPE: "summary/SET_FETCH_SUMMARY_BRANCHES_FILTER_PERIOD_TYPE",
  SET_FETCH_SUMMARY_BRANCHES_FILTER_START_AT: "summary/SET_FETCH_SUMMARY_BRANCHES_FILTER_START_AT",
  SET_FETCH_SUMMARY_BRANCHES_FILTER_END_AT: "summary/SET_FETCH_SUMMARY_BRANCHES_FILTER_END_AT",
  SET_FETCH_SUMMARY_BRANCHES_FILTER_MARKET_ID: "summary/SET_FETCH_SUMMARY_BRANCHES_FILTER_MARKET_ID",
  SET_FETCH_SUMMARY_BRANCHES_FILTER_BRANCH_ID: "summary/SET_FETCH_SUMMARY_BRANCHES_FILTER_BRANCH_ID",
  SET_FETCH_SUMMARY_BRANCHES_FILTER_PAYMENT_METHOD_KEY:
    "summary/SET_FETCH_SUMMARY_BRANCHES_FILTER_PAYMENT_METHOD_KEY",
  SET_FETCH_SUMMARY_BRANCHES_FILTER_CASHIER_ID: "summary/SET_FETCH_SUMMARY_BRANCHES_FILTER_CASHIER_ID",
  SET_FETCH_SUMMARY_BRANCHES_LOADING: "summary/SET_FETCH_SUMMARY_BRANCHES_LOADING",
  SET_FETCH_SUMMARY_BRANCHES_SUCCESS: "summary/SET_FETCH_SUMMARY_BRANCHES_SUCCESS",
  SET_FETCH_SUMMARY_BRANCHES_FAILED: "summary/SET_FETCH_SUMMARY_BRANCHES_FAILED",

  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_ROLES: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_ROLES",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_START_AT:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_START_AT",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_END_AT: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_END_AT",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_PHONE_PREFIX:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_PHONE_PREFIX",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_IS_ACTIVE:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_IS_ACTIVE",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_LOADING: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_LOADING",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_SUCCESS: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_SUCCESS",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FAILED: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FAILED",

  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_FILTER_SECTOR_ID:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_FILTER_SECTOR_ID",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_FILTER_PHONE_PREFIX:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_FILTER_PHONE_PREFIX",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_LOADING: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_LOADING",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_SUCCESS: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_SUCCESS",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_FAILED: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_FAILED",

  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_FILTER_MARKET_ID:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_FILTER_MARKET_ID",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_FILTER_IS_ACTIVE:
    "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_FILTER_IS_ACTIVE",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_LOADING: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_LOADING",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_SUCCESS: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_SUCCESS",
  SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_FAILED: "summary/SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_FAILED",

  SET_FETCH_REPORT_SUMMARY_TOTAL_USER_ROLES: "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_USER_ROLES",
  SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_START_AT: "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_START_AT",
  SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_END_AT: "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_END_AT",
  SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_PHONE_PREFIX:
    "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_PHONE_PREFIX",
  SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_IS_ACTIVE: "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_IS_ACTIVE",
  SET_FETCH_REPORT_SUMMARY_TOTAL_USER_LOADING: "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_USER_LOADING",
  SET_FETCH_REPORT_SUMMARY_TOTAL_USER_SUCCESS: "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_USER_SUCCESS",
  SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FAILED: "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FAILED",

  SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_FILTER_SECTOR_ID:
    "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_FILTER_SECTOR_ID",
  SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_FILTER_PHONE_PREFIX:
    "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_FILTER_PHONE_PREFIX",
  SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_LOADING: "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_LOADING",
  SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_SUCCESS: "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_SUCCESS",
  SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_FAILED: "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_FAILED",

  SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_FILTER_MARKET_ID:
    "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_FILTER_MARKET_ID",
  SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_FILTER_IS_ACTIVE:
    "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_FILTER_IS_ACTIVE",
  SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_LOADING: "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_LOADING",
  SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_SUCCESS: "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_SUCCESS",
  SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_FAILED: "summary/SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_FAILED",

  APPEND_DASHBOARD_SUMMARY_TOP_PRODUCTS: "summary/APPEND_DASHBOARD_SUMMARY_TOP_PRODUCTS",
  APPEND_REPORT_SUMMARY_TOP_PRODUCTS: "summary/APPEND_REPORT_SUMMARY_TOP_PRODUCTS",
  APPEND_SUMMARY_TOP_PRODUCT_CATEGORIES: "summary/APPEND_SUMMARY_TOP_PRODUCT_CATEGORIES",
  APPEND_SUMMARY_PAYMENT_METHODS: "summary/APPEND_SUMMARY_PAYMENT_METHODS",
  APPEND_SUMMARY_SUBSCRIBERS: "summary/APPEND_SUMMARY_SUBSCRIBERS",
  APPEND_SUMMARY_BRANCHES: "summary/APPEND_SUMMARY_BRANCHES",

  FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_START: "summary/FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_START",
  FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_START:
    "summary/FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_START",
  FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_START: "summary/FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_START",
  FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_START: "summary/FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_START",
  FETCH_SUMMARY_FINANCIAL_CHART_START: "summary/FETCH_SUMMARY_FINANCIAL_CHART_START",
  FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_START: "summary/FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_START",
  FETCH_REPORT_SUMMARY_TOP_PRODUCTS_START: "summary/FETCH_REPORT_SUMMARY_TOP_PRODUCTS_START",
  FETCH_SUMMARY_TOP_PRODUCT_CATEGORIES_START: "summary/FETCH_SUMMARY_TOP_PRODUCT_CATEGORIES_START",
  FETCH_SUMMARY_LATEST_ORDERS_START: "summary/FETCH_SUMMARY_LATEST_ORDERS_START",
  FETCH_SUMMARY_SUBSCRIBER_REPORT_START: "summary/FETCH_SUMMARY_SUBSCRIBER_REPORT_START",
  FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_START: "summary/FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_START",
  FETCH_SUMMARY_PAYMENT_METHODS_START: "summary/FETCH_SUMMARY_PAYMENT_METHODS_START",
  FETCH_SUMMARY_SUBSCRIBERS_START: "summary/FETCH_SUMMARY_SUBSCRIBERS_START",
  FETCH_SUMMARY_BRANCHES_START: "summary/FETCH_SUMMARY_BRANCHES_START",

  FETCH_DASHBOARD_SUMMARY_TOTAL_USER_START: "summary/FETCH_DASHBOARD_SUMMARY_TOTAL_USER_START",
  FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_START: "summary/FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_START",
  FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_START: "summary/FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_START",
  FETCH_REPORT_SUMMARY_TOTAL_USER_START: "summary/FETCH_DASHBOARD_SUMMARY_TOTAL_USER_START",
  FETCH_REPORT_SUMMARY_TOTAL_MARKET_START: "summary/FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_START",
  FETCH_REPORT_SUMMARY_TOTAL_BRANCH_START: "summary/FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_START",
};

export default SUMMARY_ACTION_TYPES;
