const TOP_UP_ACTION_TYPES = {
  RESET_TOP_UP_REDUCER: "top-up/RESET_TOP_UP_REDUCER",

  SET_TOP_UPS: "top-up/SET_TOP_UPS",
  SET_TOP_UP: "top-up/SET_TOP_UP",

  SET_IS_TOP_UPS_HAS_MORE: "top-up/SET_IS_TOP_UPS_HAS_MORE",

  SET_FETCH_TOP_UPS_SEARCH: "top-up/SET_FETCH_TOP_UPS_SEARCH",
  SET_FETCH_TOP_UPS_PAGE: "top-up/SET_FETCH_TOP_UPS_PAGE",
  SET_FETCH_TOP_UPS_PER_PAGE: "top-up/SET_FETCH_TOP_UPS_PER_PAGE",
  SET_FETCH_TOP_UPS_INCLUDES: "top-up/SET_FETCH_TOP_UPS_INCLUDES",
  SET_FETCH_TOP_UPS_FILTER_MARKET_ID: "top-up/SET_FETCH_TOP_UPS_FILTER_MARKET_ID",
  SET_FETCH_TOP_UPS_FILTER_CREATED_BY: "top-up/SET_FETCH_TOP_UPS_FILTER_CREATED_BY",
  SET_FETCH_TOP_UPS_LOADING: "top-up/SET_FETCH_TOP_UPS_LOADING",
  SET_FETCH_TOP_UPS_SUCCESS: "top-up/SET_FETCH_TOP_UPS_SUCCESS",
  SET_FETCH_TOP_UPS_FAILED: "top-up/SET_FETCH_TOP_UPS_FAILED",

  SET_FETCH_TOP_UP_LOADING: "top-up/SET_FETCH_TOP_UP_LOADING",
  SET_FETCH_TOP_UP_SUCCESS: "top-up/SET_FETCH_TOP_UP_SUCCESS",
  SET_FETCH_TOP_UP_FAILED: "top-up/SET_FETCH_TOP_UP_FAILED",

  SET_CREATE_TOP_UP_LOADING: "top-up/SET_CREATE_TOP_UP_LOADING",
  SET_CREATE_TOP_UP_SUCCESS: "top-up/SET_CREATE_TOP_UP_SUCCESS",
  SET_CREATE_TOP_UP_FAILED: "top-up/SET_CREATE_TOP_UP_FAILED",

  SET_UPDATE_TOP_UP_LOADING: "top-up/SET_UPDATE_TOP_UP_LOADING",
  SET_UPDATE_TOP_UP_SUCCESS: "top-up/SET_UPDATE_TOP_UP_SUCCESS",
  SET_UPDATE_TOP_UP_FAILED: "top-up/SET_UPDATE_TOP_UP_FAILED",

  SET_DELETE_TOP_UP_LOADING: "top-up/SET_DELETE_TOP_UP_LOADING",
  SET_DELETE_TOP_UP_SUCCESS: "top-up/SET_DELETE_TOP_UP_SUCCESS",
  SET_DELETE_TOP_UP_FAILED: "top-up/SET_DELETE_TOP_UP_FAILED",

  APPEND_TOP_UPS: "top-up/APPEND_TOP_UPS",

  FETCH_TOP_UPS_START: "top-up/FETCH_TOP_UPS_START",
  FETCH_TOP_UP_START: "top-up/FETCH_TOP_UP_START",
  CREATE_TOP_UP_START: "top-up/CREATE_TOP_UP_START",
  UPDATE_TOP_UP_START: "top-up/UPDATE_TOP_UP_START",
  DELETE_TOP_UP_START: "top-up/DELETE_TOP_UP_START",
};

export default TOP_UP_ACTION_TYPES;
