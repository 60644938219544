export const USER_INCLUDES = {
  USERABLE: "userable",
  VERIFICATIONS: "verifications",
};

export const USER_ROLES = {
  USER_ADMIN: "USER_ADMIN",
  USER_CUSTOMER: "USER_CUSTOMER",
  USER_MERCHANT: "USER_MERCHANT",
  USER_SUBSCRIBER: "USER_SUBSCRIBER",
};
