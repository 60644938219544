import { takeLatest, put, all, call, select } from "redux-saga/effects";

import SCHEDULE_ACTION_TYPES from "./schedule.type";

import { _getBranches } from "../branch/branch.saga";
import { setFetchBranchesPage } from "../branch/branch.action";
import {
  setCreateOrUpdateScheduleFailed,
  setCreateOrUpdateScheduleLoading,
  setCreateOrUpdateScheduleSuccess,
  setFetchSchedulesFailed,
  setFetchSchedulesLoading,
  setFetchSchedulesSuccess,
  setIsCreateOrUpdateScheduleHitted,
  setIsFetchSchedulesHitted,
  setSchedules,
} from "./schedule.action";
import {
  getFetchSchedulesFilterBranchId,
  getFetchSchedulesFilterMarketId,
  getFetchSchedulesIncludes,
} from "./schedule.selector";

import { getSchedules, createOrUpdateSchedule } from "../../api/schedule.api";

export function* _getSchedules() {
  try {
    yield put(setFetchSchedulesLoading(true));

    const includes = yield select(getFetchSchedulesIncludes);
    const market_id = yield select(getFetchSchedulesFilterMarketId);
    const branch_id = yield select(getFetchSchedulesFilterBranchId);

    const parameters = {
      includes,
      filter: { market_id, branch_id },
    };

    const {
      meta: { message },
      data: schedules,
    } = yield call(getSchedules, parameters);

    yield put(setIsFetchSchedulesHitted(true));
    yield put(setSchedules(schedules));

    yield put(setFetchSchedulesSuccess(message));
    yield put(setFetchSchedulesLoading(false));
  } catch (error) {
    yield put(setFetchSchedulesFailed(error));
    yield put(setFetchSchedulesLoading(false));
  }
}

export function* _createOrUpdateSchedule({ payload: request }) {
  try {
    yield put(setCreateOrUpdateScheduleLoading(true));

    const {
      meta: { message },
    } = yield call(createOrUpdateSchedule, request);

    yield put(setIsCreateOrUpdateScheduleHitted(true));
    yield put(setCreateOrUpdateScheduleSuccess(message));
    yield put(setCreateOrUpdateScheduleLoading(false));

    yield put(setFetchBranchesPage(1));
    yield call(_getBranches);
  } catch (error) {
    yield put(setCreateOrUpdateScheduleFailed(error));
    yield put(setCreateOrUpdateScheduleLoading(false));
  }
}

export function* onFetchSchedulesStart() {
  yield takeLatest(SCHEDULE_ACTION_TYPES.FETCH_SCHEDULES_START, _getSchedules);
}

export function* onCreateOrUpdateScheduleStart() {
  yield takeLatest(SCHEDULE_ACTION_TYPES.CREATE_OR_UPDATE_SCHEDULE_START, _createOrUpdateSchedule);
}

export function* scheduleSaga() {
  yield all([call(onFetchSchedulesStart), call(onCreateOrUpdateScheduleStart)]);
}
