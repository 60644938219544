import { takeLatest, put, all, call, select } from "redux-saga/effects";

import USER_SUBSCRIBER_ACTION_TYPES from "./user-subscriber.type";

import {
  appendUserSubscribers,
  setUserSubscriber,
  setUserSubscribers,
  setCreateUserSubscriberFailed,
  setCreateUserSubscriberLoading,
  setCreateUserSubscriberSuccess,
  setDeleteUserSubscriberFailed,
  setDeleteUserSubscriberLoading,
  setDeleteUserSubscriberSuccess,
  setFetchUserSubscriberFailed,
  setFetchUserSubscriberLoading,
  setFetchUserSubscriberSuccess,
  setFetchUserSubscribersFailed,
  setFetchUserSubscribersLoading,
  setFetchUserSubscribersPage,
  setFetchUserSubscribersSuccess,
  setIsUserSubscribersHasMore,
  setIsCreateUserSubscriberHitted,
  setIsDeleteUserSubscriberHitted,
  setIsFetchUserSubscriberHitted,
  setIsFetchUserSubscribersHitted,
  setIsUpdateUserSubscriberHitted,
  setUpdateUserSubscriberFailed,
  setUpdateUserSubscriberLoading,
  setUpdateUserSubscriberSuccess,
} from "./user-subscriber.action";
import {
  getFetchUserSubscribersFilterBranchId,
  getFetchUserSubscribersFilterMarketId,
  getFetchUserSubscribersFilterPermissionId,
  getFetchUserSubscribersIncludes,
  getFetchUserSubscribersKeyBy,
  getFetchUserSubscribersPage,
  getFetchUserSubscribersPerPage,
  getFetchUserSubscribersSearch,
  getFetchUserSubscribersSort,
  getIsFetchUserSubscribersHitted,
} from "./user-subscriber.selector";

import {
  createUserSubscriber,
  deleteUserSubscriber,
  getUserSubscriber,
  getUserSubscribers,
  updateUserSubscriber,
} from "../../api/user-subscriber.api";

export function* _getUserSubscribers() {
  try {
    yield put(setFetchUserSubscribersLoading(true));

    const search = yield select(getFetchUserSubscribersSearch);
    const sort = yield select(getFetchUserSubscribersSort);
    const key_by = yield select(getFetchUserSubscribersKeyBy);
    const page = yield select(getFetchUserSubscribersPage);
    const per_page = yield select(getFetchUserSubscribersPerPage);
    const includes = yield select(getFetchUserSubscribersIncludes);
    const market_id = yield select(getFetchUserSubscribersFilterMarketId);
    const branch_id = yield select(getFetchUserSubscribersFilterBranchId);
    const permission_id = yield select(
      getFetchUserSubscribersFilterPermissionId
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id, branch_id, permission_id },
    };

    const {
      meta: { message },
      data: { data: userSubscribers },
    } = yield call(getUserSubscribers, parameters);

    yield put(setIsFetchUserSubscribersHitted(true));
    yield put(setIsUserSubscribersHasMore(userSubscribers.length > 0));

    if (page > 1) {
      yield put(appendUserSubscribers(userSubscribers));
    } else {
      yield put(setUserSubscribers(userSubscribers));
    }

    yield put(setFetchUserSubscribersSuccess(message));
    yield put(setFetchUserSubscribersLoading(false));
  } catch (error) {
    yield put(setFetchUserSubscribersFailed(error));
    yield put(setFetchUserSubscribersLoading(false));
  }
}
export function* _getUserSubscriber({ payload: subscriberId }) {
  try {
    yield put(setFetchUserSubscriberLoading(true));

    const {
      meta: { message },
      data: userSubscriber,
    } = yield call(getUserSubscriber, subscriberId);

    yield put(setIsFetchUserSubscriberHitted(true));
    yield put(setUserSubscriber(userSubscriber));

    yield put(setFetchUserSubscriberSuccess(message));
    yield put(setFetchUserSubscriberLoading(false));
  } catch (error) {
    yield put(setFetchUserSubscriberFailed(error));
    yield put(setFetchUserSubscriberLoading(false));
  }
}
export function* _createUserSubscriber({ payload: request }) {
  try {
    yield put(setCreateUserSubscriberLoading(true));

    const {
      meta: { message },
    } = yield call(createUserSubscriber, request);

    yield put(setIsCreateUserSubscriberHitted(true));

    const isFetchUserSubscribersHitted = yield select(
      getIsFetchUserSubscribersHitted
    );

    if (isFetchUserSubscribersHitted) {
      yield put(setFetchUserSubscribersPage(1));
      yield call(_getUserSubscribers);
    }

    yield put(setCreateUserSubscriberSuccess(message));
    yield put(setCreateUserSubscriberLoading(false));
  } catch (error) {
    yield put(setCreateUserSubscriberFailed(error));
    yield put(setCreateUserSubscriberLoading(false));
  }
}
export function* _updateUserSubscriber({ payload: { subscriberId, request } }) {
  try {
    yield put(setUpdateUserSubscriberLoading(true));

    const {
      meta: { message },
    } = yield call(updateUserSubscriber, subscriberId, request);

    yield put(setIsUpdateUserSubscriberHitted(true));

    const isFetchUserSubscribersHitted = yield select(
      getIsFetchUserSubscribersHitted
    );

    if (isFetchUserSubscribersHitted) {
      yield put(setFetchUserSubscribersPage(1));
      yield call(_getUserSubscribers);
    }

    yield put(setUpdateUserSubscriberSuccess(message));
    yield put(setUpdateUserSubscriberLoading(false));
  } catch (error) {
    yield put(setUpdateUserSubscriberFailed(error));
    yield put(setUpdateUserSubscriberLoading(false));
  }
}
export function* _deleteUserSubscriber({ payload: subscriberId }) {
  try {
    yield put(setDeleteUserSubscriberLoading(true));

    const {
      meta: { message },
    } = yield call(deleteUserSubscriber, subscriberId);

    yield put(setIsDeleteUserSubscriberHitted(true));

    const isFetchUserSubscribersHitted = yield select(
      getIsFetchUserSubscribersHitted
    );

    if (isFetchUserSubscribersHitted) {
      yield put(setFetchUserSubscribersPage(1));
      yield call(_getUserSubscribers);
    }

    yield put(setDeleteUserSubscriberSuccess(message));
    yield put(setDeleteUserSubscriberLoading(false));
  } catch (error) {
    yield put(setDeleteUserSubscriberFailed(error));
    yield put(setDeleteUserSubscriberLoading(false));
  }
}

export function* onFetchUserSubscribersStart() {
  yield takeLatest(
    USER_SUBSCRIBER_ACTION_TYPES.FETCH_USER_SUBSCRIBERS_START,
    _getUserSubscribers
  );
}
export function* onFetchUserSubscriberStart() {
  yield takeLatest(
    USER_SUBSCRIBER_ACTION_TYPES.FETCH_USER_SUBSCRIBER_START,
    _getUserSubscriber
  );
}
export function* onCreateUserSubscriberStart() {
  yield takeLatest(
    USER_SUBSCRIBER_ACTION_TYPES.CREATE_USER_SUBSCRIBER_START,
    _createUserSubscriber
  );
}
export function* onUpdateUserSubscriberStart() {
  yield takeLatest(
    USER_SUBSCRIBER_ACTION_TYPES.UPDATE_USER_SUBSCRIBER_START,
    _updateUserSubscriber
  );
}
export function* onDeleteUserSubscriberStart() {
  yield takeLatest(
    USER_SUBSCRIBER_ACTION_TYPES.DELETE_USER_SUBSCRIBER_START,
    _deleteUserSubscriber
  );
}

export function* userSubscriberSaga() {
  yield all([
    call(onFetchUserSubscribersStart),
    call(onFetchUserSubscriberStart),
    call(onCreateUserSubscriberStart),
    call(onUpdateUserSubscriberStart),
    call(onDeleteUserSubscriberStart),
  ]);
}
