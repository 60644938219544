const WATHQ_ACTION_TYPES = {
  RESET_WATHQ_REDUCER: "wathq/RESET_WATHQ_REDUCER",

  SET_WATHQ: "wathq/SET_WATHQ",

  SET_FETCH_WATHQ_LOADING: "wathq/SET_FETCH_WATHQ_LOADING",
  SET_FETCH_WATHQ_SUCCESS: "wathq/SET_FETCH_WATHQ_SUCCESS",
  SET_FETCH_WATHQ_FAILED: "wathq/SET_FETCH_WATHQ_FAILED",

  FETCH_WATHQ_START: "wathq/FETCH_WATHQ_START",
};

export default WATHQ_ACTION_TYPES;
