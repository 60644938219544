export const PERMISSION_SUBSCRIBER_PAGES = {
  PAGE_ACCOUNT: "ACCOUNT",
  PAGE_ADVERTISEMENT: "ADVERTISEMENT",
  PAGE_APPLICATION: "APPLICATION",
  PAGE_AUTHENTICATION: "AUTHENTICATION",
  PAGE_BRANCH: "BRANCH",
  PAGE_CUSTOMER: "CUSTOMER",
  PAGE_DASHBOARD: "DASHBOARD",
  PAGE_DEVICE: "DEVICE",
  PAGE_DISCOUNT: "DISCOUNT",
  PAGE_DRIVER: "DRIVER",
  PAGE_GENERAL_SETTINGS: "GENERAL_SETTINGS",
  PAGE_INVENTORY: "INVENTORY",
  PAGE_KITCHEN: "KITCHEN",
  PAGE_ORDER: "ORDER",
  PAGE_ORDERS_OF_PURCHASE: "ORDERS_OF_PURCHASE",
  PAGE_PAYMENT_METHOD: "PAYMENT_METHOD",
  PAGE_PAYMENT_TRANSFER: "PAYMENT_TRANSFER",
  PAGE_PERMISSION: "PERMISSION",
  PAGE_PRINTER: "PRINTER",
  PAGE_PRINTER_TYPE: "PRINTER_TYPE",
  PAGE_PRODUCT: "PRODUCT",
  PAGE_PROFILE: "PROFILE",
  PAGE_PURCHASE: "PURCHASE",
  PAGE_RATING: "RATING",
  PAGE_RECIPE: "RECIPE",
  PAGE_REPORT: "REPORT",
  PAGE_REPORT_ATTENDANCE: "REPORT_ATTENDANCE",
  PAGE_REPORT_ORDER: "REPORT_ORDER",
  PAGE_REPORT_SUMMARY: "REPORT_SUMMARY",
  PAGE_SCHEDULE: "SCHEDULE",
  PAGE_SCREEN_CASHIER: "SCREEN_CASHIER",
  PAGE_SCREEN_CUSTOMER: "SCREEN_CUSTOMER",
  PAGE_SCREEN_WAITING: "SCREEN_WAITING",
  PAGE_SUBSCRIPTION: "SUBSCRIPTION",
  PAGE_SUPPLIER: "SUPPLIER",
  PAGE_TABLE: "TABLE",
  PAGE_TAX: "TAX",
};

export const PERMISSION_SUBSCRIBER_OTHERS = {
  OTHER_RETURN_ORDER: "RETURN_ORDER",
  OTHER_CASH_DRAWER: "CASH_DRAWER",
  OTHER_VERIFY_ORDER_PURCHASE: "VERIFY_ORDER_PURCHASE",
  OTHER_FAST_OFFER: "FAST_OFFER",
  OTHER_SPLIT_PAYMENTS: "SPLIT_PAYMENTS",
  OTHER_PRINT_INVOICE: "PRINT_INVOICE",
  OTHER_PAY_NOW: "PAY_NOW",
  OTHER_PAY_LATER: "PAY_LATER",
};
