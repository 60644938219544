const SELECT_PRODUCT_ACTION_TYPES = {
  RESET_SELECT_PRODUCT_REDUCER: "select-product/RESET_SELECT_PRODUCT_REDUCER",

  SET_PRODUCTS: "select-product/SET_PRODUCTS",
  SET_SEARCH_PRODUCTS: "select-product/SET_SEARCH_PRODUCTS",

  APPEND_PRODUCTS: "select-product/APPEND_PRODUCTS",
  APPEND_SEARCH_PRODUCTS: "select-product/APPEND_SEARCH_PRODUCTS",

  SET_IS_PRODUCTS_HAS_MORE: "select-product/SET_IS_PRODUCTS_HAS_MORE",
  SET_IS_SEARCH_PRODUCTS_HAS_MORE:
    "select-product/SET_IS_SEARCH_PRODUCTS_HAS_MORE",

  SET_FETCH_PRODUCTS_PARAMS: "select-product/SET_FETCH_PRODUCTS_PARAMS",
  SET_FETCH_PRODUCTS_LOADING: "select-product/SET_FETCH_PRODUCTS_LOADING",
  SET_FETCH_PRODUCTS_SUCCESS: "select-product/SET_FETCH_PRODUCTS_SUCCESS",
  SET_FETCH_PRODUCTS_FAILED: "select-product/SET_FETCH_PRODUCTS_FAILED",

  SET_SEARCH_PRODUCTS_PARAMS: "select-product/SET_SEARCH_PRODUCTS_PARAMS",
  SET_SEARCH_PRODUCTS_LOADING: "select-product/SET_SEARCH_PRODUCTS_LOADING",
  SET_SEARCH_PRODUCTS_SUCCESS: "select-product/SET_SEARCH_PRODUCTS_SUCCESS",
  SET_SEARCH_PRODUCTS_FAILED: "select-product/SET_SEARCH_PRODUCTS_FAILED",

  SET_APPEND_PRODUCTS_PARAMS: "select-product/SET_APPEND_PRODUCTS_PARAMS",
  SET_APPEND_PRODUCTS_LOADING: "select-product/SET_APPEND_PRODUCTS_LOADING",
  SET_APPEND_PRODUCTS_SUCCESS: "select-product/SET_APPEND_PRODUCTS_SUCCESS",
  SET_APPEND_PRODUCTS_FAILED: "select-product/SET_APPEND_PRODUCTS_FAILED",

  SET_IS_FETCH_PRODUCTS_HITTED: "select-product/SET_IS_FETCH_PRODUCTS_HITTED",
  SET_IS_SEARCH_PRODUCTS_HITTED: "select-product/SET_IS_SEARCH_PRODUCTS_HITTED",
  SET_IS_APPEND_PRODUCTS_HITTED: "select-product/SET_IS_APPEND_PRODUCTS_HITTED",

  FETCH_PRODUCTS_START: "select-product/FETCH_PRODUCTS_START",
  SEARCH_PRODUCTS_START: "select-product/SEARCH_PRODUCTS_START",
  APPEND_PRODUCTS_START: "select-product/APPEND_PRODUCTS_START",
};

export default SELECT_PRODUCT_ACTION_TYPES;
