import apiService from "./api";

export const getPrinterTypes = async (parameters) => (await apiService.get("/printer-type", parameters)).data;

export const getPrinterType = async (id) => (await apiService.get(`/printer-type/${id}`)).data;

export const createPrinterType = async (request) => (await apiService.post("/printer-type", request)).data;

export const updatePrinterType = async (id, request) => (await apiService.post(`/printer-type/${id}`, request)).data;

export const deletePrinterType = async (id) => (await apiService.delete(`/printer-type/${id}`)).data;
