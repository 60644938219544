export const PERMISISON_MERCHANT_TYPES = {
  MERCHANT: "MERCHANT",
  SUBSCRIBER: "SUBSCRIBER",
};

export const PERMISSION_MERCHANT_PAGES = {
  PAGE_ACCOUNT: "ACCOUNT",
  PAGE_DASHBOARD: "DASHBOARD",
  PAGE_PERMISSION: "PERMISSION",
  PAGE_SUBSCRIBER: "SUBSCRIBER",
};

export const PERMISISON_MERCHANT_OTHERS = {};
