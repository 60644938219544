import { createAction } from "../../utils/store.utils";

import AUTHENTICATION_ACTION_TYPES from "./authentication.type";

export const setUser = (user) =>
  createAction(AUTHENTICATION_ACTION_TYPES.SET_USER, user);

export const setAuthCheckLoading = (authCheckLoading) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_AUTH_CHECK_LOADING,
    authCheckLoading
  );
export const setAuthCheckSuccess = (authCheckSuccess) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_AUTH_CHECK_SUCCESS,
    authCheckSuccess
  );
export const setAuthCheckFailed = (authCheckFailed) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_AUTH_CHECK_FAILED,
    authCheckFailed
  );

export const setFetchMeLoading = (fetchMeLoading) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_FETCH_ME_LOADING,
    fetchMeLoading
  );
export const setFetchMeSuccess = (fetchMeSuccess) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_FETCH_ME_SUCCESS,
    fetchMeSuccess
  );
export const setFetchMeFailed = (fetchMeFailed) =>
  createAction(AUTHENTICATION_ACTION_TYPES.SET_FETCH_ME_FAILED, fetchMeFailed);

export const setUpdateMeLoading = (updateMeLoading) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_UPDATE_ME_LOADING,
    updateMeLoading
  );
export const setUpdateMeSuccess = (updateMeSuccess) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_UPDATE_ME_SUCCESS,
    updateMeSuccess
  );
export const setUpdateMeFailed = (updateMeFailed) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_UPDATE_ME_FAILED,
    updateMeFailed
  );

export const setSignInLoading = (signInLoading) =>
  createAction(AUTHENTICATION_ACTION_TYPES.SET_SIGN_IN_LOADING, signInLoading);
export const setSignInSuccess = (signInSuccess) =>
  createAction(AUTHENTICATION_ACTION_TYPES.SET_SIGN_IN_SUCCESS, signInSuccess);
export const setSignInFailed = (signInFailed) =>
  createAction(AUTHENTICATION_ACTION_TYPES.SET_SIGN_IN_FAILED, signInFailed);

export const setSignUpLoading = (signUpLoading) =>
  createAction(AUTHENTICATION_ACTION_TYPES.SET_SIGN_UP_LOADING, signUpLoading);
export const setSignUpSuccess = (signUpSuccess) =>
  createAction(AUTHENTICATION_ACTION_TYPES.SET_SIGN_UP_SUCCESS, signUpSuccess);
export const setSignUpFailed = (signUpFailed) =>
  createAction(AUTHENTICATION_ACTION_TYPES.SET_SIGN_UP_FAILED, signUpFailed);

export const setSignOutLoading = (signOutLoading) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_SIGN_OUT_LOADING,
    signOutLoading
  );
export const setSignOutSuccess = (signOutSuccess) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_SIGN_OUT_SUCCESS,
    signOutSuccess
  );
export const setSignOutFailed = (signOutFailed) =>
  createAction(AUTHENTICATION_ACTION_TYPES.SET_SIGN_OUT_FAILED, signOutFailed);

export const authCheckStart = () =>
  createAction(AUTHENTICATION_ACTION_TYPES.AUTH_CHECK_START);
export const fetchMeStart = () =>
  createAction(AUTHENTICATION_ACTION_TYPES.FETCH_ME_START);
export const updateMeStart = (request) =>
  createAction(AUTHENTICATION_ACTION_TYPES.UPDATE_ME_START, request);
export const signInStart = (request) =>
  createAction(AUTHENTICATION_ACTION_TYPES.SIGN_IN_START, request);
export const signUpStart = (request) =>
  createAction(AUTHENTICATION_ACTION_TYPES.SIGN_UP_START, request);
export const signOutStart = () =>
  createAction(AUTHENTICATION_ACTION_TYPES.SIGN_OUT_START);

export const setIsAuthCheckHitted = (isAuthCheckHitted) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_IS_AUTH_CHECK_HITTED,
    isAuthCheckHitted
  );
export const setIsFetchMeHitted = (isFetchMeHitted) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_IS_FETCH_ME_HITTED,
    isFetchMeHitted
  );
export const setIsUpdateMeHitted = (isUpdateMeHitted) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_IS_UPDATE_ME_HITTED,
    isUpdateMeHitted
  );
export const setIsSignInHitted = (isSignInHitted) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_IS_SIGN_IN_HITTED,
    isSignInHitted
  );
export const setIsSignUpHitted = (isSignUpHitted) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_IS_SIGN_UP_HITTED,
    isSignUpHitted
  );
export const setIsSignOutHitted = (isSignOutHitted) =>
  createAction(
    AUTHENTICATION_ACTION_TYPES.SET_IS_SIGN_OUT_HITTED,
    isSignOutHitted
  );

export const resetAuthReducer = () =>
  createAction(AUTHENTICATION_ACTION_TYPES.RESET_AUTH_REDUCER);
