import { createSelector } from "reselect";

const driverSelector = ({ driver }) => driver;

export const getDrivers = createSelector(
  [driverSelector],
  ({ drivers }) => drivers
);
export const getDriver = createSelector(
  [driverSelector],
  ({ driver }) => driver
);
export const getCreateDriver = createSelector(
  [driverSelector],
  ({ createDriver }) => createDriver
);
export const getUpdateDriver = createSelector(
  [driverSelector],
  ({ updateDriver }) => updateDriver
);

export const getIsDriversHasMore = createSelector(
  [driverSelector],
  ({ isDriversHasMore }) => isDriversHasMore
);

export const getFetchDriversSearch = createSelector(
  [driverSelector],
  ({ fetchDriversSearch }) => fetchDriversSearch
);
export const getFetchDriversPage = createSelector(
  [driverSelector],
  ({ fetchDriversPage }) => fetchDriversPage
);
export const getFetchDriversPerPage = createSelector(
  [driverSelector],
  ({ fetchDriversPerPage }) => fetchDriversPerPage
);
export const getFetchDriversIncludes = createSelector(
  [driverSelector],
  ({ fetchDriversIncludes }) => fetchDriversIncludes
);
export const getFetchDriversFilterMarketId = createSelector(
  [driverSelector],
  ({ fetchDriversFilterMarketId }) => fetchDriversFilterMarketId
);
export const getFetchDriversFilterGender = createSelector(
  [driverSelector],
  ({ fetchDriversFilterGender }) => fetchDriversFilterGender
);
export const getFetchDriversFilterStatus = createSelector(
  [driverSelector],
  ({ fetchDriversFilterStatus }) => fetchDriversFilterStatus
);
export const getFetchDriversFilterVehicleType = createSelector(
  [driverSelector],
  ({ fetchDriversFilterVehicleType }) => fetchDriversFilterVehicleType
);
export const getFetchDriversLoading = createSelector(
  [driverSelector],
  ({ fetchDriversLoading }) => fetchDriversLoading
);
export const getFetchDriversSuccess = createSelector(
  [driverSelector],
  ({ fetchDriversSuccess }) => fetchDriversSuccess
);
export const getFetchDriversFailed = createSelector(
  [driverSelector],
  ({ fetchDriversFailed }) => fetchDriversFailed
);

export const getFetchDriverLoading = createSelector(
  [driverSelector],
  ({ fetchDriverLoading }) => fetchDriverLoading
);
export const getFetchDriverSuccess = createSelector(
  [driverSelector],
  ({ fetchDriverSuccess }) => fetchDriverSuccess
);
export const getFetchDriverFailed = createSelector(
  [driverSelector],
  ({ fetchDriverFailed }) => fetchDriverFailed
);

export const getCreateDriverLoading = createSelector(
  [driverSelector],
  ({ createDriverLoading }) => createDriverLoading
);
export const getCreateDriverSuccess = createSelector(
  [driverSelector],
  ({ createDriverSuccess }) => createDriverSuccess
);
export const getCreateDriverFailed = createSelector(
  [driverSelector],
  ({ createDriverFailed }) => createDriverFailed
);

export const getUpdateDriverLoading = createSelector(
  [driverSelector],
  ({ updateDriverLoading }) => updateDriverLoading
);
export const getUpdateDriverSuccess = createSelector(
  [driverSelector],
  ({ updateDriverSuccess }) => updateDriverSuccess
);
export const getUpdateDriverFailed = createSelector(
  [driverSelector],
  ({ updateDriverFailed }) => updateDriverFailed
);

export const getIsFetchDriversHitted = createSelector(
  [driverSelector],
  ({ isFetchDriversHitted }) => isFetchDriversHitted
);
export const getIsFetchDriverHitted = createSelector(
  [driverSelector],
  ({ isFetchDriverHitted }) => isFetchDriverHitted
);
export const getIsCreateDriverHitted = createSelector(
  [driverSelector],
  ({ isCreateDriverHitted }) => isCreateDriverHitted
);
export const getIsUpdateDriverHitted = createSelector(
  [driverSelector],
  ({ isUpdateDriverHitted }) => isUpdateDriverHitted
);
