import apiService from "./api";

export const getPurchases = async (parameters) =>
  (await apiService.get("/v2/purchases", parameters)).data;
export const getPurchase = async (purchaseId) =>
  (await apiService.get(`/v2/purchases/${purchaseId}`)).data;
export const createPurchase = async (request) =>
  (await apiService.post("/v2/purchases", request)).data;
export const updatePurchase = async (purchaseId, request) =>
  (await apiService.post(`/v2/purchases/${purchaseId}`, request)).data;
export const deletePurchase = async (purchaseId) =>
  (await apiService.delete(`/v2/purchases/${purchaseId}`)).data;
export const calculatePurchase = async (request) =>
  (await apiService.post("/v2/purchases/calc", request)).data;
