const ANNOUNCEMENT_ACTION_TYPES = {
  RESET_ANNOUNCEMENT_REDUCER: "announcement/RESET_ANNOUNCEMENT_REDUCER",

  SET_ANNOUNCEMENTS: "announcement/SET_ANNOUNCEMENTS",
  SET_ANNOUNCEMENT: "announcement/SET_ANNOUNCEMENT",
  SET_CREATE_ANNOUNCEMENT: "announcement/SET_CREATE_ANNOUNCEMENT",
  SET_UPDATE_ANNOUNCEMENT: "announcement/SET_UPDATE_ANNOUNCEMENT",

  SET_IS_ANNOUNCEMENTS_HAS_MORE: "announcement/SET_IS_ANNOUNCEMENTS_HAS_MORE",

  SET_FETCH_ANNOUNCEMENTS_SEARCH: "announcement/SET_FETCH_ANNOUNCEMENTS_SEARCH",
  SET_FETCH_ANNOUNCEMENTS_SORT: "announcement/SET_FETCH_ANNOUNCEMENTS_SORT",
  SET_FETCH_ANNOUNCEMENTS_PAGE: "announcement/SET_FETCH_ANNOUNCEMENTS_PAGE",
  SET_FETCH_ANNOUNCEMENTS_PER_PAGE: "announcement/SET_FETCH_ANNOUNCEMENTS_PER_PAGE",
  SET_FETCH_ANNOUNCEMENTS_FILTER_DISPLAY_AT: "announcement/SET_FETCH_ANNOUNCEMENTS_FILTER_DISPLAY_AT",
  SET_FETCH_ANNOUNCEMENTS_FILTER_USER_ID: "announcement/SET_FETCH_ANNOUNCEMENTS_FILTER_USER_ID",
  SET_FETCH_ANNOUNCEMENTS_FILTER_ROLE: "announcement/SET_FETCH_ANNOUNCEMENTS_FILTER_ROLE",
  SET_FETCH_ANNOUNCEMENTS_FILTER_IS_READ: "announcement/SET_FETCH_ANNOUNCEMENTS_FILTER_IS_READ",
  SET_FETCH_ANNOUNCEMENTS_LOADING: "announcement/SET_FETCH_ANNOUNCEMENTS_LOADING",
  SET_FETCH_ANNOUNCEMENTS_SUCCESS: "announcement/SET_FETCH_ANNOUNCEMENTS_SUCCESS",
  SET_FETCH_ANNOUNCEMENTS_FAILED: "announcement/SET_FETCH_ANNOUNCEMENTS_FAILED",

  SET_FETCH_ANNOUNCEMENT_LOADING: "announcement/SET_FETCH_ANNOUNCEMENT_LOADING",
  SET_FETCH_ANNOUNCEMENT_SUCCESS: "announcement/SET_FETCH_ANNOUNCEMENT_SUCCESS",
  SET_FETCH_ANNOUNCEMENT_FAILED: "announcement/SET_FETCH_ANNOUNCEMENT_FAILED",

  SET_CREATE_ANNOUNCEMENT_LOADING: "announcement/SET_CREATE_ANNOUNCEMENT_LOADING",
  SET_CREATE_ANNOUNCEMENT_SUCCESS: "announcement/SET_CREATE_ANNOUNCEMENT_SUCCESS",
  SET_CREATE_ANNOUNCEMENT_FAILED: "announcement/SET_CREATE_ANNOUNCEMENT_FAILED",

  SET_UPDATE_ANNOUNCEMENT_LOADING: "announcement/SET_UPDATE_ANNOUNCEMENT_LOADING",
  SET_UPDATE_ANNOUNCEMENT_SUCCESS: "announcement/SET_UPDATE_ANNOUNCEMENT_SUCCESS",
  SET_UPDATE_ANNOUNCEMENT_FAILED: "announcement/SET_UPDATE_ANNOUNCEMENT_FAILED",

  SET_DELETE_ANNOUNCEMENT_LOADING: "announcement/SET_DELETE_ANNOUNCEMENT_LOADING",
  SET_DELETE_ANNOUNCEMENT_SUCCESS: "announcement/SET_DELETE_ANNOUNCEMENT_SUCCESS",
  SET_DELETE_ANNOUNCEMENT_FAILED: "announcement/SET_DELETE_ANNOUNCEMENT_FAILED",

  SET_READ_ANNOUNCEMENT_LOADING: "announcement/SET_READ_ANNOUNCEMENT_LOADING",
  SET_READ_ANNOUNCEMENT_SUCCESS: "announcement/SET_READ_ANNOUNCEMENT_SUCCESS",
  SET_READ_ANNOUNCEMENT_FAILED: "announcement/SET_READ_ANNOUNCEMENT_FAILED",

  APPEND_ANNOUNCEMENTS: "announcement/APPEND_ANNOUNCEMENTS",

  SET_IS_FETCH_ANNOUNCEMENTS_HITTED: "announcement/SET_IS_FETCH_ANNOUNCEMENTS_HITTED",
  SET_IS_FETCH_ANNOUNCEMENT_HITTED: "announcement/SET_IS_FETCH_ANNOUNCEMENT_HITTED",
  SET_IS_CREATE_ANNOUNCEMENT_HITTED: "announcement/SET_IS_CREATE_ANNOUNCEMENT_HITTED",
  SET_IS_UPDATE_ANNOUNCEMENT_HITTED: "announcement/SET_IS_UPDATE_ANNOUNCEMENT_HITTED",
  SET_IS_DELETE_ANNOUNCEMENT_HITTED: "announcement/SET_IS_DELETE_ANNOUNCEMENT_HITTED",
  SET_IS_READ_ANNOUNCEMENT_HITTED: "announcement/SET_IS_READ_ANNOUNCEMENT_HITTED",

  FETCH_ANNOUNCEMENTS_START: "announcement/FETCH_ANNOUNCEMENTS_START",
  FETCH_ANNOUNCEMENT_START: "announcement/FETCH_ANNOUNCEMENT_START",
  CREATE_ANNOUNCEMENT_START: "announcement/CREATE_ANNOUNCEMENT_START",
  UPDATE_ANNOUNCEMENT_START: "announcement/UPDATE_ANNOUNCEMENT_START",
  DELETE_ANNOUNCEMENT_START: "announcement/DELETE_ANNOUNCEMENT_START",
  READ_ANNOUNCEMENT_START: "announcement/READ_ANNOUNCEMENT_START",
};

export default ANNOUNCEMENT_ACTION_TYPES;
