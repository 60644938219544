import { takeLatest, put, all, call, select } from "redux-saga/effects";

import SECTOR_ACTION_TYPES from "./sector.type";

import {
  appendSelectSectors,
  appendSectors,
  setCreateSectorFailed,
  setCreateSectorLoading,
  setCreateSectorSuccess,
  setDeleteSectorFailed,
  setDeleteSectorLoading,
  setDeleteSectorSuccess,
  setFetchSelectSectorsFailed,
  setFetchSelectSectorsLoading,
  setFetchSelectSectorsPage,
  setFetchSelectSectorsSuccess,
  setFetchSectorFailed,
  setFetchSectorLoading,
  setFetchSectorsFailed,
  setFetchSectorsLoading,
  setFetchSectorsPage,
  setFetchSectorsSuccess,
  setFetchSectorSuccess,
  setIsSelectSectorsHasMore,
  setIsSectorsHasMore,
  setSelectSectors,
  setSector,
  setSectors,
  setUpdateSectorFailed,
  setUpdateSectorLoading,
  setUpdateSectorSuccess,
  setIsFetchSelectSectorsHitted,
  setIsFetchSectorsHitted,
  setIsFetchSectorHitted,
  setIsCreateSectorHitted,
  setIsDeleteSectorHitted,
  setIsUpdateSectorHitted,
} from "./sector.action";
import {
  getFetchSelectSectorsIncludes,
  getFetchSelectSectorsPage,
  getFetchSelectSectorsPerPage,
  getFetchSectorsIncludes,
  getFetchSectorsPage,
  getFetchSectorsPerPage,
  getFetchSectorsSearch,
  getFetchSectorsSort,
  getFetchSectorsKeyBy,
  getFetchSelectSectorsKeyBy,
  getFetchSelectSectorsSort,
  getFetchSelectSectorsSearch,
  getIsFetchSectorsHitted,
  getIsFetchSelectSectorsHitted,
} from "./sector.selector";

import {
  getSectors,
  getSector,
  createSector,
  updateSector,
  deleteSector,
} from "../../api/sector.api";

export function* _getSectors() {
  try {
    yield put(setFetchSectorsLoading(true));

    const search = yield select(getFetchSectorsSearch);
    const sort = yield select(getFetchSectorsSort);
    const key_by = yield select(getFetchSectorsKeyBy);
    const page = yield select(getFetchSectorsPage);
    const per_page = yield select(getFetchSectorsPerPage);
    const includes = yield select(getFetchSectorsIncludes);

    const parameters = { search, sort, key_by, page, per_page, includes };

    const {
      meta: { message },
      data: { data: sectors },
    } = yield call(getSectors, parameters);

    yield put(setIsFetchSectorsHitted(true));
    yield put(setIsSectorsHasMore(sectors.length > 0));

    if (page > 1) {
      yield put(appendSectors(sectors));
    } else {
      yield put(setSectors(sectors));
    }

    yield put(setFetchSectorsSuccess(message));
    yield put(setFetchSectorsLoading(false));
  } catch (error) {
    yield put(setFetchSectorsFailed(error));
    yield put(setFetchSectorsLoading(false));
  }
}
export function* _getSelectSectors() {
  try {
    yield put(setFetchSelectSectorsLoading(true));

    const search = yield select(getFetchSelectSectorsSearch);
    const sort = yield select(getFetchSelectSectorsSort);
    const key_by = yield select(getFetchSelectSectorsKeyBy);
    const page = yield select(getFetchSelectSectorsPage);
    const per_page = yield select(getFetchSelectSectorsPerPage);
    const includes = yield select(getFetchSelectSectorsIncludes);

    const parameters = { search, sort, key_by, page, per_page, includes };

    const {
      meta: { message },
      data: { data: sectors },
    } = yield call(getSectors, parameters);

    yield put(setIsFetchSelectSectorsHitted(true));
    yield put(setIsSelectSectorsHasMore(sectors.length > 0));

    if (page > 1) {
      yield put(appendSelectSectors(sectors));
    } else {
      yield put(setSelectSectors(sectors));
    }

    yield put(setFetchSelectSectorsSuccess(message));
    yield put(setFetchSelectSectorsLoading(false));
  } catch (error) {
    yield put(setFetchSelectSectorsFailed(error));
    yield put(setFetchSelectSectorsLoading(false));
  }
}
export function* _getSector({ payload: sectorId }) {
  try {
    yield put(setFetchSectorLoading(true));

    const {
      meta: { message },
      data: sector,
    } = yield call(getSector, sectorId);

    yield put(setIsFetchSectorHitted(true));
    yield put(setSector(sector));

    yield put(setFetchSectorSuccess(message));
    yield put(setFetchSectorLoading(false));
  } catch (error) {
    yield put(setFetchSectorFailed(error));
    yield put(setFetchSectorLoading(false));
  }
}
export function* _createSector({ payload: request }) {
  try {
    yield put(setCreateSectorLoading(true));

    const {
      meta: { message },
    } = yield call(createSector, request);

    yield put(setIsCreateSectorHitted(true));

    const isFetchSectorsHitted = yield select(getIsFetchSectorsHitted);
    const isFetchSelectSectorsHitted = yield select(
      getIsFetchSelectSectorsHitted
    );

    if (isFetchSectorsHitted) {
      yield put(setFetchSectorsPage(1));
      yield call(_getSectors);
    }
    if (isFetchSelectSectorsHitted) {
      yield put(setFetchSelectSectorsPage(1));
      yield put(_getSelectSectors);
    }

    yield put(setCreateSectorSuccess(message));
    yield put(setCreateSectorLoading(false));
  } catch (error) {
    yield put(setCreateSectorFailed(error));
    yield put(setCreateSectorLoading(false));
  }
}
export function* _updateSector({ payload: { sectorId, request } }) {
  try {
    yield put(setUpdateSectorLoading(true));

    const {
      meta: { message },
    } = yield call(updateSector, sectorId, request);

    yield put(setIsUpdateSectorHitted(true));

    const isFetchSectorsHitted = yield select(getIsFetchSectorsHitted);
    const isFetchSelectSectorsHitted = yield select(
      getIsFetchSelectSectorsHitted
    );

    if (isFetchSectorsHitted) {
      yield put(setFetchSectorsPage(1));
      yield call(_getSectors);
    }
    if (isFetchSelectSectorsHitted) {
      yield put(setFetchSelectSectorsPage(1));
      yield put(_getSelectSectors);
    }

    yield put(setUpdateSectorSuccess(message));
    yield put(setUpdateSectorLoading(false));
  } catch (error) {
    yield put(setUpdateSectorFailed(error));
    yield put(setUpdateSectorLoading(false));
  }
}
export function* _deleteSector({ payload: sectorId }) {
  try {
    yield put(setDeleteSectorLoading(true));

    const {
      meta: { message },
    } = yield call(deleteSector, sectorId);

    yield put(setIsDeleteSectorHitted(true));

    const isFetchSectorsHitted = yield select(getIsFetchSectorsHitted);
    const isFetchSelectSectorsHitted = yield select(
      getIsFetchSelectSectorsHitted
    );

    if (isFetchSectorsHitted) {
      yield put(setFetchSectorsPage(1));
      yield call(_getSectors);
    }
    if (isFetchSelectSectorsHitted) {
      yield put(setFetchSelectSectorsPage(1));
      yield put(_getSelectSectors);
    }

    yield put(setDeleteSectorSuccess(message));
    yield put(setDeleteSectorLoading(false));
  } catch (error) {
    yield put(setDeleteSectorFailed(error));
    yield put(setDeleteSectorLoading(false));
  }
}

export function* onFetchSectorsStart() {
  yield takeLatest(SECTOR_ACTION_TYPES.FETCH_SECTORS_START, _getSectors);
}
export function* onFetchSelectSectorsStart() {
  yield takeLatest(
    SECTOR_ACTION_TYPES.FETCH_SELECT_SECTORS_START,
    _getSelectSectors
  );
}
export function* onFetchSectorStart() {
  yield takeLatest(SECTOR_ACTION_TYPES.FETCH_SECTOR_START, _getSector);
}
export function* onCreateSectorStart() {
  yield takeLatest(SECTOR_ACTION_TYPES.CREATE_SECTOR_START, _createSector);
}
export function* onUpdateSectorStart() {
  yield takeLatest(SECTOR_ACTION_TYPES.UPDATE_SECTOR_START, _updateSector);
}
export function* onDeleteSectorStart() {
  yield takeLatest(SECTOR_ACTION_TYPES.DELETE_SECTOR_START, _deleteSector);
}

export function* sectorSaga() {
  yield all([
    call(onFetchSectorsStart),
    call(onFetchSelectSectorsStart),
    call(onFetchSectorStart),
    call(onCreateSectorStart),
    call(onUpdateSectorStart),
    call(onDeleteSectorStart),
  ]);
}
