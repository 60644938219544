import SELECT_MARKET_ACTION_TYPES from "./select-market.type";

const SELECT_MARKET_INITIAL_STATE = {
  markets: {},
  searchMarkets: {},

  isMarketsHasMore: true,
  isSearchMarketsHasMore: true,

  fetchMarketsParams: {},
  fetchMarketsLoading: false,
  fetchMarketsSuccess: null,
  fetchMarketsFailed: null,

  searchMarketsParams: {},
  searchMarketsLoading: false,
  searchMarketsSuccess: null,
  searchMarketsFailed: null,

  appendMarketsParams: {},
  appendMarketsLoading: false,
  appendMarketsSuccess: null,
  appendMarketsFailed: null,

  isFetchMarketsHitted: false,
  isSearchMarketsHitted: false,
  isAppendMarketsHitted: false,
};

export const selectMarketReducer = (
  state = SELECT_MARKET_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_MARKET_ACTION_TYPES.SET_MARKETS:
      return { ...state, markets: payload };
    case SELECT_MARKET_ACTION_TYPES.SET_SEARCH_MARKETS:
      return { ...state, searchMarkets: payload };

    case SELECT_MARKET_ACTION_TYPES.SET_IS_MARKETS_HAS_MORE:
      return { ...state, isMarketsHasMore: payload };
    case SELECT_MARKET_ACTION_TYPES.SET_IS_SEARCH_MARKETS_HAS_MORE:
      return { ...state, isSearchMarketsHasMore: payload };

    case SELECT_MARKET_ACTION_TYPES.SET_FETCH_MARKETS_PARAMS:
      return { ...state, fetchMarketsParams: payload };
    case SELECT_MARKET_ACTION_TYPES.SET_FETCH_MARKETS_LOADING:
      return { ...state, fetchMarketsLoading: payload };
    case SELECT_MARKET_ACTION_TYPES.SET_FETCH_MARKETS_SUCCESS:
      return { ...state, fetchMarketsSuccess: payload };
    case SELECT_MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FAILED:
      return { ...state, fetchMarketsFailed: payload };

    case SELECT_MARKET_ACTION_TYPES.SET_SEARCH_MARKETS_PARAMS:
      return { ...state, searchMarketsParams: payload };
    case SELECT_MARKET_ACTION_TYPES.SET_SEARCH_MARKETS_LOADING:
      return { ...state, searchMarketsLoading: payload };
    case SELECT_MARKET_ACTION_TYPES.SET_SEARCH_MARKETS_SUCCESS:
      return { ...state, searchMarketsSuccess: payload };
    case SELECT_MARKET_ACTION_TYPES.SET_SEARCH_MARKETS_FAILED:
      return { ...state, searchMarketsFailed: payload };

    case SELECT_MARKET_ACTION_TYPES.SET_APPEND_MARKETS_PARAMS:
      return { ...state, appendMarketsParams: payload };
    case SELECT_MARKET_ACTION_TYPES.SET_APPEND_MARKETS_LOADING:
      return { ...state, appendMarketsLoading: payload };
    case SELECT_MARKET_ACTION_TYPES.SET_APPEND_MARKETS_SUCCESS:
      return { ...state, appendMarketsSuccess: payload };
    case SELECT_MARKET_ACTION_TYPES.SET_APPEND_MARKETS_FAILED:
      return { ...state, appendMarketsFailed: payload };

    case SELECT_MARKET_ACTION_TYPES.SET_IS_FETCH_MARKETS_HITTED:
      return { ...state, isFetchMarketsHitted: payload };
    case SELECT_MARKET_ACTION_TYPES.SET_IS_SEARCH_MARKETS_HITTED:
      return { ...state, isSearchMarketsHitted: payload };
    case SELECT_MARKET_ACTION_TYPES.SET_IS_APPEND_MARKETS_HITTED:
      return { ...state, isAppendMarketsHitted: payload };

    case SELECT_MARKET_ACTION_TYPES.APPEND_MARKETS:
      return {
        ...state,
        markets: { ...state.markets, ...payload },
      };
    case SELECT_MARKET_ACTION_TYPES.APPEND_SEARCH_MARKETS:
      return {
        ...state,
        searchMarkets: { ...state.searchMarkets, ...payload },
      };

    case SELECT_MARKET_ACTION_TYPES.RESET_SELECT_MARKET_REDUCER:
      return SELECT_MARKET_INITIAL_STATE;
    default:
      return state;
  }
};
