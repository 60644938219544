import { createAction } from "../../utils/store.utils";

import FORM_ORDER_ACTION_TYPES from "./form-order.type";

export const setCreateInputValues = (createInputValues) =>
  createAction(FORM_ORDER_ACTION_TYPES.CREATE_INPUT_VALUES, createInputValues);
export const setEditInputValues = (editInputValues) =>
  createAction(FORM_ORDER_ACTION_TYPES.EDIT_INPUT_VALUES, editInputValues);

export const resetFormOrderReducer = () =>
  createAction(FORM_ORDER_ACTION_TYPES.RESET_FORM_ORDER_REDUCER);
