const SUBSCRIBER_ACTION_TYPES = {
  RESET_SUBSCRIBER_REDUCER: "subscriber/RESET_SUBSCRIBER_REDUCER",

  SET_SUBSCRIBERS: "subscriber/SET_SUBSCRIBERS",
  SET_SELECT_SUBSCRIBERS: "subscriber/SET_SELECT_SUBSCRIBERS",
  SET_SUBSCRIBER: "subscriber/SET_SUBSCRIBER",
  SET_SUBSCRIBER_REPORT: "subscriber/SET_SUBSCRIBER_REPORT",

  SET_IS_SUBSCRIBERS_HAS_MORE: "subscriber/SET_IS_SUBSCRIBERS_HAS_MORE",
  SET_IS_SELECT_SUBSCRIBERS_HAS_MORE: "subscriber/SET_IS_SELECT_SUBSCRIBERS_HAS_MORE",

  SET_FETCH_SUBSCRIBERS_SEARCH: "subscriber/SET_FETCH_SUBSCRIBERS_SEARCH",
  SET_FETCH_SUBSCRIBERS_PAGE: "subscriber/SET_FETCH_SUBSCRIBERS_PAGE",
  SET_FETCH_SUBSCRIBERS_PER_PAGE: "subscriber/SET_FETCH_SUBSCRIBERS_PER_PAGE",
  SET_FETCH_SUBSCRIBERS_INCLUDES: "subscriber/SET_FETCH_SUBSCRIBERS_INCLUDES",
  SET_FETCH_SUBSCRIBERS_FILTER_MARKET_ID: "subscriber/SET_FETCH_SUBSCRIBERS_FILTER_MARKET_ID",
  SET_FETCH_SUBSCRIBERS_FILTER_BRANCH_ID: "subscriber/SET_FETCH_SUBSCRIBERS_FILTER_BRANCH_ID",
  SET_FETCH_SUBSCRIBERS_FILTER_PERMISSION_ID: "subscriber/SET_FETCH_SUBSCRIBERS_FILTER_PERMISSION_ID",
  SET_FETCH_SUBSCRIBERS_LOADING: "subscriber/SET_FETCH_SUBSCRIBERS_LOADING",
  SET_FETCH_SUBSCRIBERS_SUCCESS: "subscriber/SET_FETCH_SUBSCRIBERS_SUCCESS",
  SET_FETCH_SUBSCRIBERS_FAILED: "subscriber/SET_FETCH_SUBSCRIBERS_FAILED",

  SET_FETCH_SELECT_SUBSCRIBERS_SEARCH: "subscriber/SET_FETCH_SELECT_SUBSCRIBERS_SEARCH",
  SET_FETCH_SELECT_SUBSCRIBERS_PAGE: "subscriber/SET_FETCH_SELECT_SUBSCRIBERS_PAGE",
  SET_FETCH_SELECT_SUBSCRIBERS_PER_PAGE: "subscriber/SET_FETCH_SELECT_SUBSCRIBERS_PER_PAGE",
  SET_FETCH_SELECT_SUBSCRIBERS_INCLUDES: "subscriber/SET_FETCH_SELECT_SUBSCRIBERS_INCLUDES",
  SET_FETCH_SELECT_SUBSCRIBERS_FILTER_MARKET_ID: "subscriber/SET_FETCH_SELECT_SUBSCRIBERS_FILTER_MARKET_ID",
  SET_FETCH_SELECT_SUBSCRIBERS_FILTER_BRANCH_ID: "subscriber/SET_FETCH_SELECT_SUBSCRIBERS_FILTER_BRANCH_ID",
  SET_FETCH_SELECT_SUBSCRIBERS_FILTER_PERMISSION_ID: "subscriber/SET_FETCH_SELECT_SUBSCRIBERS_FILTER_PERMISSION_ID",
  SET_FETCH_SELECT_SUBSCRIBERS_LOADING: "subscriber/SET_FETCH_SELECT_SUBSCRIBERS_LOADING",
  SET_FETCH_SELECT_SUBSCRIBERS_SUCCESS: "subscriber/SET_FETCH_SELECT_SUBSCRIBERS_SUCCESS",
  SET_FETCH_SELECT_SUBSCRIBERS_FAILED: "subscriber/SET_FETCH_SELECT_SUBSCRIBERS_FAILED",

  SET_FETCH_SUBSCRIBER_LOADING: "subscriber/SET_FETCH_SUBSCRIBER_LOADING",
  SET_FETCH_SUBSCRIBER_SUCCESS: "subscriber/SET_FETCH_SUBSCRIBER_SUCCESS",
  SET_FETCH_SUBSCRIBER_FAILED: "subscriber/SET_FETCH_SUBSCRIBER_FAILED",

  SET_FETCH_SUBSCRIBER_REPORT_START_AT: "subscriber/SET_FETCH_SUBSCRIBER_REPORT_START_AT",
  SET_FETCH_SUBSCRIBER_REPORT_END_AT: "subscriber/SET_FETCH_SUBSCRIBER_REPORT_END_AT",
  SET_FETCH_SUBSCRIBER_REPORT_LOADING: "subscriber/SET_FETCH_SUBSCRIBER_REPORT_LOADING",
  SET_FETCH_SUBSCRIBER_REPORT_SUCCESS: "subscriber/SET_FETCH_SUBSCRIBER_REPORT_SUCCESS",
  SET_FETCH_SUBSCRIBER_REPORT_FAILED: "subscriber/SET_FETCH_SUBSCRIBER_REPORT_FAILED",

  SET_CREATE_SUBSCRIBER_LOADING: "subscriber/SET_CREATE_SUBSCRIBER_LOADING",
  SET_CREATE_SUBSCRIBER_SUCCESS: "subscriber/SET_CREATE_SUBSCRIBER_SUCCESS",
  SET_CREATE_SUBSCRIBER_FAILED: "subscriber/SET_CREATE_SUBSCRIBER_FAILED",

  SET_UPDATE_SUBSCRIBER_LOADING: "subscriber/SET_UPDATE_SUBSCRIBER_LOADING",
  SET_UPDATE_SUBSCRIBER_SUCCESS: "subscriber/SET_UPDATE_SUBSCRIBER_SUCCESS",
  SET_UPDATE_SUBSCRIBER_FAILED: "subscriber/SET_UPDATE_SUBSCRIBER_FAILED",

  SET_DELETE_SUBSCRIBER_LOADING: "subscriber/SET_DELETE_SUBSCRIBER_LOADING",
  SET_DELETE_SUBSCRIBER_SUCCESS: "subscriber/SET_DELETE_SUBSCRIBER_SUCCESS",
  SET_DELETE_SUBSCRIBER_FAILED: "subscriber/SET_DELETE_SUBSCRIBER_FAILED",

  APPEND_SUBSCRIBERS: "subscriber/APPEND_SUBSCRIBERS",
  APPEND_SELECT_SUBSCRIBERS: "subscriber/APPEND_SELECT_SUBSCRIBERS",

  SET_IS_FETCH_SUBSCRIBERS_HITTED: "subscriber/SET_IS_FETCH_SUBSCRIBERS_HITTED",
  SET_IS_FETCH_SELECT_SUBSCRIBERS_HITTED: "subscriber/SET_IS_FETCH_SELECT_SUBSCRIBERS_HITTED",
  SET_IS_FETCH_SUBSCRIBER_REPORT_HITTED: "subscriber/SET_IS_FETCH_SUBSCRIBER_REPORT_HITTED",
  SET_IS_FETCH_SUBSCRIBER_HITTED: "subscriber/SET_IS_FETCH_SUBSCRIBER_HITTED",
  SET_IS_CREATE_SUBSCRIBER_HITTED: "subscriber/SET_IS_CREATE_SUBSCRIBER_HITTED",
  SET_IS_UPDATE_SUBSCRIBER_HITTED: "subscriber/SET_IS_UPDATE_SUBSCRIBER_HITTED",
  SET_IS_DELETE_SUBSCRIBER_HITTED: "subscriber/SET_IS_DELETE_SUBSCRIBER_HITTED",

  FETCH_SUBSCRIBERS_START: "subscriber/FETCH_SUBSCRIBERS_START",
  FETCH_SELECT_SUBSCRIBERS_START: "subscriber/FETCH_SELECT_SUBSCRIBERS_START",
  FETCH_SUBSCRIBER_REPORT_START: "subscriber/FETCH_SUBSCRIBER_REPORT_START",
  FETCH_SUBSCRIBER_START: "subscriber/FETCH_SUBSCRIBER_START",
  CREATE_SUBSCRIBER_START: "subscriber/CREATE_SUBSCRIBER_START",
  UPDATE_SUBSCRIBER_START: "subscriber/UPDATE_SUBSCRIBER_START",
  DELETE_SUBSCRIBER_START: "subscriber/DELETE_SUBSCRIBER_START",
};

export default SUBSCRIBER_ACTION_TYPES;
