const SELECT_BRANCH_ACTION_TYPES = {
  RESET_SELECT_BRANCH_REDUCER: "select-branch/RESET_SELECT_BRANCH_REDUCER",

  SET_BRANCHES: "select-branch/SET_BRANCHES",
  SET_SEARCH_BRANCHES: "select-branch/SET_SEARCH_BRANCHES",

  APPEND_BRANCHES: "select-branch/APPEND_BRANCHES",
  APPEND_SEARCH_BRANCHES: "select-branch/APPEND_SEARCH_BRANCHES",

  SET_IS_BRANCHES_HAS_MORE: "select-branch/SET_IS_BRANCHES_HAS_MORE",
  SET_IS_SEARCH_BRANCHES_HAS_MORE:
    "select-branch/SET_IS_SEARCH_BRANCHES_HAS_MORE",

  SET_FETCH_BRANCHES_PARAMS: "select-branch/SET_FETCH_BRANCHES_PARAMS",
  SET_FETCH_BRANCHES_LOADING: "select-branch/SET_FETCH_BRANCHES_LOADING",
  SET_FETCH_BRANCHES_SUCCESS: "select-branch/SET_FETCH_BRANCHES_SUCCESS",
  SET_FETCH_BRANCHES_FAILED: "select-branch/SET_FETCH_BRANCHES_FAILED",

  SET_SEARCH_BRANCHES_PARAMS: "select-branch/SET_SEARCH_BRANCHES_PARAMS",
  SET_SEARCH_BRANCHES_LOADING: "select-branch/SET_SEARCH_BRANCHES_LOADING",
  SET_SEARCH_BRANCHES_SUCCESS: "select-branch/SET_SEARCH_BRANCHES_SUCCESS",
  SET_SEARCH_BRANCHES_FAILED: "select-branch/SET_SEARCH_BRANCHES_FAILED",

  SET_APPEND_BRANCHES_PARAMS: "select-branch/SET_APPEND_BRANCHES_PARAMS",
  SET_APPEND_BRANCHES_LOADING: "select-branch/SET_APPEND_BRANCHES_LOADING",
  SET_APPEND_BRANCHES_SUCCESS: "select-branch/SET_APPEND_BRANCHES_SUCCESS",
  SET_APPEND_BRANCHES_FAILED: "select-branch/SET_APPEND_BRANCHES_FAILED",

  SET_IS_FETCH_BRANCHES_HITTED: "select-branch/SET_IS_FETCH_BRANCHES_HITTED",
  SET_IS_SEARCH_BRANCHES_HITTED: "select-branch/SET_IS_SEARCH_BRANCHES_HITTED",
  SET_IS_APPEND_BRANCHES_HITTED: "select-branch/SET_IS_APPEND_BRANCHES_HITTED",

  FETCH_BRANCHES_START: "select-branch/FETCH_BRANCHES_START",
  SEARCH_BRANCHES_START: "select-branch/SEARCH_BRANCHES_START",
  APPEND_BRANCHES_START: "select-branch/APPEND_BRANCHES_START",
};

export default SELECT_BRANCH_ACTION_TYPES;
