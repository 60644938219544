import { createSelector } from "reselect";

const exportSelector = (state) => state.export;

export const getReportOrderFileUrl = createSelector([exportSelector], ({ reportOrderFileUrl }) => reportOrderFileUrl);
export const getReportOrderFileName = createSelector(
  [exportSelector],
  ({ reportOrderFileName }) => reportOrderFileName
);
export const getReportSummaryFileUrl = createSelector(
  [exportSelector],
  ({ reportSummaryFileUrl }) => reportSummaryFileUrl
);
export const getReportSummaryFileName = createSelector(
  [exportSelector],
  ({ reportSummaryFileName }) => reportSummaryFileName
);

export const getCustomerReportUrlFile = createSelector(
  [exportSelector],
  ({ customerReportUrlFile }) => customerReportUrlFile
);
export const getCustomerReportFileName = createSelector(
  [exportSelector],
  ({ customerReportFileName }) => customerReportFileName
);
export const getIngredientReportUrlFile = createSelector(
  [exportSelector],
  ({ ingredientReportUrlFile }) => ingredientReportUrlFile
);
export const getIngredientHistoryReportFileName = createSelector(
  [exportSelector],
  ({ ingredientHistoryReportFileName }) => ingredientHistoryReportFileName
);
export const getIngredientHistoryReportUrlFile = createSelector(
  [exportSelector],
  ({ ingredientHistoryReportUrlFile }) => ingredientHistoryReportUrlFile
);
export const getIngredientReportFileName = createSelector(
  [exportSelector],
  ({ ingredientReportFileName }) => ingredientReportFileName
);
export const getTableQrCodeUrlFile = createSelector([exportSelector], ({ tableQrCodeUrlFile }) => tableQrCodeUrlFile);
export const getTableQrCodeFileName = createSelector(
  [exportSelector],
  ({ tableQrCodeFileName }) => tableQrCodeFileName
);
export const getProductBarcodeUrlFile = createSelector(
  [exportSelector],
  ({ productBarcodeUrlFile }) => productBarcodeUrlFile
);
export const getProductBarcodeFileName = createSelector(
  [exportSelector],
  ({ productBarcodeFileName }) => productBarcodeFileName
);
export const getSubscriptionReceiptUrlFile = createSelector(
  [exportSelector],
  ({ subscriptionReceiptUrlFile }) => subscriptionReceiptUrlFile
);
export const getSubscriptionReceiptFileName = createSelector(
  [exportSelector],
  ({ subscriptionReceiptFileName }) => subscriptionReceiptFileName
);

export const getExportReportOrderFilterMarketId = createSelector(
  [exportSelector],
  ({ exportReportOrderFilterMarketId }) => exportReportOrderFilterMarketId
);
export const getExportReportOrderFilterBranchId = createSelector(
  [exportSelector],
  ({ exportReportOrderFilterBranchId }) => exportReportOrderFilterBranchId
);
export const getExportReportOrderFilterPaymentMethodKey = createSelector(
  [exportSelector],
  ({ exportReportOrderFilterPaymentMethodKey }) => exportReportOrderFilterPaymentMethodKey
);
export const getExportReportOrderFilterCashierId = createSelector(
  [exportSelector],
  ({ exportReportOrderFilterCashierId }) => exportReportOrderFilterCashierId
);
export const getExportReportOrderFilterCustomerId = createSelector(
  [exportSelector],
  ({ exportReportOrderFilterCustomerId }) => exportReportOrderFilterCustomerId
);
export const getExportReportOrderFilterCreatedAtBefore = createSelector(
  [exportSelector],
  ({ exportReportOrderFilterCreatedAtBefore }) => exportReportOrderFilterCreatedAtBefore
);
export const getExportReportOrderFilterCreatedAtAfter = createSelector(
  [exportSelector],
  ({ exportReportOrderFilterCreatedAtAfter }) => exportReportOrderFilterCreatedAtAfter
);
export const getExportReportOrderLoading = createSelector(
  [exportSelector],
  ({ exportReportOrderLoading }) => exportReportOrderLoading
);
export const getExportReportOrderSuccess = createSelector(
  [exportSelector],
  ({ exportReportOrderSuccess }) => exportReportOrderSuccess
);
export const getExportReportOrderFailed = createSelector(
  [exportSelector],
  ({ exportReportOrderFailed }) => exportReportOrderFailed
);

export const getExportReportSummaryFilterMarketId = createSelector(
  [exportSelector],
  ({ exportReportSummaryFilterMarketId }) => exportReportSummaryFilterMarketId
);
export const getExportReportSummaryFilterBranchId = createSelector(
  [exportSelector],
  ({ exportReportSummaryFilterBranchId }) => exportReportSummaryFilterBranchId
);
export const getExportReportSummaryFilterPaymentMethodKey = createSelector(
  [exportSelector],
  ({ exportReportSummaryFilterPaymentMethodKey }) => exportReportSummaryFilterPaymentMethodKey
);
export const getExportReportSummaryFilterCashierId = createSelector(
  [exportSelector],
  ({ exportReportSummaryFilterCashierId }) => exportReportSummaryFilterCashierId
);
export const getExportReportSummaryFilterCustomerId = createSelector(
  [exportSelector],
  ({ exportReportSummaryFilterCustomerId }) => exportReportSummaryFilterCustomerId
);
export const getExportReportSummaryFilterCreatedAtBefore = createSelector(
  [exportSelector],
  ({ exportReportSummaryFilterCreatedAtBefore }) => exportReportSummaryFilterCreatedAtBefore
);
export const getExportReportSummaryFilterCreatedAtAfter = createSelector(
  [exportSelector],
  ({ exportReportSummaryFilterCreatedAtAfter }) => exportReportSummaryFilterCreatedAtAfter
);
export const getExportReportSummaryLoading = createSelector(
  [exportSelector],
  ({ exportReportSummaryLoading }) => exportReportSummaryLoading
);
export const getExportReportSummarySuccess = createSelector(
  [exportSelector],
  ({ exportReportSummarySuccess }) => exportReportSummarySuccess
);
export const getExportReportSummaryFailed = createSelector(
  [exportSelector],
  ({ exportReportSummaryFailed }) => exportReportSummaryFailed
);

export const getExportCustomerReportFilterMarketId = createSelector(
  [exportSelector],
  ({ exportCustomerReportFilterMarketId }) => exportCustomerReportFilterMarketId
);
export const getExportCustomerReportFilterCustomersIds = createSelector(
  [exportSelector],
  ({ exportCustomerReportFilterCustomersIds }) => exportCustomerReportFilterCustomersIds
);
export const getExportCustomerReportLoading = createSelector(
  [exportSelector],
  ({ exportCustomerReportLoading }) => exportCustomerReportLoading
);
export const getExportCustomerReportSuccess = createSelector(
  [exportSelector],
  ({ exportCustomerReportSuccess }) => exportCustomerReportSuccess
);
export const getExportCustomerReportFailed = createSelector(
  [exportSelector],
  ({ exportCustomerReportFailed }) => exportCustomerReportFailed
);
export const getExportIngredientReportFilterPeriodType = createSelector(
  [exportSelector],
  ({ exportIngredientReportFilterPeriodType }) => exportIngredientReportFilterPeriodType
);
export const getExportIngredientReportFilterStartAt = createSelector(
  [exportSelector],
  ({ exportIngredientReportFilterStartAt }) => exportIngredientReportFilterStartAt
);
export const getExportIngredientReportFilterEndAt = createSelector(
  [exportSelector],
  ({ exportIngredientReportFilterEndAt }) => exportIngredientReportFilterEndAt
);
export const getExportIngredientReportFilterMarketId = createSelector(
  [exportSelector],
  ({ exportIngredientReportFilterMarketId }) => exportIngredientReportFilterMarketId
);
export const getExportIngredientReportFilterBranchId = createSelector(
  [exportSelector],
  ({ exportIngredientReportFilterBranchId }) => exportIngredientReportFilterBranchId
);
export const getExportIngredientReportFilterIngredientsIds = createSelector(
  [exportSelector],
  ({ exportIngredientReportFilterIngredientsIds }) => exportIngredientReportFilterIngredientsIds
);
export const getExportIngredientReportLoading = createSelector(
  [exportSelector],
  ({ exportIngredientReportLoading }) => exportIngredientReportLoading
);
export const getExportIngredientReportSuccess = createSelector(
  [exportSelector],
  ({ exportIngredientReportSuccess }) => exportIngredientReportSuccess
);
export const getExportIngredientReportFailed = createSelector(
  [exportSelector],
  ({ exportIngredientReportFailed }) => exportIngredientReportFailed
);

export const getExportIngredientHistoryReportFilterPeriodType = createSelector(
  [exportSelector],
  ({ exportIngredientHistoryReportFilterPeriodType }) => exportIngredientHistoryReportFilterPeriodType
);
export const getExportIngredientHistoryReportFilterStartAt = createSelector(
  [exportSelector],
  ({ exportIngredientHistoryReportFilterStartAt }) => exportIngredientHistoryReportFilterStartAt
);
export const getExportIngredientHistoryReportFilterEndAt = createSelector(
  [exportSelector],
  ({ exportIngredientHistoryReportFilterEndAt }) => exportIngredientHistoryReportFilterEndAt
);
export const getExportIngredientHistoryReportFilterMarketId = createSelector(
  [exportSelector],
  ({ exportIngredientHistoryReportFilterMarketId }) => exportIngredientHistoryReportFilterMarketId
);
export const getExportIngredientHistoryReportFilterBranchId = createSelector(
  [exportSelector],
  ({ exportIngredientHistoryReportFilterBranchId }) => exportIngredientHistoryReportFilterBranchId
);
export const getExportIngredientHistoryReportFilterIngredientsIds = createSelector(
  [exportSelector],
  ({ exportIngredientHistoryReportFilterIngredientsIds }) => exportIngredientHistoryReportFilterIngredientsIds
);
export const getExportIngredientHistoryReportLoading = createSelector(
  [exportSelector],
  ({ exportIngredientHistoryReportLoading }) => exportIngredientHistoryReportLoading
);
export const getExportIngredientHistoryReportSuccess = createSelector(
  [exportSelector],
  ({ exportIngredientHistoryReportSuccess }) => exportIngredientHistoryReportSuccess
);
export const getExportIngredientHistoryReportFailed = createSelector(
  [exportSelector],
  ({ exportIngredientHistoryReportFailed }) => exportIngredientHistoryReportFailed
);

export const getExportTableQrCodeFilterMarketId = createSelector(
  [exportSelector],
  ({ exportTableQrCodeFilterMarketId }) => exportTableQrCodeFilterMarketId
);
export const getExportTableQrCodeFilterBranchId = createSelector(
  [exportSelector],
  ({ exportTableQrCodeFilterBranchId }) => exportTableQrCodeFilterBranchId
);
export const getExportTableQrCodeFilterTablesIds = createSelector(
  [exportSelector],
  ({ exportTableQrCodeFilterTablesIds }) => exportTableQrCodeFilterTablesIds
);
export const getExportTableQrCodeLoading = createSelector(
  [exportSelector],
  ({ exportTableQrCodeLoading }) => exportTableQrCodeLoading
);
export const getExportTableQrCodeSuccess = createSelector(
  [exportSelector],
  ({ exportTableQrCodeSuccess }) => exportTableQrCodeSuccess
);
export const getExportTableQrCodeFailed = createSelector(
  [exportSelector],
  ({ exportTableQrCodeFailed }) => exportTableQrCodeFailed
);

export const getExportProductBarcodeFilterMarketId = createSelector(
  [exportSelector],
  ({ exportProductBarcodeFilterMarketId }) => exportProductBarcodeFilterMarketId
);
export const getExportProductBarcodeFilterBranchId = createSelector(
  [exportSelector],
  ({ exportProductBarcodeFilterBranchId }) => exportProductBarcodeFilterBranchId
);
export const getExportProductBarcodeFilterProductsIds = createSelector(
  [exportSelector],
  ({ exportProductBarcodeFilterProductsIds }) => exportProductBarcodeFilterProductsIds
);
export const getExportProductBarcodeLoading = createSelector(
  [exportSelector],
  ({ exportProductBarcodeLoading }) => exportProductBarcodeLoading
);
export const getExportProductBarcodeSuccess = createSelector(
  [exportSelector],
  ({ exportProductBarcodeSuccess }) => exportProductBarcodeSuccess
);
export const getExportProductBarcodeFailed = createSelector(
  [exportSelector],
  ({ exportProductBarcodeFailed }) => exportProductBarcodeFailed
);

export const getExportSubscriptionReceiptLoading = createSelector(
  [exportSelector],
  ({ exportSubscriptionReceiptLoading }) => exportSubscriptionReceiptLoading
);
export const getExportSubscriptionReceiptSuccess = createSelector(
  [exportSelector],
  ({ exportSubscriptionReceiptSuccess }) => exportSubscriptionReceiptSuccess
);
export const getExportSubscriptionReceiptFailed = createSelector(
  [exportSelector],
  ({ exportSubscriptionReceiptFailed }) => exportSubscriptionReceiptFailed
);

export const getIsExportReportOrderHitted = createSelector(
  [exportSelector],
  ({ isExportReportOrderHitted }) => isExportReportOrderHitted
);
export const getIsExportReportSummaryHitted = createSelector(
  [exportSelector],
  ({ isExportReportSummaryHitted }) => isExportReportSummaryHitted
);
export const getIsExportCustomerReportHitted = createSelector(
  [exportSelector],
  ({ isExportCustomerReportHitted }) => isExportCustomerReportHitted
);
export const getIsExportIngredientReportHitted = createSelector(
  [exportSelector],
  ({ isExportIngredientReportHitted }) => isExportIngredientReportHitted
);
export const getIsExportIngredientHistoryReportHitted = createSelector(
  [exportSelector],
  ({ isExportIngredientHistoryReportHitted }) => isExportIngredientHistoryReportHitted
);
export const getIsExportTableQrCodeHitted = createSelector(
  [exportSelector],
  ({ isExportTableQrCodeHitted }) => isExportTableQrCodeHitted
);
export const getIsExportProductBarcodeHitted = createSelector(
  [exportSelector],
  ({ isExportProductBarcodeHitted }) => isExportProductBarcodeHitted
);
export const getIsExportSubscriptionReceiptHitted = createSelector(
  [exportSelector],
  ({ isExportSubscriptionReceiptHitted }) => isExportSubscriptionReceiptHitted
);
