import { takeLatest, put, all, call, select } from "redux-saga/effects";

import CUSTOMER_ACTION_TYPES from "./customer.type";

import {
  appendCustomers,
  appendSearchCustomers,
  setCreateCustomer,
  setCreateCustomerFailed,
  setCreateCustomerLoading,
  setCreateCustomerSuccess,
  setCustomer,
  setCustomers,
  setFetchCustomerFailed,
  setFetchCustomerLoading,
  setFetchCustomersFailed,
  setFetchCustomersLoading,
  setFetchCustomersPage,
  setFetchCustomersSuccess,
  setFetchCustomerSuccess,
  setFetchSearchCustomersFailed,
  setFetchSearchCustomersLoading,
  setFetchSearchCustomersSuccess,
  setIsCreateCustomerHitted,
  setIsCustomersHasMore,
  setIsFetchCustomerHitted,
  setIsFetchCustomersHitted,
  setIsFetchSearchCustomersHitted,
  setIsSearchCustomersHasMore,
  setIsUpdateCustomerHitted,
  setSearchCustomers,
  setUpdateCustomer,
  setUpdateCustomerFailed,
  setUpdateCustomerLoading,
  setUpdateCustomerSuccess,
} from "./customer.action";
import {
  getFetchCustomersFilterMarketId,
  getFetchCustomersIncludes,
  getFetchCustomersKeyBy,
  getFetchCustomersPage,
  getFetchCustomersPerPage,
  getFetchCustomersSearch,
  getFetchCustomersSort,
  getFetchSearchCustomersFilterMarketId,
  getFetchSearchCustomersIncludes,
  getFetchSearchCustomersKeyBy,
  getFetchSearchCustomersPage,
  getFetchSearchCustomersPerPage,
  getFetchSearchCustomersSearch,
  getFetchSearchCustomersSort,
  getIsFetchCustomersHitted,
} from "./customer.selector";

import {
  getCustomers,
  getCustomer,
  createCustomer,
  updateCustomer,
} from "../../api/customer.api";

export function* _getCustomers() {
  try {
    yield put(setFetchCustomersLoading(true));

    const search = yield select(getFetchCustomersSearch);
    const sort = yield select(getFetchCustomersSort);
    const key_by = yield select(getFetchCustomersKeyBy);
    const page = yield select(getFetchCustomersPage);
    const per_page = yield select(getFetchCustomersPerPage);
    const includes = yield select(getFetchCustomersIncludes);
    const market_id = yield select(getFetchCustomersFilterMarketId);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id },
    };

    const {
      meta: { message },
      data: { data: customers },
    } = yield call(getCustomers, parameters);

    yield put(setIsFetchCustomersHitted(true));
    yield put(setIsCustomersHasMore(customers.length > 0));

    if (page > 1) {
      yield put(appendCustomers(customers));
    } else {
      yield put(setCustomers(customers));
    }

    yield put(setFetchCustomersSuccess(message));
    yield put(setFetchCustomersLoading(false));
  } catch (error) {
    yield put(setFetchCustomersFailed(error));
    yield put(setFetchCustomersLoading(false));
  }
}
export function* _getSearchCustomers() {
  try {
    yield put(setFetchSearchCustomersLoading(true));

    const search = yield select(getFetchSearchCustomersSearch);
    const sort = yield select(getFetchSearchCustomersSort);
    const key_by = yield select(getFetchSearchCustomersKeyBy);
    const page = yield select(getFetchSearchCustomersPage);
    const per_page = yield select(getFetchSearchCustomersPerPage);
    const includes = yield select(getFetchSearchCustomersIncludes);
    const market_id = yield select(getFetchSearchCustomersFilterMarketId);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id },
    };

    const {
      meta: { message },
      data: { data: customers },
    } = yield call(getCustomers, parameters);

    yield put(setIsFetchSearchCustomersHitted(true));
    yield put(setIsSearchCustomersHasMore(customers.length > 0));

    if (page > 1) {
      yield put(appendSearchCustomers(customers));
    } else {
      yield put(setSearchCustomers(customers));
    }

    yield put(setFetchSearchCustomersSuccess(message));
    yield put(setFetchSearchCustomersLoading(false));
  } catch (error) {
    yield put(setFetchSearchCustomersFailed(error));
    yield put(setFetchSearchCustomersLoading(false));
  }
}
export function* _getCustomer({ payload: customerId }) {
  try {
    yield put(setFetchCustomerLoading(true));

    const {
      meta: { message },
      data: customer,
    } = yield call(getCustomer, customerId);

    yield put(setIsFetchCustomerHitted(true));
    yield put(setCustomer(customer));

    yield put(setFetchCustomerSuccess(message));
    yield put(setFetchCustomerLoading(false));
  } catch (error) {
    yield put(setFetchCustomerFailed(error));
    yield put(setFetchCustomerLoading(false));
  }
}
export function* _createCustomer({ payload: request }) {
  try {
    yield put(setCreateCustomerLoading(true));

    const {
      meta: { message },
      data: customer,
    } = yield call(createCustomer, request);

    yield put(setIsCreateCustomerHitted(true));
    yield put(setCreateCustomer(customer));

    const isFetchCustomersHitted = yield select(getIsFetchCustomersHitted);

    if (isFetchCustomersHitted) {
      yield put(setFetchCustomersPage(1));
      yield call(_getCustomers);
    }

    yield put(setCreateCustomerSuccess(message));
    yield put(setCreateCustomerLoading(false));
  } catch (error) {
    yield put(setCreateCustomerFailed(error));
    yield put(setCreateCustomerLoading(false));
  }
}
export function* _updateCustomer({ payload: { customerId, request } }) {
  try {
    yield put(setUpdateCustomerLoading(true));

    const {
      meta: { message },
      data: customer,
    } = yield call(updateCustomer, customerId, request);

    yield put(setIsUpdateCustomerHitted(true));
    yield put(setUpdateCustomer(customer));

    const isFetchCustomersHitted = yield select(getIsFetchCustomersHitted);

    if (isFetchCustomersHitted) {
      yield put(setFetchCustomersPage(1));
      yield call(_getCustomers);
    }

    yield put(setUpdateCustomerSuccess(message));
    yield put(setUpdateCustomerLoading(false));
  } catch (error) {
    yield put(setUpdateCustomerFailed(error));
    yield put(setUpdateCustomerLoading(false));
  }
}

export function* onFetchCustomersStart() {
  yield takeLatest(CUSTOMER_ACTION_TYPES.FETCH_CUSTOMERS_START, _getCustomers);
}
export function* onFetchSearchCustomersStart() {
  yield takeLatest(
    CUSTOMER_ACTION_TYPES.FETCH_SEARCH_CUSTOMERS_START,
    _getSearchCustomers
  );
}
export function* onFetchCustomerStart() {
  yield takeLatest(CUSTOMER_ACTION_TYPES.FETCH_CUSTOMER_START, _getCustomer);
}
export function* onCreateCustomerStart() {
  yield takeLatest(
    CUSTOMER_ACTION_TYPES.CREATE_CUSTOMER_START,
    _createCustomer
  );
}
export function* onUpdateCustomerStart() {
  yield takeLatest(
    CUSTOMER_ACTION_TYPES.UPDATE_CUSTOMER_START,
    _updateCustomer
  );
}

export function* customerSaga() {
  yield all([
    call(onFetchCustomersStart),
    call(onFetchSearchCustomersStart),
    call(onFetchCustomerStart),
    call(onCreateCustomerStart),
    call(onUpdateCustomerStart),
  ]);
}
