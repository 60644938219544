const SELECT_CASHIER_ACTION_TYPES = {
  RESET_SELECT_CASHIER_REDUCER: "select-cashier/RESET_SELECT_CASHIER_REDUCER",

  SET_CASHIERS: "select-cashier/SET_CASHIERS",
  SET_SEARCH_CASHIERS: "select-cashier/SET_SEARCH_CASHIERS",

  APPEND_CASHIERS: "select-cashier/APPEND_CASHIERS",
  APPEND_SEARCH_CASHIERS: "select-cashier/APPEND_SEARCH_CASHIERS",

  SET_IS_CASHIERS_HAS_MORE: "select-cashier/SET_IS_CASHIERS_HAS_MORE",
  SET_IS_SEARCH_CASHIERS_HAS_MORE:
    "select-cashier/SET_IS_SEARCH_CASHIERS_HAS_MORE",

  SET_FETCH_CASHIERS_PARAMS: "select-cashier/SET_FETCH_CASHIERS_PARAMS",
  SET_FETCH_CASHIERS_LOADING: "select-cashier/SET_FETCH_CASHIERS_LOADING",
  SET_FETCH_CASHIERS_SUCCESS: "select-cashier/SET_FETCH_CASHIERS_SUCCESS",
  SET_FETCH_CASHIERS_FAILED: "select-cashier/SET_FETCH_CASHIERS_FAILED",

  SET_SEARCH_CASHIERS_PARAMS: "select-cashier/SET_SEARCH_CASHIERS_PARAMS",
  SET_SEARCH_CASHIERS_LOADING: "select-cashier/SET_SEARCH_CASHIERS_LOADING",
  SET_SEARCH_CASHIERS_SUCCESS: "select-cashier/SET_SEARCH_CASHIERS_SUCCESS",
  SET_SEARCH_CASHIERS_FAILED: "select-cashier/SET_SEARCH_CASHIERS_FAILED",

  SET_APPEND_CASHIERS_PARAMS: "select-cashier/SET_APPEND_CASHIERS_PARAMS",
  SET_APPEND_CASHIERS_LOADING: "select-cashier/SET_APPEND_CASHIERS_LOADING",
  SET_APPEND_CASHIERS_SUCCESS: "select-cashier/SET_APPEND_CASHIERS_SUCCESS",
  SET_APPEND_CASHIERS_FAILED: "select-cashier/SET_APPEND_CASHIERS_FAILED",

  SET_IS_FETCH_CASHIERS_HITTED: "select-cashier/SET_IS_FETCH_CASHIERS_HITTED",
  SET_IS_SEARCH_CASHIERS_HITTED: "select-cashier/SET_IS_SEARCH_CASHIERS_HITTED",
  SET_IS_APPEND_CASHIERS_HITTED: "select-cashier/SET_IS_APPEND_CASHIERS_HITTED",

  FETCH_CASHIERS_START: "select-cashier/FETCH_CASHIERS_START",
  SEARCH_CASHIERS_START: "select-cashier/SEARCH_CASHIERS_START",
  APPEND_CASHIERS_START: "select-cashier/APPEND_CASHIERS_START",
};

export default SELECT_CASHIER_ACTION_TYPES;
