import SUMMARY_ACTION_TYPES from "./summary.type";

export const SUMMARY_INITIAL_STATE = {
  dashboardSummaryFinancialTotal: null,
  dashboardSummaryFinancialAllTimeTotal: null,
  reportSummaryFinancialTotal: null,
  accountSummaryFinancialTotal: null,
  summaryFinancialChart: null,
  dashboardSummaryTopProducts: [],
  reportSummaryTopProducts: [],
  summaryTopProductCategories: [],
  summaryLatestOrders: [],
  summaryPaymentMethods: [],
  summarySubscribers: [],
  summaryBranches: [],
  summarySubscriberReport: null,
  summarySubscriberReportBranch: null,

  dashboardSummaryTotalUser: null,
  dashboardSummaryTotalMarket: 0,
  dashboardSummaryTotalBranch: 0,

  reportSummaryTotalUser: null,
  reportSummaryTotalMarket: 0,
  reportSummaryTotalBranch: 0,

  isDashboardSummaryTopProductsHasMore: true,
  isReportSummaryTopProductsHasMore: true,
  isSummaryTopProductCategoriesHasMore: true,
  isSummaryPaymentMethodsHasMore: true,
  isSummarySubscribersHasMore: true,
  isSummaryBranchesHasMore: true,

  fetchDashboardSummaryFinancialTotalFilterPeriodType: null,
  fetchDashboardSummaryFinancialTotalFilterStartAt: null,
  fetchDashboardSummaryFinancialTotalFilterEndAt: null,
  fetchDashboardSummaryFinancialTotalFilterMarketId: null,
  fetchDashboardSummaryFinancialTotalFilterBranchId: null,
  fetchDashboardSummaryFinancialTotalFilterPaymentMethodKey: null,
  fetchDashboardSummaryFinancialTotalFilterCashierId: null,
  fetchDashboardSummaryFinancialTotalLoading: false,
  fetchDashboardSummaryFinancialTotalSuccess: null,
  fetchDashboardSummaryFinancialTotalFailed: null,

  fetchDashboardSummaryFinancialAllTimeTotalFilterPeriodType: null,
  fetchDashboardSummaryFinancialAllTimeTotalFilterStartAt: null,
  fetchDashboardSummaryFinancialAllTimeTotalFilterEndAt: null,
  fetchDashboardSummaryFinancialAllTimeTotalFilterMarketId: null,
  fetchDashboardSummaryFinancialAllTimeTotalFilterBranchId: null,
  fetchDashboardSummaryFinancialAllTimeTotalFilterPaymentMethodKey: null,
  fetchDashboardSummaryFinancialAllTimeTotalFilterCashierId: null,
  fetchDashboardSummaryFinancialAllTimeTotalLoading: false,
  fetchDashboardSummaryFinancialAllTimeTotalSuccess: null,
  fetchDashboardSummaryFinancialAllTimeTotalFailed: null,

  fetchReportSummaryFinancialTotalFilterPeriodType: null,
  fetchReportSummaryFinancialTotalFilterStartAt: null,
  fetchReportSummaryFinancialTotalFilterEndAt: null,
  fetchReportSummaryFinancialTotalFilterMarketId: null,
  fetchReportSummaryFinancialTotalFilterBranchId: null,
  fetchReportSummaryFinancialTotalFilterPaymentMethodKey: null,
  fetchReportSummaryFinancialTotalFilterCashierId: null,
  fetchReportSummaryFinancialTotalLoading: false,
  fetchReportSummaryFinancialTotalSuccess: null,
  fetchReportSummaryFinancialTotalFailed: null,

  fetchAccountSummaryFinancialTotalFilterPeriodType: null,
  fetchAccountSummaryFinancialTotalFilterStartAt: null,
  fetchAccountSummaryFinancialTotalFilterEndAt: null,
  fetchAccountSummaryFinancialTotalFilterMarketId: null,
  fetchAccountSummaryFinancialTotalFilterBranchId: null,
  fetchAccountSummaryFinancialTotalFilterPaymentMethodKey: null,
  fetchAccountSummaryFinancialTotalFilterCashierId: null,
  fetchAccountSummaryFinancialTotalLoading: false,
  fetchAccountSummaryFinancialTotalSuccess: null,
  fetchAccountSummaryFinancialTotalFailed: null,

  fetchSummaryFinancialChartFilterPeriodType: null,
  fetchSummaryFinancialChartFilterStartAt: null,
  fetchSummaryFinancialChartFilterEndAt: null,
  fetchSummaryFinancialChartFilterMarketId: null,
  fetchSummaryFinancialChartFilterBranchId: null,
  fetchSummaryFinancialChartLoading: false,
  fetchSummaryFinancialChartSuccess: null,
  fetchSummaryFinancialChartFailed: null,

  fetchDashboardSummaryTopProductsPage: null,
  fetchDashboardSummaryTopProductsPerPage: null,
  fetchDashboardSummaryTopProductsFilterPeriodType: null,
  fetchDashboardSummaryTopProductsFilterStartAt: null,
  fetchDashboardSummaryTopProductsFilterEndAt: null,
  fetchDashboardSummaryTopProductsFilterMarketId: null,
  fetchDashboardSummaryTopProductsFilterBranchId: null,
  fetchDashboardSummaryTopProductsFilterPaymentMethodKey: null,
  fetchDashboardSummaryTopProductsFilterCashierId: null,
  fetchDashboardSummaryTopProductsLoading: false,
  fetchDashboardSummaryTopProductsSuccess: null,
  fetchDashboardSummaryTopProductsFailed: null,

  fetchReportSummaryTopProductsPage: null,
  fetchReportSummaryTopProductsPerPage: null,
  fetchReportSummaryTopProductsFilterPeriodType: null,
  fetchReportSummaryTopProductsFilterStartAt: null,
  fetchReportSummaryTopProductsFilterEndAt: null,
  fetchReportSummaryTopProductsFilterMarketId: null,
  fetchReportSummaryTopProductsFilterBranchId: null,
  fetchReportSummaryTopProductsFilterPaymentMethodKey: null,
  fetchReportSummaryTopProductsFilterCashierId: null,
  fetchReportSummaryTopProductsLoading: false,
  fetchReportSummaryTopProductsSuccess: null,
  fetchReportSummaryTopProductsFailed: null,

  fetchSummaryTopProductCategoriesPage: null,
  fetchSummaryTopProductCategoriesPerPage: null,
  fetchSummaryTopProductCategoriesFilterPeriodType: null,
  fetchSummaryTopProductCategoriesFilterStartAt: null,
  fetchSummaryTopProductCategoriesFilterEndAt: null,
  fetchSummaryTopProductCategoriesFilterMarketId: null,
  fetchSummaryTopProductCategoriesFilterBranchId: null,
  fetchSummaryTopProductCategoriesFilterPaymentMethodKey: null,
  fetchSummaryTopProductCategoriesFilterCashierId: null,
  fetchSummaryTopProductCategoriesLoading: false,
  fetchSummaryTopProductCategoriesSuccess: null,
  fetchSummaryTopProductCategoriesFailed: null,

  fetchSummaryLatestOrdersLimit: null,
  fetchSummaryLatestOrdersIncludes: null,
  fetchSummaryLatestOrdersFilterPeriodType: null,
  fetchSummaryLatestOrdersFilterStartAt: null,
  fetchSummaryLatestOrdersFilterEndAt: null,
  fetchSummaryLatestOrdersFilterMarketId: null,
  fetchSummaryLatestOrdersFilterBranchId: null,
  fetchSummaryLatestOrdersFilterPaymentMethodKey: null,
  fetchSummaryLatestOrdersFilterCashierId: null,
  fetchSummaryLatestOrdersLoading: false,
  fetchSummaryLatestOrdersSuccess: null,
  fetchSummaryLatestOrdersFailed: null,

  fetchSummarySubscriberReportFilterPeriodType: null,
  fetchSummarySubscriberReportFilterStartAt: null,
  fetchSummarySubscriberReportFilterEndAt: null,
  fetchSummarySubscriberReportFilterMarketId: null,
  fetchSummarySubscriberReportFilterBranchId: null,
  fetchSummarySubscriberReportFilterCashierId: null,
  fetchSummarySubscriberReportLoading: false,
  fetchSummarySubscriberReportSuccess: null,
  fetchSummarySubscriberReportFailed: null,

  fetchSummarySubscriberReportBranchFilterPeriodType: null,
  fetchSummarySubscriberReportBranchFilterStartAt: null,
  fetchSummarySubscriberReportBranchFilterEndAt: null,
  fetchSummarySubscriberReportBranchFilterMarketId: null,
  fetchSummarySubscriberReportBranchFilterBranchId: null,
  fetchSummarySubscriberReportBranchLoading: false,
  fetchSummarySubscriberReportBranchSuccess: null,
  fetchSummarySubscriberReportBranchFailed: null,

  fetchSummaryPaymentMethodsPage: null,
  fetchSummaryPaymentMethodsPerPage: null,
  fetchSummaryPaymentMethodsFilterPeriodType: null,
  fetchSummaryPaymentMethodsFilterStartAt: null,
  fetchSummaryPaymentMethodsFilterEndAt: null,
  fetchSummaryPaymentMethodsFilterMarketId: null,
  fetchSummaryPaymentMethodsFilterBranchId: null,
  fetchSummaryPaymentMethodsFilterPaymentMethodKey: null,
  fetchSummaryPaymentMethodsFilterCashierId: null,
  fetchSummaryPaymentMethodsLoading: false,
  fetchSummaryPaymentMethodsSuccess: null,
  fetchSummaryPaymentMethodsFailed: null,

  fetchSummarySubscribersPage: null,
  fetchSummarySubscribersPerPage: null,
  fetchSummarySubscribersFilterPeriodType: null,
  fetchSummarySubscribersFilterStartAt: null,
  fetchSummarySubscribersFilterEndAt: null,
  fetchSummarySubscribersFilterMarketId: null,
  fetchSummarySubscribersFilterBranchId: null,
  fetchSummarySubscribersFilterPaymentMethodKey: null,
  fetchSummarySubscribersFilterCashierId: null,
  fetchSummarySubscribersLoading: false,
  fetchSummarySubscribersSuccess: null,
  fetchSummarySubscribersFailed: null,

  fetchSummaryBranchesPage: null,
  fetchSummaryBranchesPerPage: null,
  fetchSummaryBranchesFilterPeriodType: null,
  fetchSummaryBranchesFilterStartAt: null,
  fetchSummaryBranchesFilterEndAt: null,
  fetchSummaryBranchesFilterMarketId: null,
  fetchSummaryBranchesFilterBranchId: null,
  fetchSummaryBranchesFilterPaymentMethodKey: null,
  fetchSummaryBranchesFilterCashierId: null,
  fetchSummaryBranchesLoading: false,
  fetchSummaryBranchesSuccess: null,
  fetchSummaryBranchesFailed: null,

  fetchDashboardSummaryTotalUserRoles: null,
  fetchDashboardSummaryTotalUserFilterStartAt: null,
  fetchDashboardSummaryTotalUserFilterEndAt: null,
  fetchDashboardSummaryTotalUserFilterPhonePrefix: null,
  fetchDashboardSummaryTotalUserFilterIsActive: null,
  fetchDashboardSummaryTotalUserLoading: false,
  fetchDashboardSummaryTotalUserSuccess: null,
  fetchDashboardSummaryTotalUserFailed: null,

  fetchDashboardSummaryTotalMarketFilterSectorId: null,
  fetchDashboardSummaryTotalMarketFilterPhonePrefix: null,
  fetchDashboardSummaryTotalMarketLoading: false,
  fetchDashboardSummaryTotalMarketSuccess: null,
  fetchDashboardSummaryTotalMarketFailed: null,

  fetchDashboardSummaryTotalBranchFilterMarketId: null,
  fetchDashboardSummaryTotalBranchFilterIsActive: null,
  fetchDashboardSummaryTotalBranchLoading: false,
  fetchDashboardSummaryTotalBranchSuccess: null,
  fetchDashboardSummaryTotalBranchFailed: null,

  fetchReportSummaryTotalUserRoles: null,
  fetchReportSummaryTotalUserFilterStartAt: null,
  fetchReportSummaryTotalUserFilterEndAt: null,
  fetchReportSummaryTotalUserFilterPhonePrefix: null,
  fetchReportSummaryTotalUserFilterIsActive: null,
  fetchReportSummaryTotalUserLoading: false,
  fetchReportSummaryTotalUserSuccess: null,
  fetchReportSummaryTotalUserFailed: null,

  fetchReportSummaryTotalMarketFilterSectorId: null,
  fetchReportSummaryTotalMarketFilterPhonePrefix: null,
  fetchReportSummaryTotalMarketLoading: false,
  fetchReportSummaryTotalMarketSuccess: null,
  fetchReportSummaryTotalMarketFailed: null,

  fetchReportSummaryTotalBranchFilterMarketId: null,
  fetchReportSummaryTotalBranchFilterIsActive: null,
  fetchReportSummaryTotalBranchLoading: false,
  fetchReportSummaryTotalBranchSuccess: null,
  fetchReportSummaryTotalBranchFailed: null,
};

export const summaryReducer = (state = SUMMARY_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SUMMARY_ACTION_TYPES.SET_DASHBOARD_SUMMARY_FINANCIAL_TOTAL:
      return { ...state, dashboardSummaryFinancialTotal: payload };
    case SUMMARY_ACTION_TYPES.SET_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL:
      return { ...state, dashboardSummaryFinancialAllTimeTotal: payload };
    case SUMMARY_ACTION_TYPES.SET_REPORT_SUMMARY_FINANCIAL_TOTAL:
      return { ...state, reportSummaryFinancialTotal: payload };
    case SUMMARY_ACTION_TYPES.SET_ACCOUNT_SUMMARY_FINANCIAL_TOTAL:
      return { ...state, accountSummaryFinancialTotal: payload };
    case SUMMARY_ACTION_TYPES.SET_SUMMARY_FINANCIAL_CHART:
      return { ...state, summaryFinancialChart: payload };
    case SUMMARY_ACTION_TYPES.SET_DASHBOARD_SUMMARY_TOP_PRODUCTS:
      return { ...state, dashboardSummaryTopProducts: payload };
    case SUMMARY_ACTION_TYPES.SET_REPORT_SUMMARY_TOP_PRODUCTS:
      return { ...state, reportSummaryTopProducts: payload };
    case SUMMARY_ACTION_TYPES.SET_SUMMARY_TOP_PRODUCT_CATEGORIES:
      return { ...state, summaryTopProductCategories: payload };
    case SUMMARY_ACTION_TYPES.SET_SUMMARY_LATEST_ORDERS:
      return { ...state, summaryLatestOrders: payload };
    case SUMMARY_ACTION_TYPES.SET_SUMMARY_PAYMENT_METHODS:
      return { ...state, summaryPaymentMethods: payload };
    case SUMMARY_ACTION_TYPES.SET_SUMMARY_SUBSCRIBERS:
      return { ...state, summarySubscribers: payload };
    case SUMMARY_ACTION_TYPES.SET_SUMMARY_BRANCHES:
      return { ...state, summaryBranches: payload };
    case SUMMARY_ACTION_TYPES.SET_SUMMARY_SUBSCRIBER_REPORT:
      return { ...state, summarySubscriberReport: payload };
    case SUMMARY_ACTION_TYPES.SET_SUMMARY_SUBSCRIBER_REPORT_BRANCH:
      return { ...state, summarySubscriberReportBranch: payload };
    case SUMMARY_ACTION_TYPES.SET_DASHBOARD_SUMMARY_TOTAL_USER:
      return { ...state, dashboardSummaryTotalUser: payload };
    case SUMMARY_ACTION_TYPES.SET_DASHBOARD_SUMMARY_TOTAL_MARKET:
      return { ...state, dashboardSummaryTotalMarket: payload };
    case SUMMARY_ACTION_TYPES.SET_DASHBOARD_SUMMARY_TOTAL_BRANCH:
      return { ...state, dashboardSummaryTotalBranch: payload };
    case SUMMARY_ACTION_TYPES.SET_REPORT_SUMMARY_TOTAL_USER:
      return { ...state, reportSummaryTotalUser: payload };
    case SUMMARY_ACTION_TYPES.SET_REPORT_SUMMARY_TOTAL_MARKET:
      return { ...state, reportSummaryTotalMarket: payload };
    case SUMMARY_ACTION_TYPES.SET_REPORT_SUMMARY_TOTAL_BRANCH:
      return { ...state, reportSummaryTotalBranch: payload };
    case SUMMARY_ACTION_TYPES.SET_IS_DASHBOARD_SUMMARY_TOP_PRODUCTS_HAS_MORE:
      return { ...state, isDashboardSummaryTopProductsHasMore: payload };
    case SUMMARY_ACTION_TYPES.SET_IS_REPORT_SUMMARY_TOP_PRODUCTS_HAS_MORE:
      return { ...state, isReportSummaryTopProductsHasMore: payload };
    case SUMMARY_ACTION_TYPES.SET_IS_SUMMARY_TOP_PRODUCT_CATEGORIES_HAS_MORE:
      return { ...state, isSummaryTopProductCategoriesHasMore: payload };
    case SUMMARY_ACTION_TYPES.SET_IS_SUMMARY_PAYMENT_METHODS_HAS_MORE:
      return { ...state, isSummaryPaymentMethodsHasMore: payload };
    case SUMMARY_ACTION_TYPES.SET_IS_SUMMARY_SUBSCRIBERS_HAS_MORE:
      return { ...state, isSummarySubscribersHasMore: payload };
    case SUMMARY_ACTION_TYPES.SET_IS_SUMMARY_BRANCHES_HAS_MORE:
      return { ...state, isSummaryBranchesHasMore: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE:
      return { ...state, fetchDashboardSummaryFinancialTotalFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_MARKET_ID:
      return { ...state, fetchDashboardSummaryFinancialTotalFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_BRANCH_ID:
      return { ...state, fetchDashboardSummaryFinancialTotalFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchDashboardSummaryFinancialTotalFilterPaymentMethodKey: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID:
      return { ...state, fetchDashboardSummaryFinancialTotalFilterCashierId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT:
      return { ...state, fetchDashboardSummaryFinancialTotalFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT:
      return { ...state, fetchDashboardSummaryFinancialTotalFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_LOADING:
      return { ...state, fetchDashboardSummaryFinancialTotalLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_SUCCESS:
      return { ...state, fetchDashboardSummaryFinancialTotalSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_TOTAL_FAILED:
      return { ...state, fetchDashboardSummaryFinancialTotalFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_PERIOD_TYPE:
      return { ...state, fetchDashboardSummaryFinancialAllTimeTotalFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_MARKET_ID:
      return { ...state, fetchDashboardSummaryFinancialAllTimeTotalFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_BRANCH_ID:
      return { ...state, fetchDashboardSummaryFinancialAllTimeTotalFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchDashboardSummaryFinancialAllTimeTotalFilterPaymentMethodKey: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_CASHIER_ID:
      return { ...state, fetchDashboardSummaryFinancialAllTimeTotalFilterCashierId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_START_AT:
      return { ...state, fetchDashboardSummaryFinancialAllTimeTotalFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FILTER_END_AT:
      return { ...state, fetchDashboardSummaryFinancialAllTimeTotalFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_LOADING:
      return { ...state, fetchDashboardSummaryFinancialAllTimeTotalLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_SUCCESS:
      return { ...state, fetchDashboardSummaryFinancialAllTimeTotalSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_FINANCIAL_ALL_TIME_TOTAL_FAILED:
      return { ...state, fetchDashboardSummaryFinancialAllTimeTotalFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE:
      return { ...state, fetchReportSummaryFinancialTotalFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_MARKET_ID:
      return { ...state, fetchReportSummaryFinancialTotalFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_BRANCH_ID:
      return { ...state, fetchReportSummaryFinancialTotalFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchReportSummaryFinancialTotalFilterPaymentMethodKey: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID:
      return { ...state, fetchReportSummaryFinancialTotalFilterCashierId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT:
      return { ...state, fetchReportSummaryFinancialTotalFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT:
      return { ...state, fetchReportSummaryFinancialTotalFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_LOADING:
      return { ...state, fetchReportSummaryFinancialTotalLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_SUCCESS:
      return { ...state, fetchReportSummaryFinancialTotalSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_FINANCIAL_TOTAL_FAILED:
      return { ...state, fetchReportSummaryFinancialTotalFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_PERIOD_TYPE:
      return { ...state, fetchAccountSummaryFinancialTotalFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_MARKET_ID:
      return { ...state, fetchAccountSummaryFinancialTotalFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_BRANCH_ID:
      return { ...state, fetchAccountSummaryFinancialTotalFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchAccountSummaryFinancialTotalFilterPaymentMethodKey: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_CASHIER_ID:
      return { ...state, fetchAccountSummaryFinancialTotalFilterCashierId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_START_AT:
      return { ...state, fetchAccountSummaryFinancialTotalFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FILTER_END_AT:
      return { ...state, fetchAccountSummaryFinancialTotalFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_LOADING:
      return { ...state, fetchAccountSummaryFinancialTotalLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_SUCCESS:
      return { ...state, fetchAccountSummaryFinancialTotalSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_ACCOUNT_SUMMARY_FINANCIAL_TOTAL_FAILED:
      return { ...state, fetchAccountSummaryFinancialTotalFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_PERIOD_TYPE:
      return { ...state, fetchSummaryFinancialChartFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_MARKET_ID:
      return { ...state, fetchSummaryFinancialChartFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_BRANCH_ID:
      return { ...state, fetchSummaryFinancialChartFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_START_AT:
      return { ...state, fetchSummaryFinancialChartFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_FILTER_END_AT:
      return { ...state, fetchSummaryFinancialChartFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_LOADING:
      return { ...state, fetchSummaryFinancialChartLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_SUCCESS:
      return { ...state, fetchSummaryFinancialChartSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_FINANCIAL_CHART_FAILED:
      return { ...state, fetchSummaryFinancialChartFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_PAGE:
      return { ...state, fetchDashboardSummaryTopProductsPage: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_PER_PAGE:
      return { ...state, fetchDashboardSummaryTopProductsPerPage: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_PERIOD_TYPE:
      return { ...state, fetchDashboardSummaryTopProductsFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_START_AT:
      return { ...state, fetchDashboardSummaryTopProductsFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_END_AT:
      return { ...state, fetchDashboardSummaryTopProductsFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_MARKET_ID:
      return { ...state, fetchDashboardSummaryTopProductsFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_BRANCH_ID:
      return { ...state, fetchDashboardSummaryTopProductsFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchDashboardSummaryTopProductsFilterPaymentMethodKey: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FILTER_CASHIER_ID:
      return { ...state, fetchDashboardSummaryTopProductsFilterCashierId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_LOADING:
      return { ...state, fetchDashboardSummaryTopProductsLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_SUCCESS:
      return { ...state, fetchDashboardSummaryTopProductsSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOP_PRODUCTS_FAILED:
      return { ...state, fetchDashboardSummaryTopProductsFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_PAGE:
      return { ...state, fetchReportSummaryTopProductsPage: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_PER_PAGE:
      return { ...state, fetchReportSummaryTopProductsPerPage: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_PERIOD_TYPE:
      return { ...state, fetchReportSummaryTopProductsFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_START_AT:
      return { ...state, fetchReportSummaryTopProductsFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_END_AT:
      return { ...state, fetchReportSummaryTopProductsFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_MARKET_ID:
      return { ...state, fetchReportSummaryTopProductsFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_BRANCH_ID:
      return { ...state, fetchReportSummaryTopProductsFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchReportSummaryTopProductsFilterPaymentMethodKey: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FILTER_CASHIER_ID:
      return { ...state, fetchReportSummaryTopProductsFilterCashierId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_LOADING:
      return { ...state, fetchReportSummaryTopProductsLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_SUCCESS:
      return { ...state, fetchReportSummaryTopProductsSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOP_PRODUCTS_FAILED:
      return { ...state, fetchReportSummaryTopProductsFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_PAGE:
      return { ...state, fetchSummaryTopProductCategoriesPage: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_PER_PAGE:
      return { ...state, fetchSummaryTopProductCategoriesPerPage: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_PERIOD_TYPE:
      return { ...state, fetchSummaryTopProductCategoriesFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_START_AT:
      return { ...state, fetchSummaryTopProductCategoriesFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_END_AT:
      return { ...state, fetchSummaryTopProductCategoriesFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
      return { ...state, fetchSummaryTopProductCategoriesFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
      return { ...state, fetchSummaryTopProductCategoriesFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchSummaryTopProductCategoriesFilterPaymentMethodKey: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FILTER_CASHIER_ID:
      return { ...state, fetchSummaryTopProductCategoriesFilterCashierId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_LOADING:
      return { ...state, fetchSummaryTopProductCategoriesLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, fetchSummaryTopProductCategoriesSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_TOP_PRODUCT_CATEGORIES_FAILED:
      return { ...state, fetchSummaryTopProductCategoriesFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_LIMIT:
      return { ...state, fetchSummaryLatestOrdersLimit: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_INCLUDES:
      return { ...state, fetchSummaryLatestOrdersIncludes: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_PERIOD_TYPE:
      return { ...state, fetchSummaryLatestOrdersFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_START_AT:
      return { ...state, fetchSummaryLatestOrdersFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_END_AT:
      return { ...state, fetchSummaryLatestOrdersFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_MARKET_ID:
      return { ...state, fetchSummaryLatestOrdersFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_BRANCH_ID:
      return { ...state, fetchSummaryLatestOrdersFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchSummaryLatestOrdersFilterPaymentMethodKey: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_CASHIER_ID:
      return { ...state, fetchSummaryLatestOrdersFilterCashierId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_LOADING:
      return { ...state, fetchSummaryLatestOrdersLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_SUCCESS:
      return { ...state, fetchSummaryLatestOrdersSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FAILED:
      return { ...state, fetchSummaryLatestOrdersFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_PERIOD_TYPE:
      return { ...state, fetchSummarySubscriberReportFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_START_AT:
      return { ...state, fetchSummarySubscriberReportFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_END_AT:
      return { ...state, fetchSummarySubscriberReportFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_MARKET_ID:
      return { ...state, fetchSummarySubscriberReportFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_BRANCH_ID:
      return { ...state, fetchSummarySubscriberReportFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FILTER_CASHIER_ID:
      return { ...state, fetchSummarySubscriberReportFilterCashierId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_LOADING:
      return { ...state, fetchSummarySubscriberReportLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_SUCCESS:
      return { ...state, fetchSummarySubscriberReportSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_FAILED:
      return { ...state, fetchSummarySubscriberReportFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_PERIOD_TYPE:
      return { ...state, fetchSummarySubscriberReportBranchFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_START_AT:
      return { ...state, fetchSummarySubscriberReportBranchFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_END_AT:
      return { ...state, fetchSummarySubscriberReportBranchFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_MARKET_ID:
      return { ...state, fetchSummarySubscriberReportBranchFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FILTER_BRANCH_ID:
      return { ...state, fetchSummarySubscriberReportBranchFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_LOADING:
      return { ...state, fetchSummarySubscriberReportBranchLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_SUCCESS:
      return { ...state, fetchSummarySubscriberReportBranchSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBER_REPORT_BRANCH_FAILED:
      return { ...state, fetchSummarySubscriberReportBranchFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_PAGE:
      return { ...state, fetchSummaryPaymentMethodsPage: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_PER_PAGE:
      return { ...state, fetchSummaryPaymentMethodsPerPage: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_PERIOD_TYPE:
      return { ...state, fetchSummaryPaymentMethodsFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_START_AT:
      return { ...state, fetchSummaryPaymentMethodsFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_END_AT:
      return { ...state, fetchSummaryPaymentMethodsFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_MARKET_ID:
      return { ...state, fetchSummaryPaymentMethodsFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_BRANCH_ID:
      return { ...state, fetchSummaryPaymentMethodsFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchSummaryPaymentMethodsFilterPaymentMethodKey: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FILTER_CASHIER_ID:
      return { ...state, fetchSummaryPaymentMethodsFilterCashierId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_LOADING:
      return { ...state, fetchSummaryPaymentMethodsLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_SUCCESS:
      return { ...state, fetchSummaryPaymentMethodsSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_PAYMENT_METHODS_FAILED:
      return { ...state, fetchSummaryPaymentMethodsFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_PAGE:
      return { ...state, fetchSummarySubscribersPage: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_PER_PAGE:
      return { ...state, fetchSummarySubscribersPerPage: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_PERIOD_TYPE:
      return { ...state, fetchSummarySubscribersFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_START_AT:
      return { ...state, fetchSummarySubscribersFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_END_AT:
      return { ...state, fetchSummarySubscribersFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_MARKET_ID:
      return { ...state, fetchSummarySubscribersFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_BRANCH_ID:
      return { ...state, fetchSummarySubscribersFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchSummarySubscribersFilterPaymentMethodKey: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FILTER_CASHIER_ID:
      return { ...state, fetchSummarySubscribersFilterCashierId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_LOADING:
      return { ...state, fetchSummarySubscribersLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_SUCCESS:
      return { ...state, fetchSummarySubscribersSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_SUBSCRIBERS_FAILED:
      return { ...state, fetchSummarySubscribersFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_PAGE:
      return { ...state, fetchSummaryBranchesPage: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_PER_PAGE:
      return { ...state, fetchSummaryBranchesPerPage: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_PERIOD_TYPE:
      return { ...state, fetchSummaryBranchesFilterPeriodType: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_START_AT:
      return { ...state, fetchSummaryBranchesFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_END_AT:
      return { ...state, fetchSummaryBranchesFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_MARKET_ID:
      return { ...state, fetchSummaryBranchesFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_BRANCH_ID:
      return { ...state, fetchSummaryBranchesFilterBranchId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_PAYMENT_METHOD_KEY:
      return { ...state, fetchSummaryBranchesFilterPaymentMethodKey: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FILTER_CASHIER_ID:
      return { ...state, fetchSummaryBranchesFilterCashierId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_LOADING:
      return { ...state, fetchSummaryBranchesLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_SUCCESS:
      return { ...state, fetchSummaryBranchesSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_SUMMARY_BRANCHES_FAILED:
      return { ...state, fetchSummaryBranchesFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_ROLES:
      return { ...state, fetchDashboardSummaryTotalUserRoles: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_START_AT:
      return { ...state, fetchDashboardSummaryTotalUserFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_END_AT:
      return { ...state, fetchDashboardSummaryTotalUserFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_PHONE_PREFIX:
      return { ...state, fetchDashboardSummaryTotalUserFilterPhonePrefix: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FILTER_IS_ACTIVE:
      return { ...state, fetchDashboardSummaryTotalUserFilterIsActive: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_LOADING:
      return { ...state, fetchDashboardSummaryTotalUserLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_SUCCESS:
      return { ...state, fetchDashboardSummaryTotalUserSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_USER_FAILED:
      return { ...state, fetchDashboardSummaryTotalUserFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_FILTER_SECTOR_ID:
      return { ...state, fetchDashboardSummaryTotalMarketFilterSectorId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_FILTER_PHONE_PREFIX:
      return { ...state, fetchDashboardSummaryTotalMarketFilterPhonePrefix: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_LOADING:
      return { ...state, fetchDashboardSummaryTotalMarketLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_SUCCESS:
      return { ...state, fetchDashboardSummaryTotalMarketSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_MARKET_FAILED:
      return { ...state, fetchDashboardSummaryTotalMarketFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_FILTER_MARKET_ID:
      return { ...state, fetchDashboardSummaryTotalBranchFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_FILTER_IS_ACTIVE:
      return { ...state, fetchDashboardSummaryTotalBranchFilterIsActive: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_LOADING:
      return { ...state, fetchDashboardSummaryTotalBranchLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_SUCCESS:
      return { ...state, fetchDashboardSummaryTotalBranchSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_DASHBOARD_SUMMARY_TOTAL_BRANCH_FAILED:
      return { ...state, fetchDashboardSummaryTotalBranchFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_ROLES:
      return { ...state, fetchReportSummaryTotalUserRoles: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_START_AT:
      return { ...state, fetchReportSummaryTotalUserFilterStartAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_END_AT:
      return { ...state, fetchReportSummaryTotalUserFilterEndAt: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_PHONE_PREFIX:
      return { ...state, fetchReportSummaryTotalUserFilterPhonePrefix: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FILTER_IS_ACTIVE:
      return { ...state, fetchReportSummaryTotalUserFilterIsActive: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_LOADING:
      return { ...state, fetchReportSummaryTotalUserLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_SUCCESS:
      return { ...state, fetchReportSummaryTotalUserSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_USER_FAILED:
      return { ...state, fetchReportSummaryTotalUserFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_FILTER_SECTOR_ID:
      return { ...state, fetchReportSummaryTotalMarketFilterSectorId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_FILTER_PHONE_PREFIX:
      return { ...state, fetchReportSummaryTotalMarketFilterPhonePrefix: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_LOADING:
      return { ...state, fetchReportSummaryTotalMarketLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_SUCCESS:
      return { ...state, fetchReportSummaryTotalMarketSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_MARKET_FAILED:
      return { ...state, fetchReportSummaryTotalMarketFailed: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_FILTER_MARKET_ID:
      return { ...state, fetchReportSummaryTotalBranchFilterMarketId: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_FILTER_IS_ACTIVE:
      return { ...state, fetchReportSummaryTotalBranchFilterIsActive: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_LOADING:
      return { ...state, fetchReportSummaryTotalBranchLoading: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_SUCCESS:
      return { ...state, fetchReportSummaryTotalBranchSuccess: payload };
    case SUMMARY_ACTION_TYPES.SET_FETCH_REPORT_SUMMARY_TOTAL_BRANCH_FAILED:
      return { ...state, fetchReportSummaryTotalBranchFailed: payload };
    case SUMMARY_ACTION_TYPES.APPEND_DASHBOARD_SUMMARY_TOP_PRODUCTS:
      return { ...state, dashboardSummaryTopProducts: [...state.dashboardSummaryTopProducts, ...payload] };
    case SUMMARY_ACTION_TYPES.APPEND_REPORT_SUMMARY_TOP_PRODUCTS:
      return { ...state, reportSummaryTopProducts: [...state.reportSummaryTopProducts, ...payload] };
    case SUMMARY_ACTION_TYPES.APPEND_SUMMARY_TOP_PRODUCT_CATEGORIES:
      return { ...state, summaryTopProductCategories: [...state.summaryTopProductCategories, ...payload] };
    case SUMMARY_ACTION_TYPES.APPEND_SUMMARY_PAYMENT_METHODS:
      return { ...state, summaryPaymentMethods: [...state.summaryPaymentMethods, ...payload] };
    case SUMMARY_ACTION_TYPES.APPEND_SUMMARY_SUBSCRIBERS:
      return { ...state, summarySubscribers: [...state.summarySubscribers, ...payload] };
    case SUMMARY_ACTION_TYPES.APPEND_SUMMARY_BRANCHES:
      return { ...state, summaryBranches: [...state.summaryBranches, ...payload] };
    case SUMMARY_ACTION_TYPES.RESET_SUMMARY_REDUCER:
      return SUMMARY_INITIAL_STATE;
    default:
      return state;
  }
};
