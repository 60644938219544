import { createSelector } from "reselect";

const merchantSelector = ({ merchant }) => merchant;

export const getMerchants = createSelector(
  [merchantSelector],
  ({ merchants }) => merchants
);
export const getSelectMerchants = createSelector(
  [merchantSelector],
  ({ selectMerchants }) => selectMerchants
);
export const getMerchant = createSelector(
  [merchantSelector],
  ({ merchant }) => merchant
);

export const getIsMerchantsHasMore = createSelector(
  [merchantSelector],
  ({ isMerchantsHasMore }) => isMerchantsHasMore
);
export const getIsSelectMerchantsHasMore = createSelector(
  [merchantSelector],
  ({ isSelectMerchantsHasMore }) => isSelectMerchantsHasMore
);

export const getFetchMerchantsSearch = createSelector(
  [merchantSelector],
  ({ fetchMerchantsSearch }) => fetchMerchantsSearch
);
export const getFetchMerchantsSort = createSelector(
  [merchantSelector],
  ({ fetchMerchantsSort }) => fetchMerchantsSort
);
export const getFetchMerchantsKeyBy = createSelector(
  [merchantSelector],
  ({ fetchMerchantsKeyBy }) => fetchMerchantsKeyBy
);
export const getFetchMerchantsPage = createSelector(
  [merchantSelector],
  ({ fetchMerchantsPage }) => fetchMerchantsPage
);
export const getFetchMerchantsPerPage = createSelector(
  [merchantSelector],
  ({ fetchMerchantsPerPage }) => fetchMerchantsPerPage
);
export const getFetchMerchantsIncludes = createSelector(
  [merchantSelector],
  ({ fetchMerchantsIncludes }) => fetchMerchantsIncludes
);
export const getFetchMerchantsFilterMerchantsIds = createSelector(
  [merchantSelector],
  ({ fetchMerchantsFilterMerchantsIds }) => fetchMerchantsFilterMerchantsIds
);
export const getFetchMerchantsLoading = createSelector(
  [merchantSelector],
  ({ fetchMerchantsLoading }) => fetchMerchantsLoading
);
export const getFetchMerchantsSuccess = createSelector(
  [merchantSelector],
  ({ fetchMerchantsSuccess }) => fetchMerchantsSuccess
);
export const getFetchMerchantsFailed = createSelector(
  [merchantSelector],
  ({ fetchMerchantsFailed }) => fetchMerchantsFailed
);

export const getFetchSelectMerchantsSearch = createSelector(
  [merchantSelector],
  ({ fetchSelectMerchantsSearch }) => fetchSelectMerchantsSearch
);
export const getFetchSelectMerchantsSort = createSelector(
  [merchantSelector],
  ({ fetchSelectMerchantsSort }) => fetchSelectMerchantsSort
);
export const getFetchSelectMerchantsKeyBy = createSelector(
  [merchantSelector],
  ({ fetchSelectMerchantsKeyBy }) => fetchSelectMerchantsKeyBy
);
export const getFetchSelectMerchantsPage = createSelector(
  [merchantSelector],
  ({ fetchSelectMerchantsPage }) => fetchSelectMerchantsPage
);
export const getFetchSelectMerchantsPerPage = createSelector(
  [merchantSelector],
  ({ fetchSelectMerchantsPerPage }) => fetchSelectMerchantsPerPage
);
export const getFetchSelectMerchantsIncludes = createSelector(
  [merchantSelector],
  ({ fetchSelectMerchantsIncludes }) => fetchSelectMerchantsIncludes
);
export const getFetchSelectMerchantsFilterMerchantsIds = createSelector(
  [merchantSelector],
  ({ fetchSelectMerchantsFilterMerchantsIds }) =>
    fetchSelectMerchantsFilterMerchantsIds
);
export const getFetchSelectMerchantsLoading = createSelector(
  [merchantSelector],
  ({ fetchSelectMerchantsLoading }) => fetchSelectMerchantsLoading
);
export const getFetchSelectMerchantsSuccess = createSelector(
  [merchantSelector],
  ({ fetchSelectMerchantsSuccess }) => fetchSelectMerchantsSuccess
);
export const getFetchSelectMerchantsFailed = createSelector(
  [merchantSelector],
  ({ fetchSelectMerchantsFailed }) => fetchSelectMerchantsFailed
);

export const getAppendSelectMerchantsSearch = createSelector(
  [merchantSelector],
  ({ appendSelectMerchantsSearch }) => appendSelectMerchantsSearch
);
export const getAppendSelectMerchantsSort = createSelector(
  [merchantSelector],
  ({ appendSelectMerchantsSort }) => appendSelectMerchantsSort
);
export const getAppendSelectMerchantsKeyBy = createSelector(
  [merchantSelector],
  ({ appendSelectMerchantsKeyBy }) => appendSelectMerchantsKeyBy
);
export const getAppendSelectMerchantsPage = createSelector(
  [merchantSelector],
  ({ appendSelectMerchantsPage }) => appendSelectMerchantsPage
);
export const getAppendSelectMerchantsPerPage = createSelector(
  [merchantSelector],
  ({ appendSelectMerchantsPerPage }) => appendSelectMerchantsPerPage
);
export const getAppendSelectMerchantsIncludes = createSelector(
  [merchantSelector],
  ({ appendSelectMerchantsIncludes }) => appendSelectMerchantsIncludes
);
export const getAppendSelectMerchantsFilterMerchantsIds = createSelector(
  [merchantSelector],
  ({ appendSelectMerchantsFilterMerchantsIds }) =>
    appendSelectMerchantsFilterMerchantsIds
);
export const getAppendSelectMerchantsLoading = createSelector(
  [merchantSelector],
  ({ appendSelectMerchantsLoading }) => appendSelectMerchantsLoading
);
export const getAppendSelectMerchantsSuccess = createSelector(
  [merchantSelector],
  ({ appendSelectMerchantsSuccess }) => appendSelectMerchantsSuccess
);
export const getAppendSelectMerchantsFailed = createSelector(
  [merchantSelector],
  ({ appendSelectMerchantsFailed }) => appendSelectMerchantsFailed
);

export const getFetchMerchantLoading = createSelector(
  [merchantSelector],
  ({ fetchMerchantLoading }) => fetchMerchantLoading
);
export const getFetchMerchantSuccess = createSelector(
  [merchantSelector],
  ({ fetchMerchantSuccess }) => fetchMerchantSuccess
);
export const getFetchMerchantFailed = createSelector(
  [merchantSelector],
  ({ fetchMerchantFailed }) => fetchMerchantFailed
);

export const getCreateMerchantLoading = createSelector(
  [merchantSelector],
  ({ createMerchantLoading }) => createMerchantLoading
);
export const getCreateMerchantSuccess = createSelector(
  [merchantSelector],
  ({ createMerchantSuccess }) => createMerchantSuccess
);
export const getCreateMerchantFailed = createSelector(
  [merchantSelector],
  ({ createMerchantFailed }) => createMerchantFailed
);

export const getUpdateMerchantLoading = createSelector(
  [merchantSelector],
  ({ updateMerchantLoading }) => updateMerchantLoading
);
export const getUpdateMerchantSuccess = createSelector(
  [merchantSelector],
  ({ updateMerchantSuccess }) => updateMerchantSuccess
);
export const getUpdateMerchantFailed = createSelector(
  [merchantSelector],
  ({ updateMerchantFailed }) => updateMerchantFailed
);

export const getDeleteMerchantLoading = createSelector(
  [merchantSelector],
  ({ deleteMerchantLoading }) => deleteMerchantLoading
);
export const getDeleteMerchantSuccess = createSelector(
  [merchantSelector],
  ({ deleteMerchantSuccess }) => deleteMerchantSuccess
);
export const getDeleteMerchantFailed = createSelector(
  [merchantSelector],
  ({ deleteMerchantFailed }) => deleteMerchantFailed
);

export const getIsFetchMerchantsHitted = createSelector(
  [merchantSelector],
  ({ isFetchMerchantsHitted }) => isFetchMerchantsHitted
);
export const getIsFetchSelectMerchantsHitted = createSelector(
  [merchantSelector],
  ({ isFetchSelectMerchantsHitted }) => isFetchSelectMerchantsHitted
);
export const getIsAppendFetchMerchantsHitted = createSelector(
  [merchantSelector],
  ({ isAppendFetchMerchantsHitted }) => isAppendFetchMerchantsHitted
);
export const getIsFetchMerchantHitted = createSelector(
  [merchantSelector],
  ({ isFetchMerchantHitted }) => isFetchMerchantHitted
);
export const getIsCreateMerchantHitted = createSelector(
  [merchantSelector],
  ({ isCreateMerchantHitted }) => isCreateMerchantHitted
);
export const getIsUpdateMerchantHitted = createSelector(
  [merchantSelector],
  ({ isUpdateMerchantHitted }) => isUpdateMerchantHitted
);

export const getIsDeleteMerchantHitted = createSelector(
  [merchantSelector],
  ({ isDeleteMerchantHitted }) => isDeleteMerchantHitted
);
