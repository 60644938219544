import REPORT_MORE_ACTION_TYPES from "./report-more.type";

export const REPORT_MORE_INITIAL_STATE = {
  reportMoreCategories: [],
  reportMoreProducts: [],
  reportMoreCashiers: [],
  reportMorePayments: [],
  reportMoreTables: [],

  isReportMoreCategoriesHasMore: true,
  isReportMoreProductsHasMore: true,
  isReportMoreCashiersHasMore: true,
  isReportMorePaymentsHasMore: true,
  isReportMoreTablesHasMore: true,

  fetchReportMoreCategoriesParams: {},
  fetchReportMoreCategoriesLoading: false,
  fetchReportMoreCategoriesSuccess: null,
  fetchReportMoreCategoriesFailed: null,

  fetchReportMoreProductsParams: {},
  fetchReportMoreProductsLoading: false,
  fetchReportMoreProductsSuccess: null,
  fetchReportMoreProductsFailed: null,

  fetchReportMoreCashiersParams: {},
  fetchReportMoreCashiersLoading: false,
  fetchReportMoreCashiersSuccess: null,
  fetchReportMoreCashiersFailed: null,

  fetchReportMorePaymentsParams: {},
  fetchReportMorePaymentsLoading: false,
  fetchReportMorePaymentsSuccess: null,
  fetchReportMorePaymentsFailed: null,

  fetchReportMoreTablesParams: {},
  fetchReportMoreTablesLoading: false,
  fetchReportMoreTablesSuccess: null,
  fetchReportMoreTablesFailed: null,

  isFetchReportMoreCategoriesHitted: false,
  isFetchReportMoreProductsHitted: false,
  isFetchReportMoreCashiersHitted: false,
  isFetchReportMorePaymentsHitted: false,
  isFetchReportMoreTablesHitted: false,
};

export const reportMoreReducer = (
  state = REPORT_MORE_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case REPORT_MORE_ACTION_TYPES.SET_REPORT_MORE_CATEGORIES:
      return { ...state, reportMoreCategories: payload };
    case REPORT_MORE_ACTION_TYPES.SET_REPORT_MORE_PRODUCTS:
      return { ...state, reportMoreProducts: payload };
    case REPORT_MORE_ACTION_TYPES.SET_REPORT_MORE_CASHIERS:
      return { ...state, reportMoreCashiers: payload };
    case REPORT_MORE_ACTION_TYPES.SET_REPORT_MORE_PAYMENTS:
      return { ...state, reportMorePayments: payload };
    case REPORT_MORE_ACTION_TYPES.SET_REPORT_MORE_TABLES:
      return { ...state, reportMoreTables: payload };

    case REPORT_MORE_ACTION_TYPES.SET_IS_REPORT_MORE_CATEGORIES_HAS_MORE:
      return { ...state, isReportMoreCategoriesHasMore: payload };
    case REPORT_MORE_ACTION_TYPES.SET_IS_REPORT_MORE_PRODUCTS_HAS_MORE:
      return { ...state, isReportMoreProductsHasMore: payload };
    case REPORT_MORE_ACTION_TYPES.SET_IS_REPORT_MORE_CASHIERS_HAS_MORE:
      return { ...state, isReportMoreCashiersHasMore: payload };
    case REPORT_MORE_ACTION_TYPES.SET_IS_REPORT_MORE_PAYMENTS_HAS_MORE:
      return { ...state, isReportMorePaymentsHasMore: payload };
    case REPORT_MORE_ACTION_TYPES.SET_IS_REPORT_MORE_TABLES_HAS_MORE:
      return { ...state, isReportMoreTablesHasMore: payload };

    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CATEGORIES_PARAMS:
      return { ...state, fetchReportMoreCategoriesParams: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CATEGORIES_LOADING:
      return { ...state, fetchReportMoreCategoriesLoading: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CATEGORIES_SUCCESS:
      return { ...state, fetchReportMoreCategoriesSuccess: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CATEGORIES_FAILED:
      return { ...state, fetchReportMoreCategoriesFailed: payload };

    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PRODUCTS_PARAMS:
      return { ...state, fetchReportMoreProductsParams: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PRODUCTS_LOADING:
      return { ...state, fetchReportMoreProductsLoading: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PRODUCTS_SUCCESS:
      return { ...state, fetchReportMoreProductsSuccess: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PRODUCTS_FAILED:
      return { ...state, fetchReportMoreProductsFailed: payload };

    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CASHIERS_PARAMS:
      return { ...state, fetchReportMoreCashiersParams: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CASHIERS_LOADING:
      return { ...state, fetchReportMoreCashiersLoading: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CASHIERS_SUCCESS:
      return { ...state, fetchReportMoreCashiersSuccess: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CASHIERS_FAILED:
      return { ...state, fetchReportMoreCashiersFailed: payload };

    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PAYMENTS_PARAMS:
      return { ...state, fetchReportMorePaymentsParams: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PAYMENTS_LOADING:
      return { ...state, fetchReportMorePaymentsLoading: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PAYMENTS_SUCCESS:
      return { ...state, fetchReportMorePaymentsSuccess: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PAYMENTS_FAILED:
      return { ...state, fetchReportMorePaymentsFailed: payload };

    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_TABLES_PARAMS:
      return { ...state, fetchReportMoreTablesParams: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_TABLES_LOADING:
      return { ...state, fetchReportMoreTablesLoading: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_TABLES_SUCCESS:
      return { ...state, fetchReportMoreTablesSuccess: payload };
    case REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_TABLES_FAILED:
      return { ...state, fetchReportMoreTablesFailed: payload };

    case REPORT_MORE_ACTION_TYPES.APPEND_REPORT_MORE_CATEGORIES:
      return {
        ...state,
        reportMoreCategories: [...state.reportMoreCategories, ...payload],
      };
    case REPORT_MORE_ACTION_TYPES.APPEND_REPORT_MORE_PRODUCTS:
      return {
        ...state,
        reportMoreProducts: [...state.reportMoreProducts, ...payload],
      };
    case REPORT_MORE_ACTION_TYPES.APPEND_REPORT_MORE_CASHIERS:
      return {
        ...state,
        reportMoreCashiers: [...state.reportMoreCashiers, ...payload],
      };
    case REPORT_MORE_ACTION_TYPES.APPEND_REPORT_MORE_PAYMENTS:
      return {
        ...state,
        reportMorePayments: [...state.reportMorePayments, ...payload],
      };
    case REPORT_MORE_ACTION_TYPES.APPEND_REPORT_MORE_TABLES:
      return {
        ...state,
        reportMoreTables: [...state.reportMoreTables, ...payload],
      };

    case REPORT_MORE_ACTION_TYPES.SET_IS_FETCH_REPORT_MORE_CATEGORIES_HITTED:
      return { ...state, isFetchReportMoreCategoriesHitted: payload };
    case REPORT_MORE_ACTION_TYPES.SET_IS_FETCH_REPORT_MORE_PRODUCTS_HITTED:
      return { ...state, isFetchReportMoreProductsHitted: payload };
    case REPORT_MORE_ACTION_TYPES.SET_IS_FETCH_REPORT_MORE_CASHIERS_HITTED:
      return { ...state, isFetchReportMoreCashiersHitted: payload };
    case REPORT_MORE_ACTION_TYPES.SET_IS_FETCH_REPORT_MORE_PAYMENTS_HITTED:
      return { ...state, isFetchReportMorePaymentsHitted: payload };
    case REPORT_MORE_ACTION_TYPES.SET_IS_FETCH_REPORT_MORE_TABLES_HITTED:
      return { ...state, isFetchReportMoreTablesHitted: payload };

    case REPORT_MORE_ACTION_TYPES.RESET_REPORT_MORE_REDUCER:
      return REPORT_MORE_INITIAL_STATE;
    default:
      return state;
  }
};
