import SCHEDULE_ACTION_TYPES from "./schedule.type";
import { createAction } from "../../utils/store.utils";

export const setSchedules = (schedules) => createAction(SCHEDULE_ACTION_TYPES.SET_SCHEDULES, schedules);

export const setFetchSchedulesIncludes = (fetchSchedulesIncludes) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_INCLUDES, fetchSchedulesIncludes);
export const setFetchSchedulesFilterMarketId = (fetchSchedulesFilterMarketId) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_FILTER_MARKET_ID, fetchSchedulesFilterMarketId);
export const setFetchSchedulesFilterBranchId = (fetchSchedulesFilterBranchId) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_FILTER_BRANCH_ID, fetchSchedulesFilterBranchId);
export const setFetchSchedulesLoading = (fetchSchedulesLoading) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_LOADING, fetchSchedulesLoading);
export const setFetchSchedulesSuccess = (fetchSchedulesSuccess) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_SUCCESS, fetchSchedulesSuccess);
export const setFetchSchedulesFailed = (fetchSchedulesFailed) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_FETCH_SCHEDULES_FAILED, fetchSchedulesFailed);

export const setCreateOrUpdateScheduleLoading = (createOrUpdateScheduleLoading) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_CREATE_OR_UPDATE_SCHEDULE_LOADING, createOrUpdateScheduleLoading);
export const setCreateOrUpdateScheduleSuccess = (createOrUpdateScheduleSuccess) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_CREATE_OR_UPDATE_SCHEDULE_SUCCESS, createOrUpdateScheduleSuccess);
export const setCreateOrUpdateScheduleFailed = (createOrUpdateScheduleFailed) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_CREATE_OR_UPDATE_SCHEDULE_FAILED, createOrUpdateScheduleFailed);

export const setIsFetchSchedulesHitted = (isFetchSchedulesHitted) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_IS_FETCH_SCHEDULES_HITTED, isFetchSchedulesHitted);
export const setIsCreateOrUpdateScheduleHitted = (isCreateOrUpdateScheduleHitted) =>
  createAction(SCHEDULE_ACTION_TYPES.SET_IS_CREATE_OR_UPDATE_SCHEDULE_HITTED, isCreateOrUpdateScheduleHitted);

export const fetchSchedulesStart = () => createAction(SCHEDULE_ACTION_TYPES.FETCH_SCHEDULES_START);
export const createOrUpdateScheduleStart = (request) =>
  createAction(SCHEDULE_ACTION_TYPES.CREATE_OR_UPDATE_SCHEDULE_START, request);

export const resetScheduleReducer = () => createAction(SCHEDULE_ACTION_TYPES.RESET_SCHEDULE_REDUCER);
