import { createAction } from "../../utils/store.utils";

import FEATURE_SUBSCRIPTION_ACTION_TYPES from "./feature-subscription.type";

export const setFeature = (feature) =>
  createAction(FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_FEATURE, feature);

export const setCreateFeatureLoading = (createFeatureLoading) =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_CREATE_FEATURE_LOADING,
    createFeatureLoading
  );
export const setCreateFeatureSuccess = (createFeatureSuccess) =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_CREATE_FEATURE_SUCCESS,
    createFeatureSuccess
  );
export const setCreateFeatureFailed = (createFeatureFailed) =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_CREATE_FEATURE_FAILED,
    createFeatureFailed
  );

export const setUpdateFeatureLoading = (updateFeatureLoading) =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_FEATURE_LOADING,
    updateFeatureLoading
  );
export const setUpdateFeatureSuccess = (updateFeatureSuccess) =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_FEATURE_SUCCESS,
    updateFeatureSuccess
  );
export const setUpdateFeatureFailed = (updateFeatureFailed) =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_FEATURE_FAILED,
    updateFeatureFailed
  );

export const setDeleteFeatureLoading = (deleteFeatureLoading) =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_DELETE_FEATURE_LOADING,
    deleteFeatureLoading
  );
export const setDeleteFeatureSuccess = (deleteFeatureSuccess) =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_DELETE_FEATURE_SUCCESS,
    deleteFeatureSuccess
  );
export const setDeleteFeatureFailed = (deleteFeatureFailed) =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_DELETE_FEATURE_FAILED,
    deleteFeatureFailed
  );

export const setIsCreateFeatureHitted = (isCreateFeatureHitted) =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_IS_CREATE_FEATURE_HITTED,
    isCreateFeatureHitted
  );
export const setIsUpdateFeatureHitted = (isUpdateFeatureHitted) =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_IS_UPDATE_FEATURE_HITTED,
    isUpdateFeatureHitted
  );
export const setIsDeleteFeatureHitted = (isDeleteFeatureHitted) =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.SET_IS_DELETE_FEATURE_HITTED,
    isDeleteFeatureHitted
  );

export const createFeatureStart = (request) =>
  createAction(FEATURE_SUBSCRIPTION_ACTION_TYPES.CREATE_FEATURE_START, request);
export const updateFeatureStart = (featureId, request) =>
  createAction(FEATURE_SUBSCRIPTION_ACTION_TYPES.UPDATE_FEATURE_START, {
    featureId,
    request,
  });
export const deleteFeatureStart = (featureId) =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.DELETE_FEATURE_START,
    featureId
  );

export const resetFeatureSubscriptionReducer = () =>
  createAction(
    FEATURE_SUBSCRIPTION_ACTION_TYPES.RESET_FEATURE_SUBSCRIPTION_REDUCER
  );
