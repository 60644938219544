import { createAction } from "../../utils/store.utils";

import PERMISSION_MERCHANT_ACTION_TYPES from "./permission-merchant.type";

export const setPermissionTab = (permissionTab) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_PERMISSION_TAB,
    permissionTab
  );
export const setPermissionTypes = (permissionTypes) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_PERMISSION_TYPES,
    permissionTypes
  );
export const setPermissionMerchants = (permissionMerchants) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_PERMISSION_MERCHANTS,
    permissionMerchants
  );
export const setPermissionSubscribers = (permissionSubscribers) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_PERMISSION_SUBSCRIBERS,
    permissionSubscribers
  );
export const setSelectPermissionMerchants = (selectPermissionMerchants) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_SELECT_PERMISSION_MERCHANTS,
    selectPermissionMerchants
  );
export const setSelectPermissionSubscribers = (selectPermissionSubscribers) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_SELECT_PERMISSION_SUBSCRIBERS,
    selectPermissionSubscribers
  );
export const setPermission = (permission) =>
  createAction(PERMISSION_MERCHANT_ACTION_TYPES.SET_PERMISSION, permission);

export const setIsPermissionMerchantsHasMore = (isPermissionMerchantsHasMore) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_PERMISSION_MERCHANTS_HAS_MORE,
    isPermissionMerchantsHasMore
  );
export const setIsPermissionSubscribersHasMore = (
  isPermissionSubscribersHasMore
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE,
    isPermissionSubscribersHasMore
  );
export const setIsSelectPermissionMerchantsHasMore = (
  isSelectPermissionMerchantsHasMore
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_SELECT_PERMISSION_MERCHANTS_HAS_MORE,
    isSelectPermissionMerchantsHasMore
  );
export const setIsSelectPermissionSubscribersHasMore = (
  isSelectPermissionSubscribersHasMore
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_SELECT_PERMISSION_SUBSCRIBERS_HAS_MORE,
    isSelectPermissionSubscribersHasMore
  );

export const setFetchPermissionMerchantsSearch = (
  fetchPermissionMerchantsSearch
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_SEARCH,
    fetchPermissionMerchantsSearch
  );
export const setFetchPermissionMerchantsSort = (fetchPermissionMerchantsSort) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_SORT,
    fetchPermissionMerchantsSort
  );
export const setFetchPermissionMerchantsKeyBy = (
  fetchPermissionMerchantsKeyBy
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_KEY_BY,
    fetchPermissionMerchantsKeyBy
  );
export const setFetchPermissionMerchantsPage = (fetchPermissionMerchantsPage) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_PAGE,
    fetchPermissionMerchantsPage
  );
export const setFetchPermissionMerchantsPerPage = (
  fetchPermissionMerchantsPerPage
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_PER_PAGE,
    fetchPermissionMerchantsPerPage
  );
export const setFetchPermissionMerchantsIncludes = (
  fetchPermissionMerchantsIncludes
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_INCLUDES,
    fetchPermissionMerchantsIncludes
  );
export const setFetchPermissionMerchantsFilterMerchantId = (
  fetchPermissionMerchantsFilterMerchantId
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_FILTER_MERCHANT_ID,
    fetchPermissionMerchantsFilterMerchantId
  );
export const setFetchPermissionMerchantsFilterIsDefault = (
  fetchPermissionMerchantsFilterIsDefault
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_FILTER_IS_DEFAULT,
    fetchPermissionMerchantsFilterIsDefault
  );
export const setFetchPermissionMerchantsLoading = (
  fetchPermissionMerchantsLoading
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_LOADING,
    fetchPermissionMerchantsLoading
  );
export const setFetchPermissionMerchantsSuccess = (
  fetchPermissionMerchantsSuccess
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_SUCCESS,
    fetchPermissionMerchantsSuccess
  );
export const setFetchPermissionMerchantsFailed = (
  fetchPermissionMerchantsFailed
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_MERCHANTS_FAILED,
    fetchPermissionMerchantsFailed
  );

export const setFetchPermissionSubscribersSearch = (
  fetchPermissionSubscribersSearch
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SEARCH,
    fetchPermissionSubscribersSearch
  );
export const setFetchPermissionSubscribersSort = (
  fetchPermissionSubscribersSort
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SORT,
    fetchPermissionSubscribersSort
  );
export const setFetchPermissionSubscribersKeyBy = (
  fetchPermissionSubscribersKeyBy
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_KEY_BY,
    fetchPermissionSubscribersKeyBy
  );
export const setFetchPermissionSubscribersPage = (
  fetchPermissionSubscribersPage
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_PAGE,
    fetchPermissionSubscribersPage
  );
export const setFetchPermissionSubscribersPerPage = (
  fetchPermissionSubscribersPerPage
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_PER_PAGE,
    fetchPermissionSubscribersPerPage
  );
export const setFetchPermissionSubscribersIncludes = (
  fetchPermissionSubscribersIncludes
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_INCLUDES,
    fetchPermissionSubscribersIncludes
  );
export const setFetchPermissionSubscribersFilterMerchantId = (
  fetchPermissionSubscribersFilterMerchantId
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FILTER_MERCHANT_ID,
    fetchPermissionSubscribersFilterMerchantId
  );
export const setFetchPermissionSubscribersFilterIsDefault = (
  fetchPermissionSubscribersFilterIsDefault
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT,
    fetchPermissionSubscribersFilterIsDefault
  );
export const setFetchPermissionSubscribersLoading = (
  fetchPermissionSubscribersLoading
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING,
    fetchPermissionSubscribersLoading
  );
export const setFetchPermissionSubscribersSuccess = (
  fetchPermissionSubscribersSuccess
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS,
    fetchPermissionSubscribersSuccess
  );
export const setFetchPermissionSubscribersFailed = (
  fetchPermissionSubscribersFailed
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED,
    fetchPermissionSubscribersFailed
  );

export const setFetchSelectPermissionMerchantsSearch = (
  fetchSelectPermissionMerchantsSearch
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_SEARCH,
    fetchSelectPermissionMerchantsSearch
  );
export const setFetchSelectPermissionMerchantsSort = (
  fetchSelectPermissionMerchantsSort
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_SORT,
    fetchSelectPermissionMerchantsSort
  );
export const setFetchSelectPermissionMerchantsKeyBy = (
  fetchSelectPermissionMerchantsKeyBy
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_KEY_BY,
    fetchSelectPermissionMerchantsKeyBy
  );
export const setFetchSelectPermissionMerchantsPage = (
  fetchSelectPermissionMerchantsPage
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_PAGE,
    fetchSelectPermissionMerchantsPage
  );
export const setFetchSelectPermissionMerchantsPerPage = (
  fetchSelectPermissionMerchantsPerPage
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_PER_PAGE,
    fetchSelectPermissionMerchantsPerPage
  );
export const setFetchSelectPermissionMerchantsIncludes = (
  fetchSelectPermissionMerchantsIncludes
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_INCLUDES,
    fetchSelectPermissionMerchantsIncludes
  );
export const setFetchSelectPermissionMerchantsFilterMerchantId = (
  fetchSelectPermissionMerchantsFilterMerchantId
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_FILTER_MERCHANT_ID,
    fetchSelectPermissionMerchantsFilterMerchantId
  );
export const setFetchSelectPermissionMerchantsFilterPermissionsIds = (
  fetchSelectPermissionMerchantsFilterPermissionsIds
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_FILTER_PERMISSIONS_IDS,
    fetchSelectPermissionMerchantsFilterPermissionsIds
  );
export const setFetchSelectPermissionMerchantsFilterIsDefault = (
  fetchSelectPermissionMerchantsFilterIsDefault
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_FILTER_IS_DEFAULT,
    fetchSelectPermissionMerchantsFilterIsDefault
  );
export const setFetchSelectPermissionMerchantsLoading = (
  fetchSelectPermissionMerchantsLoading
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_LOADING,
    fetchSelectPermissionMerchantsLoading
  );
export const setFetchSelectPermissionMerchantsSuccess = (
  fetchSelectPermissionMerchantsSuccess
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_SUCCESS,
    fetchSelectPermissionMerchantsSuccess
  );
export const setFetchSelectPermissionMerchantsFailed = (
  fetchSelectPermissionMerchantsFailed
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_MERCHANTS_FAILED,
    fetchSelectPermissionMerchantsFailed
  );

export const setFetchSelectPermissionSubscribersSearch = (
  fetchSelectPermissionSubscribersSearch
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SEARCH,
    fetchSelectPermissionSubscribersSearch
  );
export const setFetchSelectPermissionSubscribersSort = (
  fetchSelectPermissionSubscribersSort
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SORT,
    fetchSelectPermissionSubscribersSort
  );
export const setFetchSelectPermissionSubscribersKeyBy = (
  fetchSelectPermissionSubscribersKeyBy
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY,
    fetchSelectPermissionSubscribersKeyBy
  );
export const setFetchSelectPermissionSubscribersPage = (
  fetchSelectPermissionSubscribersPage
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PAGE,
    fetchSelectPermissionSubscribersPage
  );
export const setFetchSelectPermissionSubscribersPerPage = (
  fetchSelectPermissionSubscribersPerPage
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE,
    fetchSelectPermissionSubscribersPerPage
  );
export const setFetchSelectPermissionSubscribersIncludes = (
  fetchSelectPermissionSubscribersIncludes
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES,
    fetchSelectPermissionSubscribersIncludes
  );
export const setFetchSelectPermissionSubscribersFilterMerchantId = (
  fetchSelectPermissionSubscribersFilterMerchantId
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_MERCHANT_ID,
    fetchSelectPermissionSubscribersFilterMerchantId
  );
export const setFetchSelectPermissionSubscribersFilterPermissionsIds = (
  fetchSelectPermissionSubscribersFilterPermissionsIds
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS,
    fetchSelectPermissionSubscribersFilterPermissionsIds
  );
export const setFetchSelectPermissionSubscribersFilterIsDefault = (
  fetchSelectPermissionSubscribersFilterIsDefault
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT,
    fetchSelectPermissionSubscribersFilterIsDefault
  );
export const setFetchSelectPermissionSubscribersLoading = (
  fetchSelectPermissionSubscribersLoading
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_LOADING,
    fetchSelectPermissionSubscribersLoading
  );
export const setFetchSelectPermissionSubscribersSuccess = (
  fetchSelectPermissionSubscribersSuccess
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS,
    fetchSelectPermissionSubscribersSuccess
  );
export const setFetchSelectPermissionSubscribersFailed = (
  fetchSelectPermissionSubscribersFailed
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FAILED,
    fetchSelectPermissionSubscribersFailed
  );

export const setAppendSelectPermissionMerchantsSearch = (
  appendSelectPermissionMerchantsSearch
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_SEARCH,
    appendSelectPermissionMerchantsSearch
  );
export const setAppendSelectPermissionMerchantsSort = (
  appendSelectPermissionMerchantsSort
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_SORT,
    appendSelectPermissionMerchantsSort
  );
export const setAppendSelectPermissionMerchantsKeyBy = (
  appendSelectPermissionMerchantsKeyBy
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_KEY_BY,
    appendSelectPermissionMerchantsKeyBy
  );
export const setAppendSelectPermissionMerchantsPage = (
  appendSelectPermissionMerchantsPage
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_PAGE,
    appendSelectPermissionMerchantsPage
  );
export const setAppendSelectPermissionMerchantsPerPage = (
  appendSelectPermissionMerchantsPerPage
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_PER_PAGE,
    appendSelectPermissionMerchantsPerPage
  );
export const setAppendSelectPermissionMerchantsIncludes = (
  appendSelectPermissionMerchantsIncludes
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_INCLUDES,
    appendSelectPermissionMerchantsIncludes
  );
export const setAppendSelectPermissionMerchantsFilterMerchantId = (
  appendSelectPermissionMerchantsFilterMerchantId
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_FILTER_MERCHANT_ID,
    appendSelectPermissionMerchantsFilterMerchantId
  );
export const setAppendSelectPermissionMerchantsFilterPermissionsIds = (
  appendSelectPermissionMerchantsFilterPermissionsIds
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_FILTER_PERMISSIONS_IDS,
    appendSelectPermissionMerchantsFilterPermissionsIds
  );
export const setAppendSelectPermissionMerchantsFilterIsDefault = (
  appendSelectPermissionMerchantsFilterIsDefault
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_FILTER_IS_DEFAULT,
    appendSelectPermissionMerchantsFilterIsDefault
  );
export const setAppendSelectPermissionMerchantsLoading = (
  appendSelectPermissionMerchantsLoading
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_LOADING,
    appendSelectPermissionMerchantsLoading
  );
export const setAppendSelectPermissionMerchantsSuccess = (
  appendSelectPermissionMerchantsSuccess
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_SUCCESS,
    appendSelectPermissionMerchantsSuccess
  );
export const setAppendSelectPermissionMerchantsFailed = (
  appendSelectPermissionMerchantsFailed
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_MERCHANTS_FAILED,
    appendSelectPermissionMerchantsFailed
  );

export const setAppendSelectPermissionSubscribersSearch = (
  appendSelectPermissionSubscribersSearch
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SEARCH,
    appendSelectPermissionSubscribersSearch
  );
export const setAppendSelectPermissionSubscribersSort = (
  appendSelectPermissionSubscribersSort
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SORT,
    appendSelectPermissionSubscribersSort
  );
export const setAppendSelectPermissionSubscribersKeyBy = (
  appendSelectPermissionSubscribersKeyBy
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY,
    appendSelectPermissionSubscribersKeyBy
  );
export const setAppendSelectPermissionSubscribersPage = (
  appendSelectPermissionSubscribersPage
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PAGE,
    appendSelectPermissionSubscribersPage
  );
export const setAppendSelectPermissionSubscribersPerPage = (
  appendSelectPermissionSubscribersPerPage
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE,
    appendSelectPermissionSubscribersPerPage
  );
export const setAppendSelectPermissionSubscribersIncludes = (
  appendSelectPermissionSubscribersIncludes
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES,
    appendSelectPermissionSubscribersIncludes
  );
export const setAppendSelectPermissionSubscribersFilterMerchantId = (
  appendSelectPermissionSubscribersFilterMerchantId
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_MERCHANT_ID,
    appendSelectPermissionSubscribersFilterMerchantId
  );
export const setAppendSelectPermissionSubscribersFilterPermissionsIds = (
  appendSelectPermissionSubscribersFilterPermissionsIds
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS,
    appendSelectPermissionSubscribersFilterPermissionsIds
  );
export const setAppendSelectPermissionSubscribersFilterIsDefault = (
  appendSelectPermissionSubscribersFilterIsDefault
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT,
    appendSelectPermissionSubscribersFilterIsDefault
  );
export const setAppendSelectPermissionSubscribersLoading = (
  appendSelectPermissionSubscribersLoading
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_LOADING,
    appendSelectPermissionSubscribersLoading
  );
export const setAppendSelectPermissionSubscribersSuccess = (
  appendSelectPermissionSubscribersSuccess
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS,
    appendSelectPermissionSubscribersSuccess
  );
export const setAppendSelectPermissionSubscribersFailed = (
  appendSelectPermissionSubscribersFailed
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FAILED,
    appendSelectPermissionSubscribersFailed
  );

export const setFetchPermissionLoading = (fetchPermissionLoading) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_LOADING,
    fetchPermissionLoading
  );
export const setFetchPermissionSuccess = (fetchPermissionSuccess) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_SUCCESS,
    fetchPermissionSuccess
  );
export const setFetchPermissionFailed = (fetchPermissionFailed) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_FETCH_PERMISSION_FAILED,
    fetchPermissionFailed
  );

export const setCreatePermissionLoading = (createPermissionLoading) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_CREATE_PERMISSION_LOADING,
    createPermissionLoading
  );
export const setCreatePermissionSuccess = (createPermissionSuccess) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_CREATE_PERMISSION_SUCCESS,
    createPermissionSuccess
  );
export const setCreatePermissionFailed = (createPermissionFailed) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_CREATE_PERMISSION_FAILED,
    createPermissionFailed
  );

export const setUpdatePermissionLoading = (updatePermissionLoading) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_UPDATE_PERMISSION_LOADING,
    updatePermissionLoading
  );
export const setUpdatePermissionSuccess = (updatePermissionSuccess) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_UPDATE_PERMISSION_SUCCESS,
    updatePermissionSuccess
  );
export const setUpdatePermissionFailed = (updatePermissionFailed) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_UPDATE_PERMISSION_FAILED,
    updatePermissionFailed
  );

export const setDeletePermissionLoading = (deletePermissionLoading) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_DELETE_PERMISSION_LOADING,
    deletePermissionLoading
  );
export const setDeletePermissionSuccess = (deletePermissionSuccess) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_DELETE_PERMISSION_SUCCESS,
    deletePermissionSuccess
  );
export const setDeletePermissionFailed = (deletePermissionFailed) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_DELETE_PERMISSION_FAILED,
    deletePermissionFailed
  );

export const setIsFetchPermissionMerchantsHitted = (
  isFetchPermissionMerchantsHitted
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_PERMISSION_MERCHANTS_HITTED,
    isFetchPermissionMerchantsHitted
  );
export const setIsFetchPermissionSubscribersHitted = (
  isFetchPermissionSubscribersHitted
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED,
    isFetchPermissionSubscribersHitted
  );
export const setIsFetchSelectPermissionMerchantsHitted = (
  isFetchSelectPermissionMerchantsHitted
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_SELECT_PERMISSION_MERCHANTS_HITTED,
    isFetchSelectPermissionMerchantsHitted
  );
export const setIsFetchSelectPermissionSubscribersHitted = (
  isFetchSelectPermissionSubscribersHitted
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_SELECT_PERMISSION_SUBSCRIBERS_HITTED,
    isFetchSelectPermissionSubscribersHitted
  );
export const setIsAppendSelectPermissionMerchantsHitted = (
  isAppendSelectPermissionMerchantsHitted
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_APPEND_SELECT_PERMISSION_MERCHANTS_HITTED,
    isAppendSelectPermissionMerchantsHitted
  );
export const setIsAppendSelectPermissionSubscribersHitted = (
  isAppendSelectPermissionSubscribersHitted
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_APPEND_SELECT_PERMISSION_SUBSCRIBERS_HITTED,
    isAppendSelectPermissionSubscribersHitted
  );
export const setIsFetchPermissionHitted = (isFetchPermissionHitted) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_FETCH_PERMISSION_HITTED,
    isFetchPermissionHitted
  );
export const setIsCreatePermissionHitted = (isCreatePermissionHitted) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_CREATE_PERMISSION_HITTED,
    isCreatePermissionHitted
  );
export const setIsUpdatePermissionHitted = (isUpdatePermissionHitted) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_UPDATE_PERMISSION_HITTED,
    isUpdatePermissionHitted
  );
export const setIsDeletePermissionHitted = (isDeletePermissionHitted) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.SET_IS_DELETE_PERMISSION_HITTED,
    isDeletePermissionHitted
  );

export const appendPermissionMerchants = (permissionMerchants) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.APPEND_PERMISSION_MERCHANTS,
    permissionMerchants
  );
export const appendPermissionSubscribers = (permissionSubscribers) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.APPEND_PERMISSION_SUBSCRIBERS,
    permissionSubscribers
  );
export const appendSelectPermissionMerchants = (selectPermissionMerchants) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.APPEND_SELECT_PERMISSION_MERCHANTS,
    selectPermissionMerchants
  );
export const appendSelectPermissionSubscribers = (
  selectPermissionSubscribers
) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.APPEND_SELECT_PERMISSION_SUBSCRIBERS,
    selectPermissionSubscribers
  );

export const fetchPermissionMerchantsStart = () =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.FETCH_PERMISSION_MERCHANTS_START
  );
export const fetchPermissionSubscribersStart = () =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.FETCH_PERMISSION_SUBSCRIBERS_START
  );
export const fetchSelectPermissionMerchantsStart = () =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.FETCH_SELECT_PERMISSION_MERCHANTS_START
  );
export const fetchSelectPermissionSubscribersStart = () =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.FETCH_SELECT_PERMISSION_SUBSCRIBERS_START
  );
export const appendSelectPermissionMerchantsStart = () =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.APPEND_SELECT_PERMISSION_MERCHANTS_START
  );
export const appendSelectPermissionSubscribersStart = () =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.APPEND_SELECT_PERMISSION_SUBSCRIBERS_START
  );
export const fetchPermissionStart = (permissionId) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.FETCH_PERMISSION_START,
    permissionId
  );
export const createPermissionStart = (request) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.CREATE_PERMISSION_START,
    request
  );
export const updatePermissionStart = (permissionId, request) =>
  createAction(PERMISSION_MERCHANT_ACTION_TYPES.UPDATE_PERMISSION_START, {
    permissionId,
    request,
  });
export const deletePermissionStart = (permissionId) =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.DELETE_PERMISSION_START,
    permissionId
  );

export const resetPermissionMerchantReducer = () =>
  createAction(
    PERMISSION_MERCHANT_ACTION_TYPES.RESET_PERMISSION_MERCHANT_REDUCER
  );
