import apiService from "./api";

export const getUserDrivers = async (parameters) =>
  (await apiService.get("/v2/users/drivers", parameters)).data;
export const getUserDriver = async (driverId) =>
  (await apiService.get(`/v2/users/drivers/${driverId}`)).data;
export const createUserDriver = async (request) =>
  (await apiService.post("/v2/users/drivers", request)).data;
export const updateUserDriver = async (driverId, request) =>
  (await apiService.post(`/v2/users/drivers/${driverId}`, request)).data;

export const addMarketUserDriver = async (driverId, marketId) =>
  (await apiService.post(`/v2/users/drivers/${driverId}/markets/${marketId}`))
    .data;
export const removeMarketUserDriver = async (driverId, marketId) =>
  (await apiService.delete(`/v2/users/drivers/${driverId}/markets/${marketId}`))
    .data;
