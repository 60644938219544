import REPORT_ATTENDANCE_ACTION_TYPES from "./report-attendance.type";
import { createAction } from "../../utils/store.utils";

export const setReportTotals = (reportTotals) =>
  createAction(REPORT_ATTENDANCE_ACTION_TYPES.SET_REPORT_TOTALS, reportTotals);
export const setReportAttendances = (reportAttendances) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.SET_REPORT_ATTENDANCES,
    reportAttendances
  );
export const setReportCashierTotals = (reportCashierTotals) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.SET_REPORT_CASHIER_TOTALS,
    reportCashierTotals
  );
export const setReportBranchTotals = (reportBranchTotals) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.SET_REPORT_BRANCH_TOTALS,
    reportBranchTotals
  );

export const setIsReportAttendancesHasMore = (isReportAttendancesHasMore) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.SET_IS_REPORT_ATTENDANCES_HAS_MORE,
    isReportAttendancesHasMore
  );

export const setFetchReportsFilterMarketId = (fetchReportsFilterMarketId) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORTS_FILTER_MARKET_ID,
    fetchReportsFilterMarketId
  );
export const setFetchReportsFilterBranchId = (fetchReportsFilterBranchId) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORTS_FILTER_BRANCH_ID,
    fetchReportsFilterBranchId
  );
export const setFetchReportsFilterUserId = (fetchReportsFilterUserId) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORTS_FILTER_USER_ID,
    fetchReportsFilterUserId
  );
export const setFetchReportsFilterStartAtAfter = (
  fetchReportsFilterStartAtAfter
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORTS_FILTER_START_AT_AFTER,
    fetchReportsFilterStartAtAfter
  );
export const setFetchReportsFilterEndAtBefore = (
  fetchReportsFilterEndAtBefore
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORTS_FILTER_END_AT_BEFORE,
    fetchReportsFilterEndAtBefore
  );

export const setFetchReportTotalsLoading = (fetchReportTotalsLoading) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_TOTALS_LOADING,
    fetchReportTotalsLoading
  );
export const setFetchReportTotalsSuccess = (fetchReportTotalsSuccess) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_TOTALS_SUCCESS,
    fetchReportTotalsSuccess
  );
export const setFetchReportTotalsFailed = (fetchReportTotalsFailed) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_TOTALS_FAILED,
    fetchReportTotalsFailed
  );

export const setFetchReportAttendancesSearch = (fetchReportAttendancesSearch) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_SEARCH,
    fetchReportAttendancesSearch
  );
export const setFetchReportAttendancesSort = (fetchReportAttendancesSort) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_SORT,
    fetchReportAttendancesSort
  );
export const setFetchReportAttendancesKeyBy = (fetchReportAttendancesKeyBy) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_KEY_BY,
    fetchReportAttendancesKeyBy
  );
export const setFetchReportAttendancesPage = (fetchReportAttendancesPage) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_PAGE,
    fetchReportAttendancesPage
  );
export const setFetchReportAttendancesPerPage = (
  fetchReportAttendancesPerPage
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_PER_PAGE,
    fetchReportAttendancesPerPage
  );
export const setFetchReportAttendancesIncludes = (
  fetchReportAttendancesIncludes
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_INCLUDES,
    fetchReportAttendancesIncludes
  );
export const setFetchReportAttendancesLoading = (
  fetchReportAttendancesLoading
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_LOADING,
    fetchReportAttendancesLoading
  );
export const setFetchReportAttendancesSuccess = (
  fetchReportAttendancesSuccess
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_SUCCESS,
    fetchReportAttendancesSuccess
  );
export const setFetchReportAttendancesFailed = (fetchReportAttendancesFailed) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_FAILED,
    fetchReportAttendancesFailed
  );

export const setFetchReportAttendanceTotalsFilterOrderStatuses = (
  fetchReportAttendanceTotalsFilterOrderStatuses
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCE_TOTALS_FILTER_ORDER_STATUSES,
    fetchReportAttendanceTotalsFilterOrderStatuses
  );
export const setFetchReportAttendanceTotalsFilterTransactionStatuses = (
  fetchReportAttendanceTotalsFilterTransactionStatuses
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCE_TOTALS_FILTER_TRANSACTION_STATUSES,
    fetchReportAttendanceTotalsFilterTransactionStatuses
  );

export const setFetchReportCashierTotalsFilterCashierId = (
  fetchReportCashierTotalsFilterCashierId
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_CASHIER_TOTALS_FILTER_CASHIER_ID,
    fetchReportCashierTotalsFilterCashierId
  );
export const setFetchReportCashierTotalsLoading = (
  fetchReportCashierTotalsLoading
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_CASHIER_TOTALS_LOADING,
    fetchReportCashierTotalsLoading
  );
export const setFetchReportCashierTotalsSuccess = (
  fetchReportCashierTotalsSuccess
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_CASHIER_TOTALS_SUCCESS,
    fetchReportCashierTotalsSuccess
  );
export const setFetchReportCashierTotalsFailed = (
  fetchReportCashierTotalsFailed
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_CASHIER_TOTALS_FAILED,
    fetchReportCashierTotalsFailed
  );

export const setFetchReportBranchTotalsLoading = (
  fetchReportBranchTotalsLoading
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_BRANCH_TOTALS_LOADING,
    fetchReportBranchTotalsLoading
  );
export const setFetchReportBranchTotalsSuccess = (
  fetchReportBranchTotalsSuccess
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_BRANCH_TOTALS_SUCCESS,
    fetchReportBranchTotalsSuccess
  );
export const setFetchReportBranchTotalsFailed = (
  fetchReportBranchTotalsFailed
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_BRANCH_TOTALS_FAILED,
    fetchReportBranchTotalsFailed
  );

export const setIsFetchReportTotalsHitted = (isFetchReportTotalsHitted) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.SET_IS_FETCH_REPORT_TOTALS_HITTED,
    isFetchReportTotalsHitted
  );
export const setIsFetchReportAttendancesHitted = (
  isFetchReportAttendancesHitted
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.SET_IS_FETCH_REPORT_ATTENDANCES_HITTED,
    isFetchReportAttendancesHitted
  );
export const setIsFetchReportCashierTotalsHitted = (
  isFetchReportCashierTotalsHitted
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.SET_IS_FETCH_REPORT_CASHIER_TOTALS_HITTED,
    isFetchReportCashierTotalsHitted
  );
export const setIsFetchReportBranchTotalsHitted = (
  isFetchReportBranchTotalsHitted
) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.SET_IS_FETCH_REPORT_BRANCH_TOTALS_HITTED,
    isFetchReportBranchTotalsHitted
  );

export const appendReportAttendances = (reportAttendances) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.APPEND_REPORT_ATTENDANCES,
    reportAttendances
  );

export const fetchReportTotalsStart = () =>
  createAction(REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_TOTALS_START);
export const fetchReportAttendancesStart = () =>
  createAction(REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_START);
export const fetchReportCashierTotalsStart = (attendanceId) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_CASHIER_TOTALS_START,
    attendanceId
  );
export const fetchReportBranchTotalsStart = (attendanceId) =>
  createAction(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_BRANCH_TOTALS_START,
    attendanceId
  );

export const resetReportAttendanceReducer = () =>
  createAction(REPORT_ATTENDANCE_ACTION_TYPES.RESET_REPORT_ATTENDANCE_REDUCER);
