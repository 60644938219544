import { getRemovedDuplicates } from "../../utils/formatter.utils";

import SUPPLIER_ACTION_TYPES from "./supplier.type";

export const SUPPLIER_INITIAL_STATE = {
  suppliers: [],
  selectSuppliers: [],
  supplier: null,

  isSuppliersHasMore: true,
  isSelectSuppliersHasMore: true,

  fetchSuppliersSearch: null,
  fetchSuppliersPage: 1,
  fetchSuppliersPerPage: null,
  fetchSuppliersIncludes: null,
  fetchSuppliersFilterMarketId: null,
  fetchSuppliersFilterBranchId: null,
  fetchSuppliersFilterSuppliersIds: null,
  fetchSuppliersLoading: false,
  fetchSuppliersSuccess: null,
  fetchSuppliersFailed: null,

  fetchSelectSuppliersSearch: null,
  fetchSelectSuppliersPage: 1,
  fetchSelectSuppliersPerPage: null,
  fetchSelectSuppliersIncludes: null,
  fetchSelectSuppliersFilterMarketId: null,
  fetchSelectSuppliersFilterBranchId: null,
  fetchSelectSuppliersFilterSuppliersIds: null,
  fetchSelectSuppliersLoading: false,
  fetchSelectSuppliersSuccess: null,
  fetchSelectSuppliersFailed: null,

  fetchSupplierLoading: false,
  fetchSupplierSuccess: null,
  fetchSupplierFailed: null,

  createSupplierLoading: false,
  createSupplierSuccess: null,
  createSupplierFailed: null,

  updateSupplierLoading: false,
  updateSupplierSuccess: null,
  updateSupplierFailed: null,

  deleteSupplierLoading: false,
  deleteSupplierSuccess: null,
  deleteSupplierFailed: null,

  isFetchSuppliersHitted: false,
  isFetchSelectSuppliersHitted: false,
  isFetchSupplierHitted: false,
  isCreateSupplierHitted: false,
  isUpdateSupplierHitted: false,
  isDeleteSupplierHitted: false,
};

export const supplierReducer = (state = SUPPLIER_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SUPPLIER_ACTION_TYPES.SET_SUPPLIERS:
      return { ...state, suppliers: payload };
    case SUPPLIER_ACTION_TYPES.SET_SELECT_SUPPLIERS:
      return { ...state, selectSuppliers: payload };
    case SUPPLIER_ACTION_TYPES.SET_SUPPLIER:
      return { ...state, supplier: payload };

    case SUPPLIER_ACTION_TYPES.SET_IS_SUPPLIERS_HAS_MORE:
      return { ...state, isSuppliersHasMore: payload };
    case SUPPLIER_ACTION_TYPES.SET_IS_SELECT_SUPPLIERS_HAS_MORE:
      return { ...state, isSelectSuppliersHasMore: payload };

    case SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_SEARCH:
      return { ...state, fetchSuppliersSearch: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_PAGE:
      return { ...state, fetchSuppliersPage: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_PER_PAGE:
      return { ...state, fetchSuppliersPerPage: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_INCLUDES:
      return { ...state, fetchSuppliersIncludes: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_FILTER_MARKET_ID:
      return { ...state, fetchSuppliersFilterMarketId: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_FILTER_BRANCH_ID:
      return { ...state, fetchSuppliersFilterBranchId: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_FILTER_SUPPLIERS_IDS:
      return { ...state, fetchSuppliersFilterSuppliersIds: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_LOADING:
      return { ...state, fetchSuppliersLoading: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_SUCCESS:
      return { ...state, fetchSuppliersSuccess: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_FAILED:
      return { ...state, fetchSuppliersFailed: payload };

    case SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_SEARCH:
      return { ...state, fetchSelectSuppliersSearch: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_PAGE:
      return { ...state, fetchSelectSuppliersPage: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_PER_PAGE:
      return { ...state, fetchSelectSuppliersPerPage: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_INCLUDES:
      return { ...state, fetchSelectSuppliersIncludes: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_FILTER_MARKET_ID:
      return { ...state, fetchSelectSuppliersFilterMarketId: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_FILTER_BRANCH_ID:
      return { ...state, fetchSelectSuppliersFilterBranchId: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_FILTER_SUPPLIERS_IDS:
      return { ...state, fetchSelectSuppliersFilterSuppliersIds: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_LOADING:
      return { ...state, fetchSelectSuppliersLoading: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_SUCCESS:
      return { ...state, fetchSelectSuppliersSuccess: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SELECT_SUPPLIERS_FAILED:
      return { ...state, fetchSelectSuppliersFailed: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIER_LOADING:
      return { ...state, fetchSupplierLoading: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIER_SUCCESS:
      return { ...state, fetchSupplierSuccess: payload };
    case SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIER_FAILED:
      return { ...state, fetchSupplierFailed: payload };

    case SUPPLIER_ACTION_TYPES.SET_CREATE_SUPPLIER_LOADING:
      return { ...state, createSupplierLoading: payload };
    case SUPPLIER_ACTION_TYPES.SET_CREATE_SUPPLIER_SUCCESS:
      return { ...state, createSupplierSuccess: payload };
    case SUPPLIER_ACTION_TYPES.SET_CREATE_SUPPLIER_FAILED:
      return { ...state, createSupplierFailed: payload };

    case SUPPLIER_ACTION_TYPES.SET_UPDATE_SUPPLIER_LOADING:
      return { ...state, updateSupplierLoading: payload };
    case SUPPLIER_ACTION_TYPES.SET_UPDATE_SUPPLIER_SUCCESS:
      return { ...state, updateSupplierSuccess: payload };
    case SUPPLIER_ACTION_TYPES.SET_UPDATE_SUPPLIER_FAILED:
      return { ...state, updateSupplierFailed: payload };

    case SUPPLIER_ACTION_TYPES.SET_DELETE_SUPPLIER_LOADING:
      return { ...state, deleteSupplierLoading: payload };
    case SUPPLIER_ACTION_TYPES.SET_DELETE_SUPPLIER_SUCCESS:
      return { ...state, deleteSupplierSuccess: payload };
    case SUPPLIER_ACTION_TYPES.SET_DELETE_SUPPLIER_FAILED:
      return { ...state, deleteSupplierFailed: payload };

    case SUPPLIER_ACTION_TYPES.APPEND_SUPPLIERS:
      return { ...state, suppliers: getRemovedDuplicates([...state.suppliers, ...payload], "id") };
    case SUPPLIER_ACTION_TYPES.APPEND_SELECT_SUPPLIERS:
      return { ...state, selectSuppliers: getRemovedDuplicates([...state.selectSuppliers, ...payload], "id") };

    case SUPPLIER_ACTION_TYPES.SET_IS_FETCH_SUPPLIERS_HITTED:
      return { ...state, isFetchSuppliersHitted: payload };
    case SUPPLIER_ACTION_TYPES.SET_IS_FETCH_SELECT_SUPPLIERS_HITTED:
      return { ...state, isFetchSelectSuppliersHitted: payload };
    case SUPPLIER_ACTION_TYPES.SET_IS_FETCH_SUPPLIER_HITTED:
      return { ...state, isFetchSupplierHitted: payload };
    case SUPPLIER_ACTION_TYPES.SET_IS_CREATE_SUPPLIER_HITTED:
      return { ...state, isCreateSupplierHitted: payload };
    case SUPPLIER_ACTION_TYPES.SET_IS_UPDATE_SUPPLIER_HITTED:
      return { ...state, isUpdateSupplierHitted: payload };
    case SUPPLIER_ACTION_TYPES.SET_IS_DELETE_SUPPLIER_HITTED:
      return { ...state, isDeleteSupplierHitted: payload };

    case SUPPLIER_ACTION_TYPES.RESET_SUPPLIER_REDUCER:
      return SUPPLIER_INITIAL_STATE;
    default:
      return state;
  }
};
