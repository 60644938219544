import PAYMENT_METHOD_ACTION_TYPES from "./payment-method.type";
import { createAction } from "../../utils/store.utils";

export const setPaymentMethods = (paymentMethods) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_PAYMENT_METHODS, paymentMethods);
export const setSelectPaymentMethods = (selectPaymentMethods) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_SELECT_PAYMENT_METHODS, selectPaymentMethods);
export const setPaymentMethod = (paymentMethod) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_PAYMENT_METHOD, paymentMethod);

export const setIsPaymentMethodsHasMore = (isPaymentMethodsHasMore) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_IS_PAYMENT_METHODS_HAS_MORE, isPaymentMethodsHasMore);
export const setIsSelectPaymentMethodsHasMore = (isSelectPaymentMethodsHasMore) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_IS_SELECT_PAYMENT_METHODS_HAS_MORE, isSelectPaymentMethodsHasMore);

export const setFetchPaymentMethodsPage = (fetchPaymentMethodsPage) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_PAGE, fetchPaymentMethodsPage);
export const setFetchPaymentMethodsPerPage = (fetchPaymentMethodsPerPage) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_PER_PAGE, fetchPaymentMethodsPerPage);
export const setFetchPaymentMethodsLoading = (fetchPaymentMethodsLoading) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_LOADING, fetchPaymentMethodsLoading);
export const setFetchPaymentMethodsSuccess = (fetchPaymentMethodsSuccess) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_SUCCESS, fetchPaymentMethodsSuccess);
export const setFetchPaymentMethodsFailed = (fetchPaymentMethodsFailed) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_FAILED, fetchPaymentMethodsFailed);

export const setFetchSelectPaymentMethodsPage = (fetchSelectPaymentMethodsPage) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHODS_PAGE, fetchSelectPaymentMethodsPage);
export const setFetchSelectPaymentMethodsPerPage = (fetchSelectPaymentMethodsPerPage) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHODS_PER_PAGE, fetchSelectPaymentMethodsPerPage);
export const setFetchSelectPaymentMethodsLoading = (fetchSelectPaymentMethodsLoading) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHODS_LOADING, fetchSelectPaymentMethodsLoading);
export const setFetchSelectPaymentMethodsSuccess = (fetchSelectPaymentMethodsSuccess) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHODS_SUCCESS, fetchSelectPaymentMethodsSuccess);
export const setFetchSelectPaymentMethodsFailed = (fetchSelectPaymentMethodsFailed) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHODS_FAILED, fetchSelectPaymentMethodsFailed);

export const setCreatePaymentMethodLoading = (createPaymentMethodLoading) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_CREATE_PAYMENT_METHOD_LOADING, createPaymentMethodLoading);
export const setCreatePaymentMethodSuccess = (createPaymentMethodSuccess) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_CREATE_PAYMENT_METHOD_SUCCESS, createPaymentMethodSuccess);
export const setCreatePaymentMethodFailed = (createPaymentMethodFailed) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_CREATE_PAYMENT_METHOD_FAILED, createPaymentMethodFailed);

export const setUpdatePaymentMethodLoading = (updatePaymentMethodLoading) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_UPDATE_PAYMENT_METHOD_LOADING, updatePaymentMethodLoading);
export const setUpdatePaymentMethodSuccess = (updatePaymentMethodSuccess) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_UPDATE_PAYMENT_METHOD_SUCCESS, updatePaymentMethodSuccess);
export const setUpdatePaymentMethodFailed = (updatePaymentMethodFailed) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_UPDATE_PAYMENT_METHOD_FAILED, updatePaymentMethodFailed);

export const setDeletePaymentMethodLoading = (deletePaymentMethodLoading) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_DELETE_PAYMENT_METHOD_LOADING, deletePaymentMethodLoading);
export const setDeletePaymentMethodSuccess = (deletePaymentMethodSuccess) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_DELETE_PAYMENT_METHOD_SUCCESS, deletePaymentMethodSuccess);
export const setDeletePaymentMethodFailed = (deletePaymentMethodFailed) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_DELETE_PAYMENT_METHOD_FAILED, deletePaymentMethodFailed);

export const appendPaymentMethods = (paymentMethods) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.APPEND_PAYMENT_METHODS, paymentMethods);
export const appendSelectPaymentMethods = (selectPaymentMethods) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.APPEND_SELECT_PAYMENT_METHODS, selectPaymentMethods);

export const fetchPaymentMethodsStart = () => createAction(PAYMENT_METHOD_ACTION_TYPES.FETCH_PAYMENT_METHODS_START);
export const fetchSelectPaymentMethodsStart = () =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.FETCH_SELECT_PAYMENT_METHODS_START);
export const createPaymentMethodStart = (request) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.CREATE_PAYMENT_METHOD_START, request);
export const updatePaymentMethodStart = (paymentMethodKey, request) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.UPDATE_PAYMENT_METHOD_START, { paymentMethodKey, request });
export const deletePaymentMethodStart = (paymentMethodKey) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.DELETE_PAYMENT_METHOD_START, paymentMethodKey);

export const setIsFetchPaymentMethodsHitted = (isFetchPaymentMethodsHitted) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_IS_FETCH_PAYMENT_METHODS_HITTED, isFetchPaymentMethodsHitted);
export const setIsFetchSelectPaymentMethodsHitted = (isFetchSelectPaymentMethodsHitted) =>
  createAction(
    PAYMENT_METHOD_ACTION_TYPES.SET_IS_FETCH_SELECT_PAYMENT_METHODS_HITTED,
    isFetchSelectPaymentMethodsHitted
  );
export const setIsCreatePaymentMethodHitted = (isCreatePaymentMethodHitted) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_IS_CREATE_PAYMENT_METHOD_HITTED, isCreatePaymentMethodHitted);
export const setIsUpdatePaymentMethodHitted = (isUpdatePaymentMethodHitted) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_IS_CREATE_PAYMENT_METHOD_HITTED, isUpdatePaymentMethodHitted);
export const setIsDeletePaymentMethodHitted = (isDeletePaymentMethodHitted) =>
  createAction(PAYMENT_METHOD_ACTION_TYPES.SET_IS_CREATE_PAYMENT_METHOD_HITTED, isDeletePaymentMethodHitted);

export const resetPaymentMethodReducer = () => createAction(PAYMENT_METHOD_ACTION_TYPES.RESET_PAYMENT_METHOD_REDUCER);
