import { BASE_URL } from "../config";

import apiService from "./api";

export const getOrderReceipt = async (id, parameters) =>
  await apiService.externalBlobGet(
    `${BASE_URL}/pdf/order/${id}/receipt`,
    parameters
  );

export const getOrderNotes = async (id, parameters) =>
  await apiService.externalBlobGet(
    `${BASE_URL}/pdf/order/${id}/notes`,
    parameters
  );

export const getExtendPeriodReceipt = async (id, parameters) =>
  await apiService.externalBlobGet(
    `${BASE_URL}/pdf/extend-period/${id}`,
    parameters
  );
