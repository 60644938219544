import { takeLatest, put, all, call, select } from "redux-saga/effects";

import USER_DRIVER_ACTION_TYPES from "./user-driver.type";

import {
  appendUserDrivers,
  setUserDriver,
  setUserDrivers,
  setCreateUserDriverFailed,
  setCreateUserDriverLoading,
  setCreateUserDriverSuccess,
  setFetchUserDriverFailed,
  setFetchUserDriverLoading,
  setFetchUserDriverSuccess,
  setFetchUserDriversFailed,
  setFetchUserDriversLoading,
  setFetchUserDriversPage,
  setFetchUserDriversSuccess,
  setIsUserDriversHasMore,
  setIsCreateUserDriverHitted,
  setIsFetchUserDriverHitted,
  setIsFetchUserDriversHitted,
  setIsUpdateUserDriverHitted,
  setUpdateUserDriverFailed,
  setUpdateUserDriverLoading,
  setUpdateUserDriverSuccess,
  setAddMarketUserDriverLoading,
  setAddMarketUserDriverSuccess,
  setAddMarketUserDriverFailed,
  setIsAddMarketUserDriverHitted,
  setRemoveMarketUserDriverLoading,
  setRemoveMarketUserDriverSuccess,
  setRemoveMarketUserDriverFailed,
  setIsRemoveMarketUserDriverHitted,
} from "./user-driver.action";
import {
  getFetchUserDriversFilterGender,
  getFetchUserDriversFilterMarketId,
  getFetchUserDriversFilterStatus,
  getFetchUserDriversFilterVehicleType,
  getFetchUserDriversIncludes,
  getFetchUserDriversKeyBy,
  getFetchUserDriversPage,
  getFetchUserDriversPerPage,
  getFetchUserDriversSearch,
  getFetchUserDriversSort,
  getIsFetchUserDriversHitted,
} from "./user-driver.selector";

import {
  addMarketUserDriver,
  createUserDriver,
  getUserDriver,
  getUserDrivers,
  removeMarketUserDriver,
  updateUserDriver,
} from "../../api/user-driver.api";

export function* _getUserDrivers() {
  try {
    yield put(setFetchUserDriversLoading(true));

    const search = yield select(getFetchUserDriversSearch);
    const sort = yield select(getFetchUserDriversSort);
    const key_by = yield select(getFetchUserDriversKeyBy);
    const page = yield select(getFetchUserDriversPage);
    const per_page = yield select(getFetchUserDriversPerPage);
    const includes = yield select(getFetchUserDriversIncludes);
    const gender = yield select(getFetchUserDriversFilterGender);
    const status = yield select(getFetchUserDriversFilterStatus);
    const vehicle_type = yield select(getFetchUserDriversFilterVehicleType);
    const market_id = yield select(getFetchUserDriversFilterMarketId);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        gender,
        status,
        vehicle_type,
        market_id,
      },
    };

    const {
      meta: { message },
      data: { data: userDrivers },
    } = yield call(getUserDrivers, parameters);

    yield put(setIsFetchUserDriversHitted(true));
    yield put(setIsUserDriversHasMore(userDrivers.length > 0));

    if (page > 1) {
      yield put(appendUserDrivers(userDrivers));
    } else {
      yield put(setUserDrivers(userDrivers));
    }

    yield put(setFetchUserDriversSuccess(message));
    yield put(setFetchUserDriversLoading(false));
  } catch (error) {
    yield put(setFetchUserDriversFailed(error));
    yield put(setFetchUserDriversLoading(false));
  }
}
export function* _getUserDriver({ payload: driverId }) {
  try {
    yield put(setFetchUserDriverLoading(true));

    const {
      meta: { message },
      data: userDriver,
    } = yield call(getUserDriver, driverId);

    yield put(setIsFetchUserDriverHitted(true));
    yield put(setUserDriver(userDriver));

    yield put(setFetchUserDriverSuccess(message));
    yield put(setFetchUserDriverLoading(false));
  } catch (error) {
    yield put(setFetchUserDriverFailed(error));
    yield put(setFetchUserDriverLoading(false));
  }
}
export function* _createUserDriver({ payload: request }) {
  try {
    yield put(setCreateUserDriverLoading(true));

    const {
      meta: { message },
    } = yield call(createUserDriver, request);

    yield put(setIsCreateUserDriverHitted(true));

    const isFetchUserDriversHitted = yield select(getIsFetchUserDriversHitted);

    if (isFetchUserDriversHitted) {
      yield put(setFetchUserDriversPage(1));
      yield call(_getUserDrivers);
    }

    yield put(setCreateUserDriverSuccess(message));
    yield put(setCreateUserDriverLoading(false));
  } catch (error) {
    yield put(setCreateUserDriverFailed(error));
    yield put(setCreateUserDriverLoading(false));
  }
}
export function* _updateUserDriver({ payload: { driverId, request } }) {
  try {
    yield put(setUpdateUserDriverLoading(true));

    const {
      meta: { message },
    } = yield call(updateUserDriver, driverId, request);

    yield put(setIsUpdateUserDriverHitted(true));

    const isFetchUserDriversHitted = yield select(getIsFetchUserDriversHitted);

    if (isFetchUserDriversHitted) {
      yield put(setFetchUserDriversPage(1));
      yield call(_getUserDrivers);
    }

    yield put(setUpdateUserDriverSuccess(message));
    yield put(setUpdateUserDriverLoading(false));
  } catch (error) {
    yield put(setUpdateUserDriverFailed(error));
    yield put(setUpdateUserDriverLoading(false));
  }
}
export function* _addMarketUserDriver({ payload: { driverId, marketId } }) {
  try {
    yield put(setAddMarketUserDriverLoading(true));

    const {
      meta: { message },
    } = yield call(addMarketUserDriver, driverId, marketId);

    yield put(setIsAddMarketUserDriverHitted(true));

    yield put(setAddMarketUserDriverSuccess(message));
    yield put(setAddMarketUserDriverLoading(false));
  } catch (error) {
    yield put(setAddMarketUserDriverFailed(error));
    yield put(setAddMarketUserDriverLoading(false));
  }
}
export function* _removeMarketUserDriver({ payload: { driverId, marketId } }) {
  try {
    yield put(setRemoveMarketUserDriverLoading(true));

    const {
      meta: { message },
    } = yield call(removeMarketUserDriver, driverId, marketId);

    yield put(setIsRemoveMarketUserDriverHitted(true));

    yield put(setRemoveMarketUserDriverSuccess(message));
    yield put(setRemoveMarketUserDriverLoading(false));
  } catch (error) {
    yield put(setRemoveMarketUserDriverFailed(error));
    yield put(setRemoveMarketUserDriverLoading(false));
  }
}

export function* onFetchUserDriversStart() {
  yield takeLatest(
    USER_DRIVER_ACTION_TYPES.FETCH_USER_DRIVERS_START,
    _getUserDrivers
  );
}
export function* onFetchUserDriverStart() {
  yield takeLatest(
    USER_DRIVER_ACTION_TYPES.FETCH_USER_DRIVER_START,
    _getUserDriver
  );
}
export function* onCreateUserDriverStart() {
  yield takeLatest(
    USER_DRIVER_ACTION_TYPES.CREATE_USER_DRIVER_START,
    _createUserDriver
  );
}
export function* onUpdateUserDriverStart() {
  yield takeLatest(
    USER_DRIVER_ACTION_TYPES.UPDATE_USER_DRIVER_START,
    _updateUserDriver
  );
}
export function* onAddMarketUserDriverStart() {
  yield takeLatest(
    USER_DRIVER_ACTION_TYPES.ADD_MARKET_USER_DRIVER_START,
    _addMarketUserDriver
  );
}
export function* onRemoveMarketUserDriverStart() {
  yield takeLatest(
    USER_DRIVER_ACTION_TYPES.REMOVE_MARKET_USER_DRIVER_START,
    _removeMarketUserDriver
  );
}

export function* userDriverSaga() {
  yield all([
    call(onFetchUserDriversStart),
    call(onFetchUserDriverStart),
    call(onCreateUserDriverStart),
    call(onUpdateUserDriverStart),
    call(onAddMarketUserDriverStart),
    call(onRemoveMarketUserDriverStart),
  ]);
}
