import RECIPE_ACTION_TYPES from "./recipe.type";
import { createAction } from "../../utils/store.utils";

export const setRecipes = (recipes) => createAction(RECIPE_ACTION_TYPES.SET_RECIPES, recipes);

export const setRecipe = (recipe) => createAction(RECIPE_ACTION_TYPES.SET_RECIPE, recipe);

export const setIsRecipesHasMore = (isRecipesHasMore) =>
  createAction(RECIPE_ACTION_TYPES.SET_IS_RECIPES_HAS_MORE, isRecipesHasMore);

export const setFetchRecipesSearch = (fetchRecipesSearch) =>
  createAction(RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_SEARCH, fetchRecipesSearch);

export const setFetchRecipesPage = (fetchRecipesPage) =>
  createAction(RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_PAGE, fetchRecipesPage);

export const setFetchRecipesPerPage = (fetchRecipesPerPage) =>
  createAction(RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_PER_PAGE, fetchRecipesPerPage);

export const setFetchRecipesIncludes = (fetchRecipesIncludes) =>
  createAction(RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_INCLUDES, fetchRecipesIncludes);

export const setFetchRecipesFilterMarketId = (fetchRecipesFilterMarketId) =>
  createAction(RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_FILTER_MARKET_ID, fetchRecipesFilterMarketId);

export const setFetchRecipesFilterBranchId = (fetchRecipesFilterBranchId) =>
  createAction(RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_FILTER_BRANCH_ID, fetchRecipesFilterBranchId);

export const setFetchRecipesLoading = (fetchRecipesLoading) =>
  createAction(RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_LOADING, fetchRecipesLoading);

export const setFetchRecipesSuccess = (fetchRecipesSuccess) =>
  createAction(RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_SUCCESS, fetchRecipesSuccess);

export const setFetchRecipesFailed = (fetchRecipesFailed) =>
  createAction(RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_FAILED, fetchRecipesFailed);

export const setFetchRecipeLoading = (fetchRecipeLoading) =>
  createAction(RECIPE_ACTION_TYPES.SET_FETCH_RECIPE_LOADING, fetchRecipeLoading);

export const setFetchRecipeSuccess = (fetchRecipeSuccess) =>
  createAction(RECIPE_ACTION_TYPES.SET_FETCH_RECIPE_SUCCESS, fetchRecipeSuccess);

export const setFetchRecipeFailed = (fetchRecipeFailed) =>
  createAction(RECIPE_ACTION_TYPES.SET_FETCH_RECIPE_FAILED, fetchRecipeFailed);

export const setCreateRecipeLoading = (createRecipeLoading) =>
  createAction(RECIPE_ACTION_TYPES.SET_CREATE_RECIPE_LOADING, createRecipeLoading);

export const setCreateRecipeSuccess = (createRecipeSuccess) =>
  createAction(RECIPE_ACTION_TYPES.SET_CREATE_RECIPE_SUCCESS, createRecipeSuccess);

export const setCreateRecipeFailed = (createRecipeFailed) =>
  createAction(RECIPE_ACTION_TYPES.SET_CREATE_RECIPE_FAILED, createRecipeFailed);

export const setUpdateRecipeLoading = (updateRecipeLoading) =>
  createAction(RECIPE_ACTION_TYPES.SET_UPDATE_RECIPE_LOADING, updateRecipeLoading);

export const setUpdateRecipeSuccess = (updateRecipeSuccess) =>
  createAction(RECIPE_ACTION_TYPES.SET_UPDATE_RECIPE_SUCCESS, updateRecipeSuccess);

export const setUpdateRecipeFailed = (updateRecipeFailed) =>
  createAction(RECIPE_ACTION_TYPES.SET_UPDATE_RECIPE_FAILED, updateRecipeFailed);

export const setDeleteRecipeLoading = (deleteRecipeLoading) =>
  createAction(RECIPE_ACTION_TYPES.SET_DELETE_RECIPE_LOADING, deleteRecipeLoading);

export const setDeleteRecipeSuccess = (deleteRecipeSuccess) =>
  createAction(RECIPE_ACTION_TYPES.SET_DELETE_RECIPE_SUCCESS, deleteRecipeSuccess);

export const setDeleteRecipeFailed = (deleteRecipeFailed) =>
  createAction(RECIPE_ACTION_TYPES.SET_DELETE_RECIPE_FAILED, deleteRecipeFailed);

export const appendRecipes = (recipes) => createAction(RECIPE_ACTION_TYPES.APPEND_RECIPES, recipes);

export const fetchRecipesStart = () => createAction(RECIPE_ACTION_TYPES.FETCH_RECIPES_START);

export const fetchRecipeStart = (id) => createAction(RECIPE_ACTION_TYPES.FETCH_RECIPE_START, id);

export const createRecipeStart = (request) => createAction(RECIPE_ACTION_TYPES.CREATE_RECIPE_START, request);

export const updateRecipeStart = (id, request) =>
  createAction(RECIPE_ACTION_TYPES.UPDATE_RECIPE_START, { id, request });

export const deleteRecipeStart = (id) => createAction(RECIPE_ACTION_TYPES.DELETE_RECIPE_START, id);

export const setIsFetchRecipesHitted = (isFetchRecipesHitted) =>
  createAction(RECIPE_ACTION_TYPES.SET_IS_FETCH_RECIPES_HITTED, isFetchRecipesHitted);

export const setIsFetchRecipeHitted = (isFetchRecipeHitted) =>
  createAction(RECIPE_ACTION_TYPES.SET_IS_FETCH_RECIPE_HITTED, isFetchRecipeHitted);

export const setIsCreateRecipeHitted = (isCreateRecipeHitted) =>
  createAction(RECIPE_ACTION_TYPES.SET_IS_CREATE_RECIPE_HITTED, isCreateRecipeHitted);

export const setIsUpdateRecipeHitted = (isUpdateRecipeHitted) =>
  createAction(RECIPE_ACTION_TYPES.SET_IS_UPDATE_RECIPE_HITTED, isUpdateRecipeHitted);

export const setIsDeleteRecipeHitted = (isDeleteRecipeHitted) =>
  createAction(RECIPE_ACTION_TYPES.SET_IS_DELETE_RECIPE_HITTED, isDeleteRecipeHitted);

export const resetRecipeReducer = (id) => createAction(RECIPE_ACTION_TYPES.RESET_RECIPE_REDUCER);
