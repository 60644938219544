const PDF_ACTION_TYPES = {
  RESET_PDF_REDUCER: "pdf/RESET_PDF_REDUCER",

  SET_ORDER_RECEIPT: "pdf/SET_ORDER_RECEIPT",
  SET_ORDER_NOTES: "pdf/SET_ORDER_NOTES",
  SET_EXTEND_PERIOD_RECEIPT: "pdf/SET_EXTEND_PERIOD_RECEIPT",

  SET_PDF_ORDER_RECEIPT_IS_MULTI_LANG: "pdf/SET_PDF_ORDER_RECEIPT_IS_MULTI_LANG",
  SET_PDF_ORDER_RECEIPT_LOADING: "pdf/SET_PDF_ORDER_RECEIPT_LOADING",
  SET_PDF_ORDER_RECEIPT_SUCCESS: "pdf/SET_PDF_ORDER_RECEIPT_SUCCESS",
  SET_PDF_ORDER_RECEIPT_FAILED: "pdf/SET_PDF_ORDER_RECEIPT_FAILED",

  SET_PDF_ORDER_NOTES_IS_MULTI_LANG: "pdf/SET_PDF_ORDER_NOTES_IS_MULTI_LANG",
  SET_PDF_ORDER_NOTES_LOADING: "pdf/SET_PDF_ORDER_NOTES_LOADING",
  SET_PDF_ORDER_NOTES_SUCCESS: "pdf/SET_PDF_ORDER_NOTES_SUCCESS",
  SET_PDF_ORDER_NOTES_FAILED: "pdf/SET_PDF_ORDER_NOTES_FAILED",

  SET_PDF_EXTEND_PERIOD_RECEIPT_LOADING: "pdf/SET_PDF_EXTEND_PERIOD_RECEIPT_LOADING",
  SET_PDF_EXTEND_PERIOD_RECEIPT_SUCCESS: "pdf/SET_PDF_EXTEND_PERIOD_RECEIPT_SUCCESS",
  SET_PDF_EXTEND_PERIOD_RECEIPT_FAILED: "pdf/SET_PDF_EXTEND_PERIOD_RECEIPT_FAILED",

  SET_IS_PDF_ORDER_RECEIPT_HITTED: "pdf/SET_IS_PDF_ORDER_RECEIPT_HITTED",
  SET_IS_PDF_ORDER_NOTES_HITTED: "pdf/SET_IS_PDF_ORDER_NOTES_HITTED",
  SET_IS_PDF_EXTEND_PERIOD_RECEIPT_HITTED: "pdf/SET_IS_PDF_EXTEND_PERIOD_RECEIPT_HITTED",

  EXPORT_ORDER_RECEIPT_START: "pdf/EXPORT_ORDER_RECEIPT_START",
  EXPORT_ORDER_NOTES_START: "pdf/EXPORT_ORDER_NOTES_START",
  EXPORT_EXTEND_PERIOD_RECEIPT_START: "pdf/EXPORT_EXTEND_PERIOD_RECEIPT_START",
};

export default PDF_ACTION_TYPES;
