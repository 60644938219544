const PRODUCT_MODIFIER_ACTION_TYPES = {
  RESET_PRODUCT_MODIFIER_REDUCER: "product-modifier/RESET_PRODUCT_MODIFIER_REDUCER",

  SET_CREATE_PRODUCT_MODIFIER: "product-modifier/SET_CREATE_PRODUCT_MODIFIER",
  SET_UPDATE_PRODUCT_MODIFIER: "product-modifier/SET_UPDATE_PRODUCT_MODIFIER",
  SET_SCANNED_PRODUCT_MODIFIER: "product-modifier/SET_SCANNED_PRODUCT_MODIFIER",
  SET_ORDER_VARIABLE_PRODUCT_MODIFIERS: "product-modifier/SET_ORDER_VARIABLE_PRODUCT_MODIFIERS",
  SET_ORDER_VARIABLE_CART_REQUEST: "product-modifier/SET_ORDER_VARIABLE_CART_REQUEST",
  SET_ORDER_WEIGHT_PRODUCT_MODIFIERS: "product-modifier/SET_ORDER_WEIGHT_PRODUCT_MODIFIERS",
  SET_ORDER_WEIGHT_CART_REQUEST: "product-modifier/SET_ORDER_WEIGHT_CART_REQUEST",
  SET_ORDER_CUSTOMIZE_PRODUCT_MODIFIERS: "product-modifier/SET_ORDER_CUSTOMIZE_PRODUCT_MODIFIERS",

  SET_CREATE_PRODUCT_MODIFIER_LOADING: "product-modifier/SET_CREATE_PRODUCT_MODIFIER_LOADING",
  SET_CREATE_PRODUCT_MODIFIER_SUCCESS: "product-modifier/SET_CREATE_PRODUCT_MODIFIER_SUCCESS",
  SET_CREATE_PRODUCT_MODIFIER_FAILED: "product-modifier/SET_CREATE_PRODUCT_MODIFIER_FAILED",

  SET_UPDATE_PRODUCT_MODIFIER_LOADING: "product-modifier/SET_UPDATE_PRODUCT_MODIFIER_LOADING",
  SET_UPDATE_PRODUCT_MODIFIER_SUCCESS: "product-modifier/SET_UPDATE_PRODUCT_MODIFIER_SUCCESS",
  SET_UPDATE_PRODUCT_MODIFIER_FAILED: "product-modifier/SET_UPDATE_PRODUCT_MODIFIER_FAILED",

  SET_FETCH_SCANNED_PRODUCT_MODIFIER_LOADING: "product-modifier/SET_FETCH_SCANNED_PRODUCT_MODIFIER_LOADING",
  SET_FETCH_SCANNED_PRODUCT_MODIFIER_SUCCESS: "product-modifier/SET_FETCH_SCANNED_PRODUCT_MODIFIER_SUCCESS",
  SET_FETCH_SCANNED_PRODUCT_MODIFIER_FAILED: "product-modifier/SET_FETCH_SCANNED_PRODUCT_MODIFIER_FAILED",

  CREATE_PRODUCT_MODIFIER_START: "product-modifier/CREATE_PRODUCT_MODIFIER_START",
  UPDATE_PRODUCT_MODIFIER_START: "product-modifier/UPDATE_PRODUCT_MODIFIER_START",
  FETCH_SCANNED_PRODUCT_MODIFIER_START: "product-modifier/FETCH_SCANNED_PRODUCT_MODIFIER_START",
};

export default PRODUCT_MODIFIER_ACTION_TYPES;
