const PERMISSION_MERCHANT_ACTION_TYPES = {
  RESET_PERMISSION_MERCHANT_REDUCER:
    "permission-merchant/RESET_PERMISSION_MERCHANT_REDUCER",

  SET_PERMISSION_TAB: "permission-merchant/SET_PERMISSION_TAB",
  SET_PERMISSION_TYPES: "permission-merchant/SET_PERMISSION_TYPES",

  SET_PERMISSION_MERCHANTS: "permission-merchant/SET_PERMISSION_MERCHANTS",
  SET_PERMISSION_SUBSCRIBERS: "permission-merchant/SET_PERMISSION_SUBSCRIBERS",
  SET_SELECT_PERMISSION_MERCHANTS:
    "permission-merchant/SET_SELECT_PERMISSION_MERCHANTS",
  SET_SELECT_PERMISSION_SUBSCRIBERS:
    "permission-merchant/SET_SELECT_PERMISSION_SUBSCRIBERS",
  SET_PERMISSION: "permission-merchant/SET_PERMISSION",

  SET_IS_PERMISSION_MERCHANTS_HAS_MORE:
    "permission-merchant/SET_IS_PERMISSION_MERCHANTS_HAS_MORE",
  SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE:
    "permission-merchant/SET_IS_PERMISSION_SUBSCRIBERS_HAS_MORE",
  SET_IS_SELECT_PERMISSION_MERCHANTS_HAS_MORE:
    "permission-merchant/SET_IS_SELECT_PERMISSION_MERCHANTS_HAS_MORE",
  SET_IS_SELECT_PERMISSION_SUBSCRIBERS_HAS_MORE:
    "permission-merchant/SET_IS_SELECT_PERMISSION_SUBSCRIBERS_HAS_MORE",

  SET_FETCH_PERMISSION_MERCHANTS_SEARCH:
    "permission-merchant/SET_FETCH_PERMISSION_MERCHANTS_SEARCH",
  SET_FETCH_PERMISSION_MERCHANTS_SORT:
    "permission-merchant/SET_FETCH_PERMISSION_MERCHANTS_SORT",
  SET_FETCH_PERMISSION_MERCHANTS_KEY_BY:
    "permission-merchant/SET_FETCH_PERMISSION_MERCHANTS_KEY_BY",
  SET_FETCH_PERMISSION_MERCHANTS_PAGE:
    "permission-merchant/SET_FETCH_PERMISSION_MERCHANTS_PAGE",
  SET_FETCH_PERMISSION_MERCHANTS_PER_PAGE:
    "permission-merchant/SET_FETCH_PERMISSION_MERCHANTS_PER_PAGE",
  SET_FETCH_PERMISSION_MERCHANTS_INCLUDES:
    "permission-merchant/SET_FETCH_PERMISSION_MERCHANTS_INCLUDES",
  SET_FETCH_PERMISSION_MERCHANTS_FILTER_MERCHANT_ID:
    "permission-merchant/SET_FETCH_PERMISSION_MERCHANTS_FILTER_MERCHANT_ID",
  SET_FETCH_PERMISSION_MERCHANTS_FILTER_IS_DEFAULT:
    "permission-merchant/SET_FETCH_PERMISSION_MERCHANTS_FILTER_IS_DEFAULT",
  SET_FETCH_PERMISSION_MERCHANTS_LOADING:
    "permission-merchant/SET_FETCH_PERMISSION_MERCHANTS_LOADING",
  SET_FETCH_PERMISSION_MERCHANTS_SUCCESS:
    "permission-merchant/SET_FETCH_PERMISSION_MERCHANTS_SUCCESS",
  SET_FETCH_PERMISSION_MERCHANTS_FAILED:
    "permission-merchant/SET_FETCH_PERMISSION_MERCHANTS_FAILED",

  SET_FETCH_PERMISSION_SUBSCRIBERS_SEARCH:
    "permission-merchant/SET_FETCH_PERMISSION_SUBSCRIBERS_SEARCH",
  SET_FETCH_PERMISSION_SUBSCRIBERS_SORT:
    "permission-merchant/SET_FETCH_PERMISSION_SUBSCRIBERS_SORT",
  SET_FETCH_PERMISSION_SUBSCRIBERS_KEY_BY:
    "permission-merchant/SET_FETCH_PERMISSION_SUBSCRIBERS_KEY_BY",
  SET_FETCH_PERMISSION_SUBSCRIBERS_PAGE:
    "permission-merchant/SET_FETCH_PERMISSION_SUBSCRIBERS_PAGE",
  SET_FETCH_PERMISSION_SUBSCRIBERS_PER_PAGE:
    "permission-merchant/SET_FETCH_PERMISSION_SUBSCRIBERS_PER_PAGE",
  SET_FETCH_PERMISSION_SUBSCRIBERS_INCLUDES:
    "permission-merchant/SET_FETCH_PERMISSION_SUBSCRIBERS_INCLUDES",
  SET_FETCH_PERMISSION_SUBSCRIBERS_FILTER_MERCHANT_ID:
    "permission-merchant/SET_FETCH_PERMISSION_SUBSCRIBERS_FILTER_MERCHANT_ID",
  SET_FETCH_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT:
    "permission-merchant/SET_FETCH_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT",
  SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING:
    "permission-merchant/SET_FETCH_PERMISSION_SUBSCRIBERS_LOADING",
  SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS:
    "permission-merchant/SET_FETCH_PERMISSION_SUBSCRIBERS_SUCCESS",
  SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED:
    "permission-merchant/SET_FETCH_PERMISSION_SUBSCRIBERS_FAILED",

  SET_FETCH_SELECT_PERMISSION_MERCHANTS_SEARCH:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_MERCHANTS_SEARCH",
  SET_FETCH_SELECT_PERMISSION_MERCHANTS_SORT:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_MERCHANTS_SORT",
  SET_FETCH_SELECT_PERMISSION_MERCHANTS_KEY_BY:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_MERCHANTS_KEY_BY",
  SET_FETCH_SELECT_PERMISSION_MERCHANTS_PAGE:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_MERCHANTS_PAGE",
  SET_FETCH_SELECT_PERMISSION_MERCHANTS_PER_PAGE:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_MERCHANTS_PER_PAGE",
  SET_FETCH_SELECT_PERMISSION_MERCHANTS_INCLUDES:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_MERCHANTS_INCLUDES",
  SET_FETCH_SELECT_PERMISSION_MERCHANTS_FILTER_MERCHANT_ID:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_MERCHANTS_FILTER_MERCHANT_ID",
  SET_FETCH_SELECT_PERMISSION_MERCHANTS_FILTER_PERMISSIONS_IDS:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_MERCHANTS_FILTER_PERMISSIONS_IDS",
  SET_FETCH_SELECT_PERMISSION_MERCHANTS_FILTER_IS_DEFAULT:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_MERCHANTS_FILTER_IS_DEFAULT",
  SET_FETCH_SELECT_PERMISSION_MERCHANTS_LOADING:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_MERCHANTS_LOADING",
  SET_FETCH_SELECT_PERMISSION_MERCHANTS_SUCCESS:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_MERCHANTS_SUCCESS",
  SET_FETCH_SELECT_PERMISSION_MERCHANTS_FAILED:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_MERCHANTS_FAILED",

  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SEARCH:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SEARCH",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SORT:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SORT",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PAGE:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PAGE",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_MERCHANT_ID:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_MERCHANT_ID",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_LOADING:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_LOADING",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS",
  SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FAILED:
    "permission-merchant/SET_FETCH_SELECT_PERMISSION_SUBSCRIBERS_FAILED",

  SET_APPEND_SELECT_PERMISSION_MERCHANTS_SEARCH:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_MERCHANTS_SEARCH",
  SET_APPEND_SELECT_PERMISSION_MERCHANTS_SORT:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_MERCHANTS_SORT",
  SET_APPEND_SELECT_PERMISSION_MERCHANTS_KEY_BY:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_MERCHANTS_KEY_BY",
  SET_APPEND_SELECT_PERMISSION_MERCHANTS_PAGE:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_MERCHANTS_PAGE",
  SET_APPEND_SELECT_PERMISSION_MERCHANTS_PER_PAGE:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_MERCHANTS_PER_PAGE",
  SET_APPEND_SELECT_PERMISSION_MERCHANTS_INCLUDES:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_MERCHANTS_INCLUDES",
  SET_APPEND_SELECT_PERMISSION_MERCHANTS_FILTER_MERCHANT_ID:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_MERCHANTS_FILTER_MERCHANT_ID",
  SET_APPEND_SELECT_PERMISSION_MERCHANTS_FILTER_PERMISSIONS_IDS:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_MERCHANTS_FILTER_PERMISSIONS_IDS",
  SET_APPEND_SELECT_PERMISSION_MERCHANTS_FILTER_IS_DEFAULT:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_MERCHANTS_FILTER_IS_DEFAULT",
  SET_APPEND_SELECT_PERMISSION_MERCHANTS_LOADING:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_MERCHANTS_LOADING",
  SET_APPEND_SELECT_PERMISSION_MERCHANTS_SUCCESS:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_MERCHANTS_SUCCESS",
  SET_APPEND_SELECT_PERMISSION_MERCHANTS_FAILED:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_MERCHANTS_FAILED",

  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SEARCH:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SEARCH",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SORT:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SORT",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_KEY_BY",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PAGE:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PAGE",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_PER_PAGE",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_INCLUDES",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_MERCHANT_ID:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_MERCHANT_ID",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_PERMISSIONS_IDS",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FILTER_IS_DEFAULT",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_LOADING:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_LOADING",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_SUCCESS",
  SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FAILED:
    "permission-merchant/SET_APPEND_SELECT_PERMISSION_SUBSCRIBERS_FAILED",

  SET_FETCH_PERMISSION_LOADING:
    "permission-merchant/SET_FETCH_PERMISSION_LOADING",
  SET_FETCH_PERMISSION_SUCCESS:
    "permission-merchant/SET_FETCH_PERMISSION_SUCCESS",
  SET_FETCH_PERMISSION_FAILED:
    "permission-merchant/SET_FETCH_PERMISSION_FAILED",

  SET_CREATE_PERMISSION_LOADING:
    "permission-merchant/SET_CREATE_PERMISSION_LOADING",
  SET_CREATE_PERMISSION_SUCCESS:
    "permission-merchant/SET_CREATE_PERMISSION_SUCCESS",
  SET_CREATE_PERMISSION_FAILED:
    "permission-merchant/SET_CREATE_PERMISSION_FAILED",

  SET_UPDATE_PERMISSION_LOADING:
    "permission-merchant/SET_UPDATE_PERMISSION_LOADING",
  SET_UPDATE_PERMISSION_SUCCESS:
    "permission-merchant/SET_UPDATE_PERMISSION_SUCCESS",
  SET_UPDATE_PERMISSION_FAILED:
    "permission-merchant/SET_UPDATE_PERMISSION_FAILED",

  SET_DELETE_PERMISSION_LOADING:
    "permission-merchant/SET_DELETE_PERMISSION_LOADING",
  SET_DELETE_PERMISSION_SUCCESS:
    "permission-merchant/SET_DELETE_PERMISSION_SUCCESS",
  SET_DELETE_PERMISSION_FAILED:
    "permission-merchant/SET_DELETE_PERMISSION_FAILED",

  APPEND_PERMISSION_MERCHANTS:
    "permission-merchant/APPEND_PERMISSION_MERCHANTS",
  APPEND_PERMISSION_SUBSCRIBERS:
    "permission-merchant/APPEND_PERMISSION_SUBSCRIBERS",
  APPEND_SELECT_PERMISSION_MERCHANTS:
    "permission-merchant/APPEND_SELECT_PERMISSION_MERCHANTS",
  APPEND_SELECT_PERMISSION_SUBSCRIBERS:
    "permission-merchant/APPEND_SELECT_PERMISSION_SUBSCRIBERS",

  SET_IS_FETCH_PERMISSION_MERCHANTS_HITTED:
    "permission-merchant/SET_IS_FETCH_PERMISSION_MERCHANTS_HITTED",
  SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED:
    "permission-merchant/SET_IS_FETCH_PERMISSION_SUBSCRIBERS_HITTED",
  SET_IS_FETCH_SELECT_PERMISSION_MERCHANTS_HITTED:
    "permission-merchant/SET_IS_FETCH_SELECT_PERMISSION_MERCHANTS_HITTED",
  SET_IS_FETCH_SELECT_PERMISSION_SUBSCRIBERS_HITTED:
    "permission-merchant/SET_IS_FETCH_SELECT_PERMISSION_SUBSCRIBERS_HITTED",
  SET_IS_APPEND_SELECT_PERMISSION_MERCHANTS_HITTED:
    "permission-merchant/SET_IS_APPEND_SELECT_PERMISSION_MERCHANTS_HITTED",
  SET_IS_APPEND_SELECT_PERMISSION_SUBSCRIBERS_HITTED:
    "permission-merchant/SET_IS_APPEND_SELECT_PERMISSION_SUBSCRIBERS_HITTED",

  SET_IS_FETCH_PERMISSION_HITTED:
    "permission-merchant/SET_IS_FETCH_PERMISSION_HITTED",
  SET_IS_CREATE_PERMISSION_HITTED:
    "permission-merchant/SET_IS_CREATE_PERMISSION_HITTED",
  SET_IS_UPDATE_PERMISSION_HITTED:
    "permission-merchant/SET_IS_UPDATE_PERMISSION_HITTED",
  SET_IS_DELETE_PERMISSION_HITTED:
    "permission-merchant/SET_IS_DELETE_PERMISSION_HITTED",

  FETCH_PERMISSION_MERCHANTS_START:
    "permission-merchant/FETCH_PERMISSION_MERCHANTS_START",
  FETCH_PERMISSION_SUBSCRIBERS_START:
    "permission-merchant/FETCH_PERMISSION_SUBSCRIBERS_START",
  FETCH_SELECT_PERMISSION_MERCHANTS_START:
    "permission-merchant/FETCH_SELECT_PERMISSION_MERCHANTS_START",
  FETCH_SELECT_PERMISSION_SUBSCRIBERS_START:
    "permission-merchant/FETCH_SELECT_PERMISSION_SUBSCRIBERS_START",
  APPEND_SELECT_PERMISSION_MERCHANTS_START:
    "permission-merchant/APPEND_SELECT_PERMISSION_MERCHANTS_TYPE_MERCHANT_START",
  APPEND_SELECT_PERMISSION_SUBSCRIBERS_START:
    "permission-merchant/APPEND_SELECT_PERMISSION_SUBSCRIBERS_START",

  FETCH_PERMISSION_START: "permission-merchant/FETCH_PERMISSION_START",
  CREATE_PERMISSION_START: "permission-merchant/CREATE_PERMISSION_START",
  UPDATE_PERMISSION_START: "permission-merchant/UPDATE_PERMISSION_START",
  DELETE_PERMISSION_START: "permission-merchant/DELETE_PERMISSION_START",
};

export default PERMISSION_MERCHANT_ACTION_TYPES;
