import styled from "styled-components";

import "react-responsive-carousel/lib/styles/carousel.min.css";

export const AuthenticationCarouselImage = styled.img`
  cursor: default;
  user-select: none;

  width: min-content;
  min-width: min-content;
  max-width: min-content;
  height: min-content;
  min-height: min-content;
  max-height: min-content;
`;

export const AuthenticationCarouselContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({ width }) => `${width}px`};
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  min-height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};

  border-radius: 1rem;
  overflow: hidden;

  .control-dots {
    display: flex;
    justify-content: center;
    column-gap: 0.525rem;

    margin-bottom: 2rem !important;

    .dot {
      border-radius: 1.5rem;
      margin: 0 !important;
      transition: all 0.3s ease-in-out;

      width: 0.775rem;
      min-width: 0.775rem;
      max-width: 0.775rem;
      height: 0.775rem;
      min-height: 0.775rem;
      max-height: 0.775rem;

      &.selected {
        width: 2rem;
        min-width: 2rem;
        max-width: 2rem;
      }
    }
  }
`;
