export const PRINTER_INCLUDES = {
  MARKET: "market",
  BRANCH: "branch",
  PRINTER_TYPE: "printerType",
  KITCHEN_GROUPS: "kitchenGroups",
  USERS: "users",
};

export const PRINTER_TARGETS = {
  KITCHEN: "KITCHEN",
  ORDER: "ORDER",
  ALL_FEATURES: "ALL_FEATURES",
};

export const PRINTER_SERVICE_TYPES = {
  TAKE_AWAY: "TAKE_AWAY",
  DINING: "DINING",
  ALL_SERVICE_TYPES: "ALL_SERVICE_TYPES",
};
