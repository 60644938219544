const ORDER_PRODUCT_ACTION_TYPES = {
  RESET_ORDER_PRODUCT_REDUCER: "order-product/RESET_ORDER_PRODUCT_REDUCER",

  SET_ORDER_PRODUCT: "order-product/SET_ORDER_PRODUCT",

  SET_FETCH_ORDER_PRODUCT_LOADING: "order-product/SET_FETCH_ORDER_PRODUCT_LOADING",
  SET_FETCH_ORDER_PRODUCT_SUCCESS: "order-product/SET_FETCH_ORDER_PRODUCT_SUCCESS",
  SET_FETCH_ORDER_PRODUCT_FAILED: "order-product/SET_FETCH_ORDER_PRODUCT_FAILED",

  SET_UPDATE_ORDER_PRODUCT_LOADING: "order-product/SET_UPDATE_ORDER_PRODUCT_LOADING",
  SET_UPDATE_ORDER_PRODUCT_SUCCESS: "order-product/SET_UPDATE_ORDER_PRODUCT_SUCCESS",
  SET_UPDATE_ORDER_PRODUCT_FAILED: "order-product/SET_UPDATE_ORDER_PRODUCT_FAILED",

  SET_IS_FETCH_ORDER_PRODUCT_HITTED: "order-product/SET_IS_FETCH_ORDER_PRODUCT_HITTED",
  SET_IS_UPDATE_ORDER_PRODUCT_HITTED: "order-product/SET_IS_UPDATE_ORDER_PRODUCT_HITTED",

  FETCH_ORDER_PRODUCT_START: "order-product/FETCH_ORDER_PRODUCT_START",
  UPDATE_ORDER_PRODUCT_START: "order-product/UPDATE_ORDER_PRODUCT_START",
};

export default ORDER_PRODUCT_ACTION_TYPES;
