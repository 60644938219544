import {
  DISCOUNT_TYPES,
  DISCOUNT_VALUE_TYPES,
} from "../../constants/discount.constant";

import DISCOUNT_ACTION_TYPES from "./discount.type";

export const DISCOUNT_INITIAL_STATE = {
  editModifierIndex: null,
  editModifier: null,

  discountTypes: [
    {
      singularLabel: "Coupon",
      pluralLabel: "Coupons",
      value: DISCOUNT_TYPES.COUPON,
    },
    {
      singularLabel: "Offer",
      pluralLabel: "Offers",
      value: DISCOUNT_TYPES.OFFER,
    },
    {
      singularLabel: "Promotion",
      pluralLabel: "Promotions",
      value: DISCOUNT_TYPES.PROMOTION,
    },
  ],
  discountValueTypes: [
    {
      label: "Percentage %",
      value: DISCOUNT_VALUE_TYPES.PERCENT,
    },
    {
      label: "Fixed Price",
      value: DISCOUNT_VALUE_TYPES.NOMINAL,
    },
  ],

  discountTab: DISCOUNT_TYPES.COUPON,
  discountCreateTab: DISCOUNT_TYPES.COUPON,
  discountEditTab: DISCOUNT_TYPES.COUPON,

  discounts: [],
  orderDiscounts: [],
  discount: null,
  createDiscount: null,
  updateDiscount: null,
  verifyCouponDiscount: null,
  verifyLoyaProDiscount: null,

  isDiscountsHasMore: true,
  isOrderDiscountsHasMore: true,

  fetchDiscountsSearch: null,
  fetchDiscountsPage: 1,
  fetchDiscountsPerPage: null,
  fetchDiscountsIncludes: null,
  fetchDiscountsFilterMarketId: null,
  fetchDiscountsFilterBranchId: null,
  fetchDiscountsFilterTypes: null,
  fetchDiscountsFilterExpiredAt: null,
  fetchDiscountsLoading: false,
  fetchDiscountsSuccess: null,
  fetchDiscountsFailed: null,

  fetchOrderDiscountsSearch: null,
  fetchOrderDiscountsPage: 1,
  fetchOrderDiscountsPerPage: null,
  fetchOrderDiscountsIncludes: null,
  fetchOrderDiscountsFilterMarketId: null,
  fetchOrderDiscountsFilterBranchId: null,
  fetchOrderDiscountsFilterTypes: null,
  fetchOrderDiscountsFilterExpiredAt: null,
  fetchOrderDiscountsLoading: false,
  fetchOrderDiscountsSuccess: null,
  fetchOrderDiscountsFailed: null,

  fetchDiscountLoading: false,
  fetchDiscountSuccess: null,
  fetchDiscountFailed: null,

  createDiscountLoading: false,
  createDiscountSuccess: null,
  createDiscountFailed: null,

  updateDiscountLoading: false,
  updateDiscountSuccess: null,
  updateDiscountFailed: null,

  deleteDiscountLoading: false,
  deleteDiscountSuccess: null,
  deleteDiscountFailed: null,

  verifyCouponDiscountLoading: false,
  verifyCouponDiscountSuccess: null,
  verifyCouponDiscountFailed: null,

  verifyLoyaProDiscountLoading: false,
  verifyLoyaProDiscountSuccess: null,
  verifyLoyaProDiscountFailed: null,

  isFetchDiscountsHitted: false,
  isFetchOrderDiscountsHitted: false,
  isFetchDiscountHitted: false,
  isCreateDiscountHitted: false,
  isUpdateDiscountHitted: false,
  isDeleteDiscountHitted: false,
  isVerifyCouponDiscountHitted: false,
  isVerifyLoyaProDiscountHitted: false,
};

export const discountReducer = (state = DISCOUNT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case DISCOUNT_ACTION_TYPES.SET_EDIT_MODIFIER_INDEX:
      return { ...state, editModifierIndex: payload };
    case DISCOUNT_ACTION_TYPES.SET_EDIT_MODIFIER:
      return { ...state, editModifier: payload };

    case DISCOUNT_ACTION_TYPES.SET_DISCOUNT_TAB:
      return { ...state, discountTab: payload };
    case DISCOUNT_ACTION_TYPES.SET_DISCOUNT_CREATE_TAB:
      return { ...state, discountCreateTab: payload };
    case DISCOUNT_ACTION_TYPES.SET_DISCOUNT_EDIT_TAB:
      return { ...state, discountEditTab: payload };

    case DISCOUNT_ACTION_TYPES.SET_DISCOUNTS:
      return { ...state, discounts: payload };
    case DISCOUNT_ACTION_TYPES.SET_ORDER_DISCOUNTS:
      return { ...state, orderDiscounts: payload };
    case DISCOUNT_ACTION_TYPES.SET_DISCOUNT:
      return { ...state, discount: payload };
    case DISCOUNT_ACTION_TYPES.SET_CREATE_DISCOUNT:
      return { ...state, createDiscount: payload };
    case DISCOUNT_ACTION_TYPES.SET_UPDATE_DISCOUNT:
      return { ...state, updateDiscount: payload };
    case DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT:
      return { ...state, verifyCouponDiscount: payload };
    case DISCOUNT_ACTION_TYPES.SET_VERIFY_LOYAPRO_DISCOUNT:
      return { ...state, verifyLoyaProDiscount: payload };

    case DISCOUNT_ACTION_TYPES.SET_IS_DISCOUNTS_HAS_MORE:
      return { ...state, isDiscountsHasMore: payload };
    case DISCOUNT_ACTION_TYPES.SET_IS_ORDER_DISCOUNTS_HAS_MORE:
      return { ...state, isOrderDiscountsHasMore: payload };

    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_SEARCH:
      return { ...state, fetchDiscountsSearch: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_PAGE:
      return { ...state, fetchDiscountsPage: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_PER_PAGE:
      return { ...state, fetchDiscountsPerPage: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_INCLUDES:
      return { ...state, fetchDiscountsIncludes: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_MARKET_ID:
      return { ...state, fetchDiscountsFilterMarketId: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_BRANCH_ID:
      return { ...state, fetchDiscountsFilterBranchId: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_TYPES:
      return { ...state, fetchDiscountsFilterTypes: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FILTER_EXPIRED_AT:
      return { ...state, fetchDiscountsFilterExpiredAt: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_LOADING:
      return { ...state, fetchDiscountsLoading: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_SUCCESS:
      return { ...state, fetchDiscountsSuccess: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNTS_FAILED:
      return { ...state, fetchDiscountsFailed: payload };

    case DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_SEARCH:
      return { ...state, fetchOrderDiscountsSearch: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_PAGE:
      return { ...state, fetchOrderDiscountsPage: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_PER_PAGE:
      return { ...state, fetchOrderDiscountsPerPage: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_INCLUDES:
      return { ...state, fetchOrderDiscountsIncludes: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_FILTER_MARKET_ID:
      return { ...state, fetchOrderDiscountsFilterMarketId: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_FILTER_BRANCH_ID:
      return { ...state, fetchOrderDiscountsFilterBranchId: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_FILTER_TYPES:
      return { ...state, fetchOrderDiscountsFilterTypes: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_FILTER_EXPIRED_AT:
      return { ...state, fetchOrderDiscountsFilterExpiredAt: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_LOADING:
      return { ...state, fetchOrderDiscountsLoading: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_SUCCESS:
      return { ...state, fetchOrderDiscountsSuccess: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_ORDER_DISCOUNTS_FAILED:
      return { ...state, fetchOrderDiscountsFailed: payload };

    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNT_LOADING:
      return { ...state, fetchDiscountLoading: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNT_SUCCESS:
      return { ...state, fetchDiscountSuccess: payload };
    case DISCOUNT_ACTION_TYPES.SET_FETCH_DISCOUNT_FAILED:
      return { ...state, fetchDiscountFailed: payload };

    case DISCOUNT_ACTION_TYPES.SET_CREATE_DISCOUNT_LOADING:
      return { ...state, createDiscountLoading: payload };
    case DISCOUNT_ACTION_TYPES.SET_CREATE_DISCOUNT_SUCCESS:
      return { ...state, createDiscountSuccess: payload };
    case DISCOUNT_ACTION_TYPES.SET_CREATE_DISCOUNT_FAILED:
      return { ...state, createDiscountFailed: payload };

    case DISCOUNT_ACTION_TYPES.SET_UPDATE_DISCOUNT_LOADING:
      return { ...state, updateDiscountLoading: payload };
    case DISCOUNT_ACTION_TYPES.SET_UPDATE_DISCOUNT_SUCCESS:
      return { ...state, updateDiscountSuccess: payload };
    case DISCOUNT_ACTION_TYPES.SET_UPDATE_DISCOUNT_FAILED:
      return { ...state, updateDiscountFailed: payload };

    case DISCOUNT_ACTION_TYPES.SET_DELETE_DISCOUNT_LOADING:
      return { ...state, deleteDiscountLoading: payload };
    case DISCOUNT_ACTION_TYPES.SET_DELETE_DISCOUNT_SUCCESS:
      return { ...state, deleteDiscountSuccess: payload };
    case DISCOUNT_ACTION_TYPES.SET_DELETE_DISCOUNT_FAILED:
      return { ...state, deleteDiscountFailed: payload };

    case DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT_LOADING:
      return { ...state, verifyCouponDiscountLoading: payload };
    case DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT_SUCCESS:
      return { ...state, verifyCouponDiscountSuccess: payload };
    case DISCOUNT_ACTION_TYPES.SET_VERIFY_COUPON_DISCOUNT_FAILED:
      return { ...state, verifyCouponDiscountFailed: payload };

    case DISCOUNT_ACTION_TYPES.SET_VERIFY_LOYAPRO_DISCOUNT_LOADING:
      return { ...state, verifyLoyaProDiscountLoading: payload };
    case DISCOUNT_ACTION_TYPES.SET_VERIFY_LOYAPRO_DISCOUNT_SUCCESS:
      return { ...state, verifyLoyaProDiscountSuccess: payload };
    case DISCOUNT_ACTION_TYPES.SET_VERIFY_LOYAPRO_DISCOUNT_FAILED:
      return { ...state, verifyLoyaProDiscountFailed: payload };

    case DISCOUNT_ACTION_TYPES.APPEND_DISCOUNTS:
      return { ...state, discounts: [...state.discounts, ...payload] };
    case DISCOUNT_ACTION_TYPES.APPEND_ORDER_DISCOUNTS:
      return {
        ...state,
        orderDiscounts: [...state.orderDiscounts, ...payload],
      };

    case DISCOUNT_ACTION_TYPES.SET_IS_FETCH_DISCOUNTS_HITTED:
      return { ...state, isFetchDiscountsHitted: payload };
    case DISCOUNT_ACTION_TYPES.SET_IS_FETCH_ORDER_DISCOUNTS_HITTED:
      return { ...state, isFetchOrderDiscountsHitted: payload };
    case DISCOUNT_ACTION_TYPES.SET_IS_FETCH_DISCOUNT_HITTED:
      return { ...state, isFetchDiscountHitted: payload };
    case DISCOUNT_ACTION_TYPES.SET_IS_CREATE_DISCOUNT_HITTED:
      return { ...state, isCreateDiscountHitted: payload };
    case DISCOUNT_ACTION_TYPES.SET_IS_UPDATE_DISCOUNT_HITTED:
      return { ...state, isUpdateDiscountHitted: payload };
    case DISCOUNT_ACTION_TYPES.SET_IS_DELETE_DISCOUNT_HITTED:
      return { ...state, isDeleteDiscountHitted: payload };
    case DISCOUNT_ACTION_TYPES.SET_IS_VERIFY_COUPON_DISCOUNT_HITTED:
      return { ...state, isVerifyCouponDiscountHitted: payload };
    case DISCOUNT_ACTION_TYPES.SET_IS_VERIFY_LOYAPRO_DISCOUNT_HITTED:
      return { ...state, isVerifyLoyaProDiscountHitted: payload };

    case DISCOUNT_ACTION_TYPES.RESET_DISCOUNT_REDUCER:
      return DISCOUNT_INITIAL_STATE;
    default:
      return state;
  }
};
