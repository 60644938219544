import apiService from "./api";

export const getBranches = async (parameters) =>
  (await apiService.get("/v2/branches", parameters)).data;
export const getBranch = async (branchId) =>
  (await apiService.get(`/v2/branches/${branchId}`)).data;
export const createBranch = async (request) =>
  (await apiService.post("/v2/branches/", request)).data;
export const updateBranch = async (branchId, request) =>
  (await apiService.post(`/v2/branches/${branchId}`, request)).data;
export const deleteBranch = async (branchId) =>
  (await apiService.delete(`/v2/branches/${branchId}`)).data;
