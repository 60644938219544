import { createSelector } from "reselect";

const termConditionSelector = ({ termCondition }) => termCondition;

export const getTermConditions = createSelector([termConditionSelector], ({ termConditions }) => termConditions);

export const getModalTermConditions = createSelector(
  [termConditionSelector],
  ({ modalTermConditions }) => modalTermConditions
);

export const getTermCondition = createSelector([termConditionSelector], ({ termCondition }) => termCondition);

export const getIsTermConditionsHasMore = createSelector(
  [termConditionSelector],
  ({ isTermConditionsHasMore }) => isTermConditionsHasMore
);

export const getIsModalTermConditionsHasMore = createSelector(
  [termConditionSelector],
  ({ isModalTermConditionsHasMore }) => isModalTermConditionsHasMore
);

export const getFetchTermConditionsSearch = createSelector(
  [termConditionSelector],
  ({ fetchTermConditionsSearch }) => fetchTermConditionsSearch
);

export const getFetchTermConditionsPage = createSelector(
  [termConditionSelector],
  ({ fetchTermConditionsPage }) => fetchTermConditionsPage
);

export const getFetchTermConditionsPerPage = createSelector(
  [termConditionSelector],
  ({ fetchTermConditionsPerPage }) => fetchTermConditionsPerPage
);

export const getFetchTermConditionsIncludes = createSelector(
  [termConditionSelector],
  ({ fetchTermConditionsIncludes }) => fetchTermConditionsIncludes
);

export const getFetchTermConditionsLoading = createSelector(
  [termConditionSelector],
  ({ fetchTermConditionsLoading }) => fetchTermConditionsLoading
);

export const getFetchTermConditionsSuccess = createSelector(
  [termConditionSelector],
  ({ fetchTermConditionsSuccess }) => fetchTermConditionsSuccess
);

export const getFetchTermConditionsFailed = createSelector(
  [termConditionSelector],
  ({ fetchTermConditionsFailed }) => fetchTermConditionsFailed
);

export const getFetchModalTermConditionsPage = createSelector(
  [termConditionSelector],
  ({ fetchModalTermConditionsPage }) => fetchModalTermConditionsPage
);

export const getFetchModalTermConditionsPerPage = createSelector(
  [termConditionSelector],
  ({ fetchModalTermConditionsPerPage }) => fetchModalTermConditionsPerPage
);

export const getFetchModalTermConditionsIncludes = createSelector(
  [termConditionSelector],
  ({ fetchModalTermConditionsIncludes }) => fetchModalTermConditionsIncludes
);

export const getFetchModalTermConditionsLoading = createSelector(
  [termConditionSelector],
  ({ fetchModalTermConditionsLoading }) => fetchModalTermConditionsLoading
);

export const getFetchModalTermConditionsSuccess = createSelector(
  [termConditionSelector],
  ({ fetchModalTermConditionsSuccess }) => fetchModalTermConditionsSuccess
);

export const getFetchModalTermConditionsFailed = createSelector(
  [termConditionSelector],
  ({ fetchModalTermConditionsFailed }) => fetchModalTermConditionsFailed
);

export const getFetchTermConditionLoading = createSelector(
  [termConditionSelector],
  ({ fetchTermConditionLoading }) => fetchTermConditionLoading
);

export const getFetchTermConditionSuccess = createSelector(
  [termConditionSelector],
  ({ fetchTermConditionSuccess }) => fetchTermConditionSuccess
);

export const getFetchTermConditionFailed = createSelector(
  [termConditionSelector],
  ({ fetchTermConditionFailed }) => fetchTermConditionFailed
);

export const getCreateTermConditionLoading = createSelector(
  [termConditionSelector],
  ({ createTermConditionLoading }) => createTermConditionLoading
);

export const getCreateTermConditionSuccess = createSelector(
  [termConditionSelector],
  ({ createTermConditionSuccess }) => createTermConditionSuccess
);

export const getCreateTermConditionFailed = createSelector(
  [termConditionSelector],
  ({ createTermConditionFailed }) => createTermConditionFailed
);

export const getUpdateTermConditionLoading = createSelector(
  [termConditionSelector],
  ({ updateTermConditionLoading }) => updateTermConditionLoading
);

export const getUpdateTermConditionSuccess = createSelector(
  [termConditionSelector],
  ({ updateTermConditionSuccess }) => updateTermConditionSuccess
);

export const getUpdateTermConditionFailed = createSelector(
  [termConditionSelector],
  ({ updateTermConditionFailed }) => updateTermConditionFailed
);

export const getDeleteTermConditionLoading = createSelector(
  [termConditionSelector],
  ({ deleteTermConditionLoading }) => deleteTermConditionLoading
);

export const getDeleteTermConditionSuccess = createSelector(
  [termConditionSelector],
  ({ deleteTermConditionSuccess }) => deleteTermConditionSuccess
);

export const getDeleteTermConditionFailed = createSelector(
  [termConditionSelector],
  ({ deleteTermConditionFailed }) => deleteTermConditionFailed
);
