import apiService from "./api";

export const getIngredientHistories = async (ingredientId, parameters) =>
  (
    await apiService.get(
      `/v2/ingredients/${ingredientId}/histories`,
      parameters
    )
  ).data;
export const getIngredientHistory = async (ingredientId, ingredientHistoryId) =>
  (
    await apiService.get(
      `/v2/ingredients/${ingredientId}/histories/${ingredientHistoryId}`
    )
  ).data;
