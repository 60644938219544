import ANNOUNCEMENT_ACTION_TYPES from "./announcement.type";

export const ANNOUNCEMENT_INITIAL_STATE = {
  announcements: [],
  announcement: null,
  createAnnouncement: null,
  updateAnnouncement: null,

  isAnnouncementsHasMore: true,

  fetchAnnouncementsSearch: null,
  fetchAnnouncementsSort: null,
  fetchAnnouncementsPage: 1,
  fetchAnnouncementsPerPage: null,
  fetchAnnouncementsFilterDisplayAt: null,
  fetchAnnouncementsFilterUserId: null,
  fetchAnnouncementsFilterRole: null,
  fetchAnnouncementsFilterIsRead: null,
  fetchAnnouncementsLoading: false,
  fetchAnnouncementsSuccess: null,
  fetchAnnouncementsFailed: null,

  fetchAnnouncementLoading: false,
  fetchAnnouncementSuccess: null,
  fetchAnnouncementFailed: null,

  createAnnouncementLoading: false,
  createAnnouncementSuccess: null,
  createAnnouncementFailed: null,

  updateAnnouncementLoading: false,
  updateAnnouncementSuccess: null,
  updateAnnouncementFailed: null,

  deleteAnnouncementLoading: false,
  deleteAnnouncementSuccess: null,
  deleteAnnouncementFailed: null,

  readAnnouncementLoading: false,
  readAnnouncementSuccess: null,
  readAnnouncementFailed: null,

  isFetchAnnouncementsHitted: false,
  isFetchAnnouncementHitted: false,
  isCreateAnnouncementHitted: false,
  isUpdateAnnouncementHitted: false,
  isDeleteAnnouncementHitted: false,
  isReadAnnouncementHitted: false,
};

export const announcementReducer = (state = ANNOUNCEMENT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ANNOUNCEMENT_ACTION_TYPES.SET_ANNOUNCEMENTS:
      return { ...state, announcements: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_ANNOUNCEMENT:
      return { ...state, announcement: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_CREATE_ANNOUNCEMENT:
      return { ...state, createAnnouncement: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_UPDATE_ANNOUNCEMENT:
      return { ...state, updateAnnouncement: payload };

    case ANNOUNCEMENT_ACTION_TYPES.SET_IS_ANNOUNCEMENTS_HAS_MORE:
      return { ...state, isAnnouncementsHasMore: payload };

    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_SEARCH:
      return { ...state, fetchAnnouncementsSearch: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_SORT:
      return { ...state, fetchAnnouncementsSort: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_PAGE:
      return { ...state, fetchAnnouncementsPage: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_PER_PAGE:
      return { ...state, fetchAnnouncementsPerPage: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_FILTER_DISPLAY_AT:
      return { ...state, fetchAnnouncementsFilterDisplayAt: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_FILTER_USER_ID:
      return { ...state, fetchAnnouncementsFilterUserId: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_FILTER_ROLE:
      return { ...state, fetchAnnouncementsFilterRole: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_FILTER_IS_READ:
      return { ...state, fetchAnnouncementsFilterIsRead: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_LOADING:
      return { ...state, fetchAnnouncementsLoading: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_SUCCESS:
      return { ...state, fetchAnnouncementsSuccess: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENTS_FAILED:
      return { ...state, fetchAnnouncementsFailed: payload };

    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENT_LOADING:
      return { ...state, fetchAnnouncementLoading: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENT_SUCCESS:
      return { ...state, fetchAnnouncementSuccess: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_FETCH_ANNOUNCEMENT_FAILED:
      return { ...state, fetchAnnouncementFailed: payload };

    case ANNOUNCEMENT_ACTION_TYPES.SET_CREATE_ANNOUNCEMENT_LOADING:
      return { ...state, createAnnouncementLoading: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_CREATE_ANNOUNCEMENT_SUCCESS:
      return { ...state, createAnnouncementSuccess: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_CREATE_ANNOUNCEMENT_FAILED:
      return { ...state, createAnnouncementFailed: payload };

    case ANNOUNCEMENT_ACTION_TYPES.SET_UPDATE_ANNOUNCEMENT_LOADING:
      return { ...state, updateAnnouncementLoading: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_UPDATE_ANNOUNCEMENT_SUCCESS:
      return { ...state, updateAnnouncementSuccess: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_UPDATE_ANNOUNCEMENT_FAILED:
      return { ...state, updateAnnouncementFailed: payload };

    case ANNOUNCEMENT_ACTION_TYPES.SET_DELETE_ANNOUNCEMENT_LOADING:
      return { ...state, deleteAnnouncementLoading: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_DELETE_ANNOUNCEMENT_SUCCESS:
      return { ...state, deleteAnnouncementSuccess: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_DELETE_ANNOUNCEMENT_FAILED:
      return { ...state, deleteAnnouncementFailed: payload };

    case ANNOUNCEMENT_ACTION_TYPES.SET_READ_ANNOUNCEMENT_LOADING:
      return { ...state, readAnnouncementLoading: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_READ_ANNOUNCEMENT_SUCCESS:
      return { ...state, readAnnouncementSuccess: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_READ_ANNOUNCEMENT_FAILED:
      return { ...state, readAnnouncementFailed: payload };

    case ANNOUNCEMENT_ACTION_TYPES.APPEND_ANNOUNCEMENTS:
      return { ...state, announcements: [...state.announcements, ...payload] };

    case ANNOUNCEMENT_ACTION_TYPES.SET_IS_FETCH_ANNOUNCEMENTS_HITTED:
      return { ...state, isFetchAnnouncementsHitted: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_IS_FETCH_ANNOUNCEMENT_HITTED:
      return { ...state, isFetchAnnouncementHitted: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_IS_CREATE_ANNOUNCEMENT_HITTED:
      return { ...state, isCreateAnnouncementHitted: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_IS_UPDATE_ANNOUNCEMENT_HITTED:
      return { ...state, isUpdateAnnouncementHitted: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_IS_DELETE_ANNOUNCEMENT_HITTED:
      return { ...state, isDeleteAnnouncementHitted: payload };
    case ANNOUNCEMENT_ACTION_TYPES.SET_IS_READ_ANNOUNCEMENT_HITTED:
      return { ...state, isReadAnnouncementHitted: payload };

    case ANNOUNCEMENT_ACTION_TYPES.RESET_ANNOUNCEMENT_REDUCER:
      return ANNOUNCEMENT_INITIAL_STATE;

    default:
      return state;
  }
};
