import { takeLatest, put, all, call, select } from "redux-saga/effects";

import {
  getSubscription,
  updateSubscription,
} from "../../api/subscription.api";

import { setFetchGlobalSubscriptionsPage } from "../global/global.action";
import { getIsFetchGlobalSubscriptionsHitted } from "../global/global.selector";
import { _getGlobalSubscriptions } from "../global/global.saga";

import SUBSCRIPTION_ACTION_TYPES from "./subscription.type";
import {
  setFetchSubscriptionFailed,
  setFetchSubscriptionLoading,
  setFetchSubscriptionSuccess,
  setIsFetchSubscriptionHitted,
  setIsUpdateSubscriptionHitted,
  setSubscription,
  setUpdateSubscriptionFailed,
  setUpdateSubscriptionLoading,
  setUpdateSubscriptionSuccess,
} from "./subscription.action";

export function* _getSubscription({ payload: subscriptionKey }) {
  try {
    yield put(setFetchSubscriptionLoading(true));

    const {
      meta: { message },
      data: subscription,
    } = yield call(getSubscription, subscriptionKey);

    yield put(setIsFetchSubscriptionHitted(true));
    yield put(setSubscription(subscription));

    yield put(setFetchSubscriptionSuccess(message));
    yield put(setFetchSubscriptionLoading(false));
  } catch (error) {
    yield put(setFetchSubscriptionFailed(error));
    yield put(setFetchSubscriptionLoading(false));
  }
}
export function* _updateSubscription({
  payload: { subscriptionKey, request },
}) {
  try {
    yield put(setUpdateSubscriptionLoading(true));

    const {
      meta: { message },
    } = yield call(updateSubscription, subscriptionKey, request);

    yield put(setIsUpdateSubscriptionHitted(true));

    const isFetchGlobalSubscriptionsHitted = yield select(
      getIsFetchGlobalSubscriptionsHitted
    );

    if (isFetchGlobalSubscriptionsHitted) {
      yield put(setFetchGlobalSubscriptionsPage(1));
      yield call(_getGlobalSubscriptions);
    }

    yield put(setUpdateSubscriptionSuccess(message));
    yield put(setUpdateSubscriptionLoading(false));
  } catch (error) {
    yield put(setUpdateSubscriptionFailed(error));
    yield put(setUpdateSubscriptionLoading(false));
  }
}

export function* onFetchSubscriptionStart() {
  yield takeLatest(
    SUBSCRIPTION_ACTION_TYPES.FETCH_SUBSCRIPTION_START,
    _getSubscription
  );
}
export function* onUpdateSubscriptionStart() {
  yield takeLatest(
    SUBSCRIPTION_ACTION_TYPES.UPDATE_SUBSCRIPTION_START,
    _updateSubscription
  );
}

export function* subscriptionSaga() {
  yield all([call(onFetchSubscriptionStart), call(onUpdateSubscriptionStart)]);
}
