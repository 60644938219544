import SECTOR_CATEGORY_ACTION_TYPES from "./sector-category.type";

export const SECTOR_CATEGORY_INITIAL_STATE = {
  sectorCategories: [],
  sectorCategory: null,

  isSectorCategoriesHasMore: true,

  fetchSectorCategoriesSearch: null,
  fetchSectorCategoriesSort: null,
  fetchSectorCategoriesKeyBy: null,
  fetchSectorCategoriesPage: 1,
  fetchSectorCategoriesPerPage: null,
  fetchSectorCategoriesIncludes: null,
  fetchSectorCategoriesLoading: false,
  fetchSectorCategoriesSuccess: null,
  fetchSectorCategoriesFailed: null,

  fetchSectorCategoryLoading: false,
  fetchSectorCategorySuccess: null,
  fetchSectorCategoryFailed: null,

  isFetchSectorCategoriesHitted: false,
  isFetchSectorCategoryHitted: false,
};

export const sectorCategoryReducer = (
  state = SECTOR_CATEGORY_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SECTOR_CATEGORY_ACTION_TYPES.SET_SECTOR_CATEGORIES:
      return { ...state, sectorCategories: payload };
    case SECTOR_CATEGORY_ACTION_TYPES.SET_SECTOR_CATEGORY:
      return { ...state, sectorCategory: payload };

    case SECTOR_CATEGORY_ACTION_TYPES.SET_IS_SECTOR_CATEGORIES_HAS_MORE:
      return { ...state, isSectorCategoriesHasMore: payload };

    case SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_SEARCH:
      return { ...state, fetchSectorCategoriesSearch: payload };
    case SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_SORT:
      return { ...state, fetchSectorCategoriesSort: payload };
    case SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_KEY_BY:
      return { ...state, fetchSectorCategoriesKeyBy: payload };
    case SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_PAGE:
      return { ...state, fetchSectorCategoriesPage: payload };
    case SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_PER_PAGE:
      return { ...state, fetchSectorCategoriesPerPage: payload };
    case SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_INCLUDES:
      return { ...state, fetchSectorCategoriesIncludes: payload };
    case SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_LOADING:
      return { ...state, fetchSectorCategoriesLoading: payload };
    case SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_SUCCESS:
      return { ...state, fetchSectorCategoriesSuccess: payload };
    case SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORIES_FAILED:
      return { ...state, fetchSectorCategoriesFailed: payload };

    case SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORY_LOADING:
      return { ...state, fetchSectorCategoryLoading: payload };
    case SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORY_SUCCESS:
      return { ...state, fetchSectorCategorySuccess: payload };
    case SECTOR_CATEGORY_ACTION_TYPES.SET_FETCH_SECTOR_CATEGORY_FAILED:
      return { ...state, fetchSectorCategoryFailed: payload };

    case SECTOR_CATEGORY_ACTION_TYPES.APPEND_SECTOR_CATEGORIES:
      return {
        ...state,
        sectorCategories: [...state.sectorCategories, ...payload],
      };

    case SECTOR_CATEGORY_ACTION_TYPES.IS_FETCH_SECTOR_CATEGORIES_HITTED:
      return { ...state, isFetchSectorCategoriesHitted: payload };
    case SECTOR_CATEGORY_ACTION_TYPES.IS_FETCH_SECTOR_CATEGORY_HITTED:
      return { ...state, isFetchSectorCategoryHitted: payload };

    case SECTOR_CATEGORY_ACTION_TYPES.RESET_SECTOR_REDUCER:
      return SECTOR_CATEGORY_INITIAL_STATE;
    default:
      return state;
  }
};
