import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { PERMISISON_ADMIN_TYPES } from "../../constants/permission-admin.constant";

import PERMISSION_ADMIN_ACTION_TYPES from "./permission-admin.type";

import {
  appendPermissionAdmins,
  appendPermissionSubscribers,
  appendSelectPermissionAdmins,
  appendSelectPermissionSubscribers,
  setAppendSelectPermissionAdminsFailed,
  setAppendSelectPermissionAdminsLoading,
  setAppendSelectPermissionAdminsSuccess,
  setAppendSelectPermissionSubscribersFailed,
  setAppendSelectPermissionSubscribersLoading,
  setAppendSelectPermissionSubscribersSuccess,
  setCreatePermissionFailed,
  setCreatePermissionLoading,
  setCreatePermissionSuccess,
  setDeletePermissionFailed,
  setDeletePermissionLoading,
  setDeletePermissionSuccess,
  setFetchPermissionAdminsFailed,
  setFetchPermissionAdminsLoading,
  setFetchPermissionAdminsPage,
  setFetchPermissionAdminsSuccess,
  setFetchPermissionFailed,
  setFetchPermissionLoading,
  setFetchPermissionSubscribersFailed,
  setFetchPermissionSubscribersLoading,
  setFetchPermissionSubscribersPage,
  setFetchPermissionSubscribersSuccess,
  setFetchPermissionSuccess,
  setFetchSelectPermissionAdminsFailed,
  setFetchSelectPermissionAdminsLoading,
  setFetchSelectPermissionAdminsPage,
  setFetchSelectPermissionAdminsSuccess,
  setFetchSelectPermissionSubscribersFailed,
  setFetchSelectPermissionSubscribersLoading,
  setFetchSelectPermissionSubscribersPage,
  setFetchSelectPermissionSubscribersSuccess,
  setIsAppendSelectPermissionAdminsHitted,
  setIsAppendSelectPermissionSubscribersHitted,
  setIsCreatePermissionHitted,
  setIsDeletePermissionHitted,
  setIsFetchPermissionAdminsHitted,
  setIsFetchPermissionHitted,
  setIsFetchPermissionSubscribersHitted,
  setIsFetchSelectPermissionAdminsHitted,
  setIsFetchSelectPermissionSubscribersHitted,
  setIsPermissionAdminsHasMore,
  setIsPermissionSubscribersHasMore,
  setIsSelectPermissionAdminsHasMore,
  setIsSelectPermissionSubscribersHasMore,
  setIsUpdatePermissionHitted,
  setPermission,
  setPermissionAdmins,
  setPermissionSubscribers,
  setUpdatePermissionFailed,
  setUpdatePermissionLoading,
  setUpdatePermissionSuccess,
} from "./permission-admin.action";
import {
  getAppendSelectPermissionAdminsFilterIsDefault,
  getAppendSelectPermissionAdminsFilterPermissionsIds,
  getAppendSelectPermissionAdminsIncludes,
  getAppendSelectPermissionAdminsKeyBy,
  getAppendSelectPermissionAdminsPage,
  getAppendSelectPermissionAdminsPerPage,
  getAppendSelectPermissionAdminsSearch,
  getAppendSelectPermissionAdminsSort,
  getAppendSelectPermissionSubscribersFilterIsDefault,
  getAppendSelectPermissionSubscribersFilterPermissionsIds,
  getAppendSelectPermissionSubscribersIncludes,
  getAppendSelectPermissionSubscribersKeyBy,
  getAppendSelectPermissionSubscribersPage,
  getAppendSelectPermissionSubscribersPerPage,
  getAppendSelectPermissionSubscribersSearch,
  getAppendSelectPermissionSubscribersSort,
  getFetchPermissionAdminsFilterIsDefault,
  getFetchPermissionAdminsIncludes,
  getFetchPermissionAdminsKeyBy,
  getFetchPermissionAdminsPage,
  getFetchPermissionAdminsPerPage,
  getFetchPermissionAdminsSearch,
  getFetchPermissionAdminsSort,
  getFetchPermissionSubscribersFilterIsDefault,
  getFetchPermissionSubscribersIncludes,
  getFetchPermissionSubscribersKeyBy,
  getFetchPermissionSubscribersPage,
  getFetchPermissionSubscribersPerPage,
  getFetchPermissionSubscribersSearch,
  getFetchPermissionSubscribersSort,
  getFetchSelectPermissionAdminsFilterIsDefault,
  getFetchSelectPermissionAdminsFilterPermissionsIds,
  getFetchSelectPermissionAdminsIncludes,
  getFetchSelectPermissionAdminsKeyBy,
  getFetchSelectPermissionAdminsPage,
  getFetchSelectPermissionAdminsPerPage,
  getFetchSelectPermissionAdminsSearch,
  getFetchSelectPermissionAdminsSort,
  getFetchSelectPermissionSubscribersFilterIsDefault,
  getFetchSelectPermissionSubscribersFilterPermissionsIds,
  getFetchSelectPermissionSubscribersIncludes,
  getFetchSelectPermissionSubscribersKeyBy,
  getFetchSelectPermissionSubscribersPage,
  getFetchSelectPermissionSubscribersPerPage,
  getFetchSelectPermissionSubscribersSearch,
  getFetchSelectPermissionSubscribersSort,
  getIsFetchPermissionAdminsHitted,
  getIsFetchPermissionSubscribersHitted,
  getIsFetchSelectPermissionAdminsHitted,
  getIsFetchSelectPermissionSubscribersHitted,
} from "./permission-admin.selector";

import {
  createPermissionAdmin,
  deletePermissionAdmin,
  getPermissionAdmin,
  getPermissionAdmins,
  updatePermissionAdmin,
} from "../../api/permission-admin.api";

export function* _getPermissionAdmins() {
  try {
    yield put(setFetchPermissionAdminsLoading(true));

    const search = yield select(getFetchPermissionAdminsSearch);
    const sort = yield select(getFetchPermissionAdminsSort);
    const key_by = yield select(getFetchPermissionAdminsKeyBy);
    const page = yield select(getFetchPermissionAdminsPage);
    const per_page = yield select(getFetchPermissionAdminsPerPage);
    const includes = yield select(getFetchPermissionAdminsIncludes);
    const is_default = yield select(getFetchPermissionAdminsFilterIsDefault);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { type: PERMISISON_ADMIN_TYPES.ADMIN, is_default },
    };

    const {
      meta: { message },
      data: { data: permissionAdmins },
    } = yield call(getPermissionAdmins, parameters);

    yield put(setIsFetchPermissionAdminsHitted(true));
    yield put(setIsPermissionAdminsHasMore(permissionAdmins.length > 0));

    if (page > 1) {
      yield put(appendPermissionAdmins(permissionAdmins));
    } else {
      yield put(setPermissionAdmins(permissionAdmins));
    }

    yield put(setFetchPermissionAdminsSuccess(message));
    yield put(setFetchPermissionAdminsLoading(false));
  } catch (error) {
    yield put(setFetchPermissionAdminsFailed(error));
    yield put(setFetchPermissionAdminsLoading(false));
  }
}
export function* _getPermissionSubscribers() {
  try {
    yield put(setFetchPermissionSubscribersLoading(true));

    const search = yield select(getFetchPermissionSubscribersSearch);
    const sort = yield select(getFetchPermissionSubscribersSort);
    const key_by = yield select(getFetchPermissionSubscribersKeyBy);
    const page = yield select(getFetchPermissionSubscribersPage);
    const per_page = yield select(getFetchPermissionSubscribersPerPage);
    const includes = yield select(getFetchPermissionSubscribersIncludes);
    const is_default = yield select(
      getFetchPermissionSubscribersFilterIsDefault
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { type: PERMISISON_ADMIN_TYPES.SUBSCRIBER, is_default },
    };

    const {
      meta: { message },
      data: { data: permissionSubscribers },
    } = yield call(getPermissionAdmins, parameters);

    yield put(setIsFetchPermissionSubscribersHitted(true));
    yield put(
      setIsPermissionSubscribersHasMore(permissionSubscribers.length > 0)
    );

    if (page > 1) {
      yield put(appendPermissionSubscribers(permissionSubscribers));
    } else {
      yield put(setPermissionSubscribers(permissionSubscribers));
    }

    yield put(setFetchPermissionSubscribersSuccess(message));
    yield put(setFetchPermissionSubscribersLoading(false));
  } catch (error) {
    yield put(setFetchPermissionSubscribersFailed(error));
    yield put(setFetchPermissionSubscribersLoading(false));
  }
}
export function* _getSelectPermissionAdmins() {
  try {
    yield put(setFetchSelectPermissionAdminsLoading(true));

    const search = yield select(getFetchSelectPermissionAdminsSearch);
    const sort = yield select(getFetchSelectPermissionAdminsSort);
    const key_by = yield select(getFetchSelectPermissionAdminsKeyBy);
    const page = yield select(getFetchSelectPermissionAdminsPage);
    const per_page = yield select(getFetchSelectPermissionAdminsPerPage);
    const includes = yield select(getFetchSelectPermissionAdminsIncludes);
    const permissions_ids = yield select(
      getFetchSelectPermissionAdminsFilterPermissionsIds
    );
    const is_default = yield select(
      getFetchSelectPermissionAdminsFilterIsDefault
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        type: PERMISISON_ADMIN_TYPES.ADMIN,
        permissions_ids,
        is_default,
      },
    };

    const {
      meta: { message },
      data: permissions,
    } = yield call(getPermissionAdmins, parameters);

    yield put(setIsFetchSelectPermissionAdminsHitted(true));
    yield put(
      setIsSelectPermissionAdminsHasMore(Object.keys(permissions).length > 0)
    );
    yield put(appendSelectPermissionAdmins(permissions));

    yield put(setFetchSelectPermissionAdminsSuccess(message));
    yield put(setFetchSelectPermissionAdminsLoading(false));
  } catch (error) {
    yield put(setFetchSelectPermissionAdminsFailed(error));
    yield put(setFetchSelectPermissionAdminsLoading(false));
  }
}
export function* _getSelectPermissionSubscribers() {
  try {
    yield put(setFetchSelectPermissionSubscribersLoading(true));

    const search = yield select(getFetchSelectPermissionSubscribersSearch);
    const sort = yield select(getFetchSelectPermissionSubscribersSort);
    const key_by = yield select(getFetchSelectPermissionSubscribersKeyBy);
    const page = yield select(getFetchSelectPermissionSubscribersPage);
    const per_page = yield select(getFetchSelectPermissionSubscribersPerPage);
    const includes = yield select(getFetchSelectPermissionSubscribersIncludes);
    const permissions_ids = yield select(
      getFetchSelectPermissionSubscribersFilterPermissionsIds
    );
    const is_default = yield select(
      getFetchSelectPermissionSubscribersFilterIsDefault
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        type: PERMISISON_ADMIN_TYPES.SUBSCRIBER,
        permissions_ids,
        is_default,
      },
    };

    const {
      meta: { message },
      data: permissions,
    } = yield call(getPermissionAdmins, parameters);

    yield put(setIsFetchSelectPermissionSubscribersHitted(true));
    yield put(
      setIsSelectPermissionSubscribersHasMore(
        Object.keys(permissions).length > 0
      )
    );
    yield put(appendSelectPermissionSubscribers(permissions));

    yield put(setFetchSelectPermissionSubscribersSuccess(message));
    yield put(setFetchSelectPermissionSubscribersLoading(false));
  } catch (error) {
    yield put(setFetchSelectPermissionSubscribersFailed(error));
    yield put(setFetchSelectPermissionSubscribersLoading(false));
  }
}
export function* _getAppendSelectPermissionAdmins() {
  try {
    yield put(setAppendSelectPermissionAdminsLoading(true));

    const search = yield select(getAppendSelectPermissionAdminsSearch);
    const sort = yield select(getAppendSelectPermissionAdminsSort);
    const key_by = yield select(getAppendSelectPermissionAdminsKeyBy);
    const page = yield select(getAppendSelectPermissionAdminsPage);
    const per_page = yield select(getAppendSelectPermissionAdminsPerPage);
    const includes = yield select(getAppendSelectPermissionAdminsIncludes);
    const permissions_ids = yield select(
      getAppendSelectPermissionAdminsFilterPermissionsIds
    );
    const is_default = yield select(
      getAppendSelectPermissionAdminsFilterIsDefault
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        type: PERMISISON_ADMIN_TYPES.ADMIN,
        permissions_ids,
        is_default,
      },
    };

    const {
      meta: { message },
      data: permissions,
    } = yield call(getPermissionAdmins, parameters);

    yield put(setIsAppendSelectPermissionAdminsHitted(true));
    yield put(appendSelectPermissionAdmins(permissions));

    yield put(setAppendSelectPermissionAdminsSuccess(message));
    yield put(setAppendSelectPermissionAdminsLoading(false));
  } catch (error) {
    yield put(setAppendSelectPermissionAdminsFailed(error));
    yield put(setAppendSelectPermissionAdminsLoading(false));
  }
}
export function* _getAppendSelectPermissionSubscribers() {
  try {
    yield put(setAppendSelectPermissionSubscribersLoading(true));

    const search = yield select(getAppendSelectPermissionSubscribersSearch);
    const sort = yield select(getAppendSelectPermissionSubscribersSort);
    const key_by = yield select(getAppendSelectPermissionSubscribersKeyBy);
    const page = yield select(getAppendSelectPermissionSubscribersPage);
    const per_page = yield select(getAppendSelectPermissionSubscribersPerPage);
    const includes = yield select(getAppendSelectPermissionSubscribersIncludes);
    const permissions_ids = yield select(
      getAppendSelectPermissionSubscribersFilterPermissionsIds
    );
    const is_default = yield select(
      getAppendSelectPermissionSubscribersFilterIsDefault
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        type: PERMISISON_ADMIN_TYPES.SUBSCRIBER,
        permissions_ids,
        is_default,
      },
    };

    const {
      meta: { message },
      data: permissions,
    } = yield call(getPermissionAdmins, parameters);

    yield put(setIsAppendSelectPermissionSubscribersHitted(true));
    yield put(appendSelectPermissionSubscribers(permissions));

    yield put(setAppendSelectPermissionSubscribersSuccess(message));
    yield put(setAppendSelectPermissionSubscribersLoading(false));
  } catch (error) {
    yield put(setAppendSelectPermissionSubscribersFailed(error));
    yield put(setAppendSelectPermissionSubscribersLoading(false));
  }
}
export function* _getPermission({ payload: permissionId }) {
  try {
    yield put(setFetchPermissionLoading(true));

    const {
      meta: { message },
      data: permission,
    } = yield call(getPermissionAdmin, permissionId);

    yield put(setIsFetchPermissionHitted(true));
    yield put(setPermission(permission));

    yield put(setFetchPermissionSuccess(message));
    yield put(setFetchPermissionLoading(false));
  } catch (error) {
    yield put(setFetchPermissionFailed(error));
    yield put(setFetchPermissionLoading(false));
  }
}
export function* _createPermission({ payload: request }) {
  try {
    yield put(setCreatePermissionLoading(true));

    const {
      meta: { message },
    } = yield call(createPermissionAdmin, request);

    yield put(setIsCreatePermissionHitted(true));

    const isFetchPermissionAdminsHitted = yield select(
      getIsFetchPermissionAdminsHitted
    );
    const isFetchPermissionSubscribersHitted = yield select(
      getIsFetchPermissionSubscribersHitted
    );
    const isFetchSelectPermissionAdminsHitted = yield select(
      getIsFetchSelectPermissionAdminsHitted
    );
    const isFetchSelectPermissionSubscribersHitted = yield select(
      getIsFetchSelectPermissionSubscribersHitted
    );

    if (isFetchPermissionAdminsHitted) {
      yield put(setFetchPermissionAdminsPage(1));
      yield call(_getPermissionAdmins);
    }
    if (isFetchPermissionSubscribersHitted) {
      yield put(setFetchPermissionSubscribersPage(1));
      yield call(_getPermissionSubscribers);
    }
    if (isFetchSelectPermissionAdminsHitted) {
      yield put(setFetchSelectPermissionAdminsPage(1));
      yield call(_getSelectPermissionAdmins);
    }
    if (isFetchSelectPermissionSubscribersHitted) {
      yield put(setFetchSelectPermissionSubscribersPage(1));
      yield call(_getSelectPermissionSubscribers);
    }

    yield put(setCreatePermissionSuccess(message));
    yield put(setCreatePermissionLoading(false));
  } catch (error) {
    yield put(setCreatePermissionFailed(error));
    yield put(setCreatePermissionLoading(false));
  }
}
export function* _updatePermission({ payload: { permissionId, request } }) {
  try {
    yield put(setUpdatePermissionLoading(true));

    const {
      meta: { message },
    } = yield call(updatePermissionAdmin, permissionId, request);

    yield put(setIsUpdatePermissionHitted(true));

    const isFetchPermissionAdminsHitted = yield select(
      getIsFetchPermissionAdminsHitted
    );
    const isFetchPermissionSubscribersHitted = yield select(
      getIsFetchPermissionSubscribersHitted
    );
    const isFetchSelectPermissionAdminsHitted = yield select(
      getIsFetchSelectPermissionAdminsHitted
    );
    const isFetchSelectPermissionSubscribersHitted = yield select(
      getIsFetchSelectPermissionSubscribersHitted
    );

    if (isFetchPermissionAdminsHitted) {
      yield put(setFetchPermissionAdminsPage(1));
      yield call(_getPermissionAdmins);
    }
    if (isFetchPermissionSubscribersHitted) {
      yield put(setFetchPermissionSubscribersPage(1));
      yield call(_getPermissionSubscribers);
    }
    if (isFetchSelectPermissionAdminsHitted) {
      yield put(setFetchSelectPermissionAdminsPage(1));
      yield call(_getSelectPermissionAdmins);
    }
    if (isFetchSelectPermissionSubscribersHitted) {
      yield put(setFetchSelectPermissionSubscribersPage(1));
      yield call(_getSelectPermissionSubscribers);
    }

    yield put(setUpdatePermissionSuccess(message));
    yield put(setUpdatePermissionLoading(false));
  } catch (error) {
    yield put(setUpdatePermissionFailed(error));
    yield put(setUpdatePermissionLoading(false));
  }
}
export function* _deletePermission({ payload: permissionId }) {
  try {
    yield put(setDeletePermissionLoading(true));

    const {
      meta: { message },
    } = yield call(deletePermissionAdmin, permissionId);

    yield put(setIsDeletePermissionHitted(true));

    const isFetchPermissionAdminsHitted = yield select(
      getIsFetchPermissionAdminsHitted
    );
    const isFetchPermissionSubscribersHitted = yield select(
      getIsFetchPermissionSubscribersHitted
    );
    const isFetchSelectPermissionAdminsHitted = yield select(
      getIsFetchSelectPermissionAdminsHitted
    );
    const isFetchSelectPermissionSubscribersHitted = yield select(
      getIsFetchSelectPermissionSubscribersHitted
    );

    if (isFetchPermissionAdminsHitted) {
      yield put(setFetchPermissionAdminsPage(1));
      yield call(_getPermissionAdmins);
    }
    if (isFetchPermissionSubscribersHitted) {
      yield put(setFetchPermissionSubscribersPage(1));
      yield call(_getPermissionSubscribers);
    }
    if (isFetchSelectPermissionAdminsHitted) {
      yield put(setFetchSelectPermissionAdminsPage(1));
      yield call(_getSelectPermissionAdmins);
    }
    if (isFetchSelectPermissionSubscribersHitted) {
      yield put(setFetchSelectPermissionSubscribersPage(1));
      yield call(_getSelectPermissionSubscribers);
    }

    yield put(setDeletePermissionSuccess(message));
    yield put(setDeletePermissionLoading(false));
  } catch (error) {
    yield put(setDeletePermissionFailed(error));
    yield put(setDeletePermissionLoading(false));
  }
}

export function* onFetchPermissionAdminsStart() {
  yield takeLatest(
    PERMISSION_ADMIN_ACTION_TYPES.FETCH_PERMISSION_ADMINS_START,
    _getPermissionAdmins
  );
}
export function* onFetchPermissionSubscribersStart() {
  yield takeLatest(
    PERMISSION_ADMIN_ACTION_TYPES.FETCH_PERMISSION_SUBSCRIBERS_START,
    _getPermissionSubscribers
  );
}
export function* onFetchSelectPermissionAdminsStart() {
  yield takeLatest(
    PERMISSION_ADMIN_ACTION_TYPES.FETCH_SELECT_PERMISSION_ADMINS_START,
    _getSelectPermissionAdmins
  );
}
export function* onFetchSelectPermissionSubscribersStart() {
  yield takeLatest(
    PERMISSION_ADMIN_ACTION_TYPES.FETCH_SELECT_PERMISSION_SUBSCRIBERS_START,
    _getSelectPermissionSubscribers
  );
}
export function* onAppendSelectPermissionAdminsStart() {
  yield takeLatest(
    PERMISSION_ADMIN_ACTION_TYPES.APPEND_SELECT_PERMISSION_ADMINS_START,
    _getAppendSelectPermissionAdmins
  );
}
export function* onAppendSelectPermissionSubscribersStart() {
  yield takeLatest(
    PERMISSION_ADMIN_ACTION_TYPES.APPEND_SELECT_PERMISSION_SUBSCRIBERS_START,
    _getAppendSelectPermissionSubscribers
  );
}
export function* onFetchPermissionStart() {
  yield takeLatest(
    PERMISSION_ADMIN_ACTION_TYPES.FETCH_PERMISSION_START,
    _getPermission
  );
}
export function* onCreatePermissionStart() {
  yield takeLatest(
    PERMISSION_ADMIN_ACTION_TYPES.CREATE_PERMISSION_START,
    _createPermission
  );
}
export function* onUpdatePermissionStart() {
  yield takeLatest(
    PERMISSION_ADMIN_ACTION_TYPES.UPDATE_PERMISSION_START,
    _updatePermission
  );
}
export function* onDeletePermissionStart() {
  yield takeLatest(
    PERMISSION_ADMIN_ACTION_TYPES.DELETE_PERMISSION_START,
    _deletePermission
  );
}

export function* permissionAdminSaga() {
  yield all([
    call(onFetchPermissionAdminsStart),
    call(onFetchPermissionSubscribersStart),
    call(onFetchSelectPermissionAdminsStart),
    call(onFetchSelectPermissionSubscribersStart),
    call(onAppendSelectPermissionAdminsStart),
    call(onAppendSelectPermissionSubscribersStart),
    call(onFetchPermissionStart),
    call(onCreatePermissionStart),
    call(onUpdatePermissionStart),
    call(onDeletePermissionStart),
  ]);
}
