import apiService from "./api";

export const getProductCategories = async (parameters) =>
  (await apiService.get("/v2/product-categories", parameters)).data;
export const getProductCategory = async (categoryId) =>
  (await apiService.get(`/v2/product-categories/${categoryId}`)).data;
export const sortProductCategories = async (request) =>
  (await apiService.post("/v2/product-categories/sort", request)).data;
export const createProductCategory = async (request) =>
  (await apiService.post("/v2/product-categories", request)).data;
export const updateProductCategory = async (categoryId, request) =>
  (await apiService.post(`/v2/product-categories/${categoryId}`, request)).data;
export const deleteProductCategory = async (categoryId) =>
  (await apiService.delete(`/v2/product-categories/${categoryId}`)).data;
