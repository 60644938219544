import { createSelector } from "reselect";

const userDriverSelector = ({ userDriver }) => userDriver;

export const getUserDrivers = createSelector(
  [userDriverSelector],
  ({ userDrivers }) => userDrivers
);
export const getUserDriver = createSelector(
  [userDriverSelector],
  ({ userDriver }) => userDriver
);

export const getIsUserDriversHasMore = createSelector(
  [userDriverSelector],
  ({ isUserDriversHasMore }) => isUserDriversHasMore
);

export const getFetchUserDriversSearch = createSelector(
  [userDriverSelector],
  ({ fetchUserDriversSearch }) => fetchUserDriversSearch
);
export const getFetchUserDriversSort = createSelector(
  [userDriverSelector],
  ({ fetchUserDriversSort }) => fetchUserDriversSort
);
export const getFetchUserDriversKeyBy = createSelector(
  [userDriverSelector],
  ({ fetchUserDriversKeyBy }) => fetchUserDriversKeyBy
);
export const getFetchUserDriversPage = createSelector(
  [userDriverSelector],
  ({ fetchUserDriversPage }) => fetchUserDriversPage
);
export const getFetchUserDriversPerPage = createSelector(
  [userDriverSelector],
  ({ fetchUserDriversPerPage }) => fetchUserDriversPerPage
);
export const getFetchUserDriversIncludes = createSelector(
  [userDriverSelector],
  ({ fetchUserDriversIncludes }) => fetchUserDriversIncludes
);
export const getFetchUserDriversFilterGender = createSelector(
  [userDriverSelector],
  ({ fetchUserDriversFilterGender }) => fetchUserDriversFilterGender
);
export const getFetchUserDriversFilterStatus = createSelector(
  [userDriverSelector],
  ({ fetchUserDriversFilterStatus }) => fetchUserDriversFilterStatus
);
export const getFetchUserDriversFilterVehicleType = createSelector(
  [userDriverSelector],
  ({ fetchUserDriversFilterVehicleType }) => fetchUserDriversFilterVehicleType
);
export const getFetchUserDriversFilterMarketId = createSelector(
  [userDriverSelector],
  ({ fetchUserDriversFilterMarketId }) => fetchUserDriversFilterMarketId
);
export const getFetchUserDriversLoading = createSelector(
  [userDriverSelector],
  ({ fetchUserDriversLoading }) => fetchUserDriversLoading
);
export const getFetchUserDriversSuccess = createSelector(
  [userDriverSelector],
  ({ fetchUserDriversSuccess }) => fetchUserDriversSuccess
);
export const getFetchUserDriversFailed = createSelector(
  [userDriverSelector],
  ({ fetchUserDriversFailed }) => fetchUserDriversFailed
);

export const getFetchUserDriverLoading = createSelector(
  [userDriverSelector],
  ({ fetchUserDriverLoading }) => fetchUserDriverLoading
);
export const getFetchUserDriverSuccess = createSelector(
  [userDriverSelector],
  ({ fetchUserDriverSuccess }) => fetchUserDriverSuccess
);
export const getFetchUserDriverFailed = createSelector(
  [userDriverSelector],
  ({ fetchUserDriverFailed }) => fetchUserDriverFailed
);

export const getCreateUserDriverLoading = createSelector(
  [userDriverSelector],
  ({ createUserDriverLoading }) => createUserDriverLoading
);
export const getCreateUserDriverSuccess = createSelector(
  [userDriverSelector],
  ({ createUserDriverSuccess }) => createUserDriverSuccess
);
export const getCreateUserDriverFailed = createSelector(
  [userDriverSelector],
  ({ createUserDriverFailed }) => createUserDriverFailed
);

export const getUpdateUserDriverLoading = createSelector(
  [userDriverSelector],
  ({ updateUserDriverLoading }) => updateUserDriverLoading
);
export const getUpdateUserDriverSuccess = createSelector(
  [userDriverSelector],
  ({ updateUserDriverSuccess }) => updateUserDriverSuccess
);
export const getUpdateUserDriverFailed = createSelector(
  [userDriverSelector],
  ({ updateUserDriverFailed }) => updateUserDriverFailed
);

export const getAddMarketUserDriverLoading = createSelector(
  [userDriverSelector],
  ({ addMarketUserDriverLoading }) => addMarketUserDriverLoading
);
export const getAddMarketUserDriverSuccess = createSelector(
  [userDriverSelector],
  ({ addMarketUserDriverSuccess }) => addMarketUserDriverSuccess
);
export const getAddMarketUserDriverFailed = createSelector(
  [userDriverSelector],
  ({ addMarketUserDriverFailed }) => addMarketUserDriverFailed
);

export const getRemoveMarketUserDriverLoading = createSelector(
  [userDriverSelector],
  ({ removeMarketUserDriverLoading }) => removeMarketUserDriverLoading
);
export const getRemoveMarketUserDriverSuccess = createSelector(
  [userDriverSelector],
  ({ removeMarketUserDriverSuccess }) => removeMarketUserDriverSuccess
);
export const getRemoveMarketUserDriverFailed = createSelector(
  [userDriverSelector],
  ({ removeMarketUserDriverFailed }) => removeMarketUserDriverFailed
);

export const getIsFetchUserDriversHitted = createSelector(
  [userDriverSelector],
  ({ isFetchUserDriversHitted }) => isFetchUserDriversHitted
);
export const getIsFetchUserDriverHitted = createSelector(
  [userDriverSelector],
  ({ isFetchUserDriverHitted }) => isFetchUserDriverHitted
);
export const getIsCreateUserDriverHitted = createSelector(
  [userDriverSelector],
  ({ isCreateUserDriverHitted }) => isCreateUserDriverHitted
);
export const getIsUpdateUserDriverHitted = createSelector(
  [userDriverSelector],
  ({ isUpdateUserDriverHitted }) => isUpdateUserDriverHitted
);
export const getIsAddMarketUserDriverHitted = createSelector(
  [userDriverSelector],
  ({ isAddMarketUserDriverHitted }) => isAddMarketUserDriverHitted
);
export const getIsRemoveMarketUserDriverHitted = createSelector(
  [userDriverSelector],
  ({ isRemoveMarketUserDriverHitted }) => isRemoveMarketUserDriverHitted
);
