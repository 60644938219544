import PURCHASE_ACTION_TYPES from "./purchase.type";
import { createAction } from "../../utils/store.utils";

export const setPurchases = (purchases) =>
  createAction(PURCHASE_ACTION_TYPES.SET_PURCHASES, purchases);
export const setPurchase = (purchase) =>
  createAction(PURCHASE_ACTION_TYPES.SET_PURCHASE, purchase);
export const setCalculatePurchase = (calculatePurchase) =>
  createAction(PURCHASE_ACTION_TYPES.SET_CALCULATE_PURCHASE, calculatePurchase);

export const setIsPurchasesHasMore = (isPurchasesHasMore) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_PURCHASES_HAS_MORE,
    isPurchasesHasMore
  );
export const setFetchPurchasesSearch = (fetchPurchasesSearch) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_SEARCH,
    fetchPurchasesSearch
  );
export const setFetchPurchasesSort = (fetchPurchasesSort) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_SORT,
    fetchPurchasesSort
  );
export const setFetchPurchasesKeyBy = (fetchPurchasesKeyBy) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_KEY_BY,
    fetchPurchasesKeyBy
  );
export const setFetchPurchasesPage = (fetchPurchasesPage) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_PAGE,
    fetchPurchasesPage
  );
export const setFetchPurchasesPerPage = (fetchPurchasesPerPage) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_PER_PAGE,
    fetchPurchasesPerPage
  );
export const setFetchPurchasesIncludes = (fetchPurchasesIncludes) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_INCLUDES,
    fetchPurchasesIncludes
  );
export const setFetchPurchasesFilterMarketId = (fetchPurchasesFilterMarketId) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_FILTER_MARKET_ID,
    fetchPurchasesFilterMarketId
  );
export const setFetchPurchasesFilterBranchId = (fetchPurchasesFilterBranchId) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_FILTER_BRANCH_ID,
    fetchPurchasesFilterBranchId
  );
export const setFetchPurchasesFilterSupplierId = (
  fetchPurchasesFilterSupplierId
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_FILTER_SUPPLIER_ID,
    fetchPurchasesFilterSupplierId
  );
export const setFetchPurchasesLoading = (fetchPurchasesLoading) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_LOADING,
    fetchPurchasesLoading
  );
export const setFetchPurchasesSuccess = (fetchPurchasesSuccess) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_SUCCESS,
    fetchPurchasesSuccess
  );
export const setFetchPurchasesFailed = (fetchPurchasesFailed) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASES_FAILED,
    fetchPurchasesFailed
  );

export const setFetchPurchaseLoading = (fetchPurchaseLoading) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASE_LOADING,
    fetchPurchaseLoading
  );
export const setFetchPurchaseSuccess = (fetchPurchaseSuccess) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASE_SUCCESS,
    fetchPurchaseSuccess
  );
export const setFetchPurchaseFailed = (fetchPurchaseFailed) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASE_FAILED,
    fetchPurchaseFailed
  );

export const setCreatePurchaseLoading = (createPurchaseLoading) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE_LOADING,
    createPurchaseLoading
  );
export const setCreatePurchaseSuccess = (createPurchaseSuccess) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE_SUCCESS,
    createPurchaseSuccess
  );
export const setCreatePurchaseFailed = (createPurchaseFailed) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE_FAILED,
    createPurchaseFailed
  );

export const setUpdatePurchaseLoading = (updatePurchaseLoading) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE_LOADING,
    updatePurchaseLoading
  );
export const setUpdatePurchaseSuccess = (updatePurchaseSuccess) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE_SUCCESS,
    updatePurchaseSuccess
  );
export const setUpdatePurchaseFailed = (updatePurchaseFailed) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE_FAILED,
    updatePurchaseFailed
  );

export const setDeletePurchaseLoading = (deletePurchaseLoading) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_DELETE_PURCHASE_LOADING,
    deletePurchaseLoading
  );
export const setDeletePurchaseSuccess = (deletePurchaseSuccess) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_DELETE_PURCHASE_SUCCESS,
    deletePurchaseSuccess
  );
export const setDeletePurchaseFailed = (deletePurchaseFailed) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_DELETE_PURCHASE_FAILED,
    deletePurchaseFailed
  );

export const setCalculatePurchaseLoading = (calculatePurchaseLoading) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CALCULATE_PURCHASE_LOADING,
    calculatePurchaseLoading
  );
export const setCalculatePurchaseSuccess = (calculatePurchaseSuccess) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CALCULATE_PURCHASE_SUCCESS,
    calculatePurchaseSuccess
  );
export const setCalculatePurchaseFailed = (calculatePurchaseFailed) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CALCULATE_PURCHASE_FAILED,
    calculatePurchaseFailed
  );

export const appendPurchases = (purchases) =>
  createAction(PURCHASE_ACTION_TYPES.APPEND_PURCHASES, purchases);

export const fetchPurchasesStart = () =>
  createAction(PURCHASE_ACTION_TYPES.FETCH_PURCHASES_START);
export const fetchPurchaseStart = (purchaseId) =>
  createAction(PURCHASE_ACTION_TYPES.FETCH_PURCHASE_START, purchaseId);
export const createPurchaseStart = (request) =>
  createAction(PURCHASE_ACTION_TYPES.CREATE_PURCHASE_START, request);
export const updatePurchaseStart = (purchaseId, request) =>
  createAction(PURCHASE_ACTION_TYPES.UPDATE_PURCHASE_START, {
    purchaseId,
    request,
  });
export const deletePurchaseStart = (purchaseId) =>
  createAction(PURCHASE_ACTION_TYPES.DELETE_PURCHASE_START, purchaseId);
export const calculatePurchaseStart = (request) =>
  createAction(PURCHASE_ACTION_TYPES.CALCULATE_PURCHASE_START, request);

export const setIsFetchPurchasesHitted = (isFetchPurchasesHitted) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_FETCH_PURCHASES_HITTED,
    isFetchPurchasesHitted
  );
export const setIsFetchPurchaseHitted = (isFetchPurchaseHitted) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_FETCH_PURCHASE_HITTED,
    isFetchPurchaseHitted
  );
export const setIsCreatePurchaseHitted = (isCreatePurchaseHitted) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_CREATE_PURCHASE_HITTED,
    isCreatePurchaseHitted
  );
export const setIsUpdatePurchaseHitted = (isUpdatePurchaseHitted) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_UPDATE_PURCHASE_HITTED,
    isUpdatePurchaseHitted
  );
export const setIsDeletePurchaseHitted = (isDeletePurchaseHitted) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_DELETE_PURCHASE_HITTED,
    isDeletePurchaseHitted
  );
export const setIsCalculatePurchaseHitted = (isCalculatePurchaseHitted) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_CALCULATE_PURCHASE_HITTED,
    isCalculatePurchaseHitted
  );

export const resetPurchaseReducer = () =>
  createAction(PURCHASE_ACTION_TYPES.RESET_PURCHASE_REDUCER);
