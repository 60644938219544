import CUSTOMER_ACTION_TYPES from "./customer.type";

export const CUSTOMER_INITIAL_STATE = {
  customers: [],
  searchCustomers: [],
  customer: null,
  currentCustomer: null,
  createCustomer: null,
  updateCustomer: null,

  isCustomersHasMore: true,
  isSearchCustomersHasMore: true,

  fetchCustomersSearch: null,
  fetchCustomersSort: null,
  fetchCustomersKeyBy: null,
  fetchCustomersPage: 1,
  fetchCustomersPerPage: null,
  fetchCustomersIncludes: null,
  fetchCustomersFilterMarketId: null,
  fetchCustomersLoading: false,
  fetchCustomersSuccess: null,
  fetchCustomersFailed: null,

  fetchSearchCustomersSearch: null,
  fetchSearchCustomersSort: null,
  fetchSearchCustomersKeyBy: null,
  fetchSearchCustomersPage: 1,
  fetchSearchCustomersPerPage: null,
  fetchSearchCustomersIncludes: null,
  fetchSearchCustomersFilterMarketId: null,
  fetchSearchCustomersLoading: false,
  fetchSearchCustomersSuccess: null,
  fetchSearchCustomersFailed: null,

  fetchCustomerLoading: false,
  fetchCustomerSuccess: null,
  fetchCustomerFailed: null,

  createCustomerLoading: false,
  createCustomerSuccess: null,
  createCustomerFailed: null,

  updateCustomerLoading: false,
  updateCustomerSuccess: null,
  updateCustomerFailed: null,

  isFetchCustomersHitted: false,
  isFetchSearchCustomersHitted: false,
  isFetchCustomerHitted: false,
  isCreateCustomerHitted: false,
  isUpdateCustomerHitted: false,
};

export const customerReducer = (state = CUSTOMER_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CUSTOMER_ACTION_TYPES.SET_CUSTOMERS:
      return { ...state, customers: payload };
    case CUSTOMER_ACTION_TYPES.SET_SEARCH_CUSTOMERS:
      return { ...state, searchCustomers: payload };
    case CUSTOMER_ACTION_TYPES.SET_CUSTOMER:
      return { ...state, customer: payload };
    case CUSTOMER_ACTION_TYPES.SET_CURRENT_CUSTOMER:
      return { ...state, currentCustomer: payload };
    case CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER:
      return { ...state, createCustomer: payload };
    case CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER:
      return { ...state, updateCustomer: payload };

    case CUSTOMER_ACTION_TYPES.SET_IS_CUSTOMERS_HAS_MORE:
      return { ...state, isCustomersHasMore: payload };
    case CUSTOMER_ACTION_TYPES.SET_IS_SEARCH_CUSTOMERS_HAS_MORE:
      return { ...state, isSearchCustomersHasMore: payload };

    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SEARCH:
      return { ...state, fetchCustomersSearch: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SORT:
      return { ...state, fetchCustomersSort: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_KEY_BY:
      return { ...state, fetchCustomersKeyBy: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_PAGE:
      return { ...state, fetchCustomersPage: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_PER_PAGE:
      return { ...state, fetchCustomersPerPage: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_INCLUDES:
      return { ...state, fetchCustomersIncludes: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_FILTER_MARKET_ID:
      return { ...state, fetchCustomersFilterMarketId: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_LOADING:
      return { ...state, fetchCustomersLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SUCCESS:
      return { ...state, fetchCustomersSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_FAILED:
      return { ...state, fetchCustomersFailed: payload };

    case CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_SEARCH:
      return { ...state, fetchSearchCustomersSearch: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_SORT:
      return { ...state, fetchSearchCustomersSort: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_KEY_BY:
      return { ...state, fetchSearchCustomersKeyBy: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_PAGE:
      return { ...state, fetchSearchCustomersPage: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_PER_PAGE:
      return { ...state, fetchSearchCustomersPerPage: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_INCLUDES:
      return { ...state, fetchSearchCustomersIncludes: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_FILTER_MARKET_ID:
      return { ...state, fetchSearchCustomersFilterMarketId: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_LOADING:
      return { ...state, fetchSearchCustomersLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_SUCCESS:
      return { ...state, fetchSearchCustomersSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SEARCH_CUSTOMERS_FAILED:
      return { ...state, fetchSearchCustomersFailed: payload };

    case CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_LOADING:
      return { ...state, createCustomerLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_SUCCESS:
      return { ...state, createCustomerSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_FAILED:
      return { ...state, createCustomerFailed: payload };

    case CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_LOADING:
      return { ...state, updateCustomerLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_SUCCESS:
      return { ...state, updateCustomerSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_FAILED:
      return { ...state, updateCustomerFailed: payload };

    case CUSTOMER_ACTION_TYPES.APPEND_CUSTOMERS:
      return { ...state, customers: [...state.customers, ...payload] };
    case CUSTOMER_ACTION_TYPES.APPEND_SEARCH_CUSTOMERS:
      return {
        ...state,
        searchCustomers: [...state.searchCustomers, ...payload],
      };

    case CUSTOMER_ACTION_TYPES.SET_IS_FETCH_CUSTOMERS_HITTED:
      return { ...state, isFetchCustomersHitted: payload };
    case CUSTOMER_ACTION_TYPES.SET_IS_FETCH_SEARCH_CUSTOMERS_HITTED:
      return { ...state, isFetchSearchCustomersHitted: payload };
    case CUSTOMER_ACTION_TYPES.SET_IS_FETCH_CUSTOMER_HITTED:
      return { ...state, isFetchCustomerHitted: payload };
    case CUSTOMER_ACTION_TYPES.SET_IS_CREATE_CUSTOMER_HITTED:
      return { ...state, isCreateCustomerHitted: payload };
    case CUSTOMER_ACTION_TYPES.SET_IS_UPDATE_CUSTOMER_HITTED:
      return { ...state, isUpdateCustomerHitted: payload };

    case CUSTOMER_ACTION_TYPES.RESET_CUSTOMER_REDUCER:
      return CUSTOMER_INITIAL_STATE;
    default:
      return state;
  }
};
