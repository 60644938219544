import KITCHEN_GROUP_ACTION_TYPES from "./kitchen-group.type";

export const KITCHEN_GROUP_INITIAL_STATE = {
  kitchenGroups: [],
  selectKitchenGroups: [],
  kitchenGroup: null,

  isKitchenGroupsHasMore: true,
  isSelectKitchenGroupsHasMore: true,

  fetchKitchenGroupsSearch: null,
  fetchKitchenGroupsPage: 1,
  fetchKitchenGroupsPerPage: null,
  fetchKitchenGroupsIncludes: null,
  fetchKitchenGroupsFilterMarketId: null,
  fetchKitchenGroupsFilterBranchId: null,
  fetchKitchenGroupsLoading: false,
  fetchKitchenGroupsSuccess: null,
  fetchKitchenGroupsFailed: null,

  fetchSelectKitchenGroupsPage: 1,
  fetchSelectKitchenGroupsPerPage: null,
  fetchSelectKitchenGroupsIncludes: null,
  fetchSelectKitchenGroupsFilterMarketId: null,
  fetchSelectKitchenGroupsFilterBranchId: null,
  fetchSelectKitchenGroupsLoading: false,
  fetchSelectKitchenGroupsSuccess: null,
  fetchSelectKitchenGroupsFailed: null,

  fetchKitchenGroupLoading: false,
  fetchKitchenGroupSuccess: null,
  fetchKitchenGroupFailed: null,

  createKitchenGroupLoading: false,
  createKitchenGroupSuccess: null,
  createKitchenGroupFailed: null,

  updateKitchenGroupLoading: false,
  updateKitchenGroupSuccess: null,
  updateKitchenGroupFailed: null,

  deleteKitchenGroupLoading: false,
  deleteKitchenGroupSuccess: null,
  deleteKitchenGroupFailed: null,

  isFetchKitchenGroupsHitted: false,
  isFetchSelectKitchenGroupsHitted: false,
  isFetchKitchenGroupHitted: false,
  isCreateKitchenGroupHitted: false,
  isUpdateKitchenGroupHitted: false,
  isDeleteKitchenGroupHitted: false,
};

export const kitchenGroupReducer = (state = KITCHEN_GROUP_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case KITCHEN_GROUP_ACTION_TYPES.SET_KITCHEN_GROUPS:
      return { ...state, kitchenGroups: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_SELECT_KITCHEN_GROUPS:
      return { ...state, selectKitchenGroups: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_KITCHEN_GROUP:
      return { ...state, kitchenGroup: payload };

    case KITCHEN_GROUP_ACTION_TYPES.SET_IS_KITCHEN_GROUPS_HAS_MORE:
      return { ...state, isKitchenGroupsHasMore: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_IS_SELECT_KITCHEN_GROUPS_HAS_MORE:
      return { ...state, isSelectKitchenGroupsHasMore: payload };

    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_SEARCH:
      return { ...state, fetchKitchenGroupsSearch: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_PAGE:
      return { ...state, fetchKitchenGroupsPage: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_PER_PAGE:
      return { ...state, fetchKitchenGroupsPerPage: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_INCLUDES:
      return { ...state, fetchKitchenGroupsIncludes: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_FILTER_MARKET_ID:
      return { ...state, fetchKitchenGroupsFilterMarketId: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_FILTER_BRANCH_ID:
      return { ...state, fetchKitchenGroupsFilterBranchId: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_LOADING:
      return { ...state, fetchKitchenGroupsLoading: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_SUCCESS:
      return { ...state, fetchKitchenGroupsSuccess: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUPS_FAILED:
      return { ...state, fetchKitchenGroupsFailed: payload };

    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_PAGE:
      return { ...state, fetchSelectKitchenGroupsPage: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_PER_PAGE:
      return { ...state, fetchSelectKitchenGroupsPerPage: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_INCLUDES:
      return { ...state, fetchSelectKitchenGroupsIncludes: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_FILTER_MARKET_ID:
      return { ...state, fetchSelectKitchenGroupsFilterMarketId: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_FILTER_BRANCH_ID:
      return { ...state, fetchSelectKitchenGroupsFilterBranchId: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_LOADING:
      return { ...state, fetchSelectKitchenGroupsLoading: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_SUCCESS:
      return { ...state, fetchSelectKitchenGroupsSuccess: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_SELECT_KITCHEN_GROUPS_FAILED:
      return { ...state, fetchSelectKitchenGroupsFailed: payload };

    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUP_LOADING:
      return { ...state, fetchKitchenGroupLoading: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUP_SUCCESS:
      return { ...state, fetchKitchenGroupSuccess: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_FETCH_KITCHEN_GROUP_FAILED:
      return { ...state, fetchKitchenGroupFailed: payload };

    case KITCHEN_GROUP_ACTION_TYPES.SET_CREATE_KITCHEN_GROUP_LOADING:
      return { ...state, createKitchenGroupLoading: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_CREATE_KITCHEN_GROUP_SUCCESS:
      return { ...state, createKitchenGroupSuccess: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_CREATE_KITCHEN_GROUP_FAILED:
      return { ...state, createKitchenGroupFailed: payload };

    case KITCHEN_GROUP_ACTION_TYPES.SET_UPDATE_KITCHEN_GROUP_LOADING:
      return { ...state, updateKitchenGroupLoading: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_UPDATE_KITCHEN_GROUP_SUCCESS:
      return { ...state, updateKitchenGroupSuccess: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_UPDATE_KITCHEN_GROUP_FAILED:
      return { ...state, updateKitchenGroupFailed: payload };

    case KITCHEN_GROUP_ACTION_TYPES.SET_DELETE_KITCHEN_GROUP_LOADING:
      return { ...state, deleteKitchenGroupLoading: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_DELETE_KITCHEN_GROUP_SUCCESS:
      return { ...state, deleteKitchenGroupSuccess: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_DELETE_KITCHEN_GROUP_FAILED:
      return { ...state, deleteKitchenGroupFailed: payload };

    case KITCHEN_GROUP_ACTION_TYPES.APPEND_KITCHEN_GROUPS:
      return { ...state, kitchenGroups: [...state.kitchenGroups, ...payload] };
    case KITCHEN_GROUP_ACTION_TYPES.APPEND_SELECT_KITCHEN_GROUPS:
      return { ...state, selectKitchenGroups: [...state.selectKitchenGroups, ...payload] };

    case KITCHEN_GROUP_ACTION_TYPES.SET_IS_FETCH_KITCHEN_GROUPS_HITTED:
      return { ...state, isFetchKitchenGroupsHitted: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_IS_FETCH_SELECT_KITCHEN_GROUPS_HITTED:
      return { ...state, isFetchSelectKitchenGroupsHitted: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_IS_FETCH_KITCHEN_GROUP_HITTED:
      return { ...state, isFetchKitchenGroupHitted: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_IS_CREATE_KITCHEN_GROUP_HITTED:
      return { ...state, isCreateKitchenGroupHitted: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_IS_UPDATE_KITCHEN_GROUP_HITTED:
      return { ...state, isUpdateKitchenGroupHitted: payload };
    case KITCHEN_GROUP_ACTION_TYPES.SET_IS_DELETE_KITCHEN_GROUP_HITTED:
      return { ...state, isDeleteKitchenGroupHitted: payload };

    case KITCHEN_GROUP_ACTION_TYPES.RESET_KITCHEN_GROUP_REDUCER:
      return KITCHEN_GROUP_INITIAL_STATE;
    default:
      return state;
  }
};
