import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PAYMENT_METHOD_ACTION_TYPES from "./payment-method.type";

import {
  appendPaymentMethods,
  appendSelectPaymentMethods,
  setCreatePaymentMethodFailed,
  setCreatePaymentMethodLoading,
  setCreatePaymentMethodSuccess,
  setDeletePaymentMethodFailed,
  setDeletePaymentMethodLoading,
  setDeletePaymentMethodSuccess,
  setFetchPaymentMethodsFailed,
  setFetchPaymentMethodsLoading,
  setFetchPaymentMethodsPage,
  setFetchPaymentMethodsSuccess,
  setFetchSelectPaymentMethodsFailed,
  setFetchSelectPaymentMethodsLoading,
  setFetchSelectPaymentMethodsPage,
  setFetchSelectPaymentMethodsSuccess,
  setIsCreatePaymentMethodHitted,
  setIsDeletePaymentMethodHitted,
  setIsFetchPaymentMethodsHitted,
  setIsFetchSelectPaymentMethodsHitted,
  setIsPaymentMethodsHasMore,
  setIsSelectPaymentMethodsHasMore,
  setIsUpdatePaymentMethodHitted,
  setPaymentMethods,
  setSelectPaymentMethods,
  setUpdatePaymentMethodFailed,
  setUpdatePaymentMethodLoading,
  setUpdatePaymentMethodSuccess,
} from "./payment-method.action";
import {
  getFetchPaymentMethodsPage,
  getFetchPaymentMethodsPerPage,
  getFetchSelectPaymentMethodsPage,
  getFetchSelectPaymentMethodsPerPage,
  getIsFetchPaymentMethodsHitted,
  getIsFetchSelectPaymentMethodsHitted,
} from "./payment-method.selector";

import {
  createPaymentMethod,
  deletePaymentMethod,
  getPaymentMethods,
  updatePaymentMethod,
} from "../../api/payment-method.api";

export function* _getPaymentMethods() {
  try {
    yield put(setFetchPaymentMethodsLoading(true));

    const page = yield select(getFetchPaymentMethodsPage);
    const per_page = yield select(getFetchPaymentMethodsPerPage);

    const parameters = { page, per_page };

    const {
      meta: { message },
      data: { data: paymentMethods },
    } = yield call(getPaymentMethods, parameters);

    yield put(setIsFetchPaymentMethodsHitted(true));
    yield put(setIsPaymentMethodsHasMore(paymentMethods.length > 0));

    if (page > 1) {
      yield put(appendPaymentMethods(paymentMethods));
    } else {
      yield put(setPaymentMethods(paymentMethods));
    }

    yield put(setFetchPaymentMethodsSuccess(message));
    yield put(setFetchPaymentMethodsLoading(false));
  } catch (error) {
    yield put(setFetchPaymentMethodsFailed(error));
    yield put(setFetchPaymentMethodsLoading(false));
  }
}
export function* _getSelectPaymentMethods() {
  try {
    yield put(setFetchSelectPaymentMethodsLoading(true));

    const page = yield select(getFetchSelectPaymentMethodsPage);
    const per_page = yield select(getFetchSelectPaymentMethodsPerPage);

    const parameters = { page, per_page };

    const {
      meta: { message },
      data: { data: paymentMethods },
    } = yield call(getPaymentMethods, parameters);

    yield put(setIsFetchSelectPaymentMethodsHitted(true));
    yield put(setIsSelectPaymentMethodsHasMore(paymentMethods.length > 0));

    if (page > 1) {
      yield put(appendSelectPaymentMethods(paymentMethods));
    } else {
      yield put(setSelectPaymentMethods(paymentMethods));
    }

    yield put(setFetchSelectPaymentMethodsSuccess(message));
    yield put(setFetchSelectPaymentMethodsLoading(false));
  } catch (error) {
    yield put(setFetchSelectPaymentMethodsFailed(error));
    yield put(setFetchSelectPaymentMethodsLoading(false));
  }
}
export function* _createPaymentMethod({ payload: request }) {
  try {
    yield put(setCreatePaymentMethodLoading(true));

    const {
      meta: { message },
    } = yield call(createPaymentMethod, request);

    yield put(setIsCreatePaymentMethodHitted(true));

    const isFetchPaymentMethodsHitted = yield select(getIsFetchPaymentMethodsHitted);
    const isFetchSelectPaymentMethodsHitted = yield select(getIsFetchSelectPaymentMethodsHitted);

    if (isFetchPaymentMethodsHitted) {
      yield put(setFetchPaymentMethodsPage(1));
      yield call(_getPaymentMethods);
    }
    if (isFetchSelectPaymentMethodsHitted) {
      yield put(setFetchSelectPaymentMethodsPage(1));
      yield call(_getSelectPaymentMethods);
    }

    yield put(setCreatePaymentMethodSuccess(message));
    yield put(setCreatePaymentMethodLoading(false));
  } catch (error) {
    yield put(setCreatePaymentMethodFailed(error));
    yield put(setCreatePaymentMethodLoading(false));
  }
}
export function* _updatePaymentMethod({ payload: { paymentMethodKey, request } }) {
  try {
    yield put(setUpdatePaymentMethodLoading(true));

    const {
      meta: { message },
    } = yield call(updatePaymentMethod, paymentMethodKey, request);

    yield put(setIsUpdatePaymentMethodHitted(true));

    const isFetchPaymentMethodsHitted = yield select(getIsFetchPaymentMethodsHitted);
    const isFetchSelectPaymentMethodsHitted = yield select(getIsFetchSelectPaymentMethodsHitted);

    if (isFetchPaymentMethodsHitted) {
      yield put(setFetchPaymentMethodsPage(1));
      yield call(_getPaymentMethods);
    }
    if (isFetchSelectPaymentMethodsHitted) {
      yield put(setFetchSelectPaymentMethodsPage(1));
      yield call(_getSelectPaymentMethods);
    }

    yield put(setUpdatePaymentMethodSuccess(message));
    yield put(setUpdatePaymentMethodLoading(false));
  } catch (error) {
    yield put(setUpdatePaymentMethodFailed(error));
    yield put(setUpdatePaymentMethodLoading(false));
  }
}
export function* _deletePaymentMethod({ payload: paymentMethodKey }) {
  try {
    yield put(setDeletePaymentMethodLoading(true));

    const {
      meta: { message },
    } = yield call(deletePaymentMethod, paymentMethodKey);

    yield put(setIsDeletePaymentMethodHitted(true));

    const isFetchPaymentMethodsHitted = yield select(getIsFetchPaymentMethodsHitted);
    const isFetchSelectPaymentMethodsHitted = yield select(getIsFetchSelectPaymentMethodsHitted);

    if (isFetchPaymentMethodsHitted) {
      yield put(setFetchPaymentMethodsPage(1));
      yield call(_getPaymentMethods);
    }
    if (isFetchSelectPaymentMethodsHitted) {
      yield put(setFetchSelectPaymentMethodsPage(1));
      yield call(_getSelectPaymentMethods);
    }

    yield put(setDeletePaymentMethodSuccess(message));
    yield put(setDeletePaymentMethodLoading(false));
  } catch (error) {
    yield put(setDeletePaymentMethodFailed(error));
    yield put(setDeletePaymentMethodLoading(false));
  }
}

export function* onFetchPaymentMethodsStart() {
  yield takeLatest(PAYMENT_METHOD_ACTION_TYPES.FETCH_PAYMENT_METHODS_START, _getPaymentMethods);
}
export function* onFetchSelectPaymentMethodsStart() {
  yield takeLatest(PAYMENT_METHOD_ACTION_TYPES.FETCH_SELECT_PAYMENT_METHODS_START, _getSelectPaymentMethods);
}
export function* onCreatePaymentMethodStart() {
  yield takeLatest(PAYMENT_METHOD_ACTION_TYPES.CREATE_PAYMENT_METHOD_START, _createPaymentMethod);
}
export function* onUpdatePaymentMethodStart() {
  yield takeLatest(PAYMENT_METHOD_ACTION_TYPES.UPDATE_PAYMENT_METHOD_START, _updatePaymentMethod);
}
export function* onDeletePaymentMethodStart() {
  yield takeLatest(PAYMENT_METHOD_ACTION_TYPES.DELETE_PAYMENT_METHOD_START, _deletePaymentMethod);
}

export function* paymentMethodSaga() {
  yield all([
    call(onFetchPaymentMethodsStart),
    call(onFetchSelectPaymentMethodsStart),
    call(onCreatePaymentMethodStart),
    call(onUpdatePaymentMethodStart),
    call(onDeletePaymentMethodStart),
  ]);
}
