import { createSelector } from "reselect";

const userCustomerSelector = ({ userCustomer }) => userCustomer;

export const getUserCustomers = createSelector(
  [userCustomerSelector],
  ({ userCustomers }) => userCustomers
);
export const getSearchUserCustomers = createSelector(
  [userCustomerSelector],
  ({ searchUserCustomers }) => searchUserCustomers
);
export const getUserCustomer = createSelector(
  [userCustomerSelector],
  ({ userCustomer }) => userCustomer
);
export const getCurrentUserCustomer = createSelector(
  [userCustomerSelector],
  ({ currentUserCustomer }) => currentUserCustomer
);
export const getCreateUserCustomer = createSelector(
  [userCustomerSelector],
  ({ createUserCustomer }) => createUserCustomer
);
export const getUpdateUserCustomer = createSelector(
  [userCustomerSelector],
  ({ updateUserCustomer }) => updateUserCustomer
);

export const getIsUserCustomersHasMore = createSelector(
  [userCustomerSelector],
  ({ isUserCustomersHasMore }) => isUserCustomersHasMore
);
export const getIsSearchUserCustomersHasMore = createSelector(
  [userCustomerSelector],
  ({ isSearchUserCustomersHasMore }) => isSearchUserCustomersHasMore
);

export const getFetchUserCustomersSearch = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersSearch }) => fetchUserCustomersSearch
);
export const getFetchUserCustomersSort = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersSort }) => fetchUserCustomersSort
);
export const getFetchUserCustomersKeyBy = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersKeyBy }) => fetchUserCustomersKeyBy
);
export const getFetchUserCustomersPage = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersPage }) => fetchUserCustomersPage
);
export const getFetchUserCustomersPerPage = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersPerPage }) => fetchUserCustomersPerPage
);
export const getFetchUserCustomersIncludes = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersIncludes }) => fetchUserCustomersIncludes
);
export const getFetchUserCustomersFilterMarketId = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersFilterMarketId }) => fetchUserCustomersFilterMarketId
);
export const getFetchUserCustomersLoading = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersLoading }) => fetchUserCustomersLoading
);
export const getFetchUserCustomersSuccess = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersSuccess }) => fetchUserCustomersSuccess
);
export const getFetchUserCustomersFailed = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersFailed }) => fetchUserCustomersFailed
);

export const getFetchSearchUserCustomersSearch = createSelector(
  [userCustomerSelector],
  ({ fetchSearchUserCustomersSearch }) => fetchSearchUserCustomersSearch
);
export const getFetchSearchUserCustomersSort = createSelector(
  [userCustomerSelector],
  ({ fetchSearchUserCustomersSort }) => fetchSearchUserCustomersSort
);
export const getFetchSearchUserCustomersKeyBy = createSelector(
  [userCustomerSelector],
  ({ fetchSearchUserCustomersKeyBy }) => fetchSearchUserCustomersKeyBy
);
export const getFetchSearchUserCustomersPage = createSelector(
  [userCustomerSelector],
  ({ fetchSearchUserCustomersPage }) => fetchSearchUserCustomersPage
);
export const getFetchSearchUserCustomersPerPage = createSelector(
  [userCustomerSelector],
  ({ fetchSearchUserCustomersPerPage }) => fetchSearchUserCustomersPerPage
);
export const getFetchSearchUserCustomersIncludes = createSelector(
  [userCustomerSelector],
  ({ fetchSearchUserCustomersIncludes }) => fetchSearchUserCustomersIncludes
);
export const getFetchSearchUserCustomersFilterMarketId = createSelector(
  [userCustomerSelector],
  ({ fetchSearchUserCustomersFilterMarketId }) =>
    fetchSearchUserCustomersFilterMarketId
);
export const getFetchSearchUserCustomersLoading = createSelector(
  [userCustomerSelector],
  ({ fetchSearchUserCustomersLoading }) => fetchSearchUserCustomersLoading
);
export const getFetchSearchUserCustomersSuccess = createSelector(
  [userCustomerSelector],
  ({ fetchSearchUserCustomersSuccess }) => fetchSearchUserCustomersSuccess
);
export const getFetchSearchUserCustomersFailed = createSelector(
  [userCustomerSelector],
  ({ fetchSearchUserCustomersFailed }) => fetchSearchUserCustomersFailed
);

export const getFetchUserCustomerLoading = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomerLoading }) => fetchUserCustomerLoading
);
export const getFetchUserCustomerSuccess = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomerSuccess }) => fetchUserCustomerSuccess
);
export const getFetchUserCustomerFailed = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomerFailed }) => fetchUserCustomerFailed
);

export const getCreateUserCustomerLoading = createSelector(
  [userCustomerSelector],
  ({ createUserCustomerLoading }) => createUserCustomerLoading
);
export const getCreateUserCustomerSuccess = createSelector(
  [userCustomerSelector],
  ({ createUserCustomerSuccess }) => createUserCustomerSuccess
);
export const getCreateUserCustomerFailed = createSelector(
  [userCustomerSelector],
  ({ createUserCustomerFailed }) => createUserCustomerFailed
);

export const getUpdateUserCustomerLoading = createSelector(
  [userCustomerSelector],
  ({ updateUserCustomerLoading }) => updateUserCustomerLoading
);
export const getUpdateUserCustomerSuccess = createSelector(
  [userCustomerSelector],
  ({ updateUserCustomerSuccess }) => updateUserCustomerSuccess
);
export const getUpdateUserCustomerFailed = createSelector(
  [userCustomerSelector],
  ({ updateUserCustomerFailed }) => updateUserCustomerFailed
);

export const getAddMarketUserCustomerLoading = createSelector(
  [userCustomerSelector],
  ({ addMarketUserCustomerLoading }) => addMarketUserCustomerLoading
);
export const getAddMarketUserCustomerSuccess = createSelector(
  [userCustomerSelector],
  ({ addMarketUserCustomerSuccess }) => addMarketUserCustomerSuccess
);
export const getAddMarketUserCustomerFailed = createSelector(
  [userCustomerSelector],
  ({ addMarketUserCustomerFailed }) => addMarketUserCustomerFailed
);

export const getRemoveMarketUserCustomerLoading = createSelector(
  [userCustomerSelector],
  ({ removeMarketUserCustomerLoading }) => removeMarketUserCustomerLoading
);
export const getRemoveMarketUserCustomerSuccess = createSelector(
  [userCustomerSelector],
  ({ removeMarketUserCustomerSuccess }) => removeMarketUserCustomerSuccess
);
export const getRemoveMarketUserCustomerFailed = createSelector(
  [userCustomerSelector],
  ({ removeMarketUserCustomerFailed }) => removeMarketUserCustomerFailed
);

export const getIsFetchUserCustomersHitted = createSelector(
  [userCustomerSelector],
  ({ isFetchUserCustomersHitted }) => isFetchUserCustomersHitted
);
export const getIsFetchSearchUserCustomersHitted = createSelector(
  [userCustomerSelector],
  ({ isFetchSearchUserCustomersHitted }) => isFetchSearchUserCustomersHitted
);
export const getIsFetchUserCustomerHitted = createSelector(
  [userCustomerSelector],
  ({ isFetchUserCustomerHitted }) => isFetchUserCustomerHitted
);
export const getIsCreateUserCustomerHitted = createSelector(
  [userCustomerSelector],
  ({ isCreateUserCustomerHitted }) => isCreateUserCustomerHitted
);
export const getIsUpdateUserCustomerHitted = createSelector(
  [userCustomerSelector],
  ({ isUpdateUserCustomerHitted }) => isUpdateUserCustomerHitted
);
export const getIsAddMarketUserCustomerHitted = createSelector(
  [userCustomerSelector],
  ({ isAddMarketUserCustomerHitted }) => isAddMarketUserCustomerHitted
);
export const getIsRemoveMarketUserCustomerHitted = createSelector(
  [userCustomerSelector],
  ({ isRemoveMarketUserCustomerHitted }) => isRemoveMarketUserCustomerHitted
);
