import PRODUCT_ACTION_TYPES from "./product.type";
import { createAction } from "../../utils/store.utils";

export const setSelectedProducts = (selectedProducts) =>
  createAction(PRODUCT_ACTION_TYPES.SET_SELECTED_PRODUCTS, selectedProducts);
export const setIsSelectProducts = (isSelectProducts) =>
  createAction(PRODUCT_ACTION_TYPES.SET_IS_SELECT_PRODUCTS, isSelectProducts);

export const setEditModifierIndex = (editModifierIndex) =>
  createAction(PRODUCT_ACTION_TYPES.SET_EDIT_MODIFIER_INDEX, editModifierIndex);
export const setEditModifierModel = (editModifierModel) =>
  createAction(PRODUCT_ACTION_TYPES.SET_EDIT_MODIFIER_MODEL, editModifierModel);

export const setProducts = (products) =>
  createAction(PRODUCT_ACTION_TYPES.SET_PRODUCTS, products);
export const setOrderProducts = (orderProducts) =>
  createAction(PRODUCT_ACTION_TYPES.SET_ORDER_PRODUCTS, orderProducts);
export const setProduct = (product) =>
  createAction(PRODUCT_ACTION_TYPES.SET_PRODUCT, product);

export const setIsProductsHasMore = (isProductsHasMore) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_IS_PRODUCTS_HAS_MORE,
    isProductsHasMore
  );
export const setIsOrderProductsHasMore = (isOrderProductsHasMore) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_IS_ORDER_PRODUCTS_HAS_MORE,
    isOrderProductsHasMore
  );

export const setFetchProductsSearch = (fetchProductsSearch) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SEARCH,
    fetchProductsSearch
  );
export const setFetchProductsSort = (fetchProductsSort) =>
  createAction(PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SORT, fetchProductsSort);
export const setFetchProductsKeyBy = (fetchProductsKeyBy) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_KEY_BY,
    fetchProductsKeyBy
  );
export const setFetchProductsPage = (fetchProductsPage) =>
  createAction(PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_PAGE, fetchProductsPage);
export const setFetchProductsPerPage = (fetchProductsPerPage) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_PER_PAGE,
    fetchProductsPerPage
  );
export const setFetchProductsIncludes = (fetchProductsIncludes) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_INCLUDES,
    fetchProductsIncludes
  );
export const setFetchProductsFilterMarketId = (fetchProductsFilterMarketId) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_MARKET_ID,
    fetchProductsFilterMarketId
  );
export const setFetchProductsFilterBranchId = (fetchProductsFilterBranchId) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_BRANCH_ID,
    fetchProductsFilterBranchId
  );
export const setFetchProductsFilterProductCategoryId = (
  fetchProductsFilterProductCategoryId
) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_PRODUCT_CATEGORY_ID,
    fetchProductsFilterProductCategoryId
  );
export const setFetchProductsFilterProductsIds = (
  fetchProductsFilterProductsIds
) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_PRODUCTS_IDS,
    fetchProductsFilterProductsIds
  );
export const setFetchProductsFilterIsActive = (fetchProductsFilterIsActive) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FILTER_IS_ACTIVE,
    fetchProductsFilterIsActive
  );
export const setFetchProductsLoading = (fetchProductsLoading) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_LOADING,
    fetchProductsLoading
  );
export const setFetchProductsSuccess = (fetchProductsSuccess) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SUCCESS,
    fetchProductsSuccess
  );
export const setFetchProductsFailed = (fetchProductsFailed) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FAILED,
    fetchProductsFailed
  );

export const setFetchOrderProductsSearch = (fetchOrderProductsSearch) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_SORT,
    fetchOrderProductsSearch
  );
export const setFetchOrderProductsSort = (fetchOrderProductsSort) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_SORT,
    fetchOrderProductsSort
  );
export const setFetchOrderProductsKeyBy = (fetchOrderProductsKeyBy) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_KEY_BY,
    fetchOrderProductsKeyBy
  );
export const setFetchOrderProductsPage = (fetchOrderProductsPage) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_PAGE,
    fetchOrderProductsPage
  );
export const setFetchOrderProductsPerPage = (fetchOrderProductsPerPage) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_PER_PAGE,
    fetchOrderProductsPerPage
  );
export const setFetchOrderProductsIncludes = (fetchOrderProductsIncludes) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_INCLUDES,
    fetchOrderProductsIncludes
  );
export const setFetchOrderProductsFilterMarketId = (
  fetchOrderProductsFilterMarketId
) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_FILTER_MARKET_ID,
    fetchOrderProductsFilterMarketId
  );
export const setFetchOrderProductsFilterBranchId = (
  fetchOrderProductsFilterBranchId
) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_FILTER_BRANCH_ID,
    fetchOrderProductsFilterBranchId
  );
export const setFetchOrderProductsFilterProductCategoryId = (
  fetchOrderProductsFilterProductCategoryId
) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_FILTER_PRODUCT_CATEGORY_ID,
    fetchOrderProductsFilterProductCategoryId
  );
export const setFetchOrderProductsFilterProductsIds = (
  fetchOrderProductsFilterProductsIds
) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_FILTER_PRODUCTS_IDS,
    fetchOrderProductsFilterProductsIds
  );
export const setFetchOrderProductsFilterIsActive = (
  fetchOrderProductsFilterIsActive
) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_FILTER_IS_ACTIVE,
    fetchOrderProductsFilterIsActive
  );
export const setFetchOrderProductsLoading = (fetchOrderProductsLoading) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_LOADING,
    fetchOrderProductsLoading
  );
export const setFetchOrderProductsSuccess = (fetchOrderProductsSuccess) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_SUCCESS,
    fetchOrderProductsSuccess
  );
export const setFetchOrderProductsFailed = (fetchOrderProductsFailed) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_ORDER_PRODUCTS_FAILED,
    fetchOrderProductsFailed
  );

export const setFetchProductLoading = (fetchProductLoading) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCT_LOADING,
    fetchProductLoading
  );
export const setFetchProductSuccess = (fetchProductSuccess) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCT_SUCCESS,
    fetchProductSuccess
  );
export const setFetchProductFailed = (fetchProductFailed) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCT_FAILED,
    fetchProductFailed
  );

export const setCreateProductLoading = (createProductLoading) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT_LOADING,
    createProductLoading
  );
export const setCreateProductSuccess = (createProductSuccess) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT_SUCCESS,
    createProductSuccess
  );
export const setCreateProductFailed = (createProductFailed) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_CREATE_PRODUCT_FAILED,
    createProductFailed
  );

export const setUpdateProductLoading = (updateProductLoading) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT_LOADING,
    updateProductLoading
  );
export const setUpdateProductSuccess = (updateProductSuccess) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT_SUCCESS,
    updateProductSuccess
  );
export const setUpdateProductFailed = (updateProductFailed) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_UPDATE_PRODUCT_FAILED,
    updateProductFailed
  );

export const setDeleteProductLoading = (deleteProductLoading) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_DELETE_PRODUCT_LOADING,
    deleteProductLoading
  );
export const setDeleteProductSuccess = (deleteProductSuccess) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_DELETE_PRODUCT_SUCCESS,
    deleteProductSuccess
  );
export const setDeleteProductFailed = (deleteProductFailed) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_DELETE_PRODUCT_FAILED,
    deleteProductFailed
  );

export const setMultipleCreateProductsLoading = (
  multipleCreateProductsLoading
) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_MULTIPLE_CREATE_PRODUCTS_LOADING,
    multipleCreateProductsLoading
  );
export const setMultipleCreateProductsSuccess = (
  multipleCreateProductsSuccess
) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_MULTIPLE_CREATE_PRODUCTS_SUCCESS,
    multipleCreateProductsSuccess
  );
export const setMultipleCreateProductsFailed = (multipleCreateProductsFailed) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_MULTIPLE_CREATE_PRODUCTS_FAILED,
    multipleCreateProductsFailed
  );

export const setMultipleDeleteProductsLoading = (
  multipleDeleteProductsLoading
) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_MULTIPLE_DELETE_PRODUCTS_LOADING,
    multipleDeleteProductsLoading
  );
export const setMultipleDeleteProductsSuccess = (
  multipleDeleteProductsSuccess
) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_MULTIPLE_DELETE_PRODUCTS_SUCCESS,
    multipleDeleteProductsSuccess
  );
export const setMultipleDeleteProductsFailed = (multipleDeleteProductsFailed) =>
  createAction(
    PRODUCT_ACTION_TYPES.SET_MULTIPLE_DELETE_PRODUCTS_FAILED,
    multipleDeleteProductsFailed
  );

export const setIsFetchProductsHitted = (isFetchProductsHitted) =>
  createAction(
    PRODUCT_ACTION_TYPES.IS_FETCH_PRODUCTS_HITTED,
    isFetchProductsHitted
  );
export const setIsFetchOrderProductsHitted = (isFetchOrderProductsHitted) =>
  createAction(
    PRODUCT_ACTION_TYPES.IS_FETCH_ORDER_PRODUCTS_HITTED,
    isFetchOrderProductsHitted
  );
export const setIsFetchProductHitted = (isFetchProductHitted) =>
  createAction(
    PRODUCT_ACTION_TYPES.IS_FETCH_PRODUCT_HITTED,
    isFetchProductHitted
  );
export const setIsCreateProductHitted = (isCreateProductHitted) =>
  createAction(
    PRODUCT_ACTION_TYPES.IS_CREATE_PRODUCT_HITTED,
    isCreateProductHitted
  );
export const setIsUpdateProductHitted = (isUpdateProductHitted) =>
  createAction(
    PRODUCT_ACTION_TYPES.IS_UPDATE_PRODUCT_HITTED,
    isUpdateProductHitted
  );
export const setIsDeleteProductHitted = (isDeleteProductHitted) =>
  createAction(
    PRODUCT_ACTION_TYPES.IS_DELETE_PRODUCT_HITTED,
    isDeleteProductHitted
  );
export const setIsMultipleCreateProductsHitted = (
  isMultipleCreateProductsHitted
) =>
  createAction(
    PRODUCT_ACTION_TYPES.IS_MULTIPLE_CREATE_PRODUCTS_HITTED,
    isMultipleCreateProductsHitted
  );
export const setIsMultipleDeleteProductsHitted = (
  isMultipleDeleteProductsHitted
) =>
  createAction(
    PRODUCT_ACTION_TYPES.IS_MULTIPLE_DELETE_PRODUCTS_HITTED,
    isMultipleDeleteProductsHitted
  );

export const appendProducts = (products) =>
  createAction(PRODUCT_ACTION_TYPES.APPEND_PRODUCTS, products);
export const appendOrderProducts = (orderProducts) =>
  createAction(PRODUCT_ACTION_TYPES.APPEND_ORDER_PRODUCTS, orderProducts);

export const fetchProductsStart = () =>
  createAction(PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_START);
export const fetchOrderProductsStart = () =>
  createAction(PRODUCT_ACTION_TYPES.FETCH_ORDER_PRODUCTS_START);
export const fetchProductStart = (productId) =>
  createAction(PRODUCT_ACTION_TYPES.FETCH_PRODUCT_START, productId);
export const createProductStart = (request) =>
  createAction(PRODUCT_ACTION_TYPES.CREATE_PRODUCT_START, request);
export const updateProductStart = (productId, request) =>
  createAction(PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_START, {
    productId,
    request,
  });
export const deleteProductStart = (productId) =>
  createAction(PRODUCT_ACTION_TYPES.DELETE_PRODUCT_START, productId);
export const multipleCreateProductsStart = (request) =>
  createAction(PRODUCT_ACTION_TYPES.MULTIPLE_CREATE_PRODUCTS_START, request);
export const multipleDeleteProductsStart = (request) =>
  createAction(PRODUCT_ACTION_TYPES.MULTIPLE_DELETE_PRODUCTS_START, request);

export const resetProductReducer = () =>
  createAction(PRODUCT_ACTION_TYPES.RESET_PRODUCT_REDUCER);
