import apiService from "./api";

export const getDiscounts = async (parameters) =>
  (await apiService.get("/v2/discounts", parameters)).data;
export const getDiscount = async (discountId) =>
  (await apiService.get(`/v2/discounts/${discountId}`)).data;
export const createDiscount = async (request) =>
  (await apiService.post("/v2/discounts", request)).data;
export const updateDiscount = async (discountId, request) =>
  (await apiService.post(`/v2/discounts/${discountId}`, request)).data;
export const deleteDiscount = async (discountId) =>
  (await apiService.delete(`/v2/discounts/${discountId}`)).data;
export const verifyCouponDiscount = async (request) =>
  (await apiService.post("/v2/discounts/coupon/verify", request)).data;
export const verifyLoyaProDiscount = async (request) =>
  (await apiService.post("/v2/discounts/loyapro/verify", request)).data;
