import { createSelector } from "reselect";

const applicationSelector = ({ application }) => application;

export const getApplications = createSelector(
  [applicationSelector],
  ({ applications }) => applications
);
export const getApplicationExtensions = createSelector(
  [applicationSelector],
  ({ applicationExtensions }) => applicationExtensions
);
export const getApplication = createSelector(
  [applicationSelector],
  ({ application }) => application
);
export const getCreateApplication = createSelector(
  [applicationSelector],
  ({ createApplication }) => createApplication
);
export const getUpdateApplication = createSelector(
  [applicationSelector],
  ({ updateApplication }) => updateApplication
);

export const getIsApplicationsHasMore = createSelector(
  [applicationSelector],
  ({ isApplicationsHasMore }) => isApplicationsHasMore
);
export const getIsApplicationExtensionsHasMore = createSelector(
  [applicationSelector],
  ({ isApplicationExtensionsHasMore }) => isApplicationExtensionsHasMore
);

export const getFetchApplicationsSearch = createSelector(
  [applicationSelector],
  ({ fetchApplicationsSearch }) => fetchApplicationsSearch
);
export const getFetchApplicationsKeyBy = createSelector(
  [applicationSelector],
  ({ fetchApplicationsKeyBy }) => fetchApplicationsKeyBy
);
export const getFetchApplicationsPage = createSelector(
  [applicationSelector],
  ({ fetchApplicationsPage }) => fetchApplicationsPage
);
export const getFetchApplicationsPerPage = createSelector(
  [applicationSelector],
  ({ fetchApplicationsPerPage }) => fetchApplicationsPerPage
);
export const getFetchApplicationsLoading = createSelector(
  [applicationSelector],
  ({ fetchApplicationsLoading }) => fetchApplicationsLoading
);
export const getFetchApplicationsSuccess = createSelector(
  [applicationSelector],
  ({ fetchApplicationsSuccess }) => fetchApplicationsSuccess
);
export const getFetchApplicationsFailed = createSelector(
  [applicationSelector],
  ({ fetchApplicationsFailed }) => fetchApplicationsFailed
);

export const getFetchApplicationExtensionsSearch = createSelector(
  [applicationSelector],
  ({ fetchApplicationExtensionsSearch }) => fetchApplicationExtensionsSearch
);
export const getFetchApplicationExtensionsKeyBy = createSelector(
  [applicationSelector],
  ({ fetchApplicationExtensionsKeyBy }) => fetchApplicationExtensionsKeyBy
);
export const getFetchApplicationExtensionsPage = createSelector(
  [applicationSelector],
  ({ fetchApplicationExtensionsPage }) => fetchApplicationExtensionsPage
);
export const getFetchApplicationExtensionsPerPage = createSelector(
  [applicationSelector],
  ({ fetchApplicationExtensionsPerPage }) => fetchApplicationExtensionsPerPage
);
export const getFetchApplicationExtensionsLoading = createSelector(
  [applicationSelector],
  ({ fetchApplicationExtensionsLoading }) => fetchApplicationExtensionsLoading
);
export const getFetchApplicationExtensionsSuccess = createSelector(
  [applicationSelector],
  ({ fetchApplicationExtensionsSuccess }) => fetchApplicationExtensionsSuccess
);
export const getFetchApplicationExtensionsFailed = createSelector(
  [applicationSelector],
  ({ fetchApplicationExtensionsFailed }) => fetchApplicationExtensionsFailed
);

export const getFetchApplicationLoading = createSelector(
  [applicationSelector],
  ({ fetchApplicationLoading }) => fetchApplicationLoading
);
export const getFetchApplicationSuccess = createSelector(
  [applicationSelector],
  ({ fetchApplicationSuccess }) => fetchApplicationSuccess
);
export const getFetchApplicationFailed = createSelector(
  [applicationSelector],
  ({ fetchApplicationFailed }) => fetchApplicationFailed
);

export const getCreateApplicationLoading = createSelector(
  [applicationSelector],
  ({ createApplicationLoading }) => createApplicationLoading
);
export const getCreateApplicationSuccess = createSelector(
  [applicationSelector],
  ({ createApplicationSuccess }) => createApplicationSuccess
);
export const getCreateApplicationFailed = createSelector(
  [applicationSelector],
  ({ createApplicationFailed }) => createApplicationFailed
);

export const getUpdateApplicationLoading = createSelector(
  [applicationSelector],
  ({ updateApplicationLoading }) => updateApplicationLoading
);
export const getUpdateApplicationSuccess = createSelector(
  [applicationSelector],
  ({ updateApplicationSuccess }) => updateApplicationSuccess
);
export const getUpdateApplicationFailed = createSelector(
  [applicationSelector],
  ({ updateApplicationFailed }) => updateApplicationFailed
);

export const getDeleteApplicationLoading = createSelector(
  [applicationSelector],
  ({ deleteApplicationLoading }) => deleteApplicationLoading
);
export const getDeleteApplicationSuccess = createSelector(
  [applicationSelector],
  ({ deleteApplicationSuccess }) => deleteApplicationSuccess
);
export const getDeleteApplicationFailed = createSelector(
  [applicationSelector],
  ({ deleteApplicationFailed }) => deleteApplicationFailed
);

export const getIsFetchApplicationsHitted = createSelector(
  [applicationSelector],
  ({ isFetchApplicationsHitted }) => isFetchApplicationsHitted
);
export const getIsFetchApplicationExtensionsHitted = createSelector(
  [applicationSelector],
  ({ isFetchApplicationExtensionsHitted }) => isFetchApplicationExtensionsHitted
);
export const getIsFetchApplicationHitted = createSelector(
  [applicationSelector],
  ({ isFetchApplicationHitted }) => isFetchApplicationHitted
);
export const getIsCreateApplicationHitted = createSelector(
  [applicationSelector],
  ({ isCreateApplicationHitted }) => isCreateApplicationHitted
);
export const getIsUpdateApplicationHitted = createSelector(
  [applicationSelector],
  ({ isUpdateApplicationHitted }) => isUpdateApplicationHitted
);
export const getIsDeleteApplicationHitted = createSelector(
  [applicationSelector],
  ({ isDeleteApplicationHitted }) => isDeleteApplicationHitted
);
