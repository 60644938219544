import apiService from "./api";

export const getSuppliers = async (parameters) => (await apiService.get("/supplier", parameters)).data;

export const getSupplier = async (id) => (await apiService.get(`/supplier/${id}`)).data;

export const createSupplier = async (request) => (await apiService.post("/supplier", request)).data;

export const updateSupplier = async (id, request) => (await apiService.post(`/supplier/${id}`, request)).data;

export const deleteSupplier = async (id) => (await apiService.delete(`/supplier/${id}`)).data;
