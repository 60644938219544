import MERCHANT_ACTION_TYPES from "./merchant.type";

export const MERCHANT_INITIAL_STATE = {
  merchants: [],
  selectMerchants: {},
  merchant: null,

  isMerchantsHasMore: true,
  isSelectMerchantsHasMore: true,

  fetchMerchantsSearch: null,
  fetchMerchantsSort: null,
  fetchMerchantsKeyBy: null,
  fetchMerchantsPage: 1,
  fetchMerchantsPerPage: null,
  fetchMerchantsIncludes: null,
  fetchMerchantsFilterMerchantsIds: null,
  fetchMerchantsLoading: false,
  fetchMerchantsSuccess: null,
  fetchMerchantsFailed: null,

  fetchSelectMerchantsSearch: null,
  fetchSelectMerchantsSort: null,
  fetchSelectMerchantsKeyBy: null,
  fetchSelectMerchantsPage: 1,
  fetchSelectMerchantsPerPage: null,
  fetchSelectMerchantsIncludes: null,
  fetchSelectMerchantsFilterMerchantsIds: null,
  fetchSelectMerchantsLoading: false,
  fetchSelectMerchantsSuccess: null,
  fetchSelectMerchantsFailed: null,

  appendSelectMerchantsSearch: null,
  appendSelectMerchantsSort: null,
  appendSelectMerchantsKeyBy: null,
  appendSelectMerchantsPage: 1,
  appendSelectMerchantsPerPage: null,
  appendSelectMerchantsIncludes: null,
  appendSelectMerchantsFilterMerchantsIds: null,
  appendSelectMerchantsLoading: false,
  appendSelectMerchantsSuccess: null,
  appendSelectMerchantsFailed: null,

  fetchMerchantLoading: false,
  fetchMerchantSuccess: null,
  fetchMerchantFailed: null,

  createMerchantLoading: false,
  createMerchantSuccess: null,
  createMerchantFailed: null,

  updateMerchantLoading: false,
  updateMerchantSuccess: null,
  updateMerchantFailed: null,

  deleteMerchantLoading: false,
  deleteMerchantSuccess: null,
  deleteMerchantFailed: null,

  isFetchMerchantsHitted: false,
  isFetchSelectMerchantsHitted: false,
  isAppendSelectMerchantsHitted: false,
  isFetchMerchantHitted: false,
  isCreateMerchantHitted: false,
  isUpdateMerchantHitted: false,
  isDeleteMerchantHitted: false,
};

export const merchantReducer = (state = MERCHANT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case MERCHANT_ACTION_TYPES.SET_MERCHANTS:
      return { ...state, merchants: payload };
    case MERCHANT_ACTION_TYPES.SET_SELECT_MERCHANTS:
      return { ...state, selectMerchants: payload };
    case MERCHANT_ACTION_TYPES.SET_MERCHANT:
      return { ...state, merchant: payload };

    case MERCHANT_ACTION_TYPES.SET_IS_MERCHANTS_HAS_MORE:
      return { ...state, isMerchantsHasMore: payload };
    case MERCHANT_ACTION_TYPES.SET_IS_SELECT_MERCHANTS_HAS_MORE:
      return { ...state, isSelectMerchantsHasMore: payload };

    case MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_SEARCH:
      return { ...state, fetchMerchantsSearch: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_SORT:
      return { ...state, fetchMerchantsSort: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_KEY_BY:
      return { ...state, fetchMerchantsKeyBy: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_PAGE:
      return { ...state, fetchMerchantsPage: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_PER_PAGE:
      return { ...state, fetchMerchantsPerPage: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_INCLUDES:
      return { ...state, fetchMerchantsIncludes: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_FILTER_MERCHANTS_IDS:
      return { ...state, fetchMerchantsFilterMerchantsIds: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_LOADING:
      return { ...state, fetchMerchantsLoading: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_SUCCESS:
      return { ...state, fetchMerchantsSuccess: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANTS_FAILED:
      return { ...state, fetchMerchantsFailed: payload };

    case MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_SEARCH:
      return { ...state, fetchSelectMerchantsSearch: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_SORT:
      return { ...state, fetchSelectMerchantsSort: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_KEY_BY:
      return { ...state, fetchSelectMerchantsKeyBy: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_PAGE:
      return { ...state, fetchSelectMerchantsPage: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_PER_PAGE:
      return { ...state, fetchSelectMerchantsPerPage: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_INCLUDES:
      return { ...state, fetchSelectMerchantsIncludes: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_FILTER_MERCHANTS_IDS:
      return { ...state, fetchSelectMerchantsFilterMerchantsIds: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_LOADING:
      return { ...state, fetchSelectMerchantsLoading: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_SUCCESS:
      return { ...state, fetchSelectMerchantsSuccess: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_SELECT_MERCHANTS_FAILED:
      return { ...state, fetchSelectMerchantsFailed: payload };

    case MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_SEARCH:
      return { ...state, appendSelectMerchantsSearch: payload };
    case MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_SORT:
      return { ...state, appendSelectMerchantsSort: payload };
    case MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_KEY_BY:
      return { ...state, appendSelectMerchantsKeyBy: payload };
    case MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_PAGE:
      return { ...state, appendSelectMerchantsPage: payload };
    case MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_PER_PAGE:
      return { ...state, appendSelectMerchantsPerPage: payload };
    case MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_INCLUDES:
      return { ...state, appendSelectMerchantsIncludes: payload };
    case MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_FILTER_MERCHANTS_IDS:
      return { ...state, appendSelectMerchantsFilterMerchantsIds: payload };
    case MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_LOADING:
      return { ...state, appendSelectMerchantsLoading: payload };
    case MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_SUCCESS:
      return { ...state, appendSelectMerchantsSuccess: payload };
    case MERCHANT_ACTION_TYPES.SET_APPEND_SELECT_MERCHANTS_FAILED:
      return { ...state, appendSelectMerchantsFailed: payload };

    case MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANT_LOADING:
      return { ...state, fetchMerchantLoading: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANT_SUCCESS:
      return { ...state, fetchMerchantSuccess: payload };
    case MERCHANT_ACTION_TYPES.SET_FETCH_MERCHANT_FAILED:
      return { ...state, fetchMerchantFailed: payload };

    case MERCHANT_ACTION_TYPES.SET_CREATE_MERCHANT_LOADING:
      return { ...state, createMerchantLoading: payload };
    case MERCHANT_ACTION_TYPES.SET_CREATE_MERCHANT_SUCCESS:
      return { ...state, createMerchantSuccess: payload };
    case MERCHANT_ACTION_TYPES.SET_CREATE_MERCHANT_FAILED:
      return { ...state, createMerchantFailed: payload };

    case MERCHANT_ACTION_TYPES.SET_UPDATE_MERCHANT_LOADING:
      return { ...state, updateMerchantLoading: payload };
    case MERCHANT_ACTION_TYPES.SET_UPDATE_MERCHANT_SUCCESS:
      return { ...state, updateMerchantSuccess: payload };
    case MERCHANT_ACTION_TYPES.SET_UPDATE_MERCHANT_FAILED:
      return { ...state, updateMerchantFailed: payload };

    case MERCHANT_ACTION_TYPES.SET_DELETE_MERCHANT_LOADING:
      return { ...state, deleteMerchantLoading: payload };
    case MERCHANT_ACTION_TYPES.SET_DELETE_MERCHANT_SUCCESS:
      return { ...state, deleteMerchantSuccess: payload };
    case MERCHANT_ACTION_TYPES.SET_DELETE_MERCHANT_FAILED:
      return { ...state, deleteMerchantFailed: payload };

    case MERCHANT_ACTION_TYPES.APPEND_MERCHANTS:
      return { ...state, merchants: [...state.merchants, ...payload] };
    case MERCHANT_ACTION_TYPES.APPEND_SELECT_MERCHANTS:
      return {
        ...state,
        selectMerchants: { ...state.selectMerchants, ...payload },
      };

    case MERCHANT_ACTION_TYPES.SET_IS_FETCH_MERCHANTS_HITTED:
      return { ...state, isFetchMerchantsHitted: payload };
    case MERCHANT_ACTION_TYPES.SET_IS_FETCH_SELECT_MERCHANTS_HITTED:
      return { ...state, isFetchSelectMerchantsHitted: payload };
    case MERCHANT_ACTION_TYPES.SET_IS_APPEND_SELECT_MERCHANTS_HITTED:
      return { ...state, isAppendSelectMerchantsHitted: payload };
    case MERCHANT_ACTION_TYPES.SET_IS_FETCH_MERCHANT_HITTED:
      return { ...state, isFetchMerchantHitted: payload };
    case MERCHANT_ACTION_TYPES.SET_IS_CREATE_MERCHANT_HITTED:
      return { ...state, isCreateMerchantHitted: payload };
    case MERCHANT_ACTION_TYPES.SET_IS_UPDATE_MERCHANT_HITTED:
      return { ...state, isUpdateMerchantHitted: payload };
    case MERCHANT_ACTION_TYPES.SET_IS_DELETE_MERCHANT_HITTED:
      return { ...state, isDeleteMerchantHitted: payload };

    case MERCHANT_ACTION_TYPES.RESET_MERCHANT_REDUCER:
      return MERCHANT_INITIAL_STATE;
    default:
      return state;
  }
};
