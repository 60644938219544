import apiService from "./api";

export const getSectors = async (parameters) =>
  (await apiService.get("/v2/public/sectors", parameters)).data;
export const getSector = async (sectorId) =>
  (await apiService.get(`/v2/public/sectors/${sectorId}`)).data;
export const createSector = async (request) =>
  (await apiService.post("/v2/sectors", request)).data;
export const updateSector = async (sectorId, request) =>
  (await apiService.post(`/v2/sectors/${sectorId}`, request)).data;
export const deleteSector = async (sectorId) =>
  (await apiService.delete(`/v2/sectors/${sectorId}`)).data;
