import { takeLatest, put, all, call, select } from "redux-saga/effects";

import GLOBAL_ACTION_TYPES from "./global.type";

import { USER_ROLES } from "../../constants/user.constant";

import {
  appendGlobalFeatureSubscriptions,
  appendGlobalSubscriptions,
  setCurrentBranch,
  setCurrentMarket,
  setCurrentMerchant,
  setCurrentPermission,
  setCurrentSchedules,
  setFetchCurrentBranchFailed,
  setFetchCurrentBranchLoading,
  setFetchCurrentBranchSuccess,
  setFetchCurrentMarketFailed,
  setFetchCurrentMarketLoading,
  setFetchCurrentMarketSuccess,
  setFetchCurrentMerchantFailed,
  setFetchCurrentMerchantLoading,
  setFetchCurrentMerchantSuccess,
  setFetchCurrentPermissionFailed,
  setFetchCurrentPermissionLoading,
  setFetchCurrentPermissionSuccess,
  setFetchCurrentSchedulesFailed,
  setFetchCurrentSchedulesLoading,
  setFetchCurrentSchedulesSuccess,
  setFetchGlobalFeatureSubscriptionsFailed,
  setFetchGlobalFeatureSubscriptionsLoading,
  setFetchGlobalFeatureSubscriptionsSuccess,
  setFetchGlobalSubscriptionsFailed,
  setFetchGlobalSubscriptionsLoading,
  setFetchGlobalSubscriptionsSuccess,
  setFetchTodayAttendanceFailed,
  setFetchTodayAttendanceLoading,
  setFetchTodayAttendanceSuccess,
  setGlobalFeatureSubscriptions,
  setGlobalSubscriptions,
  setIsFetchCurrentBranchHitted,
  setIsFetchCurrentMarketHitted,
  setIsFetchCurrentMerchantHitted,
  setIsFetchCurrentPermissionHitted,
  setIsFetchCurrentSchedulesHitted,
  setIsFetchGlobalFeatureSubscriptionsHitted,
  setIsFetchGlobalSubscriptionsHitted,
  setIsFetchTodayAttendanceHitted,
  setIsGlobalFeatureSubscriptionsHasMore,
  setIsGlobalSubscriptionsHasMore,
  setTodayAttendance,
} from "./global.action";
import {
  getFetchCurrentSchedulesFilterBranchId,
  getFetchCurrentSchedulesFilterMarketId,
  getFetchCurrentSchedulesIncludes,
  getFetchGlobalFeatureSubscriptionsFilterIsHighlight,
  getFetchGlobalFeatureSubscriptionsIncludes,
  getFetchGlobalFeatureSubscriptionsKeyBy,
  getFetchGlobalFeatureSubscriptionsPage,
  getFetchGlobalFeatureSubscriptionsPerPage,
  getFetchGlobalFeatureSubscriptionsSearch,
  getFetchGlobalFeatureSubscriptionsSort,
  getFetchGlobalSubscriptionsIncludes,
  getFetchGlobalSubscriptionsKeyBy,
  getFetchGlobalSubscriptionsPage,
  getFetchGlobalSubscriptionsPerPage,
  getFetchGlobalSubscriptionsSearch,
  getFetchGlobalSubscriptionsSort,
  getFetchTodayAttendanceBranchId,
  getFetchTodayAttendanceMarketId,
} from "./global.selector";

import { getSchedules } from "../../api/schedule.api";
import { getAttendance } from "../../api/attendance.api";
import { getMarket } from "../../api/market.api";
import { getBranch } from "../../api/branch.api";
import { getMerchant } from "../../api/merchant.api";
import { getPermission } from "../../api/permission.api";
import { getPermissionAdmin } from "../../api/permission-admin.api";
import { getPermissionMerchant } from "../../api/permission-merchant.api";
import { getPermissionSubscriber } from "../../api/permission-subscriber.api";
import { getSubscriptions } from "../../api/subscription.api";
import { getFeatures } from "../../api/feature-subscription.api";

export function* _getCurrentMarket({ payload: marketId }) {
  try {
    yield put(setFetchCurrentMarketLoading(true));

    const {
      meta: { message },
      data: market,
    } = yield call(getMarket, marketId);

    yield put(setIsFetchCurrentMarketHitted(true));
    yield put(setCurrentMarket(market));

    yield put(setFetchCurrentMarketSuccess(message));
    yield put(setFetchCurrentMarketLoading(false));
  } catch (error) {
    yield put(setFetchCurrentMarketFailed(error));
    yield put(setFetchCurrentMarketLoading(false));
  }
}
export function* _getCurrentBranch({ payload: branchId }) {
  try {
    yield put(setFetchCurrentBranchLoading(true));

    const {
      meta: { message },
      data: branch,
    } = yield call(getBranch, branchId);

    yield put(setIsFetchCurrentBranchHitted(true));
    yield put(setCurrentBranch(branch));

    yield put(setFetchCurrentBranchSuccess(message));
    yield put(setFetchCurrentBranchLoading(false));
  } catch (error) {
    yield put(setFetchCurrentBranchFailed(error));
    yield put(setFetchCurrentBranchLoading(false));
  }
}
export function* _getCurrentMerchant({ payload: merchantId }) {
  try {
    yield put(setFetchCurrentMerchantLoading(true));

    const {
      meta: { message },
      data: merchant,
    } = yield call(getMerchant, merchantId);

    yield put(setIsFetchCurrentMerchantHitted(true));
    yield put(setCurrentMerchant(merchant));

    yield put(setFetchCurrentMerchantSuccess(message));
    yield put(setFetchCurrentMerchantLoading(false));
  } catch (error) {
    yield put(setFetchCurrentMerchantFailed(error));
    yield put(setFetchCurrentMerchantLoading(false));
  }
}
export function* _getCurrentPermission({ payload: { role, permissionId } }) {
  try {
    yield put(setFetchCurrentPermissionLoading(true));

    const {
      meta: { message },
      data: permission,
    } = yield call(
      role === USER_ROLES.USER_ADMIN
        ? getPermissionAdmin
        : role === USER_ROLES.USER_MERCHANT
        ? getPermissionMerchant
        : role === USER_ROLES.USER_SUBSCRIBER
        ? getPermissionSubscriber
        : getPermission,
      permissionId
    );

    yield put(setIsFetchCurrentPermissionHitted(true));
    yield put(setCurrentPermission(permission));

    yield put(setFetchCurrentPermissionSuccess(message));
    yield put(setFetchCurrentPermissionLoading(false));
  } catch (error) {
    yield put(setFetchCurrentPermissionFailed(error));
    yield put(setFetchCurrentPermissionLoading(false));
  }
}
export function* _getCurrentSchedules() {
  try {
    yield put(setFetchCurrentSchedulesLoading(true));

    const includes = yield select(getFetchCurrentSchedulesIncludes);
    const market_id = yield select(getFetchCurrentSchedulesFilterMarketId);
    const branch_id = yield select(getFetchCurrentSchedulesFilterBranchId);

    const parameters = {
      includes,
      filter: { market_id, branch_id },
    };

    const {
      meta: { message },
      data: schedules,
    } = yield call(getSchedules, parameters);

    yield put(setIsFetchCurrentSchedulesHitted(true));
    yield put(setCurrentSchedules(schedules));

    yield put(setFetchCurrentSchedulesSuccess(message));
    yield put(setFetchCurrentSchedulesLoading(false));
  } catch (error) {
    yield put(setFetchCurrentSchedulesFailed(error));
    yield put(setFetchCurrentSchedulesLoading(false));
  }
}
export function* _getTodayAttendance({ payload: userId }) {
  try {
    yield put(setFetchTodayAttendanceLoading(true));

    const market_id = yield select(getFetchTodayAttendanceMarketId);
    const branch_id = yield select(getFetchTodayAttendanceBranchId);

    const parameters = { market_id, branch_id };

    const {
      meta: { message },
      data: attendance,
    } = yield call(getAttendance, userId, parameters);

    yield put(setIsFetchTodayAttendanceHitted(true));
    yield put(setTodayAttendance(attendance));

    yield put(setFetchTodayAttendanceSuccess(message));
    yield put(setFetchTodayAttendanceLoading(false));
  } catch (error) {
    yield put(setFetchTodayAttendanceFailed(error));
    yield put(setFetchTodayAttendanceLoading(false));
  }
}
export function* _getGlobalSubscriptions() {
  try {
    yield put(setFetchGlobalSubscriptionsLoading(true));

    const search = yield select(getFetchGlobalSubscriptionsSearch);
    const sort = yield select(getFetchGlobalSubscriptionsSort);
    const key_by = yield select(getFetchGlobalSubscriptionsKeyBy);
    const page = yield select(getFetchGlobalSubscriptionsPage);
    const per_page = yield select(getFetchGlobalSubscriptionsPerPage);
    const includes = yield select(getFetchGlobalSubscriptionsIncludes);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
    };

    const {
      meta: { message },
      data: subscriptions,
    } = yield call(getSubscriptions, parameters);

    yield put(setIsFetchGlobalSubscriptionsHitted(true));
    yield put(
      setIsGlobalSubscriptionsHasMore(Object.keys(subscriptions).length > 0)
    );

    if (page > 1) {
      yield put(appendGlobalSubscriptions(subscriptions));
    } else {
      yield put(setGlobalSubscriptions(subscriptions));
    }

    yield put(setFetchGlobalSubscriptionsSuccess(message));
    yield put(setFetchGlobalSubscriptionsLoading(false));
  } catch (error) {
    yield put(setFetchGlobalSubscriptionsFailed(error));
    yield put(setFetchGlobalSubscriptionsLoading(false));
  }
}
export function* _getGlobalFeatureSubscriptions() {
  try {
    yield put(setFetchGlobalFeatureSubscriptionsLoading(true));

    const search = yield select(getFetchGlobalFeatureSubscriptionsSearch);
    const sort = yield select(getFetchGlobalFeatureSubscriptionsSort);
    const key_by = yield select(getFetchGlobalFeatureSubscriptionsKeyBy);
    const page = yield select(getFetchGlobalFeatureSubscriptionsPage);
    const per_page = yield select(getFetchGlobalFeatureSubscriptionsPerPage);
    const includes = yield select(getFetchGlobalFeatureSubscriptionsIncludes);
    const is_highlight = yield select(
      getFetchGlobalFeatureSubscriptionsFilterIsHighlight
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { is_highlight },
    };

    const {
      meta: { message },
      data: { data: features },
    } = yield call(getFeatures, parameters);

    yield put(setIsFetchGlobalFeatureSubscriptionsHitted(true));
    yield put(setIsGlobalFeatureSubscriptionsHasMore(features.length > 0));

    if (page > 1) {
      yield put(appendGlobalFeatureSubscriptions(features));
    } else {
      yield put(setGlobalFeatureSubscriptions(features));
    }

    yield put(setFetchGlobalFeatureSubscriptionsSuccess(message));
    yield put(setFetchGlobalFeatureSubscriptionsLoading(false));
  } catch (error) {
    yield put(setFetchGlobalFeatureSubscriptionsFailed(error));
    yield put(setFetchGlobalFeatureSubscriptionsLoading(false));
  }
}

export function* onFetchCurrentMarketStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_MARKET_START,
    _getCurrentMarket
  );
}
export function* onFetchCurrentBranchStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_BRANCH_START,
    _getCurrentBranch
  );
}
export function* onFetchCurrentMerchantStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_MERCHANT_START,
    _getCurrentMerchant
  );
}
export function* onFetchCurrentPermissionStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_PERMISSION_START,
    _getCurrentPermission
  );
}
export function* onFetchCurrentSchedulesStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_SCHEDULES_START,
    _getCurrentSchedules
  );
}
export function* onFetchTodayAttendanceStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_TODAY_ATTENDANCE_START,
    _getTodayAttendance
  );
}
export function* onFetchGlobalSubscriptionsStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_GLOBAL_SUBSCRIPTIONS_START,
    _getGlobalSubscriptions
  );
}
export function* onFetchGlobalFeatureSubscriptionsStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_START,
    _getGlobalFeatureSubscriptions
  );
}

export function* globalSaga() {
  yield all([
    call(onFetchCurrentMarketStart),
    call(onFetchCurrentBranchStart),
    call(onFetchCurrentMerchantStart),
    call(onFetchCurrentPermissionStart),
    call(onFetchCurrentSchedulesStart),
    call(onFetchTodayAttendanceStart),
    call(onFetchGlobalSubscriptionsStart),
    call(onFetchGlobalFeatureSubscriptionsStart),
  ]);
}
