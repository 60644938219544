import { createSelector } from "reselect";

const kitchenSelector = ({ kitchen }) => kitchen;

export const getFirstProcessOrders = createSelector([kitchenSelector], ({ firstProcessOrders }) => firstProcessOrders);
export const getSecondProcessOrders = createSelector(
  [kitchenSelector],
  ({ secondProcessOrders }) => secondProcessOrders
);
export const getThirdProcessOrders = createSelector([kitchenSelector], ({ thirdProcessOrders }) => thirdProcessOrders);

export const getProcessOrders = createSelector([kitchenSelector], ({ processOrders }) => processOrders);
export const getCompletedOrders = createSelector([kitchenSelector], ({ completedOrders }) => completedOrders);

export const getIsProcessOrdersHasMore = createSelector(
  [kitchenSelector],
  ({ isProcessOrdersHasMore }) => isProcessOrdersHasMore
);
export const getIsCompletedOrdersHasMore = createSelector(
  [kitchenSelector],
  ({ isCompletedOrdersHasMore }) => isCompletedOrdersHasMore
);

export const getFetchProcessOrdersSearch = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersSearch }) => fetchProcessOrdersSearch
);
export const getFetchProcessOrdersSort = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersSort }) => fetchProcessOrdersSort
);
export const getFetchProcessOrdersPage = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersPage }) => fetchProcessOrdersPage
);
export const getFetchProcessOrdersPerPage = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersPerPage }) => fetchProcessOrdersPerPage
);
export const getFetchProcessOrdersIncludes = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersIncludes }) => fetchProcessOrdersIncludes
);
export const getFetchProcessOrdersFilterPeriodType = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersFilterPeriodType }) => fetchProcessOrdersFilterPeriodType
);
export const getFetchProcessOrdersFilterStartAt = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersFilterStartAt }) => fetchProcessOrdersFilterStartAt
);
export const getFetchProcessOrdersFilterEndAt = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersFilterEndAt }) => fetchProcessOrdersFilterEndAt
);
export const getFetchProcessOrdersFilterMarketId = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersFilterMarketId }) => fetchProcessOrdersFilterMarketId
);
export const getFetchProcessOrdersFilterBranchId = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersFilterBranchId }) => fetchProcessOrdersFilterBranchId
);
export const getFetchProcessOrdersFilterProductCategoriesIds = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersFilterProductCategoriesIds }) => fetchProcessOrdersFilterProductCategoriesIds
);
export const getFetchProcessOrdersFilterSources = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersFilterSources }) => fetchProcessOrdersFilterSources
);
export const getFetchProcessOrdersFilterTypes = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersFilterTypes }) => fetchProcessOrdersFilterTypes
);
export const getFetchProcessOrdersFilterTableId = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersFilterTableId }) => fetchProcessOrdersFilterTableId
);
export const getFetchProcessOrdersFilterCashierId = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersFilterCashierId }) => fetchProcessOrdersFilterCashierId
);
export const getFetchProcessOrdersLoading = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersLoading }) => fetchProcessOrdersLoading
);
export const getFetchProcessOrdersSuccess = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersSuccess }) => fetchProcessOrdersSuccess
);
export const getFetchProcessOrdersFailed = createSelector(
  [kitchenSelector],
  ({ fetchProcessOrdersFailed }) => fetchProcessOrdersFailed
);

export const getFetchCompletedOrdersSearch = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersSearch }) => fetchCompletedOrdersSearch
);
export const getFetchCompletedOrdersSort = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersSort }) => fetchCompletedOrdersSort
);
export const getFetchCompletedOrdersPage = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersPage }) => fetchCompletedOrdersPage
);
export const getFetchCompletedOrdersPerPage = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersPerPage }) => fetchCompletedOrdersPerPage
);
export const getFetchCompletedOrdersIncludes = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersIncludes }) => fetchCompletedOrdersIncludes
);
export const getFetchCompletedOrdersFilterPeriodType = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersFilterPeriodType }) => fetchCompletedOrdersFilterPeriodType
);
export const getFetchCompletedOrdersFilterStartAt = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersFilterStartAt }) => fetchCompletedOrdersFilterStartAt
);
export const getFetchCompletedOrdersFilterEndAt = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersFilterEndAt }) => fetchCompletedOrdersFilterEndAt
);
export const getFetchCompletedOrdersFilterMarketId = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersFilterMarketId }) => fetchCompletedOrdersFilterMarketId
);
export const getFetchCompletedOrdersFilterBranchId = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersFilterBranchId }) => fetchCompletedOrdersFilterBranchId
);
export const getFetchCompletedOrdersFilterProductCategoriesIds = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersFilterProductCategoriesIds }) => fetchCompletedOrdersFilterProductCategoriesIds
);
export const getFetchCompletedOrdersFilterSources = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersFilterSources }) => fetchCompletedOrdersFilterSources
);
export const getFetchCompletedOrdersFilterTypes = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersFilterTypes }) => fetchCompletedOrdersFilterTypes
);
export const getFetchCompletedOrdersFilterTableId = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersFilterTableId }) => fetchCompletedOrdersFilterTableId
);
export const getFetchCompletedOrdersFilterCashierId = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersFilterCashierId }) => fetchCompletedOrdersFilterCashierId
);
export const getFetchCompletedOrdersLoading = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersLoading }) => fetchCompletedOrdersLoading
);
export const getFetchCompletedOrdersSuccess = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersSuccess }) => fetchCompletedOrdersSuccess
);
export const getFetchCompletedOrdersFailed = createSelector(
  [kitchenSelector],
  ({ fetchCompletedOrdersFailed }) => fetchCompletedOrdersFailed
);

export const getIsFetchProcessOrdersHitted = createSelector(
  [kitchenSelector],
  ({ isFetchProcessOrdersHitted }) => isFetchProcessOrdersHitted
);
export const getIsFetchCompletedOrdersHitted = createSelector(
  [kitchenSelector],
  ({ isFetchCompletedOrdersHitted }) => isFetchCompletedOrdersHitted
);
