import apiService from "./api";

export const getPermissionSubscribers = async (parameters) =>
  (await apiService.get("/v2/permissions/subscribers", parameters)).data;
export const getPermissionSubscriber = async (permissionId) =>
  (await apiService.get(`/v2/permissions/subscribers/${permissionId}`)).data;
export const createPermissionSubscriber = async (request) =>
  (await apiService.post("/v2/permissions/subscribers", request)).data;
export const updatePermissionSubscriber = async (permissionId, request) =>
  (
    await apiService.post(
      `/v2/permissions/subscribers/${permissionId}`,
      request
    )
  ).data;
export const deletePermissionSubscriber = async (permissionId) =>
  (await apiService.delete(`/v2/permissions/subscribers/${permissionId}`)).data;
