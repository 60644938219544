import apiService from "./api";

export const getPermissions = async (parameters) => (await apiService.get("/permission", parameters)).data;

export const getPermission = async (id) => (await apiService.get(`/permission/${id}`)).data;

export const createPermission = async (request) => (await apiService.post("/permission", request)).data;

export const updatePermission = async (id, request) => (await apiService.post(`/permission/${id}`, request)).data;

export const deletePermission = async (id) => (await apiService.delete(`/permission/${id}`)).data;
