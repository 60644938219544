import { takeLatest, put, all, call, select } from "redux-saga/effects";

import DASHBOARD_MERCHANT_ACTION_TYPES from "./dashboard-merchant.type";

import {
  setFetchDashboardMerchantFailed,
  setFetchDashboardMerchantLoading,
  setFetchDashboardMerchantSuccess,
  setIsFetchDashboardMerchantHitted,
} from "./dashboard-merchant.action";
import {
  getFetchDashboardMerchantFilterCreatedAtAfter,
  getFetchDashboardMerchantFilterCreatedAtBefore,
  getFetchDashboardMerchantFilterCurrency,
} from "./dashboard-merchant.selector";

import { getDashboardMerchant } from "../../api/dashboard.api";

export function* _getDashboardMerchant() {
  try {
    yield put(setFetchDashboardMerchantLoading(true));

    const created_at_before = yield select(
      getFetchDashboardMerchantFilterCreatedAtBefore
    );
    const created_at_after = yield select(
      getFetchDashboardMerchantFilterCreatedAtAfter
    );
    const currency = yield select(getFetchDashboardMerchantFilterCurrency);

    const parameters = {
      filter: {
        created_at_before,
        created_at_after,
        currency,
      },
    };

    const {
      meta: { message },
    } = yield call(getDashboardMerchant, parameters);

    yield put(setIsFetchDashboardMerchantHitted(true));

    yield put(setFetchDashboardMerchantSuccess(message));
    yield put(setFetchDashboardMerchantLoading(false));
  } catch (error) {
    yield put(setFetchDashboardMerchantFailed(error));
    yield put(setFetchDashboardMerchantLoading(false));
  }
}

export function* onFetchDashboardMerchantStart() {
  yield takeLatest(
    DASHBOARD_MERCHANT_ACTION_TYPES.FETCH_DASHBOARD_MERCHANT_START,
    _getDashboardMerchant
  );
}

export function* dashboardMerchantSaga() {
  yield all([call(onFetchDashboardMerchantStart)]);
}
