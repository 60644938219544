import PAYMENT_DEVICE_ACTION_TYPES from "./payment-device.type";
import { createAction } from "../../utils/store.utils";

export const setPaymentDevices = (paymentDevices) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_PAYMENT_DEVICES, paymentDevices);

export const setPaymentDevice = (paymentDevice) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_PAYMENT_DEVICE, paymentDevice);

export const setIsPaymentDevicesHasMore = (isPaymentDevicesHasMore) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_IS_PAYMENT_DEVICES_HAS_MORE, isPaymentDevicesHasMore);

export const setFetchPaymentDevicesSearch = (fetchPaymentDevicesSearch) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_SEARCH, fetchPaymentDevicesSearch);

export const setFetchPaymentDevicesPage = (fetchPaymentDevicesPage) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_PAGE, fetchPaymentDevicesPage);

export const setFetchPaymentDevicesPerPage = (fetchPaymentDevicesPerPage) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_PER_PAGE, fetchPaymentDevicesPerPage);

export const setFetchPaymentDevicesIncludes = (fetchPaymentDevicesIncludes) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_INCLUDES, fetchPaymentDevicesIncludes);

export const setFetchPaymentDevicesFilterMarketId = (fetchPaymentDevicesFilterMarketId) =>
  createAction(
    PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_FILTER_MARKET_ID,
    fetchPaymentDevicesFilterMarketId
  );

export const setFetchPaymentDevicesFilterBranchId = (fetchPaymentDevicesFilterBranchId) =>
  createAction(
    PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_FILTER_BRANCH_ID,
    fetchPaymentDevicesFilterBranchId
  );

export const setFetchPaymentDevicesFilterPaymentDevicesIds = (fetchPaymentDevicesFilterPaymentDevicesIds) =>
  createAction(
    PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_FILTER_PAYMENT_DEVICES_IDS,
    fetchPaymentDevicesFilterPaymentDevicesIds
  );

export const setFetchPaymentDevicesFilterUsersIds = (fetchPaymentDevicesFilterUsersIds) =>
  createAction(
    PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_FILTER_USERS_IDS,
    fetchPaymentDevicesFilterUsersIds
  );

export const setFetchPaymentDevicesLoading = (fetchPaymentDevicesLoading) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_LOADING, fetchPaymentDevicesLoading);

export const setFetchPaymentDevicesSuccess = (fetchPaymentDevicesSuccess) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_SUCCESS, fetchPaymentDevicesSuccess);

export const setFetchPaymentDevicesFailed = (fetchPaymentDevicesFailed) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICES_FAILED, fetchPaymentDevicesFailed);

export const setFetchPaymentDeviceLoading = (fetchPaymentDeviceLoading) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICE_LOADING, fetchPaymentDeviceLoading);

export const setFetchPaymentDeviceSuccess = (fetchPaymentDeviceSuccess) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICE_SUCCESS, fetchPaymentDeviceSuccess);

export const setFetchPaymentDeviceFailed = (fetchPaymentDeviceFailed) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_FETCH_PAYMENT_DEVICE_FAILED, fetchPaymentDeviceFailed);

export const setCreatePaymentDeviceLoading = (createPaymentDeviceLoading) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_CREATE_PAYMENT_DEVICE_LOADING, createPaymentDeviceLoading);

export const setCreatePaymentDeviceSuccess = (createPaymentDeviceSuccess) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_CREATE_PAYMENT_DEVICE_SUCCESS, createPaymentDeviceSuccess);

export const setCreatePaymentDeviceFailed = (createPaymentDeviceFailed) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_CREATE_PAYMENT_DEVICE_FAILED, createPaymentDeviceFailed);

export const setUpdatePaymentDeviceLoading = (updatePaymentDeviceLoading) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_UPDATE_PAYMENT_DEVICE_LOADING, updatePaymentDeviceLoading);

export const setUpdatePaymentDeviceSuccess = (updatePaymentDeviceSuccess) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_UPDATE_PAYMENT_DEVICE_SUCCESS, updatePaymentDeviceSuccess);

export const setUpdatePaymentDeviceFailed = (updatePaymentDeviceFailed) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_UPDATE_PAYMENT_DEVICE_FAILED, updatePaymentDeviceFailed);

export const setDeletePaymentDeviceLoading = (deletePaymentDeviceLoading) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_DELETE_PAYMENT_DEVICE_LOADING, deletePaymentDeviceLoading);

export const setDeletePaymentDeviceSuccess = (deletePaymentDeviceSuccess) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_DELETE_PAYMENT_DEVICE_SUCCESS, deletePaymentDeviceSuccess);

export const setDeletePaymentDeviceFailed = (deletePaymentDeviceFailed) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_DELETE_PAYMENT_DEVICE_FAILED, deletePaymentDeviceFailed);

export const appendPaymentDevices = (paymentDevices) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.APPEND_PAYMENT_DEVICES, paymentDevices);

export const fetchPaymentDevicesStart = () => createAction(PAYMENT_DEVICE_ACTION_TYPES.FETCH_PAYMENT_DEVICES_START);

export const fetchPaymentDeviceStart = (id) => createAction(PAYMENT_DEVICE_ACTION_TYPES.FETCH_PAYMENT_DEVICE_START, id);

export const createPaymentDeviceStart = (request) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.CREATE_PAYMENT_DEVICE_START, request);

export const updatePaymentDeviceStart = (id, request) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.UPDATE_PAYMENT_DEVICE_START, { id, request });

export const deletePaymentDeviceStart = (id) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.DELETE_PAYMENT_DEVICE_START, id);

export const setIsFetchPaymentDevicesHitted = (isFetchPaymentDevicesHitted) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_IS_FETCH_PAYMENT_DEVICES_HITTED, isFetchPaymentDevicesHitted);

export const setIsFetchPaymentDeviceHitted = (isFetchPaymentDeviceHitted) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_IS_FETCH_PAYMENT_DEVICE_HITTED, isFetchPaymentDeviceHitted);

export const setIsCreatePaymentDeviceHitted = (isCreatePaymentDeviceHitted) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_IS_CREATE_PAYMENT_DEVICE_HITTED, isCreatePaymentDeviceHitted);

export const setIsUpdatePaymentDeviceHitted = (isUpdatePaymentDeviceHitted) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_IS_UPDATE_PAYMENT_DEVICE_HITTED, isUpdatePaymentDeviceHitted);

export const setIsDeletePaymentDeviceHitted = (isDeletePaymentDeviceHitted) =>
  createAction(PAYMENT_DEVICE_ACTION_TYPES.SET_IS_DELETE_PAYMENT_DEVICE_HITTED, isDeletePaymentDeviceHitted);

export const resetPaymentDeviceReducer = () => createAction(PAYMENT_DEVICE_ACTION_TYPES.RESET_PAYMENT_DEVICE_REDUCER);
