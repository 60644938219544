import { takeLatest, put, all, call, select } from "redux-saga/effects";

import INGREDIENT_ACTION_TYPES from "./ingredient.type";

import {
  appendSelectIngredients,
  appendIngredients,
  setCreateIngredientFailed,
  setCreateIngredientLoading,
  setCreateIngredientSuccess,
  setDeleteIngredientFailed,
  setDeleteIngredientLoading,
  setDeleteIngredientSuccess,
  setFetchSelectIngredientsFailed,
  setFetchSelectIngredientsLoading,
  setFetchSelectIngredientsPage,
  setFetchSelectIngredientsSuccess,
  setFetchIngredientFailed,
  setFetchIngredientLoading,
  setFetchIngredientsFailed,
  setFetchIngredientsLoading,
  setFetchIngredientsPage,
  setFetchIngredientsSuccess,
  setFetchIngredientSuccess,
  setIsSelectIngredientsHasMore,
  setIsIngredientsHasMore,
  setIngredient,
  setIngredients,
  setUpdateIngredientFailed,
  setUpdateIngredientLoading,
  setUpdateIngredientSuccess,
  setStoreStockIngredientLoading,
  setStoreStockIngredientFailed,
  setStoreStockIngredientSuccess,
  setReduceStockIngredientLoading,
  setReduceStockIngredientFailed,
  setReduceStockIngredientSuccess,
  setFetchOutOfStockIngredientsLoading,
  setIsOutOfStockIngredientsHasMore,
  appendOutOfStockIngredients,
  setOutOfStockIngredients,
  setFetchOutOfStockIngredientsSuccess,
  setFetchOutOfStockIngredientsFailed,
  setIsFetchIngredientsHitted,
  setIsFetchOutOfStockIngredientsHitted,
  setIsFetchSelectIngredientsHitted,
  setIsFetchIngredientHitted,
  setIsCreateIngredientHitted,
  setIsUpdateIngredientHitted,
  setIsDeleteIngredientHitted,
  setIsStoreStockIngredientHitted,
  setIsReduceStockIngredientHitted,
  setIsAppendSelectIngredientsHitted,
  setAppendSelectIngredientsSuccess,
  setAppendSelectIngredientsLoading,
  setAppendSelectIngredientsFailed,
  setFetchSelectGroupIngredientsLoading,
  setIsFetchSelectGroupIngredientsHitted,
  setIsSelectGroupIngredientsHasMore,
  appendSelectGroupIngredients,
  setFetchSelectGroupIngredientsSuccess,
  setFetchSelectGroupIngredientsFailed,
  setIsAppendSelectGroupIngredientsHitted,
  setAppendSelectGroupIngredientsSuccess,
  setAppendSelectGroupIngredientsLoading,
  setAppendSelectGroupIngredientsFailed,
  setSelectGroupIngredients,
  setSelectIngredients,
} from "./ingredient.action";
import {
  getFetchSelectIngredientsPage,
  getFetchSelectIngredientsPerPage,
  getFetchIngredientsPage,
  getFetchIngredientsPerPage,
  getFetchIngredientsSearch,
  getFetchIngredientsIncludes,
  getFetchSelectIngredientsIncludes,
  getFetchIngredientsFilterMarketId,
  getFetchIngredientsFilterBranchId,
  getFetchSelectIngredientsFilterMarketId,
  getFetchSelectIngredientsFilterBranchId,
  getFetchSelectIngredientsSearch,
  getFetchSelectIngredientsFilterIngredientsIds,
  getFetchOutOfStockIngredientsSearch,
  getFetchOutOfStockIngredientsPage,
  getFetchOutOfStockIngredientsPerPage,
  getFetchOutOfStockIngredientsIncludes,
  getFetchOutOfStockIngredientsFilterMarketId,
  getFetchOutOfStockIngredientsFilterBranchId,
  getFetchIngredientsFilterIsOutOfStock,
  getIsFetchIngredientsHitted,
  getIsFetchOutOfStockIngredientsHitted,
  getIsFetchSelectIngredientsHitted,
  getFetchIngredientsSort,
  getFetchIngredientsKeyBy,
  getFetchSelectIngredientsSort,
  getFetchSelectIngredientsKeyBy,
  getFetchOutOfStockIngredientsSort,
  getFetchOutOfStockIngredientsKeyBy,
  getAppendSelectIngredientsSearch,
  getAppendSelectIngredientsSort,
  getAppendSelectIngredientsKeyBy,
  getAppendSelectIngredientsPage,
  getAppendSelectIngredientsPerPage,
  getAppendSelectIngredientsIncludes,
  getAppendSelectIngredientsFilterMarketId,
  getAppendSelectIngredientsFilterBranchId,
  getAppendSelectIngredientsFilterIngredientsIds,
  getFetchSelectIngredientsFilterIsGroup,
  getAppendSelectIngredientsFilterIsGroup,
  getFetchSelectGroupIngredientsSearch,
  getFetchSelectGroupIngredientsSort,
  getFetchSelectGroupIngredientsKeyBy,
  getFetchSelectGroupIngredientsPage,
  getFetchSelectGroupIngredientsPerPage,
  getFetchSelectGroupIngredientsIncludes,
  getFetchSelectGroupIngredientsFilterMarketId,
  getFetchSelectGroupIngredientsFilterBranchId,
  getFetchSelectGroupIngredientsFilterIngredientsIds,
  getFetchSelectGroupIngredientsFilterIsGroup,
  getAppendSelectGroupIngredientsSearch,
  getAppendSelectGroupIngredientsSort,
  getAppendSelectGroupIngredientsKeyBy,
  getAppendSelectGroupIngredientsPage,
  getAppendSelectGroupIngredientsPerPage,
  getAppendSelectGroupIngredientsIncludes,
  getAppendSelectGroupIngredientsFilterMarketId,
  getAppendSelectGroupIngredientsFilterBranchId,
  getAppendSelectGroupIngredientsFilterIngredientsIds,
  getAppendSelectGroupIngredientsFilterIsGroup,
} from "./ingredient.selector";

import {
  getIngredients,
  getIngredient,
  createIngredient,
  updateIngredient,
  deleteIngredient,
  storeStockIngredient,
  reduceStockIngredient,
} from "../../api/ingredient.api";

export function* _getIngredients() {
  try {
    yield put(setFetchIngredientsLoading(true));

    const search = yield select(getFetchIngredientsSearch);
    const sort = yield select(getFetchIngredientsSort);
    const key_by = yield select(getFetchIngredientsKeyBy);
    const page = yield select(getFetchIngredientsPage);
    const per_page = yield select(getFetchIngredientsPerPage);
    const includes = yield select(getFetchIngredientsIncludes);
    const market_id = yield select(getFetchIngredientsFilterMarketId);
    const branch_id = yield select(getFetchIngredientsFilterBranchId);
    const is_out_of_stock = yield select(getFetchIngredientsFilterIsOutOfStock);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id, branch_id, is_out_of_stock },
    };

    const {
      meta: { message },
      data: { data: ingredients },
    } = yield call(getIngredients, parameters);

    yield put(setIsFetchIngredientsHitted(true));
    yield put(setIsIngredientsHasMore(ingredients.length > 0));

    if (page > 1) {
      yield put(appendIngredients(ingredients));
    } else {
      yield put(setIngredients(ingredients));
    }

    yield put(setFetchIngredientsSuccess(message));
    yield put(setFetchIngredientsLoading(false));
  } catch (error) {
    yield put(setFetchIngredientsFailed(error));
    yield put(setFetchIngredientsLoading(false));
  }
}
export function* _getSelectIngredients() {
  try {
    yield put(setFetchSelectIngredientsLoading(true));

    const search = yield select(getFetchSelectIngredientsSearch);
    const sort = yield select(getFetchSelectIngredientsSort);
    const key_by = yield select(getFetchSelectIngredientsKeyBy);
    const page = yield select(getFetchSelectIngredientsPage);
    const per_page = yield select(getFetchSelectIngredientsPerPage);
    const includes = yield select(getFetchSelectIngredientsIncludes);
    const market_id = yield select(getFetchSelectIngredientsFilterMarketId);
    const branch_id = yield select(getFetchSelectIngredientsFilterBranchId);
    const ingredients_ids = yield select(
      getFetchSelectIngredientsFilterIngredientsIds
    );
    const is_group = yield select(getFetchSelectIngredientsFilterIsGroup);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        ingredients_ids,
        is_group,
      },
    };

    const {
      meta: { message },
      data: ingredients,
    } = yield call(getIngredients, parameters);

    yield put(setIsFetchSelectIngredientsHitted(true));
    yield put(
      setIsSelectIngredientsHasMore(Object.keys(ingredients).length > 0)
    );

    if (page > 1) {
      yield put(appendSelectIngredients(ingredients));
    } else {
      yield put(setSelectIngredients(ingredients));
    }

    yield put(setFetchSelectIngredientsSuccess(message));
    yield put(setFetchSelectIngredientsLoading(false));
  } catch (error) {
    yield put(setFetchSelectIngredientsFailed(error));
    yield put(setFetchSelectIngredientsLoading(false));
  }
}
export function* _getSelectGroupIngredients() {
  try {
    yield put(setFetchSelectGroupIngredientsLoading(true));

    const search = yield select(getFetchSelectGroupIngredientsSearch);
    const sort = yield select(getFetchSelectGroupIngredientsSort);
    const key_by = yield select(getFetchSelectGroupIngredientsKeyBy);
    const page = yield select(getFetchSelectGroupIngredientsPage);
    const per_page = yield select(getFetchSelectGroupIngredientsPerPage);
    const includes = yield select(getFetchSelectGroupIngredientsIncludes);
    const market_id = yield select(
      getFetchSelectGroupIngredientsFilterMarketId
    );
    const branch_id = yield select(
      getFetchSelectGroupIngredientsFilterBranchId
    );
    const ingredients_ids = yield select(
      getFetchSelectGroupIngredientsFilterIngredientsIds
    );
    const is_group = yield select(getFetchSelectGroupIngredientsFilterIsGroup);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        ingredients_ids,
        is_group,
      },
    };

    const {
      meta: { message },
      data: ingredients,
    } = yield call(getIngredients, parameters);

    yield put(setIsFetchSelectGroupIngredientsHitted(true));
    yield put(
      setIsSelectGroupIngredientsHasMore(Object.keys(ingredients).length > 0)
    );

    if (page > 1) {
      yield put(appendSelectGroupIngredients(ingredients));
    } else {
      yield put(setSelectGroupIngredients(ingredients));
    }

    yield put(setFetchSelectGroupIngredientsSuccess(message));
    yield put(setFetchSelectGroupIngredientsLoading(false));
  } catch (error) {
    yield put(setFetchSelectGroupIngredientsFailed(error));
    yield put(setFetchSelectGroupIngredientsLoading(false));
  }
}
export function* _getOutOfStockIngredients() {
  try {
    yield put(setFetchOutOfStockIngredientsLoading(true));

    const search = yield select(getFetchOutOfStockIngredientsSearch);
    const sort = yield select(getFetchOutOfStockIngredientsSort);
    const key_by = yield select(getFetchOutOfStockIngredientsKeyBy);
    const page = yield select(getFetchOutOfStockIngredientsPage);
    const per_page = yield select(getFetchOutOfStockIngredientsPerPage);
    const includes = yield select(getFetchOutOfStockIngredientsIncludes);
    const market_id = yield select(getFetchOutOfStockIngredientsFilterMarketId);
    const branch_id = yield select(getFetchOutOfStockIngredientsFilterBranchId);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id, branch_id, is_out_of_stock: 1 },
    };

    const {
      meta: { message },
      data: { data: ingredients },
    } = yield call(getIngredients, parameters);

    yield put(setIsFetchOutOfStockIngredientsHitted(true));
    yield put(setIsOutOfStockIngredientsHasMore(ingredients.length > 0));

    if (page > 1) {
      yield put(appendOutOfStockIngredients(ingredients));
    } else {
      yield put(setOutOfStockIngredients(ingredients));
    }

    yield put(setFetchOutOfStockIngredientsSuccess(message));
    yield put(setFetchOutOfStockIngredientsLoading(false));
  } catch (error) {
    yield put(setFetchOutOfStockIngredientsFailed(error));
    yield put(setFetchOutOfStockIngredientsLoading(false));
  }
}
export function* _getAppendSelectIngredients() {
  try {
    yield put(setAppendSelectIngredientsLoading(true));

    const search = yield select(getAppendSelectIngredientsSearch);
    const sort = yield select(getAppendSelectIngredientsSort);
    const key_by = yield select(getAppendSelectIngredientsKeyBy);
    const page = yield select(getAppendSelectIngredientsPage);
    const per_page = yield select(getAppendSelectIngredientsPerPage);
    const includes = yield select(getAppendSelectIngredientsIncludes);
    const market_id = yield select(getAppendSelectIngredientsFilterMarketId);
    const branch_id = yield select(getAppendSelectIngredientsFilterBranchId);
    const ingredients_ids = yield select(
      getAppendSelectIngredientsFilterIngredientsIds
    );
    const is_group = yield select(getAppendSelectIngredientsFilterIsGroup);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        ingredients_ids,
        is_group,
      },
    };

    const {
      meta: { message },
      data: ingredients,
    } = yield call(getIngredients, parameters);

    yield put(setIsAppendSelectIngredientsHitted(true));
    yield put(appendSelectIngredients(ingredients));

    yield put(setAppendSelectIngredientsSuccess(message));
    yield put(setAppendSelectIngredientsLoading(false));
  } catch (error) {
    yield put(setAppendSelectIngredientsFailed(error));
    yield put(setAppendSelectIngredientsLoading(false));
  }
}
export function* _getAppendSelectGroupIngredients() {
  try {
    yield put(setAppendSelectGroupIngredientsLoading(true));

    const search = yield select(getAppendSelectGroupIngredientsSearch);
    const sort = yield select(getAppendSelectGroupIngredientsSort);
    const key_by = yield select(getAppendSelectGroupIngredientsKeyBy);
    const page = yield select(getAppendSelectGroupIngredientsPage);
    const per_page = yield select(getAppendSelectGroupIngredientsPerPage);
    const includes = yield select(getAppendSelectGroupIngredientsIncludes);
    const market_id = yield select(
      getAppendSelectGroupIngredientsFilterMarketId
    );
    const branch_id = yield select(
      getAppendSelectGroupIngredientsFilterBranchId
    );
    const ingredients_ids = yield select(
      getAppendSelectGroupIngredientsFilterIngredientsIds
    );
    const is_group = yield select(getAppendSelectGroupIngredientsFilterIsGroup);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        ingredients_ids,
        is_group,
      },
    };

    const {
      meta: { message },
      data: ingredients,
    } = yield call(getIngredients, parameters);

    yield put(setIsAppendSelectGroupIngredientsHitted(true));
    yield put(appendSelectGroupIngredients(ingredients));

    yield put(setAppendSelectGroupIngredientsSuccess(message));
    yield put(setAppendSelectGroupIngredientsLoading(false));
  } catch (error) {
    yield put(setAppendSelectGroupIngredientsFailed(error));
    yield put(setAppendSelectGroupIngredientsLoading(false));
  }
}

export function* _getIngredient({ payload: ingredientId }) {
  try {
    yield put(setFetchIngredientLoading(true));

    const {
      meta: { message },
      data: ingredient,
    } = yield call(getIngredient, ingredientId);

    yield put(setIsFetchIngredientHitted(true));
    yield put(setIngredient(ingredient));

    yield put(setFetchIngredientSuccess(message));
    yield put(setFetchIngredientLoading(false));
  } catch (error) {
    yield put(setFetchIngredientFailed(error));
    yield put(setFetchIngredientLoading(false));
  }
}
export function* _createIngredient({ payload: request }) {
  try {
    yield put(setCreateIngredientLoading(true));

    const {
      meta: { message },
    } = yield call(createIngredient, request);

    yield put(setIsCreateIngredientHitted(true));

    const isFetchIngredientsHitted = yield select(getIsFetchIngredientsHitted);
    const isFetchOutOfStockIngredientsHitted = yield select(
      getIsFetchOutOfStockIngredientsHitted
    );
    const isFetchSelectIngredientsHitted = yield select(
      getIsFetchSelectIngredientsHitted
    );

    if (isFetchIngredientsHitted) {
      yield put(setFetchIngredientsPage(1));
      yield call(_getIngredients);
    }
    if (isFetchOutOfStockIngredientsHitted) {
      yield put(setIsFetchOutOfStockIngredientsHitted(1));
      yield call(_getOutOfStockIngredients);
    }
    if (isFetchSelectIngredientsHitted) {
      yield put(setFetchSelectIngredientsPage(1));
      yield call(_getSelectIngredients);
    }

    yield put(setCreateIngredientSuccess(message));
    yield put(setCreateIngredientLoading(false));
  } catch (error) {
    yield put(setCreateIngredientFailed(error));
    yield put(setCreateIngredientLoading(false));
  }
}
export function* _updateIngredient({ payload: { ingredientId, request } }) {
  try {
    yield put(setUpdateIngredientLoading(true));

    const {
      meta: { message },
    } = yield call(updateIngredient, ingredientId, request);

    yield put(setIsUpdateIngredientHitted(true));

    const isFetchIngredientsHitted = yield select(getIsFetchIngredientsHitted);
    const isFetchOutOfStockIngredientsHitted = yield select(
      getIsFetchOutOfStockIngredientsHitted
    );
    const isFetchSelectIngredientsHitted = yield select(
      getIsFetchSelectIngredientsHitted
    );

    if (isFetchIngredientsHitted) {
      yield put(setFetchIngredientsPage(1));
      yield call(_getIngredients);
    }
    if (isFetchOutOfStockIngredientsHitted) {
      yield put(setIsFetchOutOfStockIngredientsHitted(1));
      yield call(_getOutOfStockIngredients);
    }
    if (isFetchSelectIngredientsHitted) {
      yield put(setFetchSelectIngredientsPage(1));
      yield call(_getSelectIngredients);
    }

    yield put(setUpdateIngredientSuccess(message));
    yield put(setUpdateIngredientLoading(false));
  } catch (error) {
    yield put(setUpdateIngredientFailed(error));
    yield put(setUpdateIngredientLoading(false));
  }
}
export function* _deleteIngredient({ payload: ingredientId }) {
  try {
    yield put(setDeleteIngredientLoading(true));

    const {
      meta: { message },
    } = yield call(deleteIngredient, ingredientId);

    yield put(setIsDeleteIngredientHitted(true));

    const isFetchIngredientsHitted = yield select(getIsFetchIngredientsHitted);
    const isFetchOutOfStockIngredientsHitted = yield select(
      getIsFetchOutOfStockIngredientsHitted
    );
    const isFetchSelectIngredientsHitted = yield select(
      getIsFetchSelectIngredientsHitted
    );

    if (isFetchIngredientsHitted) {
      yield put(setFetchIngredientsPage(1));
      yield call(_getIngredients);
    }
    if (isFetchOutOfStockIngredientsHitted) {
      yield put(setIsFetchOutOfStockIngredientsHitted(1));
      yield call(_getOutOfStockIngredients);
    }
    if (isFetchSelectIngredientsHitted) {
      yield put(setFetchSelectIngredientsPage(1));
      yield call(_getSelectIngredients);
    }

    yield put(setDeleteIngredientSuccess(message));
    yield put(setDeleteIngredientLoading(false));
  } catch (error) {
    yield put(setDeleteIngredientFailed(error));
    yield put(setDeleteIngredientLoading(false));
  }
}

export function* _storeStockIngredient({ payload: { ingredientId, request } }) {
  try {
    yield put(setStoreStockIngredientLoading(true));

    const {
      meta: { message },
    } = yield call(storeStockIngredient, ingredientId, request);

    yield put(setIsStoreStockIngredientHitted(true));

    const isFetchIngredientsHitted = yield select(getIsFetchIngredientsHitted);
    const isFetchOutOfStockIngredientsHitted = yield select(
      getIsFetchOutOfStockIngredientsHitted
    );
    const isFetchSelectIngredientsHitted = yield select(
      getIsFetchSelectIngredientsHitted
    );

    if (isFetchIngredientsHitted) {
      yield put(setFetchIngredientsPage(1));
      yield call(_getIngredients);
    }
    if (isFetchOutOfStockIngredientsHitted) {
      yield put(setIsFetchOutOfStockIngredientsHitted(1));
      yield call(_getOutOfStockIngredients);
    }
    if (isFetchSelectIngredientsHitted) {
      yield put(setFetchSelectIngredientsPage(1));
      yield call(_getSelectIngredients);
    }

    yield put(setStoreStockIngredientSuccess(message));
    yield put(setStoreStockIngredientLoading(false));
  } catch (error) {
    yield put(setStoreStockIngredientFailed(error));
    yield put(setStoreStockIngredientLoading(false));
  }
}
export function* _reduceStockIngredient({
  payload: { ingredientId, request },
}) {
  try {
    yield put(setReduceStockIngredientLoading(true));

    const {
      meta: { message },
    } = yield call(reduceStockIngredient, ingredientId, request);

    yield put(setIsReduceStockIngredientHitted(true));

    const isFetchIngredientsHitted = yield select(getIsFetchIngredientsHitted);
    const isFetchOutOfStockIngredientsHitted = yield select(
      getIsFetchOutOfStockIngredientsHitted
    );
    const isFetchSelectIngredientsHitted = yield select(
      getIsFetchSelectIngredientsHitted
    );

    if (isFetchIngredientsHitted) {
      yield put(setFetchIngredientsPage(1));
      yield call(_getIngredients);
    }
    if (isFetchOutOfStockIngredientsHitted) {
      yield put(setIsFetchOutOfStockIngredientsHitted(1));
      yield call(_getOutOfStockIngredients);
    }
    if (isFetchSelectIngredientsHitted) {
      yield put(setFetchSelectIngredientsPage(1));
      yield call(_getSelectIngredients);
    }

    yield put(setReduceStockIngredientSuccess(message));
    yield put(setReduceStockIngredientLoading(false));
  } catch (error) {
    yield put(setReduceStockIngredientFailed(error));
    yield put(setReduceStockIngredientLoading(false));
  }
}

export function* onFetchIngredientsStart() {
  yield takeLatest(
    INGREDIENT_ACTION_TYPES.FETCH_INGREDIENTS_START,
    _getIngredients
  );
}
export function* onFetchSelectIngredientsStart() {
  yield takeLatest(
    INGREDIENT_ACTION_TYPES.FETCH_SELECT_INGREDIENTS_START,
    _getSelectIngredients
  );
}
export function* onFetchSelectGroupIngredientsStart() {
  yield takeLatest(
    INGREDIENT_ACTION_TYPES.FETCH_SELECT_GROUP_INGREDIENTS_START,
    _getSelectGroupIngredients
  );
}
export function* onFetchOutOfStockIngredientsStart() {
  yield takeLatest(
    INGREDIENT_ACTION_TYPES.FETCH_OUT_OF_STOCK_INGREDIENTS_START,
    _getOutOfStockIngredients
  );
}
export function* onAppendSelectIngredientsStart() {
  yield takeLatest(
    INGREDIENT_ACTION_TYPES.APPEND_SELECT_INGREDIENTS_START,
    _getAppendSelectIngredients
  );
}
export function* onAppendSelectGroupIngredientsStart() {
  yield takeLatest(
    INGREDIENT_ACTION_TYPES.APPEND_SELECT_GROUP_INGREDIENTS_START,
    _getAppendSelectGroupIngredients
  );
}
export function* onFetchIngredientStart() {
  yield takeLatest(
    INGREDIENT_ACTION_TYPES.FETCH_INGREDIENT_START,
    _getIngredient
  );
}
export function* onCreateIngredientStart() {
  yield takeLatest(
    INGREDIENT_ACTION_TYPES.CREATE_INGREDIENT_START,
    _createIngredient
  );
}
export function* onUpdateIngredientStart() {
  yield takeLatest(
    INGREDIENT_ACTION_TYPES.UPDATE_INGREDIENT_START,
    _updateIngredient
  );
}
export function* onDeleteIngredientStart() {
  yield takeLatest(
    INGREDIENT_ACTION_TYPES.DELETE_INGREDIENT_START,
    _deleteIngredient
  );
}
export function* onStoreStockIngredientStart() {
  yield takeLatest(
    INGREDIENT_ACTION_TYPES.STORE_STOCK_INGREDIENT_START,
    _storeStockIngredient
  );
}
export function* onReduceStockIngredientStart() {
  yield takeLatest(
    INGREDIENT_ACTION_TYPES.REDUCE_STOCK_INGREDIENT_START,
    _reduceStockIngredient
  );
}

export function* ingredientSaga() {
  yield all([
    call(onFetchIngredientsStart),
    call(onFetchSelectIngredientsStart),
    call(onFetchSelectGroupIngredientsStart),
    call(onFetchOutOfStockIngredientsStart),
    call(onAppendSelectIngredientsStart),
    call(onAppendSelectGroupIngredientsStart),
    call(onFetchIngredientStart),
    call(onCreateIngredientStart),
    call(onUpdateIngredientStart),
    call(onDeleteIngredientStart),
    call(onStoreStockIngredientStart),
    call(onReduceStockIngredientStart),
  ]);
}
