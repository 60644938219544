import { takeLatest, put, all, call, select } from "redux-saga/effects";

import DASHBOARD_SUBSCRIBER_ACTION_TYPES from "./dashboard-subscriber.type";

import {
  setDashboard,
  setFetchDashboardFailed,
  setFetchDashboardLoading,
  setFetchDashboardSuccess,
  setIsFetchDashboardHitted,
} from "./dashboard-subscriber.action";
import {
  getFetchDashboardFilterBranchId,
  getFetchDashboardFilterMarketId,
  getFetchDashboardFilterOrderStatuses,
  getFetchDashboardFilterPeriodicity,
  getFetchDashboardFilterTransactionStatuses,
} from "./dashboard-subscriber.selector";

import { getDashboardSubscriber } from "../../api/dashboard.api";

export function* _getDashboard() {
  try {
    yield put(setFetchDashboardLoading(true));

    const market_id = yield select(getFetchDashboardFilterMarketId);
    const branch_id = yield select(getFetchDashboardFilterBranchId);
    const order_statuses = yield select(getFetchDashboardFilterOrderStatuses);
    const transaction_statuses = yield select(
      getFetchDashboardFilterTransactionStatuses
    );
    const periodicity = yield select(getFetchDashboardFilterPeriodicity);

    const parameters = {
      filter: {
        market_id,
        branch_id,
        order_statuses,
        transaction_statuses,
        periodicity,
      },
    };

    const {
      meta: { message },
      data: dashboard,
    } = yield call(getDashboardSubscriber, parameters);

    yield put(setIsFetchDashboardHitted(true));
    yield put(setDashboard(dashboard));

    yield put(setFetchDashboardSuccess(message));
    yield put(setFetchDashboardLoading(false));
  } catch (error) {
    yield put(setFetchDashboardFailed(error));
    yield put(setFetchDashboardLoading(false));
  }
}

export function* onFetchDashboardStart() {
  yield takeLatest(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.FETCH_DASHBOARD_START,
    _getDashboard
  );
}

export function* dashboardSubscriberSaga() {
  yield all([call(onFetchDashboardStart)]);
}
