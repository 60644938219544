import PAYMENT_METHOD_ACTION_TYPES from "./payment-method.type";

export const PAYMENT_METHOD_INITIAL_STATE = {
  paymentMethods: [],
  selectPaymentMethods: [],
  paymentMethod: null,

  isPaymentMethodsHasMore: true,
  fetchPaymentMethodsPage: 1,
  fetchPaymentMethodsPerPage: null,
  fetchPaymentMethodsLoading: false,
  fetchPaymentMethodsSuccess: null,
  fetchPaymentMethodsFailed: null,

  isSelectPaymentMethodsHasMore: true,
  fetchSelectPaymentMethodsPage: 1,
  fetchSelectPaymentMethodsPerPage: null,
  fetchSelectPaymentMethodsLoading: false,
  fetchSelectPaymentMethodsSuccess: null,
  fetchSelectPaymentMethodsFailed: null,

  createPaymentMethodLoading: false,
  createPaymentMethodSuccess: null,
  createPaymentMethodFailed: null,

  updatePaymentMethodLoading: false,
  updatePaymentMethodSuccess: null,
  updatePaymentMethodFailed: null,

  deletePaymentMethodLoading: false,
  deletePaymentMethodSuccess: null,
  deletePaymentMethodFailed: null,

  isFetchPaymentMethodsHitted: false,
  isFetchSelectPaymentMethodsHitted: false,
  isCreatePaymentMethodHitted: false,
  isUpdatePaymentMethodHitted: false,
  isDeletePaymentMethodHitted: false,
};

export const paymentMethodReducer = (state = PAYMENT_METHOD_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PAYMENT_METHOD_ACTION_TYPES.SET_PAYMENT_METHODS:
      return { ...state, paymentMethods: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_SELECT_PAYMENT_METHODS:
      return { ...state, selectPaymentMethods: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_PAYMENT_METHOD:
      return { ...state, paymentMethod: payload };

    case PAYMENT_METHOD_ACTION_TYPES.SET_IS_PAYMENT_METHODS_HAS_MORE:
      return { ...state, isPaymentMethodsHasMore: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_PAGE:
      return { ...state, fetchPaymentMethodsPage: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_PER_PAGE:
      return { ...state, fetchPaymentMethodsPerPage: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_LOADING:
      return { ...state, fetchPaymentMethodsLoading: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_SUCCESS:
      return { ...state, fetchPaymentMethodsSuccess: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_PAYMENT_METHODS_FAILED:
      return { ...state, fetchPaymentMethodsFailed: payload };

    case PAYMENT_METHOD_ACTION_TYPES.SET_IS_SELECT_PAYMENT_METHODS_HAS_MORE:
      return { ...state, isSelectPaymentMethodsHasMore: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHODS_PAGE:
      return { ...state, fetchSelectPaymentMethodsPage: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHODS_PER_PAGE:
      return { ...state, fetchSelectPaymentMethodsPerPage: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHODS_LOADING:
      return { ...state, fetchSelectPaymentMethodsLoading: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHODS_SUCCESS:
      return { ...state, fetchSelectPaymentMethodsSuccess: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_FETCH_SELECT_PAYMENT_METHODS_FAILED:
      return { ...state, fetchSelectPaymentMethodsFailed: payload };

    case PAYMENT_METHOD_ACTION_TYPES.SET_CREATE_PAYMENT_METHOD_LOADING:
      return { ...state, createPaymentMethodLoading: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_CREATE_PAYMENT_METHOD_SUCCESS:
      return { ...state, createPaymentMethodSuccess: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_CREATE_PAYMENT_METHOD_FAILED:
      return { ...state, createPaymentMethodFailed: payload };

    case PAYMENT_METHOD_ACTION_TYPES.SET_UPDATE_PAYMENT_METHOD_LOADING:
      return { ...state, updatePaymentMethodLoading: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_UPDATE_PAYMENT_METHOD_SUCCESS:
      return { ...state, updatePaymentMethodSuccess: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_UPDATE_PAYMENT_METHOD_FAILED:
      return { ...state, updatePaymentMethodFailed: payload };

    case PAYMENT_METHOD_ACTION_TYPES.SET_DELETE_PAYMENT_METHOD_LOADING:
      return { ...state, deletePaymentMethodLoading: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_DELETE_PAYMENT_METHOD_SUCCESS:
      return { ...state, deletePaymentMethodSuccess: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_DELETE_PAYMENT_METHOD_FAILED:
      return { ...state, deletePaymentMethodFailed: payload };

    case PAYMENT_METHOD_ACTION_TYPES.APPEND_PAYMENT_METHODS:
      return { ...state, paymentMethods: [...state.paymentMethods, ...payload] };
    case PAYMENT_METHOD_ACTION_TYPES.APPEND_SELECT_PAYMENT_METHODS:
      return { ...state, selectPaymentMethods: [...state.selectPaymentMethods, ...payload] };

    case PAYMENT_METHOD_ACTION_TYPES.SET_IS_FETCH_PAYMENT_METHODS_HITTED:
      return { ...state, isFetchPaymentMethodsHitted: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_IS_FETCH_SELECT_PAYMENT_METHODS_HITTED:
      return { ...state, isFetchSelectPaymentMethodsHitted: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_IS_CREATE_PAYMENT_METHOD_HITTED:
      return { ...state, isCreatePaymentMethodHitted: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_IS_UPDATE_PAYMENT_METHOD_HITTED:
      return { ...state, isUpdatePaymentMethodHitted: payload };
    case PAYMENT_METHOD_ACTION_TYPES.SET_IS_DELETE_PAYMENT_METHOD_HITTED:
      return { ...state, isDeletePaymentMethodHitted: payload };

    case PAYMENT_METHOD_ACTION_TYPES.RESET_PAYMENT_METHOD_REDUCER:
      return PAYMENT_METHOD_INITIAL_STATE;
    default:
      return state;
  }
};
