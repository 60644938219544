const DASHBOARD_SUBSCRIBER_ACTION_TYPES = {
  RESET_DASHBOARD_SUBSCRIBER_REDUCER:
    "dashboard-subscriber/RESET_DASHBOARD_SUBSCRIBER_REDUCER",

  SET_DASHBOARD: "dashboard-subscriber/SET_DASHBOARD",

  SET_FETCH_DASHBOARD_FILTER_MARKET_ID:
    "dashboard-subscriber/SET_FETCH_DASHBOARD_FILTER_MARKET_ID",
  SET_FETCH_DASHBOARD_FILTER_BRANCH_ID:
    "dashboard-subscriber/SET_FETCH_DASHBOARD_FILTER_BRANCH_ID",
  SET_FETCH_DASHBOARD_FILTER_ORDER_STATUSES:
    "dashboard-subscriber/SET_FETCH_DASHBOARD_FILTER_ORDER_STATUSES",
  SET_FETCH_DASHBOARD_FILTER_TRANSACTION_STATUSES:
    "dashboard-subscriber/SET_FETCH_DASHBOARD_FILTER_TRANSACTION_STATUSES",
  SET_FETCH_DASHBOARD_FILTER_PERIODICITY:
    "dashboard-subscriber/SET_FETCH_DASHBOARD_FILTER_PERIODICITY",
  SET_FETCH_DASHBOARD_LOADING:
    "dashboard-subscriber/SET_FETCH_DASHBOARD_LOADING",
  SET_FETCH_DASHBOARD_SUCCESS:
    "dashboard-subscriber/SET_FETCH_DASHBOARD_SUCCESS",
  SET_FETCH_DASHBOARD_FAILED: "dashboard-subscriber/SET_FETCH_DASHBOARD_FAILED",

  SET_IS_FETCH_DASHBOARD_HITTED:
    "dashboard-subscriber/SET_IS_FETCH_DASHBOARD_HITTED",

  FETCH_DASHBOARD_START: "dashboard-subscriber/FETCH_DASHBOARD_START",
};

export default DASHBOARD_SUBSCRIBER_ACTION_TYPES;
