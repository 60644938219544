import USER_ADMIN_ACTION_TYPES from "./user-admin.type";

export const USER_ADMIN_INITIAL_STATE = {
  userAdmins: [],
  userAdmin: null,

  isUserAdminsHasMore: true,

  fetchUserAdminsSearch: null,
  fetchUserAdminsSort: null,
  fetchUserAdminsKeyBy: null,
  fetchUserAdminsPage: 1,
  fetchUserAdminsPerPage: null,
  fetchUserAdminsIncludes: null,
  fetchUserAdminsFilterPermissionId: null,
  fetchUserAdminsFilterMarketId: null,
  fetchUserAdminsLoading: false,
  fetchUserAdminsSuccess: null,
  fetchUserAdminsFailed: null,

  fetchUserAdminLoading: false,
  fetchUserAdminSuccess: null,
  fetchUserAdminFailed: null,

  createUserAdminLoading: false,
  createUserAdminSuccess: null,
  createUserAdminFailed: null,

  updateUserAdminLoading: false,
  updateUserAdminSuccess: null,
  updateUserAdminFailed: null,

  deleteUserAdminLoading: false,
  deleteUserAdminSuccess: null,
  deleteUserAdminFailed: null,

  isFetchUserAdminsHitted: false,
  isFetchUserAdminHitted: false,
  isCreateUserAdminHitted: false,
  isUpdateUserAdminHitted: false,
  isDeleteUserAdminHitted: false,
};

export const userAdminReducer = (state = USER_ADMIN_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ADMIN_ACTION_TYPES.SET_USER_ADMINS:
      return { ...state, userAdmins: payload };
    case USER_ADMIN_ACTION_TYPES.SET_USER_ADMIN:
      return { ...state, userAdmin: payload };

    case USER_ADMIN_ACTION_TYPES.SET_IS_USER_ADMINS_HAS_MORE:
      return { ...state, isUserAdminsHasMore: payload };

    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_SEARCH:
      return { ...state, fetchUserAdminsSearch: payload };
    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_SORT:
      return { ...state, fetchUserAdminsSort: payload };
    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_KEY_BY:
      return { ...state, fetchUserAdminsKeyBy: payload };
    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_PAGE:
      return { ...state, fetchUserAdminsPage: payload };
    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_PER_PAGE:
      return { ...state, fetchUserAdminsPerPage: payload };
    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_INCLUDES:
      return { ...state, fetchUserAdminsIncludes: payload };
    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_FILTER_PERMISSION_ID:
      return { ...state, fetchUserAdminsFilterPermissionId: payload };
    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_FILTER_MARKET_ID:
      return { ...state, fetchUserAdminsFilterMarketId: payload };
    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_LOADING:
      return { ...state, fetchUserAdminsLoading: payload };
    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_SUCCESS:
      return { ...state, fetchUserAdminsSuccess: payload };
    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMINS_FAILED:
      return { ...state, fetchUserAdminsFailed: payload };

    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMIN_LOADING:
      return { ...state, fetchUserAdminLoading: payload };
    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMIN_SUCCESS:
      return { ...state, fetchUserAdminSuccess: payload };
    case USER_ADMIN_ACTION_TYPES.SET_FETCH_USER_ADMIN_FAILED:
      return { ...state, fetchUserAdminFailed: payload };

    case USER_ADMIN_ACTION_TYPES.SET_CREATE_USER_ADMIN_LOADING:
      return { ...state, createUserAdminLoading: payload };
    case USER_ADMIN_ACTION_TYPES.SET_CREATE_USER_ADMIN_SUCCESS:
      return { ...state, createUserAdminSuccess: payload };
    case USER_ADMIN_ACTION_TYPES.SET_CREATE_USER_ADMIN_FAILED:
      return { ...state, createUserAdminFailed: payload };

    case USER_ADMIN_ACTION_TYPES.SET_UPDATE_USER_ADMIN_LOADING:
      return { ...state, updateUserAdminLoading: payload };
    case USER_ADMIN_ACTION_TYPES.SET_UPDATE_USER_ADMIN_SUCCESS:
      return { ...state, updateUserAdminSuccess: payload };
    case USER_ADMIN_ACTION_TYPES.SET_UPDATE_USER_ADMIN_FAILED:
      return { ...state, updateUserAdminFailed: payload };

    case USER_ADMIN_ACTION_TYPES.SET_DELETE_USER_ADMIN_LOADING:
      return { ...state, deleteUserAdminLoading: payload };
    case USER_ADMIN_ACTION_TYPES.SET_DELETE_USER_ADMIN_SUCCESS:
      return { ...state, deleteUserAdminSuccess: payload };
    case USER_ADMIN_ACTION_TYPES.SET_DELETE_USER_ADMIN_FAILED:
      return { ...state, deleteUserAdminFailed: payload };

    case USER_ADMIN_ACTION_TYPES.APPEND_USER_ADMINS:
      return { ...state, userAdmins: [...state.userAdmins, ...payload] };

    case USER_ADMIN_ACTION_TYPES.SET_IS_FETCH_USER_ADMINS_HITTED:
      return { ...state, isFetchUserAdminsHitted: payload };
    case USER_ADMIN_ACTION_TYPES.SET_IS_FETCH_USER_ADMIN_HITTED:
      return { ...state, isFetchUserAdminHitted: payload };
    case USER_ADMIN_ACTION_TYPES.SET_IS_CREATE_USER_ADMIN_HITTED:
      return { ...state, isCreateUserAdminHitted: payload };
    case USER_ADMIN_ACTION_TYPES.SET_IS_UPDATE_USER_ADMIN_HITTED:
      return { ...state, isUpdateUserAdminHitted: payload };
    case USER_ADMIN_ACTION_TYPES.SET_IS_DELETE_USER_ADMIN_HITTED:
      return { ...state, isDeleteUserAdminHitted: payload };

    case USER_ADMIN_ACTION_TYPES.RESET_USER_ADMIN_REDUCER:
      return USER_ADMIN_INITIAL_STATE;
    default:
      return state;
  }
};
