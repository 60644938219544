import { createSelector } from "reselect";

const purchaseSelector = ({ purchase }) => purchase;

export const getPurchases = createSelector(
  [purchaseSelector],
  ({ purchases }) => purchases
);
export const getPurchase = createSelector(
  [purchaseSelector],
  ({ purchase }) => purchase
);
export const getCalculatePurchase = createSelector(
  [purchaseSelector],
  ({ calculatePurchase }) => calculatePurchase
);

export const getIsPurchasesHasMore = createSelector(
  [purchaseSelector],
  ({ isPurchasesHasMore }) => isPurchasesHasMore
);

export const getFetchPurchasesSearch = createSelector(
  [purchaseSelector],
  ({ fetchPurchasesSearch }) => fetchPurchasesSearch
);
export const getFetchPurchasesSort = createSelector(
  [purchaseSelector],
  ({ fetchPurchasesSort }) => fetchPurchasesSort
);
export const getFetchPurchasesKeyBy = createSelector(
  [purchaseSelector],
  ({ fetchPurchasesKeyBy }) => fetchPurchasesKeyBy
);
export const getFetchPurchasesPage = createSelector(
  [purchaseSelector],
  ({ fetchPurchasesPage }) => fetchPurchasesPage
);
export const getFetchPurchasesPerPage = createSelector(
  [purchaseSelector],
  ({ fetchPurchasesPerPage }) => fetchPurchasesPerPage
);
export const getFetchPurchasesIncludes = createSelector(
  [purchaseSelector],
  ({ fetchPurchasesIncludes }) => fetchPurchasesIncludes
);
export const getFetchPurchasesFilterMarketId = createSelector(
  [purchaseSelector],
  ({ fetchPurchasesFilterMarketId }) => fetchPurchasesFilterMarketId
);
export const getFetchPurchasesFilterBranchId = createSelector(
  [purchaseSelector],
  ({ fetchPurchasesFilterBranchId }) => fetchPurchasesFilterBranchId
);
export const getFetchPurchasesFilterSupplierId = createSelector(
  [purchaseSelector],
  ({ fetchPurchasesFilterSupplierId }) => fetchPurchasesFilterSupplierId
);
export const getFetchPurchasesLoading = createSelector(
  [purchaseSelector],
  ({ fetchPurchasesLoading }) => fetchPurchasesLoading
);
export const getFetchPurchasesSuccess = createSelector(
  [purchaseSelector],
  ({ fetchPurchasesSuccess }) => fetchPurchasesSuccess
);
export const getFetchPurchasesFailed = createSelector(
  [purchaseSelector],
  ({ fetchPurchasesFailed }) => fetchPurchasesFailed
);

export const getFetchPurchaseLoading = createSelector(
  [purchaseSelector],
  ({ fetchPurchaseLoading }) => fetchPurchaseLoading
);
export const getFetchPurchaseSuccess = createSelector(
  [purchaseSelector],
  ({ fetchPurchaseSuccess }) => fetchPurchaseSuccess
);
export const getFetchPurchaseFailed = createSelector(
  [purchaseSelector],
  ({ fetchPurchaseFailed }) => fetchPurchaseFailed
);

export const getCreatePurchaseLoading = createSelector(
  [purchaseSelector],
  ({ createPurchaseLoading }) => createPurchaseLoading
);
export const getCreatePurchaseSuccess = createSelector(
  [purchaseSelector],
  ({ createPurchaseSuccess }) => createPurchaseSuccess
);
export const getCreatePurchaseFailed = createSelector(
  [purchaseSelector],
  ({ createPurchaseFailed }) => createPurchaseFailed
);

export const getUpdatePurchaseLoading = createSelector(
  [purchaseSelector],
  ({ updatePurchaseLoading }) => updatePurchaseLoading
);
export const getUpdatePurchaseSuccess = createSelector(
  [purchaseSelector],
  ({ updatePurchaseSuccess }) => updatePurchaseSuccess
);
export const getUpdatePurchaseFailed = createSelector(
  [purchaseSelector],
  ({ updatePurchaseFailed }) => updatePurchaseFailed
);

export const getDeletePurchaseLoading = createSelector(
  [purchaseSelector],
  ({ deletePurchaseLoading }) => deletePurchaseLoading
);
export const getDeletePurchaseSuccess = createSelector(
  [purchaseSelector],
  ({ deletePurchaseSuccess }) => deletePurchaseSuccess
);
export const getDeletePurchaseFailed = createSelector(
  [purchaseSelector],
  ({ deletePurchaseFailed }) => deletePurchaseFailed
);

export const getCalculatePurchaseLoading = createSelector(
  [purchaseSelector],
  ({ calculatePurchaseLoading }) => calculatePurchaseLoading
);
export const getCalculatePurchaseSuccess = createSelector(
  [purchaseSelector],
  ({ calculatePurchaseSuccess }) => calculatePurchaseSuccess
);
export const getCalculatePurchaseFailed = createSelector(
  [purchaseSelector],
  ({ calculatePurchaseFailed }) => calculatePurchaseFailed
);

export const getIsFetchPurchasesHitted = createSelector(
  [purchaseSelector],
  ({ isFetchPurchasesHitted }) => isFetchPurchasesHitted
);
export const getIsFetchPurchaseHitted = createSelector(
  [purchaseSelector],
  ({ isFetchPurchaseHitted }) => isFetchPurchaseHitted
);
export const getIsCreatePurchaseHitted = createSelector(
  [purchaseSelector],
  ({ isCreatePurchaseHitted }) => isCreatePurchaseHitted
);
export const getIsUpdatePurchaseHitted = createSelector(
  [purchaseSelector],
  ({ isUpdatePurchaseHitted }) => isUpdatePurchaseHitted
);
export const getIsDeletePurchaseHitted = createSelector(
  [purchaseSelector],
  ({ isDeletePurchaseHitted }) => isDeletePurchaseHitted
);
export const getIsCalculatePurchaseHitted = createSelector(
  [purchaseSelector],
  ({ isCalculatePurchaseHitted }) => isCalculatePurchaseHitted
);
