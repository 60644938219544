const PURCHASE_ACTION_TYPES = {
  RESET_PURCHASE_REDUCER: "purchase/RESET_PURCHASE_REDUCER",

  SET_PURCHASES: "purchase/SET_PURCHASES",
  SET_PURCHASE: "purchase/SET_PURCHASE",
  SET_CALCULATE_PURCHASE: "purchase/SET_CALC_PURCHASE",

  SET_IS_PURCHASES_HAS_MORE: "purchase/SET_IS_PURCHASES_HAS_MORE",

  SET_FETCH_PURCHASES_SEARCH: "purchase/SET_FETCH_PURCHASES_SEARCH",
  SET_FETCH_PURCHASES_SORT: "purchase/SET_FETCH_PURCHASES_SORT",
  SET_FETCH_PURCHASES_KEY_BY: "purchase/SET_FETCH_PURCHASES_KEY_BY",
  SET_FETCH_PURCHASES_PAGE: "purchase/SET_FETCH_PURCHASES_PAGE",
  SET_FETCH_PURCHASES_PER_PAGE: "purchase/SET_FETCH_PURCHASES_PER_PAGE",
  SET_FETCH_PURCHASES_INCLUDES: "purchase/SET_FETCH_PURCHASES_INCLUDES",
  SET_FETCH_PURCHASES_FILTER_MARKET_ID:
    "purchase/SET_FETCH_PURCHASES_FILTER_MARKET_ID",
  SET_FETCH_PURCHASES_FILTER_BRANCH_ID:
    "purchase/SET_FETCH_PURCHASES_FILTER_BRANCH_ID",
  SET_FETCH_PURCHASES_FILTER_SUPPLIER_ID:
    "purchase/SET_FETCH_PURCHASES_FILTER_SUPPLIER_ID",
  SET_FETCH_PURCHASES_LOADING: "purchase/SET_FETCH_PURCHASES_LOADING",
  SET_FETCH_PURCHASES_SUCCESS: "purchase/SET_FETCH_PURCHASES_SUCCESS",
  SET_FETCH_PURCHASES_FAILED: "purchase/SET_FETCH_PURCHASES_FAILED",

  SET_FETCH_PURCHASE_LOADING: "purchase/SET_FETCH_PURCHASE_LOADING",
  SET_FETCH_PURCHASE_SUCCESS: "purchase/SET_FETCH_PURCHASE_SUCCESS",
  SET_FETCH_PURCHASE_FAILED: "purchase/SET_FETCH_PURCHASE_FAILED",

  SET_CREATE_PURCHASE_LOADING: "purchase/SET_CREATE_PURCHASE_LOADING",
  SET_CREATE_PURCHASE_SUCCESS: "purchase/SET_CREATE_PURCHASE_SUCCESS",
  SET_CREATE_PURCHASE_FAILED: "purchase/SET_CREATE_PURCHASE_FAILED",

  SET_UPDATE_PURCHASE_LOADING: "purchase/SET_UPDATE_PURCHASE_LOADING",
  SET_UPDATE_PURCHASE_SUCCESS: "purchase/SET_UPDATE_PURCHASE_SUCCESS",
  SET_UPDATE_PURCHASE_FAILED: "purchase/SET_UPDATE_PURCHASE_FAILED",

  SET_DELETE_PURCHASE_LOADING: "purchase/SET_DELETE_PURCHASE_LOADING",
  SET_DELETE_PURCHASE_SUCCESS: "purchase/SET_DELETE_PURCHASE_SUCCESS",
  SET_DELETE_PURCHASE_FAILED: "purchase/SET_DELETE_PURCHASE_FAILED",

  SET_CALCULATE_PURCHASE_LOADING: "purchase/SET_CALCULATE_PURCHASE_LOADING",
  SET_CALCULATE_PURCHASE_SUCCESS: "purchase/SET_CALCULATE_PURCHASE_SUCCESS",
  SET_CALCULATE_PURCHASE_FAILED: "purchase/SET_CALCULATE_PURCHASE_FAILED",

  APPEND_PURCHASES: "purchase/APPEND_PURCHASES",

  SET_IS_FETCH_PURCHASES_HITTED: "purchase/SET_IS_FETCH_PURCHASES_HITTED",
  SET_IS_FETCH_PURCHASE_HITTED: "purchase/SET_IS_FETCH_PURCHASE_HITTED",
  SET_IS_CREATE_PURCHASE_HITTED: "purchase/SET_IS_CREATE_PURCHASE_HITTED",
  SET_IS_UPDATE_PURCHASE_HITTED: "purchase/SET_IS_UPDATE_PURCHASE_HITTED",
  SET_IS_DELETE_PURCHASE_HITTED: "purchase/SET_IS_DELETE_PURCHASE_HITTED",
  SET_IS_CALCULATE_PURCHASE_HITTED: "purchase/SET_IS_CALCULATE_PURCHASE_HITTED",

  FETCH_PURCHASES_START: "purchase/FETCH_PURCHASES_START",
  FETCH_PURCHASE_START: "purchase/FETCH_PURCHASE_START",
  CREATE_PURCHASE_START: "purchase/CREATE_PURCHASE_START",
  UPDATE_PURCHASE_START: "purchase/UPDATE_PURCHASE_START",
  DELETE_PURCHASE_START: "purchase/DELETE_PURCHASE_START",
  CALCULATE_PURCHASE_START: "purchase/CALCULATE_PURCHASE_START",
};

export default PURCHASE_ACTION_TYPES;
