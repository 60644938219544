import FORM_CUSTOMER_ACTION_TYPES from "./form-customer.type";

export const FORM_CUSTOMER_INITIAL_STATE = {
  searchInputValues: {
    phone_prefix: "",
    phone: "",
  },
  createInputValues: {
    name: "",
    phone_prefix: "",
    phone: "",
    email: "",
    type: "",
    gender: "",
    crn: "",
    tax_number: "",
    zip_code: "",
    city: "",
    district: "",
    street: "",
  },
  editInputValues: {
    name: "",
    phone_prefix: "",
    phone: "",
    email: "",
    type: "",
    gender: "",
    crn: "",
    tax_number: "",
    zip_code: "",
    city: "",
    district: "",
    street: "",
  },
};

export const formCustomerReducer = (
  state = FORM_CUSTOMER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case FORM_CUSTOMER_ACTION_TYPES.SEARCH_INPUT_VALUES:
      const { createInputValues } = state;

      const updateState = { ...state };
      updateState.searchInputValues = payload;
      updateState.createInputValues = { ...createInputValues, ...payload };

      return updateState;
    case FORM_CUSTOMER_ACTION_TYPES.CREATE_INPUT_VALUES:
      return { ...state, createInputValues: payload };
    case FORM_CUSTOMER_ACTION_TYPES.EDIT_INPUT_VALUES:
      return { ...state, editInputValues: payload };

    case FORM_CUSTOMER_ACTION_TYPES.RESET_FORM_CUSTOMER_REDUCER:
      return FORM_CUSTOMER_INITIAL_STATE;
    default:
      return state;
  }
};
