const DASHBOARD_ACTION_TYPES = {
  RESET_DASHBOARD_REDUCER: "dashboard/RESET_DASHBOARD_REDUCER",

  SET_DASHBOARD: "dashboard/SET_DASHBOARD",
  SET_DASHBOARD_SUBSCRIBER: "dashboard/SET_DASHBOARD_SUBSCRIBER",

  SET_FETCH_DASHBOARD_FILTER_MARKET_ID:
    "dashboard/SET_FETCH_DASHBOARD_FILTER_MARKET_ID",
  SET_FETCH_DASHBOARD_FILTER_BRANCH_ID:
    "dashboard/SET_FETCH_DASHBOARD_FILTER_BRANCH_ID",
  SET_FETCH_DASHBOARD_FILTER_TIME_PERIOD:
    "dashboard/SET_FETCH_DASHBOARD_FILTER_TIME_PERIOD",
  SET_FETCH_DASHBOARD_FILTER_CREATED_AT_BEFORE:
    "dashboard/SET_FETCH_DASHBOARD_FILTER_CREATED_AT_BEFORE",
  SET_FETCH_DASHBOARD_FILTER_CREATED_AT_AFTER:
    "dashboard/SET_FETCH_DASHBOARD_FILTER_CREATED_AT_AFTER",
  SET_FETCH_DASHBOARD_FILTER_CURRENCY:
    "dashboard/SET_FETCH_DASHBOARD_FILTER_CURRENCY",
  SET_FETCH_DASHBOARD_LOADING: "dashboard/SET_FETCH_DASHBOARD_LOADING",
  SET_FETCH_DASHBOARD_SUCCESS: "dashboard/SET_FETCH_DASHBOARD_SUCCESS",
  SET_FETCH_DASHBOARD_FAILED: "dashboard/SET_FETCH_DASHBOARD_FAILED",

  SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_MARKET_ID:
    "dashboard/SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_MARKET_ID",
  SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_BRANCH_ID:
    "dashboard/SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_BRANCH_ID",
  SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_ORDER_STATUSES:
    "dashboard/SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_ORDER_STATUSES",
  SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_TRANSACTION_STATUSES:
    "dashboard/SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_TRANSACTION_STATUSES",
  SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_START_AT:
    "dashboard/SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_START_AT",
  SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_END_AT:
    "dashboard/SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_END_AT",
  SET_FETCH_DASHBOARD_SUBSCRIBER_LOADING:
    "dashboard/SET_FETCH_DASHBOARD_SUBSCRIBER_LOADING",
  SET_FETCH_DASHBOARD_SUBSCRIBER_SUCCESS:
    "dashboard/SET_FETCH_DASHBOARD_SUBSCRIBER_SUCCESS",
  SET_FETCH_DASHBOARD_SUBSCRIBER_FAILED:
    "dashboard/SET_FETCH_DASHBOARD_SUBSCRIBER_FAILED",

  SET_IS_FETCH_DASHBOARD_HITTED: "dashboard/SET_IS_FETCH_DASHBOARD_HITTED",
  SET_IS_FETCH_DASHBOARD_SUBSCRIBER_HITTED:
    "dashboard/SET_IS_FETCH_DASHBOARD_SUBSCRIBER_HITTED",

  FETCH_DASHBOARD_START: "dashboard/FETCH_DASHBOARD_START",
  FETCH_DASHBOARD_SUBSCRIBER_START:
    "dashboard/FETCH_DASHBOARD_SUBSCRIBER_START",
};

export default DASHBOARD_ACTION_TYPES;
