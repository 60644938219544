const USER_SUBSCRIBER_ACTION_TYPES = {
  RESET_USER_SUBSCRIBER_REDUCER:
    "user-subscriber/RESET_USER_SUBSCRIBER_REDUCER",

  SET_USER_SUBSCRIBERS: "user-subscriber/SET_USER_SUBSCRIBERS",
  SET_USER_SUBSCRIBER: "user-subscriber/SET_USER_SUBSCRIBER",

  SET_IS_USER_SUBSCRIBERS_HAS_MORE:
    "user-subscriber/SET_IS_USER_SUBSCRIBERS_HAS_MORE",

  SET_FETCH_USER_SUBSCRIBERS_SEARCH:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBERS_SEARCH",
  SET_FETCH_USER_SUBSCRIBERS_SORT:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBERS_SORT",
  SET_FETCH_USER_SUBSCRIBERS_KEY_BY:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBERS_KEY_BY",
  SET_FETCH_USER_SUBSCRIBERS_PAGE:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBERS_PAGE",
  SET_FETCH_USER_SUBSCRIBERS_PER_PAGE:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBERS_PER_PAGE",
  SET_FETCH_USER_SUBSCRIBERS_INCLUDES:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBERS_INCLUDES",
  SET_FETCH_USER_SUBSCRIBERS_FILTER_MARKET_ID:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBERS_FILTER_MARKET_ID",
  SET_FETCH_USER_SUBSCRIBERS_FILTER_BRANCH_ID:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBERS_FILTER_BRANCH_ID",
  SET_FETCH_USER_SUBSCRIBERS_FILTER_PERMISSION_ID:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBERS_FILTER_PERMISSION_ID",
  SET_FETCH_USER_SUBSCRIBERS_LOADING:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBERS_LOADING",
  SET_FETCH_USER_SUBSCRIBERS_SUCCESS:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBERS_SUCCESS",
  SET_FETCH_USER_SUBSCRIBERS_FAILED:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBERS_FAILED",

  SET_FETCH_USER_SUBSCRIBER_LOADING:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBER_LOADING",
  SET_FETCH_USER_SUBSCRIBER_SUCCESS:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBER_SUCCESS",
  SET_FETCH_USER_SUBSCRIBER_FAILED:
    "user-subscriber/SET_FETCH_USER_SUBSCRIBER_FAILED",

  SET_CREATE_USER_SUBSCRIBER_LOADING:
    "user-subscriber/SET_CREATE_USER_SUBSCRIBER_LOADING",
  SET_CREATE_USER_SUBSCRIBER_SUCCESS:
    "user-subscriber/SET_CREATE_USER_SUBSCRIBER_SUCCESS",
  SET_CREATE_USER_SUBSCRIBER_FAILED:
    "user-subscriber/SET_CREATE_USER_SUBSCRIBER_FAILED",

  SET_UPDATE_USER_SUBSCRIBER_LOADING:
    "user-subscriber/SET_UPDATE_USER_SUBSCRIBER_LOADING",
  SET_UPDATE_USER_SUBSCRIBER_SUCCESS:
    "user-subscriber/SET_UPDATE_USER_SUBSCRIBER_SUCCESS",
  SET_UPDATE_USER_SUBSCRIBER_FAILED:
    "user-subscriber/SET_UPDATE_USER_SUBSCRIBER_FAILED",

  SET_DELETE_USER_SUBSCRIBER_LOADING:
    "user-subscriber/SET_DELETE_USER_SUBSCRIBER_LOADING",
  SET_DELETE_USER_SUBSCRIBER_SUCCESS:
    "user-subscriber/SET_DELETE_USER_SUBSCRIBER_SUCCESS",
  SET_DELETE_USER_SUBSCRIBER_FAILED:
    "user-subscriber/SET_DELETE_USER_SUBSCRIBER_FAILED",

  APPEND_USER_SUBSCRIBERS: "user-subscriber/APPEND_USER_SUBSCRIBERS",

  SET_IS_FETCH_USER_SUBSCRIBERS_HITTED:
    "user-subscriber/SET_IS_FETCH_USER_SUBSCRIBERS_HITTED",
  SET_IS_FETCH_USER_SUBSCRIBER_HITTED:
    "user-subscriber/SET_IS_FETCH_USER_SUBSCRIBER_HITTED",
  SET_IS_CREATE_USER_SUBSCRIBER_HITTED:
    "user-subscriber/SET_IS_CREATE_USER_SUBSCRIBER_HITTED",
  SET_IS_UPDATE_USER_SUBSCRIBER_HITTED:
    "user-subscriber/SET_IS_UPDATE_USER_SUBSCRIBER_HITTED",
  SET_IS_DELETE_USER_SUBSCRIBER_HITTED:
    "user-subscriber/SET_IS_DELETE_USER_SUBSCRIBER_HITTED",

  FETCH_USER_SUBSCRIBERS_START: "user-subscriber/FETCH_USER_SUBSCRIBERS_START",
  FETCH_USER_SUBSCRIBER_START: "user-subscriber/FETCH_USER_SUBSCRIBER_START",
  CREATE_USER_SUBSCRIBER_START: "user-subscriber/CREATE_USER_SUBSCRIBER_START",
  UPDATE_USER_SUBSCRIBER_START: "user-subscriber/UPDATE_USER_SUBSCRIBER_START",
  DELETE_USER_SUBSCRIBER_START: "user-subscriber/DELETE_USER_SUBSCRIBER_START",
};

export default USER_SUBSCRIBER_ACTION_TYPES;
