import CART_ACTION_TYPES from "./cart.type";

export const CART_INITIAL_STATE = {
  customizeProduct: null,
  customizeModifiers: [],
};

export const cartReducer = (state = CART_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CART_ACTION_TYPES.SET_CUSTOMIZE_PRODUCT:
      return { ...state, customizeProduct: payload };
    case CART_ACTION_TYPES.SET_CUSTOMIZE_MODIFIERS:
      return { ...state, customizeModifiers: payload };

    case CART_ACTION_TYPES.RESET_CART_REDUCER:
      return CART_INITIAL_STATE;
    default:
      return state;
  }
};
