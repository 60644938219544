import { createSelector } from "reselect";

const scheduleSelector = ({ schedule }) => schedule;

export const getSchedules = createSelector([scheduleSelector], ({ schedules }) => schedules);

export const getFetchSchedulesIncludes = createSelector(
  [scheduleSelector],
  ({ fetchSchedulesIncludes }) => fetchSchedulesIncludes
);
export const getFetchSchedulesFilterMarketId = createSelector(
  [scheduleSelector],
  ({ fetchSchedulesFilterMarketId }) => fetchSchedulesFilterMarketId
);
export const getFetchSchedulesFilterBranchId = createSelector(
  [scheduleSelector],
  ({ fetchSchedulesFilterBranchId }) => fetchSchedulesFilterBranchId
);
export const getFetchSchedulesLoading = createSelector(
  [scheduleSelector],
  ({ fetchSchedulesLoading }) => fetchSchedulesLoading
);
export const getFetchSchedulesSuccess = createSelector(
  [scheduleSelector],
  ({ fetchSchedulesSuccess }) => fetchSchedulesSuccess
);
export const getFetchSchedulesFailed = createSelector(
  [scheduleSelector],
  ({ fetchSchedulesFailed }) => fetchSchedulesFailed
);

export const getCreateOrUpdateScheduleLoading = createSelector(
  [scheduleSelector],
  ({ createOrUpdateScheduleLoading }) => createOrUpdateScheduleLoading
);
export const getCreateOrUpdateScheduleSuccess = createSelector(
  [scheduleSelector],
  ({ createOrUpdateScheduleSuccess }) => createOrUpdateScheduleSuccess
);
export const getCreateOrUpdateScheduleFailed = createSelector(
  [scheduleSelector],
  ({ createOrUpdateScheduleFailed }) => createOrUpdateScheduleFailed
);

export const getIsFetchSchedulesHitted = createSelector(
  [scheduleSelector],
  ({ isFetchSchedulesHitted }) => isFetchSchedulesHitted
);
export const getIsCreateOrUpdateScheduleHitted = createSelector(
  [scheduleSelector],
  ({ isCreateOrUpdateScheduleHitted }) => isCreateOrUpdateScheduleHitted
);
