const PERMISSION_ACTION_TYPES = {
  RESET_PERMISSION_REDUCER: "permission/RESET_PERMISSION_REDUCER",

  SET_PERMISSIONS: "permission/SET_PERMISSIONS",
  SET_SELECT_PERMISSIONS: "permission/SET_SELECT_PERMISSIONS",
  SET_PERMISSION: "permission/SET_PERMISSION",

  SET_IS_PERMISSIONS_HAS_MORE: "permission/SET_IS_PERMISSIONS_HAS_MORE",
  SET_IS_SELECT_PERMISSIONS_HAS_MORE:
    "permission/SET_IS_SELECT_PERMISSIONS_HAS_MORE",

  SET_FETCH_PERMISSIONS_SEARCH: "permission/SET_FETCH_PERMISSIONS_SEARCH",
  SET_FETCH_PERMISSIONS_PAGE: "permission/SET_FETCH_PERMISSIONS_PAGE",
  SET_FETCH_PERMISSIONS_PER_PAGE: "permission/SET_FETCH_PERMISSIONS_PER_PAGE",
  SET_FETCH_PERMISSIONS_INCLUDES: "permission/SET_FETCH_PERMISSIONS_INCLUDES",
  SET_FETCH_PERMISSIONS_FILTER_MARKET_ID:
    "permission/SET_FETCH_PERMISSIONS_FILTER_MARKET_ID",
  SET_FETCH_PERMISSIONS_LOADING: "permission/SET_FETCH_PERMISSIONS_LOADING",
  SET_FETCH_PERMISSIONS_SUCCESS: "permission/SET_FETCH_PERMISSIONS_SUCCESS",
  SET_FETCH_PERMISSIONS_FAILED: "permission/SET_FETCH_PERMISSIONS_FAILED",

  SET_FETCH_SELECT_PERMISSIONS_PAGE:
    "permission/SET_FETCH_SELECT_PERMISSIONS_PAGE",
  SET_FETCH_SELECT_PERMISSIONS_PER_PAGE:
    "permission/SET_FETCH_SELECT_PERMISSIONS_PER_PAGE",
  SET_FETCH_SELECT_PERMISSIONS_INCLUDES:
    "permission/SET_FETCH_SELECT_PERMISSIONS_INCLUDES",
  SET_FETCH_SELECT_PERMISSIONS_FILTER_MARKET_ID:
    "permission/SET_FETCH_SELECT_PERMISSIONS_FILTER_MARKET_ID",
  SET_FETCH_SELECT_PERMISSIONS_LOADING:
    "permission/SET_FETCH_SELECT_PERMISSIONS_LOADING",
  SET_FETCH_SELECT_PERMISSIONS_SUCCESS:
    "permission/SET_FETCH_SELECT_PERMISSIONS_SUCCESS",
  SET_FETCH_SELECT_PERMISSIONS_FAILED:
    "permission/SET_FETCH_SELECT_PERMISSIONS_FAILED",

  SET_FETCH_PERMISSION_LOADING: "permission/SET_FETCH_PERMISSION_LOADING",
  SET_FETCH_PERMISSION_SUCCESS: "permission/SET_FETCH_PERMISSION_SUCCESS",
  SET_FETCH_PERMISSION_FAILED: "permission/SET_FETCH_PERMISSION_FAILED",

  SET_CREATE_PERMISSION_LOADING: "permission/SET_CREATE_PERMISSION_LOADING",
  SET_CREATE_PERMISSION_SUCCESS: "permission/SET_CREATE_PERMISSION_SUCCESS",
  SET_CREATE_PERMISSION_FAILED: "permission/SET_CREATE_PERMISSION_FAILED",

  SET_UPDATE_PERMISSION_LOADING: "permission/SET_UPDATE_PERMISSION_LOADING",
  SET_UPDATE_PERMISSION_SUCCESS: "permission/SET_UPDATE_PERMISSION_SUCCESS",
  SET_UPDATE_PERMISSION_FAILED: "permission/SET_UPDATE_PERMISSION_FAILED",

  SET_DELETE_PERMISSION_LOADING: "permission/SET_DELETE_PERMISSION_LOADING",
  SET_DELETE_PERMISSION_SUCCESS: "permission/SET_DELETE_PERMISSION_SUCCESS",
  SET_DELETE_PERMISSION_FAILED: "permission/SET_DELETE_PERMISSION_FAILED",

  SET_IS_FETCH_PERMISSIONS_HITTED: "permission/SET_IS_FETCH_PERMISSIONS_HITTED",
  SET_IS_FETCH_SELECT_PERMISSIONS_HITTED:
    "permission/SET_IS_FETCH_SELECT_PERMISSIONS_HITTED",
  SET_IS_FETCH_PERMISSION_HITTED: "permission/SET_IS_FETCH_PERMISSION_HITTED",
  SET_IS_CREATE_PERMISSION_HITTED: "permission/SET_IS_CREATE_PERMISSION_HITTED",
  SET_IS_UPDATE_PERMISSION_HITTED: "permission/SET_IS_UPDATE_PERMISSION_HITTED",
  SET_IS_DELETE_PERMISSION_HITTED: "permission/SET_IS_DELETE_PERMISSION_HITTED",

  FETCH_PERMISSIONS_START: "permission/FETCH_PERMISSIONS_START",
  FETCH_SELECT_PERMISSIONS_START: "permission/FETCH_SELECT_PERMISSIONS_START",
  FETCH_PERMISSION_START: "permission/FETCH_PERMISSION_START",
  CREATE_PERMISSION_START: "permission/CREATE_PERMISSION_START",
  UPDATE_PERMISSION_START: "permission/UPDATE_PERMISSION_START",
  DELETE_PERMISSION_START: "permission/DELETE_PERMISSION_START",

  APPEND_PERMISSIONS: "permission/APPEND_PERMISSIONS",
  APPEND_SELECT_PERMISSIONS: "permission/APPEND_SELECT_PERMISSIONS",
};

export default PERMISSION_ACTION_TYPES;
