import { takeLatest, put, all, call, select } from "redux-saga/effects";

import KITCHEN_ACTION_TYPES from "./kitchen.type";

import { ORDER_STATUSES } from "../../constants/order.constant";

import {
  appendCompletedOrders,
  appendProcessOrders,
  setCompletedOrders,
  setFetchCompletedOrdersFailed,
  setFetchCompletedOrdersLoading,
  setFetchCompletedOrdersSuccess,
  setFetchProcessOrdersFailed,
  setFetchProcessOrdersLoading,
  setFetchProcessOrdersSuccess,
  setIsCompletedOrdersHasMore,
  setIsFetchCompletedOrdersHitted,
  setIsFetchProcessOrdersHitted,
  setIsProcessOrdersHasMore,
  setProcessOrders,
} from "./kitchen.action";
import {
  getFetchCompletedOrdersFilterBranchId,
  getFetchCompletedOrdersFilterEndAt,
  getFetchCompletedOrdersFilterMarketId,
  getFetchCompletedOrdersFilterPeriodType,
  getFetchCompletedOrdersFilterProductCategoriesIds,
  getFetchCompletedOrdersFilterStartAt,
  getFetchCompletedOrdersIncludes,
  getFetchCompletedOrdersPage,
  getFetchCompletedOrdersPerPage,
  getFetchCompletedOrdersSearch,
  getFetchCompletedOrdersSort,
  getFetchProcessOrdersFilterBranchId,
  getFetchProcessOrdersFilterEndAt,
  getFetchProcessOrdersFilterMarketId,
  getFetchProcessOrdersFilterPeriodType,
  getFetchProcessOrdersFilterProductCategoriesIds,
  getFetchProcessOrdersFilterStartAt,
  getFetchProcessOrdersIncludes,
  getFetchProcessOrdersPage,
  getFetchProcessOrdersPerPage,
  getFetchProcessOrdersSearch,
  getFetchProcessOrdersSort,
  getFetchProcessOrdersFilterTypes,
  getFetchCompletedOrdersFilterTypes,
  getFetchProcessOrdersFilterCashierId,
  getFetchCompletedOrdersFilterCashierId,
  getFetchProcessOrdersFilterSources,
  getFetchCompletedOrdersFilterSources,
  getFetchProcessOrdersFilterTableId,
  getFetchCompletedOrdersFilterTableId,
} from "./kitchen.selector";

import { getOrders } from "../../api/order.api";

export function* _getProcessOrders() {
  try {
    yield put(setFetchProcessOrdersLoading(true));

    const search = yield select(getFetchProcessOrdersSearch);
    const sort = yield select(getFetchProcessOrdersSort);
    const page = yield select(getFetchProcessOrdersPage);
    const per_page = yield select(getFetchProcessOrdersPerPage);
    const includes = yield select(getFetchProcessOrdersIncludes);
    const period_type = yield select(getFetchProcessOrdersFilterPeriodType);
    const start_at = yield select(getFetchProcessOrdersFilterStartAt);
    const end_at = yield select(getFetchProcessOrdersFilterEndAt);
    const market_id = yield select(getFetchProcessOrdersFilterMarketId);
    const branch_id = yield select(getFetchProcessOrdersFilterBranchId);
    const product_categories_ids = yield select(getFetchProcessOrdersFilterProductCategoriesIds);
    const sources = yield select(getFetchProcessOrdersFilterSources);
    const types = yield select(getFetchProcessOrdersFilterTypes);
    const table_id = yield select(getFetchProcessOrdersFilterTableId);
    const cashier_id = yield select(getFetchProcessOrdersFilterCashierId);
    const statuses = [ORDER_STATUSES.PROCESS];

    const parameters = {
      search,
      sort,
      page,
      per_page,
      includes,
      filter: {
        period_type,
        start_at,
        end_at,
        market_id,
        branch_id,
        product_categories_ids,
        sources,
        types,
        statuses,
        table_id,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: orders },
    } = yield call(getOrders, parameters);

    yield put(setIsFetchProcessOrdersHitted(true));
    yield put(setIsProcessOrdersHasMore(orders.length > 0));
    yield put(setProcessOrders(orders));

    yield put(setFetchProcessOrdersSuccess(message));
    yield put(setFetchProcessOrdersLoading(false));
  } catch (error) {
    yield put(setFetchProcessOrdersFailed(error));
    yield put(setFetchProcessOrdersLoading(false));
  }
}
export function* _getCompletedOrders() {
  try {
    yield put(setFetchCompletedOrdersLoading(true));

    const search = yield select(getFetchCompletedOrdersSearch);
    const sort = yield select(getFetchCompletedOrdersSort);
    const page = yield select(getFetchCompletedOrdersPage);
    const per_page = yield select(getFetchCompletedOrdersPerPage);
    const includes = yield select(getFetchCompletedOrdersIncludes);
    const period_type = yield select(getFetchCompletedOrdersFilterPeriodType);
    const start_at = yield select(getFetchCompletedOrdersFilterStartAt);
    const end_at = yield select(getFetchCompletedOrdersFilterEndAt);
    const market_id = yield select(getFetchCompletedOrdersFilterMarketId);
    const branch_id = yield select(getFetchCompletedOrdersFilterBranchId);
    const product_categories_ids = yield select(getFetchCompletedOrdersFilterProductCategoriesIds);
    const sources = yield select(getFetchCompletedOrdersFilterSources);
    const types = yield select(getFetchCompletedOrdersFilterTypes);
    const table_id = yield select(getFetchCompletedOrdersFilterTableId);
    const cashier_id = yield select(getFetchCompletedOrdersFilterCashierId);
    const statuses = [ORDER_STATUSES.COMPLETED];

    const parameters = {
      search,
      sort,
      page,
      per_page,
      includes,
      filter: {
        period_type,
        start_at,
        end_at,
        market_id,
        branch_id,
        product_categories_ids,
        sources,
        types,
        statuses,
        table_id,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: orders },
    } = yield call(getOrders, parameters);

    yield put(setIsFetchCompletedOrdersHitted(true));
    yield put(setIsCompletedOrdersHasMore(orders.length > 0));

    if (page > 1) {
      yield put(appendCompletedOrders(orders));
    } else {
      yield put(setCompletedOrders(orders));
    }

    yield put(setFetchCompletedOrdersSuccess(message));
    yield put(setFetchCompletedOrdersLoading(false));
  } catch (error) {
    yield put(setFetchCompletedOrdersFailed(error));
    yield put(setFetchCompletedOrdersLoading(false));
  }
}

export function* onFetchProcessOrdersStart() {
  yield takeLatest(KITCHEN_ACTION_TYPES.FETCH_PROCESS_ORDERS_START, _getProcessOrders);
}
export function* onFetchCompletedOrdersStart() {
  yield takeLatest(KITCHEN_ACTION_TYPES.FETCH_COMPLETED_ORDERS_START, _getCompletedOrders);
}

export function* kitchenSaga() {
  yield all([call(onFetchProcessOrdersStart), call(onFetchCompletedOrdersStart)]);
}
