import { createSelector } from "reselect";

const customerSelector = ({ customer }) => customer;

export const getCustomers = createSelector(
  [customerSelector],
  ({ customers }) => customers
);
export const getSearchCustomers = createSelector(
  [customerSelector],
  ({ searchCustomers }) => searchCustomers
);
export const getCustomer = createSelector(
  [customerSelector],
  ({ customer }) => customer
);
export const getCurrentCustomer = createSelector(
  [customerSelector],
  ({ currentCustomer }) => currentCustomer
);
export const getCreateCustomer = createSelector(
  [customerSelector],
  ({ createCustomer }) => createCustomer
);
export const getUpdateCustomer = createSelector(
  [customerSelector],
  ({ updateCustomer }) => updateCustomer
);

export const getIsCustomersHasMore = createSelector(
  [customerSelector],
  ({ isCustomersHasMore }) => isCustomersHasMore
);
export const getIsSearchCustomersHasMore = createSelector(
  [customerSelector],
  ({ isSearchCustomersHasMore }) => isSearchCustomersHasMore
);

export const getFetchCustomersSearch = createSelector(
  [customerSelector],
  ({ fetchCustomersSearch }) => fetchCustomersSearch
);
export const getFetchCustomersSort = createSelector(
  [customerSelector],
  ({ fetchCustomersSort }) => fetchCustomersSort
);
export const getFetchCustomersKeyBy = createSelector(
  [customerSelector],
  ({ fetchCustomersKeyBy }) => fetchCustomersKeyBy
);
export const getFetchCustomersPage = createSelector(
  [customerSelector],
  ({ fetchCustomersPage }) => fetchCustomersPage
);
export const getFetchCustomersPerPage = createSelector(
  [customerSelector],
  ({ fetchCustomersPerPage }) => fetchCustomersPerPage
);
export const getFetchCustomersIncludes = createSelector(
  [customerSelector],
  ({ fetchCustomersIncludes }) => fetchCustomersIncludes
);
export const getFetchCustomersFilterMarketId = createSelector(
  [customerSelector],
  ({ fetchCustomersFilterMarketId }) => fetchCustomersFilterMarketId
);
export const getFetchCustomersLoading = createSelector(
  [customerSelector],
  ({ fetchCustomersLoading }) => fetchCustomersLoading
);
export const getFetchCustomersSuccess = createSelector(
  [customerSelector],
  ({ fetchCustomersSuccess }) => fetchCustomersSuccess
);
export const getFetchCustomersFailed = createSelector(
  [customerSelector],
  ({ fetchCustomersFailed }) => fetchCustomersFailed
);

export const getFetchSearchCustomersSearch = createSelector(
  [customerSelector],
  ({ fetchSearchCustomersSearch }) => fetchSearchCustomersSearch
);
export const getFetchSearchCustomersSort = createSelector(
  [customerSelector],
  ({ fetchSearchCustomersSort }) => fetchSearchCustomersSort
);
export const getFetchSearchCustomersKeyBy = createSelector(
  [customerSelector],
  ({ fetchSearchCustomersKeyBy }) => fetchSearchCustomersKeyBy
);
export const getFetchSearchCustomersPage = createSelector(
  [customerSelector],
  ({ fetchSearchCustomersPage }) => fetchSearchCustomersPage
);
export const getFetchSearchCustomersPerPage = createSelector(
  [customerSelector],
  ({ fetchSearchCustomersPerPage }) => fetchSearchCustomersPerPage
);
export const getFetchSearchCustomersIncludes = createSelector(
  [customerSelector],
  ({ fetchSearchCustomersIncludes }) => fetchSearchCustomersIncludes
);
export const getFetchSearchCustomersFilterMarketId = createSelector(
  [customerSelector],
  ({ fetchSearchCustomersFilterMarketId }) => fetchSearchCustomersFilterMarketId
);
export const getFetchSearchCustomersLoading = createSelector(
  [customerSelector],
  ({ fetchSearchCustomersLoading }) => fetchSearchCustomersLoading
);
export const getFetchSearchCustomersSuccess = createSelector(
  [customerSelector],
  ({ fetchSearchCustomersSuccess }) => fetchSearchCustomersSuccess
);
export const getFetchSearchCustomersFailed = createSelector(
  [customerSelector],
  ({ fetchSearchCustomersFailed }) => fetchSearchCustomersFailed
);

export const getFetchCustomerLoading = createSelector(
  [customerSelector],
  ({ fetchCustomerLoading }) => fetchCustomerLoading
);
export const getFetchCustomerSuccess = createSelector(
  [customerSelector],
  ({ fetchCustomerSuccess }) => fetchCustomerSuccess
);
export const getFetchCustomerFailed = createSelector(
  [customerSelector],
  ({ fetchCustomerFailed }) => fetchCustomerFailed
);

export const getCreateCustomerLoading = createSelector(
  [customerSelector],
  ({ createCustomerLoading }) => createCustomerLoading
);
export const getCreateCustomerSuccess = createSelector(
  [customerSelector],
  ({ createCustomerSuccess }) => createCustomerSuccess
);
export const getCreateCustomerFailed = createSelector(
  [customerSelector],
  ({ createCustomerFailed }) => createCustomerFailed
);

export const getUpdateCustomerLoading = createSelector(
  [customerSelector],
  ({ updateCustomerLoading }) => updateCustomerLoading
);
export const getUpdateCustomerSuccess = createSelector(
  [customerSelector],
  ({ updateCustomerSuccess }) => updateCustomerSuccess
);
export const getUpdateCustomerFailed = createSelector(
  [customerSelector],
  ({ updateCustomerFailed }) => updateCustomerFailed
);

export const getIsFetchCustomersHitted = createSelector(
  [customerSelector],
  ({ isFetchCustomersHitted }) => isFetchCustomersHitted
);
export const getIsFetchSearchCustomersHitted = createSelector(
  [customerSelector],
  ({ isFetchSearchCustomersHitted }) => isFetchSearchCustomersHitted
);
export const getIsFetchCustomerHitted = createSelector(
  [customerSelector],
  ({ isFetchCustomerHitted }) => isFetchCustomerHitted
);
export const getIsCreateCustomerHitted = createSelector(
  [customerSelector],
  ({ isCreateCustomerHitted }) => isCreateCustomerHitted
);
export const getIsUpdateCustomerHitted = createSelector(
  [customerSelector],
  ({ isUpdateCustomerHitted }) => isUpdateCustomerHitted
);
