import { takeLatest, put, all, call, select } from "redux-saga/effects";

import SECTOR_CATEGORY_ACTION_TYPES from "./sector-category.type";

import {
  appendSectorCategories,
  setFetchSectorCategoriesFailed,
  setFetchSectorCategoriesLoading,
  setFetchSectorCategoriesSuccess,
  setFetchSectorCategoryFailed,
  setFetchSectorCategoryLoading,
  setFetchSectorCategorySuccess,
  setIsFetchSectorCategoriesHitted,
  setIsFetchSectorCategoryHitted,
  setIsSectorCategoriesHasMore,
  setSectorCategories,
  setSectorCategory,
} from "./sector-category.action";
import {
  getFetchSectorCategoriesIncludes,
  getFetchSectorCategoriesKeyBy,
  getFetchSectorCategoriesPage,
  getFetchSectorCategoriesPerPage,
  getFetchSectorCategoriesSearch,
  getFetchSectorCategoriesSort,
} from "./sector-category.selector";

import {
  getSectorCategories,
  getSectorCategory,
} from "../../api/sector-category.api";

export function* _getSectorCategories({ payload: sectorId }) {
  try {
    yield put(setFetchSectorCategoriesLoading(true));

    const search = yield select(getFetchSectorCategoriesSearch);
    const sort = yield select(getFetchSectorCategoriesSort);
    const key_by = yield select(getFetchSectorCategoriesKeyBy);
    const page = yield select(getFetchSectorCategoriesPage);
    const per_page = yield select(getFetchSectorCategoriesPerPage);
    const includes = yield select(getFetchSectorCategoriesIncludes);

    const parameters = { search, sort, key_by, page, per_page, includes };

    const {
      meta: { message },
      data: { data: sectorCategories },
    } = yield call(getSectorCategories, sectorId, parameters);

    yield put(setIsFetchSectorCategoriesHitted(true));
    yield put(setIsSectorCategoriesHasMore(sectorCategories.length > 0));

    if (page > 1) {
      yield put(appendSectorCategories(sectorCategories));
    } else {
      yield put(setSectorCategories(sectorCategories));
    }

    yield put(setFetchSectorCategoriesSuccess(message));
    yield put(setFetchSectorCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchSectorCategoriesFailed(error));
    yield put(setFetchSectorCategoriesLoading(false));
  }
}
export function* _getSectorCategory({ payload: { sectorId, categoryId } }) {
  try {
    yield put(setFetchSectorCategoryLoading(true));

    const {
      meta: { message },
      data: sectorCategory,
    } = yield call(getSectorCategory, sectorId, categoryId);

    yield put(setIsFetchSectorCategoryHitted(true));
    yield put(setSectorCategory(sectorCategory));

    yield put(setFetchSectorCategorySuccess(message));
    yield put(setFetchSectorCategoryLoading(false));
  } catch (error) {
    yield put(setFetchSectorCategoryFailed(error));
    yield put(setFetchSectorCategoryLoading(false));
  }
}

export function* onFetchSectorCategoriesStart() {
  yield takeLatest(
    SECTOR_CATEGORY_ACTION_TYPES.FETCH_SECTOR_CATEGORIES_START,
    _getSectorCategories
  );
}
export function* onFetchSectorCategoryStart() {
  yield takeLatest(
    SECTOR_CATEGORY_ACTION_TYPES.FETCH_SECTOR_CATEGORY_START,
    _getSectorCategory
  );
}

export function* sectorCategorySaga() {
  yield all([
    call(onFetchSectorCategoriesStart),
    call(onFetchSectorCategoryStart),
  ]);
}
