import DASHBOARD_ADMIN_ACTION_TYPES from "./dashboard-admin.type";

export const DASHBOARD_ADMIN_INITIAL_STATE = {
  dashboardTotalMarket: null,
  dashboardTotalBranch: null,
  dashboardTotalOrder: null,
  dashboardTotalRevenue: null,
  dashboardOrdersChart: null,
  dashboardLatestMarkets: [],

  dashboardTotalMarketLoading: false,
  dashboardTotalBranchLoading: false,
  dashboardTotalOrderLoading: false,
  dashboardTotalRevenueLoading: false,
  dashboardOrdersChartLoading: false,
  dashboardLatestMarketsLoading: false,

  fetchDashboardAdminFilterCreatedAtBefore: null,
  fetchDashboardAdminFilterCreatedAtAfter: null,
  fetchDashboardAdminFilterCurrency: null,
  fetchDashboardAdminLoading: false,
  fetchDashboardAdminSuccess: null,
  fetchDashboardAdminFailed: null,

  isFetchDashboardAdminHitted: false,
};

export const dashboardAdminReducer = (
  state = DASHBOARD_ADMIN_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_MARKET:
      return { ...state, dashboardTotalMarket: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_BRANCH:
      return { ...state, dashboardTotalBranch: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_ORDER:
      return { ...state, dashboardTotalOrder: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_REVENUE:
      return { ...state, dashboardTotalRevenue: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_ORDERS_CHART:
      return { ...state, dashboardOrdersChart: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_LATEST_MARKETS:
      return { ...state, dashboardLatestMarkets: payload };

    case DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_MARKET_LOADING:
      return { ...state, dashboardTotalMarketLoading: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_BRANCH_LOADING:
      return { ...state, dashboardTotalBranchLoading: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_ORDER_LOADING:
      return { ...state, dashboardTotalOrderLoading: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_TOTAL_REVENUE_LOADING:
      return { ...state, dashboardTotalRevenueLoading: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_ORDERS_CHART_LOADING:
      return { ...state, dashboardOrdersChartLoading: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_DASHBOARD_LATEST_MARKETS_LOADING:
      return { ...state, dashboardLatestMarketsLoading: payload };

    case DASHBOARD_ADMIN_ACTION_TYPES.SET_FETCH_DASHBOARD_ADMIN_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchDashboardAdminFilterCreatedAtBefore: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_FETCH_DASHBOARD_ADMIN_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchDashboardAdminFilterCreatedAtAfter: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_FETCH_DASHBOARD_ADMIN_FILTER_CURRENCY:
      return { ...state, fetchDashboardAdminFilterCurrency: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_FETCH_DASHBOARD_ADMIN_LOADING:
      return { ...state, fetchDashboardAdminLoading: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_FETCH_DASHBOARD_ADMIN_SUCCESS:
      return { ...state, fetchDashboardAdminSuccess: payload };
    case DASHBOARD_ADMIN_ACTION_TYPES.SET_FETCH_DASHBOARD_ADMIN_FAILED:
      return { ...state, fetchDashboardAdminFailed: payload };

    case DASHBOARD_ADMIN_ACTION_TYPES.SET_IS_FETCH_DASHBOARD_ADMIN_HITTED:
      return { ...state, isFetchDashboardAdminHitted: payload };

    case DASHBOARD_ADMIN_ACTION_TYPES.RESET_DASHBOARD_ADMIN_REDUCER:
      return DASHBOARD_ADMIN_INITIAL_STATE;
    default:
      return state;
  }
};
