import { createSelector } from "reselect";

const sectorCategorySelector = ({ sectorCategory }) => sectorCategory;

export const getSectorCategories = createSelector(
  [sectorCategorySelector],
  ({ sectorCategories }) => sectorCategories
);
export const getSectorCategory = createSelector(
  [sectorCategorySelector],
  ({ sectorCategory }) => sectorCategory
);

export const getIsSectorCategoriesHasMore = createSelector(
  [sectorCategorySelector],
  ({ isSectorCategoriesHasMore }) => isSectorCategoriesHasMore
);

export const getFetchSectorCategoriesSearch = createSelector(
  [sectorCategorySelector],
  ({ fetchSectorCategoriesSearch }) => fetchSectorCategoriesSearch
);
export const getFetchSectorCategoriesSort = createSelector(
  [sectorCategorySelector],
  ({ fetchSectorCategoriesSort }) => fetchSectorCategoriesSort
);
export const getFetchSectorCategoriesKeyBy = createSelector(
  [sectorCategorySelector],
  ({ fetchSectorCategoriesKeyBy }) => fetchSectorCategoriesKeyBy
);
export const getFetchSectorCategoriesPage = createSelector(
  [sectorCategorySelector],
  ({ fetchSectorCategoriesPage }) => fetchSectorCategoriesPage
);
export const getFetchSectorCategoriesPerPage = createSelector(
  [sectorCategorySelector],
  ({ fetchSectorCategoriesPerPage }) => fetchSectorCategoriesPerPage
);
export const getFetchSectorCategoriesIncludes = createSelector(
  [sectorCategorySelector],
  ({ fetchSectorCategoriesIncludes }) => fetchSectorCategoriesIncludes
);
export const getFetchSectorCategoriesLoading = createSelector(
  [sectorCategorySelector],
  ({ fetchSectorCategoriesLoading }) => fetchSectorCategoriesLoading
);
export const getFetchSectorCategoriesSuccess = createSelector(
  [sectorCategorySelector],
  ({ fetchSectorCategoriesSuccess }) => fetchSectorCategoriesSuccess
);
export const getFetchSectorCategoriesFailed = createSelector(
  [sectorCategorySelector],
  ({ fetchSectorCategoriesFailed }) => fetchSectorCategoriesFailed
);

export const getFetchSectorCategoryLoading = createSelector(
  [sectorCategorySelector],
  ({ fetchSectorCategoryLoading }) => fetchSectorCategoryLoading
);
export const getFetchSectorCategorySuccess = createSelector(
  [sectorCategorySelector],
  ({ fetchSectorCategorySuccess }) => fetchSectorCategorySuccess
);
export const getFetchSectorCategoryFailed = createSelector(
  [sectorCategorySelector],
  ({ fetchSectorCategoryFailed }) => fetchSectorCategoryFailed
);

export const getIsFetchSectorCategoriesHitted = createSelector(
  [sectorCategorySelector],
  ({ isFetchSectorCategoriesHitted }) => isFetchSectorCategoriesHitted
);
export const getIsFetchSectorCategoryHitted = createSelector(
  [sectorCategorySelector],
  ({ isFetchSectorCategoryHitted }) => isFetchSectorCategoryHitted
);
