import { getRemovedDuplicates } from "../../utils/formatter.utils";

import RECIPE_ACTION_TYPES from "./recipe.type";

export const RECIPE_INITIAL_STATE = {
  recipes: [],
  recipe: null,

  isRecipesHasMore: true,

  fetchRecipesSearch: null,
  fetchRecipesPage: 1,
  fetchRecipesPerPage: null,
  fetchRecipesIncludes: null,
  fetchRecipesFilterMarketId: null,
  fetchRecipesFilterBranchId: null,
  fetchRecipesLoading: false,
  fetchRecipesSuccess: null,
  fetchRecipesFailed: null,

  fetchRecipeLoading: false,
  fetchRecipeSuccess: null,
  fetchRecipeFailed: null,

  createRecipeLoading: false,
  createRecipeSuccess: null,
  createRecipeFailed: null,

  updateRecipeLoading: false,
  updateRecipeSuccess: null,
  updateRecipeFailed: null,

  deleteRecipeLoading: false,
  deleteRecipeSuccess: null,
  deleteRecipeFailed: null,

  isFetchRecipesHitted: false,
  isFetchRecipeHitted: false,
  isCreateRecipeHitted: false,
  isUpdateRecipeHitted: false,
  isDeleteRecipeHitted: false,
};

export const recipeReducer = (state = RECIPE_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RECIPE_ACTION_TYPES.SET_RECIPES:
      return { ...state, recipes: payload };
    case RECIPE_ACTION_TYPES.SET_RECIPE:
      return { ...state, recipe: payload };

    case RECIPE_ACTION_TYPES.SET_IS_RECIPES_HAS_MORE:
      return { ...state, isRecipesHasMore: payload };

    case RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_SEARCH:
      return { ...state, fetchRecipesSearch: payload };
    case RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_PAGE:
      return { ...state, fetchRecipesPage: payload };
    case RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_PER_PAGE:
      return { ...state, fetchRecipesPerPage: payload };
    case RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_INCLUDES:
      return { ...state, fetchRecipesIncludes: payload };
    case RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_FILTER_MARKET_ID:
      return { ...state, fetchRecipesFilterMarketId: payload };
    case RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_FILTER_BRANCH_ID:
      return { ...state, fetchRecipesFilterBranchId: payload };
    case RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_LOADING:
      return { ...state, fetchRecipesLoading: payload };
    case RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_SUCCESS:
      return { ...state, fetchRecipesSuccess: payload };
    case RECIPE_ACTION_TYPES.SET_FETCH_RECIPES_FAILED:
      return { ...state, fetchRecipesFailed: payload };

    case RECIPE_ACTION_TYPES.SET_FETCH_RECIPE_LOADING:
      return { ...state, fetchRecipeLoading: payload };
    case RECIPE_ACTION_TYPES.SET_FETCH_RECIPE_SUCCESS:
      return { ...state, fetchRecipeSuccess: payload };
    case RECIPE_ACTION_TYPES.SET_FETCH_RECIPE_FAILED:
      return { ...state, fetchRecipeFailed: payload };

    case RECIPE_ACTION_TYPES.SET_CREATE_RECIPE_LOADING:
      return { ...state, createRecipeLoading: payload };
    case RECIPE_ACTION_TYPES.SET_CREATE_RECIPE_SUCCESS:
      return { ...state, createRecipeSuccess: payload };
    case RECIPE_ACTION_TYPES.SET_CREATE_RECIPE_FAILED:
      return { ...state, createRecipeFailed: payload };

    case RECIPE_ACTION_TYPES.SET_UPDATE_RECIPE_LOADING:
      return { ...state, updateRecipeLoading: payload };
    case RECIPE_ACTION_TYPES.SET_UPDATE_RECIPE_SUCCESS:
      return { ...state, updateRecipeSuccess: payload };
    case RECIPE_ACTION_TYPES.SET_UPDATE_RECIPE_FAILED:
      return { ...state, updateRecipeFailed: payload };

    case RECIPE_ACTION_TYPES.SET_DELETE_RECIPE_LOADING:
      return { ...state, deleteRecipeLoading: payload };
    case RECIPE_ACTION_TYPES.SET_DELETE_RECIPE_SUCCESS:
      return { ...state, deleteRecipeSuccess: payload };
    case RECIPE_ACTION_TYPES.SET_DELETE_RECIPE_FAILED:
      return { ...state, deleteRecipeFailed: payload };

    case RECIPE_ACTION_TYPES.APPEND_RECIPES:
      return { ...state, recipes: getRemovedDuplicates([...state.recipes, ...payload], "id") };

    case RECIPE_ACTION_TYPES.SET_IS_FETCH_RECIPES_HITTED:
      return { ...state, isFetchRecipesHitted: payload };
    case RECIPE_ACTION_TYPES.SET_IS_FETCH_RECIPE_HITTED:
      return { ...state, isFetchRecipeHitted: payload };
    case RECIPE_ACTION_TYPES.SET_IS_CREATE_RECIPE_HITTED:
      return { ...state, isCreateRecipeHitted: payload };
    case RECIPE_ACTION_TYPES.SET_IS_UPDATE_RECIPE_HITTED:
      return { ...state, isUpdateRecipeHitted: payload };
    case RECIPE_ACTION_TYPES.SET_IS_DELETE_RECIPE_HITTED:
      return { ...state, isDeleteRecipeHitted: payload };

    case RECIPE_ACTION_TYPES.RESET_RECIPE_REDUCER:
      return RECIPE_INITIAL_STATE;
    default:
      return state;
  }
};
