import TERM_CONDITION_ACTION_TYPES from "./term-condition.type";

export const TERM_CONDITION_INITIAL_STATE = {
  termConditions: [],
  modalTermConditions: [],
  termCondition: null,
  isTermConditionsHasMore: true,
  isModalTermConditionsHasMore: true,
  fetchTermConditionsSearch: null,
  fetchTermConditionsPage: 1,
  fetchTermConditionsPerPage: null,
  fetchTermConditionsIncludes: null,
  fetchTermConditionsLoading: false,
  fetchTermConditionsSuccess: null,
  fetchTermConditionsFailed: null,
  fetchModalTermConditionsPage: 1,
  fetchModalTermConditionsPerPage: null,
  fetchModalTermConditionsIncludes: null,
  fetchModalTermConditionsLoading: false,
  fetchModalTermConditionsSuccess: null,
  fetchModalTermConditionsFailed: null,
  fetchTermConditionLoading: false,
  fetchTermConditionSuccess: null,
  fetchTermConditionFailed: null,
  createTermConditionLoading: false,
  createTermConditionSuccess: null,
  createTermConditionFailed: null,
  updateTermConditionLoading: false,
  updateTermConditionSuccess: null,
  updateTermConditionFailed: null,
  deleteTermConditionLoading: false,
  deleteTermConditionSuccess: null,
  deleteTermConditionFailed: null,
};

export const termConditionReducer = (state = TERM_CONDITION_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TERM_CONDITION_ACTION_TYPES.SET_TERM_CONDITIONS:
      return { ...state, termConditions: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_MODAL_TERM_CONDITIONS:
      return { ...state, modalTermConditions: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_TERM_CONDITION:
      return { ...state, termCondition: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_IS_TERM_CONDITIONS_HAS_MORE:
      return { ...state, isTermConditionsHasMore: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_IS_MODAL_TERM_CONDITIONS_HAS_MORE:
      return { ...state, isModalTermConditionsHasMore: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_SEARCH:
      return { ...state, fetchTermConditionsSearch: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_PAGE:
      return { ...state, fetchTermConditionsPage: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_PER_PAGE:
      return { ...state, fetchTermConditionsPerPage: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_INCLUDES:
      return { ...state, fetchTermConditionsIncludes: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_LOADING:
      return { ...state, fetchTermConditionsLoading: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_SUCCESS:
      return { ...state, fetchTermConditionsSuccess: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITIONS_FAILED:
      return { ...state, fetchTermConditionsFailed: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_MODAL_TERM_CONDITIONS_PAGE:
      return { ...state, fetchModalTermConditionsPage: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_MODAL_TERM_CONDITIONS_PER_PAGE:
      return { ...state, fetchModalTermConditionsPerPage: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_MODAL_TERM_CONDITIONS_INCLUDES:
      return { ...state, fetchModalTermConditionsIncludes: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_MODAL_TERM_CONDITIONS_LOADING:
      return { ...state, fetchModalTermConditionsLoading: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_MODAL_TERM_CONDITIONS_SUCCESS:
      return { ...state, fetchModalTermConditionsSuccess: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_MODAL_TERM_CONDITIONS_FAILED:
      return { ...state, fetchModalTermConditionsFailed: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITION_LOADING:
      return { ...state, fetchTermConditionLoading: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITION_SUCCESS:
      return { ...state, fetchTermConditionSuccess: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_FETCH_TERM_CONDITION_FAILED:
      return { ...state, fetchTermConditionFailed: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_CREATE_TERM_CONDITION_LOADING:
      return { ...state, createTermConditionLoading: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_CREATE_TERM_CONDITION_SUCCESS:
      return { ...state, createTermConditionSuccess: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_CREATE_TERM_CONDITION_FAILED:
      return { ...state, createTermConditionFailed: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_UPDATE_TERM_CONDITION_LOADING:
      return { ...state, updateTermConditionLoading: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_UPDATE_TERM_CONDITION_SUCCESS:
      return { ...state, updateTermConditionSuccess: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_UPDATE_TERM_CONDITION_FAILED:
      return { ...state, updateTermConditionFailed: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_DELETE_TERM_CONDITION_LOADING:
      return { ...state, deleteTermConditionLoading: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_DELETE_TERM_CONDITION_SUCCESS:
      return { ...state, deleteTermConditionSuccess: payload };
    case TERM_CONDITION_ACTION_TYPES.SET_DELETE_TERM_CONDITION_FAILED:
      return { ...state, deleteTermConditionFailed: payload };
    case TERM_CONDITION_ACTION_TYPES.APPEND_TERM_CONDITIONS:
      return { ...state, termConditions: [...state.termConditions, ...payload] };
    case TERM_CONDITION_ACTION_TYPES.APPEND_MODAL_TERM_CONDITIONS:
      return { ...state, modalTermConditions: [...state.modalTermConditions, ...payload] };
    case TERM_CONDITION_ACTION_TYPES.RESET_TERM_CONDITION_REDUCER:
      return TERM_CONDITION_INITIAL_STATE;
    default:
      return state;
  }
};
