const INGREDIENT_HISTORY_ACTION_TYPES = {
  RESET_INGREDIENT_HISTORY_REDUCER: "ingredient-history/RESET_INGREDIENT_HISTORY_REDUCER",

  SET_INGREDIENT_HISTORIES: "ingredient-history/SET_INGREDIENT_HISTORIES",
  SET_INGREDIENT_HISTORY: "ingredient-history/SET_INGREDIENT_HISTORY",

  SET_IS_INGREDIENT_HISTORIES_HAS_MORE: "ingredient-history/SET_IS_INGREDIENT_HISTORIES_HAS_MORE",

  SET_FETCH_INGREDIENT_HISTORIES_SEARCH: "ingredient-history/SET_FETCH_INGREDIENT_HISTORIES_SEARCH",
  SET_FETCH_INGREDIENT_HISTORIES_SORT: "ingredient-history/SET_FETCH_INGREDIENT_HISTORIES_SORT",
  SET_FETCH_INGREDIENT_HISTORIES_PAGE: "ingredient-history/SET_FETCH_INGREDIENT_HISTORIES_PAGE",
  SET_FETCH_INGREDIENT_HISTORIES_PER_PAGE: "ingredient-history/SET_FETCH_INGREDIENT_HISTORIES_PER_PAGE",
  SET_FETCH_INGREDIENT_HISTORIES_LOADING: "ingredient-history/SET_FETCH_INGREDIENT_HISTORIES_LOADING",
  SET_FETCH_INGREDIENT_HISTORIES_SUCCESS: "ingredient-history/SET_FETCH_INGREDIENT_HISTORIES_SUCCESS",
  SET_FETCH_INGREDIENT_HISTORIES_FAILED: "ingredient-history/SET_FETCH_INGREDIENT_HISTORIES_FAILED",

  SET_FETCH_INGREDIENT_HISTORY_LOADING: "ingredient/SET_FETCH_INGREDIENT_HISTORY_LOADING",
  SET_FETCH_INGREDIENT_HISTORY_SUCCESS: "ingredient/SET_FETCH_INGREDIENT_HISTORY_SUCCESS",
  SET_FETCH_INGREDIENT_HISTORY_FAILED: "ingredient/SET_FETCH_INGREDIENT_HISTORY_FAILED",

  APPEND_INGREDIENT_HISTORIES: "ingredient-history/APPEND_INGREDIENT_HISTORIES",

  SET_IS_FETCH_INGREDIENT_HISTORIES_HITTED: "ingredient-history/SET_IS_FETCH_INGREDIENT_HISTORIES_HITTED",
  SET_IS_FETCH_INGREDIENT_HISTORY_HITTED: "ingredient-history/SET_IS_FETCH_INGREDIENT_HISTORY_HITTED",

  FETCH_INGREDIENT_HISTORIES_START: "ingredient-history/FETCH_INGREDIENT_HISTORIES_START",
  FETCH_INGREDIENT_HISTORY_START: "ingredient-history/FETCH_INGREDIENT_HISTORY_START",
};

export default INGREDIENT_HISTORY_ACTION_TYPES;
