import { createSelector } from "reselect";

const adminSelector = ({ admin }) => admin;

export const getAdmins = createSelector([adminSelector], ({ admins }) => admins);

export const getAdmin = createSelector([adminSelector], ({ admin }) => admin);

export const getIsAdminsHasMore = createSelector([adminSelector], ({ isAdminsHasMore }) => isAdminsHasMore);

export const getFetchAdminsSearch = createSelector([adminSelector], ({ fetchAdminsSearch }) => fetchAdminsSearch);

export const getFetchAdminsPage = createSelector([adminSelector], ({ fetchAdminsPage }) => fetchAdminsPage);

export const getFetchAdminsPerPage = createSelector([adminSelector], ({ fetchAdminsPerPage }) => fetchAdminsPerPage);

export const getFetchAdminsIncludes = createSelector([adminSelector], ({ fetchAdminsIncludes }) => fetchAdminsIncludes);

export const getFetchAdminsFilterPermissionId = createSelector(
  [adminSelector],
  ({ fetchAdminsFilterPermissionId }) => fetchAdminsFilterPermissionId
);

export const getFetchAdminsLoading = createSelector([adminSelector], ({ fetchAdminsLoading }) => fetchAdminsLoading);

export const getFetchAdminsSuccess = createSelector([adminSelector], ({ fetchAdminsSuccess }) => fetchAdminsSuccess);

export const getFetchAdminsFailed = createSelector([adminSelector], ({ fetchAdminsFailed }) => fetchAdminsFailed);

export const getFetchAdminLoading = createSelector([adminSelector], ({ fetchAdminLoading }) => fetchAdminLoading);

export const getFetchAdminSuccess = createSelector([adminSelector], ({ fetchAdminSuccess }) => fetchAdminSuccess);

export const getFetchAdminFailed = createSelector([adminSelector], ({ fetchAdminFailed }) => fetchAdminFailed);

export const getCreateAdminLoading = createSelector([adminSelector], ({ createAdminLoading }) => createAdminLoading);

export const getCreateAdminSuccess = createSelector([adminSelector], ({ createAdminSuccess }) => createAdminSuccess);

export const getCreateAdminFailed = createSelector([adminSelector], ({ createAdminFailed }) => createAdminFailed);

export const getUpdateAdminLoading = createSelector([adminSelector], ({ updateAdminLoading }) => updateAdminLoading);

export const getUpdateAdminSuccess = createSelector([adminSelector], ({ updateAdminSuccess }) => updateAdminSuccess);

export const getUpdateAdminFailed = createSelector([adminSelector], ({ updateAdminFailed }) => updateAdminFailed);

export const getDeleteAdminLoading = createSelector([adminSelector], ({ deleteAdminLoading }) => deleteAdminLoading);

export const getDeleteAdminSuccess = createSelector([adminSelector], ({ deleteAdminSuccess }) => deleteAdminSuccess);

export const getDeleteAdminFailed = createSelector([adminSelector], ({ deleteAdminFailed }) => deleteAdminFailed);

export const getIsFetchAdminsHitted = createSelector([adminSelector], ({ isFetchAdminsHitted }) => isFetchAdminsHitted);

export const getIsFetchAdminHitted = createSelector([adminSelector], ({ isFetchAdminHitted }) => isFetchAdminHitted);

export const getIsCreateAdminHitted = createSelector([adminSelector], ({ isCreateAdminHitted }) => isCreateAdminHitted);

export const getIsUpdateAdminHitted = createSelector([adminSelector], ({ isUpdateAdminHitted }) => isUpdateAdminHitted);

export const getIsDeleteAdminHitted = createSelector([adminSelector], ({ isDeleteAdminHitted }) => isDeleteAdminHitted);
