import USER_DRIVER_ACTION_TYPES from "./user-driver.type";
import { createAction } from "../../utils/store.utils";

export const setUserDrivers = (userDrivers) =>
  createAction(USER_DRIVER_ACTION_TYPES.SET_USER_DRIVERS, userDrivers);
export const setUserDriver = (userDriver) =>
  createAction(USER_DRIVER_ACTION_TYPES.SET_USER_DRIVER, userDriver);

export const setIsUserDriversHasMore = (isUserDriversHasMore) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_IS_USER_DRIVERS_HAS_MORE,
    isUserDriversHasMore
  );

export const setFetchUserDriversSearch = (fetchUserDriversSearch) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_SEARCH,
    fetchUserDriversSearch
  );
export const setFetchUserDriversSort = (fetchUserDriversSort) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_SORT,
    fetchUserDriversSort
  );
export const setFetchUserDriversKeyBy = (fetchUserDriversKeyBy) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_KEY_BY,
    fetchUserDriversKeyBy
  );
export const setFetchUserDriversPage = (fetchUserDriversPage) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_PAGE,
    fetchUserDriversPage
  );
export const setFetchUserDriversPerPage = (fetchUserDriversPerPage) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_PER_PAGE,
    fetchUserDriversPerPage
  );
export const setFetchUserDriversIncludes = (fetchUserDriversIncludes) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_INCLUDES,
    fetchUserDriversIncludes
  );
export const setFetchUserDriversFilterGender = (fetchUserDriversFilterGender) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_FILTER_GENDER,
    fetchUserDriversFilterGender
  );
export const setFetchUserDriversFilterStatus = (fetchUserDriversFilterStatus) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_FILTER_STATUS,
    fetchUserDriversFilterStatus
  );
export const setFetchUserDriversFilterVehicleType = (
  fetchUserDriversFilterVehicleType
) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_FILTER_VEHICLE_TYPE,
    fetchUserDriversFilterVehicleType
  );
export const setFetchUserDriversFilterMarketId = (
  fetchUserDriversFilterMarketId
) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_FILTER_MARKET_ID,
    fetchUserDriversFilterMarketId
  );
export const setFetchUserDriversLoading = (fetchUserDriversLoading) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_LOADING,
    fetchUserDriversLoading
  );
export const setFetchUserDriversSuccess = (fetchUserDriversSuccess) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_SUCCESS,
    fetchUserDriversSuccess
  );
export const setFetchUserDriversFailed = (fetchUserDriversFailed) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVERS_FAILED,
    fetchUserDriversFailed
  );

export const setFetchUserDriverLoading = (fetchUserDriverLoading) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVER_LOADING,
    fetchUserDriverLoading
  );
export const setFetchUserDriverSuccess = (fetchUserDriverSuccess) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVER_SUCCESS,
    fetchUserDriverSuccess
  );
export const setFetchUserDriverFailed = (fetchUserDriverFailed) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_FETCH_USER_DRIVER_FAILED,
    fetchUserDriverFailed
  );

export const setCreateUserDriverLoading = (createUserDriverLoading) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_CREATE_USER_DRIVER_LOADING,
    createUserDriverLoading
  );
export const setCreateUserDriverSuccess = (createUserDriverSuccess) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_CREATE_USER_DRIVER_SUCCESS,
    createUserDriverSuccess
  );
export const setCreateUserDriverFailed = (createUserDriverFailed) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_CREATE_USER_DRIVER_FAILED,
    createUserDriverFailed
  );

export const setUpdateUserDriverLoading = (updateUserDriverLoading) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_UPDATE_USER_DRIVER_LOADING,
    updateUserDriverLoading
  );
export const setUpdateUserDriverSuccess = (updateUserDriverSuccess) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_UPDATE_USER_DRIVER_SUCCESS,
    updateUserDriverSuccess
  );
export const setUpdateUserDriverFailed = (updateUserDriverFailed) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_UPDATE_USER_DRIVER_FAILED,
    updateUserDriverFailed
  );

export const setAddMarketUserDriverLoading = (addMarketUserDriverLoading) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_ADD_MARKET_USER_DRIVER_LOADING,
    addMarketUserDriverLoading
  );
export const setAddMarketUserDriverSuccess = (addMarketUserDriverSuccess) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_ADD_MARKET_USER_DRIVER_SUCCESS,
    addMarketUserDriverSuccess
  );
export const setAddMarketUserDriverFailed = (addMarketUserDriverFailed) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_ADD_MARKET_USER_DRIVER_FAILED,
    addMarketUserDriverFailed
  );

export const setRemoveMarketUserDriverLoading = (
  removeMarketUserDriverLoading
) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_REMOVE_MARKET_USER_DRIVER_LOADING,
    removeMarketUserDriverLoading
  );
export const setRemoveMarketUserDriverSuccess = (
  removeMarketUserDriverSuccess
) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_REMOVE_MARKET_USER_DRIVER_SUCCESS,
    removeMarketUserDriverSuccess
  );
export const setRemoveMarketUserDriverFailed = (removeMarketUserDriverFailed) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_REMOVE_MARKET_USER_DRIVER_FAILED,
    removeMarketUserDriverFailed
  );

export const appendUserDrivers = (userDrivers) =>
  createAction(USER_DRIVER_ACTION_TYPES.APPEND_USER_DRIVERS, userDrivers);

export const fetchUserDriversStart = () =>
  createAction(USER_DRIVER_ACTION_TYPES.FETCH_USER_DRIVERS_START);
export const fetchUserDriverStart = (driverId) =>
  createAction(USER_DRIVER_ACTION_TYPES.FETCH_USER_DRIVER_START, driverId);
export const createUserDriverStart = (request) =>
  createAction(USER_DRIVER_ACTION_TYPES.CREATE_USER_DRIVER_START, request);
export const updateUserDriverStart = (driverId, request) =>
  createAction(USER_DRIVER_ACTION_TYPES.UPDATE_USER_DRIVER_START, {
    driverId,
    request,
  });
export const addMarketUserDriverStart = (driverId, marketId) =>
  createAction(USER_DRIVER_ACTION_TYPES.ADD_MARKET_USER_DRIVER_START, {
    driverId,
    marketId,
  });
export const removeMarketUserDriverStart = (driverId, marketId) =>
  createAction(USER_DRIVER_ACTION_TYPES.REMOVE_MARKET_USER_DRIVER_START, {
    driverId,
    marketId,
  });

export const setIsFetchUserDriversHitted = (isFetchUserDriversHitted) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_IS_FETCH_USER_DRIVERS_HITTED,
    isFetchUserDriversHitted
  );
export const setIsFetchUserDriverHitted = (isFetchUserDriverHitted) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_IS_FETCH_USER_DRIVER_HITTED,
    isFetchUserDriverHitted
  );
export const setIsCreateUserDriverHitted = (isCreateUserDriverHitted) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_IS_CREATE_USER_DRIVER_HITTED,
    isCreateUserDriverHitted
  );
export const setIsUpdateUserDriverHitted = (isUpdateUserDriverHitted) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_IS_UPDATE_USER_DRIVER_HITTED,
    isUpdateUserDriverHitted
  );
export const setIsAddMarketUserDriverHitted = (isAddMarketUserDriverHitted) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_IS_ADD_MARKET_USER_DRIVER_HITTED,
    isAddMarketUserDriverHitted
  );
export const setIsRemoveMarketUserDriverHitted = (
  isRemoveMarketUserDriverHitted
) =>
  createAction(
    USER_DRIVER_ACTION_TYPES.SET_IS_REMOVE_MARKET_USER_DRIVER_HITTED,
    isRemoveMarketUserDriverHitted
  );

export const resetUserDriverReducer = () =>
  createAction(USER_DRIVER_ACTION_TYPES.RESET_USER_DRIVER_REDUCER);
