import apiService from "./api";

export const getUserSubscribers = async (parameters) =>
  (await apiService.get("/v2/users/subscribers", parameters)).data;
export const getUserSubscriber = async (subscriberId) =>
  (await apiService.get(`/v2/users/subscribers/${subscriberId}`)).data;
export const createUserSubscriber = async (request) =>
  (await apiService.post("/v2/users/subscribers", request)).data;
export const updateUserSubscriber = async (subscriberId, request) =>
  (await apiService.post(`/v2/users/subscribers/${subscriberId}`, request))
    .data;
export const deleteUserSubscriber = async (subscriberId) =>
  (await apiService.delete(`/v2/users/subscribers/${subscriberId}`)).data;
