import { createSelector } from "reselect";

const cardSelector = ({ card }) => card;

export const getCards = createSelector([cardSelector], ({ cards }) => cards);

export const getCard = createSelector([cardSelector], ({ card }) => card);

export const getIsCardsHasMore = createSelector([cardSelector], ({ isCardsHasMore }) => isCardsHasMore);

export const getFetchCardsSearch = createSelector([cardSelector], ({ fetchCardsSearch }) => fetchCardsSearch);

export const getFetchCardsSort = createSelector([cardSelector], ({ fetchCardsSort }) => fetchCardsSort);

export const getFetchCardsPage = createSelector([cardSelector], ({ fetchCardsPage }) => fetchCardsPage);

export const getFetchCardsPerPage = createSelector([cardSelector], ({ fetchCardsPerPage }) => fetchCardsPerPage);

export const getFetchCardsIncludes = createSelector([cardSelector], ({ fetchCardsIncludes }) => fetchCardsIncludes);

export const getFetchCardsFilterTransferType = createSelector(
  [cardSelector],
  ({ fetchCardsFilterTransferType }) => fetchCardsFilterTransferType
);

export const getFetchCardsFilterIsVerified = createSelector(
  [cardSelector],
  ({ fetchCardsFilterIsVerified }) => fetchCardsFilterIsVerified
);

export const getFetchCardsLoading = createSelector([cardSelector], ({ fetchCardsLoading }) => fetchCardsLoading);

export const getFetchCardsSuccess = createSelector([cardSelector], ({ fetchCardsSuccess }) => fetchCardsSuccess);

export const getFetchCardsFailed = createSelector([cardSelector], ({ fetchCardsFailed }) => fetchCardsFailed);

export const getFetchCardLoading = createSelector([cardSelector], ({ fetchCardLoading }) => fetchCardLoading);

export const getFetchCardSuccess = createSelector([cardSelector], ({ fetchCardSuccess }) => fetchCardSuccess);

export const getFetchCardFailed = createSelector([cardSelector], ({ fetchCardFailed }) => fetchCardFailed);

export const getCreateOrUpdateCardLoading = createSelector(
  [cardSelector],
  ({ createOrUpdateCardLoading }) => createOrUpdateCardLoading
);

export const getCreateOrUpdateCardSuccess = createSelector(
  [cardSelector],
  ({ createOrUpdateCardSuccess }) => createOrUpdateCardSuccess
);

export const getCreateOrUpdateCardFailed = createSelector(
  [cardSelector],
  ({ createOrUpdateCardFailed }) => createOrUpdateCardFailed
);

export const getIsFetchCardsHitted = createSelector([cardSelector], ({ isFetchCardsHitted }) => isFetchCardsHitted);

export const getIsFetchCardHitted = createSelector([cardSelector], ({ isFetchCardHitted }) => isFetchCardHitted);

export const getIsCreateOrUpdateCardHitted = createSelector(
  [cardSelector],
  ({ isCreateOrUpdateCardHitted }) => isCreateOrUpdateCardHitted
);
